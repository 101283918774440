import { PaymentIconProps } from './types';

const Ideal = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H40C43.3137 0 46 2.68629 46 6V26C46 29.3137 43.3137 32 40 32H6C2.68629 32 0 29.3137 0 26V6Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3223 6.60791H23.1099C30.4403 6.60791 33.2573 10.7318 33.2573 15.9445C33.2573 22.1799 29.32 25.3141 23.1099 25.3141H12.3223V6.60791ZM13.3465 7.72962V24.2583H23.1098C28.9998 24.2583 32.2008 21.4211 32.2008 15.9775C32.2008 10.3689 28.7437 7.72962 23.1098 7.72962H13.3465ZM16.2276 16.2744C17.306 16.2744 18.1802 15.3734 18.1802 14.2619C18.1802 13.1504 17.306 12.2494 16.2276 12.2494C15.1491 12.2494 14.2749 13.1504 14.2749 14.2619C14.2749 15.3734 15.1491 16.2744 16.2276 16.2744ZM14.6591 17.1322H17.7962V22.8727H14.6591V17.1322Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6474 12.4144H29.5437V15.1857H30.8561C30.504 10.138 26.6307 9.0163 23.1095 9.0163H19.3643V12.4144H19.9084C20.9328 12.4144 21.541 13.1072 21.541 14.2619C21.541 15.4166 20.9328 16.1095 19.9405 16.1095H19.3643V22.8727H23.1095C28.8394 22.8727 30.7921 20.1344 30.8881 16.1095H28.6474V12.4144ZM24.3259 15.1857V16.1095H22.0852V12.4144H24.2619V13.3382H22.9815V13.7671H24.1979V14.6908H23.0135V15.1857H24.3259ZM24.71 16.1095L25.7983 12.4144H27.0788L28.1671 16.1095H27.2388L27.0467 15.4166H25.8623L25.6703 16.1095H24.71ZM26.1285 14.4599H26.1185V14.4929L26.1285 14.4599ZM26.1285 14.4599H26.7587L26.4706 13.4371H26.4386L26.1285 14.4599ZM19.3643 15.1857V13.3382H19.9084C20.2606 13.3382 20.6447 13.4372 20.6447 14.2619C20.6447 15.0867 20.2926 15.1857 19.9084 15.1857H19.3643Z"
      fill="#D50072"
    />
    <path
      d="M6 1H40V-1H6V1ZM45 6V26H47V6H45ZM40 31H6V33H40V31ZM1 26V6H-1V26H1ZM6 31C3.23858 31 1 28.7614 1 26H-1C-1 29.866 2.13401 33 6 33V31ZM45 26C45 28.7614 42.7614 31 40 31V33C43.866 33 47 29.866 47 26H45ZM40 1C42.7614 1 45 3.23858 45 6H47C47 2.13401 43.866 -1 40 -1V1ZM6 -1C2.13401 -1 -1 2.13401 -1 6H1C1 3.23858 3.23858 1 6 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default Ideal;
