import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Container = styled.div`
  display: flex;
  margin-top: 1.25rem;
`;

export const TextContainer = styled.div`
  max-width: 320px;
  padding-right: 32px;
`;

export const FullWidthContainer = styled.div`
  width: 100%;

  & > * {
    margin-top: 1rem;
  }
  & :first-child {
    margin-top: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & :first-child {
    padding-left: 0;
  }
`;
