import styled from 'styled-components';
import Colors from '../Colors';

export const Root = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 992px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const InputHolder = styled.div`
  position: relative;
  width: 100%;
  height: max-content;
  margin-bottom: 12px;

  @media screen and (min-width: 992px) {
    width: max-content;
    margin-right: 12px;
    margin-bottom: 0;
  }
`;

export const Select = styled.select`
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  min-width: 200px;
  max-width: 100%;
  padding: 10px 48px 10px 14px;
  color: ${Colors.Gray[900]};
  font-size: 16px;
  background-color: transparent;
  border: 1px solid ${Colors.Gray[300]};
  border-radius: 8px;
  outline: none;
  appearance: none;
`;

export const Icon = styled.span`
  position: absolute;
  top: 0;
  right: 18px;
  bottom: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;

  button {
    margin: 0;
    margin: 0 10px;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`;
