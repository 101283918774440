import { CountryIconProps } from './types';

const KH = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3587)">
      <path
        d="M0 12.0009C0 14.0798 0.528891 16.0351 1.45908 17.74L12 18.7835L22.5409 17.74C23.4711 16.0351 24 14.0798 24 12.0009C24 9.92198 23.4711 7.96664 22.5409 6.26175L12 5.21826L1.45908 6.26175C0.528891 7.96664 0 9.92198 0 12.0009Z"
        fill="#D80027"
      />
      <path
        d="M1.45898 6.26123H22.5408C20.5056 2.5311 16.5484 0.000366211 11.9999 0.000366211C7.45139 0.000366211 3.49425 2.5311 1.45898 6.26123Z"
        fill="#0052B4"
      />
      <path
        d="M11.9999 24.0008C16.5484 24.0008 20.5056 21.4701 22.5408 17.74H1.45898C3.49425 21.4701 7.45139 24.0008 11.9999 24.0008Z"
        fill="#0052B4"
      />
      <path
        d="M16.1745 14.3473V12.7821H15.131V10.6951L14.0876 9.65167L13.0441 10.6951V8.60818L12.0006 7.5647L10.9571 8.60818V10.6951L9.91361 9.65167L8.87013 10.6951V12.7821H7.82669V14.3473H6.7832V15.9125H17.218V14.3473H16.1745Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3587">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default KH;
