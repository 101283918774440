/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { AuthActions } from '../../store/auth';
import { api } from '../../api';
import { ProjectActions } from '../../store/project';
import { DEMO_PROJECT_ID } from '../../api/api/constants';

async function updateCurrentUser({ dispatch }) {
  const user = await api.auth.getUserData();
  dispatch(AuthActions.updateUser(user));
}

async function updateCurrentProject({ dispatch }) {
  const projects = await api.projects.getProjects();

  if (!projects || projects.length === 0) {
    dispatch(ProjectActions.changeProjectData({ id: '' }));
    return;
  }

  // For Replai users we want to default to the demo project.
  // [2024-04] Per special requests, users with the Huuuge Casino project should see it by default.
  const project =
    projects.find((p) => p.id === DEMO_PROJECT_ID) ??
    projects.find((p) => p.id === '83cef8a6-99ba-43e0-8a99-6c0f8885ca09') ??
    projects[0];

  // Project Configuration redux update
  dispatch(ProjectActions.changeProjectData({ ...project, userProject: { uiPreferences: project.uiPreferences } }));
}

export async function finalizeLogin({ dispatch }) {
  await updateCurrentUser({
    dispatch,
  });
  await updateCurrentProject({
    dispatch,
  });
}

export default finalizeLogin;
