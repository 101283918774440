import { Typography } from '@material-ui/core';
import * as SDK from '@replai-platform/sdk';
import styled from 'styled-components';
import type { Row } from 'react-table';
import { Skeleton } from '@replai-platform/ui-components';
import { logEvent } from '../../../analytics';
import TagAvatar from '../../TagAvatar';
import VideoAvatar from '../../VideoAvatar';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';

const CreativeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const CreativeInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export type NameAccessorType = {
  title: string;
  originalName?: string;
  subTitle: string;
  tagType?: string | null;
  tagValue: string | null;
  objectId?: string;
  objectLevel?: SDK.ObjectLevel;
  conceptId?: string;
  videoUrl?: string;
  isActive?: boolean;
  projectId?: string;
  tagUrl?: string;
  isClickable?: boolean;
  onTagClick?: (tag: SDK.Tag) => void;
  tagId?: string | null;
  tagThumbnailImgUrl?: string;
  loading?: boolean;
  rowHref?: string;
  allowEdit?: boolean;
};

const NameCellDecorator: React.FC<{
  cellData: NameAccessorType;
  row: Row<any>;
}> = ({ cellData, row }) => {
  if (!cellData) {
    return null;
  }

  if (cellData.loading) {
    return <Skeleton height={20} width={100} />;
  }

  return !cellData.tagId ? (
    <VideoAvatar
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objectId={cellData.objectId!}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objectLevel={cellData.objectLevel!}
      conceptId={cellData.conceptId}
      isActive={cellData.isActive}
      name={cellData.title || SDK.messages.NOT_AVAILABLE}
      originalName={cellData.originalName}
      additionalInfo={cellData.subTitle || SDK.messages.NOT_AVAILABLE}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      projectId={cellData.projectId!}
      videoHref={cellData.rowHref}
      eventOrigin="Videos"
      allowEdit={cellData.allowEdit}
      linkOnClick={() => {
        logEvent({
          component: 'Videos',
          action: row.depth === 0 ? 'Click on Row' : 'Click on Subrow',
          category: 'user_actions',
          parameters: {
            assetId: row.depth === 0 ? cellData.conceptId : cellData.objectId,
          },
        });
      }}
    />
  ) : (
    <CreativeContainer>
      <TagAvatar
        tagId={cellData.tagId}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        tagType={cellData.tagType!}
        tagValue={cellData.tagValue}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        projectId={cellData.projectId!}
        onClick={cellData?.onTagClick}
        thumbnailImgUrl={cellData.tagThumbnailImgUrl}
      />
      <CreativeInfo>
        <Typography variant="body2">{cellData.title}</Typography>
        <Typography variant="body1">{cellData.subTitle}</Typography>
      </CreativeInfo>
    </CreativeContainer>
  );
};

function getClusterNameAndThumbnailColumn<T extends object = NameAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T, NameAccessorType>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<T, NameAccessorType>) => (
      <NameCellDecorator cellData={data?.cell?.value} row={data?.cell?.row} />
    ),
  };
}

export default getClusterNameAndThumbnailColumn;
