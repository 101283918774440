/* eslint-disable @typescript-eslint/no-floating-promises */

import { usePrevious } from 'react-use';
import { useQueryClient } from 'react-query';
import {
  Carousel,
  Colors,
  DropDownChip,
  Icons,
  TextArea,
  VideoPlayer,
  calculateClusterViewPreviewVideoStartTime,
  Typography,
  Tabs,
  Button,
  Skeleton,
  DropDownMenuOption,
  Card,
  Tab,
  MultiSelectDropDownOption,
  Tooltip,
  Notification,
} from '@replai-platform/ui-components';
import { useState, useRef, useEffect, createRef, RefObject, useMemo } from 'react';
import { message } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import ReactPlayer from 'react-player/lazy';
import { messages, type Timeframe } from '@replai-platform/sdk';
import { CarouselRef } from 'antd/lib/carousel';
import * as Styled from './styles';
import { logEvent } from '../../analytics';
import RelatedCreativesTab from './RelatedCreativesTab';
import {
  SUBMIT_TEXT_IMAGE_TO_VIDEO_PROMPT_QUERY_KEY,
  useSubmitTextImageToVideoPrompt,
} from '../../api/hooks/creativeProduction/useSubmitTextImageToVideoPrompt';
import { GET_ALL_PRODUCTION_CONCEPTS_KEY } from '../../api/hooks/creativeProduction/useGetAllProductionConcepts';
import { NOTIFICATION_MESSAGE } from '../../routes/CreativeProduction/components/TextImageToVideoModal';
import { CommonSnackbar } from '../../utils/styles';

enum ActionTab {
  EditVideo = 'Edit Video',
  GenerateVariation = 'Generate Variation',
}

enum ContentTab {
  Overview = 'Overview',
  RelatedCreatives = 'Related Creatives',
}

const MAX_MESSAGE_SIZE = 160;

const UploadIcon = Icons.getBaseIcon('Image');

type SubmitRequestProps = {
  baseProductionConceptId?: string;
  requestPrompt: string;
  parentProductionConceptId?: string;
};

export type ConceptPreviewVideo = {
  url: URL;
  label: string;
  thumbnails?: {
    seconds: number;
    url: string;
  }[];
  timeframes: Timeframe[];
};

export type ConceptPreviewProps = {
  projectId: string;
  productionConceptId: string;
  isOpen: boolean;
  title: string;
  totalVersions: number;
  onClose: () => void;
  onPlayClick: () => void;
  onPauseClick: () => void;
  onSlideChange?: (s: number) => void;
  onVersionChange: (v: number) => void;
  videos: ConceptPreviewVideo[];
  versionPrompt?: string | null;
  prompt: string;
};

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

const SUBMIT_ACTIONS = {
  resolution: {
    placeholder: 'Select Resolution',
    options: ['9:16 (1080x1920)', '16:9 (1920x1080)', '4:5 (1080x1350)', '1:1 (1080x1080)'],
  },
  localizations: {
    placeholder: 'Select Localization',
    options: ['localize texts', 'localize audio', 'localize visual elements'],
  },
};

const AVAILABLE_TABS: Tab[] = (Object.keys(ContentTab) as (keyof typeof ContentTab)[]).map((key) => ({
  id: key,
  label: ContentTab[key],
}));

const AVAILABLE_ACTIONS: Tab[] = (Object.keys(ActionTab) as (keyof typeof ActionTab)[]).map((key) => ({
  id: key,
  label: ActionTab[key],
}));

const generateVariationPrompt = (parentConceptName: string, promp: string) =>
  `Based on concept: "${parentConceptName}" | ${promp}`;

const ConceptPreview = ({
  projectId,
  productionConceptId,
  title = '',
  isOpen,
  videos = [],
  versionPrompt,
  prompt = '',
  totalVersions,
  onClose,
  onPlayClick,
  onPauseClick,
  onSlideChange,
  onVersionChange,
}: ConceptPreviewProps) => {
  const [promptImageBase64, setPromptImageBase64] = useState<string | null>(null);
  const [promptImageFile, setPromptImageFile] = useState<UploadFile | null>(null);
  const [notificationStatus, setNotificationStatus] = useState<{
    open: boolean;
    successful?: boolean;
    info: { message: string; details: string };
  }>({
    open: false,
    info: { message: '', details: '' },
  });

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [requestInfo, setRequestInfo] = useState<string>();
  const [selectedAction, setSelectedAction] = useState<keyof typeof ActionTab>('EditVideo');
  const [selectedTab, setSelectedTab] = useState<keyof typeof ContentTab>('Overview');

  const [currentSlide, setCurrentSlide] = useState(0);
  const [playerRefs, setPlayerRefs] = useState<{
    [k: number]: RefObject<ReactPlayer>;
  }>({});
  const carouselRef = useRef<CarouselRef | null>(null);
  const [durations, setDurations] = useState<{ [k: number]: number | null }>({});
  const previousVideos = usePrevious(videos);

  const clearRequestInfo = () => {
    setRequestInfo('');
  };

  const queryClient = useQueryClient();
  const [isSubmittingCreativeRequest, setIsSubmittingCreativeRequest] = useState<boolean>(false);
  const submitProductionCreativeRequest = useSubmitTextImageToVideoPrompt(projectId);
  const submitRequest = ({ baseProductionConceptId, requestPrompt, parentProductionConceptId }: SubmitRequestProps) => {
    setIsSubmittingCreativeRequest(true);
    logEvent({
      component: 'Creative Production Requests table',
      action: 'Submit Request',
      category: 'user_actions',
      parameters: { prompt },
    });
    submitProductionCreativeRequest.mutate(
      {
        baseProductionConceptId,
        prompt: requestPrompt,
        productionConceptId: parentProductionConceptId,
        imagePrompt:
          promptImageBase64 && promptImageFile?.name
            ? { base64: promptImageBase64, fileName: promptImageFile.name }
            : undefined,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: GET_ALL_PRODUCTION_CONCEPTS_KEY });
          await queryClient.invalidateQueries(SUBMIT_TEXT_IMAGE_TO_VIDEO_PROMPT_QUERY_KEY, { refetchInactive: true });
          setNotificationStatus({
            open: true,
            successful: true,
            info: {
              message: NOTIFICATION_MESSAGE.SUBMITTED_CREATIVE_REQUEST_MESSAGE,
              details: NOTIFICATION_MESSAGE.SUBMITTED_CREATIVE_REQUEST_DETAILS,
            },
          });
        },
        onError: async () => {
          setNotificationStatus({
            open: true,
            successful: false,
            info: {
              message: NOTIFICATION_MESSAGE.SUBMITTED_CREATIVE_REQUEST_ERROR_MESSAGE,
              details: NOTIFICATION_MESSAGE.SUBMITTED_CREATIVE_REQUEST_ERROR_DETAILS,
            },
          });
        },
        onSettled: async () => {
          if (promptImageFile) {
            promptImageFile.status = 'removed';
          }
          setIsSubmittingCreativeRequest(false);
          clearRequestInfo();
          setPromptImageFile(null);
          setPromptImageBase64(null);
        },
      }
    );
  };

  const isValidInput = useMemo(
    () =>
      ((requestInfo || '').length > 0 || !!promptImageBase64) &&
      requestInfo !== prompt &&
      requestInfo !== versionPrompt,
    [requestInfo, prompt, promptImageBase64, versionPrompt]
  );

  const onChangeSlide = (newIndex: number) => {
    setCurrentSlide(newIndex);
    onSlideChange?.(newIndex);
  };

  useEffect(() => {
    (playerRefs[currentSlide]?.current?.getInternalPlayer() as HTMLVideoElement)?.[isOpen ? 'play' : 'pause']();
  }, [isOpen, currentSlide, playerRefs]);

  useEffect(() => {
    const newPlayerRefs = Object.fromEntries(videos?.map((_video, index) => [index, createRef<ReactPlayer>()]) ?? []);
    // Re-use durations of video URLs that didn't change, reset the others.
    setDurations(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.fromEntries(
        (videos ?? [])
          .map((video, index) => {
            const previousIndex = previousVideos?.findIndex((previousVideo) => previousVideo?.url === video.url);
            if (previousIndex === undefined || previousIndex < 0) {
              return [index, null];
            }
            return [index, playerRefs[previousIndex]?.current?.getDuration?.() ?? null];
          })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_, duration]) => duration !== null)
      )
    );
    setPlayerRefs(newPlayerRefs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(videos?.map((video) => video.url))]);

  useEffect(() => {
    if (videos?.some((_video, index) => !playerRefs[index])) {
      setPlayerRefs({
        ...playerRefs,
        ...Object.fromEntries(
          videos.filter((_video, index) => !playerRefs[index]).map((_video, index) => [index, createRef()])
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videos]);

  const labelDropdownData = useMemo(() => {
    const options: DropDownMenuOption[] = [];

    const toLabel = (l: string) => `${l.slice(0, 9)}`;

    // eslint-disable-next-line no-plusplus
    for (let labelIndex = 0; labelIndex < videos.length; labelIndex++) {
      options.push({
        label: toLabel(videos[labelIndex].label),
        onClick: () => {
          carouselRef.current?.goTo(labelIndex);
          onChangeSlide(labelIndex);
        },
        selected: labelIndex === currentSlide,
      });
    }

    return {
      options,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videos, currentSlide]);

  const renderVideo = (video: ConceptPreviewVideo, index: number) => {
    const onLoadedMetadata = (data: React.SyntheticEvent<HTMLVideoElement>) => {
      const targetElm = data?.target as HTMLVideoElement;

      if (targetElm?.duration) {
        setDurations({ ...durations, [index]: targetElm?.duration });
      }
    };
    return (
      <Styled.VideoContainer>
        <VideoPlayer
          height="480px"
          onLoadedMetadata={onLoadedMetadata}
          onPlay={onPlayClick}
          onPause={onPauseClick}
          playerRef={playerRefs[index]}
          url={video.url.toString()}
          startTime={calculateClusterViewPreviewVideoStartTime(video.timeframes)}
          preload="metadata"
          allowDownload
          playing={index === currentSlide}
          title="TITLE"
        />
      </Styled.VideoContainer>
    );
  };

  const versionsDropdownData = useMemo(() => {
    const options: DropDownMenuOption[] = [];

    const versionName = (version: number) => `Version ${version}`;

    // eslint-disable-next-line no-plusplus
    for (let version = totalVersions; version > 0; version--) {
      options.push({
        label: versionName(version),
        onClick: () => {
          onChangeSlide(0); // Reset slide
          onVersionChange(version);
        },
      });
    }

    return {
      options,
      defaultOption: versionName(totalVersions),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalVersions]);

  const actionDropdownData = useMemo(() => {
    const element = selectedAction === 'EditVideo' ? SUBMIT_ACTIONS.resolution : SUBMIT_ACTIONS.localizations;
    const options: MultiSelectDropDownOption[] = [{ label: 'All options', selected: false, isAllOption: true }];

    // eslint-disable-next-line no-plusplus
    for (let idx = 0; idx < element.options.length; idx++) {
      const label = element.options[idx];
      options.push({
        label,
        selected: selectedOptions.includes(label),
      });
    }

    return { placeholder: element.placeholder, options };
  }, [selectedAction, selectedOptions]);

  const resetSelectedOptions = () => {
    setRequestInfo('');
    setSelectedOptions([]);
  };

  const displayMessage = (m: string) => {
    if (m.length < MAX_MESSAGE_SIZE) return <Typography style={{ overflowWrap: 'anywhere' }}>{m}</Typography>;
    return (
      <Tooltip placement="bottom" content={<Typography>{m}</Typography>}>
        <Typography style={{ overflowWrap: 'anywhere' }}>{m.slice(0, MAX_MESSAGE_SIZE / 2)}...</Typography>
      </Tooltip>
    );
  };

  const renderOverview = () => (
    <Styled.GridDisplay>
      <Styled.GridEntry>
        {videos?.length !== 0 ? (
          <Styled.VideosContainer>
            {videos ? (
              <Carousel
                slider={carouselRef}
                slidesData={videos ?? []}
                accessor={renderVideo}
                afterChange={onChangeSlide}
                arrows
                dots={false}
                disableShadow
              />
            ) : (
              <Skeleton height="100%" />
            )}
            <Styled.VideosContainerHeader>
              <Button
                size="md"
                leadingIcon={{ name: 'Download' }}
                color="BlueLight"
                variant="outlined-light"
                onClick={() => {
                  window.open(videos[currentSlide].url);
                }}
              >
                Download
              </Button>
              <DropDownChip
                disableCrossButton
                dropDownType="singleselect"
                placeHolder="Select label"
                dropDownOptions={labelDropdownData.options}
              />
            </Styled.VideosContainerHeader>
            {videos[currentSlide].thumbnails ? (
              <Styled.VideoThumbnailsContainer>
                {videos[currentSlide]?.thumbnails?.map(({ seconds, url }) => (
                  <Styled.VideoThumbnail
                    alt="Video Thumbnail"
                    onClick={() => {
                      playerRefs[currentSlide].current?.seekTo(seconds);
                      logEvent({ component: 'Concept Preview', action: 'Seek from thumbnail' });
                    }}
                    src={url}
                  />
                ))}
              </Styled.VideoThumbnailsContainer>
            ) : (
              <Skeleton height="5rem" width="100%" borderRadius="0.5rem" />
            )}
          </Styled.VideosContainer>
        ) : (
          <Styled.UnavailableVideo icon="Slash" text="Video/s currently not available" />
        )}
      </Styled.GridEntry>
      <Styled.GridEntry>
        <Typography type="display-xs" style={{ marginBottom: 0 }}>
          Concept Prompt
        </Typography>
        <Styled.ConceptPrompt>
          <Card fullWidth>{displayMessage(prompt)}</Card>
        </Styled.ConceptPrompt>
        <div style={{ height: '16rem', paddingTop: '3rem' }}>
          {versionPrompt && prompt !== versionPrompt ? (
            <>
              <Typography type="display-xs" style={{ marginBottom: 0 }}>
                Version Prompt
              </Typography>
              <Styled.Comment>
                <Card fullWidth overflowWrapAnywhere>
                  <Typography>{displayMessage(versionPrompt)}</Typography>
                </Card>
              </Styled.Comment>
            </>
          ) : undefined}
        </div>
        <>
          <Tabs
            widthVariant="long"
            tabLabels={AVAILABLE_ACTIONS}
            onTabChange={(tab) => {
              resetSelectedOptions();
              setSelectedAction(tab.id as keyof typeof ActionTab);
            }}
          />
          <div
            style={{
              display: 'flex',
              paddingTop: '1rem',
              alignItems: 'center',
            }}
          >
            <Styled.UploadDragger>
              <Styled.UploadContainer
                disabled={isSubmittingCreativeRequest}
                hideUpload={!!promptImageFile}
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                beforeUpload={beforeUpload}
                defaultFileList={[]}
                multiple={false}
                customRequest={(params) => {
                  setPromptImageFile(params.file as RcFile);
                  const reader = new FileReader();
                  reader.readAsDataURL(params.file as Blob);
                  reader.onloadend = () => {
                    const base64data = reader.result;
                    setPromptImageBase64(base64data?.toString() ?? '');
                    params?.onSuccess?.('File successfully uploaded');
                  };
                }}
                onChange={(info) => {
                  const { status } = info.file;
                  if (status === 'done') {
                    // eslint-disable-next-line no-void
                    void message.success(`${info.file.name} file uploaded successfully.`);
                    setPromptImageFile(info.file);
                  } else if (status === 'error') {
                    // eslint-disable-next-line no-void
                    void message.error(`${info.file.name} file upload failed.`);
                  }
                }}
                showUploadList={{ showRemoveIcon: true, showPreviewIcon: false }}
                fileList={promptImageFile ? [promptImageFile] : []}
                onRemove={() => {
                  setPromptImageFile(null);
                  setPromptImageBase64(null);
                }}
              >
                <div>
                  <UploadIcon color={Colors.Blue[600]} dimension={30} />
                  <Typography type="text-xs">Upload image</Typography>
                </div>
              </Styled.UploadContainer>
            </Styled.UploadDragger>
            <Tooltip
              content={
                selectedAction === 'EditVideo'
                  ? 'E.g.: "Make the intro longer"'
                  : 'E.g.: "Replace this character by another one"'
              }
            >
              <TextArea
                style={{ height: '80px', fontSize: '0.8rem' }}
                fullWidth
                placeholder="Write a comment"
                value={requestInfo}
                onChange={(e) => {
                  setSelectedOptions([]);
                  setRequestInfo(e.target.value);
                }}
                disabled={isSubmittingCreativeRequest}
              />
            </Tooltip>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              justifyContent: 'flex-end',
            }}
          >
            <DropDownChip
              placement="top"
              dropDownType="multiselect"
              placeHolder={actionDropdownData.placeholder}
              dropDownOptions={actionDropdownData.options}
              onChange={(options) => {
                const values = options.filter((o) => !o.isAllOption && o.label && o.selected).map((o) => o.label ?? '');
                setSelectedOptions(values);
                if (values.length > 0)
                  setRequestInfo(
                    `Create a new video version with the following options: [${values.map((s) => `"${s}"`).join(',')}].`
                  );
              }}
              disabled={isSubmittingCreativeRequest}
            />
            <Button
              leadingIcon={{ name: isSubmittingCreativeRequest ? 'LoadingCircle' : 'Sparkles' }}
              disabled={isSubmittingCreativeRequest || !isValidInput}
              onClick={() => {
                const requestPrompt = requestInfo ?? prompt;
                const req: SubmitRequestProps =
                  selectedAction === 'EditVideo'
                    ? { requestPrompt, parentProductionConceptId: productionConceptId }
                    : {
                        baseProductionConceptId: productionConceptId,
                        requestPrompt: generateVariationPrompt(title, requestPrompt),
                      };
                submitRequest(req);
              }}
            >
              Submit
            </Button>
          </div>
          <CommonSnackbar
            open={notificationStatus.open}
            onClose={() => setNotificationStatus({ ...notificationStatus, open: false })}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={3000}
          >
            <Notification
              badgeTitle={notificationStatus.successful ? 'Success' : 'Failure'}
              badgeLabel={notificationStatus.info?.message}
              message={notificationStatus.info?.details}
              color={notificationStatus.successful ? 'Success' : 'Error'}
            />
          </CommonSnackbar>
        </>
      </Styled.GridEntry>
    </Styled.GridDisplay>
  );

  const renderRelatedCreatives = () => <RelatedCreativesTab creativeId={productionConceptId} projectId={projectId} />;

  const render = () => {
    switch (selectedTab) {
      case 'RelatedCreatives':
        return renderRelatedCreatives();
      default:
        return renderOverview();
    }
  };

  return (
    <Styled.StyledModal
      isOpen={isOpen}
      onClose={onClose}
      className="tag-preview-modal"
      modalHeader={
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'space-between',
              paddingBottom: '1rem',
              paddingRight: '2rem',
            }}
          >
            <div>
              <p>{title}</p>
            </div>
            {selectedTab === 'Overview' && (
              <DropDownChip
                disableCrossButton
                dropDownType="singleselect"
                placeHolder="Select Version"
                defaultOption={versionsDropdownData.defaultOption}
                dropDownOptions={versionsDropdownData.options}
              />
            )}
          </div>
          <Styled.Divider />
          <Tabs
            widthVariant="long"
            tabLabels={AVAILABLE_TABS}
            onTabChange={(newTab) => {
              logEvent({
                action: `Go to tab ${newTab.id ?? messages.NOT_AVAILABLE}`,
                component: 'Concept Preview',
              });
              setSelectedTab(newTab.id as keyof typeof ContentTab);
            }}
          />
        </>
      }
      width="1050px"
    >
      {render()}
    </Styled.StyledModal>
  );
};

export default ConceptPreview;
