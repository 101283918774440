import { CountryIconProps } from './types';

const HK = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#D80027"
    />
    <path
      d="M13.2387 9.07752C12.966 10.2118 12.4815 9.99552 12.2437 10.9848C10.8429 10.648 9.98021 9.23938 10.317 7.83852C10.6537 6.43775 12.0624 5.57506 13.4632 5.91186C12.9876 7.89031 13.4827 8.063 13.2387 9.07752Z"
      fill="#F0F0F0"
    />
    <path
      d="M9.60395 9.91749C10.5985 10.5273 10.243 10.9214 11.1103 11.4533C10.3571 12.6815 8.75092 13.0665 7.5227 12.3134C6.29453 11.5603 5.90936 9.954 6.66255 8.72583C8.3972 9.78952 8.71431 9.372 9.60395 9.91749Z"
      fill="#F0F0F0"
    />
    <path
      d="M9.27946 13.6349C10.1668 12.8775 10.4316 13.3373 11.2055 12.6769C12.1409 13.7727 12.0108 15.4193 10.915 16.3547C9.81918 17.2901 8.17255 17.1598 7.2373 16.0641C8.78483 14.743 8.48573 14.3123 9.27946 13.6349Z"
      fill="#F0F0F0"
    />
    <path
      d="M12.7145 15.0914C12.2683 14.0135 12.7875 13.9036 12.3984 12.9636C13.7296 12.4127 15.2555 13.0452 15.8065 14.3764C16.3574 15.7076 15.7248 17.2334 14.3936 17.7844C13.6156 15.9043 13.1136 16.0557 12.7145 15.0914Z"
      fill="#F0F0F0"
    />
    <path
      d="M15.1618 12.2739C13.9987 12.3652 14.0545 11.8374 13.0403 11.917C12.9277 10.4808 14.0009 9.22495 15.4372 9.11222C16.8734 8.99976 18.1292 10.0728 18.2418 11.5092C16.2132 11.6682 16.202 12.1924 15.1618 12.2739Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default HK;
