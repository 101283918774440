import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase } from '@replai-platform/ui-components';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirst';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getNetworksString = (networks: SDK.Network[], projectNetworks: SDK.Network[]): string => {
  if (networks?.length === projectNetworks?.length || !networks?.length) {
    return 'All Networks';
  }

  return networks.reduce(
    (acc, network, index) =>
      acc
        ? `${acc}${
            index === networks.length - 1
              ? ` and ${camelCaseToCapitalCase(network)}`
              : `, ${camelCaseToCapitalCase(network)}`
          }`
        : camelCaseToCapitalCase(network),
    ''
  );
};

export const getPreviousMonthText = (month: string): string => {
  const monthNumber = monthNames.findIndex((monthName) => month === monthName);
  const date = new Date();
  date.setDate(1);
  date.setMonth(monthNumber - 1);
  return capitalizeFirstLetter(
    date.toLocaleDateString('en-US', {
      month: 'long',
    })
  );
};

export const subtractMonths = (numOfMonths, date = new Date()): Date => {
  const month = date.getMonth();
  date.setMonth(date.getMonth() - numOfMonths);
  while (date.getMonth() === month) {
    date.setDate(date.getDate() - 1);
  }
  return date;
};

export const getDateWithOffset = (date: Date): Date => new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

// Total days between two days (2023-05-05 - 2023-05-05 = 1 day)
export function daysBetween(date1: Date, date2: Date) {
  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(date1.getTime() - date2.getTime());
  // Convert back to days and return
  return Math.round(differenceMs / SDK.DAY_IN_MILLISECONDS) + 1;
}

export const getDailyDateArray = (fromDate: Date, days: number): Date[] => {
  let iterationsLeft: number = days;
  const dailyDateArray: Date[] = [];
  while (iterationsLeft > 0) {
    const currentDate = new Date(fromDate);
    currentDate.setDate(fromDate.getDate() + days - iterationsLeft);
    dailyDateArray.push(currentDate);
    iterationsLeft -= 1;
  }
  return dailyDateArray;
};
