/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as SDK from '@replai-platform/sdk';
import { Card, KeyValues, Typography, type KeyValue } from '@replai-platform/ui-components';
import { useCallback, useRef, useState, type MutableRefObject } from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useFeature } from '@optimizely/react-sdk';
import { logEvent } from '../../../../../analytics';
import useConceptTags from '../../../../../api/hooks/concepts/useConceptTags';
import useConceptRecommendations from '../../../../../api/hooks/recommendations/useConceptRecommendations';
import Timeline from '../../../../../components/Timeline';
import VideoSidebar from '../../../../../components/VideoSidebar';
import AssetVariationButton from '../../../../../components/AssetVariationButton';
import type { RootState } from '../../../../../store/rootReducer';
import { TIMELINE_MAX_DURATION_S_AVAILABILITY } from '../../../../../utils/constants';
import { getRecommendationRequestParams } from '../../../../../utils';
import type { ConceptData } from '../../../../../utils/types';
import { RouteAnimator } from '../../../../RouteAnimator';
import { Container, Properties, StickyLeftSide, TimelineRightSide, TimelineWrapper } from '../shared/styles';

interface TimelineProps {
  conceptData: ConceptData;
  propertyData: KeyValue[];
  url: string;
  isLoading: boolean;
}

const TimelineTab: React.FC<TimelineProps> = ({ conceptData, propertyData, url, isLoading }) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const conceptId = conceptData?.id;
  const assetId = conceptData?.assetId;
  const tagsTypes = useSelector((state: RootState) => state.project.config.tagsTypes) ?? {};
  const minRecommendationScore = useSelector((state: RootState) => state.project.config.minRecommendationScore) ?? 0.15;
  const networks = useSelector((state: RootState) => state.filters.networks);
  const projectNetworks = useSelector((state: RootState) => state.project.networks);
  const kpis = useSelector((state: RootState) => state.filters.kpis);
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const withBreakdowns = networks?.length !== 0 && networks?.length !== projectNetworks?.length;
  const location = useLocation();
  const timelineRecommendationsOnly =
    (location.state as { recommendationsOnly: boolean })?.recommendationsOnly ?? false;
  const [playedFraction, setPlayedFraction] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [onLoop, setOnLoop] = useState(false);
  const player = useRef<ReactPlayer>(null);
  const [isTechMerc1388Enabled] = useFeature('techmerc-1388'); // Creative Production

  const { data: tagData, isLoading: tagDataIsLoading } = useConceptTags({ projectId }, conceptId);

  // Get concept recommendations
  const {
    data: recommendations,
    isLoading: loadingRecommendations,
    error,
  } = useConceptRecommendations(
    getRecommendationRequestParams({
      projectId,
      minRecommendationScore,
      tagTypesToExclude: (tagsTypes.excluded || []).map((type) => ({ type })),
      networks,
      promotedObjectTypes: [],
      targets: kpis?.length ? kpis : projectKpis,
      withBreakdowns,
      objectIds: conceptId ? [conceptId] : [],
    }),
    {
      select: useCallback(
        (res) => (Array.isArray(res?.recommendations) ? res?.recommendations?.filter((r) => r.id === conceptId) : {}),
        [conceptId]
      ),
    }
  );

  const onPlay = () => {
    setIsPlaying(true);
    logEvent({
      component: 'Concept Details',
      action: 'Play Video',
      category: 'user_actions',
    });
  };

  const onPause = () => {
    setIsPlaying(false);
    logEvent({
      component: 'Concept Details',
      action: 'Pause Video',
      category: 'user_actions',
    });
  };

  const onProgress = ({
    playedSeconds: updatedPlayedSeconds,
    played: updatedPlayed,
  }: {
    playedSeconds: number;
    played: number;
  }) => {
    setPlayedSeconds(updatedPlayedSeconds);
    setPlayedFraction(updatedPlayed);
  };

  const onDuration = (updatedDuration: number) => setDuration(updatedDuration);

  if ((tagData?.duration ?? 0) > TIMELINE_MAX_DURATION_S_AVAILABILITY) {
    return (
      <RouteAnimator>
        <Typography style={{ margin: '2rem 0' }}>
          Timeline not available for creatives longer than 100 seconds. Try a different one.
        </Typography>
      </RouteAnimator>
    );
  }

  if ((!recommendations && !loadingRecommendations) || error) {
    return (
      <RouteAnimator>
        <Typography style={{ margin: '2rem 0' }}>
          Timeline not available for this creative. Try a different one.
        </Typography>
      </RouteAnimator>
    );
  }

  return (
    <RouteAnimator>
      <Container>
        <StickyLeftSide>
          <VideoSidebar
            data-test="detailed-view-video"
            url={url}
            coreVideoTags={[]}
            coreMiscTags={[]}
            showDescription={false}
            onPlay={onPlay}
            onPause={onPause}
            onProgress={onProgress}
            onDuration={onDuration}
            isPlaying={isPlaying}
            onLoop={onLoop}
            player={player}
          />
          {isTechMerc1388Enabled ? <AssetVariationButton assetId={assetId ?? ''} /> : null}
          <Card fullWidth disableContentPadding>
            <Properties>
              <KeyValues
                header="Properties"
                values={propertyData}
                isLoading={isLoading}
                loadingPlaceholderNumber={11}
              />
            </Properties>
          </Card>
        </StickyLeftSide>
        <TimelineRightSide>
          <TimelineWrapper>
            <Timeline
              assetId={assetId ?? ''}
              recommendations={(recommendations?.[0]?.subRows as SDK.CreativeRecommendation[]) ?? []}
              loadingRecommendations={loadingRecommendations || !assetId}
              tagData={tagData}
              tagDataIsLoading={tagDataIsLoading}
              playedFraction={playedFraction}
              playedSeconds={playedSeconds}
              duration={duration}
              player={player as MutableRefObject<ReactPlayer>}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              v2
              videoOnLoop={onLoop}
              setLoopVideo={setOnLoop}
              recommendationsOnly={timelineRecommendationsOnly}
            />
          </TimelineWrapper>
        </TimelineRightSide>
      </Container>
    </RouteAnimator>
  );
};

export default TimelineTab;
