/**
 * @summary Return the distance between the given dates in months and days (assumes 1M = 30d)
 * @param {Date} startDate - the start date
 * @param {Date} [endDate=new Date()]  - the end date
 * @returns {String} distance
 * @throws {Error} `startDate` must not be Invalid Date
 * @throws {Error} `endDate` must not be Invalid Date
 * @example
 * formatDateDistance(new Date(2022, 3, 1), new Date(2022, 3, 2))
 * // => "1d"
 * @example
 * formatDateDistance(new Date(2022, 3, 1), new Date(2022, 4, 1))
 * // => "1m"
 * @example
 * formatDateDistance(new Date(2022, 3, 1), new Date(2022, 4, 2))
 * // => "1m 1d"
 * */
export const formatDateDistance = (startDate: Date, endDate: Date = new Date()): string => {
  if (!(startDate instanceof Date) || Number.isNaN(startDate)) {
    throw new Error('`startDate` must be a valid date');
  }
  if (!(endDate instanceof Date) || Number.isNaN(endDate)) {
    throw new Error('`endDate` must be a valid date');
  }
  let formattedValue = '';
  const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
  const diffMonths = diffTime / (1000 * 60 * 60 * 24 * 30);
  const diffMonthsRounded = Math.floor(diffMonths);
  if (diffMonthsRounded > 0) {
    const diffDaysRemaining = Math.floor((diffMonths - diffMonthsRounded) * 30);
    if (diffDaysRemaining > 0) {
      formattedValue = `${diffMonthsRounded}m ${diffDaysRemaining}d`;
    } else {
      formattedValue = `${diffMonthsRounded}m`;
    }
  } else {
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    formattedValue = `${diffDays}d`;
  }
  return formattedValue;
};
