/* eslint-disable no-lonely-if */
/* eslint-disable default-case */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-array-index-key */

import { useEffect, useState } from 'react';
import * as Icons from '../Icons';
import * as Styled from './styles';
import { Button, Colors } from '..';
import {
  WEEK_ACRONYMS,
  generateMonthCalendar,
  MONTHS,
  isDateInRange,
  inputRangeValidaton,
  DateType,
} from './utils';

import '../common.module.css';

type DatePickerLocation = 'left' | 'right';

export type DatePickerButton = {
  label: string;
  lastDays: number;
};

const DEFAULT_BUTTONS = [
  { label: 'Yesterday', lastDays: 1 },
  { label: 'Last 7d', lastDays: 7 },
  { label: 'Last 30d', lastDays: 30 },
  { label: 'Last 60d', lastDays: 60 },
];

interface DatePickerProps {
  selectedDate?: number;
  selectedMonth?: number;
  selectedYear?: number;
  selectedDateEnd?: number;
  selectedMonthEnd?: number;
  selectedYearEnd?: number;
  selectedRange?: number | string;
  onSelectionChanged?: (
    date: number | undefined,
    month: number,
    year: number,
    dateEnd?: number,
    monthEnd?: number,
    yearEnd?: number,
    range?: number | string
  ) => void;
  customButtons?: DatePickerButton[];
}

// TODO [Daniel Reis] Improve internal logic.
const DatePicker = ({
  selectedDate,
  selectedMonth,
  selectedYear,
  selectedDateEnd,
  selectedMonthEnd,
  selectedYearEnd,
  selectedRange,
  onSelectionChanged,
  customButtons,
}: DatePickerProps) => {
  const buttons = customButtons ?? DEFAULT_BUTTONS;

  const today = new Date();

  const [selectedStartDate, setSelectedStartDate] = useState(selectedDate);
  const [selectedStartDateMonth, setSelectedStartDateMonth] = useState(
    selectedMonth ?? today.getMonth()
  );
  const [selectedStartDateYear, setSelectedStartDateYear] = useState(
    selectedYear ?? today.getFullYear()
  );
  const [selectedEndDate, setSelectedEndDate] = useState(selectedDateEnd);
  const [selectedEndDateMonth, setSelectedEndDateMonth] =
    useState(selectedMonthEnd);
  const [selectedEndDateYear, setSelectedEndDateYear] =
    useState(selectedYearEnd);
  const [selectedDaysRange, setSelectedDaysRange] = useState(selectedRange);

  const [currentMonthL, setCurrentMonthL] = useState(
    selectedMonth !== undefined
      ? inputRangeValidaton(0, 11, selectedMonth)
      : today.getMonth()
  );

  const [currentMonthR, setCurrentMonthR] = useState(
    (selectedMonthEnd !== undefined
      ? inputRangeValidaton(0, 11, selectedMonthEnd)
      : today.getMonth()) % 12
  );

  const [currentYearL, setCurrentYearL] = useState(
    selectedYear !== undefined ? selectedYear : today.getFullYear()
  );

  const [currentYearR, setCurrentYearR] = useState(
    selectedYearEnd !== undefined ? selectedYearEnd : today.getFullYear()
  );

  if (currentMonthL === currentMonthR && currentYearL === currentYearR) {
    if (currentMonthL - 1 < 0) {
      setCurrentMonthL(11);
      setCurrentYearL(currentYearL - 1);
    } else {
      setCurrentMonthL(currentMonthL - 1);
    }
  }

  useEffect(() => {
    if (onSelectionChanged) {
      onSelectionChanged(
        selectedStartDate,
        selectedStartDateMonth,
        selectedStartDateYear,
        selectedEndDate,
        selectedEndDateMonth,
        selectedEndDateYear,
        selectedDaysRange
      );
    }
  }, [
    onSelectionChanged,
    selectedStartDate,
    selectedStartDateMonth,
    selectedStartDateYear,
    selectedEndDate,
    selectedEndDateMonth,
    selectedEndDateYear,
    selectedDaysRange,
  ]);

  const setCurrentMonthAndYear = (
    month: number,
    year: number,
    datePickerLocation: DatePickerLocation,
    actionType: 'next' | 'previous'
  ) => {
    // Left date picker date should always be less than the one on the right
    if (datePickerLocation === 'left' && actionType === 'next') {
      if (
        year > currentYearR ||
        (year === currentYearR && month >= currentMonthR)
      )
        return;
    }

    // Right date picker date should always be greater than the one on the left
    if (datePickerLocation === 'right' && actionType === 'previous') {
      if (
        year < currentYearL ||
        (year === currentYearL && month <= currentMonthL)
      )
        return;
    }

    // Update both current month and year
    if (datePickerLocation === 'left') {
      setCurrentYearL(year);
      setCurrentMonthL(month);
    } else {
      setCurrentYearR(year);
      setCurrentMonthR(month);
    }
  };

  const handleMonthChange = (
    datePickerLocation: DatePickerLocation,
    actionType: 'next' | 'previous'
  ) => {
    const currentMonth =
      datePickerLocation === 'left' ? currentMonthL : currentMonthR;
    const currentYear =
      datePickerLocation === 'left' ? currentYearL : currentYearR;

    let month = currentMonth;
    let year = currentYear;

    switch (actionType) {
      case 'next':
        month = (currentMonth + 1) % 12;
        year = currentYear + Math.floor(currentMonth / 11);
        break;
      case 'previous':
        month = (currentMonth + 11) % 12;
        year = currentMonth === 0 ? currentYear - 1 : currentYear;
        break;
      default:
        throw new Error('Invalid actionType');
    }

    setCurrentMonthAndYear(month, year, datePickerLocation, actionType);
  };

  const handleDateButtonClick = (
    date: number,
    month: number,
    year: number,
    datePickerLocation: DatePickerLocation,
    dateType?: DateType
  ) => {
    setSelectedDaysRange('custom');

    if (
      (selectedStartDate && selectedEndDate) ||
      (!selectedStartDate && !selectedEndDate)
    ) {
      setSelectedStartDate(date);
      setSelectedStartDateMonth(month);
      setSelectedStartDateYear(year);
      setSelectedEndDate(undefined);
      setSelectedEndDateMonth(undefined);
      setSelectedEndDateYear(undefined);

      if (dateType && dateType !== 'current') {
        setCurrentMonthAndYear(month, year, datePickerLocation, dateType);
      }
    } else if (selectedStartDate && !selectedEndDate) {
      const d1 = new Date(
        selectedStartDateYear,
        selectedStartDateMonth,
        selectedStartDate
      );
      const d2 = new Date(year, month, date);

      const dMin = d1.getTime() < d2.getTime() ? d1 : d2;
      const dMax = d1.getTime() < d2.getTime() ? d2 : d1;

      setSelectedStartDate(dMin.getDate());
      setSelectedStartDateMonth(dMin.getMonth());
      setSelectedStartDateYear(dMin.getFullYear());
      setSelectedEndDate(dMax.getDate());
      setSelectedEndDateMonth(dMax.getMonth());
      setSelectedEndDateYear(dMax.getFullYear());

      if (datePickerLocation === 'right') {
        if (currentYearL === dMin.getFullYear()) {
          if (dMin.getMonth() - 1 > currentMonthL) {
            setCurrentMonthAndYear(
              dMin.getMonth() - 1,
              currentYearL,
              'left',
              'previous'
            );
          }
        } else {
          setCurrentMonthAndYear(
            dMin.getMonth() - 1 < 0 ? 11 : dMin.getMonth() - 1,
            dMin.getMonth() - 1 < 0
              ? dMin.getFullYear() - 1
              : dMin.getFullYear(),
            'left',
            'previous'
          );
        }
      } else {
        if (currentYearR === dMax.getFullYear()) {
          if (dMax.getMonth() + 1 < currentMonthR) {
            setCurrentMonthAndYear(
              dMax.getMonth() + 1,
              currentYearR,
              'right',
              'next'
            );
          }
        } else {
          setCurrentMonthAndYear(
            dMax.getMonth() + 1 > 11 ? 0 : dMax.getMonth() + 1,
            dMax.getMonth() + 1 > 11
              ? dMax.getFullYear() + 1
              : dMax.getFullYear(),
            'right',
            'next'
          );
        }
      }

      if (dateType && dateType !== 'current') {
        setCurrentMonthAndYear(month, year, datePickerLocation, dateType);
      }
    }
  };

  const renderDatePicker = (
    datePickerLocation: DatePickerLocation,
    currentMonth: number,
    currentYear: number
  ) => (
    <Styled.DatePicker
      className={`${datePickerLocation}Picker`}
      data-test={`${datePickerLocation}-date-picker-container`}
    >
      <Styled.Container>
        <Styled.TopBar>
          <Styled.CalendarButton
            type="button"
            onClick={() => handleMonthChange(datePickerLocation, 'previous')}
          >
            <Icons.BaseIcons.ChevronLeft
              color={Colors.Gray[500]}
              dimension={20}
            />
          </Styled.CalendarButton>
          <Styled.CalendarInfo>
            {MONTHS[currentMonth]} {currentYear}
          </Styled.CalendarInfo>
          <Styled.CalendarButton
            type="button"
            onClick={() => handleMonthChange(datePickerLocation, 'next')}
          >
            <Icons.BaseIcons.ChevronRight
              color={Colors.Gray[500]}
              dimension={20}
            />
          </Styled.CalendarButton>
        </Styled.TopBar>

        <Styled.Week>
          {WEEK_ACRONYMS.map((week, index) => (
            <Styled.Heading key={`heading-${index}`}>{week}</Styled.Heading>
          ))}
        </Styled.Week>
        {generateMonthCalendar(currentMonth, currentYear).map((week, index) => (
          <Styled.Week key={`week-${index}`}>
            {week.map((day, dayIndex) => {
              const month = currentMonth;
              const year = currentYear;

              const isStartSelected =
                day &&
                selectedStartDate === day.date &&
                (day.type === 'current'
                  ? selectedStartDateMonth === currentMonth
                  : selectedStartDateMonth === month) &&
                (day.type === 'current'
                  ? selectedStartDateYear === currentYear
                  : selectedStartDateYear === year);
              const isEndSelected =
                day &&
                selectedEndDate === day.date &&
                (day.type === 'current'
                  ? selectedEndDateMonth === currentMonth
                  : selectedEndDateMonth === month) &&
                (day.type === 'current'
                  ? selectedEndDateYear === currentYear
                  : selectedEndDateYear === year);
              const isSameMonth = day && day.type === 'current';
              const [isInRange, isFirst, isLast] =
                day && selectedStartDate && selectedEndDate
                  ? isDateInRange(
                      new Date(
                        selectedStartDateYear,
                        selectedStartDateMonth,
                        selectedStartDate
                      ),
                      new Date(
                        day.type === 'current' ? currentYear : year,
                        day.type === 'current' ? currentMonth : month,
                        day.date
                      ),
                      new Date(
                        selectedEndDateYear!,
                        selectedEndDateMonth!,
                        selectedEndDate
                      )
                    )
                  : [false, false, false];
              const isToday =
                day &&
                currentYear === today.getFullYear() &&
                currentMonth === today.getMonth() &&
                day.date === today.getDate() &&
                day.type === 'current';

              return (
                <Styled.DayContainer
                  key={`week-${index}-day-${dayIndex}`}
                  $isInRange={isInRange}
                  $isToday={Boolean(isToday && !isInRange)}
                  $isLast={isLast}
                  $isFirst={isFirst}
                >
                  <Styled.Day
                    type="button"
                    disabled={!day}
                    key={`day-${index}`}
                    className={`${day ? day.type : ''} ${
                      isEndSelected || isStartSelected
                        ? isSameMonth
                          ? 'selected'
                          : 'selectedOther'
                        : ''
                    } ${isInRange ? 'dayInRange' : ''}`}
                    onClick={() =>
                      day
                        ? handleDateButtonClick(
                            day.date,
                            month,
                            year,
                            datePickerLocation,
                            day.type
                          )
                        : null
                    }
                  >
                    {day && day.date}
                  </Styled.Day>
                </Styled.DayContainer>
              );
            })}
          </Styled.Week>
        ))}
      </Styled.Container>
    </Styled.DatePicker>
  );

  const renderDatePickerButton = (label: string, lastDays: number) => (
    <Button
      key={label}
      size="md"
      variant="outlined"
      color={lastDays === selectedDaysRange ? 'Primary' : 'Gray'}
      onClick={() => {
        if (lastDays === 0) {
          setSelectedStartDate(undefined);
          setSelectedStartDateMonth(0);
          setSelectedStartDateYear(0);
          setSelectedEndDate(undefined);
          setSelectedEndDateMonth(0);
          setSelectedEndDateYear(0);
          setSelectedDaysRange(lastDays);
          return;
        }
        // Compute start and end dates
        const endDate = new Date(today);
        endDate.setDate(endDate.getDate() - 1);
        const startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() + 1 - lastDays);

        // Update selected start and end dates
        setSelectedStartDate(startDate.getDate());
        setSelectedStartDateMonth(startDate.getMonth());
        setSelectedStartDateYear(startDate.getFullYear());
        setSelectedEndDate(endDate.getDate());
        setSelectedEndDateMonth(endDate.getMonth());
        setSelectedEndDateYear(endDate.getFullYear());
        setSelectedDaysRange(lastDays);

        // Update calendar
        setCurrentMonthAndYear(
          startDate.getMonth(),
          startDate.getFullYear(),
          'left',
          'previous'
        );
        if (startDate.getMonth() !== endDate.getMonth())
          setCurrentMonthAndYear(
            endDate.getMonth(),
            endDate.getFullYear(),
            'right',
            'next'
          );
        else
          setCurrentMonthAndYear(
            startDate.getMonth() + 1,
            startDate.getFullYear(),
            'left',
            'next'
          );
      }}
    >
      {label}
    </Button>
  );

  return (
    <Styled.Root data-test="date-pickers-container">
      <Styled.DatePickers>
        {renderDatePicker('left', currentMonthL, currentYearL)}
        {renderDatePicker('right', currentMonthR, currentYearR)}
      </Styled.DatePickers>
      <Styled.BottomPanel data-test="date-picker-bottom-panel">
        {buttons?.map((button) =>
          renderDatePickerButton(button.label, button.lastDays)
        )}
      </Styled.BottomPanel>
    </Styled.Root>
  );
};

export default DatePicker;
export type { DatePickerProps };
