import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';

export const LiftoffConnectionGuide: NetworkConnectionGuideContent = {
  title: 'Liftoff Connection Guide',
  icon: 'Liftoff',
  guide: {
    label: 'Liftoff Connection Guide',
    steps: [
      {
        title: 'Request API credentials',
        description: '',
        content: (
          <Shared.UList>
            <Shared.UListItem>
              As described in the{' '}
              {Shared.link({
                label: 'Liftoff documentation',
                url: 'https://docs.liftoff.io/advertiser/reporting_api#getting-started',
              })}
              , you should request your Liftoff CSM to get your API key and API secret.
            </Shared.UListItem>
            <Shared.UListItem>Visit the next step once you&apos;ve received those credentials.</Shared.UListItem>
          </Shared.UList>
        ),
      },
      {
        title: 'Share API credentials',
        description: '',
        content: (
          <Shared.UList>
            <Shared.UListItem>
              Share the API credentials you received from Liftoff (API key and API secret) with Replai.
            </Shared.UListItem>
          </Shared.UList>
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: [
      ...Shared.DEFAULT_NETWORK_FAQS,
      {
        title: 'Which tracking settings should I use on MMPs with Liftoff?',
        description: (
          <Shared.UList>
            <Shared.UListItem>
              You should ensure Campaign ID and Creative ID are both correctly configured in the tracking URL, otherwise
              MMP metrics will be ignored.
            </Shared.UListItem>
          </Shared.UList>
        ),
      },
    ],
  },
};
