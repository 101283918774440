import { SocialIconProps } from './types';

const Discord = ({ dimension }: SocialIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.88806 10.068C9.20406 10.068 8.66406 10.668 8.66406 11.4C8.66406 12.132 9.21606 12.732 9.88806 12.732C10.5721 12.732 11.1121 12.132 11.1121 11.4C11.1241 10.668 10.5721 10.068 9.88806 10.068ZM14.2681 10.068C13.5841 10.068 13.0441 10.668 13.0441 11.4C13.0441 12.132 13.5961 12.732 14.2681 12.732C14.9521 12.732 15.4921 12.132 15.4921 11.4C15.4921 10.668 14.9521 10.068 14.2681 10.068Z"
      fill="#7289DA"
    />
    <path
      d="M20.1005 0H4.02055C2.66455 0 1.56055 1.104 1.56055 2.472V18.696C1.56055 20.064 2.66455 21.168 4.02055 21.168H17.6285L16.9925 18.948L18.5285 20.376L19.9805 21.72L22.5605 24V2.472C22.5605 1.104 21.4565 0 20.1005 0ZM15.4685 15.672C15.4685 15.672 15.0365 15.156 14.6765 14.7C16.2485 14.256 16.8485 13.272 16.8485 13.272C16.3565 13.596 15.8885 13.824 15.4685 13.98C14.8685 14.232 14.2925 14.4 13.7285 14.496C12.5765 14.712 11.5205 14.652 10.6205 14.484C9.93655 14.352 9.34855 14.16 8.85655 13.968C8.58055 13.86 8.28055 13.728 7.98055 13.56C7.94455 13.536 7.90855 13.524 7.87255 13.5C7.84855 13.488 7.83655 13.476 7.82455 13.464C7.60855 13.344 7.48855 13.26 7.48855 13.26C7.48855 13.26 8.06455 14.22 9.58855 14.676C9.22855 15.132 8.78455 15.672 8.78455 15.672C6.13255 15.588 5.12455 13.848 5.12455 13.848C5.12455 9.984 6.85255 6.852 6.85255 6.852C8.58055 5.556 10.2245 5.592 10.2245 5.592L10.3445 5.736C8.18455 6.36 7.18855 7.308 7.18855 7.308C7.18855 7.308 7.45255 7.164 7.89655 6.96C9.18055 6.396 10.2005 6.24 10.6205 6.204C10.6925 6.192 10.7525 6.18 10.8245 6.18C11.5565 6.084 12.3845 6.06 13.2485 6.156C14.3885 6.288 15.6125 6.624 16.8605 7.308C16.8605 7.308 15.9125 6.408 13.8725 5.784L14.0405 5.592C14.0405 5.592 15.6845 5.556 17.4125 6.852C17.4125 6.852 19.1405 9.984 19.1405 13.848C19.1405 13.848 18.1205 15.588 15.4685 15.672Z"
      fill="#7289DA"
    />
  </svg>
);
export default Discord;
