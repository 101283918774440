const blueGray = {
  25: '#FCFCFD',
  50: '#F8F9FC',
  100: '#EAECF5',
  200: '#C8CCE5',
  300: '#9EA5D1',
  400: '#717BBC',
  500: '#4E5BA6',
  600: '#3E4784',
  700: '#363F72',
  800: '#293056',
  900: '#101323',
} as const;

type Keys = keyof typeof blueGray;
type BlueGray = typeof blueGray[Keys];

export default blueGray;
export type { BlueGray };
