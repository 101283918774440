import { IntegrationIconProps } from './types';

const Zeplin = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.31491 20.1683L4.85013 20.6943L4.77148 24.9776L9.84205 23.1569C8.10291 22.5826 6.83288 21.5723 6.31491 20.1683Z"
      fill="#FDBD39"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.0823 15.6379L2 17.4629L4.85096 20.6942L6.31574 20.1683C5.79929 18.7684 6.11778 17.186 7.0823 15.6379Z"
      fill="#F69833"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6349 9.45743C9.16309 11.7813 4.99074 16.5768 6.31575 20.1683L29.7523 11.7526C28.4273 8.16108 22.1067 7.1335 15.6349 9.45743Z"
      fill="#FECF33"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4338 22.4634C26.9056 20.1395 31.0858 15.3654 29.753 11.7526L6.31641 20.1682C7.64924 23.7811 13.962 24.7874 20.4338 22.4634Z"
      fill="#EE6723"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.753 11.7526L6.31641 20.1682C6.77533 21.4122 8.52538 22.025 10.9809 22.025C13.3748 22.025 16.4393 21.443 19.6341 20.2958C26.1059 17.9718 30.6593 14.2093 29.753 11.7526Z"
      fill="#F69833"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0882 9.89575C22.6943 9.89575 19.6298 10.4778 16.435 11.625C9.96316 13.949 5.40978 17.7115 6.31613 20.1682L29.7527 11.7526C29.2938 10.5086 27.5437 9.89575 25.0882 9.89575Z"
      fill="#FDBD39"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7943 25.2044H23.7941C23.1046 25.2044 22.0873 24.5922 20.7705 23.3848C19.2126 21.9562 17.4281 19.8639 15.7456 17.4933C14.0631 15.1228 12.6806 12.7528 11.8527 10.8199C10.9507 8.71353 10.8221 7.4184 11.4705 6.97045C11.6126 6.87234 11.784 6.82263 11.9798 6.82263C12.5071 6.82263 13.5347 7.20857 15.4348 9.0473L15.4764 9.08748L15.4708 9.14463C15.4578 9.27672 15.4458 9.41 15.4351 9.54081L15.414 9.80056L15.2275 9.61595C13.1802 7.58852 12.279 7.36964 11.9865 7.36964C11.9031 7.36964 11.8366 7.38631 11.789 7.41921C11.5078 7.61344 11.4897 8.56475 12.3642 10.6066C13.1773 12.5051 14.5397 14.8392 16.2004 17.1791C17.8612 19.5191 19.6184 21.5805 21.1484 22.9835C22.7983 24.4963 23.5418 24.6597 23.7849 24.6597C23.8688 24.6597 23.937 24.6419 23.9876 24.607C24.2843 24.4021 24.2846 23.383 23.3094 21.1852L23.2647 21.0843L23.3627 21.0316C23.4607 20.9788 23.5555 20.9269 23.6444 20.8772L23.7618 20.8116L23.8159 20.9335C24.5074 22.4908 25.1537 24.4701 24.3062 25.0557C24.1633 25.1544 23.9911 25.2044 23.7943 25.2044ZM23.7941 24.9642H23.7943L23.7942 25.0843L23.7941 24.9642Z"
      fill="#EE6723"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5894 22.398L20.5795 22.5177C20.4159 24.5077 19.9851 26.9002 18.8618 26.997C18.8392 26.999 18.8158 27 18.7924 27C18.0293 27 17.3074 25.9253 16.6467 23.8058C16.0216 21.8006 15.5365 19.1084 15.2808 16.225C15.0251 13.3422 15.029 10.6077 15.2916 8.52535C15.5779 6.25623 16.123 5.07122 16.9115 5.0032C16.9348 5.00109 16.959 5 16.9828 5C17.5364 5 18.3707 5.61611 19.2247 8.48587L18.6671 8.59167C18.3819 7.63267 18.0712 6.8527 17.7626 6.31674C17.4773 5.821 17.1998 5.548 16.9813 5.548C16.9741 5.548 16.9669 5.54829 16.9597 5.54889C16.6179 5.5784 16.1202 6.39336 15.8426 8.59302C15.5847 10.638 15.5815 13.3315 15.8339 16.1773C16.0864 19.0235 16.5635 21.6756 17.1774 23.6448C17.4523 24.5268 17.7539 25.2537 18.0495 25.7469C18.322 26.2017 18.5858 26.4522 18.7922 26.4522C18.7993 26.4522 18.8064 26.4519 18.8134 26.4513C19.1405 26.4231 19.7568 25.5751 20.0162 22.5913L20.5894 22.398Z"
      fill="#EE6723"
    />
  </svg>
);
export default Zeplin;
