import styled from 'styled-components';
import Trend from './Trend';
import Metrics from './Metrics';

const Container = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
`;

const Summary = ({ filtersLoading }: { filtersLoading?: boolean }) => (
  <Container>
    <Trend filtersLoading={filtersLoading} />
    <Metrics filtersLoading={filtersLoading} />
  </Container>
);

export default Summary;
