import { IntegrationIconProps } from './types';

const Weebly = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.2214 5C23.037 5 21.2164 6.48806 20.6581 8.7151C19.6515 5.36871 17.0752 5.00258 16.0004 5.00258C14.9259 5.00258 12.3506 5.36871 11.3422 8.71431C10.7836 6.4894 8.96281 5.00258 6.77892 5.00258C4.09913 5.00258 2 6.96547 2 9.47123C2 10.5794 2.2672 11.464 2.59008 12.4236L6.22866 22.9666C7.44698 26.4632 9.92903 26.9853 11.2999 26.9853C13.4505 26.9853 15.1262 25.8568 16.0001 23.8567C16.8748 25.8657 18.5507 27 20.7002 27C22.0694 27 24.549 26.477 25.7735 22.9675C27.0467 19.2861 28.3653 15.6173 29.5845 11.9177C29.7793 11.3271 30 10.6577 30 9.83736C30 7.03451 27.9903 5 25.2214 5ZM26.9461 11.486L23.2875 22.0649C22.8409 23.3447 22.098 24.2976 20.7002 24.2976C19.3906 24.2976 18.6175 23.5498 18.2311 22.2687L16.0295 15.2279H15.9697L13.7695 22.2687C13.3824 23.5501 12.609 24.2828 11.2998 24.2828C9.9018 24.2828 9.15827 23.3379 8.71234 22.058L5.08361 11.5432C4.78611 10.6589 4.63747 10.1118 4.63747 9.47101C4.63747 8.49556 5.5303 7.70502 6.77892 7.70502C7.82017 7.70502 8.5637 8.40837 8.8017 9.44456L11.2706 17.8315H11.3297L13.8284 9.62936C14.1562 8.44087 14.7507 7.70502 16.0004 7.70502C17.2495 7.70502 17.8442 8.43963 18.1713 9.62813L20.6701 17.8315H20.7292L23.1988 9.44456C23.4365 8.40837 24.1799 7.70244 25.2214 7.70244C26.47 7.70244 27.3625 8.49589 27.3625 9.83736C27.3625 10.3248 27.1536 10.8145 26.9461 11.486Z"
      fill="#3192E7"
    />
  </svg>
);
export default Weebly;
