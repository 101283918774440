import { IntegrationIconProps } from './types';

const JbRubymine = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1334_1518)">
      <path
        d="M26.6057 0L17.4629 3.29143L10.24 0L3.1543 17.7829L11.2914 14.5371L11.1543 23.5429L28.5714 23.8629L32 6.26286L26.6057 0Z"
        fill="url(#paint0_linear_1334_1518)"
      />
      <path
        d="M23.2238 10.3771L11.7038 2.83429L2.42383 8.54857L22.7667 16.4114L23.2238 10.3771Z"
        fill="url(#paint1_linear_1334_1518)"
      />
      <path
        d="M13.3029 31.1771L25.28 29.5771L21.4857 22.2629L24 19.2L24.2743 17.3257L11.7029 2.78857L0 5.66857V22.4457L6.76572 32L13.3029 31.1771Z"
        fill="url(#paint2_linear_1334_1518)"
      />
      <path d="M6.125 6.12567H25.8736V25.8742H6.125V6.12567Z" fill="black" />
      <path d="M8 22.1714H15.4057V23.4057H8V22.1714Z" fill="white" />
      <path
        d="M15.8164 8.68567H17.7821L19.9307 12.16L22.0793 8.68567H24.045V16.8685H22.2164V11.52L19.9307 15.04H19.885L17.5993 11.5657V16.8685H15.8164V8.68567Z"
        fill="white"
      />
      <path
        d="M7.95392 8.68567H11.7025C12.7539 8.68567 13.5311 8.95995 14.0796 9.50853C14.5368 9.96567 14.7653 10.6057 14.7653 11.3828V11.4285C14.7653 12.1142 14.5825 12.6171 14.2625 13.0285C13.9425 13.44 13.5311 13.76 12.9825 13.9428L14.9939 16.8685H12.8911L11.1996 14.3542H9.69106V16.8685H7.9082V8.68567H7.95392ZM11.6111 12.6628C12.0682 12.6628 12.3882 12.5714 12.6168 12.3428C12.8453 12.1142 12.9825 11.84 12.9825 11.52V11.4742C12.9825 11.0628 12.8453 10.7885 12.6168 10.6057C12.3882 10.4228 12.0225 10.3314 11.5653 10.3314H9.78249V12.6628H11.6111Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1334_1518"
        x1="22.6784"
        y1="24.0247"
        x2="13.8725"
        y2="3.27451"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.172" stopColor="#FF1F51" />
        <stop offset="0.28" stopColor="#FF3648" />
        <stop offset="0.468" stopColor="#FF593B" />
        <stop offset="0.646" stopColor="#FF7231" />
        <stop offset="0.809" stopColor="#FF812B" />
        <stop offset="0.941" stopColor="#FF8629" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1518"
        x1="15.2051"
        y1="5.8912"
        x2="11.4775"
        y2="15.285"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.022" stopColor="#9039D0" />
        <stop offset="0.629" stopColor="#FF1F51" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_1518"
        x1="0.132571"
        y1="5.13097"
        x2="20.7013"
        y2="31.2777"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.107" stopColor="#9039D0" />
        <stop offset="0.387" stopColor="#FF1F51" />
        <stop offset="0.629" stopColor="#FF1F51" />
        <stop offset="0.66" stopColor="#FF3648" />
        <stop offset="0.714" stopColor="#FF593B" />
        <stop offset="0.765" stopColor="#FF7231" />
        <stop offset="0.812" stopColor="#FF812B" />
        <stop offset="0.85" stopColor="#FF8629" />
      </linearGradient>
      <clipPath id="clip0_1334_1518">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default JbRubymine;
