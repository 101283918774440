import { camelCaseToCapitalCase, formatDuration } from '../../../utils';
import Badge from '../../Badge/Badge';
import Colors from '../../Colors';
import { Pause, Play, Repeat, Share2, SkipBack } from '../../Icons/Base';
import Tabs from '../../Tabs/Tabs';
import TimelineTooltip, {
  type TooltipVideo,
} from '../../TimelineTooltip/TimelineTooltip';
import { TagBreakdown } from '../timeline.types';
import { getTimelineTrackSegmentColor } from '../utils';
import * as Styled from './styles';

const RECOMMENDATION_TAB = 1;

interface PlayerTimestampProps {
  playedSeconds: number;
}

const PlayerTimestamp: React.FC<PlayerTimestampProps> = ({ playedSeconds }) => {
  const { minutes, seconds, milliseconds } = formatDuration(playedSeconds);
  return (
    <div>
      <Styled.DurationMain>{`${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`}</Styled.DurationMain>
      <Styled.DurationSub>{`:${milliseconds
        .toString()
        .padStart(2, '0')}`}</Styled.DurationSub>
    </div>
  );
};

interface PlayerControlsContainerProps {
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  onShareClick?: () => void;
  onClickSkipBack?: (point: number) => void;
  loopVideo?: boolean;
  setLoopVideo?: (loop: boolean) => void;
}

const PlayerControlsContainer: React.FC<PlayerControlsContainerProps> = ({
  isPlaying,
  setIsPlaying,
  onShareClick,
  onClickSkipBack,
  loopVideo,
  setLoopVideo,
}) => (
  <Styled.PlayerControls>
    <Styled.Control>
      <SkipBack
        dimension={16}
        onClick={() => onClickSkipBack?.(0)}
        color={Colors.Gray[500]}
        aria-label="Skip back"
      />
    </Styled.Control>
    <Styled.Control active={isPlaying}>
      {isPlaying ? (
        <Pause
          dimension={16}
          onClick={() => setIsPlaying(false)}
          color={Colors.Gray[600]}
          aria-label="Pause"
        />
      ) : (
        <Play
          dimension={16}
          onClick={() => {
            setIsPlaying(true);
          }}
          color={Colors.Primary[600]}
          aria-label="Play icon"
        />
      )}
    </Styled.Control>
    <Styled.Control>
      <Repeat
        dimension={16}
        onClick={() => setLoopVideo?.(!loopVideo)}
        color={loopVideo ? Colors.Primary[600] : Colors.Gray[500]}
        aria-label="Repeat"
      />
    </Styled.Control>
    <Styled.Control>
      <Share2
        dimension={16}
        onClick={onShareClick}
        color={Colors.Gray[500]}
        aria-label="Share"
      />
    </Styled.Control>
  </Styled.PlayerControls>
);

export interface TimelineHeaderProps {
  data: TagBreakdown[];
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  playedSeconds: number;
  showOnlyRecommendations: boolean;
  setShowOnlyRecommendations: (showOnlyRecommendations: boolean) => void;
  onClickShowOnlyRecommendations?: () => void;
  onShareClick?: () => void;
  onClickSkipBack?: (point: number) => void;
  loopVideo?: boolean;
  setLoopVideo?: (loop: boolean) => void;
  onRecommendationTooltipOpen: (
    isOpen: boolean,
    tags: { type: string; value: string | null }[]
  ) => void;
  recommendationTooltipVideos: TooltipVideo[];
  withoutTabs?: boolean;
  hoveredTag?: string | null;
  onMouseOver?: (id: string | null) => void;
}

const TimelineSidebar: React.FC<TimelineHeaderProps> = ({
  data,
  isPlaying,
  setIsPlaying,
  playedSeconds,
  showOnlyRecommendations,
  setShowOnlyRecommendations,
  onClickShowOnlyRecommendations,
  onShareClick,
  onClickSkipBack,
  loopVideo,
  setLoopVideo,
  onRecommendationTooltipOpen,
  recommendationTooltipVideos,
  withoutTabs,
  hoveredTag,
  onMouseOver,
}) => {
  const tagCategories = Array.from(new Set(data.map((entry) => entry.type)));

  const dataToRender = data.filter((d) =>
    showOnlyRecommendations
      ? d.recommendations && d.recommendations.length > 0
      : true
  );

  return (
    <Styled.Container>
      <Styled.TopBar>
        <PlayerTimestamp playedSeconds={playedSeconds} />
        <PlayerControlsContainer
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          onShareClick={onShareClick}
          onClickSkipBack={onClickSkipBack}
          loopVideo={loopVideo}
          setLoopVideo={setLoopVideo}
        />
      </Styled.TopBar>
      {!withoutTabs ? (
        <Styled.TabsContainer>
          <Tabs
            tabLabels={[
              { label: 'All', maxWidthInPercentage: 30 },
              { label: 'Recommendations', maxWidthInPercentage: 70 },
            ]}
            variant="gray-button"
            widthVariant="long"
            onTabChange={(tab, index) => {
              setShowOnlyRecommendations?.(index === RECOMMENDATION_TAB);
              if (index === RECOMMENDATION_TAB) {
                onClickShowOnlyRecommendations?.();
              }
            }}
            defaultSelectedTab={
              showOnlyRecommendations
                ? { label: 'Recommendations', maxWidthInPercentage: 60 }
                : { label: 'All', maxWidthInPercentage: 40 }
            }
          />
        </Styled.TabsContainer>
      ) : undefined}
      <Styled.ValuesContainer>
        {dataToRender.map((value, index) => (
          <Styled.Row
            key={`${value.id}-${index}`}
            onMouseOver={() => onMouseOver?.(value.id ?? '')}
            onMouseLeave={() => onMouseOver?.('')}
          >
            <Styled.KeyValue scale={hoveredTag === value.id ? 1.1 : 1}>
              <Styled.SegmentColor
                backgroundColor={
                  Colors[
                    getTimelineTrackSegmentColor(
                      tagCategories.indexOf(value.type)
                    )
                  ][500]
                }
                scale={hoveredTag === value.id ? 1.5 : 1}
              />
              <Styled.TypographyWithEllipsis
                type="text-sm"
                fontWeight="medium"
                color={Colors.Gray[500]}
                noMargin
              >
                {camelCaseToCapitalCase(value.type)}
              </Styled.TypographyWithEllipsis>
              <Styled.TypographyWithEllipsis
                type="text-sm"
                fontWeight="medium"
                color={Colors.Gray[700]}
                noMargin
              >
                {value.value}
              </Styled.TypographyWithEllipsis>
            </Styled.KeyValue>
            {value.recommendations && value.recommendations.length > 0 ? (
              <TimelineTooltip
                tag={{
                  type: value.type,
                  value: value.value,
                }}
                videos={recommendationTooltipVideos}
                recommendation={value.recommendations[0]}
                onOpen={onRecommendationTooltipOpen}
                tagRowColor={
                  Colors[
                    getTimelineTrackSegmentColor(
                      tagCategories.indexOf(value.type)
                    )
                  ][500]
                }
              >
                <Badge
                  leadingIcon="Bulb"
                  leadingIconColor={Colors.Warning[700]}
                  color="Warning"
                  noBorderRadius
                />
              </TimelineTooltip>
            ) : undefined}
          </Styled.Row>
        ))}
      </Styled.ValuesContainer>
    </Styled.Container>
  );
};

export default TimelineSidebar;
