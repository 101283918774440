import { CountryIconProps } from './types';

const BQ = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3577)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.51534 3.51432C0.943538 6.08612 -0.216478 9.5349 0.034444 12.898L12.899 0.0334789C9.53587 -0.21749 6.08713 0.942526 3.51534 3.51432Z"
        fill="#FFDA44"
      />
      <path
        d="M4.28516 21.1899C8.99989 25.1565 16.048 24.9219 20.4856 20.4843C24.9233 16.0467 25.1579 8.99856 21.1912 4.28387L4.28516 21.1899Z"
        fill="#0052B4"
      />
      <path
        d="M11.957 11.5167L12.9912 10.9566L11.957 10.3964C11.7156 8.82794 10.4763 7.58861 8.90783 7.34721L8.34763 6.31305L7.78747 7.34716C6.21904 7.58857 4.97976 8.82789 4.73826 10.3964L3.7041 10.9566L4.73821 11.5167C4.97966 13.0852 6.21894 14.3246 7.78743 14.566L8.34763 15.6001L8.90778 14.566C10.4763 14.3246 11.7156 13.0852 11.957 11.5167ZM8.34768 13.0436C7.19507 13.0436 6.26071 12.1092 6.26071 10.9566C6.26071 9.80393 7.19502 8.86961 8.34768 8.86961C9.50033 8.86961 10.4346 9.80393 10.4346 10.9566C10.4346 12.1092 9.50029 13.0436 8.34768 13.0436Z"
        fill="black"
      />
      <path
        d="M8.34767 9.39093L8.7995 10.1735H9.7032L9.25133 10.9561L9.7032 11.7388H8.7995L8.34767 12.5213L7.89584 11.7388H6.99219L7.44402 10.9561L6.99219 10.1735H7.89584L8.34767 9.39093Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3577">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default BQ;
