import styled from 'styled-components';
import Badge from '../Badge/Badge';
import Button from '../Button/Button';
import Colors from '../Colors';
import LinearProgress from '../LinearProgress/LinearProgress';
import SkeletonLoading from '../Skeleton/Skeleton';
import Typography from '../Typography/Typography';

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  justify-content: flex-end;
`;

export const CardOutterContainer = styled.div<{ fixedWidth: boolean }>`
  width: 100%;
  ${({ fixedWidth }) =>
    fixedWidth
      ? 'width: 17.125rem;'
      : `min-width: 15.125rem;
  max-width: 21.125rem;`}
`;

export const CardInnerContainer = styled.div<{ disableClick: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  border-radius: 0.4375rem;
  gap: 1rem;
  cursor: ${({ disableClick }) => (disableClick ? 'default' : 'pointer')};
  &:hover {
    background-color: ${Colors.Gray[50]};
  }
`;

export const TopBadge = styled(Badge)`
  position: absolute;
  top: -2px;
  left: -2px;
  z-index: 2;
  height: 1.6rem;
  color: ${Colors.Warning[25]} !important;
  background-color: ${Colors.Warning[400]} !important;
  border-radius: 0.4375rem 0 0.5rem 0 !important;
`;

export const ThumbnailContainer = styled.div<{ thumbnailHeight: number }>`
  height: ${({ thumbnailHeight }) => thumbnailHeight}rem;

  .react-loading-skeleton {
    line-height: unset;
  }
`;

export const CustomSkeletonLoading = styled(SkeletonLoading)`
  border-radius: 0.5rem;
`;

export const NameContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  overflow: hidden;
`;

export const NetworkContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 0 0.2rem;
  span {
    width: 100%;
  }
`;

export const NameLabelContainer = styled.span<{ height: string }>`
  width: 100%;
  height: ${({ height }) => height};
  overflow: hidden;
`;

export const Name = styled(Typography)<{ nameVisibleLines?: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ nameVisibleLines }) => nameVisibleLines ?? 1};
  -webkit-box-orient: vertical;
`;

export const MetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  > * {
    flex: 1;
  }
`;

export const AgeContainer = styled.div`
  .react-loading-skeleton {
    line-height: unset;
  }
`;

export const RelevanceScoreContainer = styled.div`
  .react-loading-skeleton {
    line-height: unset;
  }
`;

export const SpendContainer = styled.div`
  .react-loading-skeleton {
    line-height: unset;
  }
`;

export const SpendBarContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  min-width: 7rem;
`;

export const CleanTypography = styled(Typography)`
  margin: 0;
`;

export const SpendLinearProgress = styled(LinearProgress)`
  flex: 0.7;
`;

export const SpendTypography = styled(Typography)`
  flex: 0.3;
  margin: 0;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  flex-grow: 1;
`;

export const CompareButton = styled(Button)`
  width: 100%;
  > * {
    pointer-events: none;
  }
`;

export const ImageGridContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5%;
  column-gap: 5%;
`;

export const HiddenThumbnailsContainer = styled.div`
  position: absolute;
  margin-top: 9.5rem;
  margin-left: 58%;
  text-align: center;
`;

export const GridLoadingContainer = styled.div`
  height: 47.5%;
  width: 47.5%;
`;
