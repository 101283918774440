import styled from 'styled-components';
import Modal from '../Modal/Modal';
import Badge from '../Badge/Badge';

export const StyledModal = styled(Modal)<{ $removePadding: boolean }>`
  &.tag-preview-modal {
    .ant-modal-header {
      border: 0;
    }

    .ant-modal-body {
      padding-top: ${({ $removePadding }) => ($removePadding ? 0 : '3.5rem')};
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 29.3125rem;
`;

export const RequestedContainer = styled(Container)`
  gap: 2rem;
`;

export const TagPreviewContainer = styled(Container)`
  gap: 1.25rem;

  .react-loading-skeleton {
    line-height: unset;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SpendAndFrequencyContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 0;

  .react-loading-skeleton {
    line-height: unset;
  }
`;

export const SpendAndFrequencyValueContainer = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const Percentage = styled.span`
  font-size: 9px;
`;

export const NoWrapBadge = styled(Badge)`
  white-space: nowrap;
`;

export const VideosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .slick-slide > div > div > div > div {
    border-radius: 0;
  }
`;

export const CarouselContainer = styled.div`
  height: 14.9375rem;
`;

export const VideoContainer = styled(CarouselContainer)``;

export const TimestampsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  height: 1.125rem;
  overflow: hidden;

  > span {
    width: 100%;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TagTypeValue = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const TimelineContainer = styled.div`
  height: 1.25rem;
`;

export const DescriptionContainer = styled.div`
  min-height: 1.25rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;
