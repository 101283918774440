/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Colors, { Color } from '../Colors';

import '../common.module.css';
import * as Styled from './styles';

type TypographyTypes =
  | 'display-2xl'
  | 'display-xl'
  | 'display-lg'
  | 'display-md'
  | 'display-sm'
  | 'display-xs'
  | 'text-xl'
  | 'text-lg'
  | 'text-md'
  | 'text-sm'
  | 'text-xs'
  | 'text-xxs';

type FontWeights = 'regular' | 'medium' | 'semi-bold' | 'bold';

interface TypographyProps extends React.HTMLAttributes<HTMLParagraphElement> {
  color?: Color;
  fontWeight?: FontWeights;
  type?: TypographyTypes;
  noMargin?: boolean;
}

const Typography = React.forwardRef(
  (
    {
      children,
      className,
      color,
      fontWeight = 'regular',
      style,
      type = 'text-md',
      noMargin = false,
      ...props
    }: TypographyProps,
    ref: React.ForwardedRef<HTMLHeadingElement | HTMLParagraphElement | null>
  ) => {
    switch (type) {
      case 'display-2xl':
        return (
          <Styled.H1
            ref={ref}
            className={`${type} ${fontWeight} ${className || ''}`}
            $style={{ ...style } as string}
            $color={color || Colors.Gray[900]}
            $noMargin={noMargin}
            {...props}
          >
            {children}
          </Styled.H1>
        );

      case 'display-xl':
        return (
          <Styled.H2
            ref={ref}
            className={`${type} ${fontWeight} ${className || ''}`}
            $style={{ ...style } as string}
            $color={color || Colors.Gray[900]}
            $noMargin={noMargin}
            {...props}
          >
            {children}
          </Styled.H2>
        );

      case 'display-lg':
        return (
          <Styled.H3
            ref={ref}
            className={`${type} ${fontWeight} ${className || ''}`}
            $style={{ ...style } as string}
            $color={color || Colors.Gray[900]}
            $noMargin={noMargin}
            {...props}
          >
            {children}
          </Styled.H3>
        );

      case 'display-md':
        return (
          <Styled.H4
            ref={ref}
            className={`${type} ${fontWeight} ${className || ''}`}
            $style={{ ...style } as string}
            $color={color || Colors.Gray[900]}
            $noMargin={noMargin}
            {...props}
          >
            {children}
          </Styled.H4>
        );

      case 'display-sm':
        return (
          <Styled.H5
            ref={ref}
            className={`${type} ${fontWeight} ${className || ''}`}
            $style={{ ...style } as string}
            $color={color || Colors.Gray[900]}
            $noMargin={noMargin}
            {...props}
          >
            {children}
          </Styled.H5>
        );

      default:
        return (
          <Styled.Paragraph
            ref={ref}
            className={`${type} ${fontWeight} ${className || ''}`}
            $style={{ ...style } as string}
            $color={color || Colors.Gray[900]}
            $noMargin={noMargin}
            {...props}
          >
            {children}
          </Styled.Paragraph>
        );
    }
  }
);

export default Typography;
export type { FontWeights, TypographyProps, TypographyTypes };
