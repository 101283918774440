import { CountryIconProps } from './types';

const KW = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3651)">
      <path
        d="M23.2536 16.173C23.736 14.873 24 13.467 24 11.9991C24 10.5313 23.736 9.12521 23.2536 7.82522L12 6.78174L0.746391 7.82522C0.264047 9.12521 0 10.5313 0 11.9991C0 13.467 0.264047 14.873 0.746391 16.173L12 17.2165L23.2536 16.173Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.0007 23.9996C17.1603 23.9996 21.5588 20.7431 23.2543 16.1735H0.74707C2.44263 20.7431 6.8411 23.9996 12.0007 23.9996Z"
        fill="#D80027"
      />
      <path
        d="M11.9997 -0.000488281C6.84012 -0.000488281 2.44166 3.25601 0.746094 7.82562H23.2534C21.5578 3.25601 17.1593 -0.000488281 11.9997 -0.000488281Z"
        fill="#6DA544"
      />
      <path
        d="M3.51471 3.51453C-1.17157 8.20081 -1.17157 15.7988 3.51471 20.4852C4.73791 19.2619 5.93182 18.068 7.82609 16.1738V7.82595L3.51471 3.51453Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3651">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default KW;
