import { IconProps } from './types';

const Bulb = ({ color, dimension }: IconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 21H15"
      stroke={color || '#101828'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.73 12.692L17.5178 13.3078L16.73 12.692ZM7.27004 12.692L6.48217 13.3078H6.48217L7.27004 12.692ZM9 16.5045H10H9ZM7 9C7 6.23858 9.23858 4 12 4V2C8.13401 2 5 5.13401 5 9H7ZM12 4C14.7614 4 17 6.23858 17 9H19C19 5.13401 15.866 2 12 2V4ZM17 9C17 10.1613 16.6053 11.2277 15.9421 12.0761L17.5178 13.3078C18.4462 12.1201 19 10.6233 19 9H17ZM8.05791 12.0761C7.39469 11.2277 7 10.1613 7 9H5C5 10.6233 5.55382 12.1201 6.48217 13.3078L8.05791 12.0761ZM14 17.0002H10V19.0002H14V17.0002ZM10 17.0002V17.0002H8C8 18.1048 8.89543 19.0002 10 19.0002V17.0002ZM14 17.0002V19.0002C15.1046 19.0002 16 18.1048 16 17.0002H14ZM10 17.0002V16.5045H8V17.0002H10ZM14 16.5045V17.0002H16V16.5045H14ZM6.48217 13.3078C7.3686 14.4418 8 15.4556 8 16.5045H10C10 14.6976 8.93016 13.192 8.05791 12.0761L6.48217 13.3078ZM15.9421 12.0761C15.0698 13.192 14 14.6976 14 16.5045H16C16 15.4556 16.6314 14.4418 17.5178 13.3078L15.9421 12.0761Z"
      fill={color || '#101828'}
    />
  </svg>
);

export default Bulb;
