import { useState, useRef, useEffect, createRef, RefObject } from 'react';
import ReactPlayer from 'react-player/lazy';
import type { Timeframe } from '@replai-platform/sdk';
import { CarouselRef } from 'antd/lib/carousel';
import {
  calculateClusterViewPreviewVideoStartTime,
  calculateTimestamps,
  formatDuration,
} from '../../utils';
import Typography from '../Typography/Typography';
import SkeletonLoading from '../Skeleton/Skeleton';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import Carousel from '../Carousel/Carousel';
import Button from '../Button/Button';
import Colors from '../Colors';
import FeaturedIcons from '../FeaturedIcons/FeaturedIcons';
import FrequencyMetricBadgege from '../FrequencyMetricBadge/FrequencyMetricBadge';
import { TimelineTrack } from '../Timeline/TimelineBody/TimelineRow/TimelineRow';
import * as Styled from './styles';
import { usePrevious } from 'react-use';

const NUM_TIMESTAMPS_TO_SHOW = 9;

type Video = {
  url: URL;
  timeframes: Timeframe[];
};

export type RequestStatus =
  | 'disabled'
  | 'enabled'
  | 'requested'
  | 'requesting'
  | 'succeeded';

export type TagPreviewProps = {
  type: 'new-tag' | 'tag';
  isOpen: boolean;
  onClose: () => void;
  tag: {
    value: string | null;
    type: string;
  };
  videos?: Video[];
  tagDescription?: string;
  frequency?: {
    current: string;
    total: string;
    percentage: number;
  };
  spend?: {
    current: string;
    total: string;
    percentage: number;
  };
  requestStatus?: RequestStatus;
  onSeeAllVideosClick?: () => void;
  onRequestThisClick?: () => void;
  onContactUsClick?: () => void;
  onTimelineClick?: () => void;
  onPlayClick?: () => void;
  onPauseClick?: () => void;
};

const RequestSuccess = ({
  onContactUsClick,
  tag,
}: {
  onContactUsClick?: () => void;
  tag: {
    type: string;
    value: string | null;
  };
}) => (
  <Styled.RequestedContainer>
    <Styled.TextContainer>
      <Typography
        noMargin
        type="text-lg"
        fontWeight="medium"
        color={Colors.Gray[900]}
      >{`You have requested the "${tag.type}" tag`}</Typography>
      <Typography noMargin type="text-sm" color={Colors.Gray[500]}>
        Our team is processing your request. Feel free to contact us to receive
        more detailed information about your request or its status
      </Typography>
    </Styled.TextContainer>
    <Button size="sm" fullWidth onClick={onContactUsClick}>
      Contact us
    </Button>
  </Styled.RequestedContainer>
);

const Timeline = ({
  currentSlide,
  duration,
  seekToPoint,
  videos,
}: {
  currentSlide: number;
  duration: number | null;
  seekToPoint: (point: number) => void;
  videos?: Video[];
}) =>
  duration ? (
    <TimelineTrack
      segments={videos?.[currentSlide].timeframes ?? []}
      duration={duration}
      color="Primary"
      seekToPoint={seekToPoint}
    />
  ) : (
    <SkeletonLoading height="100%" />
  );

const SpendAndFrequency = ({
  frequency,
  spend,
}: {
  frequency?: {
    current: string;
    total: string;
    percentage: number;
  };
  spend?: {
    current: string;
    total: string;
    percentage: number;
  };
}) => (
  <Styled.SpendAndFrequencyContainer>
    {spend && frequency ? (
      <>
        <FrequencyMetricBadgege
          type="frequency"
          current={frequency.current}
          total={frequency.total}
          percentage={frequency.percentage}
        />
        <FrequencyMetricBadgege
          type="metric"
          current={spend.current}
          total={spend.total}
          percentage={spend.percentage}
        />
      </>
    ) : (
      <SkeletonLoading width={250} height="1.625rem" />
    )}
  </Styled.SpendAndFrequencyContainer>
);

const Header = ({
  frequency,
  requestStatus,
  spend,
  type,
}: {
  frequency?: {
    current: string;
    total: string;
    percentage: number;
  };
  requestStatus?: RequestStatus;
  spend?: {
    current: string;
    total: string;
    percentage: number;
  };
  type: string;
}) => {
  if (type === 'new-tag' && requestStatus === 'succeeded') {
    return (
      <FeaturedIcons
        color="Success"
        icon="CheckCircle"
        size="lg"
        variant="outline"
      />
    );
  }

  if (type === 'tag') {
    return <SpendAndFrequency frequency={frequency} spend={spend} />;
  }

  return null;
};

const Timestamp = ({ timestamp }: { timestamp: number }) => {
  const { minutes, seconds } = formatDuration(timestamp);

  return (
    <Typography
      key={timestamp}
      noMargin
      type="text-xs"
      color={Colors.Gray[500]}
      fontWeight="medium"
    >
      {`${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`}
    </Typography>
  );
};

const getButtonText = ({
  requestStatus,
  tag,
}: {
  requestStatus?: RequestStatus;
  tag: {
    type: string;
    value: string | null;
  };
}) => {
  if (tag.type === 'tag') {
    return 'See all videos';
  }

  switch (requestStatus) {
    case 'requested':
      return 'This tag has been requested';
    case 'requesting':
      return 'Requesting...';
    case 'succeeded':
    case 'disabled':
    case 'enabled':
    default:
      return (
        <>
          Request <strong>{tag.type}</strong>
        </>
      );
  }
};

const TagPreview = ({
  type,
  isOpen,
  onClose,
  tag,
  tagDescription,
  frequency,
  spend,
  videos,
  requestStatus,
  onSeeAllVideosClick,
  onRequestThisClick,
  onContactUsClick,
  onPlayClick,
  onPauseClick,
  onTimelineClick,
}: TagPreviewProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [playerRefs, setPlayerRefs] = useState<{
    [k: number]: RefObject<ReactPlayer>;
  }>({});
  const carouselRef = useRef<CarouselRef | null>(null);
  const [durations, setDurations] = useState<{ [k: number]: number | null }>(
    {}
  );
  const previousVideos = usePrevious(videos);

  const currentDuration = durations[currentSlide];
  const timestamps = currentDuration
    ? calculateTimestamps(currentDuration, NUM_TIMESTAMPS_TO_SHOW)
    : [];

  const onChangeSlide = (newIndex: number) => setCurrentSlide(newIndex);

  const seekToPoint = (point: number) => {
    playerRefs[currentSlide]?.current?.seekTo(point, 'seconds');

    onTimelineClick?.();
  };

  useEffect(() => {
    (
      playerRefs[currentSlide]?.current?.getInternalPlayer() as HTMLVideoElement
    )?.[isOpen ? 'play' : 'pause']();
  }, [isOpen, currentSlide, playerRefs]);

  useEffect(() => {
    const newPlayerRefs = Object.fromEntries(
      videos?.map((_video, index) => [index, createRef<ReactPlayer>()]) ?? []
    );
    // Re-use durations of video URLs that didn't change, reset the others.
    setDurations(
      Object.fromEntries(
        (videos ?? [])
          .map((video, index) => {
            const previousIndex = previousVideos?.findIndex(
              (previousVideo) => previousVideo?.url === video.url
            );
            if (previousIndex === undefined || previousIndex < 0) {
              return [index, null];
            }
            return [
              index,
              playerRefs[previousIndex]?.current?.getDuration?.() ?? null,
            ];
          })
          .filter(([_, duration]) => duration !== null)
      )
    );
    setPlayerRefs(newPlayerRefs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(videos?.map((video) => video.url))]);

  if (videos?.some((_video, index) => !playerRefs[index])) {
    setPlayerRefs({
      ...playerRefs,
      ...Object.fromEntries(
        videos
          .filter((_video, index) => !playerRefs[index])
          .map((_video, index) => [index, createRef()])
      ),
    });
  }

  const renderVideo = (video: Video, index: number) => {
    const onLoadedMetadata = (data: React.SyntheticEvent<HTMLVideoElement>) => {
      const targetElm = data?.target as HTMLVideoElement;

      if (targetElm?.duration) {
        setDurations({ ...durations, [index]: targetElm?.duration });
      }
    };

    return (
      <Styled.VideoContainer>
        <VideoPlayer
          onLoadedMetadata={onLoadedMetadata}
          onPlay={onPlayClick}
          onPause={onPauseClick}
          playerRef={playerRefs[index]}
          url={video.url.toString()}
          startTime={calculateClusterViewPreviewVideoStartTime(
            video.timeframes
          )}
          preload="metadata"
          playing={index === currentSlide}
          title={tag.value ?? undefined}
        />
      </Styled.VideoContainer>
    );
  };

  return (
    <Styled.StyledModal
      $removePadding={type === 'tag' || requestStatus === 'succeeded'}
      isOpen={isOpen}
      onClose={onClose}
      className="tag-preview-modal"
      modalHeader={
        <Header
          frequency={frequency}
          requestStatus={requestStatus}
          spend={spend}
          type={type}
        />
      }
    >
      {requestStatus === 'succeeded' ? (
        <RequestSuccess onContactUsClick={onContactUsClick} tag={tag} />
      ) : (
        <Styled.TagPreviewContainer>
          <Styled.VideosContainer>
            <Styled.CarouselContainer>
              {videos ? (
                <Carousel
                  slider={carouselRef}
                  slidesData={videos ?? []}
                  accessor={renderVideo}
                  afterChange={onChangeSlide}
                  arrows
                  dots={false}
                  disableShadow
                />
              ) : (
                <SkeletonLoading height="100%" />
              )}
            </Styled.CarouselContainer>
            <Styled.TimestampsContainer>
              {timestamps.length ? (
                timestamps.map((ts) => <Timestamp timestamp={ts} />)
              ) : (
                <SkeletonLoading height="100%" />
              )}
            </Styled.TimestampsContainer>
          </Styled.VideosContainer>
          <Styled.TagContainer>
            <Styled.TagTypeValue>
              <Typography
                noMargin
                type="text-sm"
                color={Colors.Gray[900]}
                fontWeight="medium"
              >
                {tag.value}
              </Typography>
              <Typography
                noMargin
                type="text-sm"
                color={Colors.Gray[500]}
                fontWeight="medium"
              >
                {tag.type}
              </Typography>
            </Styled.TagTypeValue>
            {videos && !videos?.[currentSlide].timeframes.length ? null : (
              <Styled.TimelineContainer>
                <Timeline
                  currentSlide={currentSlide}
                  duration={currentDuration}
                  seekToPoint={seekToPoint}
                  videos={videos}
                />
              </Styled.TimelineContainer>
            )}
            {tagDescription !== '' && (
              <Styled.DescriptionContainer>
                {!tagDescription ? (
                  <SkeletonLoading height="100%" />
                ) : (
                  <Typography noMargin type="text-sm" color={Colors.Gray[500]}>
                    {tagDescription}
                  </Typography>
                )}
              </Styled.DescriptionContainer>
            )}
          </Styled.TagContainer>
          <Styled.ButtonsContainer>
            <Button
              variant="outlined"
              color="Gray"
              size="sm"
              fullWidth
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              size="sm"
              fullWidth
              onClick={
                type === 'new-tag' ? onRequestThisClick : onSeeAllVideosClick
              }
              disabled={
                type === 'new-tag' &&
                ['disabled', 'requesting', 'requested'].includes(
                  requestStatus ?? ''
                )
              }
              leadingIcon={
                requestStatus === 'requesting'
                  ? { name: 'LoadingCircle' }
                  : undefined
              }
            >
              {getButtonText({ requestStatus, tag })}
            </Button>
          </Styled.ButtonsContainer>
        </Styled.TagPreviewContainer>
      )}
    </Styled.StyledModal>
  );
};

export default TagPreview;
