import { MiscIconProps } from './types';

const Dot = ({ color, dimension }: MiscIconProps) => (
  <svg
    width={dimension || '10'}
    height={dimension || '10'}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="5" cy="5" r="4" fill={color || 'white'} />
  </svg>
);

export default Dot;
