import { QueryFunction, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getConceptCsvReport = async (
  requestParams: SDK.GetConceptsCsvReportRequest
): Promise<SDK.GetConceptsCsvReportResponse> => {
  const res = await provider.getJson<object, SDK.GetConceptsCsvReportResponse>(
    `${SDK.BASE_URL()}/concepts/csv?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

const KEY = 'concept-csv-report';
type Key = typeof KEY;

type Result = SDK.GetConceptsCsvReportResponse;
type QueryKey = [Key, SDK.GetConceptsCsvReportRequest];

type QueryOptions<R = Result> = UseQueryOptions<Result, unknown, R, QueryKey>;
type QueryResult<R = Result> = UseQueryResult<R>;

const queryFn: QueryFunction<Result, QueryKey> = ({ queryKey: [, requestParams] }) =>
  getConceptCsvReport(requestParams);

// This is exported so we can use useQueries
export function getConceptCsvReportQueryOptions<R = Result>(
  requestParams: SDK.GetConceptsCsvReportRequest,
  options?: QueryOptions<R>
): QueryOptions<R> {
  return {
    queryKey: [KEY, requestParams],
    queryFn,
    ...options,
  };
}

export default function useConceptCsvReport<R = Result>(
  requestParams: SDK.GetConceptsCsvReportRequest,
  options?: QueryOptions<R>
): QueryResult<R> {
  return useQuery(getConceptCsvReportQueryOptions(requestParams, options));
}
