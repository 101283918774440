const rose = {
  25: '#FFF5F6',
  50: '#FFF1F3',
  100: '#FFE4E8',
  200: '#FECDD6',
  300: '#FEA3B4',
  400: '#FD6F8E',
  500: '#F63D68',
  600: '#E31B54',
  700: '#C01048',
  800: '#A11043',
  900: '#89123E',
} as const;

type Keys = keyof typeof rose;
type Rose = typeof rose[Keys];

export default rose;
export type { Rose };
