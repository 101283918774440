import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase, VerticalMenuItemProps, VerticalMenuItemVariant } from '@replai-platform/ui-components';
import { logEvent } from '../../../analytics';
import { TagInsight } from '../../../store/insights';
import { TAG_KINDS_TOOLTIPS } from '../../../utils/constants';
import { Page } from '../../../utils/enums';

const buildTagTypeWithCount = ({
  categoryLabel,
  categoryTooltip,
  onTagTypeClick,

  selectedTagType,
  insights,
}: {
  categoryLabel: string;
  categoryTooltip: string;
  onTagTypeClick: ({ type, originalType, kind }: { type: string; originalType: string; kind?: string }) => void;

  selectedTagType?: string;
  insights?: TagInsight[];
}) => {
  if (!insights?.length) {
    return [];
  }

  let tagTypeObjects: VerticalMenuItemProps[] = [];

  const tagTypes: { [k: string]: { count: number; kind: string } } = {};

  insights.forEach(({ content: { tag } }) => {
    tagTypes[tag.type] = { count: (tagTypes[tag.type]?.count ?? 0) + 1, kind: tag.kind };
  });

  tagTypeObjects = Object.entries(tagTypes).map(([tagType, { count, kind }]) => ({
    label: camelCaseToCapitalCase(tagType),
    badgeText: count.toString(),
    icon: 'Bulb',
    variant: VerticalMenuItemVariant.TEXT,
    onClick: () => onTagTypeClick({ type: camelCaseToCapitalCase(tagType), kind, originalType: tagType }),
    isSelected: selectedTagType === tagType,
  }));

  return [
    { variant: VerticalMenuItemVariant.DIVIDER, label: categoryLabel, tooltipText: categoryTooltip },
    ...tagTypeObjects.sort((a, b) => a.label?.localeCompare(b.label ?? '') ?? 0),
  ];
};

const buildTagTypeMenu = ({
  isTechmerc1569Enabled,
  onTagTypeClick,
  insights = [],
  selectedTagType,
}: {
  isTechmerc1569Enabled: boolean;
  onTagTypeClick: ({ type, originalType, kind }: { type: string; originalType: string; kind?: string }) => void;
  insights?: TagInsight[];
  selectedTagType?: string;
}): VerticalMenuItemProps[] => {
  const tagTypesCustom = buildTagTypeWithCount({
    insights: insights.filter(({ content: { tag } }) => tag.kind === SDK.TagKind.Custom),
    onTagTypeClick,
    selectedTagType,
    categoryLabel: TAG_KINDS_TOOLTIPS({ isTechmerc1569Enabled }).CUSTOM.label,
    categoryTooltip: TAG_KINDS_TOOLTIPS({ isTechmerc1569Enabled }).CUSTOM.tooltip,
  });

  const tagTypesNotCustom = buildTagTypeWithCount({
    insights: insights.filter(({ content: { tag } }) => tag.kind !== SDK.TagKind.Custom),
    onTagTypeClick,
    selectedTagType,
    categoryLabel: TAG_KINDS_TOOLTIPS({ isTechmerc1569Enabled }).CORE.label,
    categoryTooltip: TAG_KINDS_TOOLTIPS({ isTechmerc1569Enabled }).CORE.tooltip,
  });

  return [
    {
      label: 'All',
      icon: 'Bulb',
      badgeText: insights?.length.toString(),
      variant: VerticalMenuItemVariant.TEXT,
      onClick: () => onTagTypeClick({ type: '', originalType: '' }),
      isSelected: !selectedTagType,
    } as VerticalMenuItemProps,
  ]
    .concat(tagTypesCustom)
    .concat(tagTypesNotCustom);
};

const buildInsightsSubmenuProps = ({
  isTechmerc1569Enabled,
  selectedTagType,
  setSelectedTagType,
  isLoading = false,
  insights,
  onSearch,
}: {
  isTechmerc1569Enabled: boolean;
  selectedTagType: string;
  setSelectedTagType: (type: string) => void;
  isLoading?: boolean;
  insights?: TagInsight[];
  onSearch: (s: string) => void;
}) => {
  const menuTagTypes = insights
    ? buildTagTypeMenu({
        isTechmerc1569Enabled,
        insights,
        selectedTagType,
        onTagTypeClick: ({ originalType }) => {
          if (selectedTagType === originalType) {
            return;
          }
          setSelectedTagType(originalType);
          logEvent({
            component: 'Insights',
            action: `Click on Tag Category`,
            category: 'user_actions',
            parameters: { value: originalType, page: Page.Insights },
          });
        },
      })
    : [];

  return { items: menuTagTypes, loading: isLoading, onSearch };
};

export default buildInsightsSubmenuProps;
