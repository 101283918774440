import { CountryIconProps } from './types';

const SE = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3765)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FFDA44"
      />
      <path
        d="M9.39067 10.435H23.8977C23.1307 4.54721 18.0962 0.000244141 11.9993 0.000244141C11.1033 0.000244141 10.2306 0.099291 9.39062 0.285479V10.435H9.39067Z"
        fill="#0052B4"
      />
      <path
        d="M6.26085 10.4349V1.45923C2.96581 3.25707 0.607016 6.55478 0.101562 10.4349H6.26085V10.4349Z"
        fill="#0052B4"
      />
      <path
        d="M6.2608 13.5657H0.101562C0.607016 17.4458 2.96581 20.7436 6.26085 22.5414L6.2608 13.5657Z"
        fill="#0052B4"
      />
      <path
        d="M9.39062 13.5657V23.7152C10.2306 23.9014 11.1033 24.0005 11.9993 24.0005C18.0962 24.0005 23.1307 19.4535 23.8977 13.5657H9.39062V13.5657Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3765">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SE;
