import React, { useState } from 'react';
import styled from 'styled-components';
import Colors from '../../../Colors';
import { ChevronDown, ChevronUp } from '../../../Icons/Base';

const TagCategory = styled.span`
  margin: 0 0.5rem 0 1rem;
  color: ${Colors.Gray[900]};
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
`;

const NumberOfTags = styled(TagCategory)`
  color: ${Colors.Gray[900]};
  font-weight: normal;
`;

const GroupRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.625rem 0.25rem;
  border-bottom: 1px solid ${Colors.Gray[200]};
  cursor: pointer;
`;

export interface GroupRowProps {
  tagCategory: string;
  numberOfTags: number;
}

const GroupRow: React.FC<GroupRowProps> = ({
  tagCategory,
  numberOfTags,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      <GroupRowContainer onClick={() => setIsOpen((prevValue) => !prevValue)}>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
        <TagCategory>{tagCategory}</TagCategory>
        <NumberOfTags>({numberOfTags})</NumberOfTags>
      </GroupRowContainer>
      {isOpen ? children : null}
    </>
  );
};

export default GroupRow;
