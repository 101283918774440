import { Button, Icons, PageHeader } from '@replai-platform/ui-components';
import { ReactElement } from 'react';
import { useMatch, useNavigate } from 'react-router';
import styled from 'styled-components';
import { logEvent } from '../../analytics';
import { APPCUES_IDS } from '../../utils/constants';
import { Page } from '../../utils/enums';
import FilterBar from '../FilterBar';
import {
  MarketAppsFilterProps,
  CustomMarketTagsFilterProps,
  KpiFilterProps,
  NetworkFilterProps,
  PositiveInsightsToggleProps,
  IntroInsightsToggleProps,
  PromotedObjectFilterProps,
  PublisherFilterProps,
  TaggedWithFilterProps,
} from '../FilterBar/Filters';
import { CountryFilterProps } from '../FilterBar/Filters/CountryFilter';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const ContentRight = styled.div`
  display: flex;
  gap: 12px;
`;

const TopNavPageTitle = ({
  title,
  subtitle,
  titleIcon,
  showFilterBar = false,
  networkFilter,
  promotedObjectFilter,
  kpiFilter,
  navigateToPage,
  customFilter,
  eventPrefix,
  withAgeFilter = false,
  withCustomFilter,
  withCustomFilterLast,
  taggedWithFilter,
  alternativeBackButton,
  marketAppsFilter,
  publisherFilter,
  countryFilter,
  customMarketTagsFilter,
  positiveInsightsToggle,
  introInsightsToggle,
  titleIsLoading,
  withDateFilter = false,
}: {
  title?: string;
  subtitle?: string;
  titleIcon?: Icons.BaseIconTypes;
  showFilterBar?: boolean;
  networkFilter?: NetworkFilterProps;
  promotedObjectFilter?: PromotedObjectFilterProps;
  kpiFilter?: KpiFilterProps;
  navigateToPage?: Page | null;
  customFilter?: ReactElement;
  eventPrefix?: string;
  alternativeBackButton?: boolean;
  withAgeFilter?: boolean;
  withCustomFilter?: boolean;
  withCustomFilterLast?: boolean;
  taggedWithFilter?: TaggedWithFilterProps;
  marketAppsFilter?: MarketAppsFilterProps;
  publisherFilter?: PublisherFilterProps;
  countryFilter?: CountryFilterProps;
  customMarketTagsFilter?: CustomMarketTagsFilterProps;
  positiveInsightsToggle?: PositiveInsightsToggleProps;
  introInsightsToggle?: IntroInsightsToggleProps;
  titleIsLoading?: boolean;
  withDateFilter?: boolean;
}) => {
  const navigate = useNavigate();

  // Only show a tooltip in the Video details page
  const showTooltipInTitle = useMatch(`:projectId/${Page.VideoView}/*`);
  const insightsPage = useMatch(`:projectId/${Page.Insights}/*`);

  let appcuesId: string | undefined;
  if (insightsPage) appcuesId = APPCUES_IDS.INSIGHTS;

  let backButtonLogComponent: string;
  let backButtonComponentText: string;
  switch (navigateToPage) {
    case Page.Concepts:
      backButtonLogComponent = 'Concept Detail';
      backButtonComponentText = 'Concepts';
      break;
    case Page.Tags:
      backButtonLogComponent = 'Tag Detail';
      backButtonComponentText = 'Tags';
      break;
    case Page.Videos:
      backButtonLogComponent = 'Video Detail';
      backButtonComponentText = 'Videos';
      break;
    case Page.MarketVideosLibrary:
      backButtonLogComponent = 'Video Detail';
      backButtonComponentText = 'Creative Gallery';
      break;
    case Page.ConceptsLibrary:
      backButtonLogComponent = 'Concepts Library';
      backButtonComponentText = 'Concepts Library';
      break;
    default:
      backButtonLogComponent = '';
      backButtonComponentText = '';
      break;
  }

  return (
    <Wrapper data-test="top-nav">
      <FlexContainer>
        {navigateToPage && !alternativeBackButton ? (
          <Button
            data-test="go-back-button"
            aria-label="Go back"
            leadingIcon={{
              name: 'ChevronLeft',
            }}
            variant="no-fill"
            color="Gray"
            size="xxl"
            onClick={() => {
              logEvent({
                component: backButtonLogComponent,
                action: 'Click on Back Button',
                category: 'user_actions',
              });
              navigate(-1);
            }}
          />
        ) : undefined}
        <PageHeader
          title={title ?? ''}
          appcuesId={appcuesId}
          subtitle={subtitle}
          titleIcon={titleIcon}
          withTooltip={!!showTooltipInTitle}
          titleIsLoading={titleIsLoading}
        />
      </FlexContainer>
      <ContentRight>
        {showFilterBar ? (
          <FilterBar
            eventPrefix={eventPrefix ?? 'detail-view'}
            supportedFilters={[]}
            networkFilter={networkFilter}
            promotedObjectFilter={promotedObjectFilter}
            kpiFilter={kpiFilter}
            withAddFilterButton={false}
            withActiveFilters={false}
            withDateFilter={withDateFilter}
            customFilter={customFilter}
            withAgeFilter={withAgeFilter}
            withCustomFilter={withCustomFilter === undefined ? !!customFilter : withCustomFilter}
            withCustomFilterLast={withCustomFilterLast}
            taggedWithFilter={taggedWithFilter}
            marketAppsFilter={marketAppsFilter}
            publisherFilter={publisherFilter}
            countryFilter={countryFilter}
            customMarketTagsFilter={customMarketTagsFilter}
            positiveInsightsToggle={positiveInsightsToggle}
            introInsightsToggle={introInsightsToggle}
          />
        ) : undefined}
        {navigateToPage && alternativeBackButton ? (
          <Button
            data-test="go-back-button"
            leadingIcon={{
              name: 'ChevronLeft',
            }}
            variant="regular"
            color="Primary"
            onClick={() => {
              logEvent({
                component: backButtonLogComponent,
                action: 'Click on Back Button',
                category: 'user_actions',
              });
              navigate(-1);
            }}
          >
            {`Back to ${backButtonComponentText}`}
          </Button>
        ) : undefined}
      </ContentRight>
    </Wrapper>
  );
};

export default TopNavPageTitle;
