import { IntegrationIconProps } from './types';

const Chrome = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0015 2.00382C16.0015 2.00382 24.2536 1.63483 28.6288 9.8999H15.2993C15.2993 9.8999 12.7838 9.81911 10.6349 12.8599C10.0177 14.1363 9.35417 15.451 10.0988 18.042C9.02614 16.2313 4.4043 8.21234 4.4043 8.21234C4.4043 8.21234 7.6643 2.33057 16.0014 2.00382H16.0015Z"
      fill="#EF3F36"
    />
    <path
      d="M28.2004 22.9856C28.2004 22.9856 24.3925 30.2935 15.0254 29.9321C16.1828 27.9369 21.6919 18.4302 21.6919 18.4302C21.6919 18.4302 23.023 16.3005 21.4528 12.9253C20.6541 11.7528 19.84 10.5265 17.2167 9.87287C19.3271 9.85377 28.6055 9.87287 28.6055 9.87287C28.6055 9.87287 32.0814 15.6278 28.2004 22.9856Z"
      fill="#FCD900"
    />
    <path
      d="M3.86029 23.0434C3.86029 23.0434 -0.588016 16.1045 4.41193 8.20074C5.5655 10.1959 11.0747 19.7027 11.0747 19.7027C11.0747 19.7027 12.263 21.9171 15.9782 22.2475C17.3942 22.1438 18.8679 22.0554 20.7507 20.1217C19.7127 21.9517 15.056 29.9476 15.056 29.9476C15.056 29.9476 8.31231 30.0707 3.86018 23.0434H3.86029Z"
      fill="#61BC5B"
    />
    <path
      d="M15.0215 30.0013L16.8964 22.2053C16.8964 22.2053 18.9567 22.0437 20.6851 20.1563C19.6125 22.0362 15.0215 30.0013 15.0215 30.0013Z"
      fill="#5AB055"
    />
    <path
      d="M9.7207 16.089C9.7207 12.6523 12.5178 9.86523 15.9668 9.86523C19.4158 9.86523 22.2129 12.6523 22.2129 16.089C22.2129 19.5257 19.4158 22.3127 15.9668 22.3127C12.5178 22.3089 9.7207 19.5257 9.7207 16.089Z"
      fill="white"
    />
    <path
      d="M10.7656 16.0891C10.7656 13.229 13.0921 10.907 15.9663 10.907C18.8366 10.907 21.1669 13.2252 21.1669 16.0891C21.1669 18.9492 18.8406 21.2712 15.9663 21.2712C13.0959 21.2712 10.7656 18.9492 10.7656 16.0891Z"
      fill="url(#paint0_linear_1334_608)"
    />
    <path
      d="M28.6016 9.87685L20.8818 12.1334C20.8818 12.1334 19.7167 10.4303 17.2129 9.87685C19.3849 9.86528 28.6016 9.87685 28.6016 9.87685Z"
      fill="#EACA05"
    />
    <path
      d="M9.94832 17.7577C8.8641 15.8855 4.4043 8.21234 4.4043 8.21234L10.1219 13.848C10.1219 13.848 9.53539 15.0513 9.75538 16.7735L9.94821 17.7577H9.94832Z"
      fill="#DF3A32"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_608"
        x1="15.9661"
        y1="10.9802"
        x2="15.9661"
        y2="20.9592"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#86BBE5" />
        <stop offset="1" stopColor="#1072BA" />
      </linearGradient>
    </defs>
  </svg>
);
export default Chrome;
