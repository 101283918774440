import styled from 'styled-components';

export const Root = styled.div`
  display: inline-block;
  width: max-content;
  height: max-content;

  &.light {
    background: rgba(255, 255, 255, 0.6);
  }

  &.dark {
    background: rgba(52, 64, 84, 0.6);
  }

  &.sm {
    backdrop-filter: blur(8px);
  }

  &.md {
    backdrop-filter: blur(16px);
  }

  &.lg {
    backdrop-filter: blur(24px);
  }

  &.xl {
    backdrop-filter: blur(40px);
  }
`;
