import { CountryIconProps } from './types';

const VI = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#FCFCFC"
    />
    <path
      d="M14.0373 8.38349C14.0373 7.25793 13.1249 6.34546 11.9993 6.34546C10.8737 6.34546 9.96118 7.25793 9.96118 8.38349H5.47754C5.47754 9.49166 6.44288 10.3901 7.55105 10.3901H7.48431C7.48431 11.4984 8.38262 12.3969 9.49098 12.3969C9.49098 13.3781 10.1957 14.1934 11.1264 14.3677L11.1212 14.3794H12.8774L12.8722 14.3677C13.8029 14.1934 14.5076 13.3781 14.5076 12.3969C15.6159 12.3969 16.5143 11.4984 16.5143 10.3901H16.4474C17.5556 10.3901 18.521 9.49166 18.521 8.38349H14.0373Z"
      fill="#FFDA44"
    />
    <path
      d="M11.1035 14.1724L9.83984 17.0253C10.5066 17.2964 11.2354 17.447 11.9995 17.447C12.7636 17.447 13.4923 17.2964 14.159 17.0253L12.8954 14.1724H11.1035Z"
      fill="#FFDA44"
    />
    <path
      d="M9.3916 9.3855V12.896C9.3916 14.8928 12.0003 15.5047 12.0003 15.5047C12.0003 15.5047 14.609 14.8928 14.609 12.896V9.3855H9.3916Z"
      fill="#F0F0F0"
    />
    <path
      d="M2.91024 12.0846L4.32113 16.0307L5.71735 12.0846H6.78292L4.74732 17.302H3.8949L1.84473 12.0846H2.91024Z"
      fill="#338AF3"
    />
    <path d="M19.3047 17.302V12.0846H20.3187V17.302H19.3047Z" fill="#338AF3" />
    <path
      d="M10.4346 10.5195V14.7736C10.7932 15.0376 11.1791 15.2191 11.4781 15.3354V10.5195H10.4346Z"
      fill="#D80027"
    />
    <path
      d="M13.565 10.5195V14.7736C13.2063 15.0376 12.8205 15.2191 12.5215 15.3354V10.5195H13.565Z"
      fill="#D80027"
    />
    <path d="M14.609 9.3855H9.3916V11.0409H14.609V9.3855Z" fill="#0052B4" />
  </svg>
);
export default VI;
