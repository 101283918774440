/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Tab, Tabs } from '@replai-platform/ui-components';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTitle } from 'react-use';
import styled from 'styled-components';
import * as SDK from '@replai-platform/sdk';
import { useFeature } from '@optimizely/react-sdk';
import { api } from '../../api';
import useTagTypeValueCounts from '../../api/hooks/tags/useTagTypeValueCounts';
import FilterBar from '../../components/FilterBar';
import { FiltersContainer } from '../../components/FilterBar/common/styles';
import { CountryFilterProps } from '../../components/FilterBar/Filters/CountryFilter';
import type { BuyingStrategyFilterProps } from '../../components/FilterBar/Filters/BuyingStrategyFilter';
import type { NetworkFilterProps } from '../../components/FilterBar/Filters/NetworkFilter';
import type { PromotedObjectFilterProps } from '../../components/FilterBar/Filters/PromotedObjectFilter';
import useCountryFilter from '../../components/FilterBar/hooks/useCountryFilter';
import useNetworkFilter from '../../components/FilterBar/hooks/useNetworkFilter';
import useBuyingStrategyFilter from '../../components/FilterBar/hooks/useBuyingStrategyFilter';
import usePromotedObjectFilter from '../../components/FilterBar/hooks/usePromotedObjectFilter';
import useSidebarFilters from '../../components/FilterSidebar/buildSidebarFilters';
import TopNavPageTitle from '../../components/TopNavPageTitle';
import type { RootState } from '../../store/rootReducer';
import { TagsActions } from '../../store/tags';
import { FilterActions } from '../../store/filters';
import { Page } from '../../utils/enums';
import { RouteAnimator } from '../RouteAnimator';
import TagsTable from './TagsTable';
import { getTagTypeValueByTabLabel, groupingDropdownOptions, getTabIdByGroupingState } from './TagsTable/utils';
import { supportedFilters as tagsPageSupportedFilters } from './utils';

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const TagsView: React.VFC = () => {
  const projectName = useSelector((state: RootState) => state.project.name);
  const projectId = useSelector((state: RootState) => state.project.id);
  const tagsConfig = useSelector((state: RootState) => state.project.config.tagsTypes);
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes);
  const filters = useSelector((state: RootState) => state.filters);
  const group = useSelector((state: RootState) => state.tags.group);
  const [isTechmerc1525Enabled] = useFeature('techmerc-1525'); // Buying Strategy filter
  const [isTechmerc1569Enabled] = useFeature('techmerc-1569'); // Renaming Tags Filter
  const dispatch = useDispatch();

  // Add sidebar filters
  useSidebarFilters(tagsPageSupportedFilters(projectAccountTypes ?? []));

  // Keep the tab title updated.
  useTitle(`Tags - ${projectName}`);

  // Build network filter
  const {
    networkFilterOptions,
    networkFilterOnChange,
    isLoading: isNetworkFilterLoading,
  } = useNetworkFilter(Page.Tags);
  const networkFilter = useMemo(
    () =>
      ({
        options: networkFilterOptions,
        onChange: networkFilterOnChange,
        loading: isNetworkFilterLoading,
      } as NetworkFilterProps),
    [networkFilterOptions, networkFilterOnChange, isNetworkFilterLoading]
  );

  // Build buying strategy filter
  const {
    buyingStrategyFilterOptions,
    buyingStrategyFilterOnChange,
    isLoading: isBuyingStrategyFilterLoading,
  } = useBuyingStrategyFilter(Page.Videos);
  const buyingStrategyFilter = useMemo(
    () =>
      ({
        options: buyingStrategyFilterOptions,
        onChange: buyingStrategyFilterOnChange,
        loading: isBuyingStrategyFilterLoading,
      } as BuyingStrategyFilterProps),
    [buyingStrategyFilterOptions, buyingStrategyFilterOnChange, isBuyingStrategyFilterLoading]
  );

  // Build promoted object types filter
  const { promotedObjectTypeFilterOptions, promotedObjectTypeFilterOnChange } = usePromotedObjectFilter(Page.Videos);
  const promotedObjectFilter = useMemo(
    () =>
      ({
        options: promotedObjectTypeFilterOptions,
        onChange: promotedObjectTypeFilterOnChange,
      } as PromotedObjectFilterProps),
    [promotedObjectTypeFilterOnChange, promotedObjectTypeFilterOptions]
  );

  // Fetch a list of tag types along with their asset counts.
  const getTagTypesParams = useMemo(
    () => ({
      projectIds: [projectId],
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(filters), projectId]
  );
  const { data: tagTypes } = useTagTypeValueCounts(getTagTypesParams, {
    select: useCallback(
      (res) =>
        res.counts
          .filter((t) => {
            const tagTypesToExclude2 = tagsConfig?.excluded || [];
            return !tagTypesToExclude2.includes(t.type);
          })
          .sort((a, b) => {
            // no configuration? alphabetic sorting
            if (!tagsConfig) return a.type.localeCompare(b.type);

            // custom tags
            const idxCustomA = (tagsConfig.custom ?? []).indexOf(a.type);
            const idxCustomB = (tagsConfig.custom ?? []).indexOf(b.type);
            if (idxCustomA !== -1 || idxCustomB !== -1) {
              if (idxCustomA === -1) return 1;
              if (idxCustomB === -1) return -1;
              return idxCustomA - idxCustomB;
            }

            // psychological tags
            const idxPsychologicalA = (tagsConfig.psychological ?? []).indexOf(a.type);
            const idxPsychologicalB = (tagsConfig.psychological ?? []).indexOf(b.type);
            if (idxPsychologicalA !== -1 || idxPsychologicalB !== -1) {
              if (idxPsychologicalA === -1) return 1;
              if (idxPsychologicalB === -1) return -1;
              return idxPsychologicalA - idxPsychologicalB;
            }

            // core tags
            const idxCoreA = (tagsConfig.core ?? []).indexOf(a.type);
            const idxCoreB = (tagsConfig.core ?? []).indexOf(b.type);
            if (idxCoreA !== -1 || idxCoreB !== -1) {
              if (idxCoreA === -1) return 1;
              if (idxCoreB === -1) return -1;
              return idxCoreA - idxCoreB;
            }

            return a.type.localeCompare(b.type);
          })
          .map((tag) => ({
            type: tag.type,
            group: tagsConfig?.custom?.includes(tag.type)
              ? 'custom'
              : tagsConfig?.psychological?.includes(tag.type)
              ? 'psychological'
              : 'core',
            count: tag.valueCount,
          })),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [JSON.stringify(tagsConfig)]
    ),
  });

  const {
    countryFilterOptions,
    isLoading: countryFilterIsLoading,
    setIsInitialFilter,
    isInitialFilter,
  } = useCountryFilter({
    selectedCountries: filters.countries,
  });
  const countryFilter = useMemo<CountryFilterProps | undefined>(
    () =>
      projectAccountTypes?.includes(SDK.AccountTypes.SOCIALS)
        ? {
            options: countryFilterOptions,
            onChange: (options) => {
              dispatch(
                FilterActions.changeCountries({
                  value: options
                    .filter(({ isAllOption }) => !isAllOption)
                    .filter(({ selected }) => selected)
                    .map(({ id }) => id)
                    .filter((id): id is string => id !== undefined),
                  logEvent: !isInitialFilter,
                })
              );

              setIsInitialFilter(false);
            },
            loading: countryFilterIsLoading,
          }
        : undefined,
    [dispatch, countryFilterIsLoading, countryFilterOptions, isInitialFilter, setIsInitialFilter, projectAccountTypes]
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const tabLabels = groupingDropdownOptions(tagsConfig!, tagTypes, isTechmerc1569Enabled);
  const defaultId = getTabIdByGroupingState(group);
  const defaultTabLabel =
    tabLabels.find((tab) => tab.id === defaultId) ?? (tagsConfig?.custom?.length ? tabLabels[1] : tabLabels[0]);

  const onTabChange = (newTab: Tab) => {
    const newGroup = getTagTypeValueByTabLabel(newTab);
    dispatch(TagsActions.changeGroup(newGroup));
    dispatch(TagsActions.changeTableOffset(0));
  };

  return (
    <RouteAnimator data-test="tags-container">
      <TopNavPageTitle
        title="Tags Performance"
        subtitle="Find the best performing tags of your videos. You can focus on specific categories, and then analyze using more than 12 available KPIs."
      />
      <FiltersContainer data-test="tags-filters-container">
        <FilterBar
          eventPrefix="tags"
          supportedFilters={tagsPageSupportedFilters(projectAccountTypes ?? [])}
          networkFilter={networkFilter}
          buyingStrategyFilter={isTechmerc1525Enabled ? buyingStrategyFilter : undefined}
          promotedObjectFilter={promotedObjectFilter}
          countryFilter={countryFilter}
          withAgeFilter
          withAddFilterButton={!projectAccountTypes?.includes(SDK.AccountTypes.SOCIALS)}
        />
      </FiltersContainer>
      <TabsContainer>
        <Tabs tabLabels={tabLabels} onTabChange={onTabChange} defaultSelectedTab={defaultTabLabel} withOverflowMenu />
      </TabsContainer>
      <TagsTable />
    </RouteAnimator>
  );
};

export default TagsView;
