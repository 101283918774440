import React, { ReactNode, useMemo } from 'react';
import { ColorTypes } from '..';
import { ButtonIcon, ButtonSize, ButtonVariant } from '../Button/Button';
import * as Styled from './styles';

import '../common.module.css';

const DOTS = 0;

export type Shape = 'default' | 'leftRightRounded';
export type PaginationSelectorLayout = 'default' | 'left' | 'right';

export type ActionButtonInfo = {
  child?: ReactNode;
  buttonIcon?: ButtonIcon;
};

export interface UsePaginationProps {
  totalCount: number;
  pageSize: number;
  siblingCount?: number;
  currentPage: number;
}

export interface PaginationProps extends React.HTMLAttributes<HTMLDivElement> {
  // Style Props
  shape?: Shape;
  variant?: ButtonVariant;
  dotsVariant?: ButtonVariant;
  selectedNumerationVariant?: ButtonVariant;
  unselectedNumerationVariant?: ButtonVariant;
  size?: ButtonSize;
  color?: ColorTypes;
  selectorsLayout?: PaginationSelectorLayout;

  previousButtonInfo?: ActionButtonInfo;
  nextButtonInfo?: ActionButtonInfo;

  hidePrevButton?: boolean;
  hideNextButton?: boolean;
  groupButtons?: boolean;

  // Pagination custom props
  text?: string;
  onPageChange: (page: number) => void;
  totalCount: number;
  pageSize: number;
  siblingCount?: number;
  currentPage: number;
  disabled?: boolean;

  // When we want a simple pagination
  simple?: boolean;
}

/**
 *
 * Create an array of certain length and set the
 * elements within it from start value to end value.
 *
 * @param start begining number
 * @param end end number
 * @returns
 */
const range = (start: number, end: number): number[] =>
  Array.from({ length: end - start + 1 }, (_, idx) => idx + start);

/**
 *
 * Creates an array with all the page numbers to iterate (including the 'DOTS' abbreviation)
 *
 * @param totalCount total count of data
 * @param pageSize total elements per page
 * @param siblingCount total elements to show near the pivot number. (e.x considering pivout=4: sibling=1 -> 1..3 4 5..10 ;sibling=2 -> 1..23 4 56..10 ;)
 * @param currentPage current page
 * @returns an array with all the values where we are going to navigate (including the dots)
 */
export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
}: UsePaginationProps) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    /*
      Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount.
      Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
      Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    /*
      Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
      Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
    return [];
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};

const Pagination = ({
  style,
  shape,
  variant = 'regular',
  selectedNumerationVariant = 'light',
  unselectedNumerationVariant = 'no-fill',
  dotsVariant = 'no-fill',

  color,
  size = 'md',
  selectorsLayout = 'default',
  previousButtonInfo = {
    buttonIcon: {
      name: 'ArrowLeft',
    },
  },
  nextButtonInfo = {
    buttonIcon: {
      name: 'ArrowRight',
    },
  },

  hidePrevButton = false,
  hideNextButton = false,
  disabled = false,
  groupButtons = false,

  text = 'Page',
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,

  simple = false, // Simple pagination witout the middle numbers/dots.
}: PaginationProps) => {
  // Define flex order to arrange 'divs' inside component.
  // Order '-1' shows first (most left) while '1' shows last (most right).
  // eslint-disable-next-line no-nested-ternary
  const order =
    selectorsLayout === 'default' ? 0 : selectorsLayout === 'left' ? 1 : -1;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount: siblingCount >= 0 ? siblingCount : 1,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = (): void => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = (): void => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <Styled.PaginationRoot
      data-test="next-previous-pagination-container"
      style={style}
    >
      {!hidePrevButton && (
        <Styled.PrevButton
          data-test="previous-button"
          key="pagination-prev-button"
          className={`${
            shape && shape !== 'default'
              ? `${shape}PreviousItem`
              : 'previousItem'
          }`}
          variant={variant}
          size={size}
          color="Gray"
          disabled={currentPage === 1 || disabled}
          leadingIcon={previousButtonInfo.buttonIcon}
          onClick={onPrevious}
          isGroupButton={groupButtons}
          isFirst={groupButtons}
        >
          {previousButtonInfo.child}
        </Styled.PrevButton>
      )}

      <Styled.PaginationContent
        data-test="pagination-container"
        style={{ order }}
      >
        {simple ? (
          <Styled.SimplePagination className={size}>
            <div>{text}&nbsp;</div>
            <b>{currentPage}&nbsp;</b>
            <div>of&nbsp;</div>
            <b>{lastPage}</b>
          </Styled.SimplePagination>
        ) : (
          paginationRange.map((pageNumber: number, index: number) => {
            if (pageNumber === DOTS) {
              return (
                <Styled.DotsItem
                  key={`dots-index-${index}-page-${pageNumber}`}
                  className={`${
                    shape && shape !== 'default'
                      ? `${shape}DotsItem`
                      : 'dotsItem'
                  }`}
                  color={color}
                  variant={dotsVariant}
                  disabled
                  leadingChar=".."
                  isGroupButton={groupButtons}
                />
              );
            }
            return (
              <Styled.PaginationContentButton
                data-test="pagination-button"
                key={`pagination-pagenumber-${pageNumber}`}
                className={`${
                  shape && shape !== 'default'
                    ? `${shape}PaginationContent`
                    : 'paginationContent'
                }`}
                variant={
                  pageNumber === currentPage
                    ? selectedNumerationVariant
                    : unselectedNumerationVariant
                }
                size={size}
                color={color}
                onClick={() => onPageChange(pageNumber)}
                leadingChar={`${pageNumber}`}
                disabled={disabled}
                isGroupButton={groupButtons}
              />
            );
          })
        )}
      </Styled.PaginationContent>

      {!hideNextButton && (
        <Styled.NextButton
          data-test="next-button"
          key="pagination-next-button"
          className={`${
            shape && shape !== 'default' ? `${shape}NextItem` : 'nextItem'
          }`}
          variant={variant}
          size={size}
          color="Gray"
          disabled={currentPage === lastPage || disabled}
          trailingIcon={nextButtonInfo.buttonIcon}
          onClick={onNext}
          isGroupButton={groupButtons}
          isLast={groupButtons}
        >
          {nextButtonInfo.child}
        </Styled.NextButton>
      )}
    </Styled.PaginationRoot>
  );
};

export default Pagination;
