import { PaymentIconProps } from './types';

const Yandex = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      d="M31.7329 14.3722C31.6395 13.7948 31.1253 13.4099 30.4243 13.4099C30.1906 13.4099 30.0036 13.4099 29.7699 13.4099C29.3493 13.3618 29.2558 13.5062 29.2558 13.843C29.2558 15.5269 29.2558 17.1627 29.2558 18.8467C29.2558 19.7127 28.4145 20.6269 27.433 20.8193C24.3483 21.541 21.2635 22.2146 18.1788 22.9363C17.8517 23.0325 17.4778 23.0325 17.1038 23.0325C16.9636 23.0325 16.7299 22.9363 16.6832 22.792C16.6365 22.6957 16.7767 22.5033 16.8702 22.4071C17.0104 22.3108 17.1973 22.2146 17.3843 22.1184C17.9451 21.9259 18.5527 21.7335 19.1136 21.541C21.4038 20.7712 23.6939 20.0014 25.9841 19.1835C27.0124 18.8467 27.5732 18.125 27.5732 17.2109C27.62 14.3241 27.5732 11.3892 27.5732 8.50241C27.5732 8.02128 27.3395 7.63638 26.8254 7.44393C26.2178 7.20336 25.6569 7.39581 25.1428 7.63638C21.8711 9.2241 18.6462 10.8118 15.3745 12.3996C14.4398 12.8807 13.8789 13.5543 13.8789 14.5165C13.8789 17.259 13.8789 20.0014 13.8789 22.7438C13.8789 22.8882 13.8789 23.0325 13.9256 23.1288C14.2528 24.0429 15.0941 24.6203 16.2158 24.6203C18.506 24.6203 20.7962 24.6203 23.0863 24.6203C25.3765 24.6203 27.62 24.6203 29.9101 24.6203C30.4243 24.6203 30.8449 24.524 31.2188 24.1872C31.6862 23.8023 31.6862 23.2731 31.6862 22.792C31.6862 20.4249 31.7329 14.3722 31.7329 14.3722ZM22.1983 13.5062C22.432 12.8807 22.9461 12.4958 23.6939 12.3996C24.2081 12.3514 24.6287 12.6401 24.7222 13.1213C24.8157 13.843 23.9744 14.709 23.0863 14.709C22.3853 14.709 21.9646 14.1797 22.1983 13.5062Z"
      fill="#FD6727"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default Yandex;
