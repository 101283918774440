export const DATE_FILTER_BUTTONS = [
  {
    label: 'Last 30d',
    lastDays: 30,
  },
  {
    label: 'Last 60d',
    lastDays: 60,
  },
  {
    label: 'Last 90d',
    lastDays: 90,
  },
];

export const GENRES_ALLOWED_CUSTOM_TAGS = ['shooter'];
