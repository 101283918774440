import * as SDK from '@replai-platform/sdk';
import { useFeature } from '@optimizely/react-sdk';
import {
  camelCaseToCapitalCase,
  Colors,
  EmptyState,
  Skeleton,
  Tab,
  Tabs,
  VideosPreviewDialog,
  Typography,
} from '@replai-platform/ui-components';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import { useMount, useTitle } from 'react-use';
import { getMetricsFilters } from '../../../api/api/filterConverter';
import useMarketTagPreview from '../../../api/hooks/market/useMarketTagPreview';
import useMarketTags from '../../../api/hooks/market/useMarketTags';
import useTagDescription from '../../../api/hooks/tags/useTagDescription';
import useTagPreviewInfo from '../../../api/hooks/tags/useTagPreviewInfo';
import useTagsFrequency from '../../../api/hooks/tags/useTagsFrequency';
import FilterBar from '../../../components/FilterBar';
import { FiltersContainer } from '../../../components/FilterBar/common/styles';
import VerticalMenuPageLayout from '../../../components/VerticalMenuPageLayout';
import { MarketActions, MarketTab } from '../../../store/market';
import type { RootState } from '../../../store/rootReducer';
import { FilterActions, dateFromDateRangeDaysBeforeYesterday, defaultEndDate } from '../../../store/filters';
import { generateHref } from '../../../utils';
import { Page } from '../../../utils/enums';
import marketTagTypesToExclude from '../../../utils/market/tagTypesToExclude';
import { DATE_FILTER_BUTTONS, GENRES_ALLOWED_CUSTOM_TAGS } from '../constants';
import * as Styled from './styles';
import TabContent from './TabContent';
import { buildTagTypes, buildTagValues, logEventOnAction, logPreviewEvent } from './utils';
import useMarketAppsFilter from '../../../components/FilterBar/hooks/useMarketAppsFilter';
import { MarketAppsFilterProps } from '../../../components/FilterBar/Filters';

const getTabTooltip = (tab: string) =>
  ({
    'growth-opportunities': 'Market uses it more than you',
    'your-outliers': 'You use it more than the market',
  }[tab] ?? '');

const AVAILABLE_TABS: MarketTab[] = ['all', 'growth-opportunities', 'your-outliers'];
const TABS = Object.values(AVAILABLE_TABS).map((tab) => ({
  id: tab,
  label: camelCaseToCapitalCase(tab),
  tooltipContent: getTabTooltip(tab) ? (
    <Typography noMargin type="text-sm">
      {getTabTooltip(tab)}
    </Typography>
  ) : undefined,
}));

const NUM_VIDEOS_PER_PAGE = 8;
const NUM_FEATURED_VIDEOS = 2;

const MarketTagsLibrary: React.VFC = () => {
  const dispatch = useDispatch();
  const match = useMatch(':projectId/*');
  const onlyCompetitors = useSelector((state: RootState) => state.filters.onlyMarketCompetitors);
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectName = useSelector((state: RootState) => state.project.name);
  const organizationName = useSelector((state: RootState) => state.project.organizationName);
  const tagsConfig = useSelector((state: RootState) => state.project.config.tagsTypes);
  const projectMarketGenres = useSelector((state: RootState) => state.project.config.marketGenres);
  const allowUntaggedMarketAssets = useSelector((state: RootState) => state.project.config.allowUntaggedMarketAssets);
  const includeInactiveFirstPartyAssetsInMarketYouFrequency = useSelector(
    (state: RootState) => state.project.config.includeInactiveFirstPartyAssetsInMarketYouFrequency
  );
  const { marketAppsToConsider, marketAppsToExclude, ageStartDate, ageEndDate, marketNetworks, startDate, endDate } =
    useSelector((state: RootState) => state.filters);
  const maxFirstAppearanceSeconds =
    useSelector((state: RootState) => state.filters.maxFirstAppearanceSeconds) ?? undefined;
  const selectedTagMenuOption = useSelector((state: RootState) => state.market.tagGallery.selectedTagMenuOption);
  const currentTab = useSelector((state: RootState) => state.market.tagGallery.currentTagsTab);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewPage, setPreviewPage] = useState(1);
  const [selectedTag, setSelectedTag] = useState<(SDK.TagWithKind & { clientFrequency?: number }) | undefined>(
    undefined
  );
  const [isTechMerc1322Enabled] = useFeature('techmerc-1322'); // Enable non competitor data (all verticals on market)
  const [isTechmerc1569Enabled] = useFeature('techmerc-1569'); // Renaming Tags Filter

  const {
    marketAppsFilterOptions,
    marketAppsFilterOnChange,
    loading: appFilterLoading,
    onClearClick,
  } = useMarketAppsFilter(Page.MarketTagsLibrary);

  const marketAppsFilter = useMemo(
    () =>
      ({
        options: marketAppsFilterOptions,
        onChange: marketAppsFilterOnChange,
        loading: appFilterLoading,
        onClearClick,
      } as MarketAppsFilterProps),
    [marketAppsFilterOptions, marketAppsFilterOnChange, appFilterLoading, onClearClick]
  );

  const navigate = useNavigate();

  // Keep the tab title updated.
  useTitle(`Tags Library - Market Insights - ${projectName}`);

  // Scrolls into the selected tag type if it's not visible (on the first render, when changing pages)
  useMount(() => {
    setTimeout(() =>
      document.getElementById(selectedTagMenuOption.type)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    );

    // [TECHBUG-1469]: force the date range to 30 days when navigating to the market pages since their data is older.
    const rangeDays = 30;
    dispatch(
      FilterActions.changeDateRange({
        logEvent: false,
        startDate: dateFromDateRangeDaysBeforeYesterday(rangeDays),
        endDate: defaultEndDate,
        rangeDays,
      })
    );
  });

  const tagsFrequencyParams = useMemo<SDK.GetTagsFrequencyRequest>(
    () => ({
      projectIds: [projectId],
      adTagsFilters: { maxFirstAppearanceSeconds },
      adsFilters: {
        networksToConsider: marketNetworks,
      },
      marketMetricsFilters: {
        assetLaunchDateStartTimestamp: ageStartDate ? new Date(ageStartDate).getTime() : undefined,
        assetLaunchDateEndTimestamp: ageEndDate ? new Date(ageEndDate).getTime() : undefined,
      },
      metricsFilters: {
        ...getMetricsFilters({ startDate, endDate }),
      },
      activeOnly: !includeInactiveFirstPartyAssetsInMarketYouFrequency,
      tagIds: selectedTag ? [selectedTag?.id] : [],
      marketTaggedOnly: allowUntaggedMarketAssets ? false : !!onlyCompetitors,
    }),
    [
      projectId,
      maxFirstAppearanceSeconds,
      marketNetworks,
      ageStartDate,
      ageEndDate,
      startDate,
      endDate,
      includeInactiveFirstPartyAssetsInMarketYouFrequency,
      selectedTag,
      onlyCompetitors,
      allowUntaggedMarketAssets,
    ]
  );

  const { data: tagFrequency, isLoading: isFrequencyLoading } = useTagsFrequency(tagsFrequencyParams, {
    enabled: !!selectedTag,
    select: (data) => data[0]?.frequency,
  });
  // If the selected tag is present in any of the project assets, we show two videos from the
  // project and the remaining from the market
  const NUM_MARKET_VIDEOS = NUM_VIDEOS_PER_PAGE - (tagFrequency ? NUM_FEATURED_VIDEOS : 0);

  // Retrieve list of tags.
  const getMarketTagsParams = useMemo<SDK.GetMarketTagsRequest>(
    () => ({
      projectId,
      tagsFilters: {
        tagTypesToExclude: marketTagTypesToExclude({ projectTagTypesConfig: tagsConfig ?? {} }).map((type) => ({
          type,
        })),
      },
      adTagsFilters: { maxFirstAppearanceSeconds },
      appsFilters: {
        appIdsToInclude: marketAppsToConsider.map((ma) => ma.id),
        appIdsToExclude: marketAppsToExclude.map((ma) => ma.id),
        publishersToExclude: [organizationName],
        ...(onlyCompetitors && projectMarketGenres ? { genres: projectMarketGenres } : {}),
      },
      adsFilters: {
        networksToConsider: marketNetworks,
      },
      marketMetricsFilters: {
        assetLaunchDateStartTimestamp: ageStartDate ? new Date(ageStartDate).getTime() : undefined,
        assetLaunchDateEndTimestamp: ageEndDate ? new Date(ageEndDate).getTime() : undefined,
        startDate,
        endDate,
      },
      hasPostQaAnnotations: allowUntaggedMarketAssets ? false : !!onlyCompetitors,
      ...(onlyCompetitors && projectMarketGenres && !allowUntaggedMarketAssets
        ? { assetsFilters: { taggedGenres: projectMarketGenres } }
        : {}),
    }),
    [
      projectId,
      tagsConfig,
      maxFirstAppearanceSeconds,
      projectMarketGenres,
      marketNetworks,
      marketAppsToConsider,
      marketAppsToExclude,
      organizationName,
      allowUntaggedMarketAssets,
      ageStartDate,
      ageEndDate,
      startDate,
      endDate,
      onlyCompetitors,
    ]
  );
  const { data: tagsData, isLoading, isError } = useMarketTags(getMarketTagsParams);

  // Gets all the previews where the selected tag is present
  const previewInfoParams = useMemo<SDK.GetMarketTagsPreviewsRequest>(
    () => ({
      projectId,
      tagIds: selectedTag?.id ? [selectedTag.id] : [],
      appsFilters: {
        appIdsToInclude: marketAppsToConsider.map((ma) => ma.id),
        appIdsToExclude: marketAppsToExclude.map((ma) => ma.id),
        publishersToExclude: [organizationName],
      },
      adsFilters: {
        networksToConsider: marketNetworks,
      },
      marketMetricsFilters: {
        assetLaunchDateStartTimestamp: ageStartDate ? new Date(ageStartDate).getTime() : undefined,
        assetLaunchDateEndTimestamp: ageEndDate ? new Date(ageEndDate).getTime() : undefined,
        startDate,
        endDate,
      },
      offset: (previewPage - 1) * NUM_MARKET_VIDEOS,
      maxRecords: NUM_MARKET_VIDEOS,
      onlyCompetitors,
    }),
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      JSON.stringify(marketAppsToConsider),
      JSON.stringify(marketAppsToExclude),
      organizationName,
      previewPage,
      JSON.stringify(selectedTag),
      maxFirstAppearanceSeconds,
      marketNetworks,
      ageStartDate,
      ageEndDate,
      startDate,
      endDate,
      projectId,
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );
  const { data: { tags: tagPreviews } = {}, isLoading: isTagPreviewsLoading } = useMarketTagPreview(previewInfoParams, {
    enabled: previewOpen && !!selectedTag,
  });

  const tagPreviewInfoParams = useMemo<SDK.GetTagsPreviewsRequest>(
    () => ({
      projectIds: [projectId],
      tagIds: selectedTag?.id ? [selectedTag.id] : [],
      adsFilters: {
        networksToConsider: marketNetworks,
      },
      marketMetricsFilters: {
        assetLaunchDateStartTimestamp: ageStartDate ? new Date(ageStartDate).getTime() : undefined,
        assetLaunchDateEndTimestamp: ageEndDate ? new Date(ageEndDate).getTime() : undefined,
      },
      maxRecords: NUM_FEATURED_VIDEOS,
    }),
    [ageEndDate, ageStartDate, marketNetworks, projectId, selectedTag?.id]
  );
  const { data: { tags: projectTagPreviews } = {}, isLoading: isProjectTagPreviewsLoading } = useTagPreviewInfo(
    tagPreviewInfoParams,
    {
      enabled: previewOpen && !!selectedTag && !!tagFrequency,
    }
  );

  const tagDescriptionParams = useMemo<SDK.GetTagsDescriptionsRequest>(
    () => ({
      projectIds: [projectId],
      tags: selectedTag?.id ? [selectedTag.id] : [],
    }),
    [projectId, selectedTag?.id]
  );
  const { data: tagDescription, isLoading: isLoadingTagDescription } = useTagDescription(tagDescriptionParams, {
    enabled: previewOpen && !!selectedTag,
  });

  const tagPreviewVideosTitle = (
    <>
      <Typography>
        Videos with {selectedTag?.type ? `${camelCaseToCapitalCase(selectedTag.type)}:` : ''}{' '}
        {selectedTag?.value ?? SDK.messages.NOT_AVAILABLE}
      </Typography>
      {isLoadingTagDescription ? (
        <Skeleton width="50%" />
      ) : (
        <Typography>{tagDescription?.tags?.[0]?.description ?? ''}</Typography>
      )}
    </>
  );

  const tagTypes = useMemo(
    () =>
      tagsData
        ? buildTagTypes({
            isTechmerc1569Enabled,
            tags: tagsData.filter(
              (tag) =>
                tag.kind !== SDK.TagKind.Custom ||
                projectMarketGenres?.some((genre) => GENRES_ALLOWED_CUSTOM_TAGS.includes(genre))
            ),
            selectedTagType: selectedTagMenuOption.type,
            onTagTypeClick: ({ type, kind }) => {
              if (selectedTagMenuOption.type === type) {
                return;
              }
              dispatch(MarketActions.changeSelectedTagMenuOption({ type, kind, logEvent: true }));
              dispatch(MarketActions.changeCurrentTagsSubPage({ page: 1, logEvent: false }));
            },
            ...(onlyCompetitors ? {} : { tagKindFilter: SDK.TagKind.Core }),
          })
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, selectedTagMenuOption.type, JSON.stringify(tagsData)]
  );
  const tagValues = useMemo(() => {
    if (isLoading) {
      return [];
    }
    const allTagValues = tagsData
      ? buildTagValues({
          tags: tagsData,
          onClick: (tag) => {
            setSelectedTag(tag);
            setPreviewOpen(true);
            logEventOnAction({ action: 'Click Tag' });
          },
        })
      : [];
    const customTags = allTagValues.filter((tag) => tag.kind === SDK.TagKind.Custom);
    const otherTags = allTagValues.filter((tag) => tag.kind !== SDK.TagKind.Custom);

    const sortedTagValues = [...(onlyCompetitors ? customTags : []), ...otherTags];

    const tagValuesReturn = sortedTagValues.filter(
      ({ subTitle }) => !selectedTagMenuOption.type || subTitle === selectedTagMenuOption.type
    );

    if (tagValuesReturn?.length || isLoading) {
      return tagValuesReturn;
    }

    // if the tagValuesReturn doesn't have any value (e.g. because of a filter),
    // we reset the selected tag type to be on the 'All' tab
    dispatch(MarketActions.changeSelectedTagMenuOption({ type: '', logEvent: false }));
    return sortedTagValues;
  }, [isLoading, tagsData, dispatch, selectedTagMenuOption.type, onlyCompetitors]);

  const videos = useMemo(
    () =>
      tagPreviews?.[0].videos.map((video) => ({
        ...video,
        timeframes: [], // forcing market videos to not have a timeline
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(tagPreviews)]
  );

  const generateVideoHref = useCallback(
    (video: SDK.MarketPreviewVideo) => generateHref(match?.params?.projectId ?? '', Page.VideoView, video.id ?? ''),
    [match?.params?.projectId]
  );

  const featuredVideos = useMemo(
    () =>
      projectTagPreviews?.[0].videos.map((video) => ({
        ...video,
        href: generateVideoHref(video),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [generateVideoHref, JSON.stringify(projectTagPreviews)]
  );

  if (isError) {
    return <EmptyState icon="CloudOff" text="Could not retrieve market data. Please try again later." />;
  }

  const onVideoClick = (assetId: string) => {
    const isInternal = (featuredVideos?.filter((video) => video.id === assetId)?.length ?? 0) > 0;

    if (isInternal) {
      navigate(`/${projectId}/${Page.VideoView}/${assetId}`);
      logPreviewEvent({ action: 'Click Video', params: { value: assetId } });
    }
  };

  const onTabChange = (tab: Tab) => {
    dispatch(MarketActions.changeCurrentTagsTab({ tab: tab.id as MarketTab, logEvent: true }));
    dispatch(MarketActions.changeCurrentTagsSubPage({ page: 1, logEvent: false }));
  };

  return (
    <VerticalMenuPageLayout
      header={
        <Styled.TitleContainer>
          <Typography type="display-sm" noMargin fontWeight="medium" color={Colors.Gray[900]}>
            Tag Gallery
          </Typography>
          <Typography type="text-md" noMargin color={Colors.Gray[500]}>
            Be ahead of competition by benchmarking your creative elements usage vs the market.
          </Typography>
        </Styled.TitleContainer>
      }
      verticalMenuProps={{
        items: tagTypes,
        loading: isLoading,
        onSearch: (search) => logPreviewEvent({ action: 'Search Category', params: { search } }),
      }}
    >
      <FiltersContainer data-test="market-videos-library-filters-container">
        <FilterBar
          eventPrefix="market-tags-library"
          marketAppsFilter={marketAppsFilter}
          dateFilter={{ customRanges: DATE_FILTER_BUTTONS }}
          onlyMarketCompetitors={{
            disabled: appFilterLoading,
            checked: onlyCompetitors,
            onChange: (value: boolean) => dispatch(FilterActions.changeOnlyMarketCompetitors({ value })),
          }}
          withAgeFilter
          withDateFilter
          withOnlyMarketCompetitors={isTechMerc1322Enabled}
          withAddFilterButton={false}
        />
      </FiltersContainer>
      <Tabs tabLabels={TABS} onTabChange={onTabChange} defaultSelectedTab={TABS.find(({ id }) => id === currentTab)} />
      <TabContent isTagsLoading={isLoading} tagValues={tagValues} />
      <VideosPreviewDialog
        title={tagPreviewVideosTitle}
        videos={videos}
        withFeaturedVideos={!isFrequencyLoading && !!tagFrequency}
        featuredVideos={featuredVideos}
        isLoadingFeaturedVideos={isProjectTagPreviewsLoading || isFrequencyLoading}
        numFeaturedVideos={NUM_FEATURED_VIDEOS}
        totalVideos={tagPreviews?.[0].totalCount}
        page={previewPage}
        isOpen={previewOpen}
        isLoading={isTagPreviewsLoading}
        onVideoClick={onVideoClick}
        onPauseClick={(assetId) => logPreviewEvent({ action: 'Click Pause Video', params: { value: assetId } })}
        onPlayClick={(assetId) => logPreviewEvent({ action: 'Click Play Video', params: { value: assetId } })}
        onPageChange={(pageNumber) => {
          logPreviewEvent({ action: 'Change page', params: { page: pageNumber } });
          setPreviewPage(pageNumber);
        }}
        onClose={() => {
          setPreviewOpen(false);
          setPreviewPage(1);
        }}
      />
    </VerticalMenuPageLayout>
  );
};

export default MarketTagsLibrary;
