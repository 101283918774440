import { TableWithPaginationProps } from '@replai-platform/ui-components';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '../../../components/Table';
import { RootState } from '../../../store/rootReducer';
import { Columns } from '../../../utils/enums';
import useReports from '../../../api/hooks/reports/useReports';
import { logEvent } from '../../../analytics';
import useGrantReportPermissions from '../../../api/hooks/reports/useGrantReportPermissions';
import useToggleFavoriteReport from '../../../api/hooks/reports/useToggleFavoriteReport';

const COLUMNS = () => [
  Columns.ReportFavorite,
  'reportType',
  'reportName',
  'reportStatus',
  'userEmail',
  'createdAt',
  Columns.ReportSaveTemplate,
  Columns.ReportOpen,
];

const ITEMS_PER_PAGE = 10;

const FavoriteReportsTab = () => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const userEmail = useSelector((state: RootState) => state.auth.email);
  const grantReportPermissionsMutation = useGrantReportPermissions();
  const toggleFavoriteReportMutation = useToggleFavoriteReport();

  const [reportsTableOffset, setReportsTableOffset] = useState<number>(0);

  // Fetch reports
  const { data: reports, isLoading } = useReports(
    {
      projectId,
      onlyFavorites: true,
      maxRecords: 10,
      offset: reportsTableOffset,
    },
    // minimal cache time because we want this query to be refreshed when changing pages
    { cacheTime: 1 }
  );

  const tableData = useMemo(
    () =>
      reports?.map((report) => ({
        reportType: report.type,
        reportName: report.name,
        reportStatus: report.status,
        userEmail: report.userEmail,
        createdAt: new Date(report.createdAt),
        favorite: report.favorite,
        onReportFavoriteClick: async (
          setButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
          isFavoriteStarFilled: boolean,
          setIsFavoriteStarFilled: React.Dispatch<React.SetStateAction<boolean>>
        ) => {
          setButtonLoading(true);

          await toggleFavoriteReportMutation.mutateAsync({
            projectId,
            reportId: report.id,
            favorite: !isFavoriteStarFilled,
          });

          setIsFavoriteStarFilled(!isFavoriteStarFilled);
          setButtonLoading(false);
        },
        onReportOpenClick: report?.url
          ? (setButtonLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
              if (userEmail === report.userEmail) {
                window.open(report.url);
                return;
              }

              setButtonLoading(true);

              // Grant permissions if a given user is trying to open a report from another user
              grantReportPermissionsMutation.mutate(
                { projectId, reportId: report.id },
                {
                  onSuccess: () => {
                    window.open(report.url);
                  },
                  onError: () => {},
                  onSettled: () => {
                    setButtonLoading(false);
                    logEvent({
                      component: 'Reports',
                      action: `Open report`,
                      category: 'user_actions',
                    });
                  },
                }
              );
            }
          : undefined,
      })),
    [reports, toggleFavoriteReportMutation, projectId, userEmail, grantReportPermissionsMutation]
  );

  const onOffsetChange = useCallback<Exclude<TableWithPaginationProps['onPageUpdate'], undefined>>(
    ({ pageIndex }) => {
      if (pageIndex !== reportsTableOffset) {
        setReportsTableOffset(pageIndex * ITEMS_PER_PAGE);

        logEvent({
          component: 'Reports table',
          action: 'Change page',
          category: 'user_actions',
          parameters: { page: `Page ${pageIndex + 1}` },
        });
      }
    },
    [reportsTableOffset]
  );

  return (
    <Table
      columns={COLUMNS()}
      tableData={tableData || []}
      rowsPerPage={ITEMS_PER_PAGE}
      rowsTotal={reports?.[0]?.totalNumberOfReports ?? ITEMS_PER_PAGE}
      pageIndex={reportsTableOffset / ITEMS_PER_PAGE}
      onPageUpdate={onOffsetChange}
      emptyStateProps={{
        icon: 'Search',
        text: `No favorites found`,
        supportingText: '',
      }}
      loading={
        isLoading
          ? {
              messages: ['Fetching reports', 'Getting everything ready'],
            }
          : undefined
      }
    />
  );
};

export default FavoriteReportsTab;
