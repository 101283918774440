import { QueryFunction, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getConceptMetrics = async (
  requestParams: SDK.GetConceptsMetricsRequest & { refreshCache?: boolean }
): Promise<SDK.GetConceptsMetricsResponse> => {
  const opts = {} as provider.SupportedHeaders;
  if (requestParams.refreshCache) {
    opts['cache-control'] = 'max-age=0';
  }
  const res = await provider.getJson<object, SDK.GetConceptsMetricsResponse>(
    `${SDK.BASE_URL()}/concepts/metrics?${SDK.convertRequestToQueryParams(requestParams)}`,
    undefined,
    opts
  );
  return res;
};

const KEY = 'concept-metrics';
type Key = typeof KEY;

type Result = SDK.GetConceptsMetricsResponse;
type QueryKey = [Key, SDK.GetConceptsMetricsRequest];

type QueryOptions<R = Result> = UseQueryOptions<Result, unknown, R, QueryKey>;
type QueryResult<R = Result> = UseQueryResult<R>;

const queryFn: QueryFunction<Result, QueryKey> = ({ queryKey: [, requestParams] }) => getConceptMetrics(requestParams);

// This is exported so we can use useQueries
export function getConceptMetricsQueryOptions<R = Result>(
  requestParams: SDK.GetConceptsMetricsRequest,
  options?: QueryOptions<R>
): QueryOptions<R> {
  return {
    queryKey: [KEY, requestParams],
    queryFn,
    ...options,
  };
}

export default function useConceptMetrics<R = Result>(
  requestParams: SDK.GetConceptsMetricsRequest & { refreshCache?: boolean },
  options?: QueryOptions<R>
): QueryResult<R> {
  return useQuery(getConceptMetricsQueryOptions(requestParams, options));
}
