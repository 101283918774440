import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getMetricsPerformance = async (requestParams: SDK.GetMetricsPerformanceRequest) => {
  const res = await provider.getJson<object, SDK.GetMetricsPerformanceResponse>(
    `${SDK.BASE_URL()}/metrics/performance?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useMetricsPerformance(
  requestParams: SDK.GetMetricsPerformanceRequest,
  options?: UseQueryOptions<SDK.GetMetricsPerformanceResponse>
) {
  return useQuery(['metrics-performance', requestParams], () => getMetricsPerformance(requestParams), options);
}
