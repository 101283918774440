import { useRef, useState } from 'react';
import { useClickAway, useMeasure, useUpdateEffect } from 'react-use';
import Tooltip from '../Tooltip/Tooltip';
import '../common.module.css';
import * as Styled from './styles';
import { computeNumberOfTabsToRender } from './utils';
import Button from '../Button/Button';
import DropDownMenu, { DropDownMenuOption } from '../DropDownMenu/DropDownMenu';

interface Tab {
  id?: string;
  label: string;
  subLabel?: string;
  tooltipContent?: JSX.Element | string;
  trailingBadge?: JSX.Element;
  leadingBadge?: JSX.Element;
  maxWidthInPercentage?: number;
  disabled?: boolean;
}

type TabsVariant = 'underline' | 'gray-button' | 'primary-light';
export type TabsWidthVariant = 'short' | 'long';

interface TabsProps {
  tabLabels: Tab[];
  variant?: TabsVariant;
  widthVariant?: TabsWidthVariant;
  children?: JSX.Element;
  onTabChange?: (newTab: Tab, index: number) => void;
  defaultSelectedTab?: Tab;
  disableNavigation?: boolean;
  withOverflowMenu?: boolean;
}

const getDefaultSelectedIndex = ({
  tabLabels,
  defaultSelectedTab,
  selectedIndex,
}: {
  tabLabels: Tab[];
  defaultSelectedTab?: Tab;
  selectedIndex: number;
}): number => {
  if (selectedIndex) {
    return selectedIndex;
  }

  if (defaultSelectedTab) {
    const index = tabLabels.findIndex(
      (tab) => tab.label === defaultSelectedTab.label
    );

    return index !== -1 ? index : 0;
  }

  return 0;
};

const Container: React.FC<TabsProps> = ({
  tabLabels,
  variant = 'underline',
  widthVariant = 'short',
  children,
  onTabChange,
  defaultSelectedTab,
  disableNavigation = false,
  withOverflowMenu = false,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tabs, setTabs] = useState(tabLabels);
  const [selectedIndex, setSelectedIndex] = useState(
    getDefaultSelectedIndex({
      tabLabels: tabs,
      defaultSelectedTab,
      selectedIndex: 0,
    })
  );
  const [tabContainerRef, { width: tabsContainerWidth }] =
    useMeasure<HTMLDivElement>();
  const [numTabsToRender, setNumTabsToRender] = useState(
    tabsContainerWidth === 0
      ? tabs?.length
      : computeNumberOfTabsToRender(
          tabs.map(({ label }) => label),
          tabsContainerWidth
        )
  );
  const dropdownMenuWrapperRef = useRef(null);

  useUpdateEffect(() => {
    if (tabsContainerWidth === 0) return;
    setNumTabsToRender(
      computeNumberOfTabsToRender(
        tabs.map(({ label }) => label),
        tabsContainerWidth
      )
    );
  }, [tabsContainerWidth, tabs]);

  useUpdateEffect(() => {
    setSelectedIndex((selectedIndex) =>
      getDefaultSelectedIndex({
        tabLabels: tabs,
        defaultSelectedTab,
        selectedIndex,
      })
    );
  }, [defaultSelectedTab, tabs]);

  useUpdateEffect(() => {
    setTabs(tabLabels);
  }, [tabLabels]);

  useClickAway(dropdownMenuWrapperRef, () => {
    setIsTooltipOpen(false);
  });

  const handleTabChange = (index: number): void => {
    // Don't do anything if the tab is already selected
    if (selectedIndex === index) return;
    const newTab = tabs[index];
    let newSelectedIndex = index;
    // If the selected tab is not visible, don't show any selection.
    if (index >= numTabsToRender) {
      newSelectedIndex = -1;
    }
    // Update the selected index
    setSelectedIndex(newSelectedIndex);
    // Call the (external) callback
    onTabChange?.(newTab, index);
  };

  const hasOverflow = numTabsToRender < tabs.length;
  const tooltipDropdownOptions: DropDownMenuOption[] = tabs
    .slice(numTabsToRender)
    .map((tab, i) => ({
      label: tab.label,
      subLabel: tab.subLabel,
      onClick: () => handleTabChange(i + numTabsToRender),
      selected: i + numTabsToRender === selectedIndex,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const tabLabelsToRender = withOverflowMenu
    ? tabs.slice(0, numTabsToRender)
    : tabs;

  return (
    <Styled.Root
      className={`${variant} ${widthVariant}`}
      data-test="tabs-container"
      ref={tabContainerRef}
    >
      <Styled.Buttons
        className={`tabsButtons ${!children ? 'onlyButtons' : ''}`}
      >
        {tabLabelsToRender.map(
          (
            {
              disabled,
              tooltipContent,
              label,
              trailingBadge,
              leadingBadge,
              id,
              maxWidthInPercentage,
            },
            index
          ) => (
            <Tooltip
              key={`tab-tooltip-${id ?? index}`}
              content={tooltipContent ? tooltipContent : null}
              placement="top"
            >
              <Styled.Button
                type="button"
                className={`${selectedIndex === index ? 'selected' : ''}`}
                onClick={disabled ? undefined : () => handleTabChange(index)}
                disabled={disableNavigation}
                $disabled={disabled}
                $leadingBadge={!!leadingBadge}
                $width={
                  widthVariant === 'long'
                    ? `${maxWidthInPercentage ?? 100 / tabs.length}%`
                    : ''
                }
              >
                {leadingBadge ? leadingBadge : null}
                {label ? label : null}
                {trailingBadge ? trailingBadge : null}
              </Styled.Button>
            </Tooltip>
          )
        )}
        {withOverflowMenu && hasOverflow ? (
          <Styled.TabsTooltip
            content={
              <div ref={dropdownMenuWrapperRef}>
                <DropDownMenu
                  options={tooltipDropdownOptions}
                  includeContainerCard={false}
                />
              </div>
            }
            placement="bottom"
            open={isTooltipOpen}
          >
            <Button
              variant="text"
              centerIcon={{ name: 'MoreHorizontal' }}
              onClick={() => setIsTooltipOpen((prev) => !prev)}
            />
          </Styled.TabsTooltip>
        ) : null}
      </Styled.Buttons>
      {children ? children : null}
    </Styled.Root>
  );
};

export default Container;
export type { Tab, TabsProps };
