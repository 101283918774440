import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getCampaigns = async (requestParams: SDK.GetCampaignsRequest) =>
  provider.getJson<object, SDK.GetCampaignsResponse>(
    `${SDK.BASE_URL()}/campaigns?${SDK.convertRequestToQueryParams(requestParams)}`
  );

export default function useGetCampaigns<R = SDK.GetCampaignsResponse>(
  requestParams: SDK.GetCampaignsRequest,
  options?: UseQueryOptions<SDK.GetCampaignsResponse, unknown, R>
) {
  return useQuery(['get-campaigns', requestParams], () => getCampaigns(requestParams), options);
}
