import { CountryIconProps } from './types';

const SX = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3700)">
      <path
        d="M10.9565 13.0426C10.9565 13.0426 3.52168 3.51219 3.51465 3.51388C5.68618 1.3423 8.68623 -0.000854492 12 -0.000854492C18.6273 -0.000854492 24 5.37177 24 11.9991L10.9565 13.0426Z"
        fill="#D80027"
      />
      <path
        d="M10.9565 11.9995C10.9565 11.9995 3.52168 20.4865 3.51465 20.4848C5.68618 22.6563 8.68623 23.9995 12 23.9995C18.6273 23.9995 24 18.6269 24 11.9995H10.9565Z"
        fill="#0052B4"
      />
      <path
        d="M3.51471 3.51465C-1.17157 8.20093 -1.17157 15.7989 3.51471 20.4853C5.45126 18.5487 7.31375 16.6862 12 12L3.51471 3.51465Z"
        fill="#F0F0F0"
      />
      <path
        d="M1.3157 11.9995C1.30914 12.0857 1.30469 12.1725 1.30469 12.2603C1.30469 14.1333 2.82306 15.6516 4.696 15.6516C6.56894 15.6516 8.08731 14.1333 8.08731 12.2603C8.08731 12.1725 8.08286 12.0856 8.0763 11.9995H1.3157Z"
        fill="#FFDA44"
      />
      <path
        d="M4.69583 10.4338C5.27213 10.4338 5.73931 9.96659 5.73931 9.39029C5.73931 8.81399 5.27213 8.3468 4.69583 8.3468C4.11953 8.3468 3.65234 8.81399 3.65234 9.39029C3.65234 9.96659 4.11953 10.4338 4.69583 10.4338Z"
        fill="#FFDA44"
      />
      <path
        d="M2.34766 9.13025V12.5216C2.34766 14.3187 4.69548 14.8694 4.69548 14.8694C4.69548 14.8694 7.04331 14.3187 7.04331 12.5216V9.13025H2.34766Z"
        fill="#D80027"
      />
      <path
        d="M4.69712 13.7777C4.26155 13.6209 3.3916 13.2203 3.3916 12.5219V10.1741H6.00033V12.5219C6.00033 13.2226 5.13048 13.6222 4.69712 13.7777Z"
        fill="#338AF3"
      />
      <path
        d="M5.21743 11.4777V10.9559L4.69571 10.6951L4.17395 10.9559V11.4777L3.91309 11.7386V12.782H5.47834V11.7386L5.21743 11.4777Z"
        fill="#F3F3F3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3700">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SX;
