/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import { Colors, Icons } from '@replai-platform/ui-components';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';
import ColumnHeader from '../generateHeader';

export type AssetTypeAccessorType = {
  assetType: SDK.AssetType | null;
};

function getAssetTypeColumn<D extends object = object>({
  columnId,
  accessor,
}: RequiredGeneratorParams<D, AssetTypeAccessorType>): GeneratedColumn<D, AssetTypeAccessorType> {
  return {
    id: columnId,
    accessor,
    disableSortBy: true,
    width: 50,
    sticky: 'left',
    Cell: (data: CellData<D, AssetTypeAccessorType>) => {
      const { assetType } = data.cell.value;

      let Icon = Icons.getBaseIcon('Video');
      switch (assetType) {
        case SDK.AssetType.Video:
          Icon = Icons.getBaseIcon('Video');
          break;
        case SDK.AssetType.Image:
          Icon = Icons.getBaseIcon('Image');
          break;
        case SDK.AssetType.Playable:
          Icon = Icons.getBaseIcon('PuzzlePiece');
          break;
        default:
          return null;
      }

      return (
        <div style={{ width: 'fit-content', display: 'block', margin: 'auto' }}>
          <Icon dimension={20} color={Colors.Primary[500]} />
        </div>
      );
    },
    Header: () => <ColumnHeader title="Type" />,
  };
}

export default getAssetTypeColumn;
