// Format number to have B/M/K notation, and limit to 2 decimal places
export const formatMetricValue = (metricValue: number): string => {
  const abs = Math.abs(metricValue);
  const sign = metricValue < 0 ? '-' : '';
  if (abs >= 1000000000) {
    const k = abs / 1000000000;
    const decimalPart = k % 1;
    if (decimalPart >= 0.01) {
      return `${sign}${k.toFixed(1)}B`;
    }
    return `${sign}${Math.round(k)}B`;
  }
  if (abs >= 1000000) {
    const k = abs / 1000000;
    const decimalPart = k % 1;
    if (decimalPart >= 0.01) {
      return `${sign}${k.toFixed(1)}M`;
    }
    return `${sign}${Math.round(k)}M`;
  }
  if (abs >= 1000) {
    const k = abs / 1000;
    const decimalPart = k % 1;
    if (decimalPart >= 0.01) {
      return `${sign}${k.toFixed(1)}K`;
    }
    return `${sign}${Math.round(k)}K`;
  }
  return (Math.round((metricValue + Number.EPSILON) * 10) / 10).toString();
};
