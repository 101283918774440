import styled, { css } from 'styled-components';

export const BadgeContainer = styled.div<{
  $noBorderRadius?: boolean;
  $backgroundColor?: string;
  $color?: string;
  $style?: string;
}>`
  width: fit-content;
  font-weight: 500;
  background-color: ${(props) => props.$backgroundColor};
  color: ${(props) => props.$color};

  &&&& {
    border-radius: ${(props) => (props.$noBorderRadius ? '3px' : '1rem')};
    ${(props) =>
      css`
        ${props.$style}
      `}
  }

  &.sm {
    padding: 2px 8px;
    font-size: 12px;
  }

  &.md {
    padding: 2px 10px;
    font-size: 14px;
  }

  &.lg {
    padding: 4px 12px;
    font-size: 14px;
  }

  &.leading.sm {
    padding: 2px 8px 2px 6px;
  }

  &.leading.md {
    padding: 2px 10px 2px 8px;
  }

  &.leading.lg {
    padding: 4px 12px 4px 10px;
  }

  &.trailing.sm {
    padding: 2px 6px 2px 8px;
  }

  &.trailing.md {
    padding: 2px 8px 2px 10px;
  }

  &.trailing.lg {
    padding: 4px 10px 4px 12px;
  }

  &.country-and-avatar.sm {
    padding: 2px 8px 2px 3px;
  }

  &.country-and-avatar.md {
    padding: 2px 10px 2px 4px;
  }

  &.country-and-avatar.lg {
    padding: 4px 12px 4px 6px;
  }

  &.leading,
  &.trailing,
  &.country-and-avatar {
    display: grid;
    grid-column-gap: 4px;
    grid-template-columns: repeat(2, min-content);
    align-items: center;
  }

  &.noChildren {
    grid-template-columns: min-content;
  }

  &.noChildren.sm {
    padding: 4px;
  }

  &.noChildren.md {
    padding: 2px;
  }

  &.noChildren.lg {
    padding: 8px;
  }
`;

export const Avatar = styled.img`
  width: 16px;
  height: 16px;
  object-fit: cover;
  border-radius: 50%;
`;
