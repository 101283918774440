import { IntegrationIconProps } from './types';

const JbUpsource = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1334_1627)">
      <path
        d="M27.6577 10.9714L4.3891 12.2971L4.25195 3.38286L25.2348 0.0914307L27.6577 10.9714Z"
        fill="url(#paint0_linear_1334_1627)"
      />
      <path
        d="M9.28039 19.7486L16.7775 10.24L8.64039 4.66285C7.22325 3.38285 5.07468 2.83428 2.88039 3.74857C1.78325 4.20571 0.914679 5.07428 0.457536 6.17142C-0.502464 8.45714 0.0918217 10.6971 1.55468 12.0686L9.28039 19.7486Z"
        fill="url(#paint1_linear_1334_1627)"
      />
      <path
        d="M9.2793 19.7486L20.7993 31.1314L27.9764 17.7371L16.7764 10.24L9.2793 19.7486Z"
        fill="url(#paint2_linear_1334_1627)"
      />
      <path
        d="M24.6408 16.7314L5.48646 15.8171C4.75504 15.68 3.93218 15.7257 3.06361 16.0914C2.24075 16.4571 1.60075 17.0514 1.28075 17.8743C0.320751 20.1143 1.46361 22.2629 3.33789 22.9943L20.4807 31.0857C20.7093 31.2229 21.8522 31.5429 21.8522 31.5429C22.7665 31.8171 23.7722 31.9543 24.6865 31.9543C25.8293 31.9543 27.1093 31.6343 28.3893 30.9943C29.5779 30.4 30.3093 29.44 30.9493 28.2514C31.7265 26.88 31.955 25.6 31.955 24.32C31.9093 20.3429 28.6179 17.0057 24.6408 16.7314Z"
        fill="url(#paint3_linear_1334_1627)"
      />
      <path
        d="M23.8169 0.502901C23.4969 0.640043 23.1769 0.8229 22.9026 1.00576L12.2969 9.00576L17.554 16.6858L29.074 10.24C29.9426 9.82861 30.6283 9.09719 31.0397 8.27433C33.5997 3.33719 28.8454 -1.59996 23.8169 0.502901Z"
        fill="url(#paint4_linear_1334_1627)"
      />
      <path
        d="M6.62881 22.7657L17.5545 16.6857L12.2974 9.00574L2.24023 16.5486L6.62881 22.7657Z"
        fill="#905CFB"
      />
      <path
        d="M9.32646 25.7829V16L5.48646 15.8171C4.75504 15.68 3.93218 15.7257 3.06361 16.0914C2.24075 16.4571 1.60075 17.0514 1.28075 17.8743C0.320751 20.1143 1.46361 22.2629 3.33789 22.9943H3.38361L9.32646 25.7829Z"
        fill="url(#paint5_linear_1334_1627)"
      />
      <path d="M6.125 6.12567H25.8736V25.8742H6.125V6.12567Z" fill="black" />
      <path
        d="M8 13.44V8.68567H9.82857V13.3942C9.82857 14.7657 10.5143 15.4514 11.6114 15.4514C12.7086 15.4514 13.3943 14.7657 13.3943 13.44V8.68567H15.2229V13.3485C15.2229 15.8628 13.8057 17.0971 11.5657 17.0971C9.37143 17.1428 8 15.8628 8 13.44Z"
        fill="white"
      />
      <path
        d="M16.1836 8.68567H19.5664C21.5322 8.68567 22.7207 9.87424 22.7207 11.5657V11.6114C22.7207 13.5314 21.2122 14.5371 19.3836 14.5371H18.0122V17.0057H16.1836V8.68567ZM19.475 12.8914C20.3893 12.8914 20.9379 12.3428 20.9379 11.6571V11.6114C20.9379 10.7885 20.3893 10.3771 19.475 10.3771H18.0579V12.8914H19.475Z"
        fill="white"
      />
      <path d="M8 22.1714H15.4057V23.4057H8V22.1714Z" fill="white" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1334_1627"
        x1="29.675"
        y1="5.03314"
        x2="8.00647"
        y2="8.28343"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8200" />
        <stop offset="0.973" stopColor="#905CFB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1627"
        x1="7.16611"
        y1="15.9095"
        x2="9.18576"
        y2="4.62628"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0CB0F2" />
        <stop offset="0.973" stopColor="#905CFB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_1627"
        x1="26.6667"
        y1="24.533"
        x2="12.3321"
        y2="17.5323"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0CB0F2" />
        <stop offset="0.973" stopColor="#905CFB" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1334_1627"
        x1="27.7393"
        y1="25.6265"
        x2="3.36166"
        y2="16.8626"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0CB0F2" />
        <stop offset="0.973" stopColor="#905CFB" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1334_1627"
        x1="29.3456"
        y1="2.71319"
        x2="8.46785"
        y2="19.5402"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8200" />
        <stop offset="0.973" stopColor="#905CFB" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1334_1627"
        x1="13.9198"
        y1="22.5111"
        x2="1.03618"
        y2="17.9323"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0CB0F2" />
        <stop offset="0.973" stopColor="#905CFB" />
      </linearGradient>
      <clipPath id="clip0_1334_1627">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default JbUpsource;
