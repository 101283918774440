import { TagBreakdownWithId } from '../../../utils/types';
import {
  ACCEPTABLE_TIMELINE_DURATION_SECONDS,
  ACCEPTABLE_TIMELINE_OFFSET_SECONDS,
  SegmentPositionDifference,
  TagDifference,
  type TagBreakdownWithDifference,
} from './types';

// Calculate the difference in position between the first segments of both tags
export const getSegmentPositionDifference = ({
  referenceTag,
  otherTag,
}: {
  referenceTag: TagBreakdownWithId;
  otherTag: TagBreakdownWithId;
}): SegmentPositionDifference | null => {
  // sorting timeline segments
  const sortedReferenceSegments = referenceTag.segments.sort(
    (segment1, segment2) => segment1.startSeconds - segment2.startSeconds
  );
  const sortedOtherSegments = otherTag.segments.sort(
    (segment1, segment2) => segment1.startSeconds - segment2.startSeconds
  );
  const firstReferenceSegment = sortedReferenceSegments[0];
  const firstOtherSegment = sortedOtherSegments[0];

  if (
    !firstReferenceSegment ||
    !firstOtherSegment ||
    Math.abs(firstReferenceSegment.startSeconds - firstOtherSegment.startSeconds) < ACCEPTABLE_TIMELINE_OFFSET_SECONDS
  ) {
    return null;
  }

  return firstReferenceSegment.startSeconds > firstOtherSegment.startSeconds
    ? SegmentPositionDifference.BACKWARD
    : SegmentPositionDifference.FORWARD;
};

// Calculate the difference in duration between the two tags. The difference is positive if the
// other tag is longer.
export const getSegmentDurationDifference = ({
  referenceTag,
  otherTag,
}: {
  referenceTag: TagBreakdownWithId;
  otherTag: TagBreakdownWithId;
}): number => {
  const totalReferenceDuration = referenceTag.segments.reduce(
    (totalDuration, segment) => totalDuration + (segment.endSeconds - segment.startSeconds),
    0
  );
  const totalOtherDuration = otherTag.segments.reduce(
    (totalDuration, segment) => totalDuration + (segment.endSeconds - segment.startSeconds),
    0
  );
  return totalOtherDuration - totalReferenceDuration;
};

export const sortSegments = (
  segments: {
    startSeconds: number;
    endSeconds: number;
  }[]
): {
  startSeconds: number;
  endSeconds: number;
}[] => segments.sort((segment1, segment2) => segment1.startSeconds - segment2.startSeconds);

const areSegmentsDifferent = (referenceTag: TagBreakdownWithId, otherTag: TagBreakdownWithId) =>
  getSegmentPositionDifference({ referenceTag, otherTag }) !== null ||
  Math.abs(getSegmentDurationDifference({ referenceTag, otherTag })) > ACCEPTABLE_TIMELINE_DURATION_SECONDS;

const segmentsDifferenceReason = (
  referenceTag: TagBreakdownWithId,
  otherTag: TagBreakdownWithId
):
  | { different: false }
  | { different: true; reason: TagDifference.LENGTH; details: number }
  | { different: true; reason: TagDifference.POSITION; details: SegmentPositionDifference } => {
  const segmentPosition = getSegmentPositionDifference({ referenceTag, otherTag });
  const segmentDuration = Math.abs(getSegmentDurationDifference({ referenceTag, otherTag }));

  if (segmentPosition === null && segmentDuration <= ACCEPTABLE_TIMELINE_DURATION_SECONDS) {
    return { different: false };
  }
  if (segmentPosition !== null) {
    return { different: true, reason: TagDifference.POSITION, details: segmentPosition };
  }
  return { different: true, reason: TagDifference.LENGTH, details: segmentDuration };
};

/**
 * Returns the common tags between each video and the pivot. For a tag
 * to be common, it mush share the same segments.
 */
export const getCommonTags = ({
  pivotVideoTags,
  otherVideosTags,
}: {
  pivotVideoTags: TagBreakdownWithId[];
  otherVideosTags: TagBreakdownWithId[][];
}): TagBreakdownWithId[][] => {
  const commonTags: TagBreakdownWithId[][] = [[]];

  for (const otherVideoTags of otherVideosTags) {
    const videoCommonTags: TagBreakdownWithId[] = [];

    for (const pivotTag of pivotVideoTags) {
      const common = otherVideoTags
        .filter((tag) => tag.id === pivotTag.id)
        .map((tag) => {
          const results = segmentsDifferenceReason(tag, pivotTag);
          if (!results.different) return tag;
          return { ...tag, difference: results.reason, differenceDetails: results.details };
        });
      videoCommonTags.push(...common);
    }
    commonTags.push(videoCommonTags);
  }

  commonTags[0] = pivotVideoTags.filter((tag) => commonTags.flat().some((t) => t.id === tag.id));
  return commonTags;
};

/**
 * Returns the different tags between each video and the pivot. For a tag
 * to be different, it must not be defined as a common tag (share the same id).
 */
export const getDifferentTags = ({
  pivotVideoTags,
  commonTags,
  otherVideosTags,
}: {
  pivotVideoTags: TagBreakdownWithId[];
  commonTags: TagBreakdownWithId[][];
  otherVideosTags: TagBreakdownWithId[][];
}): TagBreakdownWithId[][] => {
  const differentTags: TagBreakdownWithId[][] = [[]];

  for (const [index, otherVideoTags] of otherVideosTags.entries()) {
    const videoDifferentTags = otherVideoTags.filter(
      (t) => !commonTags[index + 1].find((common) => common.id === t.id)
    );
    differentTags.push(videoDifferentTags);
  }

  // Calculates all tags that are common to all videos and removes them
  // from the pivot tags for differences
  const commonToAll = commonTags[0].filter(
    (tag) =>
      commonTags
        .slice(1)
        .flat()
        .filter((t) => t.id === tag.id).length ===
      commonTags.length - 1
  );
  differentTags[0] = pivotVideoTags.filter((tag) => !commonToAll.find((t) => t.id === tag.id));
  return differentTags;
};

/**
 * Returns the unique tags of the videos, meaning that if a tag is present at least
 * one video, it will appear once in the resulting array.
 *
 * @param pivotTags Tags of the pivot video
 * @param videosTags Tags of all the other videos
 * @returns An array of unique tags that are present in at least one of the videos
 */
export const getUniqueTags = (
  pivotTags: TagBreakdownWithId[],
  videosTags: { tags: TagBreakdownWithId[]; videoDuration: number }[]
): TagBreakdownWithId[] => {
  // Get unique tag ids (note that we ignore the segments)
  const tags = [...pivotTags, ...videosTags.flatMap((t) => t.tags)];
  const uniqueTags = tags.filter((tag, index, self) => self.findIndex((t) => t.id === tag.id) === index);
  return uniqueTags;
};

export const isTagInPivotVideo = (tagsInPivot: TagBreakdownWithId[], tag: TagBreakdownWithId): boolean =>
  tagsInPivot.some((t) => t.id === tag.id);

export const isTagValueDifferentFromPivot = (tagsInPivot: TagBreakdownWithId[], tag: TagBreakdownWithId): boolean =>
  tagsInPivot.some((t) => t.type === tag.type && t.value !== tag.value);

export const isTagCategoryPresentInVideo = (
  videosTags: TagBreakdownWithId[],
  tagCategory: string,
  tagValue?: string | null
) =>
  videosTags.some(
    (videoTag) => (videoTag.type === tagCategory && !tagValue) || (tagValue && videoTag.value === tagValue)
  );

const onTagExistsInVideo = (
  pivotTags: TagBreakdownWithId[],
  uniqueTag: TagBreakdownWithId,
  uniqueTagInVideo: TagBreakdownWithId,
  processedTagsForVideo: TagBreakdownWithId[],
  commonTagsForVideo: TagBreakdownWithId[],
  isTagCategoryPresent: boolean
) => {
  const result: TagBreakdownWithDifference[] = [];
  const isTagCategoryPresentInPivot = isTagCategoryPresentInVideo(pivotTags, uniqueTag.type);
  const tagInPivotVideo = pivotTags.find((tag) => tag.id === uniqueTagInVideo.id);

  // Exit early if the tag was already added to the timeline
  const isTagAlreadyInTimeline = !!processedTagsForVideo.find((tag) => tag.id === uniqueTag.id);
  if (isTagAlreadyInTimeline) return result;

  if (!tagInPivotVideo) {
    const tagSharesSegments = pivotTags.find(
      (tag) => tag.type === uniqueTagInVideo.type && !areSegmentsDifferent(uniqueTagInVideo, tag)
    );
    const isTagAlreadyProcessedInVideo =
      tagSharesSegments && !!processedTagsForVideo.find((tag) => tag.id === tagSharesSegments.id);
    const isTagShareSegmentsInCommon =
      !!tagSharesSegments && !!commonTagsForVideo.find((tag) => tag.id === tagSharesSegments.id);

    // If the tag does not exist in pivot, but there is a tag from the same category, both in the
    // video and in the pivot (meaning tag category is present in both videos, but value changed)
    // and the tag that shares the segments was not present in the common tags
    if (
      isTagCategoryPresentInPivot &&
      isTagCategoryPresent &&
      !!tagSharesSegments &&
      !isTagAlreadyProcessedInVideo &&
      !isTagShareSegmentsInCommon
    ) {
      result.push({
        ...uniqueTagInVideo,
        difference: TagDifference.VALUE_CHANGED_NEW,
      });
    }
    result.push({
      ...uniqueTagInVideo,
      difference: TagDifference.ADDED,
    });
  }

  return result;
};

const onTagDoesNotExistInVideo = (
  pivotTags: TagBreakdownWithId[],
  uniqueTag: TagBreakdownWithId,
  videoTag: {
    tags: TagBreakdownWithId[];
    videoDuration: number;
  },
  processedTagsForVideo: TagBreakdownWithId[],
  isTagCategoryPresent: boolean,
  isUniqueTagPresentInVideo: boolean
) => {
  const result: TagBreakdownWithDifference[] = [];

  // If the tag is not present as a difference from the pivot but is
  // present in the total tags of the video, it means that the tag
  // is shared with the pivot video and should not be shown in the
  // differences
  if (isUniqueTagPresentInVideo) return result;

  // If the tag is not present in the pivot video and in the current video,
  // return immediatly since it's not necessary to show tag differences
  const tagInPivotVideo = pivotTags.find((tag) => tag.id === uniqueTag.id);
  if (!tagInPivotVideo) return result;

  // If the tag does not exist, add a placeholder object to the array
  if (!isTagCategoryPresent) {
    result.push({
      ...uniqueTag,
      // Add a fake segment to the tag, so the timeline row is not empty and shows differently
      segments: [{ startSeconds: 0, endSeconds: videoTag.videoDuration }],
      difference: TagDifference.NOT_PRESENT,
    });
    return result;
  }

  // For a tag category verifies if there is a tag of other value and with similar
  // segment positioning and duration that will replace the current value
  const isTagCategoryValuePresentInPivot = isTagCategoryPresentInVideo(pivotTags, uniqueTag.type, uniqueTag.value);
  const tagSharesSegments = videoTag.tags.find(
    (tag) => tag.type === uniqueTag.type && !areSegmentsDifferent(uniqueTag, tag)
  );
  const isTagAlreadyProcessedInVideo =
    tagSharesSegments && !!processedTagsForVideo.find((tag) => tag.id === tagSharesSegments.id);

  if (isTagCategoryValuePresentInPivot && tagSharesSegments && !isTagAlreadyProcessedInVideo) {
    result.push({
      ...tagSharesSegments,
      difference: TagDifference.VALUE_CHANGED_NEW,
    });
  } else if (isTagCategoryValuePresentInPivot && !isTagAlreadyProcessedInVideo) {
    result.push({
      ...uniqueTag,
      // Add a fake segment to the tag, so the timeline row is not empty shows differently
      segments: [{ startSeconds: 0, endSeconds: videoTag.videoDuration }],
      difference: TagDifference.NOT_PRESENT,
    });
  }

  return result;
};

export const getTimelines = ({
  pivot,
  videosTags,
  allTags,
  commonTags,
}: {
  pivot: { tags: TagBreakdownWithId[]; videoDuration: number };
  videosTags: { tags: TagBreakdownWithId[]; videoDuration: number }[];
  allTags: TagBreakdownWithId[][];
  commonTags: TagBreakdownWithId[][];
}): TagBreakdownWithDifference[][] => {
  const { tags: pivotTags, videoDuration: pivotDuration } = pivot;

  // Every tag should have an object in the resulting array (even if the tag is not present, we need
  // to show "removed" or a placeholder in the UI). The total number of different tags is the sum of
  // unique tags across all videos. Every timeline should have this amount of rows.
  const uniqueTags = getUniqueTags(pivotTags, videosTags);

  // Build tags for pivot video, adds the difference key with value "null" to the pivot video tags
  // (there's no need to actually figure out the difference, because these are tags from the pivot
  // video which have no special UI representation)
  const pivotTagsWithDifference: TagBreakdownWithDifference[] = pivotTags.map((tag) => ({
    ...tag,
    difference: null,
  }));

  // Order the pivot tags so that the tags that are present in the whole video without breaks appear at the end
  const pivotTimelineTagsFullVideo = pivotTagsWithDifference.filter(
    (tag) =>
      tag.segments.length === 1 &&
      tag.segments[0].startSeconds === 0 &&
      tag.segments[0].endSeconds === Math.trunc(pivotDuration)
  );
  const pivotTagsOrdered = [
    ...pivotTagsWithDifference.filter((tag) => !pivotTimelineTagsFullVideo.find((t) => t.id === tag.id)),
    ...pivotTimelineTagsFullVideo,
  ];

  // Take all the tags that are not in the pivot video, remove the segments (so the timeline row is
  // empty) and add the difference key (these are tags that are only present in other videos), sorted
  // by order of appearance
  const tagsNotInPivot: TagBreakdownWithDifference[] = uniqueTags
    .filter((tag) => !isTagInPivotVideo(pivotTags, tag))
    .sort((a, b) => (a.segments[0]?.startSeconds ?? 0) - (b.segments[0]?.startSeconds ?? 0))
    .map((tag) => ({
      ...tag,
      segments: [{ startSeconds: 0, endSeconds: pivotDuration }],
      difference: TagDifference.NOT_PRESENT,
    }));

  // Merge all tags together, since we always show a row even if the tag is not present in the pivot video
  const allTagsForPivot: TagBreakdownWithDifference[] = [...pivotTagsOrdered, ...tagsNotInPivot];

  // Build tags for other videos
  const res: TagBreakdownWithDifference[][] = [];
  videosTags.forEach((videoTag, index) => {
    const differentTagsInVideo: TagBreakdownWithDifference[] = [];

    uniqueTags.forEach((uniqueTag) => {
      // For each unique tag, check if the tag exists in this video
      const uniqueTagInVideo = videoTag.tags.find((t) => t.id === uniqueTag.id);
      const isUniqueTagPresentInVideo = (allTags[index] ?? []).findIndex((t) => t.id === uniqueTag.id) !== -1;
      const isTagCategoryPresent = isTagCategoryPresentInVideo(videoTag.tags, uniqueTag.type);

      // Check if the tag category is present in this video (we checked the specific tag value
      // above, but we want to differentiate between "not present" and "present but different
      // value")
      if (uniqueTagInVideo) {
        differentTagsInVideo.push(
          ...onTagExistsInVideo(
            pivotTags,
            uniqueTag,
            uniqueTagInVideo,
            differentTagsInVideo,
            commonTags[index + 1],
            isTagCategoryPresent
          )
        );
      } else {
        differentTagsInVideo.push(
          ...onTagDoesNotExistInVideo(
            pivotTags,
            uniqueTag,
            videoTag,
            differentTagsInVideo,
            isTagCategoryPresent,
            isUniqueTagPresentInVideo
          )
        );
      }
    });
    res.push(differentTagsInVideo);
  });

  return [allTagsForPivot, ...res];
};

/**
 * Adds empty spaces for each timeline if the tag is not present
 * in that video, which aligns all tags horizontally and improves
 * readability.
 *
 * @param timelines The timeline information for each video.
 * @returns The timeline information for each video with empty
 * spaces.
 */
export const organizeTags = (timelines: TagBreakdownWithId[][]): TagBreakdownWithId[][] => {
  // Builds a new timeline for each video adding white space
  // if the video does not contain a tag, to improve readability
  const pivotTags = timelines[0];
  const newTimelines = timelines.slice(1).map((timeline) => {
    const newTimeline: TagBreakdownWithId[] = [];
    for (const tag of pivotTags) {
      const tagInTimeline = timeline.find((t) => tag.id === t.id);
      if (tagInTimeline) {
        newTimeline.push(tagInTimeline);
      } else {
        newTimeline.push({
          id: '',
          type: tag.type,
          originalType: tag.type,
          value: '',
          segments: [],
          difference: TagDifference.NO_SHOW,
        });
      }
    }
    return newTimeline;
  });
  return [pivotTags, ...newTimelines];
};
