import ScoreText from './ScoreText';
import { TierBadgeProps } from './types';

const Tier4 = ({ score, dimension }: TierBadgeProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimension}
    height={dimension}
    viewBox="0 0 64 64"
    fill="none"
  >
    <path
      d="M27.9996 2.30988C30.475 0.880542 33.525 0.880542 36.0004 2.30988L45.856 8.0007L55.7122 13.6905C58.1878 15.1196 59.7127 17.7609 59.7126 20.6193L59.712 32L59.7126 43.3807C59.7127 46.2391 58.1878 48.8804 55.7122 50.3095L45.856 55.9993L36.0004 61.6901C33.525 63.1195 30.475 63.1195 27.9996 61.6901L18.144 55.9993L8.28777 50.3095C5.81224 48.8804 4.28728 46.2391 4.28742 43.3807L4.288 32L4.28742 20.6193C4.28728 17.7609 5.81224 15.1196 8.28777 13.6905L18.144 8.0007L27.9996 2.30988Z"
      fill="white"
    />
    <path
      d="M27.9996 6.30988C30.475 4.88054 33.525 4.88054 36.0004 6.30988L44.124 11.0006L52.2481 15.6905C54.7237 17.1196 56.2486 19.7609 56.2485 22.6193L56.248 32L56.2485 41.3807C56.2486 44.2391 54.7237 46.8804 52.2481 48.3095L44.124 52.9994L36.0004 57.6901C33.525 59.1195 30.475 59.1195 27.9996 57.6901L19.876 52.9994L11.7519 48.3095C9.27634 46.8804 7.75138 44.2391 7.75152 41.3807L7.752 32L7.75152 22.6193C7.75138 19.7609 9.27634 17.1196 11.7519 15.6905L19.876 11.0006L27.9996 6.30988Z"
      fill="#2E90FA"
    />
    <g opacity="0.25" clipPath="url(#clip0_5457_188102)">
      <path
        d="M28.1909 41.2034C28.3977 41.0654 28.673 41.2243 28.6568 41.4724L28.4796 44.1919C28.4732 44.2903 28.5156 44.3856 28.593 44.4468L30.7323 46.1373C30.9273 46.2914 30.8611 46.6023 30.6201 46.6635L27.9793 47.3339C27.8836 47.3582 27.806 47.4281 27.7717 47.5208L26.828 50.0766C26.7418 50.3098 26.4256 50.343 26.2928 50.1328L24.838 47.828C24.7853 47.7445 24.6949 47.6922 24.5962 47.6884L21.8728 47.5808C21.6243 47.571 21.495 47.2806 21.6539 47.0893L23.3954 44.9941C23.4586 44.9181 23.4803 44.8159 23.4534 44.7209L22.7137 42.0987C22.6462 41.8594 22.8822 41.6466 23.1132 41.7384L25.6469 42.7459C25.7386 42.7824 25.8423 42.7714 25.9244 42.7166L28.1909 41.2034Z"
        fill="white"
      />
    </g>
    <g opacity="0.25" clipPath="url(#clip1_5457_188102)">
      <path
        d="M36.2017 41.2034C35.9949 41.0654 35.7196 41.2243 35.7358 41.4724L35.913 44.1919C35.9194 44.2903 35.877 44.3856 35.7996 44.4468L33.6603 46.1373C33.4652 46.2914 33.5315 46.6023 33.7725 46.6635L36.4132 47.3339C36.509 47.3582 36.5866 47.4281 36.6208 47.5208L37.5646 50.0766C37.6508 50.3098 37.967 50.343 38.0997 50.1328L39.5545 47.828C39.6072 47.7445 39.6977 47.6922 39.7964 47.6884L42.5198 47.5808C42.7682 47.571 42.8976 47.2806 42.7387 47.0893L40.9972 44.9941C40.934 44.9181 40.9123 44.8159 40.9391 44.7209L41.6789 42.0987C41.7464 41.8594 41.5103 41.6466 41.2793 41.7384L38.7456 42.7459C38.654 42.7824 38.5503 42.7714 38.4682 42.7166L36.2017 41.2034Z"
        fill="white"
      />
    </g>
    <g opacity="0.25">
      <path
        d="M31.9273 46.741C32.0373 46.5181 32.3553 46.5181 32.4653 46.741L33.6716 49.1848C33.7152 49.2732 33.7996 49.3346 33.8972 49.3488L36.5952 49.7432C36.8411 49.7791 36.9392 50.0815 36.7611 50.2549L34.8093 52.1559C34.7386 52.2249 34.7063 52.3242 34.723 52.4216L35.1835 55.1068C35.2256 55.3519 34.9683 55.5388 34.7482 55.4231L32.3359 54.1545C32.2485 54.1085 32.1441 54.1085 32.0567 54.1545L29.6444 55.4231C29.4243 55.5388 29.167 55.3519 29.2091 55.1068L29.6696 52.4216C29.6863 52.3242 29.654 52.2249 29.5833 52.1559L27.6315 50.2549C27.4534 50.0815 27.5514 49.7791 27.7974 49.7432L30.4954 49.3488C30.593 49.3346 30.6774 49.2732 30.721 49.1848L31.9273 46.741Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter0_dd_5457_188102)">
      <path
        d="M43.624 11.8666L43.624 11.8667L51.7482 16.5566C53.9143 17.807 55.2486 20.1182 55.2485 22.6193L55.248 31.9999V32.0001L55.2485 41.3807C55.2486 43.8818 53.9143 46.193 51.7482 47.4434L43.624 52.1333L43.624 52.1334L35.5003 56.8241C33.3343 58.0748 30.6657 58.0748 28.4997 56.8241L20.376 52.1334L20.376 52.1333L12.2518 47.4434C10.0857 46.193 8.7514 43.8818 8.75152 41.3807L8.752 32.0001V31.9999L8.75152 22.6193C8.7514 20.1182 10.0857 17.807 12.2518 16.5566L20.376 11.8667L20.376 11.8666L28.4997 7.17588C30.6657 5.92521 33.3343 5.92521 35.5003 7.17588L43.624 11.8666Z"
        stroke="#1570EF"
        strokeWidth="2"
      />
    </g>

    <ScoreText score={score} />

    <defs>
      <filter
        id="filter0_dd_5457_188102"
        x="4.75195"
        y="3.23779"
        width="54.4961"
        height="59.5244"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5457_188102"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5457_188102"
          result="effect2_dropShadow_5457_188102"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_5457_188102"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_5457_188102">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(24 37) rotate(30)"
        />
      </clipPath>
      <clipPath id="clip1_5457_188102">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="matrix(-0.866025 0.5 0.5 0.866025 40.3926 37)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Tier4;
