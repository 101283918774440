import * as SDK from '@replai-platform/sdk';
import * as provider from './provider';

/**
 * Get recommendations for project assets
 * @param {String} projectId ID of the project
 * @returns promise that fetches the data.
 */
export async function getAssetRecommendations(
  request: SDK.GetRecommendationsRequest
): Promise<SDK.GetRecommendationsResponse> {
  return provider.getJson(`${SDK.BASE_URL()}/recommendations/assets?${SDK.convertRequestToQueryParams(request)}`);
}

/**
 * Get recommendations for project concepts
 * @param {String} projectId ID of the project
 * @returns promise that fetches the data.
 */
export async function getConceptRecommendations(
  request: SDK.GetRecommendationsRequest & { refreshCache?: boolean }
): Promise<SDK.GetRecommendationsResponse> {
  const opts = {} as provider.SupportedHeaders;
  if (request.refreshCache) {
    opts['cache-control'] = 'max-age=0';
  }
  return provider.getJson(
    `${SDK.BASE_URL()}/recommendations/concepts?${SDK.convertRequestToQueryParams(request)}`,
    undefined,
    opts
  );
}

/**
 * Get insights for project
 * @param {String} projectId ID of the project
 * @returns promise that fetches the data.
 */
export async function getTagRecommendations(
  request: SDK.GetTagRecommendationsRequest
): Promise<SDK.GetTagRecommendationsResponse> {
  return provider.getJson(`${SDK.BASE_URL()}/recommendations/insights?${SDK.convertRequestToQueryParams(request)}`);
}
