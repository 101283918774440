import { CountryIconProps } from './types';

const MN = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3669)">
      <path
        d="M11.9994 24.0006C13.4672 24.0006 14.8733 23.7366 16.1733 23.2542L16.695 12.0006L16.1733 0.747001C14.8733 0.264657 13.4672 0.000610352 11.9994 0.000610352C10.5315 0.000610352 9.12541 0.264657 7.82548 0.747001L7.30371 12.0006L7.82543 23.2542C9.12541 23.7366 10.5315 24.0006 11.9994 24.0006Z"
        fill="#0052B4"
      />
      <path
        d="M0 12.0001C0 17.1596 3.2565 21.5582 7.82611 23.2537V0.74646C3.2565 2.44198 0 6.84049 0 12.0001Z"
        fill="#A2001D"
      />
      <path
        d="M16.1738 0.745972V23.2532C20.7434 21.5577 23.9999 17.1592 23.9999 11.9996C23.9999 6.84 20.7434 2.44149 16.1738 0.745972Z"
        fill="#A2001D"
      />
      <path
        d="M6.78274 11.9989H5.73926V16.1728H6.78274V11.9989Z"
        fill="#FFDA44"
      />
      <path
        d="M2.60891 11.9989H1.56543V16.1728H2.60891V11.9989Z"
        fill="#FFDA44"
      />
      <path
        d="M4.17434 14.6085C4.75064 14.6085 5.21783 14.1413 5.21783 13.565C5.21783 12.9887 4.75064 12.5215 4.17434 12.5215C3.59804 12.5215 3.13086 12.9887 3.13086 13.565C3.13086 14.1413 3.59804 14.6085 4.17434 14.6085Z"
        fill="#FFDA44"
      />
      <path
        d="M4.17406 10.4336C4.4622 10.4336 4.69578 10.2 4.69578 9.91185C4.69578 9.62372 4.4622 9.39014 4.17406 9.39014C3.88593 9.39014 3.65234 9.62372 3.65234 9.91185C3.65234 10.2 3.88593 10.4336 4.17406 10.4336Z"
        fill="#FFDA44"
      />
      <path
        d="M5.21783 15.1288H3.13086V16.1723H5.21783V15.1288Z"
        fill="#FFDA44"
      />
      <path
        d="M5.21783 10.9551H3.13086V11.9986H5.21783V10.9551Z"
        fill="#FFDA44"
      />
      <path
        d="M4.17458 6.26001L4.54414 7.3972H5.73983L4.77256 8.10004L5.14184 9.23723L4.17458 8.53439L3.20736 9.23723L3.57664 8.10004L2.60938 7.3972H3.80506L4.17458 6.26001Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3669">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default MN;
