import styled from 'styled-components';
import Colors from '../Colors';
import Modal from '../Modal/Modal';
import Typography from '../Typography/Typography';

const VIDEO_WIDTH = '186px';

export const StyledModal = styled(Modal)`
  &.videos-preview-modal {
    .ant-modal-header {
      border: 0;
    }

    .ant-modal-body {
      padding: 0;
    }
  }
`;

export const OutterContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr 1fr;
`;

export const FeaturedVideosContainer = styled.div`
  grid-area: 2 span / 1;
  background-color: ${Colors.Primary[50]};
  border: 1px solid ${Colors.Primary[600]};
  border-radius: 0.75rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: -0.5rem 0;
`;

export const VideosContainer = styled.div<{ withFeaturedVideos: boolean }>`
  display: grid;
  grid-template-columns: ${({ withFeaturedVideos }) =>
    withFeaturedVideos
      ? `calc(${VIDEO_WIDTH} + 1rem + 1px) repeat(auto-fill, ${VIDEO_WIDTH})`
      : `repeat(auto-fill, ${VIDEO_WIDTH})`};
  gap: 1rem;
  padding: 1.5rem;
`;

export const Container = styled.div`
  display: block;
`;

export const ActiveIndicatorContainer = styled.div`
  display: inline-block;
  margin-right: 6px;
`;

export const NameContainerSkeleton = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px 12px 0px;
`;

export const NameContainer = styled.span<{ href?: string }>`
  display: flex;
  align-items: center;
  margin: 12px 0px;
  ${({ href }) => (href && href.length > 0 ? 'cursor: pointer;' : '')}
`;

export const VideoName = styled(Typography)`
  display: inline-block;
  margin: 0;
  width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const VideoContainer = styled.div`
  height: 220px;
  width: VIDEO_WIDTH;
`;

export const TimestampsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  height: 1.125rem;
  overflow: hidden;
  margin-bottom: 5px;

  > span {
    width: 100%;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e4e7ec;
  align-content: center;
  align-items: center;
  padding: 10px 24px;
`;

export const FooterText = styled(Typography)`
  margin: 0px;
`;
