/* eslint-disable consistent-return */
import { camelCaseToCapitalCase, Input } from '@replai-platform/ui-components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'react-use';
import { logEvent } from '../../analytics';
import { RootState } from '../../store/rootReducer';

interface SearchInputProps {
  initialSearchTerm?: string;
  onSearchTermChangeEnd: (newSearchTerm: string) => void;
  placeholder?: string;
}

const SearchInput: React.VFC<SearchInputProps> = ({ initialSearchTerm, onSearchTermChangeEnd, placeholder }) => {
  const page = useSelector((state: RootState) => state.app.page);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm ?? '');

  useEffect(() => {
    setSearchTerm(initialSearchTerm ?? '');
  }, [initialSearchTerm]);

  useDebounce(
    () => {
      if (searchTerm?.length >= 1) {
        logEvent({
          component: camelCaseToCapitalCase(page.toString()),
          action: 'Search Table',
          category: 'user_actions',
          parameters: { search: searchTerm },
        });
      }
      onSearchTermChangeEnd(searchTerm);
    },
    500,
    [searchTerm]
  );

  return (
    <Input
      autoComplete="off"
      leadingIcon="Search"
      placeholder={placeholder ?? 'Search'}
      onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Enter') {
          onSearchTermChangeEnd(searchTerm);
        }
      }}
      onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Backspace') {
          const delayDebounceFn = setTimeout(() => {
            if ((event.target as HTMLInputElement).value.length < 2) {
              onSearchTermChangeEnd('');
            }
          }, 500);

          return () => clearTimeout(delayDebounceFn);
        }
      }}
      onChange={(e) => {
        setSearchTerm(e.target.value);
      }}
      value={searchTerm}
    />
  );
};

export default SearchInput;
