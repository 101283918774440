/* eslint-disable */
export function objectToQueryParams(object) {
  for (const key in object) {
    const value: any = object[key];
    if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
      delete object[key];
    }
  }
  return Object.entries(object)
    .map(([key, value]: any[]) => {
      if (Array.isArray(value)) {
        return value.map((v) => `${key}=${encodeURIComponent(v)}`);
      }

      return `${key}=${encodeURIComponent(value)}`;
    })
    .flat();
}
