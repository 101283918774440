import { Badge, SelectInput, Typography } from '@replai-platform/ui-components';
import styled from 'styled-components';

export const TrendCardContainer = styled.div`
  flex-grow: 1;
`;

export const TrendTitleTypography = styled(Typography)`
  margin: 0;
`;

export const TrendKPIValueContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
`;

export const TrendKPIBadge = styled(Badge)`
  gap: 0.2rem;
  align-items: center;
  height: fit-content;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TrendKPITypography = styled(Typography)`
  display: inline-block;
  margin-bottom: 0;
`;

export const TrendChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .recharts-responsive-container {
    /* this fixes the chart responsiveness */
    position: absolute;
  }
`;

export const VsPreviousTypography = styled(Typography)`
  display: inline-block;
  margin-bottom: 0;
`;

export const StyledSelectInput = styled(SelectInput)``;

export const SelectInputKpiContainer = styled.div`
  z-index: 2;
`;
