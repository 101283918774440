import { IntegrationIconProps } from './types';

const Sberbank = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="14" fill="#299D37" />
    <path
      d="M22.9324 9.01401L22.0735 8.23753L12.0858 13.8913L7.32502 11.1978L6.83422 12.1685L12.0858 15.1774L22.9324 9.01401Z"
      fill="white"
    />
    <path
      d="M21.0428 7.58237L19.8894 7L12.0858 11.4405L8.62563 9.47505L7.91398 10.3243L12.0858 12.678L21.0428 7.58237Z"
      fill="white"
    />
    <path
      d="M24.3311 10.664L23.6931 9.81476L12.0858 16.4149L6.4416 13.2119L6.17165 14.2795L12.0858 17.6281L24.3311 10.664Z"
      fill="white"
    />
    <path
      d="M26 16.0993C26 14.9992 25.8199 13.948 25.4601 12.9449L24.9202 11.586L12.0859 18.8656L6 15.4442V16.0993C6 17.4257 6.26159 18.7117 6.78528 19.958C7.27607 21.1387 7.98772 22.1904 8.92024 23.1125C9.86897 24.018 10.9242 24.714 12.0859 25.1993C13.3291 25.7326 14.6297 26 15.9877 26C17.3295 26 18.6302 25.7331 19.8895 25.1993C21.0842 24.714 22.1472 24.018 23.0797 23.1125C23.996 22.1904 24.7077 21.1387 25.2147 19.958L25.4601 19.3024C25.8199 18.2672 26 17.1995 26 16.0993Z"
      fill="white"
    />
  </svg>
);
export default Sberbank;
