/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import {
  FocusEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { Color, Colors, ColorTypes } from '..';
import * as Icons from '../Icons';
import * as Styled from './styles';
import '../common.module.css';

type ButtonSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'parent';
type ButtonVariant =
  | 'regular'
  | 'light'
  | 'outlined'
  | 'no-fill'
  | 'text'
  | 'outlined-light';
interface ButtonIcon {
  name: Icons.MiscIconTypes | Icons.BaseIconTypes;
  color?: Color;
  dimension?: number;
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ColorTypes;
  leadingIcon?: ButtonIcon;
  leadingChar?: string;
  size?: ButtonSize;
  centerIcon?: ButtonIcon;
  trailingIcon?: ButtonIcon;
  variant?: ButtonVariant;
  fullWidth?: boolean;
  enableTransitions?: boolean;

  /* Props for Group Button Functionality */
  isGroupButton?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  leadingChar,
  className,
  color = 'Primary',
  disabled,
  leadingIcon,
  size = 'md',
  style,
  trailingIcon,
  centerIcon,
  variant = 'regular',
  fullWidth = false,
  isGroupButton = false,
  isFirst = false,
  isLast = false,
  enableTransitions = true,
  onBlur,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  ...props
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const colorBase = Colors[color];

  // https://stackoverflow.com/questions/20859978/onblur-event-doesnt-fire-in-chrome-when-clicking-on-disabled-button-element-w
  useEffect(() => {
    if (disabled) {
      setIsFocused(false);
    }
  }, [disabled]);

  const handleMouseEnter: MouseEventHandler<HTMLButtonElement> = (event) => {
    setIsHovering(true);
    onMouseEnter?.(event);
  };

  const handleMouseLeave: MouseEventHandler<HTMLButtonElement> = (event) => {
    setIsHovering(false);
    onMouseLeave?.(event);
  };

  const handleFocus: FocusEventHandler<HTMLButtonElement> = (event) => {
    setIsFocused(true);
    onFocus?.(event);
  };

  const handleBlur: FocusEventHandler<HTMLButtonElement> = (event) => {
    setIsFocused(false);
    onBlur?.(event);
  };

  let backgroundColor = 'transparent';
  let borderColor = 'transparent';
  let btnColor: Color = Colors.Common.White;
  let boxShadow = 'none';
  switch (variant) {
    case 'regular':
      backgroundColor =
        colorBase[isHovering && !disabled ? 700 : disabled ? 200 : 600];
      borderColor = backgroundColor;
      boxShadow = isFocused ? `0px 0px 0px 4px ${colorBase[100]}` : 'none';
      break;
    case 'light':
      backgroundColor =
        colorBase[isHovering && !disabled ? 100 : disabled ? 25 : 50];
      borderColor = backgroundColor;
      boxShadow = isFocused ? `0px 0px 0px 4px ${colorBase[100]}` : 'none';
      btnColor = colorBase[disabled ? 300 : 700];
      break;
    case 'outlined-light':
      backgroundColor =
        colorBase[isHovering && !disabled ? 100 : disabled ? 25 : 50];
      ({ 300: borderColor } = colorBase);
      boxShadow = isFocused
        ? `0px 0px 0px 4px ${colorBase[100]}`
        : `0px 1px 2px rgba(16, 24, 40, 0.05)`;
      btnColor =
        colorBase[disabled ? 300 : isHovering && !disabled ? 800 : 700];
      break;
    case 'outlined':
      backgroundColor = isHovering && !disabled ? colorBase[50] : 'transparent';
      ({ 300: borderColor } = colorBase);
      boxShadow = isFocused
        ? `0px 0px 0px 4px ${colorBase[100]}`
        : `0px 1px 2px rgba(16, 24, 40, 0.05)`;
      btnColor =
        colorBase[disabled ? 300 : isHovering && !disabled ? 800 : 700];
      break;
    case 'no-fill':
      backgroundColor = isHovering && !disabled ? colorBase[50] : 'transparent';
      borderColor = backgroundColor;
      btnColor = disabled ? Colors.Gray[300] : colorBase[700];
      break;
    case 'text':
      btnColor = disabled ? Colors.Gray[300] : colorBase[700];
      break;
    default:
      throw Error('Invalid variant');
  }

  const LeadingIcon =
    leadingIcon &&
    (Object.keys(Icons.BaseIcons).includes(leadingIcon.name)
      ? Icons.getBaseIcon(leadingIcon.name as Icons.BaseIconTypes)
      : Icons.getMiscIcon(leadingIcon.name as Icons.MiscIconTypes));
  const CenterIcon =
    centerIcon &&
    (Object.keys(Icons.BaseIcons).includes(centerIcon.name)
      ? Icons.getBaseIcon(centerIcon.name as Icons.BaseIconTypes)
      : Icons.getMiscIcon(centerIcon.name as Icons.MiscIconTypes));
  const TrailingIcon =
    trailingIcon &&
    (Object.keys(Icons.BaseIcons).includes(trailingIcon.name)
      ? Icons.getBaseIcon(trailingIcon.name as Icons.BaseIconTypes)
      : Icons.getMiscIcon(trailingIcon.name as Icons.MiscIconTypes));

  let iconDimension =
    (leadingIcon || trailingIcon)?.name === 'Dot' ? '8' : '17';
  if (size === 'xxl') {
    iconDimension = (leadingIcon || trailingIcon)?.name === 'Dot' ? '8' : '20';
  }

  return (
    <Styled.Button
      type="button"
      className={`${variant || ''} ${`${size}${
        !children ? 'IconOnly' : 'Default'
      }`}
      ${className || ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      disabled={disabled}
      $style={{ ...style } as string}
      $backgroundColor={backgroundColor}
      $borderColor={borderColor}
      $boxShadow={boxShadow}
      $color={btnColor}
      $hasLeadingIcon={Boolean(leadingIcon)}
      $hasTrailingIcon={Boolean(trailingIcon)}
      $hasNoChildren={!children}
      $hasCenterIcon={Boolean(centerIcon)}
      $isGroupButton={isGroupButton}
      $isFirst={isFirst}
      $isLast={isLast}
      $hasFullWidth={fullWidth}
      $enableTransitions={enableTransitions}
      {...props}
    >
      {LeadingIcon ? (
        <LeadingIcon
          color={leadingIcon?.color || btnColor}
          dimension={leadingIcon?.dimension || iconDimension}
        />
      ) : undefined}
      {!children && !LeadingIcon && !TrailingIcon && leadingChar}
      {CenterIcon ? (
        <div className="centerContainer">
          <CenterIcon
            color={centerIcon?.color || btnColor}
            dimension={centerIcon?.dimension || iconDimension}
          />
          {children ? <div>{children}</div> : null}
        </div>
      ) : undefined}
      {!CenterIcon && children ? <div>{children}</div> : undefined}
      {TrailingIcon ? (
        <TrailingIcon
          color={trailingIcon?.color || btnColor}
          dimension={trailingIcon?.dimension || iconDimension}
        />
      ) : undefined}
    </Styled.Button>
  );
};

export default Button;
export type { ButtonIcon, ButtonProps, ButtonSize, ButtonVariant };
