import * as SDK from '@replai-platform/sdk';
import columnsConfig from '../configurations/columnsConfig';
import { Columns } from './enums';

/** Gets a list of user selected columns to display on a table. Should be used inside a `useMemo()` or a memoized selector. */
function getUserSelectedColumns({
  userColumns,
  projectKpis,
  projectAccountTypes,
}: {
  userColumns: Columns[];
  projectKpis: string[];
  projectAccountTypes?: SDK.AccountTypes[];
}): Columns[] {
  const { initialValues, groups } = columnsConfig(projectKpis, projectAccountTypes);
  return Array.from(
    new Set(
      userColumns
        ? userColumns.filter((column) => groups.find(({ options }) => options.find(({ value }) => value === column)))
        : initialValues
    )
  );
}

export default getUserSelectedColumns;
