import { Colors } from '@replai-platform/ui-components';
import styled from 'styled-components';

export const TopVideoCardsContainer = styled.div`
  padding: 1rem;
  background-color: ${Colors.Primary[50]};
  border: 1px solid ${Colors.Primary[600]};
  border-radius: 12px;
`;

export const PaddingContainer = styled.div`
  padding-right: 0.3rem;
  padding-left: 0.3rem;
`;
