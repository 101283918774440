import { IntegrationIconProps } from './types';

const Coub = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 30C23.732 30 30 23.732 30 16C30 8.26802 23.732 2 16 2C8.26802 2 2 8.26802 2 16C2 23.732 8.26802 30 16 30Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.65078 27.9182C4.6595 25.4518 2 21.0365 2 16C2 8.26802 8.26802 2 16 2C23.732 2 30 8.26802 30 16C30 23.732 23.732 30 16 30C15.6289 30 15.2612 29.9856 14.8975 29.9572L16.2746 26.1735C16.75 24.8675 18.0916 24.1277 18.7753 22.9653C19.385 21.927 21.2448 18.8078 21.2635 18.7762C21.6457 18.2281 22.259 17.2911 22.6371 16.6794L24.7022 13.3244C25.0799 12.7111 25.1085 11.9413 24.7659 11.6091C24.3106 11.1703 23.6113 11.6445 23.3042 12.0343C22.1949 13.5812 21.0431 14.946 19.8203 16.3694C19.635 16.5861 19.1572 16.9232 19.2363 16.2782C19.2434 16.2457 19.6068 14.7184 18.643 14.3115L18.6009 14.2955C18.0799 14.1059 17.6651 14.4317 17.2046 14.9794C17.0206 15.198 16.6961 14.9599 16.7514 14.7143C16.9157 13.9794 16.6127 13.2922 16.031 13.0804L15.9862 13.0655C15.2139 12.8177 14.6768 13.9312 14.1213 13.729C13.8482 13.6296 13.8783 12.8919 13.8524 12.6734C13.634 10.7502 13.8123 8.86567 13.9266 6.93726C13.9423 6.38012 13.6015 5.65564 12.9511 5.73778C12.3971 5.80904 11.9212 6.4371 11.8672 7.16802L11.5 11C11.4268 11.9898 11.9614 14.908 11.0244 15.5542C10.131 16.183 9.10968 16.9296 9.07124 18.1065C9.00345 19.9565 10.7093 22.2624 10.1162 23.892C10.113 23.9008 9.14458 26.5615 8.65078 27.9182Z"
      fill="#1848F7"
    />
  </svg>
);
export default Coub;
