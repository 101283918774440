import { useQueries } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { MultiSelectDropDownOption } from '@replai-platform/ui-components';
import { AppActions } from '../../store/app';
import { FiltersState } from '../../store/filters';
import { RootState } from '../../store/rootReducer';
import { Filters } from '../../utils/enums';
import { getFilterLabel, getFilterOptions, getFilterShowCondition, getFilterTitle } from '../FilterBar/common/utils';

const HALF_DAY_IN_MS = 1000 * 60 * 60 * 12;

// This hook builds the sidebar filters. Note that most filters require an API request to be made.
const useSidebarFilters = (supportedFilters: Filters[]): void => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const filtering = useSelector((state: RootState) => state.filters);
  const dispatch = useDispatch();

  // Build other filters
  useDeepCompareEffect(() => {
    if (supportedFilters.includes(Filters.Spend)) {
      dispatch(
        AppActions.updateAvailableFilters({
          title: getFilterTitle(Filters.Spend),
          id: Filters.Spend,
          minPlaceholder: 'Min value',
          minValue: filtering.minSpend,
        })
      );
    }

    if (supportedFilters.includes(Filters.Installs)) {
      dispatch(
        AppActions.updateAvailableFilters({
          title: getFilterTitle(Filters.Installs),
          id: Filters.Installs,
          minPlaceholder: 'Min value',
          minValue: filtering.minInstalls,
        })
      );
    }
  }, [supportedFilters, filtering]);

  if (supportedFilters.includes(Filters.LaunchedDate)) {
    getFilterOptions(Filters.LaunchedDate, projectId, filtering)
      .then((options) => {
        dispatch(
          AppActions.updateAvailableFilters({
            title: getFilterTitle(Filters.LaunchedDate),
            placeholder: `Select ${getFilterLabel(Filters.LaunchedDate)}`,
            id: Filters.LaunchedDate,
            options,
            limit: 4,
            collapsedByDefault: !getFilterShowCondition(Filters.LaunchedDate, filtering),
            singleSelect: true,
          })
        );
      })
      .catch((err) => console.error(err));
  }

  if (supportedFilters.includes(Filters.OS)) {
    getFilterOptions(Filters.OS, projectId, filtering)
      .then((options) => {
        dispatch(
          AppActions.updateAvailableFilters({
            title: getFilterTitle(Filters.OS),
            placeholder: `Select ${getFilterLabel(Filters.OS)}`,
            id: Filters.OS,
            options,
            limit: 4,
            collapsedByDefault: !getFilterShowCondition(Filters.OS, filtering),
          })
        );
      })
      .catch((err) => console.error(err));
  }

  // Build filters that require an API request
  useQueries(
    supportedFilters
      .filter(
        (filter) =>
          filter !== Filters.Spend &&
          filter !== Filters.Installs &&
          filter !== Filters.LaunchedDate &&
          filter !== Filters.OS
      )
      .map((filter) => {
        const requestParameters: [Filters, string, FiltersState] = [filter, projectId, filtering];
        return {
          queryKey: ['sidebar-filters', ...requestParameters],
          queryFn: () => getFilterOptions(...requestParameters),
          onSuccess: (res: MultiSelectDropDownOption[]) =>
            dispatch(
              AppActions.updateAvailableFilters({
                title: getFilterTitle(filter),
                placeholder: `Select ${getFilterLabel(filter)}`,
                id: filter,
                options: res,
                limit: 4,
                collapsedByDefault: !getFilterShowCondition(filter, filtering),
              })
            ),
          cacheTime: HALF_DAY_IN_MS,
        };
      })
  );
};

export default useSidebarFilters;
