import { PaymentIconProps } from './types';

const Affirm = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5123 15.9588H18.0337V15.3786C18.0337 14.624 18.4648 14.408 18.8368 14.408C19.2471 14.408 19.5668 14.5902 19.5668 14.5902L20.0702 13.4397C20.0702 13.4397 19.5599 13.1062 18.6323 13.1062C17.5895 13.1062 16.4029 13.6941 16.4029 15.5393V15.9588H13.9276V15.3786C13.9276 14.624 14.3587 14.408 14.7299 14.408C14.9413 14.408 15.2256 14.4572 15.4608 14.5902L15.9641 13.4397C15.6637 13.263 15.181 13.1062 14.5263 13.1062C13.4834 13.1062 12.2969 13.6941 12.2969 15.5393V15.9588H11.3501V17.2176H12.2969V21.6649H13.9276V17.2176H16.4029V21.6649H18.0337V17.2176H19.5123V15.9588ZM7.60621 20.6411C7.17739 20.6411 6.96375 20.4305 6.96375 20.0824C6.96375 19.4384 7.6846 19.2179 9.00026 19.0788C9.00026 19.9402 8.41774 20.6411 7.60621 20.6411ZM8.17336 15.7911C7.23349 15.7911 6.15145 16.2346 5.56432 16.7026L6.10073 17.8315C6.57182 17.4004 7.3334 17.0323 8.02043 17.0323C8.67288 17.0323 9.03331 17.2505 9.03331 17.6893C9.03331 17.986 8.7943 18.1351 8.34243 18.1935C6.65635 18.4132 5.33301 18.8782 5.33301 20.1785C5.33301 21.209 6.06692 21.833 7.21351 21.833C8.03119 21.833 8.75972 21.3781 9.10631 20.7779V21.6647H10.631V17.9491C10.631 16.4144 9.56433 15.7911 8.17336 15.7911ZM23.4849 15.9588V21.6649H25.1172V18.916C25.1172 17.6088 25.908 17.2253 26.4597 17.2253C26.6749 17.2253 26.9654 17.2876 27.1575 17.4313L27.4549 15.9227C27.2029 15.8151 26.9393 15.7913 26.7233 15.7913C25.8841 15.7913 25.357 16.1633 25.0088 16.9179V15.9588H23.4849ZM33.1773 16.7936C33.5131 16.3017 34.1579 15.7914 35.0209 15.7914C36.0638 15.7914 36.9268 16.4385 36.9268 17.7211V21.665H35.296V18.2368C35.296 17.4814 34.8411 17.1678 34.41 17.1678C33.8705 17.1678 33.331 17.6612 33.331 18.7279V21.665H31.7003V18.2483C31.7003 17.4698 31.2714 17.1678 30.805 17.1678C30.2893 17.1678 29.7375 17.6727 29.7375 18.7279V21.665H28.1045V15.959H29.6776V16.822C29.9535 16.271 30.5506 15.7914 31.4136 15.7914C32.2044 15.7914 32.8653 16.158 33.1773 16.7936ZM22.1151 21.6651H20.4859V15.9591H22.1151V21.6651Z"
      fill="#060809"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.9905 8C25.5855 8 21.6592 11.0578 20.5449 14.9894H22.1419C23.0725 12.0615 26.2318 9.49165 29.9905 9.49165C34.5592 9.49165 38.5077 12.9698 38.5077 18.3847C38.5077 19.6004 38.3501 20.6963 38.052 21.6646H39.6013L39.6166 21.6116C39.8702 20.6125 39.9993 19.5274 39.9993 18.3847C39.9993 12.3458 35.5989 8 29.9905 8Z"
      fill="#0FA0EA"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default Affirm;
