/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { DropDownChip } from '@replai-platform/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { FILTERS_WITH_CONSIDER_OR_EXCLUDING, FILTER_SUFFIX } from '../../../utils/constants';
import { RootState } from '../../../store/rootReducer';
import { Filters } from '../../../utils/enums';
import { getFilterAction, getFilterLabel, getFilterSelectedCondition, getFilterStateKey } from '../common/utils';

interface FilterDropDownGroupProps {
  filter: Filters;
  eventPrefix?: string;
  useAllLabel?: boolean;
  type?: 'singleselect' | 'multiselect';
}

const FilterDropDownGroup = ({
  filter,
  eventPrefix = '',
  useAllLabel = true,
  type = 'multiselect',
}: FilterDropDownGroupProps) => {
  const dispatch = useDispatch();
  const availableFilters = useSelector((state: RootState) => state.app.availableFilters);
  const filterDefaultData = availableFilters.find((item) => item.id === filter);
  const allFilterValues = useSelector((state: RootState) => state.filters);
  const filterStateKey = getFilterStateKey(filter, allFilterValues);
  const stateFilterValue = useSelector((state: RootState) => state.filters[filterStateKey]);
  const toExcludeFilter = filterStateKey.includes(FILTER_SUFFIX.TO_EXCLUDE);

  // If we have the filter data but not the value, we dont show anything
  if (!stateFilterValue || stateFilterValue.length === 0) {
    return null;
  }

  const filterData = {
    ...filterDefaultData,
    options: filterDefaultData?.options?.map((item) => ({
      ...item,
      selected: getFilterSelectedCondition({
        filter,
        filterValue: stateFilterValue,
        item,
        toExclude: toExcludeFilter,
      }),
      onClick: () => {
        if (type === 'singleselect') {
          const action = getFilterAction(filter);
          dispatch(
            action?.({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value: item.id ?? 0,
              logEvent: true,
              eventPrefix,
              toExclude: toExcludeFilter,
            })
          );
        }
      },
    })),
  };

  return filterData?.options ? (
    <DropDownChip
      placeHolder={filterData.placeholder ?? 'Select items'}
      dropDownType={type}
      dropDownOptions={filterData.options}
      onChange={(options) => {
        if (options && filterData && options !== filterData.options) {
          const action = getFilterAction(filter);

          const selectedOptions = options?.filter(({ selected, isAllOption }) => selected && !isAllOption);
          const unSelectedOptions = options?.filter(({ selected, isAllOption }) => !selected && !isAllOption);

          const toExclude =
            FILTERS_WITH_CONSIDER_OR_EXCLUDING.includes(filter) && selectedOptions.length > unSelectedOptions.length;

          const newIds = (toExclude ? unSelectedOptions : selectedOptions)?.reduce(
            (prev, o) => [
              ...prev,
              ...((filter !== Filters.CustomTag ? o.id?.split(',') : [JSON.parse(o.id ?? '')]) ?? []),
            ],
            []
          );

          dispatch(
            action?.({
              value: filter === Filters.LaunchedDate ? newIds[0] : newIds,
              toExclude,
              logEvent: true,
              eventPrefix,
            })
          );
        }
      }}
      prefixLabel={getFilterLabel(filter)}
      useAllLabel={useAllLabel}
    />
  ) : null;
};

export default FilterDropDownGroup;
