import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { DropDownMenuOption } from '@replai-platform/ui-components';
import { RootState } from '../../../store/rootReducer';
import { ProjectActions } from '../../../store/project';
import { InsightsActions } from '../../../store/insights';
import useGetCampaigns from '../../../api/hooks/campaigns/useGetCampaigns';

const useCampaignFilter = (
  initialOptions?: string[]
): { campaignFilterOptions: DropDownMenuOption[]; isLoading: boolean; hasData: boolean } => {
  const dispatch = useDispatch();
  const projectId = useSelector((state: RootState) => state.project.id);

  // Fetch and update project networks
  const { isSuccess, data, isLoading } = useGetCampaigns({ projectIds: [projectId] });

  const mappedCampaigns = useMemo(
    () => data?.campaigns?.map(({ id, name }) => ({ campaignId: id, campaignName: name })) ?? [],
    [data]
  );

  useEffect(() => {
    if (isSuccess && data && data?.campaigns.length > 0) {
      dispatch(ProjectActions.changeCampaigns(data));
      dispatch(InsightsActions.addProjectCampaigns(mappedCampaigns));
    }
  }, [mappedCampaigns, data, isSuccess, dispatch]);

  // Create filter options
  const campaignFilterOptions = useMemo(
    () =>
      isSuccess && data
        ? [
            {
              label: 'All Campaigns',
              isAllOption: true,
              selected: !initialOptions,
            },
            ...mappedCampaigns.map((campaign) => ({
              label: campaign.campaignName,
              id: campaign.campaignId,
              selected: !initialOptions ? true : initialOptions.includes(campaign.campaignId),
            })),
          ]
        : [],
    [mappedCampaigns, data, isSuccess]
  );

  return { campaignFilterOptions, isLoading, hasData: campaignFilterOptions.length > 0 };
};

export default useCampaignFilter;
