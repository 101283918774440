import { Image } from 'antd';
import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';
import { TwoColumnLayout } from '../shared/TwoColumnLayout';
import { VerticalLayout } from '../shared/VerticalLayout';

const FACEBOOK_FORM_URL = 'https://docs.google.com/forms/d/1PZTp8QmO17eIHERSjR7cJ5OkP8SiKfQKwT6f1c-yf0c/viewform';

export const FacebookConnectionGuide: NetworkConnectionGuideContent = {
  title: 'Facebook Connection Guide',
  icon: 'Facebook',
  guide: {
    label: 'Connection Guide',
    steps: [
      {
        title: 'Step I',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              {
                item: (
                  <>
                    Go to {Shared.link({ label: 'Business settings', url: 'https://business.facebook.com/settings' })}.
                  </>
                ),
              },
              {
                item: (
                  <>
                    Below <Shared.BoldText>Users</Shared.BoldText>, click <Shared.BoldText>Partners</Shared.BoldText>.
                  </>
                ),
              },
              {
                item: (
                  <>
                    Click <Shared.BoldText>+ Add</Shared.BoldText>.
                  </>
                ),
              },
              {
                item: (
                  <>
                    Select <Shared.BoldText>Give a partner access to your assets</Shared.BoldText>.
                  </>
                ),
              },
            ]}
          />
        ),
      },
      {
        title: 'Step II',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                Enter the <Shared.BoldText>Partner business ID</Shared.BoldText> you want to add and click{' '}
                <Shared.BoldText>Next</Shared.BoldText>. When prompted, enter the Partner Business ID as:
                <Shared.BoldText>597 579 187 338 811</Shared.BoldText>
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/facebook/step-5.png"
          />
        ),
      },
      {
        title: 'Step III',
        description: '',
        content: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              paddingTop: '2rem',
              alignItems: 'center',
              fontWeight: 'normal',
            }}
          >
            <Shared.OList>
              <Shared.OListItem>
                <Shared.RegularText>
                  On this screen, you can add partners to multiple assets. Choose a type of asset in the first column.
                  Select the assets that you want to add your partner to in the second column. Assign a role for your
                  partner in the third column. Repeat these steps until you&apos;ve chosen roles for all of the assets
                  that you want to assign.
                </Shared.RegularText>
              </Shared.OListItem>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Shared.BoldText>1. Select the Facebook Page where you run the ads</Shared.BoldText>
                <Shared.BoldText>2. Ad accounts: the ad account where you want to run the trial</Shared.BoldText>
              </div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <figure>
                  <Image
                    width="430px"
                    height="100%"
                    src="https://cdn.replai.io/assets/integration-guide/facebook/step-6.1.png"
                    alt="Step 6.1"
                  />
                  <Shared.FigureCaption>select: View Page Performance Access</Shared.FigureCaption>
                </figure>
                <figure>
                  <Image
                    width="500px"
                    height="100%"
                    src="https://cdn.replai.io/assets/integration-guide/facebook/step-6.2.png"
                    alt="Step 6.1"
                  />
                  <Shared.FigureCaption>select: View Performance Access</Shared.FigureCaption>
                </figure>
              </div>
            </Shared.OList>
          </div>
        ),
      },
      {
        title: 'Step IV',
        description: '(Optional)',
        content: (
          <TwoColumnLayout
            contentDescription="You can skip the Final Steps if you are not using Business Creative Folders"
            contentItems={[
              { item: 'Select: Data Sources' },
              { item: 'Select: Business Creative Folders' },
              { item: 'Select: Pick the Folder -> Assign Partner' },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/facebook/step-7.png"
          />
        ),
      },
      {
        title: 'Step V',
        description: '(Optional)',
        content: (
          <TwoColumnLayout
            contentDescription="You can skip the Final Steps if you are not using Business Creative Folders"
            contentItems={[
              {
                item: (
                  <>
                    Add: Add our Partner ID (<Shared.BoldText>597579187338811</Shared.BoldText>)
                  </>
                ),
              },
              { item: 'Select: View Content' },
              { item: 'Select: View Insights' },
              {
                item: (
                  <>
                    Use {Shared.link({ label: 'this form', url: FACEBOOK_FORM_URL })} to confirm that you&apos;ve
                    completed the steps on this guide.
                  </>
                ),
              },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/facebook/step-8.png"
          />
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: [
      {
        title: 'How does Replai fetch country-level metrics on Facebook?',
        description: (
          <Shared.P>
            Replai fetches Facebook metrics using the{' '}
            {Shared.link({
              label: 'Ads Insights API',
              url: 'https://developers.facebook.com/docs/marketing-api/reference/adgroup/insights',
            })}
            . This API allows breaking down the Ad metrics by entities such as the creative, country, date, and a few
            others. However, one limitation of this API is that it{' '}
            {Shared.link({
              label: "doesn't allow users to break down metrics simultaneously by creative and country",
              url: 'https://developers.facebook.com/docs/marketing-api/insights/breakdowns#combiningbreakdowns',
            })}
            . This means that, on ads with multiple creatives, Facebook doesn&apos;t report the country level metrics
            for each creative, just the global metrics. Because of this, when Replai detects ads with a single creative,
            we can infer the country level metrics of the creative based on the ad&apos;s country level metrics, but on
            ads with multiple creatives Replai estimates the country level metrics based on all other metrics available.
          </Shared.P>
        ),
      },
      {
        title: 'Why does the total of my metrics (e.g. spend) not match the total visible on Replai?',
        description: (
          <>
            <Shared.UList>
              <Shared.UListItem>
                Facebook&apos;s metrics like spend or installs can get assigned to all creatives on your ads, which can
                be videos, images, text, headlines and many others. Replai only fetches metrics for videos and images,
                so if you have metrics assigned to other creative types those wouldn&apos;t show up on the Replai
                platform.
              </Shared.UListItem>
              <Shared.UListItem>
                You can verify this on the Facebook UI in two ways:
                <Shared.UList>
                  <Shared.UListItem>
                    Enable the breakdown “By Creative Element &gt; Image, video and slideshow”
                    <Shared.UList>
                      <Shared.UListItem>
                        Check that on some ads, the totals of metrics like “Results” and “Spend” don&apos;t match the
                        sum of each of the creative&apos;s metrics.
                      </Shared.UListItem>
                    </Shared.UList>
                  </Shared.UListItem>
                  <Shared.UListItem>
                    With the same breakdown enabled, use the “Reports” button on the top-right and click “Export table
                    data” (tick the “Include deleted items with delivery” option). When the CSV is downloaded, open it
                    and verify that on some ads the total metrics don&apos;t match the sum of the metrics of all
                    creatives.
                  </Shared.UListItem>
                </Shared.UList>
              </Shared.UListItem>
            </Shared.UList>
            <Image
              style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
              width="800px"
              height="100%"
              src="https://cdn.replai.io/assets/integration-guide/facebook/faq-1.png"
              alt="Step 1"
            />
          </>
        ),
      },
      {
        title: 'How does Replai provide creative-level metrics on Facebook AAA campaigns?',
        description: (
          <Shared.UList>
            <Shared.UListItem>
              Facebook Automated App Ads (AAA) are a unique ad type where you can simply provide a mix of different
              creative elements and they&apos;re automatically combined into multiple ads in a way that aims to maximize
              your results without requiring manual configuration from the user.
            </Shared.UListItem>
            <Shared.UListItem>
              As you might know from Facebook&apos;s UI, Facebook restricts certain simultaneous breakdowns on AAA
              campaigns, which prevents you from getting accurate creative-level metrics with breakdowns such as the
              country breakdown. This limitation is well-known in the industry and is also faced by{' '}
              {Shared.link({
                label: 'other partners',
                url: 'https://www.singular.net/blog/facebook-aaa-creative-optimization/',
              })}
              .
            </Shared.UListItem>
            <Shared.UListItem>
              To still enable you to get creative-level insights on AAA campaigns, Replai has a proprietary algorithm
              trained on years of Facebook data that accurately infers your creative-level metrics based on all metadata
              and all other metrics available for your ads and creatives.
            </Shared.UListItem>
            <Shared.UListItem>
              Replai still closely monitors API updates from Facebook so if this behavior is ever officially supported
              you&apos;ll get that automatically on your Replai integration.
            </Shared.UListItem>
          </Shared.UList>
        ),
      },
      ...Shared.DEFAULT_NETWORK_FAQS,
    ],
  },
};
