import { CountryIconProps } from './types';

const FO = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M23.4781 12L23.898 10.4348C23.8283 9.90131 23.7237 9.37875 23.5865 8.86959H10.9563V0.0456543C10.4238 0.0914981 9.90139 0.172545 9.39111 0.285701L7.30414 1.5652L6.26066 1.45987C5.71151 1.7595 5.18834 2.10075 4.69545 2.47945V8.86954H0.413187C0.275984 9.3787 0.171359 9.90126 0.101609 10.4347L0.521516 12L0.101562 13.5652C0.171312 14.0987 0.275937 14.6213 0.413141 15.1304H4.69541V21.5205C5.1883 21.8993 5.71151 22.2405 6.26061 22.5401L7.82581 22.4347L9.39101 23.7142C9.90134 23.8274 10.4238 23.9084 10.9562 23.9543V15.1305H23.5863C23.7235 14.6213 23.8281 14.0987 23.8979 13.5652L23.4781 12Z"
      fill="#0052B4"
    />
    <path
      d="M23.8984 10.4347H9.39136H9.39131V0.285156C8.28234 0.531062 7.23098 0.929641 6.26086 1.459V10.4347H0.101578C0.034875 10.9471 0 11.4694 0 11.9999C0 12.5304 0.034875 13.0528 0.101578 13.5651H6.26081H6.26086V22.5408C7.23098 23.0701 8.28234 23.4688 9.39131 23.7146V13.5653V13.5652H23.8984C23.9651 13.0528 24 12.5304 24 11.9999C24 11.4694 23.9651 10.9471 23.8984 10.4347Z"
      fill="#D80027"
    />
  </svg>
);
export default FO;
