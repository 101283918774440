/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as SDK from '@replai-platform/sdk';

/**
 * Checks if a KPI has inverse order
 */
const checkInverseKpi = (kpi: SDK.Metrics) =>
  Object.values(SDK.MetricKPIWithInverseOrder)
    .map((t) => t as string)
    .includes(kpi);

export default checkInverseKpi;
