import * as SDK from '@replai-platform/sdk';

const getMetricsFromColumns = (columns: string[]) =>
  columns.reduce((acc: SDK.Metrics[], curr: string) => {
    const metricName = curr.split('.')?.[1];
    if (metricName) {
      acc.push(metricName as SDK.Metrics);
    }
    return acc;
  }, []);

export default getMetricsFromColumns;
