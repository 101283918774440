import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  SocialButton,
  NetworkConnectionGuide,
  SocialButtonProps,
  Badge,
  Icons,
} from '@replai-platform/ui-components';
import { Col, Empty, Row, Table } from 'antd';
import * as SDK from '@replai-platform/sdk';
import styled from 'styled-components';
import { useFeature } from '@optimizely/react-sdk';
import { ProjectActions } from '../../../store/project';
import type { RootState } from '../../../store/rootReducer';
import { useConnectedNetworkAccounts } from '../../../api/hooks/networks/useNetworks';
import * as Styles from './style';
import { getConnectionGuides, ConnectionGuides } from './networksConnectionGuide';
import { NetworkConnectionGuideContent } from './types';
import { logEvent } from '../../../analytics';

export const NetworkAccountsTable = styled(Table)`
  .ant-pagination-item-link,
  .ant-pagination-item {
    border-radius: 0.5rem;
  }
`;

type ExtendedNetworkConnectionGuideContent = NetworkConnectionGuideContent & { totalAccounts: number };

function getProjectAvailableNetworks({
  connectionGuides,
  isTechMerc1473Enabled,
}: {
  connectionGuides: ConnectionGuides;
  isTechMerc1473Enabled: boolean;
}) {
  return Object.entries(connectionGuides).filter(([network]) =>
    isTechMerc1473Enabled ? true : network !== SDK.Network.YOUTUBE
  );
}

const NetworkManagement: React.VFC = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedNetwork, setSelectedNetwork] = React.useState<SDK.Network | null>(null);
  const { id: projectId, connectedNetworkAccounts } = useSelector((state: RootState) => state.project);
  const LoadingCircle = Icons.getMiscIcon('LoadingCircle');
  const [isTechMerc1473Enabled] = useFeature('techmerc-1473'); // YouTube integration
  const connectionGuides = useMemo(() => getConnectionGuides(), []);

  // Fetch and update project networks
  const { isSuccess, isLoading } = useConnectedNetworkAccounts(
    { projectId },
    {
      onSuccess: (res) => {
        dispatch(ProjectActions.changeConnectedNetworkAccounts(res.connectedNetworks));
      },
    }
  );

  const showInfo = (n: SDK.Network | null) => {
    setSelectedNetwork(n);
    setIsOpen(!!n);
  };

  // Connection guides information per network
  const connectionGuidePerNetworkContent = React.useMemo(
    () =>
      Object.keys(connectionGuides).reduce((acc, network) => {
        const tableData = (connectedNetworkAccounts[network] ?? []) as object[];
        const availableColumns = Object.keys(tableData?.[0] ?? {});

        const tableColumns = availableColumns.map((value, index) => ({
          key: `${network}-${value}-${index}`,
          title: value,
          dataIndex: value,
        }));

        acc[network] = {
          ...connectionGuides[network],
          totalAccounts: tableData.length,
          details: {
            label: `Connected Accounts`,
            content: (
              <div style={{ width: '100%' }}>
                <NetworkAccountsTable
                  key={network}
                  locale={{
                    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Accounts" />,
                  }}
                  columns={tableColumns}
                  dataSource={tableData.map((entry, index) => ({
                    key: index,
                    ...entry,
                  }))}
                  size="large"
                  pagination={{ pageSize: 8 }}
                />
              </div>
            ),
          },
        } as ExtendedNetworkConnectionGuideContent;
        return acc;
      }, {} as { [key in SDK.Network]: ExtendedNetworkConnectionGuideContent }),
    [connectedNetworkAccounts, connectionGuides]
  );

  const networkConnectionGuide = React.useMemo<NetworkConnectionGuideContent | undefined>(() => {
    if (selectedNetwork) {
      return connectionGuidePerNetworkContent[selectedNetwork];
    }
    return undefined;
  }, [selectedNetwork, connectionGuidePerNetworkContent]);

  return (
    <Styles.MainContainer data-test="external-api-key-management-container">
      {isOpen && networkConnectionGuide ? (
        <NetworkConnectionGuide
          isOpen={isOpen}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...networkConnectionGuide}
          onClose={() => showInfo(null)}
          onTabChange={() =>
            logEvent({
              component: 'Settings',
              action: 'Change Connection Guide Tab',
              category: 'user_actions',
              parameters: {
                network: selectedNetwork,
              },
            })
          }
        />
      ) : undefined}

      <Styles.TextContainer>
        <Typography fontWeight="semi-bold">Integrated Networks</Typography>
        <Typography type="text-sm">
          View and manage the Ad Network and MMP accounts you have connected with Replai
        </Typography>
      </Styles.TextContainer>
      <Styles.NetworksContainer>
        <Row gutter={[8, 8]} style={{ width: '100%' }}>
          {getProjectAvailableNetworks({ connectionGuides, isTechMerc1473Enabled }).map(([network, props]) => (
            <Col key={network} span={Object.keys(connectionGuides).length <= 2 ? 24 : 8}>
              <SocialButton
                icon={props.icon as SocialButtonProps['icon']}
                socialButtonType="flat"
                fullWidth
                onClick={() => showInfo(network as SDK.Network)}
              >
                <div style={{ display: 'flex', gap: '0.7rem' }}>
                  {props.icon}
                  {isLoading || !isSuccess ? (
                    <LoadingCircle color="#363F72" dimension="1.5rem" />
                  ) : (
                    <Badge color="BlueGray">
                      {`${connectionGuidePerNetworkContent[network as SDK.Network].totalAccounts}`}
                    </Badge>
                  )}
                </div>
              </SocialButton>
            </Col>
          ))}
        </Row>
      </Styles.NetworksContainer>
    </Styles.MainContainer>
  );
};
export default NetworkManagement;
