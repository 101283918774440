import { IntegrationIconProps } from './types';

const Mastercard = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="16" r="9" fill="#E80B26" />
    <circle cx="22" cy="16" r="9" fill="#F59D31" />
    <path
      d="M16 22.7083C17.8413 21.0603 19 18.6655 19 16C19 13.3345 17.8413 10.9397 16 9.29175C14.1587 10.9397 13 13.3345 13 16C13 18.6655 14.1587 21.0603 16 22.7083Z"
      fill="#FC6020"
    />
  </svg>
);
export default Mastercard;
