import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';
import { TypographyClean } from '../../../utils/styles';

export type UserEmailAccessorType = {
  email: string;
};

const UserEmailCellDecorator: React.FC<{
  cellData: UserEmailAccessorType;
}> = ({ cellData }) => {
  if (!cellData) {
    return null;
  }

  return <TypographyClean>{cellData.email}</TypographyClean>;
};

function getUserEmailColumn<T extends object = UserEmailAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T, UserEmailAccessorType>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<T, UserEmailAccessorType>) => <UserEmailCellDecorator cellData={data?.cell?.value} />,
  };
}

export default getUserEmailColumn;
