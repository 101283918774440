import { Network } from '../../ads';
import { AssetType } from '../../assets';
import { UUID } from '../../atomics';

export type ProductionConcept = {
  id: UUID;
  name: string | null;
  projectId: UUID;
  referenceAssetId: UUID;
  referenceProductionConceptId: UUID;
  createdAt: string | null;
  updatedAt: string | null;
};

export type ProductionAsset = {
  id: string;
  label: string;
  url: string | null;
};

export type ProductionConceptVersion = {
  id: UUID;
  productionConceptId: UUID;
  requestingUserId: UUID;
  requestingUserEmail: string | null;
  completionStatusPercentage: number;
  prompt: string | null;
  promptResourcePath: string | null;
  promptResourceExtension: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  assets: ProductionAsset[];
};

// -------------- Get All Production Concepts - Begin --------------

export type GetAllProductionConceptsRequest = {
  projectId: UUID;
  search?: string;
};

export type GetAllProductionConceptsResponse = {
  results: {
    conceptId: string;
    conceptName: string | null;
    prompt?: string | null;
    createdAt: string;
    updatedAt: string;
    versions: ProductionConceptVersion[];
  }[];
};

// -------------- Get All Production Concepts - End   --------------

// -------------- Submit Text Image to Video Prompt - Begin --------------

export type SubmitTextImageToVideoPromptPath = {
  projectId: UUID;
};

export type SubmitTextImageToVideoPromptRequestBody = {
  prompt: string;
  productionConceptId?: string;
  baseAssetId?: string;
  baseProductionConceptId?: string;
  imagePrompt?: {
    base64: string;
    fileName: string;
  };
};

export type SubmitTextImageToVideoPromptResponse = ProductionConcept;

// -------------- Submit Text Image to Video - End   --------------

// -------------- Get Creative Preferences - Begin --------------

export type CreativePreferencesType =
  | 'CREATIVE_GUIDELINES'
  | 'TESTING_GUIDELINES';

export type GetCreativePreferencesPath = {
  projectId: UUID;
};

export type GetCreativePreferencesResponse = {
  files: {
    id: string;
    fileName: string;
    fileType: string;
    submissionType: CreativePreferencesType;
  }[];
};

// -------------- Get Creative Preferences - End   --------------

// -------------- Submit Creative Preferences - Begin --------------

export type SubmitCreativePreferencesPath = {
  projectId: UUID;
};

export type SubmitCreativePreferencesResponse = { ok: boolean };

export type SubmitCreativePreferencesRequestBody = {
  submissionType: CreativePreferencesType;
  fileName: string;
  fileBinary: string;
};

// -------------- Submit Creative Preferences - End   --------------

// -------------- Delete Creative Preferences - Begin --------------

export type DeleteCreativePreferencesPath = {
  projectId: UUID;
};

export type DeleteCreativePreferencesRequestBody = {
  fileId: string;
};

export type DeleteCreativePreferencesResponse = { ok: boolean };

// -------------- Delete Creative Preferences - End   --------------

// -------------- Get Creative Thumbnail - Start   --------------

export type GetCreativeThumbnailPath = {
  projectId: UUID;
  creativeId: UUID;
};

export type GetCreativeThumbnailResponse = {
  previewUrl: string | null;
};

// -------------- Get Creative Thumbnail - End   --------------

// -------------- Get Creative Thumbnails - Start   --------------

export type GetCreativeThumbnailsRequest = {
  numberOfThumbnails: number;
};

export type GetCreativeThumbnailsPath = {
  projectId: UUID;
  creativeId: UUID;
};

export type GetCreativeThumbnailsResponse = {
  thumbnails: {
    seconds: number;
    url: string;
  }[];
};

// -------------- Get Creative Thumbnails - End   --------------

// -------------- Get Related Creatives - Begin --------------

export enum RelatedCreativeType {
  GENERATED_CONCEPT = 'GENERATED_CONCEPT',
  LAUNCHED_ASSET = 'LAUNCHED_ASSET',
}

export type RelatedCreative = {
  id: UUID;
  isMarketData: boolean;
  name: string;
  assetType: AssetType;
  assetPreviewUrl: string;
  latestUpdate: string;
  productionAssetId: UUID;
  launchDate: string;
  networks?: Network[];
  type: RelatedCreativeType;
  productionAssetUrl?: string;
};

export type GetRelatedCreativesPath = {
  projectId: UUID;
  // the baseCreativeId can be a production concept ID or a launched asset ID
  baseCreativeId: UUID;
};

export type GetRelatedCreativesResponse = {
  parent?: RelatedCreative;
  children: RelatedCreative[];
};

// -------------- Get Related Creatives - End   --------------

// VIDEO PRODUCTION TEMPLATES

export enum VideoGenerationTemplate {
  backgammon1 = 'backgammon1',
  royalmatch1 = 'royalmatch1',
}

// -------------- Generate Davinci Resolve Project - Start  --------------

export type GenerateDavinciResolveProjectRequest = Record<string, unknown>;

export type GenerateDavinciResolveProjectRequestBody = {
  gameplayPath: string;
  newProjectName: string;
  prompt: string;
  template?: VideoGenerationTemplate;
  numberGenerations?: number;
};

export type GenerateDavinciResolveProjectPath = {
  projectId: UUID;
};

export type GenerateDavinciResolveProjectResponse = {
  newProjectPath: string | string[];
};

// -------------- Generate Davinci Resolve Project - End  --------------
