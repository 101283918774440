import type { Timeframe } from '@replai-platform/sdk';

/**
 * Calculates the most probable timeframe in which the tag is present. For that, it considers
 * the minimum timeframe because it reduces the possibility of returning a start time in which
 * the tag does not appear.
 *
 * @param timeframes An array containing multiple timeframes on which a given tag appears
 * @returns The most probable start time in which the tag is present
 */
export const calculateClusterViewPreviewVideoStartTime = (
  timeframes?: Timeframe[]
): string => {
  if (!timeframes || !timeframes.length) {
    return '0';
  }

  const minTimeframeIndex: number = timeframes.reduce(
    (acc, elem, index) =>
      acc[0] === -1 || acc[1] > elem.endSeconds - elem.startSeconds
        ? [index, elem.endSeconds - elem.startSeconds]
        : acc,
    [-1, -1] as number[]
  )[0];

  return (
    (timeframes[minTimeframeIndex].startSeconds +
      timeframes[minTimeframeIndex].endSeconds) /
    2
  ).toFixed(1);
};
