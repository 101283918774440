/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { ReactNode, useEffect, useState } from 'react';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import '../common.module.css';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import * as Icons from '../Icons';
import Input from '../Input/Input';
import LoadingScreen, { MessageDelay } from '../LoadingScreen/LoadingScreen';
import MultiSelectDropDown, {
  MultiSelectDropDownOption,
} from '../MultiSelectDropDown/MultiSelectDropDown';
import SectionHeader from '../SectionHeader/SectionHeader';
import * as Styled from './styles';

type SlideoutMenuSection = {
  id?: string;
  title?: string;
};

type MultiSelectDropDownMenuOption = {
  options: MultiSelectDropDownOption[];
  limit?: number;
  onChange?: (options: MultiSelectDropDownOption[]) => void;
  collapsedByDefault?: boolean;
  singleSelect?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & SlideoutMenuSection;

type RangeInputMenuOption = {
  minValue?: number;
  maxValue?: number;
  minPlaceholder: string;
  maxPlaceholder?: string;
} & SlideoutMenuSection;

type CheckboxMenuOption = {
  label: string;
  active?: boolean;
  onChange?: (checked: boolean) => void;
} & SlideoutMenuSection;

type CustomOption = {
  render: () => ReactNode;
} & SlideoutMenuSection;

interface SlideoutMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle: string;
  closeButton?: {
    icon: Icons.BaseIconTypes;
    label?: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  };
  closeHeaderButton?: {
    icon: Icons.BaseIconTypes;
    label?: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  };
  saveButton?: {
    icon?: Icons.BaseIconTypes;
    label?: string;
    disabled?: boolean;
    onClick: (
      multiSelectOptions: MultiSelectDropDownMenuOption[],
      rangeInput: RangeInputMenuOption[],
      checkboxOption: CheckboxMenuOption[]
    ) => void;
  };
  menuSections: (
    | MultiSelectDropDownMenuOption
    | RangeInputMenuOption
    | CheckboxMenuOption
    | CustomOption
  )[];
  onCollapseClick?: (section: MultiSelectDropDownMenuOption) => void;
  loading?: {
    messages: string[];
    firstMessageDelay?: MessageDelay;
  } | null;
}

const sectionBody = (
  section:
    | MultiSelectDropDownMenuOption
    | RangeInputMenuOption
    | CheckboxMenuOption
    | CustomOption,
  onChangeMultiSelect: (section: MultiSelectDropDownMenuOption) => void,
  onChangeRangeInput: (section: RangeInputMenuOption) => void,
  onChangeCheckbox: (section: CheckboxMenuOption) => void,
  singleSelectOptions: MultiSelectDropDownOption[],
  setSingleSelectOptions: React.Dispatch<
    React.SetStateAction<MultiSelectDropDownOption[]>
  >,
  onCollapseClick?: (section: MultiSelectDropDownMenuOption) => void
) => {
  if ((section as MultiSelectDropDownMenuOption).options !== undefined) {
    // it's a dropdown section
    return (
      <Styled.DropdownSectionContainer>
        {(section as MultiSelectDropDownMenuOption).singleSelect ? (
          <DropDownMenu
            title={section.title ?? ''}
            options={singleSelectOptions?.map((o) => ({
              ...o,
              onClick: (e) => {
                setSingleSelectOptions((options) =>
                  options.map((o) => ({
                    ...o,
                    selected: (e.target as any).id === o.label,
                  }))
                );
                onChangeMultiSelect({
                  ...(section as MultiSelectDropDownMenuOption),
                  options: singleSelectOptions.map((o) => ({
                    ...o,
                    selected: (e.target as any).id === o.label,
                  })),
                });
              },
            }))}
            hasInput={false}
            includeContainerCard={false}
            collapsable
            collapsedByDefault={
              (section as MultiSelectDropDownMenuOption).collapsedByDefault
            }
            onCollapseClick={(isCollapsed) =>
              !isCollapsed
                ? onCollapseClick?.(section as MultiSelectDropDownMenuOption)
                : null
            }
            selected={singleSelectOptions.filter((o) => o.selected)?.[0]?.label}
          />
        ) : (
          <MultiSelectDropDown
            title={section.title}
            options={(section as MultiSelectDropDownMenuOption).options}
            onChange={(options) =>
              onChangeMultiSelect({
                ...(section as MultiSelectDropDownMenuOption),
                options,
              })
            }
            input={{
              leadingIcon: 'Search',
              placeholder: 'Search',
            }}
            includeContainerCard={false}
            collapsable
            collapsedByDefault={
              (section as MultiSelectDropDownMenuOption).collapsedByDefault
            }
            onCollapseClick={(isCollapsed) =>
              !isCollapsed
                ? onCollapseClick?.(section as MultiSelectDropDownMenuOption)
                : null
            }
            limit={(section as MultiSelectDropDownMenuOption).limit}
          />
        )}
      </Styled.DropdownSectionContainer>
    );
  }
  if ((section as RangeInputMenuOption).minPlaceholder !== undefined) {
    // it's a range input section
    return (
      <Styled.RangeInputContainer>
        {(section as RangeInputMenuOption).minPlaceholder !== undefined ? (
          <Input
            fullWidth
            type="number"
            defaultValue={(section as RangeInputMenuOption).minValue}
            placeholder={(section as RangeInputMenuOption).minPlaceholder}
            onChange={(e) =>
              onChangeRangeInput({
                ...(section as RangeInputMenuOption),
                minValue: parseInt(e.currentTarget.value, 10),
              })
            }
          />
        ) : null}
        {(section as RangeInputMenuOption).maxPlaceholder !== undefined ? (
          <Input
            fullWidth
            type="number"
            style={{ marginLeft: 12 }}
            defaultValue={(section as RangeInputMenuOption).maxValue}
            placeholder={(section as RangeInputMenuOption).maxPlaceholder}
            onChange={(e) =>
              onChangeRangeInput({
                ...(section as RangeInputMenuOption),
                maxValue: parseInt(e.currentTarget.value, 10),
              })
            }
          />
        ) : null}
      </Styled.RangeInputContainer>
    );
  }
  if ((section as CheckboxMenuOption).active !== undefined) {
    // it's a checkbox section
    return (
      <Checkbox
        checked={(section as CheckboxMenuOption).active}
        label={(section as CheckboxMenuOption).label}
        onClick={(checked) =>
          onChangeCheckbox({
            ...(section as CheckboxMenuOption),
            active: checked,
          })
        }
      />
    );
  }
  if ((section as CustomOption).render !== undefined) {
    // it's a custom component
    return (section as CustomOption).render();
  }

  return <div />;
};

const SlideoutMenu = ({
  title,
  subtitle,
  closeButton,
  closeHeaderButton,
  saveButton,
  menuSections,
  loading,
  onCollapseClick,
}: SlideoutMenuProps) => {
  const [multiSelectOptions, setMultiSelectOptions] = useState(
    menuSections.filter(
      (s) => (s as MultiSelectDropDownMenuOption).options !== undefined
    )
  );
  const [singleSelectOptions, setSingleSelectOptions] = useState(
    (
      multiSelectOptions.filter(
        (s) => (s as MultiSelectDropDownMenuOption).singleSelect
      )?.[0] as MultiSelectDropDownMenuOption
    )?.options ?? []
  );
  const [rangeInput, setRangeInputValues] = useState(
    menuSections.filter(
      (s) => (s as RangeInputMenuOption).minPlaceholder !== undefined
    )
  );
  const [checkboxOption, setCheckboxOption] = useState(
    menuSections.filter((s) => (s as CheckboxMenuOption).active !== undefined)
  );

  const [, setCustomOption] = useState(
    menuSections.filter((s) => (s as CustomOption).render !== undefined)
  );

  useEffect(() => {
    setMultiSelectOptions(
      menuSections.filter(
        (s) => (s as MultiSelectDropDownMenuOption).options !== undefined
      )
    );
    setRangeInputValues(
      menuSections.filter(
        (s) => (s as RangeInputMenuOption).minPlaceholder !== undefined
      )
    );
    setCheckboxOption(
      menuSections.filter((s) => (s as CheckboxMenuOption).active !== undefined)
    );
    setCustomOption(
      menuSections.filter((s) => (s as CustomOption).render !== undefined)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(menuSections)]);

  const onChangeMultiselectOptions = (
    section: MultiSelectDropDownMenuOption
  ) => {
    const newOptions = [...multiSelectOptions];
    const sectionIndex = newOptions.findIndex((s) => s.id === section.id);
    if (sectionIndex !== -1) {
      newOptions[sectionIndex] = section;
      setMultiSelectOptions(newOptions);
    }
  };

  const onChangeCheckbox = (section: CheckboxMenuOption) => {
    const newOptions = [...multiSelectOptions];
    const sectionIndex = newOptions.findIndex((s) => s.id === section.id);
    if (sectionIndex !== -1) {
      newOptions[sectionIndex] = section;
      setCheckboxOption(newOptions);
    }
  };

  const onChangeRangeInput = (section: RangeInputMenuOption) => {
    const newOptions = [...rangeInput];
    const sectionIndex = newOptions.findIndex((s) => s.id === section.id);
    if (sectionIndex !== -1) {
      newOptions[sectionIndex] = section;
      setRangeInputValues(newOptions);
    }
  };

  return (
    <Styled.Container size="md">
      <SectionHeader
        title={title}
        subtitle={subtitle}
        iconButton={closeHeaderButton || closeButton}
        withDivider={false}
        withPaddingBottom={false}
      />
      {loading ? (
        <>
          <Styled.LoadingScreenWrapper>
            <LoadingScreen
              messages={loading.messages}
              firstMessageDelay={loading.firstMessageDelay}
            />
          </Styled.LoadingScreenWrapper>
          <Styled.ClearFloat />
        </>
      ) : (
        menuSections.map((section) => (
          <React.Fragment key={section.id}>
            {'options' in section ? (
              ''
            ) : (
              <Styled.Title>{section.title}</Styled.Title>
            )}
            {sectionBody(
              section,
              onChangeMultiselectOptions,
              onChangeRangeInput,
              onChangeCheckbox,
              singleSelectOptions,
              setSingleSelectOptions,
              onCollapseClick
            )}
          </React.Fragment>
        ))
      )}
      <Styled.Footer>
        <Button
          size="sm"
          variant="outlined"
          color="Gray"
          onClick={closeButton?.onClick}
        >
          {closeButton?.label ?? 'Cancel'}
        </Button>
        <Button
          data-test="slideout-menu-save-button"
          size="sm"
          leadingIcon={
            saveButton?.icon ? { name: saveButton?.icon } : undefined
          }
          disabled={saveButton?.disabled || false}
          onClick={() =>
            saveButton?.onClick(
              [
                ...multiSelectOptions,
                ...singleSelectOptions,
              ] as MultiSelectDropDownMenuOption[],
              rangeInput as RangeInputMenuOption[],
              checkboxOption as CheckboxMenuOption[]
            )
          }
        >
          {saveButton?.label ?? 'Save'}
        </Button>
      </Styled.Footer>
    </Styled.Container>
  );
};

export default SlideoutMenu;
export type {
  SlideoutMenuProps,
  MultiSelectDropDownMenuOption,
  RangeInputMenuOption,
  CheckboxMenuOption,
  CustomOption,
};
