import { CountryIconProps } from './types';

const KM = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3598)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.9994 11.9653H10.9911C8.40842 14.548 6.84664 16.542 5.25195 18.1367L12.0346 18.7479L22.5596 17.7044C23.4781 16.0078 23.9998 14.0649 23.9998 12.0001C23.9998 11.9884 23.9994 11.977 23.9994 11.9653Z"
        fill="#D80027"
      />
      <path
        d="M5.25272 6.22564H22.522C20.4812 2.51464 16.5348 -0.000488281 12.0005 -0.000488281C8.69559 -0.000488281 5.70328 1.33597 3.5332 3.49751L5.25272 6.22564Z"
        fill="#FFDA44"
      />
      <path
        d="M5.77327 17.704C4.69449 18.7828 4.27759 19.6866 3.49707 20.4671C5.66996 22.649 8.67676 23.9996 11.9994 23.9996C16.562 23.9996 20.5297 21.4531 22.5592 17.704H5.77327Z"
        fill="#0052B4"
      />
      <path
        d="M3.51471 3.48035C-1.17157 8.16663 -1.17157 15.7646 3.51471 20.451C5.45126 18.5144 7.31374 16.6519 12 11.9657L3.51471 3.48035Z"
        fill="#6DA544"
      />
      <path
        d="M3.16584 11.9647C3.16584 10.2163 4.39457 8.75575 6.03538 8.39744C5.78319 8.34236 5.52154 8.3125 5.25276 8.3125C3.23568 8.3125 1.60059 9.9476 1.60059 11.9647C1.60059 13.9818 3.23568 15.6169 5.25276 15.6169C5.52149 15.6169 5.78315 15.587 6.03538 15.5319C4.39457 15.1736 3.16584 13.713 3.16584 11.9647Z"
        fill="#F0F0F0"
      />
      <path
        d="M5.99574 8.83472L6.19004 9.43256H6.81863L6.31004 9.80208L6.50434 10.3999L5.99574 10.0305L5.48715 10.3999L5.68145 9.80208L5.17285 9.43256H5.80145L5.99574 8.83472Z"
        fill="#F0F0F0"
      />
      <path
        d="M5.99574 10.3998L6.19004 10.9977H6.81863L6.31004 11.3671L6.50434 11.965L5.99574 11.5955L5.48715 11.965L5.68145 11.3671L5.17285 10.9977H5.80145L5.99574 10.3998Z"
        fill="#F0F0F0"
      />
      <path
        d="M5.99574 11.9646L6.19004 12.5624H6.81863L6.31004 12.932L6.50434 13.5298L5.99574 13.1603L5.48715 13.5298L5.68145 12.932L5.17285 12.5624H5.80145L5.99574 11.9646Z"
        fill="#F0F0F0"
      />
      <path
        d="M5.99574 13.5297L6.19004 14.1275H6.81863L6.31004 14.497L6.50434 15.0949L5.99574 14.7254L5.48715 15.0949L5.68145 14.497L5.17285 14.1275H5.80145L5.99574 13.5297Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3598">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default KM;
