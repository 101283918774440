import styled from 'styled-components';
import Colors from '../Colors';
import Shadow from '../Shadow/Shadow';

export const MetricItem = styled(Shadow)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 0.75rem;
  background-color: white;
  border: 1px solid ${Colors.Gray[200]};
  border-radius: 0.5rem;
`;

export const MetricName = styled.span`
  color: ${Colors.Gray[500]};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const MetricValue = styled.span`
  color: ${Colors.Gray[700]};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const MetricValueExtra = styled.span`
  color: ${Colors.Gray[500]};
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  vertical-align: middle;
`;
