import { CountryIconProps } from './types';

const SS = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3707)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.0634 7.34694C21.2461 3.03018 16.9771 -0.000854492 11.9995 -0.000854492C8.68576 -0.000854492 5.68609 1.34254 3.51465 3.51425L6.51981 7.34694H23.0634Z"
        fill="black"
      />
      <path
        d="M6.55388 16.6075L3.51465 20.4837C5.68609 22.6554 8.68576 23.9988 11.9995 23.9988C16.9939 23.9988 21.2751 20.9475 23.0819 16.6075H6.55388Z"
        fill="#496E2D"
      />
      <path
        d="M2.6084 8.82605V15.1304H23.5864C23.8555 14.1323 23.9997 13.0831 23.9997 12C23.9997 10.9011 23.8517 9.83705 23.575 8.82605H2.6084Z"
        fill="#A2001D"
      />
      <path
        d="M3.51471 3.51465C-1.17157 8.20093 -1.17157 15.7989 3.51471 20.4853C5.45126 18.5487 7.31375 16.6862 12 12L3.51471 3.51465Z"
        fill="#0052B4"
      />
      <path
        d="M3.90889 9.01648L5.3735 11.0603L7.77013 10.2988L6.27899 12.3235L7.7436 14.3674L5.35728 13.5748L3.8661 15.5995L3.8825 13.085L1.49609 12.2923L3.89253 11.5309L3.90889 9.01648Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3707">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SS;
