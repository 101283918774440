import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getConceptPerformance = async (requestParams: SDK.GetAssetsMetricsRequest) => {
  const { conceptId, ...rest } = requestParams;
  const res = await provider.getJson(
    `${SDK.BASE_URL()}/concepts/${conceptId ?? ''}/performance?${SDK.convertRequestToQueryParams(rest)}`
  );
  return res;
};

export default function useConceptPerformance(
  requestParams: SDK.GetAssetsMetricsRequest & { conceptId: SDK.UUID },
  options?: UseQueryOptions<any>
) {
  return useQuery(['concept-performance', requestParams], () => getConceptPerformance(requestParams), options);
}
