/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useQuery, UseQueryOptions } from 'react-query';
import { BASE_URL } from '@replai-platform/sdk';
import * as provider from '../../api/provider';

export interface GetAssetPreviewInfoResponse {
  previewUrl?: string;
  thumbnailUrl?: string;
}

const getPlayablePreview = async (requestParams: { projectId: string; assetId: string; size: string }) => {
  const { assetId, projectId, size } = requestParams;
  const res = await provider.getJson<object, GetAssetPreviewInfoResponse>(`${BASE_URL()}/assets/playablePreview`, {
    method: 'POST',
    body: {
      assetId,
      projectId,
      size,
    },
  });
  return res;
};

export default function usePlayablePreview(
  requestParams: { projectId: string; assetId: string; size: string },
  options?: UseQueryOptions<GetAssetPreviewInfoResponse>
) {
  return useQuery(['playable-preview-info', requestParams], () => getPlayablePreview(requestParams), options);
}
