import * as SDK from '@replai-platform/sdk';
import { TagCard, TagCardProps } from '@replai-platform/ui-components';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MAX_PREVIEW_ITEMS } from '../../Tags/Library/utils';
import { getTagIconDefinition } from '../../../utils';
import { RootState } from '../../../store/rootReducer';
import { logEventOnAction } from './utils';
import useTagPreviewInfo from '../../../api/hooks/tags/useTagPreviewInfo';

export type TagValue = TagCardProps & {
  tagId: SDK.UUID;
  rawTagType: string;
  kind: string;
};

type TagFrequency = {
  tagId: SDK.UUID;
  frequency: number;
};

const hasTagIcon = ({ type, value }: { type: string; value: string | null }) =>
  !!getTagIconDefinition({
    type,
    value,
  })?.iconName;

const getTagPreview = ({ tagPreviews, tagId }: { tagPreviews: SDK.TagPreviewItem[]; tagId: string }) => {
  const tagPreview = (tagPreviews ?? []).find(({ tagId: id }) => id === tagId);
  const videoPreview = tagPreview?.videos?.find((v) => !!v.timeframes?.length) ?? null;

  return {
    thumbnailUrl: tagPreview?.thumbnails?.[0]?.imageUrl ?? '',
    ...(videoPreview && {
      videoUrl: videoPreview.url,
      startSeconds: videoPreview.timeframes?.[0]?.startSeconds ?? 0,
      endSeconds: videoPreview.timeframes?.[0]?.endSeconds ?? 3,
    }),
  };
};

const TagCardsPage = ({
  pageTagValues,
  selectedTagType,
  tagsFrequency,
}: {
  pageTagValues: TagValue[];
  selectedTagType: string;
  tagsFrequency?: TagFrequency[];
}) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const organizationName = useSelector((state: RootState) => state.project.organizationName);
  const { marketAppsToConsider, marketAppsToExclude, ageStartDate, ageEndDate } = useSelector(
    (state: RootState) => state.filters
  );
  const maxFirstAppearanceSeconds =
    useSelector((state: RootState) => state.filters.maxFirstAppearanceSeconds) ?? undefined;
  const includeInactiveFirstPartyAssetsInMarketYouFrequency = useSelector(
    (state: RootState) => state.project.config.includeInactiveFirstPartyAssetsInMarketYouFrequency
  );

  // Fetch tag previews.
  const previewInfoParams = useMemo<SDK.GetTagsPreviewsRequest>(
    () => ({
      projectIds: [projectId],
      tagIds: pageTagValues
        .filter(({ title, rawTagType }) => !hasTagIcon({ type: rawTagType, value: title === undefined ? null : title }))
        .map(({ tagId }) => tagId),
      isMarketData: true,
      adTagsFilters: { maxFirstAppearanceSeconds },
      appsFilters: {
        appIdsToInclude: marketAppsToConsider.map((ma) => ma.id),
        appIdsToExclude: marketAppsToExclude.map((ma) => ma.id),
        publishersToExclude: [organizationName],
      },
      maxRecords: MAX_PREVIEW_ITEMS,
      startDate: ageStartDate ?? '',
      endDate: ageEndDate ?? '',
    }),
    [
      projectId,
      pageTagValues,
      marketAppsToConsider,
      marketAppsToExclude,
      organizationName,
      maxFirstAppearanceSeconds,
      ageStartDate,
      ageEndDate,
    ]
  );
  const { data: { tags: tagPreviews } = {}, isLoading: isTagPreviewsLoading } = useTagPreviewInfo(previewInfoParams, {
    enabled: (previewInfoParams.tagIds?.length ?? 0) > 0,
  });

  return (
    <>
      {pageTagValues.map(({ tagId, ...tag }) => {
        const { thumbnailUrl, videoUrl, startSeconds, endSeconds } = getTagPreview({
          tagPreviews: tagPreviews ?? [],
          tagId,
        });
        const clientFrequency = (tagsFrequency || []).find((t) => t.tagId === tagId)?.frequency ?? 0;

        return (
          <TagCard
            key={tagId}
            variant="frequency"
            clientFrequency={tagsFrequency ? clientFrequency : undefined}
            clientFrequencyActiveVideos={!includeInactiveFirstPartyAssetsInMarketYouFrequency}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...tag}
            thumbnailLoading={isTagPreviewsLoading}
            thumbnailUrl={thumbnailUrl}
            videoUrl={videoUrl ? new URL(videoUrl) : undefined}
            startSeconds={startSeconds}
            endSeconds={endSeconds}
            onToggleHoverPreview={(enabled) => enabled && logEventOnAction({ action: 'Hover Tag' })}
            alwaysShowSubtitle={!selectedTagType}
            isFiltered={!!marketAppsToConsider?.length}
          />
        );
      })}
    </>
  );
};

export default TagCardsPage;
