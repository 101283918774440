import React from 'react';
import { To, useHref, useLinkClickHandler } from 'react-router-dom';

const Link: React.FC<{
  component?: React.ReactElement;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  replace?: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  state?: any;
  target?: React.HTMLAttributeAnchorTarget;
  to: To;
}> = ({ component, children, onClick, replace, state, target, to }) => {
  const href = useHref(to);
  const handleClick = useLinkClickHandler(to, {
    replace,
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
    state,
    target,
  });

  const componentProps: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> = {
    href,
    onClick: (event) => {
      onClick?.(event);
      if (!event.defaultPrevented) {
        handleClick(event);
      }
    },
    target,
    children,
  };

  return component ? (
    React.cloneElement(component, componentProps)
  ) : (
    <a href={href} onClick={onClick} target={target}>
      {children}
    </a>
  );
};

export default Link;
