import * as SDK from '@replai-platform/sdk';

export type AssetToCompare = ConceptToCompare | MarketAssetToCompare;

export type ConceptToCompare = {
  type: 'concept';
  id: string;
  displayName?: string;
  name: string;
  spend: number;
  totalSpend: number;
  installs: number;
  age?: string;
  ipm: number;
  ctr: number;
  maxDate: string;
};

export type MarketAssetToCompare = {
  type: 'market';
  id: string;
  name: string;
  previewUrl: string;
  appPublisher: string;
  launchDate: string;
  isActive: boolean;
};

export type HiddenAsset = {
  id: string;
  name: string;
  spend?: string;
  isActive: boolean;
  launchDate?: string;
};

export enum KpiState {
  WINNING = 'winning',
  LOSING = 'losing',
  NEUTRAL = 'neutral',
}

export type TimelineData = SDK.MarketAssetWithTags | SDK.ConceptTags;
