import { Ref } from 'react';
import ReactPlayer from 'react-player';
import format from 'date-fns/format';
import ActiveIndicator from '../ActiveIndicator/ActiveIndicator';
import Colors, { ColorTypes } from '../Colors';
import { BaseIcons } from '../Icons';
import LinearProgress from '../LinearProgress/LinearProgress';
import SkeletonLoading from '../Skeleton/Skeleton';
import Typography from '../Typography/Typography';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import * as Styled from './styles';

type Status = 'winning' | 'losing' | 'neutral';
type Metric = {
  name: string;
  value: string;
  useBadge?: boolean;
  status?: Status;
};
type PercentageValue = {
  label: string;
  value: string;
  percentage: number;
};

export type VideoModuleProps = {
  name?: string;
  videoUrl?: string;
  percentageValue?: PercentageValue;
  metrics?: Metric[];
  date?: string;
  isActive?: boolean;
  reference: boolean;
  player?: Ref<ReactPlayer>;
  isPlaying?: boolean;
  showMetrics?: boolean;
  noCustomMetrics?: boolean;
  noPercentage?: boolean;
  onChangeRadioButton?: () => void;
  onClickEyeIcon?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onProgress?: (progress: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => void;
  onEnded?: () => void;
};

const getBadgeColor = (status?: Status): ColorTypes =>
  ({ winning: 'Success', losing: 'Error', neutral: 'Gray' }[
    status ?? 'neutral'
  ] as ColorTypes);

const getMetricColor = (status?: Status) =>
  ({
    winning: Colors.Success[700],
    losing: Colors.Error[700],
    neutral: Colors.Gray[700],
  }[status ?? 'neutral']);

const VideoModule = ({
  name,
  videoUrl,
  percentageValue,
  metrics,
  date,
  isActive,
  reference,
  player,
  isPlaying = false,
  showMetrics = true,
  noCustomMetrics = false,
  noPercentage = false,
  onChangeRadioButton,
  onClickEyeIcon,
  onPlay,
  onPause,
  onProgress,
  onEnded,
}: VideoModuleProps) => {
  const renderMetricLabel = (label: string) => (
    <Typography noMargin type="text-xs" color={Colors.Gray[500]}>
      {label}
    </Typography>
  );

  const renderMetricValue = ({
    value,
    status: metricStatus,
    useBadge,
  }: Omit<Metric, 'name'>) =>
    useBadge ? (
      <Styled.StyledBadge size="sm" color={getBadgeColor(metricStatus)}>
        {value ?? ''}
      </Styled.StyledBadge>
    ) : (
      <Styled.MetricTypography
        noMargin
        type="text-sm"
        fontWeight="medium"
        color={getMetricColor(metricStatus)}
      >
        {value ?? ''}
      </Styled.MetricTypography>
    );

  const renderDate = (value: string) => (
    <Styled.MetricTypography noMargin type="text-sm" fontWeight="medium">
      {format(new Date(value), 'yyyy-MM-dd') ?? ''}
    </Styled.MetricTypography>
  );

  const renderPercentageValue = (percentageValue: PercentageValue) => {
    if (!percentageValue.value) return;
    return (
      <>
        {renderMetricLabel(percentageValue.label)}
        <Styled.SpendContainer>
          {renderMetricValue({ value: percentageValue?.value ?? '' })}
          <LinearProgress value={percentageValue?.percentage ?? 0} />
        </Styled.SpendContainer>
      </>
    );
  };

  const renderMetricSkeleton = (height = '2.5rem') => (
    <SkeletonLoading height={height} />
  );

  const renderVideoView = () => (
    <>
      <Styled.VideoContainer>
        {videoUrl ? (
          <VideoPlayer
            url={videoUrl ?? ''}
            playing={isPlaying}
            playerRef={player}
            onPlay={() => onPlay?.()}
            onPause={() => onPause?.()}
            onProgress={(progress) => onProgress?.(progress)}
            onEnded={() => onEnded?.()}
            title={name}
          />
        ) : (
          <Styled.VideoSkeletonLoading height="100%" />
        )}
      </Styled.VideoContainer>
      <Styled.MetricsContainer show={showMetrics}>
        {noPercentage ? undefined : (
          <Styled.MetricLine>
            {percentageValue
              ? renderPercentageValue(percentageValue)
              : renderMetricSkeleton('1.75rem')}
          </Styled.MetricLine>
        )}
        {metrics && !noCustomMetrics ? (
          <Styled.CustomMetricsContainer>
            {metrics?.map(({ name: metricLabel, ...metric }) => (
              <Styled.CustomMetric key={metricLabel}>
                {renderMetricLabel(metricLabel)}
                {renderMetricValue(metric)}
              </Styled.CustomMetric>
            ))}
          </Styled.CustomMetricsContainer>
        ) : undefined}
        {!metrics && !noCustomMetrics ? renderMetricSkeleton() : undefined}
        {date ? (
          <Styled.DateContainer>
            {renderMetricLabel('Launch Date')}
            {renderDate(date)}
          </Styled.DateContainer>
        ) : undefined}
      </Styled.MetricsContainer>
    </>
  );

  const ariaLabelId = reference ? 'reference' : 'make-reference';
  return (
    <Styled.Container data-test="video-module" reference={reference}>
      <Styled.NameContainer>
        <input
          type="radio"
          aria-labelledby={ariaLabelId}
          checked={reference}
          style={{ accentColor: Colors.Primary[700] }}
          onChange={!reference ? onChangeRadioButton : undefined}
          readOnly={reference}
        />
        <Typography
          noMargin
          id={ariaLabelId}
          type="text-sm"
          fontWeight="medium"
          color={Colors.Gray[700]}
        >
          {reference ? 'Reference' : 'Make reference'}
        </Typography>
        <Styled.RightAlignedContainer
          onClick={() => {
            onClickEyeIcon?.();
          }}
          role="button"
          aria-label="Hide Video"
          reference={reference}
        >
          <BaseIcons.EyeOff color={Colors.Gray[500]} dimension={18} />
        </Styled.RightAlignedContainer>
      </Styled.NameContainer>
      <Styled.MarginContainer>
        <Styled.Header>
          <Styled.NameContainer>
            {name ? (
              <ActiveIndicator color={isActive ? 'Success' : 'Error'} />
            ) : null}
            <Styled.NameLabelContainer>
              <Styled.Name
                noMargin
                type="text-sm"
                fontWeight="medium"
                color={Colors.Gray[700]}
              >
                {name}
              </Styled.Name>
            </Styled.NameLabelContainer>
          </Styled.NameContainer>
        </Styled.Header>
        {renderVideoView()}
      </Styled.MarginContainer>
    </Styled.Container>
  );
};

export default VideoModule;
