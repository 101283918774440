import { useMemo } from 'react';
import type { DropDownMenuOption } from '@replai-platform/ui-components';
import * as SDK from '@replai-platform/sdk';

const useBreakdownFilter = ({
  onChange,
}: {
  onChange: (value: SDK.Breakdown) => void;
}): { breakdownFilterOptions: DropDownMenuOption[] } => {
  // Create filter options
  const breakdownFilterOptions = useMemo(
    () => [
      {
        label: 'Network',
        selected: true,
        onClick: () => onChange('network'),
      },
      {
        label: 'Country',
        selected: false,
        onClick: () => onChange('country'),
      },
    ],
    []
  );

  return { breakdownFilterOptions };
};

export default useBreakdownFilter;
