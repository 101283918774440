/* eslint-disable react/jsx-props-no-spreading */
import { IconProps } from './types';

const PauseCircle2 = ({ dimension, ...rest }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10 15V9M14 15V9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      fill="none"
      stroke="rgba(0,0,0,0.05)"
      strokeWidth={2}
    />
  </svg>
);

export default PauseCircle2;
