import React from 'react';
import { capitalizeFirstLetter } from '../../utils';
import Colors from '../Colors';
import '../common.module.css';
import { formatMetricValue } from '../MetricItem/utils';
import Tooltip from '../Tooltip/Tooltip';
import * as Styled from './styles';

interface MetricsSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  metrics: Metrics[];
}

interface Metrics {
  name: string;
  value?: number;
  isPercentage?: boolean;
  status?: 'up' | 'down';
  goodness?: 'good' | 'bad';
  delta?: number;
}

const MetricsSection = ({ metrics }: MetricsSectionProps) => {
  const calculateColor = (goodness?: 'good' | 'bad') => {
    if (!goodness) return 'Gray';
    return goodness === 'good' ? 'Success' : 'Error';
  };

  const renderBadge = (
    status: 'up' | 'down',
    goodness?: 'good' | 'bad',
    noBackground = false
  ) => (
    <Styled.Badge
      leadingIcon={
        `Arrow${capitalizeFirstLetter(status)}` as 'ArrowUp' | 'ArrowDown'
      }
      color={calculateColor(goodness)}
      noBackground={noBackground}
      size="sm"
    />
  );

  const tooltipContent = (
    delta: number,
    status: 'up' | 'down',
    goodness?: 'good' | 'bad'
  ) => (
    <div>
      <Styled.BadgeContainer>
        {renderBadge(status, goodness, true)}
      </Styled.BadgeContainer>
      <Styled.Delta
        color={Colors[calculateColor(goodness)][!goodness ? 400 : 700]}
        type="text-sm"
      >
        {delta}%
      </Styled.Delta>
    </div>
  );

  const renderMetric = ({
    name,
    value,
    isPercentage,
    status,
    goodness,
    delta,
  }: Metrics) => (
    <Styled.Metric key={name}>
      <Styled.Name type="text-xs" color="#667085">
        {name}
      </Styled.Name>
      <Styled.Value>
        <Styled.ValueText type="text-md" fontWeight="semi-bold">
          {value != null
            ? `${formatMetricValue(value)}${isPercentage ? '%' : ''}`
            : 'N/A'}
        </Styled.ValueText>
        {status && (
          <Styled.BadgeContainer>
            <Tooltip
              placement="top"
              content={delta ? tooltipContent(delta, status, goodness) : null}
            >
              {renderBadge(status, goodness)}
            </Tooltip>
          </Styled.BadgeContainer>
        )}
      </Styled.Value>
    </Styled.Metric>
  );

  return (
    <Styled.MetricsSection data-test="kpis-container">
      {metrics.map((metric) => renderMetric(metric))}
    </Styled.MetricsSection>
  );
};

export default MetricsSection;
export type { MetricsSectionProps, Metrics };
