import { WYSIWYGProps } from './types';

const MDItalics = ({ color }: WYSIWYGProps) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.25 0V2.625H6.18375L3.19125 9.625H0.75V12.25H7.75V9.625H5.81625L8.80875 2.625H11.25V0H4.25Z"
      fill={color || '#98A2B3'}
    />
  </svg>
);
export default MDItalics;
