import { Toggle } from '@replai-platform/ui-components';
import { ReactNode } from 'react';

export interface IntroInsightsToggleProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  tooltipContent?: ReactNode;
  secondaryLabel?: string;
}

const IntroInsightsToggle: React.VFC<IntroInsightsToggleProps> = ({
  checked,
  label,
  secondaryLabel,
  onChange,
  tooltipContent,
}) => (
  <Toggle
    data-test="filter-intro-insights-toggle"
    checked={checked}
    label={label}
    onChange={onChange}
    tooltipContent={tooltipContent}
    secondaryLabel={secondaryLabel}
    variant="outlined"
  />
);

export default IntroInsightsToggle;
