import { CommonTagIconProps } from '../types';

const HighSceneTransitionsDiff = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2439 16.813C19.9021 16.813 22.0569 14.6582 22.0569 12C22.0569 9.34187 19.9021 7.18701 17.2439 7.18701C14.5858 7.18701 12.4309 9.34187 12.4309 12C12.4309 14.6582 14.5858 16.813 17.2439 16.813Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.27994 14.5952C7.59994 15.2752 6.66994 15.6952 5.63994 15.6952C3.59994 15.6952 1.93994 14.0452 1.93994 11.9952C1.93994 9.95518 3.59994 8.30518 5.63994 8.30518C6.66994 8.30518 7.59994 8.72518 8.27994 9.40518"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.33 14.8052C12.68 15.3652 11.84 15.6952 10.92 15.6952C9.88997 15.6952 8.94997 15.2752 8.27997 14.5952C7.61997 13.9252 7.21997 13.0052 7.21997 11.9952C7.21997 10.9852 7.61997 10.0752 8.27997 9.40518C8.94997 8.72518 9.88997 8.30518 10.92 8.30518C11.84 8.30518 12.68 8.63518 13.33 9.19518"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.7859 10.2568L18.5299 11.9998L16.7859 13.7438"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default HighSceneTransitionsDiff;
