/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Colors } from '..';
import { loaderTransition } from './animations';
import AnimatedCharacters from './AnimatedCharacters';

const container = {
  visible: {
    transition: {
      staggerChildren: 0.025,
    },
  },
};

const Loading = styled(motion.div).attrs({
  initial: 'out',
  exit: 'out',
  variants: loaderTransition,
  includeMessage: false,
})`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(props) => (props.includeMessage ? '120%' : '100%')};
  text-align: center;
  pointer-events: none;
`;

type LoadingSize = 's' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const availableVariations = {
  s: { width: 15, height: 15, radius: 40, strokeWidth: 6, fontSize: 10 },
  sm: { width: 32, height: 32, radius: 40, strokeWidth: 6, fontSize: 10 },
  md: { width: 48, height: 48, radius: 40, strokeWidth: 7, fontSize: 12 },
  lg: { width: 56, height: 56, radius: 40, strokeWidth: 7, fontSize: 14 },

  xl: { width: 64, height: 64, radius: 40, strokeWidth: 8, fontSize: 16 },
  xxl: { width: 72, height: 72, radius: 40, strokeWidth: 8, fontSize: 18 },
};

const LoadingIcon = ({
  width = 50,
  height = 50,
  strokeWidth = 8,
  radius = 40,
}: {
  width?: number;
  height?: number;
  radius?: number;
  strokeWidth?: number;
}) => {
  const baseColor = Colors.Primary[600];
  return (
    // https://developer.mozilla.org/en-US/docs/Web/SVG/Element/circle
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: 'auto',
        background: 'none',
        display: 'block',
        shapeRendering: 'auto',
      }}
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={baseColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        r={radius}
        strokeDasharray="164.93361431346415 96.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="0.8s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
    </svg>
  );
};

export type MessageDelay = 0 | 1 | 2 | 3 | 4;
export interface LoadingScreenProps {
  loadingSize?: LoadingSize;
  messages?: string[];
  firstMessageDelay?: MessageDelay; // Status message will only be displayed if the container is loading for more than X seconds
}

/**
 *
 * @param props.showMessage boolean value indicating the presentation of waiting messages during a loading
 */
const LoadingScreen = ({
  messages = [],
  firstMessageDelay = 3,
  loadingSize = 'md',
}: LoadingScreenProps): JSX.Element => {
  const { width, height, radius, strokeWidth, fontSize } =
    availableVariations[loadingSize];
  const showMessage = messages.length > 0;
  const [replay, setReplay] = useState(true);
  const [waitingMessage, setWaitingMessage] = useState(
    showMessage ? messages[0] : ''
  );
  const [messageIndex, setMessageIndex] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [showMessageState, setShowMessage] = useState(showMessage);

  useEffect(() => {
    if (!showMessage) return;

    // Reset all stats before showing
    setMessageIndex(0);
    setWaitingMessage(messages[0]);
    setTotalTime(0);
    setShowMessage(false);
    // Only display message IF ('isDelayed' AND 'MESSAGE_DELAY' > X milliseconds)
    const interval = setInterval(() => {
      setShowMessage(true);
    }, firstMessageDelay * 1000); // Value for setInterval should be in milliseconds

    return () => {
      clearInterval(interval);
    };
  }, [firstMessageDelay, messages, showMessage]);

  // Message promp and flow control for animated characters
  const timeInterval = 100;
  useEffect(() => {
    if (!showMessageState) return;

    const interval = setInterval(() => {
      if (totalTime < 2000) {
        if (totalTime >= 1600) {
          setReplay(false);
        }
        setTotalTime(totalTime + timeInterval);
      } else {
        const newMessageindex =
          messageIndex + 1 >= messages.length ? 0 : messageIndex + 1;
        setMessageIndex(newMessageindex);
        setWaitingMessage(messages[newMessageindex]);
        setReplay(true);
        setTotalTime(0);
      }
    }, timeInterval);

    return () => {
      clearInterval(interval);
    };
  }, [totalTime, waitingMessage, messageIndex, showMessageState, messages]);

  return (
    <Loading includeMessage={showMessage} animate="in">
      <motion.div
        initial="hidden"
        animate={replay ? 'visible' : 'hidden'}
        variants={container}
        style={{ position: 'absolute' }}
      >
        <LoadingIcon
          width={width}
          height={height}
          radius={radius}
          strokeWidth={strokeWidth}
        />
        {showMessage && (
          <AnimatedCharacters
            visible={showMessageState}
            message={waitingMessage}
            type="p"
            fontSize={fontSize}
          />
        )}
      </motion.div>
    </Loading>
  );
};

export default LoadingScreen;
