import { DropDownChip, DropDownChipProps } from '@replai-platform/ui-components';

export interface BuyingStrategyFilterProps {
  options: DropDownChipProps['dropDownOptions'];
  onChange: DropDownChipProps['onChange'];
  loading: boolean;
  disabled?: boolean;
}

const BuyingStrategyFilter: React.VFC<BuyingStrategyFilterProps> = ({
  options,
  onChange,
  loading,
  disabled = false,
}) => (
  <DropDownChip
    data-test="filter-buyingstrat-dropdown"
    placeHolder="All Buying Strategies"
    defaultOption="All Buying Strategies"
    dropDownType="multiselect"
    dropDownOptions={options}
    dropDownAlignment="right"
    onChange={onChange}
    prefixLabel="Buying Strategies"
    disabled={disabled}
    loading={loading}
  />
);

export default BuyingStrategyFilter;
