import { CountryIconProps } from './types';

const BI = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3586)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M19.3067 2.48005C15.0105 -0.82586 8.98955 -0.82586 4.69336 2.48L12 9.78669L19.3067 2.48005Z"
        fill="#D80027"
      />
      <path
        d="M12 14.214L4.69336 21.5206C8.98955 24.8265 15.0105 24.8265 19.3067 21.5206L12 14.2139L12 14.214Z"
        fill="#D80027"
      />
      <path
        d="M9.78705 12.0003L2.48041 4.6936C-0.8255 8.98979 -0.8255 15.0108 2.48041 19.307L9.78705 12.0003Z"
        fill="#6DA544"
      />
      <path
        d="M14.2148 12.0003L21.5214 19.307C24.8273 15.0108 24.8273 8.98979 21.5214 4.6936L14.2148 12.0003Z"
        fill="#6DA544"
      />
      <path
        d="M12.0006 17.2178C14.8821 17.2178 17.218 14.8819 17.218 12.0005C17.218 9.11898 14.8821 6.78308 12.0006 6.78308C9.1191 6.78308 6.7832 9.11898 6.7832 12.0005C6.7832 14.8819 9.1191 17.2178 12.0006 17.2178Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 8.34827L12.4518 9.13089H13.3555L12.9037 9.91347L13.3555 10.6961H12.4518L12 11.4787L11.5482 10.6961H10.6445L11.0964 9.91347L10.6445 9.13089H11.5482L12 8.34827Z"
        fill="#D80027"
      />
      <path
        d="M9.70319 12.001L10.155 12.7836H11.0587L10.6068 13.5662L11.0587 14.3488H10.155L9.70319 15.1314L9.25131 14.3488H8.34766L8.79948 13.5662L8.34766 12.7836H9.25131L9.70319 12.001Z"
        fill="#D80027"
      />
      <path
        d="M14.2969 12.001L14.7488 12.7836H15.6524L15.2006 13.5662L15.6524 14.3488H14.7488L14.2969 15.1314L13.8451 14.3488H12.9414L13.3932 13.5662L12.9414 12.7836H13.8451L14.2969 12.001Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3586">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default BI;
