import { QueryFunction, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getStaticsMetrics = async (
  requestParams: SDK.GetStaticsMetricsRequest & { refreshCache?: boolean }
): Promise<SDK.GetStaticsMetricsResponse> => {
  const opts = {} as provider.SupportedHeaders;
  if (requestParams.refreshCache) {
    opts['cache-control'] = 'max-age=0';
  }
  const res = await provider.getJson<object, SDK.GetStaticsMetricsResponse>(
    `${SDK.BASE_URL()}/statics/metrics?${SDK.convertRequestToQueryParams(requestParams)}`,
    undefined,
    opts
  );
  return res;
};

const KEY = 'static-metrics';
type Key = typeof KEY;

type Result = SDK.GetStaticsMetricsResponse;
type QueryKey = [Key, SDK.GetStaticsMetricsRequest];

type QueryOptions<R = Result> = UseQueryOptions<Result, unknown, R, QueryKey>;
type QueryResult<R = Result> = UseQueryResult<R>;

const queryFn: QueryFunction<Result, QueryKey> = ({ queryKey: [, requestParams] }) => getStaticsMetrics(requestParams);

// This is exported so we can use useQueries
export function getStaticsMetricsQueryOptions<R = Result>(
  requestParams: SDK.GetStaticsMetricsRequest,
  options?: QueryOptions<R>
): QueryOptions<R> {
  return {
    queryKey: [KEY, requestParams],
    queryFn,
    ...options,
  };
}

export default function useStaticsMetrics<R = Result>(
  requestParams: SDK.GetStaticsMetricsRequest & { refreshCache?: boolean },
  options?: QueryOptions<R>
): QueryResult<R> {
  return useQuery(getStaticsMetricsQueryOptions(requestParams, options));
}
