import { useDispatch, useSelector } from 'react-redux';
import { FilterActions } from '../../../store/filters';
import { RootState } from '../../../store/rootReducer';
import { Page } from '../../../utils/enums';
import DatesPicker from '../DatesPicker';

export interface AgeDateFilterProps {
  page?: Page;
  customDispatch?: (params) => void;
  customDates?: { ageStartDate: string | null; ageEndDate: string | null; ageRangeDays: string | number };
}

const AgeDateFilter = ({ page, customDates, customDispatch }: AgeDateFilterProps) => {
  const { ageStartDate, ageEndDate, ageRangeDays } = useSelector((state: RootState) => state.filters);

  const startDate = customDates?.ageStartDate ?? ageStartDate;
  const endDate = customDates?.ageEndDate ?? ageEndDate;
  const rangeDays = customDates?.ageRangeDays ?? ageRangeDays;

  const dispatch = useDispatch();
  return (
    <DatesPicker
      data-test="filter-datepicker-age"
      label="Launch Date"
      startDate={startDate ?? undefined}
      endDate={endDate ?? undefined}
      rangeDays={rangeDays}
      onChange={(start, end, range) => {
        const dispatchParams = {
          logEvent: true,
          startDate: start,
          endDate: end,
          value: range,
          page,
        };

        dispatch(customDispatch ? customDispatch(dispatchParams) : FilterActions.changeAgeDateRange(dispatchParams));
      }}
      dataTest="age-date-picker"
      leadingIcon={{ name: 'Rocket' }}
      customRanges={[
        { label: 'All-time', lastDays: 0 },
        { label: 'Last 30 days', lastDays: 30 },
        { label: 'Last 90 days', lastDays: 90 },
        { label: 'Last 180 days', lastDays: 180 },
      ]}
      buttonColor="BlueLight"
      buttonVariant="outlined-light"
      allLabel="All-time"
    />
  );
};

export default AgeDateFilter;
