import { IntegrationIconProps } from './types';

const Webmoney = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="14" fill="#036CB5" />
    <path
      d="M15.9524 6C17.5128 6 18.9973 6.36301 20.3295 7.01236C20.5384 7.10807 20.7487 7.22262 20.9569 7.33728L20.0435 8.1589L18.6741 6.72611L16.3145 8.82729L14.925 7.31794L10.586 11.2342L13.364 14.3099L12.2795 15.2648L15.0202 18.3405L13.9356 19.295L17.8561 23.6697L20.1764 21.5301L22.1945 23.823C21.7946 24.1287 21.3569 24.435 20.8817 24.702C19.4351 25.5233 17.7602 26 15.9716 26C10.4715 26 6.00006 21.5117 6.00006 15.9914C5.98064 10.5084 10.4528 6 15.9524 6ZM14.1252 15.3797L16.5041 13.24L18.6359 15.6278L16.2566 17.7673L14.1252 15.3797ZM15.8194 19.5061L18.1789 17.3474L20.3295 19.7351L17.9505 21.8748L15.8194 19.5061ZM12.4326 11.3109L14.8107 9.17125L16.9421 11.5591L14.5631 13.6986L12.4326 11.3109ZM16.7896 10.1644L18.5595 8.5598L20.1584 10.3365L18.3885 11.96L16.7896 10.1644ZM19.9489 17.519L21.7183 15.9144L23.3171 17.7096L21.5471 19.3152L19.9489 17.519ZM21.5092 21.1299L23.2792 19.5249L24.8768 21.3015L23.1074 22.9248L21.5092 21.1299ZM22.2324 12.877L23.4121 11.8071L24.4775 12.992L23.298 14.0614L22.2324 12.877ZM20.71 9.34294L21.8898 8.25431L22.9559 9.45759L21.7752 10.5274L20.71 9.34294ZM23.7356 16.4114L24.9153 15.3416L26 16.526L24.8015 17.5955L23.7356 16.4114ZM18.4264 13.756L20.1964 12.1513L21.7946 13.9275L20.0253 15.5325L18.4264 13.756Z"
      fill="white"
    />
  </svg>
);
export default Webmoney;
