import { CountryIconProps } from './types';

const JP = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M11.9996 17.2178C14.8811 17.2178 17.217 14.8819 17.217 12.0004C17.217 9.11892 14.8811 6.78302 11.9996 6.78302C9.11813 6.78302 6.78223 9.11892 6.78223 12.0004C6.78223 14.8819 9.11813 17.2178 11.9996 17.2178Z"
      fill="#D80027"
    />
  </svg>
);
export default JP;
