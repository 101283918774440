import { useMutation } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const putLockedFeatureUserInterest = async (requestParams: SDK.PutLockedFeatureUserInterestRequest) => {
  const res = await provider.getJson<null, SDK.PutLockedFeatureUserInterestResponse>(
    `${SDK.BASE_URL()}/projects/locked_features/user_interest?${SDK.convertRequestToQueryParams(requestParams)}`,
    { body: null, method: 'PUT' }
  );
  return res;
};

export default function usePutLockedFeatureUserInterest() {
  return useMutation(
    ['project-locked-feature-user-interest'],
    (requestParams: SDK.PutLockedFeatureUserInterestRequest) => putLockedFeatureUserInterest(requestParams)
  );
}
