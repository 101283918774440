import { CountryIconProps } from './types';

const TM = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3716)">
      <path
        d="M11.9995 -0.000610352C11.1035 -0.000610352 10.2308 0.0984365 9.39086 0.284624L8.86914 11.9993L9.39086 23.7141C10.2308 23.9003 11.1036 23.9994 11.9995 23.9994C18.6269 23.9994 23.9995 18.6268 23.9995 11.9993C23.9995 5.37197 18.6269 -0.000610352 11.9995 -0.000610352Z"
        fill="#496E2D"
      />
      <path
        d="M3.13041 3.91711C1.18584 6.04974 0 8.88615 0 11.9997C0 15.1132 1.18589 17.9496 3.13045 20.0823L3.65217 11.9997L3.13041 3.91711Z"
        fill="#496E2D"
      />
      <path
        d="M17.5512 9.05156C18.1416 7.40596 17.4783 5.61623 16.0548 4.72485C16.3109 4.75818 16.5672 4.81846 16.8201 4.90917C18.7187 5.59035 19.7056 7.68154 19.0244 9.58007C18.3432 11.4787 16.252 12.4656 14.3535 11.7844C14.1005 11.6936 13.8643 11.5771 13.6455 11.4401C15.311 11.657 16.9608 10.6971 17.5512 9.05156Z"
        fill="#F0F0F0"
      />
      <path
        d="M14.2766 4.27429L13.7636 4.9372L12.9747 4.65412L13.4465 5.34684L12.9336 6.00965L13.7382 5.77499L14.2101 6.46762L14.2355 5.62987L15.0401 5.39517L14.2513 5.11218L14.2766 4.27429Z"
        fill="#F0F0F0"
      />
      <path
        d="M15.6624 6.4353L15.1493 7.09816L14.3604 6.81508L14.8323 7.5078L14.3193 8.17062L15.1239 7.93587L15.5959 8.62863L15.6213 7.79083L16.4259 7.55608L15.637 7.27305L15.6624 6.4353Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.8332 5.06042L11.3202 5.72324L10.5313 5.44025L11.0032 6.13293L10.4902 6.79578L11.2948 6.56108L11.7667 7.25375L11.7921 6.416L12.5967 6.1813L11.8079 5.89822L11.8332 5.06042Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.7776 7.53455L11.2646 8.19741L10.4757 7.91437L10.9477 8.60709L10.4346 9.26991L11.2393 9.03525L11.7112 9.72787L11.7365 8.89012L12.5412 8.65542L11.7522 8.37234L11.7776 7.53455Z"
        fill="#F0F0F0"
      />
      <path
        d="M14.1321 8.38L13.6191 9.04282L12.8302 8.75979L13.3022 9.4525L12.7891 10.1153L13.5938 9.88061L14.0657 10.5733L14.091 9.73549L14.8957 9.50083L14.1067 9.2178L14.1321 8.38Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.12988 3.91743V20.0826C4.76488 21.8758 6.9366 23.1707 9.39074 23.7147V0.285278C6.93655 0.82931 4.76493 2.12423 3.12988 3.91743Z"
        fill="#D80027"
      />
      <path
        d="M5.51985 6.31761L4.47168 5.53676V4.43253L5.51985 3.65173H7.00228L8.05045 4.43253V5.53676L7.00228 6.31761H5.51985Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.26108 3.65173H5.51985L4.47168 4.43253V4.97239H6.26108V3.65173Z"
        fill="#FF9811"
      />
      <path
        d="M6.26074 6.31668H7.00193L8.0501 5.53583V4.97253H6.26074V6.31668Z"
        fill="#FF9811"
      />
      <path
        d="M5.51985 20.3485L4.47168 19.5676V18.4635L5.51985 17.6826H7.00228L8.05045 18.4635V19.5676L7.00228 20.3485H5.51985Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.26108 17.6826H5.51985L4.47168 18.4635V19.0033H6.26108V17.6826Z"
        fill="#FF9811"
      />
      <path
        d="M6.26074 20.3493H7.00193L8.0501 19.5685V19.0052H6.26074V20.3493Z"
        fill="#FF9811"
      />
      <path
        d="M5.51985 13.3324L4.47168 12.5515V11.4473L5.51985 10.6665H7.00227L8.05044 11.4473V12.5515L7.00227 13.3324H5.51985Z"
        fill="#496E2D"
      />
      <path
        d="M8.0867 15.0514H7.63014V14.5949H6.82787L6.26059 14.0276L5.69335 14.5949H4.89109V15.0514H4.43457V15.9644H4.89109V16.4209H5.69331L6.26063 16.9883L6.82787 16.4209H7.63014V15.9644H8.0867V15.0514Z"
        fill="#496E2D"
      />
      <path
        d="M8.0867 8.03605H7.63014V7.57949H6.82787L6.26059 7.01221L5.69335 7.57949H4.89109V8.03605H4.43457V8.94908H4.89109V9.4056H5.69331L6.26063 9.97297L6.82787 9.4056H7.63014V8.94908H8.0867V8.03605Z"
        fill="#496E2D"
      />
      <path
        d="M6.78274 11.4791H5.73926V12.5226H6.78274V11.4791Z"
        fill="#D80027"
      />
      <path
        d="M6.26097 9.01487C6.54911 9.01487 6.78269 8.78129 6.78269 8.49315C6.78269 8.20502 6.54911 7.97144 6.26097 7.97144C5.97284 7.97144 5.73926 8.20502 5.73926 8.49315C5.73926 8.78129 5.97284 9.01487 6.26097 9.01487Z"
        fill="#D80027"
      />
      <path
        d="M6.26097 16.0309C6.54911 16.0309 6.78269 15.7973 6.78269 15.5091C6.78269 15.221 6.54911 14.9874 6.26097 14.9874C5.97284 14.9874 5.73926 15.221 5.73926 15.5091C5.73926 15.7973 5.97284 16.0309 6.26097 16.0309Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3716">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default TM;
