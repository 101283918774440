import { IntegrationIconProps } from './types';

const Airbnb = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2C8.27812 2 2 8.27812 2 16C2 23.7219 8.27812 30 16 30C23.7219 30 30 23.7219 30 16C30 8.27812 23.7219 2 16 2Z"
      fill="#FF5A5F"
    />
    <path
      d="M15.9993 19.6867C15.0815 18.5322 14.5416 17.5201 14.3616 16.6542C14.1816 15.9506 14.2536 15.3914 14.5596 14.9765C14.8835 14.4894 15.3694 14.2549 15.9993 14.2549C16.6293 14.2549 17.1152 14.4894 17.4391 14.9765C17.7451 15.3914 17.8171 15.9506 17.6371 16.6542C17.4391 17.5382 16.8992 18.5484 15.9993 19.6867ZM21.0242 22.3566C19.4944 23.0241 17.9808 21.9597 16.685 20.5165C18.8285 17.8268 19.2245 15.7342 18.3048 14.3794C17.7649 13.6037 16.991 13.2248 15.9993 13.2248C14.0017 13.2248 12.902 14.9206 13.334 16.8887C13.5859 17.9531 14.2518 19.1636 15.3137 20.5165C14.5172 21.4012 13.4723 22.4047 12.2181 22.519C10.4004 22.7896 8.97684 21.0217 9.62474 19.1978L14.2338 9.63307C14.6272 8.91403 15.1127 8.29812 15.9975 8.29812C16.6454 8.29812 17.1494 8.67696 17.3653 8.98363L22.3704 19.1978C22.8607 20.432 22.2389 21.8308 21.0242 22.3566ZM23.3458 18.837L19.0607 9.90367C18.2508 8.244 17.6749 7.25 15.9993 7.25C14.3436 7.25 13.6399 8.40455 12.92 9.90367L8.65289 18.837C7.73503 21.3644 9.62474 23.5833 11.9122 23.5833C12.0562 23.5833 12.1991 23.5653 12.3441 23.5653C13.5319 23.421 14.7575 22.6633 15.9993 21.3085C17.2412 22.6615 18.4668 23.421 19.6546 23.5653C19.7996 23.5653 19.9425 23.5833 20.0865 23.5833C22.374 23.5851 24.2637 21.3644 23.3458 18.837Z"
      fill="white"
    />
  </svg>
);
export default Airbnb;
