import { CommonTagIconProps } from '../types';

const DurationVeryShort = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9999 21.5829C16.5033 21.5829 20.1539 17.9322 20.1539 13.4289C20.1539 8.92557 16.5033 5.2749 11.9999 5.2749C7.49662 5.2749 3.84595 8.92557 3.84595 13.4289C3.84595 17.9322 7.49662 21.5829 11.9999 21.5829Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12 3.24707V4.31107"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M18.4529 5.98511L17.7009 6.73711"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.293 2.41699H13.707"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12 8.33301V13.429H17.096"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9999 19.4719C12.5229 19.4719 12.9469 19.0479 12.9469 18.5249C12.9469 18.0019 12.5229 17.5779 11.9999 17.5779C11.4768 17.5779 11.0529 18.0019 11.0529 18.5249C11.0529 19.0479 11.4768 19.4719 11.9999 19.4719Z"
      fill="#101828"
    />
    <path
      d="M15.603 17.979C16.126 17.979 16.55 17.555 16.55 17.032C16.55 16.5089 16.126 16.085 15.603 16.085C15.08 16.085 14.656 16.5089 14.656 17.032C14.656 17.555 15.08 17.979 15.603 17.979Z"
      fill="#101828"
    />
    <path
      d="M8.39695 10.7729C8.91997 10.7729 9.34395 10.3489 9.34395 9.82591C9.34395 9.30289 8.91997 8.87891 8.39695 8.87891C7.87394 8.87891 7.44995 9.30289 7.44995 9.82591C7.44995 10.3489 7.87394 10.7729 8.39695 10.7729Z"
      fill="#101828"
    />
    <path
      d="M6.90391 14.3759C7.42692 14.3759 7.85091 13.9519 7.85091 13.4289C7.85091 12.9059 7.42692 12.4819 6.90391 12.4819C6.3809 12.4819 5.95691 12.9059 5.95691 13.4289C5.95691 13.9519 6.3809 14.3759 6.90391 14.3759Z"
      fill="#101828"
    />
    <path
      d="M8.39695 17.979C8.91997 17.979 9.34395 17.555 9.34395 17.032C9.34395 16.5089 8.91997 16.085 8.39695 16.085C7.87394 16.085 7.44995 16.5089 7.44995 17.032C7.44995 17.555 7.87394 17.979 8.39695 17.979Z"
      fill="#101828"
    />
  </svg>
);
export default DurationVeryShort;
