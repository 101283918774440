import { useQuery } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getTagRecommendations = async (requestParams: SDK.GetTagRecommendationsRequest) => {
  const res = await provider.getJson<object, SDK.GetTagRecommendationsResponse>(
    `${SDK.BASE_URL()}/recommendations/insights?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useTagRecommendations(requestParams: SDK.GetTagRecommendationsRequest) {
  return useQuery(['recommendations-tag', requestParams], () => getTagRecommendations(requestParams));
}
