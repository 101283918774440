import { AVAILABLE_BACKPAGES } from './constants';
import { Page } from './enums';

/**
 *
 * Retrieves the page where a certain path should return to
 *
 * @param pathname complete location path
 * @returns page to return
 */
export const getNavigateToPage = (pathname: string): Page | null => {
  const currentPage = {
    currentPage: (pathname?.split('/')?.[2] as Page) ?? null,
    tab: pathname?.split('/')?.[4],
  };
  const backPage = AVAILABLE_BACKPAGES[currentPage?.currentPage];
  if (backPage) {
    return backPage?.tabs?.includes(currentPage.tab ?? '') ? backPage.returnPage : null;
  }
  return null;
};
