import React from 'react';
import { Colors, Typography } from '..';

export interface ModalHeaderProps extends React.HTMLAttributes<HTMLElement> {
  title: string;
  subtitle?: string;
}

export const ModalHeader = ({ title, subtitle }: ModalHeaderProps) => (
  <div style={{ backgroundColor: 'transparent', marginBottom: '8px' }}>
    <Typography type="display-xs" style={{ marginBottom: 0 }}>
      {title}
    </Typography>
    {subtitle ? (
      <Typography
        type="text-sm"
        color={Colors.Gray[500]}
        style={{ marginBottom: 0 }}
      >
        {subtitle}
      </Typography>
    ) : null}
  </div>
);
