/* eslint-disable react/jsx-props-no-spreading */
import { FlatSocialIconProps } from './types';

const IronSource = ({ color, dimension, ...props }: FlatSocialIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        fill="#fcfcfd"
        d="M -0.5,-0.5 C 66.1667,-0.5 132.833,-0.5 199.5,-0.5C 199.5,66.1667 199.5,132.833 199.5,199.5C 132.833,199.5 66.1667,199.5 -0.5,199.5C -0.5,132.833 -0.5,66.1667 -0.5,-0.5 Z"
      />
    </g>
    <g>
      <path
        fill="#11158c"
        d="M 91.5,6.5 C 135.817,6.03962 167.317,25.7063 186,65.5C 198.101,101.812 191.768,134.479 167,163.5C 135.891,192.655 100.391,199.488 60.5,184C 20.7508,162.342 3.25081,128.842 8,83.5C 18.4739,38.8545 46.3072,13.1879 91.5,6.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fbfbfc"
        d="M 151.5,75.5 C 151.5,78.5 151.5,81.5 151.5,84.5C 143.167,84.5 134.833,84.5 126.5,84.5C 126.795,82.2646 126.461,80.2646 125.5,78.5C 122.35,74.1707 118.516,73.504 114,76.5C 113.333,78.5 113.333,80.5 114,82.5C 119.454,86.9312 125.621,89.9312 132.5,91.5C 154.478,101.863 158.645,117.197 145,137.5C 132.806,146.407 119.306,148.907 104.5,145C 93.1545,142.116 86.4878,134.949 84.5,123.5C 84.5,120.5 84.5,117.5 84.5,114.5C 92.8333,114.5 101.167,114.5 109.5,114.5C 109.336,117.187 109.503,119.854 110,122.5C 111.011,123.337 112.178,123.67 113.5,123.5C 118.965,125.857 122.631,124.191 124.5,118.5C 124.167,117.833 123.833,117.167 123.5,116.5C 115.032,112.058 106.366,107.892 97.5,104C 86.5421,96.2269 82.7088,85.7269 86,72.5C 88.9075,62.5935 95.4075,56.4269 105.5,54C 118.537,50.764 130.87,52.4307 142.5,59C 147.915,63.2326 150.915,68.7326 151.5,75.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fcfdfe"
        d="M 54.5,54.5 C 62.8333,54.5 71.1667,54.5 79.5,54.5C 79.5,61.1667 79.5,67.8333 79.5,74.5C 71.1667,74.5 62.8333,74.5 54.5,74.5C 54.5,67.8333 54.5,61.1667 54.5,54.5 Z"
      />
    </g>
    <g>
      <path
        fill="#f8f8fb"
        d="M 53.5,79.5 C 62.1667,79.5 70.8333,79.5 79.5,79.5C 79.6664,87.84 79.4997,96.1733 79,104.5C 77.737,108.219 75.237,110.719 71.5,112C 74.6667,113.833 77.1667,116.333 79,119.5C 79.4997,127.493 79.6664,135.493 79.5,143.5C 70.8333,143.5 62.1667,143.5 53.5,143.5C 53.6663,136.492 53.4996,129.492 53,122.5C 51.4478,121.034 49.6145,120.034 47.5,119.5C 46.5059,114.544 46.1725,109.544 46.5,104.5C 48.677,104.414 50.677,103.747 52.5,102.5C 53.4975,94.8622 53.8308,87.1955 53.5,79.5 Z"
      />
    </g>
    <g>
      <path
        fill="#211f8b"
        d="M 125.5,78.5 C 125.5,80.8333 125.5,83.1667 125.5,85.5C 134.5,85.5 143.5,85.5 152.5,85.5C 144.677,86.3292 136.677,86.8292 128.5,87C 130.644,87.7921 131.977,89.2921 132.5,91.5C 125.621,89.9312 119.454,86.9312 114,82.5C 113.333,80.5 113.333,78.5 114,76.5C 118.516,73.504 122.35,74.1707 125.5,78.5 Z"
      />
    </g>
    <g>
      <path
        fill="#c7cae5"
        d="M 151.5,75.5 C 152.479,78.6248 152.813,81.9581 152.5,85.5C 143.5,85.5 134.5,85.5 125.5,85.5C 125.5,83.1667 125.5,80.8333 125.5,78.5C 126.461,80.2646 126.795,82.2646 126.5,84.5C 134.833,84.5 143.167,84.5 151.5,84.5C 151.5,81.5 151.5,78.5 151.5,75.5 Z"
      />
    </g>
    <g>
      <path
        fill="#a9aaca"
        d="M 113.5,123.5 C 112.178,123.67 111.011,123.337 110,122.5C 109.503,119.854 109.336,117.187 109.5,114.5C 101.167,114.5 92.8333,114.5 84.5,114.5C 84.5,117.5 84.5,120.5 84.5,123.5C 83.5206,120.375 83.1872,117.042 83.5,113.5C 92.5,113.5 101.5,113.5 110.5,113.5C 110.022,117.338 111.022,120.671 113.5,123.5 Z"
      />
    </g>
  </svg>
);
export default IronSource;
