import styled, { css } from 'styled-components';
import Colors from '../Colors';

const TableDataMixin = css<{ $isFirstColumn: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${(props) => (props.$isFirstColumn ? '1.5rem' : '0.3rem')};

  &:first {
    flex-grow: 9999999;
  }

  .cellContentWrapper {
    flex-grow: 1;
    overflow: hidden;
  }

  &.expander {
    width: 48px !important;
    padding: 0 0 0 0.5rem;
  }
`;

export const TableData = styled.td<{ $isFirstColumn: boolean }>`
  ${TableDataMixin}
`;

export const TableHeadData = styled.th<{ $isFirstColumn: boolean }>`
  ${TableDataMixin}
`;

export const TableHead = styled.thead`
  .cell {
    min-width: 4.25rem;
    height: 2.75rem;
    font-weight: 500;
  }

  .cell.expander {
    padding: 0;
  }

  [data-sticky-td] {
    background-color: inherit;
  }
`;

export const Table = styled.table`
  width: 100%;
  min-height: 15rem;
  color: ${Colors.Gray[500]};
  font-size: 0.75rem;
  border-collapse: collapse;
`;

export const TableBody = styled.tbody`
  .cell {
    position: relative;
    min-height: 3.75rem;
  }

  .cell > .rowLink {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: inherit;
    text-decoration: none;
  }

  .cell > .rowLink:focus {
    outline: 0;
  }

  [data-sticky-td] {
    background-color: inherit;
  }
  .tableRow {
    background-color: ${Colors.Common.White};
    transition: background-color 300ms ease;
  }
  .tableRow:focus-within,
  .tableRow:hover {
    background-color: ${Colors.Gray[200]};
    outline: none;
  }
  .tableRow.tableMainRow.tableRowOdd {
    background-color: ${Colors.Gray[50]};
  }

  .tableRow.tableMainRow:hover {
    background-color: ${Colors.Gray[200]};
  }

  .tableRow.tableSubRow {
    background-color: ${Colors.Common.White};
  }

  .tableRow.tableSubRow:hover {
    background-color: ${Colors.Gray[200]};
  }

  .tableRow.tableSubRow .cell:not(.expander) .cellContentWrapper {
    padding-left: 1.5rem;
  }

  .tableRow.tableSubRow
    .cell:not(.expander)
    ~ .cell:not(.expander)
    .cellContentWrapper {
    padding-left: 0;
  }

  // Horizontal line on each videoAvatarLine before "Preview"
  .tableRow.tableSubRow #videoAvatarLine {
    padding-left: 0.5rem;
    :before {
      margin-left: 1.8rem;
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      border-top: 2px solid ${Colors.Gray[300]};
      background: ${Colors.Gray[300]};
      width: 15%;
      transform: translateY(-50%);
    }
  }

  // Last tableSubRow in group of "tableSubRow"s
  .tableSubRow:not(:has(+ .tableSubRow)) #videoAvatarLine {
    :after {
      content: '';
      position: absolute;
      margin-left: 1.8rem;
      left: 0px;
      top: 0%;
      height: 50%;
      border-left: 2px solid ${Colors.Gray[300]};
    }
  }

  // All group of "tableSubRow" except last
  .tableSubRow:has(+ .tableSubRow) #videoAvatarLine {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border-left: 2px solid ${Colors.Gray[300]};
  }
`;

export const TableRow = styled.tr<{ $style?: string }>`
  background-color: ${Colors.Gray[50]};

  &&& {
    ${(props) =>
      css`
        ${props.$style}
      `}
  }
`;

export const TFoot = styled.tfoot`
  .cell {
    min-width: 4.25rem;
    height: 2.75rem;
    font-weight: 500;
    border-bottom: 1px solid ${Colors.Gray[200]};
  }

  .cell.expander {
    padding: 0;
  }

  [data-sticky-td] {
    background-color: inherit;
  }
`;

export const LoadingScreenWrapper = styled.div`
  position: relative;
  float: left;
  width: 100%;
  height: 10rem;
`;

export const ClearFloat = styled.div`
  clear: both;
`;

export const TooltipContent = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;
