/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as SDK from '@replai-platform/sdk';
import gtag from '../../analytics/gtag';
import { ActivityType } from '../../utils/enums';

export interface Campaign {
  campaignId: string;
  campaignName: string;
}

export type Country = { country: string; spend: number };

export const DEFAULT_PROJECT_KPIS: SDK.MetricKPIWithSpend[] = [
  SDK.KPI.IPM,
  SDK.KPI.CTR,
  SDK.KPI.CPI,
  SDK.KPI.CTI,
  SDK.KPI.CPPDAY7,
  SDK.KPI.ROASDAY7,
  SDK.Metrics.SPEND,
];

export const STARS_THRESHOLDS_0_5_STARS = 0.1765;
export const STARS_THRESHOLDS_1_STARS = 0.2163;
export const STARS_THRESHOLDS_1_5_STARS = 0.2552;
export const STARS_THRESHOLDS_2_STARS = 0.2942;
export const STARS_THRESHOLDS_2_5_STARS = 0.339;
export const STARS_THRESHOLDS_3_STARS = 0.3889;
export const STARS_THRESHOLDS_3_5_STARS = 0.4489;
export const STARS_THRESHOLDS_4_STARS = 0.5301;
export const STARS_THRESHOLDS_4_5_STARS = 0.6495;

export interface ProjectState {
  id: string;
  name: string;
  organizationId: SDK.UUID;
  organizationName: string;
  icon: string;
  config: Omit<SDK.ProjectConfig, 'defaultProjectKpis'> & {
    defaultProjectKpis: SDK.MetricKPIWithSpend[];
    dateRangeDays: number;
    activityType: ActivityType;
    accountTypes?: SDK.AccountTypes[];
  };
  userProject: SDK.UserProject;
  networks?: SDK.Network[];
  connectedNetworkAccounts: { [key in SDK.Network | SDK.MMP]?: object[] };
  promotedObjectTypes?: SDK.PromotedObjectType[];
  campaigns?: Campaign[];
  contextTagTypes?: SDK.Tag[];
  countries?: Country[];
  customTags: SDK.Tag[];
  marketTags: SDK.Tag[];
  baseMetric: SDK.Metrics;
}

export const initialState: ProjectState = {
  id: '',
  name: '',
  organizationId: '',
  organizationName: '',
  icon: '',
  config: {
    dateRangeDays: 60,
    activityType: ActivityType.active,
    creativesListMinimumSpend: 0,
    minSpend: 50,
    tagsTypes: {
      core: [],
      custom: [],
      excluded: [],
      psychological: [],
    },
    topCreativesMinimumSpend: 0,
    recommendationStarThresholds: [
      STARS_THRESHOLDS_0_5_STARS,
      STARS_THRESHOLDS_1_STARS,
      STARS_THRESHOLDS_1_5_STARS,
      STARS_THRESHOLDS_2_STARS,
      STARS_THRESHOLDS_2_5_STARS,
      STARS_THRESHOLDS_3_STARS,
      STARS_THRESHOLDS_3_5_STARS,
      STARS_THRESHOLDS_4_STARS,
      STARS_THRESHOLDS_4_5_STARS,
    ],
    minRecommendationScore: 0.15,
    minInsightScore: 0.6,
    defaultProjectKpis: DEFAULT_PROJECT_KPIS,
    contextTagTypes: [],
  },
  userProject: {},
  networks: [],
  connectedNetworkAccounts: {},
  promotedObjectTypes: [],
  campaigns: [],
  countries: [],
  customTags: [],
  marketTags: [],
  baseMetric: SDK.Metrics.SPEND,
};

const ProjectSlice = createSlice({
  name: 'Project',
  initialState,
  reducers: {
    changeProjectData: (
      _,
      action: PayloadAction<{
        id: SDK.UUID;
        organizationId?: SDK.UUID;
        organizationName?: string;
        config?: any;
        userProject?: any;
      }>
    ) => {
      // analytics
      gtag('set', {
        project_id: action.payload.id,
      });

      gtag('set', 'user_properties', {
        project_id: action.payload.id,
      });

      const config = action.payload?.config;

      if (config?.defaultProjectKpis) {
        config.defaultProjectKpis = [
          ...config?.defaultProjectKpis.filter((k) => k !== SDK.Metrics.SPEND),
          ...config?.defaultProjectKpis.filter((k) => k === SDK.Metrics.SPEND),
        ];
      }

      return {
        ...initialState,
        ...(action.payload ?? {}),
        config: {
          ...initialState.config,
          ...(config ?? {}),
        },
        userProject: {
          ...initialState.userProject,
          ...(action.payload?.userProject ?? {}),
          uiPreferences: {
            ...initialState.userProject.uiPreferences,
            ...(action.payload?.userProject?.uiPreferences ?? {}),
          },
        },
        // TODO(MERC-1555): cleanup
        baseMetric:
          action.payload.id === '248ca9eb-6f5b-4106-b52e-cc3c6d96436b'
            ? SDK.Metrics.INSTALLS
            : config?.accountTypes?.includes(SDK.AccountTypes.SOCIALS)
            ? SDK.Metrics.VIEWS
            : SDK.Metrics.SPEND,
      };
    },
    changeUiPreferences: (state, action) => {
      state.userProject = {
        ...initialState.userProject,
        uiPreferences: {
          ...initialState.userProject.uiPreferences,
          ...action.payload,
        },
      };
    },
    changeNetworks: (state, action) => {
      state.networks = action.payload;
    },
    changeConnectedNetworkAccounts: (state, action) => {
      state.connectedNetworkAccounts = action.payload;
    },
    changePromotedObjectTypes: (state, action) => {
      state.promotedObjectTypes = action.payload;
    },
    changeCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    changeContextTags: (state, action) => {
      state.contextTagTypes = action.payload;
    },
    changeCountries: (state, action) => {
      state.countries = action.payload;
    },
    changeCustomTags: (state, action) => {
      state.customTags = action.payload;
    },
    changeMarketTags: (state, action) => {
      state.marketTags = action.payload;
    },
  },
});

export const ProjectActions = ProjectSlice.actions;
export const ProjectReducer = ProjectSlice.reducer;
