import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase, Icons } from '@replai-platform/ui-components';
import { rejectNotHandled } from './rejectNotHandled';

export const getSocialIconByName = (name: SDK.NetworkOrMmp | SDK.PromotedObjectType): Icons.FlatSocialIconTypes => {
  switch (name) {
    case SDK.Network.FACEBOOK:
    case SDK.Network.GOOGLE:
    case SDK.Network.LIFTOFF:
    case SDK.Network.MINTEGRAL:
    case SDK.Network.MOLOCO:
    case SDK.Network.SNAPCHAT:
    case SDK.Network.UNITY:
    case SDK.Network.VUNGLE:
    case SDK.Network.YOUTUBE:
    case SDK.MMP.ADJUST:
    case SDK.MMP.SINGULAR:
    case SDK.PromotedObjectType.ANDROID:
    case SDK.PromotedObjectType.WEBSITE:
      return camelCaseToCapitalCase(name.toLowerCase()) as Icons.FlatSocialIconTypes;
    case SDK.Network.APPLOVIN:
      return 'AppLovin';
    case SDK.Network.IRONSOURCE:
      return 'IronSource';
    case SDK.Network.SENSORTOWER:
      return 'SensorTower';
    case SDK.Network.TIKTOK:
      return 'TikTok';
    case SDK.Network.REPLAI_API:
      return 'Website';
    case SDK.MMP.APPSFLYER:
      return 'AppsFlyer';
    case SDK.PromotedObjectType.IOS:
      return 'Apple';
    default:
      return rejectNotHandled(name);
  }
};
