const blueLight = {
  25: '#F5FBFF',
  50: '#F0F9FF',
  100: '#E0F2FE',
  200: '#B9E6FE',
  300: '#7CD4FD',
  400: '#36BFFA',
  500: '#0BA5EC',
  600: '#0086C9',
  700: '#026AA2',
  800: '#065986',
  900: '#0B4A6F',
} as const;

type Keys = keyof typeof blueLight;
type BlueLight = typeof blueLight[Keys];

export default blueLight;
export type { BlueLight };
