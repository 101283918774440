import { CountryIconProps } from './types';

const BO = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12.0006C24 10.5328 23.736 9.12669 23.2536 7.82676L12 7.30499L0.746391 7.82671C0.264 9.12669 0 10.5328 0 12.0006C0 13.4685 0.264 14.8746 0.746391 16.1745L12 16.6963L23.2536 16.1746C23.736 14.8746 24 13.4685 24 12.0006Z"
      fill="#FFDA44"
    />
    <path
      d="M11.9997 23.9999C17.1593 23.9999 21.5578 20.7434 23.2533 16.1738H0.746094C2.44161 20.7434 6.84013 23.9999 11.9997 23.9999Z"
      fill="#6DA544"
    />
    <path
      d="M0.746094 7.82641H23.2534C21.5578 3.25681 17.1593 0.000305176 11.9997 0.000305176C6.84012 0.000305176 2.44161 3.25681 0.746094 7.82641Z"
      fill="#D80027"
    />
  </svg>
);
export default BO;
