import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../../common';
import { TypographyClean } from '../../../../utils/styles';

export type ReportNameAccessorType = {
  name: string;
};

const ReportNameCellDecorator: React.FC<{
  cellData: ReportNameAccessorType;
}> = ({ cellData }) => {
  if (!cellData) {
    return null;
  }

  return <TypographyClean>{cellData.name}</TypographyClean>;
};

function getReportNameColumn<T extends object = ReportNameAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T, ReportNameAccessorType>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<T, ReportNameAccessorType>) => <ReportNameCellDecorator cellData={data?.cell?.value} />,
  };
}

export default getReportNameColumn;
