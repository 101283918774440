import styled from 'styled-components';
import { ReactPlayerProps } from 'react-player';
import Colors from '../Colors';
import Carousel from '../Carousel/Carousel';
import VideoWithTimeline from './VideoWithTimeline';
import { IconType, TagIconProps, TagIconTypes } from '../Icons';
import FeaturedIcons from '../FeaturedIcons/FeaturedIcons';

export interface TagPreviewCarouselProps extends ReactPlayerProps {
  thumbnails?: PreviewThumbnail[];
  videos?: PreviewVideo[];
  onPressProgressBar?: () => void;
  icon?: TagIconTypes;
  iconProps?: TagIconProps;
}

export type PreviewVideo = {
  url: URL;
  timeframes?: Timeframe[];
  onPlay?: () => void;
  onPause?: () => void;
  onPressProgressBar?: () => void;
};

export type Timeframe = {
  startSeconds: number;
  endSeconds: number;
};

type PreviewThumbnail = {
  imageUrl: URL;
};

type ThumbnailWithType = PreviewThumbnail & { type: 'thumbnail' };

type VideoWithType = PreviewVideo & { type: 'video' };

type Data = ThumbnailWithType | VideoWithType;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  height: 28.75rem;

  .ant-carousel .slick-next,
  .slick-prev {
    /* we need this to center the arrows with the preview only, and not preview + timeline */
    margin-top: -35px;
  }
`;

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  height: 29rem;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26.25rem;
  background-color: ${Colors.Gray[50]};
  border-radius: 8px;

  img {
    max-width: 100%;
  }
`;

const IconThumbnailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26.25rem;
`;

const renderIconThumbnail = ({
  icon,
  iconProps,
}: {
  icon: TagIconTypes;
  iconProps?: TagIconProps;
}) => (
  <IconThumbnailContainer>
    <FeaturedIcons
      icon={icon}
      iconProps={iconProps}
      iconType={IconType.TAG}
      color="Gray"
      size="xxl"
      variant="outline"
    />
  </IconThumbnailContainer>
);

const renderImageThumbnail = ({ imageUrl }: PreviewThumbnail) => (
  <ImageContainer>
    <img alt="Preview of a tag instance" src={imageUrl.toString()} />
  </ImageContainer>
);

const renderVideo = ({
  url,
  timeframes,
  onPlay,
  onPause,
  onPressProgressBar,
}: PreviewVideo) => (
  <VideoWithTimeline
    url={url}
    onPlay={onPlay}
    onPause={onPause}
    onPressProgressBar={onPressProgressBar}
    timeframes={timeframes}
  />
);

const TagPreviewCarousel = ({
  thumbnails,
  videos,
  onPause,
  onPlay,
  onPressProgressBar,
  icon,
  iconProps,
}: TagPreviewCarouselProps) => {
  const data: Data[] = [
    ...(videos || []).map(
      (t: PreviewVideo) =>
        ({ ...t, type: 'video', onPlay, onPause, onPressProgressBar } as Data)
    ),
    ...(thumbnails || []).map(
      (t: PreviewThumbnail) => ({ ...t, type: 'thumbnail' } as Data)
    ),
  ];

  const renderSlideContent = ({ type, ...rest }: { type: string }) => {
    switch (type) {
      case 'thumbnail':
        return icon
          ? renderIconThumbnail({ icon, iconProps })
          : renderImageThumbnail(rest as PreviewThumbnail);
      case 'video':
        return renderVideo(rest as PreviewVideo);
      default:
        return null;
    }
  };

  const renderSlide = (d: Data) => (
    <SlideContainer>{renderSlideContent(d)}</SlideContainer>
  );

  return (
    <Container>
      <Carousel
        slidesData={data}
        accessor={renderSlide}
        arrows
        dots={false}
        disableShadow
      />
    </Container>
  );
};

export default TagPreviewCarousel;
