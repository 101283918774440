import { CommonTagIconProps } from '../types';

const Grayscale = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.005 3.34497C7.22497 3.34497 3.34497 7.22497 3.34497 12.005C3.34497 16.785 7.22497 20.655 12.005 20.655C16.785 20.655 20.655 16.785 20.655 12.005C20.655 7.22497 16.785 3.34497 12.005 3.34497ZM15.285 12.165C15.245 13.055 14.855 13.845 14.245 14.405C13.655 14.955 12.875 15.295 12.005 15.295C11.135 15.295 10.335 14.955 9.75497 14.405C9.14497 13.845 8.75497 13.055 8.71497 12.165C8.70497 12.115 8.70497 12.055 8.70497 12.005C8.70497 11.135 9.04497 10.335 9.59497 9.75497C10.195 9.10497 11.055 8.70497 12.005 8.70497C12.955 8.70497 13.805 9.10497 14.405 9.75497C14.955 10.335 15.295 11.135 15.295 12.005C15.295 12.055 15.295 12.115 15.285 12.165Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.75492 14.405L6.16492 17.995"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.835 6.32495L14.405 9.75495"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.835 17.995L14.245 14.405"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.59492 9.75495L6.16492 6.32495"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.005 20.4149V15.2949"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.005 8.70503V3.90503"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M20.2549 12.165H15.2849"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.715 12.165H3.745"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default Grayscale;
