/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import * as SDK from '@replai-platform/sdk';
import { ActivityType } from '../../utils/enums';

// TODO: 'filters' should use a proper type but right now adding it could introduce
// a circular dependency because this is a separate package.
type FiltersState = any;

export const getAdsFilters = (filters: FiltersState): SDK.AdsFilters => ({
  campaignIdsToConsider: filters.campaignIdsToConsider,
  campaignIdsToExclude: filters.campaignIdsToExclude,
  networksToConsider: filters.networks,
  promotedObjectTypesToConsider:
    filters.promotedObjectTypesToConsider?.length === SDK.PROMOTED_OBJECT_TYPES.length
      ? []
      : filters.promotedObjectTypesToConsider,
});

const getDateTwoDaysAgo = (endDate: string): number => {
  const activeDate = new Date(endDate);
  activeDate.setDate(activeDate.getDate() - 2);
  activeDate.setUTCHours(0, 0, 0, 0);
  return activeDate.getTime();
};

export const getAssetFilters = (filters: FiltersState): SDK.AssetFilters => ({
  minSpend: filters.minSpend > 0 ? filters.minSpend : undefined,
  minInstalls: filters.minInstalls > 0 ? filters.minInstalls : undefined,
  minActiveDate: filters.activity === ActivityType.active ? getDateTwoDaysAgo(new Date().toISOString()) : undefined,
  maxActiveDate: filters.activity === ActivityType.inactive ? getDateTwoDaysAgo(new Date().toISOString()) : undefined,
  types: filters.assetTypes ? filters.assetTypes : undefined,
});

export const getMetricsFilters = (filters: FiltersState): SDK.MetricsFilters => ({
  countriesToConsider: filters.countries ? filters.countries : undefined,
  countriesToExclude: filters.excludedCountries ? filters.excludedCountries : undefined,
  dateStartTimestamp: filters.startDate
    ? new Date(`${filters.startDate as string}T00:00:00.000Z`).getTime()
    : undefined,
  dateEndTimestamp: filters.endDate ? new Date(`${filters.endDate as string}T23:59:59.999Z`).getTime() : undefined,
  ageDateStartTimestamp: filters.ageStartDate
    ? new Date(`${filters.ageStartDate as string}T00:00:00.000Z`).getTime()
    : undefined,
  ageDateEndTimestamp: filters.ageEndDate
    ? new Date(`${filters.ageEndDate as string}T23:59:59.999Z`).getTime()
    : undefined,
});

/* eslint-disable @typescript-eslint/no-unsafe-call */
export const getTagsFilters = (filters: FiltersState): SDK.TagsFilters => ({
  tagsToConsider: filters.tagsToConsider?.map((tag) => ({ type: tag.type, value: tag.value })) ?? [],
  tagsToExclude: filters.tagsToExclude?.map((tag) => ({ type: tag.type, value: tag.value })) ?? [],
  tagTypesToConsider: filters.tagTypesToConsider?.map((type) => ({ type })) ?? [],
  tagTypesToExclude: filters.tagTypesToExclude?.map((type) => ({ type })) ?? [],
});

export const getAdTagsFilters = (filters: FiltersState): SDK.AdTagsFilters => ({
  maxFirstAppearanceSeconds: filters.maxFirstAppearanceSeconds ? filters.maxFirstAppearanceSeconds : undefined,
  minFirstAppearanceSeconds: filters.minFirstAppearanceSeconds ? filters.minFirstAppearanceSeconds : undefined,
});
