/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import buildFilteringWhereClause from '../../utils/filtering';
import { filtersAsQueryParams } from './filters';
import * as provider from './provider';

/**
 * Get the history
 * @deprecated use getHistoryMetrics instead for assets and concepts
 * @param {String} projectId ID of the project to get the history
 * @param {Array<String>} assetIds IDs of the assets to get the history
 * @param {Array<String>} assetClusterIds IDs of the asset clusters to get the history
 * @param {String} fields fields to get
 * @param {String} where where conditions
 * @param {String} order order by conditions
 * @param {Object} tagFiltering filters to use
 * @param {Number} maxRecords number maximum of records to return
 */
export async function get({
  projectId,
  assetIds = [],
  assetClusterIds = [],
  fields = null,
  where = null,
  order = null,
  tagFiltering,
  maxRecords = null,
}: any): Promise<
  Array<{
    metrics: Partial<SDK.Metric> & {
      date: string;
    };
  }>
> {
  const queryParams: string[] = [];
  queryParams.push(
    ...filtersAsQueryParams({
      projectId,
      tagFiltering,
      assetIds,
      assetClusterIds,
      maxRecords,
    })
  );
  if (fields && fields.length > 0) queryParams.push(`fields=${encodeURIComponent(fields)}`);
  const whereClause = buildFilteringWhereClause(where);
  if (whereClause.length > 0) queryParams.push(`where=${encodeURIComponent(whereClause.toString())}`);
  if (order && order.length > 0) queryParams.push(`order=${encodeURIComponent(order)}`);

  const url = `${SDK.BASE_URL()}/history${queryParams.length > 0 ? `?${queryParams.join('&')}` : ''}`;

  return provider.getJson(url);
}
