export type { IconProps as BaseIconProps, BaseIconTypes } from './Base/types';
export type { BrandIconProps } from './Brand/types';
export type { CountryIconProps, CountryIconTypes } from './Country/types';
export type {
  IntegrationIconProps,
  IntegrationIconTypes,
} from './Integrations/types';
export type { MiscIconProps, MiscIconTypes } from './Misc/types';
export type { PaymentIconProps, PaymentIconTypes } from './Payment/types';
export type {
  TagIconProps,
  CommonTagIconProps,
  LanguageTagIconProps,
  TagIconTypes,
  ColorValue,
} from './Tag';
export type {
  BandSocialIconTypes,
  BrandSocialIconProps,
  FlatSocialIconProps,
  FlatSocialIconTypes,
} from './Social';
export type { WYSIWYGProps, WYSIWYGType } from './WYSIWYG';

export * as BaseIcons from './Base';
export * as BrandIcons from './Brand';
export * as CountryIcons from './Country';
export * as IntegrationIcons from './Integrations';
export * as MiscIcons from './Misc';
export * as PaymentIcons from './Payment';
export * as TagIcons from './Tag';
export * as SocialIcons from './Social';
export * as WYSIWYGIcons from './WYSIWYG';

export {
  getIcon,
  getBaseIcon,
  getBrandIcon,
  getCountryIcon,
  getFlatIcon,
  getIntegrationIcon,
  getMiscIcon,
  getPaymentIcon,
  getTagIcon,
  getWYSIWYGIcon,
} from './getIcon';

export enum IconType {
  BASE = 'BASE',
  BRAND = 'BRAND',
  COUNTRY = 'COUNTRY',
  FLAT = 'FLAT',
  INTEGRATION = 'INTEGRATION',
  MISC = 'MISC',
  PAYMENT = 'PAYMENT',
  TAG = 'TAG',
  WYSIWYG = 'WYSIWYG',
}
