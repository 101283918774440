import { IntegrationIconProps } from './types';

const AdobeDreamweaver = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="28" height="28" fill="#4EF641" />
    <rect x="3" y="3" width="26" height="26" fill="#0A2503" />
    <path
      d="M5 9.22931C5 9.12427 5.01977 9.08285 5.1398 9.06214C5.59731 9.04142 6.91338 9 8.26757 9C12.3104 9 14.3212 11.7325 14.3212 15.7389C14.3212 21.309 10.8362 23 8.14754 23C7.13225 23 5.65662 22.9793 5.12003 22.9586C5.04095 22.9586 5 22.8964 5 22.7914V9.22931ZM7.17179 20.9554C7.51069 20.9761 7.80864 20.9968 8.18708 20.9968C10.4168 20.9968 12.0703 19.3487 12.0901 15.801C12.1099 12.3376 10.3984 11.0017 8.26757 11.0017C7.84959 11.0017 7.51069 11.0224 7.17179 11.0431V20.9554ZM22.4644 22.9985C22.3246 22.9985 22.285 22.9423 22.2653 22.8165C21.668 19.3325 21.191 16.8085 20.9721 15.0983C20.7137 16.6413 20.1937 19.2703 19.3973 22.8387C19.3578 22.9645 19.3183 23 19.218 23H17.2665C17.1267 23 17.0872 22.9793 17.0476 22.8535L14.9383 12.8318C14.9185 12.7489 14.958 12.6646 15.0781 12.6646H17.0296C17.1086 12.6646 17.1694 12.6942 17.1891 12.777C17.946 17.2627 18.2651 19.5869 18.3837 20.5042H18.4628C18.6026 19.4197 19.0206 17.1577 19.9159 12.8185C19.9554 12.6927 19.9554 12.6646 20.0754 12.6646H22.1272C22.2274 12.6646 22.2472 12.7149 22.267 12.8199C22.9646 16.9298 23.4221 19.5248 23.5421 20.5678H23.6014C23.8005 19.2318 24.0589 17.4299 24.9161 12.7992C24.9358 12.7164 24.9556 12.6646 25.0559 12.6646H26.8873C26.9664 12.6646 27.0271 12.706 26.9876 12.8111L24.5966 22.8535C24.5769 22.9586 24.5571 23 24.4173 23L22.4644 22.9985Z"
      fill="#4EF641"
    />
  </svg>
);
export default AdobeDreamweaver;
