import { Ref } from 'react';
import { default as _ReactPlayer } from 'react-player/lazy';
import type { ReactPlayerProps } from 'react-player';
import styled from 'styled-components';
import Colors from '../Colors';

export interface VideoPlayerProps extends ReactPlayerProps {
  url: string;
  playerRef?: Ref<_ReactPlayer> | null;
  startTime?: string;
  preload?: string;
  title?: string;
  allowDownload?: boolean;
  width?: string;
  height?: string;
  onLoadedMetadata?: (metadata: React.SyntheticEvent<HTMLVideoElement>) => void;
  objectFit?: 'cover' | 'contain';
}

const PlayerContainer = styled.div<{ objectFit: 'cover' | 'contain' }>`
  width: 100%;
  height: 100%;

  > div {
    background-color: ${Colors.Common.Black};
    border-radius: 8px;

    video {
      object-fit: ${({ objectFit }) => objectFit};
      border-radius: 8px;
    }
  }
`;

const VideoPlayer = ({
  url,
  playerRef = null,
  startTime = '0',
  preload = 'auto',
  title,
  allowDownload = false,
  onLoadedMetadata,
  width,
  height,
  objectFit = 'contain',
  ...props
}: VideoPlayerProps) => {
  // see: https://github.com/cookpete/react-player/issues/1406
  const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

  return (
    <PlayerContainer objectFit={objectFit}>
      <ReactPlayer
        ref={playerRef}
        aria-label={title ? `Video ${title}` : 'Video Player'}
        url={url ? `${url}#t=${startTime}` : ''}
        config={
          !allowDownload
            ? {
                file: {
                  attributes: {
                    disablePictureInPicture: true,
                    controlsList: 'nodownload',
                    preload,
                  },
                },
              }
            : {}
        }
        progressInterval={Math.round((1000 * 1) / 60)}
        muted
        controls
        width={width ?? '100%'}
        height={height ?? '100%'}
        onLoadedMetadata={onLoadedMetadata}
        playsinline
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </PlayerContainer>
  );
};

export default VideoPlayer;
