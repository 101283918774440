import { CommonTagIconProps } from '../types';

const DurationMiddle = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9999 21.5829C16.5033 21.5829 20.1539 17.9322 20.1539 13.4289C20.1539 8.92557 16.5033 5.2749 11.9999 5.2749C7.49662 5.2749 3.84595 8.92557 3.84595 13.4289C3.84595 17.9322 7.49662 21.5829 11.9999 21.5829Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12 3.24707V4.31107"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M18.4529 5.98511L17.7009 6.73711"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.293 2.41699H13.707"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.992 8.33301L12 13.429L8.39099 9.83101"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DurationMiddle;
