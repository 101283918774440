import { PaymentIconProps } from './types';

const AMEX = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="#1F72CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.12722 11.3334L3.88574 20.9957H8.96339L9.59287 19.4551H11.0317L11.6612 20.9957H17.2503V19.8199L17.7483 20.9957H20.6394L21.1374 19.795V20.9957H32.7611L34.1745 19.4951L35.498 20.9957L41.4682 21.0081L37.2133 16.1915L41.4682 11.3334H35.5906L34.2147 12.8062L32.9329 11.3334H20.2878L19.202 13.8273L18.0907 11.3334H13.0237V12.4692L12.46 11.3334H8.12722ZM9.10972 12.7054H11.5848L14.3982 19.2575V12.7054H17.1095L19.2825 17.4032L21.2852 12.7054H23.983V19.6388H22.3415L22.3281 14.2059L19.9348 19.6388H18.4664L16.0598 14.2059V19.6388H12.6827L12.0425 18.0844H8.58358L7.94468 19.6374H6.1353L9.10972 12.7054ZM32.1598 12.7054H25.4849V19.6347H32.0564L34.1745 17.3382L36.2161 19.6347H38.3502L35.2483 16.1902L38.3502 12.7054H36.3087L34.2013 14.9755L32.1598 12.7054ZM10.3138 13.8785L9.17419 16.6475H11.452L10.3138 13.8785ZM27.1332 15.4067V14.141V14.1398H31.2982L33.1155 16.1639L31.2176 18.1991H27.1332V16.8174H30.7747V15.4067H27.1332Z"
      fill="white"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default AMEX;
