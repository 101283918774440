import { CountryIconProps } from './types';

const VU = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3776)">
      <path
        d="M24 12.0003C24 11.4698 23.9651 10.9475 23.8984 10.4351H10.4347C7.98306 7.98365 3.51452 3.51539 3.5147 3.51501C-1.17157 8.20129 -1.17157 15.7992 3.5147 20.4856L10.4348 13.5656H23.8984C23.9651 13.0532 24 12.5308 24 12.0003Z"
        fill="black"
      />
      <path
        d="M23.9968 12.2726C23.9963 12.2958 23.9958 12.319 23.9951 12.3422C23.9957 12.3189 23.9962 12.2958 23.9968 12.2726Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.9853 12.6002C23.9843 12.6232 23.9827 12.646 23.9814 12.6689C23.9827 12.6461 23.9842 12.6232 23.9853 12.6002Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.9684 12.8632C23.966 12.8969 23.9637 12.9307 23.9609 12.9643C23.9637 12.9307 23.966 12.8969 23.9684 12.8632Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.9287 10.6921C23.9331 10.7323 23.9375 10.7724 23.9415 10.8127C23.9375 10.7724 23.9331 10.7323 23.9287 10.6921Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.9805 11.3298C23.9817 11.3527 23.9833 11.3756 23.9844 11.3985C23.9832 11.3756 23.9817 11.3527 23.9805 11.3298Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.9951 11.6567C23.9958 11.6799 23.9962 11.7032 23.9968 11.7264C23.9963 11.7031 23.9957 11.6799 23.9951 11.6567Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.9609 11.0355C23.9637 11.0692 23.966 11.1029 23.9684 11.1367C23.966 11.1029 23.9637 11.0692 23.9609 11.0355Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.8984 10.4344C23.9038 10.4755 23.9086 10.5168 23.9135 10.558C23.2013 4.61044 18.1395 -0.000366211 12 -0.000366211C8.68626 -0.000366211 5.68622 1.34283 3.51465 3.5145L10.4345 10.4344H23.8984V10.4344Z"
        fill="#D80027"
      />
      <path
        d="M23.8984 13.5652H10.4345L3.51465 20.4851C5.68622 22.6567 8.68626 24 12 24C18.1395 24 23.2014 19.3892 23.9135 13.4415C23.9086 13.4827 23.9037 13.5241 23.8984 13.5652Z"
        fill="#6DA544"
      />
      <path
        d="M23.9415 13.1877C23.9375 13.228 23.9331 13.2681 23.9287 13.3083C23.9331 13.2681 23.9376 13.2281 23.9415 13.1877Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.9688 11.137C23.9733 11.2012 23.9774 11.2655 23.981 11.33C23.9774 11.2655 23.9733 11.2012 23.9688 11.137Z"
        fill="black"
      />
      <path
        d="M23.9131 10.5583C23.9185 10.6029 23.9236 10.6475 23.9284 10.6922C23.9235 10.6475 23.9184 10.6029 23.9131 10.5583Z"
        fill="black"
      />
      <path
        d="M23.9414 10.8124C23.9487 10.8869 23.9553 10.9616 23.9613 11.0367C23.9554 10.9616 23.9488 10.8869 23.9414 10.8124Z"
        fill="black"
      />
      <path
        d="M24.0005 12C24.0005 12.0913 23.9991 12.1823 23.9971 12.2731C23.9991 12.1823 24.0005 12.0913 24.0005 12Z"
        fill="black"
      />
      <path
        d="M23.981 12.6688C23.9774 12.7333 23.9733 12.7977 23.9688 12.8619C23.9733 12.7977 23.9773 12.7333 23.981 12.6688Z"
        fill="black"
      />
      <path
        d="M23.9613 12.964C23.9553 13.0389 23.9487 13.1137 23.9414 13.1882C23.9488 13.1137 23.9554 13.0389 23.9613 12.964Z"
        fill="black"
      />
      <path
        d="M23.9284 13.3081C23.9236 13.3528 23.9185 13.3974 23.9131 13.4419C23.9184 13.3974 23.9235 13.3528 23.9284 13.3081Z"
        fill="black"
      />
      <path
        d="M23.9971 11.7274C23.9991 11.8183 24.0005 11.9093 24.0005 12.0005C24.0005 11.9093 23.9991 11.8183 23.9971 11.7274Z"
        fill="black"
      />
      <path
        d="M23.9854 11.3982C23.9896 11.4841 23.9928 11.5704 23.9952 11.6569C23.9928 11.5704 23.9896 11.4841 23.9854 11.3982Z"
        fill="black"
      />
      <path
        d="M23.9952 12.3419C23.9928 12.4283 23.9896 12.5146 23.9854 12.6006C23.9897 12.5146 23.9928 12.4283 23.9952 12.3419Z"
        fill="black"
      />
      <path
        d="M23.9892 11.4733H9.9945L2.80591 4.28577C2.57355 4.53083 2.32169 4.87302 2.16592 5.11672L9.04055 11.995L2.16016 18.8755C2.29637 19.1178 2.59314 19.4765 2.8015 19.7099L9.99464 12.5168H23.9909C23.9984 12.3437 24.0007 12.1699 24.0007 11.995C24.0007 11.7608 23.9942 11.588 23.9892 11.4733Z"
        fill="#FFDA44"
      />
      <path
        d="M2.91515 14.5574V13.5523C3.91818 13.5523 4.73413 12.7362 4.73413 11.7333C4.73413 11.0237 4.15677 10.4464 3.44718 10.4464C2.96376 10.4464 2.57043 10.8396 2.57043 11.3232C2.57043 11.6323 2.82191 11.8838 3.1311 11.8838C3.30585 11.8838 3.44802 11.7416 3.44802 11.5668H4.45311C4.45311 12.2959 3.86005 12.8889 3.13115 12.8889C2.26776 12.889 1.56543 12.1865 1.56543 11.3232C1.56543 10.2856 2.40965 9.44141 3.44722 9.44141C4.71106 9.44141 5.73931 10.4697 5.73931 11.7335C5.73927 13.2905 4.47238 14.5574 2.91515 14.5574Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3776">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default VU;
