import * as SDK from '@replai-platform/sdk';
import * as provider from './provider';

/**
 * Get the network and its metrics for a specific tag.
 */
export async function getNetworksMetrics(
  request: SDK.GetNetworksMetricsRequest
): Promise<SDK.GetNetworksMetricsResponse> {
  return provider.getJson(`${SDK.BASE_URL()}/networks/metrics?${SDK.convertRequestToQueryParams(request)}`);
}
