import * as SDK from '@replai-platform/sdk';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import * as provider from '../../api/provider';

export const GET_CREATIVE_PREFERENCES_KEY = 'get-creative-requests';

const submitCreativePreferencesFile = async (projectId: SDK.UUID, body: SDK.SubmitCreativePreferencesRequestBody) => {
  const url = `${SDK.BASE_URL()}/creative-production/${projectId}/creative-preferences`;
  const res = await provider.getJson<object, { id: string }>(url, {
    method: 'POST',
    body,
  });
  return res;
};

export function useSubmitCreativePreferencesFile(projectId: SDK.UUID) {
  return useMutation([`submit-creative-preferences`], (body: SDK.SubmitCreativePreferencesRequestBody) =>
    submitCreativePreferencesFile(projectId, body)
  );
}

const getCreativePreferencesFiles = async (projectId: SDK.UUID): Promise<SDK.GetCreativePreferencesResponse> =>
  provider.getJson(`${SDK.BASE_URL()}/creative-production/${projectId}/creative-preferences`);

export function useGetCreativePreferencesFiles(
  projectId: SDK.UUID,
  options?: UseQueryOptions<SDK.GetCreativePreferencesResponse>
) {
  return useQuery(GET_CREATIVE_PREFERENCES_KEY, () => getCreativePreferencesFiles(projectId), options);
}

const deleteCreativePreferencesFile = async (projectId: SDK.UUID, body: SDK.DeleteCreativePreferencesRequestBody) => {
  const url = `${SDK.BASE_URL()}/creative-production/${projectId}/creative-preferences`;
  const res = await provider.getJson<object, object>(url, {
    method: 'DELETE',
    body,
  });
  return res;
};

export function useDeleteCreativePreferencesFile(projectId: SDK.UUID) {
  const queryClient = useQueryClient();

  return useMutation(
    [`delete-creative-preferences`],
    (body: SDK.DeleteCreativePreferencesRequestBody) => deleteCreativePreferencesFile(projectId, body),
    {
      onSettled: () => queryClient.invalidateQueries({ queryKey: GET_CREATIVE_PREFERENCES_KEY }),

      onMutate: async (variables) => {
        await queryClient.cancelQueries({ queryKey: GET_CREATIVE_PREFERENCES_KEY });
        const lastQueryData =
          queryClient.getQueryData<SDK.GetCreativePreferencesResponse>(GET_CREATIVE_PREFERENCES_KEY);

        const updatedData = lastQueryData;
        if (updatedData) {
          updatedData.files = lastQueryData?.files.filter((f) => f.id !== variables.fileId);
        }

        // Perform an update so that changes reflect immediately.
        queryClient.setQueryData(GET_CREATIVE_PREFERENCES_KEY, updatedData);
        return { previous: lastQueryData, new: updatedData };
      },
    }
  );
}
