import { DropDownChip, DropDownChipProps } from '@replai-platform/ui-components';

export interface CampaignFilterProps {
  options: DropDownChipProps['dropDownOptions'];
  onChange: DropDownChipProps['onChange'];
  loading: boolean;
  dropdownMaxHeightInVH?: number;
}

const CampaignFilter: React.VFC<CampaignFilterProps> = ({ options, onChange, loading, dropdownMaxHeightInVH }) => (
  <DropDownChip
    data-test="filter-campaign-dropdown"
    placeHolder="Select Campaign"
    defaultOption="All Campaigns"
    dropDownType="multiselect"
    dropDownOptions={options}
    dropDownAlignment="right"
    onChange={onChange}
    prefixLabel="Campaigns"
    loading={loading}
    dropdownMaxHeightInVH={dropdownMaxHeightInVH}
  />
);

export default CampaignFilter;
