import * as SDK from '@replai-platform/sdk';
import {
  camelCaseToCapitalCase,
  Colors,
  DropDownChip,
  EmptyState,
  Icons,
  Pagination,
  Skeleton,
  TagCard,
  Typography,
  VerticalMenuItemProps,
  VerticalMenuItemVariant,
} from '@replai-platform/ui-components';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useFeature } from '@optimizely/react-sdk';
import { useDeepCompareEffect, useMount, useTitle } from 'react-use';
import useTagDescription from '../../../api/hooks/tags/useTagDescription';
import { api } from '../../../api';
import useLockedFeaturesUserInterest from '../../../api/hooks/projects/useLockedFeaturesUserInterest';
import useTagMetrics from '../../../api/hooks/tags/useTagMetrics';
import useTagPreviewInfo from '../../../api/hooks/tags/useTagPreviewInfo';
import NetworkFilter, { NetworkFilterProps } from '../../../components/FilterBar/Filters/NetworkFilter';
import BuyingStrategyFilter, {
  BuyingStrategyFilterProps,
} from '../../../components/FilterBar/Filters/BuyingStrategyFilter';
import useBuyingStrategyFilter from '../../../components/FilterBar/hooks/useBuyingStrategyFilter';
import useNetworkFilter from '../../../components/FilterBar/hooks/useNetworkFilter';
import TagPreviewVideosWrapper from '../../../components/TagPreviewVideosWrapper';
import VerticalMenuPageLayout from '../../../components/VerticalMenuPageLayout';
import { LibraryActions } from '../../../store/library';
import type { RootState } from '../../../store/rootReducer';
import { getHighlightScore, getTagIconDefinition } from '../../../utils';
import { Page } from '../../../utils/enums';
import { TAG_KINDS_TOOLTIPS } from '../../../utils/constants';
import * as Styled from './styles';
import TagPreviewWrapper from './TagPreviewWrapper';
import type { PreviewedTag, SortBy, TagValue } from './types';
import { getTagBadge, hasTagIcon, logEventOnAction, MAX_PREVIEW_ITEMS, NEW_BADGE_PROPS } from './utils';

const PAGE_SIZE = 30;

const hasTagTypeBeenRequested = ({
  tagType,
  lockedFeatureInterests,
}: {
  tagType: string;
  lockedFeatureInterests: SDK.LockedFeatureInterest[];
}) => !!(lockedFeatureInterests || []).find((f) => f.subtype === tagType);

const buildTagTypeWithCount = ({
  selectedTagType,
  onTagTypeClick,
  categoryLabel,
  categoryTooltip,
  tags,
  newTags,
  lockedFeatureInterests,
}: {
  selectedTagType: string;
  onTagTypeClick: ({ type, kind }: { type: string; kind?: string; isTagRequest?: boolean }) => void;
  categoryLabel: string;
  categoryTooltip: string;
  tags?: SDK.TagWithKind[];
  newTags?: SDK.TagTypeSuggestion[];
  lockedFeatureInterests?: SDK.LockedFeatureInterest[];
}) => {
  if (!tags?.length && !newTags?.length) {
    return [];
  }

  let tagTypeObjects: VerticalMenuItemProps[] = [];

  if (tags) {
    const tagTypes: { [k: string]: { count: number; kind: string } } = {};

    (tags || []).forEach((tag) => {
      tagTypes[tag.type] = { count: (tagTypes[tag.type]?.count ?? 0) + 1, kind: tag.kind };
    });

    tagTypeObjects = Object.entries(tagTypes).map(([tagType, { count, kind }]) => ({
      id: camelCaseToCapitalCase(tagType),
      label: camelCaseToCapitalCase(tagType),
      badgeText: count.toString(),
      variant: VerticalMenuItemVariant.COUNTER,
      onClick: () => onTagTypeClick({ type: camelCaseToCapitalCase(tagType), kind }),
      isSelected: selectedTagType === camelCaseToCapitalCase(tagType),
    }));
  } else if (newTags) {
    tagTypeObjects = (newTags || []).map(({ tagType }) => ({
      id: camelCaseToCapitalCase(tagType),
      label: camelCaseToCapitalCase(tagType),
      onClick: () => onTagTypeClick({ type: camelCaseToCapitalCase(tagType), kind: 'new' }),
      onIconClick: () => onTagTypeClick({ type: camelCaseToCapitalCase(tagType), kind: 'new', isTagRequest: true }),
      isSelected: selectedTagType === camelCaseToCapitalCase(tagType),
      ...(hasTagTypeBeenRequested({ tagType, lockedFeatureInterests: lockedFeatureInterests ?? [] })
        ? {
            variant: VerticalMenuItemVariant.TEXT,
            badgeText: 'In progress',
          }
        : { variant: VerticalMenuItemVariant.ICON, icon: 'Plus' as Icons.BaseIconTypes }),
    }));
  }

  return [
    { variant: VerticalMenuItemVariant.DIVIDER, label: categoryLabel, tooltipText: categoryTooltip },
    ...tagTypeObjects.sort((a, b) => a.label?.localeCompare(b.label ?? '') ?? 0),
  ];
};

const buildTagTypes = ({
  isTechmerc1569Enabled,
  tags = [],
  newTags,
  lockedFeatureInterests,
  selectedTagType,
  onTagTypeClick,
}: {
  isTechmerc1569Enabled: boolean;
  tags: SDK.TagWithKind[];
  newTags: SDK.TagTypeSuggestion[];
  lockedFeatureInterests?: SDK.LockedFeatureInterest[];
  selectedTagType: string;
  onTagTypeClick: ({ type, kind }: { type: string; kind?: string; isTagRequest?: boolean }) => void;
}) => {
  const tagTypes = tags.map((tag) => tag.type);
  const newTagTypes = buildTagTypeWithCount({
    newTags: newTags.filter((t) => !tagTypes.includes(t.tagType)),
    onTagTypeClick,
    selectedTagType,
    lockedFeatureInterests,
    categoryLabel: TAG_KINDS_TOOLTIPS({ isTechmerc1569Enabled }).NEW.label,
    categoryTooltip: TAG_KINDS_TOOLTIPS({ isTechmerc1569Enabled }).NEW.tooltip,
  });

  const tagTypesCustom = buildTagTypeWithCount({
    tags: tags.filter((tag) => tag.kind === SDK.TagKind.Custom),
    onTagTypeClick,
    selectedTagType,
    categoryLabel: TAG_KINDS_TOOLTIPS({ isTechmerc1569Enabled }).CUSTOM.label,
    categoryTooltip: TAG_KINDS_TOOLTIPS({ isTechmerc1569Enabled }).CUSTOM.tooltip,
  });

  const tagTypesNotCustom = buildTagTypeWithCount({
    tags: tags.filter((tag) => tag.kind !== SDK.TagKind.Custom),
    onTagTypeClick,
    selectedTagType,
    categoryLabel: TAG_KINDS_TOOLTIPS({ isTechmerc1569Enabled }).CORE.label,
    categoryTooltip: TAG_KINDS_TOOLTIPS({ isTechmerc1569Enabled }).CORE.tooltip,
  });

  return [
    {
      label: 'All',
      badgeText: tags?.length.toString(),
      variant: VerticalMenuItemVariant.COUNTER,
      onClick: () => onTagTypeClick({ type: '' }),
      isSelected: !selectedTagType,
    } as VerticalMenuItemProps,
  ]
    .concat(newTagTypes)
    .concat(tagTypesCustom)
    .concat(tagTypesNotCustom);
};

const buildTagValues = ({
  tags,
  newTags,
  onClick,
}: {
  tags: SDK.TagWithKind[];
  newTags: SDK.TagTypeSuggestion[];
  onClick: (t: PreviewedTag) => void;
}) => {
  const tagObjects = (tags || []).map((tag) => {
    const { iconName: iconThumbnail, props: iconProps } = getTagIconDefinition({
      type: tag.type,
      value: tag.value,
    });

    return {
      tagId: tag.id,
      rawTagType: tag.type,
      kind: tag.kind,
      value: tag.value,
      title: tag.value ?? SDK.messages.NOT_AVAILABLE,
      subTitle: camelCaseToCapitalCase(tag.type),
      onClick: () => onClick({ ...tag, value: tag.value, rawTagType: tag.type, type: 'tag' }),
      iconThumbnail,
      iconProps,
      newScore: 0,
      bestScore: 0,
    };
  });

  const tagTypes = tags.map((tag) => tag.type);
  const newTagObjects = (newTags || [])
    .filter((t) => !tagTypes.includes(t.tagType))
    .reduce(
      (acc, { tagType, values }) => [
        ...acc,
        ...values.map((tag) => ({
          tagId: tag.tagId,
          rawTagType: tagType,
          kind: 'new',
          value: tag.tagValue,
          title: tag.tagValue ?? SDK.messages.NOT_AVAILABLE,
          subTitle: camelCaseToCapitalCase(tagType),
          bestScore: 0,
          newScore: 1,
          badge: NEW_BADGE_PROPS,
          badgeTooltip: 'This is a suggested tag and can be requested',
          ...(tag.exampleAssetId && tag.exampleAssetUrl
            ? {
                onClick: () => onClick({ ...tag, value: tag.tagValue, rawTagType: tagType, tagType, type: 'new-tag' }),
              }
            : {}),
        })),
      ],
      [] as TagValue[]
    );

  return [...tagObjects, ...newTagObjects];
};

const sortTagValues = ({ tagValues, sortBy }: { tagValues: TagValue[]; sortBy: SortBy }) => {
  switch (sortBy) {
    case 'alphabetical':
      return [
        ...tagValues.sort((a, b) => {
          if (!a.title) {
            return 1;
          }
          if (!b.title) {
            return -1;
          }

          return a.title?.localeCompare(b.title);
        }),
      ];
    case 'promising':
      return [...tagValues.sort((a, b) => (a.bestScore > b.bestScore ? -1 : 1))];
    case 'new':
      return [...tagValues.sort((a, b) => (a.newScore > b.newScore ? -1 : 1))];
    default:
      return tagValues;
  }
};

const splitSortTagValues = ({ tagValues, sortBy }: { tagValues: TagValue[]; sortBy: SortBy }) => {
  const newTags = tagValues.filter((tag) => tag.kind === 'new');
  const customTags = tagValues.filter((tag) => tag.kind === SDK.TagKind.Custom);
  const otherTags = tagValues.filter((tag) => tag.kind !== 'new' && tag.kind !== SDK.TagKind.Custom);
  return [
    ...sortTagValues({ tagValues: newTags, sortBy }),
    ...sortTagValues({ tagValues: customTags, sortBy }),
    ...sortTagValues({ tagValues: otherTags, sortBy }),
  ];
};

const getNewTagVideoPreview = ({ newTags, tagId }: { newTags: SDK.TagTypeSuggestion[]; tagId: SDK.UUID }) => {
  const allTagValues = newTags.flatMap((t) => t.values);
  const tag = allTagValues.find((t) => t.tagId === tagId);

  if (tag?.exampleAssetUrl) {
    return {
      id: tag.exampleAssetId ?? '',
      url: tag.exampleAssetUrl,
      timeframes: tag.exampleAnnotation as SDK.ParsedAnnotationTimeframe[],
    };
  }

  return null;
};

const getTagPreview = ({
  tagPreviews,
  newTags,
  isNewTag,
  tagId,
}: {
  tagPreviews: SDK.TagPreviewItem[];
  tagId: string;
  newTags: SDK.TagTypeSuggestion[];
  isNewTag: boolean;
}) => {
  const tagPreview = (tagPreviews ?? []).find(({ tagId: id }) => id === tagId);
  let videoPreview: SDK.PreviewVideo | null;

  if (isNewTag) {
    videoPreview = getNewTagVideoPreview({ newTags, tagId });
  } else {
    videoPreview = tagPreview?.videos?.find((v) => !!v.timeframes?.length) ?? null;
  }

  return {
    thumbnailUrl: tagPreview?.thumbnails?.[0]?.imageUrl ?? '',
    ...(videoPreview && {
      videoUrl: videoPreview.url,
      startSeconds: videoPreview.timeframes?.[0]?.startSeconds ?? 0,
      endSeconds: videoPreview.timeframes?.[0]?.endSeconds ?? 3,
    }),
  };
};

const TagLibrary = () => {
  const [tagTypes, setTagTypes] = useState<VerticalMenuItemProps[]>([]);
  const [tagValues, setTagValues] = useState<TagValue[]>([]);
  const [previewedTag, setPreviewedTag] = useState<PreviewedTag | null>();
  const [sortBy, setSortBy] = useState<SortBy>('alphabetical');
  const tagsConfig = useSelector((state: RootState) => state.project.config.tagsTypes);
  const [isTechmerc1525Enabled] = useFeature('techmerc-1525'); // Buying Strategy filter
  const [isTechmerc1569Enabled] = useFeature('techmerc-1569'); // Renaming Tags Filter
  const dispatch = useDispatch();

  const projectName = useSelector((state: RootState) => state.project.name);
  const projectId = useSelector((state: RootState) => state.project.id);
  const selectedNetworks = useSelector((state: RootState) => state.filters.networks);
  const selectedTagMenuOption = useSelector((state: RootState) => state.library.selectedTagMenuOption);
  const currentPage = useSelector((state: RootState) => state.library.currentTagsSubPage);

  const pageTagValues = useMemo(
    () => (tagValues ?? []).slice((currentPage - 1) * PAGE_SIZE, (currentPage - 1) * PAGE_SIZE + PAGE_SIZE),
    [currentPage, tagValues]
  );

  const resetScroll = () => {
    // For some reason, scrollIntoView doesn't work when called synchronously in Chrome/Brave.
    setTimeout(() => document.getElementById('grid-container')?.scrollIntoView({ behavior: 'smooth' }));
  };

  const resetPagePosition = useCallback(() => {
    dispatch(LibraryActions.changeCurrentTagsSubPage({ page: 1, logEvent: false }));
    resetScroll();
  }, [dispatch]);

  // Wrap the dropdown options in 'useMemo' to prevent the list from being re-created at every
  // render which causes the dropdown to update on each render too.
  const sortByOptions = useMemo(() => {
    const onSortByClick = (sort: SortBy) => {
      setSortBy(sort);
      resetPagePosition();
      logEventOnAction({ action: `Click Sort By - ${sort}` });
    };
    return [
      { label: 'A-Z', onClick: () => onSortByClick('alphabetical') },
      { label: 'Promising', onClick: () => onSortByClick('promising') },
      { label: 'New', onClick: () => onSortByClick('new') },
    ];
  }, [resetPagePosition]);

  const {
    networkFilterOptions,
    networkFilterOnChange,
    isLoading: isNetworkFilterLoading,
  } = useNetworkFilter(Page.TagsLibrary);
  const networkFilter = useMemo(
    () =>
      ({
        options: networkFilterOptions,
        onChange: (...args) => {
          networkFilterOnChange(...args);
          resetPagePosition();
        },
        loading: isNetworkFilterLoading,
      } as NetworkFilterProps),
    [networkFilterOptions, networkFilterOnChange, resetPagePosition, isNetworkFilterLoading]
  );

  // Build buying strategy filter
  const {
    buyingStrategyFilterOptions,
    buyingStrategyFilterOnChange,
    isLoading: isBuyingStrategyFilterLoading,
  } = useBuyingStrategyFilter(Page.Videos);
  const buyingStrategyFilter = useMemo(
    () =>
      ({
        options: buyingStrategyFilterOptions,
        onChange: buyingStrategyFilterOnChange,
        loading: isBuyingStrategyFilterLoading,
      } as BuyingStrategyFilterProps),
    [buyingStrategyFilterOptions, buyingStrategyFilterOnChange, isBuyingStrategyFilterLoading]
  );

  useTitle(`Library - ${projectName}`);

  // Scrolls into the selected tag type if it's not visible (on the first render, when changing pages)
  useMount(() => {
    setTimeout(() =>
      document.getElementById(selectedTagMenuOption.type)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    );
  });

  // get tags list
  const tagsParams = useMemo(
    () => ({
      projectIds: [projectId],
      tagsFilters: {
        tagTypesToExclude: [...(tagsConfig?.excluded ?? []), ...(tagsConfig?.psychological ?? [])].map((type) => ({
          type,
        })),
      },
      adsFilters: {
        networksToConsider: selectedNetworks,
      },
      assetFilters: {
        types: [SDK.AssetType.Video],
      },
    }),
    [projectId, tagsConfig, selectedNetworks]
  );

  const { data: tags, isLoading: isTagsLoading } = useQuery<SDK.GetTagsResponse>(['tags.getTags', tagsParams], () =>
    api.tags.getTags(tagsParams)
  );

  // get tags metrics
  const tagsMetricsParams = useMemo(
    () => ({
      projectIds: [projectId],
      tagsFilters: {
        tagTypesToExclude: [...(tagsConfig?.excluded ?? []), ...(tagsConfig?.psychological ?? [])].map((type) => ({
          type,
        })),
      },
      adsFilters: {
        networksToConsider: selectedNetworks,
      },
      assetFilters: {
        types: [SDK.AssetType.Video],
      },
    }),
    [projectId, tagsConfig, selectedNetworks]
  );
  const { data: { tags: tagsMetrics } = {}, isLoading: isTagsMetricsLoading } = useTagMetrics(tagsMetricsParams, {
    enabled: !!tags?.length,
  });

  // get suggested tags list
  const tagTypeSuggestionsParams = useMemo(
    () => ({
      projectIds: [projectId],
    }),
    [projectId]
  );
  const { data: newTags, isLoading: isTagTypesSuggestionsLoading } = useQuery<SDK.GetTagTypeSuggestionsResponse>(
    ['tags.getTagTypeSuggestions', tagTypeSuggestionsParams],
    () => api.tags.getTagTypeSuggestions(tagTypeSuggestionsParams)
  );

  const isLoading = isTagsLoading || isTagTypesSuggestionsLoading;

  // get tag previews
  const previewInfoParams = useMemo(
    () => ({
      projectIds: [projectId],
      tagIds: pageTagValues
        .filter(({ title, rawTagType }) => !hasTagIcon({ type: rawTagType, value: title === undefined ? null : title }))
        .map(({ tagId }) => tagId),
      maxRecords: MAX_PREVIEW_ITEMS,
    }),
    [projectId, pageTagValues]
  );
  const { data: { tags: tagPreviews } = {}, isLoading: isTagPreviewsLoading } = useTagPreviewInfo(previewInfoParams, {
    enabled: !isTagsLoading && !!previewInfoParams.tagIds?.length,
  });

  // get user locked features requests
  const lockedFeaturesParams = useMemo(() => ({ projectId }), [projectId]);
  const { data: { lockedFeatureInterests } = {} } = useLockedFeaturesUserInterest(lockedFeaturesParams);

  // get selected tag description
  const selectedTagId = previewedTag
    ? (previewedTag as SDK.TagWithKind).id ?? (previewedTag as SDK.TagValueSuggestion).tagId
    : null;
  const tagDescriptionParams = useMemo<SDK.GetTagsDescriptionsRequest>(
    () => ({
      projectIds: [projectId],
      tags: selectedTagId ? [selectedTagId] : [],
    }),
    [selectedTagId, projectId]
  );
  const { data: tagDescription, isLoading: isLoadingTagDescription } = useTagDescription(tagDescriptionParams, {
    enabled: !!selectedTagId && previewedTag?.type === 'tag',
  });

  const tagPreviewVideosTitle = (
    <>
      <Typography>
        Videos with {previewedTag?.type ? `${camelCaseToCapitalCase(previewedTag.type)}:` : ''}{' '}
        {previewedTag?.value ?? SDK.messages.NOT_AVAILABLE}
      </Typography>
      {isLoadingTagDescription ? (
        <Skeleton width="50%" />
      ) : (
        <Typography>{tagDescription?.tags?.[0]?.description ?? ''}</Typography>
      )}
    </>
  );

  const onTagClick = (tag: PreviewedTag) => {
    setPreviewedTag({ ...tag });
    logEventOnAction({ action: 'Click Tag' });
  };

  const onTagTypeClick = ({ type, kind, isTagRequest }: { type: string; kind: string; isTagRequest?: boolean }) => {
    dispatch(LibraryActions.changeSelectedTagMenuOption({ type, kind, isTagRequest, logEvent: true }));
    dispatch(LibraryActions.changeCurrentTagsSubPage({ page: 1, logEvent: false }));

    if (isTagRequest) {
      const { values = [] } = (newTags || []).find((t) => camelCaseToCapitalCase(t.tagType) === type) ?? {};
      const randomIndex = Math.floor(Math.random() * values.length);
      const randomTagValue = values[randomIndex] ?? {};
      setPreviewedTag({ ...randomTagValue, value: randomTagValue.tagValue, tagType: type, type: 'new-tag' });
    }
  };

  const onToggleHoverPreview = (shouldPlayVideo: boolean) =>
    shouldPlayVideo && logEventOnAction({ action: 'Hover Tag' });

  const onPageChange = (page: number) => {
    dispatch(LibraryActions.changeCurrentTagsSubPage({ page, logEvent: true }));
    resetScroll();
  };

  useDeepCompareEffect(() => {
    // prevent unnecessary operations until we have both responses
    if (!isTagsLoading && !isTagTypesSuggestionsLoading && tags && newTags) {
      let unfilteredTagValues = buildTagValues({ tags, newTags, onClick: onTagClick });

      if (tagsMetrics?.length) {
        const updatedTagValues = (unfilteredTagValues || []).map((tagValue) => {
          const tagMetric = tagsMetrics.find(({ tag }) => tag.id === tagValue.tagId);

          return {
            ...tagValue,
            bestScore: tagMetric
              ? getHighlightScore({
                  shareOfSpend: tagMetric?.shareOfSpend ?? 0,
                  shareOfAssets: tagMetric?.shareOfAssets ?? 0,
                })
              : 0,
            ...(tagMetric && getTagBadge({ tagMetric })),
          };
        });
        unfilteredTagValues = updatedTagValues;
      }

      unfilteredTagValues = splitSortTagValues({
        tagValues: unfilteredTagValues,
        sortBy,
      });

      setTagTypes(
        buildTagTypes({
          isTechmerc1569Enabled,
          tags,
          newTags,
          lockedFeatureInterests,
          selectedTagType: selectedTagMenuOption.type,
          onTagTypeClick,
        })
      );
      setTagValues(
        unfilteredTagValues.filter(({ subTitle }) =>
          selectedTagMenuOption.type ? subTitle === selectedTagMenuOption.type : true
        )
      );
    }
  }, [
    tags || {},
    tagsMetrics || {},
    newTags || {},
    lockedFeatureInterests || {},
    isTagsLoading,
    isTagTypesSuggestionsLoading,
    selectedTagMenuOption.type,
    sortBy,
  ]);

  const renderSkeleton = () => Array.from(Array(18), (_val, index) => <TagCard key={index} thumbnailLoading />);

  return (
    <>
      <VerticalMenuPageLayout
        header={
          <Styled.HeaderContainer>
            <Styled.MainSection>
              <Typography type="display-sm" noMargin fontWeight="medium" color={Colors.Gray[900]}>
                Tags
              </Typography>
            </Styled.MainSection>
            <Styled.SubSection>
              <Typography type="text-md" noMargin color={Colors.Gray[500]}>
                Explore your world of tags. Click on each one to see some related examples from your videos.
              </Typography>
              <Styled.FiltersContainer>
                <NetworkFilter
                  options={networkFilter.options}
                  onChange={networkFilter.onChange}
                  loading={networkFilter.loading}
                />
                {isTechmerc1525Enabled && (
                  <BuyingStrategyFilter
                    options={buyingStrategyFilter.options}
                    onChange={buyingStrategyFilter.onChange}
                    loading={buyingStrategyFilter.loading}
                  />
                )}
                <DropDownChip
                  dropDownType="singleselect"
                  dropDownOptions={sortByOptions}
                  defaultOption={sortByOptions[0]?.label}
                  prefixLabel="Sort by: "
                  disableCrossButton
                  loading={isLoading || isTagsMetricsLoading}
                />
              </Styled.FiltersContainer>
            </Styled.SubSection>
          </Styled.HeaderContainer>
        }
        verticalMenuProps={{
          items: tagTypes,
          loading: isLoading,
          onSearch: (search) => logEventOnAction({ action: 'Search Category', params: { search } }),
        }}
      >
        <Styled.TagsContainer id="grid-container" data-test="tags-library-main-container">
          {isLoading
            ? renderSkeleton()
            : pageTagValues.length
            ? pageTagValues.map(({ tagId, ...tag }) => {
                const { thumbnailUrl, videoUrl, startSeconds, endSeconds } = getTagPreview({
                  tagPreviews: tagPreviews ?? [],
                  newTags: newTags ?? [],
                  tagId,
                  isNewTag: tag.newScore === 1,
                });

                return (
                  <TagCard
                    key={tagId}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...tag}
                    thumbnailLoading={isTagPreviewsLoading}
                    thumbnailUrl={thumbnailUrl}
                    videoUrl={videoUrl ? new URL(videoUrl) : undefined}
                    startSeconds={startSeconds}
                    endSeconds={endSeconds}
                    onToggleHoverPreview={onToggleHoverPreview}
                    alwaysShowSubtitle={!selectedTagMenuOption.type}
                  />
                );
              })
            : undefined}
        </Styled.TagsContainer>
        {!isLoading && pageTagValues.length === 0 ? <EmptyState icon="Tag" text="No tags available" /> : undefined}
        {!isLoading && pageTagValues.length === 0 ? undefined : (
          <Pagination
            style={{ alignSelf: 'center' }}
            totalCount={tagValues.length}
            pageSize={PAGE_SIZE}
            currentPage={currentPage}
            onPageChange={onPageChange}
          />
        )}
      </VerticalMenuPageLayout>
      {!!previewedTag && previewedTag.type === 'new-tag' && (
        <TagPreviewWrapper
          tag={previewedTag}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          videoPreviews={[getNewTagVideoPreview({ newTags: newTags!, tagId: previewedTag.tagId })!]}
          tagRequested={false}
        />
      )}
      {!!previewedTag && previewedTag.type === 'tag' && (
        <TagPreviewVideosWrapper
          title={tagPreviewVideosTitle}
          isOpen
          tag={{ value: previewedTag.value, type: previewedTag.rawTagType ?? '' }}
          page={Page.TagsLibrary}
          component="Tags Library"
          onClose={() => setPreviewedTag(null)}
        />
      )}
    </>
  );
};

export default TagLibrary;
