/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as SDK from '@replai-platform/sdk';

export const orderAnnotations = (
  annotations: SDK.ParsedAnnotationTimeframe[],
  duration: number,
  allowNoNone = false
): SDK.ParsedAnnotationTimeframe[] => {
  // Remove None/No tags
  const withoutNoNone = allowNoNone
    ? annotations
    : annotations.filter((tag) => !(tag.value ?? '').startsWith('None') && !(tag.value ?? '').startsWith('No'));

  const fullVideoTags = withoutNoNone.filter(
    (tag) => tag.endSeconds === Math.round(duration) && tag.startSeconds === 0
  );

  // Order the rest of the tags by start time
  const rest = withoutNoNone
    .filter((tag) => !fullVideoTags.find((t) => t.type === tag.type && t.value === tag.value))
    .sort((a, b) => a.startSeconds - b.startSeconds);

  // Full tags at the bottom
  return [...rest, ...fullVideoTags];
};

export const createCoreAnnotations = (recommendations, coreAnnotation) =>
  recommendations
    .filter((r) => r.name.tag?.kind === SDK.TagKind.Core || r.name.previous?.kind === SDK.TagKind.Core)
    .map((rec) => ({
      type: rec.name.tag?.type ?? rec.name.previous?.type,
      value: rec.name.tag?.value ?? rec.name.previous?.value,
    }))
    .filter(
      (tag) => !coreAnnotation.some((annotation) => annotation.type === tag.type && annotation.value === tag.value)
    )
    .map((tag) => ({
      type: tag.type,
      value: tag.value,
      endSeconds: 0,
      startSeconds: 0,
    }));
