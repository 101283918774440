import { PaymentIconProps } from './types';

const Skrill = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.4621 13.0004C27.4621 12.4481 27.8996 12 28.4384 12C28.9781 12 29.4153 12.4481 29.4153 13.0004C29.4153 13.5531 28.9781 14.0002 28.4384 14.0002C27.8996 14.0002 27.4621 13.5531 27.4621 13.0004ZM30.2691 12.8181L32.2493 12.4552V20.2412H30.2691V12.8181ZM33.1614 12.8181V20.2412H35.1428V12.4552L33.1614 12.8181ZM27.4485 14.5464V20.2412H29.4296V14.5464H27.4485ZM23.5706 17.0375C23.5706 15.3648 24.5006 14.5233 26.331 14.4629C26.331 14.4629 26.6616 14.4499 26.8567 14.4924V16.157C25.6964 16.1979 25.5682 16.6271 25.5682 17.6282V20.2412H23.5706V17.0375ZM20.9563 14.5399L20.9462 14.5667C20.8753 14.7574 20.5525 15.6252 19.828 16.5225V12.4552L17.7835 12.8695V20.2412H19.828V17.9622C20.4201 18.872 20.7126 20.2412 20.7126 20.2412H23.161C22.919 19.2099 21.8596 17.3088 21.8596 17.3088C22.8108 16.0735 23.228 14.7579 23.2946 14.5399H20.9563ZM13.5247 14.7621C13.5247 15.2966 14.1047 15.3353 14.355 15.3518C16.1911 15.4779 17.2854 16.3984 17.2854 17.8147C17.2854 19.0643 16.3069 20.3247 14.124 20.3247C13.1119 20.3247 12.2483 20.2226 11.4824 20.0163V18.1974C11.9938 18.4129 12.8365 18.639 13.7046 18.639C14.4968 18.639 14.968 18.3706 14.968 17.9144C14.968 17.3641 14.3966 17.3282 14.1775 17.3145L14.1751 17.3143C11.6182 17.1359 11.3428 15.5868 11.3428 14.9291C11.3428 13.787 12.1341 12.4552 14.3674 12.4552C15.6629 12.4552 16.3612 12.6622 16.9627 12.8776L16.9929 12.887V14.6536L16.9704 14.6471C16.7289 14.5425 16.4453 14.4398 16.4453 14.4398C15.9357 14.2835 15.2231 14.1172 14.6723 14.1172C14.3591 14.1172 13.5247 14.1172 13.5247 14.7621Z"
      fill="#862165"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default Skrill;
