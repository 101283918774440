import { CountryIconProps } from './types';

const MK = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#FFDA44"
    />
    <path
      d="M13.8259 23.8624C15.7776 23.5647 17.5741 22.7961 19.0973 21.6772L12.001 12.0004L13.8259 23.8624Z"
      fill="#D80027"
    />
    <path
      d="M10.1757 0.137695C8.22408 0.435445 6.42755 1.20401 4.9043 2.32287L12.0007 11.9997L10.1757 0.137695Z"
      fill="#D80027"
    />
    <path
      d="M4.9043 21.6772C6.42745 22.7961 8.22398 23.5646 10.1756 23.8625L12.0007 12.0004L4.9043 21.6772Z"
      fill="#D80027"
    />
    <path
      d="M2.32384 4.90234C1.20494 6.42559 0.436469 8.22208 0.138672 10.1738L12.0007 11.9988L2.32384 4.90234Z"
      fill="#D80027"
    />
    <path
      d="M0.138672 13.8253C0.436422 15.777 1.20498 17.5735 2.32389 19.0967L12.0007 12.0004L0.138672 13.8253Z"
      fill="#D80027"
    />
    <path
      d="M23.863 10.174C23.5652 8.22233 22.7967 6.42579 21.6778 4.90259L12.001 11.999L23.863 10.174Z"
      fill="#D80027"
    />
    <path
      d="M19.0973 2.32282C17.5741 1.20396 15.7776 0.435445 13.8259 0.137695L12.001 11.9997L19.0973 2.32282Z"
      fill="#D80027"
    />
    <path
      d="M21.6779 19.0967C22.7968 17.5735 23.5653 15.7769 23.8631 13.8254L12.001 12.0004L21.6779 19.0967Z"
      fill="#D80027"
    />
    <path
      d="M12.001 16.1743C14.3062 16.1743 16.1749 14.3056 16.1749 12.0004C16.1749 9.69525 14.3062 7.82654 12.001 7.82654C9.69586 7.82654 7.82715 9.69525 7.82715 12.0004C7.82715 14.3056 9.69586 16.1743 12.001 16.1743Z"
      fill="#D80027"
    />
    <path
      d="M12.0006 15.1297C13.7295 15.1297 15.131 13.7281 15.131 11.9992C15.131 10.2703 13.7295 8.86877 12.0006 8.86877C10.2717 8.86877 8.87012 10.2703 8.87012 11.9992C8.87012 13.7281 10.2717 15.1297 12.0006 15.1297Z"
      fill="#FFDA44"
    />
  </svg>
);
export default MK;
