import { CountryIconProps } from './types';

const CK = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3599)">
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C0 12.0029 12 0.0013125 12 0C18.6274 0 24 5.37262 24 12Z"
        fill="#0052B4"
      />
      <path
        d="M11.9678 12.001H11.9999C11.9999 11.9902 11.9999 11.9797 11.9999 11.9689C11.9892 11.9796 11.9785 11.9903 11.9678 12.001Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 6.26184C12 4.15035 12 2.76684 12 0.000976562H11.998C5.37145 0.00210156 0 5.37421 0 12.001H6.26086V8.47546L9.78637 12.001H11.9679C11.9786 11.9903 11.9893 11.9796 12 11.9689C12 11.1604 12 10.4391 12 9.78745L8.47443 6.26184H12Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.07124 1.56458C4.19441 2.63328 2.63409 4.19356 1.56543 6.07039V11.9994H4.69588V4.69512V4.69503H12.0002C12.0002 3.7077 12.0002 2.76711 12.0002 1.56458H6.07124Z"
        fill="#D80027"
      />
      <path
        d="M11.9999 10.5236L7.73637 6.26013H6.26074V6.26023L11.9998 11.9992H11.9999C11.9999 11.9992 11.9999 10.9817 11.9999 10.5236Z"
        fill="#D80027"
      />
      <path
        d="M16.1747 12L16.3968 12.6833H17.1152L16.534 13.1055L16.756 13.7888L16.1747 13.3665L15.5935 13.7888L15.8155 13.1055L15.2344 12.6833H15.9527L16.1747 12Z"
        fill="#F0F0F0"
      />
      <path
        d="M13.2229 13.223L13.863 13.5491L14.371 13.0411L14.2586 13.7507L14.8987 14.0769L14.1891 14.1892L14.0768 14.8989L13.7506 14.2587L13.041 14.3711L13.5491 13.8631L13.2229 13.223Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 16.1731L12.6833 15.9511L12.6833 15.2328L13.1055 15.8139L13.7888 15.5918L13.3665 16.1731L13.7888 16.7544L13.1055 16.5324L12.6833 17.1135V16.3952L12 16.1731Z"
        fill="#F0F0F0"
      />
      <path
        d="M13.2229 19.1254L13.549 18.4853L13.041 17.9774L13.7506 18.0897L14.0768 17.4496L14.1891 18.1592L14.8987 18.2715L14.2586 18.5977L14.371 19.3073L13.863 18.7992L13.2229 19.1254Z"
        fill="#F0F0F0"
      />
      <path
        d="M16.1738 20.3481L15.9518 19.6648H15.2334L15.8146 19.2426L15.5926 18.5593L16.1738 18.9816L16.7551 18.5593L16.5331 19.2426L17.1143 19.6648H16.3959L16.1738 20.3481Z"
        fill="#F0F0F0"
      />
      <path
        d="M19.125 19.1254L18.4849 18.7994L17.977 19.3073L18.0893 18.5977L17.4492 18.2716L18.1588 18.1592L18.2712 17.4496L18.5973 18.0897L19.3069 17.9774L18.7988 18.4853L19.125 19.1254Z"
        fill="#F0F0F0"
      />
      <path
        d="M20.3484 16.1731L19.6651 16.3952V17.1135L19.2428 16.5323L18.5596 16.7544L18.9819 16.1731L18.5596 15.5918L19.2428 15.8139L19.6651 15.2328V15.9511L20.3484 16.1731Z"
        fill="#F0F0F0"
      />
      <path
        d="M19.125 13.223L18.799 13.8631L19.3069 14.3711L18.5973 14.2587L18.2712 14.8988L18.1588 14.1892L17.4492 14.0768L18.0893 13.7507L17.977 13.0411L18.485 13.5491L19.125 13.223Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3599">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default CK;
