import { Avatar, AvatarProps, Modal } from '@replai-platform/ui-components';
import { useState } from 'react';
import styled from 'styled-components';
import { logEvent } from '../../analytics';

const Container = styled.div`
  position: relative;
  padding: 0.25rem; /* Compensate for hover outline */

  /* Make avatar clickable */
  > div > span:first-child {
    z-index: 1;
  }

  /* Override image style to improve usability. */
  > div > div > div > img {
    height: 100%;
    object-fit: contain;
  }
  cursor: pointer;
`;

export type ClickableAvatarProps = {
  avatarImageUrl?: string;
  dialogContent?: JSX.Element;
  label?: string;
  secondaryLabel?: string;
  size?: AvatarProps['size'];
  status?: AvatarProps['status'];
  onDialogContentOpen?: () => void;
  onDialogContentClose?: () => void;
  dialogOpen?: boolean;
};

const ClickableAvatar = ({
  avatarImageUrl,
  dialogContent,
  status,
  label,
  secondaryLabel,
  size,
  onDialogContentOpen,
  onDialogContentClose,
  dialogOpen,
}: ClickableAvatarProps) => {
  const [open, setOpen] = useState(dialogOpen ?? false);

  return (
    <Container aria-label="Clickable Avatar">
      <Avatar
        icon="ZoomIn"
        clickable
        imgUrl={avatarImageUrl}
        label={label}
        secondaryLabel={secondaryLabel}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          logEvent({
            component: 'ClickableAvatar',
            action: 'Click to Preview',
            category: 'user_actions',
          });
          onDialogContentOpen?.();
          setOpen(true);
        }}
        squared
        overlayIcon="ZoomIn"
        outline="hover"
        size={size}
        status={status}
      />
      {dialogContent ? (
        <Modal
          width="1000px"
          height="800px"
          onClick={(e) => {
            e.stopPropagation();
          }}
          onClose={() => {
            onDialogContentClose?.();
            setOpen(false);
          }}
          isOpen={open}
        >
          {dialogContent}
        </Modal>
      ) : null}
    </Container>
  );
};

export default ClickableAvatar;
