import React, { useState } from 'react';
import styled from 'styled-components';
import Colors from '../Colors';
import type { TagBreakdownT } from './timeline.types';
import TimelineHeader from './TimelineHeader/TimelineHeader';
import TimelineBody from './TimelineBody/TimelineBody';
import TimelineTimestamps from './TimelineTimestamps/TimelineTimestamps';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.Gray[50]};
  border: 1px solid ${Colors.Gray[200]};
  border-radius: 0.5rem;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
`;

const TimelineTimestampContainer = styled.div`
  padding: 0.75rem 1.5rem;
  background-color: ${Colors.Gray[50]};
  border-bottom: 1px solid ${Colors.Gray[200]};
`;

const TimelineBodyContainer = styled.div`
  margin: 0 1rem;
`;

export interface TimelineProps {
  breakdown: TagBreakdownT[];
  projectId?: string;
  clusterId?: string;
  setIsPlaying: (isPlaying: boolean) => void;
  isPlaying: boolean;
  playedSeconds: number;
  playedFraction: number;
  videoDurationInSeconds: number;
  timestampsInSeconds: number[];
  seekToPoint: (point: number) => void;
  customHeaderActionsComponent?: React.ReactNode;
  showWithBreakdowns?: boolean;
  showRecommendationsToggle?: boolean;
  loading?: boolean;
  resetFilters?: () => void;
  onClickGroup?: (group: boolean) => void;
  onClickWithBreakdowns?: (withBreakdowns: boolean) => void;
  onClickShowOnlyRecommendations?: () => void;
  rowsSpacing?: string;
}

const Timeline: React.FC<TimelineProps> = ({
  breakdown,
  setIsPlaying,
  isPlaying,
  playedSeconds,
  playedFraction,
  timestampsInSeconds,
  videoDurationInSeconds,
  seekToPoint,
  customHeaderActionsComponent,
  showWithBreakdowns = false,
  showRecommendationsToggle = true,
  loading = false,
  resetFilters,
  onClickGroup,
  onClickWithBreakdowns,
  onClickShowOnlyRecommendations,
  rowsSpacing,
}) => {
  const [groupByTagCategory, setGroupByTagCategory] = useState(false);
  const [showOnlyRecommendations, setShowOnlyRecommendations] = useState(false);

  return (
    <Container>
      <TimelineHeader
        customHeaderActionsComponent={customHeaderActionsComponent}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        playedSeconds={playedSeconds}
        groupByTagCategory={groupByTagCategory}
        setGroupByTagCategory={setGroupByTagCategory}
        setShowOnlyRecommendations={setShowOnlyRecommendations}
        showWithBreakdowns={showWithBreakdowns}
        showRecommendationsToggle={showRecommendationsToggle}
        resetFilters={resetFilters}
        onClickGroup={onClickGroup}
        onClickWithBreakdowns={onClickWithBreakdowns}
        onClickShowOnlyRecommendations={onClickShowOnlyRecommendations}
      />
      <TimelineTimestampContainer>
        <TimelineTimestamps timestampsInSeconds={timestampsInSeconds} />
      </TimelineTimestampContainer>
      <TimelineBodyContainer>
        <TimelineBody
          data={breakdown}
          videoDurationInSeconds={videoDurationInSeconds}
          playedFraction={playedFraction}
          playedSeconds={playedSeconds}
          seekToPoint={seekToPoint}
          recommendationsOnly={showOnlyRecommendations}
          groupByTagCategory={groupByTagCategory}
          loading={loading}
          rowsSpacing={rowsSpacing}
        />
      </TimelineBodyContainer>
    </Container>
  );
};

export default Timeline;
export type { TagBreakdownT };
