import { CountryIconProps } from './types';

const AO = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3557)">
      <path
        d="M0 12C0 5.37262 5.37262 0 12 0C18.6274 0 24 5.37262 24 12C23.4783 12 12 13.5652 12 13.5652L0 12Z"
        fill="#D80027"
      />
      <path
        d="M24 12.0004C24 18.6277 18.6274 24.0004 12 24.0004C5.37262 24.0004 0 18.6277 0 12.0004"
        fill="black"
      />
      <path
        d="M10.3535 9.54359L11.3708 10.2819L10.9831 11.4776L11.9997 10.7381L13.017 11.4763L12.6279 10.281L13.6443 9.54162L12.3874 9.54237L11.9982 8.34711L11.6105 9.5428L10.3535 9.54359Z"
        fill="#FFDA44"
      />
      <path
        d="M14.9994 6.80332C14.0538 6.25736 13.0199 5.99969 11.9994 6.00147V7.56635C12.7537 7.56499 13.5178 7.7553 14.2168 8.1589C16.3345 9.38158 17.0627 12.0992 15.84 14.2169C14.6173 16.3346 11.8998 17.0628 9.78199 15.8402C9.16502 15.484 8.66698 15.0003 8.29985 14.4422L6.99316 15.3048C7.4899 16.06 8.16471 16.7137 8.99941 17.1956C11.8646 18.8498 15.5413 17.8646 17.1955 14.9995C18.8497 12.1343 17.8646 8.45754 14.9994 6.80332Z"
        fill="#FFDA44"
      />
      <path
        d="M8.53898 10.956C8.12414 11.7144 8.40267 12.6655 9.16101 13.0804L14.2446 15.8582C13.8989 16.4901 14.0825 17.2593 14.7144 17.605L16.0876 18.3561C16.7195 18.7019 17.5122 18.4698 17.8579 17.8378L18.6091 16.4646L8.53898 10.956Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3557">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default AO;
