import styled from 'styled-components';

export const Container = styled.div`
  max-width: 200px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const KPINameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const KPIValueContainer = styled.div`
  display: flex;
  align-items: center;
  width: 75px;
`;
