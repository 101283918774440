export enum PromotedObjectType {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  WEBSITE = 'WEBSITE',
}

export enum Network {
  APPLOVIN = 'applovin',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  IRONSOURCE = 'ironsource',
  LIFTOFF = 'liftoff',
  MINTEGRAL = 'mintegral',
  MOLOCO = 'moloco',
  SENSORTOWER = 'sensortower',
  REPLAI_API = 'replai_api',
  SNAPCHAT = 'snapchat',
  TIKTOK = 'tiktok',
  UNITY = 'unity',
  VUNGLE = 'vungle',
  YOUTUBE = 'youtube',
}

export enum MMP {
  ADJUST = 'adjust',
  APPSFLYER = 'appsflyer',
  SINGULAR = 'singular',
}

export type NetworkOrMmp = Network | MMP;
