import { CountryIconProps } from './types';

const MV = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#D80027"
    />
    <path d="M19.826 6.26099H4.17383V17.7393H19.826V6.26099Z" fill="#6DA544" />
    <path
      d="M13.9323 15.3913C12.0594 15.3913 10.541 13.8729 10.541 12C10.541 10.127 12.0594 8.60867 13.9323 8.60867C14.5163 8.60867 15.0658 8.75633 15.5455 9.0163C14.793 8.28032 13.7638 7.82605 12.628 7.82605C10.3229 7.82605 8.4541 9.69477 8.4541 11.9999C8.4541 14.3051 10.3229 16.1738 12.628 16.1738C13.7638 16.1738 14.793 15.7196 15.5455 14.9836C15.0658 15.2436 14.5163 15.3913 13.9323 15.3913Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default MV;
