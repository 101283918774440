import * as SDK from '@replai-platform/sdk';
import { InsightMetricsCarousel, SectionHeader } from '@replai-platform/ui-components';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { logEvent } from '../../analytics';
import TagPreviewVideosWrapper from '../../components/TagPreviewVideosWrapper';
import useFetchInsights from '../../hooks/useFetchInsights';
import { RootState } from '../../store/rootReducer';
import { Page } from '../../utils/enums';
import { getInsightMetricProps } from '../Insights/utils';
import { getNetworksString } from './utils';

const INSIGHTS_LIMIT = 5;

const Container = styled.div`
  margin-top: 2rem;
`;

const InsightsContainer = styled.div<{ $shrink: boolean }>`
  display: flex;
  flex-grow: 0;
  max-width: 100%;
  min-height: ${({ $shrink }) => ($shrink ? '9.8493rem' : '14.0625rem')};
  margin-top: 1rem;

  > div {
    width: 100%;
  }
`;

const TopInsights = () => {
  const navigate = useNavigate();
  const projectId = useSelector((state: RootState) => state.project.id);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const projectNetworks = useSelector((state: RootState) => state.project.networks)!;
  const networks = useSelector((state: RootState) => state.filters.networks);

  const [tagPreviewOpen, setTagPreviewOpen] = useState(false);
  const [currentTag, setCurrentTag] = useState({} as SDK.Tag);

  const {
    insightsData: insights,
    isInsightsDataLoading: isInsightsLoading,
    tagsPreviewData: tagPreviews,
    isTagsPreviewDataLoading: isTagPreviewsLoading,
  } = useFetchInsights({ maxRecords: INSIGHTS_LIMIT, positiveInsightsOnly: true });

  const insightsCarouselData = useMemo(
    () =>
      insights?.map((insight) =>
        getInsightMetricProps({
          insight,
          projectNetworks,
          thumbnailUrl: tagPreviews?.tags.find((tagPreview) => tagPreview.tagId === insight.content?.tag?.id)
            ?.thumbnails?.[0]?.imageUrl,
          thumbnailLoading: isTagPreviewsLoading,
          onClick: () => {
            logEvent({
              component: 'Dashboard',
              action: 'Click Insight',
              category: 'user_actions',
              parameters: {
                tag: insight.content?.tag ?? {},
                page: Page.Dashboard,
              },
            });
            setCurrentTag(insight.content?.tag);
            setTagPreviewOpen(true);
          },
          showCoreTagThumbnails: true,
        })
      ),
    [insights, projectNetworks, tagPreviews?.tags, isTagPreviewsLoading]
  );

  return (
    <Container data-test="top-insights-container">
      <SectionHeader
        title="Top Insights"
        subtitle={`Insights for ${getNetworksString(networks, projectNetworks)}`}
        buttons={[
          {
            children: 'See all',
            variant: 'outlined',
            color: 'Gray',
            onClick: () => {
              navigate(`/${projectId}/${Page.Insights}`);
              logEvent({
                component: 'Dashboard',
                action: 'Click See all (Top Insights)',
                category: 'user_actions',
                parameters: { page: Page.Dashboard },
              });
            },
          },
        ]}
      />
      <InsightsContainer data-test="insights-container" $shrink>
        <InsightMetricsCarousel
          data={isInsightsLoading ? null : insightsCarouselData}
          emptyStateProps={{
            icon: 'Activity',
            text: 'No insights available',
          }}
        />
      </InsightsContainer>
      <TagPreviewVideosWrapper
        isOpen={tagPreviewOpen}
        tag={currentTag}
        page={Page.Dashboard}
        component="Top Insights"
        onClose={() => setTagPreviewOpen(false)}
      />
    </Container>
  );
};

export default TopInsights;
