/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { CssBaseline, ThemeProvider as MaterialThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { Colors } from '@replai-platform/ui-components';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import styled, { createGlobalStyle, ThemeProvider as StyledThemeProvider } from 'styled-components';
import IdleManager from './components/IdleManager';
import './index.css';
import AppRoutes from './routes';
import store from './store';
import { RootState } from './store/rootReducer';
import OptimizelyInstance from './utils/optimizely';

type CustomTheme = {
  colors: {
    'brand-primary': string;
    'brand-secondary': string;
    'color-tertiary': string;
    'color-white': string;
    'color-grey': string;
    background: string;
    'color-accessory-1': string;
    'color-accessory-2': string;
    'color-accessory-3': string;
    'color-accessory-4': string;
    'color-accessory-5': string;
    'color-accessory-6': string;
    'color-accessory-7': string;
    'top-nav-button-background': string;
  };
};
declare module '@material-ui/core/styles/createMuiTheme' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
  /* eslint-enable @typescript-eslint/no-empty-interface */
}

const theme = createMuiTheme({
  colors: {
    'brand-primary': Colors.Primary[500],
    'brand-secondary': Colors.BlueGray[500],
    'color-tertiary': Colors.BlueLight[500],
    'color-white': Colors.Gray[25],
    'color-grey': Colors.Gray[500],
    background: 'white',
    'color-accessory-1': Colors.Indigo[500],
    'color-accessory-2': Colors.Purple[500],
    'color-accessory-3': Colors.Pink[500],
    'color-accessory-4': Colors.Rose[500],
    'color-accessory-5': Colors.Orange[500],
    'color-accessory-6': Colors.BlueGray[500],
    'color-accessory-7': Colors.BlueLight[500],
    'top-nav-button-background': Colors.Gray[200], // TODO: remove this when we start using the new component
  },
  palette: {
    type: 'light',
    validation: {
      green: Colors.Success[500],
      red: Colors.Error[500],
    },
    navBar: {
      background: Colors.Gray[25],
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    divider: 'rgba(255,255,255,0.2)',
  },
  typography: {
    fontFamily: [
      'Inter',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(','),
    h1: {
      fontWeight: 600,
      fontSize: '1rem',
      paddingBottom: '0.5rem',
      borderBottom: '1px solid white',
      marginBottom: '1.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.135rem',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '0.8rem',
      color: 'rgba(0,0,0, 1)',
    },
    body2: {
      fontWeight: 'bold',
      fontSize: '0.8rem',
      color: 'rgba(0,0,0,0.7)',
    },
    subtitle1: {
      fontWeight: 'normal',
      fontSize: '0.8rem',
      color: 'rgba(255,255,255, 0.4)',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          '-webkit-appearance': 'none',
        },
        '*::-webkit-scrollbar:vertical': {
          width: '8px',
        },
        '*::-webkit-scrollbar:horizontal': {
          height: '8px',
        },
        '*::-webkit-scrollbar-track': {
          borderRadius: '5px',
          background: Colors.Common.White,
          boxShadow: 'inset 0 0 6px rgba(1,1,1,0.10)',
          webkitBoxShadow: 'inset 0 0 6px rgba(1,1,1,0.10)',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: '5px',
          background: Colors.Gray[400],
        },
        '*::-webkit-scrollbar-thumb:hover': {
          background: Colors.Gray[600],
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.75rem',
        backgroundColor: '#444444',
      },
    },
  },
});

const Full = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

const Global = createGlobalStyle`
 font-family:       Inter-ExtraBold,Inter,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
`;

hotjar.initialize(2334472, 6);

const OptimizelyApp = () => {
  const { id: userId, email: userEmail } = useSelector((state: RootState) => state.auth);
  const projectId = useSelector((state: RootState) => state.project.id);
  const organizationId = useSelector((state: RootState) => state.project.organizationId);

  useEffect(() => {
    if (userId == null) {
      return;
    }

    // Sentry identify user
    Sentry.setUser({ id: userId, email: userEmail });

    // Hotjar setup
    hotjar.identify(userId, { userId, userEmailDomain: userEmail ? userEmail.split('@')[1] : undefined });

    // Optimizely setup
    OptimizelyInstance.setUser({
      id: userId,
      attributes: {
        USER_ID: userId,
        PROJECT_ID: projectId,
        PROJECT_ORGANIZATION_ID: organizationId,
      },
    });

    // Appcues setup
    window.Appcues?.identify(userId, { projectId });
  }, [userId, projectId, userEmail, organizationId]);

  return (
    <OptimizelyProvider optimizely={OptimizelyInstance}>
      <Global />
      <IdleManager />
      <MaterialThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <CssBaseline />
          <Full>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </Full>
        </StyledThemeProvider>
      </MaterialThemeProvider>
    </OptimizelyProvider>
  );
};

// React Query
// https://react-query.tanstack.com/guides/important-defaults
// https://react-query.tanstack.com/guides/window-focus-refetching
// The option: 'refetchOnWindowFocus' was added in order to not apply the refetch every time we leave the application.

const App = () => (
  <Provider store={store}>
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              staleTime: 30 * 1000,
              refetchOnWindowFocus: false,
            },
          },
        })
      }
    >
      <OptimizelyApp />
      <ReactQueryDevtools initialIsOpen={false} position="top-right" />
    </QueryClientProvider>
  </Provider>
);

export default App;
