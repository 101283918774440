import { IntegrationIconProps } from './types';

const YandexKassa = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="24.9085"
      cy="16.0067"
      rx="4.09207"
      ry="4.08308"
      fill="#28EDBD"
    />
    <path
      d="M7.63619 7.99953L11.1437 4.49976C10.2881 5.3864 9.7088 6.61845 9.7088 7.9465C9.7088 9.12891 10.0946 10.436 10.9535 11.2872L22.7157 22.9443L14.6423 31L5.39708 21.7751C2.19723 18.5822 2.20153 13.4043 5.40668 10.2168L7.63619 7.99953Z"
      fill="#0D59AF"
    />
    <path
      d="M22.7336 9.05568L14.6602 1L11.0243 4.62794C10.2347 5.50325 9.70898 6.68132 9.70898 7.94653C9.70898 9.12893 10.0948 10.436 10.9537 11.2872L15.7416 16.0324L22.7336 9.05568Z"
      fill="#1374EC"
    />
  </svg>
);
export default YandexKassa;
