/* eslint-disable react/no-array-index-key */
import '../../common.module.css';
import * as Icons from '../../Icons';
import * as Styled from './styles';
import { Colors } from '../..';

interface SelectInputProps {
  values: string[];
  onChange: (value: string) => void;
}

const SelectInput = ({ values, onChange }: SelectInputProps) => (
  <Styled.Root data-test="select-input-container">
    <Styled.Select onChange={(e) => onChange(e.target.value)}>
      {values.map((value, index) => (
        <option data-test="select-input-option" value={value} key={index}>
          {value}
        </option>
      ))}
    </Styled.Select>
    <Styled.IconHolder>
      <Icons.BaseIcons.ChevronDown color={Colors.Gray[500]} dimension={24} />
    </Styled.IconHolder>
  </Styled.Root>
);

export default SelectInput;
