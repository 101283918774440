import * as SDK from '@replai-platform/sdk';
import { Card, SelectInput, type KeyValue } from '@replai-platform/ui-components';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import CreativeFatigueTrend from '../../../../../components/CreativeFatigueTrend';
import ContextPerformanceTable from '../../../../../components/ContextPerformanceTable';
import CreativeMetricsBarChart from '../../../../../components/CreativeMetricsBarChart';
import CreativeTotalMetricChart from '../../../../../components/CreativeTotalMetricChart';
import { RootState } from '../../../../../store/rootReducer';
import buildKpiDropdownFilterProps from '../../../../../utils/buildKpiDropdownFilterProps';
import { TypographyClean } from '../../../../../utils/styles';
import { CreativeDetailsData } from '../../../../../utils/types';
import * as Style from './styles';
import TabWithSidebar from '../shared/TabWithSidebar';

const StaticMetricsTab: React.VFC<{
  creativeData: Partial<CreativeDetailsData>;
  propertyData?: KeyValue[];
  url: string;
  isLoading: boolean;
}> = ({ creativeData, propertyData, url, isLoading }) => {
  const defaultProjectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const availableKpis = buildKpiDropdownFilterProps(defaultProjectKpis).map((kpi) => ({
    title: kpi.label,
    value: kpi.value,
  }));

  const [selectedKpiIndex, setSelectedKpiIndex] = useState(0);

  const renderFatigueTrend = () => (
    <Card fullWidth disableContentPadding disableShadow>
      <Style.CardHeader>
        <TypographyClean type="text-lg" fontWeight="medium">
          Fatigue Trend
        </TypographyClean>
        <Style.CardHeaderRight>
          <SelectInput
            defaultOption={{ title: availableKpis[selectedKpiIndex].title }}
            onChange={(index) => {
              setSelectedKpiIndex(index);
              if (selectedKpiIndex !== index) {
                logEvent({
                  component: 'Concept Details Overview',
                  action: 'Change KPI',
                  category: 'user_actions',
                  parameters: {
                    changedKpi: availableKpis[selectedKpiIndex],
                    changedKpiLabel: `KPI ${availableKpis[selectedKpiIndex].title}`,
                  },
                });
              }
            }}
            options={availableKpis}
            placeholder="Select KPI"
          />
        </Style.CardHeaderRight>
      </Style.CardHeader>
      <Style.CardChart data-test="overview-chart">
        <CreativeFatigueTrend
          assetId={creativeData?.id ?? ''}
          selectedKpi={SDK.getMetricOrKPI(availableKpis[selectedKpiIndex].value) ?? SDK.KPI.IPM}
        />
      </Style.CardChart>
    </Card>
  );

  const renderMetrics = () => (
    <Style.MetricsContainer data-test="static-metrics-container">
      <Style.MetricsCardWrapper>
        <Card fullWidth>
          <Style.CardHeader data-test="static-card-header">
            <TypographyClean type="text-lg" fontWeight="medium">
              Total spend
            </TypographyClean>
          </Style.CardHeader>
          <Style.CardChart data-test="static-card-chart">
            <CreativeTotalMetricChart assetId={creativeData?.id ?? ''} />
          </Style.CardChart>
        </Card>
      </Style.MetricsCardWrapper>
      <Style.MetricsCardWrapper>
        <Card fullWidth>
          <Style.CardHeader data-test="static-card-header">
            <TypographyClean type="text-lg" fontWeight="medium">
              Impressions / Clicks
            </TypographyClean>
          </Style.CardHeader>
          <Style.CardChart data-test="static-card-chart">
            <CreativeMetricsBarChart assetId={creativeData?.id ?? ''} />
          </Style.CardChart>
        </Card>
      </Style.MetricsCardWrapper>
    </Style.MetricsContainer>
  );

  return (
    <TabWithSidebar
      propertyData={propertyData}
      url={url}
      isLoading={isLoading}
      mainContent={
        <>
          <Style.SectionContainer data-test="static-overview-fatigue-trend">
            {renderFatigueTrend()}
          </Style.SectionContainer>
          <Style.SectionContainer data-test="static-overview-metrics">{renderMetrics()}</Style.SectionContainer>
          <Style.SectionContainer data-test="static-overview-context-performance">
            <ContextPerformanceTable creativeData={{ ...creativeData }} />
          </Style.SectionContainer>
        </>
      }
    />
  );
};

export default StaticMetricsTab;
