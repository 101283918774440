/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSlice } from '@reduxjs/toolkit';
import { MultiSelectDropDownOption } from '@replai-platform/ui-components';
import { Filters, Page } from '../../utils/enums';
import { Alert } from '../../utils/types';

type CompareItem = {
  id: string;
  imageUrl: string;
  name: string;
  isMarketData: boolean;
};

type AppState = {
  page: Page;
  filterMenuOpen: boolean;
  compareMenuOpen: boolean;
  compareItems: { [key: string]: CompareItem };
  availableFilters: {
    id: Filters;
    title?: string;
    placeholder?: string;
    options?: MultiSelectDropDownOption[];
    minPlaceholder?: string;
    minValue?: number;
    limit?: number;
    collapsedByDefault?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
  }[];
  alert?: Alert;
};

const initialState: AppState = {
  page: Page.Dashboard,
  filterMenuOpen: false,
  compareMenuOpen: false,
  compareItems: {},
  availableFilters: [],
  alert: {
    open: false,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    duration: 3000,
  },
};

const AppSlice = createSlice({
  name: 'App',

  initialState,

  reducers: {
    setPage: (state, action: { payload: Page }) => {
      state.page = action.payload;
    },
    setFilterMenuOpen: (state, action: { payload: boolean }) => {
      state.filterMenuOpen = action.payload;
    },
    setCompareMenuOpen: (state, action: { payload: boolean }) => {
      state.compareMenuOpen = action.payload;
    },
    setCompareItems: (state, action: { payload: { [key: string]: CompareItem } }) => {
      state.compareItems = action.payload;
    },
    addCompareItem: (state, action: { payload: CompareItem }) => {
      state.compareItems[action.payload.id] = action.payload;
    },
    removeCompareItem: (state, action: { payload: { id: string } }) => {
      delete state.compareItems[action.payload.id];
    },
    setAlertOpen: (state, action: { payload: Alert }) => {
      state.alert = { duration: 3000, open: true, ...action.payload };
    },
    setAlertClose: (state) => {
      state.alert = { ...state.alert, open: false };
    },
    updateAvailableFilters: (
      state,
      action: {
        payload: {
          id: Filters;
          title?: string;
          placeholder?: string;
          options?: MultiSelectDropDownOption[];
          minPlaceholder?: string;
          minValue?: number;
          limit?: number;
          collapsedByDefault?: boolean;
          singleSelect?: boolean;
        };
      }
    ) => {
      const filterIndex = state.availableFilters.findIndex((filter) => filter.id === action.payload.id);
      if (filterIndex !== -1) {
        state.availableFilters[filterIndex] = action.payload;
      } else {
        state.availableFilters = [...state.availableFilters, action.payload];
      }
    },
  },
});

export const AppActions = AppSlice.actions;
export const AppReducer = AppSlice.reducer;
