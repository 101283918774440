import { IntegrationIconProps } from './types';

const Git = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.58536 17.4132C1.80488 16.6327 1.80488 15.3673 2.58536 14.5868L14.5868 2.58536C15.3673 1.80488 16.6327 1.80488 17.4132 2.58536L29.4146 14.5868C30.1951 15.3673 30.1951 16.6327 29.4146 17.4132L17.4132 29.4146C16.6327 30.1951 15.3673 30.1951 14.5868 29.4146L2.58536 17.4132Z"
      fill="#EE513B"
    />
    <path
      d="M12.1489 5.06165L10.9336 6.27699L14.0725 9.41589C13.9455 9.68831 13.8746 9.99213 13.8746 10.3125C13.8746 11.2221 14.4461 11.9982 15.2496 12.3014V19.9799C14.4461 20.283 13.8746 21.0592 13.8746 21.9688C13.8746 23.1424 14.826 24.0938 15.9996 24.0938C17.1732 24.0938 18.1246 23.1424 18.1246 21.9688C18.1246 21.1441 17.6549 20.4292 16.9684 20.0769V12.3118L19.9689 15.3123C19.8481 15.5792 19.7809 15.8755 19.7809 16.1875C19.7809 17.3611 20.7323 18.3125 21.9059 18.3125C23.0795 18.3125 24.0309 17.3611 24.0309 16.1875C24.0309 15.0139 23.0795 14.0625 21.9059 14.0625C21.6778 14.0625 21.4582 14.0984 21.2522 14.1649L18.0297 10.9424C18.0914 10.7434 18.1246 10.5318 18.1246 10.3125C18.1246 9.1389 17.1732 8.1875 15.9996 8.1875C15.7803 8.1875 15.5688 8.22073 15.3697 8.28242L12.1489 5.06165Z"
      fill="white"
    />
  </svg>
);
export default Git;
