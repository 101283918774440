import { CountryIconProps } from './types';

const DO = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M0.414062 15.1311C1.52209 19.2422 4.75914 22.4793 8.87027 23.5873V15.1311H0.414062Z"
      fill="#D80027"
    />
    <path
      d="M8.86924 0.41333C4.75816 1.52131 1.52107 4.75841 0.413086 8.86949H8.86929V0.41333H8.86924Z"
      fill="#0052B4"
    />
    <path
      d="M23.587 8.86973C22.479 4.75865 19.2419 1.52156 15.1309 0.413574V8.86978H23.587V8.86973Z"
      fill="#D80027"
    />
    <path
      d="M15.1309 23.5871C19.2419 22.479 22.479 19.242 23.5871 15.1309H15.1309V23.5871Z"
      fill="#0052B4"
    />
    <path
      d="M15.131 11.9998C15.131 13.7288 13.7295 15.1303 12.0006 15.1303C10.2717 15.1303 8.87012 13.7288 8.87012 11.9998C8.87012 10.2709 12.0006 8.86938 12.0006 8.86938C12.0006 8.86938 15.131 10.2709 15.131 11.9998Z"
      fill="#496E2D"
    />
    <path
      d="M8.87012 11.9998C8.87012 10.2709 10.2717 8.86938 12.0006 8.86938C13.7295 8.86938 15.131 10.2709 15.131 11.9998"
      fill="#0052B4"
    />
    <path
      d="M10.2393 10.4344V12.391C10.2393 13.3635 11.0276 14.1518 12.0001 14.1518C12.9726 14.1518 13.761 13.3635 13.761 12.391V10.4344H10.2393Z"
      fill="#D80027"
    />
  </svg>
);
export default DO;
