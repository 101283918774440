import { CountryIconProps } from './types';

const MO = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#496E2D"
    />
    <path
      d="M14.0872 11.5003C13.7927 11.5003 13.513 11.559 13.2533 11.6525C13.3956 11.3354 13.491 10.9826 13.491 10.6059C13.491 9.11521 12.0003 7.99719 12.0003 7.99719C12.0003 7.99719 10.5096 9.11521 10.5096 10.6059C10.5096 10.9826 10.605 11.3354 10.7473 11.6525C10.4875 11.5591 10.2079 11.5003 9.91337 11.5003C8.4227 11.5003 7.30469 12.9909 7.30469 12.9909C7.30469 12.9909 8.4227 14.4816 9.91337 14.4816C10.7874 14.4816 11.5328 13.9694 12.0003 13.5454C12.4679 13.9693 13.2133 14.4816 14.0873 14.4816C15.578 14.4816 16.696 12.9909 16.696 12.9909C16.696 12.9909 15.5779 11.5003 14.0872 11.5003Z"
      fill="#F0F0F0"
    />
    <path
      d="M12.0006 4.69458L12.3086 5.64221H13.305L12.499 6.22796L12.8066 7.17558L12.0006 6.58983L11.1946 7.17558L11.5023 6.22796L10.6963 5.64221H11.6927L12.0006 4.69458Z"
      fill="#FFDA44"
    />
    <path
      d="M8.2111 6.35156L8.80351 6.88491L9.49384 6.48637L9.16965 7.21458L9.76201 7.74802L8.96926 7.66467L8.64507 8.39288L8.47923 7.6132L7.68652 7.52986L8.3768 7.13123L8.2111 6.35156Z"
      fill="#FFDA44"
    />
    <path
      d="M5.4375 9.12594L6.21722 9.29164L6.6158 8.60132L6.69914 9.39402L7.47877 9.55986L6.75061 9.8841L6.83395 10.6768L6.30047 10.0845L5.57231 10.4087L5.97089 9.7183L5.4375 9.12594Z"
      fill="#FFDA44"
    />
    <path
      d="M15.7891 6.35156L15.1968 6.88491L14.5065 6.48637L14.8306 7.21458L14.2383 7.74802L15.031 7.66467L15.3552 8.39288L15.5211 7.6132L16.3138 7.52986L15.6235 7.13123L15.7891 6.35156Z"
      fill="#FFDA44"
    />
    <path
      d="M18.5627 9.12594L17.783 9.29164L17.3845 8.60132L17.3011 9.39402L16.5215 9.55986L17.2496 9.8841L17.1663 10.6768L17.6998 10.0845L18.4279 10.4087L18.0294 9.7183L18.5627 9.12594Z"
      fill="#FFDA44"
    />
    <path
      d="M11.9997 18.6554C13.1838 18.6554 14.2752 18.2603 15.1507 17.5956H8.84863C9.72416 18.2603 10.8156 18.6554 11.9997 18.6554Z"
      fill="#F0F0F0"
    />
    <path
      d="M7.2168 15.5243C7.38241 15.9033 7.59241 16.2583 7.83906 16.5841H16.1602C16.4069 16.2584 16.6169 15.9033 16.7825 15.5243H7.2168Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default MO;
