import { RecommendationType, TagIntroWithKind, TagWithKind, CreativeRecommendation } from '@replai-platform/sdk';
import { Color, Colors, Icons } from '@replai-platform/ui-components';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../store/rootReducer';
import { rejectNotHandled } from '../../utils';
import TagAvatar from '../TagAvatar';

export interface RecommendationFigureProps {
  value: CreativeRecommendation['name'];
  showIcon?: boolean;
  showPreviousOnReplace?: boolean;
}

type ActionType = 'add' | 'replace' | 'remove';

export interface AvatarPositioning {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  width?: string;
  height?: string;
}

const Container = styled.div`
  position: relative;
  z-index: 1;
  width: 3rem;
  height: 3rem;
`;

const FirstAvatarWrapper = styled.div`
  position: absolute;
`;

const LastAvatarWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;

const ActionIndicator = styled.div<{
  $color: string;
}>`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1.125rem;
  height: 1.125rem;
  padding: 0.2rem;
  background: ${Colors.Error[50]};
  border: 1.5px solid ${({ $color }) => $color};
  border-radius: 0.75rem;
`;

const ACTION_CONFIG: {
  [k in ActionType]: {
    color: Color;
  };
} = {
  add: {
    color: Colors.Success[500],
  },
  remove: {
    color: Colors.Error[500],
  },
  replace: {
    color: Colors.Warning[400],
  },
};

function renderActionIndicator({ actionType, showIcon = true }: { actionType: ActionType; showIcon?: boolean }) {
  let IconElement: ({ color, dimension }: Icons.BaseIconProps) => JSX.Element;
  switch (actionType) {
    case 'add':
      IconElement = Icons.getBaseIcon('Plus');
      break;
    case 'remove':
      IconElement = Icons.getBaseIcon('Trash');
      break;
    case 'replace':
      IconElement = Icons.getBaseIcon('RefreshCw');
      break;
    default:
      rejectNotHandled(actionType);
      throw new Error();
  }
  return (
    showIcon && (
      <ActionIndicator $color={ACTION_CONFIG[actionType].color}>
        <IconElement color={ACTION_CONFIG[actionType].color} />
      </ActionIndicator>
    )
  );
}

const RecommendationFigure: React.FC<RecommendationFigureProps> = ({
  value,
  showIcon = true,
  showPreviousOnReplace = true,
}: RecommendationFigureProps) => {
  const projectId = useSelector((state: RootState) => state.project.id);

  let firstTag: TagWithKind | TagIntroWithKind;
  let lastTag: TagWithKind | TagIntroWithKind;
  let actionType: ActionType;
  const tagType = value?.type;
  switch (tagType) {
    case RecommendationType.CREATIVE_ADD_TAG:
    case RecommendationType.CREATIVE_ADD_TAG_INTRO:
      actionType = 'add';
      firstTag = value?.tag;
      lastTag = value?.tag;
      break;
    case RecommendationType.CREATIVE_REMOVE_TAG:
    case RecommendationType.CREATIVE_REMOVE_TAG_INTRO:
      actionType = 'remove';
      firstTag = value?.tag;
      lastTag = value?.tag;
      break;
    case RecommendationType.CREATIVE_REPLACE_TAG:
    case RecommendationType.CREATIVE_REPLACE_TAG_INTRO:
      actionType = 'replace';
      firstTag = value?.previous;
      lastTag = value?.new;
      break;
    default:
      rejectNotHandled(tagType);
      throw new Error();
  }

  return (
    <Container>
      {actionType === 'replace' && showPreviousOnReplace ? (
        <FirstAvatarWrapper>
          <TagAvatar
            projectId={projectId}
            size={actionType === 'replace' && showPreviousOnReplace ? 'xs' : 'md'}
            tagId={firstTag.id}
            tagType={firstTag?.type}
            tagValue={firstTag?.value}
          />
        </FirstAvatarWrapper>
      ) : (
        ''
      )}
      <LastAvatarWrapper>
        <TagAvatar
          projectId={projectId}
          size={actionType === 'replace' && showPreviousOnReplace ? 'sm' : 'md'}
          tagId={lastTag?.id}
          tagType={lastTag?.type}
          tagValue={lastTag?.value}
        />
      </LastAvatarWrapper>
      {renderActionIndicator({
        actionType,
        showIcon,
      })}
    </Container>
  );
};

export default RecommendationFigure;
