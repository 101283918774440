import { CountryIconProps } from './types';

const NI = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3680)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.0007 -0.000244141C7.24316 -0.000244141 3.13241 2.76843 1.19141 6.78238H22.8099C20.869 2.76843 16.7582 -0.000244141 12.0007 -0.000244141Z"
        fill="#338AF3"
      />
      <path
        d="M12.0007 24.001C16.7582 24.001 20.869 21.2323 22.81 17.2184H1.19141C3.13241 21.2323 7.24316 24.001 12.0007 24.001Z"
        fill="#338AF3"
      />
      <path
        d="M11.9998 8.34766C9.9828 8.34766 8.34766 9.9828 8.34766 11.9998C8.34766 14.0169 9.9828 15.652 11.9998 15.652C14.0169 15.652 15.652 14.0169 15.652 11.9998C15.652 9.9828 14.0169 8.34766 11.9998 8.34766ZM11.9998 14.0868C10.8473 14.0868 9.91286 13.1525 9.91286 11.9998C9.91286 10.8472 10.8472 9.91286 11.9998 9.91286C13.1524 9.91286 14.0868 10.8472 14.0868 11.9998C14.0868 13.1525 13.1524 14.0868 11.9998 14.0868Z"
        fill="#FFDA44"
      />
      <path
        d="M13.8071 12.5222L11.9997 12.0005L10.1923 12.5222L9.58984 13.5657H14.4095L13.8071 12.5222Z"
        fill="#0052B4"
      />
      <path
        d="M11.9998 9.39136L10.7949 11.4783L11.9998 12L13.2048 11.4783L11.9998 9.39136Z"
        fill="#338AF3"
      />
      <path
        d="M10.1924 12.5211H13.8072L13.2047 11.4777H10.7949L10.1924 12.5211Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3680">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default NI;
