import { DropDownChip, DropDownChipProps } from '@replai-platform/ui-components';

export interface BreakdownFilterProps {
  options: DropDownChipProps['dropDownOptions'];
  disabled?: boolean;
}

const BreakdownFilter: React.VFC<BreakdownFilterProps> = ({ options, disabled = false }) => (
  <DropDownChip
    data-test="filter-breakdown-dropdown"
    placeHolder="network"
    defaultOption="Network"
    dropDownType="singleselect"
    dropDownOptions={options}
    dropDownAlignment="right"
    prefixLabel="Breakdown: "
    disabled={disabled}
    disableCrossButton
  />
);

export default BreakdownFilter;
