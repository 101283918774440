import { CountryIconProps } from './types';

const KY = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3590)">
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C0 12.0029 12 0.0013125 12 0C18.6274 0 24 5.37262 24 12Z"
        fill="#0052B4"
      />
      <path
        d="M11.9688 12.0004H12.0009C12.0009 11.9896 12.0009 11.9791 12.0009 11.9683C11.9902 11.979 11.9795 11.9897 11.9688 12.0004Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 6.26037C12 4.14889 12 2.76537 12 -0.000488281H11.998C5.37145 0.000636719 0 5.37275 0 11.9995H6.26086V8.474L9.78638 11.9995H11.9679C11.9786 11.9888 11.9893 11.9781 12 11.9674C12 11.1589 12 10.4376 12 9.78598L8.47444 6.26037H12Z"
        fill="#F0F0F0"
      />
      <path
        d="M17.2169 8.86913C18.3695 8.86913 19.3038 7.93476 19.3038 6.78216C19.3038 5.62956 18.3695 4.69519 17.2169 4.69519C16.0642 4.69519 15.1299 5.62956 15.1299 6.78216C15.1299 7.93476 16.0642 8.86913 17.2169 8.86913Z"
        fill="#6DA544"
      />
      <path
        d="M6.07027 1.56531C4.19344 2.63401 2.63311 4.19429 1.56445 6.07112V12.0001H4.69491V4.69586V4.69576H11.9993C11.9993 3.70843 11.9993 2.76784 11.9993 1.56531H6.07027Z"
        fill="#D80027"
      />
      <path
        d="M11.9989 10.5239L7.73539 6.2605H6.25977V6.26059L11.9988 11.9996H11.9989C11.9989 11.9996 11.9989 10.9821 11.9989 10.5239Z"
        fill="#D80027"
      />
      <path
        d="M20.8688 6.2605H13.5645V9.39095H20.8688V6.2605Z"
        fill="#D80027"
      />
      <path
        d="M13.5654 14.0869V16.1739H14.6634C15.3218 16.8191 16.223 17.2174 17.2176 17.2174C18.2122 17.2174 19.1134 16.8191 19.7718 16.1739H19.8263H20.8698V14.0869H13.5654Z"
        fill="#FFDA44"
      />
      <path
        d="M13.5654 8.86902V11.9995C13.5654 14.7951 17.2176 15.6516 17.2176 15.6516C17.2176 15.6516 20.8698 14.7951 20.8698 11.9995V8.86902H13.5654Z"
        fill="#338AF3"
      />
      <path
        d="M17.2174 10.5404C16.3044 10.5404 16.3044 11.3752 15.3914 11.3752C14.4784 11.3752 14.4784 10.5404 13.5654 10.5404V12.0013C14.4784 12.0013 14.4784 12.8361 15.3914 12.8361C16.3044 12.8361 16.3044 12.0013 17.2174 12.0013C18.1304 12.0013 18.1304 12.8361 19.0436 12.8361C19.9567 12.8361 19.9566 12.0013 20.8698 12.0013V10.5404C19.9567 10.5404 19.9567 11.3752 19.0436 11.3752C18.1304 11.3752 18.1304 10.5404 17.2174 10.5404Z"
        fill="#F3F3F3"
      />
      <path
        d="M17.2174 7.6167C16.3044 7.6167 16.3044 8.4515 15.3914 8.4515C14.4784 8.4515 14.4784 7.6167 13.5654 7.6167V9.07756C14.4784 9.07756 14.4784 9.91236 15.3914 9.91236C16.3044 9.91236 16.3044 9.07756 17.2174 9.07756C18.1304 9.07756 18.1304 9.91236 19.0436 9.91236C19.9567 9.91236 19.9566 9.07756 20.8698 9.07756V7.6167C19.9567 7.6167 19.9567 8.4515 19.0436 8.4515C18.1304 8.4515 18.1304 7.6167 17.2174 7.6167Z"
        fill="#F3F3F3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3590">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default KY;
