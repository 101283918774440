import React from 'react';
import Colors from '../Colors';
import Skeleton from '../Skeleton/Skeleton';
import Typography from '../Typography/Typography';
import * as Styled from './styles';

export type KeyValue = {
  key: string;
  value: string;
};

export type KeyValuesProps = {
  header: string;
  values: KeyValue[];
  isLoading?: boolean;
  loadingPlaceholderNumber?: number;
};

const KeyValues: React.FC<KeyValuesProps> = ({
  header,
  values,
  isLoading = false,
  loadingPlaceholderNumber = 10,
}) => {
  return (
    <Styled.Container>
      <Typography type="text-md" fontWeight="medium">
        {header}
      </Typography>
      {isLoading
        ? Array.from(Array(loadingPlaceholderNumber)).map((_val, index) => (
            <Skeleton key={index} />
          ))
        : values.map(({ key, value }) => (
            <Styled.KeyValue key={key}>
              <Typography
                type="text-xs"
                fontWeight="medium"
                color={Colors.Gray[500]}
                style={{ marginBottom: '0.5rem' }}
              >
                {key}:
              </Typography>
              <Styled.ValueTypography type="text-xs" fontWeight="medium">
                {value}
              </Styled.ValueTypography>
            </Styled.KeyValue>
          ))}
    </Styled.Container>
  );
};

export default KeyValues;
