import * as SDK from '@replai-platform/sdk';
import FrequencyMetricBadgege from '../../FrequencyMetricBadge/FrequencyMetricBadge';
import * as Styled from './styles';

interface Metrics {
  individualFrequency: string;
  totalFrequency: string;
  percentageFrequency: number;
  individualSpend: string;
  totalSpend: string;
  percentageSpend: number;
}

export interface TagMetricsProps {
  metrics: Metrics;
}

const TagMetrics: React.VFC<TagMetricsProps> = ({ metrics }) => {
  const {
    individualFrequency,
    totalFrequency,
    percentageFrequency,
    individualSpend,
    totalSpend,
    percentageSpend,
  } = metrics;
  return (
    <Styled.MetricsContainer>
      <FrequencyMetricBadgege
        type="frequency"
        current={individualFrequency}
        total={totalFrequency}
        percentage={percentageFrequency}
      />
      <FrequencyMetricBadgege
        type="metric"
        current={individualSpend}
        total={totalSpend}
        percentage={percentageSpend}
        metric={SDK.Metrics.SPEND}
      />
    </Styled.MetricsContainer>
  );
};

export default TagMetrics;
