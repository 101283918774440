import * as SDK from '@replai-platform/sdk';
import { UseQueryOptions, useQuery } from 'react-query';
import * as provider from '../../api/provider';

export const GET_CREATIVE_THUMBNAILS_REQUEST_KEY = 'get-creative-thumbnails-request';

const getCreativeThumbnailsRequest = async (
  projectId: SDK.UUID,
  creativeId: SDK.UUID,
  requestParams: SDK.GetCreativeThumbnailsRequest
): Promise<SDK.GetCreativeThumbnailsResponse> =>
  provider.getJson(
    `${SDK.BASE_URL()}/creative-production/${projectId}/creative/${creativeId}/thumbnails?${SDK.convertRequestToQueryParams(
      requestParams
    )}`
  );

export function useGetCreativeThumbnailsRequest(
  projectId: SDK.UUID,
  creativeId: SDK.UUID,
  requestParams: SDK.GetCreativeThumbnailsRequest,
  options?: UseQueryOptions<SDK.GetCreativeThumbnailsResponse>
) {
  return useQuery(
    [GET_CREATIVE_THUMBNAILS_REQUEST_KEY, projectId, creativeId, requestParams],
    () => getCreativeThumbnailsRequest(projectId, creativeId, requestParams),
    options
  );
}
