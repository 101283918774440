import { IntegrationIconProps } from './types';

const MsXbox = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      opacity="0.852"
      cx="16"
      cy="16"
      r="14"
      fill="url(#paint0_radial_1334_1808)"
    />
    <path
      d="M6.75235 26.5333C6.73325 26.5193 6.71368 26.5016 6.69409 26.4801C6.33171 26.1504 5.74143 25.6304 5.1246 24.8444C4.59188 23.6889 4.89384 21.748 6.20483 19.0075C8.05455 15.1409 11.0141 11.2151 12.2275 9.8225C11.8822 9.43732 10.8129 8.38253 9.29756 7.24477C7.78227 6.10701 6.67836 5.99047 6.29362 6.05961C6.64876 5.67443 7.47744 4.94852 8.17294 4.45964C10.3275 3.39299 14.3091 5.19543 16.0157 6.22257V13.2891C11.6652 16.6372 7.64021 21.8075 6.82633 23.8222C6.20515 25.36 6.45072 26.2128 6.69409 26.4801C6.71422 26.4984 6.73366 26.5161 6.75235 26.5333Z"
      fill="url(#paint1_linear_1334_1808)"
    />
    <path
      d="M6.75235 26.5331C6.73325 26.5191 6.71368 26.5014 6.69409 26.4799C6.3317 26.1502 5.74143 25.6302 5.1246 24.8442C4.59188 23.6887 4.89384 21.7477 6.20483 19.0073C8.35231 14.5183 12.2275 9.82227 12.2275 9.82227C12.2275 9.82227 12.9194 11.5405 14.4898 14.5183C10.4454 18.1034 7.64021 21.8072 6.82633 23.822C6.20516 25.3598 6.45072 26.2126 6.69409 26.4799C6.71422 26.4982 6.73366 26.5159 6.75235 26.5331Z"
      fill="url(#paint2_linear_1334_1808)"
    />
    <path
      d="M25.2789 26.5333C25.298 26.5193 25.3176 26.5016 25.3372 26.4801C25.6995 26.1504 26.2898 25.6304 26.9067 24.8444C27.4394 23.6889 27.1374 21.748 25.8264 19.0075C23.9767 15.1409 21.0171 11.2151 19.8037 9.8225C20.149 9.43732 21.2184 8.38253 22.7337 7.24477C24.249 6.10701 25.3529 5.99047 25.7376 6.05961C25.3825 5.67443 24.5538 4.94852 23.8583 4.45964C21.7038 3.39299 17.7222 5.19543 16.0155 6.22257V13.2891C20.3661 16.6372 24.391 21.8075 25.2049 23.8222C25.8261 25.36 25.5805 26.2128 25.3372 26.4801C25.317 26.4984 25.2976 26.5161 25.2789 26.5333Z"
      fill="url(#paint3_linear_1334_1808)"
    />
    <path
      d="M25.2789 26.5331C25.298 26.5191 25.3176 26.5014 25.3372 26.4799C25.6995 26.1502 26.2898 25.6302 26.9067 24.8442C27.4394 23.6887 27.1374 21.7477 25.8264 19.0073C23.6789 14.5183 19.8037 9.82227 19.8037 9.82227C19.8037 9.82227 19.1118 11.5405 17.5414 14.5183C21.5859 18.1034 24.391 21.8072 25.2049 23.822C25.8261 25.3598 25.5805 26.2126 25.3372 26.4799C25.317 26.4982 25.2976 26.5159 25.2789 26.5331Z"
      fill="url(#paint4_linear_1334_1808)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1334_1808"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 14.5) rotate(90.0548) scale(15.5)"
      >
        <stop stopColor="#FAFAFA" />
        <stop offset="0.499976" stopColor="#EFEFEF" />
        <stop offset="0.828794" stopColor="#C0BEC0" />
        <stop offset="1" stopColor="#879288" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_1334_1808"
        x1="12.9935"
        y1="4.72589"
        x2="13.2306"
        y2="12.7852"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008B00" />
        <stop offset="1" stopColor="#6CC329" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_1808"
        x1="10.1491"
        y1="12.6667"
        x2="12.875"
        y2="16.4"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008C00" />
        <stop offset="1" stopColor="#48BF21" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1334_1808"
        x1="19.0377"
        y1="4.72589"
        x2="18.8007"
        y2="12.7852"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008B00" />
        <stop offset="1" stopColor="#6CC329" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1334_1808"
        x1="21.8822"
        y1="12.6667"
        x2="19.1562"
        y2="16.4"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008C00" />
        <stop offset="1" stopColor="#48BF21" />
      </linearGradient>
    </defs>
  </svg>
);
export default MsXbox;
