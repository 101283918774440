/* eslint-disable @typescript-eslint/no-floating-promises */
import { awsConfig } from '@replai-platform/sdk';
import { Icons } from '@replai-platform/ui-components';
import { Amplify } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { api } from '../../api';
import { RootState } from '../../store/rootReducer';
import LoginView from './LoginView';
import { finalizeLogin } from './utils';

Amplify.configure(awsConfig);

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & h2 {
    animation: spin 5s infinite;
  }

  @keyframes spin {
    from {
      transform: rotateY(0deg);
    }

    to {
      transform: rotateY(-360deg);
    }
  }
  & h3 {
    margin: 0;
    margin: 0.5rem;
    font-size: 1.625rem;
  }
`;

const LoginRoute = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const dispatch = useDispatch();
  const projectId = useSelector((state: RootState) => state.project.id);

  useEffect(() => {
    api.auth
      .isLoggedIn()
      .then((status) => {
        setIsLoggedIn(status);
        if (status) {
          finalizeLogin({
            dispatch,
          });
        }
      })
      .catch(() => {});
  }, [dispatch]);

  if (isLoggedIn === null || (isLoggedIn && !projectId)) {
    // Still loading data.
    return (
      <Loader>
        <h2>
          <Icons.BrandIcons.Replai dimension={48} />
        </h2>
        <h3>Loading Project</h3>
      </Loader>
    );
  }

  return isLoggedIn ? <Navigate replace to={{ pathname: `/${projectId}/dashboard` }} /> : <LoginView />;
};

export default LoginRoute;
