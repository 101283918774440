import React from 'react';
import * as Styled from './styles';
import { Colors, ColorTypes } from '..';

interface ActiveIndicatorProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
  color?: ColorTypes;
}

const ActiveIndicator = ({
  size = 6,
  color = 'Success',
  style = {},
}: ActiveIndicatorProps) => {
  const colorBase = Colors[color];

  return (
    <Styled.Root
      $style={{ ...style } as string}
      $width={`${size}px`}
      $height={`${size}px`}
      $backgroundColor={color === 'Gray' ? colorBase[400] : colorBase[600]}
    />
  );
};

export default ActiveIndicator;
export type { ActiveIndicatorProps };
