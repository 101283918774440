import { CellProps } from 'react-table';
import { GeneratedColumn, RequiredGeneratorParams } from '../common';
import ClickableAvatar from '../../ClickableAvatar';
import ColumnHeader from '../generateHeader';

type ClickablePreviewAccessorType = {
  dialogContent: JSX.Element;
  previewUrl: string;
};

function getClickablePreviewColumn<D extends object>({
  columnId,
  accessor,
}: RequiredGeneratorParams<D, ClickablePreviewAccessorType>): GeneratedColumn<D, ClickablePreviewAccessorType> {
  return {
    id: columnId,
    accessor,
    Cell: ({ cell }: CellProps<D, ClickablePreviewAccessorType>) => (
      <ClickableAvatar avatarImageUrl={cell.value.previewUrl} dialogContent={cell.value.dialogContent} />
    ),
    Header: <ColumnHeader title="Preview" />,
    tooltip: 'Preview Thumbnail',
    sortDescFirst: false,
    sticky: 'left',
    width: 90,
    disableSortBy: true,
  };
}

export default getClickablePreviewColumn;
