import { IntegrationIconProps } from './types';

const MsYammer = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 17C28 22.9198 25.2353 28.2542 20.8126 32C5 17 5 17 5 17C19.3749 17 22.9686 17.0001 28 17Z"
      fill="url(#paint0_linear_1334_1266)"
    />
    <path
      d="M28 17C21.8905 17 16.8593 17 5 17C5 17 5 17 20.8126 1C25.2353 4.99557 28 10.6856 28 17Z"
      fill="url(#paint1_linear_1334_1266)"
    />
    <path
      d="M30.8334 24C31.5901 21.6436 32 19.1217 32 16.5C32 13.8783 31.5901 11.3564 30.8334 9L5 17L30.8334 24Z"
      fill="url(#paint2_linear_1334_1266)"
    />
    <path
      d="M7 12C7 10.3431 8.34315 9 10 9H17C18.6569 9 20 10.3431 20 12V24C20 25.6569 18.6569 27 17 27H16.7426C15.947 27 15.1839 26.6839 14.6213 26.1213L13.5 25H10C8.34315 25 7 23.6569 7 22V12Z"
      fill="black"
      fillOpacity="0.3"
    />
    <rect
      y="7"
      width="18"
      height="18"
      rx="2"
      fill="url(#paint3_linear_1334_1266)"
    />
    <path
      d="M8.77658 14.9916L6.41911 11H4L7.83667 17.4426V21H10.1479V17.4426L14 11H11.5655L9.20801 14.9916H8.77658Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_1266"
        x1="14.703"
        y1="21.8724"
        x2="24.6227"
        y2="27.9097"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0E51A3" />
        <stop offset="1" stopColor="#1464B6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1266"
        x1="12.9062"
        y1="13.1623"
        x2="24.9259"
        y2="6.21585"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2292E2" />
        <stop offset="1" stopColor="#2294DB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_1266"
        x1="12.56"
        y1="16.8586"
        x2="31.64"
        y2="16.8586"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#176FE0" />
        <stop offset="1" stopColor="#2F9FEF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1334_1266"
        x1="-5.21539e-08"
        y1="16"
        x2="18"
        y2="16"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#084C8A" />
        <stop offset="1" stopColor="#1572CB" />
      </linearGradient>
    </defs>
  </svg>
);
export default MsYammer;
