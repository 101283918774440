import { CountryIconProps } from './types';

const MQ = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M23.8964 10.4353C23.5654 7.90475 22.4287 5.45947 20.4845 3.51533C18.5404 1.57119 16.095 0.434425 13.5645 0.103394L13.5645 10.4353H23.8964Z"
      fill="#0052B4"
    />
    <path
      d="M10.4345 0.104065C7.9039 0.435096 5.45857 1.57182 3.51443 3.51596C1.57034 5.46014 0.43357 7.90547 0.102539 10.4361L10.4345 10.4361L10.4345 0.104065Z"
      fill="#0052B4"
    />
    <path
      d="M0.102539 13.5664C0.43357 16.097 1.57034 18.5424 3.51443 20.4865C5.45857 22.4306 7.90385 23.5673 10.4345 23.8984L10.4345 13.5665L0.102539 13.5664Z"
      fill="#0052B4"
    />
    <path
      d="M13.5645 23.8983C16.095 23.5673 18.5403 22.4306 20.4845 20.4864C22.4286 18.5423 23.5653 16.097 23.8964 13.5664H13.5645V23.8983Z"
      fill="#0052B4"
    />
    <path
      d="M16.0961 7.61471C16.0961 7.45515 16.2258 7.32545 16.3854 7.32545H17.0053C17.9851 7.32545 18.7823 6.52829 18.7823 5.54846C18.7823 4.56863 17.9852 3.77143 17.0053 3.77143H16.4166C16.2245 3.6271 15.9667 3.53809 15.6824 3.53809C15.0892 3.53809 14.6084 3.9227 14.6084 4.39716C14.6084 4.86704 15.0801 5.24823 15.6652 5.25563L15.665 5.25906H17.0053C17.1649 5.25906 17.2946 5.38881 17.2946 5.54837C17.2946 5.70793 17.1649 5.83763 17.0053 5.83763H16.3854C15.4055 5.83763 14.6084 6.63479 14.6084 7.61462C14.6084 8.59445 15.4055 9.39165 16.3854 9.39165H18.7823V7.90393H16.3854C16.2258 7.90398 16.0961 7.77428 16.0961 7.61471Z"
      fill="#F0F0F0"
    />
    <path
      d="M6.70442 7.61484C6.70442 7.45527 6.83413 7.32557 6.99373 7.32557H7.61361C8.59348 7.32557 9.39069 6.52841 9.39069 5.54858C9.39069 4.56876 8.59353 3.77155 7.61361 3.77155H7.02495C6.83281 3.62722 6.57505 3.53821 6.2907 3.53821C5.69759 3.53821 5.2168 3.92282 5.2168 4.39729C5.2168 4.86716 5.68841 5.24835 6.27359 5.25576L6.27331 5.25918H7.61366C7.77327 5.25918 7.90297 5.38893 7.90297 5.54849C7.90297 5.70805 7.77327 5.83776 7.61366 5.83776H6.99378C6.01391 5.83776 5.2168 6.63491 5.2168 7.61474C5.2168 8.59457 6.01386 9.39177 6.99378 9.39177H9.39064V7.90406H6.99373C6.83413 7.9041 6.70442 7.7744 6.70442 7.61484Z"
      fill="#F0F0F0"
    />
    <path
      d="M16.0961 18.6859C16.0961 18.5264 16.2258 18.3967 16.3854 18.3967H17.0053C17.9852 18.3967 18.7824 17.5995 18.7824 16.6197C18.7824 15.6399 17.9852 14.8427 17.0053 14.8427H16.4167C16.2245 14.6983 15.9667 14.6093 15.6824 14.6093C15.0892 14.6093 14.6084 14.994 14.6084 15.4684C14.6084 15.9383 15.0801 16.3195 15.6652 16.3269L15.665 16.3303H17.0053C17.1649 16.3303 17.2946 16.46 17.2946 16.6196C17.2946 16.7792 17.1649 16.9089 17.0053 16.9089H16.3854C15.4055 16.9089 14.6084 17.706 14.6084 18.6858C14.6084 19.6657 15.4055 20.4629 16.3854 20.4629H18.7823V18.9752H16.3854C16.2258 18.9753 16.0961 18.8455 16.0961 18.6859Z"
      fill="#F0F0F0"
    />
    <path
      d="M6.70447 18.6859C6.70447 18.5264 6.83417 18.3967 6.99378 18.3967H7.61365C8.59353 18.3967 9.39073 17.5995 9.39073 16.6197C9.39073 15.6399 8.59358 14.8427 7.61365 14.8427H7.025C6.83286 14.6983 6.57509 14.6093 6.29075 14.6093C5.69759 14.6093 5.2168 14.994 5.2168 15.4684C5.2168 15.9383 5.68841 16.3195 6.27359 16.3269L6.27331 16.3303H7.61365C7.77326 16.3303 7.90297 16.46 7.90297 16.6196C7.90297 16.7792 7.77326 16.9089 7.61365 16.9089H6.99378C6.01391 16.9089 5.2168 17.706 5.2168 18.6858C5.2168 19.6657 6.01386 20.4629 6.99378 20.4629H9.39068V18.9752H6.99378C6.83417 18.9753 6.70447 18.8455 6.70447 18.6859Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default MQ;
