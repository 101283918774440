import { CountryIconProps } from './types';

const TT = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3775)">
      <path
        d="M6.49812 1.33191C5.42384 1.88639 4.41541 2.61319 3.51485 3.51375C2.61424 4.41436 1.88745 5.42278 1.33301 6.49706L9.04896 14.9509L17.5028 22.6669C18.5771 22.1124 19.5856 21.3856 20.4861 20.4851C21.3867 19.5845 22.1135 18.576 22.668 17.5018L14.952 9.04786L6.49812 1.33191Z"
        fill="#F0F0F0"
      />
      <path
        d="M20.4858 20.486C20.9892 19.9826 21.4382 19.4455 21.8334 18.8821L5.11852 2.16711C4.55513 2.56232 4.01799 3.01129 3.5146 3.51468C3.01121 4.01807 2.56224 4.55521 2.16699 5.11865L18.8819 21.8335C19.4453 21.4383 19.9824 20.9894 20.4858 20.486Z"
        fill="black"
      />
      <path
        d="M3.51431 20.4865C7.30026 24.2725 12.9861 24.9992 17.5023 22.6683L1.33251 6.49854C-0.998347 11.0148 -0.271457 16.7006 3.51431 20.4865Z"
        fill="#D80027"
      />
      <path
        d="M20.485 3.51461C16.6991 -0.271293 11.0133 -0.998043 6.49707 1.33272L22.6668 17.5026C24.9977 12.9864 24.271 7.30052 20.485 3.51461Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3775">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default TT;
