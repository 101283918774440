import styled from 'styled-components';
import { messages } from '@replai-platform/sdk';
import Checkbox from '../../../Checkbox/Checkbox';
import { TypographyClean } from '../../../CombinationDetail/Tabs/TagTrends/styles';
import Colors, { type ColorTypes } from '../../../Colors';
import Tooltip from '../../../Tooltip/Tooltip';
import * as Icons from '../../../Icons';
import { SegmentPositionDifference, TagDifference } from '../../timeline.types';
import { capitalizeFirstLetter } from '../../../../utils';

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TagInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
`;

const TagName = styled.span`
  color: ${Colors.Gray[900]};
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 50%;
`;

const TagCategory = styled(TagName)`
  color: ${Colors.Gray[500]};
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

interface HeadingProps {
  tagValue: string;
  tagType: string;
  onTagClick?: ({
    value,
    type,
    checked,
  }: {
    value: string;
    type: string;
    checked: boolean;
  }) => void | Promise<void>;
}

const Heading: React.FC<HeadingProps> = ({ tagValue, tagType, onTagClick }) => (
  <HeadingContainer>
    <TagInfoContainer>
      {onTagClick ? (
        <Tooltip content="Deselect tag">
          <div>
            <Checkbox
              checked
              onClick={(checked) =>
                onTagClick({ value: tagValue, type: tagType, checked })
              }
              variant="rounded"
            />
          </div>
        </Tooltip>
      ) : null}
      <TagName>{tagType}</TagName>
      <TagCategory>{tagValue}</TagCategory>
    </TagInfoContainer>
  </HeadingContainer>
);

type HeadingDifferenceProps = {
  difference?: TagDifference | null;
  differenceDetails?: SegmentPositionDifference | number;
  tagType?: string;
  tagValue?: string | null;
};

type HeadingDifferenceIconProps = {
  difference?: TagDifference | null;
  differenceDetails?: SegmentPositionDifference | number;
};

const getHeadingDifferenceIcon = (
  tagDifference: HeadingDifferenceIconProps
) => {
  switch (tagDifference.difference) {
    case TagDifference.ADDED:
      return Icons.getBaseIcon('Plus');
    case TagDifference.POSITION:
      if (
        tagDifference.differenceDetails === SegmentPositionDifference.FORWARD
      ) {
        return Icons.getBaseIcon('ArrowRight');
      }
      return Icons.getBaseIcon('ArrowLeft');
    case TagDifference.LENGTH:
      if (
        tagDifference.differenceDetails &&
        tagDifference.differenceDetails > 0
      ) {
        return Icons.getBaseIcon('ChevronsRight');
      }
      return Icons.getBaseIcon('ChevronsLeft');
    case TagDifference.VALUE_CHANGED_NEW:
      return Icons.getBaseIcon('Shuffle');
    case TagDifference.NOT_PRESENT:
      return Icons.getBaseIcon('Slash');
    default:
      return null;
  }
};

const formatDifferenceValue = ({
  difference,
  differenceDetails,
}: {
  difference: TagDifference;
  differenceDetails?: SegmentPositionDifference | number;
}) => {
  if (difference === TagDifference.VALUE_CHANGED_NEW) {
    return `${capitalizeFirstLetter('Replaced by')}`;
  }
  if (difference === TagDifference.POSITION) {
    return `${capitalizeFirstLetter(
      differenceDetails as SegmentPositionDifference
    )}`;
  }
  if (difference === TagDifference.LENGTH) {
    return `${
      (differenceDetails as number) > 0 ? '+' : ''
    }${new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    }).format(differenceDetails as number)} seconds`;
  }
  return differenceDetails;
};

const headingDifferenceContent = ({
  difference,
  differenceDetails,
  tagType,
  tagValue,
}: HeadingDifferenceProps) => {
  switch (difference) {
    case TagDifference.VALUE_CHANGED_NEW:
      return (
        <>
          <TagName>{capitalizeFirstLetter('Replaced by')}:</TagName>
          <Tooltip content={tagValue}>
            <TagCategory>{tagValue}</TagCategory>
          </Tooltip>
        </>
      );
    case TagDifference.ADDED:
      return (
        <>
          <TagName>{capitalizeFirstLetter('Present')}:</TagName>
          <Tooltip content={tagValue}>
            <TagCategory>{tagValue}</TagCategory>
          </Tooltip>
        </>
      );
    case TagDifference.NOT_PRESENT:
      return (
        <>
          <TagName>{capitalizeFirstLetter('Absent')}:</TagName>
          <Tooltip content={tagValue}>
            <TagCategory>{tagValue}</TagCategory>
          </Tooltip>
        </>
      );
    default:
      return (
        <>
          <TagName>{capitalizeFirstLetter(difference ?? '')}:</TagName>
          {difference && differenceDetails ? (
            <TagCategory>
              {formatDifferenceValue({ difference, differenceDetails })}
            </TagCategory>
          ) : null}
        </>
      );
  }
};

const HeadingDifference: React.VFC<HeadingDifferenceProps> = ({
  difference,
  differenceDetails,
  tagType,
  tagValue,
}) => {
  const Icon = getHeadingDifferenceIcon({ difference, differenceDetails });
  return (
    <HeadingContainer>
      <TagInfoContainer>
        {Icon ? <Icon dimension={16} color={Colors.Gray[400]} /> : null}
        {headingDifferenceContent({
          difference,
          differenceDetails,
          tagType,
          tagValue,
        })}
      </TagInfoContainer>
    </HeadingContainer>
  );
};

const Track = styled.div`
  position: relative;
  height: 1.25rem;
  background: ${Colors.Gray[100]};
  border-radius: 0.25rem;
`;

const Container = styled.div<{
  $showDividers: boolean;
  rowsSpacing?: string;
}>`
  min-height: 68px;
  display: flex;
  flex-direction: column;
  gap: ${({ $showDividers }) => ($showDividers ? '1.125rem' : '0.75rem')};
  margin: 0 -1rem;
  padding: ${({ $showDividers }) => ($showDividers ? '1.625rem' : '0')} 1rem
    1rem 1rem;
  padding-bottom: ${({ rowsSpacing }) => rowsSpacing ?? '1rem'};
  background-color: transparent;
  &:nth-last-child(n + 3) {
    ${({ $showDividers }) =>
      $showDividers ? `border-bottom: 1px solid ${Colors.Gray[200]};` : ''}
  }
`;

interface SegmentContainerProps {
  start: number;
  end: number;
  color: ColorTypes;
  withStripes?: boolean;
  onClick?: (point: number) => void;
  isFakeSegment?: boolean;
}

const getSegmentBg = ({
  color,
  withStripes,
  isFakeSegment,
}: {
  color: ColorTypes;
  withStripes?: boolean;
  isFakeSegment?: boolean;
}): string => {
  if (isFakeSegment) {
    return `repeating-linear-gradient(
      -60deg,
      ${Colors.Gray[50]},
      ${Colors.Gray[50]} 8px,
      ${Colors.Gray[100]} 8px,
      ${Colors.Gray[100]} 16px
    )`;
  }
  if (withStripes) {
    return `repeating-linear-gradient(
      -60deg,
      ${Colors[color][300]},
      ${Colors[color][300]} 0.5rem,
      ${Colors[color][400]} 0.5rem,
      ${Colors[color][400]} 1rem
    )`;
  }
  return Colors[color][400];
};

const SegmentContainer = styled.div<SegmentContainerProps>`
  position: absolute;
  left: ${({ start }) => 100 * start}%;
  display: inline-block;
  width: ${({ start, end }) => 100 * Math.min(end - start, 1 - start)}%;
  height: 100%;
  background: ${({ color, withStripes, isFakeSegment }) =>
    getSegmentBg({ color, withStripes, isFakeSegment })};
  border-radius: 0.25rem;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  outline: 4px solid transparent;
  transition: 0.2s outline;

  &:hover {
    outline: ${({ color }) => `4px solid ${Colors[color][100]}`};
  }
`;

interface TimelineSegmentProps {
  color?: ColorTypes;
  start: number;
  end: number;
  startSeconds: number;
  seekToPoint?: (point: number) => void;
  withStripes?: boolean;
  isFakeSegment?: boolean;
}

const TimelineSegment: React.VFC<TimelineSegmentProps> = ({
  color,
  start,
  end,
  startSeconds,
  seekToPoint,
  withStripes,
  isFakeSegment,
}) => (
  <SegmentContainer
    aria-label="Timeline Segment"
    color={color ?? 'Primary'}
    withStripes={withStripes}
    start={start}
    end={end}
    onClick={seekToPoint ? () => seekToPoint(startSeconds) : undefined}
    isFakeSegment={isFakeSegment}
  />
);

interface TimelineTrackProps {
  color?: ColorTypes;
  segments: { startSeconds: number; endSeconds: number }[];
  duration: number;
  seekToPoint?: (point: number) => void;
  showEmpty?: boolean;
  withStripes?: boolean;
  isFakeSegment?: boolean;
}

export const TimelineTrack: React.VFC<TimelineTrackProps> = ({
  color,
  segments,
  duration,
  seekToPoint,
  showEmpty = false,
  withStripes = false,
  isFakeSegment = false,
}) => (
  <div>
    {showEmpty || segments.length > 0 ? (
      <Tooltip
        content={
          isFakeSegment ? 'This tag is not present in this video' : undefined
        }
      >
        <Track>
          {segments.map((segment, i) => (
            <TimelineSegment
              data-test="timeline-segment"
              key={i}
              color={color}
              start={duration !== 0 ? segment.startSeconds / duration : 0}
              end={duration !== 0 ? segment.endSeconds / duration : 0}
              startSeconds={segment.startSeconds}
              seekToPoint={seekToPoint}
              withStripes={withStripes}
              isFakeSegment={isFakeSegment}
            />
          ))}
        </Track>
      </Tooltip>
    ) : null}
  </div>
);

type TimelineRowProps = {
  color: ColorTypes;
  segments: { startSeconds: number; endSeconds: number }[];
  type: string;
  value: string | null;
  description?: string;
  duration: number;
  seekToPoint?: (point: number) => void;
  recommendations?: JSX.Element[] | JSX.Element;
  showDividers?: boolean;
  showTypeAndValue?: boolean;
  showRecommendationsOnTop?: boolean;
  rowsSpacing?: string;
  onTagClick?: ({
    value,
    type,
    checked,
  }: {
    value: string;
    type: string;
    checked: boolean;
  }) => void | Promise<void>;
  showEmptyTracks?: boolean;
  showDifference?: boolean;
  showFake?: boolean;
  difference?: TagDifference | null;
  differenceDetails?: number | SegmentPositionDifference;
};

const TimelineRow: React.VFC<TimelineRowProps> = ({
  color,
  segments,
  type,
  value,
  description,
  duration,
  seekToPoint,
  recommendations,
  showDividers = true,
  showTypeAndValue = true,
  showRecommendationsOnTop = false,
  onTagClick,
  rowsSpacing,
  showEmptyTracks = false,
  showDifference = false,
  showFake = false,
  difference,
  differenceDetails,
}) => (
  <Container
    $showDividers={showDividers}
    rowsSpacing={rowsSpacing}
    data-test="timeline-row-container"
  >
    {showTypeAndValue &&
    (!showDifference || !difference) &&
    difference !== TagDifference.NO_SHOW ? (
      <Heading
        data-test="tag-name"
        tagValue={value === null ? messages.NOT_AVAILABLE : value}
        tagType={type}
        onTagClick={onTagClick}
      />
    ) : null}
    {showDifference && !!difference && difference !== TagDifference.NO_SHOW ? (
      <HeadingDifference
        data-test="tag-difference"
        difference={difference}
        differenceDetails={differenceDetails}
        tagValue={value === null ? messages.NOT_AVAILABLE : value}
        tagType={type}
      />
    ) : null}
    {!!recommendations && showRecommendationsOnTop ? recommendations : null}
    <div data-test="timeline-track-container">
      {difference === TagDifference.NO_SHOW ? (
        <></>
      ) : (
        <TimelineTrack
          data-test="timeline-track"
          color={color}
          segments={segments}
          duration={duration}
          seekToPoint={seekToPoint}
          showEmpty={showEmptyTracks}
          withStripes={showDifference}
          isFakeSegment={
            (showDifference || showFake) &&
            difference === TagDifference.NOT_PRESENT
          }
        />
      )}
    </div>
    {!!recommendations && showRecommendationsOnTop ? null : recommendations}
    {description ? (
      <TypographyClean color={Colors.Gray[500]} type="text-sm">
        {description}
      </TypographyClean>
    ) : null}
  </Container>
);
export default TimelineRow;
