import { useState, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import type { Timeframe } from '@replai-platform/sdk';
import {
  calculateClusterViewPreviewVideoStartTime,
  calculateTimestamps,
  formatDuration,
} from '../../utils';
import Typography from '../Typography/Typography';
import SkeletonLoading from '../Skeleton/Skeleton';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import Colors from '../Colors';
import { TimelineTrack } from '../Timeline/TimelineBody/TimelineRow/TimelineRow';
import * as Styled from './styles';

const NUM_TIMESTAMPS_TO_SHOW = 4;

type Video = {
  url: URL | string;
  timeframes?: Timeframe[];
};

export type VideoTimelineProps = {
  video?: Video;
  onTimelineClick?: () => void;
  onPlayClick?: () => void;
  onPauseClick?: () => void;
};

const VideoTimeline = ({
  video,
  onPlayClick,
  onPauseClick,
  onTimelineClick,
}: VideoTimelineProps) => {
  const [timestamps, setTimestamps] = useState<number[]>([]);
  const duration = useRef<number | null>(null);
  const playerRef = useRef<ReactPlayer | null>(null);

  const seekToPoint = (point: number) => {
    playerRef.current?.seekTo(point, 'seconds');
    onTimelineClick?.();
  };

  const renderVideo = (video: Video) => {
    const onLoadedMetadata = (data: React.SyntheticEvent<HTMLVideoElement>) => {
      const targetElm = data?.target as HTMLVideoElement;

      if (targetElm?.duration) {
        duration.current = targetElm?.duration;

        setTimestamps(
          calculateTimestamps(targetElm?.duration, NUM_TIMESTAMPS_TO_SHOW)
        );
      }
    };

    return (
      <Styled.VideoContainer>
        <VideoPlayer
          onPlay={onPlayClick}
          onPause={onPauseClick}
          playerRef={playerRef}
          url={video.url.toString()}
          startTime={calculateClusterViewPreviewVideoStartTime(
            video.timeframes
          )}
          onLoadedMetadata={onLoadedMetadata}
          preload="metadata"
          playing
        />
      </Styled.VideoContainer>
    );
  };

  const renderTimestamp = (timestamp: number) => {
    const { minutes, seconds } = formatDuration(timestamp);

    return (
      <Typography
        key={timestamp}
        noMargin
        type="text-xs"
        color={Colors.Gray[500]}
        fontWeight="medium"
      >
        {`${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`}
      </Typography>
    );
  };

  const renderTimeline = () =>
    duration.current ? (
      <TimelineTrack
        segments={video?.timeframes ?? []}
        duration={duration.current}
        color="Primary"
        seekToPoint={seekToPoint}
      />
    ) : (
      <SkeletonLoading height="100%" />
    );

  return (
    <Styled.Container>
      <Styled.VideosContainer>
        {video ? renderVideo(video) : <SkeletonLoading height="100%" />}
        <Styled.TimestampsContainer>
          {timestamps.length ? (
            timestamps.map((ts) => renderTimestamp(ts))
          ) : (
            <SkeletonLoading height="100%" />
          )}
        </Styled.TimestampsContainer>
      </Styled.VideosContainer>
      {video && video.timeframes && video.timeframes?.length > 0 ? (
        <Styled.TimelineContainer>{renderTimeline()}</Styled.TimelineContainer>
      ) : null}
    </Styled.Container>
  );
};

export default VideoTimeline;
