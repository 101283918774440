import styled from 'styled-components';
import Colors from '../Colors';
import Tooltip from '../Tooltip/Tooltip';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;

  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }

  &.underline .tabsButtons {
    width: 100%;
    border-bottom: 1px solid ${Colors.Gray[200]};

    > button {
      box-sizing: border-box;
      margin-bottom: -1px;
      padding: 0 20px 16px 20px;
      color: ${Colors.Gray[500]};
      font-size: 14px;
      border-bottom: 2px solid transparent;
    }

    .selected {
      color: ${Colors.Primary[700]};
      border-bottom: 2px solid ${Colors.Primary[700]};
    }
  }

  &.gray-button {
    padding: 4px;
    background: ${Colors.Gray[50]};
    border: 1px solid ${Colors.Gray[100]};
    border-radius: 8px;

    .tabsButtons {
      > button {
        display: flex;
        flex-direction: row;
        padding: 8px 12px 8px 12px;
        color: ${Colors.Gray[500]};
        font-size: 16px;
      }
      .selected {
        color: ${Colors.Gray[700]};
        background-color: ${Colors.Common.White};
        border-radius: 6px;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
          0px 1px 2px rgba(16, 24, 40, 0.06);

        :is(div, svg, path) {
          opacity: 1;
        }
      }
      :not(.selected) {
        opacity: 0.6;
      }
    }
  }

  &.primary-light .tabsButtons {
    > button {
      padding: 8px 12px;
      color: ${Colors.Gray[500]};
      font-size: 14px;
    }
    .selected {
      color: ${Colors.Primary[700]};
      background-color: ${Colors.Primary[50]};
      border-radius: 6px;
    }
  }

  &.short .tabsButtons > button {
    width: max-content;
  }

  &.long .tabsButtons > button {
    flex-shrink: 0;
    justify-content: center;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;

  button {
    font-weight: 500;
    white-space: nowrap;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: 0.2s;
  }

  &.onlyButtons {
    width: 100%;
  }
`;

export const Button = styled.button<{
  $disabled?: boolean;
  $width?: string;
  $leadingBadge: boolean;
}>`
  ${(props) =>
    props.$disabled
      ? `
  color: ${Colors.Gray[300]} !important;
  cursor: not-allowed !important;
  `
      : ''}
  display: ${(props) => (props.$leadingBadge ? 'flex' : 'block')};
  width: ${(props) => props.$width};
`;

export const TabsTooltip = styled(Tooltip)`
  max-height: 600px;
  overflow-y: auto;
  padding: 0;
  .ant-tooltip-inner {
    box-shadow: unset;
  }
`;
