import { useRef, useState } from 'react';
import Colors from '../Colors';
import Tooltip from '../Tooltip/Tooltip';
import Typography from '../Typography/Typography';
import '../common.module.css';

import * as Icons from '../Icons';
import * as Styled from './styles';
import generateAcronym from './generateAcronym';

import { useOverflow } from '../../utils';
import { IconType } from '../Icons';

interface AvatarProps {
  alt?: string;
  clickable?: boolean;
  companyIconUrl?: string;
  imgUrl?: string | null;
  isFirst?: boolean;
  isGroup?: boolean;
  label?: string;
  profileIcon?: boolean;
  showIcon?: boolean;
  icon?: Icons.BaseIconTypes | Icons.TagIconTypes;
  iconType?: IconType;
  iconProps?: Icons.TagIconProps;
  secondaryLabel?: string;
  shouldGenerateAcronym?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  status?: 'active' | 'inactive';
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  outline?: 'hover' | 'fixed';
  multiLineLabel?: boolean;
  squared?: boolean;
  dark?: boolean;
  overlayIcon?: Icons.BaseIconTypes;
  noBackground?: boolean;
}

const Avatar = ({
  alt,
  clickable,
  companyIconUrl,
  imgUrl,
  label,
  profileIcon,
  icon = 'User',
  iconType = IconType.BASE,
  iconProps,
  secondaryLabel,
  shouldGenerateAcronym = true,
  size = 'md',
  status,
  onClick,
  outline,
  squared = false,
  dark = false,
  noBackground = false,

  /* Props for Avatar Group Functionality  */
  isFirst,
  isGroup,
  multiLineLabel = true,
  overlayIcon,
}: AvatarProps) => {
  const [isHovering, setIsHovering] = useState(false);

  const labelRef = useRef<HTMLSpanElement>(null);
  const labelOverflows = useOverflow(labelRef);

  const secondLabelRef = useRef<HTMLSpanElement>(null);
  const secondLabelOverflows = useOverflow(secondLabelRef);

  let additionalInfoSize = 16;
  let iconSize = 24;
  let fontSize = 24;
  let labelSize = 18;
  let shiftLeft = 24;
  let borderWidth = 4;
  switch (size) {
    case 'xs':
      additionalInfoSize = 6;
      fontSize = 12;
      iconSize = 12;
      labelSize = 14;
      shiftLeft = 4;
      borderWidth = 1.5;
      break;
    case 'sm':
      additionalInfoSize = 8;
      fontSize = 14;
      iconSize = 16;
      labelSize = 14;
      shiftLeft = 8;
      borderWidth = 1.5;
      break;
    case 'md':
      additionalInfoSize = 10;
      fontSize = 16;
      iconSize = 22;
      labelSize = 16;
      shiftLeft = 12;
      borderWidth = 2;
      break;
    case 'lg':
      additionalInfoSize = 12;
      fontSize = 18;
      iconSize = 26;
      labelSize = 16;
      shiftLeft = 16;
      borderWidth = 2;
      break;
    case 'xl':
      additionalInfoSize = 14;
      fontSize = 20;
      iconSize = 30;
      shiftLeft = 20;
      borderWidth = 3;
      break;
    default:
      throw new Error('Invalid size');
  }

  const additionalInfoStyle = {
    height: additionalInfoSize,
    width: additionalInfoSize,
  };

  const getOutlineClass = () => {
    switch (outline) {
      case 'hover':
        return 'hoverOutline';
      case 'fixed':
        return 'fixedOutline';
      default:
        return '';
    }
  };

  const getOverlayIcon = () => {
    const OverlayIcon = Icons.getBaseIcon(overlayIcon ?? 'Play');
    return (
      <Styled.IconOverlay>
        <OverlayIcon
          dimension={iconSize * 0.8}
          color={Colors.Gray[900]}
          fill={Colors.Gray[900]}
        />
      </Styled.IconOverlay>
    );
  };

  const getAvatarImg = () => {
    if (imgUrl) {
      return (
        <Styled.ImageHolder
          className="imgHolder"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <Styled.Image src={imgUrl} alt={alt} />
          {isHovering && overlayIcon ? getOverlayIcon() : null}
        </Styled.ImageHolder>
      );
    }

    if (alt) {
      return (
        <Styled.Acronym>
          {shouldGenerateAcronym ? generateAcronym(alt) : alt}
        </Styled.Acronym>
      );
    }

    const Icon = Icons.getIcon({ name: icon, iconType });

    return (
      <Icon
        dimension={iconSize}
        {...(iconType !== IconType.TAG && {
          color: dark ? Colors.Gray[300] : Colors.Primary[600],
        })}
        {...iconProps}
      />
    );
  };

  return (
    <Styled.Root
      className={`${label || secondaryLabel ? `labelRoot root-${size}` : ''}`}
      $marginLeft={isGroup && !isFirst ? -shiftLeft : 0}
    >
      <Styled.IconRoot
        data-test="thumbnail"
        className={`${size}
        ${getOutlineClass()}`}
        onClick={clickable ? onClick : undefined}
        $clickable={clickable}
        $profileIcon={profileIcon}
        $isGroup={isGroup}
        $squared={squared}
        $backgroundColor={
          noBackground ? '' : dark ? Colors.Gray[700] : Colors.Primary[50]
        }
        $color={Colors.Primary[600]}
        $fontSize={`${fontSize}px`}
        $borderWidth={isGroup ? `${borderWidth}px` : ''}
      >
        {getAvatarImg()}
        {companyIconUrl ? (
          <Styled.CompanyIcon
            $width={additionalInfoStyle.width}
            $height={additionalInfoStyle.height}
            $backgroundImage={`url(${companyIconUrl})`}
          />
        ) : (
          status && (
            <Styled.ActiveIndicator
              data-test="active-indicator"
              className={status === 'active' ? 'active' : 'inactive'}
              $width={additionalInfoStyle.width}
              $height={additionalInfoStyle.height}
            />
          )
        )}
      </Styled.IconRoot>
      <Styled.InfoHolder>
        <Tooltip
          content={
            labelOverflows ? (
              <Typography type="text-sm" color={Colors.Gray[500]} noMargin>
                {label}
              </Typography>
            ) : null
          }
          placement="top"
        >
          <span>
            <Styled.LabelSpan
              $multiLine={multiLineLabel}
              $color={Colors.Gray[700]}
              $labelSize={labelSize}
              $fontWeight={500}
              ref={labelRef}
              className="label"
            >
              {label}
            </Styled.LabelSpan>
          </span>
        </Tooltip>
        {secondaryLabel && (
          <Tooltip
            content={
              secondLabelOverflows ? (
                <Typography type="text-sm" color={Colors.Gray[500]} noMargin>
                  {secondaryLabel}
                </Typography>
              ) : null
            }
            placement="top"
          >
            <span>
              <Styled.LabelSpan
                $multiLine={multiLineLabel}
                $color={Colors.Gray[500]}
                $labelSize={labelSize - 2}
                className="secondaryLabel"
                ref={secondLabelRef}
              >
                {secondaryLabel}
              </Styled.LabelSpan>
            </span>
          </Tooltip>
        )}
      </Styled.InfoHolder>
    </Styled.Root>
  );
};

export default Avatar;
export type { AvatarProps };
