import { useFloating } from '@floating-ui/react-dom';
import { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import Colors from '../Colors';
import * as Icons from '../Icons';
import * as Styled from './styles';

const ChevronDownIconJSX = Icons.getBaseIcon('ChevronDown');

const XIconJSX = Icons.getBaseIcon('X');
const xIcon = <XIconJSX color={Colors.Gray[900]} dimension={20} />;

interface CustomizableDropDownChipProps {
  disabled?: boolean;
  dropDownAlignment?: 'right' | 'left';
  dropdownContent?: JSX.Element;
  iconType?: 'chevron' | 'cross';
  label?: string;
  loading?: boolean;
  onClearClick?: () => void;
  onDropdownOpenClose?: (open: boolean) => void;
}

const CustomizableDropDownChip = ({
  disabled = false,
  dropDownAlignment = 'right',
  dropdownContent,
  iconType,
  label,
  loading = false,
  onClearClick,
  onDropdownOpenClose,
}: CustomizableDropDownChipProps) => {
  const [isDropDownCollapsed, setIsDropDownCollapsed] = useState(true);
  const isDisabled = disabled || loading;
  const dropDownChipRef = useRef(null);
  const chevronDownIcon = (
    <ChevronDownIconJSX
      color={isDisabled ? Colors.Gray[300] : Colors.Gray[900]}
      dimension={20}
    />
  );

  const { x, y, reference, floating, strategy } = useFloating({
    placement: `bottom-${dropDownAlignment === 'right' ? 'end' : 'start'}`,
  });

  // used to colapse the dropdown if the user clicks outside of it
  useClickAway(dropDownChipRef, () => {
    setIsDropDownCollapsed(true);
  });

  const getIcon = () => {
    if (loading) {
      return (
        <span data-test="dropdown-chip-loading-icon">
          <Icons.MiscIcons.LoadingCircle
            color={Colors.Gray[300]}
            dimension={20}
          />
        </span>
      );
    } else if (iconType) {
      switch (iconType) {
        case 'chevron':
          return chevronDownIcon;
        case 'cross':
          return (
            <span
              onClick={(e) => {
                e.stopPropagation();
                onClearClick?.();
              }}
              role="button"
              tabIndex={0}
              aria-hidden="true"
            >
              {xIcon}
            </span>
          );
        default: {
          const exhaustiveCheck: never = iconType;
          return exhaustiveCheck;
        }
      }
    }
    return null;
  };

  return (
    <span data-test="dropdown-chip-filter" ref={dropDownChipRef}>
      <span ref={reference}>
        <Styled.Button
          variant="outlined"
          color="Gray"
          size="md"
          disabled={isDisabled}
          onClick={() => {
            setIsDropDownCollapsed((c) => {
              const newState = !c;
              onDropdownOpenClose?.(newState);
              return newState;
            });
          }}
        >
          <Styled.ButtonContent>
            <Styled.ButtonText>{label}</Styled.ButtonText>
            {getIcon()}
          </Styled.ButtonContent>
        </Styled.Button>
      </span>
      {!isDropDownCollapsed && (
        <Styled.DropDownContainer
          data-test="filter-dropdown-container"
          ref={floating}
          $position={strategy}
          $left={x}
          $top={y}
        >
          <Styled.DropDownContentRoot>
            {dropdownContent}
          </Styled.DropDownContentRoot>
        </Styled.DropDownContainer>
      )}
    </span>
  );
};

export default CustomizableDropDownChip;
export type { CustomizableDropDownChipProps };
