import { CountryIconProps } from './types';

const PN = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C0 12.0029 12 0.0013125 12 0C18.6274 0 24 5.37262 24 12Z"
      fill="#0052B4"
    />
    <path
      d="M11.9678 12.0005H11.9999C11.9999 11.9897 11.9999 11.9792 11.9999 11.9684C11.9892 11.9791 11.9785 11.9898 11.9678 12.0005Z"
      fill="#F0F0F0"
    />
    <path
      d="M12 6.26098C12 4.1495 12 2.76598 12 0.00012207H11.998C5.37145 0.00124707 0 5.37336 0 12.0001H6.26086V8.47461L9.78637 12.0001H11.9679C11.9786 11.9894 11.9893 11.9787 12 11.968C12 11.1596 12 10.4382 12 9.78659L8.47443 6.26098H12Z"
      fill="#F0F0F0"
    />
    <path
      d="M6.07124 1.56506C4.19441 2.63377 2.63409 4.19405 1.56543 6.07088V11.9999H4.69588V4.69561V4.69552H12.0002C12.0002 3.70819 12.0002 2.7676 12.0002 1.56506H6.07124Z"
      fill="#D80027"
    />
    <path
      d="M12.0009 10.5248L7.73734 6.26135H6.26172V6.26145L12.0008 12.0005H12.0009C12.0009 12.0005 12.0009 10.9829 12.0009 10.5248Z"
      fill="#D80027"
    />
    <path
      d="M18.783 7.30388L17.2178 7.82564L15.6526 7.30388L15.1309 5.99953L15.6526 4.69519H18.783L18.2613 5.99953L18.783 7.30388Z"
      fill="#ACABB1"
    />
    <path
      d="M13.5654 7.30396V13.0431C13.5654 15.8387 17.2176 16.6953 17.2176 16.6953C17.2176 16.6953 20.8698 15.8387 20.8698 13.0431V7.30396H13.5654Z"
      fill="#338AF3"
    />
    <path
      d="M13.8945 14.4023C14.7923 16.1258 17.2175 16.6948 17.2175 16.6948C17.2175 16.6948 19.6427 16.1258 20.5405 14.4023L17.2175 8.86877L13.8945 14.4023Z"
      fill="#6DA544"
    />
    <path
      d="M20.8698 12.9851L17.2176 7.30396L13.5654 12.9851V13.043C13.5654 13.5605 13.6908 14.0113 13.8946 14.4027L17.2176 9.23356L20.5406 14.4027C20.7444 14.0112 20.8698 13.5604 20.8698 13.043V12.9851Z"
      fill="#FFDA44"
    />
  </svg>
);
export default PN;
