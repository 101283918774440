/* eslint-disable react/jsx-props-no-spreading */
import { FlatSocialIconProps } from './types';

const Adjust = ({ dimension, ...props }: FlatSocialIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1080 1080"
    width={dimension || 24}
    height={dimension || 24}
  >
    <g transform="matrix(5.67 0 0 5.67 1714.84 540)">
      <g vector-effect="non-scaling-stroke">
        <g transform="matrix(1 0 0 1 -207.29 0)" fill="rgb(0,188,212)">
          <path
            vector-effect="non-scaling-stroke"
            transform=" translate(39.3, -275.4)"
            d="M -4.5 296.7 L -11 292 C -24.1 282.5 -36 274.5 -46.6 268.2 L -48.9 266.59999999999997 L -33.5 231.89999999999998 L -4.5 296.7 z M 4.7 319.7 L 14.8 309.59999999999997 L -25.7 219.09999999999997 L -40.9 219.09999999999997 L -59.099999999999994 260.99999999999994 L -61.099999999999994 259.99999999999994 C -72 254.59999999999994 -80.39999999999999 251.99999999999994 -86.69999999999999 251.99999999999994 C -94.89999999999999 251.99999999999994 -98.6 256.49999999999994 -100.1 259.09999999999997 C -104.6 267.09999999999997 -102 278.29999999999995 -92.3 293.29999999999995 C -88.7 298.79999999999995 -84.89999999999999 303.9 -82.1 307.4 L -67.5 307.4 L -61.5 293.79999999999995 L -67.8 279.59999999999997 L -75.2 295.79999999999995 L -77.7 292.59999999999997 C -79.3 290.4 -80.9 288.2 -82.2 286.09999999999997 C -90.2 273.9 -91.4 267.59999999999997 -89.60000000000001 265.29999999999995 C -87.9 263.19999999999993 -77.9 265.99999999999994 -66.9 271.4 C -60.900000000000006 274.09999999999997 -54.7 277.5 -48.60000000000001 281.7 C -44.20000000000001 284.4 -39.60000000000001 287.4 -34.80000000000001 290.7 C -17.1 302.6 -1.2 315.1 4.7 319.7 M 41.4 275.4 C 41.4 319.9 5.199999999999996 356.2 -39.300000000000004 356.2 C -83.80000000000001 356.2 -120 320 -120 275.4 C -120 230.89999999999998 -83.8 194.59999999999997 -39.3 194.59999999999997 C 5.2 194.6 41.4 230.9 41.4 275.4 M 53.3 275.4 C 53.3 224.29999999999998 11.699999999999996 182.79999999999998 -39.3 182.79999999999998 C -90.4 182.79999999999998 -131.89999999999998 224.39999999999998 -131.89999999999998 275.4 C -131.89999999999998 326.4 -90.4 368 -39.3 368 C 11.7 368 53.3 326.5 53.3 275.4"
            stroke-linecap="round"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default Adjust;
