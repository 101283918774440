import { useMutation, useQueryClient } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';
import { GET_ALL_QUERY_KEY } from './constants';

export type MarkProductUpdatesAsReadRequestParamsAndBody = {
  requestParams: SDK.MarkProductUpdatesAsReadRequest;
  body: SDK.MarkProductUpdatesAsReadRequestBody;
};

const markProductUpdatesAsRead = async (
  requestParams: SDK.MarkProductUpdatesAsReadRequest,
  body: SDK.MarkProductUpdatesAsReadRequestBody
): Promise<SDK.GetProductUpdatesResponse> => {
  const url = `${SDK.BASE_URL()}/productUpdates/markAsRead?${SDK.convertRequestToQueryParams(requestParams)}`;
  const res = await provider.getJson<object, SDK.GetProductUpdatesResponse>(url, {
    method: 'PUT',
    body,
  });
  return res;
};

export default function useMarkProductUpdatesAsRead() {
  const queryClient = useQueryClient();
  return useMutation<
    SDK.GetProductUpdatesResponse,
    unknown,
    MarkProductUpdatesAsReadRequestParamsAndBody,
    { lastProductUpdatesQueryResult?: SDK.GetProductUpdatesResponse }
  >(
    [`product-updates-mark-as-read`],
    (requestParamsAndBody: MarkProductUpdatesAsReadRequestParamsAndBody) =>
      markProductUpdatesAsRead(requestParamsAndBody.requestParams, requestParamsAndBody.body),
    {
      // For more info on the optimistic update pattern see https://tanstack.com/query/v4/docs/react/guides/optimistic-updates.
      onError: (_error, _variables, context) => {
        queryClient.setQueryData(GET_ALL_QUERY_KEY, context?.lastProductUpdatesQueryResult);
      },
      onSettled: () => queryClient.invalidateQueries({ queryKey: GET_ALL_QUERY_KEY }),
      onMutate: async (variables) => {
        await queryClient.cancelQueries({ queryKey: GET_ALL_QUERY_KEY });
        const lastQueryData = queryClient.getQueryData<SDK.GetProductUpdatesResponse>(GET_ALL_QUERY_KEY);
        if (lastQueryData) {
          // Perform an update so that changes reflect immediately.
          queryClient.setQueryData(GET_ALL_QUERY_KEY, {
            ...lastQueryData,
            productUpdates: lastQueryData.productUpdates.map((e) => ({
              ...e,
              read: e.read || variables.body.productUpdateIds.includes(e.id),
            })),
          });
        }
        return { lastProductUpdatesQueryResult: lastQueryData };
      },
    }
  );
}
