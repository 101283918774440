import { IntegrationIconProps } from './types';

const Line = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 14.4979C30 8.15792 23.7199 3 15.9999 3C8.28094 3 2 8.15792 2 14.4979C2 20.1817 6.98063 24.9417 13.7084 25.8418C14.1644 25.9412 14.7849 26.146 14.9419 26.5404C15.0831 26.8986 15.0342 27.4598 14.987 27.8216C14.987 27.8216 14.8227 28.8214 14.7873 29.0343C14.7264 29.3926 14.5061 30.4353 15.9999 29.7981C17.4942 29.1609 24.0626 24.9935 26.9998 21.572C29.0287 19.3204 30 17.0353 30 14.4979Z"
      fill="#2CCF54"
    />
    <path
      d="M13.1553 11.4244H12.1733C12.0228 11.4244 11.9004 11.5478 11.9004 11.6995V17.866C11.9004 18.0179 12.0228 18.1411 12.1733 18.1411H13.1553C13.3059 18.1411 13.428 18.0179 13.428 17.866V11.6995C13.428 11.5478 13.3059 11.4244 13.1553 11.4244Z"
      fill="white"
    />
    <path
      d="M19.9147 11.4244H18.9327C18.7821 11.4244 18.66 11.5478 18.66 11.6995V15.3631L15.8645 11.5467C15.8128 11.4683 15.729 11.4295 15.6375 11.4244H14.6558C14.5052 11.4244 14.3828 11.5478 14.3828 11.6995V17.866C14.3828 18.0179 14.5052 18.1411 14.6558 18.1411H15.6375C15.7883 18.1411 15.9104 18.0179 15.9104 17.866V14.2035L18.7094 18.0247C18.7597 18.0967 18.845 18.1411 18.9327 18.1411H19.9147C20.0655 18.1411 20.1874 18.0179 20.1874 17.866V11.6995C20.1874 11.5478 20.0655 11.4244 19.9147 11.4244Z"
      fill="white"
    />
    <path
      d="M10.7874 16.597H8.11916V11.6999C8.11916 11.5477 7.99704 11.4242 7.84675 11.4242H6.86447C6.71391 11.4242 6.5918 11.5477 6.5918 11.6999V17.8653C6.5918 18.015 6.71337 18.1412 6.8642 18.1412H10.7874C10.938 18.1412 11.0595 18.0175 11.0595 17.8653V16.8727C11.0595 16.7205 10.938 16.597 10.7874 16.597Z"
      fill="white"
    />
    <path
      d="M25.3382 12.9684C25.4888 12.9684 25.6103 12.8453 25.6103 12.6928V11.7001C25.6103 11.5479 25.4888 11.4242 25.3382 11.4242H21.4153C21.2646 11.4242 21.1426 11.5502 21.1426 11.6999V17.8656C21.1426 18.0148 21.2643 18.1412 21.4147 18.1412H25.3382C25.4888 18.1412 25.6103 18.0175 25.6103 17.8656V16.8727C25.6103 16.7207 25.4888 16.597 25.3382 16.597H22.6702V15.5547H25.3382C25.4888 15.5547 25.6103 15.4313 25.6103 15.2791V14.2864C25.6103 14.1342 25.4888 14.0105 25.3382 14.0105H22.6702V12.9684H25.3382Z"
      fill="white"
    />
  </svg>
);
export default Line;
