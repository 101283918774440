import { CountryIconProps } from './types';

const BQ2 = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3762)">
      <path
        d="M5.2373 21.9127C7.16321 23.2292 9.49182 23.9997 12.0008 23.9997C14.5097 23.9997 16.8384 23.2292 18.7642 21.9127L12.0008 20.8693L5.2373 21.9127Z"
        fill="#D80027"
      />
      <path
        d="M18.7642 2.08709C16.8384 0.770606 14.5097 0.00012207 12.0008 0.00012207C9.49182 0.00012207 7.16312 0.770606 5.2373 2.08709L12.0008 3.13057L18.7642 2.08709Z"
        fill="#D80027"
      />
      <path
        d="M5.2373 21.9127C7.16321 23.2292 9.49182 23.9997 12.0008 23.9997C14.5097 23.9997 16.8384 23.2292 18.7642 21.9127L12.0008 20.8693L5.2373 21.9127Z"
        fill="#D80027"
      />
      <path
        d="M18.7642 2.08709C16.8384 0.770606 14.5097 0.00012207 12.0008 0.00012207C9.49182 0.00012207 7.16312 0.770606 5.2373 2.08709L12.0008 3.13057L18.7642 2.08709Z"
        fill="#D80027"
      />
      <path
        d="M23.9706 11.2165C23.6863 7.51292 21.436 3.91217 18.7646 2.08606H5.2377C2.2782 4.10923 0.27068 7.42274 0.0263672 11.2165L12.0012 11.9991L23.9706 11.2165Z"
        fill="#0052B4"
      />
      <path
        d="M0.0253906 12.7823C0.269703 16.5761 2.27717 19.8895 5.23667 21.9127H18.7636C21.7232 19.8896 23.7306 16.5761 23.9749 12.7823L12.0002 11.9996L0.0253906 12.7823Z"
        fill="#0052B4"
      />
      <path
        d="M23.9694 11.2173H12.7826V1.56519H11.2174V11.2173H0.0252656C0.008625 11.4761 0 11.737 0 12C0 12.2629 0.00857813 12.5238 0.0252187 12.7826H11.2174V22.4348H12.7826V12.7826H23.9748C23.9914 12.5238 24 12.2629 24 12C24 11.7389 23.9895 11.4779 23.9694 11.2173Z"
        fill="#D80027"
      />
      <path
        d="M4.69629 11.9992L12.0006 7.4082L19.305 11.9992L12.0006 16.5903L4.69629 11.9992Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.82617 14.0872H17.2174L15.1305 10.9568L14.087 12.0003L13.0435 11.4786L12.0001 13.0438H9.91309L8.86961 12.0003L7.82617 14.0872Z"
        fill="#6DA544"
      />
      <path
        d="M12.0004 8.8689L12.2595 9.66601H13.0975L12.4195 10.1587L12.6785 10.9559L12.0004 10.4632L11.3223 10.9559L11.5814 10.1587L10.9033 9.66601H11.7414L12.0004 8.8689Z"
        fill="#FFDA44"
      />
      <path
        d="M12.0006 6.09656L2.60938 11.9993L12.0006 17.9021L21.392 11.9993L12.0006 6.09656ZM5.55069 11.9993L12.0006 7.94526L18.4507 11.9993L12.0006 16.0534L5.55069 11.9993Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3762">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default BQ2;
