import { DropDownChip, DropDownChipProps } from '@replai-platform/ui-components';

export interface TaggedWithFilterProps {
  options: DropDownChipProps['dropDownOptions'];
  onChange: DropDownChipProps['onChange'];
  loading: boolean;
}

const TaggedWithFilter: React.VFC<TaggedWithFilterProps> = ({ options, onChange, loading }) => (
  <DropDownChip
    data-test="filter-taggedwith-dropdown"
    placeHolder="Tagged with"
    defaultOption="All Tags"
    dropDownType="multiselect"
    dropDownOptions={options}
    dropDownAlignment="right"
    onChange={onChange}
    prefixLabel="Tags"
    loading={loading}
  />
);

export default TaggedWithFilter;
