import { IconProps } from './types';

const ThreeLayers = ({ color, dimension }: IconProps) => (
  <svg
    width={dimension || '24'}
    height={dimension || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 17L12 22L22 17M2 12L12 17L22 12M12 2L2 7L12 12L22 7L12 2Z"
      stroke={color || '#101828'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ThreeLayers;
