import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type MultiSelectDropDownOption } from '@replai-platform/ui-components';
import { RootState } from '../../../store/rootReducer';
import { FilterActions } from '../../../store/filters';
import { ReportsActions } from '../../../store/reports';
import { Page } from '../../../utils/enums';
import { api } from '../../../api';
import useGetCampaigns from '../../../api/hooks/campaigns/useGetCampaigns';

type AgencyConfig = {
  label: string;
  // Null is the catch-all option.
  campaignNameFilter: string[];
};

type AgencyValues = AgencyConfig & {
  campaigns: { id: string; name: string }[];
};

const AGENCIES_CONFIG: Array<AgencyConfig> = [
  {
    label: 'NianticAWARE',
    campaignNameFilter: ['NianticAWARE', 'NianticAware'],
  },
  {
    label: 'NianticEM',
    campaignNameFilter: ['NianticEM', 'Niantic EM'],
  },
  {
    label: 'NianticRT',
    campaignNameFilter: ['NianticRT'],
  },
  {
    label: 'NianticTPC',
    campaignNameFilter: ['NianticTPC'],
  },
  {
    label: 'TPCi',
    campaignNameFilter: ['TPCi'],
  },
  {
    label: 'TPC',
    campaignNameFilter: ['TPC'],
  },
  {
    label: 'CyberZ',
    campaignNameFilter: ['CyberZ'],
  },
  {
    label: 'Ramp',
    campaignNameFilter: ['Ramp'],
  },
  {
    label: 'SepteniTPC',
    campaignNameFilter: ['SepteniTPC'],
  },
  {
    label: 'Septeni',
    campaignNameFilter: ['Septeni'],
  },
  {
    label: 'Niantic',
    campaignNameFilter: ['Niantic'],
  },
];

const useAgencyFilter = (
  page?: Page
): {
  agencyFilterOptions: MultiSelectDropDownOption[];
  agencyFilterOnChange: (options: MultiSelectDropDownOption[]) => void;
  isLoading: boolean;
} => {
  const dispatch = useDispatch();
  const project = useSelector((state: RootState) => state.project);
  const agencyCampaignsToConsider = useSelector((state: RootState) => state.filters.agencyCampaignsToConsider);
  const filtering = useSelector((state: RootState) => state.filters);

  const { isSuccess, data, isLoading } = useGetCampaigns({
    projectIds: [project.id],
    metricsFilters:
      page === Page.Reports
        ? undefined
        : {
            countriesToConsider: ['ALL'],
            ...(({ dateEndTimestamp, dateStartTimestamp }) => ({ dateEndTimestamp, dateStartTimestamp }))(
              api.filterConverter.getMetricsFilters(filtering)
            ),
          },
  });

  // The default state of the filter is "ALL" selected. The problem is that the "ALL" option is not
  // stored in the redux store, so we dont have an easy way to know whether to select it or not. We
  // use this ref to identify the first onChange, and until the ref value changes, we can assume
  // that we want the "ALL" option to be selected (assuming that there are no networks in the store,
  // in which case that takes precedence).
  const isInitialFilter = useRef<boolean>(true);

  // Create filter options
  const agencyFilterOptions = useMemo<MultiSelectDropDownOption[]>(() => {
    if (isLoading || !data) {
      return [];
    }

    const filterData: Record<string, AgencyValues> = {};

    data.campaigns.forEach((campaign) => {
      const uniformCampaignName = campaign.name;
      const campaignAgency = AGENCIES_CONFIG.find((agency) =>
        agency.campaignNameFilter.some((filter) => uniformCampaignName?.includes(filter))
      );

      if (campaignAgency) {
        if (filterData[campaignAgency.label]) {
          filterData[campaignAgency.label].campaigns.push(campaign);
        } else {
          filterData[campaignAgency.label] = {
            ...campaignAgency,
            campaigns: [campaign],
          };
        }
      }
    });

    return isSuccess
      ? [
          ...Object.keys(filterData).map((key) => {
            const optionInfo = filterData[key];
            const selected = !optionInfo.campaigns.find((campaign) => !agencyCampaignsToConsider.includes(campaign.id));

            return {
              id: optionInfo.campaigns.map((c) => c.id).join(';'),
              label: optionInfo.label,
              isAllOption: false,
              selected,
            };
          }),
        ]
      : [];
  }, [JSON.stringify(data), JSON.stringify(agencyCampaignsToConsider)]);

  const agencyFilterOnChange = useCallback(
    (options: MultiSelectDropDownOption[]) => {
      dispatch(
        FilterActions.changeCampaigns({
          value: options.filter((option) => option.selected).flatMap((option) => option?.id?.split(';') ?? []),
          toExclude: false,
          logEvent: !isInitialFilter.current,
          logComponent: 'AgencyFilter',
        })
      );
      dispatch(
        ReportsActions.changeCampaigns({
          value: options.filter((option) => option.selected).flatMap((option) => option?.id?.split(';') ?? []),
          toExclude: false,
        })
      );
    },
    [dispatch, page]
  );

  return { agencyFilterOptions, agencyFilterOnChange, isLoading };
};

export default useAgencyFilter;
