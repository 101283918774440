/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ColorTypes, VideoCard, VideoCardProps } from '@replai-platform/ui-components';
import * as SDK from '@replai-platform/sdk';
import { useFeature } from '@optimizely/react-sdk';
import * as Styled from './styles';
import { CONCEPTS_PLACEHOLDER_ARRAY } from '../../../utils/constants';
import AssetVariationButton from '../../AssetVariationButton';

const VideoCardGrid = ({
  data,
  isLoading,
  thumbnailsQueries,
  showAge,
  showKeyMetric,
  showNetworks,
  showHasPostQaAnnotations,
  showLaunchDate,
  showRelevanceScore,
  showRecommendations,
  disableClick,
  disableCompare,
  showCompare,
  showSave,
  compareButtonColor = 'Gray',
  compareButtonText = 'Compare',
  generateVariationButtonLabel,
  showDownloadButton,
}: {
  data: (VideoCardProps & {
    id?: SDK.UUID;
    conceptsCount?: number;
  })[];
  isLoading: boolean;
  thumbnailsQueries: any[];
  showAge?: boolean;
  showHasPostQaAnnotations?: boolean;
  showKeyMetric?: boolean;
  showNetworks?: boolean;
  showLaunchDate?: boolean;
  showRelevanceScore?: boolean;
  showRecommendations?: boolean;
  disableClick?: boolean;
  disableCompare?: boolean;
  showCompare?: boolean;
  showSave?: boolean;
  compareButtonColor?: ColorTypes;
  compareButtonText?: string;
  generateVariationButtonLabel?: string;
  showDownloadButton?: boolean;
}) => {
  const [isTechMerc1388Enabled] = useFeature('techmerc-1388'); // Creative Production

  const getVariationButton = (assetId: string) => (
    <AssetVariationButton assetId={assetId} buttonLabel={generateVariationButtonLabel} />
  );

  const renderVideoCards = () =>
    data?.map((concept, index) => (
      <VideoCard
        key={concept.id}
        {...concept}
        previewLoading={!!thumbnailsQueries[index]?.isLoading}
        previewUrl={thumbnailsQueries[index]?.data?.previewUrl ?? concept.previewUrl}
        showAge={showAge}
        showKeyMetric={showKeyMetric}
        showNetworks={showNetworks}
        showHasPostQaAnnotations={showHasPostQaAnnotations}
        showLaunchDate={showLaunchDate}
        showRelevanceScore={showRelevanceScore}
        showRecommendations={showRecommendations}
        disableClick={disableClick}
        showCompareButton={showCompare}
        showSaveButton={showSave}
        disableCompareButton={disableCompare}
        compareButtonColor={compareButtonColor}
        compareButtonText={compareButtonText}
        customButton={isTechMerc1388Enabled ? getVariationButton((concept.assetId ?? concept.id) as string) : undefined}
        showDownloadButton={showDownloadButton}
      />
    ));

  const renderLoading = () =>
    CONCEPTS_PLACEHOLDER_ARRAY?.map((concept, index) => (
      <VideoCard
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        {...concept}
        previewLoading={!!thumbnailsQueries[index]?.isLoading}
        previewUrl={thumbnailsQueries[index]?.data?.previewUrl ?? concept.previewUrl}
        showAge={showAge}
        showNetworks={showNetworks}
        showHasPostQaAnnotations={false}
        showKeyMetric={showKeyMetric}
        showLaunchDate={showLaunchDate}
        showRelevanceScore={showRelevanceScore}
        showRecommendations={showRecommendations}
        disableClick={disableClick}
        showCompareButton={showCompare}
        showSaveButton={showSave}
        disableCompareButton={disableCompare}
        compareButtonColor={compareButtonColor}
        compareButtonText={compareButtonText}
      />
    ));

  return (
    <Styled.Container data-test="main-concepts-container">
      <Styled.VideoCardsContainer>
        {renderVideoCards()}
        {isLoading ? renderLoading() : undefined}
      </Styled.VideoCardsContainer>
    </Styled.Container>
  );
};

export default VideoCardGrid;
