import { motion } from 'framer-motion';
import styled from 'styled-components';

export const LeftPanel = styled.div`
  z-index: 5;
  flex-shrink: 0;
  height: 100vh;
`;

export const RightPanel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: calc(100vw - 14rem);
  padding: 0;
  overflow: auto;
`;

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
`;

export const Loader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & h2 {
    animation: spin 5s infinite;
  }

  @keyframes spin {
    from {
      transform: rotateY(0deg);
    }

    to {
      transform: rotateY(-360deg);
    }
  }
  & h3 {
    margin: 0;
    margin: 0.5rem;
    font-size: 1.625rem;
  }
`;
