import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Colors, Typography } from '..';
import { ModalButton, ModalButtonsContainer } from '../';
import * as Styled from './styles';

export type IdleRefreshProps = {
  isOpen: boolean;
  timeout?: number; // Time in seconds to timeout
  onClose: (cause: 'timeout' | 'keep-connected') => void;
};

const parseTimeout = (timeout: number): string => {
  const minutes = Math.floor(timeout / 60);
  const seconds = timeout - minutes * 60;
  if (minutes) {
    return `${minutes}m ${`0${seconds}`.slice(-2)}s`;
  }
  return `${seconds}s`;
};

const IdleRefresh = ({ isOpen, timeout = 10, onClose }: IdleRefreshProps) => {
  const [seconds, setSeconds] = useState(timeout);
  const [isActive, setIsActive] = useState(false);

  const reset = () => {
    setSeconds(timeout);
    setIsActive(false);
  };

  useEffect(() => {
    reset();
    setIsActive(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    let interval: number | undefined;
    if (isActive) {
      interval = window.setInterval(() => {
        const newSeconds = seconds > 0 ? seconds - 1 : 0;
        setSeconds(newSeconds);
        if (newSeconds === 0) {
          onClose('timeout');
        }
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, seconds]);

  return (
    <Styled.Modal
      className="replai-idle-refresh-ant-modal"
      maskClosable={false}
      isOpen={isOpen}
      closable={false}
      modalHeader={null}
      onClose={() => onClose('keep-connected')}
    >
      <Styled.IdleRefreshContent>
        <Styled.Typography
          $margin="20px auto 0 auto"
          type="text-md"
          fontWeight="semi-bold"
        >
          You have been idle for too long..
        </Styled.Typography>
        <Styled.Typography
          $margin="20px auto 0 auto"
          type="display-md"
          fontWeight="semi-bold"
        >
          {parseTimeout(seconds)}
        </Styled.Typography>
        <Styled.Typography
          $margin="8px auto 32px auto"
          type="text-sm"
          color={Colors.Gray[500]}
        >
          Do you wish to keep connected?
        </Styled.Typography>

        <ModalButtonsContainer>
          <ModalButton
            variation="primary"
            onClick={() => onClose('keep-connected')}
          >
            <Styled.IdleRefreshDialogButton>Yes</Styled.IdleRefreshDialogButton>
          </ModalButton>
        </ModalButtonsContainer>
      </Styled.IdleRefreshContent>
    </Styled.Modal>
  );
};

export default IdleRefresh;
