import { CountryIconProps } from './types';

const DK = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3607)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M9.39165 10.4349H23.8987C23.1317 4.54709 18.0972 0.00012207 12.0003 0.00012207C11.1043 0.00012207 10.2316 0.099169 9.3916 0.285356V10.4349H9.39165Z"
        fill="#D80027"
      />
      <path
        d="M6.26085 10.4357V1.46002C2.96586 3.25787 0.607016 6.55557 0.101562 10.4357H6.26085V10.4357Z"
        fill="#D80027"
      />
      <path
        d="M6.2608 13.5656H0.101562C0.607016 17.4457 2.96586 20.7434 6.26085 22.5412L6.2608 13.5656Z"
        fill="#D80027"
      />
      <path
        d="M9.3916 13.5656V23.7151C10.2316 23.9013 11.1043 24.0003 12.0003 24.0003C18.0972 24.0003 23.1317 19.4534 23.8987 13.5656H9.3916V13.5656Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3607">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default DK;
