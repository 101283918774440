import * as SDK from '@replai-platform/sdk';
import { getMetricOrKPI } from '@replai-platform/sdk';
import {
  Card,
  KeyValue,
  KeyValues,
  MetricsSection,
  SelectInput,
  Skeleton,
  Typography,
} from '@replai-platform/ui-components';
import { useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useFeature } from '@optimizely/react-sdk';
import { capitalCase } from 'capital-case';
import { logEvent } from '../../../../../analytics';
import { api } from '../../../../../api';
import useMetricsPerformance from '../../../../../api/hooks/metrics/useMetricsPerformance';
import AssetNetworkLinkButton from '../../../../../components/AssetNetworkLinkButton';
import CustomizeFieldsButton from '../../../../../components/CustomizeFieldsButton';
import CreativeRetentionChart from '../../../../../components/CreativeRetentionChart';
import CreativeWeekdayPerformanceChart from '../../../../../components/CreativeWeekdayPerformanceChart';
import VideoSidebar from '../../../../../components/VideoSidebar';
import VideosTable from '../../../../../components/VideosTable';
import AssetVariationButton from '../../../../../components/AssetVariationButton';
import { RootState } from '../../../../../store/rootReducer';
import buildKpiDropdownFilterProps from '../../../../../utils/buildKpiDropdownFilterProps';
import { calculateDelta, deltaToGoodness, deltaToStatus } from '../../../../../utils/calculateDelta';
import { TypographyClean } from '../../../../../utils/styles';
import type { ConceptData } from '../../../../../utils/types';
import { RouteAnimator } from '../../../../RouteAnimator';
import { TableContainer } from '../../../ConceptsTable/styles';
import {
  CardChart,
  CardHeader,
  CardHeaderRight,
  Container,
  LeftSide,
  MetricsCard,
  MetricsCardWrapper,
  MetricsContainer,
  Properties,
  RightSide,
  SectionContainer,
} from '../shared/styles';
import ContextPerformance from './ContextPerformance';
import FatigueTrend from './FatigueTrend';
import ImpressionClicksChart from './ImpressionClicksChart';
import ConceptTotalMetricChart from './ConceptTotalMetricChart';
import { ALLOWED_COLUMNS_ON_CONCEPTS_TABLE, NON_CUSTOMIZABLE_COLUMNS } from '../../../../../utils/tables';
import { CUSTOMIZE_DIALOG_SUBTITLE, CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP } from '../../../../../utils/constants';

interface ConceptMetricsProps {
  conceptData: ConceptData;
  propertyData: KeyValue[];
  url: string;
  isLoading: boolean;
}

const networkAssetIdRegex = /\/([^/]+)\.[^./]+$/;

const ConceptMetrics: React.VFC<ConceptMetricsProps> = ({ conceptData, propertyData, url, isLoading }) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectBaseMetric = useSelector((state: RootState) => state.project.baseMetric);
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes);
  const filters = useSelector((state: RootState) => state.filters);
  const defaultProjectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const availableKpis = buildKpiDropdownFilterProps(
    projectBaseMetric === SDK.Metrics.SPEND ? defaultProjectKpis.concat(SDK.KPI.AVG_CONCEPT_SPEND) : defaultProjectKpis
  ).map((kpi) => ({
    title: kpi.label,
    value: kpi.value,
  }));
  const [selectedKpiIndex, setSelectedKpiIndex] = useState(0);
  const [weekdayPerformanceModeIdx, setWeekdayPerformanceModeIdx] = useState(0);
  const weekdayPerformanceModeOptions: Array<'total' | 'average'> = ['total', 'average'];
  const [weekdayPerformanceSelectedKpiIndex, setWeekdayPerformanceSelectedKpiIndex] = useState(0);
  const player = useRef<ReactPlayer | null>(null);
  const [isTechMerc1388Enabled] = useFeature('techmerc-1388'); // Creative Production

  const networkAssetId = url.match(networkAssetIdRegex)?.[1];

  const projectMetricsParams = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: defaultProjectKpis,
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: {
        ...api.filterConverter.getMetricsFilters({
          ...filters,
        }),
      },
    }),
    [filters, projectId, defaultProjectKpis]
  );
  const { data: projectMetrics, isLoading: isLoadingProjectMetrics } = useMetricsPerformance(projectMetricsParams);

  // Calculates the metrics values and deltas taking into consideration
  // the project and concept KPI's values
  const metrics = useMemo(() => {
    if (isLoadingProjectMetrics) return [];
    return (defaultProjectKpis || [])
      .filter((kpi) => SDK.Metrics.SPEND !== kpi)
      .map((metric) => ({
        name: SDK.kpiUtils.getDisplayName(metric),
        value: conceptData[metric]
          ? conceptData[metric] * (SDK.kpiUtils.filters[metric]?.isPercentage ? 100 : 1)
          : undefined,
        isPercentage: !!SDK.kpiUtils.filters[metric]?.isPercentage,
        goodness: deltaToGoodness(
          conceptData as SDK.Metric,
          (projectMetrics?.metrics ?? {}) as SDK.Metric,
          metric as SDK.Metrics
        ),
        status: deltaToStatus(
          conceptData as SDK.Metric,
          (projectMetrics?.metrics ?? {}) as SDK.Metric,
          metric as SDK.Metrics
        ),
        delta: calculateDelta(conceptData[metric] as number, projectMetrics?.metrics?.[metric] ?? 0, true, true, 2),
      }))
      .filter((metric) => metric.value !== undefined);
  }, [defaultProjectKpis, isLoadingProjectMetrics, JSON.stringify(conceptData)]);

  const renderFatigueTrend = () => (
    <Card fullWidth disableContentPadding disableShadow>
      <CardHeader>
        <TypographyClean type="text-lg" fontWeight="medium">
          Fatigue Trend
        </TypographyClean>
        <CardHeaderRight>
          <SelectInput
            defaultOption={{ title: availableKpis[selectedKpiIndex].title }}
            onChange={(index) => {
              setSelectedKpiIndex(index);
              if (selectedKpiIndex !== index) {
                logEvent({
                  component: 'Concept Details Overview',
                  action: 'Change KPI',
                  category: 'user_actions',
                  parameters: {
                    changedKpi: availableKpis[selectedKpiIndex],
                    changedKpiLabel: `KPI ${availableKpis[selectedKpiIndex].title}`,
                  },
                });
              }
            }}
            options={availableKpis}
            placeholder="Select KPI"
          />
        </CardHeaderRight>
      </CardHeader>
      <CardChart data-test="overview-chart">
        <FatigueTrend
          conceptId={conceptData.id}
          selectedKpi={getMetricOrKPI(availableKpis[selectedKpiIndex].value) ?? SDK.KPI.IPM}
        />
      </CardChart>
    </Card>
  );

  const renderMetricsNumbers = () => {
    if (isLoadingProjectMetrics) return <Skeleton key="metrics" height={78} />;
    if (metrics.length === 0) {
      return (
        <TypographyClean type="text-md" fontWeight="medium">
          No metrics available
        </TypographyClean>
      );
    }
    return <MetricsSection metrics={metrics} />;
  };

  const renderMetrics = () => (
    <MetricsContainer data-test="tag-metrics-container">
      <MetricsCardWrapper>
        {projectAccountTypes?.includes(SDK.AccountTypes.SOCIALS) ? (
          <Card fullWidth>
            <CardHeader data-test="video-card-header">
              <TypographyClean type="text-lg" fontWeight="medium">
                Video retention
              </TypographyClean>
            </CardHeader>
            <CardChart data-test="video-card-chart">
              <CreativeRetentionChart conceptId={conceptData?.id} />
            </CardChart>
          </Card>
        ) : (
          <Card fullWidth>
            <CardHeader data-test="tag-card-header">
              <TypographyClean type="text-lg" fontWeight="medium">
                Total {projectBaseMetric}
              </TypographyClean>
            </CardHeader>
            <CardChart data-test="tag-card-chart">
              <ConceptTotalMetricChart conceptId={conceptData?.id} baseMetric={projectBaseMetric} />
            </CardChart>
          </Card>
        )}
      </MetricsCardWrapper>
      {projectAccountTypes?.includes(SDK.AccountTypes.SOCIALS) ? (
        <MetricsCardWrapper>
          <Card fullWidth>
            <CardHeader data-test="video-card-header">
              <TypographyClean type="text-lg" fontWeight="medium">
                Video performance per weekday
              </TypographyClean>
              <CardHeaderRight>
                <SelectInput
                  defaultOption={{ title: availableKpis[weekdayPerformanceSelectedKpiIndex].title }}
                  onChange={(index) => {
                    setWeekdayPerformanceSelectedKpiIndex(index);
                    if (weekdayPerformanceSelectedKpiIndex !== index) {
                      logEvent({
                        component: 'Concept Details Weekday Performance',
                        action: 'Change KPI',
                        category: 'user_actions',
                        parameters: {
                          changedKpi: availableKpis[weekdayPerformanceSelectedKpiIndex],
                          changedKpiLabel: `KPI ${availableKpis[weekdayPerformanceSelectedKpiIndex].title}`,
                        },
                      });
                    }
                  }}
                  options={availableKpis}
                  placeholder="Select KPI"
                />
                <SelectInput
                  defaultOption={{
                    title: capitalCase(weekdayPerformanceModeOptions[weekdayPerformanceModeIdx]),
                  }}
                  onChange={(modeIdx) => {
                    setWeekdayPerformanceModeIdx(modeIdx);
                    if (weekdayPerformanceModeIdx !== modeIdx) {
                      logEvent({
                        component: 'Concept Details Weekday Performance',
                        action: 'Change aggregation',
                        category: 'user_actions',
                        parameters: {
                          mode: weekdayPerformanceModeOptions[weekdayPerformanceModeIdx],
                        },
                      });
                    }
                  }}
                  options={weekdayPerformanceModeOptions.map((opt) => ({
                    title: capitalCase(opt),
                  }))}
                  placeholder="Select aggregation mode"
                />
              </CardHeaderRight>
            </CardHeader>
            <CardChart data-test="video-card-chart">
              <CreativeWeekdayPerformanceChart
                conceptId={conceptData?.id}
                kpi={availableKpis[weekdayPerformanceSelectedKpiIndex].value as SDK.Metrics}
                mode={weekdayPerformanceModeOptions[weekdayPerformanceModeIdx]}
              />
            </CardChart>
          </Card>
        </MetricsCardWrapper>
      ) : null}
      {projectAccountTypes?.includes(SDK.AccountTypes.SOCIALS) ? null : (
        <MetricsCardWrapper>
          <Card fullWidth>
            <CardHeader data-test="tag-card-header">
              <TypographyClean type="text-lg" fontWeight="medium">
                Impressions / Clicks
              </TypographyClean>
            </CardHeader>
            <CardChart data-test="tag-card-chart">
              <ImpressionClicksChart conceptId={conceptData?.id} />
            </CardChart>
          </Card>
        </MetricsCardWrapper>
      )}
    </MetricsContainer>
  );

  const renderVideos = () => (
    <Card fullWidth disableContentPadding>
      <CardHeader>
        <TypographyClean type="text-lg" fontWeight="medium">
          Videos
        </TypographyClean>
        <CustomizeFieldsButton
          tableColumns={ALLOWED_COLUMNS_ON_CONCEPTS_TABLE}
          nonCustomizableColumns={NON_CUSTOMIZABLE_COLUMNS}
          disallowedTooltip={CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP}
          subtitle={CUSTOMIZE_DIALOG_SUBTITLE}
          disabled={false}
          eventPrefix="concept_overview"
        />
      </CardHeader>
      <TableContainer>
        <VideosTable conceptId={conceptData.id} />
      </TableContainer>
    </Card>
  );

  const renderContextPerformance = () => <ContextPerformance conceptData={conceptData} />;

  if (!isLoadingProjectMetrics && !projectMetrics) {
    return (
      <RouteAnimator>
        <Typography type="text-lg">Unable to load cluster details, please try again later.</Typography>
      </RouteAnimator>
    );
  }

  return (
    <RouteAnimator>
      <Container>
        <LeftSide>
          <VideoSidebar
            data-test="detailed-view-video"
            url={url}
            coreVideoTags={[]}
            coreMiscTags={[]}
            showDescription={false}
            onPlay={() => {
              logEvent({
                component: 'Concept Details',
                action: 'Play Video',
                category: 'user_actions',
              });
            }}
            onPause={() => {
              logEvent({
                component: 'Concept Details',
                action: 'Pause Video',
                category: 'user_actions',
              });
            }}
            player={player}
          />
          {isTechMerc1388Enabled ? <AssetVariationButton assetId={conceptData.assetId ?? ''} /> : null}
          <AssetNetworkLinkButton networkAssetId={networkAssetId} network={conceptData?.network?.[0]} />
          <Card fullWidth disableContentPadding>
            <Properties>
              <KeyValues
                header="Properties"
                values={propertyData}
                isLoading={isLoading}
                loadingPlaceholderNumber={11}
              />
            </Properties>
          </Card>
          <Card fullWidth disableContentPadding>
            <MetricsCard>{renderMetricsNumbers()}</MetricsCard>
          </Card>
        </LeftSide>
        <RightSide>
          <SectionContainer data-test="concept-overview-fatigue-trend">{renderFatigueTrend()}</SectionContainer>
          <SectionContainer data-test="concept-overview-metrics">{renderMetrics()}</SectionContainer>
          <SectionContainer data-test="concept-overview-videos">{renderVideos()}</SectionContainer>
          <SectionContainer data-test="concept-overview-context-performance">
            {renderContextPerformance()}
          </SectionContainer>
        </RightSide>
      </Container>
    </RouteAnimator>
  );
};

export default ConceptMetrics;
