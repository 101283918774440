import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as SDK from '@replai-platform/sdk';
import { batch } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { logEvent } from '../../analytics';
import { Page } from '../../utils/enums';

export type LibraryState = {
  selectedTagMenuOption: { type: string; kind?: string };
  currentTagsSubPage: number;
  filters: {
    tagsToConsider: SDK.Tag[];
    tagsToExclude: SDK.Tag[];
  };
};

const initialState: LibraryState = {
  selectedTagMenuOption: { type: '', kind: undefined },
  currentTagsSubPage: 1,
  filters: {
    tagsToConsider: [],
    tagsToExclude: [],
  },
};

export const LibrarySlice = createSlice({
  name: 'Library',
  initialState,
  reducers: {
    reset: (state) => Object.assign(state, initialState),
    changeSelectedTagMenuOption: (
      state,
      action: PayloadAction<{ type: string; kind?: string; isTagRequest?: boolean; logEvent: boolean }>
    ) => {
      state.selectedTagMenuOption = action.payload;
      if (action.payload.logEvent) {
        logEvent({
          component: `Tag Library`,
          action: `Click Tag Type${action.payload.isTagRequest ? ' - Plus Icon' : ''}`,
          category: 'user_actions',
          parameters: { page: Page.TagsLibrary, type: action.payload.type ?? 'All', kind: action.payload.kind },
        });
      }
    },
    changeCurrentTagsSubPage: (state, action: PayloadAction<{ page: number; logEvent: boolean }>) => {
      state.currentTagsSubPage = action.payload.page;
      if (action.payload.logEvent) {
        logEvent({
          component: `Tag Library`,
          action: 'Change Page',
          category: 'user_actions',
          parameters: { page: Page.TagsLibrary, pageNumber: action.payload.page },
        });
      }
    },
    changeTags: (state, action: PayloadAction<{ value: SDK.Tag[]; logEvent: boolean; toExclude: boolean }>) => {
      if (action.payload.logEvent) {
        logEvent({
          component: 'Sidebar Filters',
          // Ideally the event name would be 'Change Tags' however, to not mess up existing events, keep it like this
          action: `Change Tags To Consider`,
          category: 'user_actions',
          parameters: { ...action.payload, toExclude: action.payload.toExclude },
        });
      }
      batch(() => {
        state.filters.tagsToConsider = action.payload.toExclude ? [] : action.payload.value;
        state.filters.tagsToExclude = action.payload.toExclude ? action.payload.value : [];
      });
    },
  },
});

export const LibraryActions = LibrarySlice.actions;
export const LibraryReducer = LibrarySlice.reducer;
export const LibraryInitialState = initialState;
