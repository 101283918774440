import { CommonTagIconProps } from '../types';

const CutFrequencyHigh = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7649 14.383C11.5109 16.21 11.2089 17.625 10.7409 17.625C9.42094 17.625 9.42094 6.37402 8.10094 6.37402C6.78294 6.37402 6.78294 17.625 5.46394 17.625C4.96194 17.625 4.65094 15.986 4.38594 13.96C4.23994 12.841 3.28394 12 2.15494 12H2.01794"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.9819 12C20.8529 12 19.8969 11.16 19.7499 10.041C19.4829 8.014 19.1709 6.375 18.6669 6.375C17.3449 6.375 17.3449 17.626 16.0219 17.626C14.7019 17.626 14.7019 6.375 13.3809 6.375C12.8689 6.375 12.5549 8.07 12.2859 10.144"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default CutFrequencyHigh;
