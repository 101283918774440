import { CountryIconProps } from './types';

const FJ = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3621)">
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C0 12.0029 12 0.0013125 12 0C18.6274 0 24 5.37262 24 12Z"
        fill="#338AF3"
      />
      <path
        d="M12.0001 5.74023V9.78756L7.82617 5.74023H12.0001Z"
        fill="#0052B4"
      />
      <path
        d="M6.26098 12.0018H9.78654L6.26098 7.30615L5.73926 10.9583L6.26098 12.0018Z"
        fill="#0052B4"
      />
      <path
        d="M11.9678 12.0001H11.9999C11.9999 11.9893 11.9999 11.9787 11.9999 11.9679C11.9892 11.9786 11.9785 11.9894 11.9678 12.0001Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 6.26147C12 4.14999 12 2.76647 12 0.000610352H11.998C5.3715 0.00173535 0 5.37384 0 12.0006H6.26086V8.47509L9.78642 12.0006H11.968C11.9787 11.9899 11.9894 11.9792 12.0001 11.9685C12.0001 11.16 12.0001 10.4387 12.0001 9.78708L8.47443 6.26147H12Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.07124 1.5658C4.19441 2.6345 2.63413 4.19478 1.56543 6.07161V12.0006H4.69588V4.69635V4.69625H12.0002C12.0002 3.70892 12.0002 2.76833 12.0002 1.5658H6.07124Z"
        fill="#D80027"
      />
      <path
        d="M11.9998 10.5249L7.73641 6.26147H6.26074V6.26157L11.9997 12.0006H11.9998C11.9998 12.0006 11.9998 10.983 11.9998 10.5249Z"
        fill="#D80027"
      />
      <path
        d="M13.5654 7.82718V12.001C13.5654 14.7966 17.2176 15.6532 17.2176 15.6532C17.2176 15.6532 20.8698 14.7966 20.8698 12.001V7.82718L17.2176 6.78369L13.5654 7.82718Z"
        fill="#F3F3F3"
      />
      <path
        d="M20.8698 7.82631V6.26111H13.5654V7.82631H16.6958V10.9568H13.5654V12.0002C13.5654 12.0002 13.5654 12.0003 13.5654 12.0003L16.6958 12.0003V15.4924C17.0107 15.6038 17.2176 15.6524 17.2176 15.6524C17.2176 15.6524 17.4244 15.6039 17.7393 15.4924V12.0003H20.8698V12.0002V10.9568H17.7393V7.82626H20.8698V7.82631Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3621">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default FJ;
