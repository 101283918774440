import { CountryIconProps } from './types';

const HR = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12C24 10.5321 23.736 9.12602 23.2536 7.82609L12 7.30432L0.746391 7.82604C0.264047 9.12602 0 10.5321 0 12C0 13.4678 0.264047 14.8739 0.746391 16.1739L12 16.6956L23.2536 16.1739C23.736 14.8739 24 13.4678 24 12Z"
      fill="#F0F0F0"
    />
    <path
      d="M11.9997 23.9995C17.1593 23.9995 21.5578 20.743 23.2533 16.1733H0.746094C2.44161 20.743 6.84013 23.9995 11.9997 23.9995Z"
      fill="#0052B4"
    />
    <path
      d="M0.746094 7.82666H23.2534C21.5578 3.25705 17.1593 0.000549316 11.9997 0.000549316C6.84012 0.000549316 2.44161 3.25705 0.746094 7.82666Z"
      fill="#D80027"
    />
    <path
      d="M15.1309 8.34753H13.0439L13.3918 5.7388L14.7831 4.69531L16.1743 5.7388V7.82576L15.1309 8.34753Z"
      fill="#338AF3"
    />
    <path
      d="M8.86961 8.34802H10.9566L10.6087 5.73928L9.21742 4.6958L7.82617 5.73928V7.82625L8.86961 8.34802Z"
      fill="#338AF3"
    />
    <path
      d="M13.3911 8.34777H10.6084V5.73904L11.9997 4.69556L13.3911 5.73904V8.34777Z"
      fill="#0052B4"
    />
    <path
      d="M7.82617 7.82574V13.5648C7.82617 14.9314 8.48627 16.1467 9.50439 16.9085L10.2348 16.7258L11.1566 17.653C11.4292 17.7092 11.7112 17.7388 12.0001 17.7388C12.2875 17.7388 12.5681 17.7096 12.8392 17.654L13.8914 16.7779L14.4957 16.9068C15.5134 16.145 16.174 14.931 16.174 13.5648V7.82574H7.82617Z"
      fill="#F0F0F0"
    />
    <path
      d="M9.49572 7.82593H7.82617V9.49548H9.49572V7.82593Z"
      fill="#D80027"
    />
    <path d="M12.8346 7.82593H11.165V9.49548H12.8346V7.82593Z" fill="#D80027" />
    <path
      d="M16.1744 7.82593H14.5049V9.49548H16.1744V7.82593Z"
      fill="#D80027"
    />
    <path d="M11.1647 9.495H9.49512V11.1645H11.1647V9.495Z" fill="#D80027" />
    <path d="M14.5045 9.495H12.835V11.1645H14.5045V9.495Z" fill="#D80027" />
    <path
      d="M9.49572 11.1638H7.82617V12.8334H9.49572V11.1638Z"
      fill="#D80027"
    />
    <path
      d="M11.1647 12.8347H9.49512V14.5042H11.1647V12.8347Z"
      fill="#D80027"
    />
    <path d="M12.8346 11.1638H11.165V12.8334H12.8346V11.1638Z" fill="#D80027" />
    <path
      d="M16.1744 11.1638H14.5049V12.8334H16.1744V11.1638Z"
      fill="#D80027"
    />
    <path d="M14.5045 12.8347H12.835V14.5042H14.5045V12.8347Z" fill="#D80027" />
    <path d="M12.8346 14.5039H11.165V16.1734H12.8346V14.5039Z" fill="#D80027" />
    <path
      d="M9.49542 14.5039H7.93359C8.07684 15.1245 8.35847 15.6924 8.74462 16.1734H9.49542V14.5039Z"
      fill="#D80027"
    />
    <path
      d="M14.5049 16.1736H15.2556C15.6419 15.6926 15.9234 15.1247 16.0667 14.5041H14.5049V16.1736Z"
      fill="#D80027"
    />
    <path
      d="M9.49512 16.1732V16.9016C9.98069 17.267 10.5482 17.529 11.1647 17.6547V16.1732H9.49512Z"
      fill="#D80027"
    />
    <path
      d="M12.835 16.1732V17.6547C13.4515 17.5291 14.0189 17.267 14.5045 16.9016V16.1732H12.835Z"
      fill="#D80027"
    />
  </svg>
);
export default HR;
