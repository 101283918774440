import * as SDK from '@replai-platform/sdk';
import { Badge, camelCaseToCapitalCase, Tab, Tabs } from '@replai-platform/ui-components';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useMountedState } from 'react-use';
import useHistoryState from 'use-history-state';
import { logEvent } from '../../../../analytics';
import useConceptPreviewInfo from '../../../../api/hooks/concepts/useConceptPreviewInfo';
import useConceptTags from '../../../../api/hooks/concepts/useConceptTags';
import { RootState } from '../../../../store/rootReducer';
import { formatDuration } from '../../../../utils';
import { Page } from '../../../../utils/enums';
import { TIMELINE_MAX_DURATION_S_AVAILABILITY } from '../../../../utils/constants';
import type { ConceptData } from '../../../../utils/types';
import { RouteAnimator } from '../../../RouteAnimator';
import { PROPERTY_TAG_TYPES } from '../../utils';
import ConceptMetrics from './ConceptMetrics';
import { MainContainer } from './shared/styles';
import * as Styled from './styles';
import TabPaths from './TabPaths';
import TimelineTab from './Timeline';

const getTabLabel = (tab: string): string => {
  const cleanTab = tab.replace('-', ' ');
  return cleanTab[0].toUpperCase() + cleanTab.slice(1).toLowerCase();
};

interface ConceptViewTabsProps {
  conceptData: ConceptData;
  loading: boolean;
}

const ConceptViewTabs: React.VFC<ConceptViewTabsProps> = ({ conceptData, loading }) => {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const match = useMatch(`:projectId/${Page.Concepts}/:clusterId/*`);
  const pathnameBase = match?.pathnameBase;
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useHistoryState(TabPaths.Timeline, 'currentTab');

  const tabs = useMemo(() => [TabPaths.Timeline, TabPaths.Metrics], []);

  const location = useLocation();

  const projectId = useSelector((state: RootState) => state.project.id);

  const conceptId = conceptData?.id;

  // Tag data is also used to get the right preview of the concept.
  const { data: tagData, isLoading } = useConceptTags({ projectId }, conceptId, { enabled: !!conceptId });
  const { data: conceptPreviewData } = useConceptPreviewInfo({
    conceptId,
    size: '200x200',
  });
  const isAssetTimelineDisabled = useMemo(
    () => (tagData?.duration || 0) > TIMELINE_MAX_DURATION_S_AVAILABILITY,
    [tagData]
  );

  const isMountedValue = isMounted();
  useEffect(() => {
    if (isMountedValue && isAssetTimelineDisabled) {
      setCurrentTab(TabPaths.Metrics, true);
    }
  }, [isMountedValue, isAssetTimelineDisabled]);

  const tabLabels: Tab[] = useMemo<Tab[]>(
    () =>
      tabs.map((tab) => {
        const isDisabledDueToDuration = tab === TabPaths.Timeline && isAssetTimelineDisabled;
        return {
          label: getTabLabel(tab),
          trailingBadge: isDisabledDueToDuration ? (
            <Badge style={{ marginLeft: '3px' }} leadingIcon="AlertTriangle" color="Warning" />
          ) : undefined,
          disabled: isDisabledDueToDuration,
          tooltipContent: isDisabledDueToDuration
            ? 'Timeline not available for creatives longer than 100 seconds.'
            : undefined,
        };
      }),
    [tabs, isAssetTimelineDisabled]
  );

  useEffect(() => {
    if (location.pathname.indexOf(TabPaths.Timeline) > 0 && currentTab !== TabPaths.Timeline)
      setCurrentTab(TabPaths.Timeline, true);
    else if (location.pathname.indexOf(TabPaths.Metrics) > 0 && currentTab !== TabPaths.Metrics)
      setCurrentTab(TabPaths.Metrics, true);
    else if (!loading && !tabs.some((tab) => location.pathname.indexOf(tab) > 0)) {
      setCurrentTab(TabPaths.Timeline, true);
      navigate(`${pathnameBase ?? location.pathname}/${TabPaths.Timeline}${location.search}`, {
        replace: true,
      });
    }
  }, [
    currentTab,
    setCurrentTab,
    location,
    tabs,
    pathnameBase,
    loading,
    navigate,
    dispatch,
    conceptData?.name,
    conceptData?.displayName,
  ]);

  const onChangeTab = (tab: Tab, tabIndex: number) => {
    navigate(`${pathnameBase ?? location.pathname}/${tabs[tabIndex]}${location.search}`, {
      replace: true,
    });
    logEvent({
      component: 'Concept Details',
      action: `click ${camelCaseToCapitalCase(tabs[tabIndex])}`,
      category: 'user_actions',
    });
    setCurrentTab(tabs[tabIndex], true);
  };

  const propertyData = useMemo(() => {
    const durationProperty = {
      key: 'Duration',
      value: tagData?.duration ? formatDuration(tagData?.duration) : '00:00:00',
    };
    const propertiesTags =
      tagData?.tags
        .filter((tag) => PROPERTY_TAG_TYPES.includes(tag.type as SDK.CoreTags) && !!tag.value)
        .map((tag) => ({
          key: camelCaseToCapitalCase(tag.type),
          value: camelCaseToCapitalCase(tag.value || '-'),
        })) ?? [];

    return [...propertiesTags, durationProperty].sort((a, b) => a.key.localeCompare(b.key));
  }, [tagData?.duration, tagData?.tags]);

  return (
    <MainContainer>
      <Tabs
        variant="gray-button"
        tabLabels={tabLabels}
        onTabChange={onChangeTab}
        defaultSelectedTab={tabLabels.find((tab) => tab.label === getTabLabel(currentTab))}
      />
      <Styled.Container>
        <Styled.TabContainer>
          <AnimatePresence exitBeforeEnter>
            <Routes>
              <Route
                path={`${TabPaths.Timeline}/*`}
                element={
                  <RouteAnimator>
                    <TimelineTab
                      conceptData={conceptData}
                      propertyData={propertyData}
                      url={tagData?.url ?? (isLoading ? '' : conceptPreviewData?.previewUrl) ?? ''}
                      isLoading={isLoading}
                    />
                  </RouteAnimator>
                }
              />
              <Route
                path={`${TabPaths.Metrics}/*`}
                element={
                  <RouteAnimator>
                    <ConceptMetrics
                      conceptData={conceptData}
                      propertyData={propertyData}
                      url={tagData?.url ?? (isLoading ? '' : conceptPreviewData?.previewUrl) ?? ''}
                      isLoading={isLoading}
                    />
                  </RouteAnimator>
                }
              />
            </Routes>
          </AnimatePresence>
        </Styled.TabContainer>
      </Styled.Container>
    </MainContainer>
  );
};

export default ConceptViewTabs;
