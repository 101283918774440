import { Button } from '@replai-platform/ui-components';
import { logEvent } from '../../analytics';

type DownloadAssetButtonProps = {
  url: string;
};

const DownloadAssetButton = ({ url }: DownloadAssetButtonProps) => {
  if (!url) {
    return null;
  }

  return (
    <div style={{ width: '100%' }}>
      <Button
        style={{ width: '100%' }}
        // color={config.color}
        leadingIcon={{ name: 'Download' }}
        onClick={(e) => {
          e.stopPropagation();
          window.open(url, '_blank', 'noopener,noreferrer');
          logEvent({
            component: 'DownloadAssetButton',
            action: 'click_download_asset',
            category: 'user_actions',
          });
        }}
      >
        Download creative
      </Button>
    </div>
  );
};

export default DownloadAssetButton;
