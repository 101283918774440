import { IntegrationIconProps } from './types';

const JbAppcode = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1334_1420)">
      <path
        d="M27.0631 25.1429L32.0002 12.0229L17.0517 8.68573L15.0859 14.2629L27.0631 25.1429Z"
        fill="#247CE6"
      />
      <path
        d="M32.0008 25.6457L24.458 32L9.41797 28.1143L13.4408 20.8L32.0008 25.6457Z"
        fill="url(#paint0_linear_1334_1420)"
      />
      <path
        d="M4.43375 15.9086L0.59375 4.93714L17.5538 0L16.1823 14.1714L4.43375 15.9086Z"
        fill="url(#paint1_linear_1334_1420)"
      />
      <path
        d="M27.9314 18.5143L23.1771 10.3771L23.2686 10.3314L17.5543 0L0 18.9714V32L31.9086 25.6457L27.9314 18.5143Z"
        fill="url(#paint2_linear_1334_1420)"
      />
      <path d="M6.125 6.12567H25.8736V25.8742H6.125V6.12567Z" fill="black" />
      <path
        d="M7.95312 22.1714H15.3588V23.4057H7.95312V22.1714Z"
        fill="white"
      />
      <path
        d="M11.1989 8.68567H12.8446L16.3189 16.8685H14.4446L13.7132 15.04H10.2846L9.55318 16.8685H7.72461L11.1989 8.68567ZM13.0732 13.44L11.976 10.7885L10.8789 13.44H13.0732Z"
        fill="white"
      />
      <path
        d="M15.8613 12.8457C15.8613 10.4685 17.6442 8.54852 20.1585 8.54852C21.7128 8.54852 22.627 9.05138 23.4042 9.82852L22.2613 11.1542C21.6213 10.56 20.9813 10.24 20.1585 10.24C18.787 10.24 17.7813 11.3828 17.7813 12.8C17.7813 14.2171 18.7413 15.4057 20.1585 15.4057C21.1185 15.4057 21.667 15.04 22.307 14.4457L23.4499 15.5885C22.5813 16.5028 21.667 17.0514 20.067 17.0514C17.6442 17.0514 15.8613 15.1771 15.8613 12.8457Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1334_1420"
        x1="9.42574"
        y1="26.4023"
        x2="32.0008"
        y2="26.4023"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.194" stopColor="#00DAF0" />
        <stop offset="0.903" stopColor="#247CE6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1420"
        x1="0.581864"
        y1="7.96114"
        x2="17.5583"
        y2="7.96114"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.194" stopColor="#00DAF0" />
        <stop offset="0.903" stopColor="#247CE6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_1420"
        x1="20.9719"
        y1="33.0158"
        x2="5.11863"
        y2="10.891"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.091" stopColor="#1DDF93" />
        <stop offset="0.484" stopColor="#00DAF0" />
        <stop offset="0.903" stopColor="#247CE6" />
      </linearGradient>
      <clipPath id="clip0_1334_1420">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default JbAppcode;
