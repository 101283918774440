/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SortingRule } from 'react-table';
import { Button, EmptyStateProps, Input, Notification, Typography } from '@replai-platform/ui-components';
import { validate } from 'react-email-validator';
import { Auth } from 'aws-amplify';
import type { RootState } from '../../../store/rootReducer';
import Table from '../../../components/Table';
import { logEvent } from '../../../analytics';
import { CommonSnackbar } from '../../../utils/styles';
import * as Styled from './styles';
import useInvite from '../../../api/hooks/users/useInvite';
import useResendInvite from '../../../api/hooks/users/useResendInvite';
import useProjectUsers from '../../../api/hooks/projects/useProjectUsers';

const SUPPORTED_COLUMNS = ['user', 'status', 'actions'];
const ITEMS_PER_PAGE = 5;

const TeamManagement: React.VFC = () => {
  const projectName = useSelector((state: RootState) => state.project.name);
  const projectId = useSelector((state: RootState) => state.project.id);
  const [offset, setOffset] = useState(0);
  const [sorting, setSorting] = useState([{ id: 'name', desc: true }] as SortingRule<string>[]);
  const [inputFields, setInputFields] = useState([
    { id: 0, error: false, domainError: false, warning: false, value: '' },
    { id: 1, error: false, domainError: false, warning: false, value: '' },
    { id: 2, error: false, domainError: false, warning: false, value: '' },
  ]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [inviting, setLoadingInvites] = useState(false);
  const [emailsToResend, setEmailsToResend] = useState<string[]>([]);
  const inviteUserMutation = useInvite();
  const resendInviteUserMutation = useResendInvite();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user: { attributes: { email: string } }) => {
        setCurrentUserEmail(user?.attributes?.email);
      })
      .catch(() => {
        // do nothing
      });
  }, [projectName]);

  const onOffsetChange = useCallback(
    ({ pageIndex }) => {
      if (pageIndex !== offset) {
        setOffset(pageIndex * ITEMS_PER_PAGE);
        logEvent({
          component: 'Team Management',
          action: 'Change page',
          category: 'user_actions',
          parameters: { pageIndex, page: `Page ${+pageIndex + 1}` },
        });
      }
    },
    [offset]
  );

  const onSort = useCallback(
    (sortBy: SortingRule<string>[]) => {
      if (sortBy !== sorting) {
        setSorting(sortBy);
      }
    },
    [sorting]
  );

  const emptyStateProps = {
    icon: 'Search',
    text: 'No users found',
    supportingText: 'We did not find any users on your organization',
  } as EmptyStateProps;

  const { data, isLoading, refetch } = useProjectUsers({ projectId });

  const tableData = useMemo(() => data?.slice(offset, offset + ITEMS_PER_PAGE), [data, offset]);

  const validateEmail = (inputId: number, email: string) => {
    const newInputFields = inputFields.map((i) =>
      i.id === inputId
        ? {
            ...i,
            error: email ? !validate(email) : false,
            domainError: email ? validate(email) && currentUserEmail.split('@')[1] !== email.split('@')[1] : false,
          }
        : i
    );
    setInputFields(newInputFields);
  };

  const hintText = (inputField: { error: any; domainError: any; warning: any }) => {
    if (inputField.error) {
      return 'This email is not valid';
    }

    if (inputField.domainError) {
      return `Email should be from the org domain (${currentUserEmail.split('@')[1]})`;
    }

    if (inputField.warning) {
      return 'Email is already invited';
    }

    return '';
  };

  const onSendInvite = async () => {
    const emails = inputFields.filter((i) => i.value && !i.error && !i.domainError).map((i) => i.value);
    if (emails) {
      setLoadingInvites(true);
      await inviteUserMutation.mutateAsync(
        { emails, projectId },
        {
          onSuccess: (res) => {
            if (res.length === 0) setSuccess(true);
            setLoadingInvites(false);
            setEmailsToResend(res);
            setInputFields(
              inputFields.map((i) =>
                res.includes(i.value)
                  ? { ...i, warning: true, error: false, domainError: false }
                  : { ...i, value: '', error: false, warning: false, domainError: false }
              )
            );
            return refetch();
          },
          onError: () => {
            setError(true);
            setLoadingInvites(false);
          },
          onSettled: () => {
            logEvent({
              component: 'Team Management',
              action: `Send Invites`,
              category: 'user_actions',
            });
          },
        }
      );
    }
  };

  const onResendInvite = async () => {
    await resendInviteUserMutation.mutateAsync(
      { emails: emailsToResend },
      {
        onSuccess: () => {
          setSuccess(true);
          setEmailsToResend([]);
        },
        onError: () => {
          setError(true);
          setEmailsToResend([]);
        },
        onSettled: () => {
          logEvent({
            component: 'Team Management',
            action: `Resend Invites`,
            category: 'user_actions',
          });
        },
      }
    );
  };

  return (
    <Styled.MainContainer data-test="team-container">
      <Styled.Container data-test="users-table">
        <Styled.TextContainer>
          <Typography fontWeight="semi-bold">Team members</Typography>
          <Typography type="text-sm">Manage your existing team and change roles/permissions</Typography>
        </Styled.TextContainer>
        <Table
          pageIndex={offset / ITEMS_PER_PAGE}
          onPageUpdate={onOffsetChange}
          onSort={onSort}
          tableData={tableData || []}
          columns={SUPPORTED_COLUMNS}
          rowsPerPage={ITEMS_PER_PAGE}
          rowsTotal={data?.length}
          sorting={sorting}
          emptyStateProps={emptyStateProps}
          loading={
            isLoading
              ? {
                  messages: ['Fetching users', 'Getting everything ready'],
                }
              : undefined
          }
        />
      </Styled.Container>
      <Styled.Container data-test="invite-members">
        <Styled.TextContainer>
          <Typography fontWeight="semi-bold">Invite team members</Typography>
          <Typography type="text-sm">
            Get your projects up and running faster by inviting your team to collaborate
          </Typography>
        </Styled.TextContainer>
        <Styled.FullWidthContainer>
          {inputFields.map((i) => (
            <Input
              key={i.id}
              onKeyUp={(event: any) => {
                if (event.code === 'Backspace') {
                  const delayDebounceFn = setTimeout(() => {
                    if (event.target.value.length < 2) {
                      validateEmail(i.id, '');
                    }
                  }, 500);

                  return () => clearTimeout(delayDebounceFn);
                }
              }}
              onChange={(e) => {
                i.value = e.target.value;
                if (e.target.value.length > 2) {
                  validateEmail(i.id, e.target.value);
                }
              }}
              error={i.error || i.domainError}
              warning={i.warning}
              hintText={hintText(i)}
              fullWidth
              placeholder="example@example.com"
              leadingIcon="Mail"
              data-test={`invite-member-${i.id}`}
            />
          ))}
          <Styled.ButtonContainer>
            <Button
              variant="no-fill"
              color="Gray"
              onClick={() => {
                setInputFields([
                  ...inputFields,
                  { id: inputFields.length, error: false, domainError: false, warning: false, value: '' },
                ]);
                logEvent({
                  component: 'Team Management',
                  action: `Add User Field`,
                  category: 'user_actions',
                });
              }}
              leadingIcon={{ name: 'Plus' }}
              data-test="invite-member-add"
            >
              Add another
            </Button>
            <Button
              variant="regular"
              onClick={onSendInvite}
              leadingIcon={inviting ? { name: 'LoadingCircle' } : { name: 'Mail' }}
              disabled={inviting || inputFields.filter(({ value }) => !!value).length === 0}
              data-test="invite-member-send"
            >
              Send invites
            </Button>
          </Styled.ButtonContainer>
        </Styled.FullWidthContainer>
      </Styled.Container>
      <CommonSnackbar
        open={success && emailsToResend.length === 0}
        onClose={() => setSuccess(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
      >
        <Notification
          badgeTitle="Success"
          badgeLabel="Invites were sent to your teammates"
          message="You will be notified when they accept invitations"
          color="Success"
        />
      </CommonSnackbar>
      <CommonSnackbar
        open={error}
        onClose={() => setError(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
      >
        <Notification
          badgeTitle="Error"
          badgeLabel="Could not invite your teammates"
          message="Please check if all the information provided is valid and try again"
          color="Error"
        />
      </CommonSnackbar>
      {emailsToResend.map((email: string) => (
        <CommonSnackbar
          key={email}
          open={emailsToResend && emailsToResend.length > 0}
          onClose={() => {
            setError(false);
            setEmailsToResend([]);
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={5000}
        >
          <Notification
            badgeTitle="Warning"
            badgeLabel="User already invited"
            message={`${email} is already invited`}
            color="Warning"
            buttons={[
              {
                children: 'Resend',
                variant: 'no-fill',
                color: 'Gray',
                onClick: onResendInvite,
              },
            ]}
          />
        </CommonSnackbar>
      ))}
    </Styled.MainContainer>
  );
};

export default TeamManagement;
