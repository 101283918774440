import styled from 'styled-components';
import { Colors } from '@replai-platform/ui-components';

export const TIMELINE_WIDTH = 23;
export const COLUMN_WIDTH = 22;
export const PADDING = 1;
export const TIMELINE_LINES_OFFSET_TOP = 3.5;
export const TIMELINE_LINES_SHARED_OFFSET_TOP = 7.5;
export const TIMELINE_LINES_EMPTY_SHARED_OFFSET_TOP = 23.5;
export const TIMELINE_LINES_DIFFERENCES_OFFSET_TOP =
  TIMELINE_LINES_OFFSET_TOP + TIMELINE_LINES_SHARED_OFFSET_TOP + PADDING;

const calculateTopOffset = (isSharedOpen: boolean, isSharedEmpty: boolean): number => {
  if (isSharedOpen && !isSharedEmpty) return TIMELINE_LINES_OFFSET_TOP;
  if (isSharedOpen) return TIMELINE_LINES_EMPTY_SHARED_OFFSET_TOP;
  return TIMELINE_LINES_DIFFERENCES_OFFSET_TOP;
};

export const AccordionContainer = styled.div`
  position: relative;
  width: 100%;

  > * {
    margin-bottom: ${PADDING}rem;
  }

  &:nth-child(2) > * {
    margin-right: -1px;
    margin-left: -1px;
  }

  &:first-child > div > * {
    margin-right: -1px;
    margin-left: -1px;
  }

  #accordion-content {
    padding: 0;
  }

  > :nth-child(3) #accordion-content {
    position: relative;
    padding: 0;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-auto-columns: 22rem;
  grid-auto-flow: column;
  gap: 1rem;

  > :first-child {
    padding: 1rem 1.8rem 1rem 1.8rem;
    background-color: ${Colors.Gray[50]};
    border-right: 1px solid ${Colors.Gray[200]};
    border-bottom-left-radius: 0.5rem;
  }

  > * {
    z-index: 0;
    width: 22.1rem;
    padding: 1rem 1.8rem 1rem 1rem;
    border-right: 1px solid ${Colors.Gray[200]};
  }

  > :last-child {
    border-right: 0px;
  }

  > :first-child:last-child {
    width: 22rem;
    border-bottom-right-radius: 0.5rem;
  }
`;

export const ColumnContainer = styled.div`
  position: relative;
  display: block;
`;

export const Timestamps = styled.div`
  margin-bottom: 2.5rem;
`;

export const Absolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  pointer-events: none;
`;

export const FixedContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: ${TIMELINE_WIDTH}rem;
  padding-left: 0.85rem;
  background-color: ${Colors.Gray[50]};
  pointer-events: all;

  > :first-child {
    margin-bottom: ${PADDING}rem;
  }

  > * {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  #accordion-content {
    padding: 0;
    border-bottom-right-radius: 0;
  }
`;

export const GridContainerFixed = styled.div`
  width: 23rem;

  > :first-child {
    background-color: ${Colors.Gray[50]};
    border-bottom-left-radius: 0.5rem;
  }

  > * {
    z-index: 0;
    width: ${COLUMN_WIDTH}rem;
    padding: 1rem 1.8rem 1rem 1.8rem;
  }
`;

export const Border = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: ${TIMELINE_WIDTH}rem;
  height: 100%;
  background-color: ${Colors.Gray[50]};
  border-right: 1px solid ${Colors.Gray[200]};
`;

export const EmptyStateWrapper = styled.div`
  > div > div {
    margin: 2rem 0;
  }
`;

export const Empty = styled.div`
  height: 11.5rem;
`;

export const TimestampLines = styled.div<{ isSharedOpen: boolean; isSharedEmpty: boolean }>`
  position: absolute;
  top: ${({ isSharedOpen, isSharedEmpty }) => `${calculateTopOffset(isSharedOpen, isSharedEmpty)}rem`};
  left: 0.9rem;
  z-index: 2;
  display: grid;
  grid-auto-columns: 20.5rem;
  grid-auto-flow: column;
  gap: 2.5rem;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const TimestampCol = styled.div<{ index: number }>`
  position: relative;
  left: ${({ index }) => (index === 0 ? '1.8rem' : '1rem')};
  height: 100%;
  margin-right: ${({ index }) => (index === 0 ? '2rem' : '1rem')};
  pointer-events: none;
`;

export const AbsoluteTimestampLine = styled.div<{ isSharedOpen: boolean; isSharedEmpty: boolean }>`
  position: absolute;
  top: ${({ isSharedOpen, isSharedEmpty }) => `${calculateTopOffset(isSharedOpen, isSharedEmpty)}rem`};
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const TimestampLine = styled.div`
  position: sticky;
  top: 0;
  left: 0.9rem;
  z-index: 2;
  display: block;
  width: 20.4rem;
  height: 100%;
  pointer-events: none;
`;
