import * as SDK from '@replai-platform/sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BarChart, LoadingBars } from '@replai-platform/ui-components';
import { kpiUtils, Metrics } from '@replai-platform/sdk';
import { LoadingContainer } from '../../../../../../utils/styles';
import { RootState } from '../../../../../../store/rootReducer';
import { api } from '../../../../../../api/index';
import useMetricsHistory from '../../../../../../api/hooks/metrics/useMetricsHistory';

interface ImpressionClicksChartProps {
  tag: SDK.Tag;
}

const ImpressionClicksChart = ({ tag }: ImpressionClicksChartProps) => {
  const filters = useSelector((state: RootState) => state.filters);
  const projectId = useSelector((state: RootState) => state.project.id);

  const bars = [
    {
      name: kpiUtils.getDisplayName(Metrics.CLICKS),
      accessor: Metrics.CLICKS,
    },
    {
      name: kpiUtils.getDisplayName(Metrics.IMPRESSIONS),
      accessor: Metrics.IMPRESSIONS,
    },
  ];

  const getTagImpressionClicksParams = useMemo(
    () => ({
      projectIds: [projectId],
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      tagsFilters: { tagsToConsider: [tag] },
      metrics: [Metrics.CLICKS, Metrics.IMPRESSIONS],
    }),
    [JSON.stringify(filters), projectId, tag]
  );

  const { data, isLoading } = useMetricsHistory(getTagImpressionClicksParams, {
    enabled: !!tag,
  });

  // aggregates all clicks and impressions from the same month
  // to show a per month bar in the graph
  const monthlyData = useMemo(() => {
    const newData: {
      name: string;
      clicks: number;
      impressions: number;
    }[] = [];
    let current: (typeof newData)[number] = { name: '', [Metrics.CLICKS]: 0, [Metrics.IMPRESSIONS]: 0 };

    (data?.history || []).forEach((entry) => {
      if (!current.name) {
        current = {
          name: entry.date,
          [Metrics.CLICKS]: entry.metrics[Metrics.CLICKS] || 0,
          [Metrics.IMPRESSIONS]: entry.metrics[Metrics.IMPRESSIONS] || 0,
        };
        return;
      }

      const currentMonth = new Date(current.name).getMonth();
      if (currentMonth !== new Date(entry.date).getMonth()) {
        newData.push(current);
        current = {
          name: entry.date,
          [Metrics.CLICKS]: entry.metrics[Metrics.CLICKS] || 0,
          [Metrics.IMPRESSIONS]: entry.metrics[Metrics.IMPRESSIONS] || 0,
        };
        return;
      }

      current[Metrics.CLICKS] += entry.metrics[Metrics.CLICKS] || 0;
      current[Metrics.IMPRESSIONS] += entry.metrics[Metrics.IMPRESSIONS] || 0;
    });

    if (current.name !== '') newData.push(current);
    return newData;
  }, [JSON.stringify(data)]);

  if (isLoading || !monthlyData) {
    return (
      <LoadingContainer>
        <LoadingBars barSize="xxl" />
      </LoadingContainer>
    );
  }

  return (
    <BarChart
      data-test="chart"
      data={monthlyData}
      bars={bars}
      yValueFormatter={(v) => kpiUtils.format(Metrics.IMPRESSIONS, v)}
      xValueFormatter={(s) =>
        new Date(s).toLocaleDateString('en-US', {
          month: 'long',
        })
      }
    />
  );
};

export default ImpressionClicksChart;
