import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase, type MultiSelectDropDownOption } from '@replai-platform/ui-components';
import { useQueryParam, ArrayParam } from 'use-query-params';
import { useNetworks } from '../../../api/hooks/networks/useNetworks';
import type { RootState } from '../../../store/rootReducer';
import { ProjectActions } from '../../../store/project';
import { FilterActions } from '../../../store/filters';
import { Page, QueryParamFilter } from '../../../utils/enums';

const HALF_DAY_IN_MS = 12 * 60 * 60 * 1000;

const useNetworkFilter = (
  page?: Page,
  initialOptions?: SDK.Network[]
): {
  networkFilterOptions: MultiSelectDropDownOption[];
  networkFilterOnChange: (options: MultiSelectDropDownOption[]) => void;
  isLoading: boolean;
} => {
  const dispatch = useDispatch();
  const project = useSelector((state: RootState) => state.project);
  const networks = useSelector((state: RootState) => initialOptions ?? state.filters.networks);

  // get the network query parameter
  const [networksQueryParam, setNetworksQueryParam] = useQueryParam(QueryParamFilter.network, ArrayParam);

  // The default state of the filter is "ALL" selected. The problem is that the "ALL" option is not
  // stored in the redux store, so we dont have an easy way to know whether to select it or not. We
  // use this ref to identify the first onChange, and until the ref value changes, we can assume
  // that we want the "ALL" option to be selected (assuming that there are no networks in the store,
  // in which case that takes precedence).
  const isInitialFilter = useRef<boolean>(false);

  // Fetch and update project networks
  const { isSuccess, isLoading } = useNetworks(
    { projectId: project.id },
    {
      onSuccess: (res) => {
        const fetchedNetworks: SDK.Network[] = res.networks.map((network) => network.network);
        dispatch(ProjectActions.changeNetworks(fetchedNetworks));
        if (networksQueryParam?.length) {
          dispatch(
            FilterActions.changeNetworks({
              value: networksQueryParam as SDK.Network[],
              page,
              logEvent: false,
            })
          );
          setNetworksQueryParam([]);
        }
        isInitialFilter.current = true;
      },
      cacheTime: HALF_DAY_IN_MS,
    }
  );

  // Create filter options
  const allOption = useMemo(
    () => ({
      label: 'All Networks',
      isAllOption: true,
      selected:
        (isInitialFilter.current && networks.length === 0) || networks.length === (project.networks ?? []).length,
    }),
    [networks, project.networks]
  );
  const networkFilterOptions = useMemo<MultiSelectDropDownOption[]>(
    () =>
      isSuccess && (project.networks ?? []).length > 0
        ? [
            allOption,
            ...(project.networks ?? []).map((network) => ({
              label: camelCaseToCapitalCase(network as string),
              selected: (isInitialFilter.current && networks.length === 0) || networks.includes(network),
            })),
          ]
        : [allOption],
    [isSuccess, networks, project.networks, allOption]
  );

  const networkFilterOnChange = useCallback(
    (options: MultiSelectDropDownOption[]) => {
      const selectedOptions = options
        .slice(1)
        .filter(({ selected }) => selected)
        .map(({ label }) => (label ?? '').toLowerCase() as SDK.Network);
      dispatch(FilterActions.changeNetworks({ value: selectedOptions, page, logEvent: !isInitialFilter.current }));
      isInitialFilter.current = false;
    },
    [dispatch, page]
  );

  return { networkFilterOptions, networkFilterOnChange, isLoading };
};

export default useNetworkFilter;
