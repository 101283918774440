import { CountryIconProps } from './types';

const SZ = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3764)">
      <path
        d="M22.8093 17.2165C23.5721 15.639 24 13.869 24 11.9992C24 10.1293 23.5721 8.35936 22.8093 6.78178L12 6.26001L1.19072 6.78173C0.427875 8.35936 0 10.1293 0 11.9992C0 13.869 0.427875 15.639 1.19072 17.2165L12 17.7383L22.8093 17.2165Z"
        fill="#FFDA44"
      />
      <path
        d="M11.9997 0.000305176C7.24218 0.000305176 3.13143 2.76898 1.19043 6.78293H22.8089C20.868 2.76898 16.7572 0.000305176 11.9997 0.000305176Z"
        fill="black"
      />
      <path
        d="M11.9997 23.9994C7.24218 23.9994 3.13143 21.2307 1.19043 17.2167H22.8089C20.868 21.2307 16.7572 23.9994 11.9997 23.9994Z"
        fill="#0052B4"
      />
      <path
        d="M23.4333 8.34784H0.566672C0.199078 9.49951 0 10.7265 0 12C0 13.2736 0.199078 14.5005 0.566672 15.6522H23.4333C23.8009 14.5005 24 13.2736 24 12C24 10.7265 23.8009 9.49951 23.4333 8.34784Z"
        fill="#A2001D"
      />
      <path
        d="M19.826 11.4791H4.17383V12.5226H19.826V11.4791Z"
        fill="#FFDA44"
      />
      <path
        d="M17.739 9.39117H6.26074V10.4347H17.739V9.39117Z"
        fill="#FFDA44"
      />
      <path
        d="M17.8133 11.9995C17.8133 11.9995 15.13 15.13 11.9996 15.13C8.86919 15.13 8.86914 11.9995 8.86914 11.9995C8.86914 11.9995 8.86914 8.86908 11.9996 8.86908C15.1301 8.86908 17.8133 11.9995 17.8133 11.9995Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9992 15.13C8.86877 15.13 6.18555 11.9995 6.18555 11.9995C6.18555 11.9995 8.86877 8.86908 11.9992 8.86908"
        fill="black"
      />
      <path
        d="M10.9566 10.957H9.91309V13.0439H10.9566V10.957Z"
        fill="#F0F0F0"
      />
      <path d="M14.0865 10.957H13.043V13.0439H14.0865V10.957Z" fill="black" />
      <path
        d="M4.17434 13.0445C4.75065 13.0445 5.21783 12.5773 5.21783 12.001C5.21783 11.4247 4.75065 10.9575 4.17434 10.9575C3.59804 10.9575 3.13086 11.4247 3.13086 12.001C3.13086 12.5773 3.59804 13.0445 4.17434 13.0445Z"
        fill="#0052B4"
      />
      <path
        d="M19.8267 13.0441C20.403 13.0441 20.8702 12.5769 20.8702 12.0006C20.8702 11.4243 20.403 10.9572 19.8267 10.9572C19.2504 10.9572 18.7832 11.4243 18.7832 12.0006C18.7832 12.5769 19.2504 13.0441 19.8267 13.0441Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3764">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SZ;
