import { Toggle } from '@replai-platform/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { FilterActions } from '../../store/filters';
import { RootState } from '../../store/rootReducer';
import { ActivityType } from '../../utils/enums';

const ActiveFilterToggle = (): JSX.Element => {
  const activityFilter = useSelector((state: RootState) => state.filters.activity);
  const dispatch = useDispatch();

  return (
    <div data-test="active-filter-toggle">
      <Toggle
        checked={activityFilter === ActivityType.active}
        label="Show only active videos"
        labelPosition="start"
        onChange={(checked: boolean) =>
          dispatch(
            FilterActions.changeActivity({
              logEvent: true,
              value: checked ? ActivityType.active : ActivityType.all,
            })
          )
        }
        variant="outlined"
      />
    </div>
  );
};

export default ActiveFilterToggle;
