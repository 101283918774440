import { IntegrationIconProps } from './types';

const JbTeamcity = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1334_1617)">
      <path
        d="M18.1493 21.8971L15.3608 6.35427C15.1779 5.25713 14.8122 4.15998 14.1265 3.10856C13.2122 1.6457 11.7493 0.639984 10.1036 0.228556C3.61221 -1.32573 -1.18779 5.1657 1.64649 10.9257L8.41221 25.4171C8.59506 25.8743 8.86935 26.3314 9.18935 26.7428C9.73792 27.4743 10.4693 28.0228 11.3379 28.2971C15.7265 29.6686 19.2465 25.7828 18.1493 21.8971Z"
        fill="url(#paint0_linear_1334_1617)"
      />
      <path
        d="M30.8108 12.1143C30.1708 11.1085 29.2565 10.3314 28.2051 9.87426L11.6565 0.777119C11.1994 0.548547 10.6965 0.319976 10.1479 0.182833C3.06221 -1.46288 -2.01207 6.30855 2.51364 12.3428C3.19936 13.2571 4.15936 13.9885 5.2565 14.4L21.9422 21.8971C22.3079 22.1257 22.6736 22.2628 23.0851 22.4C29.4851 24.4114 34.3308 17.6457 30.8108 12.1143Z"
        fill="url(#paint1_linear_1334_1617)"
      />
      <path
        d="M30.8106 12.1143C29.9878 10.8343 28.7078 9.91998 27.1992 9.55427C25.5992 9.18855 24.0906 9.3257 22.8106 9.87427L5.21063 16.5028C5.21063 16.5028 5.1192 16.5486 4.93634 16.6857C0.410628 18.4686 -1.82937 24.3657 1.82777 29.2571C2.65063 30.3543 3.79349 31.1771 5.07349 31.5428C7.49634 32.2743 9.69063 32 11.4735 31.04H11.5192L28.7078 21.8514C28.7078 21.8514 28.7078 21.8514 28.7535 21.8057C31.7706 20.0686 33.1878 15.8171 30.8106 12.1143Z"
        fill="url(#paint2_linear_1334_1617)"
      />
      <path
        d="M22.9945 5.85141C23.543 4.61712 23.4973 3.10855 22.583 1.73712C22.0802 0.914262 21.2573 0.365691 20.343 0.137119C18.2859 -0.365738 16.5487 0.594262 15.7259 2.05712L1.60017 19.2C1.60017 19.2 1.60017 19.2 1.60017 19.2457C-0.411257 21.8971 -0.731257 25.8285 1.82874 29.2571C2.6516 30.3543 3.79446 31.1771 5.07446 31.5428C9.87446 33.0514 13.8973 30.4 15.1773 26.6057L22.9945 5.85141Z"
        fill="url(#paint3_linear_1334_1617)"
      />
      <path d="M6.125 6.12567H25.8736V25.8742H6.125V6.12567Z" fill="black" />
      <path d="M8 22.1714H15.4057V23.4057H8V22.1714Z" fill="white" />
      <path
        d="M10.4686 10.3771H8V8.73138H14.7657V10.3771H12.2514V16.9142H10.4686V10.3771Z"
        fill="white"
      />
      <path
        d="M14.8555 12.8457C14.8555 10.5142 16.5926 8.59424 19.1069 8.59424C20.6612 8.59424 21.5755 9.0971 22.3526 9.87424L21.2098 11.2C20.5698 10.6057 19.9298 10.2857 19.1069 10.2857C17.7355 10.2857 16.7298 11.4285 16.7298 12.8457V12.8C16.7298 14.2171 17.6898 15.36 19.1069 15.36C20.0212 15.36 20.6155 14.9942 21.2555 14.4L22.3983 15.5428C21.5755 16.4571 20.6155 17.0057 19.0612 17.0057C16.6383 17.0514 14.8555 15.1771 14.8555 12.8457Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1334_1617"
        x1="0.811749"
        y1="14.2962"
        x2="18.3624"
        y2="14.2962"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#905CFB" />
        <stop offset="0.068" stopColor="#776CF9" />
        <stop offset="0.173" stopColor="#5681F7" />
        <stop offset="0.286" stopColor="#3B92F5" />
        <stop offset="0.41" stopColor="#269FF4" />
        <stop offset="0.547" stopColor="#17A9F3" />
        <stop offset="0.711" stopColor="#0FAEF2" />
        <stop offset="0.968" stopColor="#0CB0F2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1617"
        x1="2.42724"
        y1="4.42009"
        x2="31.6464"
        y2="20.053"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#905CFB" />
        <stop offset="0.068" stopColor="#776CF9" />
        <stop offset="0.173" stopColor="#5681F7" />
        <stop offset="0.286" stopColor="#3B92F5" />
        <stop offset="0.41" stopColor="#269FF4" />
        <stop offset="0.547" stopColor="#17A9F3" />
        <stop offset="0.711" stopColor="#0FAEF2" />
        <stop offset="0.968" stopColor="#0CB0F2" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_1617"
        x1="-8.81634"
        y1="32.3748"
        x2="25.5914"
        y2="15.1707"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3BEA62" />
        <stop offset="0.117" stopColor="#31DE80" />
        <stop offset="0.302" stopColor="#24CEA8" />
        <stop offset="0.484" stopColor="#1AC1C9" />
        <stop offset="0.659" stopColor="#12B7DF" />
        <stop offset="0.824" stopColor="#0EB2ED" />
        <stop offset="0.968" stopColor="#0CB0F2" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1334_1617"
        x1="17.8031"
        y1="2.67426"
        x2="2.47926"
        y2="35.4327"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3BEA62" />
        <stop offset="0.094" stopColor="#2FDB87" />
        <stop offset="0.196" stopColor="#24CEA8" />
        <stop offset="0.306" stopColor="#1BC3C3" />
        <stop offset="0.426" stopColor="#14BAD8" />
        <stop offset="0.56" stopColor="#10B5E7" />
        <stop offset="0.719" stopColor="#0DB1EF" />
        <stop offset="0.968" stopColor="#0CB0F2" />
      </linearGradient>
      <clipPath id="clip0_1334_1617">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default JbTeamcity;
