import * as SDK from '@replai-platform/sdk';
import { useQuery, UseQueryOptions } from 'react-query';
import * as provider from '../../api/provider';

const getAsset = async (assetId: SDK.UUID, requestParams: SDK.GetAssetRequest) => {
  const res = await provider.getJson<object, SDK.GetAssetResponse>(
    `${SDK.BASE_URL()}/assets/${assetId}?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useAsset(
  assetId: SDK.UUID,
  requestParams: SDK.GetAssetRequest,
  options?: UseQueryOptions<SDK.GetAssetResponse>
) {
  return useQuery(['asset', [assetId, requestParams]], () => getAsset(assetId, requestParams), options);
}
