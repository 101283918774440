import { IconProps } from './types';

const Watch = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 9.00002V12L13.5 13.5M16.51 17.35L16.16 21.18C16.1149 21.6787 15.8845 22.1423 15.5142 22.4792C15.1439 22.8162 14.6607 23.002 14.16 23H9.83C9.32933 23.002 8.84611 22.8162 8.4758 22.4792C8.1055 22.1423 7.87506 21.6787 7.83 21.18L7.48 17.35M7.49 6.65002L7.84 2.82002C7.88491 2.32309 8.11393 1.8609 8.48211 1.52417C8.8503 1.18744 9.33105 1.00049 9.83 1.00002H14.18C14.6807 0.997985 15.1639 1.18381 15.5342 1.52079C15.9045 1.85776 16.1349 2.32137 16.18 2.82002L16.53 6.65002M19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13402 8.13401 5.00002 12 5.00002C15.866 5.00002 19 8.13402 19 12Z"
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default Watch;
