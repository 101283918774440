import * as SDK from '@replai-platform/sdk';
import * as provider from './provider';

/**
 * Get asset metrics
 * @param {GetAssetsMetricsRequest} request request params
 * @param {UUID} assetId ID of the asset
 * @returns promise that fetches the data.
 * @deprecated Use the custom hook `useAssetsMetrics` instead.
 */
export function getAssetsMetrics(request: SDK.GetAssetsMetricsRequest): Promise<SDK.GetAssetsMetricsResponse> {
  return provider.getJson(`${SDK.BASE_URL()}/assets/metrics?${SDK.convertRequestToQueryParams(request)}`);
}
