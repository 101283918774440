import { PaymentIconProps } from './types';

const Lightcoin = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.3333 16C33.3333 21.891 28.5577 26.6666 22.6667 26.6666C16.7756 26.6666 12 21.891 12 16C12 10.1089 16.7756 5.33331 22.6667 5.33331C28.5577 5.33331 33.3333 10.1089 33.3333 16ZM19.7712 16.792L21.2749 11.1517H24.5338L23.4058 15.5387L24.91 14.9121L24.5338 16.2906L23.0296 16.792L22.2777 19.5495H27.6676L27.1661 21.5546H18.5173L19.395 18.1705L18.1417 18.6719L18.5173 17.2934L19.7712 16.792Z"
      fill="#A5A8A9"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default Lightcoin;
