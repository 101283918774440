import { CountryIconProps } from './types';

const NP = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3677)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.9292 13.3041C23.9292 13.304 23.9293 13.3039 23.9293 13.3037L10.7693 0.0621338C10.1757 0.122649 9.59475 0.226431 9.02986 0.37029C3.83897 1.69193 0 11.9997 0 11.9997C0 11.9997 16.7999 23.242 18.7165 21.9449C19.1 21.6854 19.4677 21.4043 19.8177 21.1034L12.0185 13.3041H23.9292Z"
        fill="#0052B4"
      />
      <path
        d="M20.8695 11.9995L9.19899 0.328979C3.92288 1.59071 0 6.33662 0 11.9995C0 18.6269 5.37263 23.9995 12 23.9995C14.5139 23.9995 16.8467 23.226 18.7748 21.9047L8.86955 11.9995H20.8695Z"
        fill="#D80027"
      />
      <path
        d="M11.4126 17.7174L9.94704 17.028L10.7275 15.6087L9.13605 15.9131L8.9344 14.3054L7.82595 15.4878L6.71749 14.3054L6.51579 15.9131L4.92443 15.6086L5.70485 17.028L4.23926 17.7174L5.70485 18.4067L4.92443 19.826L6.51588 19.5216L6.71745 21.1292L7.82595 19.9468L8.9344 21.1292L9.13605 19.5216L10.7274 19.8261L9.94699 18.4067L11.4126 17.7174Z"
        fill="#F0F0F0"
      />
      <path
        d="M10.4342 6.6549L9.36828 6.15357L9.93589 5.12124L8.77841 5.34268L8.63174 4.17334L7.82553 5.03335L7.01933 4.17334L6.87261 5.34268L5.71512 5.12124L6.28278 6.15357L5.2168 6.6549L7.82553 7.17671L10.4342 6.6549Z"
        fill="#F0F0F0"
      />
      <path
        d="M10.9572 6.65527C10.9572 8.38416 9.55563 9.78572 7.82674 9.78572C6.09785 9.78572 4.69629 8.38416 4.69629 6.65527"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3677">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default NP;
