import styled from 'styled-components';
import Typography from '../Typography/Typography';
import Colors from '../Colors';

export const getHeight = ({ wrapped }: { wrapped: boolean }) => {
  if (wrapped) {
    return '24.0625rem';
  }

  return '8.725rem';
};

const getWidth = ({ wrapped }: { wrapped: boolean }) => {
  if (wrapped) {
    return '16.7rem';
  }

  return '25.6rem';
};

export const MainContainer = styled.div<{
  $wrapped: boolean;
}>`
  min-width: ${({ $wrapped }) => getWidth({ wrapped: $wrapped })};
  max-width: ${({ $wrapped }) => ($wrapped ? '23.25rem' : 'unset')};
  overflow: hidden;
  border: 1px solid ${Colors.Gray[200]};
  border-radius: 0.5rem;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  transition: 0.2s background-color ease-in-out;
  .react-loading-skeleton {
    line-height: unset;
  }
  :hover {
    background-color: ${Colors.Gray[50]};
    cursor: pointer;
  }
`;

export const Container = styled.div<{
  $wrapped: boolean;
}>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: ${({ $wrapped }) => ($wrapped ? 'column' : 'row')};
  height: ${({ $wrapped }) => getHeight({ wrapped: $wrapped })};
  overflow: hidden;
`;

export const RightContainer = styled.div<{ $wrapped: boolean }>`
  display: flex;
  flex: ${({ $wrapped }) => ($wrapped ? 0.55 : 1.689)};
  flex-direction: column;
  gap: 12px;
  padding: 1rem;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

export const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  gap: 0.5rem;
  align-items: center;
  padding: 0.625rem 1rem;
  border-top: 1px solid ${Colors.Gray[200]};
`;

export const ReplaiScoreContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const TypeValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const FirstSecondsContainer = styled.div`
  display: inline-block;
  display: flex;
  align-items: flex-start;
`;

export const TypographyFirstSeconds = styled(Typography)`
  display: inline-block;
  margin: 0;
`;

export const StyledTypography = styled(Typography)<{ $maxLines: number }>`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  width: 100%;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ $maxLines }) => $maxLines};
`;

export const DescriptionContainer = styled.div`
  display: flex;
`;

export const SpendAndFrequencyContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  .react-loading-skeleton {
    line-height: unset;
  }
`;

export const IconSpan = styled.span`
  display: inline-block;
  height: 1.5rem;
`;

export const EllipsisTypography = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TooltipContainer = styled.div`
  width: 25rem;
  text-align: center;

  > * {
    display: block;
  }
`;

export const TooltipLink = styled(Typography)`
  margin: 0;
  text-decoration: underline;
`;
