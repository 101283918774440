import { IntegrationIconProps } from './types';

const Jira = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.6647 15.2165L17.2075 3.1679L16 2L6.62269 11.0697L2.33526 15.2165C1.88825 15.6494 1.88825 16.3506 2.33526 16.7835L10.9025 25.0697L16 30L25.3773 20.9303L25.5225 20.7899L29.6647 16.7835C30.1118 16.3506 30.1118 15.6494 29.6647 15.2165ZM16 20.1394L11.7202 16L16 11.8606L20.2798 16L16 20.1394Z"
      fill="#2684FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9999 11.8606C13.1979 9.15007 13.1842 4.75994 15.9694 2.0332L6.60352 11.0881L11.701 16.0184L15.9999 11.8606Z"
      fill="url(#paint0_linear_1334_1136)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2912 15.9889L16 20.1394C17.3525 21.4467 18.1124 23.2203 18.1124 25.0697C18.1124 26.9191 17.3525 28.6926 16 30L25.3888 20.9192L20.2912 15.9889Z"
      fill="url(#paint1_linear_1334_1136)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_1136"
        x1="15.2357"
        y1="7.67312"
        x2="8.09646"
        y2="10.7902"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1136"
        x1="16.8177"
        y1="24.2788"
        x2="23.9441"
        y2="21.1838"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default Jira;
