import { CountryIconProps } from './types';

const BH = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3567)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9997 -0.000976562C9.59141 -0.000976562 7.34928 0.708945 5.46997 1.93018L8.55617 3.99873L4.69531 5.9987L8.55617 7.99866L4.69531 9.99863L8.55617 11.9984L4.69531 13.9983L8.55617 15.9982L4.69531 17.9984L8.55617 19.9983L5.46959 22.0675C7.349 23.289 9.59127 23.999 11.9997 23.999C18.627 23.999 23.9997 18.6264 23.9997 11.999C23.9997 5.37165 18.627 -0.000976562 11.9997 -0.000976562Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3567">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default BH;
