import styled from 'styled-components';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const MetricsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
`;

export const MetricsCardWrapper = styled.div`
  flex: 2;
  min-width: 400px;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  > [aria-hidden='true']:last-child {
    z-index: 101;
    display: none;
    width: auto;
  }
`;

export const CardHeaderRight = styled.div`
  > :last-child {
    z-index: 10;
    width: auto;
  }
`;

export const CardChart = styled.div`
  width: 100%;
  height: 300px;
`;
