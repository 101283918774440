import { camelCaseToCapitalCase, TreeSelectDropDown } from '@replai-platform/ui-components';
import * as SDK from '@replai-platform/sdk';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from '@material-ui/core';
import { FilterActions } from '../../../store/filters';
import type { RootState } from '../../../store/rootReducer';

type DropdownOption = { id: string | null; type: string | null; value: string | null; selected: boolean };

export interface CustomMarketTagsFilterProps {
  options: DropdownOption[];
  onChange: (params: string[]) => void;
  loading: boolean;
}

function serializeEntry(entry: DropdownOption) {
  return JSON.stringify({ id: entry.id, type: entry.type, value: entry.value });
}

const CustomMarketTagsFilter: React.VFC<CustomMarketTagsFilterProps> = ({ options, onChange, loading }) => {
  const dispatch = useDispatch();
  const marketTags = useSelector((state: RootState) => state.filters.marketTags);

  const { data, selected } = useMemo(
    () => ({
      data: options
        .reduce((acc, curr) => {
          const idx = acc.findIndex(({ type }) => type === curr.type);
          const element = {
            key: serializeEntry(curr),
            title: curr.value ? capitalize(curr.value) : SDK.messages.NOT_AVAILABLE,
          };
          if (idx >= 0) {
            acc[idx].children.push(element);
          } else {
            acc.push({ type: curr.type || SDK.messages.NOT_AVAILABLE, children: [element] });
          }
          return acc;
        }, [] as { type: string; children: { key: string; title: string }[] }[])
        .map((opt) => ({
          key: JSON.stringify(opt.type),
          title: camelCaseToCapitalCase(opt.type || ''),
          children: opt.children,
        })),
      selected: options.filter((opt) => opt.selected).map((opt) => serializeEntry(opt)),
    }),
    [options]
  );

  const chipLabel = useMemo(() => {
    switch (marketTags.length) {
      case 0:
        return 'Select Market Tags';
      case 1:
        return `${marketTags.length} tag selected`;
      default:
        return `${marketTags.length} tags selected`;
    }
  }, [marketTags.length]);

  return (
    <TreeSelectDropDown
      data={data}
      label={chipLabel}
      loading={loading}
      searchable
      onChangeSelectedKeys={onChange}
      onClearClick={() => {
        dispatch(
          FilterActions.changeMarketTags({
            value: [],
            logEvent: true,
          })
        );
      }}
      selectedKeys={selected}
    />
  );
};

export default CustomMarketTagsFilter;
