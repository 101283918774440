import format from 'date-fns/format';
import ActiveIndicator from '../ActiveIndicator/ActiveIndicator';
import Colors from '../Colors';
import { BaseIcons } from '../Icons';
import Typography from '../Typography/Typography';
import * as Styled from './styles';

type Item = {
  id: string;
  name: string;
  isActive: boolean;
  spend?: string;
  launchDate?: string;
};

export type VideoSidebarProps = {
  items: Item[];
  onClickEyeIcon?: (item: Item) => void;
};

const VideoSidebar = ({ items, onClickEyeIcon }: VideoSidebarProps) => {
  return (
    <Styled.VerticalMenuContainer>
      {items.map((item) => (
        <Styled.VideoInfoContainer key={item.id}>
          <Styled.NameContainer>
            <ActiveIndicator
              color={item.isActive ? 'Success' : 'Error'}
              style={{ display: 'inline-block', minWidth: '6px' }}
            />
            <Styled.Label>
              <Typography
                noMargin
                type="text-sm"
                fontWeight="medium"
                color={Colors.Gray[700]}
              >
                {item.name}
              </Typography>
            </Styled.Label>
            <Styled.IconContainer
              onClick={() => onClickEyeIcon?.(item)}
              aria-label="Show Video"
              role="button"
            >
              <BaseIcons.Eye color={Colors.Gray[500]} dimension={18} />
            </Styled.IconContainer>
          </Styled.NameContainer>
          <Styled.PaddingLeftContainer>
            {item.spend ? (
              <Styled.MetricLine>
                <Typography noMargin type="text-xs" color={Colors.Gray[500]}>
                  Total Spend
                </Typography>
                <Typography
                  noMargin
                  type="text-xs"
                  fontWeight="medium"
                  color={Colors.Gray[700]}
                >
                  {item.spend}
                </Typography>
              </Styled.MetricLine>
            ) : undefined}
            {item.launchDate ? (
              <Styled.MetricLine>
                <Typography noMargin type="text-xs" color={Colors.Gray[500]}>
                  Launch Date
                </Typography>
                <Typography
                  noMargin
                  type="text-xs"
                  fontWeight="medium"
                  color={Colors.Gray[700]}
                >
                  {format(new Date(item.launchDate), 'yyyy-MM-dd') ?? ''}
                </Typography>
              </Styled.MetricLine>
            ) : undefined}
          </Styled.PaddingLeftContainer>
        </Styled.VideoInfoContainer>
      ))}
    </Styled.VerticalMenuContainer>
  );
};

export default VideoSidebar;
