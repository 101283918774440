import { PaymentIconProps } from './types';

const BitcoinCash = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.266 10.3825C35.2161 15.6085 33.3713 22.2365 28.1455 25.1865C22.9196 28.1365 16.2917 26.2913 13.3416 21.0652C10.3915 15.8391 12.2364 9.21112 17.4622 6.26117C22.688 3.31121 29.3159 5.15637 32.266 10.3825ZM23.0925 10.9249C24.6639 10.6339 26.0088 10.7672 26.5309 12.1334C26.9144 13.1333 26.653 13.869 26.0593 14.4101C27.2687 14.3672 28.1072 15.108 28.4394 16.3885C28.7814 18.3082 27.5205 19.1978 25.5531 19.8668L26.0935 21.8328L24.9048 22.1621L24.3715 20.2225C24.0635 20.3082 23.7469 20.3926 23.4193 20.4769L23.9555 22.4257L22.768 22.7544L22.2251 20.7851L19.8275 21.4426L19.6741 19.9611C19.6741 19.9611 20.5573 19.7301 20.5382 19.7214C20.8745 19.6275 20.8976 19.3617 20.8774 19.2054L19.4088 13.8703C19.2973 13.6422 19.0637 13.4074 18.5819 13.5405C18.5916 13.5202 17.7188 13.7804 17.7188 13.7804L17.3699 12.5145L19.7655 11.8465L19.2299 9.89878L20.418 9.56982L20.9434 11.4788C21.2595 11.3838 21.5775 11.2894 21.8897 11.2028L21.3673 9.30622L22.556 8.97692L23.0925 10.9249ZM22.205 15.1792C23.0213 14.9666 24.7977 14.504 24.4607 13.2779C24.1151 12.0238 22.4266 12.5393 21.5998 12.7917C21.5061 12.8203 21.4235 12.8455 21.3552 12.8644L22.007 15.2318C22.0633 15.2161 22.1301 15.1987 22.205 15.1792ZM23.2857 18.973C24.2626 18.717 26.3993 18.1569 26.0271 16.8063C25.6469 15.4251 23.6159 16.04 22.6249 16.3401C22.5142 16.3736 22.4165 16.4032 22.3355 16.4256L23.0534 19.035C23.1196 19.0165 23.1978 18.9961 23.2853 18.9731L23.2854 18.9731L23.2857 18.973Z"
      fill="#8FC258"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default BitcoinCash;
