import { CountryIconProps } from './types';

const GY = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3636)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9997 -0.000976562C9.87813 -0.000976562 7.88556 0.550367 6.15625 1.51646V22.4816C7.88561 23.4477 9.87813 23.999 11.9997 23.999C18.6271 23.999 23.9997 18.6264 23.9997 11.999C23.9997 5.37165 18.6271 -0.000976562 11.9997 -0.000976562Z"
        fill="#6DA544"
      />
      <path
        d="M6.19178 1.49817C5.43686 1.91653 4.73195 2.41378 4.08794 2.97909C4.15305 2.922 4.21881 2.86556 4.28509 2.80987L13.4756 12.0005L4.28505 21.1911C4.21872 21.1354 4.153 21.0789 4.08789 21.0218C4.73191 21.5872 5.43686 22.0844 6.19173 22.5028L24 12.0005L6.19178 1.49817Z"
        fill="#F0F0F0"
      />
      <path
        d="M5.17837 2.12695C4.81241 2.38036 4.46095 2.65303 4.12602 2.94445L12.0001 11.9997L4.12598 21.0549C4.4609 21.3463 4.81237 21.619 5.17832 21.8724L21.9459 11.9997L5.17837 2.12695Z"
        fill="#FFDA44"
      />
      <path
        d="M4.28504 2.80981C4.01827 3.03397 3.76084 3.26896 3.51465 3.5151L10.9565 12.0004L3.51465 20.4857C3.76084 20.7319 4.01823 20.9669 4.28504 21.191L13.4756 12.0004L4.28504 2.80981Z"
        fill="black"
      />
      <path
        d="M3.51471 3.51428C-1.17157 8.20056 -1.17157 15.7986 3.51471 20.4849C5.45126 18.5484 7.31375 16.6859 12 11.9996L3.51471 3.51428Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3636">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default GY;
