import { IntegrationIconProps } from './types';

const Amazon = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2C8.27812 2 2 8.27812 2 16C2 23.7219 8.27812 30 16 30C23.7219 30 30 23.7219 30 16C30 8.27812 23.7219 2 16 2Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8099 18.038C20.7688 17.979 20.7274 17.9213 20.6862 17.8639C20.3513 17.3971 20.0322 16.9521 20.0322 16.0603V12.7356C20.0322 12.62 20.0328 12.5052 20.0335 12.3913C20.0408 11.1166 20.0474 9.95337 19.1281 9.06351C18.3397 8.27737 17.0301 8 16.0286 8C14.0698 8 11.8846 8.75761 11.4264 11.2681C11.3779 11.5348 11.5646 11.6743 11.734 11.7136L13.7285 11.9374C13.9153 11.9276 14.0502 11.7372 14.0863 11.5455C14.2574 10.6804 14.956 10.2648 15.741 10.2648C16.1643 10.2648 16.6451 10.4253 16.8956 10.8191C17.154 11.2117 17.1499 11.7359 17.1462 12.2047C17.1458 12.2593 17.1454 12.3131 17.1454 12.3659V12.6446C16.9603 12.6661 16.7664 12.6864 16.5667 12.7073C15.4789 12.8214 14.2185 12.9535 13.2746 13.3826C11.9859 13.9614 11.0809 15.1391 11.0809 16.8701C11.0809 19.0885 12.4275 20.1975 14.1613 20.1975C15.6244 20.1975 16.4251 19.8394 17.5539 18.6462C17.6103 18.7309 17.6609 18.809 17.7087 18.8826C17.9779 19.2978 18.1537 19.5687 18.7337 20.0691C18.8868 20.1538 19.0894 20.1506 19.2251 20.0231C19.6361 19.6441 20.3824 18.9717 20.8031 18.6061C20.9712 18.4629 20.9414 18.2315 20.8099 18.038ZM16.7604 17.0792C16.4337 17.6812 15.9133 18.0495 15.3351 18.0495C14.5467 18.0495 14.0838 17.4253 14.0838 16.5018C14.0838 14.6838 15.6572 14.3538 17.145 14.3538C17.145 14.4626 17.146 14.572 17.1469 14.6816C17.1542 15.5046 17.1617 16.3439 16.7604 17.0792Z"
      fill="white"
    />
    <path
      d="M23.2699 21.6659C21.2997 23.1894 18.4425 24 15.9818 24C12.5345 24 9.42926 22.6645 7.07958 20.4412C6.89528 20.2664 7.05978 20.0277 7.28121 20.1634C9.81666 21.7097 12.9516 22.6405 16.1893 22.6405C18.3738 22.6405 20.7746 22.1658 22.9836 21.1832C23.3168 21.0355 23.5961 21.4139 23.2699 21.6659Z"
      fill="white"
    />
    <path
      d="M21.7891 20.6047C22.4234 20.5244 23.8379 20.3467 24.0897 20.6845C24.3419 21.022 23.8123 22.4099 23.5736 23.0355L23.5719 23.04C23.5001 23.2287 23.6545 23.304 23.8172 23.161C24.8745 22.2346 25.1477 20.2928 24.931 20.0114C24.7161 19.7339 22.8677 19.4936 21.7398 20.3239C21.5664 20.4527 21.5961 20.6282 21.7891 20.6047Z"
      fill="white"
    />
  </svg>
);
export default Amazon;
