import { CountryIconProps } from './types';

const MH = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 9.98611 23.5031 8.08847 22.6265 6.42188L11.9859 12.0141L17.5781 1.37353C15.9115 0.496875 14.0139 0 12 0C5.37262 0 0 5.37262 0 12C0 15.3137 1.34316 18.3137 3.51469 20.4853C5.68627 22.6569 8.68627 24 12 24Z"
      fill="#0052B4"
    />
    <path
      d="M22.6141 6.43435C22.0648 5.38885 21.3507 4.40724 20.4716 3.52815C19.5925 2.64906 18.611 1.93492 17.5654 1.38568L3.50098 20.4987L22.6141 6.43435Z"
      fill="#F0F0F0"
    />
    <path
      d="M3.50098 20.4994L22.6141 6.43501C22.0648 5.38951 21.3507 4.4079 20.4716 3.52881L3.50098 20.4994Z"
      fill="#FF9811"
    />
    <path
      d="M10.4204 7.03688L8.95485 7.72622L9.73527 9.1456L8.14387 8.84114L7.94221 10.4488L6.83371 9.26635L5.72521 10.4488L5.52365 8.84114L3.93224 9.1455L4.71257 7.72617L3.24707 7.03688L4.71262 6.34749L3.93224 4.9282L5.52355 5.23256L5.72526 3.62494L6.83371 4.80736L7.94221 3.62494L8.14387 5.23256L9.73532 4.9282L8.9549 6.34753L10.4204 7.03688Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default MH;
