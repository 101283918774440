import * as SDK from '@replai-platform/sdk';
import { QueryFunction, UseQueryOptions } from 'react-query';
import * as provider from '../../api/provider';

const getTagInsightsMetrics = async (requestParams: SDK.GetTagInsightsMetricsRequest) => {
  const res = await provider.getJson<object, SDK.GetTagInsightsMetricsResponse>(
    `${SDK.BASE_URL()}/tags/insights/metrics?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

const KEY = 'tag-insights-metrics';
type Key = typeof KEY;

type Result = SDK.GetTagInsightsMetricsResponse;
type QueryKey = [Key, SDK.GetTagInsightsMetricsRequest];

type QueryOptions<R = Result> = UseQueryOptions<Result, unknown, R, QueryKey>;

const queryFn: QueryFunction<Result, QueryKey> = ({ queryKey: [, requestParams] }) =>
  getTagInsightsMetrics(requestParams);

// This is exported so we can use useQueries
export function getTagInsightsMetricsQueryOptions<R = Result>(
  requestParams: SDK.GetTagInsightsMetricsRequest,
  options?: QueryOptions<R>
): QueryOptions<R> {
  return {
    queryKey: [KEY, requestParams],
    queryFn,
    ...options,
  };
}
