import * as SDK from '@replai-platform/sdk';
import { useQuery, UseQueryOptions } from 'react-query';
import * as provider from '../../api/provider';

const getMarketApps = async (request: SDK.GetMarketAppsRequest): Promise<SDK.GetMarketAppsResponse> =>
  provider.getJson(`${SDK.BASE_URL()}/market/apps?${SDK.convertRequestToQueryParams(request as object)}`);

export default function useMarketApps(
  requestParams: SDK.GetMarketAppsRequest,
  options?: UseQueryOptions<SDK.GetMarketAppsResponse>
) {
  return useQuery(['market.useMarketApps', requestParams], () => getMarketApps(requestParams), options);
}
