import * as SDK from '@replai-platform/sdk';
import { Button } from '@replai-platform/ui-components';
import { useState } from 'react';
import { logEvent } from '../../analytics';
import TextImageToVideoModal from '../../routes/CreativeProduction/components/TextImageToVideoModal';

type AssetVariationButtonProps = {
  assetId: string;
  assetType?: SDK.AssetType;
  buttonLabel?: string;
};

const AssetVariationButton = ({ assetId, buttonLabel, assetType }: AssetVariationButtonProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ width: '100%' }} aria-label="Clickable Avatar">
      <Button
        style={{ width: '100%' }}
        color="Purple"
        leadingIcon={{ name: 'Sparkles' }}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
          logEvent({
            component: 'AssetVariationButton',
            action: 'click_generate_asset_variation',
            category: 'user_actions',
          });
        }}
      >
        {buttonLabel ?? 'Generate variation'}
      </Button>
      <TextImageToVideoModal
        modalOpen={open}
        setModalOpen={setOpen}
        baseAssetId={assetId}
        title={`Generate a new creative based on this ${assetType === SDK.AssetType.Image ? 'static' : 'video'}`}
      />
    </div>
  );
};

export default AssetVariationButton;
