import * as SDK from '@replai-platform/sdk';
import { useMutation } from 'react-query';
import * as provider from '../../api/provider';

export type GenerateImageRequestParamsAndBody = {
  requestParams: SDK.GenerateImageRequest;
  body: SDK.GenerateImageRequestBody;
};

const generateImage = async (requestParams: SDK.GenerateImageRequest, body: SDK.GenerateImageRequestBody) => {
  const url = `${SDK.BASE_URL()}/inspire/image?${SDK.convertRequestToQueryParams(requestParams)}`;
  const res = await provider.getJson<object, SDK.GenerateImageResponse>(url, {
    method: 'POST',
    body,
  });
  return res;
};

export default function useGenerateImage() {
  return useMutation([`inspire-generate-image`], (requestParamsAndBody: GenerateImageRequestParamsAndBody) =>
    generateImage(requestParamsAndBody.requestParams, requestParamsAndBody.body)
  );
}
