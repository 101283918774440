import { IntegrationIconProps } from './types';

const StackOverflow = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2C8.27812 2 2 8.27812 2 16C2 23.7219 8.27812 30 16 30C23.7219 30 30 23.7219 30 16C30 8.27812 23.7219 2 16 2Z"
      fill="#F48024"
    />
    <path
      d="M19.5405 18.3627L12.1082 17.2884L12.3658 15.6605L19.6337 17.0582L19.5405 18.3627Z"
      fill="white"
    />
    <path
      d="M19.672 16.6307L13.0948 13.5613L13.6977 12.2458L20.2749 15.3152L19.672 16.6307Z"
      fill="white"
    />
    <path
      d="M20.549 14.9864L14.9583 10.3275L15.8901 9.23128L21.4808 13.8902L20.549 14.9864Z"
      fill="white"
    />
    <path
      d="M17.37 7.80621L18.521 6.92926L22.851 12.7391L21.7 13.6161L17.37 7.80621Z"
      fill="white"
    />
    <path d="M19.5624 20.5222H11.889V18.8779H19.5624V20.5222Z" fill="white" />
    <path
      d="M20.6586 17.2335V21.6183H10.7927V17.2335H9.14844V23.2626H22.3029V17.2335H20.6586Z"
      fill="white"
    />
  </svg>
);
export default StackOverflow;
