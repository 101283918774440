import { QueryClient, useMutation, useQueryClient } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const deleteReport = async (
  request: SDK.DeleteReportRequest,
  queryClient: QueryClient
): Promise<SDK.DeleteReportResponse> => {
  const url = `${SDK.BASE_URL()}/reports/delete?${SDK.convertRequestToQueryParams(request)}`;
  const res = await provider.getJson<object, SDK.DeleteReportResponse>(url, { method: 'DELETE', body: {} });

  await queryClient.invalidateQueries('getReports');
  await queryClient.invalidateQueries('getReportsTemplates');

  return res;
};

export default function useDeleteReport() {
  const queryClient = useQueryClient();
  return useMutation(['delete-report'], (request: SDK.DeleteReportRequest) => deleteReport(request, queryClient));
}
