import styled from 'styled-components';
import Typography from '../Typography/Typography';

export const Container = styled.div<{
  backgroundColor: string;
  borderColor: string;
}>`
  left: 16px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  box-sizing: border-box;
  max-height: 64px;
  padding: 14px 16px;
  background: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 8px;
`;

export const RightAlignedButtonGroup = styled.div`
  display: flex;
  margin-left: auto;
`;

export const Message = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
