import styled from 'styled-components';
import Typography from '../../../Typography/Typography';

export const TypographyClean = styled(Typography)`
  margin: 0;
  padding: 0;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
  > [aria-hidden='true']:last-child {
    z-index: 101;
    width: auto;
  }
`;

export const CardHeaderRight = styled.div`
  > :last-child {
    z-index: 10;
    width: auto;
  }
`;

export const CardChart = styled.div`
  width: 100%;
  height: 300px;
`;
