import styled, { css } from 'styled-components';
import Colors from '../Colors';

export const DatePicker = styled.div`
  &.leftPicker,
  &.rightPicker {
    position: static;
    top: 0px;
    left: 329px;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
    order: 2;
    width: 328px;
    height: 376px;
    margin: 0px 0px;
    padding: 0px;
  }

  &.leftPicker {
    border-right: 0.5px solid ${Colors.Gray[200]};
    border-radius: 4px 0px 0px 4px;
  }

  &.rightPicker {
    border-left: 0.5px solid ${Colors.Gray[200]};
    border-radius: 0px 4px 4px 0px;
  }
`;

export const Root = styled.div`
  position: static;
  top: 0px;
  left: 193px;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-grow: 0;
  align-items: flex-start;
  order: 2;
  width: 657px;
  height: 452px;
  margin: 0px 0px;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
`;

export const DatePickers = styled.div`
  position: static;
  top: 0px;
  left: 0px;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-grow: 0;
  align-items: flex-start;
  order: 0;
  width: 657px;
  height: 376px;
  margin: 0px 0px;
  padding: 0px;
  border-bottom: 2px solid ${Colors.Gray[200]};
`;

export const BottomPanel = styled.div`
  position: static;
  top: 397px;
  left: 0px;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-grow: 0;
  align-items: flex-start;
  align-self: stretch;
  justify-content: flex-start;
  order: 2;
  width: max-content;
  height: max-content;
  margin: 0px 0px;
  padding: 16px 24px;

  > button {
    margin: 0 6px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }
`;

export const Container = styled.div`
  margin: 12px 0px;

  @media screen and (min-width: 992px) {
    margin: 12px 0px;
  }
`;

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 12px;
`;

export const CalendarButton = styled.button`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 6px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  @media screen and (min-width: 992px) {
    padding: 0 8px;
  }
`;

export const CalendarInfo = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: ${Colors.Gray[700]};
  font-weight: 500;
  font-size: 14px;

  @media screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

export const Week = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 40px);
  justify-items: center;
  margin: 4px 0;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  color: ${Colors.Gray[700]};
  font-size: 14px;
  font-weight: 500;
`;

export const DayContainer = styled.div<{
  $isInRange?: boolean;
  $isToday?: boolean;
  $isLast?: boolean;
  $isFirst?: boolean;
}>`
  ${(props) => (props.$isInRange ? InRangeMixin : '')}
  ${(props) => (props.$isToday ? TodayMixin : '')}
  ${(props) => (props.$isLast ? LastInRangeMixin : '')}
  ${(props) => (props.$isFirst ? FirstInRangeMixin : '')}
`;

export const Day = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  font-size: 14px;
  background: transparent;
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover:enabled {
    color: ${Colors.Primary[600]};
    background-color: ${Colors.Primary[50]};
  }

  &.selected:hover:enabled {
    color: ${Colors.Common.White};
    background-color: ${Colors.Primary[600]};
  }

  &:hover:disabled {
    cursor: default;
  }

  &.dayInRange {
    color: ${Colors.Primary[700]};
  }

  &.current {
    color: ${Colors.Gray[700]};
  }

  &.previous,
  &.next {
    color: ${Colors.Gray[500]};
  }

  &.selected,
  &.selected:hover {
    color: ${Colors.Common.White};
    background-color: ${Colors.Primary[600]};
  }

  &.selected.dayInRange,
  &.selectedOther.dayInRange {
    color: ${Colors.Common.White};
  }

  &.selectedOther,
  &.selectedOther:hover {
    color: ${Colors.Common.White};
    background-color: ${Colors.Primary[300]};
  }
`;

/*  Mixins  */

const InRangeMixin = css`
  background-color: ${Colors.Primary[50]};
`;

const TodayMixin = css`
  background-color: ${Colors.Gray[100]};
  border-radius: 20px;
`;

const LastInRangeMixin = css`
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const FirstInRangeMixin = css`
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`;
