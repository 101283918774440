import styled, { css } from 'styled-components';

export const Root = styled.div<{
  $width?: string;
  $height?: string;
  $backgroundColor?: string;
  $style?: string;
}>`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  background-color: ${(props) => props.$backgroundColor};
  border-radius: 50%;

  &&& {
    ${(props) =>
      css`
        ${props.$style}
      `}
  }
`;
