import { API_BASE_URL, DEV_API_BASE_URL } from '../../url';

export const BASE_URL = (localCall = false) => {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test' ||
    process.env.REACT_APP_REPLAI_DEV_ENV
  ) {
    if (
      localCall ||
      process.env.REACT_APP_CORE_API_PIPELINE === 'true' ||
      process.env.REACT_APP_FULL_LOCAL_CALLS === 'true'
    ) {
      return 'http://localhost:3000/dev';
    }
    return DEV_API_BASE_URL;
  }
  return API_BASE_URL;
};
