import { CountryIconProps } from './types';

const ZA = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3705)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.51471 20.4853C-1.17157 15.7989 -1.17157 8.20098 3.51471 3.51465C3.51438 3.51516 10.4348 12 10.4348 12L3.51471 20.4853Z"
        fill="black"
      />
      <path
        d="M10.4351 12.0002L1.59187 6.02429C1.3177 6.50087 1.07591 6.99831 0.868164 7.51318L5.34515 12.0003L0.868305 16.4876C1.07587 17.0021 1.31741 17.4992 1.5914 17.9755L10.4351 12.0002Z"
        fill="#FFDA44"
      />
      <path
        d="M23.8982 10.4347H10.4342L3.51434 3.51477C2.76865 4.26046 2.12079 5.10379 1.59129 6.02399L7.55562 11.9999L1.59082 17.975C2.12032 18.8955 2.76846 19.7391 3.51434 20.485L10.4342 13.5651H23.8982C23.9649 13.0528 23.9997 12.5304 23.9997 11.9999C23.9997 11.4694 23.9649 10.947 23.8982 10.4347Z"
        fill="#6DA544"
      />
      <path
        d="M4.69336 21.5181C6.71691 23.0735 9.24989 23.9988 11.9994 23.9988C17.5437 23.9988 22.209 20.2385 23.586 15.1293H11.0822L4.69336 21.5181Z"
        fill="#0052B4"
      />
      <path
        d="M23.586 8.86869C22.209 3.7595 17.5437 -0.000854492 11.9994 -0.000854492C9.24989 -0.000854492 6.71691 0.924505 4.69336 2.47991L11.0821 8.86869H23.586Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3705">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ZA;
