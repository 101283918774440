/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as SDK from '@replai-platform/sdk';
import { kpiUtils } from '@replai-platform/sdk';
import { Card, LoadingScreen, MetricsSection, SelectInput, Skeleton } from '@replai-platform/ui-components';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTitle } from 'react-use';
import { logEvent } from '../../../../../analytics';
import { api } from '../../../../../api';
import useMetricsPerformance from '../../../../../api/hooks/metrics/useMetricsPerformance';
import Preview, { PreviewData } from '../../../../../components/TagPreviews';
import { FiltersState } from '../../../../../store/filters';
import { RootState } from '../../../../../store/rootReducer';
import buildKpiDropdownFilterProps from '../../../../../utils/buildKpiDropdownFilterProps';
import { calculateDelta, deltaToGoodness, deltaToStatus } from '../../../../../utils/calculateDelta';
import { RouteAnimator } from '../../../../RouteAnimator';
import ContextPerformance from './ContextPerformance';
import FatigueTrend from './FatigueTrend';
import ImpressionClicksChart from './ImpressionClicksChart';
import * as Styled from './styles';
import TagTotalMetricChart from './TagTotalMetricChart';

type LoadingStatus = {
  tagLoading: boolean;
  metricsLoading: boolean;
  projectMetrics: boolean;
};

const getTotalTrendParams = ({
  projectId,
  kpis,
  filters,
}: {
  projectId: SDK.UUID;
  kpis: SDK.MetricKPIWithSpend[];
  filters: FiltersState;
}) => ({
  projectIds: [projectId],
  metrics: kpis,
  adsFilters: api.filterConverter.getAdsFilters(filters),
  assetFilters: api.filterConverter.getAssetFilters(filters),
  metricsFilters: {
    ...api.filterConverter.getMetricsFilters({
      ...filters,
    }),
  },
});

const TagMetrics = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const tag = useSelector((state: RootState) => state.tagView.tag)!;
  const tagMetrics = useSelector((state: RootState) => state.tagView.tagMetrics);
  const projectName = useSelector((state: RootState) => state.project.name);
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes);
  const projectBaseMetric = useSelector((state: RootState) => state.project.baseMetric);
  const defaultProjectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);

  const [selectedKpiIndex, setSelectedKpiIndex] = useState(0);
  const availableKpis = buildKpiDropdownFilterProps(defaultProjectKpis).map(({ label, value }) => ({
    title: label,
    value,
  }));

  useTitle(`Tag Overview - ${projectName}`);

  const kpis = defaultProjectKpis;
  const filters = useSelector((state: RootState) => state.filters);
  const projectId = useSelector((state: RootState) => state.project.id);
  const currentTotalTrendCurrentParams = useMemo(
    () => getTotalTrendParams({ projectId, kpis, filters }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, JSON.stringify(kpis), JSON.stringify(filters)]
  );

  const {
    data: projectMetrics,
    isLoading: isLoadingProjectMetrics,
    isFetched: isProjectMetricsFetched,
  } = useMetricsPerformance(currentTotalTrendCurrentParams);

  // Define the loading state of the component
  const loadingStatus: LoadingStatus = useMemo(
    () => ({
      tagLoading: !tag,
      metricsLoading: !isProjectMetricsFetched && (!tagMetrics || !tagMetrics.metrics),
      projectMetrics: isLoadingProjectMetrics,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(tag), JSON.stringify(tagMetrics), isLoadingProjectMetrics]
  );

  // Calculates the metrics values and deltas taking into consideration
  // the project and tags KPI's values
  const metrics = useMemo(
    () => {
      if (loadingStatus.metricsLoading || loadingStatus.projectMetrics) return [];
      return (defaultProjectKpis || [])
        .filter((kpi) => SDK.Metrics.SPEND !== kpi)
        .map((metric) => ({
          name: kpiUtils.getDisplayName(metric),
          value: tagMetrics?.metrics[metric]
            ? (tagMetrics?.metrics[metric] ?? 0) * (kpiUtils.filters[metric]?.isPercentage ? 100 : 1)
            : undefined,
          isPercentage: !!kpiUtils.filters[metric]?.isPercentage,
          goodness: deltaToGoodness(
            (tagMetrics ?? {}) as SDK.Metric,
            (projectMetrics?.metrics ?? {}) as SDK.Metric,
            metric as SDK.Metrics
          ),
          status: deltaToStatus(
            (tagMetrics ?? {}) as SDK.Metric,
            (projectMetrics?.metrics ?? {}) as SDK.Metric,
            metric as SDK.Metrics
          ),
          delta: calculateDelta(tagMetrics?.metrics[metric] ?? 0, projectMetrics?.metrics[metric] ?? 0, true, false, 2),
        }))
        .filter((metric) => metric.value !== null);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      JSON.stringify(defaultProjectKpis),
      loadingStatus.metricsLoading,
      JSON.stringify(loadingStatus.projectMetrics),
      JSON.stringify(projectMetrics?.metrics),
      JSON.stringify(tagMetrics?.metrics),
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  const renderFatigueTrend = () => (
    <Styled.CardContainer data-test="tag-card-container">
      <Card fullWidth>
        <Styled.CardHeader data-test="tag-card-header">
          <Styled.TypographyClean type="text-lg" fontWeight="medium">
            Fatigue Trend
          </Styled.TypographyClean>
          <Styled.CardHeaderRight>
            <SelectInput
              defaultOption={{ title: availableKpis[selectedKpiIndex].title }}
              onChange={(index) => {
                if (selectedKpiIndex !== index) {
                  logEvent({
                    component: 'Single Tag View',
                    action: 'Change KPI',
                    category: 'user_actions',
                    parameters: {
                      changeKpi: availableKpis[selectedKpiIndex].title,
                      changeKpiLabel: `KPI ${availableKpis[selectedKpiIndex].title}`,
                    },
                  });
                }
                setSelectedKpiIndex(index);
              }}
              options={availableKpis}
              placeholder="Select KPI"
            />
          </Styled.CardHeaderRight>
        </Styled.CardHeader>
        <Styled.CardChart data-test="tag-card-chart">
          <FatigueTrend
            tag={tag}
            selectedKpi={SDK.getMetricOrKPI(availableKpis[selectedKpiIndex].value) ?? SDK.KPI.IPM}
          />
        </Styled.CardChart>
      </Card>
    </Styled.CardContainer>
  );

  const renderMetricsNumbers = () => {
    if (loadingStatus.metricsLoading) return <Skeleton key="metrics" height={78} />;
    if (metrics.length === 0)
      return (
        <Styled.TypographyClean type="text-md" fontWeight="medium">
          No metrics available
        </Styled.TypographyClean>
      );
    return <MetricsSection metrics={metrics} />;
  };

  const renderMetrics = () => (
    <Styled.MetricsContainer data-test="tag-metrics-container">
      <Styled.MetricsCardWrapper>
        <Card fullWidth>
          <Styled.CardHeader data-test="tag-card-header">
            <Styled.TypographyClean type="text-lg" fontWeight="medium">
              Total {projectBaseMetric}
            </Styled.TypographyClean>
          </Styled.CardHeader>
          <Styled.CardChart data-test="tag-card-chart">
            <TagTotalMetricChart tag={tag} baseMetric={projectBaseMetric} />
          </Styled.CardChart>
        </Card>
      </Styled.MetricsCardWrapper>
      {projectAccountTypes?.includes(SDK.AccountTypes.SOCIALS) ? null : (
        <Styled.MetricsCardWrapper>
          <Card fullWidth>
            <Styled.CardHeader data-test="tag-card-header">
              <Styled.TypographyClean type="text-lg" fontWeight="medium">
                Impressions / Clicks
              </Styled.TypographyClean>
            </Styled.CardHeader>
            <Styled.CardChart data-test="tag-card-chart">
              <ImpressionClicksChart tag={tag} />
            </Styled.CardChart>
          </Card>
        </Styled.MetricsCardWrapper>
      )}
    </Styled.MetricsContainer>
  );

  const renderContextPerformance = () => (
    <Card fullWidth fullHeight disableContentPadding>
      <ContextPerformance />
    </Card>
  );

  return (
    <RouteAnimator>
      {loadingStatus.tagLoading ? (
        <Styled.LoadingContainer>
          <LoadingScreen
            firstMessageDelay={3}
            messages={['Loading Tag..', 'Fetching Metrics..', 'Aggregating results..', 'Building chart..']}
          />
        </Styled.LoadingContainer>
      ) : (
        <Styled.MainContainer data-test="tag-overview">
          <Styled.PreviewContainer>
            <Preview
              tag={tag}
              isOpen
              stateData={undefined}
              onLoad={(data: PreviewData[]) => data}
              eventPrefix="Single Tag View"
            />
            <Styled.LeftMetricsContainer>
              <Card fullWidth fullHeight disableContentPadding>
                <Styled.MetricsCard>{renderMetricsNumbers()}</Styled.MetricsCard>
              </Card>
            </Styled.LeftMetricsContainer>
          </Styled.PreviewContainer>
          <Styled.SectionContainer data-test="tag-aggregated-metrics">
            {renderFatigueTrend()}
            {renderMetrics()}
            {renderContextPerformance()}
          </Styled.SectionContainer>
        </Styled.MainContainer>
      )}
    </RouteAnimator>
  );
};

export default TagMetrics;
