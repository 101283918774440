import { useRef } from 'react';
import Colors from '../Colors';
import Tooltip from '../Tooltip/Tooltip';
import Typography from '../Typography/Typography';
import '../common.module.css';

import * as Styled from './styles';

import { useOverflow } from '../../utils';

interface InfoHolderProps {
  label?: string;
  secondaryLabel?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  multiLineLabel?: boolean;
}

const InfoHolder = ({
  label,
  secondaryLabel,
  size = 'md',
  multiLineLabel = true,
}: InfoHolderProps) => {
  const labelRef = useRef<HTMLSpanElement>(null);
  const labelOverflows = useOverflow(labelRef);

  const secondLabelRef = useRef<HTMLSpanElement>(null);
  const secondLabelOverflows = useOverflow(secondLabelRef);

  let labelSize;
  switch (size) {
    case 'xs':
      labelSize = 14;
      break;
    case 'sm':
      labelSize = 14;
      break;
    case 'md':
      labelSize = 16;
      break;
    case 'lg':
      labelSize = 16;
      break;
    case 'xl':
      labelSize = 18;
      break;
    default:
      throw new Error('Invalid size');
  }

  return (
    <Styled.Root
      className={`${label || secondaryLabel ? `labelRoot root-${size}` : ''}`}
    >
      <Styled.Info>
        <Tooltip
          content={
            labelOverflows ? (
              <Typography type="text-sm" color={Colors.Gray[500]} noMargin>
                {label}
              </Typography>
            ) : null
          }
          placement="top"
        >
          <span>
            <Styled.LabelSpan
              $multiLine={multiLineLabel}
              $color={Colors.Gray[700]}
              $labelSize={labelSize}
              $fontWeight={500}
              ref={labelRef}
              className="label"
            >
              {label}
            </Styled.LabelSpan>
          </span>
        </Tooltip>
        {secondaryLabel && (
          <Tooltip
            content={
              secondLabelOverflows ? (
                <Typography type="text-sm" color={Colors.Gray[500]} noMargin>
                  {secondaryLabel}
                </Typography>
              ) : null
            }
            placement="top"
          >
            <span>
              <Styled.LabelSpan
                $multiLine={multiLineLabel}
                $color={Colors.Gray[500]}
                $labelSize={labelSize - 2}
                className="secondaryLabel"
                ref={secondLabelRef}
              >
                {secondaryLabel}
              </Styled.LabelSpan>
            </span>
          </Tooltip>
        )}
      </Styled.Info>
    </Styled.Root>
  );
};

export default InfoHolder;
export type { InfoHolderProps };
