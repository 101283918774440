import { IntegrationIconProps } from './types';

const Zapier = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9551 18.9619C17.0555 19.6767 14.9268 19.678 13.0271 18.9623C12.3114 17.0647 12.3112 14.9365 13.026 13.0386C14.9252 12.3225 17.056 12.3224 18.9552 13.0385C19.6707 14.9358 19.6704 17.0646 18.9551 18.9619ZM29.8054 13.6667H21.628L27.4099 7.88811C26.5025 6.61426 25.3822 5.49525 24.1076 4.58838L18.3253 10.367V2.19492C16.782 1.93548 15.1993 1.93587 13.6561 2.19492V10.367L7.87371 4.58838C6.59939 5.49464 5.47877 6.61518 4.57129 7.88811L10.3541 13.6667H2.17683C1.97498 15.2552 1.9094 16.7481 2.17683 18.3333H10.3542L4.5714 24.1119C5.48108 25.3877 6.59713 26.503 7.87371 27.4121L13.6561 21.633V29.8055C15.1995 30.064 16.7817 30.0642 18.3251 29.8055V21.633L24.108 27.4121C25.3832 26.5043 26.5015 25.3862 27.4099 24.1119L21.6271 18.3333H29.8054C30.0649 16.7924 30.0649 15.2076 29.8054 13.6667Z"
      fill="#FF4A00"
    />
  </svg>
);
export default Zapier;
