/* eslint-disable react/jsx-props-no-spreading */
import { IconProps } from './types';

const PlayCircle = ({ dimension, ...rest }: IconProps) => (
  <svg
    fill="rgba(0,0,0,0.05)"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
    <path d="M10 8L16 12L10 16V8Z" fill="white" />
  </svg>
);

export default PlayCircle;
