import { CommonTagIconProps } from '../types';

const Warm = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6501 12.353V5.64805C15.6501 3.63205 14.0161 1.99805 12.0001 1.99805C9.98406 1.99805 8.35006 3.63205 8.35006 5.64805V12.354C7.20706 13.365 6.48206 14.839 6.48206 16.485C6.48206 19.533 8.95306 22.003 12.0001 22.003C15.0481 22.003 17.5181 19.532 17.5181 16.485C17.5181 14.838 16.7931 13.364 15.6501 12.353Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12 5.79517V14.5592"
      stroke="#F93535"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.0001 18.0431C12.8903 18.0431 13.6121 17.3214 13.6121 16.4311C13.6121 15.5408 12.8903 14.8191 12.0001 14.8191C11.1098 14.8191 10.3881 15.5408 10.3881 16.4311C10.3881 17.3214 11.1098 18.0431 12.0001 18.0431Z"
      stroke="#F93535"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default Warm;
