import { WYSIWYGProps } from './types';

const MDOrderedList = ({ color }: WYSIWYGProps) => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.125 11.375H1.875V11.8125H1V12.6875H1.875V13.125H0.125V14H2.75V10.5H0.125V11.375ZM1 3.5H1.875V0H0.125V0.875H1V3.5ZM0.125 6.125H1.7L0.125 7.9625V8.75H2.75V7.875H1.175L2.75 6.0375V5.25H0.125V6.125ZM4.5 0.875V2.625H16.75V0.875H4.5ZM4.5 13.125H16.75V11.375H4.5V13.125ZM4.5 7.875H16.75V6.125H4.5V7.875Z"
      fill={color || '#98A2B3'}
    />
  </svg>
);
export default MDOrderedList;
