import { Cell, Row } from 'react-table';
import { HTMLProps } from 'react';
import '../common.module.css';
import { CellLink } from './types/custom-types';
import TableCellExpander from './TableCellExpander';

import * as Styled from './styles';

interface TableCellContainerProps extends HTMLProps<HTMLTableCellElement> {
  isFirstCell: boolean;
  isExpanderColumn?: boolean;
  'data-test': string;
}

const TableCellContainer: React.FC<
  Omit<TableCellContainerProps, 'ref' | 'as'> // otherwise we get incompatible types error
> = ({ isExpanderColumn, isFirstCell, ...rest }) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <Styled.TableData
    {...rest}
    className={`cell ${isExpanderColumn ? 'expander' : ''}`}
    $isFirstColumn={isFirstCell}
  />
  /* eslint-enable react/jsx-props-no-spreading */
);

type TableCellProps<D extends object = object> = {
  cell: Cell<D, any>;
  cellIndex: number;
  link?: CellLink<D>;
  rowDepth?: number;
  onClickExpand?: (row: Row<any>) => void;
};

function buildLinkAnchor({
  ariaLabel,
  cellIndex,
  href,
}: {
  ariaLabel?: string;
  cellIndex: number;
  href?: string;
}) {
  /* eslint-disable jsx-a11y/anchor-has-content */
  return (
    <a
      aria-label={ariaLabel}
      tabIndex={cellIndex === 0 ? 0 : -1}
      className="rowLink"
      href={href}
      data-test="build-link-anchor"
    />
  );
  /* eslint-enable jsx-a11y/anchor-has-content */
}

const TableCell = <D extends object = object>({
  cell,
  cellIndex,
  link,
  rowDepth,
  onClickExpand,
}: TableCellProps<D>) => {
  const isExpanderColumn = cell.column.id === 'expander';
  const isFavoriteColumn = cell.column.id === 'reportFavorite';

  const showExpander =
    cell.row.subRows === null // Do not show the expander if NULL value explicitly defined while building row
      ? false
      : isExpanderColumn && (rowDepth ?? 0) === 0;
  const dataTest = `table-cell-${cellIndex}`;
  const linkElement = link?.({
    cell,
    anchorFactory: ({ ariaLabel, href }) =>
      buildLinkAnchor({
        ariaLabel,
        cellIndex,
        href,
      }),
  });
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TableCellContainer
      {...cell.getCellProps()}
      // if favorite column, ignore if its first cell or not
      isFirstCell={cellIndex === 0 && !isFavoriteColumn}
      isExpanderColumn={isExpanderColumn}
      data-test={dataTest}
    >
      {showExpander ? (
        <TableCellExpander
          row={cell.row}
          onClick={(row) => onClickExpand?.(row)}
          data-test="table-cell-expander"
        />
      ) : (
        <>
          {linkElement}
          <div className="cellContentWrapper">{cell.render('Cell')}</div>
        </>
      )}
    </TableCellContainer>
  );
};

export default TableCell;
