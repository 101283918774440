import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getConceptRetention = async (requestParams: SDK.GetConceptRetentionInfoRequest, conceptId: SDK.UUID) => {
  const res = await provider.getJson<object, SDK.GetConceptRetentionInfoResponse>(
    `${SDK.BASE_URL()}/concepts/${conceptId}/retention?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useConceptRetention(
  conceptId: SDK.UUID,
  requestParams: SDK.GetConceptRetentionInfoRequest,
  options?: UseQueryOptions<SDK.GetConceptRetentionInfoResponse>
) {
  return useQuery(
    ['concept-retention', conceptId, requestParams],
    () => getConceptRetention(requestParams, conceptId),
    options
  );
}
