import { CountryIconProps } from './types';

const MZ = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3673)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.48568 16.6959L3.51465 20.4852C5.68609 22.6569 8.68576 24.0003 11.9995 24.0003C16.9603 24.0003 21.2172 20.9895 23.0447 16.6959H6.48568Z"
        fill="#FFDA44"
      />
      <path
        d="M23.0634 7.34841C21.2461 3.03164 16.9771 0.000610352 11.9995 0.000610352C8.68576 0.000610352 5.68609 1.344 3.51465 3.51572L6.51981 7.34841H23.0634Z"
        fill="#496E2D"
      />
      <path
        d="M2.6084 8.82593V15.1302H23.5864C23.8555 14.1322 23.9997 13.083 23.9997 11.9998C23.9997 10.901 23.8517 9.83693 23.575 8.82593H2.6084Z"
        fill="black"
      />
      <path
        d="M3.51471 3.51428C-1.17157 8.20056 -1.17157 15.7986 3.51471 20.4849C5.45126 18.5484 7.31374 16.6859 12 11.9996L3.51471 3.51428Z"
        fill="#A2001D"
      />
      <path
        d="M4.85694 8.86938L5.6339 11.2608H8.14855L6.11427 12.7388L6.89118 15.1303L4.85694 13.6523L2.82262 15.1303L3.59971 12.7388L1.56543 11.2608H4.0799L4.85694 8.86938Z"
        fill="#FFDA44"
      />
      <path
        d="M7.13101 11.9991H2.58301V14.0861H7.13101V11.9991Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.99252 9.60729L7.25466 8.86938L4.8571 11.2669L2.45954 8.86938L1.72168 9.60729L4.12135 12.0027L1.72168 14.4024L2.45954 15.1303L4.8571 12.737L7.25466 15.1303L7.99252 14.4024L5.59285 12.0027L7.99252 9.60729Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3673">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default MZ;
