import { SocialIconProps } from './types';

const Mintegral = ({ dimension, ...props }: SocialIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M 51 6.8 L 50 6.8 L 32.6 24.2 L 15.3 6.8 L 14.3 6.8 C 10.7 6.8 7 9.8 7 14.9 L 7 58.6 L 11.8 58.6 L 11.8 14.8 C 11.8 13.2 12.5 12.1 13.4 11.7 L 32.6 30.9 L 51.8 11.7 C 52.7 12.1 53.4 13.1 53.4 14.8 L 53.4 58.5 L 58.2 58.5 L 58.2 14.8 C 58.3 9.8 54.6 6.8 51 6.8 Z"
        fill="#00D1AE"
      />
    </g>
  </svg>
);
export default Mintegral;
