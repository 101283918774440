/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getProjectUsers = async (requestParams: { projectId: SDK.UUID }) => {
  const { projectId } = requestParams;
  const res = await provider.getJson<object, any>(`${SDK.BASE_URL()}/project_users?projectIds=["${projectId}"]`);
  return res;
};

export default function useProjectUsers(requestParams: { projectId: SDK.UUID }, options?: UseQueryOptions<any>) {
  return useQuery(['project-users', requestParams], () => getProjectUsers(requestParams), options);
}
