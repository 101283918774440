import * as SDK from '@replai-platform/sdk';
import { logEvent } from '../../analytics';
import { KpiState } from './types';

export const calculateKpiBadgeStatus = ({
  kpi,
  controlKpi,
  iterationKpi,
}: {
  kpi: SDK.MetricKPIWithSpend;
  controlKpi: number;
  iterationKpi: number;
}) => {
  if (iterationKpi == null || controlKpi == null) {
    return undefined;
  }

  if (SDK.kpiUtils.shouldMaximize(kpi)) {
    return iterationKpi > controlKpi ? KpiState.WINNING : KpiState.LOSING;
  }

  return iterationKpi < controlKpi ? KpiState.WINNING : KpiState.LOSING;
};

export const logEventOnAction = ({
  component,
  action,
  params = {},
}: {
  component: string;
  action: string;
  params?: object;
}) => {
  logEvent({
    component,
    action,
    category: 'user_actions',
    parameters: { page: 'Compare', ...params },
  });
};
