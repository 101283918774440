import { CountryIconProps } from './types';

const TL = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3611)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FFDA44"
      />
      <path
        d="M12.0001 -0.000366211C9.06209 -0.000366211 6.37119 1.05606 4.28516 2.80904L17.2175 11.9996L4.28516 21.1902C6.37119 22.9432 9.06209 23.9996 12.0001 23.9996C18.6275 23.9996 24.0001 18.627 24.0001 11.9996C24.0001 5.37226 18.6275 -0.000366211 12.0001 -0.000366211Z"
        fill="#D80027"
      />
      <path
        d="M3.51471 3.5155C-1.17157 8.20178 -1.17157 15.7998 3.51471 20.4861C5.45126 18.5496 7.31375 16.6871 12 12.0008L3.51471 3.5155Z"
        fill="black"
      />
      <path
        d="M3.32659 9.25146L5.15284 10.9798L7.36103 9.77684L6.28173 12.0479L8.10803 13.7763L5.61456 13.4516L4.53513 15.7226L4.07355 13.2508L1.58008 12.926L3.78817 11.7231L3.32659 9.25146Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3611">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default TL;
