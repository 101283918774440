import { IntegrationIconProps } from './types';

const Blackberry = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 16C30 23.728 23.735 30 16 30C8.265 30 2 23.728 2 16C2 8.265 8.265 2 16 2C23.735 2 30 8.265 30 16Z"
      fill="#1D1D1D"
    />
    <path
      d="M8.95479 8L7.97739 12H11.3381C12.2351 12 13.017 11.3754 13.2345 10.4851C13.543 9.22278 12.6098 8 11.3381 8H8.95479Z"
      fill="white"
    />
    <path
      d="M7.97739 14L7 18H10.3607C11.2577 18 12.0396 17.3754 12.2571 16.4851C12.5656 15.2228 11.6325 14 10.3607 14H7.97739Z"
      fill="white"
    />
    <path
      d="M13.8418 18L14.8192 14H17.2025C18.4742 14 19.4073 15.2228 19.0989 16.4851C18.8814 17.3754 18.0995 18 17.2025 18H13.8418Z"
      fill="white"
    />
    <path
      d="M13.8418 20L12.8644 24H16.2251C17.1221 24 17.904 23.3754 18.1215 22.4851C18.43 21.2228 17.4968 20 16.2251 20H13.8418Z"
      fill="white"
    />
    <path
      d="M23.0669 22H19.7061L20.6835 18H23.0669C24.3386 18 25.2717 19.2228 24.9633 20.4851C24.7457 21.3754 23.9638 22 23.0669 22Z"
      fill="white"
    />
    <path
      d="M21.6609 12L20.6835 16H24.0442C24.9412 16 25.7231 15.3754 25.9407 14.4851C26.2491 13.2228 25.316 12 24.0442 12H21.6609Z"
      fill="white"
    />
    <path
      d="M14.8192 12L15.7966 8H18.1799C19.4516 8 20.3847 9.22278 20.0763 10.4851C19.8588 11.3754 19.0769 12 18.1799 12H14.8192Z"
      fill="white"
    />
  </svg>
);
export default Blackberry;
