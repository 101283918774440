import { useState, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Amplify, Auth } from 'aws-amplify';
import { awsConfig } from '@replai-platform/sdk';
import { RootState } from '../../store/rootReducer';
import { RouteAnimator } from '../RouteAnimator';
import { finalizeLogin } from './utils';
import TopBar from './TopBar';
import SignIn from './SignIn';
import SetPassword from './SetPassword';

Amplify.configure(awsConfig);

type View = 'sign-in' | 'set-password';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const LoginView = () => {
  const dispatch = useDispatch();
  const [view, setView] = useState<View>('sign-in');
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const projectId = useSelector((state: RootState) => state.project.id);
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const cognitoUser = useRef<any>();

  const signIn = async (email: string, password: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    cognitoUser.current = await Auth.signIn(email, password);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (cognitoUser?.current?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      // user's first login, needs to set new password
      setIsFirstLogin(true);
      setView('set-password');
    } else {
      await finalizeLogin({
        dispatch,
      });
    }
  };

  const requestCode = async (email: string) => {
    await Auth.forgotPassword(email);
  };

  const setPassword = async ({
    password,
    email,
    verificationCode,
  }: {
    password: string;
    email?: string;
    verificationCode?: string;
  }) => {
    if (verificationCode && email) {
      // forgot password
      await Auth.forgotPasswordSubmit(email, verificationCode, password);
    } else {
      // first login
      await Auth.completeNewPassword(cognitoUser.current, password);
    }
  };

  const goToView = (v: View) => setView(v);

  const getActiveView = () => {
    switch (view) {
      case 'sign-in':
        return <SignIn signIn={signIn} goToView={goToView} />;
      case 'set-password':
        return (
          <SetPassword
            requestCode={requestCode}
            setPassword={setPassword}
            goToView={goToView}
            initialStep={isFirstLogin ? 'set-password' : 'request-code'}
          />
        );
      default:
        return <SignIn signIn={signIn} goToView={goToView} />;
    }
  };

  return projectId ? (
    <Navigate replace to={{ pathname: `/${projectId}/dashboard` }} />
  ) : (
    <RouteAnimator>
      <Container>
        <TopBar />
        {getActiveView()}
      </Container>
    </RouteAnimator>
  );
};

export default LoginView;
