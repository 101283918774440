import { PaymentIconProps } from './types';

const Alipay = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      d="M11.8395 11.7311H5.49541C4.66923 11.7311 4 12.4065 4 13.2388V19.6367C4 20.469 4.66923 21.1444 5.49541 21.1444H11.8395C12.6656 21.1444 13.334 20.469 13.334 19.6367V13.2388C13.3349 12.4056 12.6656 11.7311 11.8395 11.7311Z"
      fill="#1677FF"
    />
    <path
      d="M11.6854 17.6422C11.3124 17.5161 10.8107 17.323 10.2526 17.1194C10.5876 16.5313 10.8557 15.862 11.032 15.1346H9.19185V14.4662H11.4455V14.0933H9.19185V12.9788H8.2722C8.11085 12.9788 8.11085 13.1392 8.11085 13.1392V14.0924H5.83157V14.4654H8.11085V15.1337H6.22835V15.5067H9.87872C9.74557 15.9705 9.5657 16.4069 9.3532 16.8028C8.16904 16.4087 6.90464 16.0895 6.11108 16.2861C5.60321 16.4122 5.27609 16.6371 5.08387 16.8734C4.20214 17.9552 4.83434 19.5988 6.69655 19.5988C7.79783 19.5988 8.85855 18.9798 9.68033 17.9597C10.9068 18.5539 13.3342 19.575 13.3342 19.575V18.1201C13.3351 18.1201 13.03 18.0954 11.6854 17.6422ZM6.52285 18.9701C5.07064 18.9701 4.64124 17.8168 5.35897 17.1855C5.5988 16.9721 6.03614 16.8681 6.26891 16.8451C7.13124 16.7596 7.93009 17.0911 8.87266 17.5549C8.21048 18.4261 7.36667 18.9701 6.52285 18.9701Z"
      fill="white"
    />
    <path
      d="M24.2686 13.1093C24.2686 13.596 24.6248 13.924 25.1212 13.924C25.6176 13.924 25.9738 13.596 25.9738 13.1093C25.9738 12.6314 25.6176 12.2946 25.1212 12.2946C24.6248 12.2946 24.2686 12.6314 24.2686 13.1093Z"
      fill="#1677FF"
    />
    <path d="M23.3692 12.5H21.8896V19.3369H23.3692V12.5Z" fill="black" />
    <path
      d="M19.2958 12.7345H17.3101L15.0908 19.3378H16.4584L16.8331 18.0452H19.1838L19.54 19.3378H21.2911L19.2958 12.7345ZM17.1417 16.9677L18.0217 13.9239H18.0587L18.892 16.9677H17.1417Z"
      fill="black"
    />
    <path d="M25.8604 14.3173H24.3809V19.3378H25.8604V14.3173Z" fill="black" />
    <path
      d="M41.3244 14.327L41.3333 14.3173H39.9384L39.0575 17.3707H39.0108L37.9994 14.3173H36.3418L38.3363 19.3563L37.503 20.8923V20.9294H38.8045L41.3244 14.327Z"
      fill="black"
    />
    <path
      d="M29.5422 14.2141C28.999 14.2141 28.5776 14.4204 28.0997 14.8137V14.3173H26.6201V20.9294H28.0997V19.2902C28.3809 19.3652 28.6428 19.4031 28.9611 19.4031C30.2819 19.4031 31.4714 18.4288 31.4714 16.6962C31.4714 15.1417 30.6099 14.2141 29.5422 14.2141ZM28.6049 18.4755C28.4365 18.4755 28.2769 18.457 28.0988 18.4006V15.6848C28.4083 15.4697 28.6604 15.3665 28.9787 15.3665C29.5316 15.3665 29.9716 15.8065 29.9716 16.7438C29.9725 17.9421 29.3261 18.4755 28.6049 18.4755Z"
      fill="black"
    />
    <path
      d="M36.051 18.0823V15.9846C36.051 14.8419 35.3765 14.2141 34.187 14.2141C33.4279 14.2141 32.9041 14.3455 31.9483 14.6356L32.2102 15.788C33.0814 15.3947 33.4649 15.2263 33.8679 15.2263C34.3546 15.2263 34.5706 15.5729 34.5706 16.1072V16.1442C32.875 16.4625 32.3504 16.6406 32.0233 16.9686C31.7799 17.212 31.6768 17.5585 31.6768 17.9615C31.6768 18.9261 32.4262 19.441 33.1281 19.441C33.6527 19.441 34.0742 19.2444 34.6455 18.8132L34.7487 19.3378H36.2283L36.051 18.0823ZM34.5715 18.1202C34.1782 18.3353 33.9534 18.42 33.6915 18.42C33.3353 18.42 33.1105 18.1854 33.1105 17.8116C33.1105 17.6714 33.1387 17.5303 33.2507 17.4183C33.4288 17.2402 33.7753 17.1088 34.5715 16.9219V18.1202Z"
      fill="black"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default Alipay;
