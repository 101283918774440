import { CommonTagIconProps } from '../types';

const CutFrequencyNormal = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.512 14.2291C11.124 16.2021 10.666 17.7631 9.94103 17.7631C7.97103 17.7631 7.97103 6.23706 6.00103 6.23706C5.33803 6.23706 4.89803 7.54406 4.53003 9.27906C4.27703 10.4741 3.22903 11.3391 2.00703 11.3391H1.95703"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.043 11.564C20.803 11.564 19.736 12.451 19.511 13.67C19.099 15.899 18.624 17.764 17.83 17.764C15.856 17.764 15.856 6.23804 13.882 6.23804C13.132 6.23804 12.668 7.90504 12.272 9.97204"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default CutFrequencyNormal;
