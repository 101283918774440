import { CustomizableOptionsSelector, LoadingScreenProps, SelectDropDownOption } from '@replai-platform/ui-components';
import { CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP } from '../../utils/constants';

export type PickAndSortDataGroup = {
  title: string;
  options: Array<{
    label: string;
    id: string;
    disallowed?: boolean;
  }>;
};

export type PickAndSortDialogData = {
  groups: Array<PickAndSortDataGroup>;
  initialValues: Array<{
    label: string;
    id: string;
    disallowed?: boolean;
  }>;
};

type PickAndSortDialogProps = {
  data: PickAndSortDialogData;
  initialSelectedOptions?: PickAndSortDialogData['initialValues'];
  onCancel: () => void;
  onSave: (values: string[]) => Promise<void> | void;
  open: boolean;
  title: string;
  subtitle?: string;
  loading?: LoadingScreenProps;
  showSort?: boolean;
  onReset?: () => void;
  disallowedTooltip?: string;
};

const PickAndSortDialog = ({
  open,
  title,
  subtitle,
  onSave,
  onCancel,
  onReset,
  loading,
  showSort = true,
  data,
  initialSelectedOptions,
  disallowedTooltip = CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP,
}: PickAndSortDialogProps) => {
  if (!open) {
    return null;
  }

  return (
    <CustomizableOptionsSelector
      key={JSON.stringify(data)} // TODO: This is a hack that will be fixed with  CustomizableOptionsSelector dependencies update
      loading={loading}
      title={title}
      subtitle={subtitle}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSave={async (selected: SelectDropDownOption[]) => {
        await onSave(selected?.map((o) => o.id));
      }}
      onClose={onCancel}
      availableOptions={data?.groups || []}
      selectedOptions={data?.initialValues || []}
      initialSelectedOptions={initialSelectedOptions}
      isOpen={open}
      showSort={showSort}
      onReset={onReset}
      disallowedTooltip={disallowedTooltip}
    />
  );
};

export default PickAndSortDialog;
