import styled from 'styled-components';
import Colors from '../Colors';
import { default as DefaultButton } from '../Button/Button';

export const Button = styled(DefaultButton)`
  max-width: 300px;
  margin-left: 0.75rem;
`;

export const ButtonContent = styled.span`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

export const ButtonText = styled.span`
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropDownContainer = styled.div<{
  $position?: string;
  $left?: number | null;
  $top?: number | null;
}>`
  z-index: 300;
  margin-top: 1rem;

  position: ${(props) => props.$position ?? 'absolute'};
  top: ${(props) => (props.$top ? `${props.$top}px` : 0)};
  left: ${(props) => (props.$left ? `${props.$left}px` : 0)};
`;

export const DropDownContentRoot = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  max-height: 500px;
  overflow: scroll;
  background-color: ${Colors.Common.White};
  border-radius: 8px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  padding: 10px 16px;
`;
