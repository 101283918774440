import { useMutation } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const grantReportPermissions = async (
  request: SDK.GrantReportPermissionsRequest
): Promise<SDK.GrantReportPermissionsResponse> =>
  provider.getJson(`${SDK.BASE_URL()}/reports/grant_permission?${SDK.convertRequestToQueryParams(request)}`);

export default function useGrantReportPermissions() {
  return useMutation(['grant-report-permissions'], (requestParams: SDK.GrantReportPermissionsRequest) =>
    grantReportPermissions(requestParams)
  );
}
