import { Colors } from '..';
import generateGradient from './generateGradient';

const blue = {
  conical: generateGradient(
    [Colors.Blue[600], Colors.Blue[25]],
    'conical',
    {},
    {
      originDeg: 259.08,
      originX: 50,
      originY: 50,
      deg: [0, 360],
    }
  ),
  linear01: generateGradient([Colors.Blue[600], Colors.Blue[500]], 'linear', {
    originDeg: 90,
    percent: [0, 100],
  }),
  linear02: generateGradient([Colors.Blue[700], Colors.Blue[600]], 'linear', {
    originDeg: 45,
    percent: [0, 100],
  }),
  linear03: generateGradient([Colors.Blue[800], Colors.Blue[600]], 'linear', {
    originDeg: 45,
    percent: [0, 100],
  }),
  linear04: generateGradient([Colors.Blue[800], Colors.Blue[600]], 'linear', {
    originDeg: 90,
    percent: [0, 100],
  }),
  linear05: generateGradient([Colors.Blue[800], Colors.Blue[700]], 'linear', {
    originDeg: 26.5,
    percent: [0, 100],
  }),
  linear06: generateGradient([Colors.Blue[900], Colors.Blue[600]], 'linear', {
    originDeg: 45,
    percent: [0, 100],
  }),
} as const;

export default blue;
