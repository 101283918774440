import { IconProps } from './types';

const Cpu = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1V4M15 1V4M9 20V23M15 20V23M20 9H23M20 14H23M1 9H4M1 14H4M6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4ZM9 9H15V15H9V9Z"
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default Cpu;
