import { useMemo, useRef, useState } from 'react';
import ReactPlayer, { type ReactPlayerProps } from 'react-player';
import {
  useDeepCompareEffect,
  useMeasure,
  usePrevious,
  useUpdateEffect,
  useMountedState,
} from 'react-use';
import styled from 'styled-components';
import { TagBreakdownT } from '../../../Timeline/timeline.types';
import TimelineBody from '../../../Timeline/TimelineBody/TimelineBody';
import TimelineTimestamps from '../../../Timeline/TimelineTimestamps/TimelineTimestamps';
import TagCarousel from './TagCarousel';

export interface TagPreviewProps {
  thumbnails?: PreviewThumbnail[];
  videos?: PreviewVideo[];
  onTagClick?: ({
    value,
    type,
    checked,
  }: {
    value: string;
    type: string;
    checked: boolean;
  }) => void | Promise<void>;
  onVideoPlay?: () => void;
  onVideoPause?: () => void;
  onTimelineClick?: () => void;
  onPreviewNavigation?: () => void;
  onCopyNameToClipboardClick?: (name: string) => void | Promise<void>;
}

export interface PreviewVideo {
  url: URL;
  timeline: TagBreakdownT[];
  videoPlayerRef?: React.Ref<ReactPlayer>;
}

export interface PreviewThumbnail {
  imageUrl: URL;
}

export type ThumbnailWithType = PreviewThumbnail & { type: 'thumbnail' };

export type VideoWithType = PreviewVideo & { type: 'video' };

export type Data = ThumbnailWithType | (VideoWithType & { name: string });

const TimelineTimestampsContainer = styled.div`
  padding: 0.75rem 0;
`;

const TagPreview: React.VFC<TagPreviewProps> = ({
  thumbnails,
  videos,
  onTagClick,
  onVideoPlay,
  onVideoPause,
  onTimelineClick,
  onPreviewNavigation,
  onCopyNameToClipboardClick,
}) => {
  const isMounted = useMountedState();
  const tags: Data[] = useMemo(
    () => [
      ...(videos || []).map(
        (t: PreviewVideo) => ({ ...t, type: 'video' } as Data)
      ),
      ...(thumbnails || []).map(
        (t: PreviewThumbnail) => ({ ...t, type: 'thumbnail' } as Data)
      ),
    ],
    [thumbnails, videos]
  );
  const [currentPreview, setCurrentPreview] = useState<Data>(tags[0]);
  useDeepCompareEffect(() => {
    setCurrentPreview(tags[0]);
  }, [tags]);
  const [containerRef, { width }] = useMeasure<HTMLDivElement>();
  const [isPlaying, setIsPlaying] = useState(false);
  const previousIsPlaying = usePrevious(isPlaying);
  const videoPlayer = useRef<ReactPlayer | null>(null);
  const [updatedPlayedFraction, setPlayedFraction] = useState(0);
  const [updatedPlayedSeconds, setPlayedSeconds] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const onBeforeSlideChange = () => {
    setIsPlaying(false);
    videoPlayer.current?.seekTo(0);
    setPlayedSeconds(0);
    setPlayedFraction(0);
  };
  const onAfterSlideChange = (currentSlide: number) => {
    onPreviewNavigation?.();
    setCurrentPreview(tags[currentSlide]);
    setPlayedSeconds(0);
    setPlayedFraction(0);
    videoPlayer.current?.seekTo(0);
    // defer getting the video duration until the stack is cleared, to make sure we have the correct
    // player ref. same as setTimeout(fn, 0), pseudo async
    setTimeout(() => {
      // Fix "Can't perform a React state update on an unmounted component" warning
      if (!isMounted()) return;

      setVideoDuration(videoPlayer.current?.getDuration() ?? 0);
    });
  };
  const timestampsInSeconds = useMemo(() => {
    if (!videoDuration) return [];
    if (videoDuration > 0 && width > 0) {
      // Minimum two timestamps (00:00 and video duration)
      const numTimestampsToShow = Math.max(2, Math.floor(width / 50) - 2);
      // Calculate interval between timestamps
      const interval = Math.floor(videoDuration / (numTimestampsToShow - 1));
      // Generate timestamps with zero in beginning, duraton in end, and intervals in between
      return [
        0,
        ...Array.from(
          Array(numTimestampsToShow - 2),
          (_, i) => interval * (i + 1)
        ),
        videoDuration,
      ];
    }
    return [];
  }, [videoDuration, width]);
  const seekToPoint = (point: number) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fraction = point / videoDuration!;
    setPlayedFraction(fraction);
    setPlayedSeconds(point);
    videoPlayer.current?.seekTo(point, 'seconds');
    onTimelineClick?.();
  };
  const onVideoProgress: ReactPlayerProps['onProgress'] = ({
    playedSeconds,
    played,
  }) => {
    setPlayedSeconds(playedSeconds);
    setPlayedFraction(played);
  };
  const onVideoDuration: ReactPlayerProps['onDuration'] = (duration) => {
    setVideoDuration(duration);
  };
  useUpdateEffect(() => {
    if (previousIsPlaying !== isPlaying) {
      if (isPlaying) {
        onVideoPlay?.();
      } else {
        onVideoPause?.();
      }
    }
  }, [isPlaying, onVideoPause, onVideoPlay, previousIsPlaying]);

  return (
    <div ref={containerRef}>
      <TagCarousel
        videoPlayerRef={videoPlayer}
        tags={tags}
        onAfterSlideChange={onAfterSlideChange}
        onBeforeSlideChange={onBeforeSlideChange}
        playedSeconds={updatedPlayedSeconds}
        onVideoProgress={onVideoProgress}
        isVideoPlaying={isPlaying}
        setVideoPlaying={setIsPlaying}
        onVideoDuration={onVideoDuration}
        onCopyNameToClipboardClick={onCopyNameToClipboardClick}
      />
      {currentPreview.type === 'video' ? (
        <>
          <TimelineTimestampsContainer>
            <TimelineTimestamps timestampsInSeconds={timestampsInSeconds} />
          </TimelineTimestampsContainer>
          <TimelineBody
            data={currentPreview.timeline}
            videoDurationInSeconds={videoDuration}
            recommendationsOnly={false}
            groupByTagCategory={false}
            loading={false}
            showTimelineTimestampLine
            showDividers={false}
            showTypeAndValue
            showRecommendationsOnTop={false}
            playedFraction={updatedPlayedFraction}
            playedSeconds={updatedPlayedSeconds}
            seekToPoint={seekToPoint}
            onTagClick={onTagClick}
            timestampLineVerticalOffset={-16}
            rowsSpacing="1.625rem"
          />
        </>
      ) : null}
    </div>
  );
};

export default TagPreview;
