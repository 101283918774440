/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import { Row } from 'react-table';
import styled from 'styled-components';
import Button from '../Button/Button';

const Container = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExpanderCell = ({
  row,
  onClick,
}: {
  row: Row<any>;
  onClick?: (row: Row<any>) => void;
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <>
    {row.original?.subRows !== null ? (
      <Container {...row.getToggleRowExpandedProps()}>
        <Button
          leadingIcon={{
            name: row.isExpanded ? 'ChevronUp' : 'ChevronDown',
          }}
          variant="no-fill"
          color="Gray"
          aria-label="Expand Row"
          onClick={() => onClick?.(row)}
        />
      </Container>
    ) : (
      ''
    )}
  </>
);
export default ExpanderCell;
