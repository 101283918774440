/* eslint-disable react/jsx-props-no-spreading */
import './PreferencesModal.global.css';
import * as SDK from '@replai-platform/sdk';
import { Button, Modal, Typography } from '@replai-platform/ui-components';
import { useState } from 'react';
import styled from 'styled-components';
import type { UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Button as AntdButton, message, Upload } from 'antd';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import {
  useGetCreativePreferencesFiles,
  useSubmitCreativePreferencesFile,
} from '../../../api/hooks/creativeProduction/useCreativeRequests';
import { RootState } from '../../../store/rootReducer';
import PreferencesFileEntry from './PreferencesFileEntry';

const Container = styled.div`
  position: relative;
  padding: 0.25rem; /* Compensate for hover outline */

  /* Make avatar clickable */
  > div > span:first-child {
    z-index: 1;
  }

  /* Override image style to improve usability. */
  > div > div > div > img {
    height: 100%;
    object-fit: contain;
  }
  cursor: pointer;
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
`;

const SectionContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
`;

const PreferencesModal = () => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const [open, setOpen] = useState(false);

  const { data: filesList, isLoading: isListLoading } = useGetCreativePreferencesFiles(
    projectId,
    { cacheTime: 1 } // minimal cache time because we want this query to be refreshed when changing pages
  );

  const submitProductionCreativeRequest = useSubmitCreativePreferencesFile(projectId);
  const handleFileUploadFunc = (submissionType: SDK.CreativePreferencesType) => {
    const handleFileUpload: UploadProps['customRequest'] = (params) => {
      const reader = new FileReader();
      reader.readAsDataURL(params.file as Blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        submitProductionCreativeRequest.mutate({
          submissionType,
          fileName: (params.file as { name: string }).name,
          fileBinary: base64data as string,
        });
        params?.onSuccess?.('File successfully uploaded');
      };
    };
    return handleFileUpload;
  };

  const getUploadProps = (submissionType: SDK.CreativePreferencesType): UploadProps => ({
    accept: 'image/*,.pdf',
    name: 'file',
    defaultFileList: [],
    fileList: [],
    multiple: true,
    customRequest: handleFileUploadFunc(submissionType),
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        // eslint-disable-next-line no-void
        void message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        // eslint-disable-next-line no-void
        void message.error(`${info.file.name} file upload failed.`);
      }
    },
  });

  return (
    <Container aria-label="Clickable Avatar">
      <Button
        variant="outlined"
        leadingIcon={{ name: 'Settings' }}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          logEvent({
            component: 'PreferencesModal',
            action: 'Click to Open',
            category: 'user_actions',
          });
          setOpen(true);
        }}
      >
        Creative preferences
      </Button>
      <Modal
        className="replai-preferences-modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
        onClose={() => setOpen(false)}
        isOpen={open}
      >
        <div style={{ padding: '4em' }}>
          <SectionsContainer>
            <SectionContainer>
              <Typography type="display-md">Creative preferences</Typography>
              <Typography>
                Upload any files relevant for your creative preferences, including brand/creative guidelines or other
                resources Replai can use like creative assets or character models. All of these will be combined to
                ensure generated videos meet your existing brand requirements.
              </Typography>
              {isListLoading ? (
                <>
                  <span>File list loading...</span>
                  <br />
                </>
              ) : (
                filesList?.files
                  .filter((f) => f.submissionType === 'CREATIVE_GUIDELINES')
                  .map((file) => <PreferencesFileEntry fileId={file?.id} fileName={file.fileName} />)
              )}
              <Upload {...getUploadProps('CREATIVE_GUIDELINES')}>
                <AntdButton icon={<UploadOutlined rev={undefined} />}>Upload</AntdButton>
              </Upload>
            </SectionContainer>
            <SectionContainer>
              <Typography type="display-md">Testing guidelines</Typography>
              <Typography>
                Upload files specifying how you test new creatives. This is used to influence creative generation in a
                way that maximizes the chance of success of your new creatives.
              </Typography>
              {isListLoading ? (
                <>
                  <span>File list loading...</span>
                  <br />
                </>
              ) : (
                filesList?.files
                  .filter((f) => f.submissionType === 'TESTING_GUIDELINES')
                  .map((file) => <PreferencesFileEntry fileId={file?.id} fileName={file.fileName} />)
              )}
              <Upload {...getUploadProps('TESTING_GUIDELINES')}>
                <AntdButton icon={<UploadOutlined rev={undefined} />}>Upload</AntdButton>
              </Upload>
            </SectionContainer>
          </SectionsContainer>
        </div>
      </Modal>
    </Container>
  );
};

export default PreferencesModal;
