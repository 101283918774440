import { CountryIconProps } from './types';

const CA = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3589)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M24.0004 12.0011C24.0004 7.24352 21.2317 3.13277 17.2178 1.19177V22.8103C21.2317 20.8693 24.0004 16.7586 24.0004 12.0011Z"
        fill="#D80027"
      />
      <path
        d="M0 12.0004C0 16.758 2.76867 20.8687 6.78262 22.8097V1.19116C2.76867 3.13216 0 7.24291 0 12.0004Z"
        fill="#D80027"
      />
      <path
        d="M14.087 13.5663L16.174 12.5228L15.1305 12.001V10.9576L13.0435 12.001L14.087 9.91408H13.0435L12.0001 8.34888L10.9566 9.91408H9.91309L10.9566 12.001L8.86961 10.9576V12.001L7.82617 12.5228L9.91309 13.5663L9.39138 14.6097H11.4783V16.1749H12.5218V14.6097H14.6088L14.087 13.5663Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3589">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default CA;
