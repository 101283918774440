/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Colors, Icons } from '@replai-platform/ui-components';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { TypographyClean } from '../../utils/styles';
import { formatTagType } from '../../utils/timelineUtils';
import SidebarSection from '../Timeline/Sidebar/SidebarSection';
import SidebarSectionItem from '../Timeline/Sidebar/SidebarSectionItem';
import { VideoTagsT } from '../Timeline/types';
import VideoComponent from '../VideoComponent';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
  background-color: ${Colors.Common.Black};
  border-radius: 0.5rem;
`;

const VideoSidebar: React.FC<{
  url: string;
  coreVideoTags: { type: string; value: string | null }[];
  coreMiscTags: { type: string; value: string | null }[];
  onPlay?: () => void;
  onPause?: () => void;
  onProgress?: (state: { played: number; playedSeconds: number; loaded: number; loadedSeconds: number }) => void;
  onDuration?: (duration: number) => void;
  showDescription?: boolean;
  isPlaying?: boolean;
  onLoop?: boolean;
  player?: React.LegacyRef<ReactPlayer>;
}> = ({
  url,
  coreVideoTags,
  coreMiscTags,
  onPlay,
  onPause,
  onProgress,
  onDuration,
  showDescription = true,
  isPlaying,
  onLoop,
  player,
}) => (
  <>
    <VideoContainer data-test="timeline-video">
      <VideoComponent
        url={url}
        onPlay={onPlay}
        onPause={onPause}
        onProgress={onProgress}
        onDuration={onDuration}
        isPlaying={isPlaying}
        onLoop={onLoop}
        player={player}
      />
    </VideoContainer>
    {showDescription ? (
      <SidebarSection title="Description">
        <Grid data-test="timeline-sidebar-section-grid">
          {[...coreVideoTags, ...coreMiscTags].map(({ type, value }) => {
            const title = formatTagType(type as VideoTagsT);
            switch (type) {
              case 'cutFrequency':
                return (
                  <SidebarSectionItem title={title} key={type}>
                    <TypographyClean type="text-md" color={Colors.Gray[700]} fontWeight="medium">
                      {value}
                    </TypographyClean>
                    <Icons.BaseIcons.ArrowDownRight />
                  </SidebarSectionItem>
                );
              case 'duration':
              case 'aspectRatio':
              case 'language':
              case 'sceneTransitionsDiff':
              default:
                return (
                  <SidebarSectionItem title={title} key={type}>
                    <TypographyClean type="text-md" color={Colors.Gray[700]} fontWeight="medium">
                      {value}
                    </TypographyClean>
                  </SidebarSectionItem>
                );
            }
          })}
        </Grid>
      </SidebarSection>
    ) : undefined}
  </>
);

export default VideoSidebar;
