import { Auth } from 'aws-amplify';

type ProviderOptions<T> = { body: T; method: HTTPMethod };
type HTTPMethod = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'CONNECT' | 'OPTIONS' | 'TRACE' | 'PATCH';

export interface SupportedHeaders {
  'cache-control'?: string;
}

type RequestParams<T> = {
  url: string;
  options?: ProviderOptions<T>;
  customHeaders?: SupportedHeaders;
};

export async function raw<T extends object | null>({ url, options, customHeaders }: RequestParams<T>) {
  const session = await Auth.currentSession();
  const { body, method } = options ?? { body: null, method: 'GET' };

  return fetch(url, {
    body: body ? JSON.stringify(body) : null,
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: session.getIdToken().getJwtToken(),
      ...customHeaders,
    },
  });
}

export async function getJson<T extends object | null, P = unknown>(
  url: string,
  options?: ProviderOptions<T>,
  customHeaders?: SupportedHeaders
) {
  const session = await Auth.currentSession();
  const { body, method } = options ?? { body: null, method: 'GET' };

  return fetch(url, {
    body: body ? JSON.stringify(body) : null,
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: session.getIdToken().getJwtToken(),
      ...customHeaders,
    },
  }).then((response) => {
    if (response.ok) return response.json() as Promise<P>;
    throw Error(response.statusText);
  });
}

// TODO use something like this for inspire
export async function getImage<T extends object>(
  imageMimeType: string,
  url: string,
  options?: ProviderOptions<T>,
  customHeaders?: SupportedHeaders
) {
  const session = await Auth.currentSession();
  const { body, method } = options ?? { body: null, method: 'GET' };

  return fetch(url, {
    body: body ? JSON.stringify(body) : null,
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': imageMimeType,
      Authorization: session.getIdToken().getJwtToken(),
      ...customHeaders,
    },
  }).then((response) => {
    if (response.ok) return response;
    throw Error(response.statusText);
  });
}
export default getJson;
