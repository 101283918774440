import * as SDK from '@replai-platform/sdk';
import { useQuery, UseQueryOptions } from 'react-query';
import * as provider from '../../api/provider';

const getMarketTags = async (request: SDK.GetMarketTagsRequest): Promise<SDK.GetMarketTagsResponse> =>
  provider.getJson<object, SDK.GetMarketTagsResponse>(
    `${SDK.BASE_URL()}/market/tags?${SDK.convertRequestToQueryParams(request)}`
  );

export default function useMarketTags<R = SDK.GetMarketTagsResponse>(
  requestParams: SDK.GetMarketTagsRequest,
  options?: UseQueryOptions<SDK.GetMarketTagsResponse, unknown, R>
) {
  return useQuery(['market.useMarketTags', requestParams], () => getMarketTags(requestParams), options);
}
