import { IntegrationIconProps } from './types';

const Joomla = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.6773 23.0044C27.4221 20.6503 26.8357 18.1981 25.3074 16.6239C23.4981 14.7602 19.8196 11 19.8196 11L17 13.9044C17 13.9044 20.9241 17.8562 22.5176 19.4976C23.2669 20.2694 23.2669 21.5208 22.5176 22.2926C21.7781 23.0543 20.5853 23.0643 19.834 22.3227L17.0439 25.1966C18.6625 26.8407 20.9782 27.3189 23.0024 26.6313C23.0715 28.5034 24.611 30 26.5 30C28.433 30 30 28.433 30 26.5C30 24.6265 28.5279 23.0968 26.6773 23.0044Z"
      fill="#F44321"
    />
    <path
      d="M23.0044 5.32272C20.6503 4.57793 18.1981 5.16434 16.6239 6.69259C14.7602 8.50188 11 12.1804 11 12.1804L13.9044 15C13.9044 15 17.8562 11.0759 19.4976 9.48241C20.2694 8.7331 21.5208 8.7331 22.2926 9.48241C23.0543 10.2219 23.0643 11.4147 22.3227 12.166L25.1966 14.9561C26.8407 13.3375 27.3189 11.0218 26.6313 8.99758C28.5034 8.92853 30 7.38904 30 5.50001C30 3.56701 28.433 2.00001 26.5 2.00001C24.6265 2.00001 23.0968 3.4721 23.0044 5.32272Z"
      fill="#FBB854"
    />
    <path
      d="M5.32271 8.99558C4.57793 11.3497 5.16434 13.8019 6.69259 15.3761C8.50188 17.2398 12.1804 21 12.1804 21L15 18.0956C15 18.0956 11.0759 14.1438 9.48241 12.5024C8.7331 11.7306 8.7331 10.4792 9.48241 9.7074C10.2219 8.94571 11.4147 8.9357 12.166 9.67734L14.9561 6.80344C13.3375 5.15927 11.0218 4.68106 8.99758 5.36871C8.92853 3.49655 7.38904 2 5.50001 2C3.56701 2 2.00001 3.56702 2.00001 5.5C2.00001 7.37354 3.4721 8.90323 5.32271 8.99558Z"
      fill="#7AC143"
    />
    <path
      d="M8.99557 26.6773C11.3497 27.4221 13.8019 26.8357 15.3761 25.3074C17.2398 23.4981 21 19.8196 21 19.8196L18.0956 17C18.0956 17 14.1438 20.9241 12.5024 22.5176C11.7306 23.2669 10.4792 23.2669 9.7074 22.5176C8.94571 21.7781 8.9357 20.5853 9.67734 19.834L6.80344 17.0439C5.15927 18.6625 4.68106 20.9782 5.36871 23.0024C3.49655 23.0715 2 24.611 2 26.5C2 28.433 3.56702 30 5.5 30C7.37353 30 8.90323 28.5279 8.99557 26.6773Z"
      fill="#5091CD"
    />
  </svg>
);
export default Joomla;
