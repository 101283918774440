import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-column-gap: 1.5rem;
  grid-template-rows: auto auto;
  grid-template-columns: 320px minmax(0, 1fr);
  padding-top: 2rem;
  row-gap: 2rem;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  height: 100vh;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Properties = styled.div`
  width: 100%;
  padding: 1rem;
`;
