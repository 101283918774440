import { IntegrationIconProps } from './types';

const GoogleDrive = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || '32'}
    height={dimension ? (dimension * 33) / 32 : '33'}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1334_1325)">
      <path
        d="M2 11.9556C2 8.47078 2 6.7284 2.67818 5.39739C3.27473 4.22661 4.22661 3.27473 5.39739 2.67818C6.7284 2 8.47078 2 11.9556 2H20.0444C23.5292 2 25.2716 2 26.6026 2.67818C27.7734 3.27473 28.7253 4.22661 29.3218 5.39739C30 6.7284 30 8.47078 30 11.9556V20.0444C30 23.5292 30 25.2716 29.3218 26.6026C28.7253 27.7734 27.7734 28.7253 26.6026 29.3218C25.2716 30 23.5292 30 20.0444 30H11.9556C8.47078 30 6.7284 30 5.39739 29.3218C4.22661 28.7253 3.27473 27.7734 2.67818 26.6026C2 25.2716 2 23.5292 2 20.0444V11.9556Z"
        fill="white"
      />
      <path
        d="M16.0019 12.4509L12.541 6.34322C12.6559 6.22622 12.7881 6.14948 12.9203 6.0979C11.8998 6.43379 11.4315 7.57986 11.4315 7.57986L5.10895 18.7348C5.01999 19.0845 4.99528 19.4003 5.0064 19.6783H11.9072L16.0019 12.4509Z"
        fill="#34A853"
      />
      <path
        d="M16.002 12.4509L20.0967 19.6783H26.9975C27.0086 19.4003 26.9839 19.0845 26.8949 18.7348L20.5724 7.57986C20.5724 7.57986 20.1029 6.43379 19.0835 6.0979C19.2145 6.14948 19.3479 6.22622 19.4628 6.34322L16.002 12.4509Z"
        fill="#FBBC05"
      />
      <path
        d="M16.0019 12.4512L19.4628 6.34346C19.3479 6.22646 19.2144 6.14973 19.0835 6.09815C18.9327 6.04908 18.7709 6.01637 18.5954 6.00757H18.4125H13.5913H13.4084C13.2342 6.01512 13.0711 6.04783 12.9203 6.09815C12.7894 6.14973 12.6559 6.22646 12.541 6.34346L16.0019 12.4512Z"
        fill="#188038"
      />
      <path
        d="M11.9082 19.6783L8.48687 25.7169C8.48687 25.7169 8.3732 25.6615 8.21875 25.547C8.70434 25.9207 9.17633 25.9999 9.17633 25.9999H22.6134C23.3547 25.9999 23.5092 25.7169 23.5092 25.7169C23.5116 25.7156 23.5129 25.7144 23.5153 25.7131L20.0965 19.6783H11.9082Z"
        fill="#4285F4"
      />
      <path
        d="M11.9086 19.6783H5.00781C5.04241 20.4986 5.39826 20.9779 5.39826 20.9779L5.65773 21.4283C5.67627 21.4547 5.68739 21.4698 5.68739 21.4698L6.25205 22.4611L7.51976 24.6677C7.55683 24.757 7.60008 24.8388 7.6458 24.9168C7.66309 24.9432 7.67915 24.9721 7.69769 24.9973C7.70263 25.0048 7.70757 25.0124 7.71252 25.0199C7.86944 25.2413 8.04489 25.4124 8.22034 25.547C8.37479 25.6628 8.48847 25.7169 8.48847 25.7169L11.9086 19.6783Z"
        fill="#1967D2"
      />
      <path
        d="M20.0977 19.6783H26.9984C26.9638 20.4986 26.608 20.9779 26.608 20.9779L26.3485 21.4283C26.33 21.4547 26.3188 21.4698 26.3188 21.4698L25.7542 22.4611L24.4865 24.6677C24.4494 24.757 24.4062 24.8388 24.3604 24.9168C24.3431 24.9432 24.3271 24.9721 24.3085 24.9973C24.3036 25.0048 24.2987 25.0124 24.2937 25.0199C24.1368 25.2413 23.9613 25.4124 23.7859 25.547C23.6314 25.6628 23.5178 25.7169 23.5178 25.7169L20.0977 19.6783Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1334_1325"
        x="-2"
        y="-1"
        width="36"
        height="36"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1334_1325"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1334_1325"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default GoogleDrive;
