import { CountryIconProps } from './types';

const BL = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M21.4265 9.65236H17.4787C17.4787 8.932 16.8947 8.34802 16.1744 8.34802L15.1309 10.435C15.1309 10.435 16.2439 13.5654 16.2091 13.5654H17.5135C18.2339 13.5654 18.8178 12.9815 18.8178 12.2611C19.5382 12.2611 20.1221 11.6771 20.1221 10.9567H20.0787C20.799 10.9567 21.4265 10.3728 21.4265 9.65236Z"
      fill="#ACABB1"
    />
    <path
      d="M2.57422 9.65224H6.52204C6.52204 8.93188 7.10601 8.3479 7.82637 8.3479L8.86987 10.4348C8.86987 10.4348 7.7568 13.5653 7.79161 13.5653H6.48727C5.76687 13.5653 5.18294 12.9814 5.18294 12.261C4.46253 12.261 3.8786 11.677 3.8786 10.9566H3.92204C3.20164 10.9566 2.57422 10.3726 2.57422 9.65224Z"
      fill="#ACABB1"
    />
    <path
      d="M16.174 16.6957V17.2174H7.82621V16.6957H5.73926V18.7826H7.82621V19.3043H16.174V18.7826H18.261V16.6957H16.174Z"
      fill="#FFDA44"
    />
    <path
      d="M7.82617 8.34851V13.5659C7.82617 16.7608 12.0001 17.7398 12.0001 17.7398C12.0001 17.7398 16.174 16.7608 16.174 13.5659V8.34851L12.0001 7.82678L7.82617 8.34851Z"
      fill="#0052B4"
    />
    <path d="M16.174 10.4342H7.82617V13.5646H16.174V10.4342Z" fill="#D80027" />
    <path
      d="M14.087 5.73881V6.52142L13.5653 6.78231L13.0436 6.26054V4.69531H10.9566V6.26054L10.4349 6.78231L9.91312 6.52142V5.73881H7.82617V8.34748H16.174V5.73881H14.087Z"
      fill="#FFDA44"
    />
  </svg>
);
export default BL;
