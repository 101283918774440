import { useEffect, useState, useRef } from 'react';
import { clamp } from '../utils';

export interface DragPosition {
  x: number;
  y: number;
}

interface useDraggableHandlers {
  onDragStart?: () => void;
  onDragEnd?: () => void;
}

interface useDraggableOptions {
  prevent?: boolean;
  useParentForDimensions?: boolean;
}

export function useDraggable<T extends HTMLElement = HTMLDivElement>(
  onChange: (value: DragPosition) => void,
  handlers?: useDraggableHandlers,
  options?: useDraggableOptions
) {
  const ref = useRef<T>(null);
  const isMounted = useRef<boolean>(false);
  const isDragging = useRef(false);
  const frame = useRef(0);
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    const onDrag = ({ x, y }: DragPosition) => {
      // Cancels previous animation so that there is no
      // delay and visual 'noise' in the dragging feedback
      cancelAnimationFrame(frame.current);

      frame.current = requestAnimationFrame(() => {
        if (!isMounted.current || !ref.current) return;

        ref.current.style.userSelect = 'none';

        let rect;
        if (options?.useParentForDimensions) {
          rect = ref.current.parentElement?.getBoundingClientRect();
        } else {
          rect = ref.current.getBoundingClientRect();
        }

        if (!rect || !rect.width || !rect.height) return;

        // Calculates the position of the element without extending
        // the dimensions of itself or its container
        onChange({
          x: clamp((x - rect.left) / rect.width, 0, 1),
          y: clamp((y - rect.top) / rect.height, 0, 1),
        });
      });
    };

    const bindEvents = () => {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', onTouchEnd);
    };

    const unbindEvents = () => {
      document.removeEventListener('touchend', onTouchEnd);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };

    const startDragging = () => {
      if (isDragging.current || !isMounted.current) return;

      isDragging.current = true;
      handlers?.onDragStart?.();
      setDragging(true);
      bindEvents();
    };

    const stopDragging = () => {
      if (!isDragging.current || !isMounted.current) return;
      isDragging.current = false;
      setDragging(false);
      unbindEvents();
      setTimeout(() => handlers?.onDragEnd?.(), 0);
    };

    // Handlers
    const onMouseDown = (event: MouseEvent) => {
      if (options?.prevent) event.preventDefault();
      startDragging();
      onMouseMove(event);
    };

    const onMouseMove = (event: MouseEvent) => {
      if (options?.prevent) event.preventDefault();
      onDrag({ x: event.clientX, y: event.clientY });
    };

    const onMouseUp = (event: MouseEvent) => {
      if (options?.prevent) event.preventDefault();
      stopDragging();
    };

    const onTouchStart = (event: TouchEvent) => {
      if (options?.prevent || event.cancelable) event.preventDefault();

      startDragging();
      onTouchMove(event);
    };

    const onTouchMove = (event: TouchEvent) => {
      if (options?.prevent || event.cancelable) event.preventDefault();

      onDrag({
        x: event.changedTouches[0].clientX,
        y: event.changedTouches[0].clientY,
      });
    };

    const onTouchEnd = (event: TouchEvent) => {
      if (options?.prevent || event.cancelable) event.preventDefault();
      stopDragging();
    };

    ref.current?.addEventListener('mousedown', onMouseDown);
    ref.current?.addEventListener('touchstart', onTouchStart, {
      passive: false,
    });

    return () => {
      if (!ref.current) return;
      ref.current.removeEventListener('mousedown', onMouseDown);
      ref.current.removeEventListener('touchstart', onTouchStart);
    };
  }, [onChange]);

  return { ref, dragging };
}

export default useDraggable;
