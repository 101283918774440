/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as SDK from '@replai-platform/sdk';
import styled from 'styled-components';
import { Skeleton } from '@replai-platform/ui-components';
import PlatformDecorator from '../../../../PlatformDecorator';
import PromotedObjectDecorator from '../../../../PromotedObjectDecorator';

type Props = {
  loading?: boolean;
  projectNetworks: SDK.Network[];
  possiblePromotedObjectTypes: SDK.PromotedObjectType[];
};

const NetworkAndOSContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  gap: 8px;
`;

const NetworkAndOS = ({ loading, projectNetworks, possiblePromotedObjectTypes }: Props): JSX.Element =>
  loading ? (
    <Skeleton height={20} width={125} />
  ) : (
    <NetworkAndOSContainer>
      <PlatformDecorator projectNetworks={projectNetworks} />
      <PromotedObjectDecorator possiblePromotedObjects={possiblePromotedObjectTypes} />
    </NetworkAndOSContainer>
  );

export default NetworkAndOS;
