import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getFrequency = async (requestParams: SDK.GetTagsFrequencyRequest): Promise<SDK.GetTagsFrequencyResponse> =>
  provider.getJson<object, SDK.GetTagsFrequencyResponse>(
    `${SDK.BASE_URL()}/tags/frequency?${SDK.convertRequestToQueryParams(requestParams)}`
  );

export default function useTagsFrequency<R = SDK.GetTagsFrequencyResponse>(
  requestParams: SDK.GetTagsFrequencyRequest,
  options?: UseQueryOptions<SDK.GetTagsFrequencyResponse, unknown, R>
) {
  return useQuery(['tag-frequency', requestParams], () => getFrequency(requestParams), options);
}
