import { messages } from '@replai-platform/sdk';
import { Colors, Badge, ColorTypes } from '@replai-platform/ui-components';
import styled from 'styled-components';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';

export type MetricAccessorType = {
  performance: number | null; // Raw performance
  goodness: number; // Contextualized performance
  disabled: boolean; // Disabled column
};

type Props = MetricAccessorType;

const GrayContainer = styled.span`
  color: ${Colors.Gray[400]};
`;

const MetricDecorator = ({ performance, goodness, disabled }: Props) => {
  if (!performance && performance !== 0) {
    return (
      <Badge color="Gray">
        {disabled ? <GrayContainer>{messages.NOT_AVAILABLE}</GrayContainer> : messages.NOT_AVAILABLE}
      </Badge>
    );
  }

  const color: ColorTypes = goodness === 0 ? 'Gray' : goodness > 0 ? 'Success' : 'Error';
  const leadingIcon: 'ArrowUp' | 'ArrowDown' = performance >= 0 ? 'ArrowUp' : 'ArrowDown';

  return (
    <Badge leadingIcon={leadingIcon} color={color}>
      {Math.abs(performance * 100).toFixed(1)}%
    </Badge>
  );
};

function getContextPerformanceMetricColumn<T extends object = MetricAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    width: 140,
    minWidth: 140,
    maxWidth: 140,
    Cell: (data: CellData<T, MetricAccessorType>) => (
      <MetricDecorator
        performance={data?.cell?.value.performance}
        goodness={data?.cell?.value.goodness ?? 0}
        disabled={data?.cell?.value?.disabled}
      />
    ),
  };
}

export default getContextPerformanceMetricColumn;
