import { IntegrationIconProps } from './types';

const AdobeIndesign = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="28" height="28" fill="#F9488E" />
    <rect x="3" y="3" width="26" height="26" fill="#2C0213" />
    <path
      d="M12.1444 22.8317C12.1444 22.9568 12.1249 23 11.987 23H10.1575C10.039 23 10 22.9375 10 22.8317V9.16826C10 9.04169 10.0585 9 10.1575 9H12.0065C12.1054 9 12.1444 9.04169 12.1444 9.14742V22.8317ZM14.2188 17.7973C14.2188 14.7522 16.131 12.4859 19.1379 12.4859C19.3929 12.4859 19.5309 12.4859 19.7664 12.5067V9.12657C19.7664 9.04318 19.8249 9 19.8848 9H21.823C21.9206 9 21.9415 9.04169 21.9415 9.10572V21.0106C21.9415 21.368 21.9415 21.8088 22 22.2912C22 22.3746 21.9749 22.3969 21.8969 22.4386C20.8755 22.9643 19.6983 23 18.677 23C15.4038 23 14.2288 20.6945 14.2188 17.7973ZM19.7664 14.5631C19.5894 14.4797 19.3539 14.4365 19.0585 14.4365C17.5258 14.4365 16.4515 15.6962 16.4515 17.7973C16.4515 20.1917 17.5453 21.1566 18.9206 21.1566C19.216 21.1566 19.5295 21.1149 19.765 21.0091L19.7664 14.5631Z"
      fill="#F9488E"
    />
  </svg>
);
export default AdobeIndesign;
