import { CountryIconProps } from './types';

const LB = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3655)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9997 -0.000732422C7.24218 -0.000732422 3.13143 2.76794 1.19043 6.78189H22.8089C20.868 2.76794 16.7572 -0.000732422 11.9997 -0.000732422Z"
        fill="#D80027"
      />
      <path
        d="M11.9997 23.9993C16.7572 23.9993 20.868 21.2306 22.809 17.2167H1.19043C3.13143 21.2306 7.24218 23.9993 11.9997 23.9993Z"
        fill="#D80027"
      />
      <path
        d="M15.131 14.0862L12.0006 8.34705L8.87012 14.0862H11.2179V15.6514H12.7832V14.0862H15.131Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3655">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default LB;
