import { useFeature } from '@optimizely/react-sdk';
import { Tab, Tabs, Button, Typography, camelCaseToCapitalCase } from '@replai-platform/ui-components';
import { useMemo } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useMount, useTitle } from 'react-use';
import styled from 'styled-components';
import FilterBar from '../../../components/FilterBar';
import { FiltersContainer } from '../../../components/FilterBar/common/styles';
import { type MarketAppsFilterProps, type CustomMarketTagsFilterProps } from '../../../components/FilterBar/Filters';
import useMarketAppsFilter from '../../../components/FilterBar/hooks/useMarketAppsFilter';
import useMarketTagsFilter from '../../../components/FilterBar/hooks/useMarketTagsFilter';
import TopNavPageTitle from '../../../components/TopNavPageTitle';
import { AppActions } from '../../../store/app';
import type { RootState } from '../../../store/rootReducer';
import { MarketActions, MarketTab } from '../../../store/market';
import { Page } from '../../../utils/enums';
import { RouteAnimator } from '../../RouteAnimator';
import { DATE_FILTER_BUTTONS } from '../constants';
import MarketVideosGrid from './VideosGrid';
import { dateFromDateRangeDaysBeforeYesterday, defaultEndDate, FilterActions } from '../../../store/filters';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const getTabTooltip = (tab: string) =>
  ({
    all: 'All available Market Videos',
    trending: 'Last 30d trending Market Videos',
    favourites: 'Favourite Market Videos creatives',
  }[tab] ?? '');

const getTabs = (availableTabs: MarketTab[]) =>
  Object.values(availableTabs).map((tab) => ({
    id: tab,
    label: camelCaseToCapitalCase(tab),
    tooltipContent: getTabTooltip(tab) ? (
      <Typography noMargin type="text-sm">
        {getTabTooltip(tab)}
      </Typography>
    ) : undefined,
  }));

const MarketVideosLibrary: React.VFC = () => {
  const dispatch = useDispatch();
  const onlyCompetitors = useSelector((state: RootState) => state.filters.onlyMarketCompetitors);
  const projectName = useSelector((state: RootState) => state.project.name);
  const compareMenuOpen = useSelector((state: RootState) => state.app.compareMenuOpen);
  const compareItems = useSelector((state: RootState) => state.app.compareItems);
  const currentTab = useSelector((state: RootState) => state.market.creativeGallery.currentVideoTab);
  const [isTechMerc1322Enabled] = useFeature('techmerc-1322'); // Enable non competitor data (all verticals on market)

  const availableTabs = useMemo(() => {
    const tabs: MarketTab[] = ['all', 'favourites'];
    return getTabs(tabs);
  }, []);

  useTitle(`Videos Library - ${projectName}`);

  const handleCompareButtonClick = () => {
    if (!compareMenuOpen) dispatch(AppActions.setCompareMenuOpen(true));
  };

  // App Filter
  const {
    marketAppsFilterOptions,
    marketAppsFilterOnChange,
    loading: marketAppsLoading,
    onClearClick,
  } = useMarketAppsFilter(Page.MarketVideosLibrary);

  const marketAppsFilter = useMemo(
    () =>
      ({
        options: marketAppsFilterOptions,
        onChange: marketAppsFilterOnChange,
        loading: marketAppsLoading,
        onClearClick,
      } as MarketAppsFilterProps),
    [marketAppsFilterOptions, marketAppsFilterOnChange, marketAppsLoading, onClearClick]
  );

  // Market Tags Filter
  const { marketTagsFilterOptions, marketTagsFilterOnChange, loading: marketTagsFilterLoading } = useMarketTagsFilter();
  const marketTagsFilter = useMemo(
    () =>
      ({
        options: marketTagsFilterOptions,
        onChange: marketTagsFilterOnChange,
        loading: marketTagsFilterLoading,
      } as CustomMarketTagsFilterProps),
    [marketTagsFilterOptions, marketTagsFilterOnChange, marketTagsFilterLoading]
  );

  const onTabChange = (tab: Tab) => {
    const rangeDays = 30;
    const startDate = dateFromDateRangeDaysBeforeYesterday(rangeDays);
    batch(() => {
      dispatch(
        FilterActions.changeDateRange({
          logEvent: false,
          startDate,
          endDate: defaultEndDate,
          rangeDays,
        })
      );
      dispatch(MarketActions.changeCurrentVideoTab({ tab: tab.id as MarketTab, logEvent: true }));
      dispatch(MarketActions.changeCurrentCreativesTablePage({ page: 1, logEvent: false }));
    });
  };

  useMount(() => {
    // [TECHBUG-1469]: force the date range to 30 days when navigating to the market pages since their data is older.
    const rangeDays = 30;
    const startDate = dateFromDateRangeDaysBeforeYesterday(rangeDays);
    dispatch(
      FilterActions.changeDateRange({
        logEvent: false,
        startDate,
        endDate: defaultEndDate,
        rangeDays,
      })
    );
  });

  return (
    <RouteAnimator data-test="market-creative-gallery-main-container">
      <Container>
        <TopNavPageTitle
          subtitle="Get a holistic view on the creatives your competitors are launching, or laser focus on a specific publisher or game/app."
          title="Creative Gallery"
        />
        <FiltersContainer data-test="market-videos-library-filters-container">
          <FilterBar
            eventPrefix="market-videos-library"
            marketAppsFilter={marketAppsFilter}
            customMarketTagsFilter={currentTab !== 'favourites' ? marketTagsFilter : undefined}
            dateFilter={{ customRanges: DATE_FILTER_BUTTONS }}
            onlyMarketCompetitors={{
              disabled: marketTagsFilterLoading,
              checked: onlyCompetitors,
              onChange: (value: boolean) => {
                // Unselect tags if we want all verticals
                if (!value) dispatch(FilterActions.changeMarketTags({ value: [], logEvent: false }));
                dispatch(FilterActions.changeOnlyMarketCompetitors({ value }));
              },
            }}
            withAgeFilter={currentTab !== 'favourites'}
            withDateFilter
            withCustomFilter={false}
            withCustomFilterLast
            withAddFilterButton={false}
            withOnlyMarketCompetitors={isTechMerc1322Enabled}
            customFilter={
              Object.keys(compareItems).length > 0 ? (
                <Button
                  leadingIcon={{ name: 'Scales' }}
                  style={{ marginLeft: '0.75rem' }}
                  onClick={handleCompareButtonClick}
                >
                  Compare {`(${Object.keys(compareItems).length})`}
                </Button>
              ) : undefined
            }
          />
        </FiltersContainer>
        <Tabs
          tabLabels={availableTabs}
          onTabChange={onTabChange}
          defaultSelectedTab={availableTabs.find(({ id }) => id === currentTab)}
        />
        <MarketVideosGrid />
      </Container>
    </RouteAnimator>
  );
};

export default MarketVideosLibrary;
