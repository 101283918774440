import { Color } from '..';

const generateGradient = (
  colors: Color[],
  type: 'conical' | 'linear',
  linearOptions: {
    originDeg?: number;
    percent?: number[];
  },
  conicalOptions?: {
    originDeg?: number;
    originX?: number;
    originY?: number;
    deg?: number[];
  }
) => {
  if (colors.length === 0) {
    throw new Error('At least one colors is required to generate a gradient');
  }

  if (type === 'conical') {
    const { originDeg, originX, originY, deg } = conicalOptions || {};
    let colorString = '';
    for (let i = 0; i < colors.length; i += 1) {
      colorString += ` ${colors[i]} ${deg ? deg[i] || 0 : 0}deg${
        i < colors.length - 1 ? ',' : ''
      }`;
    }
    const gradient = `conic-gradient(from ${originDeg || 0}deg at ${
      originX || 50
    }% ${originY || 50}%,${colorString})`;
    return gradient;
  }

  if (type === 'linear') {
    const { originDeg, percent } = linearOptions || {};
    let colorString = '';
    for (let i = 0; i < colors.length; i += 1) {
      colorString += ` ${colors[i]} ${percent ? percent[i] || 0 : 0}%${
        i < colors.length - 1 ? ',' : ''
      }`;
    }
    const gradient = `linear-gradient(${originDeg || 0}deg,${colorString})`;
    return gradient;
  }

  return '';
};

export default generateGradient;
