import { messages } from '@replai-platform/sdk';
import * as SDK from '@replai-platform/sdk';
import { NavigateFunction } from 'react-router';
import { logEvent } from '../../../../analytics';
import { formatDateDistance, isCreativeActive } from '../../../../utils';
import { Page } from '../../../../utils/enums';

export const formatMetrics = (
  conceptMetrics: SDK.GetConceptsMetricsResponse | undefined,
  navigate: NavigateFunction,
  projectId: SDK.UUID,
  baseMetric: SDK.Metrics,
  compareItems: { [key: string]: { id: string; imageUrl: string; name: string } }
) =>
  conceptMetrics?.concepts.map((concept) => ({
    id: concept.id,
    assetId: concept.assetId,
    conceptsCount: concept.conceptsCount ?? 0,
    recommendationNumber: 0,
    isActive: isCreativeActive(concept.maxDate ?? '', new Date().toDateString()),
    name: (concept.displayName || concept.name) ?? messages.NOT_AVAILABLE,
    age: formatDateDistance(new Date(concept.age ?? '')),
    keyMetric: SDK.kpiUtils.format(baseMetric, concept[baseMetric]),
    keyMetricLabel: SDK.kpiUtils.getDisplayName(baseMetric),
    keyMetricRatio:
      (concept[baseMetric] ?? 0) /
      ((concept[`total${SDK.capitalizeFirstLetter(baseMetric)}`] === 0
        ? 1
        : concept[`total${SDK.capitalizeFirstLetter(baseMetric)}`]) ?? 1),
    compareState: (compareItems[concept.id] !== undefined ? 'clicked' : 'default') as 'clicked' | 'default' | 'hovered',
    onCardClick: () => {
      navigate(`/${projectId}/${Page.Concepts}/${concept.id}/timeline`);
      logEvent({
        component: 'Concept Library',
        action: 'Click video card',
        category: 'user_actions',
        parameters: { value: concept.name, page: Page.ConceptsLibrary },
      });
    },
    onVideoPlay: () => {
      logEvent({
        component: `Concept Library`,
        action: 'Play video from video card',
        category: 'user_actions',
        parameters: { value: concept.name, page: Page.ConceptsLibrary },
      });
    },
    onVideoPause: () => {
      logEvent({
        component: `Concept Library`,
        action: 'Pause video from video card',
        category: 'user_actions',
        parameters: { value: concept.name, page: Page.ConceptsLibrary },
      });
    },
  }));
