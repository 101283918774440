import { Upload } from 'antd';
import { Colors, EmptyState, Modal } from '@replai-platform/ui-components';
import styled from 'styled-components';

export const Divider = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px solid ${Colors.Gray[200]};
`;

export const ConceptPrompt = styled.div`
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
`;

export const UploadContainer = styled(Upload)<{ hideUpload: boolean }>`
  .ant-upload-select-picture-card {
    ${({ hideUpload }) => (hideUpload ? 'display: none;' : '')}
  }
`;

export const UploadDragger = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    width: 80px;
    height: 80px;
  }
`;

export const StyledModal = styled(Modal)`
  &.tag-preview-modal {
    .ant-modal-header {
      border: 0;
    }

    .ant-modal-body {
      padding-top: 0rem;
    }
  }
`;

export const GridDisplay = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 100%;
  padding: 0.5rem 0 1rem 0;
`;

export const VideosContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin-left: 0;
  }
`;

export const GridEntry = styled.div`
  width: 50%;
  height: 39rem;
  padding: 0.8rem;
`;

export const VideosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const UnavailableVideo = styled(EmptyState)`
  padding-top: 5rem;
`;

export const CarouselContainer = styled.div`
  height: 100%;
`;

export const VideoContainer = styled(CarouselContainer)`
  display: grid;
  gap: 0.5rem;
`;

export const VideoThumbnailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 5rem;
  padding: 0.2rem;
  background-color: ${Colors.Gray[200]};
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px 0px;
`;

export const VideoThumbnail = styled.img`
  border-radius: 0.25rem;
  ${({ onClick }) => onClick && 'cursor: pointer;'};
`;

export const ImgBox = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
  }
`;
