import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Colors, ColorTypes } from '..';

export interface SkeletonProps {
  height?: number | string;
  width?: number | string;
  color?: ColorTypes;
  borderRadius?: number | string;
}

/**
 *
 * @param props all SkeletonProps available properties
 */
const SkeletonLoading = ({
  height,
  width,
  color = 'Gray',
  borderRadius,
}: SkeletonProps): JSX.Element => {
  const baseColor = Colors[color][50];
  const highlightColor = Colors[color][300];
  return (
    <Skeleton
      borderRadius={borderRadius}
      baseColor={baseColor}
      highlightColor={highlightColor}
      enableAnimation
      height={height}
      width={width}
    />
  );
};

export default SkeletonLoading;
