import styled from 'styled-components';
import { default as DefaultButton } from '../Button/Button';

export const PaginationRoot = styled.div`
  display: flex;
`;

export const PrevButton = styled(DefaultButton)`
  &.leftRightRoundedPreviousItem {
    display: flex;
    border-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &.previousItem {
    display: flex;
    margin-right: 5px;
    margin-left: 5px;
  }
`;

export const NextButton = styled(DefaultButton)`
  &.nextItem {
    display: flex;
    margin-right: 5px;
    margin-left: 5px;
  }

  &.leftRightRoundedNextItem {
    display: flex;
    border-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const PaginationContentButton = styled(DefaultButton)`
  &.paginationContent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &.leftRightRoundedPaginationContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0;
  }
`;

export const PaginationContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SimplePagination = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;

  &.sm {
    padding: 8px 14px;
    font-size: 14px;
  }

  &.md {
    padding: 10px 16px;
    font-size: 14px;
  }

  &.lg {
    padding: 10px 18px;
    font-size: 16px;
  }

  &.xl {
    padding: 12px 20px;
    font-size: 16px;
  }

  &.xxl {
    padding: 16px 28px;
    font-size: 18px;
  }
`;

export const DotsItem = styled(DefaultButton)`
  &.leftRightRoundedDotsItem {
    display: flex;
    border-radius: 0;
  }

  &.leftRightRoundedDotsItem:disabled {
    cursor: default;
  }

  &.dotsItem {
    display: flex;
  }

  &.dotsItem:disabled {
    cursor: default;
  }
`;
