import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import { BASE_URL, convertRequestToQueryParams } from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getPlayableContent = async (requestParams: SDK.GetPlayableContentRequest) => {
  const res = await provider.getJson<object, SDK.GetPlayableContentResponse>(
    `${BASE_URL()}/assets/playable/content?${convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function usePlayableContent(
  requestParams: SDK.GetPlayableContentRequest,
  options?: UseQueryOptions<SDK.GetPlayableContentResponse>
) {
  return useQuery(['playable-content', requestParams], () => getPlayableContent(requestParams), options);
}
