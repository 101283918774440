/* eslint-disable import/prefer-default-export */
import { InspireImage } from '@replai-platform/sdk';
import { NonDeletedExcalidrawElement } from '@replai/replai-excalidraw/types/element/types';
import { ExcalidrawImperativeAPI } from '@replai/replai-excalidraw/types/types';
import { UseMutationResult } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import { v4 as uuidv4 } from 'uuid';
import { GenerateImageRequestParamsAndBody } from '../../api/hooks/inspire/useGenerateImage';
import { importImagesIntoTheLibrary } from './utils';
import { GeneratedImageVariation } from './types';

export const generateImage = ({
  generateImageMutation,
  projectId,
  promptText,
  parentGenerativeFrame,
  inspireImagesInsideFrame,
  variationNumber,
  excalidrawAPI,
  setGeneratedImageVariations,
}: {
  generateImageMutation: UseMutationResult<
    SDK.GenerateImageResponse,
    unknown,
    GenerateImageRequestParamsAndBody,
    unknown
  >;
  projectId: string;
  promptText: string;
  parentGenerativeFrame: NonDeletedExcalidrawElement;
  inspireImagesInsideFrame: InspireImage[];
  variationNumber: number;
  excalidrawAPI: ExcalidrawImperativeAPI;
  setGeneratedImageVariations: React.Dispatch<React.SetStateAction<GeneratedImageVariation[]>>;
}) => {
  const generatedImageUuid = uuidv4();
  const id = `${generatedImageUuid}_${variationNumber}`;
  const genTechComponentGroupId = parentGenerativeFrame.groupIds[0];

  generateImageMutation.mutate(
    {
      requestParams: {
        projectId,
      },
      body: {
        prompt: promptText,
        generativeFrameHeight: parentGenerativeFrame?.height ?? 0,
        generativeFrameWidth: parentGenerativeFrame?.width ?? 0,
        images: inspireImagesInsideFrame,
      },
    },
    {
      onSuccess: (generatedImagesData) => {
        if (!excalidrawAPI) {
          return;
        }

        if (generatedImagesData && generatedImagesData.generatedImages?.length === 1) {
          importImagesIntoTheLibrary({
            excalidrawAPI,
            images: generatedImagesData.generatedImages.map((image) => ({
              id,
              b64: image.b64,
              width: parentGenerativeFrame?.width,
              height: parentGenerativeFrame?.height,
            })),
          });
        }
      },
      onError: (e) => {
        console.error('Error happened!', e);
      },
      onSettled: (generatedImagesData) => {
        const requestFailed = generatedImagesData?.generatedImages?.length !== 1;

        setGeneratedImageVariations((prevValues) => {
          const variationsFromOtherGenTechFrames = prevValues.filter(
            (val) => val.genTechComponentGroupId !== genTechComponentGroupId
          );

          const variationsFromThisGenTechFrame = prevValues.filter(
            (val) => val.genTechComponentGroupId === genTechComponentGroupId
          );

          const variationFromThisRequest = variationsFromThisGenTechFrame.find((val) => val.n === variationNumber);

          if (!variationFromThisRequest) {
            return prevValues;
          }

          const otherVariationsFromThisGenTechFrame = variationsFromThisGenTechFrame.filter(
            (val) => val.n !== variationNumber
          );

          const variationNumberToShow =
            !requestFailed && otherVariationsFromThisGenTechFrame.every((val) => val.loading || !val.id)
              ? variationNumber
              : variationsFromThisGenTechFrame.find((val) => val.beingShown)?.n;

          return [
            ...variationsFromOtherGenTechFrames,
            ...otherVariationsFromThisGenTechFrame.map((variation) => ({
              ...variation,
              beingShown: variation.n === (variationNumberToShow ?? 0),
            })),
            {
              ...variationFromThisRequest,
              id: requestFailed ? undefined : id,
              loading: false,
              beingShown: variationNumber === (variationNumberToShow ?? 0),
            },
          ];
        });
      },
    }
  );
};
