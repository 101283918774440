import styled from 'styled-components';
import { default as DefaultButton } from '../Button/Button';

export const Button = styled(DefaultButton)`
  max-width: 300px;
  margin-left: 0.75rem;
  width: fit-content;
`;

export const ButtonContent = styled.span`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

export const ButtonText = styled.span`
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropDownChipContainer = styled.span`
  display: flex;
  flex-direction: column;
`;

export const DropDownContainer = styled.div<{
  $position?: string;
  $left?: number | null;
  $top?: number | null;
}>`
  z-index: 300;
  margin-top: 1rem;

  position: ${(props) => props.$position ?? 'absolute'};
  top: ${(props) => (props.$top ? `${props.$top}px` : 0)};
  left: ${(props) => (props.$left ? `${props.$left}px` : 0)};
`;
