import { Collapse, Steps } from 'antd';
import { ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FlatSocialIconTypes, getFlatIcon, Tabs, Typography } from '..';
import { ModalButton, ModalButtonsContainer } from '..';
import * as Styled from './styles';

const allTabs = ['details', 'faqs', 'guide'] as const;

type Tab = (typeof allTabs)[number];

export interface NetworkConnectionGuideProps {
  title: string;
  icon?: FlatSocialIconTypes;

  details?: { label: string; content: ReactNode };
  guide?: {
    label: string;
    steps: { title: string; description: string; content: ReactNode }[];
  };
  faqs?: {
    label: string;
    content: { title: string; description: ReactNode }[];
  };

  isOpen: boolean;
  onClose: () => void;
  onTabChange: (tab: string) => void;
}

const NetworkConnectionGuideContent = styled.div<{ height?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65rem;
  margin-top: 1.5rem;
  ${(props) => props.height && `height: ${props.height};`}
`;

const renderIcon = (icon?: FlatSocialIconTypes) => {
  if (icon) {
    const Icon = getFlatIcon(icon);
    return <Icon />;
  } else {
    return undefined;
  }
};

const NetworkConnectionGuide = ({
  title,
  icon,
  guide,
  details,
  faqs,
  isOpen,
  onClose,
  onTabChange,
}: NetworkConnectionGuideProps) => {
  const [currentStep, setCurrentStep] = useState(0);

  // All available tabs taking into consideration the filled options
  const availableTabs: Tab[] = useMemo(() => {
    const tabs: Tab[] = [];
    if (details?.content) tabs.push('details');
    if (faqs?.content.length ?? 0 >= 1) tabs.push('faqs');
    if (guide?.steps.length ?? 0 >= 1) tabs.push('guide');
    return tabs;
  }, [guide, details, faqs]);

  const [currentTab, setCurrentTab] = useState<Tab>(
    availableTabs[0] ?? 'details'
  );

  // Key value object with step options content
  const guideContentMap = useMemo(() => {
    return guide?.steps.reduce((acc, step, index) => {
      acc[index] = step.content;
      return acc;
    }, {} as { [key: string]: ReactNode });
  }, [guide]);

  const showTabContent = (tab: Tab) => {
    switch (tab) {
      case 'faqs':
        return (
          <Collapse style={{ borderRadius: '5px', marginTop: '1rem' }}>
            {faqs?.content.map((faq, index) => (
              <Collapse.Panel header={faq.title} key={index}>
                {faq.description}
              </Collapse.Panel>
            ))}
          </Collapse>
        );
      case 'guide':
        return (
          <>
            <Steps
              style={{ marginBottom: '1rem' }}
              current={currentStep}
              onChange={(step) => setCurrentStep(step)}
            >
              {guide?.steps.map((step) => (
                <Steps.Step
                  key={JSON.stringify(step)}
                  title={step.title}
                  description={step.description}
                />
              ))}
            </Steps>
            {guideContentMap?.[currentStep]}
          </>
        );
      case 'details':
        return details?.content;
      default:
        return undefined;
    }
  };

  const changeTab = (index: number) => {
    onTabChange(availableTabs[index]);
    setCurrentTab(availableTabs[index]);
  };

  const getTabLabel = (tab: Tab) => {
    switch (tab) {
      case 'details':
        return { label: `${details?.label}` };
      case 'faqs':
        return { label: `${faqs?.label}` };
      case 'guide':
        return { label: `${guide?.label}` };
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = tab;
        return _exhaustiveCheck;
    }
  };

  return (
    <Styled.Modal
      width={'80rem'}
      className="replai-network-connection-guide-ant-modal"
      isOpen={isOpen}
      closable={false}
      modalHeader={
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          {renderIcon(icon)}
          <Typography type="text-xl" style={{ marginBottom: 0 }}>
            {title}
          </Typography>
        </div>
      }
      onClose={onClose}
      modalFooter={
        <ModalButtonsContainer>
          {guide && currentTab === 'guide' ? (
            <>
              <ModalButton
                disabled={currentStep <= 0}
                leadingIcon={{ name: 'ArrowLeft' }}
                variation="primary"
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                Previous
              </ModalButton>
              <ModalButton
                disabled={currentStep + 1 >= guide.steps.length}
                leadingIcon={{ name: 'ArrowRight' }}
                variation="primary"
                onClick={() => setCurrentStep(currentStep + 1)}
              >
                Next
              </ModalButton>
            </>
          ) : undefined}
        </ModalButtonsContainer>
      }
    >
      {availableTabs.length > 1 ? (
        <Tabs
          onTabChange={(_, index) => changeTab(index)}
          widthVariant="long"
          tabLabels={availableTabs.map((tab: Tab) => getTabLabel(tab))}
        />
      ) : undefined}

      <NetworkConnectionGuideContent
        height={currentTab === 'guide' ? '32rem' : undefined}
      >
        {showTabContent(currentTab)}
      </NetworkConnectionGuideContent>
    </Styled.Modal>
  );
};

export default NetworkConnectionGuide;
