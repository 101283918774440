import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import * as SDK from '@replai-platform/sdk';
import type { RootState } from '../../../store/rootReducer';
import { FilterActions } from '../../../store/filters';
import useMarketApps from '../../../api/hooks/market/useMarketApps';
import { MarketAppsFilterProps } from '../Filters/MarketAppsFilter';
import { Page } from '../../../utils/enums';
import { MarketActions } from '../../../store/market';

const useMarketAppsFilter = (
  page?: Page
): {
  marketAppsFilterOptions: MarketAppsFilterProps['options'];
  marketAppsFilterOnChange: (options: string[]) => void;
  loading: boolean;
  onClearClick: () => void;
} => {
  const dispatch = useDispatch();
  const allMarketApps = useSelector((state: RootState) => state.market.marketApps);

  const marketAppsToConsider = useSelector((state: RootState) => state.filters.marketAppsToConsider);
  const marketAppsToExclude = useSelector((state: RootState) => state.filters.marketAppsToExclude);

  const onlyCompetitors = useSelector((state: RootState) => state.filters.onlyMarketCompetitors);
  const organizationName = useSelector((state: RootState) => state.project.organizationName);
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectMarketGenres = useSelector((state: RootState) => state.project.config.marketGenres);
  const isInitialFilter = useRef<boolean>(true);

  const [everyOptionSelected, setEveryOptionSelected] = useState(false);

  // Fetch and update market apps
  const { data: fetchedMarketApps, isLoading } = useMarketApps({
    appsFilters: {
      publishersToExclude: [organizationName],
      ...(onlyCompetitors && projectMarketGenres ? { genres: projectMarketGenres } : {}),
    }, // TODO: Missing 'newApps' here!
    projectId,
  });

  useEffect(() => {
    if (fetchedMarketApps && !isLoading) {
      dispatch(MarketActions.changeMarketApps({ apps: fetchedMarketApps }));
      isInitialFilter.current = false;
    }
  }, [dispatch, fetchedMarketApps, isLoading, page]);

  // Create filter options
  const marketAppsFilterOptions = useMemo<MarketAppsFilterProps['options']>(
    () =>
      !!fetchedMarketApps?.length || !!marketAppsToConsider.length || !!marketAppsToExclude.length
        ? [
            ...(fetchedMarketApps ?? marketAppsToConsider ?? marketAppsToExclude).map((marketApp) => {
              const toExcludeHasOptions = !!marketAppsToExclude?.length;

              const selected = everyOptionSelected
                ? true
                : toExcludeHasOptions
                ? !marketAppsToExclude.find((ma) => ma.genreName === marketApp.genreName && ma.name === marketApp.name)
                : !!marketAppsToConsider.find(
                    (ma) => ma.genreName === marketApp.genreName && ma.name === marketApp.name
                  );

              return {
                id: marketApp.id,
                name: marketApp.name,
                genreName: marketApp.genreName,
                selected,
              };
            }),
          ]
        : [],
    [everyOptionSelected, fetchedMarketApps, marketAppsToConsider, marketAppsToExclude]
  );

  const marketAppsFilterOnChange = useCallback(
    (options: string[]) => {
      const selectedOptions = options
        .map((option) => (JSON.parse(option) as SDK.MarketApp) ?? '')
        .filter((option) => option?.genreName?.length > 0);

      const unselectedOptions =
        allMarketApps?.filter((app) => !selectedOptions.some((selectedOption) => selectedOption.id === app.id)) ?? [];

      setEveryOptionSelected(selectedOptions.length === allMarketApps.length);

      if (selectedOptions.length <= unselectedOptions.length) {
        batch(() => {
          dispatch(
            FilterActions.changeMarketAppsToConsider({
              value: selectedOptions,
              page,
              logEvent: true,
            })
          );
          dispatch(
            FilterActions.changeMarketAppsToExclude({
              value: [],
              page,
              logEvent: true,
            })
          );
        });
      } else {
        batch(() => {
          dispatch(
            FilterActions.changeMarketAppsToConsider({
              value: [],
              page,
              logEvent: true,
            })
          );
          dispatch(
            FilterActions.changeMarketAppsToExclude({
              value: unselectedOptions,
              page,
              logEvent: true,
            })
          );
        });
      }
    },
    [dispatch, page, allMarketApps]
  );

  const onClearClick = useCallback(() => {
    batch(() => {
      dispatch(
        FilterActions.changeMarketAppsToConsider({
          value: [],
          logEvent: true,
        })
      );
      dispatch(
        FilterActions.changeMarketAppsToExclude({
          value: [],
          logEvent: true,
        })
      );
      setEveryOptionSelected(false);
    });
  }, [dispatch]);

  return { marketAppsFilterOptions, marketAppsFilterOnChange, loading: isLoading, onClearClick };
};

export default useMarketAppsFilter;
