import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getAssetRetention = async (requestParams: SDK.GetAssetRetentionInfoRequest, assetId: SDK.UUID) => {
  const res = await provider.getJson<object, SDK.GetAssetRetentionInfoResponse>(
    `${SDK.BASE_URL()}/assets/${assetId}/retention?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useAssetRetention(
  assetId: SDK.UUID,
  requestParams: SDK.GetAssetRetentionInfoRequest,
  options?: UseQueryOptions<SDK.GetAssetRetentionInfoResponse>
) {
  return useQuery(
    ['asset-retention', assetId, requestParams],
    () => getAssetRetention(requestParams, assetId),
    options
  );
}
