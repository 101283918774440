import { CountryIconProps } from './types';

const SO = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#338AF3"
    />
    <path
      d="M12.0006 6.26062L13.2956 10.2464H17.4864L14.096 12.7096L15.391 16.6954L12.0006 14.2321L8.6101 16.6954L9.90516 12.7096L6.51465 10.2464H10.7055L12.0006 6.26062Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default SO;
