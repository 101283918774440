// TODO: Export button padding from button component. Waiting for SC migration.

// Button horizontal padding in pixels
const BUTTON_HORIZONTAL_PADDING = 2 * 20;
// Aprox width of a character in pixels
const PIXEL_PER_CHAR = 8;
// Width of the 3 dots icon in pixels
export const ELLIPSIS_ICON_WIDTH = 24;

export const getTabSize = (label: string) => {
  return label.length * PIXEL_PER_CHAR + BUTTON_HORIZONTAL_PADDING;
};

export const computeNumberOfTabsToRender = (
  labels: string[],
  containerSizeInPixels: number,
  lastTabLabel?: string
) => {
  let numberOfTabsToRender = lastTabLabel ? 1 : 0;
  let tabsWidth = lastTabLabel ? getTabSize(lastTabLabel) : 0;
  for (let i = 0; i < labels.length; i++) {
    const tabSize = getTabSize(labels[i]);
    if (tabsWidth + tabSize > containerSizeInPixels) {
      break;
    }
    tabsWidth += tabSize;
    numberOfTabsToRender++;
  }
  return numberOfTabsToRender;
};
