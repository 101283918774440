import styled, { css } from 'styled-components';
import Colors from '../Colors';

const Sizes = css`
  &.sm {
    grid-column-gap: 8px;
  }

  &.md {
    grid-column-gap: 12px;
  }

  &.lg {
    grid-column-gap: 12px;
  }

  &.sm .input {
    width: 64px;
    height: 64px;
    padding: 0 16px;
    font-size: 48px;
  }

  &.md .input {
    width: 80px;
    height: 80px;
    padding: 0 24px;
    font-size: 48px;
  }

  &.lg .input {
    width: 96px;
    height: 96px;
    padding: 0 28px;
    font-size: 60px;
  }
`;

export const CodeHolderRoot = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: max-content;

  ${Sizes}
`;

export const SixDigitCodeHolder = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 1fr max-content 1fr;
  align-items: center;
  width: max-content;
  color: ${Colors.Gray[300]};

  span {
    display: inline-block;
    font-size: 60px;
  }
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: ${Colors.Gray[300]};
  border: 1px solid ${Colors.Gray[300]};
  border-radius: 8px;
  outline: none;
  -moz-appearance: textfield;
  -webkit-appearance: none;

  ::placeholder {
    color: #d0d5dd;
    font-weight: 500;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -moz-appearance: textfield;
    -webkit-appearance: none;
  }
`;

export const SixDigitCode = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: max-content;

  ${Sizes}
`;

export const Label = styled.label`
  display: inline-block;
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 14px;
  color: ${Colors.Gray[700]};
`;

export const Hint = styled.div`
  margin-top: 6px;
  font-size: 14px;
  color: ${Colors.Gray[500]};
`;
