import styled from 'styled-components';
import Colors from '../Colors';
import { default as DefaultTypography } from '../Typography/Typography';

export const CollapsableTitle = styled.span`
  color: ${Colors.Primary[700]};
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
`;

export const OptionsContainer = styled.div<{ borderTop: boolean }>`
  overflow: auto;
  border-top: ${(props) =>
    props.borderTop ? `1px solid ${Colors.Gray[100]}` : 'none'};
`;

export const TitleContainer = styled.span`
  margin: 0px 0px -4px 16px;
`;

export const Typography = styled(DefaultTypography)`
  display: inline-block;
  max-width: 75%;
  margin-top: 20px;
  vertical-align: center;
`;

export const CloseIconContainer = styled.span`
  float: right;
  margin: 8px 8px;
`;

export const InputContainer = styled.div`
  &.inputContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 16px;
  }

  &.inputContainer > div {
    width: 100%;
  }
`;

export const Root = styled.div`
  &.root {
    display: flex;
    flex-direction: column;
    min-width: 350px;
    max-height: 500px;
    overflow: hidden;
    background-color: ${Colors.Common.White};
    border-radius: 8px;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  }

  &.rootWithTitle {
    display: flex;
    flex-direction: column;
    min-width: 400px;
    max-height: 500px;
    overflow: hidden;
    background-color: ${Colors.Common.White};
    border-radius: 8px;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${Colors.Gray[100]};
`;

export const AvatarContainer = styled.div`
  display: flex;
  margin-right: 8px;
`;

export const IconOption = styled.div`
  display: inline;
`;

export const IconContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 14px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 16px;
  color: ${Colors.Gray[700]};
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover,
  &.selected {
    color: ${Colors.Primary[700]};
  }

  &.selected {
    background: ${Colors.Primary[50]};
  }
`;

export const NoMatches = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
`;

export const SubLabel = styled.span`
  color: #6b7280;
  font-size: 12px;
  white-space: nowrap;
`;
