import { IntegrationIconProps } from './types';

const StumbleUpon = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16Z"
      fill="#EB4823"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 9C13.7778 9 11.9765 10.8182 11.9765 13.0611V18.8321C11.9765 19.3633 11.5499 19.7939 11.0235 19.7939C10.4972 19.7939 10.0706 19.3633 10.0706 18.8321V16.2672H7V18.9389C7 21.1818 8.8014 23 11.0235 23C13.2457 23 15.0471 21.1818 15.0471 18.9389V13.0611C15.0471 12.5298 15.4737 12.0992 16 12.0992C16.5263 12.0992 16.9529 12.5298 16.9529 13.0611V14.2366L18.3748 15.0151L20.0235 14.2366V13.0611C20.0235 10.8182 18.2222 9 16 9ZM16.9529 16.3206V18.9389C16.9529 21.1818 18.7543 23 20.9765 23C23.1986 23 25 21.1818 25 18.9389V16.374H21.9294V18.9389C21.9294 19.4702 21.5028 19.9008 20.9765 19.9008C20.4501 19.9008 20.0235 19.4702 20.0235 18.9389V16.3206L18.2235 16.9084L16.9529 16.3206Z"
      fill="white"
    />
  </svg>
);
export default StumbleUpon;
