/* eslint-disable react/jsx-props-no-spreading */
import { SocialIconProps } from './types';

const AppLovin = ({ dimension, ...props }: SocialIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 421 356"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        fill="#fdfdfd"
        d="M 85.5,24.5 C 89.5138,24.3345 93.5138,24.5012 97.5,25C 98.4637,29.0659 98.797,33.2325 98.5,37.5C 94.4862,37.6655 90.4862,37.4988 86.5,37C 85.5363,32.9341 85.203,28.7675 85.5,24.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fdfdfd"
        d="M 295.5,24.5 C 299.514,24.3345 303.514,24.5012 307.5,25C 308.464,29.0659 308.797,33.2325 308.5,37.5C 304.486,37.6655 300.486,37.4988 296.5,37C 295.536,32.9341 295.203,28.7675 295.5,24.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fdfdfd"
        d="M 400.5,24.5 C 404.514,24.3345 408.514,24.5012 412.5,25C 413.464,29.0659 413.797,33.2325 413.5,37.5C 409.486,37.6655 405.486,37.4988 401.5,37C 400.536,32.9341 400.203,28.7675 400.5,24.5 Z"
      />
    </g>
    <g>
      <path
        fill="#0f9ac5"
        d="M 288.5,182.5 C 295.44,196.092 302.773,209.425 310.5,222.5C 316.481,234.462 322.981,246.129 330,257.5C 331.648,256.283 333.481,255.45 335.5,255C 343.994,253.934 352.328,254.601 360.5,257C 378.486,265.983 386.653,280.483 385,300.5C 378.094,326.557 361.261,337.557 334.5,333.5C 329.772,332.302 325.439,330.302 321.5,327.5C 320.71,326.599 319.71,325.932 318.5,325.5C 310.889,317.942 306.222,308.942 304.5,298.5C 303.764,288.171 306.431,278.838 312.5,270.5C 298.064,266.58 283.397,263.746 268.5,262C 225.136,257.195 181.803,257.528 138.5,263C 127.029,264.694 115.696,267.027 104.5,270C 114.59,285.471 115.09,301.305 106,317.5C 103.715,320.432 101.215,323.098 98.5,325.5C 86.8255,334.384 73.8255,336.884 59.5,333C 37.3086,322.783 28.8086,305.616 34,281.5C 42.8578,261.151 58.3578,252.318 80.5,255C 83.1944,256.19 85.8611,256.356 88.5,255.5C 117.08,203.339 145.913,151.339 175,99.5C 175.667,98.5 175.667,97.5 175,96.5C 167.747,83.6275 166.747,70.2942 172,56.5C 185.989,33.9497 205.156,28.1164 229.5,39C 250.875,55.2994 254.875,75.1327 241.5,98.5C 257.194,126.562 272.861,154.562 288.5,182.5 Z"
      />
    </g>
    <g>
      <path
        fill="#f3f4f5"
        d="M 202.5,51.5 C 222.083,48.9087 231.916,57.5753 232,77.5C 230.131,86.5938 224.965,92.9271 216.5,96.5C 216.5,94.1667 216.5,91.8333 216.5,89.5C 212.167,89.5 207.833,89.5 203.5,89.5C 203.5,85.5 203.5,81.5 203.5,77.5C 207.833,77.5 212.167,77.5 216.5,77.5C 216.5,72.8333 216.5,68.1667 216.5,63.5C 212.167,63.5 207.833,63.5 203.5,63.5C 203.817,59.2984 203.483,55.2984 202.5,51.5 Z"
      />
    </g>
    <g>
      <path
        fill="#f9f9fa"
        d="M 202.5,51.5 C 203.483,55.2984 203.817,59.2984 203.5,63.5C 207.833,63.5 212.167,63.5 216.5,63.5C 216.5,68.1667 216.5,72.8333 216.5,77.5C 212.167,77.5 207.833,77.5 203.5,77.5C 203.5,81.5 203.5,85.5 203.5,89.5C 207.833,89.5 212.167,89.5 216.5,89.5C 216.5,91.8333 216.5,94.1667 216.5,96.5C 201.675,99.9526 191.508,94.6192 186,80.5C 183.351,66.0233 188.851,56.3567 202.5,51.5 Z"
      />
    </g>
    <g>
      <path
        fill="#f6f6f6"
        d="M 253.5,156.5 C 250.167,156.5 246.833,156.5 243.5,156.5C 243.5,160.5 243.5,164.5 243.5,168.5C 238.833,168.5 234.167,168.5 229.5,168.5C 229.5,173.167 229.5,177.833 229.5,182.5C 234.167,182.5 238.833,182.5 243.5,182.5C 243.5,186.5 243.5,190.5 243.5,194.5C 238.833,194.5 234.167,194.5 229.5,194.5C 229.5,199.167 229.5,203.833 229.5,208.5C 234.167,208.5 238.833,208.5 243.5,208.5C 243.5,212.5 243.5,216.5 243.5,220.5C 247.5,220.5 251.5,220.5 255.5,220.5C 255.5,216.5 255.5,212.5 255.5,208.5C 260.167,208.5 264.833,208.5 269.5,208.5C 269.5,203.833 269.5,199.167 269.5,194.5C 264.833,194.5 260.167,194.5 255.5,194.5C 255.5,190.5 255.5,186.5 255.5,182.5C 259.5,182.5 263.5,182.5 267.5,182.5C 280.392,204.608 293.059,226.942 305.5,249.5C 305,249.833 304.5,250.167 304,250.5C 256.862,241.61 209.362,239.11 161.5,243C 144.961,244.275 128.627,246.775 112.5,250.5C 112.167,250.167 111.833,249.833 111.5,249.5C 137.5,202.833 163.5,156.167 189.5,109.5C 195.55,112.877 202.05,114.544 209,114.5C 215.687,113.864 222.187,112.697 228.5,111C 236.973,126.114 245.306,141.28 253.5,156.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fdfdfd"
        d="M 85.5,129.5 C 89.5138,129.334 93.5138,129.501 97.5,130C 98.4637,134.066 98.797,138.233 98.5,142.5C 94.4862,142.666 90.4862,142.499 86.5,142C 85.5363,137.934 85.203,133.767 85.5,129.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fdfdfd"
        d="M 190.5,129.5 C 194.514,129.334 198.514,129.501 202.5,130C 203.464,134.066 203.797,138.233 203.5,142.5C 199.486,142.666 195.486,142.499 191.5,142C 190.536,137.934 190.203,133.767 190.5,129.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fdfdfd"
        d="M 295.5,129.5 C 299.514,129.334 303.514,129.501 307.5,130C 308.464,134.066 308.797,138.233 308.5,142.5C 304.486,142.666 300.486,142.499 296.5,142C 295.536,137.934 295.203,133.767 295.5,129.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fdfdfd"
        d="M 400.5,129.5 C 404.514,129.334 408.514,129.501 412.5,130C 413.464,134.066 413.797,138.233 413.5,142.5C 409.486,142.666 405.486,142.499 401.5,142C 400.536,137.934 400.203,133.767 400.5,129.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fafafb"
        d="M 253.5,156.5 C 258.672,164.84 263.338,173.507 267.5,182.5C 263.5,182.5 259.5,182.5 255.5,182.5C 255.5,186.5 255.5,190.5 255.5,194.5C 260.167,194.5 264.833,194.5 269.5,194.5C 269.5,199.167 269.5,203.833 269.5,208.5C 264.833,208.5 260.167,208.5 255.5,208.5C 255.5,212.5 255.5,216.5 255.5,220.5C 251.5,220.5 247.5,220.5 243.5,220.5C 243.5,216.5 243.5,212.5 243.5,208.5C 238.833,208.5 234.167,208.5 229.5,208.5C 229.5,203.833 229.5,199.167 229.5,194.5C 234.167,194.5 238.833,194.5 243.5,194.5C 243.5,190.5 243.5,186.5 243.5,182.5C 238.833,182.5 234.167,182.5 229.5,182.5C 229.5,177.833 229.5,173.167 229.5,168.5C 234.167,168.5 238.833,168.5 243.5,168.5C 243.5,164.5 243.5,160.5 243.5,156.5C 246.833,156.5 250.167,156.5 253.5,156.5 Z"
      />
    </g>
    <g>
      <path
        fill="#efefef"
        d="M 150.5,182.5 C 155.167,182.5 159.833,182.5 164.5,182.5C 164.5,186.5 164.5,190.5 164.5,194.5C 159.833,194.5 155.167,194.5 150.5,194.5C 150.5,190.5 150.5,186.5 150.5,182.5 Z"
      />
    </g>
    <g>
      <path
        fill="#eef0f0"
        d="M 288.5,182.5 C 290.833,182.5 293.167,182.5 295.5,182.5C 295.5,186.5 295.5,190.5 295.5,194.5C 299.833,194.5 304.167,194.5 308.5,194.5C 308.5,199.167 308.5,203.833 308.5,208.5C 312.833,208.5 317.167,208.5 321.5,208.5C 321.5,212.5 321.5,216.5 321.5,220.5C 317.351,219.92 313.684,220.586 310.5,222.5C 302.773,209.425 295.44,196.092 288.5,182.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fdfdfd"
        d="M 400.5,234.5 C 404.514,234.334 408.514,234.501 412.5,235C 413.464,239.066 413.797,243.233 413.5,247.5C 409.486,247.666 405.486,247.499 401.5,247C 400.536,242.934 400.203,238.767 400.5,234.5 Z"
      />
    </g>
    <g>
      <path
        fill="#f6f7f7"
        d="M 304.5,298.5 C 301.713,299.477 298.713,299.81 295.5,299.5C 295.5,304.167 295.5,308.833 295.5,313.5C 299.833,313.5 304.167,313.5 308.5,313.5C 308.5,317.5 308.5,321.5 308.5,325.5C 311.833,325.5 315.167,325.5 318.5,325.5C 319.71,325.932 320.71,326.599 321.5,327.5C 321.5,331.5 321.5,335.5 321.5,339.5C 317.167,339.5 312.833,339.5 308.5,339.5C 308.5,335.167 308.5,330.833 308.5,326.5C 304.486,326.334 300.486,326.501 296.5,327C 295.536,331.066 295.203,335.233 295.5,339.5C 291.486,339.334 287.486,339.501 283.5,340C 282.536,344.066 282.203,348.233 282.5,352.5C 286.833,352.5 291.167,352.5 295.5,352.5C 295.5,353.5 295.5,354.5 295.5,355.5C 229.833,355.5 164.167,355.5 98.5,355.5C 98.5,354.5 98.5,353.5 98.5,352.5C 102.767,352.797 106.934,352.464 111,351.5C 111.499,347.514 111.666,343.514 111.5,339.5C 107.167,339.5 102.833,339.5 98.5,339.5C 98.5,334.833 98.5,330.167 98.5,325.5C 101.215,323.098 103.715,320.432 106,317.5C 115.09,301.305 114.59,285.471 104.5,270C 115.696,267.027 127.029,264.694 138.5,263C 181.803,257.528 225.136,257.195 268.5,262C 283.397,263.746 298.064,266.58 312.5,270.5C 306.431,278.838 303.764,288.171 304.5,298.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fefefe"
        d="M 334.5,273.5 C 334.833,273.5 335.167,273.5 335.5,273.5C 339.833,273.5 344.167,273.5 348.5,273.5C 348.5,278.167 348.5,282.833 348.5,287.5C 343.833,287.5 339.167,287.5 334.5,287.5C 334.5,282.833 334.5,278.167 334.5,273.5 Z"
      />
    </g>
    <g>
      <path
        fill="#edeeef"
        d="M 334.5,273.5 C 334.5,278.167 334.5,282.833 334.5,287.5C 330.5,287.5 326.5,287.5 322.5,287.5C 324.689,281.309 328.689,276.642 334.5,273.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fdfdfe"
        d="M 322.5,287.5 C 326.5,287.5 330.5,287.5 334.5,287.5C 334.5,291.5 334.5,295.5 334.5,299.5C 330.5,299.5 326.5,299.5 322.5,299.5C 321.209,295.601 321.209,291.601 322.5,287.5 Z"
      />
    </g>
    <g>
      <path
        fill="#efefef"
        d="M 334.5,287.5 C 339.167,287.5 343.833,287.5 348.5,287.5C 348.5,291.5 348.5,295.5 348.5,299.5C 343.833,299.5 339.167,299.5 334.5,299.5C 334.5,295.5 334.5,291.5 334.5,287.5 Z"
      />
    </g>
    <g>
      <path
        fill="#f4f5f5"
        d="M 65.5,271.5 C 85.1541,269.334 95.3208,278.001 96,297.5C 91.6346,313.859 81.1346,320.359 64.5,317C 49.8174,309.284 45.6508,297.784 52,282.5C 55.3358,277.269 59.8358,273.602 65.5,271.5 Z"
      />
    </g>
    <g>
      <path
        fill="#edeeef"
        d="M 304.5,298.5 C 306.222,308.942 310.889,317.942 318.5,325.5C 315.167,325.5 311.833,325.5 308.5,325.5C 308.5,321.5 308.5,317.5 308.5,313.5C 304.167,313.5 299.833,313.5 295.5,313.5C 295.5,308.833 295.5,304.167 295.5,299.5C 298.713,299.81 301.713,299.477 304.5,298.5 Z"
      />
    </g>
    <g>
      <path
        fill="#f2f3f3"
        d="M 322.5,299.5 C 326.5,299.5 330.5,299.5 334.5,299.5C 334.5,304.167 334.5,308.833 334.5,313.5C 339.167,313.5 343.833,313.5 348.5,313.5C 348.5,308.833 348.5,304.167 348.5,299.5C 348.5,295.5 348.5,291.5 348.5,287.5C 348.5,282.833 348.5,278.167 348.5,273.5C 344.167,273.5 339.833,273.5 335.5,273.5C 351.13,267.776 361.964,272.776 368,288.5C 369.699,305.968 361.865,315.968 344.5,318.5C 332.779,317.105 325.446,310.772 322.5,299.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fefefe"
        d="M 334.5,299.5 C 339.167,299.5 343.833,299.5 348.5,299.5C 348.5,304.167 348.5,308.833 348.5,313.5C 343.833,313.5 339.167,313.5 334.5,313.5C 334.5,308.833 334.5,304.167 334.5,299.5 Z"
      />
    </g>
    <g>
      <path
        fill="#fdfdfd"
        d="M 190.5,339.5 C 194.514,339.334 198.514,339.501 202.5,340C 203.464,344.066 203.797,348.233 203.5,352.5C 199.486,352.666 195.486,352.499 191.5,352C 190.536,347.934 190.203,343.767 190.5,339.5 Z"
      />
    </g>
    <g>
      <path
        fill="#f3f3f3"
        d="M 321.5,327.5 C 325.439,330.302 329.772,332.302 334.5,333.5C 334.5,335.5 334.5,337.5 334.5,339.5C 339.167,339.5 343.833,339.5 348.5,339.5C 348.5,343.833 348.5,348.167 348.5,352.5C 352.5,352.5 356.5,352.5 360.5,352.5C 360.5,348.167 360.5,343.833 360.5,339.5C 365.167,339.5 369.833,339.5 374.5,339.5C 374.5,343.833 374.5,348.167 374.5,352.5C 378.5,352.5 382.5,352.5 386.5,352.5C 386.5,353.5 386.5,354.5 386.5,355.5C 356.167,355.5 325.833,355.5 295.5,355.5C 295.5,354.5 295.5,353.5 295.5,352.5C 291.167,352.5 286.833,352.5 282.5,352.5C 282.203,348.233 282.536,344.066 283.5,340C 287.486,339.501 291.486,339.334 295.5,339.5C 295.203,335.233 295.536,331.066 296.5,327C 300.486,326.501 304.486,326.334 308.5,326.5C 308.5,330.833 308.5,335.167 308.5,339.5C 312.833,339.5 317.167,339.5 321.5,339.5C 321.5,335.5 321.5,331.5 321.5,327.5 Z"
      />
    </g>
  </svg>
);
export default AppLovin;
