import { CreativeRecommendation, RecommendationType, RecommendedCreativeLevelContent } from '@replai-platform/sdk';

const introSecondsFromContent = (content: RecommendedCreativeLevelContent) => {
  switch (content?.type) {
    case RecommendationType.CREATIVE_ADD_TAG_INTRO:
    case RecommendationType.CREATIVE_REMOVE_TAG_INTRO:
      return content?.tag?.firstSeconds;
    case RecommendationType.CREATIVE_REPLACE_TAG_INTRO:
      return content?.new?.firstSeconds;
    default:
      return 0;
  }
};

export const getBadgeText = (
  tagType: string,
  content: CreativeRecommendation['name'],
  showVerb = true,
  verbPastTense = false
) => {
  const tagTypeText = tagType ? tagType.toLowerCase() : 'tag';
  return {
    CREATIVE_REMOVE_TAG: `${showVerb ? (verbPastTense ? 'Removed ' : 'Remove ') : ''}${tagTypeText}`,
    [RecommendationType.CREATIVE_REMOVE_TAG_INTRO]: `${
      showVerb ? (verbPastTense ? 'Removed ' : 'Remove ') : ''
    }${tagTypeText} from first ${
      introSecondsFromContent(content) > 1 ? `${introSecondsFromContent(content)} seconds` : `second`
    }`,
    CREATIVE_ADD_TAG: `${showVerb ? (verbPastTense ? 'Added ' : 'Add ') : ''} ${tagTypeText}`,
    CREATIVE_ADD_TAG_INTRO: `${showVerb ? (verbPastTense ? 'Added ' : 'Add ') : ''}${tagTypeText} to first ${
      introSecondsFromContent(content) > 1 ? `${introSecondsFromContent(content)} seconds` : `second`
    }`,
    CREATIVE_REPLACE_TAG: `${showVerb ? (verbPastTense ? 'Replaced ' : 'Replace ') : ''}${tagTypeText}`,
    CREATIVE_REPLACE_TAG_INTRO: `${showVerb ? (verbPastTense ? 'Replaced ' : 'Replace ') : ''}${tagTypeText} in first ${
      introSecondsFromContent(content) > 1 ? `${introSecondsFromContent(content)} seconds` : `second`
    }`,
  }[content?.type];
};
