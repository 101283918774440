import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';
import { VerticalLayout } from '../shared/VerticalLayout';

const MINTEGRAL_FORM_URL = 'https://docs.google.com/forms/d/1elF4XP5Px7CglEdys1ForFSdoMvpdqHk1tH_lpeB5kQ/viewform';

export const MintegralConnectionGuide: NetworkConnectionGuideContent = {
  title: 'Mintegral Connection Guide',
  icon: 'Mintegral',
  guide: {
    label: 'Connection Guide',
    steps: [
      {
        title: 'Access Mintegral',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                Login into the{' '}
                {Shared.link({
                  label: 'Mintegral',
                  url: 'https://adv.mintegral.com/login',
                })}{' '}
                plaform
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/mintegral/step-1.png"
          />
        ),
      },
      {
        title: 'Account Basic Information',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                On the top navigation bar, click on <Shared.BoldText>Account-&gt;Basic Information</Shared.BoldText>
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/mintegral/step-2.png"
          />
        ),
      },
      {
        title: 'Share API Key',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                Send Replai the credentials shown on the page for both <Shared.BoldText>Access Key</Shared.BoldText> and{' '}
                <Shared.BoldText>API Key</Shared.BoldText> using{' '}
                {Shared.link({
                  label: 'this form',
                  url: MINTEGRAL_FORM_URL,
                })}
                .
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/mintegral/step-3.png"
          />
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: [
      ...Shared.DEFAULT_NETWORK_FAQS,
      {
        title: 'Which tracking settings should I use on MMPs with Mintegral?',
        description: (
          <Shared.UList>
            <Shared.UListItem>
              In the specific case of Mintegral, you should use Offer ID as Ad ID and Offer Name as Ad Name. Avoid using
              Mintegral&apos;s Offer UUID as it&apos;s different from the Offer ID and causes some functionality to not
              work as intended.
            </Shared.UListItem>
          </Shared.UList>
        ),
      },
    ],
  },
};
