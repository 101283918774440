import { Dispatch, SetStateAction } from 'react';
import FeaturedIcons from '../FeaturedIcons/FeaturedIcons';
import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import Colors from '../Colors';
import * as Styled from './styles';

export type RequestStatus =
  | 'disabled'
  | 'enabled'
  | 'requested'
  | 'requesting'
  | 'succeeded';

const RequestSuccess = ({
  onContactUsClick,
  message,
  title,
}: {
  onContactUsClick: () => void;
  message: string;
  title: string;
}) => (
  <>
    <Styled.BodyText>
      <Typography
        noMargin
        type="text-lg"
        fontWeight="medium"
        color={Colors.Gray[900]}
      >
        {title}
      </Typography>
      <Typography noMargin type="text-sm" color={Colors.Gray[500]}>
        {message}
      </Typography>
    </Styled.BodyText>
    <Styled.ButtonsContainer>
      <Button size="sm" fullWidth onClick={() => onContactUsClick()}>
        Contact us
      </Button>
    </Styled.ButtonsContainer>
  </>
);

type LockedModalProps = {
  content: {
    title?: string;
    subtitle?: string;
  };
  isOpen: boolean;
  requestConfig?: {
    onContactUsClick: () => void;
    onRequest: () => void;
    status: RequestStatus;
    successTitle: string;
    successMessage: string;
  };
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const LockedModal = ({
  content,
  isOpen,
  requestConfig,
  setIsOpen,
}: LockedModalProps) => (
  <Styled.StyledModal
    isOpen={isOpen}
    onClose={() => setIsOpen(false)}
    className="nav-locked-modal"
    modalHeader={
      <FeaturedIcons color="Primary" icon="Lock" size="xl" variant="outline" />
    }
  >
    <Styled.ModalBody>
      {requestConfig?.status === 'succeeded' ? (
        <RequestSuccess
          onContactUsClick={requestConfig.onContactUsClick}
          message={requestConfig.successMessage}
          title={requestConfig.successTitle}
        />
      ) : (
        <>
          <Styled.BodyText>
            <Styled.BodyTitle>
              <Typography
                noMargin
                type="text-lg"
                fontWeight="bold"
              >{`Unlock: ${content.title}`}</Typography>
              {content.subtitle && (
                <Typography noMargin type="text-sm" color={Colors.Gray[600]}>
                  {content.subtitle}
                </Typography>
              )}
            </Styled.BodyTitle>
            <Typography noMargin type="text-sm" color={Colors.Gray[600]}>
              {requestConfig ? (
                <>
                  Request the <strong>{content.title}</strong> functionality by
                  pressing the button below and your Account Manager will take
                  care of the next steps!
                </>
              ) : (
                <>
                  Request the <strong>{content.title}</strong> functionality by
                  contacting your Account Manager and they'll take care of the
                  next steps!
                </>
              )}
            </Typography>
          </Styled.BodyText>
          <Styled.ButtonsContainer>
            <Button
              variant="outlined"
              color="Gray"
              size="sm"
              fullWidth
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button>
            {requestConfig && (
              <Button
                disabled={['disabled', 'requesting', 'requested'].includes(
                  requestConfig.status
                )}
                fullWidth
                leadingIcon={
                  requestConfig.status === 'requesting'
                    ? { name: 'LoadingCircle' }
                    : undefined
                }
                onClick={requestConfig.onRequest}
                size="sm"
              >
                Request&nbsp;<strong>{content.title}</strong>
              </Button>
            )}
          </Styled.ButtonsContainer>
        </>
      )}
    </Styled.ModalBody>
  </Styled.StyledModal>
);

export default LockedModal;
