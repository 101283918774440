import { ReactElement } from 'react';
import styled from 'styled-components';
import * as Icons from '../Icons';
import { Input, InputProps } from '..';
import Button, { ButtonProps } from '../Button/Button';
import Colors from '../Colors';
import Typography from '../Typography/Typography';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid #e4e7ec;

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const IconButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(Typography)`
  display: flex;
  justify-content: space-between;
`;

const SubTitle = styled(Typography)`
  margin-top: 4;
  margin-bottom: 0;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const InputHolder = styled.div`
  max-width: 25rem;
`;

export interface CardHeaderProps {
  buttons?: ButtonProps[];
  iconButton?: {
    icon: Icons.BaseIconTypes;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  };
  input?: InputProps;
  subtitle?: string;
  title: string | ReactElement;
}

const CardHeader = ({
  buttons,
  iconButton,
  input,
  subtitle,
  title,
}: CardHeaderProps) => {
  const Icon = iconButton && Icons.getBaseIcon(iconButton.icon);

  return (
    <Root data-test="card-header">
      <MainContainer>
        <TitleContainer>
          <Title type="text-lg" fontWeight="medium" style={{ margin: 0 }}>
            {title}
            {Icon && (
              <IconButton type="button" onClick={iconButton.onClick}>
                <Icon dimension={16} color={Colors.Gray[400]} />
              </IconButton>
            )}
          </Title>
          {subtitle ? (
            <SubTitle type="text-sm" color={Colors.Gray[500]}>
              {subtitle}
            </SubTitle>
          ) : null}
        </TitleContainer>
        {
          /* eslint-disable react/jsx-props-no-spreading */
          input && (
            <InputHolder>
              <Input {...input} />
            </InputHolder>
          )
          /* eslint-enable react/jsx-props-no-spreading */
        }
      </MainContainer>

      {
        /* eslint-disable react/jsx-props-no-spreading */
        /* eslint-disable react/no-array-index-key */
        buttons && (
          <Buttons>
            {buttons.map((buttonProps, index) => (
              <Button key={index} {...buttonProps} />
            ))}
          </Buttons>
        )
        /* eslint-enable react/no-array-index-key */
        /* eslint-enable react/jsx-props-no-spreading */
      }
    </Root>
  );
};

export default CardHeader;
