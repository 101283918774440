import * as SDK from '@replai-platform/sdk';
import { useMutation } from 'react-query';
import * as provider from '../../api/provider';

export const SUBMIT_TEXT_IMAGE_TO_VIDEO_PROMPT_QUERY_KEY = 'submit-text-image-to-video-prompt';

const submitTextImageToVideoPrompt = async (projectId: SDK.UUID, body: SDK.SubmitTextImageToVideoPromptRequestBody) => {
  const url = `${SDK.BASE_URL()}/creative-production/${projectId}/text-image-to-video-prompt`;
  const res = await provider.getJson<object, { id: string }>(url, {
    method: 'POST',
    body,
  });
  return res;
};

export function useSubmitTextImageToVideoPrompt(projectId: SDK.UUID) {
  return useMutation(
    [SUBMIT_TEXT_IMAGE_TO_VIDEO_PROMPT_QUERY_KEY],
    (body: SDK.SubmitTextImageToVideoPromptRequestBody) => submitTextImageToVideoPrompt(projectId, body)
  );
}
