import { DropDownChip, DropDownChipProps } from '@replai-platform/ui-components';

export interface CountryFilterProps {
  options: DropDownChipProps['dropDownOptions'];
  onChange: DropDownChipProps['onChange'];
  loading: boolean;
  dropdownMaxHeightInVH?: number;
}

const CountryFilter: React.VFC<CountryFilterProps> = ({ options, onChange, loading, dropdownMaxHeightInVH }) => (
  <DropDownChip
    data-test="filter-taggedwith-dropdown"
    placeHolder="All Countries"
    defaultOption="All Countries"
    dropDownType="multiselect"
    dropDownOptions={options}
    dropDownAlignment="right"
    onChange={onChange}
    prefixLabel="Countries"
    loading={loading}
    dropdownMaxHeightInVH={dropdownMaxHeightInVH}
  />
);

export default CountryFilter;
