import styled from 'styled-components';

export const Container = styled.div`
  display: block;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  height: 100vh;
  margin-top: 1.5rem;
`;

export const MainContainer = styled.div<{ marginTop: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop}rem;
`;
