import * as SDK from '@replai-platform/sdk';

/**
 * Highlight tags if the spend percentage is at least 1.5x the frequency percentage
 */
const FREQ_SPEND_TIMES_THRESHOLD = 1.5;

export const getHighlightScore = ({
  shareOfSpend,
  shareOfAssets,
}: Pick<SDK.TagMetrics, 'shareOfSpend' | 'shareOfAssets'>) => shareOfSpend / shareOfAssets;

/**
 * Determines whether a given tag should be highlighted, or not,
 * based on the tag's frequency and spend ratios. Returns True if
 * the tag should be highlighted, False otherwise.
 */
export const shouldHighlight = ({
  shareOfSpend,
  shareOfAssets,
}: Pick<SDK.TagMetrics, 'shareOfSpend' | 'shareOfAssets'>) =>
  getHighlightScore({ shareOfSpend, shareOfAssets }) >= FREQ_SPEND_TIMES_THRESHOLD;
