import { hexToRgb } from '.';

const ALPHA_VALUES = [
  1, 0.738, 0.541, 0.382, 0.278, 0.194, 0.126, 0.075, 0.042, 0.021, 0.008,
  0.002, 0,
];
const STOP_VALUES = [
  0, 19, 34, 47, 56.5, 65, 73, 80.2, 86.1, 91, 95.2, 98.2, 100,
];

/**
 * Creates a smooth gradient from "color" to transparent
 * without the hard edge line that is usually present in gradients
 * Based on: https://bluegg.co.uk/blog/making-css-gradients-smooth
 *
 * @param color gradient color
 * @param direction gradient direction
 * @returns linear-gradient to be used in your CSS
 */
export const scrimGradient = (
  color: string,
  direction: 'to top' | 'to bottom' | 'to left' | 'to right'
) => {
  const rgb = hexToRgb(color) ?? [];

  return `${STOP_VALUES.reduce(
    (gradient, stop, i) =>
      `${gradient}, rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${ALPHA_VALUES[i]}) ${stop}%`,
    `linear-gradient(${direction}`
  )})`;
};
