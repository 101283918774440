import { Modal, Typography, VideoPlayer } from '@replai-platform/ui-components';
import { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import styled from 'styled-components';
import { logEvent } from '../../analytics';

export interface VideoPreviewDialogProps {
  isImage: boolean;
  onClose: () => unknown;
  open: boolean;
  src: string;
  name?: string;
}

const PreviewLoadError = styled(Typography)`
  width: 100%;
  text-align: center;
`;

const ImgBox = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

const VideoPreviewDialog: React.FC<VideoPreviewDialogProps> = ({ isImage, onClose, open, src, name }) => {
  const videoPlayer = useRef<ReactPlayer | null>(null);

  useEffect(() => {
    if (!open && videoPlayer.current) {
      (videoPlayer.current.getInternalPlayer() as HTMLVideoElement).pause();
    } else {
      videoPlayer.current?.seekTo(0);
      (videoPlayer.current?.getInternalPlayer() as HTMLVideoElement)?.play().catch(() => {});
    }
  }, [open]);

  return (
    <Modal
      onClick={(e) => {
        e.stopPropagation();
      }}
      onClose={onClose}
      isOpen={open}
    >
      {isImage ? (
        <ImgBox>
          <img alt={name} src={src} />
        </ImgBox>
      ) : src ? (
        <VideoContainer>
          <VideoPlayer
            playerRef={videoPlayer}
            autoPlay
            playing
            url={src}
            onPlay={() =>
              logEvent({
                component: 'Video Preview',
                action: 'Play Video',
              })
            }
            onPause={() =>
              logEvent({
                component: 'Video Preview',
                action: 'Pause Video',
              })
            }
          />
        </VideoContainer>
      ) : (
        <PreviewLoadError type="text-md">
          We were unable to load the preview video, please try again later.
        </PreviewLoadError>
      )}
    </Modal>
  );
};

export default VideoPreviewDialog;
