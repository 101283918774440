import { CountryIconProps } from './types';

const CX = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3595)">
      <path
        d="M20.4853 20.4857C15.799 25.172 8.20104 25.172 3.51472 20.4857C-1.1716 15.7995 -1.17155 8.20146 3.51472 3.51514C3.88363 3.884 13.1068 10.8936 13.1068 10.8936L20.4853 20.4857Z"
        fill="#0052B4"
      />
      <path
        d="M3.51465 3.51422C8.20092 -1.17206 15.7989 -1.17206 20.4853 3.51422C25.1716 8.20049 25.1715 15.7985 20.4853 20.4848"
        fill="#6DA544"
      />
      <path
        d="M12.0006 15.131C13.7295 15.131 15.131 13.7295 15.131 12.0006C15.131 10.2717 13.7295 8.87012 12.0006 8.87012C10.2717 8.87012 8.87012 10.2717 8.87012 12.0006C8.87012 13.7295 10.2717 15.131 12.0006 15.131Z"
        fill="#FFDA44"
      />
      <path
        d="M4.46876 17.2175L4.79806 17.9061L5.54164 17.7342L5.20864 18.4209L5.80667 18.8952L5.06211 19.0629L5.06417 19.8262L4.46876 19.3487L3.87336 19.8262L3.87542 19.0629L3.13086 18.8952L3.72889 18.4209L3.39584 17.7342L4.13947 17.9061L4.46876 17.2175Z"
        fill="#F0F0F0"
      />
      <path
        d="M2.31442 11.4769L2.64372 12.1655L3.38729 11.9935L3.05434 12.6803L3.65228 13.1546L2.90776 13.3223L2.90982 14.0856L2.31442 13.6081L1.71906 14.0856L1.72108 13.3223L0.976562 13.1546L1.57455 12.6803L1.24155 11.9935L1.98512 12.1655L2.31442 11.4769Z"
        fill="#F0F0F0"
      />
      <path
        d="M4.46872 6.78186L4.79801 7.47041L5.54159 7.29847L5.20864 7.98528L5.80662 8.45951L5.06206 8.62728L5.06412 9.39054L4.46872 8.91307L3.87331 9.39054L3.87537 8.62728L3.13086 8.45951L3.7288 7.98528L3.3958 7.29847L4.13942 7.47041L4.46872 6.78186Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.1445 10.4346L7.47379 11.1231L8.21742 10.9512L7.88437 11.638L8.48236 12.1122L7.73784 12.28L7.73986 13.0432L7.1445 12.5658L6.54909 13.0432L6.55116 12.28L5.80664 12.1122L6.40458 11.638L6.07162 10.9512L6.8152 11.1231L7.1445 10.4346Z"
        fill="#F0F0F0"
      />
      <path
        d="M5.23094 13.5646L5.48998 14.3617H6.3281L5.65001 14.8544L5.90904 15.6515L5.23094 15.1589L4.55285 15.6515L4.81188 14.8544L4.13379 14.3617H4.97191L5.23094 13.5646Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 14.0871H13.0434C13.0434 14.0871 13.4956 13.2872 13.0434 12.5219L14.0869 11.4784L13.5652 10.4349H13.0435C13.0435 10.4349 12.7826 11.2176 11.7391 11.2176C10.6957 11.2176 10.4348 10.4349 10.4348 10.4349H9.91309L10.4348 11.4784L9.91309 12.5219L10.4348 13.0436C10.4348 13.0436 10.9565 12.0001 12 12.5219C12 12.5219 12.4434 13.1741 12 14.0871Z"
        fill="#6DA544"
      />
      <path
        d="M19.7888 6.57258C19.8134 6.44437 19.8265 6.31214 19.8265 6.17677C19.8265 5.24864 19.2205 4.4625 18.3828 4.19128C18.6387 4.7116 18.783 5.29678 18.783 5.91591C18.783 6.12469 18.7662 6.32953 18.7347 6.52945C18.6086 6.32634 18.4466 6.14077 18.2488 5.98331C17.5034 5.39025 16.4849 5.37455 15.7318 5.874C16.3132 6.00094 16.8754 6.25898 17.3726 6.65456C17.7618 6.96431 18.0763 7.38328 18.3172 7.78772L15.1309 8.78545C18.2613 9.30717 20.87 7.22025 20.87 7.22025C20.5809 6.64219 20.132 6.54469 19.7888 6.57258Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3595">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default CX;
