import styled from 'styled-components';
import { default as DefaultModal } from '../Modal/Modal';
import { default as DefaultTypography } from '../Typography/Typography';

export const Modal = styled(DefaultModal)`
  &.replai-idle-refresh-ant-modal.replai-ant-modal .ant-modal-content {
    width: fit-content;
    margin: auto;
  }
`;

export const Typography = styled(DefaultTypography)<{ $margin?: string }>`
  margin: ${(props) => props.$margin};
`;

export const IdleRefreshContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IdleRefreshDialogButton = styled.div`
  padding: 0 74px;
`;
