import { CountryIconProps } from './types';

const VC = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3712)">
      <path
        d="M17.7395 1.45932C16.0346 0.529135 14.0793 0.000244141 12.0004 0.000244141C9.92149 0.000244141 7.96615 0.529135 6.26126 1.45932L5.21777 12.0002L6.26126 22.5412C7.96615 23.4714 9.92149 24.0002 12.0004 24.0002C14.0793 24.0002 16.0346 23.4714 17.7395 22.5412L18.783 12.0002L17.7395 1.45932Z"
        fill="#FFDA44"
      />
      <path
        d="M6.26086 22.5408V1.45898C2.53073 3.4943 0 7.45144 0 12C0 16.5485 2.53073 20.5056 6.26086 22.5408Z"
        fill="#338AF3"
      />
      <path
        d="M24.0001 12.0002C24.0001 7.45168 21.4694 3.49454 17.7393 1.45923V22.541C21.4694 20.5059 24.0001 16.5487 24.0001 12.0002Z"
        fill="#6DA544"
      />
      <path
        d="M9.3917 15.1298L7.30469 11.9993L9.39166 8.8689L11.4787 11.9993L9.3917 15.1298Z"
        fill="#6DA544"
      />
      <path
        d="M14.6094 15.1298L12.5225 11.9993L14.6094 8.8689L16.6964 11.9993L14.6094 15.1298Z"
        fill="#6DA544"
      />
      <path
        d="M12.001 19.3037L9.91406 16.1733L12.001 13.0428L14.088 16.1733L12.001 19.3037Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3712">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default VC;
