import * as SDK from '@replai-platform/sdk';
import {
  createRef,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactPlayer from 'react-player/lazy';
import {
  calculateClusterViewPreviewVideoStartTime,
  calculateTimestamps,
  formatDuration,
} from '../../utils';
import ActiveIndicator from '../ActiveIndicator/ActiveIndicator';
import Colors from '../Colors';
import EmptyState from '../EmptyState/EmptyState';
import Pagination from '../Pagination/Pagination';
import SkeletonLoading from '../Skeleton/Skeleton';
import { TimelineTrack } from '../Timeline/TimelineBody/TimelineRow/TimelineRow';
import Typography from '../Typography/Typography';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import * as Styled from './styles';

const PAGE_SIZE = 8;
const NUM_TIMESTAMPS_TO_SHOW = 4;

type Video = {
  id: SDK.UUID;
  url: string;
  timeframes?: SDK.Timeframe[];
  name?: string;
  isActive?: boolean;
  href?: string;
};

export type VideosPreviewDialogProps = {
  title: ReactNode;
  videos?: Video[];
  withFeaturedVideos?: boolean;
  numFeaturedVideos?: number;
  featuredVideos?: Video[];
  isLoadingFeaturedVideos?: boolean;
  totalVideos?: number;
  page?: number;
  isOpen: boolean;
  isLoading?: boolean;
  isUpdating?: boolean;
  pageSize?: number;
  width?: number;
  onClose: () => void;
  onVideoClick?: (id: string) => void;
  onTimelineClick?: (id: string) => void;
  onPlayClick?: (id: string) => void;
  onPauseClick?: (id: string) => void;
  onPageChange?: (page: number) => void;
};

const VideosPreviewDialog: React.VFC<VideosPreviewDialogProps> = ({
  title,
  videos,
  withFeaturedVideos = false,
  numFeaturedVideos = 0,
  featuredVideos,
  isLoadingFeaturedVideos = false,
  totalVideos = 1,
  page = 1,
  isOpen,
  isLoading,
  isUpdating,
  pageSize = PAGE_SIZE,
  width = 860,
  onClose,
  onVideoClick,
  onPlayClick,
  onPauseClick,
  onTimelineClick,
  onPageChange,
}) => {
  const playerRefs = useRef<Map<string, RefObject<ReactPlayer>>>(new Map());
  const [durations, setDurations] = useState<Map<string, number>>(new Map());

  useEffect(() => {
    if (isOpen) return;
    Object.values(playerRefs.current).forEach((player) =>
      (player.current?.getInternalPlayer() as HTMLVideoElement)?.pause()
    );
  }, [isOpen]);

  const updateVideosDataOnPageChange = () => {
    if (featuredVideos) {
      // Delete refs to videos that are no longer in the list.
      // Keep featured videos since they don't change.
      videos?.map((video) => playerRefs.current.delete(video.id));
      const updatedDurations = new Map(durations);
      videos?.map((video) => updatedDurations.delete(video.id));
      setDurations(updatedDurations);
    }
  };

  const seekToPoint = (point: number, assetId: string) => {
    playerRefs.current?.get(assetId)?.current?.seekTo(point, 'seconds');
    onTimelineClick?.(assetId);
  };

  const onLoadedMetadata = (
    data: React.SyntheticEvent<HTMLVideoElement>,
    assetId: string
  ) => {
    const targetElm = data?.target as HTMLVideoElement;

    if (targetElm?.duration && !durations.has(assetId)) {
      setDurations((prevDurations) =>
        new Map(prevDurations).set(assetId, targetElm.duration)
      );
    }
  };

  const renderTimestamp = (timestamp: number) => {
    const { minutes, seconds } = formatDuration(timestamp);

    return (
      <Typography
        key={timestamp}
        noMargin
        type="text-xs"
        color={Colors.Gray[500]}
        fontWeight="medium"
      >
        {`${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`}
      </Typography>
    );
  };

  const renderVideoSkeletons = (amount: number) =>
    Array.from(Array(amount), (_value, index) => (
      <Styled.Container key={`video-skeleton-${index}`}>
        <Styled.VideoContainer>
          <SkeletonLoading height="100%" />
        </Styled.VideoContainer>
        <Styled.NameContainerSkeleton>
          <SkeletonLoading height={15} width={189} />
        </Styled.NameContainerSkeleton>
        <SkeletonLoading width={189} />
        <SkeletonLoading height={20} width={189} />
      </Styled.Container>
    ));

  const renderVideos = (videosToRender: Video[] = []) => {
    return videosToRender
      .slice(
        0,
        Math.min(videosToRender.length, pageSize - (withFeaturedVideos ? 2 : 0))
      )
      .map(({ id, url, timeframes, name, isActive, href }: Video) => {
        if (!playerRefs.current.has(id)) {
          playerRefs.current.set(id, createRef());
        }

        return (
          <Styled.Container key={url.toString()}>
            <Styled.VideoContainer>
              <VideoPlayer
                preload="metadata"
                playerRef={playerRefs.current.get(id)}
                url={url.toString()}
                title={name}
                startTime={
                  timeframes
                    ? calculateClusterViewPreviewVideoStartTime(timeframes)
                    : '0'
                }
                onPlay={() => onPlayClick?.(id)}
                onPause={() => onPauseClick?.(id)}
                onLoadedMetadata={(metadata) => onLoadedMetadata(metadata, id)}
              />
            </Styled.VideoContainer>
            <Styled.NameContainer
              onClick={() => onVideoClick?.(id)}
              href={href}
            >
              {isActive !== undefined ? (
                <Styled.ActiveIndicatorContainer>
                  <ActiveIndicator color={isActive ? 'Success' : 'Error'} />
                </Styled.ActiveIndicatorContainer>
              ) : undefined}
              <Styled.VideoName
                type="text-sm"
                fontWeight="medium"
                data-test="video-card-title"
              >
                {name}
              </Styled.VideoName>
            </Styled.NameContainer>
            <Styled.TimestampsContainer>
              {durations.get(id) === undefined ? (
                <SkeletonLoading width="100%" />
              ) : undefined}
              {durations.get(id) !== undefined &&
              timeframes &&
              timeframes.length > 0
                ? calculateTimestamps(
                    durations.get(id)!,
                    NUM_TIMESTAMPS_TO_SHOW
                  ).map((ts: number) => renderTimestamp(ts))
                : undefined}
            </Styled.TimestampsContainer>
            {durations.get(id) === undefined ? (
              <SkeletonLoading width="100%" height={20} />
            ) : undefined}
            {durations.get(id) !== undefined &&
            timeframes &&
            timeframes.length > 0 ? (
              <TimelineTrack
                segments={timeframes ?? []}
                duration={durations.get(id)!}
                color="Primary"
                seekToPoint={(point) => seekToPoint(point, id)}
              />
            ) : undefined}
          </Styled.Container>
        );
      });
  };

  const renderFeaturedVideos = () => {
    if (isLoadingFeaturedVideos) {
      return renderVideoSkeletons(numFeaturedVideos);
    }
    return renderVideos(featuredVideos);
  };

  return (
    <Styled.StyledModal
      className="videos-preview-modal"
      isOpen={isOpen}
      modalHeader={title}
      onClose={onClose}
      width={width}
    >
      {!isLoading && !isUpdating && (!videos || videos?.length === 0) ? (
        <EmptyState icon="Search" text="No videos found" />
      ) : undefined}
      <Styled.VideosContainer withFeaturedVideos={withFeaturedVideos}>
        {withFeaturedVideos ? (
          <Styled.FeaturedVideosContainer>
            {renderFeaturedVideos()}
          </Styled.FeaturedVideosContainer>
        ) : undefined}
        {isLoading || isUpdating || isLoadingFeaturedVideos
          ? renderVideoSkeletons(pageSize - (withFeaturedVideos ? 2 : 0))
          : renderVideos(videos)}
      </Styled.VideosContainer>
      <Styled.Footer>
        {isLoading ? (
          <>
            <SkeletonLoading width={90} height={20} />
            <SkeletonLoading width={380} height={40} />
          </>
        ) : (
          <>
            <Styled.FooterText type="text-sm" fontWeight="medium">
              {`${
                (page - 1) * pageSize + (videos?.length ?? pageSize)
              } out of ${totalVideos}`}
            </Styled.FooterText>
            <Pagination
              totalCount={totalVideos}
              pageSize={pageSize}
              currentPage={page}
              onPageChange={(page: number) => {
                updateVideosDataOnPageChange();
                onPageChange?.(page);
              }}
            />
          </>
        )}
      </Styled.Footer>
    </Styled.StyledModal>
  );
};

export default VideosPreviewDialog;
