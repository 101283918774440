import * as SDK from '@replai-platform/sdk';
import styled from 'styled-components';
import Colors from '../Colors';
import { getIconInnerContainerBgColor } from './utils';

export const Container = styled.div`
  min-width: 300px;
  padding: 1.5rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const Header = styled.div<{ borderLeftColor: string }>`
  border-left: 2px solid ${({ borderLeftColor }) => borderLeftColor};
  border-radius: 1px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0.5rem;
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${Colors.Gray[50]};
  border-radius: 0.5rem;
  padding: 0.75rem;
  gap: 0.5rem;
`;

export const RecommendationContainer = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  column-gap: 0.5rem;
`;

export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  position: relative;
  height: 309px;
`;

export const VideoTimelineContainer = styled.div`
  width: 172px;
`;

export const RecommendationTypeIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: ${Colors.Common.White};
`;

export const RecommendationTypeIconInnerContainer = styled.div<{
  recommendationType: SDK.RecommendationType;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  z-index: 100;
  background-color: ${({ recommendationType }) =>
    getIconInnerContainerBgColor(recommendationType)};
`;

export const RecommendationInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  align-items: center;
  white-space: nowrap;
`;
