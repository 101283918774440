import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

export const GET_EXTRENAL_API_KEY = 'get-external-api-key';

const getExternalApiKey = async (requestParams: SDK.GetApiKeyRequest) =>
  provider.getJson<object, SDK.GetApiKeyResponse>(
    `${SDK.BASE_URL()}/api/key?${SDK.convertRequestToQueryParams(requestParams)}`
  );

export default function useGetExternalApiKey<R = SDK.GetApiKeyResponse>(
  requestParams: SDK.GetApiKeyRequest,
  options?: UseQueryOptions<SDK.GetApiKeyResponse, unknown, R>
) {
  return useQuery([GET_EXTRENAL_API_KEY, requestParams], () => getExternalApiKey(requestParams), options);
}
