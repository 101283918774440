import { IntegrationIconProps } from './types';

const Treehouse = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="14" fill="#5FCF80" />
    <path
      d="M23.9214 10.3182C23.1242 9.84123 22.2246 9.17205 21.3929 10.0273C20.3594 11.4949 18.4621 13.4709 19.4303 15.3909C19.8211 16.1278 20.4671 16.6776 20.9862 17.3182C21.2909 17.6314 21.4516 18.0393 21.3929 18.4818C21.3222 19.0818 20.8448 19.5546 20.2613 19.6273C19.4833 19.7182 18.8114 19.0818 18.8114 18.3C18.7407 17.8818 18.8468 16.9364 18.1395 16.8273C17.6798 16.7546 17.0963 17.5 16.7957 18.4818C16.5551 19.2926 16.6896 19.9824 16.6896 20.7909C16.6896 21.6572 16.0625 22.4359 15.1336 22.3364C14.5147 22.2637 13.9843 21.7546 13.8959 21.1182C13.8183 20.6128 14.036 20.0992 14.3379 19.7C14.6385 19.2455 15.0982 18.5546 15.3281 17.9182C15.5756 17.2455 15.9391 16.7091 15.4695 16.3546C15 16 13.0075 18.9663 11.9155 18.7728C11.4204 18.6818 11.0314 18.2637 10.943 17.7728C10.8016 17.0273 11.279 16.3728 11.9332 16.2455C12.7011 16.1061 14.9769 15.2501 14.7269 14.3182C14.4997 13.5239 12.5471 14.9954 11.9155 14.9364C11.3143 14.9182 10.8369 14.3909 10.8193 13.7727C10.8193 13.0812 11.3715 12.5909 12.0393 12.5909C12.8218 12.5909 13.5955 12.9211 14.391 12.7364C16.43 12.2837 17.1935 10.4653 18.2102 8.84548C18.6876 8.08184 18.4578 7.04548 17.6798 6.59093C16.7251 6.05921 15.738 5.74335 14.7092 6.35457L8.0609 10.3182C7.40668 10.7 7 11.4273 7 12.2091V19.8091C7 20.5909 7.40668 21.3182 8.0609 21.7L14.7269 25.6455C15.5049 26.1182 16.4774 26.1182 17.2731 25.6455L23.9391 21.7C24.5933 21.3182 25 20.5909 25 19.8091V12.2091C24.9823 11.4273 24.5756 10.7 23.9214 10.3182Z"
      fill="white"
    />
  </svg>
);
export default Treehouse;
