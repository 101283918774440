import { WYSIWYGProps } from './types';

const MDGallery = ({ color }: WYSIWYGProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.875 14.125V1.875C15.875 0.9125 15.0875 0.125 14.125 0.125H1.875C0.9125 0.125 0.125 0.9125 0.125 1.875V14.125C0.125 15.0875 0.9125 15.875 1.875 15.875H14.125C15.0875 15.875 15.875 15.0875 15.875 14.125ZM4.9375 9.3125L7.125 11.9462L10.1875 8L14.125 13.25H1.875L4.9375 9.3125Z"
      fill={color || '#98A2B3'}
    />
  </svg>
);
export default MDGallery;
