import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type MultiSelectDropDownOption } from '@replai-platform/ui-components';
import { RootState } from '../../../store/rootReducer';
import { FilterActions } from '../../../store/filters';
import { ReportsActions } from '../../../store/reports';
import { Page } from '../../../utils/enums';
import useGetCampaigns from '../../../api/hooks/campaigns/useGetCampaigns';

type BuyingStrategyConfig = {
  label: string;
  // Null is the catch-all option.
  campaignNameFilter: string;
};

type BuyingStrategyValues = BuyingStrategyConfig & {
  campaigns: { id: string; name: string }[];
};

const BUYING_STRATEGIES_CONFIG: Array<BuyingStrategyConfig> = [
  {
    label: 'Retargeting',
    campaignNameFilter: 'RT',
  },
];

const DEFAULT_BUYING_STRATEGIES_CONFIG: BuyingStrategyConfig = {
  label: 'UA',
  campaignNameFilter: '',
};
const useBuyingStrategyFilter = (
  page?: Page
): {
  buyingStrategyFilterOptions: MultiSelectDropDownOption[];
  buyingStrategyFilterOnChange: (options: MultiSelectDropDownOption[]) => void;
  isLoading: boolean;
} => {
  const dispatch = useDispatch();
  const project = useSelector((state: RootState) => state.project);
  const buyingStrategyCampaignsToConsider = useSelector(
    (state: RootState) => state.filters.buyingStrategyCampaignsToConsider
  );

  // Fetch and update project networks
  const { isSuccess, data, isLoading } = useGetCampaigns({ projectIds: [project.id] });

  // The default state of the filter is "ALL" selected. The problem is that the "ALL" option is not
  // stored in the redux store, so we dont have an easy way to know whether to select it or not. We
  // use this ref to identify the first onChange, and until the ref value changes, we can assume
  // that we want the "ALL" option to be selected (assuming that there are no networks in the store,
  // in which case that takes precedence).
  const isInitialFilter = useRef<boolean>(true);

  // Create filter options
  const buyingStrategyFilterOptions = useMemo<MultiSelectDropDownOption[]>(() => {
    if (isLoading || !data) {
      return [];
    }

    const filterData: Record<string, BuyingStrategyValues> = {};

    data.campaigns.forEach((campaign) => {
      const uniformCampaignName = campaign.name;
      const campaignBuyingStrategy = BUYING_STRATEGIES_CONFIG.find((buyingStrat) =>
        uniformCampaignName?.includes(buyingStrat.campaignNameFilter)
      );

      if (campaignBuyingStrategy) {
        if (filterData[campaignBuyingStrategy.label]) {
          filterData[campaignBuyingStrategy.label].campaigns.push(campaign);
        } else {
          filterData[campaignBuyingStrategy.label] = {
            ...campaignBuyingStrategy,
            campaigns: [campaign],
          };
        }
      } else if (filterData[DEFAULT_BUYING_STRATEGIES_CONFIG.label]) {
        filterData[DEFAULT_BUYING_STRATEGIES_CONFIG.label].campaigns.push(campaign);
      } else {
        filterData[DEFAULT_BUYING_STRATEGIES_CONFIG.label] = {
          ...DEFAULT_BUYING_STRATEGIES_CONFIG,
          campaigns: [campaign],
        };
      }
    });

    return isSuccess
      ? [
          // allOption,
          ...Object.keys(filterData).map((key) => {
            const optionInfo = filterData[key];
            const selected = !optionInfo.campaigns.find(
              (campaign) => !buyingStrategyCampaignsToConsider.includes(campaign.id)
            );
            return {
              id: optionInfo.campaigns.map((c) => c.id).join(';'),
              label: optionInfo.label,
              isAllOption: false,
              selected,
            };
          }),
        ]
      : [];
  }, [isSuccess, JSON.stringify(data), JSON.stringify(buyingStrategyCampaignsToConsider)]);

  const buyingStrategyFilterOnChange = useCallback(
    (options: MultiSelectDropDownOption[]) => {
      dispatch(
        FilterActions.changeCampaigns({
          value: options.filter((option) => option.selected).flatMap((option) => option?.id?.split(';') ?? []),
          toExclude: false,
          logEvent: !isInitialFilter.current,
          logComponent: 'BuyingStrategyFilter',
        })
      );
      dispatch(
        ReportsActions.changeCampaigns({
          value: options.filter((option) => option.selected).flatMap((option) => option?.id?.split(';') ?? []),
          toExclude: false,
        })
      );
    },
    [dispatch, page]
  );

  return { buyingStrategyFilterOptions, buyingStrategyFilterOnChange, isLoading };
};

export default useBuyingStrategyFilter;
