import { CountryIconProps } from './types';

const CN = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#D80027"
    />
    <path
      d="M6.56719 7.30139L7.60313 10.4889H10.9547L8.24531 12.4623L9.28125 15.6498L6.56719 13.6811L3.85313 15.6498L4.89375 12.4623L2.17969 10.4889H5.53125L6.56719 7.30139Z"
      fill="#FFDA44"
    />
    <path
      d="M14.226 18.5846L13.4338 17.6096L12.2619 18.0643L12.9416 17.0096L12.1494 16.03L13.3635 16.3534L14.0479 15.2987L14.1135 16.555L15.3322 16.8784L14.1557 17.3284L14.226 18.5846Z"
      fill="#FFDA44"
    />
    <path
      d="M15.802 15.7253L16.177 14.5253L15.1504 13.7987L16.4066 13.78L16.777 12.58L17.1848 13.7706L18.441 13.7565L17.4332 14.5065L17.8363 15.6971L16.8098 14.9706L15.802 15.7253Z"
      fill="#FFDA44"
    />
    <path
      d="M17.9256 8.80662L17.3725 9.9363L18.2725 10.8129L17.0303 10.6347L16.4771 11.7597L16.2615 10.5222L15.0146 10.3441L16.1303 9.75818L15.9146 8.51599L16.8146 9.39255L17.9256 8.80662Z"
      fill="#FFDA44"
    />
    <path
      d="M14.2592 5.38501L14.1654 6.63657L15.3326 7.11001L14.1092 7.41001L14.0201 8.66626L13.3592 7.59751L12.1357 7.89751L12.9467 6.93657L12.2811 5.87251L13.4482 6.34595L14.2592 5.38501Z"
      fill="#FFDA44"
    />
  </svg>
);
export default CN;
