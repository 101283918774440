import { CountryIconProps } from './types';

const AX = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3552)">
      <path
        d="M23.5866 15.1299C23.8556 14.1317 24 13.0826 24 11.9994C24 10.9163 23.8556 9.86715 23.5866 8.869L10.9565 0.045105C8.61178 0.247136 6.46008 1.12323 4.69566 2.4789L0.413391 8.869C0.144375 9.86715 0 10.9163 0 11.9994C0 13.0763 0.142641 14.1197 0.408656 15.1126L4.69561 21.52C6.46003 22.8757 8.61173 23.7518 10.9565 23.9538L23.5866 15.1299Z"
        fill="#FFDA44"
      />
      <path
        d="M0.413086 15.1305C1.1074 17.7067 2.63763 19.9395 4.69535 21.5206V15.1305H0.413086Z"
        fill="#0052B4"
      />
      <path
        d="M10.9561 23.9544C11.3 23.984 11.6479 24.0001 11.9995 24.0001C17.5439 24.0001 22.2091 20.2397 23.5861 15.1305H10.9561V23.9544Z"
        fill="#0052B4"
      />
      <path
        d="M23.5861 8.86955C22.2091 3.76036 17.5439 0 11.9995 0C11.6479 0 11.3 0.0160312 10.9561 0.0456562V8.86955H23.5861Z"
        fill="#0052B4"
      />
      <path
        d="M4.69535 2.4798C2.63763 4.06089 1.1074 6.29369 0.413086 8.86989H4.69535V2.4798Z"
        fill="#0052B4"
      />
      <path
        d="M23.8984 10.4343H9.39136H9.39131V0.284729C8.28234 0.530635 7.23098 0.929213 6.26086 1.45857V10.4342H0.101578C0.0347813 10.9466 0 11.469 0 11.9995C0 12.53 0.0347813 13.0524 0.101578 13.5647H6.26081H6.26086V22.5404C7.23098 23.0697 8.28234 23.4684 9.39131 23.7142V13.5648V13.5647H23.8984C23.9651 13.0524 24 12.53 24 11.9995C24 11.469 23.9651 10.9466 23.8984 10.4343Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3552">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default AX;
