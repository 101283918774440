import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
`;

export const AlignRightHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  z-index: 10;
`;
