import { CountryIconProps } from './types';

const JO = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3734)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.30388 7.82623H23.2531C21.5576 3.25662 17.1591 0.00012207 11.9995 0.00012207C8.68576 0.00012207 5.68609 1.34351 3.51465 3.51523L7.30388 7.82623Z"
        fill="black"
      />
      <path
        d="M7.30388 16.1745H23.2531C21.5576 20.7441 17.1591 24.0006 11.9995 24.0006C8.68576 24.0006 5.68609 22.6572 3.51465 20.4855L7.30388 16.1745Z"
        fill="#6DA544"
      />
      <path
        d="M3.51471 3.51379C-1.17157 8.20008 -1.17157 15.7981 3.51471 20.4844C5.45126 18.5479 7.31375 16.6854 12 11.9991L3.51471 3.51379Z"
        fill="#D80027"
      />
      <path
        d="M4.76268 9.39178L5.42123 10.7689L6.90852 10.4252L6.24243 11.7987L7.43845 12.7471L5.94937 13.0827L5.95349 14.6092L4.76268 13.6542L3.57182 14.6092L3.57599 13.0827L2.08691 12.7471L3.28288 11.7987L2.61688 10.4252L4.10409 10.7689L4.76268 9.39178Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3734">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default JO;
