import { CountryIconProps } from './types';

const TO = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M7.82577 6.26137V4.69617H6.26052V6.26137H4.69531V7.82662H6.26052V9.39183H7.82577V7.82662H9.39097V6.26137H7.82577Z"
      fill="#D80027"
    />
    <path
      d="M12 0V12C5.37262 12 2.73867 12 0 12C0 18.6274 5.37262 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37262 18.6274 0 12 0Z"
      fill="#D80027"
    />
  </svg>
);
export default TO;
