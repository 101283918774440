import { WYSIWYGProps } from './types';

const MDUnorderedList = ({ color }: WYSIWYGProps) => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.21875 5.6875C1.4925 5.6875 0.90625 6.27375 0.90625 7C0.90625 7.72625 1.4925 8.3125 2.21875 8.3125C2.945 8.3125 3.53125 7.72625 3.53125 7C3.53125 6.27375 2.945 5.6875 2.21875 5.6875ZM2.21875 0.4375C1.4925 0.4375 0.90625 1.02375 0.90625 1.75C0.90625 2.47625 1.4925 3.0625 2.21875 3.0625C2.945 3.0625 3.53125 2.47625 3.53125 1.75C3.53125 1.02375 2.945 0.4375 2.21875 0.4375ZM2.21875 10.9375C1.4925 10.9375 0.90625 11.5325 0.90625 12.25C0.90625 12.9675 1.50125 13.5625 2.21875 13.5625C2.93625 13.5625 3.53125 12.9675 3.53125 12.25C3.53125 11.5325 2.945 10.9375 2.21875 10.9375ZM4.84375 13.125H17.0938V11.375H4.84375V13.125ZM4.84375 7.875H17.0938V6.125H4.84375V7.875ZM4.84375 0.875V2.625H17.0938V0.875H4.84375Z"
      fill={color || '#98A2B3'}
    />
  </svg>
);
export default MDUnorderedList;
