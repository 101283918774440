/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { Avatar, Colors, ColorTypes, Typography } from '..';
import * as Icons from '../Icons';
import * as Styled from './styles';

import '../common.module.css';

interface SelectInputOption {
  title: string;
  secondary?: string;
  imgUrl?: string;
  icon?: Icons.BaseIconTypes | Icons.MiscIconTypes;
  iconColor?: ColorTypes;
  isDisabled?: boolean;
}

interface SelectInputProps {
  iconColor?: ColorTypes;
  iconPlaceHolder?: Icons.BaseIconTypes | Icons.MiscIconTypes;
  label?: string;
  options: SelectInputOption[];
  defaultOption?: SelectInputOption;
  placeholder: string;
  keepPlaceholderAsPrefix?: boolean; // This option allows to keep the 'placeholder' value as a prefix of the displayed option
  onChange: (index: number) => void;
}
let timer: NodeJS.Timeout | null = null;

const SelectInput = ({
  iconColor,
  iconPlaceHolder,
  label,
  options,
  placeholder,
  keepPlaceholderAsPrefix = false,
  defaultOption,
  onChange,
}: SelectInputProps) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isOptionsFocused, setIsOptionsFocused] = useState(false);
  const [selected, setSelected] = useState<number | null>(() => {
    if (defaultOption) {
      const optionIndex = options.findIndex(
        (option) => defaultOption.title === option.title
      );
      return optionIndex !== -1 ? optionIndex : null;
    }
    return null;
  });

  useEffect(
    () => () => {
      if (timer !== null) clearTimeout(timer);
    },
    []
  );
  useEffect(() => {
    if (onChange && selected !== null) {
      onChange(selected);
    }
    setIsInputFocused(false);
    setIsOptionsFocused(false);
  }, [onChange, selected]);

  const isBaseIcon =
    iconPlaceHolder && Object.keys(Icons.BaseIcons).includes(iconPlaceHolder);
  const Icon =
    iconPlaceHolder && isBaseIcon
      ? Icons.getBaseIcon(iconPlaceHolder as Icons.BaseIconTypes)
      : Icons.getMiscIcon(iconPlaceHolder as Icons.MiscIconTypes);

  // using timeout to avoid race condition
  const handleAddTimer = () => {
    timer = setTimeout(() => setIsInputFocused(false), 0);
  };
  const handleButtonClick = (index: number) => setSelected(index);

  return (
    <Styled.Root data-test="single-select-container">
      {label && (
        <Styled.Typography
          type="text-sm"
          fontWeight="medium"
          color={Colors.Gray[700]}
        >
          {label}
        </Styled.Typography>
      )}
      <Styled.RootButton
        type="button"
        className={`${
          selected !== null && options[selected].imgUrl ? 'rootWithAvatar' : ''
        }`}
        onFocus={() => setIsInputFocused(true)}
        onBlur={handleAddTimer}
        data-test="single-select-button"
      >
        {selected !== null && options[selected].imgUrl ? (
          <Styled.AvatarContainer>
            <Avatar imgUrl={options[selected].imgUrl} size="xs" />
          </Styled.AvatarContainer>
        ) : (
          Icon && (
            <Styled.PlaceholderIcon>
              <Icon
                color={
                  iconColor
                    ? Colors[iconColor][500]
                    : isBaseIcon
                    ? Colors.Gray[500]
                    : Colors.Success[500]
                }
                dimension={isBaseIcon ? 16 : undefined}
              />
            </Styled.PlaceholderIcon>
          )
        )}
        {selected !== null ? (
          <>
            {keepPlaceholderAsPrefix ? `${placeholder} ` : ''}
            {options[selected].title}
            {options[selected].secondary && (
              <Styled.SecondaryTitle>
                {options[selected].secondary}
              </Styled.SecondaryTitle>
            )}
          </>
        ) : (
          <Styled.Placeholder>{placeholder}</Styled.Placeholder>
        )}
        <Styled.IconContainer>
          <Icons.BaseIcons.ChevronDown
            dimension={20}
            color={Colors.Gray[500]}
          />
        </Styled.IconContainer>
      </Styled.RootButton>

      {(isInputFocused || isOptionsFocused) && (
        <Styled.DropDown
          onFocus={() => setIsOptionsFocused(true)}
          onBlur={() => setIsOptionsFocused(false)}
        >
          {options.map((option, index) => {
            const isBaseIcon =
              option.icon && Object.keys(Icons.BaseIcons).includes(option.icon);
            const Icon =
              option.icon && isBaseIcon
                ? Icons.getBaseIcon(option.icon as Icons.BaseIconTypes)
                : Icons.getMiscIcon(option.icon as Icons.MiscIconTypes);

            return (
              <Styled.Option
                type="button"
                key={index}
                disabled={option.isDisabled}
                onClick={() => handleButtonClick(index)}
                data-test={`single-select-option-${index}`}
                $optionWithAvatar={Boolean(option.imgUrl)}
              >
                {option.imgUrl ? (
                  <Styled.AvatarContainer>
                    <Avatar imgUrl={option.imgUrl} size="xs" />
                  </Styled.AvatarContainer>
                ) : (
                  Icon && (
                    <Styled.PlaceholderIcon>
                      <Icon
                        color={
                          option.iconColor
                            ? Colors[option.iconColor][500]
                            : isBaseIcon
                            ? Colors.Gray[500]
                            : Colors.Success[500]
                        }
                        dimension={isBaseIcon ? 16 : undefined}
                      />
                    </Styled.PlaceholderIcon>
                  )
                )}
                {option.title}
                {option.secondary && (
                  <Styled.SecondaryTitle>
                    {option.secondary}
                  </Styled.SecondaryTitle>
                )}
                {index === selected && (
                  <Styled.Selected>
                    <Icons.BaseIcons.Check
                      dimension={20}
                      color={Colors.Primary[600]}
                    />
                  </Styled.Selected>
                )}
              </Styled.Option>
            );
          })}
        </Styled.DropDown>
      )}
    </Styled.Root>
  );
};

export default SelectInput;
export type { SelectInputOption, SelectInputProps };
