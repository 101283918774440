import { CountryIconProps } from './types';

const SB = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3703)">
      <path
        d="M20.4863 20.4851C24.9868 15.9846 25.1645 8.79939 21.0213 4.08569L12.0007 11.9994L4.08691 21.0201C8.80061 25.1633 15.9858 24.9856 20.4863 20.4851Z"
        fill="#496E2D"
      />
      <path
        d="M3.51438 3.5144C8.01488 -0.986107 15.2001 -1.16381 19.9138 2.97942L12 12.0001L2.97944 19.9138C-1.16383 15.2001 -0.986083 8.01491 3.51438 3.5144Z"
        fill="#0052B4"
      />
      <path
        d="M19.9139 2.98022L2.97949 19.9146C3.15068 20.1094 3.32843 20.3005 3.51448 20.4864C3.70048 20.6724 3.89158 20.8502 4.0864 21.0214L21.0207 4.08703C20.8495 3.89222 20.6717 3.70116 20.4856 3.51511C20.2997 3.32921 20.1086 3.15141 19.9139 2.98022Z"
        fill="#FFDA44"
      />
      <path
        d="M5.05619 4.17444L5.31526 4.97164H6.15343L5.47534 5.46425L5.73433 6.26145L5.05619 5.76875L4.37805 6.26145L4.63712 5.46425L3.95898 4.97164H4.7972L5.05619 4.17444Z"
        fill="#F0F0F0"
      />
      <path
        d="M9.33744 4.17358L9.59652 4.97079H10.4346L9.75655 5.46339L10.0156 6.2606L9.33744 5.76789L8.6593 6.2606L8.91837 5.46339L8.24023 4.97079H9.07841L9.33744 4.17358Z"
        fill="#F0F0F0"
      />
      <path
        d="M5.05619 8.34778L5.31526 9.14498H6.15343L5.47534 9.63763L5.73433 10.4348L5.05619 9.94213L4.37805 10.4348L4.63712 9.63763L3.95898 9.14498H4.7972L5.05619 8.34778Z"
        fill="#F0F0F0"
      />
      <path
        d="M9.33744 8.34778L9.59652 9.14498H10.4346L9.75655 9.63763L10.0156 10.4348L9.33744 9.94213L8.6593 10.4348L8.91837 9.63763L8.24023 9.14498H9.07841L9.33744 8.34778Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.19686 6.2616L7.45593 7.0588H8.29406L7.61597 7.55145L7.875 8.34865L7.19686 7.85595L6.51872 8.34865L6.77775 7.55145L6.09961 7.0588H6.93783L7.19686 6.2616Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3703">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SB;
