import { CountryIconProps } from './types';

const PK = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3748)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M0 12.0002C0 16.5487 2.53074 20.5059 6.26086 22.5411V1.45929C2.53074 3.49455 0 7.4517 0 12.0002Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9999 0.000793457C9.92098 0.000793457 7.96563 0.529684 6.26074 1.45987V22.5417C7.96563 23.4719 9.92098 24.0008 11.9999 24.0008C18.6273 24.0008 23.9999 18.6282 23.9999 12.0008C23.9999 5.37342 18.6273 0.000793457 11.9999 0.000793457Z"
        fill="#496E2D"
      />
      <path
        d="M17.1307 13.9861C15.6126 15.083 13.4926 14.7415 12.3957 13.2234C11.2987 11.7053 11.6402 9.58533 13.1584 8.48846C13.6317 8.14646 14.1636 7.94428 14.7046 7.87402C13.6637 7.71835 12.5633 7.95291 11.6428 8.61802C9.77437 9.96811 9.35404 12.5772 10.7041 14.4458C12.0541 16.3142 14.6633 16.7345 16.5318 15.3843C17.4524 14.7192 18.0206 13.7483 18.1995 12.711C17.963 13.2025 17.6041 13.644 17.1307 13.9861Z"
        fill="#F0F0F0"
      />
      <path
        d="M17.0659 7.82721L17.9211 8.74882L19.0618 8.22044L18.4495 9.31844L19.3047 10.2401L18.0711 9.9971L17.4588 11.0952L17.3088 9.84691L16.0752 9.60386L17.216 9.07549L17.0659 7.82721Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3748">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default PK;
