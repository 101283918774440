/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { Colors } from '..';
import * as Icons from '../Icons';
import * as Styled from './styles';

import '../common.module.css';

interface CheckboxProps {
  addHoverStyle?: boolean;
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  label?: string;
  secondaryLabel?: string;
  variant?: 'default' | 'rounded';
  // this can be triggered by an user action (e.g. click) or if a checkbox changes programatically
  onChange?: (checked: boolean) => void;
  // this is only triggered by the user action (click)
  onClick?: (checked: boolean, e: React.MouseEvent) => void;
}

const Checkbox = ({
  addHoverStyle,
  checked,
  disabled,
  indeterminate,
  label,
  secondaryLabel,
  variant = 'default',
  onChange,
  onClick,
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked || false);
  const [isIndeterminate, setIsIndeterminate] = useState(
    indeterminate || false
  );

  useEffect(() => {
    if (checked !== undefined) {
      setIsChecked(checked);
    }

    if (checked) {
      setIsIndeterminate(false);
    }
  }, [checked]);

  useEffect(() => {
    onChange?.(isChecked);
    if (isChecked) {
      setIsIndeterminate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsChecked(e.target.checked);
  };

  const toggleChecked = (e: React.MouseEvent) => {
    setIsChecked(!isChecked);
    onClick?.(!isChecked, e);
  };

  const isRounded = variant === 'rounded';

  return (
    <Styled.Root>
      <Styled.Switch>
        <Styled.ToggleHolder>
          <Styled.Input
            type="checkbox"
            checked={isChecked}
            onChange={handleChange}
            disabled={disabled}
          />
          <Styled.Button
            className={isRounded ? 'rounded' : ''}
            type="button"
            $isDisabled={!!disabled}
            $isChecked={isChecked}
            $isIndeterminate={isIndeterminate}
            onClick={(e) => (disabled ? undefined : toggleChecked(e))}
          >
            {isChecked ? (
              <Styled.IconHolder>
                <Icons.BaseIcons.Check
                  color={disabled ? Colors.Gray[200] : Colors.Primary[50]}
                  dimension={12}
                />
              </Styled.IconHolder>
            ) : (
              !isRounded &&
              isIndeterminate && (
                <Styled.IconHolder>
                  <Icons.BaseIcons.Minus
                    color={disabled ? Colors.Gray[200] : Colors.Primary[50]}
                    dimension={12}
                  />
                </Styled.IconHolder>
              )
            )}
          </Styled.Button>
        </Styled.ToggleHolder>
        {(label || secondaryLabel) && (
          <Styled.Label
            $color={addHoverStyle ? Colors.Primary[700] : Colors.Gray[700]}
            onClick={disabled ? undefined : toggleChecked}
          >
            {label}
          </Styled.Label>
        )}
        {secondaryLabel && (
          <>
            <div />
            <Styled.SecondaryLabel
              $color={addHoverStyle ? Colors.Primary[500] : Colors.Gray[500]}
            >
              {secondaryLabel}
            </Styled.SecondaryLabel>
          </>
        )}
      </Styled.Switch>
    </Styled.Root>
  );
};

export default Checkbox;
export type { CheckboxProps };
