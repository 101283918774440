import styled from 'styled-components';
import Colors from '../Colors';

export const Root = styled.div`
  display: flex;
`;

export const TrailingAddButton = styled.div<{ $borderWidth?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  border: 1px dashed ${Colors.Gray[300]};
  border-radius: 50%;
  cursor: pointer;
  border-width: ${(props) => props.$borderWidth};

  // added min-width because otherwise it gets incorrectly stretched
  &.xs {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }

  &.sm {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }

  &.md {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }

  &.lg {
    min-width: 48px;
    width: 48px;
    height: 48px;
  }

  &.xl {
    min-width: 56px;
    width: 56px;
    height: 56px;
  }

  &.xxl {
    min-width: 64px;
    width: 64px;
    height: 64px;
  }
`;
