import styled from 'styled-components';
import Typography from '../Typography/Typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const KeyValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: unset;
`;

export const ValueTypography = styled(Typography)`
  text-align: right;
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0.5rem;
`;
