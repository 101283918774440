import React, { useMemo } from 'react';
import {
  Column,
  Row,
  SortingRule,
  useExpanded,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import TableFooter from './TableFooter';
import TableHead from './TableHead';
import TableRow from './TableRow';

import '../common.module.css';
import * as Styled from './styles';

import EmptyState, { EmptyStateProps } from '../EmptyState/EmptyState';
import LoadingScreen, { MessageDelay } from '../LoadingScreen/LoadingScreen';
import { CellLink } from './types/custom-types';

import './types/react-table-config';

interface TableProps<D extends object = object>
  extends React.TableHTMLAttributes<HTMLTableElement> {
  columns: Column<D>[];
  data: D[];
  loading?: {
    messages: string[];
    firstMessageDelay?: MessageDelay;
  } | null;
  cellLink?: CellLink<D>;
  onRowProps?: (row: Row<D>) => object;
  onSort?: (sortBy: SortingRule<D>[]) => any;
  sorting?: SortingRule<D>[];
  pageIndex?: number;
  rowsPerPage?: number;
  rowsTotal: number;
  emptyStateProps: EmptyStateProps;
  disableAutoExpanderColumn?: boolean;
  onRowExpand?: (row: Row<any>) => void;
}

const Table = <D extends object = object>({
  columns,
  data,
  loading,
  cellLink,
  onRowProps,
  onSort,
  sorting,
  pageIndex: inputPageIndex,
  rowsPerPage: inputRowsPerPage,
  rowsTotal,
  emptyStateProps,
  disableAutoExpanderColumn = true,
  onRowExpand,
}: TableProps<D>) => {
  const rowsPerPage = inputRowsPerPage || 10;

  const hasFooter = columns.some((column) => column.Footer);
  const newColumns = useMemo(
    () =>
      !disableAutoExpanderColumn
        ? [
            {
              id: 'expander',
              width: 40,
              sticky: 'left' as const,
            },
            ...columns,
          ]
        : columns,
    [disableAutoExpanderColumn, columns]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    page,
    setPageSize,
    setSortBy,
    state: { sortBy },
  } = useTable<D>(
    {
      columns: newColumns,
      data,
      disableMultiSort: true,
      disableSortBy: !onSort || !sorting,
      initialState: {
        pageIndex: inputPageIndex ?? 0,
        pageSize: rowsPerPage,
        sortBy: sorting ?? [],
      },
      manualPagination: true,
      manualSortBy: true,
      pageCount: Math.ceil(rowsTotal / rowsPerPage),
    },
    useSortBy,
    useFlexLayout,
    useSticky,
    useExpanded,
    usePagination
  );

  React.useEffect(() => {
    setSortBy(sorting ?? []);
  }, [setSortBy, sorting]);

  React.useEffect(() => {
    onSort?.(sortBy);
  }, [onSort, sortBy]);

  React.useEffect(() => {
    setPageSize(rowsPerPage);
  }, [rowsPerPage, setPageSize]);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div style={{ overflowX: 'auto' }}>
      {!rows.length && !loading ? (
        <EmptyState {...emptyStateProps} />
      ) : (
        <Styled.Table {...getTableProps()} data-test="table">
          <TableHead headerGroups={headerGroups} />

          <Styled.TableBody {...getTableBodyProps()} data-test="table-body">
            {loading && (
              <tr>
                <td>
                  <Styled.LoadingScreenWrapper>
                    <LoadingScreen
                      messages={loading.messages}
                      firstMessageDelay={loading.firstMessageDelay}
                    />
                  </Styled.LoadingScreenWrapper>
                  <Styled.ClearFloat />
                </td>
              </tr>
            )}

            {!loading &&
              page.map((row, rowIndex) => (
                <TableRow
                  key={`table-row-${row.id}`}
                  cellLink={cellLink}
                  prepareRow={prepareRow}
                  row={row}
                  rowIndex={rowIndex}
                  rowProps={(r: Row<D>) => onRowProps?.(r) || {}}
                  onClickExpand={(rowInfo) => onRowExpand?.(rowInfo)}
                />
              ))}
          </Styled.TableBody>

          {hasFooter && <TableFooter footerGroups={footerGroups} />}
        </Styled.Table>
      )}
    </div>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

export type { TableProps };

export default Table;
