import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase, Colors, Icons, Typography } from '@replai-platform/ui-components';
import styled from 'styled-components';
import { NetworkIcon } from '../../NetworkIcon';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';

export type NetworkAccessorType = {
  network: SDK.Network;
  promotedObjectType?: SDK.PromotedObjectType;
  disabled: boolean;
};

const CreativeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const CreativeInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const GrayscaleIconContainer = styled.span`
  filter: grayscale(1);
`;

type Props = NetworkAccessorType;

const NetworkDecorator = ({ network, promotedObjectType, disabled = false }: Props) => {
  const isPromotedObjectType = !!promotedObjectType?.length;
  let Icon: ({
    dimension,
  }: Icons.IntegrationIcons.IntegrationIconProps | Icons.FlatSocialIconProps) => JSX.Element | null = () => null;
  if (isPromotedObjectType) {
    switch (promotedObjectType) {
      case SDK.PromotedObjectType.ANDROID:
        Icon = Icons.getFlatIcon('Android');
        break;
      case SDK.PromotedObjectType.IOS:
        Icon = Icons.getFlatIcon('Apple');
        break;
      case SDK.PromotedObjectType.WEBSITE:
      default:
        Icon = Icons.getFlatIcon('Website');
        break;
    }
  } else {
    switch (network) {
      case SDK.Network.FACEBOOK:
        Icon = Icons.getIntegrationIcon('Facebook');
        break;
      case SDK.Network.GOOGLE:
        Icon = Icons.getIntegrationIcon('Google');
        break;
      case SDK.Network.SNAPCHAT:
        Icon = Icons.getIntegrationIcon('Snapchat');
        break;
      case SDK.Network.TIKTOK:
        Icon = Icons.getIntegrationIcon('Tiktok');
        break;
      case SDK.Network.APPLOVIN:
      case SDK.Network.IRONSOURCE:
      case SDK.Network.LIFTOFF:
      case SDK.Network.MINTEGRAL:
      case SDK.Network.MOLOCO:
      case SDK.Network.REPLAI_API:
      case SDK.Network.SENSORTOWER:
      case SDK.Network.UNITY:
      case SDK.Network.VUNGLE:
      case SDK.Network.YOUTUBE:
        Icon = NetworkIcon(network);
        break;
      default: {
        const exhaustiveCheck: never = network;
        return exhaustiveCheck;
      }
    }
  }

  return (
    <CreativeContainer>
      {disabled ? (
        <GrayscaleIconContainer>
          <Icon dimension={45} />
        </GrayscaleIconContainer>
      ) : (
        <Icon dimension={45} />
      )}
      <CreativeInfo>
        {disabled ? (
          <Typography fontWeight="semi-bold" noMargin color={Colors.Gray[400]} type="text-sm">
            {isPromotedObjectType ? SDK.getPromotedObjectString(promotedObjectType) : camelCaseToCapitalCase(network)}
          </Typography>
        ) : (
          <Typography noMargin type="text-sm" fontWeight="semi-bold">
            {isPromotedObjectType ? SDK.getPromotedObjectString(promotedObjectType) : camelCaseToCapitalCase(network)}
          </Typography>
        )}
        {disabled ? (
          <Typography noMargin color={Colors.Gray[400]} type="text-xs">
            Data not available
          </Typography>
        ) : null}
      </CreativeInfo>
    </CreativeContainer>
  );
};

function getNetworkNameIconColumn<T extends object = NetworkAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<T, NetworkAccessorType>) => (
      <NetworkDecorator
        network={data?.cell?.value?.network}
        promotedObjectType={data?.cell?.value?.promotedObjectType}
        disabled={data?.cell?.value?.disabled}
      />
    ),
  };
}

export default getNetworkNameIconColumn;
