import { CountryIconProps } from './types';

const SG = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M0 12C0 5.37262 5.37262 0 12 0C18.6274 0 24 5.37262 24 12"
      fill="#D80027"
    />
    <path
      d="M7.30353 6.26101C7.30353 4.51267 8.53226 3.05214 10.1731 2.69378C9.92089 2.63875 9.65928 2.60889 9.39045 2.60889C7.37337 2.60889 5.73828 4.24398 5.73828 6.26106C5.73828 8.27814 7.37337 9.91323 9.39045 9.91323C9.65919 9.91323 9.92084 9.88337 10.1731 9.82829C8.53226 9.46993 7.30353 8.0094 7.30353 6.26101Z"
      fill="#F0F0F0"
    />
    <path
      d="M11.9995 2.87L12.2585 3.66711H13.0967L12.4186 4.15981L12.6776 4.95692L11.9995 4.46431L11.3214 4.95692L11.5804 4.15981L10.9023 3.66711H11.7405L11.9995 2.87Z"
      fill="#F0F0F0"
    />
    <path
      d="M9.96639 4.43469L10.2254 5.23185H11.0635L10.3855 5.7245L10.6445 6.52161L9.96639 6.029L9.2882 6.52161L9.54728 5.7245L8.86914 5.23185H9.70731L9.96639 4.43469Z"
      fill="#F0F0F0"
    />
    <path
      d="M14.0327 4.43469L14.2918 5.23185H15.1299L14.4518 5.7245L14.7108 6.52161L14.0327 6.029L13.3546 6.52161L13.6136 5.7245L12.9355 5.23185H13.7737L14.0327 4.43469Z"
      fill="#F0F0F0"
    />
    <path
      d="M13.2505 6.78345L13.5095 7.5806H14.3476L13.6695 8.07326L13.9286 8.87037L13.2505 8.37776L12.5724 8.87037L12.8314 8.07326L12.1533 7.5806H12.9914L13.2505 6.78345Z"
      fill="#F0F0F0"
    />
    <path
      d="M10.7486 6.78345L11.0076 7.5806H11.8458L11.1676 8.07326L11.4267 8.87037L10.7486 8.37776L10.0704 8.87037L10.3295 8.07326L9.65137 7.5806H10.4895L10.7486 6.78345Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default SG;
