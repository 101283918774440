import { Avatar } from '@replai-platform/ui-components';
import * as SDK from '@replai-platform/sdk';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../../common';

export type ReportTypeAccessorType = {
  type: SDK.ReportTypes;
};

const ReportTypeCellDecorator: React.FC<{
  cellData: ReportTypeAccessorType;
}> = ({ cellData }) => {
  if (!cellData) {
    return null;
  }

  return <Avatar squared noBackground imgUrl={cellData?.type ? SDK.getReportTypeImageUrl(cellData?.type) : ''} />;
};

function getReportTypeColumn<T extends object = ReportTypeAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T, ReportTypeAccessorType>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<T, ReportTypeAccessorType>) => <ReportTypeCellDecorator cellData={data?.cell?.value} />,
  };
}

export default getReportTypeColumn;
