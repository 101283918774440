import { useDispatch, useSelector } from 'react-redux';
import { DatePickerButton } from '@replai-platform/ui-components';
import { FilterActions } from '../../../store/filters';
import { RootState } from '../../../store/rootReducer';
import DatesPicker from '../DatesPicker';

export interface DateFilterProps {
  customRanges?: DatePickerButton[];
  disabled?: boolean;
  customDispatch?: (params) => void;
  customDates?: { startDate?: string; endDate?: string; rangeDays?: string | number };
}

const DateFilter: React.VFC<DateFilterProps> = ({ customRanges, disabled, customDispatch, customDates }) => {
  const { startDate, endDate, rangeDays } = useSelector((state: RootState) => state.filters);
  const dispatch = useDispatch();
  return (
    <DatesPicker
      data-test="filter-datepicker-date"
      startDate={customDates?.startDate ?? startDate}
      endDate={customDates?.endDate ?? endDate}
      rangeDays={customDates?.rangeDays ?? rangeDays}
      customRanges={customRanges}
      disabled={disabled}
      onChange={(start, end, range) => {
        const dispatchParams = {
          logEvent: true,
          startDate: start,
          endDate: end,
          rangeDays: range,
        };
        dispatch(customDispatch ? customDispatch(dispatchParams) : FilterActions.changeDateRange(dispatchParams));
      }}
    />
  );
};

export default DateFilter;
