import styled from 'styled-components';
import Badge from '../Badge/Badge';
import Colors from '../Colors';
import SkeletonLoading from '../Skeleton/Skeleton';
import Typography from '../Typography/Typography';

export const Container = styled.div<{ reference: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 23rem;
  min-width: 23rem;
  min-height: 23rem;
  padding: 15px;
  border-right: 1px solid ${Colors.Gray[200]};
  border-top: 1px solid ${Colors.Gray[200]};
  background-color: ${({ reference }) =>
    reference ? Colors.Gray[50] : Colors.Common.White};
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const MarginContainer = styled.div`
  padding: 16px;
  background-color: ${Colors.Common.White};
  border-radius: 8px;
`;

export const NameContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  overflow: hidden;
  height: 20px;
`;

export const NameLabelContainer = styled.span`
  width: 100%;
  overflow: hidden;
`;

export const Name = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const VideoContainer = styled.div`
  height: 14rem;
  max-height: 14rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

export const MetricsContainer = styled.div<{ show: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  min-height: ${({ show }) => (show ? '100px' : 0)};
  max-height: ${({ show }) => (show ? '100px' : 0)};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  overflow: hidden;
`;

export const MetricLine = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;

  .react-loading-skeleton {
    line-height: unset;
  }
`;

export const StyledBadge = styled(Badge)`
  white-space: nowrap;
`;

export const MetricTypography = styled(Typography)`
  white-space: nowrap;
`;

export const CustomMetricsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1.5rem;
`;

export const CustomMetric = styled(MetricLine)`
  align-items: center;
`;

export const SpendContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const VideoSkeletonLoading = styled(SkeletonLoading)`
  border-radius: 8px;
`;

export const RightAlignedContainer = styled.div<{ reference: boolean }>`
  margin-left: auto;
  cursor: pointer;
  display: ${({ reference }) => reference && 'none'};
`;
