/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Table } from '@replai-platform/ui-components';
import { useMemo, useState } from 'react';
import { SortingRule } from 'react-table';
import { kpiUtils } from '@replai-platform/sdk';
import { getNetworkNameIconColumn, getSpendColumn, getPerformanceColumn, getExpandCollapseColumn } from '../columns';
import { KpiColumnData } from '../columns/generators/getPerformanceColumn';
import * as Styled from './styles';

const TOTAL_KPIS = ['spend', 'installs', 'impressions', 'earningsDay7', 'purchasesDay7', 'clicks'];

export type NetworksPerformanceProps = {
  networksData: any[];
  perNetworkPerformance?: any[];
  isLoading: boolean;
  kpis: string[];
};

export const NetworksPerformance: React.FC<NetworksPerformanceProps> = ({
  networksData,
  perNetworkPerformance,
  isLoading,
  kpis,
}) => {
  const [sorting, setSorting] = useState([{ id: 'spend', desc: true } as SortingRule<any>]);

  const networks = useMemo(() => [...new Set(networksData?.map((a) => a.network))], [networksData]);

  const tableData = useMemo(() => {
    if (isLoading) return [];

    const sortKPI = sorting?.[0]?.id;
    const descSorting = sorting?.[0]?.desc;

    const data: {
      name: any;
      spend: any;
      totalSpend: any;
      subRows: any[];
      entity: string;
    }[] = [];
    networks?.forEach((network) => {
      const networkAssets = networksData?.filter((a) => a.network === network);
      const totalSpend = networkAssets?.reduce((previous, a) => previous + a.spend, 0);
      const kpiValues = {};

      kpis.forEach((kpi) => {
        if (TOTAL_KPIS.includes(kpi)) {
          kpiValues[kpi] = networkAssets?.reduce((previous, asset) => previous + asset[kpi], 0);
        } else {
          kpiValues[kpi] = perNetworkPerformance?.find((e) => e.network === network && e[kpi])
            ? perNetworkPerformance.find((e) => e.network === network)[kpi]
            : networkAssets?.reduce((previous, asset) => previous + (asset[kpi] * asset.spend) / totalSpend, 0);
        }
      });

      const networkData: (typeof data)[number] = {
        ...kpiValues,
        name: network,
        spend: totalSpend,
        totalSpend: networkAssets?.[0].totalSpend,
        subRows: [],
        entity: 'network',
      };
      networksData?.forEach((asset) => {
        if (asset?.network === network && asset?.spend > 0) {
          networkData.subRows.push({
            ...asset,
            name: asset.campaign,
            spend: asset.spend,
            totalSpend: networkAssets?.[0].totalSpend,
            entity: 'campaign',
          });
        }
      });
      networkData.subRows.sort((a, b) => (a[sortKPI] < b[sortKPI] ? (descSorting ? 1 : -1) : descSorting ? -1 : 1));
      data.push(networkData);
    });

    data.sort((a, b) => (a[sortKPI] < b[sortKPI] ? (descSorting ? 1 : -1) : descSorting ? -1 : 1));

    return data;
  }, [isLoading, kpis, networks, networksData, sorting]);

  const tableColumns = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return [
      {
        ...getNetworkNameIconColumn({
          columnId: 'network',
          accessor: (data: any) => ({
            name: data.name ?? '',
            // We always need a "network" key so we can call this function to get the Icon, though
            // it will only exist when its actually a network and not an Ad
            network: data.entity === 'network' ? data.name : '',
            promotedObjectType: data.entity === 'campaign' ? data.name : '',
          }),
        }),
        Header: 'Network',
        width: 200,
        minWidth: 200,
        maxWidth: 200,
      },
      ...kpis.map((kpi) =>
        kpi === 'spend'
          ? {
              ...getSpendColumn({
                columnId: 'spend',
                accessor: (data: any) => ({
                  spendValue: data?.spend,
                  total: data?.totalSpend,
                }),
              }),
              Header: <Styled.AlignRightHeaderWrapper>Spend</Styled.AlignRightHeaderWrapper>,
            }
          : {
              ...getPerformanceColumn({
                columnId: `${kpi}`,
                accessor: (data: any): KpiColumnData => ({
                  kpi,
                  kpiValue: data[kpi],
                }),
                includeFooter: true,
              }),
              width: 100,
              minWidth: 100,
              maxWidth: 100,
              Header: <Styled.AlignRightHeaderWrapper>{kpiUtils.getDisplayName(kpi)}</Styled.AlignRightHeaderWrapper>,
              tooltip: `${kpiUtils.getDisplayName(kpi)} (${kpiUtils.getLongName(
                kpi
              )}) of the cluster according to the filters you have selected.`,
            }
      ),
      {
        ...getExpandCollapseColumn({
          columnId: 'expand',
          accessor: (data) => data,
        }),
        width: 44,
        disableSortBy: true,
      },
    ];
  }, [isLoading, kpis]);

  return (
    <div data-test="performance-metrics-container">
      <Table
        data={isLoading ? [] : tableData}
        rowsTotal={isLoading ? 0 : tableData.length}
        columns={tableColumns}
        loading={
          isLoading
            ? {
                firstMessageDelay: 3,
                messages: ['Loading context performance', 'Calculating context performance'],
              }
            : null
        }
        emptyStateProps={{
          icon: 'Search',
          text: 'Context performance is not available at this time',
        }}
        sorting={sorting}
        onSort={(sortBy) => setSorting(sortBy)}
      />
    </div>
  );
};
