import { DropDownChip, DropDownChipProps } from '@replai-platform/ui-components';

export interface AdSetFilterProps {
  options: DropDownChipProps['dropDownOptions'];
  onChange: DropDownChipProps['onChange'];
  loading: boolean;
  dropdownMaxHeightInVH?: number;
}

const AdSetFilter: React.VFC<AdSetFilterProps> = ({ options, onChange, loading, dropdownMaxHeightInVH }) => (
  <DropDownChip
    data-test="filter-ad-set-dropdown"
    placeHolder="Select Ad Sets"
    defaultOption="All Ad Sets"
    dropDownType="multiselect"
    dropDownOptions={options}
    dropDownAlignment="right"
    onChange={onChange}
    prefixLabel="Ad Sets"
    loading={loading}
    dropdownMaxHeightInVH={dropdownMaxHeightInVH}
  />
);

export default AdSetFilter;
