import { CommonTagIconProps } from '../types';

const LowSceneTransitionsDiff = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75599 16.813C9.41414 16.813 11.569 14.6582 11.569 12C11.569 9.34187 9.41414 7.18701 6.75599 7.18701C4.09785 7.18701 1.94299 9.34187 1.94299 12C1.94299 14.6582 4.09785 16.813 6.75599 16.813Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.72 14.5952C16.4 15.2752 17.33 15.6952 18.36 15.6952C20.4 15.6952 22.06 14.0452 22.06 11.9952C22.06 9.95518 20.4 8.30518 18.36 8.30518C17.33 8.30518 16.4 8.72518 15.72 9.40518"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.67 9.19518C11.32 8.63518 12.16 8.30518 13.08 8.30518C14.11 8.30518 15.05 8.72518 15.72 9.40518C16.38 10.0752 16.78 10.9852 16.78 11.9952C16.78 13.0052 16.38 13.9252 15.72 14.5952C15.05 15.2752 14.11 15.6952 13.08 15.6952C12.16 15.6952 11.32 15.3652 10.67 14.8052"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.16602 10.2559L7.90902 11.9999L6.16602 13.7439"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default LowSceneTransitionsDiff;
