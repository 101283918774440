import { CountryIconProps } from './types';

const TW = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C4.12172 7.87828 7.61737 4.38263 12 0C18.6274 0 24 5.37262 24 12Z"
      fill="#D80027"
    />
    <path
      d="M12 12C12 5.37262 12 4.59131 12 0C5.37262 0 0 5.37262 0 12H12Z"
      fill="#0052B4"
    />
    <path
      d="M10.435 7.02305L8.96945 7.71249L9.74983 9.13181L8.15847 8.82731L7.95677 10.435L6.84836 9.25261L5.73986 10.435L5.53825 8.82731L3.94684 9.13172L4.72722 7.71239L3.26172 7.02305L4.72727 6.33375L3.94684 4.91438L5.5382 5.21883L5.73991 3.61121L6.84836 4.79358L7.95681 3.61121L8.15847 5.21883L9.74987 4.91438L8.96945 6.3338L10.435 7.02305Z"
      fill="#F0F0F0"
    />
    <path
      d="M6.84897 9.25671C8.08262 9.25671 9.0827 8.25664 9.0827 7.02298C9.0827 5.78932 8.08262 4.78925 6.84897 4.78925C5.61531 4.78925 4.61523 5.78932 4.61523 7.02298C4.61523 8.25664 5.61531 9.25671 6.84897 9.25671Z"
      fill="#0052B4"
    />
    <path
      d="M6.84841 8.21399C6.19211 8.21399 5.6582 7.68004 5.6582 7.02374C5.6582 6.36745 6.19216 5.8335 6.84841 5.8335C7.50471 5.8335 8.03866 6.36745 8.03866 7.02374C8.03861 7.68004 7.50461 8.21399 6.84841 8.21399Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default TW;
