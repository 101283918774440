import * as SDK from '@replai-platform/sdk';
import { Skeleton } from '@replai-platform/ui-components';
import { FiltersState } from '../../../store/filters';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';
import ReplaiScore from './components/ReplaiScore/ReplaiScore';

export type ReplaiScoreAccessorType = {
  objectId: SDK.UUID;
  projectId: SDK.UUID;
  filters: FiltersState;
  score: number | null;
  objectType?: SDK.ObjectLevel;
};

function getReplaiScoreColumn<D extends object = object>({
  columnId,
  accessor,
}: RequiredGeneratorParams<D, ReplaiScoreAccessorType>): GeneratedColumn<D, ReplaiScoreAccessorType> {
  return {
    id: columnId,
    accessor,
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    Cell: (data: CellData<D, ReplaiScoreAccessorType>) => {
      const { objectId, score: preComputedScore } = data.cell.value;

      if (!objectId) {
        return <Skeleton height={20} width={100} />;
      }

      return preComputedScore ? <ReplaiScore score={preComputedScore} useCase="video-performance" /> : null;
    },
  };
}

export default getReplaiScoreColumn;
