import { CountryIconProps } from './types';

const AG = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3559)">
      <path
        d="M0 11.9992C0 12.8212 0.082875 13.6238 0.240328 14.3992L12 15.1297L23.7597 14.3992C23.9172 13.6238 24 12.8212 24 11.9992C24 11.1773 23.9171 10.3747 23.7597 9.59923L12 8.86877L0.240328 9.59923C0.082875 10.3747 0 11.1773 0 11.9992Z"
        fill="#0052B4"
      />
      <path
        d="M23.7596 14.3992H0.240234C1.35206 19.8765 6.19448 23.9992 11.9999 23.9992C17.8053 23.9992 22.6477 19.8765 23.7596 14.3992Z"
        fill="#F0F0F0"
      />
      <path
        d="M0.240234 9.6H23.7597C22.6478 4.12266 17.8054 0 11.9999 0C6.19439 0 1.35206 4.12266 0.240234 9.6Z"
        fill="black"
      />
      <path
        d="M17.218 9.59937H6.7832L8.91508 8.59653L7.77981 6.53192L10.0947 6.97466L10.3882 4.63611L12.0006 6.35614L13.6131 4.63611L13.9063 6.97466L16.2214 6.53192L15.0862 8.59662L17.218 9.59937Z"
        fill="#FFDA44"
      />
      <path
        d="M0 12.0008C0 18.6282 5.37262 24.0008 12 24.0008L2.39981 4.80042C0.893062 6.80615 0 9.2991 0 12.0008Z"
        fill="#A2001D"
      />
      <path
        d="M12 23.9999C18.6274 23.9999 24 18.6272 24 11.9999C24 9.29813 23.1069 6.80517 21.6002 4.79944L12 23.9999Z"
        fill="#A2001D"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3559">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default AG;
