import styled from 'styled-components';
import { Colors } from '..';
import Shadow from '../Shadow/Shadow';

export const Container = styled(Shadow)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  box-sizing: border-box;
  width: 400px;
  min-height: 100%;
  padding: 16px;
  background-color: ${Colors.Common.White};
  border: 1px solid ${Colors.Gray[200]};
  border-radius: 0.5rem;
`;

export const Title = styled.span`
  padding-top: 24px;
`;

export const Footer = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(2, max-content);
  justify-content: end;
  margin-top: auto;
  padding-top: 16px;
  border-top: 1px solid ${Colors.Gray[200]};
`;

export const DropdownSectionContainer = styled.div`
  padding-top: 24px;
`;

export const RangeInputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const LoadingScreenWrapper = styled.div`
  position: relative;
  float: left;
  width: 100%;
  height: 10rem;
`;

export const ClearFloat = styled.div`
  clear: both;
`;
