import { IntegrationIconProps } from './types';

const Safari = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
      fill="url(#paint0_linear_1334_471)"
    />
    <path
      d="M16.0008 28.95C23.1529 28.95 28.9508 23.1521 28.9508 16C28.9508 8.8479 23.1529 3.04999 16.0008 3.04999C8.84869 3.04999 3.05078 8.8479 3.05078 16C3.05078 23.1521 8.84869 28.95 16.0008 28.95Z"
      fill="url(#paint1_radial_1334_471)"
    />
    <path
      d="M16.0215 6.20001C15.934 6.20001 15.8574 6.13441 15.8574 6.04691V3.76095C15.8574 3.67345 15.934 3.60785 16.0215 3.60785C16.109 3.60785 16.1855 3.67345 16.1855 3.76095V6.04691C16.1746 6.13441 16.109 6.20001 16.0215 6.20001Z"
      fill="#F3F3F3"
    />
    <path
      d="M16.0226 6.21096C15.9242 6.21096 15.8477 6.13441 15.8477 6.04691V3.76097C15.8477 3.67347 15.9242 3.59692 16.0226 3.59692C16.1211 3.59692 16.1976 3.67347 16.1976 3.76097V6.04691C16.1976 6.13441 16.1101 6.21096 16.0226 6.21096ZM16.0226 3.61871C15.9461 3.61871 15.8696 3.68442 15.8696 3.76097V6.04691C15.8696 6.12346 15.9352 6.18917 16.0226 6.18917C16.0992 6.18917 16.1757 6.12346 16.1757 6.04691V3.76097C16.1649 3.68442 16.0992 3.61871 16.0226 3.61871Z"
      fill="white"
    />
    <path
      d="M17.116 4.975C17.0285 4.96405 16.9629 4.89845 16.9629 4.81095L17.0394 3.79367C17.0503 3.70628 17.1269 3.64057 17.2144 3.65152C17.3019 3.66248 17.3675 3.72819 17.3675 3.81569L17.291 4.83285C17.28 4.92035 17.2035 4.98595 17.116 4.97511V4.975Z"
      fill="#F3F3F3"
    />
    <path
      d="M17.1152 4.98598C17.0168 4.97503 16.9512 4.89848 16.9512 4.81098L17.0277 3.79371C17.0387 3.70632 17.1152 3.62978 17.2137 3.64061C17.312 3.65157 17.3777 3.72823 17.3777 3.81562L17.3012 4.83288C17.2902 4.93133 17.2137 4.99693 17.1152 4.98598ZM17.2137 3.66252C17.1371 3.66252 17.0605 3.71728 17.0605 3.79383L16.9839 4.81109C16.9839 4.88753 17.0387 4.95324 17.1262 4.96407C17.2027 4.96407 17.2793 4.90943 17.2793 4.83288L17.3558 3.81562C17.3558 3.73907 17.2902 3.67347 17.2136 3.66252H17.2137Z"
      fill="white"
    />
    <path
      d="M17.9354 6.39693C17.8478 6.37502 17.7932 6.29847 17.8041 6.21097L18.2525 3.9687C18.2745 3.88131 18.351 3.82656 18.4385 3.84846C18.526 3.87036 18.5806 3.94691 18.5697 4.03441L18.1213 6.27668C18.1103 6.35312 18.0228 6.40788 17.9353 6.39693H17.9354Z"
      fill="#F3F3F3"
    />
    <path
      d="M17.9366 6.40784C17.838 6.38593 17.7834 6.29843 17.7944 6.21093L18.2428 3.96869C18.2647 3.8813 18.3522 3.81559 18.4505 3.8375C18.5489 3.8594 18.6037 3.9469 18.5927 4.0344L18.1443 6.27664C18.1224 6.36403 18.0349 6.41879 17.9366 6.40784ZM18.4505 3.8594C18.374 3.84845 18.2974 3.89214 18.2755 3.9688L17.8272 6.21105C17.8163 6.28748 17.8599 6.36403 17.9474 6.38605C18.024 6.39689 18.1005 6.35308 18.1224 6.27653L18.5708 4.0344C18.5818 3.9469 18.5271 3.8813 18.4505 3.8594Z"
      fill="white"
    />
    <path
      d="M19.2479 5.41257C19.2271 5.40755 19.2074 5.39847 19.1901 5.38586C19.1728 5.37324 19.1581 5.35734 19.1469 5.33905C19.1358 5.32076 19.1283 5.30045 19.125 5.27928C19.1217 5.25811 19.1226 5.23649 19.1276 5.21567L19.4011 4.23138C19.4229 4.14388 19.5104 4.10007 19.5979 4.12198C19.6854 4.14388 19.7402 4.23138 19.7183 4.31888L19.4448 5.30328C19.4229 5.39067 19.3354 5.43448 19.2479 5.41257Z"
      fill="#F3F3F3"
    />
    <path
      d="M19.248 5.42346C19.1605 5.40155 19.0949 5.3031 19.1278 5.21572L19.4012 4.23121C19.423 4.14383 19.5214 4.08907 19.6089 4.11097C19.6964 4.13287 19.762 4.23132 19.7292 4.31871L19.4558 5.30321C19.4339 5.3906 19.3464 5.44536 19.2481 5.42346H19.248ZM19.6089 4.14383C19.5324 4.12192 19.4449 4.16573 19.423 4.24228L19.1497 5.22655C19.1278 5.3031 19.1714 5.37976 19.2589 5.40155C19.3355 5.42346 19.423 5.37976 19.4449 5.30321L19.7183 4.31871C19.7292 4.24228 19.6855 4.16573 19.6089 4.14371V4.14383Z"
      fill="white"
    />
    <path
      d="M19.7835 6.95468C19.696 6.92182 19.6633 6.82337 19.696 6.74682L20.571 4.63588C20.6038 4.55934 20.7023 4.51553 20.7787 4.55934C20.8664 4.59208 20.8992 4.69064 20.8664 4.76708L19.9914 6.87801C19.9586 6.95467 19.8601 6.99837 19.7837 6.95468H19.7835Z"
      fill="#F3F3F3"
    />
    <path
      d="M19.7852 6.96578C19.7432 6.94977 19.7092 6.91774 19.6907 6.87673C19.6723 6.83571 19.6708 6.78905 19.6867 6.74698L20.5618 4.63601C20.5946 4.54851 20.6931 4.51565 20.7915 4.54851C20.8335 4.56453 20.8675 4.59655 20.886 4.63757C20.9044 4.67858 20.9059 4.72524 20.89 4.76732L20.015 6.87828C19.9711 6.96578 19.8727 7.00948 19.7852 6.96578ZM20.7806 4.57041C20.704 4.53767 20.6165 4.57041 20.5837 4.64696L19.7086 6.75793C19.6759 6.83448 19.7196 6.91103 19.7961 6.94388C19.8727 6.97662 19.9602 6.94388 19.993 6.86733L20.8681 4.75637C20.89 4.69066 20.8571 4.60316 20.7806 4.57041Z"
      fill="white"
    />
    <path
      d="M21.2612 6.25474C21.1846 6.21093 21.1517 6.12343 21.1846 6.04689L21.6439 5.13916C21.6876 5.0625 21.7751 5.02965 21.8627 5.07345C21.9392 5.11726 21.972 5.20476 21.9392 5.28119L21.4798 6.18903C21.4361 6.26558 21.3486 6.29854 21.2611 6.25474H21.2612Z"
      fill="#F3F3F3"
    />
    <path
      d="M21.262 6.26571C21.1743 6.2219 21.1416 6.12345 21.1853 6.03595L21.6447 5.12822C21.6884 5.04061 21.787 5.00786 21.8744 5.05156C21.9619 5.09536 21.9947 5.19381 21.9509 5.28131L21.4916 6.18905C21.4478 6.27655 21.3493 6.3094 21.2618 6.2656L21.262 6.26571ZM21.8635 5.07346C21.7869 5.04072 21.6994 5.06251 21.6666 5.13906L21.2072 6.04691C21.1744 6.1125 21.2072 6.2 21.2728 6.24381C21.3493 6.27655 21.4368 6.25476 21.4697 6.17821L21.9291 5.27036C21.9619 5.20477 21.9291 5.11727 21.8635 5.07346Z"
      fill="white"
    />
    <path
      d="M21.4804 7.87353C21.4038 7.81888 21.3819 7.72043 21.4366 7.65483L22.7054 5.76256C22.7491 5.68601 22.8477 5.67506 22.9241 5.71887C23.0008 5.77351 23.0225 5.87196 22.9679 5.93756L21.6991 7.82983C21.6554 7.90638 21.5569 7.92828 21.4804 7.87353Z"
      fill="#F3F3F3"
    />
    <path
      d="M21.4793 7.88442C21.4028 7.82977 21.3809 7.72037 21.4247 7.64382L22.6934 5.75161C22.748 5.67506 22.8465 5.65316 22.934 5.70791C23.0105 5.76256 23.0324 5.87196 22.9887 5.94851L21.7199 7.84072C21.6653 7.91727 21.5559 7.93906 21.4793 7.88442ZM22.923 5.72982C22.8575 5.68601 22.759 5.69696 22.7153 5.76256L21.4465 7.65478C21.4028 7.72026 21.4246 7.80787 21.4903 7.85156C21.5559 7.89537 21.6543 7.88442 21.698 7.81882L22.9668 5.93755C23.0105 5.87196 22.9887 5.77351 22.923 5.72982Z"
      fill="white"
    />
    <path
      d="M23.0774 7.46886C23.0119 7.4141 22.99 7.31565 23.0446 7.25005L23.679 6.4516C23.7336 6.38612 23.8321 6.37505 23.8977 6.42981C23.9634 6.48445 23.9852 6.58291 23.9304 6.6485L23.2962 7.44695C23.2415 7.51255 23.1431 7.5235 23.0775 7.46886H23.0774Z"
      fill="#F3F3F3"
    />
    <path
      d="M23.065 7.47974C22.9885 7.42498 22.9775 7.31557 23.0323 7.23902L23.6665 6.44066C23.7212 6.364 23.8306 6.35305 23.9071 6.41876C23.9837 6.4734 23.9946 6.58281 23.9399 6.65936L23.3056 7.45783C23.251 7.53439 23.1416 7.54534 23.065 7.47974ZM23.8852 6.44055C23.8197 6.3859 23.7321 6.39685 23.6775 6.46245L23.0431 7.26093C22.9994 7.32652 23.0104 7.41403 23.0759 7.46867C23.1416 7.52343 23.2291 7.51248 23.2837 7.44688L23.9181 6.64841C23.9619 6.58281 23.9508 6.48436 23.8853 6.44066L23.8852 6.44055Z"
      fill="white"
    />
    <path
      d="M22.9672 9.10941C22.9016 9.04382 22.9016 8.94537 22.9672 8.89061L24.5858 7.28289C24.6515 7.2173 24.7498 7.22814 24.8046 7.28289C24.8592 7.33754 24.8702 7.44694 24.8046 7.50158L23.1969 9.10941C23.1312 9.17501 23.0328 9.17501 22.9672 9.10941Z"
      fill="#F3F3F3"
    />
    <path
      d="M22.9555 9.12031C22.9239 9.08822 22.9062 9.04502 22.9062 9.00001C22.9062 8.95501 22.9239 8.91181 22.9555 8.87972L24.5742 7.27187C24.6063 7.24029 24.6495 7.2226 24.6946 7.2226C24.7396 7.2226 24.7828 7.24029 24.8149 7.27187C24.8465 7.30397 24.8641 7.34717 24.8641 7.39217C24.8641 7.43717 24.8465 7.48037 24.8149 7.51247L23.1961 9.1202C23.164 9.15174 23.1208 9.16942 23.0758 9.16942C23.0308 9.16942 22.9876 9.15174 22.9555 9.1202V9.12031ZM24.804 7.28282C24.7493 7.22807 24.6509 7.22807 24.5962 7.28282L22.9774 8.89055C22.9226 8.94531 22.9226 9.04376 22.9774 9.09841C23.032 9.15317 23.1305 9.15317 23.1851 9.09841L24.804 7.49068C24.8586 7.43592 24.8586 7.34842 24.804 7.28282Z"
      fill="white"
    />
    <path
      d="M24.6081 9.02186C24.5534 8.95627 24.5534 8.85782 24.619 8.80306L25.3955 8.14689C25.4611 8.09225 25.5596 8.10308 25.6143 8.16879C25.6689 8.23439 25.6689 8.33284 25.6034 8.38748L24.8269 9.04376C24.7612 9.09841 24.6627 9.08746 24.6081 9.02186Z"
      fill="#F3F3F3"
    />
    <path
      d="M24.5968 9.03285C24.5312 8.95631 24.5422 8.8469 24.6077 8.79226L25.3842 8.13596C25.4498 8.07036 25.5592 8.08131 25.6248 8.15786C25.6905 8.23441 25.6796 8.34381 25.614 8.39846L24.8374 9.05476C24.7719 9.12024 24.6624 9.1094 24.5969 9.03285H24.5968ZM25.603 8.16881C25.5483 8.10322 25.4608 8.10322 25.3953 8.14691L24.6187 8.80321C24.5641 8.85786 24.5531 8.94535 24.6077 9.01095C24.6624 9.07655 24.75 9.07655 24.8155 9.03285L25.5921 8.37655C25.6577 8.32191 25.6577 8.23441 25.603 8.16881Z"
      fill="white"
    />
    <path
      d="M24.171 10.5969C24.1163 10.5204 24.1382 10.4219 24.2148 10.3781L26.1178 9.1094C26.1943 9.06559 26.2928 9.0875 26.3365 9.15321C26.3912 9.22976 26.3693 9.32821 26.2928 9.3719L24.3898 10.6406C24.3241 10.6844 24.2256 10.6625 24.171 10.5969Z"
      fill="#F3F3F3"
    />
    <path
      d="M24.1592 10.5969C24.1046 10.5204 24.1265 10.411 24.2031 10.3563L26.1061 9.08751C26.1827 9.03287 26.2921 9.05477 26.3467 9.14227C26.4015 9.21882 26.3795 9.32822 26.303 9.38275L24.4 10.6517C24.3233 10.7064 24.214 10.6845 24.1592 10.597V10.5969ZM26.3248 9.16417C26.2811 9.09847 26.1936 9.07656 26.128 9.12037L24.2248 10.389C24.1594 10.4329 24.1373 10.5204 24.1921 10.5969C24.2358 10.6625 24.3233 10.6844 24.389 10.6407L26.2921 9.37191C26.3577 9.31727 26.3686 9.22966 26.3248 9.16417Z"
      fill="white"
    />
    <path
      d="M25.7997 10.8267C25.7559 10.75 25.7778 10.6517 25.8543 10.6079L26.7403 10.1048C26.8168 10.061 26.9153 10.0938 26.959 10.1703C27.0028 10.2469 26.9809 10.3452 26.9043 10.3891L26.0184 10.8922C25.9418 10.9359 25.8434 10.9032 25.7996 10.8265L25.7997 10.8267Z"
      fill="#F3F3F3"
    />
    <path
      d="M25.7899 10.8375C25.7461 10.75 25.768 10.6516 25.8446 10.6079L26.7305 10.1048C26.8071 10.061 26.9165 10.0938 26.9602 10.1703C27.004 10.2579 26.9821 10.3563 26.9055 10.4L26.0196 10.9033C25.943 10.9469 25.8336 10.9141 25.7899 10.8375ZM26.9492 10.1813C26.9055 10.1048 26.818 10.0829 26.7524 10.1266L25.8665 10.6298C25.8009 10.6735 25.779 10.761 25.8117 10.8266C25.8555 10.9031 25.943 10.925 26.0086 10.8813L26.8946 10.3781C26.9602 10.3454 26.9821 10.2579 26.9492 10.1813Z"
      fill="white"
    />
    <path
      d="M25.0671 12.2813C25.0344 12.1938 25.0671 12.1063 25.1546 12.0736L27.2656 11.1986C27.3421 11.1658 27.4406 11.2095 27.4733 11.286C27.5062 11.3735 27.4733 11.461 27.3858 11.4937L25.275 12.3687C25.1983 12.4016 25.1 12.3687 25.0671 12.2813Z"
      fill="#F3F3F3"
    />
    <path
      d="M25.0554 12.2922C25.0227 12.2048 25.0554 12.1063 25.1429 12.0625L27.2538 11.1876C27.2959 11.1717 27.3425 11.1732 27.3835 11.1916C27.4245 11.2101 27.4565 11.244 27.4725 11.286C27.5054 11.3735 27.4725 11.4719 27.385 11.5156L25.2742 12.3906C25.2322 12.4065 25.1855 12.4051 25.1445 12.3866C25.1035 12.3682 25.0715 12.3343 25.0554 12.2923V12.2922ZM27.4507 11.297C27.4179 11.2204 27.3304 11.1876 27.2648 11.2095L25.1539 12.0844C25.0773 12.1172 25.0445 12.2048 25.0773 12.2813C25.1101 12.3579 25.1976 12.3907 25.2633 12.3688L27.3741 11.4938C27.4507 11.4611 27.4836 11.3735 27.4507 11.297Z"
      fill="white"
    />
    <path
      d="M26.6105 12.8283C26.5777 12.7406 26.6214 12.6533 26.7089 12.6204L27.6713 12.3031C27.7479 12.2704 27.8463 12.325 27.8682 12.4016C27.9009 12.4891 27.8573 12.5766 27.7698 12.6094L26.8074 12.9267C26.7308 12.9594 26.6433 12.9158 26.6105 12.8281V12.8283Z"
      fill="#F3F3F3"
    />
    <path
      d="M26.5986 12.8391C26.5659 12.7516 26.6096 12.6531 26.7081 12.6204L27.6704 12.3031C27.7579 12.2704 27.8564 12.325 27.8892 12.4126C27.9219 12.5 27.8783 12.5985 27.7797 12.6313L26.8174 12.9486C26.7299 12.9704 26.6315 12.9266 26.5986 12.8391ZM27.8673 12.4236C27.8454 12.3469 27.7579 12.3031 27.6814 12.325L26.719 12.6423C26.6424 12.6641 26.6096 12.7516 26.6315 12.8282C26.6534 12.9048 26.7409 12.9485 26.8174 12.9266L27.7798 12.6094C27.8564 12.5767 27.8892 12.5 27.8673 12.4235V12.4236Z"
      fill="white"
    />
    <path
      d="M25.6259 14.1297C25.604 14.0422 25.6587 13.9547 25.7462 13.9438L27.9883 13.5063C28.0757 13.4844 28.1523 13.55 28.1741 13.6375C28.196 13.725 28.1414 13.8125 28.0539 13.8234L25.8118 14.2609C25.7243 14.2719 25.6368 14.2172 25.6258 14.1297H25.6259Z"
      fill="#F3F3F3"
    />
    <path
      d="M25.6039 14.1299C25.582 14.0314 25.6476 13.9439 25.7351 13.922L27.9773 13.4844C28.0648 13.4625 28.1523 13.5283 28.1741 13.6267C28.196 13.7251 28.1304 13.8126 28.0429 13.8345L25.8006 14.272C25.7133 14.2939 25.6257 14.2283 25.6039 14.1297V14.1299ZM28.1523 13.6376C28.1413 13.561 28.0648 13.5064 27.9883 13.5173L25.746 13.9548C25.6695 13.9658 25.6148 14.0532 25.6366 14.1299C25.6476 14.2064 25.7241 14.2611 25.8008 14.2501L28.0429 13.8126C28.1194 13.7907 28.1741 13.7141 28.1523 13.6376Z"
      fill="white"
    />
    <path
      d="M27.0248 14.9719C27.0139 14.8844 27.0685 14.8079 27.156 14.7969L28.1622 14.6765C28.2497 14.6657 28.3264 14.7313 28.3372 14.8188C28.3482 14.9063 28.2935 14.9829 28.206 14.9938L27.1997 15.1141C27.1232 15.125 27.0357 15.0594 27.0247 14.9719H27.0248Z"
      fill="#F3F3F3"
    />
    <path
      d="M27.0149 14.9719C27.0039 14.8734 27.0695 14.7859 27.157 14.775L28.1631 14.6548C28.2506 14.6438 28.3381 14.7094 28.3491 14.8078C28.36 14.9063 28.2944 14.9938 28.207 15.0047L27.2007 15.125C27.1133 15.1359 27.0257 15.0703 27.0149 14.9719ZM28.3381 14.8188C28.3273 14.7423 28.2508 14.6765 28.1741 14.6875L27.168 14.8078C27.0914 14.8188 27.0367 14.8953 27.0476 14.9719C27.0586 15.0484 27.1351 15.1141 27.2116 15.1032L28.2179 14.9828C28.2944 14.9719 28.3491 14.8953 28.3383 14.8188H28.3381Z"
      fill="white"
    />
    <path
      d="M25.8008 16.0329C25.8008 15.9454 25.8663 15.8688 25.9539 15.8688H28.2398C28.3273 15.8688 28.3929 15.9454 28.3929 16.0329C28.3929 16.1204 28.3273 16.1969 28.2398 16.1969H25.9539C25.8664 16.1969 25.8008 16.1204 25.8008 16.0329Z"
      fill="#F3F3F3"
    />
    <path
      d="M25.7891 16.0328C25.7891 15.9344 25.8656 15.8578 25.9531 15.8578H28.2391C28.3266 15.8578 28.4031 15.9344 28.4031 16.0328C28.4031 16.1313 28.3266 16.2078 28.2391 16.2078H25.9531C25.8656 16.2078 25.7891 16.1313 25.7891 16.0328ZM28.3812 16.0438C28.3812 15.9672 28.3156 15.8907 28.2389 15.8907H25.9532C25.8767 15.8907 25.811 15.9562 25.811 16.0438C25.811 16.1203 25.8767 16.1969 25.9532 16.1969H28.2392C28.3157 16.1859 28.3813 16.1203 28.3813 16.0438H28.3812Z"
      fill="white"
    />
    <path
      d="M27.0138 17.1375C27.0246 17.05 27.0903 16.9844 27.1778 16.9844L28.195 17.0609C28.2824 17.0719 28.348 17.1484 28.3371 17.2359C28.3262 17.3234 28.2605 17.389 28.173 17.389L27.1559 17.3125C27.0794 17.3015 27.0137 17.225 27.0137 17.1375H27.0138Z"
      fill="#F3F3F3"
    />
    <path
      d="M27.0039 17.1376C27.0149 17.0391 27.0914 16.9735 27.1789 16.9735L28.1963 17.0501C28.2837 17.061 28.3602 17.1376 28.3493 17.236C28.3384 17.3345 28.2618 17.4001 28.1743 17.4001L27.157 17.3235C27.0695 17.3126 27.0039 17.2251 27.0039 17.1376ZM28.3384 17.236C28.3493 17.1595 28.2837 17.0829 28.2071 17.0829L27.1898 17.0064C27.1133 17.0064 27.0476 17.061 27.0367 17.1486C27.0258 17.2251 27.0914 17.3016 27.168 17.3016L28.1853 17.3782C28.2618 17.3782 28.3275 17.3127 28.3384 17.236Z"
      fill="white"
    />
    <path
      d="M25.603 17.936C25.6249 17.8485 25.7014 17.7937 25.7889 17.8048L28.0311 18.2532C28.1186 18.275 28.1733 18.3516 28.1514 18.4391C28.1295 18.5266 28.053 18.5813 27.9655 18.5704L25.7232 18.1219C25.6467 18.111 25.592 18.0235 25.603 17.936Z"
      fill="#F3F3F3"
    />
    <path
      d="M25.5912 17.936C25.6131 17.8375 25.7006 17.7829 25.7882 17.7937L28.0305 18.2422C28.118 18.264 28.1836 18.3515 28.1617 18.45C28.1399 18.5484 28.0524 18.6032 27.9649 18.5922L25.7224 18.1438C25.6349 18.1219 25.5803 18.0344 25.5912 17.9361V17.936ZM28.1399 18.45C28.1508 18.3734 28.1071 18.2969 28.0305 18.275L25.788 17.8266C25.7116 17.8157 25.6349 17.8594 25.613 17.9469C25.6022 18.0235 25.6459 18.1 25.7226 18.1219L27.9649 18.5703C28.0524 18.5813 28.118 18.5265 28.1399 18.45Z"
      fill="white"
    />
    <path
      d="M26.5877 19.2485C26.5927 19.2277 26.6018 19.208 26.6144 19.1907C26.627 19.1734 26.6429 19.1587 26.6612 19.1476C26.6794 19.1364 26.6997 19.1289 26.7209 19.1256C26.7421 19.1223 26.7637 19.1232 26.7845 19.1282L27.7689 19.4016C27.8564 19.4235 27.9001 19.5111 27.8783 19.5985C27.8733 19.6193 27.8642 19.639 27.8516 19.6563C27.839 19.6737 27.8231 19.6883 27.8048 19.6995C27.7865 19.7107 27.7662 19.7182 27.745 19.7215C27.7238 19.7248 27.7022 19.7239 27.6814 19.7189L26.697 19.4454C26.6095 19.4235 26.5658 19.336 26.5876 19.2485H26.5877Z"
      fill="#F3F3F3"
    />
    <path
      d="M26.577 19.2485C26.5989 19.161 26.6974 19.0954 26.7849 19.1282L27.7693 19.4015C27.8568 19.4234 27.9115 19.5219 27.8896 19.6094C27.8677 19.6969 27.7693 19.7624 27.6818 19.7297L26.6974 19.4563C26.6099 19.4344 26.5551 19.3469 26.5771 19.2486L26.577 19.2485ZM27.8568 19.6094C27.8787 19.5328 27.8349 19.4453 27.7583 19.4234L26.7739 19.15C26.6974 19.1281 26.6208 19.1719 26.5989 19.2594C26.5771 19.336 26.6208 19.4234 26.6974 19.4453L27.6818 19.7188C27.7583 19.7297 27.8349 19.686 27.8568 19.6094Z"
      fill="white"
    />
    <path
      d="M25.0457 19.7735C25.0784 19.686 25.177 19.6531 25.2534 19.686L27.3644 20.5609C27.441 20.5937 27.4847 20.6922 27.4519 20.7687C27.4191 20.8563 27.3206 20.889 27.2441 20.8563L25.1332 19.9813C25.0457 19.9486 25.0128 19.85 25.0457 19.7736V19.7735Z"
      fill="#F3F3F3"
    />
    <path
      d="M25.0339 19.7626C25.05 19.7205 25.082 19.6866 25.123 19.6681C25.164 19.6496 25.2107 19.6482 25.2527 19.6641L27.3636 20.5391C27.4511 20.572 27.4839 20.6704 27.4511 20.7689C27.4351 20.8109 27.4031 20.8449 27.3621 20.8633C27.321 20.8818 27.2744 20.8832 27.2323 20.8674L25.1214 19.9923C25.0339 19.9485 25.0012 19.8501 25.0339 19.7626ZM27.4292 20.7579C27.462 20.6814 27.4292 20.5939 27.3527 20.561L25.2418 19.686C25.1652 19.6533 25.0887 19.697 25.0558 19.7735C25.0231 19.8501 25.0558 19.9376 25.1324 19.9704L27.2433 20.8454C27.3198 20.8674 27.4073 20.8345 27.4292 20.7579Z"
      fill="white"
    />
    <path
      d="M25.7559 21.25C25.7997 21.1735 25.8871 21.1406 25.9637 21.1735L26.8714 21.6329C26.9479 21.6656 26.9808 21.7641 26.9371 21.8516C26.8933 21.9281 26.8058 21.9611 26.7293 21.9281L25.8216 21.4688C25.745 21.425 25.7121 21.3266 25.7559 21.25Z"
      fill="#F3F3F3"
    />
    <path
      d="M25.7458 21.2391C25.7896 21.1516 25.8881 21.1188 25.9756 21.1625L26.8834 21.6219C26.9709 21.6657 27.0037 21.7642 26.9599 21.8516C26.9162 21.9391 26.8176 21.9719 26.7303 21.9281L25.8225 21.4688C25.7349 21.4361 25.7021 21.3266 25.7458 21.2391ZM26.9272 21.8406C26.9599 21.7641 26.938 21.6766 26.8615 21.6438L25.9537 21.1844C25.888 21.1517 25.8006 21.1844 25.7568 21.25C25.724 21.3266 25.7458 21.4141 25.8224 21.4469L26.7301 21.9063C26.8068 21.9391 26.8943 21.9173 26.9272 21.8406Z"
      fill="white"
    />
    <path
      d="M24.1475 21.4579C24.2023 21.3813 24.2898 21.3594 24.3663 21.4141L26.2695 22.6829C26.346 22.7266 26.357 22.8251 26.3133 22.9016C26.2585 22.9782 26.171 23 26.0945 22.9454L24.1913 21.6766C24.1148 21.6219 24.0929 21.5235 24.1475 21.4579Z"
      fill="#F3F3F3"
    />
    <path
      d="M24.1369 21.4469C24.1917 21.3704 24.3011 21.3485 24.3776 21.3923L26.2808 22.6609C26.3574 22.7156 26.3793 22.814 26.3245 22.9015C26.2698 22.9782 26.1604 23 26.084 22.9563L24.1807 21.6875C24.0932 21.6329 24.0823 21.5235 24.1369 21.4469ZM26.2918 22.8906C26.3354 22.8251 26.3245 22.7265 26.259 22.6828L24.3557 21.4141C24.2901 21.3704 24.2026 21.3923 24.1588 21.4579C24.1151 21.5235 24.1261 21.6219 24.1916 21.6656L26.0948 22.9344C26.1604 22.9782 26.2479 22.9563 26.2918 22.8907V22.8906Z"
      fill="white"
    />
    <path
      d="M24.5526 23.0438C24.6073 22.9781 24.7057 22.9563 24.7713 23.0111L25.5698 23.6344C25.6354 23.6891 25.6463 23.7875 25.5917 23.8532C25.537 23.9187 25.4386 23.9407 25.3729 23.886L24.5744 23.2625C24.5089 23.2078 24.4979 23.1094 24.5526 23.0438Z"
      fill="#F3F3F3"
    />
    <path
      d="M24.542 23.0329C24.5966 22.9563 24.706 22.9454 24.7826 23.0001L25.581 23.6235C25.6575 23.6781 25.6685 23.7875 25.6137 23.8641C25.5591 23.9407 25.4497 23.9516 25.3733 23.8969L24.5747 23.2735C24.4982 23.2188 24.4874 23.1094 24.542 23.0329ZM25.592 23.8532C25.6466 23.7875 25.6356 23.7 25.57 23.6454L24.7716 23.0219C24.706 22.9782 24.6185 22.9892 24.5639 23.0546C24.5091 23.1204 24.5201 23.2079 24.5857 23.2625L25.3841 23.886C25.4497 23.9298 25.5372 23.9187 25.5918 23.8532H25.592Z"
      fill="white"
    />
    <path
      d="M22.9238 22.9344C22.9895 22.8688 23.088 22.8688 23.1426 22.9344L24.7613 24.5421C24.8271 24.6078 24.8161 24.7063 24.7613 24.7609C24.6959 24.8265 24.5973 24.8265 24.5426 24.7609L22.9238 23.1532C22.8692 23.0984 22.8692 22.989 22.9238 22.9344Z"
      fill="#F3F3F3"
    />
    <path
      d="M22.9226 22.9234C22.9547 22.8918 22.998 22.8741 23.043 22.8741C23.088 22.8741 23.1313 22.8918 23.1634 22.9234L24.7821 24.5311C24.8137 24.5632 24.8314 24.6064 24.8314 24.6515C24.8314 24.6965 24.8137 24.7397 24.7821 24.7718C24.75 24.8034 24.7068 24.8211 24.6618 24.8211C24.6168 24.8211 24.5736 24.8034 24.5415 24.7718L22.9226 23.1641C22.8461 23.0984 22.8461 22.9891 22.9226 22.9234ZM24.7602 24.7499C24.815 24.6953 24.815 24.5968 24.7602 24.5422L23.1415 22.9344C23.0868 22.8797 22.9884 22.8797 22.9337 22.9344C22.879 22.989 22.879 23.0875 22.9337 23.1421L24.5525 24.7499C24.6071 24.8156 24.6947 24.8156 24.7602 24.7499Z"
      fill="white"
    />
    <path
      d="M23.0217 24.5641C23.0874 24.5094 23.1858 24.5094 23.2405 24.575L23.9078 25.3406C23.9624 25.4063 23.9515 25.5048 23.8858 25.5594C23.8203 25.6141 23.7217 25.6141 23.6671 25.5485L22.9998 24.7829C22.9452 24.7282 22.9561 24.6298 23.0217 24.5641Z"
      fill="#F3F3F3"
    />
    <path
      d="M23.0118 24.564C23.0883 24.4986 23.1868 24.5094 23.2523 24.575L23.9195 25.3406C23.9851 25.4063 23.9742 25.5156 23.8976 25.5813C23.8211 25.6469 23.7226 25.6359 23.657 25.5703L22.9898 24.8047C22.9351 24.7282 22.9351 24.6188 23.0117 24.564H23.0118ZM23.8868 25.5594C23.9523 25.5047 23.9523 25.4171 23.9086 25.3516L23.2413 24.5859C23.1867 24.5313 23.0992 24.5203 23.0336 24.575C22.968 24.6297 22.968 24.7172 23.0117 24.7827L23.6789 25.5484C23.7336 25.614 23.8212 25.614 23.8867 25.5594H23.8868Z"
      fill="white"
    />
    <path
      d="M21.4475 24.1484C21.524 24.0938 21.6225 24.1157 21.6663 24.1922L22.935 26.0844C22.9788 26.1609 22.9569 26.2593 22.8913 26.3032C22.8147 26.3578 22.7162 26.3359 22.6724 26.2593L21.4037 24.3671C21.3598 24.2907 21.3818 24.1921 21.4474 24.1484H21.4475Z"
      fill="#F3F3F3"
    />
    <path
      d="M21.4477 24.1375C21.5242 24.0829 21.6336 24.1048 21.6883 24.1813L22.957 26.0735C23.0116 26.1499 22.9897 26.2593 22.9023 26.3139C22.8257 26.3687 22.7164 26.3468 22.6617 26.2702L21.3931 24.3781C21.3384 24.2907 21.3603 24.1923 21.4477 24.1376V24.1375ZM22.8914 26.2921C22.957 26.2483 22.9789 26.1608 22.9351 26.0952L21.6665 24.2031C21.6227 24.1376 21.5352 24.1266 21.4588 24.1704C21.3931 24.2141 21.3712 24.3016 21.415 24.3673L22.6836 26.2593C22.7274 26.3139 22.8258 26.3358 22.8913 26.292L22.8914 26.2921Z"
      fill="white"
    />
    <path
      d="M21.2171 25.7671C21.2936 25.7234 21.3921 25.7453 21.4358 25.8219L21.939 26.7078C21.9827 26.7844 21.9498 26.8828 21.8733 26.9265C21.7967 26.9703 21.6983 26.9484 21.6546 26.8719L21.1515 25.9859C21.1187 25.9094 21.1406 25.8109 21.217 25.7672L21.2171 25.7671Z"
      fill="#F3F3F3"
    />
    <path
      d="M21.2164 25.7563C21.3039 25.7125 21.4024 25.7344 21.4461 25.811L21.9492 26.697C21.993 26.7736 21.9711 26.883 21.8837 26.9267C21.7961 26.9705 21.6976 26.9486 21.6539 26.8721L21.1508 25.986C21.107 25.9095 21.1289 25.811 21.2163 25.7563H21.2164ZM21.8726 26.9159C21.9383 26.8721 21.9711 26.7845 21.9274 26.7189L21.4243 25.8329C21.3805 25.7673 21.293 25.7454 21.2274 25.7781C21.1617 25.8219 21.1289 25.9095 21.1726 25.9751L21.6758 26.8611C21.7086 26.9267 21.807 26.9596 21.8726 26.9158V26.9159Z"
      fill="white"
    />
    <path
      d="M19.7625 25.0454C19.85 25.0126 19.9375 25.0454 19.9704 25.1219L20.8563 27.2328C20.8891 27.3094 20.8454 27.4078 20.7688 27.4405C20.6923 27.4734 20.5938 27.4405 20.5611 27.364L19.6751 25.2532C19.6423 25.1765 19.686 25.0782 19.7625 25.0454Z"
      fill="#F3F3F3"
    />
    <path
      d="M19.7616 25.0344C19.8491 25.0017 19.9475 25.0344 19.9913 25.1219L20.8772 27.2329C20.8931 27.2749 20.8916 27.3216 20.8732 27.3626C20.8547 27.4035 20.8208 27.4356 20.7788 27.4516C20.6913 27.4844 20.5929 27.4516 20.5492 27.3641L19.6633 25.2532C19.6304 25.1766 19.6742 25.0782 19.7616 25.0344ZM20.7569 27.4298C20.8334 27.3969 20.8663 27.3094 20.8334 27.2438L19.9475 25.1329C19.9148 25.0563 19.8273 25.0235 19.7507 25.0563C19.6742 25.0891 19.6412 25.1766 19.6742 25.2423L20.56 27.3532C20.6038 27.4298 20.6804 27.4625 20.7569 27.4298Z"
      fill="white"
    />
    <path
      d="M19.2264 26.5984C19.314 26.5657 19.4014 26.6094 19.4342 26.6969L19.7515 27.6594C19.7842 27.736 19.7296 27.8344 19.6531 27.8563C19.5656 27.8891 19.4781 27.8454 19.4453 27.7579L19.1281 26.7954C19.0953 26.7188 19.139 26.6313 19.2265 26.5984H19.2264Z"
      fill="#F3F3F3"
    />
    <path
      d="M19.2166 26.5876C19.2586 26.5718 19.3053 26.5732 19.3463 26.5917C19.3873 26.6101 19.4193 26.6441 19.4353 26.6861L19.7526 27.6485C19.7853 27.736 19.7307 27.8345 19.6431 27.8673C19.601 27.8832 19.5544 27.8817 19.5134 27.8633C19.4724 27.8448 19.4404 27.8109 19.4243 27.7689L19.1071 26.8064C19.0852 26.7189 19.1291 26.6205 19.2166 26.5876ZM19.6432 27.8453C19.7197 27.8235 19.7634 27.736 19.7416 27.6595L19.4243 26.697C19.4025 26.6205 19.315 26.5876 19.2385 26.6095C19.1618 26.6314 19.1181 26.7189 19.14 26.7955L19.4572 27.7579C19.4628 27.7765 19.4722 27.7937 19.4849 27.8084C19.4975 27.8231 19.5131 27.8349 19.5307 27.8432C19.5482 27.8514 19.5673 27.8559 19.5867 27.8563C19.6061 27.8567 19.6253 27.853 19.6432 27.8454V27.8453Z"
      fill="white"
    />
    <path
      d="M17.9355 25.6032C18.0229 25.5813 18.1106 25.636 18.1214 25.7235L18.5809 27.9658C18.6028 28.0532 18.5371 28.1299 18.4495 28.1516C18.3621 28.1735 18.2745 28.1189 18.2637 28.0314L17.8041 25.7891C17.7933 25.7125 17.8479 25.625 17.9354 25.6031L17.9355 25.6032Z"
      fill="#F3F3F3"
    />
    <path
      d="M17.9369 25.592C18.0353 25.5702 18.1227 25.6359 18.1446 25.7234L18.604 27.9658C18.6259 28.0532 18.5602 28.1517 18.4727 28.1626C18.3743 28.1845 18.2868 28.1189 18.265 28.0314L17.8055 25.789C17.7837 25.7015 17.8385 25.6139 17.9368 25.5922L17.9369 25.592ZM18.4508 28.1407C18.5274 28.1299 18.5821 28.0532 18.5711 27.9657L18.1118 25.7234C18.101 25.6468 18.0135 25.5922 17.9368 25.6139C17.8603 25.6249 17.8056 25.7015 17.8166 25.789L18.2759 28.0314C18.2977 28.108 18.3743 28.1517 18.4509 28.1408L18.4508 28.1407Z"
      fill="white"
    />
    <path
      d="M17.1043 27.025C17.1918 27.0141 17.2683 27.0688 17.2902 27.1563L17.4214 28.1625C17.4324 28.25 17.3668 28.3265 17.2791 28.3375C17.1918 28.3484 17.1152 28.2938 17.0933 28.2063L16.9621 27.2C16.9512 27.1125 17.0168 27.036 17.1043 27.025Z"
      fill="#F3F3F3"
    />
    <path
      d="M17.1055 27.0141C17.204 27.0032 17.2916 27.0688 17.3025 27.1563L17.4338 28.1626C17.4447 28.2501 17.3791 28.3376 17.2806 28.3485C17.1821 28.3595 17.0946 28.2939 17.0836 28.2063L16.9524 27.2C16.9414 27.1125 17.007 27.025 17.1055 27.0141ZM17.2697 28.3266C17.3462 28.3158 17.412 28.2391 17.401 28.1626L17.2697 27.1563C17.2587 27.0798 17.1821 27.025 17.1055 27.036C17.0289 27.0469 16.9632 27.1235 16.9742 27.2L17.1055 28.2064C17.1165 28.2829 17.1931 28.3376 17.2697 28.3266Z"
      fill="white"
    />
    <path
      d="M15.9785 25.7999C16.066 25.7999 16.1425 25.8656 16.1425 25.9531V28.239C16.1425 28.3265 16.066 28.3922 15.9785 28.3922C15.891 28.3922 15.8145 28.3265 15.8145 28.239V25.9531C15.8254 25.8656 15.891 25.7999 15.9785 25.7999Z"
      fill="#F3F3F3"
    />
    <path
      d="M15.9777 25.7889C16.0762 25.7889 16.1527 25.8655 16.1527 25.953V28.2389C16.1527 28.3264 16.0762 28.403 15.9777 28.403C15.8793 28.403 15.8027 28.3264 15.8027 28.2389V25.9531C15.8027 25.8656 15.8902 25.7889 15.9777 25.7889ZM15.9777 28.3812C16.0543 28.3812 16.1308 28.3156 16.1308 28.2389V25.9531C16.1308 25.8764 16.0652 25.8108 15.9777 25.8108C15.9012 25.8108 15.8246 25.8764 15.8246 25.9531V28.2389C15.8355 28.3156 15.9012 28.3812 15.9777 28.3812Z"
      fill="white"
    />
    <path
      d="M14.8844 27.025C14.9719 27.0359 15.0375 27.1015 15.0375 27.189L14.9609 28.2063C14.9501 28.2938 14.8734 28.3594 14.7859 28.3485C14.6984 28.3375 14.6328 28.2719 14.6328 28.1844L14.7094 27.1671C14.7203 27.0798 14.7969 27.014 14.8844 27.025Z"
      fill="#F3F3F3"
    />
    <path
      d="M14.8836 27.014C14.982 27.025 15.0476 27.1016 15.0476 27.1891L14.9711 28.2064C14.9601 28.2939 14.8836 28.3704 14.7851 28.3595C14.6868 28.3485 14.6211 28.272 14.6211 28.1845L14.6976 27.1672C14.7086 27.0688 14.7851 27.0032 14.8836 27.014ZM14.7851 28.3376C14.8617 28.3376 14.9384 28.2829 14.9384 28.2064L15.0149 27.1891C15.0149 27.1125 14.9601 27.0469 14.8726 27.036C14.7961 27.036 14.7195 27.0906 14.7195 27.1673L14.643 28.1845C14.643 28.261 14.7086 28.3267 14.7853 28.3376H14.7851Z"
      fill="white"
    />
    <path
      d="M14.0635 25.6031C14.151 25.6249 14.2056 25.7014 14.1947 25.7889L13.7463 28.0314C13.7244 28.1189 13.6478 28.1737 13.5603 28.1516C13.4728 28.1298 13.4182 28.0533 13.4291 27.9657L13.8776 25.7233C13.8885 25.6468 13.976 25.5921 14.0635 25.6031Z"
      fill="#F3F3F3"
    />
    <path
      d="M14.0639 25.592C14.1623 25.6139 14.217 25.7015 14.2061 25.7889L13.7576 28.0313C13.7357 28.1188 13.6482 28.1844 13.5499 28.1624C13.4514 28.1405 13.3967 28.053 13.4076 27.9655L13.8561 25.7233C13.878 25.6358 13.9655 25.581 14.0638 25.5921L14.0639 25.592ZM13.5499 28.1405C13.6264 28.1515 13.703 28.1078 13.7249 28.0313L14.1732 25.7889C14.1842 25.7124 14.1405 25.6358 14.053 25.6139C13.9764 25.6031 13.8999 25.6468 13.878 25.7234L13.4295 27.9655C13.4186 28.053 13.4732 28.1188 13.5499 28.1405Z"
      fill="white"
    />
    <path
      d="M12.7522 26.5876C12.8398 26.6095 12.8944 26.697 12.8725 26.7845L12.5991 27.7688C12.5773 27.8563 12.4898 27.9 12.4023 27.8782C12.3814 27.8732 12.3618 27.8641 12.3445 27.8515C12.3271 27.8389 12.3125 27.823 12.3013 27.8047C12.2901 27.7864 12.2826 27.7661 12.2793 27.7449C12.276 27.7238 12.2769 27.7022 12.2819 27.6813L12.5554 26.697C12.5773 26.6095 12.6648 26.5658 12.7523 26.5876H12.7522Z"
      fill="#F3F3F3"
    />
    <path
      d="M12.7515 26.5768C12.8391 26.5986 12.9047 26.697 12.8718 26.7845L12.5984 27.7689C12.5766 27.8564 12.4781 27.9112 12.3906 27.8891C12.3031 27.8674 12.2375 27.7689 12.2703 27.6814L12.5437 26.697C12.5656 26.6095 12.6531 26.5548 12.7515 26.5767V26.5768ZM12.3906 27.8564C12.4672 27.8783 12.5547 27.8345 12.5766 27.758L12.8499 26.7736C12.8718 26.697 12.8281 26.6205 12.7406 26.5986C12.6641 26.5767 12.5766 26.6205 12.5547 26.697L12.2812 27.6814C12.2703 27.758 12.314 27.8345 12.3906 27.8564Z"
      fill="white"
    />
    <path
      d="M12.215 25.0454C12.3025 25.0781 12.3352 25.1766 12.3025 25.2531L11.4275 27.3641C11.3947 27.4407 11.2962 27.4844 11.2197 27.4407C11.1431 27.3969 11.0993 27.3094 11.1321 27.2329L12.0071 25.1219C12.0398 25.0454 12.1384 25.0016 12.2148 25.0454H12.215Z"
      fill="#F3F3F3"
    />
    <path
      d="M12.2153 25.0344C12.2573 25.0504 12.2913 25.0825 12.3098 25.1235C12.3282 25.1645 12.3296 25.2111 12.3138 25.2532L11.4387 27.364C11.4059 27.4515 11.3074 27.4844 11.209 27.4515C11.167 27.4355 11.133 27.4035 11.1146 27.3625C11.0961 27.3215 11.0947 27.2749 11.1105 27.2328L11.9855 25.1219C12.0294 25.0344 12.1278 24.9907 12.2153 25.0344ZM11.2199 27.4297C11.2965 27.4625 11.384 27.4297 11.4168 27.3532L12.2919 25.2423C12.3246 25.1657 12.2809 25.0891 12.2044 25.0563C12.1278 25.0236 12.0403 25.0563 12.0074 25.1329L11.1324 27.2438C11.1105 27.3094 11.1434 27.3969 11.2199 27.4297Z"
      fill="white"
    />
    <path
      d="M10.7397 25.7455C10.8162 25.7892 10.849 25.8767 10.8162 25.9532L10.3568 26.8609C10.313 26.9374 10.2255 26.9703 10.138 26.9265C10.0615 26.8828 10.0287 26.7953 10.0615 26.7188L10.521 25.8112C10.5647 25.7346 10.6522 25.7017 10.7397 25.7455Z"
      fill="#F3F3F3"
    />
    <path
      d="M10.7396 25.7344C10.8271 25.7781 10.8599 25.8767 10.8162 25.964L10.3568 26.8718C10.313 26.9593 10.2145 26.9922 10.1271 26.9484C10.0396 26.9047 10.0068 26.8061 10.0506 26.7187L10.5099 25.8111C10.5537 25.7235 10.6521 25.6907 10.7396 25.7344ZM10.1381 26.9266C10.2146 26.9593 10.3021 26.9374 10.3349 26.8609L10.7943 25.9532C10.827 25.8875 10.7943 25.8 10.7287 25.7563C10.6521 25.7236 10.5646 25.7454 10.5318 25.8219L10.0724 26.7296C10.0396 26.7953 10.0724 26.8828 10.1381 26.9265V26.9266Z"
      fill="white"
    />
    <path
      d="M10.5198 24.1267C10.5963 24.1813 10.6182 24.2798 10.5636 24.3454L9.29479 26.2378C9.2511 26.3142 9.15253 26.3252 9.0761 26.2815C8.99955 26.2267 8.97765 26.1284 9.03229 26.0626L10.3011 24.1702C10.3448 24.0938 10.4432 24.0719 10.5198 24.1265V24.1267Z"
      fill="#F3F3F3"
    />
    <path
      d="M10.52 24.1156C10.5965 24.1704 10.6184 24.2798 10.5746 24.3563L9.3059 26.2486C9.25125 26.325 9.15279 26.3469 9.06529 26.2923C8.98874 26.2375 8.96684 26.1282 9.01053 26.0516L10.2794 24.1594C10.334 24.0829 10.4434 24.061 10.52 24.1156ZM9.07624 26.2704C9.14173 26.3141 9.2403 26.3031 9.28399 26.2377L10.5528 24.3454C10.5965 24.2798 10.5747 24.1923 10.509 24.1485C10.4434 24.1048 10.345 24.1157 10.3013 24.1812L9.03244 26.0625C8.98874 26.1282 9.01053 26.2266 9.07624 26.2703V26.2704Z"
      fill="white"
    />
    <path
      d="M8.92307 24.5313C8.98878 24.586 9.01057 24.6844 8.95593 24.75L8.32154 25.5484C8.26689 25.614 8.16844 25.625 8.10285 25.5703C8.03725 25.5157 8.01535 25.4172 8.0701 25.3515L8.70438 24.5531C8.75903 24.4876 8.85748 24.4766 8.92307 24.5313Z"
      fill="#F3F3F3"
    />
    <path
      d="M8.93452 24.5203C9.01107 24.575 9.02203 24.6844 8.96727 24.7609L8.33297 25.5594C8.27832 25.636 8.16892 25.6469 8.09237 25.5813C8.01582 25.5266 8.00486 25.4173 8.05962 25.3406L8.69392 24.5422C8.74857 24.4656 8.85797 24.4547 8.93452 24.5203ZM8.11427 25.5594C8.17975 25.6141 8.26737 25.6032 8.32201 25.5375L8.95643 24.739C9.00023 24.6736 8.98917 24.5859 8.92368 24.5313C8.85797 24.4765 8.77047 24.4875 8.71583 24.5532L8.08141 25.3516C8.03772 25.4173 8.04867 25.5156 8.11416 25.5594H8.11427Z"
      fill="white"
    />
    <path
      d="M9.03301 22.8907C9.09861 22.9562 9.09861 23.0548 9.03301 23.1094L7.41437 24.7171C7.34866 24.7829 7.25033 24.7719 7.19557 24.7171C7.14093 24.6625 7.12998 24.5531 7.19557 24.4985L8.80326 22.8907C8.86897 22.825 8.96742 22.825 9.03301 22.8907Z"
      fill="#F3F3F3"
    />
    <path
      d="M9.04327 22.8798C9.07478 22.9119 9.09244 22.9551 9.09244 23C9.09244 23.045 9.07478 23.0882 9.04327 23.1203L7.42452 24.7283C7.39243 24.7598 7.34925 24.7775 7.30428 24.7775C7.25931 24.7775 7.21613 24.7598 7.18404 24.7283C7.15246 24.6962 7.13477 24.653 7.13477 24.6079C7.13477 24.5629 7.15246 24.5197 7.18404 24.4876L8.80268 22.8798C8.83478 22.8482 8.87798 22.8306 8.92298 22.8306C8.96798 22.8306 9.01118 22.8482 9.04327 22.8798ZM7.19488 24.7172C7.24952 24.7719 7.34797 24.7719 7.40262 24.7172L9.02137 23.1093C9.07613 23.0547 9.07613 22.9562 9.02137 22.9016C8.96673 22.8468 8.86828 22.8468 8.81364 22.9016L7.19488 24.5094C7.14024 24.564 7.14024 24.6516 7.19488 24.7171V24.7172Z"
      fill="white"
    />
    <path
      d="M7.39172 22.9782C7.44636 23.0437 7.44636 23.1423 7.38077 23.1969L6.60422 23.8533C6.53862 23.908 6.44017 23.897 6.38541 23.8314C6.33077 23.7657 6.33077 23.6673 6.39636 23.6126L7.17291 22.9563C7.23862 22.9016 7.33696 22.9125 7.39172 22.9782Z"
      fill="#F3F3F3"
    />
    <path
      d="M7.40234 22.9671C7.46794 23.0438 7.45699 23.1531 7.39139 23.2078L6.61483 23.864C6.54924 23.9297 6.43983 23.9187 6.37424 23.8422C6.30853 23.7655 6.31948 23.6562 6.38508 23.6015L7.16174 22.9453C7.22723 22.8798 7.33674 22.8907 7.40223 22.9672L7.40234 22.9671ZM6.39603 23.8312C6.45079 23.8968 6.53829 23.8968 6.60377 23.8531L7.38044 23.1969C7.43508 23.1422 7.44603 23.0546 7.39139 22.9891C7.33663 22.9234 7.24913 22.9234 7.18364 22.9672L6.40698 23.6234C6.34138 23.678 6.34138 23.7656 6.39603 23.8311V23.8312Z"
      fill="white"
    />
    <path
      d="M7.83077 21.4031C7.88542 21.4798 7.86351 21.5781 7.78696 21.6219L5.88388 22.8906C5.80733 22.9343 5.70888 22.9124 5.66519 22.8468C5.61043 22.7703 5.63233 22.6719 5.70888 22.628L7.61208 21.3594C7.67779 21.3157 7.77613 21.3375 7.83088 21.4031H7.83077Z"
      fill="#F3F3F3"
    />
    <path
      d="M7.84089 21.4031C7.89554 21.4797 7.87364 21.589 7.79709 21.6438L5.89403 22.9125C5.81748 22.9672 5.70808 22.9452 5.65344 22.8578C5.59868 22.7813 5.6207 22.6719 5.69713 22.6172L7.6003 21.3484C7.67685 21.2938 7.78614 21.3157 7.84078 21.4031H7.84089ZM5.67534 22.8359C5.71904 22.9015 5.80653 22.9234 5.87213 22.8797L7.77519 21.6109C7.84089 21.5672 7.86268 21.4797 7.80804 21.4032C7.76435 21.3375 7.67685 21.3157 7.61114 21.3594L5.70808 22.6282C5.64249 22.6828 5.63154 22.7703 5.67534 22.8359Z"
      fill="white"
    />
    <path
      d="M6.19922 21.1735C6.24302 21.25 6.22112 21.3485 6.14457 21.3923L5.25861 21.8953C5.18206 21.939 5.0836 21.9063 5.03991 21.8297C4.9961 21.7532 5.01801 21.6547 5.09456 21.6109L5.98052 21.1079C6.05707 21.0641 6.15552 21.0969 6.19933 21.1735H6.19922Z"
      fill="#F3F3F3"
    />
    <path
      d="M6.21025 21.1625C6.25394 21.25 6.23204 21.3484 6.15549 21.3922L5.26955 21.8953C5.193 21.939 5.0836 21.9062 5.03991 21.8297C4.9961 21.7422 5.01801 21.6437 5.09455 21.5999L5.98049 21.0969C6.05704 21.0531 6.16644 21.0859 6.21014 21.1625H6.21025ZM5.05075 21.8187C5.09455 21.8953 5.18205 21.9172 5.24765 21.8734L6.13359 21.3703C6.19918 21.3265 6.22109 21.239 6.18835 21.1734C6.14454 21.0969 6.05704 21.075 5.99145 21.1188L5.10551 21.6218C5.03991 21.6546 5.01801 21.7422 5.05086 21.8187H5.05075Z"
      fill="white"
    />
    <path
      d="M6.93236 19.7188C6.96511 19.8063 6.93236 19.8939 6.84486 19.9266L4.73391 20.8016C4.65736 20.8345 4.55891 20.7908 4.52617 20.7141C4.49332 20.6266 4.52617 20.5391 4.61367 20.5064L6.72462 19.6313C6.80117 19.5986 6.89962 19.6313 6.93236 19.7188Z"
      fill="#F3F3F3"
    />
    <path
      d="M6.94332 19.7079C6.97606 19.7954 6.94332 19.8938 6.85582 19.9375L4.74486 20.8125C4.7028 20.8284 4.65617 20.8269 4.61518 20.8085C4.5742 20.79 4.54219 20.7561 4.52617 20.7141C4.49332 20.6266 4.52617 20.5281 4.61367 20.4844L6.72462 19.6094C6.76668 19.5936 6.81331 19.595 6.8543 19.6135C6.89529 19.6319 6.9273 19.6659 6.94332 19.7079ZM4.54796 20.7031C4.58082 20.7798 4.66832 20.8126 4.73391 20.7906L6.84486 19.9156C6.92141 19.8829 6.95427 19.7954 6.92141 19.7188C6.88867 19.6423 6.80117 19.6094 6.73546 19.6313L4.62451 20.5063C4.54796 20.5391 4.51522 20.6266 4.54796 20.7032V20.7031Z"
      fill="white"
    />
    <path
      d="M5.38982 19.1719C5.42256 19.2594 5.37887 19.3469 5.29137 19.3797L4.32886 19.6969C4.25231 19.7297 4.15386 19.675 4.13196 19.5985C4.09922 19.511 4.14291 19.4235 4.23041 19.3907L5.19292 19.0735C5.26946 19.0407 5.35697 19.0844 5.38982 19.1719Z"
      fill="#F3F3F3"
    />
    <path
      d="M5.40093 19.161C5.43379 19.2485 5.39009 19.347 5.29164 19.3798L4.32915 19.697C4.24166 19.7297 4.1432 19.6751 4.11035 19.5876C4.07761 19.5001 4.1213 19.4016 4.21987 19.3689L5.18224 19.0516C5.26974 19.0298 5.36819 19.0735 5.40104 19.1611L5.40093 19.161ZM4.13237 19.5767C4.15427 19.6533 4.24177 19.6971 4.31832 19.6752L5.2808 19.3579C5.35735 19.3361 5.39021 19.2486 5.3683 19.172C5.3464 19.0955 5.2589 19.0517 5.18235 19.0736L4.21987 19.3909C4.14332 19.4236 4.11046 19.5002 4.13237 19.5767Z"
      fill="white"
    />
    <path
      d="M6.37554 17.8703C6.39733 17.9578 6.34268 18.0453 6.25518 18.0563L4.01294 18.4938C3.92544 18.5157 3.84889 18.45 3.8271 18.3625C3.8052 18.275 3.85984 18.1875 3.94734 18.1765L6.18959 17.739C6.27709 17.7282 6.36459 17.7828 6.37543 17.8703H6.37554Z"
      fill="#F3F3F3"
    />
    <path
      d="M6.39676 17.8704C6.41866 17.9688 6.35307 18.0563 6.26557 18.0781L4.02331 18.5156C3.93581 18.5375 3.84831 18.4719 3.82652 18.3735C3.80461 18.275 3.87021 18.1875 3.95771 18.1657L6.19997 17.7282C6.28736 17.7063 6.37497 17.7719 6.39676 17.8704ZM3.84831 18.3626C3.85926 18.4391 3.93581 18.4938 4.01236 18.4829L6.25461 18.0454C6.33116 18.0344 6.38581 17.9469 6.36402 17.8704C6.35307 17.7938 6.27652 17.7391 6.19986 17.75L3.95771 18.1875C3.88116 18.2094 3.82652 18.286 3.84831 18.3625V18.3626Z"
      fill="white"
    />
    <path
      d="M4.976 17.0283C4.98684 17.1157 4.93219 17.1923 4.84469 17.2033L3.8385 17.3236C3.75089 17.3345 3.67434 17.2689 3.6635 17.1813C3.65255 17.0939 3.70719 17.0173 3.79469 17.0062L4.801 16.886C4.87755 16.875 4.96505 16.9406 4.976 17.0283Z"
      fill="#F3F3F3"
    />
    <path
      d="M4.98599 17.0282C4.99683 17.1265 4.93123 17.214 4.84373 17.225L3.83754 17.3453C3.74993 17.3563 3.66254 17.2907 3.65159 17.1922C3.64063 17.0938 3.70623 17.0063 3.79373 16.9954L4.80004 16.875C4.88743 16.8641 4.97504 16.9298 4.98588 17.0281L4.98599 17.0282ZM3.66243 17.1813C3.67338 17.2578 3.75004 17.3235 3.82659 17.3125L4.83278 17.1922C4.90933 17.1813 4.96409 17.1048 4.95314 17.0282C4.94218 16.9516 4.86564 16.886 4.78909 16.8969L3.78278 17.0173C3.70623 17.0282 3.65159 17.1048 3.66243 17.1813Z"
      fill="white"
    />
    <path
      d="M6.19944 15.9671C6.19944 16.0547 6.13396 16.1313 6.04634 16.1313H3.76052C3.67302 16.1313 3.60742 16.0547 3.60742 15.9672C3.60742 15.8798 3.67302 15.8031 3.76052 15.8031H6.04646C6.13396 15.8031 6.19966 15.8798 6.19966 15.9672L6.19944 15.9671Z"
      fill="#F3F3F3"
    />
    <path
      d="M6.2117 15.9671C6.2117 16.0657 6.13504 16.1421 6.04754 16.1421H3.7617C3.6742 16.1421 3.59766 16.0656 3.59766 15.9671C3.59766 15.8688 3.6742 15.7921 3.7617 15.7921H6.04766C6.13516 15.7921 6.2117 15.8688 6.2117 15.9671ZM3.61945 15.9563C3.61945 16.0328 3.68504 16.1094 3.7617 16.1094H6.04754C6.12409 16.1094 6.1898 16.0438 6.1898 15.9563C6.1898 15.8797 6.12409 15.8031 6.04754 15.8031H3.7617C3.68516 15.814 3.61956 15.8797 3.61956 15.9563H3.61945Z"
      fill="white"
    />
    <path
      d="M4.98686 14.8625C4.9758 14.95 4.9102 15.0156 4.8227 15.0156L3.80543 14.9391C3.71804 14.9281 3.65244 14.8516 3.66328 14.7641C3.67423 14.6766 3.73994 14.611 3.82745 14.611L4.8446 14.6875C4.92115 14.6985 4.98686 14.775 4.98686 14.8625Z"
      fill="#E2E2E2"
    />
    <path
      d="M4.99667 14.8625C4.98572 14.961 4.90917 15.0266 4.82167 15.0266L3.80442 14.95C3.71703 14.9391 3.64049 14.8625 3.65144 14.7641C3.66228 14.6657 3.73894 14.6 3.82644 14.6L4.84358 14.6766C4.93108 14.6875 4.99667 14.775 4.99667 14.8625ZM3.66228 14.7641C3.65144 14.8406 3.71703 14.9173 3.79358 14.9173L4.81083 14.9938C4.88727 14.9938 4.95298 14.9391 4.96393 14.8516C4.97488 14.775 4.90917 14.6985 4.83263 14.6985L3.81537 14.6219C3.73894 14.6219 3.67323 14.6875 3.66228 14.7642V14.7641Z"
      fill="white"
    />
    <path
      d="M6.39703 14.064C6.37513 14.1515 6.29858 14.2063 6.21108 14.1953L3.96893 13.736C3.88144 13.7141 3.82668 13.6375 3.84858 13.55C3.87048 13.4625 3.94703 13.4078 4.03453 13.4188L6.27679 13.8673C6.35334 13.889 6.40798 13.9765 6.39703 14.064Z"
      fill="#F3F3F3"
    />
    <path
      d="M6.40846 14.064C6.38656 14.1625 6.29906 14.2172 6.21156 14.2063L3.96943 13.7578C3.88193 13.7359 3.81633 13.6484 3.83824 13.5501C3.86003 13.4515 3.94752 13.3969 4.03502 13.4078L6.27727 13.8563C6.36477 13.8782 6.41941 13.9657 6.40846 14.064ZM3.86003 13.55C3.84907 13.6265 3.89277 13.7031 3.96943 13.725L6.21167 14.1734C6.28822 14.1844 6.36477 14.1407 6.38667 14.0531C6.39751 13.9765 6.35382 13.9 6.27716 13.8781L4.03514 13.4297C3.94764 13.4188 3.88204 13.4734 3.86014 13.55H3.86003Z"
      fill="white"
    />
    <path
      d="M5.41346 12.7516C5.40843 12.7725 5.39936 12.7921 5.38674 12.8094C5.37412 12.8268 5.35822 12.8414 5.33993 12.8526C5.32164 12.8637 5.30133 12.8712 5.28016 12.8745C5.25899 12.8778 5.23737 12.8769 5.21655 12.8719L4.23213 12.5984C4.14463 12.5765 4.10094 12.4891 4.12273 12.4016C4.12775 12.3807 4.13683 12.3611 4.14945 12.3438C4.16206 12.3265 4.17797 12.3118 4.19626 12.3006C4.21454 12.2895 4.23485 12.282 4.25602 12.2787C4.27719 12.2754 4.29881 12.2763 4.31964 12.2813L5.30405 12.5548C5.39155 12.5765 5.43525 12.664 5.41346 12.7515V12.7516Z"
      fill="#F3F3F3"
    />
    <path
      d="M5.42282 12.7517C5.40103 12.8391 5.30258 12.9048 5.21508 12.8719L4.23066 12.5985C4.14315 12.5766 4.0884 12.4781 4.1103 12.3907C4.1322 12.3031 4.23066 12.2375 4.31816 12.2704L5.30258 12.5438C5.39008 12.5657 5.44484 12.6532 5.42282 12.7516V12.7517ZM4.14315 12.3905C4.12125 12.4672 4.16506 12.5547 4.24161 12.5765L5.22603 12.8499C5.30258 12.8718 5.37913 12.828 5.40103 12.7405C5.42282 12.664 5.37913 12.5765 5.30258 12.5547L4.31816 12.2812C4.24161 12.2703 4.16506 12.314 4.14315 12.3906V12.3905Z"
      fill="white"
    />
    <path
      d="M6.95441 12.2267C6.92167 12.3141 6.8231 12.3469 6.74667 12.3141L4.6356 11.4391C4.55905 11.4063 4.51536 11.3079 4.5481 11.2313C4.58096 11.1438 4.67941 11.111 4.75596 11.1438L6.86691 12.0188C6.95441 12.0516 6.98726 12.15 6.95441 12.2266V12.2267Z"
      fill="#F3F3F3"
    />
    <path
      d="M6.96488 12.2375C6.94886 12.2796 6.91684 12.3135 6.87582 12.332C6.83481 12.3504 6.78815 12.3519 6.74607 12.336L4.63511 11.461C4.54761 11.4283 4.51487 11.3298 4.54761 11.2314C4.56362 11.1893 4.59565 11.1554 4.63667 11.1369C4.67768 11.1185 4.72434 11.117 4.76642 11.1329L6.87738 12.0079C6.96488 12.0516 6.99762 12.1501 6.96488 12.2375ZM4.56951 11.2423C4.53677 11.3189 4.56951 11.4064 4.64606 11.4391L6.75703 12.3141C6.83357 12.3469 6.91012 12.3031 6.94298 12.2266C6.97572 12.15 6.94298 12.0625 6.86643 12.0298L4.75546 11.1548C4.67891 11.1329 4.59141 11.1658 4.56951 11.2423Z"
      fill="white"
    />
    <path
      d="M6.24379 10.75C6.20009 10.8266 6.11259 10.8594 6.03604 10.8266L5.12819 10.3673C5.05164 10.3344 5.01879 10.236 5.06259 10.1484C5.10629 10.0719 5.19379 10.039 5.27033 10.0719L6.17808 10.5313C6.25474 10.575 6.28759 10.6736 6.24379 10.75Z"
      fill="#F3F3F3"
    />
    <path
      d="M6.25485 10.761C6.21104 10.8485 6.11259 10.8814 6.02509 10.8375L5.11735 10.3781C5.02985 10.3344 4.997 10.236 5.04081 10.1485C5.0845 10.061 5.18306 10.0281 5.27045 10.0719L6.17819 10.5313C6.2658 10.5641 6.29854 10.6736 6.25485 10.761ZM5.07355 10.1594C5.04081 10.236 5.06271 10.3235 5.13926 10.3563L6.04699 10.8158C6.1127 10.8485 6.2002 10.8158 6.2439 10.75C6.27664 10.6735 6.25485 10.586 6.1783 10.5531L5.25939 10.094C5.19379 10.0612 5.10629 10.083 5.07344 10.1595L5.07355 10.1594Z"
      fill="white"
    />
    <path
      d="M7.85211 10.5422C7.79747 10.6188 7.70997 10.6407 7.63342 10.586L5.74112 9.32823C5.66468 9.28442 5.65373 9.18597 5.69742 9.10942C5.74123 9.03287 5.83968 9.01097 5.91623 9.06562L7.81937 10.3344C7.88497 10.3782 7.90687 10.4767 7.85211 10.5422Z"
      fill="#F3F3F3"
    />
    <path
      d="M7.86312 10.5531C7.80848 10.6298 7.69907 10.6516 7.62264 10.6079L5.73039 9.33905C5.65385 9.28441 5.63194 9.18596 5.68659 9.09857C5.74135 9.02191 5.85075 9 5.9273 9.04381L7.81943 10.3125C7.90693 10.3673 7.91788 10.4766 7.86312 10.5531ZM5.70849 9.10941C5.6648 9.175 5.67575 9.27346 5.74123 9.31715L7.64443 10.5861C7.71003 10.6299 7.79752 10.608 7.84133 10.5424C7.88502 10.4767 7.87407 10.3782 7.80859 10.3345L5.90528 9.06571C5.83968 9.02191 5.75218 9.04381 5.70838 9.10941H5.70849Z"
      fill="white"
    />
    <path
      d="M7.44709 8.95623C7.39244 9.02183 7.29399 9.04373 7.22839 8.98897L6.42993 8.36565C6.36433 8.3109 6.35338 8.21245 6.40802 8.14685C6.46267 8.08126 6.56112 8.05935 6.62683 8.11411L7.42518 8.73743C7.49089 8.79219 7.50185 8.89053 7.44709 8.95623Z"
      fill="#F3F3F3"
    />
    <path
      d="M7.45756 8.96712C7.40292 9.04378 7.29352 9.05473 7.21697 8.99998L6.41854 8.37665C6.34199 8.322 6.33104 8.2126 6.38579 8.13617C6.44044 8.05951 6.54984 8.04856 6.62627 8.10332L7.42471 8.72664C7.50137 8.7814 7.51232 8.89069 7.45756 8.96735V8.96712ZM6.40758 8.14689C6.35294 8.21249 6.36389 8.29999 6.42949 8.35463L7.22792 8.97819C7.29352 9.02188 7.38101 9.01093 7.43566 8.94533C7.49042 8.87974 7.47946 8.79224 7.41387 8.73759L6.61543 8.11404C6.54984 8.07035 6.46223 8.0813 6.4077 8.14689H6.40758Z"
      fill="white"
    />
    <path
      d="M9.07709 9.06562C9.0116 9.13133 8.91304 9.13133 8.85839 9.06562L7.22882 7.45786C7.16322 7.39227 7.17406 7.29381 7.22882 7.23905C7.29442 7.17357 7.39287 7.17357 7.44752 7.23905L9.06636 8.84692C9.13196 8.90157 9.13196 9.01097 9.07732 9.06573L9.07709 9.06562Z"
      fill="#F3F3F3"
    />
    <path
      d="M9.07664 9.07666C9.04455 9.10817 9.00137 9.12582 8.95639 9.12582C8.91142 9.12582 8.86824 9.10817 8.83615 9.07666L7.21725 7.4687C7.18567 7.4366 7.16797 7.39338 7.16797 7.34835C7.16797 7.30332 7.18567 7.26009 7.21725 7.22799C7.24934 7.19645 7.29254 7.17877 7.33754 7.17877C7.38255 7.17877 7.42575 7.19645 7.45784 7.22799L9.07664 8.83584C9.15318 8.90143 9.15318 9.01083 9.07664 9.07643V9.07666ZM7.23938 7.2499C7.18462 7.30466 7.18462 7.40311 7.23938 7.45764L8.85806 9.06548C8.91281 9.12024 9.01115 9.12024 9.0658 9.06548C9.12055 9.01083 9.12055 8.91238 9.0658 8.85774L7.44712 7.23906C7.39247 7.18441 7.30486 7.18441 7.23938 7.25001V7.2499Z"
      fill="white"
    />
    <path
      d="M8.97761 7.43599C8.91202 7.49075 8.81357 7.49075 8.75881 7.42504L8.09156 6.65944C8.03703 6.59384 8.04787 6.49539 8.11358 6.44075C8.17906 6.38599 8.27762 6.38599 8.33227 6.45158L8.99951 7.21729C9.05416 7.27194 9.04321 7.37039 8.97761 7.43599Z"
      fill="#F3F3F3"
    />
    <path
      d="M8.98998 7.43599C8.91343 7.50159 8.81498 7.49075 8.74927 7.42504L8.08213 6.65944C8.01653 6.59384 8.02748 6.48444 8.10403 6.41884C8.18058 6.35313 8.27903 6.36408 8.34463 6.42979L9.01188 7.19528C9.06653 7.27195 9.06653 7.38135 8.98998 7.43599ZM8.11498 6.44075C8.04927 6.49539 8.04927 6.58289 8.09308 6.64849L8.76033 7.41409C8.81498 7.46885 8.90248 7.4798 8.96808 7.42504C9.03367 7.3704 9.03367 7.28278 8.98998 7.2173L8.32272 6.45158C8.26808 6.3861 8.18046 6.3861 8.11498 6.44075Z"
      fill="white"
    />
    <path
      d="M10.5525 7.85165C10.4759 7.90629 10.3775 7.88439 10.3337 7.80784L9.05405 5.91559C9.01024 5.83905 9.03214 5.7406 9.09774 5.6969C9.17429 5.64226 9.27274 5.66416 9.31654 5.74071L10.5852 7.63284C10.64 7.70939 10.6181 7.80784 10.5525 7.85153V7.85165Z"
      fill="#F3F3F3"
    />
    <path
      d="M10.5528 7.86258C10.4762 7.91733 10.3668 7.89532 10.3121 7.81888L9.04345 5.92661C8.98881 5.85006 9.01071 5.74077 9.09809 5.68612C9.17464 5.63137 9.28404 5.65327 9.33879 5.72982L10.6074 7.62198C10.6621 7.70948 10.6402 7.80793 10.5528 7.86258ZM9.10893 5.70792C9.04334 5.75161 9.02144 5.83911 9.06524 5.90482L10.3339 7.79698C10.3777 7.86258 10.4652 7.87353 10.5416 7.82972C10.6073 7.78603 10.6292 7.69853 10.5854 7.63293L9.31689 5.75161C9.27309 5.68612 9.17464 5.66411 9.10916 5.70792H9.10893Z"
      fill="white"
    />
    <path
      d="M10.7824 6.23289C10.7059 6.27658 10.6075 6.25479 10.5638 6.17824L10.0606 5.29229C10.0169 5.21574 10.0496 5.11729 10.1263 5.07349C10.2028 5.02979 10.3013 5.05158 10.345 5.12824L10.848 6.01408C10.8809 6.09063 10.859 6.18908 10.7826 6.23289H10.7824Z"
      fill="#F3F3F3"
    />
    <path
      d="M10.782 6.24385C10.6945 6.28754 10.5961 6.26575 10.5524 6.18909L10.0492 5.30324C10.0055 5.22658 10.0274 5.11729 10.1149 5.07349C10.2024 5.02979 10.3008 5.05158 10.3445 5.12824L10.8476 6.01409C10.8914 6.09064 10.8695 6.18909 10.7821 6.24385H10.782ZM10.1258 5.08444C10.0601 5.12813 10.0274 5.21563 10.0711 5.28134L10.5743 6.1673C10.618 6.23278 10.7055 6.2548 10.7711 6.22194C10.8368 6.17825 10.8695 6.09064 10.8258 6.02504L10.3226 5.13908C10.2899 5.0736 10.1914 5.04063 10.1258 5.08444Z"
      fill="white"
    />
    <path
      d="M12.2374 6.95474C12.1501 6.98748 12.0625 6.95474 12.0297 6.87819L11.1438 4.76713C11.1111 4.69058 11.1548 4.59213 11.2313 4.55927C11.3188 4.52653 11.4063 4.55927 11.439 4.63582L12.3249 6.74677C12.3578 6.82332 12.3141 6.92177 12.2374 6.95451V6.95474Z"
      fill="#F3F3F3"
    />
    <path
      d="M12.2379 6.96577C12.1505 6.99851 12.0521 6.96577 12.0082 6.87815L11.1223 4.76733C11.1064 4.72525 11.1078 4.67859 11.1263 4.63758C11.1447 4.59656 11.1787 4.56454 11.2207 4.54852C11.3082 4.51578 11.4067 4.54852 11.4505 4.63602L12.3363 6.74696C12.3692 6.82351 12.3255 6.92196 12.238 6.96577H12.2379ZM11.2426 4.57043C11.1661 4.60317 11.1332 4.69078 11.1661 4.75638L12.0519 6.8672C12.0847 6.94375 12.1722 6.97649 12.2487 6.94375C12.3253 6.91101 12.3583 6.8234 12.3253 6.7578L11.4394 4.64686C11.3956 4.57031 11.3191 4.53746 11.2425 4.57031L11.2426 4.57043Z"
      fill="white"
    />
    <path
      d="M12.7729 5.40168C12.6854 5.43442 12.5979 5.39062 12.5652 5.30323L12.248 4.34063C12.2152 4.26408 12.2699 4.16563 12.3464 4.14384C12.4229 4.12194 12.5214 4.15479 12.5541 4.24229L12.8714 5.20478C12.9041 5.28133 12.8604 5.36883 12.7729 5.40157V5.40168Z"
      fill="#F3F3F3"
    />
    <path
      d="M12.7839 5.41257C12.7418 5.42843 12.6952 5.42697 12.6542 5.40852C12.6132 5.39006 12.5812 5.35612 12.5651 5.31412L12.2479 4.35162C12.2151 4.26412 12.2698 4.16578 12.3574 4.13293C12.3995 4.11707 12.4461 4.11853 12.4871 4.13699C12.5281 4.15544 12.5601 4.18938 12.5761 4.23138L12.8933 5.19388C12.9152 5.28138 12.8714 5.37983 12.7839 5.41257ZM12.3573 4.15483C12.2807 4.17662 12.2369 4.26412 12.2588 4.34067L12.5761 5.30328C12.5979 5.37983 12.6854 5.41257 12.7621 5.39067C12.8386 5.36888 12.8823 5.28138 12.8604 5.20483L12.5432 4.24222C12.5377 4.22365 12.5282 4.20647 12.5156 4.19178C12.5029 4.1771 12.4873 4.16524 12.4698 4.15699C12.4522 4.14873 12.4332 4.14427 12.4138 4.14387C12.3944 4.14348 12.3751 4.14718 12.3573 4.15472V4.15483Z"
      fill="white"
    />
    <path
      d="M14.0636 6.3969C13.9761 6.4188 13.8886 6.36416 13.8777 6.27655L13.4183 4.03441C13.3964 3.94692 13.4621 3.87037 13.5495 3.84846C13.6371 3.82656 13.7245 3.88121 13.7354 3.96882L14.1949 6.21106C14.2058 6.2875 14.1511 6.375 14.0636 6.3969Z"
      fill="#F3F3F3"
    />
    <path
      d="M14.0641 6.40785C13.9656 6.42976 13.8781 6.36405 13.8562 6.27655L13.3968 4.0344C13.3749 3.9469 13.4406 3.84845 13.5281 3.8375C13.6266 3.81559 13.7141 3.8813 13.7359 3.9688L14.1954 6.21106C14.2172 6.29845 14.1625 6.38606 14.0641 6.40785ZM13.55 3.8594C13.4734 3.87035 13.4187 3.9469 13.4297 4.0344L13.8891 6.27666C13.9 6.35309 13.9874 6.40785 14.0641 6.38595C14.1406 6.375 14.1953 6.29845 14.1843 6.21095L13.7249 3.96869C13.7031 3.89226 13.6266 3.84845 13.5499 3.8594H13.55Z"
      fill="white"
    />
    <path
      d="M14.8945 4.97504C14.807 4.98599 14.7305 4.93134 14.7086 4.84384L14.5774 3.83752C14.5664 3.75002 14.632 3.67347 14.7196 3.66252C14.807 3.65157 14.8835 3.70633 14.9054 3.79383L15.0366 4.80003C15.0476 4.88754 14.982 4.96409 14.8945 4.97504Z"
      fill="#F3F3F3"
    />
    <path
      d="M14.8957 4.98597C14.7972 4.99693 14.7097 4.93133 14.6988 4.84372L14.5676 3.83752C14.5566 3.75002 14.6222 3.66252 14.7207 3.65156C14.8191 3.64072 14.9066 3.70632 14.9176 3.79382L15.0488 4.80002C15.0597 4.88752 14.9941 4.97502 14.8957 4.98597ZM14.7316 3.67347C14.6551 3.68442 14.5894 3.76097 14.6003 3.83752L14.7316 4.84383C14.7426 4.92038 14.8191 4.97502 14.8957 4.96407C14.9722 4.95323 15.0379 4.87657 15.027 4.80002L14.8957 3.79382C14.8847 3.71727 14.8082 3.66252 14.7316 3.67347Z"
      fill="white"
    />
    <path
      d="M16.077 28.9391C23.229 28.9391 29.027 23.1411 29.027 15.9891C29.027 8.83697 23.229 3.03906 16.077 3.03906C8.92487 3.03906 3.12695 8.83697 3.12695 15.9891C3.12695 23.1411 8.92487 28.9391 16.077 28.9391Z"
      fill="url(#paint2_linear_1334_471)"
      fillOpacity="0.2"
    />
    <path
      d="M25.0444 7.77502L14.5991 14.4688H14.5881V14.4797L14.5772 14.4906L8.05859 25.2423L17.6398 17.5313L17.6507 17.5205V17.5095L25.0445 7.77514L25.0444 7.77502Z"
      fill="black"
      fillOpacity="0.05"
    />
    <path
      d="M24.8484 7.15155L14.5234 14.4906L17.5859 17.5312L24.8484 7.15155Z"
      fill="#CD151E"
    />
    <path
      d="M14.5352 14.4688L16.0774 15.989L24.8491 7.15155L14.5352 14.4688Z"
      fill="#FA5153"
    />
    <path
      d="M14.534 14.4688L17.5965 17.5093L7.27148 24.8484L14.534 14.4687V14.4688Z"
      fill="#ACACAC"
    />
    <path
      d="M7.27148 24.8484L16.0761 15.989L14.5339 14.4687L7.27148 24.8484Z"
      fill="#EEEEEE"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_471"
        x1="16"
        y1="30"
        x2="16"
        y2="2"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.25" stopColor="#DBDBDA" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_1334_471"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.8203 13.1554) scale(15.8073)"
      >
        <stop stopColor="#2ABCE1" />
        <stop offset="0.11363" stopColor="#2ABBE1" />
        <stop offset="1" stopColor="#3375F8" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_1334_471"
        x1="15.8309"
        y1="12.2861"
        x2="9.78662"
        y2="23.1302"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0" />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
);
export default Safari;
