import { CountryIconProps } from './types';

const TN = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#D80027"
    />
    <path
      d="M11.9999 17.7386C15.1695 17.7386 17.739 15.1692 17.739 11.9995C17.739 8.82988 15.1695 6.26038 11.9999 6.26038C8.83025 6.26038 6.26074 8.82988 6.26074 11.9995C6.26074 15.1692 8.83025 17.7386 11.9999 17.7386Z"
      fill="#F0F0F0"
    />
    <path
      d="M12.707 9.80505L13.6915 11.1619L15.2861 10.6448L14.3 12.0004L15.2844 13.3572L13.6904 12.8382L12.7043 14.1938L12.7053 12.5175L11.1113 11.9985L12.7059 11.4815L12.707 9.80505Z"
      fill="#D80027"
    />
    <path
      d="M13.3044 15.3906C11.4315 15.3906 9.91309 13.8722 9.91309 11.9993C9.91309 10.1263 11.4315 8.60794 13.3044 8.60794C13.8884 8.60794 14.4379 8.7556 14.9176 9.01557C14.165 8.27958 13.1359 7.82532 12.0001 7.82532C9.69494 7.82532 7.82617 9.69404 7.82617 11.9992C7.82617 14.3044 9.69494 16.1731 12.0001 16.1731C13.1359 16.1731 14.1651 15.7188 14.9176 14.9828C14.4379 15.2429 13.8884 15.3906 13.3044 15.3906Z"
      fill="#D80027"
    />
  </svg>
);
export default TN;
