import { RecommendationType, FeatureRecommendation, CreativeRecommendation } from '@replai-platform/sdk';
import { Start } from '@mui/icons-material';
import styled from 'styled-components';
import { Tooltip, Typography } from '@replai-platform/ui-components';

const Container = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: row;
  gap: 0.1rem;
  align-items: center;
  color: grey;
  font-size: 13px;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.1rem;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  margin: 0;
`;

const INTRO_FEATURE_RECOMMENDATION_TYPES = [
  RecommendationType.GOOD_TAG_INTRO,
  RecommendationType.BAD_TAG_INTRO,
] as const;
const INTRO_CREATIVE_RECOMMENDATION_TYPES = [
  RecommendationType.CREATIVE_ADD_TAG_INTRO,
  RecommendationType.CREATIVE_REMOVE_TAG_INTRO,
  RecommendationType.CREATIVE_REPLACE_TAG_INTRO,
] as const;

const IntroIndicator: React.FC<{
  content: CreativeRecommendation['name'] | FeatureRecommendation['content'];
}> = ({ content }) => {
  let firstSeconds: number | null = null;
  if ((INTRO_FEATURE_RECOMMENDATION_TYPES as readonly any[]).includes(content?.type)) {
    const introContent = content as FeatureRecommendation['content'] & {
      type: typeof INTRO_FEATURE_RECOMMENDATION_TYPES[number];
    };
    firstSeconds = introContent?.tag?.firstSeconds ?? 3;
  }
  if ((INTRO_CREATIVE_RECOMMENDATION_TYPES as readonly any[]).includes(content?.type)) {
    const introContent = content as CreativeRecommendation['name'] & {
      type: typeof INTRO_CREATIVE_RECOMMENDATION_TYPES[number];
    };

    switch (introContent?.type) {
      case RecommendationType.CREATIVE_ADD_TAG_INTRO:
      case RecommendationType.CREATIVE_REMOVE_TAG_INTRO:
        firstSeconds = introContent?.tag?.firstSeconds ?? 3;
        break;
      case RecommendationType.CREATIVE_REPLACE_TAG_INTRO:
        firstSeconds = introContent?.new?.firstSeconds ?? 3;
        break;
      default:
        firstSeconds = null;
    }
  }

  if (!firstSeconds) {
    return null;
  }
  return (
    <Container>
      <Tooltip content={<StyledTypography type="text-sm">Initial seconds of the video.</StyledTypography>}>
        <InnerWrapper>
          <Start fontSize="inherit" />
          {firstSeconds}s
        </InnerWrapper>
      </Tooltip>
    </Container>
  );
};

export default IntroIndicator;
