import { CommonTagIconProps } from '../types';

const DurationMiddle = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9999 21.5829C16.5033 21.5829 20.1539 17.9322 20.1539 13.4289C20.1539 8.92557 16.5033 5.2749 11.9999 5.2749C7.49662 5.2749 3.84595 8.92557 3.84595 13.4289C3.84595 17.9322 7.49662 21.5829 11.9999 21.5829Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12 3.24707V4.31107"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M18.4529 5.98511L17.7009 6.73711"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.293 2.41699H13.707"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.9999 8.33301V13.429H6.90393"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9999 9.27999C12.5229 9.27999 12.9469 8.856 12.9469 8.33299C12.9469 7.80997 12.5229 7.38599 11.9999 7.38599C11.4768 7.38599 11.0529 7.80997 11.0529 8.33299C11.0529 8.856 11.4768 9.27999 11.9999 9.27999Z"
      fill="#101828"
    />
    <path
      d="M8.39695 10.7729C8.91997 10.7729 9.34395 10.3489 9.34395 9.82591C9.34395 9.30289 8.91997 8.87891 8.39695 8.87891C7.87394 8.87891 7.44995 9.30289 7.44995 9.82591C7.44995 10.3489 7.87394 10.7729 8.39695 10.7729Z"
      fill="#101828"
    />
    <path
      d="M6.90391 14.3759C7.42692 14.3759 7.85091 13.9519 7.85091 13.4289C7.85091 12.9059 7.42692 12.4819 6.90391 12.4819C6.3809 12.4819 5.95691 12.9059 5.95691 13.4289C5.95691 13.9519 6.3809 14.3759 6.90391 14.3759Z"
      fill="#101828"
    />
  </svg>
);
export default DurationMiddle;
