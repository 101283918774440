/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as SDK from '@replai-platform/sdk';
import { Pagination } from '@replai-platform/ui-components';
import { useMemo, useState } from 'react';
import { useQueries } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useDeepCompareEffect } from 'react-use';
import { logEvent } from '../../../../analytics';
import { api } from '../../../../api';
import useConceptMetrics from '../../../../api/hooks/concepts/useConceptMetrics';
import { getConceptPreviewInfoQueryOptions } from '../../../../api/hooks/concepts/useConceptPreviewInfo';
import LibraryGrid from '../../../../components/LibraryGrid';
import type { RootState } from '../../../../store/rootReducer';
import { CONCEPTS_PLACEHOLDER_ARRAY } from '../../../../utils/constants';
import { Page, metricToColumnEnum } from '../../../../utils/enums';
import * as Styled from './styles';
import { formatMetrics } from './utils';

const MAX_RECORDS_PER_PAGE = 10;
const TOP_CONCEPTS_COUNT = 5;

const ConceptsGrid = ({
  networkFilterLoading,
  searchTerm,
  showCompare = false,
  onCompareClick,
}: {
  networkFilterLoading: boolean;
  searchTerm: string;
  showCompare: boolean;
  onCompareClick?: ({ id, name, thumbnailUrl }: { id: string; name: string; thumbnailUrl: string }) => void;
}) => {
  const navigate = useNavigate();
  const projectId = useSelector((state: RootState) => state.project.id);
  const baseMetric = useSelector((state: RootState) => state.project.baseMetric);
  const filters = useSelector((state: RootState) => state.filters);
  const libraryFilters = useSelector((state: RootState) => state.library.filters);
  const compareItems = useSelector((state: RootState) => state.app.compareItems);
  const [currentPage, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(MAX_RECORDS_PER_PAGE + 1);

  // Reset pagination when filters are changed
  useDeepCompareEffect(() => {
    setPage(1);
  }, [filters]);

  // Setup calls parameters
  const metricsParams = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: [baseMetric],
      orderBy: {
        condition: SDK.OrderByCondition.DESC_NULLS_LAST,
        value: baseMetric,
      },
      adsFilters: api.filterConverter.getAdsFilters({
        campaignIdsToConsider: filters.campaignIdsToConsider,
        campaignIdsToExclude: filters.campaignIdsToExclude,
        networks: filters.networks,
      }),
      assetFilters: api.filterConverter.getAssetFilters({ assetTypes: [SDK.AssetType.Video] }),
      metricsFilters: api.filterConverter.getMetricsFilters({
        startDate: filters.startDate,
        endDate: filters.endDate,
        countries: ['ALL'],
      }),
      tagsFilters: api.filterConverter.getTagsFilters({
        tagsToConsider: libraryFilters.tagsToConsider,
        tagsToExclude: libraryFilters.tagsToExclude,
      }),
      maxRecords: MAX_RECORDS_PER_PAGE,
      offset: (currentPage - 1) * MAX_RECORDS_PER_PAGE + (!searchTerm ? TOP_CONCEPTS_COUNT : 0),
      includeTotalsAndAvg: true,
      sorting: [{ id: metricToColumnEnum(baseMetric), desc: true }],
      search: searchTerm,
    }),
    [
      baseMetric,
      filters.campaignIdsToConsider,
      filters.campaignIdsToExclude,
      filters.endDate,
      filters.networks,
      filters.startDate,
      libraryFilters.tagsToConsider,
      libraryFilters.tagsToExclude,
      currentPage,
      projectId,
      searchTerm,
    ]
  );

  const topConceptsParams = useMemo(
    () => ({ ...metricsParams, maxRecords: TOP_CONCEPTS_COUNT, offset: 0 }),
    [metricsParams]
  );

  // Make calls to get data
  const { data: topConceptMetrics, isLoading: topIsLoading } = useConceptMetrics(topConceptsParams, {
    enabled: !networkFilterLoading,
  });

  const {
    data: conceptMetrics,
    isLoading,
    isError,
  } = useConceptMetrics(metricsParams, { enabled: !networkFilterLoading });

  // Format data
  const data = useMemo(() => {
    if (conceptMetrics) {
      const countWithoutTop = (conceptMetrics?.concepts?.[0]?.conceptsCount ?? 0) - TOP_CONCEPTS_COUNT;
      setTotalCount(countWithoutTop > 0 ? countWithoutTop : TOP_CONCEPTS_COUNT);
    }
    return formatMetrics(conceptMetrics, navigate, projectId, baseMetric, compareItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conceptMetrics, navigate, projectId, JSON.stringify(compareItems)]);

  const topConceptsData = useMemo(
    () => formatMetrics(topConceptMetrics, navigate, projectId, baseMetric, compareItems),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, projectId, topConceptMetrics, JSON.stringify(compareItems)]
  );

  // Thumbnails
  const conceptIds = useMemo(() => data?.map((concept) => concept.id), [data]);

  const thumbnailsQueries = useQueries(
    isLoading || !data || isError
      ? []
      : conceptIds?.map((id) =>
          getConceptPreviewInfoQueryOptions({ projectId, conceptId: id, size: '200x200' }, { enabled: !isLoading })
        ) ?? []
  );

  const topConceptIds = useMemo(() => topConceptsData?.map((concept) => concept.id), [topConceptsData]);

  const topThumbnailsQueries = useQueries(
    topIsLoading || !topConceptsData
      ? []
      : topConceptIds?.map((id) =>
          getConceptPreviewInfoQueryOptions({ projectId, conceptId: id, size: '200x200' }, { enabled: !topIsLoading })
        ) ?? []
  );

  const isLoadingThumbnails =
    thumbnailsQueries.some((q) => q.isLoading) || topThumbnailsQueries.some((q) => q.isLoading);

  const conceptData = useMemo(
    () =>
      (data || [])?.map((concept, index) => ({
        ...concept,
        onCompareClick: () => {
          onCompareClick?.({
            id: concept.id,
            name: concept.name,
            thumbnailUrl: thumbnailsQueries[index]?.data?.thumbnailUrl ?? '',
          });
          logEvent({
            component: `Concept Library`,
            action: 'Click compare',
            category: 'user_actions',
            parameters: { value: concept.name, page: Page.ConceptsLibrary },
          });
        },
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(data), JSON.stringify(thumbnailsQueries), onCompareClick]
  );

  const topFiveData = useMemo(
    () =>
      (topIsLoading ? CONCEPTS_PLACEHOLDER_ARRAY : topConceptsData)
        ?.map((concept, index) => ({
          ...concept,
          keyMetric: concept.keyMetric,
          keyMetricRatio: concept.keyMetricRatio,
          keyMetricLabel: SDK.kpiUtils.getDisplayName(baseMetric),
          onCompareClick: () => {
            onCompareClick?.({
              id: concept.id,
              name: concept.name,
              thumbnailUrl: topThumbnailsQueries[index]?.data?.thumbnailUrl || '',
            });
            logEvent({
              component: `Concept Library`,
              action: 'Click compare',
              category: 'user_actions',
              parameters: { value: concept.name, page: Page.ConceptsLibrary },
            });
          },
          previewLoading: !!topThumbnailsQueries[index]?.isLoading,
          previewUrl: topThumbnailsQueries[index]?.data?.previewUrl ?? '',
        }))
        // drop extra attributes from concept that carousel doesn't need
        .map(({ id, conceptsCount, ...keepAttrs }) => keepAttrs),
    [onCompareClick, topConceptsData, topIsLoading, topThumbnailsQueries]
  );

  return (
    <Styled.Container id="grid-container">
      <LibraryGrid
        title="Top Concepts"
        emptyStateIcon="Video"
        emptyStateText="No concepts found"
        topFiveData={topFiveData}
        videoCardsData={conceptData ?? []}
        isLoading={isLoading}
        isEmpty={(data?.length === 0 && topFiveData?.length === 0) || isError}
        searchTerm={searchTerm}
        thumbnailsQueries={thumbnailsQueries}
        showCompare={showCompare}
        disableCompare={isLoading || isLoadingThumbnails}
      />
      <Pagination
        style={{ alignSelf: 'center' }}
        totalCount={totalCount}
        pageSize={MAX_RECORDS_PER_PAGE}
        currentPage={currentPage}
        disabled={isLoading}
        onPageChange={(page) => {
          setPage(page);
          logEvent({
            component: 'Concepts Library',
            action: 'Change page',
            category: 'user_actions',
          });
          // For some reason, scrollIntoView doesn't work when called synchronously in Chrome/Brave.
          setTimeout(() => document.getElementById('grid-container')?.scrollIntoView({ behavior: 'smooth' }));
        }}
      />
    </Styled.Container>
  );
};

export default ConceptsGrid;
