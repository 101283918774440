import { LegacyRef } from 'react';
import ReactPlayer from 'react-player/lazy';

interface VideoComponentProps {
  onProgress?: (state: { played: number; playedSeconds: number; loaded: number; loadedSeconds: number }) => void;
  url: string;
  player?: LegacyRef<ReactPlayer>;
  style?: object;
  startTime?: string;
  onLoadedMetadata?: (metadata: any) => void;
  videoMaxHeight?: string;
  isPlaying?: boolean;
  onDuration?: (duration: number) => void;
  onPlay?: () => void;
  onPause?: () => void;
  onLoop?: boolean;
}

const VideoComponent: React.FC<VideoComponentProps> = ({
  onProgress,
  url,
  player = null,
  style = null,
  startTime = '0',
  onLoadedMetadata,
  videoMaxHeight,
  isPlaying = false,
  onDuration,
  onPlay,
  onPause,
  onLoop,
}) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <ReactPlayer
    ref={player}
    config={{
      file: {
        attributes: {
          disablePictureInPicture: true,
          controlsList: 'nodownload',
        },
      },
    }}
    playing={isPlaying}
    onLoadedMetadata={onLoadedMetadata}
    loop={onLoop}
    muted
    controls
    disablePictureInPicture
    onDuration={onDuration}
    onPlay={onPlay}
    onPause={onPause}
    onProgress={onProgress}
    preload="auto"
    playsinline
    pip={false}
    progressInterval={Math.round((1000 * 1) / 60)}
    style={
      style ?? {
        borderRadius: '8px',
        objectFit: 'scale-down',
        objectPosition: '50% 50%',
        backgroundColor: '#000',
        maxHeight: videoMaxHeight,
        overflow: 'hidden',
      }
    }
    width="100%"
    height="100%"
    url={url ? `${url}#t=${startTime}` : ''}
    aria-label="Video Player"
  />
);

export default VideoComponent;
