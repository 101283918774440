import { QueryFunction, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getConceptCount = async (requestParams: SDK.GetConceptsCountRequest) => {
  const res = await provider.getJson<object, SDK.GetConceptsCountResponse>(
    `${SDK.BASE_URL()}/concepts/count?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

const KEY = 'concept-count';
type Key = typeof KEY;

type Result = SDK.GetConceptsCountResponse;
type QueryKey = [Key, SDK.GetConceptsCountRequest];

type QueryOptions = UseQueryOptions<Result, unknown, Result, QueryKey>;
type QueryResult = UseQueryResult<Result>;

const queryFn: QueryFunction<Result, QueryKey> = ({ queryKey: [, requestParams] }) => getConceptCount(requestParams);

// This is exported so we can use useQueries
export function getConceptCountQueryOptions(
  requestParams: SDK.GetConceptsCountRequest,
  options?: QueryOptions
): QueryOptions {
  return {
    queryKey: [KEY, requestParams],
    queryFn,
    ...options,
  };
}

export default function useConceptCount(
  requestParams: SDK.GetConceptsCountRequest,
  options?: QueryOptions
): QueryResult {
  return useQuery(getConceptCountQueryOptions(requestParams, options));
}
