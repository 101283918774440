import { IntegrationIconProps } from './types';

const Quora = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8749 27.5391C17.8014 27.8394 16.6694 28 15.5 28C8.59644 28 3 22.4036 3 15.5C3 8.59644 8.59644 3 15.5 3C22.4036 3 28 8.59644 28 15.5C28 19.4441 26.1733 22.9615 23.3199 25.2525C24.1059 26.2448 25.0179 27.0132 26.0149 27.0132C27.6591 27.0132 28.1539 25.6898 28.1957 25.0281H30C30 28.5309 27.9054 30.8792 24.462 30.9909C23.2483 31.0303 21.2146 31.0962 18.8749 27.5391ZM17.7747 25.6258C17.1152 25.8697 16.3609 26 15.5 26C10.329 26 9 21.299 9 15.5C9 9.70101 10.3302 5 15.5 5C20.6698 5 22 9.70101 22 15.5C22 17.8536 21.7811 20.0263 21.1752 21.7773C20.1957 20.0543 18.6501 18 15.801 18C13.4287 18 11.6119 18.9712 11 19.4568L11.7531 20.9617C11.9623 20.8923 12.4905 20.7536 12.9298 20.7536C13.4789 20.7536 15.4244 20.5775 17.3072 24.6599C17.4639 24.9996 17.6198 25.3212 17.7747 25.6258Z"
      fill="#B72D2C"
    />
  </svg>
);
export default Quora;
