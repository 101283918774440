import styled from 'styled-components';
import Colors from '../Colors';

export const Root = styled.div`
  display: inline-block;
`;

export const Switch = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: max-content auto;
  align-items: center;
  height: 100%;
`;

export const ToggleHolder = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
`;

export const Input = styled.input`
  display: none;
`;

export const IconHolder = styled.span`
  display: flex;
  align-items: center;
`;

export const Label = styled.label<{ $color?: string }>`
  max-width: 300px;
  overflow-wrap: break-word;
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 20px;
  color: ${(props) => props.$color};
`;

export const SecondaryLabel = styled.label<{ $color?: string }>`
  font-weight: normal;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 20px;
  color: ${(props) => props.$color};
`;

export const Button = styled.button<{
  $isDisabled: boolean;
  $isChecked: boolean;
  $isIndeterminate: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  border: 0;
  border: 1px solid ${Colors.Gray[300]};
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: 0.2s;

  background-color: ${({ $isDisabled, $isChecked, $isIndeterminate }) =>
    $isDisabled
      ? Colors.Gray[100]
      : $isChecked || $isIndeterminate
      ? Colors.Primary[600]
      : Colors.Common.White};
  border-color: ${({ $isDisabled, $isChecked, $isIndeterminate }) =>
    $isDisabled
      ? Colors.Gray[100]
      : $isChecked || $isIndeterminate
      ? Colors.Primary[600]
      : Colors.Gray[300]};
  cursor: ${({ $isDisabled: $disabled }) =>
    $disabled ? 'not-allowed' : 'pointer'};

  &:focus: {
    box-shadow: ${({ $isDisabled }) =>
      $isDisabled ? '' : `0 0 0 4px ${Colors.Primary[100]}`};
    border-color: ${({ $isDisabled }) =>
      $isDisabled ? Colors.Gray[100] : Colors.Primary[600]};
  }

  &:hover {
    background-color: ${({ $isDisabled }) =>
      $isDisabled ? Colors.Gray[100] : Colors.Primary[600]};
    border-color: ${({ $isDisabled }) =>
      $isDisabled ? Colors.Gray[100] : Colors.Primary[600]};
  }

  &.rounded {
    border-radius: 50%;
  }
`;
