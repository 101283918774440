import { useMemo, useState } from 'react';
import { Button } from '@replai-platform/ui-components';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Buffer } from 'buffer';
import useGetPlayable from '../../api/hooks/assets/useGetPlayable';
import type { RootState } from '../../store/rootReducer';

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

const PlayButton = styled(Button)`
  width: fit-content;
  margin: auto;
`;

export type PlayablePreviewProps = {
  assetId: string;
  enabled: boolean;
};

const PlayablePreview = ({ assetId, enabled }: PlayablePreviewProps) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const [open, setOpen] = useState(false);

  const { isLoading, data: playableData } = useGetPlayable({ projectIds: [projectId], assetId }, { enabled: true });

  const isDisabled = isLoading || !playableData?.htmlContent;

  const iframeContent = useMemo(
    () => (enabled ? Buffer.from(playableData?.htmlContent ?? '', 'base64').toString('utf8') : ''),
    [enabled, playableData]
  );

  return (
    <Container>
      {open ? (
        <iframe
          key={enabled ? 'en' : 'dis'}
          width="100%"
          height="100%"
          title="Playable content"
          srcDoc={iframeContent}
          allowFullScreen
        />
      ) : (
        <PlayButton
          centerIcon={isDisabled ? undefined : { name: 'Play' }}
          disabled={isDisabled}
          onClick={() => setOpen(true)}
        >
          {isLoading
            ? 'Loading creative'
            : playableData
            ? 'Play Creative'
            : 'Failed to load creative. Please close and try again.'}
        </PlayButton>
      )}
    </Container>
  );
};

export default PlayablePreview;
