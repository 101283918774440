import { IntegrationIconProps } from './types';

const Igtv = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2086 14.633C17.2086 14.2493 17.0359 13.8872 16.7408 13.652C16.4456 13.4168 16.0612 13.335 15.6994 13.4305L9.31093 15.1155C8.66439 15.286 8.27484 15.9627 8.44085 16.6268C8.60685 17.291 9.26554 17.6912 9.91208 17.5206L14.7914 16.2337V17.8257C14.7914 18.2122 14.9665 18.5766 15.2653 18.8115C15.564 19.0465 15.9522 19.1251 16.3153 19.0243L22.7038 17.2506C23.3482 17.0717 23.7294 16.39 23.5553 15.7281C23.3811 15.0661 22.7175 14.6745 22.0731 14.8534L17.2086 16.204V14.633Z"
      fill="url(#paint0_angular_1334_979)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2498 0.251035C12.7824 -0.162333 13.5404 -0.0539183 13.9428 0.493187L15.9409 3.20996L18.0778 0.466044C18.4948 -0.0694058 19.2554 -0.156219 19.7766 0.27214C20.2979 0.7005 20.3824 1.48182 19.9654 2.01727L18.1521 4.34565C21.4826 4.34652 23.1937 4.37097 24.5118 5.06091C25.7139 5.6901 26.6912 6.69406 27.3037 7.9289C28 9.33274 28 11.1705 28 14.8459V18.4997C28 22.1752 28 24.0129 27.3037 25.4167C26.6912 26.6516 25.7139 27.6555 24.5118 28.2847C23.1453 29 21.3563 29 17.7784 29H14.2216C10.6437 29 8.85475 29 7.48818 28.2847C6.2861 27.6555 5.30879 26.6516 4.6963 25.4167C4 24.0129 4 22.1752 4 18.4997V14.8459C4 11.1705 4 9.33274 4.6963 7.9289C5.30879 6.69406 6.2861 5.6901 7.48818 5.06091C8.79289 4.378 10.4826 4.34709 13.7466 4.34569L12.0141 1.99013C11.6117 1.44302 11.7172 0.664404 12.2498 0.251035ZM17.7784 6.8288H14.2216C12.3928 6.8288 11.1665 6.83073 10.2225 6.90996C9.30667 6.98682 8.87132 7.12388 8.58559 7.27343C7.83836 7.66455 7.23084 8.28863 6.8501 9.05624C6.70452 9.34976 6.5711 9.79698 6.49628 10.7378C6.41915 11.7075 6.41727 12.9672 6.41727 14.8459V18.4997C6.41727 20.3784 6.41915 21.6381 6.49628 22.6079C6.5711 23.5486 6.70452 23.9959 6.8501 24.2894C7.23084 25.057 7.83836 25.6811 8.58559 26.0722C8.87132 26.2217 9.30667 26.3588 10.2225 26.4357C11.1665 26.5149 12.3928 26.5168 14.2216 26.5168H17.7784C19.6072 26.5168 20.8335 26.5149 21.7775 26.4357C22.6933 26.3588 23.1287 26.2217 23.4144 26.0722C24.1616 25.6811 24.7692 25.057 25.1499 24.2894C25.2955 23.9959 25.4289 23.5486 25.5037 22.6079C25.5809 21.6381 25.5827 20.3784 25.5827 18.4997V14.8459C25.5827 12.9672 25.5809 11.7075 25.5037 10.7378C25.4289 9.79698 25.2955 9.34976 25.1499 9.05624C24.7692 8.28863 24.1616 7.66455 23.4144 7.27343C23.1287 7.12388 22.6933 6.98682 21.7775 6.90996C20.8335 6.83073 19.6072 6.8288 17.7784 6.8288Z"
      fill="url(#paint1_angular_1334_979)"
    />
    <defs>
      <radialGradient
        id="paint0_angular_1334_979"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.1223 13.4802) rotate(-49.3396) scale(17.887 15.1504)"
      >
        <stop offset="0.0673096" stopColor="#FB218B" />
        <stop offset="0.279546" stopColor="#FB372B" />
        <stop offset="0.490204" stopColor="#FC6822" />
        <stop offset="0.75064" stopColor="#FDB630" />
      </radialGradient>
      <radialGradient
        id="paint1_angular_1334_979"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.1223 13.4802) rotate(-49.3396) scale(17.887 15.1504)"
      >
        <stop offset="0.0673096" stopColor="#FB218B" />
        <stop offset="0.279546" stopColor="#FB372B" />
        <stop offset="0.490204" stopColor="#FC6822" />
        <stop offset="0.75064" stopColor="#FDB630" />
      </radialGradient>
    </defs>
  </svg>
);
export default Igtv;
