import { useMutation } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const toggleFavoriteReport = async (
  body: SDK.ToggleFavoriteReportRequestBody
): Promise<SDK.ToggleFavoriteReportResponse> => {
  const url = `${SDK.BASE_URL()}/reports/favorite`;
  const res = await provider.getJson<object, SDK.ToggleFavoriteReportResponse>(url, { method: 'POST', body });
  return res;
};

export default function useToggleFavoriteReport() {
  return useMutation(['toggle-favorite-report'], (body: SDK.ToggleFavoriteReportRequestBody) =>
    toggleFavoriteReport(body)
  );
}
