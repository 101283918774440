import { Card, KeyValues, type KeyValue } from '@replai-platform/ui-components';
import ClickableImagePreview from '../../../../../../components/ClickableImagePreview';
import { RouteAnimator } from '../../../../../RouteAnimator';
import * as Style from './styles';

const TabWithSidebar: React.VFC<{
  propertyData?: KeyValue[];
  url: string;
  isLoading: boolean;
  mainContent: JSX.Element;
  extraButtons?: JSX.Element[];
}> = ({ propertyData, url, isLoading, mainContent, extraButtons }) => (
  <RouteAnimator>
    <Style.Container>
      <Style.LeftSide>
        <ClickableImagePreview imageUrl={url} />
        {extraButtons ?? null}
        {propertyData ? (
          <Card fullWidth disableContentPadding>
            <Style.Properties>
              <KeyValues
                header="Properties"
                values={propertyData}
                isLoading={isLoading}
                loadingPlaceholderNumber={11}
              />
            </Style.Properties>
          </Card>
        ) : null}
      </Style.LeftSide>
      <Style.RightSide>{mainContent}</Style.RightSide>
    </Style.Container>
  </RouteAnimator>
);

export default TabWithSidebar;
