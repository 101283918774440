import { IntegrationIconProps } from './types';

const Gnome = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.92295 19.1596C10.1099 20.7686 10.3768 21.9836 10.9573 23.4163C11.5375 24.8485 12.8173 26.7633 14.3785 27.9028C15.9397 29.0418 18.4862 30.1058 20.2077 29.9916C21.9291 29.8777 22.6963 29.2416 23.4882 28.4359C24.2796 27.6302 26.0593 25.1779 26.6796 22.8878C27.3003 20.5982 26.9131 19.7946 26.4136 19.1302C25.9145 18.4658 24.7883 18.0148 23.2513 18.1322C21.7143 18.2497 20.0539 19.2032 19.9594 19.9912C19.8133 21.2092 22.0816 21.3775 21.7143 22.4474C21.5667 22.8783 21.0169 23.2253 19.5574 23.1812C18.0979 23.1376 16.5003 22.4287 16.1289 21.3026C15.7575 20.1765 15.9198 19.1419 16.7085 18.5958C20.0393 16.29 21.1507 14.3906 21.3692 13.5268C21.5877 12.663 21.2458 11.6504 20.3816 10.874C18.5259 9.20692 14.794 9.95197 12.9843 11.2344C10.2984 13.1383 9.56225 16.0568 9.92295 19.1596ZM14.305 3.41756C13.9943 4.11836 13.5847 5.58187 13.9979 6.78901C14.4113 7.99616 15.9751 9.36286 17.695 9.04395C19.4147 8.72483 19.362 6.04055 19.0249 4.87596C18.7199 3.82306 17.185 2.09042 16.1128 2.00617C15.0406 1.92192 14.616 2.71633 14.305 3.41756ZM9.25489 7.55853C10.2618 9.03182 11.8365 9.26202 12.6359 8.9897C13.4352 8.71717 13.0897 6.49201 12.6082 5.46996C12.0066 4.19346 10.6039 4.07623 9.78758 4.49216C8.9713 4.90766 8.2482 6.08523 9.25489 7.55853ZM6.66963 8.61207C5.60554 8.97949 5.9129 11.1834 6.52869 12.0588C7.14427 12.9343 8.98501 13.4196 9.69441 12.6299C10.404 11.8401 9.52049 10.2783 9.09404 9.77305C8.66758 9.26734 7.73372 8.24465 6.66963 8.61207ZM5.08913 14.1219C5.39092 13.4332 6.78058 13.5019 7.82304 14.1015C9.13687 14.8574 9.1902 16.7536 8.4157 17.0629C7.78941 17.3131 6.67948 17.1512 5.98637 16.6021C5.29325 16.053 4.78734 14.8103 5.08913 14.1219Z"
      fill="black"
    />
  </svg>
);
export default Gnome;
