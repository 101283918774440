import { Button } from '@replai-platform/ui-components';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeature } from '@optimizely/react-sdk';
import { useTitle } from 'react-use';
import * as SDK from '@replai-platform/sdk';
import FilterBar from '../../../components/FilterBar';
import { FiltersContainer } from '../../../components/FilterBar/common/styles';
import type {
  BuyingStrategyFilterProps,
  CampaignFilterProps,
  NetworkFilterProps,
  CustomTagsFilterProps,
} from '../../../components/FilterBar/Filters';
import useCustomTagsFilter from '../../../components/FilterBar/hooks/useCustomTagsFilter';
import useCampaignFilter from '../../../components/FilterBar/hooks/useCampaignFilter';
import useBuyingStrategyFilter from '../../../components/FilterBar/hooks/useBuyingStrategyFilter';
import useNetworkFilter from '../../../components/FilterBar/hooks/useNetworkFilter';
import TopNavPageTitle from '../../../components/TopNavPageTitle';
import { AppActions } from '../../../store/app';
import { FilterActions } from '../../../store/filters';
import type { RootState } from '../../../store/rootReducer';
import { Page } from '../../../utils/enums';
import { RouteAnimator } from '../../RouteAnimator';
import ConceptsGrid from './ConceptsGrid';

const ConceptsLibrary: React.VFC = () => {
  const dispatch = useDispatch();
  const projectName = useSelector((state: RootState) => state.project.name);
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes);
  const compareItems = useSelector((state: RootState) => state.app.compareItems);
  const compareMenuOpen = useSelector((state: RootState) => state.app.compareMenuOpen);
  const [searchTerm, setSearchTerm] = useState('');
  const [isTechmerc1525Enabled] = useFeature('techmerc-1525'); // Buying Strategy filter

  useTitle(`Concepts Library - ${projectName}`);

  const onCompareClick = ({ id, name, thumbnailUrl }: { id: string; name: string; thumbnailUrl: string }) => {
    if (!compareMenuOpen) dispatch(AppActions.setCompareMenuOpen(true));
    if (compareItems[id] !== undefined) {
      if (Object.keys(compareItems).length === 1) dispatch(AppActions.setCompareMenuOpen(false));
      dispatch(AppActions.removeCompareItem({ id }));
    } else {
      dispatch(
        AppActions.addCompareItem({
          id,
          imageUrl: thumbnailUrl || '',
          name,
          isMarketData: false,
        })
      );
    }
  };

  const handleCompareButtonClick = () => {
    if (!compareMenuOpen) dispatch(AppActions.setCompareMenuOpen(true));
  };

  // Build network filter
  const {
    networkFilterOptions,
    networkFilterOnChange,
    isLoading: isNetworkFilterLoading,
  } = useNetworkFilter(Page.ConceptsLibrary);
  const networkFilter: NetworkFilterProps = useMemo(
    () => ({
      options: networkFilterOptions,
      onChange: networkFilterOnChange,
      loading: isNetworkFilterLoading,
    }),
    [networkFilterOptions, networkFilterOnChange, isNetworkFilterLoading]
  );

  // Build buying strategy filter
  const {
    buyingStrategyFilterOptions,
    buyingStrategyFilterOnChange,
    isLoading: isBuyingStrategyFilterLoading,
  } = useBuyingStrategyFilter(Page.Videos);
  const buyingStrategyFilter = useMemo(
    () =>
      ({
        options: buyingStrategyFilterOptions,
        onChange: buyingStrategyFilterOnChange,
        loading: isBuyingStrategyFilterLoading,
      } as BuyingStrategyFilterProps),
    [buyingStrategyFilterOptions, buyingStrategyFilterOnChange, isBuyingStrategyFilterLoading]
  );

  // Build Campaign Filter
  const { campaignFilterOptions, isLoading } = useCampaignFilter();
  const campaignFilter: CampaignFilterProps | undefined = useMemo(
    () =>
      projectAccountTypes?.includes(SDK.AccountTypes.SOCIALS)
        ? undefined
        : {
            options: campaignFilterOptions,
            loading: isLoading,
            onChange: (options) => {
              const isAllSelected =
                options.some(({ isAllOption, selected }) => isAllOption && selected) &&
                options.every(({ selected }) => !!selected);

              const selectedOptions = options
                .filter(({ selected, isAllOption }) => selected && !isAllOption)
                .map(({ id }) => id)
                .filter((v): v is string => v !== undefined);

              const unselectedOptions = options
                .filter(({ selected, isAllOption }) => !selected && !isAllOption)
                .map(({ id }) => id)
                .filter((v): v is string => v !== undefined);

              const toExclude = selectedOptions.length > unselectedOptions.length;

              dispatch(
                FilterActions.changeCampaigns({
                  value: isAllSelected ? [] : toExclude ? unselectedOptions : selectedOptions,
                  logEvent: true,
                  logComponent: 'Filters',
                  toExclude,
                })
              );
            },
          },
    [campaignFilterOptions, dispatch, isLoading, projectAccountTypes]
  );

  // Build tagged with filter
  const {
    customTagsFilterOptions,
    customTagsFilterOnChange,
    isLoading: isCustomTagsFilterLoading,
  } = useCustomTagsFilter('library');
  const customTagsFilter: CustomTagsFilterProps = useMemo(
    () => ({
      options: customTagsFilterOptions,
      onChange: customTagsFilterOnChange,
      loading: isCustomTagsFilterLoading,
    }),
    [customTagsFilterOnChange, customTagsFilterOptions, isCustomTagsFilterLoading]
  );

  return (
    <RouteAnimator data-test="concepts-container">
      <TopNavPageTitle
        title="Concepts Library"
        subtitle={`Dive into your creatives. Add to compare for a deeper analysis on what works and what doesn't.`}
      />
      <FiltersContainer data-test="videos-filters-container">
        <FilterBar
          eventPrefix="concept-library"
          withAddFilterButton={false}
          campaignFilter={campaignFilter}
          networkFilter={networkFilter}
          buyingStrategyFilter={isTechmerc1525Enabled ? buyingStrategyFilter : undefined}
          customFilter={
            Object.keys(compareItems).length > 0 ? (
              <Button
                leadingIcon={{ name: 'Scales' }}
                style={{ marginLeft: '0.75rem' }}
                onClick={handleCompareButtonClick}
              >
                Compare {`(${Object.keys(compareItems).length})`}
              </Button>
            ) : undefined
          }
          withSearchInput
          withCustomFilterLast
          onSearchTermChangeEnd={(search) => setSearchTerm(search)}
          customTagsFilter={customTagsFilter}
        />
      </FiltersContainer>
      <ConceptsGrid
        searchTerm={searchTerm}
        networkFilterLoading={isNetworkFilterLoading}
        showCompare
        onCompareClick={onCompareClick}
      />
    </RouteAnimator>
  );
};

export default ConceptsLibrary;
