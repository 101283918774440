import React, { useMemo, useState } from 'react';
import { Input, Tree, TreeProps } from 'antd';
import type { DataNode } from 'antd/es/tree';
import CustomizableDropDownChip from '../CustomizableDropDownChip/CustomizableDropDownChip';
import Typography from '../Typography/Typography';
import { TreeDropDownStyle } from './styles';
import Colors from '../Colors';

type TreeSelectLeafNode = { title: string; key: string };
type TreeSelectNode = {
  title: string;
  key: string;
  children: TreeSelectLeafNode[];
};

function renderLabel(label: string) {
  return (
    <Typography
      type="text-sm"
      style={{ margin: '0.1rem 0', color: Colors.Gray[700] }}
    >
      {label}
    </Typography>
  );
}

function treeNodeToDataNode(parent: TreeSelectNode): DataNode {
  const formattedParent: DataNode = { ...parent };
  formattedParent.title = renderLabel(parent.title);
  formattedParent.children = parent.children.map((child) => ({
    ...child,
    title: renderLabel(child.title),
  }));
  return formattedParent;
}

interface TreeSelectDropDownProps {
  data: TreeSelectNode[];
  label: string;
  loading?: boolean;
  onChangeSelectedKeys?: (keys: string[]) => void;
  onClearClick?: () => void;
  searchable?: boolean;
  selectedKeys?: string[];
}

const TreeSelectDropDown: React.FC<TreeSelectDropDownProps> = ({
  data,
  label,
  loading,
  onChangeSelectedKeys,
  onClearClick,
  searchable = false,
  selectedKeys,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const tableData = useMemo(() => {
    const searchRegex = new RegExp(searchValue, 'i');
    const { primaryOptions, remainingChildren } = data.reduce<{
      primaryOptions: TreeSelectNode[];
      remainingChildren: TreeSelectLeafNode[];
    }>(
      (acc, parent) => {
        if (!searchable || searchRegex.test(parent.title)) {
          // Always include the node if it matches the search or search is disabled.
          acc.primaryOptions.push(parent);
        } else {
          // Check which children match the search prompt to split from the ones which don't.
          const { filtered, unfiltered } = parent.children.reduce<
            Record<'filtered' | 'unfiltered', TreeSelectLeafNode[]>
          >(
            (acc, child) => {
              if (searchRegex.test(child.title)) {
                acc.filtered.push(child);
              } else {
                acc.unfiltered.push(child);
              }
              return acc;
            },
            { filtered: [], unfiltered: [] }
          );
          if (filtered.length > 0) {
            acc.primaryOptions.push({ ...parent, children: filtered });
          }
          if (unfiltered.length > 0) {
            acc.remainingChildren.push(...unfiltered);
          }
        }
        return acc;
      },
      { primaryOptions: [], remainingChildren: [] }
    );
    const tableData = {
      primaryOptions: primaryOptions.map(treeNodeToDataNode),
      remainingChildren,
    };
    return {
      ...tableData,
      onCheck: ((e: (string | number)[]) => {
        // Since we can only pass to the Tree component the keys that we want to have
        // displayed, we need some custom logic to calculate all selected keys.
        // The output list of keys includes:
        //  - keys that are *excluded* by the search prompt but were selected before
        //  - keys that are *included* by the search prompt and are still selected
        return onChangeSelectedKeys?.([
          ...remainingChildren
            .filter(({ key }) => selectedKeys?.includes(key))
            .map(({ key }) => key),
          ...(e as string[]),
        ]);
      }) as TreeProps['onCheck'],
    };
  }, [data, searchValue, searchable, onChangeSelectedKeys, selectedKeys]);

  return (
    <>
      <TreeDropDownStyle />
      <CustomizableDropDownChip
        iconType={(selectedKeys?.length ?? 0) > 0 ? 'cross' : 'chevron'}
        label={label}
        loading={loading}
        onClearClick={onClearClick}
        onDropdownOpenClose={() => {
          // Reset the search when the dropdown changes state.
          setSearchValue('');
        }}
        dropdownContent={
          <div style={{ width: '200px' }}>
            {searchable ? (
              <Input
                style={{ marginBottom: 8, borderRadius: '0.5em' }}
                placeholder="Search"
                onChange={onSearchChange}
              />
            ) : null}
            {tableData.primaryOptions.length === 0 ? (
              searchable ? (
                <span>No options match your search term</span>
              ) : (
                <span>No options available</span>
              )
            ) : (
              <Tree
                className="replai-tree"
                checkable
                selectable={false}
                defaultExpandAll={tableData.primaryOptions.length === 1} // If only one option exists, expand it
                autoExpandParent={false}
                onCheck={tableData.onCheck}
                checkedKeys={selectedKeys}
                treeData={tableData.primaryOptions}
              />
            )}
          </div>
        }
      />
    </>
  );
};

export default TreeSelectDropDown;
export type { TreeSelectDropDownProps };
