/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { PromotedObjectType } from '@replai-platform/sdk';
import { Icons } from '@replai-platform/ui-components';
import ConceptTabPaths from '../routes/Concepts/ConceptView/ConceptViewTabs/TabPaths';
import VideoTabPaths from '../routes/Concepts/CreativeView/Tabs/TabPaths';
import TagViewTabPaths from '../routes/Tags/TagView/TagViewTabs/tagViewTabPaths';
import { Filters, Page } from './enums';

/** Max video duration in seconds until which the timeline should be available for a creative. */
export const TIMELINE_MAX_DURATION_S_AVAILABILITY = 100;

// Threshold for performance on "Appears In" column
export const NETWORK_OS_PERFORMANCE_POSITIVE_THRESHOLD = 2.5;

// Available OS icons
export const OBJECT_TYPE_TO_ICON: {
  [objectType in PromotedObjectType]: Icons.FlatSocialIconTypes;
} = {
  IOS: 'Apple',
  ANDROID: 'Android',
  WEBSITE: 'Website',
};

// Used for Mixpanel integration
export const MIXPANEL_PROJECT_ID = '0ff75a76c77d181c52c34f07e62c5e69';

export const APPCUES_IDS = {
  CUSTOM_SCORE: '49bfac6e-b2ac-48a1-8b73-d463b027feb1',
  REPLAI_CONFIDENCE: '00f8eb29-56f3-4867-a1a7-dc82ccc52d8f',
  REPLAI_SCORE: '8e5c697b-a9d3-4872-a5cd-ca9260d81c6c',
  APPEARS_IN: '72fb2003-079c-4efc-8d8d-961634423a45',
  INSIGHTS: '399e64ae-bb4b-4d21-9e76-93e57404d430',
  RECOMMENDATIONS: '2e212d9d-f703-47f9-9288-85711e8dd75e',
  KPI_FILTER: 'd4290d8b-2a89-4f4c-8248-f91e9c4302b0',
  TIMELINE: '18b994bb-fd51-4876-b463-ed25ac13a77a',
  CONCEPT_NAME_COLUMN: '7acca1d9-3cd4-4b5d-a341-adf9a0130627',
  COMBINATIONS: 'c18861fb-4c49-40db-aa6e-7dcecda5f6d8',
  EDIT_CUSTOM_SCORE: 'c3be845f-0070-4fac-bcde-8a6d84c80d9d',
};

// All the pages and tabs with the back button option
export const AVAILABLE_BACKPAGES: { [currentPage in Page]?: { returnPage: Page; tabs?: string[] } } = {
  [Page.VideoView]: {
    returnPage: Page.Videos,
    tabs: [VideoTabPaths.Timeline, VideoTabPaths.Metrics],
  },
  [Page.SocialPostView]: {
    returnPage: Page.SocialPosts,
    tabs: [VideoTabPaths.Timeline, VideoTabPaths.Metrics],
  },
  [Page.Tags]: {
    returnPage: Page.Tags,
    tabs: [TagViewTabPaths.Videos, TagViewTabPaths.Metrics],
  },
  [Page.Concepts]: {
    returnPage: Page.Concepts,
    tabs: [ConceptTabPaths.Timeline, ConceptTabPaths.Metrics],
  },
};

// Used for skeleton loading on the concepts library
export const CONCEPTS_PLACEHOLDER_ARRAY = Array(10).fill({ id: '', conceptsCount: 0, recommendationNumber: 0 });

export const COMPARE_SCROLL_THRESHOLD = 0;
export const COMPARE_SCROLL_OFFSET = 98;

export const GOOGLE_CONFIG = 'G-JNNBPBTRZN';

/**
 * Labels and tooltips used in tag menus
 */
export const TAG_KINDS_TOOLTIPS = ({ isTechmerc1569Enabled }: { isTechmerc1569Enabled: boolean }) => ({
  NEW: { label: 'SUGGESTED AI TAGS', tooltip: 'Replai AI detected these tags, and you can choose to request them' },
  CUSTOM: {
    label: `CUSTOM ${isTechmerc1569Enabled ? 'FEATURES' : 'TAGS'}`,
    tooltip: 'Unique tags for this particular app',
  },
  MARKET: { label: 'MARKET TAGS', tooltip: "Market tags based on Replai's taxonomy" },
  CORE: { label: `BASE ${isTechmerc1569Enabled ? 'FEATURES' : 'TAGS'}`, tooltip: 'Standard tags for all apps' },
});

/**
 * List of filters that UI wise are just one but technically they work as X to consider or X to exclude depending
 * on the amount of options selected. Useful for filters that can have hundreds of options to choose from.
 */
export const FILTERS_WITH_CONSIDER_OR_EXCLUDING = [Filters.CustomTag, Filters.Campaigns];

export const FILTER_SUFFIX = {
  TO_CONSIDER: 'ToConsider',
  TO_EXCLUDE: 'ToExclude',
};

export const CUSTOMER_SUCCESS_EMAIL_ADDRESS = 'customer-success@replai.io';

export const CUSTOMIZE_DIALOG_SUBTITLE = 'These settings are applied across all platform tables';

export const CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP =
  'Please note: Some columns may not be available in this view. However, you can still re-arrange them as these settings are applied across all platform tables.';
