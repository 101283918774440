import { Badge, ColorTypes, camelCaseToCapitalCase } from '@replai-platform/ui-components';
import * as SDK from '@replai-platform/sdk';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../../common';

export type ReportStatusAccessorType = {
  status: string;
};

const ReportStatusCellDecorator: React.FC<{
  cellData: ReportStatusAccessorType;
}> = ({ cellData }) => {
  if (!cellData) {
    return null;
  }

  let color: ColorTypes;

  switch (cellData.status) {
    case SDK.ReportStatus.IN_PROGRESS:
      color = 'Blue';
      break;
    case SDK.ReportStatus.COMPLETED:
      color = 'Success';
      break;
    case SDK.ReportStatus.FAILED:
      color = 'Error';
      break;
    default:
      color = 'Gray';
  }

  return <Badge color={color}>{camelCaseToCapitalCase(cellData.status)}</Badge>;
};

function getReportStatusColumn<T extends object = ReportStatusAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T, ReportStatusAccessorType>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<T, ReportStatusAccessorType>) => <ReportStatusCellDecorator cellData={data?.cell?.value} />,
  };
}

export default getReportStatusColumn;
