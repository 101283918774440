import { ReactNode } from 'react';
import { Tooltip as AntTooltip } from 'antd';
import { TooltipProps as AntTooltipProps } from 'antd/lib/tooltip';

import 'antd/dist/antd.min.css';
import Colors, { Color } from '../Colors';
import * as Styled from './styles';

export type TooltipProps = Omit<AntTooltipProps, 'title' | 'overlay'> & {
  content?: ReactNode;
  className?: string;
  color?: Color;
  showArrow?: boolean;
  disableShadow?: boolean;
  disablePadding?: boolean;
  enableMaxContent?: boolean;
};

const Tooltip = ({
  children,
  content,
  className,
  showArrow = false,
  disableShadow = false,
  disablePadding = false,
  enableMaxContent = false,
  color = Colors.Common.White,
  ...props
}: TooltipProps) => (
  <>
    <Styled.TooltipStyle />
    <AntTooltip
      data-test="tooltip"
      overlayClassName={`
      replai-tooltip
      ${className ?? ''}
      ${showArrow ? 'show-arrow' : ''}
      ${disableShadow ? 'disable-shadow' : ''}
      ${disablePadding ? 'disable-padding' : ''}
      ${enableMaxContent ? 'enable-max-content' : ''}
    `}
      title={content}
      color={color}
      {...props}
    >
      {children}
    </AntTooltip>
  </>
);

export default Tooltip;
