import ScoreText from './ScoreText';
import { TierBadgeProps } from './types';

const Tier3 = ({ score, dimension }: TierBadgeProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimension}
    height={dimension}
    viewBox="0 0 64 64"
    fill="none"
  >
    <path
      d="M27.9996 2.30988C30.475 0.880542 33.525 0.880542 36.0004 2.30988L45.856 8.0007L55.7122 13.6905C58.1878 15.1196 59.7127 17.7609 59.7126 20.6193L59.712 32L59.7126 43.3807C59.7127 46.2391 58.1878 48.8804 55.7122 50.3095L45.856 55.9993L36.0004 61.6901C33.525 63.1195 30.475 63.1195 27.9996 61.6901L18.144 55.9993L8.28777 50.3095C5.81224 48.8804 4.28728 46.2391 4.28742 43.3807L4.288 32L4.28742 20.6193C4.28728 17.7609 5.81224 15.1196 8.28777 13.6905L18.144 8.0007L27.9996 2.30988Z"
      fill="white"
    />
    <path
      d="M27.9996 6.30988C30.475 4.88054 33.525 4.88054 36.0004 6.30988L44.124 11.0006L52.2481 15.6905C54.7237 17.1196 56.2486 19.7609 56.2485 22.6193L56.248 32L56.2485 41.3807C56.2486 44.2391 54.7237 46.8804 52.2481 48.3095L44.124 52.9994L36.0004 57.6901C33.525 59.1195 30.475 59.1195 27.9996 57.6901L19.876 52.9994L11.7519 48.3095C9.27634 46.8804 7.75138 44.2391 7.75152 41.3807L7.752 32L7.75152 22.6193C7.75138 19.7609 9.27634 17.1196 11.7519 15.6905L19.876 11.0006L27.9996 6.30988Z"
      fill="#32D583"
    />
    <g opacity="0.25" clipPath="url(#clip0_5457_188047)">
      <path
        d="M28.1909 44.2034C28.3977 44.0654 28.673 44.2243 28.6568 44.4724L28.4796 47.1919C28.4732 47.2903 28.5156 47.3856 28.593 47.4468L30.7323 49.1373C30.9273 49.2914 30.8611 49.6023 30.6201 49.6635L27.9793 50.3339C27.8836 50.3582 27.806 50.4281 27.7717 50.5208L26.828 53.0766C26.7418 53.3098 26.4256 53.343 26.2928 53.1328L24.838 50.828C24.7853 50.7445 24.6949 50.6922 24.5962 50.6884L21.8728 50.5808C21.6243 50.571 21.495 50.2806 21.6539 50.0893L23.3954 47.9941C23.4586 47.9181 23.4803 47.8159 23.4534 47.7209L22.7137 45.0987C22.6462 44.8594 22.8822 44.6466 23.1132 44.7384L25.6469 45.7459C25.7386 45.7824 25.8423 45.7714 25.9244 45.7166L28.1909 44.2034Z"
        fill="white"
      />
    </g>
    <g opacity="0.25" clipPath="url(#clip1_5457_188047)">
      <path
        d="M36.2017 44.2034C35.9949 44.0654 35.7196 44.2243 35.7358 44.4724L35.913 47.1919C35.9194 47.2903 35.877 47.3856 35.7996 47.4468L33.6603 49.1373C33.4652 49.2914 33.5315 49.6023 33.7725 49.6635L36.4132 50.3339C36.509 50.3582 36.5866 50.4281 36.6208 50.5208L37.5646 53.0766C37.6508 53.3098 37.967 53.343 38.0997 53.1328L39.5545 50.828C39.6072 50.7445 39.6977 50.6922 39.7964 50.6884L42.5198 50.5808C42.7682 50.571 42.8976 50.2806 42.7387 50.0893L40.9972 47.9941C40.934 47.9181 40.9123 47.8159 40.9391 47.7209L41.6789 45.0987C41.7464 44.8594 41.5103 44.6466 41.2793 44.7384L38.7456 45.7459C38.654 45.7824 38.5503 45.7714 38.4682 45.7166L36.2017 44.2034Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter0_dd_5457_188047)">
      <path
        d="M43.624 11.8666L43.624 11.8667L51.7482 16.5566C53.9143 17.807 55.2486 20.1182 55.2485 22.6193L55.248 31.9999V32.0001L55.2485 41.3807C55.2486 43.8818 53.9143 46.193 51.7482 47.4434L43.624 52.1333L43.624 52.1334L35.5003 56.8241C33.3343 58.0748 30.6657 58.0748 28.4997 56.8241L20.376 52.1334L20.376 52.1333L12.2518 47.4434C10.0857 46.193 8.7514 43.8818 8.75152 41.3807L8.752 32.0001V31.9999L8.75152 22.6193C8.7514 20.1182 10.0857 17.807 12.2518 16.5566L20.376 11.8667L20.376 11.8666L28.4997 7.17588C30.6657 5.92521 33.3343 5.92521 35.5003 7.17588L43.624 11.8666Z"
        stroke="#12B76A"
        strokeWidth="2"
      />
    </g>

    <ScoreText score={score} />

    <defs>
      <filter
        id="filter0_dd_5457_188047"
        x="4.75195"
        y="3.23779"
        width="54.4961"
        height="59.5244"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5457_188047"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5457_188047"
          result="effect2_dropShadow_5457_188047"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_5457_188047"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_5457_188047">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(24 40) rotate(30)"
        />
      </clipPath>
      <clipPath id="clip1_5457_188047">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="matrix(-0.866025 0.5 0.5 0.866025 40.3926 40)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Tier3;
