export interface TagBreakdownT {
  segments: { startSeconds: number; endSeconds: number }[];
  type: string;
  value: string | null;
  description?: string;
  id?: string;
  key?: string;
  originalType?: string;
  recommendations?: JSX.Element[] | JSX.Element;
  difference?: TagDifference | null;
  differenceDetails?: number | SegmentPositionDifference;
}

export enum TagDifference {
  NOT_PRESENT = 'not-present',
  ADDED = 'added',
  POSITION = 'position',
  LENGTH = 'length',
  VALUE_CHANGED_OLD = 'value-changed-old',
  VALUE_CHANGED_NEW = 'value-changed-new',
  NO_SHOW = 'no-show',
}

export enum SegmentPositionDifference {
  BACKWARD = 'backward',
  FORWARD = 'forward',
}
