import { CommonTagIconProps } from '../types';

const Keyword = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1891 14.6931C17.2808 14.6931 19.7871 12.1868 19.7871 9.09507C19.7871 6.00338 17.2808 3.49707 14.1891 3.49707C11.0974 3.49707 8.59106 6.00338 8.59106 9.09507C8.59106 12.1868 11.0974 14.6931 14.1891 14.6931Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.282 13.3901L4.21301 20.0131"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.51905 20.503L5.52405 18.73"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.68104 17.6521L7.51904 16.7021"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default Keyword;
