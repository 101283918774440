import { CommonTagIconProps } from '../types';

const NumberOfScenes1To2 = ({ dimension }: CommonTagIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 24"
    fill="none"
    stroke="#101828"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    width={dimension || 20}
    height={dimension || 20}
  >
    <rect x="1" y="2" width="32" height="20" rx="2" ry="2" />
    <rect x="4" y="5" width="4" height="14" />
  </svg>
);
export default NumberOfScenes1To2;
