/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import {
  Button,
  camelCaseToCapitalCase,
  Colors,
  CombinationDetail,
  Tab,
  TrendIcon,
} from '@replai-platform/ui-components';
import millify from 'millify';
import { useCallback, useMemo, useState } from 'react';
import { useQueries, useQuery, UseQueryResult } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect, useTitle } from 'react-use';
import { logEvent } from '../../analytics';
import { api } from '../../api';
import useUpdateUiPreferences from '../../api/hooks/projects/useUpdateUiPreferences';
import useAssetsByTags from '../../api/hooks/tags/useAssetsByTags';
import useTagDescription from '../../api/hooks/tags/useTagDescription';
import useTagMetrics from '../../api/hooks/tags/useTagMetrics';
import useTagTypeMetrics, { getTagTypeMetricsQueryOptions } from '../../api/hooks/tags/useTagTypeMetrics';
import useTagTypes from '../../api/hooks/tags/useTagTypes';
import FilterBar from '../../components/FilterBar';
import type { KpiFilterProps } from '../../components/FilterBar/Filters/KpiFilter';
import type { NetworkFilterProps } from '../../components/FilterBar/Filters/NetworkFilter';
import useNetworkFilter from '../../components/FilterBar/hooks/useNetworkFilter';
import PickAndSortDialog from '../../components/PickAndSortDialog';
import TopNavPageTitle from '../../components/TopNavPageTitle';
import { AppActions } from '../../store/app';
import { CombinationsActions } from '../../store/combinations';
import { FilterActions } from '../../store/filters';
import { ProjectActions } from '../../store/project';
import type { RootState } from '../../store/rootReducer';
import { capitalizeFirstLetter, getTagKind, shortenString } from '../../utils';
import { Page } from '../../utils/enums';
import * as Styled from './styles';
import TagPerformanceExplorer from './TagPerformanceExplorer';
import { getHistory, getTrendIcon, retrieveMetricsPerformance } from './utils';

const Combinations: React.VFC = () => {
  const dispatch = useDispatch();
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectBaseMetric = useSelector((state: RootState) => state.project.baseMetric);
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis) as SDK.Metrics[];
  const tagFilters = useSelector((state: RootState) => state.filters.tagFilters);
  const defaultProjectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const filters = useSelector((state: RootState) => state.filters);
  const projectName: string = useSelector((state: RootState) => state.project.name);
  const defaultKpi = useSelector((state: RootState) => state.filters.kpi) || defaultProjectKpis?.[0];
  const [chartKpi, setChartKpi] = useState(defaultKpi);
  const filteringKPIS: SDK.Metrics[] = useMemo(
    () => [
      ...new Set([
        ...(filters.kpi ? [filters.kpi as unknown as SDK.Metrics] : [defaultKpi as SDK.Metrics]),
        projectBaseMetric,
      ]),
    ],
    [defaultKpi, filters.kpi]
  );
  const tagsTypesConfig = useSelector((state: RootState) => state.project.config).tagsTypes;
  const uiPreferences = useSelector((state: RootState) => state.project.userProject?.uiPreferences);
  const userSelectedTagTypes = uiPreferences?.tagExplorer?.tagTypes;
  useTitle(`${capitalizeFirstLetter(Page.Combinations)} - ${projectName}`);
  const updateUiPreferencesMutation = useUpdateUiPreferences();

  // Build network filter
  const {
    networkFilterOptions,
    networkFilterOnChange,
    isLoading: isNetworkFilterLoading,
  } = useNetworkFilter(Page.Combinations);
  const networkFilter = useMemo(
    () =>
      ({
        options: networkFilterOptions,
        onChange: networkFilterOnChange,
        loading: isNetworkFilterLoading,
      } as NetworkFilterProps),
    [networkFilterOptions, networkFilterOnChange, isNetworkFilterLoading]
  );

  // Build KPI Filter
  const kpiFilterOptions = defaultProjectKpis.map((kpi) => ({
    label: SDK.kpiUtils.getDisplayName(kpi),
    onClick: () => dispatch(FilterActions.changeKPI({ value: kpi, logEvent: true, page: Page.Combinations })),
  }));
  const kpiFilter = useMemo(
    () =>
      ({
        dropdownType: 'singleselect',
        options: kpiFilterOptions,
        defaultOption: defaultKpi ?? 'Select KPI',
      } as KpiFilterProps),
    [kpiFilterOptions, defaultKpi]
  );

  const { isLoading: isGlobalPerformanceLoading, data: globalPerformanceMetrics } = useQuery<
    SDK.Metric | Record<string, never>
  >(['metrics.getMetricsPerformance', { projectId, filters, projectKpis }], () =>
    retrieveMetricsPerformance({
      projectId,
      filters,
      projectKpis,
    })
  );

  const { isLoading: isTagPerformanceLoading, data: tagPerformanceMetrics } = useQuery<
    SDK.Metric | Record<string, never>
  >(
    ['metrics.getMetricsGlobalPerformance', { projectId, filters, projectKpis, tagFilters }],
    () =>
      retrieveMetricsPerformance({
        projectId,
        filters,
        projectKpis,
        taggedWith: tagFilters.map((tag) => tag.id),
      }),
    { enabled: tagFilters.length > 0 }
  );

  // Global Performance Metrics
  useDeepCompareEffect(() => {
    if (globalPerformanceMetrics && !isGlobalPerformanceLoading && tagFilters.length === 0) {
      dispatch(CombinationsActions.setGlobalPerformance(globalPerformanceMetrics));
    } else if (tagPerformanceMetrics && !isTagPerformanceLoading && tagFilters.length > 0) {
      dispatch(CombinationsActions.setGlobalPerformance(tagPerformanceMetrics));
    }
  }, [
    isGlobalPerformanceLoading,
    globalPerformanceMetrics,
    isTagPerformanceLoading,
    tagPerformanceMetrics,
    tagFilters,
  ]);

  // Tag Performance Metrics
  useDeepCompareEffect(() => {
    if (tagPerformanceMetrics && !isTagPerformanceLoading) {
      dispatch(CombinationsActions.setTagPerformance(tagPerformanceMetrics));
    }
  }, [isTagPerformanceLoading, tagPerformanceMetrics || {}]);

  const availableKpis = useMemo(
    () => defaultProjectKpis.map((kpi) => ({ title: SDK.kpiUtils.getDisplayName(kpi) })),
    [defaultProjectKpis]
  );

  const { data: performanceChartData, isLoading: performanceChartIsLoading } = useQuery(
    ['metrics.getHistoryMetrics', { filters, tagFilters, chartKpi }],
    () =>
      getHistory({
        projectId,
        tagFiltering: filters,
        filteredTagsIds: tagFilters.map((tag) => tag.id),
        filteringKpi: chartKpi as SDK.Metrics,
      })
  );

  // Transform all the performance values into an aggregated value containing both global and tag performance
  const aggregatedPerformance = useMemo(
    () =>
      Object.keys(globalPerformanceMetrics || []).map((kpi) => {
        const globalValue = globalPerformanceMetrics?.[kpi];
        const tagValue = tagPerformanceMetrics?.[kpi];
        const isKpiTagCompare =
          tagFilters.length > 0 &&
          tagValue &&
          // Disable performance comparison for spend (TECHPROM-242).
          kpi !== SDK.Metrics.SPEND;
        const goodness = (SDK.kpiUtils.shouldMaximize(kpi) ? 1 : -1) * (tagValue - globalValue);
        const value = tagValue / globalValue - 1;
        const trendIcon = isKpiTagCompare ? getTrendIcon({ goodness, value }) : undefined;
        return {
          kpiName: SDK.kpiUtils.getDisplayName(kpi as SDK.Metrics),
          kpiValue: SDK.kpiUtils.format(
            kpi,
            isKpiTagCompare ? value : (tagValue as number) ?? (globalValue as number),
            {
              isDelta: isKpiTagCompare,
            }
          ),
          kpiValueColor: !isKpiTagCompare
            ? undefined
            : (SDK.kpiUtils.shouldMaximize(kpi) && (isKpiTagCompare ? value > 0 : (tagValue ?? globalValue) > 0)) ||
              (!SDK.kpiUtils.shouldMaximize(kpi) && (isKpiTagCompare ? value < 0 : (tagValue ?? globalValue) < 0))
            ? Colors.Success[500]
            : Colors.Error[500],
          trendIcon: trendIcon?.icon as TrendIcon,
          trendIconColor: trendIcon?.color,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(globalPerformanceMetrics), JSON.stringify(tagPerformanceMetrics)]
  );

  const getTagsMetricsParams = useMemo<SDK.GetTagTypeMetricsRequest>(
    () => ({
      projectIds: [projectId],
      metrics: filteringKPIS,
      orderBy: {
        condition: SDK.OrderByCondition.DESC_NULLS_LAST,
        value: projectBaseMetric,
      },
      adTagsFilters: api.filterConverter.getAdTagsFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters({
        ...filters,
        countriesToConsider: undefined,
        countriesToExclude: undefined,
        ageStartDate: undefined,
        ageEndDate: undefined,
      }),
      adsFilters: {
        ...api.filterConverter.getAdsFilters({ ...filters, campaignIdsToConsider: [] }),
        ...(tagFilters && tagFilters.length > 0 ? { taggedWith: tagFilters.map((tag) => tag.id) } : null),
      },
      tagsFilters: {
        tagTypeToConsider: { type: tagFilters[0]?.type },
      },
      assetFilters: api.filterConverter.getAssetFilters(filters),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, JSON.stringify(filteringKPIS), JSON.stringify(filters), JSON.stringify(tagFilters)]
  );

  const { data: selectedTagsPerformance } = useTagTypeMetrics(getTagsMetricsParams, {
    enabled: tagFilters.length > 0,
  });

  const getGlobalTagsMetricsParams = useMemo<SDK.GetTagsMetricsRequest>(
    () => ({
      projectIds: [projectId],
      metrics: [projectBaseMetric],
      orderBy: {
        condition: SDK.OrderByCondition.DESC_NULLS_LAST,
        value: projectBaseMetric,
      },
      adTagsFilters: api.filterConverter.getAdTagsFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters({
        ...filters,
        countriesToConsider: undefined,
        countriesToExclude: undefined,
        ageStartDate: undefined,
        ageEndDate: undefined,
      }),
      assetFilters: api.filterConverter.getAssetFilters(filters),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, JSON.stringify(filters)]
  );

  const { data: globalTagsPerformance } = useTagMetrics(getGlobalTagsMetricsParams);

  const metrics = useMemo(
    () => ({
      individualFrequency: millify(selectedTagsPerformance?.tags[0]?.individualObjectCount ?? 0),
      totalFrequency: millify(globalTagsPerformance?.tags[0]?.totalObjectCount ?? 0),
      percentageFrequency:
        selectedTagsPerformance?.tags[0]?.individualObjectCount && globalTagsPerformance?.tags[0]?.totalObjectCount
          ? (selectedTagsPerformance.tags[0].individualObjectCount / globalTagsPerformance.tags[0].totalObjectCount) *
            100
          : 0,
      individualSpend: millify(selectedTagsPerformance?.tags[0]?.totalSpend ?? 0),
      totalSpend: millify(globalTagsPerformance?.tags[0]?.totalSpend ?? 0),
      percentageSpend:
        selectedTagsPerformance?.tags[0]?.totalSpend && globalTagsPerformance?.tags[0]?.totalSpend
          ? (selectedTagsPerformance.tags[0].totalSpend / globalTagsPerformance.tags[0].totalSpend) * 100
          : 0,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(selectedTagsPerformance), JSON.stringify(globalTagsPerformance)]
  );

  const assetsByTagsParams = useMemo<SDK.GetTagAssetsByTagsRequest>(
    () => ({
      projectIds: [projectId],
      tagIds: tagFilters.map((tag) => tag.id),
      metricsFilters: api.filterConverter.getMetricsFilters({
        ...filters,
        countriesToConsider: undefined,
        countriesToExclude: undefined,
        ageStartDate: undefined,
        ageEndDate: undefined,
      }),
      maxRecords: 3,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, JSON.stringify(tagFilters)]
  );
  const { data: tagsAssetsData, isLoading: tagsAssetsLoading } = useAssetsByTags(assetsByTagsParams, {
    enabled: tagFilters.length > 0,
    select: useCallback(
      (data) =>
        data.assets.map(
          (asset: {
            name: string;
            url: string;
            annotation: { type: string; value: string; startSeconds: number; endSeconds: number }[];
          }) => ({
            name: asset.name,
            url: new URL(asset.url),
            timeline: asset.annotation
              .concat(
                tagFilters.map((tag) => ({ type: tag.type, value: tag.value ?? '', startSeconds: 0, endSeconds: 0 }))
              )
              .reduce((acc, annotation) => {
                // An annotation can have multiple segments so we need to merge them
                // Also, we only care about the selected tags, so we filter those
                const isTagSelected = tagFilters.find(
                  (tag) => tag.type === annotation.type && tag.value === annotation.value
                );
                if (!isTagSelected) return acc;
                const existingAnnotationTypeValueIndex = acc.findIndex(
                  (a) => a.type === annotation.type && a.value === annotation.value
                );
                if (existingAnnotationTypeValueIndex === -1) {
                  acc.push({
                    type: annotation.type,
                    value: annotation.value,
                    segments:
                      annotation.startSeconds === 0 && annotation.endSeconds === 0
                        ? []
                        : [{ startSeconds: annotation.startSeconds, endSeconds: annotation.endSeconds }],
                  });
                } else {
                  acc[existingAnnotationTypeValueIndex].segments.push({
                    startSeconds: annotation.startSeconds,
                    endSeconds: annotation.endSeconds,
                  });
                }
                return acc;
              }, [] as { type: string; value: string; segments: SDK.Timeframe[] }[]),
          })
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [JSON.stringify(tagFilters)]
    ),
  });

  const getTagsDescriptionParams = useMemo(
    () => ({
      projectIds: [projectId],
      tags: tagFilters.map((tag) => tag.id),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, JSON.stringify(tagFilters)]
  );
  const { data: tagsDescription, isLoading: tagsDescriptionLoading } = useTagDescription(getTagsDescriptionParams, {
    enabled: tagsAssetsData?.length > 0,
  });

  const data = useMemo(() => {
    if (!tagsAssetsData || tagsAssetsData.length === 0 || !tagsDescription || tagsDescription?.tags?.length === 0) {
      return [];
    }
    return tagsAssetsData.map((asset) => ({
      ...asset,
      timeline: asset.timeline.map((annotation: { type: string; value: string }) => {
        const tag = tagsDescription.tags.find((t) => t.type === annotation.type && t.value === annotation.value);
        return {
          ...annotation,
          type: camelCaseToCapitalCase(annotation.type),
          description: tag?.description,
        };
      }),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tagsAssetsData), JSON.stringify(tagsDescription?.tags)]);

  // Retrieves information about the tags that are to be shown
  const getSelectedTagTypesParams = useMemo<SDK.GetTagsTypesRequest>(
    () => ({
      projectIds: [projectId],
      tagsFilters: {
        tagTypesToConsider:
          userSelectedTagTypes && userSelectedTagTypes.length > 0
            ? userSelectedTagTypes.map((type) => ({ type }))
            : tagsTypesConfig?.custom?.map((type) => ({ type })),
        tagTypesToExclude: userSelectedTagTypes ? [] : tagsTypesConfig?.excluded?.map((type) => ({ type })),
      },
    }),
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      projectId,
      JSON.stringify(tagsTypesConfig?.custom),
      JSON.stringify(tagsTypesConfig?.excluded),
      JSON.stringify(userSelectedTagTypes),
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );
  const {
    data: selectedTagTypes,
    isLoading: isLoadingSelectedTagTypes,
    isSuccess,
    isPreviousData,
  } = useTagTypes(getSelectedTagTypesParams, {
    select: useCallback(
      (res) => {
        const userTagTypes = userSelectedTagTypes ?? [];

        // Maps and then sorts the tag types to consider
        const sortedMappedTagTypes: { type: string; kind: SDK.TagKind; values: string[] }[] = res.map(
          (tag: { type: string; values: string }) => ({
            type: tag.type,
            kind: getTagKind(tag.type, tagsTypesConfig || {}),
            values: tag.values,
          })
        );

        // Sort the tags according to the user's defined order
        sortedMappedTagTypes.sort((a, b) => userTagTypes.indexOf(a.type) - userTagTypes.indexOf(b.type));
        return sortedMappedTagTypes;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [JSON.stringify(tagsTypesConfig), JSON.stringify(userSelectedTagTypes)]
    ),
    keepPreviousData: true,
  });

  // BEGIN - CustomizeVisibleTags states/configs
  const [openCustomizeVisibleTags, setOpenCustomizeVisibleTags] = useState(false);

  const { data: tagTypesData, isLoading: isLoadingTagTypes } = useTagTypes({
    projectIds: [projectId],
    tagsFilters: {
      tagTypesToExclude: tagsTypesConfig?.excluded?.map((type) => ({ type })),
    },
  });

  const tagTypesArray = useQueries(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    tagTypesData
      ? tagTypesData.map((tag: { type: string; numValues: number }) => {
          const formatResult = () => ({
            type: tag.type,
            group: tagsTypesConfig?.custom?.includes(tag.type)
              ? 'custom'
              : tagsTypesConfig?.psychological?.includes(tag.type)
              ? 'psychological'
              : 'core',
            count: tag.numValues,
          });

          return getTagTypeMetricsQueryOptions(
            {
              projectIds: [projectId],
              metrics: [projectBaseMetric],
              orderBy: {
                condition: SDK.OrderByCondition.DESC_NULLS_LAST,
                value: projectBaseMetric,
              },
              adsFilters: {
                ...api.filterConverter.getAdsFilters({
                  ...filters,
                  campaignIdsToConsider: [],
                }),
              },
              adTagsFilters: api.filterConverter.getAdTagsFilters(filters),
              assetFilters: api.filterConverter.getAssetFilters(filters),
              metricsFilters: api.filterConverter.getMetricsFilters({
                ...filters,
                countriesToConsider: undefined,
                countriesToExclude: undefined,
                ageStartDate: undefined,
                ageEndDate: undefined,
              }),
              tagsFilters: {
                tagTypeToConsider: { type: tag.type },
              },
            },
            {
              enabled: !isLoadingTagTypes && !!tagTypesData,
              select: formatResult,
            }
          );
        })
      : []
  ) as UseQueryResult<{
    type: string;
    group: string;
    count: number;
  }>[];
  const isLoadingTagTypesArray = tagTypesArray.some((c) => c.isLoading || c.isIdle);
  // Sort the array when all requests are finished
  if (!isLoadingTagTypesArray) {
    tagTypesArray.sort(({ data: a }: any, { data: b }: any) => {
      // no configuration? alphabetic sorting
      if (!tagsTypesConfig) return a.type.localeCompare(b.type);

      // psychological tags
      const idxPsychologicalA = tagsTypesConfig?.psychological?.indexOf(a.type as string);
      const idxPsychologicalB = tagsTypesConfig?.psychological?.indexOf(b.type as string);
      if (
        idxPsychologicalA !== undefined &&
        idxPsychologicalB !== undefined &&
        (idxPsychologicalA !== -1 || idxPsychologicalB !== -1)
      ) {
        if (idxPsychologicalA === -1) return 1;
        if (idxPsychologicalB === -1) return -1;
        return idxPsychologicalA - idxPsychologicalB;
      }

      // custom tags
      const idxCustomA = tagsTypesConfig?.custom?.indexOf(a.type as string);
      const idxCustomB = tagsTypesConfig?.custom?.indexOf(b.type as string);
      if (idxCustomA !== undefined && idxCustomB !== undefined && (idxCustomA !== -1 || idxCustomB !== -1)) {
        if (idxCustomA === -1) return 1;
        if (idxCustomB === -1) return -1;
        return idxCustomA - idxCustomB;
      }

      // core tags
      const idxCoreA = tagsTypesConfig?.core?.indexOf(a.type as string);
      const idxCoreB = tagsTypesConfig?.core?.indexOf(b.type as string);
      if (idxCoreA !== undefined && idxCoreB !== undefined && (idxCoreA !== -1 || idxCoreB !== -1)) {
        if (idxCoreA === -1) return 1;
        if (idxCoreB === -1) return -1;
        return idxCoreA - idxCoreB;
      }

      return a.type.localeCompare(b.type);
    });
  }

  // Convert to array of groups of tag types.
  const groups = ['psychological', 'custom', 'core'].reduce((acc, group) => {
    const title = camelCaseToCapitalCase(group);
    const options = !isLoadingTagTypesArray
      ? tagTypesArray
          ?.filter(({ data: tagType }) => tagType?.group === group)
          .map(({ data: tagType }) => ({
            id: tagType?.type ?? '',
            label: `${camelCaseToCapitalCase((tagType?.type as string) ?? '')} (${tagType?.count ?? 0})`,
            value: tagType?.type,
          }))
      : [];
    // Don't show the group if there are no options
    if (options.length > 0) {
      acc.push({
        title,
        options,
      });
    }
    return acc;
  }, [] as { title: string; options: { id: string; label: string; value?: string }[] }[]);

  const initialValues = !isLoadingTagTypesArray
    ? userSelectedTagTypes
      ? userSelectedTagTypes.reduce((acc: { id: string; label: string }[], type: string) => {
          const tagTypes = tagTypesArray.find(({ data: tagType }: any) => tagType.type === type) as {
            data: { type: string; count: number };
          };
          if (tagTypes?.data) {
            acc.push({
              id: tagTypes.data.type,
              label: `${camelCaseToCapitalCase(tagTypes.data.type)} (${tagTypes.data.count})`,
            });
          }
          return acc;
        }, [])
      : tagTypesArray
          .filter(({ data: entry }: any) => entry.group === 'psychological' || entry.group === 'custom')
          .map(({ data: tagType }: any) => ({
            id: tagType.type,
            label: `${camelCaseToCapitalCase(tagType.type as string)} (${tagType.count as number})`,
          }))
    : [];

  const defaultInitialValues = useMemo(
    () =>
      !isLoadingTagTypesArray
        ? tagTypesArray
            .filter(({ data: entry }) => entry?.group === 'custom')
            .map(({ data: tagType }) => ({
              id: tagType?.type ?? '',
              label: `${camelCaseToCapitalCase(tagType?.type ?? '')} (${tagType?.count ?? ''})`,
            }))
        : [{ id: '', label: '' }],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingTagTypesArray, JSON.stringify(tagTypesArray)]
  );
  // END - CustomizeVisibleTags states/configs

  const onTabChange = (newTab: Tab) => {
    logEvent({
      component: 'Combinations',
      action: `Change detail tab to ${newTab.label}`,
      category: 'user_actions',
    });
  };
  const onVideoPlay = () => {
    logEvent({
      component: 'Combinations',
      action: `Play Video`,
      category: 'user_actions',
    });
  };
  const onVideoPause = () => {
    logEvent({
      component: 'Combinations',
      action: `Pause Video`,
      category: 'user_actions',
    });
  };
  const onTimelineClick = () => {
    logEvent({
      component: 'Combinations',
      action: `Timeline Click`,
      category: 'user_actions',
    });
  };
  const onPreviewNavigation = () => {
    logEvent({
      component: 'Combinations',
      action: `Preview Video Navigation`,
      category: 'user_actions',
    });
  };
  const onCopyNameToClipboardClick = async (name: string) => {
    logEvent({
      component: 'Combinations',
      action: `Copy Name to Clipboard`,
      category: 'user_actions',
    });
    await navigator.clipboard.writeText(name ?? SDK.messages.NOT_AVAILABLE);
    dispatch(
      AppActions.setAlertOpen({
        badgeTitle: 'Success',
        badgeLabel: 'Copied to the clipboard',
        message: `Text '${name ? shortenString(name, 30) : SDK.messages.NOT_AVAILABLE}' was copied`,
        color: 'Success',
      })
    );
  };

  return (
    <Styled.StyledRouteAnimator data-test="combinations-container">
      <Styled.HeaderContainer>
        <TopNavPageTitle
          title="Combinations"
          subtitle="Analyse the data about your tags and get the most performing combinations."
        />
        <FilterBar
          eventPrefix="Combinations"
          withActiveFilters={false}
          withAddFilterButton={false}
          withCustomFilter
          withIntroTagsFilter
          networkFilter={networkFilter}
          kpiFilter={kpiFilter}
          customFilter={
            <Button
              data-test="customize-visible-tags-button"
              variant="outlined"
              color="Gray"
              onClick={(e) => {
                setOpenCustomizeVisibleTags(true);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              Customize Visible Tags
            </Button>
          }
        />
      </Styled.HeaderContainer>
      <Styled.Container>
        <TagPerformanceExplorer
          data={selectedTagTypes}
          isLoading={isLoadingSelectedTagTypes}
          isSuccess={isSuccess}
          isPrevious={isPreviousData}
          data-test="overview-tag-performance"
        />
        <CombinationDetail
          onTabChange={onTabChange}
          onVideoPlay={onVideoPlay}
          onVideoPause={onVideoPause}
          onTimelineClick={onTimelineClick}
          onPreviewNavigation={onPreviewNavigation}
          onCopyNameToClipboardClick={onCopyNameToClipboardClick}
          metrics={tagFilters.length > 0 ? metrics : undefined}
          availableKpis={availableKpis}
          defaultKpi={{ title: SDK.kpiUtils.getDisplayName(chartKpi) }}
          onKpiChange={(kpiIndex) => {
            const newKpi = defaultProjectKpis[kpiIndex];
            if (chartKpi !== newKpi) {
              setChartKpi(newKpi);
              logEvent({
                component: 'Combination Details',
                action: 'Change KPI',
                category: 'user_actions',
                parameters: { value: defaultProjectKpis[kpiIndex] },
              });
            }
          }}
          performanceChartData={performanceChartData}
          performanceChartIsLoading={performanceChartIsLoading}
          kpiScores={aggregatedPerformance}
          videos={data}
          videosLoading={tagsAssetsLoading || tagsDescriptionLoading}
          onTagClick={({ value, type }) => {
            dispatch(
              FilterActions.changeTagFilters(tagFilters.filter((tag) => tag.type !== type && tag.value !== value))
            );
          }}
        />
      </Styled.Container>
      <PickAndSortDialog
        loading={isLoadingTagTypesArray || isLoadingTagTypes ? { messages: [] } : undefined}
        data={{ groups, initialValues }}
        onCancel={() => {
          // TODO: [DanielReis] Distinguish between cancel and close events
          logEvent({
            component: 'Customize Visible Tags',
            action: 'Cancel',
            category: 'user_actions',
          });
          setOpenCustomizeVisibleTags(false);
        }}
        onSave={async (values) => {
          logEvent({
            component: 'Customize Visible Tags',
            action: 'Save',
            category: 'user_actions',
          });
          await updateUiPreferencesMutation.mutateAsync(
            {
              uiPreferences: [
                {
                  op: 'add',
                  path: '/tagExplorer',
                  value: {
                    tagTypes: values,
                  },
                },
              ],
              projectId,
            },
            {
              onSuccess: (res) => {
                dispatch(ProjectActions.changeUiPreferences(res));
              },
              onSettled: () => {
                setOpenCustomizeVisibleTags(false);
              },
            }
          );
        }}
        open={openCustomizeVisibleTags}
        title="Customize visible tags"
        subtitle="Apply filters to update table data."
        initialSelectedOptions={defaultInitialValues}
        onReset={() => {
          logEvent({
            component: 'Customize Visible Tags',
            action: 'Reset',
            category: 'user_actions',
          });
        }}
      />
    </Styled.StyledRouteAnimator>
  );
};

export default Combinations;
