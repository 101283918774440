import { HeaderGroup, TableHeaderProps } from 'react-table';
import Colors from '../Colors';
import { ArrowDown, ArrowUp } from '../Icons/Base';
import Tooltip from '../Tooltip/Tooltip';

import * as Styled from './styles';

const TableHeadGroupContainer = (props: TableHeaderProps) => (
  <Styled.TableRow
    /* eslint-disable react/jsx-props-no-spreading */
    {...props}
    /* eslint-enable react/jsx-props-no-spreading */
    $style={{ ...props.style } as string}
  />
);

interface TableHeadProps<D extends object = object> {
  headerGroups: HeaderGroup<D>[];
}

const renderSortingArrow = <D extends object = object>(
  column: HeaderGroup<D>
) => {
  const dimension = 16;

  if (!column.canSort) {
    return null;
  }
  if (!column.isSorted) {
    return <ArrowDown dimension={dimension} color={Colors.Gray[400]} />;
  }
  return column.isSortedDesc ? (
    <ArrowDown dimension={dimension} color={Colors.Common.Black} />
  ) : (
    <ArrowUp dimension={dimension} color={Colors.Common.Black} />
  );
};

const TableHead = <D extends object = object>({
  headerGroups,
}: TableHeadProps<D>) => (
  <Styled.TableHead data-test="table-head">
    {headerGroups.map((headerGroup) => (
      /* eslint-disable react/jsx-props-no-spreading */
      <TableHeadGroupContainer {...headerGroup.getHeaderGroupProps()}>
        {
          /* eslint-enable react/jsx-props-no-spreading */
          headerGroup.headers.map((column, columnIndex) => {
            const isExpanderColumn = column.id === 'expander';
            return (
              <Styled.TableHeadData
                /* eslint-disable react/jsx-props-no-spreading */
                {...column.getHeaderProps(
                  column.getSortByToggleProps({ title: undefined })
                )}
                /* eslint-enable react/jsx-props-no-spreading */
                className={`cell ${isExpanderColumn ? 'expander' : ''}`}
                data-test={`table-header-cell-${columnIndex}`}
                $isFirstColumn={columnIndex === 0}
              >
                <Tooltip
                  content={column.tooltip}
                  {...(column.tooltipProps ?? {})}
                >
                  <Styled.TooltipContent>
                    {column.render('Header')}
                    {renderSortingArrow(column)}
                  </Styled.TooltipContent>
                </Tooltip>
              </Styled.TableHeadData>
            );
          })
        }
      </TableHeadGroupContainer>
    ))}
  </Styled.TableHead>
);

export default TableHead;
