import { CountryIconProps } from './types';

const CD = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3606)">
      <path
        d="M22.667 6.49842C22.1125 5.42409 21.3857 4.41572 20.4852 3.51516C19.5845 2.61455 18.5761 1.88775 17.5018 1.33331L9.04798 9.04922L1.33203 17.5031C1.88652 18.5774 2.61331 19.5858 3.51387 20.4864C4.41444 21.387 5.42286 22.1138 6.49714 22.6682L14.951 14.9523L22.667 6.49842Z"
        fill="#FFDA44"
      />
      <path
        d="M3.51455 20.4854C4.01795 20.9888 4.55509 21.4378 5.11848 21.8331L21.8334 5.11811C21.4381 4.55472 20.9892 4.01758 20.4858 3.51419C19.9824 3.0108 19.4453 2.56188 18.8819 2.16663L2.16699 18.8815C2.5622 19.4448 3.01112 19.982 3.51455 20.4854Z"
        fill="#D80027"
      />
      <path
        d="M3.51441 3.51437C-0.271541 7.30032 -0.998338 12.9862 1.33257 17.5023L17.5024 1.33257C12.9862 -0.998286 7.30032 -0.271396 3.51441 3.51437Z"
        fill="#338AF3"
      />
      <path
        d="M20.486 20.4851C24.2719 16.6992 24.9986 11.0133 22.6679 6.49713L6.49805 22.6669C11.0142 24.9978 16.7001 24.271 20.486 20.4851Z"
        fill="#338AF3"
      />
      <path
        d="M6.39547 3.65118L7.043 5.64417H9.1385L7.44322 6.87586L8.09075 8.86879L6.39547 7.63706L4.70009 8.86879L5.34772 6.87586L3.65234 5.64417H5.74784L6.39547 3.65118Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3606">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default CD;
