import * as SDK from '@replai-platform/sdk';
import { useQuery, UseQueryOptions } from 'react-query';
import * as provider from '../../api/provider';

const getReports = async (request: SDK.GetReportsRequest): Promise<SDK.GetReportsResponse> =>
  provider.getJson(`${SDK.BASE_URL()}/reports?${SDK.convertRequestToQueryParams(request)}`);

export default function useReports(
  requestParams: SDK.GetReportsRequest,
  options?: UseQueryOptions<SDK.GetReportsResponse>
) {
  return useQuery([`getReports`, requestParams], () => getReports(requestParams), options);
}
