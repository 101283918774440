import { Network, PromotedObjectType, getPromotedObjectString } from '@replai-platform/sdk';
import { StatusIcon } from '@replai-platform/ui-components';
import styled from 'styled-components';
import { OBJECT_TYPE_TO_ICON } from '../../../utils/constants';
import { networkIconType } from '../../NetworkIcon';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';

const PlatformsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const renderTooltipContent = (promotedObjectTypes: PromotedObjectType[] | null) =>
  promotedObjectTypes && promotedObjectTypes.some((p) => p) ? (
    <TooltipContainer>
      {promotedObjectTypes.map((p) => (
        <span key={p}>{getPromotedObjectString(p)}</span>
      ))}
    </TooltipContainer>
  ) : (
    ''
  );

export const getPerformanceIndicatorType = (
  possibilities: Network[],
  current: Network,
  isPositiveIndicator: boolean
) => {
  if ((possibilities || []).includes(current)) {
    return isPositiveIndicator ? 'success' : 'error';
  }

  return 'default';
};

export const PlatformDecorator: React.FC<{
  networks?: Network[];
  promotedObjectType?: PromotedObjectType;
  projectNetworks?: string[];
  isPositiveIndicator: boolean;
}> = ({ networks, promotedObjectType, projectNetworks, isPositiveIndicator }) => {
  if (!networks || !projectNetworks) {
    return null;
  }

  return (
    <PlatformsContainer>
      {networks.length &&
        projectNetworks.map((network: Network) => (
          <StatusIcon
            icon={networkIconType(network)}
            status={getPerformanceIndicatorType(networks, network, isPositiveIndicator)}
          />
        ))}
      {promotedObjectType && (
        <StatusIcon
          icon={OBJECT_TYPE_TO_ICON[promotedObjectType] ?? 'Website'}
          status={isPositiveIndicator ? 'success' : 'error'}
        />
      )}
    </PlatformsContainer>
  );
};

export type PlatformsAccessorType = {
  projectNetworks?: string[];
  networks?: Network[];
  promotedObjectType?: PromotedObjectType;
};

function getPlatformsColumn<D extends object = object>({
  columnId,
  accessor,
}: RequiredGeneratorParams<D, PlatformsAccessorType>): GeneratedColumn<D, PlatformsAccessorType> {
  return {
    id: columnId,
    accessor,
    width: 150,
    maxWidth: 150,
    minWidth: 150,
    Cell: (data: CellData<D, PlatformsAccessorType>) => {
      const { networks, promotedObjectType, projectNetworks } = data?.cell?.value ?? {};

      return (
        <PlatformDecorator
          networks={networks}
          promotedObjectType={promotedObjectType}
          projectNetworks={projectNetworks}
          isPositiveIndicator
        />
      );
    },
  };
}

export default getPlatformsColumn;
