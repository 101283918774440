import { constructRectangleElement } from '@replai/replai-excalidraw';
import { ExcalidrawElement, ExcalidrawImageElement, NonDeleted } from '@replai/replai-excalidraw/types/element/types';
import { LibraryItem } from '@replai/replai-excalidraw/types/types';
import { HTMLComponentOnCanvas } from './types';

export const GEN_FRAME_SQUARE_SIZE = 512;

// the base of the id to group different elements of the gen tech component
export const GEN_TECH_COMPONENT_BASE_GROUP_ID = 'gen_tech_component_';

export const GENERATIVE_FRAME_ELEMENT_TAG = 'generative_frame';
export const GENERATIVE_FRAME_BUTTONS_CONTAINER_TAG = 'buttons_container';
export const GENERATE_BUTTON_TAG = 'generate_button';
export const GET_INSPIRED_BUTTON_TAG = 'get_inspired_button';
export const RESET_BUTTON_TAG = 'reset_button';
export const PREVIOUS_VARIATION_BUTTON_TAG = 'previous_variation';
export const NEXT_VARIATION_BUTTON_TAG = 'next_variation';
export const INPUT_CONTAINER_TAG = 'input_container';
export const LOADING_ICON_ON_FRAME_TAG = 'loading_icon_on_frame';

export const constructLibraryItem = ({ fileId, width, height }: { fileId: string; width: number; height: number }) =>
  ({
    status: 'published',
    id: fileId,
    created: 1,
    elements: [
      {
        fileId,
        type: 'image',
        width,
        height,
        strokeColor: 'transparent',
        backgroundColor: 'transparent',
        opacity: 100,
        versionNonce: 2028982666,
        isDeleted: false,
        boundElements: null,
        updated: 1644914782403,
        link: null,
        status: 'saved',
        scale: [1, 1],
      } as ExcalidrawImageElement,
    ],
  } as LibraryItem);

export const genTechComponentExcalidrawElements: readonly NonDeleted<ExcalidrawElement>[] = [
  constructRectangleElement({
    propsToOverride: {
      id: GENERATIVE_FRAME_ELEMENT_TAG,
      x: 0,
      y: 70,
      width: GEN_FRAME_SQUARE_SIZE,
      originalWidth: GEN_FRAME_SQUARE_SIZE,
      height: GEN_FRAME_SQUARE_SIZE,
      originalHeight: GEN_FRAME_SQUARE_SIZE,
      nonCustomizable: true,
      strokeStyle: 'dashed',
    },
  }),
];

export const genTechComponentHTMLElements: { [name: string]: HTMLComponentOnCanvas } = {
  GENERATE_BUTTON: {
    componentTag: GENERATE_BUTTON_TAG,
    width: 112,
    height: 45,
    topOffset: -60,
    leftOffset: 400,
    fontSize: 14,
  },
  PROMPT_INPUT: {
    componentTag: INPUT_CONTAINER_TAG,
    width: 385,
    height: 45,
    topOffset: -60,
    leftOffset: 0,
    fontSize: 14,
  },
  LOADING_ICON: {
    componentTag: LOADING_ICON_ON_FRAME_TAG,
    width: 40,
    height: 40,
    topOffset: GEN_FRAME_SQUARE_SIZE / 2 - 40 / 2,
    leftOffset: GEN_FRAME_SQUARE_SIZE / 2 - 40 / 2,
    disablePointerEvents: true,
  },
  GET_INSPIRED_BUTTON: {
    componentTag: GET_INSPIRED_BUTTON_TAG,
    width: 95,
    height: 30,
    topOffset: -52.5,
    leftOffset: 282,
    fontSize: 12,
  },
  PREVIOUS_VARIATION_BUTTON: {
    componentTag: PREVIOUS_VARIATION_BUTTON_TAG,
    width: 40,
    height: 40,
    topOffset: (GEN_FRAME_SQUARE_SIZE - 40) / 2,
    leftOffset: -40 - 16,
  },
  NEXT_VARIATION_BUTTON: {
    componentTag: NEXT_VARIATION_BUTTON_TAG,
    width: 40,
    height: 40,
    topOffset: (GEN_FRAME_SQUARE_SIZE - 40) / 2,
    leftOffset: GEN_FRAME_SQUARE_SIZE + 16,
  },
};
