import { IconProps } from './types';

const Play = ({ color, dimension, fill, ...rest }: IconProps) => (
  <svg
    fill={fill ?? 'none'}
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M5 3L19 12L5 21V3Z"
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default Play;
