/* eslint-disable react/jsx-props-no-spreading */
import './TextImageToVideoModal.global.css';
import {
  Button,
  Colors,
  Icons,
  Modal,
  Tooltip,
  Typography,
  Notification,
  TextArea,
} from '@replai-platform/ui-components';
import { useState } from 'react';
import styled from 'styled-components';
import type { UploadProps } from 'antd';
import { message, Upload } from 'antd';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { UploadFile } from 'antd/es/upload';
import { RcFile } from 'antd/lib/upload';
import { logEvent } from '../../../analytics';
import {} from '../../../api/hooks/creativeProduction/useCreativeRequests';
import { RootState } from '../../../store/rootReducer';
import { CommonSnackbar } from '../../../utils/styles';
import {
  SUBMIT_TEXT_IMAGE_TO_VIDEO_PROMPT_QUERY_KEY,
  useSubmitTextImageToVideoPrompt,
} from '../../../api/hooks/creativeProduction/useSubmitTextImageToVideoPrompt';
import { GET_ALL_PRODUCTION_CONCEPTS_KEY } from '../../../api/hooks/creativeProduction/useGetAllProductionConcepts';

const StyledUpload = styled(Upload)<{ hideUpload: boolean }>`
  .ant-upload-select-picture-card {
    ${({ hideUpload }) => (hideUpload ? 'display: none;' : '')}
  }

  .ant-upload-list-picture-card-container {
    width: 10rem;
    height: 9.4rem;
    border-radius: 0.5rem;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 10rem;
    height: 9.4rem;
    border-radius: 0.5rem;
  }
`;

const ModalInnerContainer = styled.div`
  display: flex;
  height: 10rem;
  margin-top: 2rem;
`;

const UploadContainer = styled.div`
  width: 10rem;
  margin-top: 0.35rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const CreativeRequestSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 4;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: right;
  margin-left: 1rem;
`;

const UploadIcon = Icons.getBaseIcon('Image');

export const NOTIFICATION_MESSAGE = {
  SUBMITTED_CREATIVE_REQUEST_MESSAGE: 'Your creative request was submitted',
  SUBMITTED_CREATIVE_REQUEST_DETAILS: 'Request list should be updated soon',
  SUBMITTED_CREATIVE_REQUEST_ERROR_MESSAGE: 'Creative request failed while submitting',
  SUBMITTED_CREATIVE_REQUEST_ERROR_DETAILS: 'Try again or contact your account manager',
};

const TextImageToVideoModal = ({
  modalOpen,
  setModalOpen,
  baseAssetId,
  title,
}: {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  baseAssetId?: string;
  title?: string;
}) => {
  const [promptImageBase64, setPromptImageBase64] = useState<string | null>(null);
  const [promptImageFile, setPromptImageFile] = useState<UploadFile | null>(null);

  const projectId = useSelector((state: RootState) => state.project.id);

  const [isSubmittingCreativeRequest, setIsSubmittingCreativeRequest] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>('');
  const [notificationStatus, setNotificationStatus] = useState<{
    open: boolean;
    successful?: boolean;
    info: { message: string; details: string };
  }>({
    open: false,
    info: { message: '', details: '' },
  });
  const submitProductionCreativeRequest = useSubmitTextImageToVideoPrompt(projectId);

  const queryClient = useQueryClient();

  const isValidInput = () => prompt !== '' || !!promptImageBase64;

  const clearPrompt = () => {
    setPrompt('');
  };

  const submitRequest = () => {
    setIsSubmittingCreativeRequest(true);
    logEvent({
      component: 'Creative Production Requests table',
      action: 'Submit Request',
      category: 'user_actions',
      parameters: { prompt },
    });
    submitProductionCreativeRequest.mutate(
      {
        prompt,
        baseAssetId,
        imagePrompt:
          promptImageBase64 && promptImageFile?.name
            ? { base64: promptImageBase64, fileName: promptImageFile.name }
            : undefined,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: GET_ALL_PRODUCTION_CONCEPTS_KEY });
          await queryClient.invalidateQueries(SUBMIT_TEXT_IMAGE_TO_VIDEO_PROMPT_QUERY_KEY, { refetchInactive: true });
          setNotificationStatus({
            open: true,
            successful: true,
            info: {
              message: NOTIFICATION_MESSAGE.SUBMITTED_CREATIVE_REQUEST_MESSAGE,
              details: NOTIFICATION_MESSAGE.SUBMITTED_CREATIVE_REQUEST_DETAILS,
            },
          });
        },
        onError: async () => {
          setNotificationStatus({
            open: true,
            successful: false,
            info: {
              message: NOTIFICATION_MESSAGE.SUBMITTED_CREATIVE_REQUEST_ERROR_MESSAGE,
              details: NOTIFICATION_MESSAGE.SUBMITTED_CREATIVE_REQUEST_ERROR_DETAILS,
            },
          });
        },
        onSettled: async () => {
          if (promptImageFile) {
            promptImageFile.status = 'removed';
          }
          setModalOpen(false);
          setIsSubmittingCreativeRequest(false);
          clearPrompt();
          setPromptImageFile(null);
          setPromptImageBase64(null);
        },
      }
    );
  };

  const uploadProps: UploadProps = {
    accept: 'image/*',
    name: 'file',
    defaultFileList: [],
    multiple: false,
    customRequest: (params) => {
      setPromptImageFile(params.file as RcFile);
      const reader = new FileReader();
      reader.readAsDataURL(params.file as Blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        setPromptImageBase64(base64data?.toString() ?? '');
        params?.onSuccess?.('File successfully uploaded');
      };
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        // eslint-disable-next-line no-void
        void message.success(`${info.file.name} file uploaded successfully.`);
        setPromptImageFile(info.file);
      } else if (status === 'error') {
        // eslint-disable-next-line no-void
        void message.error(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: { showRemoveIcon: true, showPreviewIcon: false },
    listType: 'picture-card',

    fileList: promptImageFile ? [promptImageFile] : [],
    onRemove: () => {
      setPromptImageFile(null);
      setPromptImageBase64(null);
    },
  };

  return (
    <div>
      <Modal
        className="replai-preferences-modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
        onClose={() => setModalOpen(false)}
        isOpen={modalOpen}
      >
        <Typography type="text-lg" fontWeight="bold">
          {title ?? 'Generate a video from text and images'}
        </Typography>
        <ModalInnerContainer>
          <UploadContainer>
            <StyledUpload {...uploadProps} hideUpload={!!promptImageFile}>
              <div>
                <UploadIcon color={Colors.Blue[600]} dimension={40} />
                <Typography type="text-sm">
                  Click to upload <br />
                  or drag an image
                </Typography>
              </div>
            </StyledUpload>
          </UploadContainer>
          <CreativeRequestSection>
            <Tooltip content='E.g.: "Gameplay of our game, narrated by our main character wearing sunglasses"'>
              <TextArea
                fullWidth
                placeholder="Write a prompt to turn your idea into a creative. The more details you provide, the more accurate the result will be. "
                onChange={(e) => setPrompt(e.target.value)}
                value={prompt}
                disabled={isSubmittingCreativeRequest}
              />
            </Tooltip>
          </CreativeRequestSection>
        </ModalInnerContainer>
        <ButtonContainer>
          <Button
            leadingIcon={isSubmittingCreativeRequest ? { name: 'LoadingCircle' } : { name: 'Sparkles' }}
            disabled={isSubmittingCreativeRequest || !isValidInput()}
            onClick={() => {
              submitRequest();
            }}
          >
            Generate new concept
          </Button>
        </ButtonContainer>
      </Modal>

      <CommonSnackbar
        open={notificationStatus.open}
        onClose={() => setNotificationStatus({ ...notificationStatus, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
      >
        <Notification
          badgeTitle={notificationStatus.successful ? 'Success' : 'Failure'}
          badgeLabel={notificationStatus.info?.message}
          message={notificationStatus.info?.details}
          color={notificationStatus.successful ? 'Success' : 'Error'}
        />
      </CommonSnackbar>
    </div>
  );
};

export default TextImageToVideoModal;
