import styled from 'styled-components';
import { motion } from 'framer-motion';
import Colors, { ColorTypes } from '../Colors';

export type LoadingBarsProps = {
  barSize?: BarSize;
  color?: ColorTypes;
};

const LoadingBarsWrapper = styled(motion.div)`
  display: block;
  transform: 'translate(0, 10%)';
`;
type BarSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const availableSizes = {
  sm: { width: 40, height: 36 },
  md: { width: 44, height: 40 },
  lg: { width: 48, height: 44 },
  xl: { width: 52, height: 48 },
  xxl: { width: 64, height: 60 },
};

const LoadingBars = ({
  barSize = 'md',
  color = 'Primary',
}: LoadingBarsProps) => {
  const { width, height } = availableSizes[barSize];

  const colorBase = Colors[color][300];
  return (
    <LoadingBarsWrapper data-test="loading-bars-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ margin: 'auto', display: 'block' }}
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <rect x="17.5" y="30" width="15" height="40" fill={colorBase} rx="3">
          <animate
            attributeName="y"
            repeatCount="indefinite"
            dur="1s"
            calcMode="spline"
            keyTimes="0;0.5;1"
            values="20;30;30"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.2s"
          />
          <animate
            attributeName="height"
            repeatCount="indefinite"
            dur="1s"
            calcMode="spline"
            keyTimes="0;0.5;1"
            values="60;40;40"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.2s"
          />
        </rect>
        <rect x="42.5" y="30" width="15" height="40" fill={colorBase} rx="3">
          <animate
            attributeName="y"
            repeatCount="indefinite"
            dur="1s"
            calcMode="spline"
            keyTimes="0;0.5;1"
            values="20;30;30"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.1s"
          />
          <animate
            attributeName="height"
            repeatCount="indefinite"
            dur="1s"
            calcMode="spline"
            keyTimes="0;0.5;1"
            values="60;40;40"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.1s"
          />
        </rect>
        <rect x="67.5" y="30" width="15" height="40" fill={colorBase} rx="3">
          <animate
            attributeName="y"
            repeatCount="indefinite"
            dur="1s"
            calcMode="spline"
            keyTimes="0;0.5;1"
            values="20;30;30"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          />
          <animate
            attributeName="height"
            repeatCount="indefinite"
            dur="1s"
            calcMode="spline"
            keyTimes="0;0.5;1"
            values="60;40;40"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          />
        </rect>
      </svg>
    </LoadingBarsWrapper>
  );
};

export default LoadingBars;
