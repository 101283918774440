import * as SDK from '@replai-platform/sdk';
import { useMutation } from 'react-query';
import * as provider from '../../api/provider';

const saveReportAsTemplate = async (
  request: SDK.SaveReportAsTemplateRequest
): Promise<SDK.SaveReportAsTemplateResponse> =>
  provider.getJson(`${SDK.BASE_URL()}/reports/templates?${SDK.convertRequestToQueryParams(request)}`, {
    method: 'POST',
    body: {},
  });

export default function useSaveReportAsTemplate() {
  return useMutation(['report-save-template'], (requestParams: SDK.SaveReportAsTemplateRequest) =>
    saveReportAsTemplate(requestParams)
  );
}
