import { IntegrationIconProps } from './types';

const Tor = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16Z"
      fill="#59316B"
    />
    <path
      d="M17.0331 5.64229L16.4619 7.96739C17.2711 6.32521 18.5562 5.0895 20.0319 4C18.9529 5.28452 17.9693 6.56916 17.3663 7.85369C18.3818 6.39022 19.7463 5.57729 21.2853 5.04069C19.2385 6.91062 17.6139 8.91715 16.3762 10.9334L15.3926 10.4944C15.567 8.88465 16.1604 7.23589 17.0331 5.64229Z"
      fill="#ABCD03"
    />
    <path
      d="M14.4073 10.067L16.2796 10.863C16.2796 11.3509 16.2409 12.839 16.5386 13.278C19.6525 17.3878 19.1286 25.6259 15.9079 25.8372C11.0033 25.8372 9.13281 22.4228 9.13281 19.2845C9.13281 16.4227 12.4805 14.5203 14.4798 12.8293C14.9875 12.374 14.8994 11.3678 14.4073 10.067Z"
      fill="#FFFCDB"
    />
    <path
      d="M16.2793 10.8343L16.9541 11.187C16.8906 11.6422 16.9858 12.6505 17.4299 12.9105C19.3975 14.1625 21.2537 15.5284 21.9837 16.8942C24.5858 21.7073 20.159 26.1625 16.3352 25.7397C18.4137 24.1624 19.0166 20.9267 18.2392 17.3982C17.9219 16.0161 17.4299 14.764 16.5572 13.3494C16.1791 12.655 16.3111 11.7938 16.2793 10.8343Z"
      fill="white"
    />
    <path
      d="M15.9552 10.7155L17.3514 10.9106C16.9389 12.309 18.1607 13.2845 18.5573 13.5123C19.4458 14.0164 20.3026 14.5367 20.9849 15.1708C22.2702 16.374 23 18.0651 23 19.8537C23 21.626 22.2066 23.3333 20.8739 24.4715C19.6204 25.5447 17.891 26 16.2091 26C15.1619 26 14.2258 25.9513 13.2104 25.6097C10.894 24.813 9.16445 22.7805 9.02167 20.3414C8.89469 18.4389 9.30723 16.9918 10.7512 15.4796C11.4968 14.6829 13.0042 13.7723 14.0356 13.0406C14.5434 12.6828 15.0828 11.6746 14.0514 9.77231L14.2577 9.60968L15.7862 10.6588L14.4958 10.1138C14.6068 10.2764 14.9083 11.0081 14.9719 11.2195C15.1147 11.8211 15.0512 12.4066 14.9242 12.6666C14.2738 13.87 13.1631 14.1951 12.354 14.878C10.9259 16.0812 9.37101 17.0406 9.54551 20.3414C9.62486 21.9674 10.8625 23.9511 12.7187 24.878C13.766 25.3984 14.9719 25.6097 16.1936 25.6747C17.2883 25.7235 19.3827 25.0568 20.5251 24.0812C21.7468 23.0407 22.4291 21.4634 22.4291 19.8537C22.4291 18.2276 21.7944 16.6829 20.6044 15.5934C19.9221 14.9593 18.7957 14.1951 18.0976 13.7885C17.3995 13.3821 16.5268 12.2439 16.8123 11.1545L15.9552 10.7155Z"
      fill="black"
    />
    <path
      d="M15.5277 13.122C15.3849 13.8701 15.2262 15.2196 14.5915 15.7237C14.3218 15.9187 14.052 16.114 13.7665 16.309C12.6241 17.1058 11.4816 17.8536 10.9581 19.7724C10.8471 20.179 10.9423 20.6179 11.0376 21.0244C11.3232 22.1951 12.1323 23.4634 12.767 24.2115C12.767 24.244 12.894 24.3253 12.894 24.3578C13.4176 24.992 13.5763 25.1709 15.5595 25.626L15.5119 25.8538C14.3219 25.5286 13.3382 25.236 12.7194 24.5041C12.7194 24.4879 12.6083 24.374 12.6083 24.374C11.9419 23.5934 11.1326 22.2927 10.8313 21.0732C10.7202 20.5853 10.6251 20.2115 10.7519 19.7073C11.2913 17.7236 12.4655 16.9431 13.6555 16.1138C13.9253 15.935 14.2425 15.7723 14.4964 15.5609C14.9882 15.1871 15.2578 14.0488 15.5277 13.122Z"
      fill="black"
    />
    <path
      d="M16.0335 16.2114C16.0494 17.0569 15.9688 17.4811 16.1751 18.0828C16.3019 18.4405 16.7306 18.9283 16.8576 19.3999C17.0321 20.0341 17.2222 20.7332 17.2061 21.156C17.2061 21.6439 17.1764 22.5541 16.9701 23.5297C16.8129 24.3359 16.4505 25.0277 15.8411 25.4198C15.2168 25.2881 14.4839 25.0632 14.0512 24.6831C13.2103 23.9352 12.4656 22.6845 12.3703 21.595C12.2911 20.7008 13.099 19.3819 14.2255 18.7153C15.1774 18.1462 15.3989 17.4976 15.6051 16.4569C15.3195 17.3675 15.0513 18.1308 14.1311 18.6186C12.7983 19.334 12.1144 20.535 12.1779 21.6732C12.273 23.1365 12.8449 24.1281 13.9713 24.925C14.4473 25.2664 15.3366 25.6273 15.8919 25.7249V25.6505C16.313 25.5696 16.8583 24.8608 17.1299 23.9018C17.3679 23.0401 17.4618 21.9371 17.4457 21.2379C17.4298 20.8314 17.2549 19.9511 16.9375 19.1543C16.763 18.7154 16.4949 18.2764 16.3203 17.9675C16.1301 17.6583 16.1287 16.9918 16.0335 16.2114Z"
      fill="black"
    />
    <path
      d="M15.9405 19.3998C15.9563 19.9689 16.1794 20.6981 16.2745 21.4462C16.354 21.9991 16.3195 22.5544 16.3036 23.0423C16.2879 23.607 16.1043 24.6189 15.8534 25.1109C15.6168 24.9997 15.5243 24.8729 15.3705 24.6682C15.1802 24.3917 15.0509 24.1153 14.924 23.7901C14.8288 23.5462 14.7176 23.267 14.6698 22.9418C14.6064 22.4541 14.6242 21.691 15.1636 20.9104C15.5761 20.2925 15.6707 20.2455 15.8134 19.5301C15.6229 20.1642 15.4809 20.2288 15.0365 20.7653C14.5447 21.3507 14.4629 22.213 14.4629 22.9121C14.4629 23.2048 14.5768 23.5297 14.688 23.8385C14.8148 24.1638 14.9237 24.4876 15.0982 24.7314C15.3606 25.1268 15.6963 25.3516 15.8606 25.3937C15.8617 25.3939 15.8632 25.3935 15.8643 25.3937C15.8678 25.3945 15.8718 25.3967 15.8751 25.3974V25.3788C16.183 25.0271 16.3683 24.6776 16.4306 24.3259C16.51 23.9031 16.5282 23.4794 16.5758 22.9753C16.6234 22.5525 16.5889 21.9831 16.4778 21.3978C16.3192 20.6661 16.0516 19.92 15.9405 19.3998L15.9405 19.3998Z"
      fill="black"
    />
    <path
      d="M15.9871 12.4391C16.003 13.2846 16.0664 14.862 16.2886 15.4798C16.352 15.6911 16.939 16.618 17.3515 17.7399C17.6371 18.5204 17.7006 19.2358 17.7482 19.4472C17.9386 20.374 17.7006 21.935 17.3832 23.4147C17.2246 24.2114 16.6851 25.2033 16.0663 25.5936L15.9395 25.8212C16.2886 25.8049 17.1452 24.9432 17.4467 23.87C17.9545 22.0488 18.1607 21.2033 17.9228 19.187C17.8911 18.9918 17.8118 18.3253 17.5103 17.6097C17.066 16.5202 16.4313 15.4797 16.3521 15.2682C16.2092 14.9269 16.0188 13.4472 15.9871 12.4391Z"
      fill="black"
    />
    <path
      d="M16.2845 11.2827C16.2375 12.1516 16.2249 12.4716 16.3834 13.1057C16.5579 13.8049 17.4466 14.813 17.8115 15.9675C18.5096 18.1789 18.3351 21.0732 17.8273 23.3333C17.637 24.1299 16.7324 25.2846 15.8281 25.6584L16.4945 25.821C16.8594 25.8047 17.7955 24.9105 18.1605 23.8861C18.7474 22.2763 18.8586 20.3577 18.6205 18.3414C18.6046 18.1463 18.2872 16.4065 17.9859 15.6748C17.5574 14.5854 16.7958 13.6097 16.7165 13.3985C16.5739 13.0407 16.2604 12.2979 16.2845 11.2827Z"
      fill="black"
    />
    <rect
      x="15.7793"
      y="10.6712"
      width="0.492005"
      height="15.0036"
      fill="black"
    />
  </svg>
);
export default Tor;
