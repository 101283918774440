import { camelCaseToCapitalCase, TreeSelectDropDown } from '@replai-platform/ui-components';
import * as SDK from '@replai-platform/sdk';
import { useMemo } from 'react';
import { capitalize } from '@material-ui/core';

type DropdownOption = SDK.MarketApp & { selected: boolean };

export interface MarketAppsFilterProps {
  options: DropdownOption[];
  onChange: (params: string[]) => void;
  loading: boolean;
  onClearClick: () => void;
}

function serializeEntry(entry: DropdownOption) {
  return JSON.stringify({
    id: entry.id,
    name: entry.name,
    genreName: entry.genreName,
  });
}

const MarketAppsFilter: React.VFC<MarketAppsFilterProps> = ({ options, onChange, loading, onClearClick }) => {
  const { data, selected } = useMemo(
    () => ({
      data: options
        .reduce((acc, curr) => {
          const idx = acc.findIndex(({ genreName }) => genreName === curr.genreName);
          const element = {
            key: serializeEntry(curr),
            title: curr.name ? capitalize(curr.name) : SDK.messages.NOT_AVAILABLE,
          };
          if (idx >= 0) {
            acc[idx].children.push(element);
          } else {
            acc.push({ genreName: curr.genreName || SDK.messages.NOT_AVAILABLE, children: [element] });
          }
          return acc;
        }, [] as { genreName: string; children: { key: string; title: string }[] }[])
        .map((opt) => ({
          key: JSON.stringify(opt.genreName),
          title: camelCaseToCapitalCase(opt.genreName || ''),
          children: opt.children,
        })),
      selected: options.filter((opt) => opt.selected).map((opt) => serializeEntry(opt)),
    }),
    [options]
  );

  const chipLabel = useMemo(() => {
    const selectedAppsLength = options.filter((option) => option.selected).length;

    switch (selectedAppsLength) {
      case 0:
        return 'Select Market Apps';
      case 1:
        return `${selectedAppsLength} app selected`;
      default:
        return `${selectedAppsLength} apps selected`;
    }
  }, [options]);

  return (
    <TreeSelectDropDown
      data-test="filter-app-dropdown"
      data={data}
      label={chipLabel}
      loading={loading}
      searchable
      onChangeSelectedKeys={onChange}
      onClearClick={onClearClick}
      selectedKeys={selected}
    />
  );
};

export default MarketAppsFilter;
