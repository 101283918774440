export const API_UNVERSIONED_URL = 'https://internal-api.replai.io';

export const API_VERSION = '34';

export const API_BASE_URL = `${API_UNVERSIONED_URL}/v${API_VERSION}`;

export const DEV_API_BASE_URL = API_BASE_URL;

/**
 * Converts a request to a string of query params to be added after the '?' sign.
 *
 * This stringifies and URL-encodes each top-level field of the request object.
 */
export function convertRequestToQueryParams(request: object): string {
  return (
    Object.entries(request)
      // Prevent printing empty values on the request URL.
      .filter(
        ([, value]) =>
          value !== undefined &&
          value !== null &&
          (!Array.isArray(value) || value?.length > 0)
      )
      .map(
        ([key, value]) => `${key}=${encodeURIComponent(JSON.stringify(value))}`
      )
      .join('&') || ''
  );
}
