import { CommonTagIconProps } from '../types';

const CutFrequencyLow = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.226 9.82411C10.599 8.25911 9.85103 7.05811 8.70203 7.05811C7.48303 7.05811 6.71503 8.4121 6.06503 10.1171C5.63403 11.2461 4.55603 12.0001 3.34703 12.0001H1.95703"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.043 12H20.65C19.441 12 18.364 12.753 17.933 13.883C17.282 15.589 16.514 16.943 15.294 16.943C14.205 16.943 13.476 15.865 12.869 14.42"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default CutFrequencyLow;
