import { CountryIconProps } from './types';

const KN = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37257 18.6274 0 12 0C5.37257 0 0 5.37257 0 12C0 18.6274 5.37257 24 12 24Z"
      fill="#FFDA44"
    />
    <path
      d="M3.51539 20.4852C4.01878 20.9885 4.55593 21.4375 5.11932 21.8327L21.8335 5.11856C21.4383 4.55512 20.9893 4.01802 20.4859 3.51463C19.9825 3.01124 19.4454 2.56241 18.882 2.16711L2.16797 18.8813C2.56313 19.4447 3.012 19.9818 3.51539 20.4852Z"
      fill="black"
    />
    <path
      d="M3.51426 3.51475C-0.271509 7.30053 -0.998308 12.9861 1.33251 17.5021L17.5016 1.33309C12.9856 -0.997679 7.2999 -0.27088 3.51426 3.51475Z"
      fill="#6DA544"
    />
    <path
      d="M20.4855 20.4847C24.2712 16.699 24.998 11.0134 22.6673 6.49731L6.49805 22.6665C11.0141 24.9972 16.6997 24.2705 20.4855 20.4847Z"
      fill="#D80027"
    />
    <path
      d="M7.62971 14.1578L8.74993 14.7286L9.63896 13.8396L9.44228 15.0813L10.5624 15.6521L9.32068 15.8488L9.12399 17.0905L8.55324 15.9703L7.31152 16.167L8.20051 15.278L7.62971 14.1578Z"
      fill="#F0F0F0"
    />
    <path
      d="M14.1561 7.63057L15.2763 8.20136L16.1653 7.31238L15.9686 8.55405L17.0888 9.1249L15.8471 9.32158L15.6504 10.5633L15.0796 9.44313L13.8379 9.63977L14.7269 8.75079L14.1561 7.63057Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default KN;
