import { Columns } from './enums';

const shiftColumns = (columns: Columns[], mainColumns: Columns[]) => {
  mainColumns.reverse().forEach((o) => {
    const index = Object.values(columns).indexOf(o);
    if (index > 0) columns.unshift(columns.splice(index, 1)[0]);
  });
  return columns;
};

export default shiftColumns;
