import { useState } from 'react';
import styled from 'styled-components';
import { Colors, Button, Typography, Input, FeaturedIcons, Icons } from '@replai-platform/ui-components';
import { isValidEmail } from '../../utils';

const MIN_PASSWORD_LENGTH = 8;

type SetPasswordProps = {
  requestCode: (email: string) => Promise<void>;
  setPassword: (args: { password: string; email?: string; verificationCode?: string }) => Promise<void>;
  goToView: (view: string) => void;
  initialStep: 'request-code' | 'set-password';
};

const SetPasswordContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;
  margin-top: 1.125rem;
`;

const GoBackButton = styled(Button)`
  align-self: center;
`;

const Content = styled.div`
  width: 21rem;
  margin-top: 7rem;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
`;

const initialFormValues: {
  email: string;
  verificationCode: string;
  password: string;
  confirmPassword: string;
  form: string;
} = {
  email: '',
  verificationCode: '',
  password: '',
  confirmPassword: '',
  form: '',
};

const initialErrorMessages = { ...initialFormValues, form: '' };

const SetPassword = ({ requestCode, setPassword, goToView, initialStep }: SetPasswordProps) => {
  const [{ email, verificationCode, password, confirmPassword }, setFormValues] = useState(initialFormValues);
  const [step, setStep] = useState<'set-password' | 'request-code' | 'success'>(initialStep);
  const [resetPasswordDisabled, setResetPasswordDisabled] = useState(false);
  const [errorMessages, setErrorMessages] = useState(initialErrorMessages);
  const isFirstLogin = initialStep === 'set-password';

  const goToSignIn = () => goToView('sign-in');

  const isValidForm = () => {
    let isValid = true;

    if (password.length < MIN_PASSWORD_LENGTH) {
      setErrorMessages((m) => ({ ...m, password: `Must be at least ${MIN_PASSWORD_LENGTH} characters.` }));
      isValid = false;
    }

    if (password !== confirmPassword) {
      setErrorMessages((m) => ({ ...m, confirmPassword: 'Passwords must match.' }));
      isValid = false;
    }

    if (!isFirstLogin && !verificationCode) {
      setErrorMessages((m) => ({ ...m, verificationCode: 'Please input verification code.' }));
      isValid = false;
    }

    return isValid;
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.name) {
      setErrorMessages(initialErrorMessages);
      setFormValues((v) => ({ ...v, [event.target.name]: event.target.value }));
    }
  };

  const doRequestCode = async () => {
    if (!isValidEmail(email)) {
      setErrorMessages((m) => ({ ...m, email: 'Email is empty or not valid.' }));
      setResetPasswordDisabled(false);
      return;
    }

    try {
      await requestCode(email);
      setStep('set-password');
    } catch (e) {
      if (e instanceof Error) {
        const { message = '' } = e ?? {};
        setErrorMessages((m) => ({ ...m, email: message ?? 'Request failed.' }));
      } else {
        setErrorMessages((m) => ({ ...m, email: 'Request failed.' }));
      }
    } finally {
      setResetPasswordDisabled(false);
    }
  };

  const doSetPassword = async () => {
    if (!isValidForm()) {
      setResetPasswordDisabled(false);
      return;
    }

    try {
      await setPassword({ email, verificationCode, password });
      setStep('success');
    } catch (e) {
      if (e instanceof Error) {
        const { message = '', name } = e ?? {};

        if (name === 'CodeMismatchException') {
          setErrorMessages((m) => ({ ...m, verificationCode: message }));
        } else {
          setErrorMessages((m) => ({ ...m, form: message ?? 'Request failed' }));
        }
      } else {
        setErrorMessages((m) => ({ ...m, form: 'Request failed' }));
      }
    } finally {
      setResetPasswordDisabled(false);
    }
  };

  const onResetPasswordClick = async () => {
    setErrorMessages(initialErrorMessages);
    setResetPasswordDisabled(true);

    if (step === 'set-password') {
      await doSetPassword();
    } else if (step === 'request-code') {
      await doRequestCode();
    }
  };

  const renderHeader = (icon: Icons.BaseIconTypes, title: string, subtitle: string) => (
    <Header>
      <FeaturedIcons icon={icon} variant="outline" size="xl" color={step === 'success' ? 'Success' : 'Primary'} />
      <HeaderText>
        <Typography type="display-sm" fontWeight="semi-bold">
          {title}
        </Typography>
        <Typography type="text-md" fontWeight="regular" color={Colors.Gray[500]}>
          {subtitle}
        </Typography>
      </HeaderText>
    </Header>
  );

  const renderRequestCodeForm = () => (
    <>
      {renderHeader('Key', 'Forgot your password?', 'We’ll send you the reset instructions.')}
      <Input
        type="email"
        label="Email"
        name="email"
        placeholder="Enter your email"
        value={email}
        onChange={onInputChange}
        hintText={errorMessages.email}
        error={!!errorMessages.email}
      />
    </>
  );

  const renderSetPasswordForm = () => (
    <>
      {renderHeader('Key', 'Set a new password', 'Your new password must be different from previously used passwords.')}
      <InputsContainer>
        {!isFirstLogin && (
          <Input
            label="Verification code"
            name="verificationCode"
            placeholder="Enter your verification code"
            value={verificationCode}
            onChange={onInputChange}
            hintText={errorMessages.verificationCode}
            error={!!errorMessages.verificationCode || !!errorMessages.form}
          />
        )}
        <Input
          type="password"
          label="Password"
          name="password"
          placeholder="••••••••"
          value={password}
          onChange={onInputChange}
          hintText={`Must be at least ${MIN_PASSWORD_LENGTH} characters.`}
          error={!!errorMessages.password || !!errorMessages.form}
        />
        <Input
          type="password"
          label="Confirm password"
          name="confirmPassword"
          placeholder="••••••••"
          value={confirmPassword}
          onChange={onInputChange}
          hintText={errorMessages.confirmPassword || errorMessages.form}
          error={!!errorMessages.confirmPassword || !!errorMessages.form}
        />
      </InputsContainer>
    </>
  );

  const renderStepView = () => {
    switch (step) {
      case 'request-code':
        return renderRequestCodeForm();
      case 'set-password':
        return renderSetPasswordForm();
      case 'success':
        return renderHeader(
          'CheckCircle',
          'Success!',
          'Your password has been successfully reset. Click below to continue to the log in.'
        );
      default:
        return null;
    }
  };

  return (
    <SetPasswordContainer>
      <Content>
        <FormContainer>
          {renderStepView()}
          <ButtonsContainer>
            <Button
              size="lg"
              onClick={step === 'success' ? goToSignIn : onResetPasswordClick}
              disabled={resetPasswordDisabled}
            >
              {step === 'success' ? 'Continue' : 'Reset password'}
            </Button>
            {step !== 'success' && (
              <GoBackButton
                size="md"
                variant="text"
                color="Gray"
                leadingIcon={{ name: 'ArrowLeft' }}
                onClick={goToSignIn}
              >
                Back to log in
              </GoBackButton>
            )}
          </ButtonsContainer>
        </FormContainer>
      </Content>
    </SetPasswordContainer>
  );
};

export default SetPassword;
