import { PaymentIconProps } from './types';

const Klarna = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="#FEB4C7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.0253 20.5062H5.18066V12H7.0253V20.5062ZM11.6306 12H9.82595C9.82595 13.5654 9.14591 15.0021 7.96024 15.9421L7.24528 16.5087L10.0155 20.5065H12.2932L9.74421 16.8279C10.9525 15.5546 11.6306 13.8415 11.6306 12ZM14.5728 20.5038H12.8307V12.0016H14.5728V20.5038ZM19.8361 14.6225V14.9987C19.366 14.6594 18.7987 14.4599 18.1867 14.4599C16.5672 14.4599 15.2544 15.8494 15.2544 17.5633C15.2544 19.2772 16.5672 20.6667 18.1867 20.6667C18.7987 20.6667 19.366 20.4672 19.8361 20.128V20.5038H21.5V14.6225H19.8361ZM19.831 17.5633C19.831 18.399 19.156 19.0766 18.3233 19.0766C17.4907 19.0766 16.8157 18.399 16.8157 17.5633C16.8157 16.7276 17.4907 16.0503 18.3233 16.0503C19.156 16.0503 19.831 16.7276 19.831 17.5633ZM37.3895 14.9987V14.6225H39.0533V20.5038H37.3895V20.128C36.9194 20.4672 36.3521 20.6667 35.7401 20.6667C34.1206 20.6667 32.8077 19.2772 32.8077 17.5633C32.8077 15.8494 34.1206 14.4599 35.7401 14.4599C36.3521 14.4599 36.9194 14.6594 37.3895 14.9987ZM35.8767 19.0766C36.7094 19.0766 37.3843 18.399 37.3843 17.5633C37.3843 16.7276 36.7094 16.0503 35.8767 16.0503C35.044 16.0503 34.3691 16.7276 34.3691 17.5633C34.3691 18.399 35.044 19.0766 35.8767 19.0766ZM39.7754 19.5229C39.7754 18.9125 40.2429 18.4178 40.8195 18.4178C41.3961 18.4178 41.8637 18.9125 41.8637 19.5229C41.8637 20.133 41.3961 20.6278 40.8195 20.6278C40.2429 20.6278 39.7754 20.133 39.7754 19.5229ZM29.8511 14.4643C29.1864 14.4643 28.5574 14.6827 28.1368 15.2852V14.6229H26.4802V20.5038H28.1572V17.4132C28.1572 16.5188 28.7239 16.0809 29.4062 16.0809C30.1374 16.0809 30.5578 16.5432 30.5578 17.401V20.5038H32.2197V16.7638C32.2197 15.3951 31.1915 14.4643 29.8511 14.4643ZM24.0738 14.6226V15.3886C24.4074 14.9291 25.0289 14.623 25.7045 14.623V16.3343L25.6953 16.3339C25.6918 16.3338 25.6883 16.3336 25.6848 16.3336C25.0264 16.3336 24.0776 16.8315 24.0776 17.758V20.5039H22.3703V14.6226H24.0738Z"
      fill="#17120F"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default Klarna;
