import { Row } from 'react-table';
import TableCell from './TableCell';
import { CellLink } from './types/custom-types';
import LoadingBars from '../LoadingBars/LoadingBars';

/* eslint-disable react/jsx-props-no-spreading */
const TableRowContainer = (props: any) => <tr {...props} />;
/* eslint-enable react/jsx-props-no-spreading */

type TableRowProps<D extends object = object> = {
  cellLink?: CellLink<D>;
  row: Row<D>;
  rowProps: (row: Row<D>) => any;
  rowIndex: number;
  prepareRow: (row: Row<D>) => any;
  onClickExpand?: (row: Row<any>) => void;
};

export default function TableRow<D extends object = object>({
  cellLink,
  row,
  rowProps,
  rowIndex,
  prepareRow,
  onClickExpand,
}: TableRowProps<D>) {
  prepareRow(row);
  const loading = row.cells.filter((c) => !c.value).length === row.cells.length;

  const tableRowElement = (
    <TableRowContainer
      /* eslint-disable react/jsx-props-no-spreading */
      /* eslint-disable @typescript-eslint/no-unsafe-argument */
      {...row.getRowProps({
        ...rowProps(row),
      })}
      /* eslint-enable @typescript-eslint/no-unsafe-argument */
      /* eslint-enable react/jsx-props-no-spreading */
      className={[
        'tableRow',
        row.depth === 0 ? 'tableMainRow' : 'tableSubRow',
        ...(row.index % 2 === 0 ? [] : ['tableRowOdd']),
      ].join(' ')}
      data-test={`table-row-${rowIndex}`}
    >
      {loading && <LoadingBars />}
      {!loading &&
        row.cells.map((cell, cellIndex) => (
          /* eslint-disable react/no-array-index-key */
          <TableCell
            key={`table-cell-${cellIndex}`}
            cell={cell}
            cellIndex={cellIndex}
            link={cellLink}
            rowDepth={row.depth}
            onClickExpand={(rowInfo) => onClickExpand?.(rowInfo)}
          />
          /* eslint-enable react/no-array-index-key */
        ))}
    </TableRowContainer>
  );

  return tableRowElement;
}
