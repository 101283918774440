/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { messages } from '@replai-platform/sdk';
import * as SDK from '@replai-platform/sdk';
import { MultiSelectDropDownOption } from '@replai-platform/ui-components';
import { api } from '../../../api';
import { FiltersState } from '../../../store/filters';

export async function fetchCountries(projectId: SDK.UUID, currentFiltering: FiltersState) {
  return api.countries
    .getCountriesMetrics({
      projectIds: [projectId],
      metrics: [SDK.Metrics.SPEND] as SDK.Metrics[],
      orderBy: {
        condition: SDK.OrderByCondition.DESC_NULLS_LAST,
        value: SDK.Metrics.SPEND,
      },
      metricsFilters: {
        // Filter only by date range. This is important to guarantee good performance and to ensure results are
        // ordered by spend according to the data in the selected time range.
        ...(({ dateEndTimestamp, dateStartTimestamp }) => ({ dateEndTimestamp, dateStartTimestamp }))(
          api.filterConverter.getMetricsFilters(currentFiltering)
        ),
        countriesToConsider: [],
        countriesToExclude: ['ALL'],
      },
    })
    .then((values: SDK.GetCountriesMetricsResponse) => {
      const countries = values.countriesMetrics
        .sort(
          (a: { country: string; metrics: SDK.Metric }, b: { country: string; metrics: SDK.Metric }) =>
            (b.metrics.spend ?? 0) - (a.metrics.spend ?? 0)
        )
        .map(
          (entry) =>
            ({
              label: entry.country ? entry.country : messages.NOT_AVAILABLE,
              type: 'option',
              selected: currentFiltering.countries?.includes(entry.country),
              id: entry.country,
            } as MultiSelectDropDownOption)
        )
        .sort((a, b) => (a.selected === b.selected ? 0 : a.selected ? -1 : 1));

      let options: MultiSelectDropDownOption[] = [
        { id: 'All', label: 'All', isAllOption: true, type: 'option', selected: false },
        ...countries,
      ];

      if (options.filter((o) => o.selected)?.length === 0) {
        options = options.map((o) => ({ ...o, selected: true }));
      }

      if (options.filter((o) => o.selected)?.length === options.length - 1) {
        options[0].selected = true;
      }

      return options;
    });
}
