/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { messages } from '@replai-platform/sdk';
import { Badge, Skeleton, Tooltip, Typography } from '@replai-platform/ui-components';
import millify from 'millify';
import styled from 'styled-components';
import * as SDK from '@replai-platform/sdk';
import { capitalizeFirstLetter } from '../../../utils';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';
import { FooterContainer } from '../common/styles';

export type DefaultAccessorType = {
  defaultValue: string | number;
  entity: SDK.ObjectLevel;
  alignLeft?: boolean;
  capitalize?: boolean;
  loading?: boolean;
  tooltip?: string;
};

const Container = styled.div`
  position: relative;
  z-index: 1;
`;

const getAggregatedMetricKeyName = (columnId: string): string | undefined => {
  switch (columnId) {
    case 'installs':
      return 'totalInstalls';
    case 'impressions':
      return 'totalImpressions';
    case 'purchases':
      return 'totalPurchases';
    case 'earnings':
      return 'totalEarnings';
    case 'clicks':
      return 'totalClicks';
    default:
      return undefined;
  }
};

const DefaultDecorator: React.FC<{
  value: string | number;
  capitalize: boolean;
}> = ({ value, capitalize }) => {
  let renderValue: string;

  if (typeof value === 'number') {
    renderValue = millify(value);
  } else if (typeof value === 'string') {
    renderValue = capitalize ? capitalizeFirstLetter(value.toString()) : value;
  } else {
    renderValue = messages.NOT_AVAILABLE;
  }

  return <Badge color="Gray">{renderValue}</Badge>;
};

function getDefaultColumn({
  columnId,
  accessor,
  includeFooter,
}: RequiredGeneratorParams<DefaultAccessorType>): GeneratedColumn<DefaultAccessorType> {
  return {
    id: columnId,
    accessor,
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    Cell: (data: CellData<DefaultAccessorType>) => {
      const defaultValue = data?.cell?.value?.defaultValue;
      const loading = data?.cell?.value?.loading;
      const capitalize = data?.cell?.value?.capitalize;
      const tooltip = data?.cell?.value?.tooltip;
      const tooltipProps = data?.cell?.value?.tooltipProps ?? {};

      return loading ? (
        <Skeleton height={20} width={100} />
      ) : tooltip ? (
        <Tooltip
          placement="bottom"
          content={
            <Typography type="text-sm" fontWeight="medium" noMargin>
              {tooltip}
            </Typography>
          }
          color={tooltipProps.color}
          showArrow={tooltipProps.showArrow}
          disableShadow={tooltipProps.disableShadow}
          disablePadding={tooltipProps.disablePadding}
          enableMaxContent={tooltipProps.enableMaxContent}
        >
          <Container>
            <DefaultDecorator value={defaultValue} capitalize={capitalize} />
          </Container>
        </Tooltip>
      ) : (
        <DefaultDecorator value={defaultValue} capitalize={capitalize} />
      );
    },
    Footer: (info) => {
      const kpi = columnId.split('.')[1];
      const aggregatedMetricKey = getAggregatedMetricKeyName(kpi);
      const aggregatedMetric = aggregatedMetricKey ? info?.data?.[0]?.cluster?.metrics?.[aggregatedMetricKey] : null;
      return includeFooter && aggregatedMetric ? (
        <FooterContainer style={{ marginLeft: '0.75rem' }}>{millify(aggregatedMetric as number)}</FooterContainer>
      ) : null;
    },
  };
}

export default getDefaultColumn;
