import { IntegrationIconProps } from './types';

const Intercom = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="14" fill="#3290E8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 8H10C8.90972 7.9983 8.00192 8.91171 8 10V22C8 23.1044 8.89568 24 10 24H22C23.0903 24.0017 23.9981 23.0883 24 22V10C24 8.89556 23.1048 8 22 8ZM18.1329 10.9333C18.1329 10.6391 18.3721 10.4 18.6667 10.4C18.9612 10.4 19.2 10.6391 19.2 10.9333V18.0591C19.2 18.3533 18.9608 18.5924 18.6667 18.5924C18.3843 18.5927 18.134 18.3424 18.1333 18.0591L18.1329 10.9333ZM15.4662 10.6636C15.4662 10.3689 15.7054 10.1302 16 10.1302C16.2946 10.1302 16.5338 10.3689 16.5338 10.6636V18.3969C16.5338 18.6916 16.2946 18.9302 16 18.9302C15.7176 18.9305 15.4674 18.6802 15.4667 18.3969L15.4662 10.6636ZM12.8 10.9333C12.8 10.6391 13.0392 10.4 13.3333 10.4C13.6279 10.4 13.8667 10.6391 13.8667 10.9333V18.0591C13.8667 18.3533 13.6279 18.5924 13.3333 18.5924C13.051 18.5927 12.8007 18.3424 12.8 18.0591V10.9333ZM10.1333 12C10.1333 11.7053 10.3721 11.4667 10.6667 11.4667C10.9612 11.4667 11.2 11.7053 11.2 12V16.7969C11.2 17.0916 10.9608 17.3302 10.6667 17.3302C10.3759 17.3307 10.1338 17.0871 10.1333 16.7969V12ZM21.6806 20.1351C21.5987 20.2062 19.6226 21.8636 16 21.8636C12.3774 21.8636 10.4027 20.2058 10.3194 20.1347C10.099 19.9459 10.0729 19.6033 10.2618 19.3831C10.4459 19.1688 10.7972 19.1413 11.0122 19.3244C11.0432 19.3507 12.8075 20.7969 15.9996 20.7969C19.2315 20.7969 20.9688 19.34 20.9856 19.3258C21.2003 19.1413 21.5529 19.1682 21.7373 19.3831C21.9274 19.6029 21.9017 19.9464 21.6806 20.1351ZM21.8667 16.7973C21.8667 17.0916 21.6275 17.3307 21.3333 17.3307C21.0426 17.3312 20.8005 17.0876 20.8 16.7973V12C20.8 11.7053 21.0388 11.4667 21.3329 11.4667C21.6275 11.4667 21.8667 11.7053 21.8667 12V16.7973Z"
      fill="white"
    />
  </svg>
);
export default Intercom;
