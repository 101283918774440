import { CountryIconProps } from './types';

const BY = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3570)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FCFCFC"
      />
      <path
        d="M4.95669 11.1289L3.65234 8.76249L4.95669 6.43323L6.26103 8.76249L4.95669 11.1289Z"
        fill="#A2001D"
      />
      <path
        d="M2.34731 11.1289L1.04297 8.76249L2.34731 6.43323L3.65166 8.76249L2.34731 11.1289Z"
        fill="#A2001D"
      />
      <path
        d="M4.95669 17.5643L3.65234 15.1979L4.95669 12.8687L6.26103 15.1979L4.95669 17.5643Z"
        fill="#A2001D"
      />
      <path
        d="M2.34731 17.5643L1.04297 15.1979L2.34731 12.8687L3.65166 15.1979L2.34731 17.5643Z"
        fill="#A2001D"
      />
      <path
        d="M6.26041 2.32875L5.89113 1.66931C5.22878 2.06175 4.60755 2.51611 4.03516 3.02442L4.95606 4.69509L6.26041 2.32875Z"
        fill="#A2001D"
      />
      <path
        d="M4.95605 19.3042L4.02539 20.9662C4.59727 21.4752 5.21808 21.9302 5.88014 22.3235L6.26044 21.6335L4.95605 19.3042Z"
        fill="#A2001D"
      />
      <path
        d="M7.30371 15.1306V23.0461C8.74601 23.66 10.3329 24.0002 11.9994 24.0002C17.1589 24.0002 21.5575 20.7437 23.253 16.1741L7.30371 15.1306Z"
        fill="#6DA544"
      />
      <path
        d="M23.253 16.174C23.7354 14.874 23.9994 13.468 23.9994 12.0001C23.9994 5.37275 18.6268 0.00012207 11.9994 0.00012207C10.333 0.00012207 8.74601 0.340106 7.30371 0.953935V16.1741H23.253V16.174Z"
        fill="#A2001D"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3570">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default BY;
