import styled from 'styled-components';
import { default as DefaultModal } from '../Modal/Modal';
import { default as DefaultTypography } from '../Typography/Typography';

export const Modal = styled(DefaultModal)`
  &.replai-unsave-ant-modal.replai-ant-modal .ant-modal-content {
    width: fit-content;
    margin: 25%;
  }
`;

export const UnsaveContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const UnsaveDialogButton = styled.div`
  padding: 0 4.625rem;
`;

export const Typography = styled(DefaultTypography)<{ $margin?: string }>`
  margin: ${(props) => props.$margin};
`;
