import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .react-loading-skeleton {
    line-height: unset;
  }
`;

export const VideosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const VideoContainer = styled.div`
  height: 14.9375rem;
`;

export const TimestampsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  height: 1.125rem;
  overflow: hidden;

  > span {
    width: 100%;
  }
`;

export const TimelineContainer = styled.div`
  height: 1.25rem;
`;
