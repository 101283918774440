import { createGlobalStyle } from 'styled-components';

export const TreeDropDownStyle = createGlobalStyle`
  .replai-tree .ant-tree-checkbox-inner {
    border-radius: 4px;
  }

  .replai-tree .ant-tree-treenode {
    margin: 0.1rem 0;
  }
`;
