/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import * as Icons from '../Icons';
import * as Styled from './styles';
import { Colors, ColorTypes } from '..';
import '../common.module.css';

interface BadgeGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  children: string;
  color?: ColorTypes;
  colorScheme?: 'light-on-dark' | 'dark-on-light' | 'light-on-light';
  icon?: Icons.BaseIconTypes;
  size?: 'md' | 'lg';
  title: string;
  titleIcon?: Icons.BaseIconTypes;
  trailingTitle?: boolean;
}

const BadgeGroup = ({
  children,
  className,
  color = 'Primary',
  colorScheme = 'dark-on-light',
  icon,
  titleIcon,
  size = 'md',
  style,
  title,
  trailingTitle,
  ...props
}: BadgeGroupProps) => {
  const colorBase = Colors[color];

  const Icon = icon && Icons.getBaseIcon(icon);
  const TitleIcon = titleIcon && Icons.getBaseIcon(titleIcon);

  return (
    <Styled.Root
      className={`${size} ${trailingTitle ? 'trailingTitle' : ''} ${
        className || ''
      }`}
      $style={{ ...style } as string}
      $backgroundColor={
        colorScheme === 'light-on-dark' ? colorBase[100] : colorBase[50]
      }
      $color={colorBase[700]}
      {...props}
    >
      <Styled.TitleContainer
        style={{
          backgroundColor:
            colorScheme === 'light-on-dark'
              ? colorBase[50]
              : colorScheme === 'dark-on-light'
              ? colorBase[600]
              : 'white',
          color: colorScheme === 'dark-on-light' ? 'white' : undefined,
        }}
      >
        {TitleIcon && !trailingTitle && (
          <Styled.LeadingTitleIconWrapper>
            <TitleIcon
              color={
                colorScheme === 'dark-on-light'
                  ? Colors.Common.White
                  : colorBase[700]
              }
              dimension={12}
            />
          </Styled.LeadingTitleIconWrapper>
        )}
        {title}
        {Icon && trailingTitle && (
          <Styled.TrailingTitleIconWrapper>
            <Icon
              color={
                colorScheme === 'dark-on-light'
                  ? Colors.Common.White
                  : colorBase[700]
              }
              dimension={12}
            />
          </Styled.TrailingTitleIconWrapper>
        )}
      </Styled.TitleContainer>
      <Styled.BodyContainer>
        {children}
        {Icon && !trailingTitle && (
          <Styled.IconWrapper>
            <Icon color={colorBase[700]} dimension={16} />
          </Styled.IconWrapper>
        )}
      </Styled.BodyContainer>
    </Styled.Root>
  );
};

export default BadgeGroup;
export type { BadgeGroupProps };
