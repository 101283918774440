import * as SDK from '@replai-platform/sdk';
import { EmptyState, Pagination, TagCard } from '@replai-platform/ui-components';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMetricsFilters } from '../../../api/api/filterConverter';
import useTagsFrequency from '../../../api/hooks/tags/useTagsFrequency';
import { MarketActions } from '../../../store/market';
import { RootState } from '../../../store/rootReducer';
import * as Styled from './styles';
import TagCardsPage, { TagValue } from './TagCardsPage';

const PAGE_SIZE = 30;
const RATIO_THRESHOLD = 0.1;

const getPageValues = ({ tagValues, currentPage }: { tagValues: TagValue[]; currentPage: number }) =>
  (tagValues ?? []).slice((currentPage - 1) * PAGE_SIZE, (currentPage - 1) * PAGE_SIZE + PAGE_SIZE);

const getFrequencyDiff = ({
  tagId,
  marketFrequency = 0,
  tagsFrequency,
}: {
  tagId: SDK.UUID;
  marketFrequency?: number;
  tagsFrequency?: SDK.GetTagsFrequencyResponse;
}) => {
  const clientFrequency = (tagsFrequency || []).find((t) => t.tagId === tagId)?.frequency ?? 0;
  return clientFrequency - marketFrequency;
};

const renderTagsSkeleton = () =>
  Array.from(Array(18), (_value, index) => <TagCard variant="frequency" key={`skeleton-${index}`} thumbnailLoading />);

const TabContent = ({ isTagsLoading, tagValues }: { isTagsLoading: boolean; tagValues: TagValue[] }) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const onlyCompetitors = useSelector((state: RootState) => state.filters.onlyMarketCompetitors);
  const { marketNetworks, ageStartDate, ageEndDate, startDate, endDate } = useSelector(
    (state: RootState) => state.filters
  );
  const allowUntaggedMarketAssets = useSelector((state: RootState) => state.project.config.allowUntaggedMarketAssets);
  const maxFirstAppearanceSeconds =
    useSelector((state: RootState) => state.filters.maxFirstAppearanceSeconds) ?? undefined;
  const selectedTagType = useSelector((state: RootState) => state.market.tagGallery.selectedTagMenuOption.type);
  const currentPage = useSelector((state: RootState) => state.market.tagGallery.currentTagsSubPage);
  const currentTab = useSelector((state: RootState) => state.market.tagGallery.currentTagsTab);
  const dispatch = useDispatch();
  const isAllTab = currentTab === 'all';
  const includeInactiveFirstPartyAssetsInMarketYouFrequency = useSelector(
    (state: RootState) => state.project.config.includeInactiveFirstPartyAssetsInMarketYouFrequency
  );

  const tagValuesToConsiderForFrequency = useMemo(
    () => (isAllTab ? getPageValues({ tagValues, currentPage }) : tagValues),
    [isAllTab, tagValues, currentPage]
  );
  const tagsFrequencyParams = useMemo<SDK.GetTagsFrequencyRequest>(
    () => ({
      projectIds: [projectId],
      adTagsFilters: { maxFirstAppearanceSeconds },
      adsFilters: {
        networksToConsider: marketNetworks,
        launchDateMin: ageStartDate ?? undefined,
        launchDateMax: ageEndDate ?? undefined,
      },
      metricsFilters: {
        ...getMetricsFilters({ startDate, endDate }),
      },
      activeOnly: !includeInactiveFirstPartyAssetsInMarketYouFrequency,
      ...(isAllTab
        ? { tagIds: (tagValuesToConsiderForFrequency || [])?.map(({ tagId }) => tagId) }
        : {
            tagsFilters: {
              tagTypesToConsider: Array.from(new Set(tagValuesToConsiderForFrequency.map((t) => t.rawTagType))).map(
                (type) => ({ type })
              ),
            },
          }),
      marketTaggedOnly: allowUntaggedMarketAssets ? false : !!onlyCompetitors,
    }),
    [
      projectId,
      tagValuesToConsiderForFrequency,
      maxFirstAppearanceSeconds,
      isAllTab,
      marketNetworks,
      ageEndDate,
      ageStartDate,
      startDate,
      endDate,
      includeInactiveFirstPartyAssetsInMarketYouFrequency,
      onlyCompetitors,
      allowUntaggedMarketAssets,
    ]
  );
  const { data: tagsFrequency, isLoading: isFrequencyLoading } = useTagsFrequency(tagsFrequencyParams, {
    enabled: !!tagValuesToConsiderForFrequency?.length,
  });

  const filteredAndSortedTagValues = useMemo(() => {
    switch (currentTab) {
      case 'all':
        return tagValues;
      case 'growth-opportunities':
      case 'your-outliers':
        // growth-opportunities: you - market < -10%
        // your-outliers: you - market > 10%
        return tagValues
          .map((tag) => ({
            ...tag,
            diff: getFrequencyDiff({ tagId: tag.tagId, marketFrequency: tag.marketFrequency, tagsFrequency }),
          }))
          .filter(({ diff }) =>
            currentTab === 'growth-opportunities' ? diff < -RATIO_THRESHOLD : diff > RATIO_THRESHOLD
          )
          .sort((a, b) => Math.abs(b.diff) - Math.abs(a.diff));
      default:
        return tagValues;
    }
  }, [currentTab, tagValues, tagsFrequency]);

  const pageTagValues = useMemo(
    () => getPageValues({ tagValues: filteredAndSortedTagValues, currentPage }),
    [filteredAndSortedTagValues, currentPage]
  );
  const isLoading = isTagsLoading || (!isAllTab && isFrequencyLoading);

  return (
    <>
      <Styled.TagsContainer id="grid-container" data-test="market-tag-gallery-main-container">
        {isLoading ? (
          renderTagsSkeleton()
        ) : (
          <TagCardsPage pageTagValues={pageTagValues} selectedTagType={selectedTagType} tagsFrequency={tagsFrequency} />
        )}
      </Styled.TagsContainer>
      {!isLoading && pageTagValues.length === 0 ? <EmptyState icon="Tag" text="No tags available" /> : undefined}
      {isLoading || pageTagValues.length === 0 ? undefined : (
        <Pagination
          style={{ alignSelf: 'center' }}
          totalCount={isLoading ? 0 : filteredAndSortedTagValues.length}
          pageSize={PAGE_SIZE}
          currentPage={currentPage}
          onPageChange={(page) => {
            dispatch(MarketActions.changeCurrentTagsSubPage({ page, logEvent: true }));
            setTimeout(() => document.getElementById('grid-container')?.scrollIntoView({ behavior: 'smooth' }));
          }}
        />
      )}
    </>
  );
};

export default TabContent;
