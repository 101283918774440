/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styled from 'styled-components';

export const ImgBox = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
  }
`;

const LinkImage = ({ imageUrl, linkUrl }: { imageUrl: string; linkUrl: string }) => (
  <div>
    <img
      src={imageUrl}
      onClick={() => window.open(linkUrl, '_blank')}
      aria-label="clickable image"
      style={{
        position: 'relative',
        cursor: 'pointer',
        width: '100%',
        height: 'auto',
        borderRadius: '6px',
        zIndex: 1, // To show above the skeleton
      }}
    />
  </div>
);

export default LinkImage;
