import { CommonTagIconProps } from '../types';

const Music = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.34698 20.181C8.84699 20.181 10.063 18.965 10.063 17.465C10.063 15.965 8.84699 14.749 7.34698 14.749C5.84698 14.749 4.63098 15.965 4.63098 17.465C4.63098 18.965 5.84698 20.181 7.34698 20.181Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.653 17.0702C18.153 17.0702 19.369 15.8542 19.369 14.3542C19.369 12.8542 18.153 11.6382 16.653 11.6382C15.153 11.6382 13.937 12.8542 13.937 14.3542C13.937 15.8542 15.153 17.0702 16.653 17.0702Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.063 17.4651V7.30011C10.063 6.88211 10.329 6.51111 10.724 6.37611L18.078 3.87211C18.711 3.65611 19.368 4.12711 19.368 4.79611V14.3541"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default Music;
