import styled from 'styled-components';
import Colors from '../Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  background-color: ${Colors.Gray[25]};
  border: ${() => `1px solid ${Colors.Gray[200]}`};
  border-radius: 0.5rem;
  height: fit-content;
  position: sticky;
  top: 0;
`;
