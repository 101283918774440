import { PaymentIconProps } from './types';

const Visa = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H40C43.3137 0 46 2.68629 46 6V26C46 29.3137 43.3137 32 40 32H6C2.68629 32 0 29.3137 0 26V6Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3341 21.1443H11.5877L9.52833 13.0565C9.43058 12.6844 9.22304 12.3555 8.91774 12.2005C8.15584 11.811 7.31628 11.5009 6.40039 11.3446V11.0332H10.8245C11.4351 11.0332 11.893 11.5009 11.9694 12.0442L13.0379 17.8782L15.7829 11.0332H18.4529L14.3341 21.1443ZM19.9794 21.1443H17.3857L19.5214 11.0332H22.1151L19.9794 21.1443ZM25.4707 13.8343C25.547 13.2897 26.0049 12.9783 26.5392 12.9783C27.3788 12.9001 28.2933 13.0565 29.0565 13.4447L29.5145 11.2677C28.7512 10.9564 27.9117 10.8 27.1498 10.8C24.6324 10.8 22.8007 12.2005 22.8007 14.1443C22.8007 15.623 24.0982 16.3995 25.0141 16.8672C26.0049 17.3336 26.3865 17.645 26.3102 18.1114C26.3102 18.811 25.547 19.1224 24.7851 19.1224C23.8692 19.1224 22.9533 18.8892 22.1151 18.4996L21.6571 20.6779C22.573 21.0661 23.5639 21.2225 24.4798 21.2225C27.3024 21.2993 29.0565 19.9001 29.0565 17.8C29.0565 15.1553 25.4707 15.0003 25.4707 13.8343ZM38.1337 21.1443L36.0743 11.0332H33.8623C33.4043 11.0332 32.9464 11.3446 32.7937 11.811L28.9802 21.1443H31.6502L32.1831 19.6669H35.4637L35.769 21.1443H38.1337ZM34.2439 13.7561L35.0058 17.5668H32.8701L34.2439 13.7561Z"
      fill="#172B85"
    />
    <path
      d="M6 1H40V-1H6V1ZM45 6V26H47V6H45ZM40 31H6V33H40V31ZM1 26V6H-1V26H1ZM6 31C3.23858 31 1 28.7614 1 26H-1C-1 29.866 2.13401 33 6 33V31ZM45 26C45 28.7614 42.7614 31 40 31V33C43.866 33 47 29.866 47 26H45ZM40 1C42.7614 1 45 3.23858 45 6H47C47 2.13401 43.866 -1 40 -1V1ZM6 -1C2.13401 -1 -1 2.13401 -1 6H1C1 3.23858 3.23858 1 6 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default Visa;
