/* eslint-disable react/jsx-props-no-spreading */
import { FlatSocialIconProps } from './types';

const Liftoff = ({ color, dimension, ...props }: FlatSocialIconProps) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 200.000000 200.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
      fill={color ?? '#000000'}
      stroke="none"
    >
      <path
        d="M962 1965 c-76 -86 -173 -247 -226 -378 -65 -156 -86 -256 -95 -449
l-7 -173 -88 -194 c-49 -106 -89 -207 -90 -225 l-1 -31 135 0 135 0 7 335 c4
184 12 373 18 420 20 155 119 400 214 535 l34 47 36 -48 c55 -75 153 -279 181
-379 35 -124 45 -232 53 -595 l7 -315 135 0 135 0 -1 30 c-1 17 -42 120 -92
230 l-90 200 -5 165 c-8 230 -41 355 -154 571 -53 102 -176 279 -199 286 -6 2
-25 -12 -42 -32z"
      />
      <path
        d="M929 1417 c-71 -48 -71 -156 0 -204 84 -57 193 1 193 102 0 100 -109
158 -193 102z"
      />
      <path
        d="M816 494 c-9 -8 -16 -25 -16 -37 0 -40 30 -147 59 -217 31 -72 127
-230 141 -230 19 0 142 218 169 301 6 20 16 62 21 95 8 47 7 63 -5 81 -15 22
-19 23 -185 23 -140 0 -171 -3 -184 -16z"
      />
    </g>
  </svg>
);
export default Liftoff;
