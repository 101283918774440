import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const KEY = 'market-asset-thumbnail';
type Key = typeof KEY;

type Params = SDK.GetMarketAssetThumbnailRequest & SDK.GetMarketAssetThumbnailRequestPath;
type Result = SDK.GetMarketAssetThumbnailResponse | null;
type QueryKey = [Key, Params];

type QueryOptions = UseQueryOptions<Result, unknown, Result, QueryKey>;
type QueryResult = UseQueryResult<Result>;

const getMarketAssetThumbnail = async (requestParams: Params) => {
  const { assetId, size } = requestParams;
  return provider.getJson<object, Result>(
    `${SDK.BASE_URL()}/market/assets/${assetId}/thumbnail?${SDK.convertRequestToQueryParams({ size } as object)}`
  );
};

export default function useMarketAssetThumbnail(requestParams: Params, options?: QueryOptions): QueryResult {
  return useQuery(['market-asset-thumbnail', requestParams], () => getMarketAssetThumbnail(requestParams), options);
}
