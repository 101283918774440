import { CountryIconProps } from './types';

const PA = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M0 11.9998C0 18.6271 5.37262 23.9998 12 23.9998C12 19.4362 12 11.9998 12 11.9998C12 11.9998 4.17389 11.9998 0 11.9998Z"
      fill="#0052B4"
    />
    <path
      d="M12 0.000854492C18.6274 0.000854492 24 5.37348 24 12.0009C19.4365 12.0009 12 12.0009 12 12.0009C12 12.0009 12 4.17475 12 0.000854492Z"
      fill="#D80027"
    />
    <path
      d="M7.1441 4.1748L7.92115 6.56627H10.4357L8.40138 8.04424L9.17838 10.4357L7.1441 8.9577L5.10982 10.4357L5.88682 8.04424L3.85254 6.56627H6.36705L7.1441 4.1748Z"
      fill="#0052B4"
    />
    <path
      d="M16.857 13.5647L17.634 15.9562H20.1486L18.1142 17.4341L18.8913 19.8256L16.857 18.3476L14.8227 19.8256L15.5997 17.4341L13.5654 15.9562H16.0799L16.857 13.5647Z"
      fill="#D80027"
    />
  </svg>
);
export default PA;
