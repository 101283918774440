import * as Styled from './styles';
import TagMetrics, { TagMetricsProps } from './TagMetrics';
import Tabs, { TabsProps } from './Tabs';

export interface CombinationDetailProps
  extends Partial<TabsProps>,
    Partial<TagMetricsProps> {}

const CombinationDetail: React.VFC<CombinationDetailProps> = ({
  metrics,
  availableKpis,
  defaultKpi,
  onKpiChange,
  performanceChartData,
  performanceChartIsLoading,
  kpiScores,
  thumbnails = [],
  videos = [],
  videosLoading = false,
  onTabChange,
  onTagClick,
  onVideoPlay,
  onVideoPause,
  onTimelineClick,
  onPreviewNavigation,
  onCopyNameToClipboardClick,
}) => (
  <Styled.Container>
    {metrics ? <TagMetrics metrics={metrics} /> : null}
    <Tabs
      availableKpis={availableKpis}
      defaultKpi={defaultKpi}
      onKpiChange={onKpiChange}
      performanceChartData={performanceChartData}
      performanceChartIsLoading={performanceChartIsLoading}
      kpiScores={kpiScores}
      thumbnails={thumbnails}
      videos={videos}
      videosLoading={videosLoading}
      onTabChange={onTabChange}
      onTagClick={onTagClick}
      onVideoPlay={onVideoPlay}
      onVideoPause={onVideoPause}
      onTimelineClick={onTimelineClick}
      onPreviewNavigation={onPreviewNavigation}
      onCopyNameToClipboardClick={onCopyNameToClipboardClick}
    />
  </Styled.Container>
);

export default CombinationDetail;
