/* eslint-disable import/no-cycle */
import { combineReducers } from '@reduxjs/toolkit';
import { AppReducer } from './app';
import { AuthReducer } from './auth';
import { CombinationsReducer } from './combinations';
import { DashboardReducer } from './dashboard';
import { FilterReducer } from './filters';
import { InsightsReducer } from './insights';
import { ProjectReducer } from './project';
import { TagsReducer } from './tags';
import { TagViewReducer } from './tagView';
import { ConceptsReducer } from './concepts';
import { MarketReducer } from './market';
import { LibraryReducer } from './library';
import { ReportsReducer } from './reports';

const rootReducer = combineReducers({
  auth: AuthReducer,
  project: ProjectReducer,
  filters: FilterReducer,
  dashboard: DashboardReducer,
  insights: InsightsReducer,
  tags: TagsReducer,
  combinations: CombinationsReducer,
  concepts: ConceptsReducer,
  tagView: TagViewReducer,
  app: AppReducer,
  market: MarketReducer,
  library: LibraryReducer,
  reports: ReportsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
