import styled from 'styled-components';

export const ContentContainer = styled.div`
  margin-top: 1.5rem;
`;

export const TableWrapper = styled.div`
  width: calc(100% + 2rem);
  margin: 0 -1rem -1rem -1rem;
`;

export const FiltersGroup = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  gap: 12px;
`;
