export function formatDuration(totalSeconds: number): string {
  const secondsInt = Math.round(totalSeconds);
  const hours = Math.floor(secondsInt / 3600);
  const minutes = Math.floor(secondsInt / 60) % 60;
  const seconds = secondsInt % 60;

  return [hours, minutes, seconds].map((v) => (v < 10 ? `0${v}` : v)).join(':');
}

export default formatDuration;
