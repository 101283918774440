import Tooltip from '@material-ui/core/Tooltip';
import { Network } from '@replai-platform/sdk';
import { Icons } from '@replai-platform/ui-components';
import { ReactFragment } from 'react';
import styled from 'styled-components';
import { networkIconType } from '../NetworkIcon';

const PlatformsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;

  div {
    margin-right: 0.5rem;
  }
`;

type Props = {
  projectNetworks: Network[];
  tooltipContent?: string | ReactFragment;
};

const PlatformDecorator = ({ projectNetworks, tooltipContent }: Props) => {
  const render = () => (
    <PlatformsContainer>
      {projectNetworks?.map((network: Network) => {
        const Icon = Icons.getFlatIcon(networkIconType(network));
        return (
          <div>
            <Icon dimension={20} />
          </div>
        );
      })}
    </PlatformsContainer>
  );

  return tooltipContent ? <Tooltip title={tooltipContent}>{render()}</Tooltip> : render();
};

export default PlatformDecorator;
