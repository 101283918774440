import styled from 'styled-components';

export const RatingContainer = styled.div<{ isBadge: boolean }>`
  position: relative;
  z-index: 1;
  margin-left: ${({ isBadge }) => (isBadge ? '1.3rem' : 0)};
`;

export const Wrapper = styled.div`
  width: fit-content;
`;

export const TooltipContent = styled.div`
  width: 18rem;
  text-align: center;

  > p {
    margin-bottom: 0;
  }
`;

export const TooltipList = styled.ul`
  margin: 0;
  padding-left: 1.5rem;
  text-align: left;
`;
