import { CountryIconProps } from './types';

const GL = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3629)">
      <path
        d="M0 12C0 5.37262 5.37262 0 12 0C18.6274 0 24 5.37262 24 12C23.4783 12 12 13.5652 12 13.5652L0 12Z"
        fill="#F0F0F0"
      />
      <path
        d="M24 12.0004C24 18.6278 18.6274 24.0004 12 24.0004C5.37262 24.0004 0 18.6278 0 12.0004"
        fill="#D80027"
      />
      <path
        d="M8.34754 17.7397C11.5172 17.7397 14.0867 15.1702 14.0867 12.0006C14.0867 8.83091 11.5172 6.26141 8.34754 6.26141C5.1779 6.26141 2.6084 8.83091 2.6084 12.0006C2.6084 15.1702 5.1779 17.7397 8.34754 17.7397Z"
        fill="#F0F0F0"
      />
      <path
        d="M2.6084 12.0006C2.6084 8.83101 5.1779 6.26141 8.34754 6.26141C11.5172 6.26141 14.0867 8.83096 14.0867 12.0006"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3629">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default GL;
