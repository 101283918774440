import { CountryIconProps } from './types';

const EC = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3612)">
      <path
        d="M0 12C0 5.37263 5.37262 0 12 0C18.6274 0 24 5.37263 24 12L12 13.0435L0 12Z"
        fill="#FFDA44"
      />
      <path
        d="M1.60547 18.0009C3.68039 21.5876 7.55808 24.0009 11.9997 24.0009C16.4414 24.0009 20.319 21.5876 22.394 18.0009L11.9997 17.2183L1.60547 18.0009Z"
        fill="#D80027"
      />
      <path
        d="M22.3942 18.0002C23.4153 16.2352 24 14.1861 24 12.0002H0C0 14.1861 0.584719 16.2352 1.60575 18.0002H22.3942Z"
        fill="#0052B4"
      />
      <path
        d="M12.0001 16.1744C14.3052 16.1744 16.174 14.3057 16.174 12.0006C16.174 9.69537 14.3052 7.82666 12.0001 7.82666C9.69489 7.82666 7.82617 9.69537 7.82617 12.0006C7.82617 14.3057 9.69489 16.1744 12.0001 16.1744Z"
        fill="#FFDA44"
      />
      <path
        d="M12.0003 14.6092C10.5618 14.6092 9.3916 13.439 9.3916 12.0005V10.4353C9.3916 8.99689 10.5619 7.82666 12.0003 7.82666C13.4387 7.82666 14.609 8.99694 14.609 10.4353V12.0005C14.609 13.439 13.4387 14.6092 12.0003 14.6092Z"
        fill="#338AF3"
      />
      <path
        d="M16.1741 5.74002H13.0436C13.0436 5.16374 12.5764 4.69653 12.0001 4.69653C11.4238 4.69653 10.9566 5.16374 10.9566 5.74002H7.82617C7.82617 6.31635 8.32816 6.7835 8.90439 6.7835H8.86966C8.86966 7.35983 9.33681 7.82699 9.91314 7.82699C9.91314 8.40332 10.3803 8.87047 10.9566 8.87047H13.0436C13.6199 8.87047 14.0871 8.40332 14.0871 7.82699C14.6634 7.82699 15.1306 7.35983 15.1306 6.7835H15.0958C15.6721 6.7835 16.1741 6.3163 16.1741 5.74002Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3612">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default EC;
