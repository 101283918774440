import { CountryIconProps } from './types';

const KE = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3649)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.0007 -0.000732422C7.24316 -0.000732422 3.13241 2.76794 1.19141 6.78189H22.8099C20.869 2.76794 16.7582 -0.000732422 12.0007 -0.000732422Z"
        fill="black"
      />
      <path
        d="M12.0007 24C7.24316 24 3.13241 21.2314 1.19141 17.2174H22.8099C20.869 21.2314 16.7582 24 12.0007 24Z"
        fill="#496E2D"
      />
      <path
        d="M23.4333 8.34705H0.566672C0.199078 9.49872 0 10.7257 0 11.9992C0 13.2728 0.199078 14.4997 0.566672 15.6514H23.4333C23.8009 14.4997 24 13.2728 24 11.9992C24 10.7257 23.8009 9.49872 23.4333 8.34705Z"
        fill="#A2001D"
      />
      <path
        d="M15.7369 5.54508L14.3158 4.88916L12.0003 10.1322L9.68478 4.88916L8.26367 5.54508L11.1384 11.9997L8.26367 18.4544L9.68478 19.1103L12.0003 13.8673L14.3158 19.1103L15.7369 18.4544L12.8622 11.9997L15.7369 5.54508Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.9656 6.16321C12.4154 5.56242 12.0004 5.21704 12.0004 5.21704C12.0004 5.21704 11.5853 5.56237 11.0352 6.16321V17.836C11.5853 18.4369 12.0004 18.7823 12.0004 18.7823C12.0004 18.7823 12.4154 18.437 12.9656 17.8361V6.16321Z"
        fill="#F0F0F0"
      />
      <path
        d="M9.3916 8.40226V15.5981C9.97294 16.6282 10.6845 17.4725 11.2177 18.0323V5.96814C10.6845 6.52787 9.9729 7.37223 9.3916 8.40226Z"
        fill="#A2001D"
      />
      <path
        d="M14.6083 8.40287C14.0269 7.3728 13.3155 6.52848 12.7822 5.96875V18.0329C13.3154 17.4731 14.0269 16.6288 14.6083 15.5987V8.40287Z"
        fill="#A2001D"
      />
      <path
        d="M14.6084 8.40088V15.5968C15.1969 14.5538 15.6519 13.3206 15.6519 11.9989C15.6519 10.6771 15.1969 9.44385 14.6084 8.40088Z"
        fill="black"
      />
      <path
        d="M9.39114 8.40088V15.5968C8.80263 14.5538 8.34766 13.3206 8.34766 11.9989C8.34766 10.6771 8.80263 9.44385 9.39114 8.40088Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3649">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default KE;
