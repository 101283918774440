import { CountryIconProps } from './types';

const NU = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3683)">
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C0 12.0029 12 0.0013125 12 0C18.6274 0 24 5.37262 24 12Z"
        fill="#FFDA44"
      />
      <path
        d="M12.0001 5.7395V9.78683L7.82617 5.7395H12.0001Z"
        fill="#0052B4"
      />
      <path
        d="M6.26098 12.0002H9.78649L6.26098 7.30457L5.73926 10.9567L6.26098 12.0002Z"
        fill="#0052B4"
      />
      <path
        d="M11.9688 12.0007H12.0009C12.0009 11.9899 12.0009 11.9794 12.0009 11.9685C11.9902 11.9793 11.9795 11.99 11.9688 12.0007Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 6.26086C12 4.14937 12 2.76586 12 0H11.998C5.37145 0.001125 0 5.37323 0 12H6.26086V8.47448L9.78637 12H11.9679C11.9786 11.9893 11.9893 11.9786 12 11.9679C12 11.1594 12 10.4381 12 9.78647L8.47443 6.26086H12Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9989 10.5247L7.73539 6.26123H6.25977V6.26132L11.9988 12.0003H11.9989C11.9989 12.0003 11.9989 10.9828 11.9989 10.5247Z"
        fill="#D80027"
      />
      <path
        d="M6.07027 1.56494C5.60766 1.82838 5.16445 2.12196 4.74295 2.44244H5.23533L3.93398 3.3881L4.43095 4.91796L3.12961 3.97249L2.99114 4.07313C2.45081 4.68672 1.97175 5.35554 1.56445 6.07075V11.9997H4.69491V4.69549V4.69539H11.9993C11.9993 3.70807 11.9993 2.76747 11.9993 1.56494H6.07027Z"
        fill="#D80027"
      />
      <path
        d="M5.12601 2.16431C4.99659 2.25492 4.86899 2.34806 4.74332 2.44363L3.93449 3.38929L3.13012 3.97363L2.99048 4.07507C2.69582 4.40976 2.41949 4.76095 2.16309 5.12718C2.45535 5.26903 2.78343 5.34876 3.13012 5.34876C4.35477 5.34876 5.34749 4.35604 5.34749 3.13138C5.34759 2.7846 5.2679 2.45662 5.12601 2.16431Z"
        fill="#0052B4"
      />
      <path
        d="M2.99023 4.07382L3.12987 3.97238L4.43122 4.9179L3.9342 3.38804L5.23545 2.44238H4.74299C4.10577 2.92702 3.51856 3.47377 2.99023 4.07382Z"
        fill="#FFDA44"
      />
      <path
        d="M8.29451 2.0874L8.55354 2.88451H9.39167L8.71362 3.37721L8.97251 4.17432L8.29451 3.68167L7.61647 4.17432L7.87531 3.37721L7.19727 2.88451H8.03544L8.29451 2.0874Z"
        fill="#FFDA44"
      />
      <path
        d="M3.13041 7.30518L3.38944 8.10228H4.22756L3.54956 8.59504L3.80845 9.39215L3.13041 8.89944L2.45231 9.39215L2.7112 8.59504L2.0332 8.10228H2.87133L3.13041 7.30518Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3683">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default NU;
