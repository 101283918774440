import styled from 'styled-components';
import { default as DefaultBadge } from '../Badge/Badge';
import { default as DefaultTypography } from '../Typography/Typography';

export const Badge = styled(DefaultBadge)`
  border-radius: 4px;

  &&& {
    padding: 1px;
  }
`;

export const BadgeContainer = styled.span`
  display: inline-block;
  vertical-align: middle;
`;

export const Delta = styled(DefaultTypography)`
  display: inline-block;
  margin-bottom: 0;
  margin-left: 4px;
  padding: 0;
  vertical-align: middle;
`;

export const Metric = styled.div`
  display: block;
  min-width: 70px;
`;

export const Name = styled(DefaultTypography)`
  margin: 0;
`;

export const Value = styled.div`
  margin: 0;
`;

export const ValueText = styled(DefaultTypography)`
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 0;
  vertical-align: middle;
`;

export const MetricsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-content: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;
