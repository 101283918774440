import { CountryIconProps } from './types';

const NF = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3743)">
      <path
        d="M17.2165 1.19047C15.6389 0.427631 13.869 -0.000244141 11.9991 -0.000244141C10.1292 -0.000244141 8.35939 0.427631 6.78176 1.19047L5.73828 11.9998L6.78176 22.809C8.35939 23.5719 10.1292 23.9998 11.9991 23.9998C13.869 23.9998 15.6389 23.5719 17.2165 22.809L18.26 11.9998L17.2165 1.19047Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.78262 1.19122C2.76867 3.13213 0 7.24255 0 12.0001C0 16.7576 2.76867 20.868 6.78262 22.8089V1.19122Z"
        fill="#6DA544"
      />
      <path
        d="M17.2168 1.19122V22.8089C21.2307 20.868 23.9994 16.7576 23.9994 12.0001C23.9994 7.2426 21.2307 3.13213 17.2168 1.19122Z"
        fill="#6DA544"
      />
      <path
        d="M15.131 15.6516L12.0006 5.73859L8.87012 15.6516H11.2179V18.2603H12.7832V15.6516H15.131Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3743">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default NF;
