import styled from 'styled-components';

export const Root = styled.div`
  display: inline-block;
  width: max-content;
  height: max-content;

  &.xs {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }

  &.sm {
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
  }

  &.md {
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  }

  &.lg {
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  }

  &.xl {
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  }

  &.xxl {
    box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.25);
  }

  &.xxxl {
    box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.2);
  }
`;
