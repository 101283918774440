import { PaymentIconProps } from './types';

const giropay = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.24155 10.3237C7.43777 10.325 6.78745 10.9177 6.78589 11.6502V11.6502V20.4569V20.4569C6.7859 20.4626 6.78595 20.4684 6.78604 20.474C6.78595 20.4683 6.7859 20.4626 6.78589 20.4569M6.81592 20.7241C6.95242 21.3281 7.53823 21.7822 8.24155 21.7834H23.3871V21.7834H8.24155C7.53824 21.7822 6.95243 21.3281 6.81592 20.7241ZM23.3871 10.3237V10.3237H8.24155M8.24155 9.33331H37.2278C38.632 9.33353 39.7697 10.3701 39.7702 11.6502V20.4569C39.7697 21.7366 38.632 22.7732 37.2278 22.7737H8.24155C6.83724 22.7732 5.6997 21.7366 5.69922 20.4569V11.6502C5.6997 10.3701 6.83724 9.33353 8.24155 9.33331Z"
      fill="#0F3365"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.8653 19.0163H24.5938V14.3094H25.7665V14.9147H25.7807C26.035 14.4447 26.5721 14.2322 27.1515 14.2322C28.169 14.2322 28.7267 15.1144 28.7267 15.9255C28.7267 16.9172 28.1404 17.7414 27.0453 17.7414C26.6003 17.7414 26.1198 17.5935 25.8795 17.1943H25.8653V19.0163ZM26.6429 16.8528C27.123 16.8528 27.4128 16.486 27.4128 15.9514C27.4128 15.5069 27.1372 15.1207 26.6429 15.1207C26.1764 15.1207 25.8652 15.4943 25.8652 15.9964C25.8652 16.486 26.2188 16.8528 26.6429 16.8528ZM33.5993 19.0163C33.8609 19.068 34.1221 19.0938 34.3907 19.0938C35.4906 19.0938 35.7979 18.3658 36.1315 17.5757L36.1316 17.5755C36.1516 17.5281 36.1717 17.4804 36.1921 17.4327L37.5418 14.3094H36.2701L35.5139 16.4986H35.4998L34.7084 14.3094H33.3383L34.8284 17.7414C34.7371 18.0376 34.4968 18.2051 34.1863 18.2051C34.0092 18.2051 33.8537 18.1861 33.6844 18.1341L33.5993 19.0163ZM33.1253 17.7732C33.0689 17.5093 33.0545 17.2454 33.0545 16.9813V15.7321C33.0545 14.7083 32.2422 14.3415 31.3025 14.3415C30.7583 14.3415 30.285 14.412 29.8329 14.5797L29.8538 15.3652C30.2074 15.185 30.6171 15.1141 31.0266 15.1141C31.486 15.1141 31.8607 15.2364 31.8677 15.6937C31.7052 15.6677 31.4793 15.6482 31.2744 15.6482C30.596 15.6482 29.3738 15.771 29.3738 16.7947C29.3738 17.5225 30.0238 17.8509 30.7515 17.8509C31.2744 17.8509 31.6276 17.6638 31.9171 17.2454H31.9309C31.9309 17.3475 31.9384 17.4497 31.9459 17.553L31.9459 17.5531V17.5531C31.9512 17.6257 31.9566 17.699 31.9594 17.7732H33.1253ZM30.5605 16.7111C30.5605 16.3891 30.8995 16.2668 31.3306 16.2668C31.4658 16.2668 31.5973 16.2732 31.7203 16.2793C31.771 16.2817 31.8202 16.2841 31.8676 16.286C31.8676 16.6786 31.564 17.0784 31.0832 17.0784C30.7866 17.0784 30.5605 16.9426 30.5605 16.7111Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5419 17.6639H14.8136V14.3094H13.5419V17.6639ZM13.5419 13.762H14.8136V12.912H13.5419V13.762ZM17.9237 14.2322C18.0719 14.2322 18.2273 14.2515 18.3546 14.2705L18.2908 15.2364C18.1497 15.1979 18.0083 15.1979 17.8601 15.1979C17.2667 15.1979 16.9345 15.5907 16.9345 16.2477V17.6639H15.6629V14.3094H16.8216V14.9274H16.8355C17.0547 14.5026 17.3727 14.2322 17.9237 14.2322ZM22.6847 15.9901C22.6847 17.0977 21.7591 17.7414 20.5442 17.7414C19.3291 17.7414 18.4035 17.0977 18.4035 15.9901C18.4035 14.8759 19.3291 14.2322 20.5442 14.2322C21.7591 14.2322 22.6847 14.8759 22.6847 15.9901ZM19.7176 15.9901C19.7176 16.486 19.9577 16.93 20.5442 16.93C21.1305 16.93 21.3708 16.486 21.3708 15.9901C21.3708 15.4878 21.1305 15.0435 20.5442 15.0435C19.9577 15.0435 19.7176 15.4878 19.7176 15.9901ZM11.5126 14.3094H12.6644V17.2715C12.6644 18.6493 11.9297 19.0938 10.446 19.0938C9.83849 19.0938 9.33683 18.9776 9.04 18.8814L9.10374 17.9669C9.49222 18.1408 9.8172 18.2437 10.3471 18.2437C11.082 18.2437 11.4774 17.9283 11.4774 17.2715V17.0909H11.4631C11.1596 17.4841 10.7357 17.6639 10.2271 17.6639C9.21668 17.6639 8.58789 16.9691 8.58789 15.9771C8.58789 14.9789 9.10374 14.2322 10.2481 14.2322C10.792 14.2322 11.2302 14.5026 11.4987 14.9145H11.5126V14.3094ZM10.6227 15.1208C10.1493 15.1208 9.90203 15.5007 9.90203 15.9577C9.90203 16.4791 10.2412 16.7756 10.6227 16.7756C11.0747 16.7756 11.4352 16.4279 11.4352 15.9126C11.4352 15.5389 11.1948 15.1208 10.6227 15.1208Z"
      fill="#D8232A"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default giropay;
