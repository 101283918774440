import { IntegrationIconProps } from './types';

const Autodesk = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.4829 28.999L11.6717 4.04045C11.4855 3.61551 11.1869 3.06723 10.4142 3.06723C9.72361 3.06723 9.39469 3.39316 9.20898 3.74363C9.30498 3.4909 9.59562 2.99995 10.4381 2.99995H17.8997C18.5873 2.99995 18.9172 3.39484 19.0675 3.75637L30.0003 28.999H22.4829Z"
      fill="url(#paint0_linear_1334_819)"
    />
    <path
      d="M9.20863 3.74367L2.35935 19.6889C2.15077 20.1968 2 20.5 2 20.9031C2 21.6252 2.56266 22.3493 3.98221 22.3493H8.68212L14.1438 9.74829L11.6714 4.04048C11.4851 3.61555 11.1865 3.06726 10.4138 3.06726C9.72328 3.06726 9.39433 3.39321 9.20863 3.74367Z"
      fill="url(#paint1_linear_1334_819)"
    />
    <path
      d="M3.98224 22.3493L19.5761 22.3493L10.5266 27.8161C10.0855 28.0706 8.40787 29 7.23082 29C6.47205 29 6.07784 28.7209 5.69931 28.0727L2.24201 21.8228C2.00384 21.3749 2 21.2257 2 20.9031C2 21.6253 2.56269 22.3493 3.98224 22.3493Z"
      fill="url(#paint2_linear_1334_819)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_819"
        x1="14.0496"
        y1="3.48412"
        x2="16.3686"
        y2="9.14699"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#29A5E3" />
        <stop offset="0.515" stopColor="#356EB9" />
        <stop offset="1" stopColor="#394DA5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_819"
        x1="12.3865"
        y1="5.14647"
        x2="10.8085"
        y2="8.94685"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#236736" />
        <stop offset="0.493" stopColor="#6BA443" />
        <stop offset="1" stopColor="#86C146" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_819"
        x1="4.173"
        y1="25.0603"
        x2="7.4853"
        y2="24.3835"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2B7A77" />
        <stop offset="0.51" stopColor="#4EBAA9" />
        <stop offset="1" stopColor="#5CC2B1" />
      </linearGradient>
    </defs>
  </svg>
);
export default Autodesk;
