import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DashboardState = {
  shouldRefreshConceptsCache?: boolean;
};

const DashboardSlice = createSlice({
  name: 'Dashboard',
  initialState: {
    dateFilter: {},
    shouldRefreshConceptsCache: false,
  } as DashboardState,
  reducers: {
    changeShouldRefreshConceptsCache: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldRefreshConceptsCache = payload;
    },
  },
});

export const DashboardActions = DashboardSlice.actions;
export const DashboardReducer = DashboardSlice.reducer;
