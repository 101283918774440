import ScoreText from './ScoreText';
import { TierBadgeProps } from './types';

const Tier2 = ({ score, dimension }: TierBadgeProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimension}
    height={dimension}
    viewBox="0 0 64 64"
    fill="none"
  >
    <path
      d="M27.9996 2.30988C30.475 0.880542 33.525 0.880542 36.0004 2.30988L45.856 8.0007L55.7122 13.6905C58.1878 15.1196 59.7127 17.7609 59.7126 20.6193L59.712 32L59.7126 43.3807C59.7127 46.2391 58.1878 48.8804 55.7122 50.3095L45.856 55.9993L36.0004 61.6901C33.525 63.1195 30.475 63.1195 27.9996 61.6901L18.144 55.9993L8.28777 50.3095C5.81224 48.8804 4.28728 46.2391 4.28742 43.3807L4.288 32L4.28742 20.6193C4.28728 17.7609 5.81224 15.1196 8.28777 13.6905L18.144 8.0007L27.9996 2.30988Z"
      fill="white"
    />
    <path
      d="M27.9996 6.30988C30.475 4.88054 33.525 4.88054 36.0004 6.30988L44.124 11.0006L52.2481 15.6905C54.7237 17.1196 56.2486 19.7609 56.2485 22.6193L56.248 32L56.2485 41.3807C56.2486 44.2391 54.7237 46.8804 52.2481 48.3095L44.124 52.9994L36.0004 57.6901C33.525 59.1195 30.475 59.1195 27.9996 57.6901L19.876 52.9994L11.7519 48.3095C9.27634 46.8804 7.75138 44.2391 7.75152 41.3807L7.752 32L7.75152 22.6193C7.75138 19.7609 9.27634 17.1196 11.7519 15.6905L19.876 11.0006L27.9996 6.30988Z"
      fill="#FDB022"
    />
    <g filter="url(#filter0_dd_5457_188226)">
      <path
        d="M43.624 11.8666L43.624 11.8667L51.7482 16.5566C53.9143 17.807 55.2486 20.1182 55.2485 22.6193L55.248 31.9999V32.0001L55.2485 41.3807C55.2486 43.8818 53.9143 46.193 51.7482 47.4434L43.624 52.1333L43.624 52.1334L35.5003 56.8241C33.3343 58.0748 30.6657 58.0748 28.4997 56.8241L20.376 52.1334L20.376 52.1333L12.2518 47.4434C10.0857 46.193 8.7514 43.8818 8.75152 41.3807L8.752 32.0001V31.9999L8.75152 22.6193C8.7514 20.1182 10.0857 17.807 12.2518 16.5566L20.376 11.8667L20.376 11.8666L28.4997 7.17588C30.6657 5.92521 33.3343 5.92521 35.5003 7.17588L43.624 11.8666Z"
        stroke="#F79009"
        strokeWidth="2"
      />
    </g>

    <ScoreText score={score} />

    <g opacity="0.25">
      <path
        d="M31.9273 45.741C32.0373 45.5181 32.3553 45.5181 32.4653 45.741L33.6716 48.1848C33.7152 48.2732 33.7996 48.3346 33.8972 48.3488L36.5952 48.7432C36.8411 48.7791 36.9392 49.0815 36.7611 49.2549L34.8093 51.1559C34.7386 51.2249 34.7063 51.3242 34.723 51.4216L35.1835 54.1068C35.2256 54.3519 34.9683 54.5388 34.7482 54.4231L32.3359 53.1545C32.2485 53.1085 32.1441 53.1085 32.0567 53.1545L29.6444 54.4231C29.4243 54.5388 29.167 54.3519 29.2091 54.1068L29.6696 51.4216C29.6863 51.3242 29.654 51.2249 29.5833 51.1559L27.6315 49.2549C27.4534 49.0815 27.5514 48.7791 27.7974 48.7432L30.4954 48.3488C30.593 48.3346 30.6774 48.2732 30.721 48.1848L31.9273 45.741Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_5457_188226"
        x="4.75195"
        y="3.23779"
        width="54.4961"
        height="59.5244"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5457_188226"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5457_188226"
          result="effect2_dropShadow_5457_188226"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_5457_188226"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default Tier2;
