import styled from 'styled-components';
import Colors from '../Colors';

export const VerticalMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 9rem;
  min-width: 9rem;
  height: 100%;
  border-right: 1px solid ${Colors.Gray[200]};
  border-top: 1px solid ${Colors.Gray[200]};
`;

export const VideoInfoContainer = styled.div`
  display: grid;
  gap: 0.5rem;
  background-color: ${Colors.Primary[50]};
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.5rem;
`;

export const NameContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  overflow: hidden;
  align-items: baseline;
`;

export const MetricLine = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  padding-bottom: 2rem;

  .react-loading-skeleton {
    line-height: unset;
  }
`;

export const Label = styled.span`
  width: 100%;
  overflow: hidden;
  display: inline-block;
  word-break: break-all;
`;

export const PaddingLeftContainer = styled.div`
  padding-left: 1rem;
`;

export const IconContainer = styled.div`
  display: inline-block;
  cursor: pointer;
`;
