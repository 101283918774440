import { IconProps } from './types';

const CornerLeftUp = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 9L9 4M9 4L4 9M9 4V16C9 17.0609 9.42143 18.0783 10.1716 18.8284C10.9217 19.5786 11.9391 20 13 20H20"
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default CornerLeftUp;
