import * as SDK from '@replai-platform/sdk';
import { Badge, camelCaseToCapitalCase, Tab, Tabs, Typography } from '@replai-platform/ui-components';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useMountedState } from 'react-use';
import { Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';
import useHistoryState from 'use-history-state';
import { logEvent } from '../../../../analytics';
import { formatDuration } from '../../../../utils';
import { Page } from '../../../../utils/enums';
import { RouteAnimator } from '../../../RouteAnimator';
import { PROPERTY_TAG_TYPES } from '../../utils';
import { MainContainer } from './shared/styles';
import { TIMELINE_MAX_DURATION_S_AVAILABILITY } from '../../../../utils/constants';
import * as Styled from './styles';
import TabPaths from './TabPaths';
import TimelineTab from './Timeline';
import VideoMetrics from './VideoMetrics';

const getTabLabel = (tab: string): string => {
  const cleanTab = tab.replace('-', ' ');
  return camelCaseToCapitalCase(cleanTab);
};

const CreativeViewTabs: React.FC<{
  currentPage: Page;
  cluster: SDK.AssetMetricsWithTags;
  loading: boolean;
  defaultTab?: TabPaths;
  allowedTabs?: TabPaths[];
}> = ({ currentPage, cluster, loading, defaultTab, allowedTabs }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isMounted = useMountedState();
  const { pathnameBase } = useMatch(`:projectId/${currentPage}/:clusterId/*`) || { pathnameBase: location.pathname };
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useHistoryState(defaultTab ?? TabPaths.Timeline, 'currentTab');

  const tabs = useMemo(() => allowedTabs ?? [TabPaths.Timeline, TabPaths.Metrics], [allowedTabs]);

  const { id, duration } = cluster ?? {};

  const isAssetTimelineDisabled = useMemo(() => (duration || 0) > TIMELINE_MAX_DURATION_S_AVAILABILITY, [duration]);

  const isMountedValue = isMounted();
  useEffect(() => {
    if (isMountedValue && isAssetTimelineDisabled) {
      setCurrentTab(TabPaths.Metrics, true);
    }
  }, [isMountedValue, isAssetTimelineDisabled]);

  const tabLabels: Tab[] = useMemo<Tab[]>(
    () =>
      tabs.map((tab) => {
        const isDisabledDueToDuration = tab === TabPaths.Timeline && isAssetTimelineDisabled;
        return {
          label: getTabLabel(tab),
          trailingBadge: isDisabledDueToDuration ? (
            <Badge style={{ marginLeft: '3px' }} leadingIcon="AlertTriangle" color="Warning" />
          ) : undefined,
          disabled: isDisabledDueToDuration,
          tooltipContent: isDisabledDueToDuration
            ? 'Timeline not available for creatives longer than 100 seconds.'
            : undefined,
        };
      }),
    [tabs, isAssetTimelineDisabled]
  );

  useEffect(() => {
    if (location.pathname.indexOf(TabPaths.Timeline) > 0 && currentTab !== TabPaths.Timeline)
      setCurrentTab(TabPaths.Timeline, true);
    else if (location.pathname.indexOf(TabPaths.Metrics) > 0 && currentTab !== TabPaths.Metrics)
      setCurrentTab(TabPaths.Metrics, true);
    else if (!loading && !tabs.some((tab) => location.pathname.indexOf(tab) > 0)) {
      setCurrentTab(defaultTab ?? TabPaths.Timeline, true);
      navigate(`${pathnameBase}/${defaultTab ?? TabPaths.Timeline}${location.search}`, {
        replace: true,
      });
    }
  }, [currentTab, setCurrentTab, location, tabs, pathnameBase, loading, navigate, dispatch, cluster?.name]);

  const onChangeTab = (tab: Tab, tabIndex: number) => {
    navigate(`${pathnameBase}/${tabs[tabIndex]}${location.search}`, {
      replace: true,
    });
    logEvent({
      component: 'Video Details',
      action: `click ${camelCaseToCapitalCase(tabs[tabIndex])}`,
      category: 'user_actions',
    });
    setCurrentTab(tabs[tabIndex], true);
  };

  const propertyData = useMemo(() => {
    const durationProperty = {
      key: 'Duration',
      value: cluster.duration ? formatDuration(cluster.duration) : '00:00:00',
    };

    const propertiesTags =
      cluster?.tags
        .filter((tag) => PROPERTY_TAG_TYPES.includes(tag.type as SDK.CoreTags) && !!tag.value)
        .map((tag) => ({
          key: camelCaseToCapitalCase(tag.type),
          value: camelCaseToCapitalCase(tag.value || '-'),
        })) ?? [];

    return [...propertiesTags, durationProperty].sort((a, b) => a.key.localeCompare(b.key));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(cluster)]);

  return (
    <MainContainer marginTop={2}>
      <Tabs
        variant="gray-button"
        tabLabels={tabLabels}
        onTabChange={onChangeTab}
        defaultSelectedTab={tabLabels.find((tab) => tab.label === getTabLabel(currentTab))}
      />
      <Styled.Container>
        <Styled.TabContainer>
          {!loading && !id ? (
            <Typography>This video is not available on your filter combination.</Typography>
          ) : (
            <AnimatePresence exitBeforeEnter>
              <Routes>
                <Route
                  path={`${TabPaths.Timeline}/*`}
                  element={
                    <RouteAnimator>
                      <TimelineTab
                        cluster={cluster}
                        propertyData={propertyData}
                        url={cluster.url}
                        isLoading={loading}
                      />
                    </RouteAnimator>
                  }
                />
                <Route
                  path={`${TabPaths.Metrics}/*`}
                  element={
                    <RouteAnimator>
                      <VideoMetrics
                        cluster={cluster}
                        propertyData={propertyData}
                        url={cluster.url}
                        isLoading={loading}
                      />
                    </RouteAnimator>
                  }
                />
              </Routes>
            </AnimatePresence>
          )}
        </Styled.TabContainer>
      </Styled.Container>
    </MainContainer>
  );
};

export default CreativeViewTabs;
