import * as SDK from '@replai-platform/sdk';
import { messages } from '@replai-platform/sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';
import { api } from '../../../api';
import useConceptMetrics from '../../../api/hooks/concepts/useConceptMetrics';
import { NetworkFilterProps, PromotedObjectFilterProps } from '../../../components/FilterBar/Filters';
import useNetworkFilter from '../../../components/FilterBar/hooks/useNetworkFilter';
import usePromotedObjectFilter from '../../../components/FilterBar/hooks/usePromotedObjectFilter';
import TopNavPageTitle from '../../../components/TopNavPageTitle';
import type { RootState } from '../../../store/rootReducer';
import { ActivityType, Page } from '../../../utils/enums';
import { getNavigateToPage } from '../../../utils/getNavigateToPage';
import { RouteAnimator } from '../../RouteAnimator';
import ConceptViewTabs from './ConceptViewTabs';
import useSidebarFilters from '../../../components/FilterSidebar/buildSidebarFilters';
import { supportedFilters } from './utils';
import FilterBar from '../../../components/FilterBar';

const ConceptView: React.VFC = () => {
  const { id: projectId } = useSelector((state: RootState) => state.project);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { params } = useMatch(`:projectId/${Page.Concepts}/:conceptId/*`)!;
  const filters = useSelector((state: RootState) => state.filters);
  const kpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const baseMetric = useSelector((state: RootState) => state.project.baseMetric);
  useSidebarFilters(supportedFilters);

  const getConceptsMetricsParams: SDK.GetAssetsMetricsRequest = {
    projectIds: [projectId],
    // TODO: This conceptId filter is not implemented in the endpoint so we filter manually
    conceptId: params.conceptId,
    metrics: [...kpis, SDK.Metrics.INSTALLS, SDK.Metrics.IMPRESSIONS],
    adsFilters: api.filterConverter.getAdsFilters({ ...filters, activity: ActivityType.all }),
    assetFilters: api.filterConverter.getAssetFilters({ ...filters, activity: ActivityType.all }),
    metricsFilters: api.filterConverter.getMetricsFilters({ ...filters, activity: ActivityType.all }),
    includeTotalsAndAvg: false,
    orderBy: {
      condition: SDK.OrderByCondition.DESC_NULLS_LAST,
      value: SDK.Metrics.SPEND,
    },
  };
  const { isLoading: conceptMetricsLoading, data: conceptMetrics } = useConceptMetrics(getConceptsMetricsParams);

  const data = useMemo(
    () =>
      conceptMetrics?.concepts.find((c) => c.id === params.conceptId) ?? {
        id: params.conceptId ?? '',
        name: messages.NOT_AVAILABLE,
        displayName: messages.NOT_AVAILABLE,
      },
    [conceptMetrics, params.conceptId]
  );

  // Workaround for having the concept name in the title even if the concept is filtered out -> change to getConceptById when TECHAPL-603 is done
  const getConceptNameParams = {
    projectIds: [projectId],
    metrics: [baseMetric],
    includeTotalsAndAvg: false,
    orderBy: {
      condition: SDK.OrderByCondition.DESC_NULLS_LAST,
      value: baseMetric,
    },
  };

  const { data: conceptNameResult, isLoading } = useConceptMetrics(getConceptNameParams);

  const conceptName = useMemo(() => {
    const concept = conceptNameResult?.concepts.find((c) => c.id === params.conceptId);

    return concept?.displayName ?? concept?.name ?? messages.NOT_AVAILABLE;
  }, [conceptNameResult, params.conceptId]);

  // Build network filter
  const {
    networkFilterOptions,
    networkFilterOnChange,
    isLoading: isNetworkFilterLoading,
  } = useNetworkFilter(Page.Videos);
  const networkFilter = useMemo(
    () =>
      ({
        options: networkFilterOptions,
        onChange: networkFilterOnChange,
        loading: isNetworkFilterLoading,
      } as NetworkFilterProps),
    [networkFilterOptions, networkFilterOnChange, isNetworkFilterLoading]
  );

  // Build promoted object types filter
  const { promotedObjectTypeFilterOptions, promotedObjectTypeFilterOnChange } = usePromotedObjectFilter(Page.Videos);
  const promotedObjectFilter = useMemo(
    () =>
      ({
        options: promotedObjectTypeFilterOptions,
        onChange: promotedObjectTypeFilterOnChange,
      } as PromotedObjectFilterProps),
    [promotedObjectTypeFilterOnChange, promotedObjectTypeFilterOptions]
  );

  const location = useLocation();
  const navigateToPage = getNavigateToPage(location.pathname);

  return (
    <RouteAnimator data-test="concept-view">
      <TopNavPageTitle
        title={conceptName}
        subtitle="Concept"
        navigateToPage={navigateToPage}
        titleIsLoading={isLoading}
      />
      <FilterBar
        eventPrefix="concept-view"
        supportedFilters={supportedFilters}
        networkFilter={networkFilter}
        promotedObjectFilter={promotedObjectFilter}
        withDateFilter={false}
      />
      <ConceptViewTabs conceptData={data} loading={conceptMetricsLoading} />
    </RouteAnimator>
  );
};

export default ConceptView;
