/**
 * A metric is a raw performance value extracted from the networks/MMPs we integrate with, including
 * values like dollars spent, impressions, installs, ...
 * <p>Because metrics are collected over several days, they can be combined by being added.
 * Read the following document to understand the distinction between
 * KPIs and Metrics: https://replai.atlassian.net/wiki/spaces/RPL/pages/2121662570/Understanding+how+metrics+work
 */
export enum Metrics {
  AGE = 'age',
  IMPRESSIONS = 'impressions',
  INSTALLS = 'installs',
  SPEND = 'spend',
  EARNINGS_DAY_7 = 'earningsDay7',
  PURCHASES_DAY_7 = 'purchasesDay7',
  CLICKS = 'clicks',
  PURCHASES = 'purchases',
  EARNINGS = 'earnings',
  LIKES = 'likes',
  COMMENTS = 'comments',
  DISLIKES = 'dislikes',
  SHARES = 'shares',
  VIEWS = 'views',
  AVG_VIEW_DURATION = 'avgViewDuration',
  ANNOTATION_CLICK_RATE = 'annotationClickThroughRate',
  ANNOTATION_CLOSE_RATE = 'annotationCloseRate',
  MINUTES_WATCHED = 'minutesWatched',
  SUBSCRIBERS_GAINED = 'subscribersGained',
  SUBSCRIBERS_LOST = 'subscribersLost',
}

// TODO(TECHPROM-372): refactor this further to include more info on the KPIs.
// TODO(TECHPROM-372): find and remove unused KPIs.
/**
 * A KPI is a calculation over a set of Metrics that represents a higher-level abstraction
 * of ad performance. Read the following document to understand the distinction between
 * KPIs and Metrics: https://replai.atlassian.net/wiki/spaces/RPL/pages/2121662570/Understanding+how+metrics+work
 */
export enum KPI {
  CPC = 'cpc',
  VINTED_CPL = 'vintedCostPerFirstListing',
  CPL = 'cpl',
  CPI = 'cpi',
  CTI = 'cti',
  CTR = 'ctr',
  CPP = 'cpp',
  IPM = 'ipm',
  YOKE_CPA = 'yokeCpa',
  CTR_7D_CHANGE = 'change7DCtr',
  IPM_7D_CHANGE = 'change7DIpm',
  CPPDAY0 = 'cppDay0',
  CPPDAY1 = 'cppDay1',
  CPPDAY2 = 'cppDay2',
  CPPDAY3 = 'cppDay3',
  CPPDAY4 = 'cppDay4',
  CPPDAY5 = 'cppDay5',
  CPPDAY6 = 'cppDay6',
  CPPDAY7 = 'cppDay7',
  CPPDAY8 = 'cppDay8',
  CPPDAY9 = 'cppDay9',
  CPPDAY10 = 'cppDay10',
  CPPDAY11 = 'cppDay11',
  CPPDAY12 = 'cppDay12',
  CPPDAY13 = 'cppDay13',
  CPPDAY14 = 'cppDay14',
  CPPDAY15 = 'cppDay15',
  CPPDAY16 = 'cppDay16',
  CPPDAY17 = 'cppDay17',
  CPPDAY18 = 'cppDay18',
  CPPDAY19 = 'cppDay19',
  CPPDAY20 = 'cppDay20',
  CPPDAY21 = 'cppDay21',
  CPPDAY22 = 'cppDay22',
  CPPDAY23 = 'cppDay23',
  CPPDAY24 = 'cppDay24',
  CPPDAY25 = 'cppDay25',
  CPPDAY26 = 'cppDay26',
  CPPDAY27 = 'cppDay27',
  CPPDAY28 = 'cppDay28',
  CPPDAY29 = 'cppDay29',
  CPPDAY30 = 'cppDay30',
  CPI_7D_CHANGE = 'change7DCpi',
  CPPD0_7D_CHANGE = 'change7DCppDay0',
  CPPD1_7D_CHANGE = 'change7DCppDay1',
  CPPD2_7D_CHANGE = 'change7DCppDay2',
  CPPD3_7D_CHANGE = 'change7DCppDay3',
  CPPD4_7D_CHANGE = 'change7DCppDay4',
  CPPD5_7D_CHANGE = 'change7DCppDay5',
  CPPD6_7D_CHANGE = 'change7DCppDay6',
  CPPD7_7D_CHANGE = 'change7DCppDay7',
  CPPD8_7D_CHANGE = 'change7DCppDay8',
  CPPD9_7D_CHANGE = 'change7DCppDay9',
  CPPD1_7D_CHANGE0 = 'change7DCppDay10',
  CPPD1_7D_CHANGE1 = 'change7DCppDay11',
  CPPD1_7D_CHANGE2 = 'change7DCppDay12',
  CPPD1_7D_CHANGE3 = 'change7DCppDay13',
  CPPD1_7D_CHANGE4 = 'change7DCppDay14',
  CPPD1_7D_CHANGE5 = 'change7DCppDay15',
  CPPD1_7D_CHANGE6 = 'change7DCppDay16',
  CPPD1_7D_CHANGE7 = 'change7DCppDay17',
  CPPD1_7D_CHANGE8 = 'change7DCppDay18',
  CPPD1_7D_CHANGE9 = 'change7DCppDay19',
  CPPD2_7D_CHANGE0 = 'change7DCppDay20',
  CPPD2_7D_CHANGE1 = 'change7DCppDay21',
  CPPD2_7D_CHANGE2 = 'change7DCppDay22',
  CPPD2_7D_CHANGE3 = 'change7DCppDay23',
  CPPD2_7D_CHANGE4 = 'change7DCppDay24',
  CPPD2_7D_CHANGE5 = 'change7DCppDay25',
  CPPD2_7D_CHANGE6 = 'change7DCppDay26',
  CPPD2_7D_CHANGE7 = 'change7DCppDay27',
  CPPD2_7D_CHANGE8 = 'change7DCppDay28',
  CPPD2_7D_CHANGE9 = 'change7DCppDay29',
  CPPD3_7D_CHANGE0 = 'change7DCppDay30',
  ROAS = 'roas',
  ROASDAY0 = 'roasDay0',
  ROASDAY1 = 'roasDay1',
  ROASDAY2 = 'roasDay2',
  ROASDAY3 = 'roasDay3',
  ROASDAY4 = 'roasDay4',
  ROASDAY5 = 'roasDay5',
  ROASDAY6 = 'roasDay6',
  ROASDAY7 = 'roasDay7',
  ROASDAY8 = 'roasDay8',
  ROASDAY9 = 'roasDay9',
  ROASDAY10 = 'roasDay10',
  ROASDAY11 = 'roasDay11',
  ROASDAY12 = 'roasDay12',
  ROASDAY13 = 'roasDay13',
  ROASDAY14 = 'roasDay14',
  ROASDAY15 = 'roasDay15',
  ROASDAY16 = 'roasDay16',
  ROASDAY17 = 'roasDay17',
  ROASDAY18 = 'roasDay18',
  ROASDAY19 = 'roasDay19',
  ROASDAY20 = 'roasDay20',
  ROASDAY21 = 'roasDay21',
  ROASDAY22 = 'roasDay22',
  ROASDAY23 = 'roasDay23',
  ROASDAY24 = 'roasDay24',
  ROASDAY25 = 'roasDay25',
  ROASDAY26 = 'roasDay26',
  ROASDAY27 = 'roasDay27',
  ROASDAY28 = 'roasDay28',
  ROASDAY29 = 'roasDay29',
  ROASDAY30 = 'roasDay30',
  ROASD07D_CHANGE = 'change7DRoasDay0',
  ROASD17D_CHANGE = 'change7DRoasDay1',
  ROASD27D_CHANGE = 'change7DRoasDay2',
  ROASD37D_CHANGE = 'change7DRoasDay3',
  ROASD47D_CHANGE = 'change7DRoasDay4',
  ROASD57D_CHANGE = 'change7DRoasDay5',
  ROASD67D_CHANGE = 'change7DRoasDay6',
  ROASD77D_CHANGE = 'change7DRoasDay7',
  ROASD87D_CHANGE = 'change7DRoasDay8',
  ROASD97D_CHANGE = 'change7DRoasDay9',
  ROASD17D_CHANGE0 = 'change7DRoasDay10',
  ROASD17D_CHANGE1 = 'change7DRoasDay11',
  ROASD17D_CHANGE2 = 'change7DRoasDay12',
  ROASD17D_CHANGE3 = 'change7DRoasDay13',
  ROASD17D_CHANGE4 = 'change7DRoasDay14',
  ROASD17D_CHANGE5 = 'change7DRoasDay15',
  ROASD17D_CHANGE6 = 'change7DRoasDay16',
  ROASD17D_CHANGE7 = 'change7DRoasDay17',
  ROASD17D_CHANGE8 = 'change7DRoasDay18',
  ROASD17D_CHANGE9 = 'change7DRoasDay19',
  ROASD27D_CHANGE0 = 'change7DRoasDay20',
  ROASD27D_CHANGE1 = 'change7DRoasDay21',
  ROASD27D_CHANGE2 = 'change7DRoasDay22',
  ROASD27D_CHANGE3 = 'change7DRoasDay23',
  ROASD27D_CHANGE4 = 'change7DRoasDay24',
  ROASD27D_CHANGE5 = 'change7DRoasDay25',
  ROASD27D_CHANGE6 = 'change7DRoasDay26',
  ROASD27D_CHANGE7 = 'change7DRoasDay27',
  ROASD27D_CHANGE8 = 'change7DRoasDay28',
  ROASD27D_CHANGE9 = 'change7DRoasDay29',
  ROASD37D_CHANGE0 = 'change7DRoasDay30',
  RETENTIONDAY0 = 'retentionDay0',
  RETENTIONDAY1 = 'retentionDay1',
  RETENTIONDAY2 = 'retentionDay2',
  RETENTIONDAY3 = 'retentionDay3',
  RETENTIONDAY4 = 'retentionDay4',
  RETENTIONDAY5 = 'retentionDay5',
  RETENTIONDAY6 = 'retentionDay6',
  RETENTIONDAY7 = 'retentionDay7',
  RETENTIONDAY8 = 'retentionDay8',
  RETENTIONDAY9 = 'retentionDay9',
  RETENTIONDAY10 = 'retentionDay10',
  RETENTIONDAY11 = 'retentionDay11',
  RETENTIONDAY12 = 'retentionDay12',
  RETENTIONDAY13 = 'retentionDay13',
  RETENTIONDAY14 = 'retentionDay14',
  RETENTIONDAY15 = 'retentionDay15',
  RETENTIONDAY16 = 'retentionDay16',
  RETENTIONDAY17 = 'retentionDay17',
  RETENTIONDAY18 = 'retentionDay18',
  RETENTIONDAY19 = 'retentionDay19',
  RETENTIONDAY20 = 'retentionDay20',
  RETENTIONDAY21 = 'retentionDay21',
  RETENTIONDAY22 = 'retentionDay22',
  RETENTIONDAY23 = 'retentionDay23',
  RETENTIONDAY24 = 'retentionDay24',
  RETENTIONDAY25 = 'retentionDay25',
  RETENTIONDAY26 = 'retentionDay26',
  RETENTIONDAY27 = 'retentionDay27',
  RETENTIONDAY28 = 'retentionDay28',
  RETENTIONDAY29 = 'retentionDay29',
  RETENTIONDAY30 = 'retentionDay30',
  RETENTIOND07D_CHANGE = 'change7DRetentionDay0',
  RETENTIOND17D_CHANGE = 'change7DRetentionDay1',
  RETENTIOND27D_CHANGE = 'change7DRetentionDay2',
  RETENTIOND37D_CHANGE = 'change7DRetentionDay3',
  RETENTIOND47D_CHANGE = 'change7DRetentionDay4',
  RETENTIOND57D_CHANGE = 'change7DRetentionDay5',
  RETENTIOND67D_CHANGE = 'change7DRetentionDay6',
  RETENTIOND77D_CHANGE = 'change7DRetentionDay7',
  RETENTIOND87D_CHANGE = 'change7DRetentionDay8',
  RETENTIOND97D_CHANGE = 'change7DRetentionDay9',
  RETENTIOND17D_CHANGE0 = 'change7DRetentionDay10',
  RETENTIOND17D_CHANGE1 = 'change7DRetentionDay11',
  RETENTIOND17D_CHANGE2 = 'change7DRetentionDay12',
  RETENTIOND17D_CHANGE3 = 'change7DRetentionDay13',
  RETENTIOND17D_CHANGE4 = 'change7DRetentionDay14',
  RETENTIOND17D_CHANGE5 = 'change7DRetentionDay15',
  RETENTIOND17D_CHANGE6 = 'change7DRetentionDay16',
  RETENTIOND17D_CHANGE7 = 'change7DRetentionDay17',
  RETENTIOND17D_CHANGE8 = 'change7DRetentionDay18',
  RETENTIOND17D_CHANGE9 = 'change7DRetentionDay19',
  RETENTIOND27D_CHANGE0 = 'change7DRetentionDay20',
  RETENTIOND27D_CHANGE1 = 'change7DRetentionDay21',
  RETENTIOND27D_CHANGE2 = 'change7DRetentionDay22',
  RETENTIOND27D_CHANGE3 = 'change7DRetentionDay23',
  RETENTIOND27D_CHANGE4 = 'change7DRetentionDay24',
  RETENTIOND27D_CHANGE5 = 'change7DRetentionDay25',
  RETENTIOND27D_CHANGE6 = 'change7DRetentionDay26',
  RETENTIOND27D_CHANGE7 = 'change7DRetentionDay27',
  RETENTIOND27D_CHANGE8 = 'change7DRetentionDay28',
  RETENTIOND27D_CHANGE9 = 'change7DRetentionDay29',
  RETENTIOND37D_CHANGE0 = 'change7DRetentionDay30',
  TREWATWELL_CPL = 'treatwellCostPerLead',
  AGE_PREDICTION = 'agePrediction',
  ASSET_COUNT = 'assetCount',
  CONCEPT_COUNT = 'conceptCount',
  AVG_CONCEPT_SPEND = 'avgConceptSpend',
  AVG_ASSET_SPEND = 'avgAssetSpend',
  ENGAGEMENT_RATE = 'engagementRate',
}

/** Types of data possible to be shown on table columns. */
export type MetricOrKpi = Metrics | KPI;

// KPIs where the lower its value, the better they are
// TODO(TECHPROM-372): delete this once we move the KPI configs from the Website.
export enum MetricKPIWithInverseOrder {
  CPC = 'cpc',
  VINTED_CPL = 'vintedCostPerFirstListing',
  YOKE_CPA = 'yokeCpa',
  CPL = 'cpl',
  CPI = 'cpi',
  CPPDAY0 = 'cppDay0',
  CPPDAY1 = 'cppDay1',
  CPPDAY2 = 'cppDay2',
  CPPDAY3 = 'cppDay3',
  CPPDAY4 = 'cppDay4',
  CPPDAY5 = 'cppDay5',
  CPPDAY6 = 'cppDay6',
  CPPDAY7 = 'cppDay7',
  CPPDAY8 = 'cppDay8',
  CPPDAY9 = 'cppDay9',
  CPPDAY10 = 'cppDay10',
  CPPDAY11 = 'cppDay11',
  CPPDAY12 = 'cppDay12',
  CPPDAY13 = 'cppDay13',
  CPPDAY14 = 'cppDay14',
  CPPDAY15 = 'cppDay15',
  CPPDAY16 = 'cppDay16',
  CPPDAY17 = 'cppDay17',
  CPPDAY18 = 'cppDay18',
  CPPDAY19 = 'cppDay19',
  CPPDAY20 = 'cppDay20',
  CPPDAY21 = 'cppDay21',
  CPPDAY22 = 'cppDay22',
  CPPDAY23 = 'cppDay23',
  CPPDAY24 = 'cppDay24',
  CPPDAY25 = 'cppDay25',
  CPPDAY26 = 'cppDay26',
  CPPDAY27 = 'cppDay27',
  CPPDAY28 = 'cppDay28',
  CPPDAY29 = 'cppDay29',
  CPPDAY30 = 'cppDay30',
  TREWATWELL_CPL = 'treatwellCostPerLead',
}

// TODO(TECHPROM-372): delete this once we move the KPI configs from the Website.
export enum MetricKPIAvg {
  AVGIPM = 'avgIpm',
  AVGCPI = 'avgCpi',
  AVGCPL = 'avgCpl',
  AVGCTI = 'avgCti',
  AVGCTR = 'avgCtr',
  AVGCPPDAY7 = 'avgCppDay7',
  AVGROASDAY7 = 'avgRoasDay7',
  AVGVINTEDCOSTPERFIRSTLISTING = 'avgVintedCostPerFirstListing',
  AVGYOKECPA = 'avgYokeCpa',
  AVGTREATWELLCPL = 'avgTreatWellCpl',
  AVGCPC = 'avgCpc',
  AVGENGAGEMENTRATE = 'avgEngagementRate',
}

// TODO(TECHPROM-372): delete this once we move the KPI configs from the Website.
export enum MetricTotal {
  TOTALSPEND = 'totalSpend',
  TOTALIMPRESSIONS = 'totalImpressions',
  TOTALVIEWS = 'totalViews',
  TOTALPURCHASES = 'totalPurchases',
  TOTALINSTALLS = 'totalInstalls',
  TOTALEARNINGS = 'totalEarnings',
  TOTALCLICKS = 'totalClicks',
}
