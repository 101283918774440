import { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem;
  overflow-y: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: visible;
`;

const MainPanelLayout = ({ children }: { children?: ReactNode }): JSX.Element => (
  <Container id="content-container">
    <Content>{children}</Content>
  </Container>
);

export default MainPanelLayout;
