import { CountryIconProps } from './types';

const YE = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M11.9997 23.9992C17.1593 23.9992 21.5578 20.7427 23.2533 16.1731H0.746094C2.44161 20.7427 6.84013 23.9992 11.9997 23.9992Z"
      fill="black"
    />
    <path
      d="M11.9997 0.000610352C6.84013 0.000610352 2.44161 3.25711 0.746094 7.82672H23.2534C21.5578 3.25711 17.1593 0.000610352 11.9997 0.000610352Z"
      fill="#D80027"
    />
  </svg>
);
export default YE;
