import { IconProps } from './types';

const Sliders = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 21V14M4 10V3M12 21V12M12 8V3M20 21V16M20 12V3M1 14H7M9 8H15M17 16H23"
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default Sliders;
