/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getProjects = async () => {
  const res = await provider.getJson<object, (SDK.Project & any)[]>(`${SDK.BASE_URL()}/projects/all`);
  return res;
};

export default function useProjects(options?: UseQueryOptions<(SDK.Project & any)[]>) {
  return useQuery(['projects'], () => getProjects(), options);
}
