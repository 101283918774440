/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  CreativeRecommendation,
  kpiUtils,
  Network,
  RecommendationType,
  TagIntroWithKind,
  TagWithKind,
} from '@replai-platform/sdk';
import { Badge, Colors, StatusIcon } from '@replai-platform/ui-components';
import * as Styled from './styles';
import IntroIndicator from '../IntroIndicator';
import { OBJECT_TYPE_TO_ICON } from '../../utils/constants';
import { getBadgeText } from '../../utils/getBadgeText';
import { nameFromContent } from '../../utils/nameFromContent';
import { getPerformanceIndicatorType } from '../columns/generators/getPlatformsColumn';
import { networkIconType } from '../NetworkIcon';
import RecommendationFigure from '../RecommendationFigure';

const SuggestionDecorator: React.FC<{
  value: CreativeRecommendation['name'];
  includeType?: boolean;
  showIcon?: boolean;
  showPreviousOnReplace?: boolean;
  showVerb?: boolean;
  verbPastTense?: boolean;
}> = ({
  value,
  includeType = true,
  showIcon = true,
  showPreviousOnReplace = true,
  showVerb = true,
  verbPastTense = false,
}) => {
  let firstTag: TagWithKind | TagIntroWithKind;
  switch (value?.type) {
    case RecommendationType.CREATIVE_ADD_TAG:
    case RecommendationType.CREATIVE_ADD_TAG_INTRO:
    case RecommendationType.CREATIVE_REMOVE_TAG:
    case RecommendationType.CREATIVE_REMOVE_TAG_INTRO:
      firstTag = value?.tag;
      break;
    case RecommendationType.CREATIVE_REPLACE_TAG:
    case RecommendationType.CREATIVE_REPLACE_TAG_INTRO:
      firstTag = value?.previous || value?.new;
      break;
  }

  return (
    <Styled.SuggestionContainer>
      <Styled.Figures>
        <RecommendationFigure value={value} showIcon={showIcon} showPreviousOnReplace={showPreviousOnReplace} />
        <IntroIndicator data-test="recommendation-intro-indicator" content={value} />
      </Styled.Figures>
      <Styled.SuggestionInfoContainer>
        <Styled.TagInfoContainer>
          <Styled.NoMarginTypography type="text-sm" fontWeight="medium">
            {nameFromContent({ content: value, includeType, showPreviousOnReplace })}
          </Styled.NoMarginTypography>
          <Styled.NoMarginTypography type="text-sm" fontWeight="regular" color={Colors.Gray[500]}>
            {getBadgeText(firstTag?.type, value, showVerb, verbPastTense)}
          </Styled.NoMarginTypography>
        </Styled.TagInfoContainer>
      </Styled.SuggestionInfoContainer>
    </Styled.SuggestionContainer>
  );
};

interface TimelineRecommendationsProps {
  recommendations: any;
  projectNetworks: Network[];
}

const TimelineRecommendations: React.FC<TimelineRecommendationsProps> = ({ recommendations, projectNetworks }) => {
  if (!recommendations || !recommendations.length) return [];

  return recommendations.map((r) => (
    <Styled.RecommendationContainer>
      <SuggestionDecorator
        value={r.name}
        includeType={false}
        showIcon={r.showIcon}
        showPreviousOnReplace={r.showPreviousOnReplace}
        showVerb={r.showVerb}
        verbPastTense={r.verbPastTense}
      />
      <div style={{ display: 'flex' }}>
        <Styled.KpisContainer>
          {(r.name?.targets || []).map((target) => (
            <Badge key={target} color="Gray" size="sm">
              {kpiUtils.getDisplayName(target)}
            </Badge>
          ))}
        </Styled.KpisContainer>
        <Styled.PlatformsContainer>
          {r.networks?.length &&
            projectNetworks.map((network: Network) => (
              <StatusIcon
                icon={networkIconType(network)}
                status={getPerformanceIndicatorType(r.networks, network, true)}
              />
            ))}
          {r.promotedObjectType && (
            <StatusIcon icon={OBJECT_TYPE_TO_ICON[r.promotedObjectType] ?? 'Website'} status="success" />
          )}
        </Styled.PlatformsContainer>
      </div>
    </Styled.RecommendationContainer>
  ));
};

export default TimelineRecommendations;
