import { useMemo } from 'react';
import styled from 'styled-components';
import * as SDK from '@replai-platform/sdk';
import { formatWithDecimals } from '../../utils';
import Typography from '../Typography/Typography';
import Tooltip from '../Tooltip/Tooltip';
import Badge from '../Badge/Badge';
import Colors from '../Colors';

export type FrequencyMetricBadgeProps = {
  type: 'frequency' | 'metric';
  current: string;
  total: string;
  percentage?: number;
  colored?: boolean;
  includeTooltip?: boolean;
  metric?: SDK.Metrics;
};

const ValueContainer = styled.div`
  display: flex;
  gap: 0.25rem;
`;

const Percentage = styled.span`
  font-size: 10px;
  align-self: center;
`;

const NoWrapBadge = styled(Badge)`
  white-space: nowrap;
`;

const FrequencyMetricBadge = ({
  type,
  current,
  total,
  percentage,
  colored = true,
  includeTooltip = true,
  metric,
}: FrequencyMetricBadgeProps) => {
  const tooltipContent =
    type === 'frequency' ? (
      <>
        This tag is present across <strong>{current}</strong> concepts out of{' '}
        <strong>{total}</strong> total in the selected context
      </>
    ) : (
      <>
        The videos where this tag is present aggregate{' '}
        <strong>{current}</strong> {metric} out of <strong>{total}</strong>{' '}
        total in the selected context
      </>
    );

  const badgeColor = useMemo(() => {
    if (colored) {
      return type === 'frequency' ? 'Blue' : 'Success';
    }

    return 'Gray';
  }, [type, colored]);

  return (
    <Tooltip
      content={
        includeTooltip ? (
          <Typography noMargin type="text-sm">
            {tooltipContent}
          </Typography>
        ) : null
      }
    >
      <NoWrapBadge
        data-test={`insight-${type}`}
        color={badgeColor}
        size="sm"
        leadingIcon={
          type === 'frequency'
            ? 'Video'
            : metric && metric !== SDK.Metrics.SPEND
            ? 'LineChart'
            : 'DollarSign'
        }
        leadingIconColor={
          Colors[badgeColor === 'Blue' ? 'BlueLight' : badgeColor][500]
        }
      >
        <ValueContainer>
          <span>
            <strong>{current}</strong> / {total}
          </span>
          {percentage !== undefined && (
            <Percentage>{`(${formatWithDecimals(percentage, 1)}%)`}</Percentage>
          )}
        </ValueContainer>
      </NoWrapBadge>
    </Tooltip>
  );
};

export default FrequencyMetricBadge;
