import styled, { css } from 'styled-components';

export const Button = styled.button<{
  $backgroundColor?: string;
  $borderColor?: string;
  $boxShadow?: string;
  $color?: string;
  $style?: string;
  $hasFullWidth?: boolean;
  $hasLeadingIcon?: boolean;
  $hasTrailingIcon?: boolean;
  $hasCenterIcon?: boolean;
  $hasNoChildren?: boolean;
  $isGroupButton?: boolean;
  $isFirst?: boolean;
  $isLast?: boolean;
  $enableTransitions?: boolean;
}>`
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 0.08em solid transparent;
  border-radius: 0.571em;
  box-shadow: 0px 0.08em 0.143em rgba(16, 24, 40, 0.05);
  ${(props) => (props.$enableTransitions ? 'transition: 0.2s;' : '')}
  background-color: ${(props) => props.$backgroundColor};
  box-shadow: ${(props) => props.$boxShadow};
  color: ${(props) => props.$color};

  &&& {
    border-color: ${(props) => props.$borderColor};
    ${(props) =>
      css`
        ${props.$style}
      `}
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.regular {
    color: white;
    border: none;
  }

  &.light {
    border: none;
  }

  &.parentDefault {
    height: 100%;
    width: 100%;
  }

  &.smDefault {
    min-width: 105px;
    height: 36px;
    padding: 8px 14px 8px 14px;
    font-size: 14px;
    line-height: 14px;
  }

  &.mdDefault {
    min-width: 109px;
    height: 40px;
    padding: 10px 16px 10px 16px;
    font-size: 14px;
    line-height: 14px;
  }

  &.lgDefault {
    min-width: 124px;
    height: 44px;
    padding: 10px 18px 10px 18px;
    font-size: 16px;
    line-height: 16px;
  }

  &.xlDefault {
    min-width: 128px;
    height: 48px;
    padding: 12px 20px 12px 20px;
    font-size: 16px;
    line-height: 16px;
  }

  &.xxlDefault {
    min-width: 155px;
    height: 60px;
    padding: 16px 28px 16px 28px;
    font-size: 18px;
    line-height: 18px;
  }

  &.smIconOnly {
    justify-content: center;
    min-width: 36px;
    height: 36px;
    padding: 8px;
    font-size: 14px;
  }

  &.mdIconOnly {
    justify-content: center;
    min-width: 40px;
    height: 40px;
    padding: 10px;
    font-size: 14px;
  }

  &.lgIconOnly {
    justify-content: center;
    min-width: 44px;
    height: 44px;
    padding: 12px;
    font-size: 16px;
  }

  &.xlIconOnly {
    justify-content: center;
    min-width: 48px;
    height: 48px;
    padding: 14px;
    font-size: 16px;
  }

  &.xxlIconOnly {
    justify-content: center;
    min-width: 56px;
    height: 56px;
    padding: 16px;
    font-size: 18px;
  }

  ${(props) => (props.$hasLeadingIcon ? LeadingIconButtonMixin : '')}
  ${(props) => (props.$hasTrailingIcon ? TrailingIconButtonMixin : '')}
  ${(props) => (props.$hasNoChildren ? NoChildrenMixin : '')}
  ${(props) => (props.$hasCenterIcon ? CenterIconButtonMixin : '')}
  ${(props) => (props.$isGroupButton ? GroupButtonMixin : '')}
  ${(props) => (props.$isFirst ? FirstButtonMixin : '')}
  ${(props) => (props.$isLast ? LastButtonMixin : '')}
  ${(props) => (props.$hasFullWidth ? FullWidthMixin : '')}
`;

/*  Mixins  */

const FullWidthMixin = css`
  width: 100%;
`;

const LeadingIconButtonMixin = css`
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 8px;
  align-items: center;
`;

const TrailingIconButtonMixin = css`
  display: grid;
  grid-template-columns: auto min-content;
  grid-gap: 8px;
  align-items: center;
`;

const CenterIconButtonMixin = css`
  > .centerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .centerContainer > :nth-child(2) {
    margin-left: 10px;
  }
`;

const NoChildrenMixin = css`
  grid-template-columns: min-content;
`;

const GroupButtonMixin = css`
  border-right: 0px;
  border-radius: 0px;
`;

const FirstButtonMixin = css`
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

const LastButtonMixin = css`
  border-right: 1px solid transparent;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;
