import { CountryIconProps } from './types';

const LC = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#338AF3"
    />
    <path
      d="M7.56543 16.1739H16.435L12.0002 5.73914L7.56543 16.1739Z"
      fill="#F3F3F3"
    />
    <path
      d="M9.10645 15.1303L12 8.54858L14.8936 15.1303H9.10645Z"
      fill="#333333"
    />
    <path
      d="M7.56543 16.1745H16.435L12.0002 12.0006L7.56543 16.1745Z"
      fill="#FFDA44"
    />
  </svg>
);
export default LC;
