import { useEffect } from 'react';
import { camelCaseToCapitalCase, Tab, Tabs } from '@replai-platform/ui-components';
import { AnimatePresence } from 'framer-motion';
import { Navigate, Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useHistoryState from 'use-history-state';
import { Page } from '../../../../utils/enums';
import TagViewTabPaths from './tagViewTabPaths';
import TagMetrics from './TagMetrics';
import TagVideos from './TagVideos';
import { logEvent } from '../../../../analytics';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
`;

const TabContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
`;

const getTabLabel = (tab: string): string => {
  const cleanTab = tab.replace('-', ' ');
  return cleanTab[0].toUpperCase() + cleanTab.slice(1).toLowerCase();
};

const AVAILABLE_TABS = [TagViewTabPaths.Videos, TagViewTabPaths.Metrics];

const TagViewTabs = () => {
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { pathnameBase } = useMatch(`:projectId/${Page.Tags}/:tagId/*`)!;
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useHistoryState(TagViewTabPaths.Videos, 'currentTab');

  const tabLabels: Tab[] = AVAILABLE_TABS.map((tab) => ({
    label: getTabLabel(tab),
  }));

  useEffect(() => {
    // Set the current tab on use
    if (location.pathname.indexOf(TagViewTabPaths.Metrics) > 0 && currentTab !== TagViewTabPaths.Metrics)
      setCurrentTab(TagViewTabPaths.Metrics, true);
    else if (location.pathname.indexOf(TagViewTabPaths.Videos) > 0 && currentTab !== TagViewTabPaths.Videos)
      setCurrentTab(TagViewTabPaths.Videos, true);
    else if (!AVAILABLE_TABS.some((tab) => location.pathname.indexOf(tab) > 0)) {
      setCurrentTab(TagViewTabPaths.Videos, true);
      navigate(`${pathnameBase}/${TagViewTabPaths.Videos}${location.search}`, {
        replace: true,
      });
    }
  }, [currentTab, setCurrentTab, location, pathnameBase, navigate]);

  const onChangeTab = (tab: Tab, tabIndex: number) => {
    navigate(`${pathnameBase}/${AVAILABLE_TABS[tabIndex]}${location.search}`, {
      replace: true,
    });
    logEvent({
      component: 'Single Tag View',
      action: `Click ${camelCaseToCapitalCase(AVAILABLE_TABS[tabIndex])}`,
      category: 'user_actions',
    });
    setCurrentTab(AVAILABLE_TABS[tabIndex], true);
  };

  return (
    <Container>
      <Tabs
        variant="gray-button"
        tabLabels={tabLabels}
        onTabChange={onChangeTab}
        defaultSelectedTab={tabLabels.find((tab) => tab.label === getTabLabel(currentTab))}
      />
      <TabContainer>
        <AnimatePresence exitBeforeEnter>
          <Routes>
            <Route path={`${TagViewTabPaths.Videos}/*`} element={<TagVideos />} />
            <Route path={`${TagViewTabPaths.Metrics}/*`} element={<TagMetrics />} />
            <Route path="/" element={<Navigate replace to={`${TagViewTabPaths.Metrics}${location.search}`} />} />
          </Routes>
        </AnimatePresence>
      </TabContainer>
    </Container>
  );
};

export default TagViewTabs;
