import { Colors } from '@replai-platform/ui-components';
import styled from 'styled-components';

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1.25rem;
  margin-bottom: 1.5625rem;
  background-color: ${Colors.Gray[200]};
`;
