import { IntegrationIconProps } from './types';

const YandexMusic = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7002 30.0001C22.162 30.0001 27.4003 24.9599 27.4003 18.7425C27.4003 12.5251 22.162 7.48486 15.7002 7.48486C9.23834 7.48486 4 12.5251 4 18.7425C4 24.9599 9.23834 30.0001 15.7002 30.0001Z"
      fill="#FFCC00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7002 7.27697V17.7794C21.7002 18.8364 21.621 19.8221 21.4729 20.3249C21.0986 21.5884 20.2877 22.6913 19.1739 23.4514C18.0601 24.2115 16.7102 24.5833 15.3472 24.5054C13.9841 24.4275 12.6894 23.9045 11.677 23.023C10.6646 22.1414 9.99497 20.9539 9.7788 19.6566C9.56263 18.3594 9.81282 17.03 10.488 15.8881C11.1633 14.7461 12.2231 13.86 13.4926 13.3759C14.762 12.8919 16.165 12.839 17.4699 13.226C18.7748 13.6129 19.9035 14.4166 20.6696 15.5042L20.5009 14.5145V2L28.0003 4.30892V9.21674L21.7002 7.27697Z"
      fill="#FF3333"
    />
  </svg>
);
export default YandexMusic;
