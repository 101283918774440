import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useQuery, useQueryClient } from 'react-query';
import millify from 'millify';
import * as SDK from '@replai-platform/sdk';
import {
  TagPreview,
  TagPreviewProps,
  RequestStatus,
  SnackbarMessage,
  camelCaseToCapitalCase,
} from '@replai-platform/ui-components';
import type { RootState } from '../../../store/rootReducer';
import { api } from '../../../api';
import { Page } from '../../../utils/enums';
import { logEventOnAction, MAX_PREVIEW_ITEMS, TAG_SUGGESTIONS_LOCKED_FEATURE_NAME } from './utils';
import { PreviewedTag } from './types';
import useAvailableLockedFeatures from '../../../api/hooks/projects/useAvailableLockedFeatures';
import usePutLockedFeatureUserInterest from '../../../api/hooks/projects/usePutLockedFeatureUserInterest';
import { CUSTOMER_SUCCESS_EMAIL_ADDRESS } from '../../../utils/constants';

const logPreviewEvent = (params: { action: string; componentSuffix?: string; params?: object }) =>
  logEventOnAction({ componentSuffix: 'Tag Preview', ...params });

const getRawTagType = (tag: PreviewedTag) => (tag.type === 'tag' ? tag.type : tag.tagType);

interface TagPreviewWrapperProps {
  tag: PreviewedTag;
  tagRequested: boolean;
  tagMetrics?: SDK.TagMetrics;
  videoPreviews?: SDK.PreviewVideo[];
}

const TagPreviewWrapper: React.VFC<TagPreviewWrapperProps> = ({ tag, tagRequested, tagMetrics, videoPreviews }) => {
  const [tagPreviewProps, setTagPreviewProps] = useState<TagPreviewProps & { tagId: SDK.UUID }>();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(tagRequested ? 'requested' : 'disabled');

  const projectId = useSelector((state: RootState) => state.project.id);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const requestTag = usePutLockedFeatureUserInterest();

  const tagsDescriptionParams = useMemo(
    () => ({
      projectIds: [projectId],
      tags: [tagPreviewProps?.tagId ?? ''],
    }),
    [projectId, tagPreviewProps?.tagId]
  );
  const { data: tagDescription } = useQuery<string>(
    ['tags.getDescription', tagsDescriptionParams],
    () =>
      api.tags
        .getDescription(tagsDescriptionParams)
        .then((tagDescriptions) => tagDescriptions?.tags?.[0]?.description ?? ''),
    { enabled: (tagPreviewProps?.isOpen ?? false) && tag.type === 'tag' }
  );

  // for core tags, we are not fetching the preview because we use an icon
  // so we need to fetch here
  const previewInfoParams = useMemo(
    () => ({
      projectId,
      tagIds: [tagPreviewProps?.tagId ?? ''],
      maxRecords: MAX_PREVIEW_ITEMS,
    }),
    [projectId, tagPreviewProps?.tagId]
  );
  const { data: { tags: preview } = {} } = useQuery<SDK.GetTagsPreviewsResponse>(
    ['tags.getPreviewInfo', previewInfoParams],
    () => api.tags.getPreviewInfo(previewInfoParams),
    { enabled: !!tagPreviewProps && !videoPreviews && tag.type === 'tag' }
  );

  const availableLockedFeaturesParams = useMemo(() => ({ projectId }), [projectId]);
  const { data: { availableLockedFeatures } = {} } = useAvailableLockedFeatures(availableLockedFeaturesParams, {
    enabled: tag.type === 'new-tag' && !tagRequested,
  });
  const featureId = availableLockedFeatures?.find((f) => f.name === TAG_SUGGESTIONS_LOCKED_FEATURE_NAME)?.id;

  const onTagPreviewClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setTagPreviewProps((prevTagPreview) => ({ ...prevTagPreview!, isOpen: false }));
    logPreviewEvent({ action: 'Click Close' });
  };

  const onSeeAllVideosClick = () => {
    navigate(`/${projectId}/${Page.Tags}/${tagPreviewProps?.tagId ?? ''}/videos`);
    logPreviewEvent({ action: 'Click See All Videos' });
  };

  const onContactUsClick = () => {
    logPreviewEvent({ action: 'Click Contact Us', params: { tagType: getRawTagType(tag) } });
    window.location.href = `mailto:${CUSTOMER_SUCCESS_EMAIL_ADDRESS}`;
  };

  const onRequestThisClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    requestTag.mutate({ projectId, featureId: featureId!, featureSubtype: getRawTagType(tag) });
    logPreviewEvent({ action: 'Click Request Tag', params: { tagType: getRawTagType(tag) } });
  };

  useEffect(() => {
    if (!tagRequested) {
      if (featureId) {
        setRequestStatus('enabled');
      } else {
        setRequestStatus('disabled');
      }
      // prevent change from 'succeeded' screen while modal is open
    } else if (!tagPreviewProps?.isOpen) {
      setRequestStatus('requested');
    }
  }, [featureId, tagRequested, tagPreviewProps?.isOpen]);

  useEffect(() => {
    if (requestTag.isLoading) {
      setRequestStatus('requesting');
    } else if (requestTag.isSuccess) {
      setRequestStatus('succeeded');

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      SnackbarMessage({
        content: `You've requested a new tag: "${tagPreviewProps?.tag?.type || ''}"!`,
        state: 'success',
      });

      requestTag.reset();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries(['projects.getLockedFeatureUserInterest']);
    } else if (requestTag.isError) {
      setRequestStatus('enabled');

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      SnackbarMessage({
        content: 'There was an error requesting the new tag, please try again.',
        state: 'error',
      });

      requestTag.reset();
    }
  }, [
    requestTag.isLoading,
    requestTag.isSuccess,
    requestTag.isError,
    requestTag,
    tagPreviewProps?.tag?.type,
    queryClient,
  ]);

  useEffect(() => {
    if (tag) {
      switch (tag.type) {
        case 'tag':
          setTagPreviewProps({
            tagId: tag.id,
            isOpen: true,
            onClose: onTagPreviewClose,
            type: 'tag',
            tag: {
              type: camelCaseToCapitalCase(tag.type),
              value: tag.value,
            },
            ...(tagMetrics && {
              frequency: {
                current: millify(tagMetrics.individualObjectCount),
                total: millify(tagMetrics.totalObjectCount),
                percentage: (tagMetrics.individualObjectCount / tagMetrics.totalObjectCount) * 100,
              },
              spend: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                current: millify(tagMetrics.metrics.spend!),
                total: millify(tagMetrics.totalSpend),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                percentage: (tagMetrics.metrics.spend! / tagMetrics.totalSpend) * 100,
              },
            }),
          });
          break;
        case 'new-tag':
          setTagPreviewProps({
            tagId: tag.tagId,
            isOpen: true,
            onClose: onTagPreviewClose,
            type: 'new-tag',
            tag: {
              type: camelCaseToCapitalCase(tag.tagType),
              value: tag.tagValue,
            },
          });
          break;
        default:
          break;
      }
    }
  }, [tag, tagMetrics]);

  return tagPreviewProps ? (
    <TagPreview
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...tagPreviewProps}
      onSeeAllVideosClick={onSeeAllVideosClick}
      onRequestThisClick={onRequestThisClick}
      onContactUsClick={onContactUsClick}
      onTimelineClick={() => logPreviewEvent({ action: 'Click Timeline' })}
      tagDescription={tag.type === 'new-tag' ? '' : tagDescription}
      videos={
        (videoPreviews || preview?.[0]?.videos)?.map((v) => ({
          url: new URL(v.url),
          timeframes: v.timeframes ?? [],
        })) ?? undefined
      }
      requestStatus={requestStatus}
    />
  ) : null;
};

export default TagPreviewWrapper;
