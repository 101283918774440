/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { Checkbox, Colors, Input, InputProps } from '..';
import '../common.module.css';
import * as Icons from '../Icons';
import * as Styled from './styles';
import Typography from '../Typography/Typography';
import { useUpdateEffect } from 'react-use';

interface MultiSelectDropDownOption {
  id?: string;
  label?: string;
  selected?: boolean;
  type?: 'option' | 'divider';
  isAllOption?: boolean;
}

interface MultiSelectDropDownProps {
  input?: InputProps;
  options: MultiSelectDropDownOption[];
  onChange?: (options: MultiSelectDropDownOption[]) => void;
  title?: string;
  id?: string;
  includeContainerCard?: boolean; // being used inside another component
  collapsable?: boolean;
  collapsedByDefault?: boolean;
  onCollapseClick?: (isCollapsed?: boolean) => void;
  onShowMoreClick?: (id: string) => void;
  limit?: number;
  disableSearch?: boolean;
  disableScroll?: boolean;
  maxHeightInVH?: number;
}

const selectOrDeselectAllOption = (
  updatedOptions: MultiSelectDropDownOption[],
  inputText?: string
) => {
  // if a regular option is being selected/de-selected, the all option (if it exists) needs to
  // get selected (if all options are selected) or de-selected (if one of the options gets de-selected)
  const isEveryOptionSelected = !(
    inputText
      ? updatedOptions.filter(
          (o) =>
            o.label?.toLowerCase().includes(inputText.toLowerCase()) ||
            o.isAllOption
        )
      : updatedOptions
  ).find(
    (option) =>
      !option.isAllOption && !option.selected && option.type !== 'divider'
  );
  return updatedOptions.map((option) =>
    option.isAllOption ? { ...option, selected: isEveryOptionSelected } : option
  );
};

const MultiSelectDropDown = ({
  input = {},
  options,
  onChange,
  title,
  id,
  collapsable = false,
  collapsedByDefault = false,
  onCollapseClick,
  onShowMoreClick,
  includeContainerCard = true,
  limit = 0,
  disableSearch = false,
  disableScroll = false,
  maxHeightInVH,
}: MultiSelectDropDownProps) => {
  const [inputText, setInputText] = useState('');
  const [currentOptions, setCurrentOptions] = useState(
    selectOrDeselectAllOption(options)
  );
  const [isHovering, setIsHovering] = useState(options.map(() => false));
  const [isCollapsed, setIsCollapsed] = useState(collapsedByDefault);
  // Only show the "show all" button if limit is 0 OR when there are no selected options whose index is greater than the limit
  const [shouldShowAllOptions, setShowAllOptions] = useState(
    () =>
      limit === 0 ||
      options.filter((_, i) => i >= limit).find((option) => option.selected) !==
        undefined
  );
  const shouldShowSearch =
    (currentOptions.length > limit || limit === 0) &&
    !disableSearch &&
    !isCollapsed;

  useUpdateEffect(() => {
    onChange?.(currentOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentOptions)]);

  // ensuring when a parent changes the option, this component re-renders
  useEffect(() => {
    setCurrentOptions(selectOrDeselectAllOption(options));
  }, [options]);

  useEffect(() => {
    setShowAllOptions(
      limit === 0 ||
        currentOptions
          .filter((_, i) => i >= limit)
          .find((option) => option.selected) !== undefined
    );
  }, [limit, currentOptions]);

  const handleOptionSelect = (
    index: number,
    value: boolean,
    isAllOption?: boolean
  ) => {
    let updatedOptions = isAllOption
      ? options.map((o) =>
          !inputText
            ? { ...o, selected: value }
            : !o.label?.toLowerCase().includes(inputText.toLowerCase()) &&
              !o.isAllOption
            ? o
            : { ...o, selected: value }
        )
      : currentOptions.map((option, i) =>
          i === index ? { ...option, selected: value } : option
        );

    if (!isAllOption) {
      updatedOptions = selectOrDeselectAllOption(updatedOptions);
    }

    setCurrentOptions(updatedOptions);
  };

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newOptions = e.target.value
      ? options.map((o) => ({
          ...o,
          selected:
            (o.isAllOption
              ? false
              : currentOptions.filter((c) => c.id === o.id)?.[0]?.selected) ??
            false,
        }))
      : currentOptions;
    newOptions = selectOrDeselectAllOption(newOptions, e.target.value);
    setCurrentOptions(newOptions);
    setInputText(e.target.value);
    setShowAllOptions(!!e.target.value);
  };

  return (
    <Styled.Root
      data-test="multi-select-drop-down-container"
      $includeContainerCard={includeContainerCard}
      $disableScroll={disableScroll}
      $maxHeightInVH={maxHeightInVH}
    >
      <Styled.InputContainer
        data-test="multi-select-drop-down-search"
        $includeContainerCard={includeContainerCard}
      >
        {collapsable ? (
          <Styled.IconsContainer
            role="button"
            tabIndex={0}
            onClick={() => {
              setIsCollapsed(!isCollapsed);
              if (onCollapseClick) onCollapseClick(!isCollapsed);
            }}
            onKeyDown={() => {
              setIsCollapsed(!isCollapsed);
              if (onCollapseClick) onCollapseClick(!isCollapsed);
            }}
          >
            {isCollapsed ? (
              <Icons.BaseIcons.Plus
                color={Colors.Primary[700]}
                dimension={12}
              />
            ) : (
              <Icons.BaseIcons.Minus
                color={Colors.Primary[700]}
                dimension={12}
              />
            )}{' '}
            {title}
          </Styled.IconsContainer>
        ) : title ? (
          <Styled.TypographyNoMargin type="text-sm" fontWeight="semi-bold">
            {title}
          </Styled.TypographyNoMargin>
        ) : (
          ''
        )}
        {shouldShowSearch ? (
          <Input
            {...input}
            fullWidth
            value={inputText}
            onChange={(e) => handleChangeSearch(e)}
          />
        ) : (
          ''
        )}
      </Styled.InputContainer>
      {!isCollapsed &&
        (limit && !shouldShowAllOptions && !inputText
          ? currentOptions.slice(0, limit)
          : currentOptions
        ).map((option, index) => {
          if (option.type === 'divider') {
            return inputText ? (
              <React.Fragment key={`divider-${index}`} />
            ) : (
              <Styled.Divider key={`divider-${index}`} />
            );
          }
          if (option.selected === undefined || option.label === undefined) {
            throw new Error('Invalid option');
          }
          return !!inputText &&
            !option.label.toLowerCase().includes(inputText.toLowerCase()) &&
            !option.isAllOption ? (
            <React.Fragment key={`${option.label}-${index}`} />
          ) : (
            <Styled.Option
              data-test="multi-select-drop-down-option"
              key={`${option.label}-${index}`}
              onMouseEnter={() =>
                setIsHovering((isHovering) =>
                  isHovering.map((isHovering, i) =>
                    i === index ? true : isHovering
                  )
                )
              }
              onMouseLeave={() =>
                setIsHovering((isHovering) =>
                  isHovering.map((isHovering, i) =>
                    i === index ? false : isHovering
                  )
                )
              }
            >
              <Checkbox
                checked={option.selected}
                onClick={(c) =>
                  handleOptionSelect(index, c, option.isAllOption)
                }
                label={option.label}
                addHoverStyle={isHovering[index]}
              />
            </Styled.Option>
          );
        })}
      {!inputText &&
      !isCollapsed &&
      !shouldShowAllOptions &&
      currentOptions.length > limit ? (
        <Styled.ShowMoreOption
          data-test="show-more-button"
          role="button"
          tabIndex={0}
          onClick={() => {
            setShowAllOptions(true);
            onShowMoreClick?.((id ?? title) || '');
          }}
          onKeyDown={() => {
            setShowAllOptions(true);
            onShowMoreClick?.((id ?? title) || '');
          }}
        >{`Show ${currentOptions.length - limit} more`}</Styled.ShowMoreOption>
      ) : (
        ''
      )}
      {inputText &&
        options.filter((option) =>
          option.label?.toLowerCase().includes(inputText.toLowerCase())
        ).length === 0 && (
          <Styled.NoMatches data-test="multi-select-drop-down-no-matches">
            <Typography type="text-sm" style={{ marginTop: 0 }}>
              No matches found
            </Typography>
          </Styled.NoMatches>
        )}
    </Styled.Root>
  );
};

export default MultiSelectDropDown;
export type { MultiSelectDropDownOption, MultiSelectDropDownProps };
