import { IntegrationIconProps } from './types';

const JbDotpeek = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1334_1555)">
      <path
        d="M20.2514 9.55426L2.37714 13.8514L0 18.6514L6.44571 32L22.5371 29.2114L20.2514 9.55426Z"
        fill="url(#paint0_linear_1334_1555)"
      />
      <path
        d="M30.08 14.4L17.4629 6.40002L0 10.1029V18.6515L28.6171 24.3657L30.08 14.4Z"
        fill="url(#paint1_linear_1334_1555)"
      />
      <path
        d="M31.9998 14.3086L18.1027 0L7.22266 3.29143L10.7884 14.1257L28.6169 24.3657L31.9998 14.3086Z"
        fill="url(#paint2_linear_1334_1555)"
      />
      <path
        d="M29.4855 8.96L28.2512 0H23.4512H18.1027L7.22266 3.29143L10.7884 14.1257L29.4855 8.96Z"
        fill="url(#paint3_linear_1334_1555)"
      />
      <path d="M6.125 6.12567H25.8736V25.8742H6.125V6.12567Z" fill="black" />
      <path
        d="M7.95312 22.1714H15.3588V23.4057H7.95312V22.1714Z"
        fill="white"
      />
      <path
        d="M8 8.73138H11.2C13.76 8.73138 15.5429 10.5142 15.5429 12.8V12.8457C15.5429 15.1314 13.76 16.9142 11.2 16.9142H8V8.73138ZM9.78286 10.3771V15.3142H11.1543C12.6171 15.3142 13.6229 14.3085 13.6229 12.8914V12.8457C13.6229 11.3828 12.6171 10.3771 11.1543 10.3771H9.78286Z"
        fill="white"
      />
      <path
        d="M16.3203 8.73138H19.6117C21.5775 8.73138 22.766 9.91996 22.766 11.6114C22.766 13.4857 21.3032 14.4914 19.4746 14.4914H18.1032V16.9142H16.3203V8.73138ZM19.4746 12.8457C20.3889 12.8457 20.8917 12.2971 20.8917 11.6114C20.8917 10.7885 20.3432 10.3314 19.4289 10.3314H18.0575V12.8457H19.4746Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1334_1555"
        x1="4.97326"
        y1="12.2482"
        x2="21.5081"
        y2="29.1277"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.097" stopColor="#E343E6" />
        <stop offset="0.108" stopColor="#D948E8" />
        <stop offset="0.165" stopColor="#AC5DEF" />
        <stop offset="0.222" stopColor="#876FF5" />
        <stop offset="0.279" stopColor="#6B7DF9" />
        <stop offset="0.336" stopColor="#5687FC" />
        <stop offset="0.392" stopColor="#4A8DFE" />
        <stop offset="0.446" stopColor="#468FFF" />
        <stop offset="0.935" stopColor="#00CAFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1555"
        x1="4.416"
        y1="25.4839"
        x2="15.0139"
        y2="16.6524"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.097" stopColor="#E343E6" />
        <stop offset="0.118" stopColor="#DE45E7" />
        <stop offset="0.289" stopColor="#B951F0" />
        <stop offset="0.462" stopColor="#9D5AF6" />
        <stop offset="0.632" stopColor="#8861FB" />
        <stop offset="0.802" stopColor="#7C65FE" />
        <stop offset="0.968" stopColor="#7866FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_1555"
        x1="18.2192"
        y1="7.73806"
        x2="25.7625"
        y2="23.1557"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.199" stopColor="#E343E6" />
        <stop offset="0.31" stopColor="#DA46E8" />
        <stop offset="0.491" stopColor="#C24EEE" />
        <stop offset="0.718" stopColor="#9A5BF7" />
        <stop offset="0.887" stopColor="#7866FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1334_1555"
        x1="9.08551"
        y1="8.368"
        x2="28.0355"
        y2="3.77417"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.097" stopColor="#E343E6" />
        <stop offset="0.175" stopColor="#DA48E7" />
        <stop offset="0.302" stopColor="#C157EA" />
        <stop offset="0.464" stopColor="#996FEE" />
        <stop offset="0.653" stopColor="#6290F4" />
        <stop offset="0.863" stopColor="#1CB9FC" />
        <stop offset="0.941" stopColor="#00CAFF" />
      </linearGradient>
      <clipPath id="clip0_1334_1555">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default JbDotpeek;
