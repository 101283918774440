import { UUID } from '../../atomics';
import { MetricOrKpi } from '../../metrics';

export type Project = {
  id: UUID;
  name: string;
  icon: string;
  config: ProjectConfig;
  organizationId?: UUID;
  organizationName?: string;
  uiPreferences: any;
};

/**
 * Explicitly represents the tag's section of each database project JSON configuration
 */
export type ProjectConfigTags = {
  core: string[];
  custom: string[];
  excluded: string[];
  psychological: string[];
};

/**
 * Explicitly represents the project JSON configuration. This is currently incomplete, as such,
 * feel free to keep improving this type.
 */
export type ProjectConfig = Partial<{
  creativesListMinimumSpend: number;
  minSpend: number;
  tagsTypes: Partial<ProjectConfigTags>;
  topCreativesMinimumSpend: number;
  recommendationStarThresholds: number[];
  minRecommendationScore: number;
  minInsightScore: number;
  defaultProjectKpis: MetricOrKpi[];
  tagsKpis: MetricOrKpi[];
  marketGenres: string[];
  contextTagTypes?: string[];
  includeInactiveFirstPartyAssetsInMarketYouFrequency?: boolean;
  allowUntaggedMarketAssets?: boolean;
  replaiCreativeFilter?: string;
  creativeGenerationToolsAssets?: {
    textImageToVideoPath?: string;
    textVideoToVideoPath?: string;
    characterToVideoPath?: string;
    textImageToImagePath?: string;
  };
  accountTypes?: AccountTypes[];
}>;

/**
 * Represents a feature that is only enabled for select projects.
 */
export type LockedFeature = {
  id: number;
  name: string;
  description: string;
};

/**
 * Represents the interest of a user on a locked feature.
 */
export type LockedFeatureInterest = {
  id: number;
  subtype?: string;
};

export enum AccountTypes {
  ADS = 'ads',
  SOCIALS = 'social',
}
