import React, { RefObject, useEffect, useState } from 'react';
import { useLifecycles } from 'react-use';

export const useOverflow = (elementRef: RefObject<HTMLElement | null>) => {
  const [overflowStatus, setOverflowStatus] = useState(false);

  const checkOverflow = React.useCallback(() => {
    setOverflowStatus(
      elementRef.current
        ? elementRef.current.scrollHeight > elementRef.current.clientHeight ||
            elementRef.current.scrollWidth > elementRef.current.clientWidth
        : false
    );
  }, [elementRef]);

  useEffect(checkOverflow, [
    checkOverflow,
    elementRef,
    elementRef?.current,
    elementRef?.current?.scrollHeight,
    elementRef?.current?.clientHeight,
    elementRef?.current?.scrollWidth,
    elementRef?.current?.clientWidth,
  ]);

  useLifecycles(
    () => {
      checkOverflow();
      window.addEventListener('resize', checkOverflow);
    },
    () => {
      window.removeEventListener('resize', checkOverflow);
    }
  );

  return overflowStatus;
};
