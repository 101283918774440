import { CountryIconProps } from './types';

const KR = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M16.174 11.9996C16.174 13.0431 14.3052 15.6517 12.0001 15.6517C9.69489 15.6517 7.82617 13.0431 7.82617 11.9996C7.82617 9.69436 9.69489 7.82568 12.0001 7.82568C14.3052 7.82568 16.174 9.69436 16.174 11.9996Z"
      fill="#D80027"
    />
    <path
      d="M16.174 11.9995C16.174 14.3047 14.3052 16.1734 12.0001 16.1734C9.69489 16.1734 7.82617 14.3047 7.82617 11.9995"
      fill="#0052B4"
    />
    <path
      d="M17.5335 14.5826L16.4268 15.6893L17.1646 16.4272L18.2714 15.3204L17.5335 14.5826Z"
      fill="black"
    />
    <path
      d="M15.6888 16.4268L14.582 17.5336L15.3199 18.2714L16.4266 17.1647L15.6888 16.4268Z"
      fill="black"
    />
    <path
      d="M19.7474 16.7947L18.6406 17.9015L19.3785 18.6393L20.4852 17.5326L19.7474 16.7947Z"
      fill="black"
    />
    <path
      d="M17.9027 18.6409L16.7959 19.7477L17.5337 20.4855L18.6405 19.3788L17.9027 18.6409Z"
      fill="black"
    />
    <path
      d="M18.6409 15.6896L17.5342 16.7964L18.272 17.5342L19.3788 16.4275L18.6409 15.6896Z"
      fill="black"
    />
    <path
      d="M16.7962 17.5339L15.6895 18.6406L16.4273 19.3785L17.5341 18.2717L16.7962 17.5339Z"
      fill="black"
    />
    <path
      d="M15.6893 5.35821L18.6406 8.30957L19.3785 7.57172L16.4271 4.62037L15.6893 5.35821Z"
      fill="black"
    />
    <path
      d="M14.5827 6.46612L15.6895 7.57288L16.4273 6.83506L15.3205 5.7283L14.5827 6.46612Z"
      fill="black"
    />
    <path
      d="M16.4274 8.31L17.5342 9.41675L18.272 8.67893L17.1652 7.57218L16.4274 8.31Z"
      fill="black"
    />
    <path
      d="M16.7946 4.25225L17.9014 5.35901L18.6392 4.62116L17.5325 3.5144L16.7946 4.25225Z"
      fill="black"
    />
    <path
      d="M18.6413 6.09771L19.748 7.20447L20.4859 6.46662L19.3791 5.35987L18.6413 6.09771Z"
      fill="black"
    />
    <path
      d="M7.20331 19.748L4.25195 16.7966L3.5141 17.5345L6.46546 20.4858L7.20331 19.748Z"
      fill="black"
    />
    <path
      d="M8.31086 18.64L7.2041 17.5332L6.46629 18.271L7.57304 19.3778L8.31086 18.64Z"
      fill="black"
    />
    <path
      d="M6.46613 16.796L5.35938 15.6892L4.62153 16.4271L5.72828 17.5338L6.46613 16.796Z"
      fill="black"
    />
    <path
      d="M9.41718 17.5336L6.46582 14.5823L5.72797 15.3201L8.67933 18.2715L9.41718 17.5336Z"
      fill="black"
    />
    <path
      d="M4.25274 7.20465L7.2041 4.2533L6.46625 3.51545L3.5149 6.46681L4.25274 7.20465Z"
      fill="black"
    />
    <path
      d="M5.35821 8.30976L8.30957 5.3584L7.57172 4.62055L4.62036 7.57191L5.35821 8.30976Z"
      fill="black"
    />
    <path
      d="M6.46661 9.41706L9.41797 6.4657L8.68015 5.72788L5.7288 8.67924L6.46661 9.41706Z"
      fill="black"
    />
  </svg>
);
export default KR;
