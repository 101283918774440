import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { MultiSelectDropDownOption } from '@replai-platform/ui-components';
import * as SDK from '@replai-platform/sdk';
import type { RootState } from '../../../store/rootReducer';
import useGetCountries from '../../../api/hooks/countries/useGetCountries';
import { ProjectActions } from '../../../store/project';

const countryCodeToNameMapper = new Intl.DisplayNames(['en'], { type: 'region' });

const useCountryFilter = ({
  selectedCountries,
}: {
  selectedCountries: string[];
}): {
  countryFilterOptions: MultiSelectDropDownOption[];
  hasData: boolean;
  isLoading: boolean;
  isInitialFilter: boolean;
  setIsInitialFilter: (i: boolean) => void;
} => {
  const dispatch = useDispatch();
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectBaseMetric = useSelector((state: RootState) => state.project.baseMetric);
  const isInitialFilter = useRef(true);

  const { since, until } = SDK.getDateRangeForRecommendations();

  const setIsInitialFilter = (i: boolean) => {
    isInitialFilter.current = i;
  };

  // Fetch and update project networks
  const { isSuccess, data, isLoading } = useGetCountries({
    projectIds: [projectId],
    metrics: [projectBaseMetric],
    orderBy: {
      condition: SDK.OrderByCondition.DESC_NULLS_LAST,
      value: projectBaseMetric,
    },
    metricsFilters: {
      dateStartTimestamp: since.getTime(),
      dateEndTimestamp: until.getTime(),
      countriesToExclude: ['ALL'],
    },
  });

  const mappedCountries = useMemo(
    () =>
      data?.countriesMetrics
        ?.filter((countryMetric) => (countryMetric.metrics[projectBaseMetric] ?? 0) > 0)
        .map((countryMetric) => ({
          country: countryMetric.country,
          spend: countryMetric.metrics.spend ?? 0,
        })) ?? [],
    [data, projectBaseMetric]
  );

  useEffect(() => {
    if (isSuccess && mappedCountries?.length > 0) {
      dispatch(ProjectActions.changeCountries(mappedCountries));
    }
  }, [dispatch, isSuccess, mappedCountries]);

  const getCountryName = (countryCode: string): string => {
    let countryName = countryCode;
    try {
      countryName = countryCodeToNameMapper.of(countryCode) ?? '';
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      console.error('Invalid country code', { countryCode, error });
    }

    return countryName;
  };

  // Create filter options
  const countryFilterOptions = useMemo(
    () =>
      isSuccess && data
        ? [
            {
              label: 'All Countries',
              isAllOption: true,
              selected: selectedCountries.length === mappedCountries.length,
            },
            ...mappedCountries.map((countryMetric) => ({
              label: getCountryName(countryMetric.country),
              id: countryMetric.country,
              selected: selectedCountries.includes(countryMetric.country),
            })),
          ]
        : [],
    [mappedCountries, data, isSuccess, selectedCountries]
  );

  return {
    countryFilterOptions,
    isLoading,
    hasData: countryFilterOptions.length > 0,
    setIsInitialFilter,
    isInitialFilter: isInitialFilter.current,
  };
};

export default useCountryFilter;
