import { ReactNode } from 'react';
import { TooltipProps } from 'recharts';
import styled from 'styled-components';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import Typography from '../Typography/Typography';
import Shadow from '../Shadow/Shadow';
import { Colors, Color } from '..';

const StyledShadow = styled(Shadow)`
  padding: 12px;
  background-color: ${Colors.Common.White};
  border-radius: 8px;
`;

const TooltipLabel = styled(Typography)`
  margin-top: 0;
`;

const TooltipValueContainer = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 5px;
  }
`;

const TooltipValue = styled(Typography)`
  margin: 0 0 0 4px;
`;

const Bar = styled.div<{ color: Color }>`
  display: inline-block;
  width: 4px;
  height: 12px;
  background-color: ${({ color }) => color};
  border-radius: 5px;
`;

const LineChartTooltip = ({
  payload,
  label,
  formatter,
}: TooltipProps<ValueType, NameType>): ReactNode | null => {
  if (!payload) {
    return null;
  }

  // do not show value from area chart in the tooltip
  // we need to manually filter values with type = 'none' (https://github.com/recharts/recharts/issues/368)
  const filteredValues = payload.filter((p) => p.type !== 'none');

  const renderValue = (value: ValueType | null | undefined, i: number) => {
    if (value === null || value === undefined) {
      return 'N/A';
    }

    if (formatter) {
      return formatter(value, undefined, i) as string;
    }

    return Intl.NumberFormat('en-GB', {
      maximumFractionDigits: 2,
    }).format(+value);
  };

  return (
    <StyledShadow size="lg">
      <TooltipLabel type="text-xs" color={Colors.Gray[500]}>
        {label}
      </TooltipLabel>
      {filteredValues.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <TooltipValueContainer key={i}>
          <Bar color={item.color as Color} />
          <TooltipValue type="text-sm" fontWeight="semi-bold">
            {renderValue(item.value, i)}
          </TooltipValue>
          <TooltipValue type="text-xs" color={Colors.Gray[500]}>
            {item.name}
          </TooltipValue>
        </TooltipValueContainer>
      ))}
    </StyledShadow>
  );
};

export default LineChartTooltip;
