import { messages } from '@replai-platform/sdk';
import * as SDK from '@replai-platform/sdk';
import { MultiSelectDropDownOption } from '@replai-platform/ui-components';
import { api } from '../../../api';
import { FiltersState } from '../../../store/filters';

export async function fetchCampaigns(
  projectId: SDK.UUID,
  currentFiltering: FiltersState
): Promise<MultiSelectDropDownOption[]> {
  return api.campaigns
    .getAllCampaigns({
      projectId,
      metricsFilters: {
        // Filter only by date range. This is important to guarantee good performance and to ensure results are
        // ordered by spend according to the data in the selected time range.
        countriesToConsider: ['ALL'],
        ...(({ dateEndTimestamp, dateStartTimestamp }) => ({ dateEndTimestamp, dateStartTimestamp }))(
          api.filterConverter.getMetricsFilters(currentFiltering)
        ),
      },
    })
    .then((values: SDK.GetCampaignsResponse) => {
      const nothingSelected =
        currentFiltering.campaignIdsToConsider.length === 0 && currentFiltering.campaignIdsToExclude.length === 0;
      const toExclude = !nothingSelected && currentFiltering.campaignIdsToExclude.length > 0;

      const campaigns = values.campaigns
        .map(
          (campaign) =>
            ({
              label: campaign.name ? campaign.name : messages.NOT_AVAILABLE,
              type: 'option',
              selected: nothingSelected
                ? false
                : toExclude
                ? !currentFiltering.campaignIdsToExclude?.includes(campaign.id)
                : currentFiltering.campaignIdsToConsider?.includes(campaign.id),
              id: campaign.id,
            } as MultiSelectDropDownOption)
        )
        .sort((a, b) => (a.selected === b.selected ? 0 : a.selected ? -1 : 1));

      const options: MultiSelectDropDownOption[] = [
        {
          id: 'All',
          label: 'All',
          isAllOption: true,
          type: 'option',
          selected: false,
        },
        ...campaigns,
      ];

      if (options.filter((o) => o.selected)?.length === options.length - 1) {
        options[0].selected = true;
      }

      return options;
    });
}
