import styled from 'styled-components';
import { Badge, Skeleton, Typography } from '@replai-platform/ui-components';
import { CellProps } from 'react-table';
import 'react-loading-skeleton/dist/skeleton.css';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';
import { FooterContainer } from '../common/styles';

export type ColumnData = {
  numInstances?: number;
  numTotalInstances?: number;
  loading?: boolean;
};

const TypographyNoMargin = styled(Typography)`
  margin: 0;
`;

function getNumInstancesColumn<D extends object = object>({
  columnId,
  accessor,
  includeFooter,
}: RequiredGeneratorParams<D, ColumnData>): GeneratedColumn<D, ColumnData> {
  return {
    id: columnId,
    accessor,
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    Cell: (data: CellData<D, ColumnData>) => {
      const loading = data.cell.value?.loading;

      if (loading) {
        return <Skeleton height={20} width={100} />;
      }

      const { numInstances } = data.cell.value;

      return (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Badge color="Gray">{numInstances && numInstances >= 0 ? numInstances : 'N/A'}</Badge>{' '}
        </div>
      );
    },
    Footer: (info: CellProps<D, ColumnData>) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!info?.data?.[0]) {
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const temp = accessor(info?.data?.[0] as D);
      return includeFooter && temp.numTotalInstances ? (
        <FooterContainer style={{ marginLeft: '0.75rem' }}>
          <TypographyNoMargin fontWeight="medium" type="text-sm">
            {temp.numTotalInstances}
          </TypographyNoMargin>
        </FooterContainer>
      ) : null;
    },
  };
}

export default getNumInstancesColumn;
