import * as SDK from '@replai-platform/sdk';
import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ConceptPreview from '../../../components/ConceptPreview/ConceptPreview';
import { useGetCreativeThumbnailsRequest } from '../../../api/hooks/creativeProduction/useCreativeThumbnails';
import { RootState } from '../../../store/rootReducer';

const NUMBER_OF_THUMBNAILS = 4;

interface ConceptPreviewWrapperProps {
  concept: SDK.GetAllProductionConceptsResponse['results'][number];
  isOpen: boolean;
  onClose: () => void;
}

const ConceptPreviewWrapper = ({ concept, isOpen, onClose }: ConceptPreviewWrapperProps) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [versionNumber, setVersionNumber] = useState(concept.versions.length);

  // Update concept version number if he changes
  useEffect(() => {
    setVersionNumber(concept.versions.length);
  }, [concept]);

  const currentVersion = useMemo(
    () => concept.versions[concept.versions.length - versionNumber],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [concept, versionNumber]
  );

  const { data: creativeThumbnails } = useGetCreativeThumbnailsRequest(
    projectId,
    currentVersion.assets[currentSlide]?.id,
    { numberOfThumbnails: NUMBER_OF_THUMBNAILS },
    {
      enabled: (currentVersion.assets[currentSlide]?.id?.length ?? 0) > 0,
    }
  );

  return (
    <ConceptPreview
      projectId={projectId}
      productionConceptId={currentVersion.productionConceptId}
      isOpen={isOpen}
      title={concept.conceptName ?? SDK.messages.NOT_AVAILABLE}
      onClose={onClose}
      onPlayClick={() => null}
      onPauseClick={() => null}
      onSlideChange={setCurrentSlide}
      onVersionChange={setVersionNumber}
      totalVersions={concept.versions.length}
      prompt={concept.prompt && concept.prompt.length > 0 ? concept.prompt : 'N/A'}
      videos={currentVersion.assets
        .filter((c): c is typeof c & { url: string } => typeof c.url === 'string')
        .map((creative) => ({
          url: new URL(creative.url),
          thumbnails: creativeThumbnails?.thumbnails,
          timeframes: [],
          label: creative.label,
        }))}
      versionPrompt={currentVersion.prompt}
    />
  );
};

export default ConceptPreviewWrapper;
