import { CountryIconProps } from './types';

const NE = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3681)">
      <path
        d="M1.19072 6.78213C0.427875 8.35976 0 10.1296 0 11.9995C0 13.8694 0.427875 15.6393 1.19072 17.2169L12 18.2604L22.8093 17.2169C23.5721 15.6393 24 13.8694 24 11.9995C24 10.1296 23.5721 8.35976 22.8093 6.78213L12 5.73865L1.19072 6.78213Z"
        fill="#F0F0F0"
      />
      <path
        d="M1.19043 17.2183C3.13134 21.2322 7.24176 24.0009 11.9993 24.0009C16.7568 24.0009 20.8672 21.2322 22.8081 17.2183H1.19043Z"
        fill="#6DA544"
      />
      <path
        d="M1.19043 6.78238H22.8081C20.8672 2.76843 16.7568 -0.000244141 11.9993 -0.000244141C7.24181 -0.000244141 3.13134 2.76843 1.19043 6.78238Z"
        fill="#FF9811"
      />
      <path
        d="M11.9991 16.1742C14.3043 16.1742 16.173 14.3055 16.173 12.0003C16.173 9.69513 14.3043 7.82642 11.9991 7.82642C9.69391 7.82642 7.8252 9.69513 7.8252 12.0003C7.8252 14.3055 9.69391 16.1742 11.9991 16.1742Z"
        fill="#FF9811"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3681">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default NE;
