import { LockedModal, RequestStatus, SnackbarMessage } from '@replai-platform/ui-components';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import useAvailableLockedFeatures from '../../api/hooks/projects/useAvailableLockedFeatures';
import usePutLockedFeatureUserInterest from '../../api/hooks/projects/usePutLockedFeatureUserInterest';
import { RootState } from '../../store/rootReducer';
import { CUSTOMER_SUCCESS_EMAIL_ADDRESS } from '../../utils/constants';

type LockedNavigationItemModalProps = {
  featureName: string;
  isOpen?: boolean;
  content: {
    title?: string;
    subtitle?: string;
  };
  onClose: () => void;
};

const LockedNavigationItemModal = ({ featureName, isOpen, onClose, content }: LockedNavigationItemModalProps) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>('disabled');
  const queryClient = useQueryClient();

  const unlockRequest = usePutLockedFeatureUserInterest();

  const availableLockedFeaturesParams = useMemo(() => ({ projectId }), [projectId]);
  const { data: { availableLockedFeatures } = {} } = useAvailableLockedFeatures(availableLockedFeaturesParams, {
    enabled: isOpen,
  });
  const featureId = availableLockedFeatures?.find((f) => f.name === featureName)?.id;

  const onUnlockRequest = () => {
    if (featureId !== null && featureId !== undefined) {
      unlockRequest.mutate({ projectId, featureId });
    }
    logEvent({
      action: `Click Request "${featureName}"`,
      component: 'Menu',
      category: 'user_actions',
    });
  };

  useEffect(() => {
    if (featureId) {
      setRequestStatus('enabled');
    } else {
      setRequestStatus('disabled');
    }
  }, [featureId]);

  useEffect(() => {
    if (unlockRequest.isLoading) {
      setRequestStatus('requesting');
    } else if (unlockRequest.isSuccess) {
      setRequestStatus('succeeded');

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      SnackbarMessage({
        content: content?.title
          ? `You've requested a new feature: "${content?.title}"!`
          : "You've requested a new feature!",
        state: 'success',
      });

      unlockRequest.reset();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries(['projects.getLockedFeatureUserInterest']);
    } else if (unlockRequest.isError) {
      setRequestStatus('enabled');

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      SnackbarMessage({
        content: 'There was an error requesting the new tag, please try again.',
        state: 'error',
      });

      unlockRequest.reset();
    }
  }, [
    unlockRequest.isLoading,
    unlockRequest.isSuccess,
    unlockRequest.isError,
    unlockRequest,
    queryClient,
    content?.title,
  ]);

  return (
    <LockedModal
      isOpen={!!isOpen}
      setIsOpen={(open) => {
        if (!open) {
          setRequestStatus(featureId ? 'enabled' : 'disabled');
          logEvent({
            action: `Close Locked Feature Modal`,
            component: 'Menu',
            category: 'user_actions',
          });
          onClose();
        }
      }}
      content={content ?? {}}
      requestConfig={{
        onContactUsClick: () => {
          logEvent({
            action: `Click Contact Us ("${featureName}" feature)`,
            component: 'Menu',
            category: 'user_actions',
          });
          window.location.href = `mailto:${CUSTOMER_SUCCESS_EMAIL_ADDRESS}`;
        },
        onRequest: () => onUnlockRequest(),
        status: requestStatus,
        successTitle: `You have requested the "${featureName}" feature`,
        successMessage:
          'Our team is processing your request. Feel free to contact us to receive more detailed information about your request or its status',
      }}
    />
  );
};

export default LockedNavigationItemModal;
