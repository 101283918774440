import { CountryIconProps } from './types';

const BB = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3569)">
      <path
        d="M17.2175 1.19084C15.6399 0.427997 13.87 0.00012207 12.0001 0.00012207C10.1303 0.00012207 8.36037 0.427997 6.78274 1.19084L5.73926 12.0001L6.78274 22.8094C8.36037 23.5722 10.1303 24.0001 12.0001 24.0001C13.87 24.0001 15.6399 23.5722 17.2175 22.8094L18.261 12.0001L17.2175 1.19084Z"
        fill="#FFDA44"
      />
      <path
        d="M6.78262 1.19128C2.76872 3.13219 0 7.24261 0 12.0001C0 16.7577 2.76872 20.8681 6.78262 22.809V1.19128Z"
        fill="#0052B4"
      />
      <path
        d="M17.2178 1.19165V22.8094C21.2317 20.8685 24.0004 16.758 24.0004 12.0005C24.0004 7.24303 21.2317 3.13256 17.2178 1.19165Z"
        fill="#0052B4"
      />
      <path
        d="M15.6518 7.30337L16.3518 7.65334L15.6518 7.30337L14.9518 6.9534C14.9096 7.03783 13.9956 8.8975 13.8463 11.7382H12.7822V7.30337L11.9996 6.25989L11.217 7.30337V11.7382H10.153C10.0036 8.8975 9.08966 7.03783 9.04743 6.9534L7.64746 7.65339C7.65712 7.67261 8.60835 9.60878 8.60835 12.5208V13.3034H11.217V17.7382H12.7822V13.3034H15.3909V12.5207C15.3909 11.0152 15.6514 9.77378 15.87 8.99744C16.1083 8.15087 16.3503 7.65648 16.3527 7.65156L15.6518 7.30337Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3569">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default BB;
