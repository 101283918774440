import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as SDK from '@replai-platform/sdk';
import { batch } from 'react-redux';
import { isoDateSubtract } from '../../utils';
import { toFormattedDate } from '../../components/FilterBar/DatesPicker/helpers';

const todayDate = new Date();
export const today = todayDate.toISOString().substring(0, 10);

const yesterdayDate = new Date(todayDate);
yesterdayDate.setDate(todayDate.getDate() - 1);
export const yesterday = yesterdayDate.toISOString().substring(0, 10);

export const DEFAULT_DATE_RANGE_DAYS = 30;
const DEFAULT_MAX_TOP_ITEMS = 10;
export const defaultStartDate = isoDateSubtract(yesterday, DEFAULT_DATE_RANGE_DAYS);
export const defaultEndDate = yesterday;

export const defaultCompareToEndDate = isoDateSubtract(defaultEndDate, DEFAULT_DATE_RANGE_DAYS + 1);
export const defaultCompareToStartDate = isoDateSubtract(defaultCompareToEndDate, DEFAULT_DATE_RANGE_DAYS);

export enum GeneratingReportNotificationStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  TODO = 'TODO',
}
type ReportsState = {
  template: SDK.GoogleSlidesTemplates;
  defaultSortByKpi?: SDK.MetricOrKpi;
  selectedTagTypes: string[];
  selectedKpis: SDK.MetricOrKpi[];
  generatingReportNotification: GeneratingReportNotificationStatus;
  selectedDate: { startDate?: string; endDate?: string; rangeDays?: string | number };
  selectedCompareToDate: { startDate?: string; endDate?: string; rangeDays?: string | number };
  ageSelectedDate: { ageStartDate: string | null; ageEndDate: string | null; ageRangeDays: string | number };
  adSetIdsToConsider: string[];
  adSetIdsToExclude: string[];
  campaignIdsToConsider: string[];
  campaignIdsToExclude: string[];
  countries: string[];
  maxFirstAppearanceSeconds: number | null;
  networks: SDK.Network[];
  promotedObjectTypes: SDK.PromotedObjectType[];
  tagsToConsider: SDK.Tag[];
  tagsToExclude: SDK.Tag[];
  trendSlidesToInclude: SDK.TrendSlideToInclude[];
  breakdown: SDK.Breakdown;
  minSpend?: number;
  maxTopItems: number;
  conceptLevel: boolean;
  templateId?: SDK.UUID;
  baseSpreadsheetId?: string;
};

export const initialState: ReportsState = {
  template: SDK.GoogleSlidesTemplates.TOP_CREATIVES,
  selectedTagTypes: [],
  selectedKpis: [],
  generatingReportNotification: GeneratingReportNotificationStatus.TODO,
  selectedDate: { startDate: defaultStartDate, endDate: defaultEndDate, rangeDays: DEFAULT_DATE_RANGE_DAYS },
  selectedCompareToDate: {
    startDate: defaultCompareToStartDate,
    endDate: defaultCompareToEndDate,
    rangeDays: DEFAULT_DATE_RANGE_DAYS,
  },
  ageSelectedDate: { ageStartDate: null, ageEndDate: null, ageRangeDays: 0 },
  adSetIdsToConsider: [],
  adSetIdsToExclude: [],
  campaignIdsToConsider: [],
  campaignIdsToExclude: [],
  countries: ['ALL'],
  maxFirstAppearanceSeconds: null,
  networks: [],
  promotedObjectTypes: [],
  tagsToConsider: [],
  tagsToExclude: [],
  trendSlidesToInclude: Object.values(SDK.TrendSlideToInclude),
  breakdown: 'network',
  maxTopItems: DEFAULT_MAX_TOP_ITEMS,
  conceptLevel: false,
  minSpend: undefined,
  templateId: undefined,
  defaultSortByKpi: undefined,
  baseSpreadsheetId: undefined,
};

const ReportsSlice = createSlice({
  name: 'Reports',
  initialState,
  reducers: {
    setTemplate: (state, action: PayloadAction<SDK.GoogleSlidesTemplates>) => {
      state.template = action.payload;
    },
    setSelectedTagTypes: (state, action: PayloadAction<string[]>) => {
      state.selectedTagTypes = action.payload;
    },
    setDefaultSortByKpi: (state, action: PayloadAction<SDK.MetricOrKpi>) => {
      state.defaultSortByKpi = action.payload;
    },
    setSelectedKpis: (state, action: PayloadAction<SDK.MetricOrKpi[]>) => {
      state.selectedKpis = action.payload;
    },
    setGeneratingReportNotification: (state, action: PayloadAction<GeneratingReportNotificationStatus>) => {
      state.generatingReportNotification = action.payload;
    },
    setTemplateId: (state, action: PayloadAction<SDK.UUID | undefined>) => {
      state.templateId = action.payload;
    },
    setBaseSpreadsheetId: (state, action: PayloadAction<string | undefined>) => {
      state.baseSpreadsheetId = action.payload;
    },
    changeMinSpend: (state, action: PayloadAction<number>) => {
      state.minSpend = action.payload;
    },
    changeMaxTopItems: (state, action: PayloadAction<number | null>) => {
      state.maxTopItems = action.payload ?? DEFAULT_MAX_TOP_ITEMS;
    },
    changeConceptLevel: (state, action: PayloadAction<boolean>) => {
      state.conceptLevel = action.payload;
    },
    changeDateRange: (
      state,
      action: { payload: { startDate: string; endDate: string; rangeDays: string | number } }
    ) => {
      state.selectedDate.startDate = action.payload.startDate;
      state.selectedDate.endDate = action.payload.endDate;
      state.selectedDate.rangeDays = action.payload.rangeDays;
    },
    reset: (state) => {
      Object.keys(initialState).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        state[key] = initialState[key];
      });
    },
    changeCompareToTimeRange: (
      state,
      action: { payload: { startDate: string; endDate: string; rangeDays: string | number } }
    ) => {
      state.selectedCompareToDate.startDate = action.payload.startDate;
      state.selectedCompareToDate.endDate = action.payload.endDate;
      state.selectedCompareToDate.rangeDays = action.payload.rangeDays;
    },
    changeAgeDateRange: (
      state,
      action: { payload: { startDate: string | null; endDate: string | null; value: string | number } }
    ) => {
      const rangeDaysNum = Number(action.payload.value ?? 0);
      const currentDate = new Date();
      const startDate =
        action.payload.value !== 'custom' ? new Date(isoDateSubtract(currentDate, rangeDaysNum)) : new Date();

      const ageStartDate =
        rangeDaysNum === 0 || action.payload.value === 'custom'
          ? action.payload.startDate ?? null
          : toFormattedDate({
              year: startDate.getUTCFullYear(),
              month: startDate.getMonth() + 1,
              day: startDate.getUTCDate(),
            }) ?? null;
      const ageEndDate =
        rangeDaysNum === 0 || action.payload.value === 'custom'
          ? action.payload.endDate ?? null
          : toFormattedDate({
              year: currentDate.getUTCFullYear(),
              month: currentDate.getMonth() + 1,
              day: currentDate.getUTCDate(),
            }) ?? null;

      state.ageSelectedDate.ageStartDate = ageStartDate;
      state.ageSelectedDate.ageEndDate = ageEndDate;
      state.ageSelectedDate.ageRangeDays = rangeDaysNum;
    },
    changeAdSets: (state, action: PayloadAction<{ value: string[]; toExclude: boolean }>) => {
      batch(() => {
        state.adSetIdsToConsider = action.payload.toExclude ? [] : action.payload.value;
        state.adSetIdsToExclude = action.payload.toExclude ? action.payload.value : [];
      });
    },
    changeBreakdown: (state, action: PayloadAction<{ value: SDK.Breakdown }>) => {
      batch(() => {
        state.breakdown = action.payload.value;
      });
    },
    changeCampaigns: (state, action: PayloadAction<{ value: string[]; toExclude: boolean }>) => {
      batch(() => {
        state.campaignIdsToConsider = action.payload.toExclude ? [] : action.payload.value;
        state.campaignIdsToExclude = action.payload.toExclude ? action.payload.value : [];
      });
    },
    changeMaxFirstAppearanceSeconds: (state, action: PayloadAction<{ value?: number }>) => {
      state.maxFirstAppearanceSeconds = action.payload.value ?? null;
    },
    changeNetworks: (state, action: PayloadAction<{ value: SDK.Network[] }>) => {
      state.networks = action.payload.value;
    },
    changeCountries: (state, action: PayloadAction<{ value: string[] }>) => {
      const transformedPayloadValue =
        !action.payload.value || action.payload.value?.length === 0 ? ['ALL'] : action.payload.value;
      state.countries = transformedPayloadValue;
    },
    changePromotedObjectTypes: (state, action: PayloadAction<{ value: SDK.PromotedObjectType[] }>) => {
      state.promotedObjectTypes = action.payload.value;
    },
    changeTags: (state, action: PayloadAction<{ value: SDK.Tag[]; toExclude: boolean }>) => {
      batch(() => {
        state.tagsToConsider = action.payload.toExclude ? [] : action.payload.value;
        state.tagsToExclude = action.payload.toExclude ? action.payload.value : [];
      });
    },
    changeTrendSlidesToInclude: (state, action: PayloadAction<{ value: SDK.TrendSlideToInclude[] }>) => {
      batch(() => {
        state.trendSlidesToInclude = action.payload.value;
      });
    },
  },
});

export const ReportsActions = ReportsSlice.actions;
export const ReportsReducer = ReportsSlice.reducer;
