import * as SDK from '@replai-platform/sdk';

export const getTagKind = (tagType: string, config: Partial<SDK.ProjectConfigTags>): SDK.TagKind => {
  let group = SDK.TagKind.Core;

  if (config?.custom?.includes(tagType)) {
    group = SDK.TagKind.Custom;
  }
  if (config?.psychological?.includes(tagType)) {
    group = SDK.TagKind.Psychological;
  }

  return group;
};

export default getTagKind;
