import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase } from '@replai-platform/ui-components';
// eslint-disable-next-line import/no-cycle
import { logEvent } from '../../analytics';
import { Page } from '../../utils/enums';

export type InsightEvent = 'discard';

export type TagInsight = Omit<SDK.FeatureRecommendation, 'content' | 'type'> &
  (
    | {
        content: SDK.RecommendedTagContent;
        type: SDK.RecommendedTagContent['type'];
      }
    | {
        content: SDK.RecommendedTagIntroContent;
        type: SDK.RecommendedTagIntroContent['type'];
      }
  );

type InsightsFilters = {
  networks: SDK.Network[];
  promotedObjectTypes: SDK.PromotedObjectType[];
  kpis: SDK.MetricKPIWithSpend[];
  campaigns: string[];
  contextTags: SDK.UUID[];
  countries: string[];
  positiveInsightsOnly: boolean;
  introInsights: boolean;
};

export type Campaign = { campaignId: string; campaignName: string };

export type SpendAndFrequencyBadgeValues = {
  spendAndFrequency: {
    isLoading: boolean;
    insightObjectCount?: number;
    contextObjectCount?: number;
    insightSpend?: number;
    contextSpend?: number;
  };
};

type InsightsState = {
  filters: InsightsFilters;
  tagsPreviewLoading: boolean;
  projectCampaigns?: Campaign[];
  insights?: (TagInsight & SpendAndFrequencyBadgeValues)[];
  filteredInsights?: (SDK.FeatureRecommendation & SpendAndFrequencyBadgeValues)[];
  tagsPreviewData?: SDK.GetTagsPreviewsResponse;
};

export const initialState: InsightsState = {
  filters: {
    networks: [],
    promotedObjectTypes: SDK.PROMOTED_OBJECT_TYPES,
    kpis: [],
    campaigns: [],
    contextTags: [],
    countries: [],
    positiveInsightsOnly: true,
    introInsights: false,
  },
  tagsPreviewLoading: true,
};

const InsightsSlice = createSlice({
  name: 'Insights',
  initialState,
  reducers: {
    addProjectCampaigns: (state, action: PayloadAction<Campaign[]>) => {
      state.projectCampaigns = action.payload;
    },
    addFilteredInsights: (
      state,
      action: PayloadAction<(SDK.FeatureRecommendation & SpendAndFrequencyBadgeValues)[]>
    ) => {
      state.filteredInsights = action.payload;
      state.insights = Object.assign(
        <(SDK.FeatureRecommendation & SpendAndFrequencyBadgeValues)[]>[],
        action.payload
      ).sort((a, b) => b.score - a.score);
    },
    setFilter: <T extends keyof InsightsFilters>(
      state: InsightsState,
      action: PayloadAction<{ key: T; value: InsightsFilters[T]; logEvent?: boolean }>
    ) => {
      const { projectCampaigns } = state;
      const { key, logEvent: shouldLogEvent = true } = action.payload;
      let { value } = action.payload;

      if (key === 'campaigns') {
        value = ((value as string[])?.length === projectCampaigns?.length ? [] : value) as InsightsFilters[T];
      }

      if (shouldLogEvent) {
        const filter = camelCaseToCapitalCase(key);
        logEvent({
          component: 'Insights',
          action: `Filter by ${filter}`,
          category: 'insights',
          parameters: { page: Page.Insights },
        });
      }
      state.filters[key] = value;
    },
    setTagsPreviewData: (state, action: PayloadAction<SDK.GetTagsPreviewsResponse>) => {
      state.tagsPreviewData = action.payload;
    },
    setTagsPreviewLoading: (state, action: PayloadAction<boolean>) => {
      state.tagsPreviewLoading = action.payload;
    },
    reset: (state, action?: { payload?: (keyof InsightsState)[] }) => {
      if (!action?.payload) return { ...initialState };
      for (const key of action.payload) {
        delete state[key];
      }
      return state;
    },
  },
});

/**
 * Retrieves a subset of all of the insights retrieved so far
 * @param insights Set of all of the insights retrieved
 * @param type Insight tag type
 * @param kind Insight tag kind
 */
export function getFilteredInsights(
  insights: (TagInsight & SpendAndFrequencyBadgeValues)[],
  type: string | undefined,
  kind: SDK.TagKind | undefined
): (TagInsight & SpendAndFrequencyBadgeValues)[] {
  return insights.filter(
    (i) => (!type ? true : i.content.tag.type === type) && (!kind ? true : i.content.tag.kind === kind)
  );
}

export const InsightsActions = InsightsSlice.actions;
export const InsightsReducer = InsightsSlice.reducer;
