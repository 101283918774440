import { createInstance, setLogLevel } from '@optimizely/react-sdk';

const OptimizelyInstance = createInstance({
  datafileOptions: {
    urlTemplate: 'https://cdn.replai.io/datafiles/%s.json', // Circunvents blocking extensions such as uBlock Origin.
  },
  sdkKey: '2ne4BxVpcw8NW97PNfsZE',
});
if (process.env.NODE_ENV !== 'development' && !process.env.REACT_APP_REPLAI_DEV_ENV) {
  setLogLevel('error');
}

export default OptimizelyInstance;
