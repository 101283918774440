import { CountryIconProps } from './types';

const AU = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3563)">
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C0 12.0028 12 0.00135937 12 0C18.6274 0 24 5.37262 24 12Z"
        fill="#0052B4"
      />
      <path
        d="M11.9678 12.0004H11.9999C11.9999 11.9895 11.9999 11.9791 11.9999 11.9683C11.9892 11.979 11.9785 11.9897 11.9678 12.0004Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 6.26171C12 4.15023 12 2.76662 12 0.000854492H11.998C5.3715 0.00193262 0 5.37404 0 12.0008H6.26086V8.47524L9.78642 12.0008H11.9679C11.9787 11.9901 11.9894 11.9794 12 11.9687C12 11.1602 12 10.4389 12 9.78732L8.47443 6.26171H12Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.07124 1.56567C4.19441 2.63438 2.63413 4.19466 1.56543 6.07149V12.0005H4.69588V4.69617V4.69613H12.0002C12.0002 3.7088 12.0002 2.76821 12.0002 1.56567H6.07124Z"
        fill="#D80027"
      />
      <path
        d="M12.0008 10.5249L7.73739 6.2615H6.26172C6.26172 6.26145 6.26172 6.2615 6.26172 6.2615L12.0008 12.0005H12.0008C12.0008 12.0005 12.0008 10.9831 12.0008 10.5249Z"
        fill="#D80027"
      />
      <path
        d="M7.23729 14.0864L7.89588 15.4635L9.38309 15.1198L8.71709 16.4933L9.91305 17.4417L8.42398 17.7774L8.42815 19.3038L7.23729 18.3488L6.04648 19.3038L6.05065 17.7774L4.56152 17.4417L5.75754 16.4933L5.09145 15.1198L6.57874 15.4635L7.23729 14.0864Z"
        fill="#F0F0F0"
      />
      <path
        d="M17.9668 16.6954L18.2961 17.384L19.0397 17.2121L18.7066 17.8989L19.3047 18.3731L18.5601 18.5409L18.5622 19.3041L17.9668 18.8266L17.3714 19.3041L17.3734 18.5409L16.6289 18.3731L17.2269 17.8989L16.8939 17.2121L17.6375 17.384L17.9668 16.6954Z"
        fill="#F0F0F0"
      />
      <path
        d="M14.9033 9.39148L15.2326 10.0801L15.9762 9.90814L15.6432 10.5949L16.2412 11.0691L15.4967 11.2369L15.4987 12.0002L14.9033 11.5227L14.3079 12.0002L14.3099 11.2369L13.5654 11.0691L14.1634 10.5949L13.8304 9.90814L14.574 10.0801L14.9033 9.39148Z"
        fill="#F0F0F0"
      />
      <path
        d="M17.9668 5.21765L18.2961 5.90625L19.0397 5.73436L18.7067 6.42112L19.3046 6.89531L18.5601 7.06317L18.5622 7.82639L17.9668 7.34887L17.3714 7.82639L17.3734 7.06317L16.6289 6.89531L17.2268 6.42112L16.8939 5.73436L17.6375 5.90625L17.9668 5.21765Z"
        fill="#F0F0F0"
      />
      <path
        d="M20.6416 8.34778L20.9709 9.03637L21.7145 8.86443L21.3815 9.5512L21.9795 10.0254L21.2349 10.1933L21.237 10.9565L20.6416 10.479L20.0462 10.9565L20.0482 10.1933L19.3037 10.0254L19.9017 9.5512L19.5687 8.86443L20.3123 9.03637L20.6416 8.34778Z"
        fill="#F0F0F0"
      />
      <path
        d="M18.729 12.0004L18.988 12.7975H19.8262L19.1481 13.2902L19.4072 14.0873L18.729 13.5947L18.0509 14.0873L18.3099 13.2902L17.6318 12.7975H18.47L18.729 12.0004Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3563">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default AU;
