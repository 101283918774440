import { DropDownChip, DropDownChipProps } from '@replai-platform/ui-components';

export interface KpiFilterProps {
  options: DropDownChipProps['dropDownOptions'];
  onChange?: DropDownChipProps['onChange'];
  defaultOption: string;
  dropdownType: DropDownChipProps['dropDownType'];
  prefixLabel?: string;
}
const KpiFilter: React.VFC<KpiFilterProps> = ({
  options,
  defaultOption = 'All KPIs',
  dropdownType = 'singleselect',
  onChange,
  prefixLabel,
}) => (
  <DropDownChip
    data-test="filter-kpi-dropdown"
    placeHolder="Select KPIs"
    dropDownType={dropdownType}
    defaultOption={defaultOption}
    dropDownOptions={options}
    prefixLabel={prefixLabel ?? (dropdownType === 'singleselect' ? undefined : 'KPIs')}
    onChange={onChange}
  />
);
export default KpiFilter;
