import { CountryIconProps } from './types';

const MS = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C0 12.0029 12 0.0013125 12 0C18.6274 0 24 5.37262 24 12Z"
      fill="#0052B4"
    />
    <path
      d="M11.9678 11.9997H11.9999C11.9999 11.9889 11.9999 11.9784 11.9999 11.9675C11.9892 11.9783 11.9785 11.989 11.9678 11.9997Z"
      fill="#F0F0F0"
    />
    <path
      d="M12 6.26123C12 4.14974 12 2.76623 12 0.000366211H11.998C5.37145 0.00149121 0 5.3736 0 12.0004H6.26086V8.47485L9.78637 12.0004H11.9679C11.9786 11.9897 11.9893 11.979 12 11.9683C12 11.1598 12 10.4384 12 9.78683L8.47443 6.26123H12Z"
      fill="#F0F0F0"
    />
    <path
      d="M6.07124 1.56543C4.19441 2.63413 2.63409 4.19441 1.56543 6.07124V12.0002H4.69588V4.69598V4.69588H12.0002C12.0002 3.70856 12.0002 2.76796 12.0002 1.56543H6.07124Z"
      fill="#D80027"
    />
    <path
      d="M12.0009 10.5242L7.73734 6.26074H6.26172V6.26084L12.0008 11.9998H12.0009C12.0009 11.9998 12.0009 10.9823 12.0009 10.5242Z"
      fill="#D80027"
    />
    <path
      d="M13.5654 6.2605V11.9996C13.5654 14.7952 17.2176 15.6518 17.2176 15.6518C17.2176 15.6518 20.8698 14.7952 20.8698 11.9996V6.2605H13.5654Z"
      fill="#338AF3"
    />
    <path
      d="M13.5654 11.9991C13.5654 14.7948 17.2176 15.6514 17.2176 15.6514C17.2176 15.6514 20.8698 14.7948 20.8698 11.9991H13.5654Z"
      fill="#A2001D"
    />
    <path
      d="M18.7828 8.86909H17.7393V7.82556H16.6958V8.86909H15.6523V9.91253H16.6958V13.0429H17.7393V9.91253H18.7828V8.86909Z"
      fill="black"
    />
  </svg>
);
export default MS;
