import { CountryIconProps } from './types';

const GR = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3628)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 8.87002H23.5866C23.2857 7.75373 22.8284 6.70181 22.2387 5.73956H12V8.87002Z"
        fill="#338AF3"
      />
      <path
        d="M4.53058 21.3914H19.4702C20.5654 20.5191 21.5046 19.4597 22.2391 18.2609H1.76172C2.4963 19.4596 3.43548 20.5191 4.53058 21.3914Z"
        fill="#338AF3"
      />
      <path
        d="M4.17433 2.90375C3.23092 3.71614 2.41534 4.67281 1.76172 5.7395H4.17433V2.90375Z"
        fill="#338AF3"
      />
      <path
        d="M12 12C12 10.7776 12 9.75469 12 8.86951H7.30434V12H4.17389V8.86951H0.413391C0.144375 9.86766 0 10.9168 0 12C0 13.0831 0.144375 14.1323 0.413391 15.1304H23.5867C23.8556 14.1323 24 13.0831 24 12H12Z"
        fill="#338AF3"
      />
      <path
        d="M12.0003 0.000671387C10.334 0.000671387 8.74703 0.340656 7.30469 0.954484V5.73981H12.0003C12.0003 4.64706 12.0003 3.71767 12.0003 2.60936H19.4702C17.4203 0.976796 14.8245 0.000671387 12.0003 0.000671387Z"
        fill="#338AF3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3628">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default GR;
