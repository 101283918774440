import styled from 'styled-components';

export const FeaturedIconContainer = styled.div<{
  $showShadow?: boolean;
  $backgroundColor?: string;
  $borderColor?: string;
  $width?: string;
  $height?: string;
  $borderWidth?: string;
  $borderRadius?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $showShadow }) =>
    $showShadow ? 'box-shadow: 0.25rem 0.25rem rgba(0, 0, 0, 0.08);' : ''}

  background-color: ${(props) => props.$backgroundColor};
  border-color: ${(props) =>
    props.$borderColor ? `${props.$borderColor} !important` : ''};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  border-width: ${(props) => `${props.$borderWidth} !important`};
  border-radius: ${(props) => props.$borderRadius};

  &.xs {
    width: 24px;
    height: 24px;
  }

  &.sm {
    width: 32px;
    height: 32px;
  }

  &.md {
    width: 40px;
    height: 40px;
  }

  &.lg {
    width: 48px;
    height: 48px;
  }

  &.xl {
    width: 56px;
    height: 56px;
  }

  &.xxl {
    width: 84px;
    height: 84px;
  }

  &.default {
    border-radius: 50%;
  }

  &.outline {
    border: 10px solid transparent;
    border-radius: 50%;
  }
`;
