/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Colors, { Color } from '../../../Colors';
import { getBaseIcon } from '../../../Icons';
import Typography from '../../../Typography/Typography';
import * as Styled from './styles';

export type TrendIcon = 'ArrowDown' | 'ArrowUp' | 'MinusCircle';
export interface KPIScores {
  kpiName: string;
  kpiValue: string;
  kpiValueColor?: Color;
  trendIcon?: TrendIcon;
  trendIconColor?: Color;
}

export interface KPIScoresProps {
  kpiScores: KPIScores[];
}

const KpiScores: React.VFC<KPIScoresProps> = ({ kpiScores }) => (
  <Styled.Container>
    {kpiScores.map(
      ({
        kpiName,
        kpiValue,
        kpiValueColor = Colors.Gray[900],
        trendIcon,
        trendIconColor,
      }) => {
        const TrendIcon = trendIcon ? getBaseIcon(trendIcon) : undefined;
        return (
          <Styled.Row key={kpiName}>
            <Styled.KPINameContainer>
              <Typography
                type="text-sm"
                fontWeight="regular"
                color={Colors.Gray[500]}
              >
                {kpiName}
              </Typography>
            </Styled.KPINameContainer>
            <Styled.KPIValueContainer>
              <Typography
                type="text-sm"
                fontWeight="bold"
                style={{ display: 'flex', minWidth: '2rem' }}
              >
                {TrendIcon ? <TrendIcon color={trendIconColor} /> : null}
              </Typography>
              <Typography
                type="text-sm"
                fontWeight="bold"
                color={kpiValueColor}
              >
                {kpiValue}
              </Typography>
            </Styled.KPIValueContainer>
          </Styled.Row>
        );
      }
    )}
  </Styled.Container>
);

export default KpiScores;
