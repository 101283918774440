import * as SDK from '@replai-platform/sdk';
import { Card, ChartValue, Colors, LineChart, Skeleton, Tooltip } from '@replai-platform/ui-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueries } from 'react-query';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { api } from '../../../api';
import { getHistoryMetricsQueryOptions } from '../../../api/hooks/metrics/useMetricsHistory';
import useMetricsPerformance from '../../../api/hooks/metrics/useMetricsPerformance';
import { FiltersState, initialState } from '../../../store/filters';
import type { RootState } from '../../../store/rootReducer';
import { calculateDelta, capitalizeFirstLetter, isoDateSubtract } from '../../../utils';
import { ActivityType, Page } from '../../../utils/enums';
import { TypographyClean } from '../../../utils/styles';
import { daysBetween, getDailyDateArray } from '../utils';
import * as Styled from './styles';

const buildAvailableKpis = ({
  projectKpis,
  projectAccountTypes,
}: {
  projectKpis: SDK.MetricKPIWithSpend[];
  projectAccountTypes?: SDK.AccountTypes[];
}) => {
  const allTargets = [
    // Include project KPIs first.
    ...projectKpis,

    // Include additional metrics that are relevant to be displayed in the trend chart (if they are not present in the project KPIs list).
    ...(projectAccountTypes?.includes(SDK.AccountTypes.SOCIALS)
      ? [
          SDK.Metrics.VIEWS,
          SDK.Metrics.IMPRESSIONS,
          SDK.Metrics.LIKES,
          SDK.Metrics.DISLIKES,
          SDK.Metrics.COMMENTS,
          SDK.Metrics.SHARES,
          SDK.Metrics.MINUTES_WATCHED,
          SDK.Metrics.SUBSCRIBERS_GAINED,
          SDK.Metrics.SUBSCRIBERS_LOST,
        ]
      : [SDK.Metrics.SPEND, SDK.Metrics.INSTALLS, SDK.Metrics.CLICKS, SDK.Metrics.IMPRESSIONS].filter(
          (v) => !projectKpis.includes(v)
        )),
  ];
  return Array.from(new Set(allTargets)).map((kpi) => ({
    title: SDK.kpiUtils.getDisplayName(kpi).toUpperCase(),
    value: kpi,
  }));
};

const getTotalTrendParams = ({
  projectId,
  selectedKpi,
  filters,
  startDate,
  endDate,
}: {
  projectId: SDK.UUID;
  selectedKpi: SDK.MetricKPIWithSpend;
  filters: FiltersState;
  startDate: string;
  endDate: string;
}) => ({
  projectIds: [projectId],
  metrics: [selectedKpi],
  adsFilters: api.filterConverter.getAdsFilters(filters),
  assetFilters: api.filterConverter.getAssetFilters(filters),
  metricsFilters: {
    ...api.filterConverter.getMetricsFilters({
      ...filters,
      startDate,
      endDate,
    }),
  },
});

const Trend = ({ filtersLoading }: { filtersLoading?: boolean }) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const keyMetric = useSelector((state: RootState) => state.project.baseMetric);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const projectNetworks = useSelector((state: RootState) => state.project.networks)!;
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis ?? []);
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes ?? []);
  const allFilters = useSelector((state: RootState) => state.filters);
  const filters = useMemo(
    () => ({
      ...initialState,
      networks: allFilters.networks,
      activity: ActivityType.all,
      startDate: allFilters.startDate,
      endDate: allFilters.endDate,
    }),
    [allFilters.endDate, allFilters.networks, allFilters.startDate]
  );

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, []);
  const baseNetworks = filters.networks?.length ? filters.networks : projectNetworks;
  // TODO(TECHMERC-1555): cleanup
  const selectedNetworks = [
    ...baseNetworks,
    ...(projectId === '248ca9eb-6f5b-4106-b52e-cc3c6d96436b' ? ['replai_api' as SDK.Network] : []),
  ];

  const availableKpis = useMemo(() => buildAvailableKpis({ projectKpis, projectAccountTypes }), [projectKpis]);

  // TODO(TECHMERC-1555): cleanup
  const defaultKpiIndex =
    projectId === '248ca9eb-6f5b-4106-b52e-cc3c6d96436b'
      ? availableKpis.findIndex((kpi) => kpi.value === 'installs')
      : availableKpis.findIndex((kpi) => kpi.value === keyMetric);
  const [selectedKpiIndex, setSelectedKpiIndex] = useState(defaultKpiIndex === -1 ? 0 : defaultKpiIndex);
  const selectedKpi = availableKpis[selectedKpiIndex].value as SDK.MetricKPIWithSpend;
  const cohortDelayDays = SDK.kpiUtils.filters[selectedKpi].cohortDays ?? 0;

  const startDate = isoDateSubtract(filters.startDate, cohortDelayDays);
  const endDate = isoDateSubtract(filters.endDate, cohortDelayDays);
  const daysOfDataToConsider = daysBetween(new Date(startDate), new Date(endDate));

  const previousTimeframeStartDate = isoDateSubtract(startDate, daysOfDataToConsider);
  const previousTimeframeEndDate = isoDateSubtract(startDate, 1);

  const enabled =
    !filtersLoading &&
    !!filters.endDate &&
    !!filters.startDate &&
    // TODO(TECHMERC-1555): cleanup
    (!!selectedNetworks?.length || projectId === '248ca9eb-6f5b-4106-b52e-cc3c6d96436b');

  const getHistoryParams = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: [selectedKpi] as SDK.Metrics[],
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters({ ...filters, startDate, endDate }),
    }),
    [endDate, filters, projectId, selectedKpi, startDate]
  );

  const trendChartDataRequests = useQueries(
    selectedNetworks.map((network) =>
      getHistoryMetricsQueryOptions(
        { ...getHistoryParams, adsFilters: { ...getHistoryParams.adsFilters, networksToConsider: [network] } },
        { enabled }
      )
    )
  );
  let chartData: ChartValue[] = [];

  if (!trendChartDataRequests.some((query) => query.isLoading || query.isIdle)) {
    // Fetches the date of the oldest metric from all the networks
    const oldestDateInChart = trendChartDataRequests
      .map((request) => request.data?.history[0]?.date)
      .sort((curr, prev) => new Date(curr ?? 0).getTime() - new Date(prev ?? 0).getTime());
    const fromDate = new Date(oldestDateInChart[0] ?? 0);

    chartData = getDailyDateArray(fromDate, daysBetween(fromDate, new Date(endDate as string | number | Date))).map(
      (date) => ({
        name: date.toISOString().split('T')[0],
        ...selectedNetworks.reduce(
          // eslint-disable-next-line no-return-assign
          (network, item, networkIndex) => (
            (network[item] = trendChartDataRequests[networkIndex].data?.history.find(
              (currentMetric) => currentMetric.date === date.toISOString().split('T')[0]
              // eslint-disable-next-line no-sequences
            )?.metrics[selectedKpi]),
            network
          ),
          {}
        ),
      })
    );
  }

  const trendLines = selectedNetworks.map((network) => ({ name: capitalizeFirstLetter(network), accessor: network }));

  const currentTotalTrendCurrentParams = useMemo(
    () => getTotalTrendParams({ projectId, selectedKpi, filters, startDate, endDate }),
    [endDate, filters, projectId, selectedKpi, startDate]
  );

  const {
    data: trendCurrentTotalData,
    isLoading: isLoadingTrendCurrentTotal,
    isIdle: isIdleTrendCurrentTotal,
  } = useMetricsPerformance(currentTotalTrendCurrentParams, { enabled });

  const pastTimeTotalTrendCurrentParams = useMemo(
    () =>
      getTotalTrendParams({
        projectId,
        selectedKpi,
        filters,
        startDate: previousTimeframeStartDate,
        endDate: previousTimeframeEndDate,
      }),
    [previousTimeframeEndDate, filters, projectId, selectedKpi, previousTimeframeStartDate]
  );

  const {
    data: trendPastTotalData,
    isLoading: isLoadingTrendPastTotal,
    isIdle: isIdleTrendPastTotal,
  } = useMetricsPerformance(pastTimeTotalTrendCurrentParams, { enabled });

  let deltaTrend: number | null = null;
  let currentTimeframeTotal: number | null = null;
  let pastTimeframeTotal: number | null = null;

  if (!isLoadingTrendCurrentTotal && !isLoadingTrendPastTotal && !isIdleTrendCurrentTotal && !isIdleTrendPastTotal) {
    currentTimeframeTotal = trendCurrentTotalData?.metrics[selectedKpi] ?? null;
    pastTimeframeTotal = trendPastTotalData?.metrics[selectedKpi] ?? null;
    if (currentTimeframeTotal !== null && pastTimeframeTotal !== null) {
      deltaTrend = (calculateDelta(currentTimeframeTotal, pastTimeframeTotal) ?? 0) * 100;
    }
  }
  const onKpiChange = useCallback(
    (index: number) => {
      setSelectedKpiIndex(index);
      if (!firstRender.current) {
        logEvent({
          component: 'Dashboard',
          action: 'Change KPI',
          category: 'user_actions',
          parameters: { value: availableKpis[index].title, page: Page.Dashboard },
        });
      }
    },
    [availableKpis, firstRender]
  );

  const renderKpiTotalsSection = () => (
    <Styled.TrendKPIValueContainer>
      <Styled.TrendKPITypography type="display-sm" fontWeight="medium" data-test="trend-card-kpi-current-value">
        {currentTimeframeTotal === null
          ? SDK.messages.NOT_AVAILABLE
          : `${SDK.kpiConfig[selectedKpi]?.isCurrency ? '$' : ''}${SDK.kpiUtils.format(
              selectedKpi,
              currentTimeframeTotal,
              { longDisplay: true }
            )}`}
      </Styled.TrendKPITypography>
      <Tooltip
        content={
          <TypographyClean type="text-sm" data-test="trend-card-kpi-past-value">
            Previous {daysOfDataToConsider} days:{' '}
            {pastTimeframeTotal === null
              ? SDK.messages.NOT_AVAILABLE
              : `${SDK.kpiConfig[selectedKpi]?.isCurrency ? '$' : ''}${SDK.kpiUtils.format(
                  selectedKpi,
                  pastTimeframeTotal,
                  { longDisplay: true }
                )}`}
          </TypographyClean>
        }
        placement="top"
      >
        {deltaTrend === null ? (
          <Styled.TrendKPIBadge color="Gray" data-test="trend-card-badge-no-data">
            N/A
          </Styled.TrendKPIBadge>
        ) : (
          <Styled.TrendKPIBadge
            color={
              deltaTrend === 0
                ? 'Gray'
                : (SDK.kpiUtils.shouldMaximize(selectedKpi) ? 1 : -1) * deltaTrend > 0
                ? 'Success'
                : 'Error'
            }
            data-test="trend-card-badge-percentual-value"
            leadingIcon={deltaTrend >= 0 ? 'ArrowUp' : 'ArrowDown'}
          >
            {Math.abs(deltaTrend).toFixed(2).concat('%')}
          </Styled.TrendKPIBadge>
        )}
      </Tooltip>
      <Styled.VsPreviousTypography type="text-sm" fontWeight="medium" color={Colors.Gray[500]}>
        vs previous {daysOfDataToConsider} days
      </Styled.VsPreviousTypography>
    </Styled.TrendKPIValueContainer>
  );

  return (
    <Styled.TrendCardContainer data-test="trend-card-container">
      <Card fullHeight fullWidth>
        <Styled.CardHeader>
          <Styled.TrendTitleTypography type="text-lg" fontWeight="medium">
            Trend
          </Styled.TrendTitleTypography>
          <Styled.SelectInputKpiContainer data-test="trend-card-kpi-select-input">
            <Styled.StyledSelectInput
              defaultOption={{ title: availableKpis[selectedKpiIndex].title }}
              onChange={onKpiChange}
              options={availableKpis}
              placeholder="Select KPI"
            />
          </Styled.SelectInputKpiContainer>
        </Styled.CardHeader>
        {isLoadingTrendCurrentTotal || isLoadingTrendPastTotal || isIdleTrendCurrentTotal || isIdleTrendPastTotal ? (
          <Skeleton height={38} width={400} />
        ) : (
          renderKpiTotalsSection()
        )}
        <Styled.TrendChartContainer>
          {!trendChartDataRequests?.length ||
          trendChartDataRequests.some((query) => query.isLoading || query.isIdle) ? (
            <Skeleton height="100%" width="100%" />
          ) : (
            <LineChart
              data-test="trend-card-chart"
              data={chartData}
              yValueFormatter={(v) => SDK.kpiUtils.format(selectedKpi, v)}
              xValueFormatter={(s) =>
                new Date(s).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                })
              }
              lines={trendLines}
              // Shows one day per week on the x axis
              xAxisTicks={chartData
                ?.filter((dataPoint) => new Date(dataPoint.name.toString()).getDay() === 1)
                .map((dataPoint) => dataPoint.name as string)}
            />
          )}
        </Styled.TrendChartContainer>
      </Card>
    </Styled.TrendCardContainer>
  );
};

export default Trend;
