import { ColorTypes } from '../../Colors';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { TagBreakdown } from '../timeline.types';
import { getTimelineTrackSegmentColor } from '../utils';
import * as Styled from './styles';
import TimelineRow from './TimelineRow/TimelineRow';
import TimelineTimestampLine from './TimelineTimestampLine/TimelineTimestampLine';

interface DataGroupedByType {
  [type: string]: TagBreakdown[];
}

export interface TimelineBodyProps {
  data: TagBreakdown[];
  videoDurationInSeconds: number;
  recommendationsOnly: boolean;
  loading: boolean;
  playedFraction?: number;
  seekToPoint?: (point: number) => void;
  timestampLineVerticalOffset?: number;
  showEmptyTracks?: boolean;
  customTrackColorType?: ColorTypes;
  onTimestampLineDrag?: () => void;
  onMouseOver?: (id: string) => void;
  hoveredTag?: string | null;
  withoutTabs?: boolean;
}

const TimelineBody: React.FC<TimelineBodyProps> = ({
  data,
  videoDurationInSeconds,
  recommendationsOnly,
  loading,
  playedFraction = 0,
  seekToPoint,
  timestampLineVerticalOffset,
  showEmptyTracks = false,
  customTrackColorType,
  onTimestampLineDrag,
  onMouseOver,
  hoveredTag,
  withoutTabs,
}) => {
  let dataToRender: TimelineBodyProps['data'] | DataGroupedByType = data;
  if (recommendationsOnly) {
    dataToRender = data.filter(
      (row) => row.recommendations && row.recommendations.length > 0
    );
  }
  const tagCategories = Array.from(new Set(data.map((entry) => entry.type)));

  if (loading) {
    return (
      <Styled.LoadingContainer>
        <LoadingScreen />
      </Styled.LoadingContainer>
    );
  }

  return (
    <Styled.Container>
      {dataToRender
        .filter((d) => (recommendationsOnly ? d.recommendations : true))
        .map((entry, i) => (
          <TimelineRow
            key={`${entry.key}-${i}`}
            color={
              customTrackColorType ??
              getTimelineTrackSegmentColor(tagCategories.indexOf(entry.type))
            }
            segments={entry.segments}
            duration={videoDurationInSeconds}
            seekToPoint={seekToPoint}
            showEmptyTracks={showEmptyTracks}
            recommendations={entry.recommendations}
            onMouseOver={() => onMouseOver?.(entry.id ?? '')}
            onMouseLeave={() => onMouseOver?.('')}
            hoveredTag={hoveredTag === entry.id}
          />
        ))}
      <TimelineTimestampLine
        playedFraction={playedFraction}
        verticalOffset={timestampLineVerticalOffset}
        onUpdateFraction={(fraction) =>
          seekToPoint?.(fraction * videoDurationInSeconds)
        }
        onDrag={onTimestampLineDrag}
        withoutTabs={withoutTabs}
      />
    </Styled.Container>
  );
};

export default TimelineBody;
