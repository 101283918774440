import { CountryIconProps } from './types';

const DS = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3713)">
      <path
        d="M1.19072 6.78262C0.427875 8.36025 0 10.1301 0 12C0 13.8699 0.427875 15.6398 1.19072 17.2174L12 18.2609L22.8093 17.2174C23.5721 15.6398 24 13.8699 24 12C24 10.1301 23.5721 8.36025 22.8093 6.78262L12 5.73914L1.19072 6.78262Z"
        fill="#F0F0F0"
      />
      <path
        d="M1.19141 17.2167C3.13231 21.2306 7.24273 23.9993 12.0003 23.9993C16.7578 23.9993 20.8682 21.2306 22.8091 17.2167H1.19141Z"
        fill="black"
      />
      <path
        d="M1.19141 6.78287H22.8091C20.8682 2.76892 16.7578 0.000244141 12.0003 0.000244141C7.24273 0.000244141 3.13231 2.76892 1.19141 6.78287Z"
        fill="#D80027"
      />
      <path
        d="M3.51471 3.51392C-1.17157 8.2002 -1.17157 15.7982 3.51471 20.4845C5.45126 18.548 7.31375 16.6855 12 11.9992L3.51471 3.51392Z"
        fill="#496E2D"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3713">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default DS;
