import { offset, useFloating } from '@floating-ui/react-dom';
import { useMemo, useRef, useState, useEffect } from 'react';
import { useClickAway } from 'react-use';
import { NavigationItem, NavigationItemProps } from '.';
import {
  Avatar,
  AvatarProps,
  Colors,
  DropDownMenu,
  DropDownMenuOption,
  Tooltip,
  Typography,
} from '..';
import * as Icons from '../Icons';
import * as Styled from './styles';

export type NavigationProps = {
  bottomAvatar: AvatarProps;
  bottomItems?: NavigationItemProps[];
  topAvatar: AvatarProps;
  topAvatarOptions: DropDownMenuOption[];
  topItems: NavigationItemProps[];
  logo?: {
    href?: string;
    onClick?: () => void;
  };
  onLogOut?: () => void;
};

const Navigation = ({
  bottomAvatar,
  bottomItems,
  topAvatar,
  topAvatarOptions,
  topItems,
  logo,
  onLogOut,
}: NavigationProps) => {
  const [isDropDownMenuCollapsed, setIsDropDownMenuCollapsed] = useState(true);
  const [closeTooltip, setCloseTooltip] = useState(false);

  const dropDownRef = useRef(null);

  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'bottom',
    middleware: [offset({ mainAxis: 8, crossAxis: 148 })],
  });

  useEffect(() => {
    if (closeTooltip) {
      setCloseTooltip(false);
    }
  }, [closeTooltip]);

  // used to colapse the dropdown if the user clicks outside of it
  useClickAway(dropDownRef, async () => {
    setIsDropDownMenuCollapsed(true);
  });

  const topAvatarOptionsWithEnhancedOnClick = useMemo(
    () =>
      topAvatarOptions.map((option) => ({
        ...option,
        squared: true,
        onClick: async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setIsDropDownMenuCollapsed(true);

          if (option.onClick) {
            option.onClick(e);
          }
        },
      })),
    [topAvatarOptions]
  );

  const getBottomAvatarMenu = () => (
    <Styled.AccountTooltipContainer>
      <Typography color={Colors.Common.White} fontWeight="medium" noMargin>
        Account
      </Typography>
      <Styled.LogoutContainer>
        <Styled.Email
          data-test="user-email"
          noMargin
          type="text-sm"
          color={Colors.Common.White}
        >
          {bottomAvatar.label}
        </Styled.Email>
        <Styled.LogoutIconContainer
          onClick={() => {
            onLogOut?.();
            setCloseTooltip(true);
          }}
          data-test="logout-button"
        >
          <Icons.BaseIcons.LogOut color={Colors.Gray[300]} />
        </Styled.LogoutIconContainer>
      </Styled.LogoutContainer>
    </Styled.AccountTooltipContainer>
  );

  const getProjectName = () => (
    <Styled.TooltipContainer>
      <Typography noMargin color={Colors.Common.White}>
        {topAvatar.label}
      </Typography>
    </Styled.TooltipContainer>
  );

  return (
    <>
      <Styled.GlobalNavigationStyle />
      <Styled.NavigationContainer data-test="navigation-bar">
        <Styled.TopContainer>
          <Styled.StyledLink to={logo?.href ?? '#'} onClick={logo?.onClick}>
            <Icons.BrandIcons.Replai dimension={27} />
          </Styled.StyledLink>
          <Styled.ProjectContainer>
            <div ref={dropDownRef}>
              <Tooltip
                placement="right"
                content={getProjectName()}
                color={Colors.Gray[900]}
                align={{ targetOffset: [-6, 0] }}
                overlayStyle={{ maxWidth: 'unset' }}
                open={closeTooltip ? false : undefined}
                arrowPointAtCenter
                showArrow
                disableShadow
                disablePadding
              >
                <Styled.AvatarContainer
                  data-test="projects-button"
                  onClick={async () => {
                    setIsDropDownMenuCollapsed((c) => !c);
                    setCloseTooltip(true);
                  }}
                  ref={reference}
                >
                  <Avatar
                    {...topAvatar}
                    alt={topAvatar.label}
                    label=""
                    size="lg"
                    squared
                  />
                </Styled.AvatarContainer>
              </Tooltip>
              {!isDropDownMenuCollapsed && (
                <Styled.DropdownContainer
                  data-test="projects-search-results"
                  ref={floating}
                  $position={strategy}
                  $left={`${x}px`}
                  $top={`${y}px`}
                >
                  <DropDownMenu
                    title=""
                    options={topAvatarOptionsWithEnhancedOnClick}
                    hasInput
                    input={{
                      leadingIcon: 'Search',
                      placeholder: 'Search',
                      autoFocus: true,
                    }}
                    selected={topAvatar.label}
                  />
                </Styled.DropdownContainer>
              )}
            </div>
          </Styled.ProjectContainer>
        </Styled.TopContainer>
        <Styled.ItemsContainer>
          {topItems.map((item) => (
            <NavigationItem
              data-test="navigation-item"
              {...item}
              key={item.label}
            />
          ))}
        </Styled.ItemsContainer>
        {bottomItems && (
          <Styled.BottomItemsContainer>
            {bottomItems.map((item) => (
              <NavigationItem
                data-test="navigation-item"
                {...item}
                onClick={(e) => {
                  item.onClick?.(e);
                }}
                key={item.label}
              />
            ))}
          </Styled.BottomItemsContainer>
        )}
        <Tooltip
          className="nav-sub-menu-tooltip"
          placement="rightBottom"
          content={getBottomAvatarMenu()}
          color={Colors.Gray[900]}
          align={{ targetOffset: [8, -10] }}
          overlayStyle={{ maxWidth: 'unset' }}
          open={closeTooltip ? false : undefined}
          arrowPointAtCenter
          showArrow
          disableShadow
          disablePadding
        >
          <Styled.BottomIconContainer data-test="navigation-bar-bottom">
            <Styled.BottomAvatarContainer>
              <Avatar {...bottomAvatar} size="lg" label="" dark />
            </Styled.BottomAvatarContainer>
          </Styled.BottomIconContainer>
        </Tooltip>
      </Styled.NavigationContainer>
    </>
  );
};

export default Navigation;
