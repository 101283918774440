import * as SDK from '@replai-platform/sdk';
import { ALLOWED_EXCLUDED_TAGS, MARKET_CORE_TAGS } from '../../configurations/market';

export default function marketTagTypesToExclude({
  projectTagTypesConfig,
}: {
  projectTagTypesConfig: Partial<SDK.ProjectConfigTags>;
}) {
  return [
    ...new Set([
      ...(projectTagTypesConfig?.excluded ?? []).filter((t) => ALLOWED_EXCLUDED_TAGS.includes(t)),
      ...Object.values(SDK.CoreTags).filter((t) => !MARKET_CORE_TAGS.includes(t)),
      ...(projectTagTypesConfig?.psychological ?? []),
    ]),
  ];
}
