/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { Colors, Typography } from '..';

// Padding to be applied on each 'pick and sort' element AND container
const grid = 16;

const DRAGGING_COLORS = {
  container: {
    background: Colors.Gray[50],
    dragging: Colors.Gray[50],
    disallowed: Colors.Gray[200],
  },
  item: {
    background: Colors.Common.White,
    dragging: Colors.Common.White,
    disallowed: Colors.Gray[200],
  },
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 10px;
  background-color: ${DRAGGING_COLORS.container.background};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 *
 * Orders a specific element in a list by deleting it and add it again to the specified endIndex position
 *
 * @param list list to order
 * @param startIndex begining element index to order
 * @param endIndex index position to place the element
 * @returns ordered list
 */
function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

const getItemStyle = (
  isDragging: boolean,
  isDisallowed: boolean,
  draggableStyle?: DraggingStyle | NotDraggingStyle
): React.CSSProperties =>
  ({
    display: 'flex',
    height: '43px',

    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '0 12px',
    margin: `0 0 ${grid / 2}px 0`,
    borderRadius: '8px',

    // change background colour if dragging
    background: isDisallowed
      ? DRAGGING_COLORS.item.disallowed
      : isDragging
      ? DRAGGING_COLORS.item.dragging
      : DRAGGING_COLORS.item.background,
    boxShadow:
      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    // styles we need to apply on draggables
    ...draggableStyle,
  } as React.CSSProperties);

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver
    ? DRAGGING_COLORS.container.dragging
    : DRAGGING_COLORS.container.background,
  padding: grid,
  width: '100%',
  height: '100%',
});

export type ExtraData = {
  id: string;
  renderedValue: ReactNode;
  disallowed?: boolean;
};

export interface PickAndSortProps<T extends ExtraData>
  extends React.HTMLAttributes<HTMLElement> {
  title?: string;
  disallowed?: boolean;
  data: (T & ExtraData)[];
  onSorted?: (data: T[]) => void;
}

const PickAndSort = <T,>({
  title,
  data,
  style,
  onSorted,
}: PickAndSortProps<T & ExtraData>) => {
  const [sortedData, setSortedData] = React.useState(data);

  useEffect(() => {
    if (data) {
      setSortedData(data);
    }
  }, [data]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const newSortedData = reorder(
      sortedData,
      result.source.index,
      result.destination.index
    );

    // If the items were sorted, notify callback
    if (onSorted) {
      onSorted(newSortedData);
    }
    setSortedData(newSortedData);
  };

  return (
    <MainContainer style={style}>
      {title ? (
        <Header>
          <Typography
            type="text-sm"
            fontWeight="semi-bold"
            color={Colors.Gray[500]}
          >
            {title}
          </Typography>
        </Header>
      ) : null}
      <Content>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(mainDroppableProvided, mainDropStateSnapshot) => (
              <div
                {...mainDroppableProvided.droppableProps}
                ref={mainDroppableProvided.innerRef}
                style={getListStyle(mainDropStateSnapshot.isDraggingOver)}
              >
                {sortedData.map((dataItem, index: number) => (
                  <Draggable
                    key={dataItem.id}
                    draggableId={dataItem.id}
                    index={index}
                  >
                    {(itemDraggableProvided, itemDraggableStateSnapshot) => (
                      <div
                        ref={itemDraggableProvided.innerRef}
                        {...itemDraggableProvided.draggableProps}
                        {...itemDraggableProvided.dragHandleProps}
                        style={getItemStyle(
                          itemDraggableStateSnapshot.isDragging,
                          dataItem.disallowed ?? false,
                          itemDraggableProvided.draggableProps.style
                        )}
                      >
                        {dataItem.renderedValue}
                      </div>
                    )}
                  </Draggable>
                ))}
                {mainDroppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Content>
    </MainContainer>
  );
};

export default PickAndSort;
