import { useMutation } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const generateGoogleSlidesReport = async (body: SDK.ExportToGoogleSlidesRequestBody) => {
  const url = `${SDK.BASE_URL()}/reports/google_slides_async`;
  const res = await provider.getJson<object, string>(url, { method: 'POST', body });
  return res;
};

export default function useGenerateGoogleSlidesReport() {
  return useMutation(['generate-google-slides-report'], (body: SDK.ExportToGoogleSlidesRequestBody) =>
    generateGoogleSlidesReport(body)
  );
}
