import { Colors, Typography } from '@replai-platform/ui-components';
import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
`;

export const TypographyClean = styled(Typography)`
  margin: 0;
  padding: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.25rem;
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SidebarSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
`;

export const CustomDivider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1.25rem;
  margin-bottom: 1.5625rem;
  background-color: ${Colors.Gray[200]};
`;
