import * as SDK from '@replai-platform/sdk';
import * as provider from './provider';

/**
 * Get all campaigns
 */
export async function getAllCampaigns({
  projectId,
  adsFilters,
  metricsFilters,
}: {
  projectId: SDK.UUID;
  adsFilters?: SDK.AdsFilters;
  metricsFilters?: SDK.MetricsFilters;
}): Promise<SDK.GetCampaignsResponse> {
  const request: SDK.GetCampaignsRequest = {
    projectIds: [projectId],
    adsFilters,
    metricsFilters,
  };
  return provider.getJson(`${SDK.BASE_URL()}/campaigns?${SDK.convertRequestToQueryParams(request)}`);
}
