import { IntegrationIconProps } from './types';

const JiraCore = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6016 20.8682C17.6016 25.9116 21.8097 30.0001 27.0007 30.0001V11.8042L17.6016 18.059V20.8682Z"
      fill="url(#paint0_linear_1334_1151)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 11.8041V3.35546C26.9991 2.85282 26.7124 2.3918 26.2549 2.15711C25.7974 1.92242 25.2439 1.95246 24.8162 2.2352L3 16.7053C5.91944 20.8735 11.7633 21.9543 16.0548 19.1198L27 11.8041Z"
      fill="#2684FF"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_1151"
        x1="19.2176"
        y1="15.4493"
        x2="26.9456"
        y2="17.3531"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.17" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default JiraCore;
