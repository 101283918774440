import { IntegrationIconProps } from './types';

const Ie = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.4247 10.4547C32.7037 0.58468 23.8399 2.03027 23.3463 2.12873C21.4684 2.50329 19.731 3.10454 18.1285 3.86681C12.0215 3.51526 6.48276 7.85253 5.14644 13.7968C8.29872 10.1926 10.5047 8.73828 11.8258 8.15642C7.94624 11.694 4.10934 16.3198 2.6958 21.4678C-0.859433 34.4159 10.2883 29.003 11.8473 28.1807C13.5259 29.026 15.4156 29.5017 17.4148 29.5017C22.8876 29.5017 27.5439 25.9412 29.2771 20.9692H22.6638C21.6853 22.6541 19.8014 23.7953 17.6371 23.7953C14.466 23.7953 11.8954 21.3466 11.8954 18.3259H29.8975C30.2378 15.629 29.7267 12.8439 28.4247 10.4547ZM27.6499 4.05024C28.7338 4.7959 29.6031 5.96674 28.1102 9.91C26.6782 7.56298 24.524 5.72187 21.9635 4.70993C23.1283 4.13666 26.0127 2.92401 27.6499 4.05024ZM4.61614 28.1545C3.73321 27.2318 3.5772 24.9845 5.52532 20.8893C6.50836 23.7701 8.47016 26.184 11.0058 27.7159C9.74477 28.4234 6.39687 30.0162 4.61614 28.1545ZM11.8676 14.818C11.9683 11.8821 14.4753 9.53143 17.5554 9.53143C20.6356 9.53143 23.1427 11.8821 23.2434 14.818H11.8676Z"
      fill="#1EBBEE"
    />
  </svg>
);
export default Ie;
