import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { DropDownMenuOption } from '@replai-platform/ui-components';
import { RootState } from '../../../store/rootReducer';
import useGetAdSets from '../../../api/hooks/adSets/useGetAdSets';

const useAdSetFilter = (
  initialOptions?: string[]
): { adSetFilterOptions: DropDownMenuOption[]; isLoading: boolean; hasData: boolean } => {
  const projectId = useSelector((state: RootState) => state.project.id);

  // Fetch and update project networks
  const { isSuccess, data, isLoading } = useGetAdSets({ projectIds: [projectId] });

  const mappedAdSets = useMemo(
    () => data?.adSets?.map(({ id, name }) => ({ adSetId: id, adSetName: name })) ?? [],
    [data]
  );

  // Create filter options
  const adSetFilterOptions = useMemo(
    () =>
      isSuccess && data
        ? [
            {
              label: 'All Ad Sets',
              isAllOption: true,
              selected: !initialOptions,
            },
            ...mappedAdSets.map((adSet) => ({
              label: `${adSet.adSetName} (${adSet.adSetId})`,
              id: adSet.adSetId,
              selected: initialOptions ? initialOptions.includes(adSet.adSetId) : true,
            })),
          ]
        : [],
    [mappedAdSets, data, isSuccess, initialOptions]
  );

  return { adSetFilterOptions, isLoading, hasData: adSetFilterOptions.length > 0 };
};

export default useAdSetFilter;
