/* eslint-disable @typescript-eslint/no-floating-promises */
import { useFeature } from '@optimizely/react-sdk';
import { isEmpty, xor } from 'lodash';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState, useEffect } from 'react';
import * as SDK from '@replai-platform/sdk';
import {
  Button,
  Colors,
  DropDownButton,
  DropDownChip,
  MultiSelectDropDownOption,
  Tabs,
  Toggle,
  Typography,
  camelCaseToCapitalCase,
} from '@replai-platform/ui-components';
import { useNavigate } from 'react-router';
import TopNavPageTitle from '../../../components/TopNavPageTitle';
import useCountryFilter from '../../../components/FilterBar/hooks/useCountryFilter';
import type { KpiFilterProps } from '../../../components/FilterBar/Filters/KpiFilter';
import CountryFilter, { CountryFilterProps } from '../../../components/FilterBar/Filters/CountryFilter';
import { RootState } from '../../../store/rootReducer';
import {
  DateFilter,
  type CampaignFilterProps,
  AdSetFilter,
  AdSetFilterProps,
  BuyingStrategyFilterProps,
  CampaignFilter,
  KpiFilter,
  SpendFilter,
  PromotedObjectFilter,
  PromotedObjectFilterProps,
  CustomTagsFilter,
  CustomTagsFilterProps,
  AgeDateFilter,
  NetworkFilterProps,
  NetworkFilter,
  BuyingStrategyFilter,
} from '../../../components/FilterBar/Filters';
import useAdSetFilter from '../../../components/FilterBar/hooks/useAdSetFilter';
import useCampaignFilter from '../../../components/FilterBar/hooks/useCampaignFilter';
import useBuyingStrategyFilter from '../../../components/FilterBar/hooks/useBuyingStrategyFilter';
import { GeneratingReportNotificationStatus, ReportsActions } from '../../../store/reports';
import { TypographyClean } from '../../../utils/styles';
import { api } from '../../../api';
import useGenerateGoogleSlidesReport from '../../../api/hooks/reports/useGenerateGoogleSlidesReport';
import { logEvent } from '../../../analytics';
import { Page } from '../../../utils/enums';
import useTagTypes from '../../../api/hooks/tags/useTagTypes';
import usePromotedObjectFilter from '../../../components/FilterBar/hooks/usePromotedObjectFilter';
import useCustomTagsFilter from '../../../components/FilterBar/hooks/useCustomTagsFilter';
import useNetworkFilter from '../../../components/FilterBar/hooks/useNetworkFilter';
import useTrendSlidesToIncludeSelector from './hooks/useTrendSlidesToIncludeSelector';
import IntroTagsToggle, { INTRO_TAGS_MAX_FIRST_APPEARANCE_SECS } from '../../../components/IntroTagsToggle';
import BreakdownFilter from '../../../components/FilterBar/Filters/BreakdownFilter';
import useBreakdownFilter from '../../../components/FilterBar/hooks/useBreakdownFilter';
import useAgencyFilter from '../../../components/FilterBar/hooks/useAgencyFilter';
import AgencyFilter, { AgencyFilterProps } from '../../../components/FilterBar/Filters/AgencyFilter';

const DISALLOWED_METRICS_TO_DISPLAY: SDK.MetricKPIWithSpend[] = [SDK.Metrics.SPEND];

const TopLevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  margin-bottom: 15rem;
`;

const MainContainer = styled.div`
  display: flex;
  gap: 4rem;
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex: 2;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
`;

const TextContainer = styled.div`
  width: 30%;
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
`;
const CustomizationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.1rem;
`;

const CustomizationChipsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
`;

const FilterChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 38rem;
  row-gap: 1rem;
`;

const FilterLineContainer = styled.div<{ columnGap?: number }>`
  display: flex;
  justify-content: flex-end;
  width: 38rem;
  ${(props) => (props.columnGap ? `column-gap: ${props.columnGap}rem;` : '')}
`;

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 1rem;
  min-width: 24rem;
`;

const StyledImage = styled.img`
  width: 100%;
  border-style: solid;
  opacity: 0.5;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1.25rem;
  margin-bottom: 1.5625rem;
  background-color: ${Colors.Gray[200]};
`;

const InvalidTextTypography = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.25rem;
`;

const NUMBER_OF_CUSTOMIZABLE_ROWS_TOP_CREATIVES = 8;
const NUMBER_OF_CUSTOMIZABLE_ROWS_TRENDS = 6;

// Custom comparator function to sort tags by kind and then by type
function compareTags(tagA: { type: string; kind: string }, tagB: { type: string; kind: string }) {
  if (tagA.kind > tagB.kind) {
    return -1;
  }
  if (tagA.kind < tagB.kind) {
    return 1;
  }

  if (tagA.type < tagB.type) {
    return -1;
  }
  if (tagA.type > tagB.type) {
    return 1;
  }

  return 0;
}

const ReportsGoogleSlides = () => {
  const [isTechMerc1456Enabled] = useFeature('techmerc-1456'); // Trends template.
  const [isTechMerc1525Enabled] = useFeature('techmerc-1525'); // Buying Strategy filter
  const [isTechMerc1513Enabled] = useFeature('techmerc-1513'); // Winners DNA Template
  const [isTechMerc1550Enabled] = useFeature('techmerc-1550'); // Agency filter

  const projectId = useSelector((state: RootState) => state.project.id);
  const projectNetworks = useSelector((state: RootState) => state.project.networks);
  const userEmail = useSelector((state: RootState) => state.auth.email);
  const {
    template,
    selectedKpis,
    selectedTagTypes,
    selectedDate,
    selectedCompareToDate,
    ageSelectedDate,
    countries,
    maxFirstAppearanceSeconds,
    networks,
    adSetIdsToConsider,
    adSetIdsToExclude,
    campaignIdsToConsider,
    campaignIdsToExclude,
    promotedObjectTypes,
    tagsToConsider,
    tagsToExclude,
    trendSlidesToInclude,
    breakdown,
    minSpend: defaultMinSpend,
    maxTopItems,
    conceptLevel,
    templateId,
    defaultSortByKpi,
  } = useSelector((state: RootState) => state.reports);
  const { tagsTypes, defaultProjectKpis } = useSelector((state: RootState) => state.project.config);
  const baseMetric = useSelector((state: RootState) => state.project.baseMetric);

  const shouldFilterByNetwork = () => networks.length !== 0 && !isEmpty(xor(networks, projectNetworks));

  const [formError, setFormError] = useState<string | null>(null);
  const [sortByKpi, setSortByKpi] = useState<string>(defaultSortByKpi ?? baseMetric);
  const [minSpend, setMinSpend] = useState<number | null>(defaultMinSpend ?? null);

  const generateReportMutation = useGenerateGoogleSlidesReport();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      template === SDK.GoogleSlidesTemplates.TOP_CREATIVES &&
      (selectedKpis?.length ?? 0) + (selectedTagTypes?.length ?? 0) > NUMBER_OF_CUSTOMIZABLE_ROWS_TOP_CREATIVES
    ) {
      setFormError(`You cannot select more than ${NUMBER_OF_CUSTOMIZABLE_ROWS_TOP_CREATIVES} values on this section`);
      return;
    }
    if (
      template === SDK.GoogleSlidesTemplates.TRENDS &&
      (selectedKpis?.length ?? 0) + (selectedTagTypes?.length ?? 0) > NUMBER_OF_CUSTOMIZABLE_ROWS_TRENDS
    ) {
      setFormError(`You cannot select more than ${NUMBER_OF_CUSTOMIZABLE_ROWS_TRENDS} values on this section`);
      return;
    }
    if (template === SDK.GoogleSlidesTemplates.TRENDS && trendSlidesToInclude.length <= 0) {
      setFormError('Select at least one type of slide to include.');
      return;
    }
    setFormError(null);
  }, [selectedKpis, selectedTagTypes, template, trendSlidesToInclude]);

  // Build network filter
  const { networkFilterOptions, isLoading: isNetworkFilterLoading } = useNetworkFilter(Page.Reports, networks);

  const networkFilter = useMemo(
    () =>
      ({
        options: networkFilterOptions,
        onChange: (options: MultiSelectDropDownOption[]) => {
          const selectedOptions = options
            .slice(1)
            .filter(({ selected }) => selected)
            .map(({ label }) => (label ?? '').toLowerCase() as SDK.Network);
          dispatch(ReportsActions.changeNetworks({ value: selectedOptions }));
        },
        loading: isNetworkFilterLoading,
      } as NetworkFilterProps),
    [networkFilterOptions, isNetworkFilterLoading, dispatch]
  );

  // Build country filter
  const {
    countryFilterOptions,
    isLoading: countryFilterIsLoading,
    setIsInitialFilter: setIsInitialFilterCountry,
  } = useCountryFilter({
    selectedCountries: countries,
  });
  const countryFilter = useMemo(
    () =>
      ({
        options: countryFilterOptions,
        onChange: (options) => {
          const selectedOptions = options
            .filter(({ selected, isAllOption }) => selected && !isAllOption)
            .map(({ id }) => id)
            .filter((v): v is string => typeof v === 'string');

          dispatch(
            ReportsActions.changeCountries({
              value: selectedOptions,
            })
          );

          setIsInitialFilterCountry(false);
        },
        loading: countryFilterIsLoading,
      } as CountryFilterProps),
    [dispatch, countryFilterIsLoading, countryFilterOptions, setIsInitialFilterCountry]
  );

  // Build Campaign Filter
  const { campaignFilterOptions, isLoading: campaignFilterIsLoading } = useCampaignFilter(campaignIdsToConsider);
  const campaignFilter: CampaignFilterProps = useMemo(
    () => ({
      options: campaignFilterOptions,
      loading: campaignFilterIsLoading,
      onChange: (options) => {
        const isAllSelected =
          options.some(({ isAllOption, selected }) => isAllOption && selected) &&
          options.every(({ selected }) => !!selected);

        const selectedOptions = options
          .filter(({ selected, isAllOption }) => selected && !isAllOption)
          .map(({ id }) => id)
          .filter((v): v is string => typeof v === 'string');

        const unselectedOptions = options
          .filter(({ selected, isAllOption }) => !selected && !isAllOption)
          .map(({ id }) => id)
          .filter((v): v is string => typeof v === 'string');

        const toExclude = selectedOptions.length > unselectedOptions.length;

        dispatch(
          ReportsActions.changeCampaigns({
            value: isAllSelected ? [] : toExclude ? unselectedOptions : selectedOptions,
            toExclude,
          })
        );
      },
    }),
    [campaignFilterOptions, dispatch, campaignFilterIsLoading]
  );

  // Build Buying Strategy Filter
  const {
    buyingStrategyFilterOptions,
    buyingStrategyFilterOnChange,
    isLoading: isBuyingStrategyFilterLoading,
  } = useBuyingStrategyFilter(Page.Reports);
  const buyingStrategyFilter = useMemo(
    () =>
      ({
        options: buyingStrategyFilterOptions,
        onChange: buyingStrategyFilterOnChange,
        loading: isBuyingStrategyFilterLoading,
      } as BuyingStrategyFilterProps),
    [buyingStrategyFilterOptions, buyingStrategyFilterOnChange, isBuyingStrategyFilterLoading]
  );

  // Build agency filter
  const { agencyFilterOptions, agencyFilterOnChange, isLoading: isAgencyFilterLoading } = useAgencyFilter(Page.Reports);
  const agencyFilter = useMemo(
    () =>
      ({
        options: agencyFilterOptions,
        onChange: agencyFilterOnChange,
        loading: isAgencyFilterLoading,
      } as AgencyFilterProps),
    [agencyFilterOptions, agencyFilterOnChange, isAgencyFilterLoading]
  );

  // Build Breakdown filter
  const onBreakdownChange = (value: SDK.Breakdown) => {
    dispatch(
      ReportsActions.changeBreakdown({
        value,
      })
    );
  };

  const { breakdownFilterOptions } = useBreakdownFilter({ onChange: onBreakdownChange });

  // Build Ad Set Filter
  const { adSetFilterOptions, isLoading: adSetFilterIsLoading } = useAdSetFilter(adSetIdsToConsider);
  const adSetFilter: AdSetFilterProps = useMemo(
    () => ({
      options: adSetFilterOptions,
      loading: adSetFilterIsLoading,
      onChange: (options) => {
        const isAllSelected =
          options.some(({ isAllOption, selected }) => isAllOption && selected) &&
          options.every(({ selected }) => !!selected);

        const selectedOptions = options
          .filter(({ selected, isAllOption }) => selected && !isAllOption)
          .map(({ id }) => id)
          .filter((v): v is string => typeof v === 'string');

        const unselectedOptions = options
          .filter(({ selected, isAllOption }) => !selected && !isAllOption)
          .map(({ id }) => id)
          .filter((v): v is string => typeof v === 'string');

        const toExclude = selectedOptions.length > unselectedOptions.length;

        dispatch(
          ReportsActions.changeAdSets({
            value: isAllSelected ? [] : toExclude ? unselectedOptions : selectedOptions,
            toExclude,
          })
        );
      },
    }),
    [adSetFilterOptions, dispatch, adSetFilterIsLoading]
  );

  // KPI Selector
  const kpiFilterOptions = Array.from(new Set([...defaultProjectKpis, SDK.Metrics.IMPRESSIONS]))
    .filter((kpi) => kpi !== SDK.Metrics.AGE)
    .map((kpi) => ({
      label: SDK.kpiUtils.getDisplayName(kpi),
      onClick: () => setSortByKpi(kpi),
    }));
  const kpiFilter = useMemo<KpiFilterProps>(
    () => ({
      dropdownType: 'singleselect',
      options: kpiFilterOptions,
      defaultOption: sortByKpi,
    }),
    [kpiFilterOptions, sortByKpi]
  );

  const { data: tags, isLoading: isTagsLoading } = useTagTypes({
    projectIds: [projectId],
    tagsFilters: {
      tagTypesToExclude: [...(tagsTypes?.excluded ?? []), ...(tagsTypes?.psychological ?? [])].map((type) => ({
        type,
      })),
    },
  });

  const tagTypesSelectorOptions = useMemo(
    () =>
      [...new Set(tags?.sort(compareTags)?.map((tag) => tag.type))].map((tagType) => ({
        id: tagType,
        label: camelCaseToCapitalCase(tagType),
        selected: selectedTagTypes.includes(tagType),
      })) ?? [],
    [tags]
  );

  const tagTypesSelectorOnChange = useCallback(
    (options: MultiSelectDropDownOption[]) => {
      dispatch(
        ReportsActions.setSelectedTagTypes(
          options?.filter((option) => option.selected).map((option) => option.id ?? '') ?? []
        )
      );
    },
    [dispatch]
  );

  const kpisSelectorOptions = useMemo(
    () =>
      Array.from(
        new Set([
          ...defaultProjectKpis,
          SDK.Metrics.INSTALLS,
          SDK.Metrics.AGE,
          SDK.Metrics.CLICKS,
          SDK.Metrics.IMPRESSIONS,
        ])
      )
        .filter((kpi) => !DISALLOWED_METRICS_TO_DISPLAY.includes(kpi))
        .map((kpi) => ({
          id: kpi,
          label: SDK.kpiUtils.getDisplayName(kpi),
          selected: selectedKpis.includes(kpi),
        })),
    [defaultProjectKpis]
  );

  const kpisSelectorOnChange = useCallback(
    (options: MultiSelectDropDownOption[]) => {
      dispatch(
        ReportsActions.setSelectedKpis(
          options?.filter((option) => option.selected).map((option) => (option.id ?? '') as SDK.MetricOrKpi) ?? []
        )
      );
    },
    [dispatch]
  );

  // Build promoted object types filter
  const { promotedObjectTypeFilterOptions, setIsInitialFilter: setIsInitialFilterPOT } = usePromotedObjectFilter(
    Page.Reports,
    promotedObjectTypes
  );
  const promotedObjectFilter: PromotedObjectFilterProps = useMemo(
    () => ({
      options: promotedObjectTypeFilterOptions,
      onChange: (options) => {
        setIsInitialFilterPOT(false);

        const selectedOptions = options
          .slice(1)
          .filter(({ selected }) => selected)
          .map(({ id }) => id as SDK.PromotedObjectType);

        dispatch(
          ReportsActions.changePromotedObjectTypes({
            value: selectedOptions,
          })
        );
      },
      loading: false,
    }),
    [dispatch, promotedObjectTypeFilterOptions, setIsInitialFilterPOT]
  );

  // Tags filter
  const {
    customTagsFilterOptions,
    customTagsFilterOnChange,
    isLoading: isCustomTagsFilterLoading,
  } = useCustomTagsFilter('reports');
  const customTagsFilter: CustomTagsFilterProps = useMemo(
    () => ({
      options: customTagsFilterOptions,
      onChange: customTagsFilterOnChange,
      loading: isCustomTagsFilterLoading,
    }),
    [customTagsFilterOptions, isCustomTagsFilterLoading, customTagsFilterOnChange]
  );

  // Trend slides to include selector
  const { trendSlidesToIncludeSelectorOptions, trendSlidesToIncludeSelectorOnChange } =
    useTrendSlidesToIncludeSelector();

  const onGenerateReport = async () => {
    const body: SDK.ExportToGoogleSlidesRequestBody = {
      templateId,
      reportType: SDK.ReportTypes.GOOGLE_SLIDES,
      adsFilters: {
        adSetIdsToConsider,
        adSetIdsToExclude,
        campaignIdsToConsider,
        campaignIdsToExclude,
        promotedObjectTypesToConsider: promotedObjectTypes,
        networksToConsider: shouldFilterByNetwork() ? networks : undefined,
      },
      assetFilters: {
        minSpend: minSpend ?? undefined,
      },
      assetTagsFilters: {
        maxFirstAppearanceSeconds:
          template === SDK.GoogleSlidesTemplates.TRENDS ? maxFirstAppearanceSeconds ?? undefined : undefined,
      },
      compareToTimeRange: {
        dateStartTimestamp: new Date(`${selectedCompareToDate.startDate ?? ''}T00:00:00.000Z`).getTime(),
        dateEndTimestamp: new Date(`${selectedCompareToDate.endDate ?? ''}T00:00:00.000Z`).getTime(),
      },
      mainKpi: sortByKpi as SDK.MetricOrKpi,
      metricsFilters: api.filterConverter.getMetricsFilters({
        countries,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        ageStartDate: ageSelectedDate.ageStartDate,
        ageEndDate: ageSelectedDate.ageEndDate,
      }),
      tagsFilters: api.filterConverter.getTagsFilters({
        tagsToConsider,
        tagsToExclude,
      }),
      projectIds: [projectId],
      shareWithEmail: userEmail,
      metricsToDisplay: selectedKpis,
      tagTypesToDisplay: selectedTagTypes,
      conceptLevel,
      maxTopItems,
      template,
      trendSlidesToInclude,
      breakdown,
    };

    await generateReportMutation.mutateAsync(body, {
      onSuccess: () => {
        dispatch(ReportsActions.setGeneratingReportNotification(GeneratingReportNotificationStatus.SUCCESS));
        navigate(`/${projectId}/${Page.Reports}`);
      },
      onError: () => {
        dispatch(ReportsActions.setGeneratingReportNotification(GeneratingReportNotificationStatus.FAILURE));
      },
      onSettled: () => {
        logEvent({
          component: 'Reports',
          action: `Generate report`,
          category: 'user_actions',
        });
      },
    });
  };

  const tabLabels = useMemo(
    () => [
      { id: SDK.GoogleSlidesTemplates.TOP_CREATIVES, label: 'Metrics Report' },
      {
        disabled: !isTechMerc1456Enabled,
        id: SDK.GoogleSlidesTemplates.TRENDS,
        label: 'Trends Report',
        tooltipContent: isTechMerc1456Enabled ? undefined : <Typography>Coming soon.</Typography>,
      },
      ...(isTechMerc1513Enabled ? [{ id: SDK.GoogleSlidesTemplates.WINNERS_DNA, label: 'Winners DNA Report' }] : []),
    ],
    [isTechMerc1456Enabled, isTechMerc1513Enabled]
  );

  return (
    <TopLevelContainer>
      <TopNavPageTitle
        title="Google Slides Report"
        subtitle="Generate a Google Slides presentation from our templates"
        eventPrefix="reports-google-slides"
        navigateToPage={Page.Reports}
      />

      <Tabs
        onTabChange={(newTab) => {
          logEvent({
            action: `Go to tab ${newTab.id ?? SDK.messages.NOT_AVAILABLE}`,
            component: 'Google Slides Report',
          });
          dispatch(ReportsActions.setTemplate(newTab.id as SDK.GoogleSlidesTemplates));
        }}
        tabLabels={tabLabels}
        defaultSelectedTab={tabLabels.find((tab) => tab.id === template)}
      >
        <MainContainer>
          <FormContainer>
            <div>
              <FiltersContainer>
                <TextContainer>
                  <Typography fontWeight="semi-bold" type="text-lg">
                    Filters
                  </Typography>
                  <Typography type="text-sm">
                    {template === SDK.GoogleSlidesTemplates.TRENDS
                      ? 'Narrow the data you want the report to be about. The trends report will be computed by comparing metrics between the two time ranges selected.'
                      : 'Narrow the data you want the report to be about.'}
                  </Typography>
                </TextContainer>
                <FilterChipsContainer>
                  <FilterLineContainer columnGap={0.75}>
                    {template !== SDK.GoogleSlidesTemplates.WINNERS_DNA ? (
                      <>
                        <CampaignFilter
                          options={campaignFilter.options}
                          onChange={campaignFilter.onChange}
                          loading={campaignFilter.loading}
                          dropdownMaxHeightInVH={65}
                        />
                        {isTechMerc1525Enabled && (
                          <BuyingStrategyFilter
                            options={buyingStrategyFilter.options}
                            onChange={buyingStrategyFilter.onChange}
                            loading={buyingStrategyFilter.loading}
                          />
                        )}
                        {isTechMerc1550Enabled && (
                          <AgencyFilter
                            options={agencyFilter.options}
                            onChange={agencyFilter.onChange}
                            loading={agencyFilter.loading}
                          />
                        )}
                      </>
                    ) : undefined}
                  </FilterLineContainer>
                  <FilterLineContainer columnGap={0.75}>
                    <CountryFilter
                      options={countryFilter.options}
                      onChange={countryFilter.onChange}
                      loading={countryFilter.loading}
                      dropdownMaxHeightInVH={65}
                    />
                    <NetworkFilter
                      options={networkFilter.options}
                      onChange={networkFilter.onChange}
                      disabled={networkFilter.disabled}
                      loading={networkFilter.loading}
                    />
                  </FilterLineContainer>
                  {template !== SDK.GoogleSlidesTemplates.WINNERS_DNA ? (
                    <FilterLineContainer columnGap={0.75}>
                      <AdSetFilter
                        options={adSetFilter.options}
                        onChange={adSetFilter.onChange}
                        loading={adSetFilter.loading}
                        dropdownMaxHeightInVH={65}
                      />
                    </FilterLineContainer>
                  ) : undefined}
                  <FilterLineContainer columnGap={0.75}>
                    <BreakdownFilter options={breakdownFilterOptions} />
                  </FilterLineContainer>
                  {template === SDK.GoogleSlidesTemplates.TRENDS ? (
                    <>
                      <FilterLineContainer columnGap={0.75}>
                        <Typography noMargin style={{ marginTop: '0.75rem', textAlign: 'end' }} type="text-xs">
                          1st Period:
                        </Typography>
                        <DateFilter
                          customDispatch={ReportsActions.changeCompareToTimeRange}
                          customDates={selectedCompareToDate}
                        />
                      </FilterLineContainer>
                      <FilterLineContainer columnGap={0.75}>
                        <Typography noMargin style={{ marginTop: '0.75rem', textAlign: 'end' }} type="text-xs">
                          2nd Period:
                        </Typography>
                        <DateFilter customDispatch={ReportsActions.changeDateRange} customDates={selectedDate} />
                      </FilterLineContainer>
                      <FilterLineContainer columnGap={0.75}>
                        <AgeDateFilter
                          customDispatch={ReportsActions.changeAgeDateRange}
                          customDates={ageSelectedDate}
                        />
                      </FilterLineContainer>
                    </>
                  ) : (
                    <FilterLineContainer columnGap={0.75}>
                      <DateFilter customDispatch={ReportsActions.changeDateRange} customDates={selectedDate} />
                      <AgeDateFilter customDispatch={ReportsActions.changeAgeDateRange} customDates={ageSelectedDate} />
                    </FilterLineContainer>
                  )}
                  <FilterLineContainer columnGap={0.75}>
                    <SpendFilter
                      customState={{
                        value: minSpend,
                        onChange: setMinSpend,
                      }}
                    />
                    <PromotedObjectFilter
                      options={promotedObjectFilter.options}
                      onChange={promotedObjectFilter.onChange}
                      disabled={promotedObjectFilter.disabled}
                      loading={promotedObjectFilter.loading}
                    />
                    <CustomTagsFilter
                      options={customTagsFilter.options}
                      onChange={customTagsFilter.onChange}
                      loading={customTagsFilter.loading}
                    />
                  </FilterLineContainer>
                  <FilterLineContainer columnGap={0.75}>
                    {template === SDK.GoogleSlidesTemplates.TRENDS ? (
                      <IntroTagsToggle
                        initialValue={maxFirstAppearanceSeconds ?? undefined}
                        customDispatch={(checked) =>
                          ReportsActions.changeMaxFirstAppearanceSeconds({
                            value: checked ? INTRO_TAGS_MAX_FIRST_APPEARANCE_SECS : undefined,
                          })
                        }
                      />
                    ) : null}
                    <Toggle
                      variant="outlined"
                      label="Concept Level"
                      labelPosition="start"
                      checked={conceptLevel}
                      onChange={() => dispatch(ReportsActions.changeConceptLevel(!conceptLevel))}
                    />
                    <DropDownButton
                      onClickCrossButton={() => dispatch(ReportsActions.changeMaxTopItems(null))}
                      onConfirm={(value) =>
                        dispatch(ReportsActions.changeMaxTopItems(Number.parseInt(value.toString(), 10)))
                      }
                      placeHolder={`Top ${maxTopItems} items`}
                      defaultValue={maxTopItems}
                      title="Max top items"
                    />
                  </FilterLineContainer>
                </FilterChipsContainer>
              </FiltersContainer>
              {template !== SDK.GoogleSlidesTemplates.WINNERS_DNA ? (
                <>
                  <Divider />
                  <CustomizationContainer>
                    <TextContainer>
                      <Typography fontWeight="semi-bold" type="text-lg">
                        Customization
                      </Typography>
                      <Typography type="text-sm">
                        Select the information you want to see in the report. The total number of custom rows to display
                        cannot be higher than{' '}
                        {template === SDK.GoogleSlidesTemplates.TOP_CREATIVES
                          ? NUMBER_OF_CUSTOMIZABLE_ROWS_TOP_CREATIVES
                          : NUMBER_OF_CUSTOMIZABLE_ROWS_TRENDS}
                        .
                      </Typography>
                    </TextContainer>
                    <CustomizationChipsContainer>
                      <KpiFilter
                        options={kpiFilter.options}
                        defaultOption={SDK.kpiUtils.getDisplayName(kpiFilter.defaultOption)}
                        dropdownType={kpiFilter.dropdownType}
                        onChange={kpiFilter.onChange}
                        prefixLabel="Sort by KPI: "
                      />
                      <DropDownChip
                        placeHolder="Select KPIs"
                        dropDownType="multiselect"
                        dropDownOptions={kpisSelectorOptions}
                        onChange={kpisSelectorOnChange}
                        dropdownMaxHeightInVH={30}
                        prefixLabel="KPIs"
                        invalid={!!formError}
                      />
                      {[SDK.GoogleSlidesTemplates.TOP_CREATIVES].includes(template) ? (
                        <DropDownChip
                          placeHolder="Select Tag Types"
                          dropDownType="multiselect"
                          dropDownOptions={tagTypesSelectorOptions}
                          onChange={tagTypesSelectorOnChange}
                          prefixLabel="Tags"
                          invalid={!!formError}
                          loading={isTagsLoading}
                          dropdownMaxHeightInVH={50}
                        />
                      ) : null}
                      {[SDK.GoogleSlidesTemplates.TRENDS].includes(template) ? (
                        <DropDownChip
                          placeHolder="Select slides to include"
                          dropDownType="multiselect"
                          dropDownOptions={trendSlidesToIncludeSelectorOptions}
                          onChange={trendSlidesToIncludeSelectorOnChange}
                          prefixLabel="Slides to include"
                          invalid={!!formError}
                          loading={isTagsLoading}
                          dropdownMaxHeightInVH={50}
                        />
                      ) : null}
                    </CustomizationChipsContainer>
                  </CustomizationContainer>
                </>
              ) : undefined}
              {formError ? (
                <InvalidTextTypography>
                  <TypographyClean type="text-sm" color={Colors.Error[400]}>
                    {formError}
                  </TypographyClean>
                </InvalidTextTypography>
              ) : (
                ''
              )}
              <Divider />

              <ButtonContainer>
                <Button
                  leadingIcon={generateReportMutation?.isLoading ? { name: 'LoadingCircle' } : { name: 'Sparkles' }}
                  onClick={() => {
                    onGenerateReport();
                  }}
                  disabled={generateReportMutation?.isLoading || !!formError}
                >
                  Generate Report
                </Button>
              </ButtonContainer>
            </div>
          </FormContainer>
          <ImageContainer>
            <TypographyClean fontWeight="semi-bold" type="text-lg">
              Current template:
            </TypographyClean>
            <StyledImage
              key={`image-${template}-1`}
              src={SDK.getReportTemplateImageUrl({
                reportType: SDK.ReportTypes.GOOGLE_SLIDES,
                reportTemplate: template,
                index: 1,
              })}
            />
            {template !== SDK.GoogleSlidesTemplates.WINNERS_DNA ? (
              <StyledImage
                key={`image-${template}-2`}
                src={SDK.getReportTemplateImageUrl({
                  reportType: SDK.ReportTypes.GOOGLE_SLIDES,
                  reportTemplate: template,
                  index: 2,
                })}
              />
            ) : undefined}
          </ImageContainer>
        </MainContainer>
      </Tabs>
    </TopLevelContainer>
  );
};

export default ReportsGoogleSlides;
