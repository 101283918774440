import styled, { css } from 'styled-components';
import Colors from '../Colors';

export const Root = styled.div<{ $marginLeft: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-radius: 50%;
  margin-left: ${(props) => props.$marginLeft}px;

  &.labelRoot {
    flex-grow: 1;
  }

  &.root-xs {
    gap: 8px;
  }

  &.root-sm {
    gap: 10px;
  }

  &.root-md {
    gap: 12px;
  }

  &.root-lg {
    gap: 12px;
  }

  &.root-xl {
    gap: 16px;
  }

  &.root-xxl {
    gap: 18px;
  }
`;

export const IconRoot = styled.div<{
  $backgroundColor?: string;
  $color?: string;
  $fontSize?: string;
  $borderWidth?: string;
  $clickable?: boolean;
  $profileIcon?: boolean;
  $isGroup?: boolean;
  $squared?: boolean;
}>`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 50%;
  background-color: ${(props) => props.$backgroundColor};
  color: ${(props) => props.$color};
  font-size: ${(props) => props.$fontSize};
  border-width: ${(props) => props.$borderWidth};

  &.fixedOutline {
    outline: 4px solid ${Colors.Blue[100]};
  }

  &.hoverOutline:hover {
    outline: 4px solid ${Colors.Blue[100]};
  }

  &.xs {
    width: 24px;
    height: 24px;
  }

  &.sm {
    width: 32px;
    height: 32px;
  }

  &.md {
    width: 40px;
    height: 40px;
  }

  &.lg {
    width: 48px;
    height: 48px;
  }

  &.xl {
    width: 56px;
    height: 56px;
  }

  &.xxl {
    width: 64px;
    height: 64px;
  }

  ${(props) => (props.$clickable ? ClickableMixin : '')}
  ${(props) => (props.$profileIcon ? ProfileIconMixin : '')}
  ${(props) => (props.$isGroup ? IsGroupMixin : '')}
  ${(props) => (props.$squared ? SquaredMixin : '')}
`;

export const ImageHolder = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 64px;
`;

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: inherit;
  position: 'absolute';
`;

export const Acronym = styled.span`
  color: ${Colors.Common.White};
`;

export const CompanyIcon = styled.div<{
  $width: number;
  $height: number;
  $backgroundImage: string;
}>`
  position: absolute;
  right: 0;
  bottom: 0;
  border: 1.5px solid ${Colors.Common.White};
  border-radius: 50%;
  overflow: hidden;
  background-position: center;
  background-size: cover;

  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  background-image: ${(props) => props.$backgroundImage};
`;

export const IconOverlay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.6);
`;

export const ActiveIndicator = styled.div<{
  $width: number;
  $height: number;
}>`
  position: absolute;
  right: 0;
  bottom: 0;
  border: 1.5px solid ${Colors.Common.White};
  border-radius: 50%;

  &.active {
    width: max-content;
    background: ${Colors.Success[500]};
  }

  &.inactive {
    width: max-content;
    background: #ff2d3c;
  }

  &&& {
    width: ${(props) => props.$width}px;
    height: ${(props) => props.$height}px;
  }
`;

export const InfoHolder = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-width: 0;
`;

export const LabelSpan = styled.span<{
  $multiLine: boolean;
  $color: string;
  $labelSize: number;
  $fontWeight?: number;
}>`
  overflow: hidden;
  ${({ $fontWeight }) =>
    $fontWeight ? `font-weight: ${$fontWeight || 0};` : ''}
  ${({ $multiLine }) => ($multiLine ? '' : 'white-space: nowrap;')}
  ${({ $multiLine }) => ($multiLine ? '' : 'text-overflow: ellipsis;')}
  color: ${({ $color }) => $color};
  font-size: ${({ $labelSize }) => $labelSize}px;
`;

/*  Mixins  */

const ClickableMixin = css`
  cursor: pointer;
`;

const ProfileIconMixin = css`
  border: 4px solid ${Colors.Common.White} !important;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
`;

const IsGroupMixin = css`
  border: 1.5px solid ${Colors.Common.White};
`;

const SquaredMixin = css`
  border-radius: 4px;

  .imgHolder {
    border-radius: 4px;
  }
`;
