import { IntegrationIconProps } from './types';

const Linear = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 10.05C2 7.20255 2 5.77882 2.56293 4.69513C3.03731 3.78191 3.78191 3.03731 4.69513 2.56293C5.77882 2 7.20255 2 10.05 2H21.95C24.7975 2 26.2212 2 27.3049 2.56293C28.2181 3.03731 28.9627 3.78191 29.4371 4.69513C30 5.77882 30 7.20255 30 10.05V21.95C30 24.7975 30 26.2212 29.4371 27.3049C28.9627 28.2181 28.2181 28.9627 27.3049 29.4371C26.2212 30 24.7975 30 21.95 30H10.05C7.20255 30 5.77882 30 4.69513 29.4371C3.78191 28.9627 3.03731 28.2181 2.56293 27.3049C2 26.2212 2 24.7975 2 21.95V10.05Z"
      fill="url(#paint0_linear_1677_406626)"
    />
    <path
      d="M14.6209 24.1936L7.80664 17.3794C8.3887 20.8625 11.1378 23.6116 14.6209 24.1936Z"
      fill="#F2F3F8"
    />
    <path
      d="M7.70508 15.5375L16.4626 24.295C16.9821 24.2665 17.489 24.1903 17.9788 24.0706L7.92952 14.0214C7.80983 14.5112 7.73364 15.018 7.70508 15.5375Z"
      fill="#F2F3F8"
    />
    <path
      d="M8.36719 12.7175L19.2838 23.634C19.6808 23.463 20.0619 23.262 20.4241 23.0338L8.96744 11.5771C8.73919 11.9393 8.53819 12.3204 8.36719 12.7175Z"
      fill="#F2F3F8"
    />
    <path
      d="M9.70508 10.5767C11.2285 8.81032 13.4829 7.69226 15.9986 7.69226C20.5868 7.69226 24.3063 11.4118 24.3063 15.9999C24.3063 18.5156 23.1882 20.77 21.4218 22.2934L9.70508 10.5767Z"
      fill="#F2F3F8"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1677_406626"
        x1="2"
        y1="30"
        x2="30"
        y2="2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#29359B" />
        <stop offset="1" stopColor="#6068CA" />
      </linearGradient>
    </defs>
  </svg>
);
export default Linear;
