import { CommonTagIconProps } from '../types';

const CallToAction = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5001 5.53491H4.50006C3.05506 5.53491 1.88306 6.70691 1.88306 8.15191V15.8469C1.88306 17.2919 3.05506 18.4639 4.50006 18.4639H19.5001C20.9451 18.4639 22.1171 17.2919 22.1171 15.8469V8.15191C22.1171 6.70691 20.9451 5.53491 19.5001 5.53491Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.73702 11.285C6.98902 11.285 7.19502 11.399 7.38902 11.64L7.57202 11.88L8.85402 10.747L8.69402 10.53C8.23602 9.93401 7.52602 9.61401 6.66802 9.61401C5.20302 9.61401 4.15002 10.621 4.15002 12.006C4.15002 13.379 5.20302 14.386 6.66802 14.386C7.52602 14.386 8.23602 14.066 8.69402 13.47L8.85402 13.253L7.57202 12.12L7.38902 12.36C7.19402 12.6 6.98802 12.715 6.73702 12.715C6.32502 12.715 6.08502 12.452 6.08502 12.005C6.08502 11.548 6.32502 11.285 6.73702 11.285Z"
      fill="black"
    />
    <path
      d="M13.831 9.70508H9.55103V11.3311H10.73V14.2951H12.652V11.3311H13.831V9.70508Z"
      fill="black"
    />
    <path
      d="M17.7831 9.70508H16.0781L14.0751 14.2941H16.0891L16.3521 13.5961H17.4851L17.7601 14.2941H19.7861L17.7831 9.70508ZM17.3141 12.3381H16.5361L16.9251 11.3201L17.3141 12.3381Z"
      fill="black"
    />
  </svg>
);
export default CallToAction;
