import { IntegrationIconProps } from './types';

const AdobeIllustrator = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="28" height="28" fill="#FB7C33" />
    <rect x="3" y="3" width="26" height="26" fill="#261302" />
    <path
      d="M11.9701 19.1606L11.0339 22.874C11.0145 22.9754 10.9758 23 10.8582 23H9.1227C9.00654 23 8.98718 22.9594 9.00654 22.816L12.3614 10.501C12.4195 10.2764 12.4596 10.0823 12.479 9.46942C12.479 9.38829 12.5177 9.34627 12.5772 9.34627H15.0539C15.1314 9.34627 15.1715 9.36655 15.1908 9.46942L18.955 22.8363C18.9744 22.9377 18.955 23 18.8569 23H16.907C16.8102 23 16.7507 22.9754 16.7314 22.8942L15.7564 19.1606H11.9701ZM15.2655 17.1482L13.8619 11.3283L12.4776 17.1482H15.2655ZM20.5827 10.2663C20.5827 9.46942 21.1096 9 21.7914 9C22.5132 9 23 9.51144 23 10.2663C23 11.0834 22.4925 11.5325 21.772 11.5325C21.0695 11.5325 20.5827 11.0834 20.5827 10.2663ZM20.7155 13.0813C20.7155 12.9785 20.7542 12.9379 20.8524 12.9379H22.7068C22.805 12.9379 22.8437 12.9785 22.8437 13.0813V22.8566C22.8437 22.9594 22.8244 23 22.7068 23H20.8717C20.7542 23 20.7155 22.9391 20.7155 22.8363V13.0813Z"
      fill="#FB7C33"
    />
  </svg>
);
export default AdobeIllustrator;
