import { IntegrationIconProps } from './types';

const KakaoTalk = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9947 3C8.26662 3 2 7.97177 2 14.1072C2 18.074 4.6181 21.5489 8.56102 23.5218L7.14157 28.6258C7.14157 28.6258 7.11528 28.8638 7.26774 28.9537C7.4202 29.0436 7.60421 28.9749 7.60421 28.9749C8.04581 28.9114 12.7037 25.6163 13.5186 25.045C14.3229 25.1614 15.1588 25.2196 16.0053 25.2196C23.7334 25.2196 30 20.2478 30 14.1124C30 7.97706 23.7229 3 15.9947 3Z"
      fill="black"
    />
    <path
      d="M8.25642 17.9787C7.80955 17.9787 7.4468 17.6296 7.4468 17.2012V12.3722H6.18507C5.74872 12.3722 5.39648 12.0179 5.39648 11.5789C5.39648 11.1399 5.75398 10.7855 6.18507 10.7855H10.3225C10.7589 10.7855 11.1111 11.1399 11.1111 11.5789C11.1111 12.0179 10.7536 12.3722 10.3225 12.3722H9.06077V17.2012C9.06077 17.6243 8.69277 17.9787 8.25116 17.9787H8.25642ZM15.3379 17.9681C15.0014 17.9681 14.7438 17.8306 14.665 17.6085L14.2654 16.5506H11.805L11.4055 17.6085C11.3266 17.8306 11.069 17.9681 10.7326 17.9681C10.5538 17.9681 10.3803 17.9311 10.2174 17.8623C9.99656 17.7566 9.78101 17.4762 10.0281 16.7093L11.9575 11.5947C12.0942 11.2033 12.5095 10.8014 13.0352 10.7908C13.561 10.7802 13.9815 11.2033 14.113 11.5947L16.0424 16.7093C16.2895 17.4762 16.0739 17.7671 15.8531 17.8623C15.6954 17.9364 15.5166 17.9681 15.3379 17.9681ZM13.8396 15.112L13.0352 12.8059L12.2309 15.112H13.8396ZM17.3409 17.8623C16.9203 17.8623 16.5681 17.5291 16.5681 17.1219V11.5947C16.5681 11.1452 16.9361 10.7802 17.3935 10.7802C17.8509 10.7802 18.2189 11.1505 18.2189 11.5947V16.3655H19.9327C20.3533 16.3655 20.7055 16.6987 20.7055 17.106C20.7055 17.5133 20.3586 17.8465 19.9327 17.8465L17.3409 17.8623ZM21.0157 17.1536V11.6C21.0157 11.1505 21.3837 10.7855 21.8253 10.7855C22.2669 10.7855 22.6349 11.1557 22.6349 11.6V13.3454L24.8955 11.0711C25.1444 10.8208 25.6148 10.8427 25.8997 11.1293C26.1673 11.3985 26.2368 11.8535 25.9575 12.1395L24.107 13.9907L26.0942 16.6353C26.3612 16.9791 26.2964 17.5147 25.9365 17.7671C25.5816 18.0316 25.0603 17.9434 24.8062 17.6085L22.9136 15.0856L22.6349 15.3659V17.1377C22.6349 18.1931 21.0157 18.2338 21.0157 17.1536Z"
      fill="#FFE812"
    />
  </svg>
);
export default KakaoTalk;
