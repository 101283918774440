import moment from 'moment';
import { SerializedMetricsTimeFrame, MetricsTimeFrame, Metric } from '../types';
import { KPI, MetricOrKpi } from '../enums';
import { PerformanceIndicatorType } from '../../common/enums';
import { ComparableResult } from '../../../utils/types';

export function getMetricOrKPI(metricOrKpi: string): MetricOrKpi | undefined {
  return <MetricOrKpi>(metricOrKpi || '') ?? undefined;
}

export function parseMetrics(
  obj: SerializedMetricsTimeFrame
): MetricsTimeFrame {
  return {
    maxDate: obj.maxDate ? moment(obj.maxDate) : undefined,
    minDate: obj.minDate ? moment(obj.minDate) : undefined,
  };
}

/**
 * Verifies if a specific KPI is superior to another tanking into consideration the KPI type
 * If the comparison takes into consideration the rating, it requires more fields for this purpose.
 *
 * @param metric1 first metric to be compared
 * @param metric2 second metric to be compared
 * @returns ComparableResult
 */

export function isKpiGreaterThan(
  metric1: Metric,
  metric2: Metric
): ComparableResult<PerformanceIndicatorType> {
  const availableKpis = new Set<string>([
    ...Object.keys(metric1),
    ...Object.keys(metric2),
  ]);

  // Cannot evaluate metrics with different KPIs
  if (availableKpis.size > 1) {
    return { isComparable: false };
  }
  const kpi = getMetricOrKPI(availableKpis.values().next().value as string);

  if (kpi === undefined) return { isComparable: false };

  if (!(kpi.toLowerCase() in metric1) || !(kpi.toLowerCase() in metric2)) {
    return { isComparable: false };
  }

  const compareNumbers = (n1: number, n2: number): number => {
    if (n1 === n2) {
      return 0;
    }
    return n1 > n2 ? 1 : -1;
  };

  // The 'eslint-disable-next-line' exist in the switch case below because
  // the use case of the field being 'undefined' cannot occur (if cycle above ensures that)

  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  let compareResult;

  // TODO(TECHPROM-372): use a KPI config for this.
  switch (kpi) {
    case KPI.CPL:
    case KPI.CPI:
    case KPI.YOKE_CPA:
    case KPI.CPPDAY0:
    case KPI.CPPDAY1:
    case KPI.CPPDAY2:
    case KPI.CPPDAY3:
    case KPI.CPPDAY4:
    case KPI.CPPDAY5:
    case KPI.CPPDAY6:
    case KPI.CPPDAY7:
    case KPI.CPPDAY8:
    case KPI.CPPDAY9:
    case KPI.CPPDAY10:
    case KPI.CPPDAY11:
    case KPI.CPPDAY12:
    case KPI.CPPDAY13:
    case KPI.CPPDAY14:
    case KPI.CPPDAY15:
    case KPI.CPPDAY16:
    case KPI.CPPDAY17:
    case KPI.CPPDAY18:
    case KPI.CPPDAY19:
    case KPI.CPPDAY20:
    case KPI.CPPDAY21:
    case KPI.CPPDAY22:
    case KPI.CPPDAY23:
    case KPI.CPPDAY24:
    case KPI.CPPDAY25:
    case KPI.CPPDAY26:
    case KPI.CPPDAY27:
    case KPI.CPPDAY28:
    case KPI.CPPDAY29:
    case KPI.CPPDAY30:
      compareResult = compareNumbers(metric2[kpi]!, metric1[kpi]!);
      break;
    case KPI.CTI:
    case KPI.CTR:
    case KPI.IPM:
    case KPI.ROASDAY0:
    case KPI.ROASDAY1:
    case KPI.ROASDAY2:
    case KPI.ROASDAY3:
    case KPI.ROASDAY4:
    case KPI.ROASDAY5:
    case KPI.ROASDAY6:
    case KPI.ROASDAY7:
    case KPI.ROASDAY8:
    case KPI.ROASDAY9:
    case KPI.ROASDAY10:
    case KPI.ROASDAY11:
    case KPI.ROASDAY12:
    case KPI.ROASDAY13:
    case KPI.ROASDAY14:
    case KPI.ROASDAY15:
    case KPI.ROASDAY16:
    case KPI.ROASDAY17:
    case KPI.ROASDAY18:
    case KPI.ROASDAY19:
    case KPI.ROASDAY20:
    case KPI.ROASDAY21:
    case KPI.ROASDAY22:
    case KPI.ROASDAY23:
    case KPI.ROASDAY24:
    case KPI.ROASDAY25:
    case KPI.ROASDAY26:
    case KPI.ROASDAY27:
    case KPI.ROASDAY28:
    case KPI.ROASDAY29:
    case KPI.ROASDAY30:
    case KPI.RETENTIONDAY0:
    case KPI.RETENTIONDAY1:
    case KPI.RETENTIONDAY2:
    case KPI.RETENTIONDAY3:
    case KPI.RETENTIONDAY4:
    case KPI.RETENTIONDAY5:
    case KPI.RETENTIONDAY6:
    case KPI.RETENTIONDAY7:
    case KPI.RETENTIONDAY8:
    case KPI.RETENTIONDAY9:
    case KPI.RETENTIONDAY10:
    case KPI.RETENTIONDAY11:
    case KPI.RETENTIONDAY12:
    case KPI.RETENTIONDAY13:
    case KPI.RETENTIONDAY14:
    case KPI.RETENTIONDAY15:
    case KPI.RETENTIONDAY16:
    case KPI.RETENTIONDAY17:
    case KPI.RETENTIONDAY18:
    case KPI.RETENTIONDAY19:
    case KPI.RETENTIONDAY20:
    case KPI.RETENTIONDAY21:
    case KPI.RETENTIONDAY22:
    case KPI.RETENTIONDAY23:
    case KPI.RETENTIONDAY24:
    case KPI.RETENTIONDAY25:
    case KPI.RETENTIONDAY26:
    case KPI.RETENTIONDAY27:
    case KPI.RETENTIONDAY28:
    case KPI.RETENTIONDAY29:
    case KPI.RETENTIONDAY30:
      compareResult = compareNumbers(metric1[kpi]!, metric2[kpi]!);
      break;
    default:
  }
  /* eslint-enable @typescript-eslint/no-non-null-assertion */

  let result;
  switch (compareResult) {
    case 1:
      result = PerformanceIndicatorType.POSITIVE;
      break;
    case -1:
      result = PerformanceIndicatorType.NEGATIVE;
      break;
    default:
      result = PerformanceIndicatorType.NEUTRAL;
  }

  return { isComparable: true, result };
}
