import { DropDownChip, DropDownChipProps } from '@replai-platform/ui-components';

export interface PromotedObjectFilterProps {
  options: DropDownChipProps['dropDownOptions'];
  onChange: DropDownChipProps['onChange'];
  loading: boolean;
  disabled?: boolean;
}

const PromotedObjectFilter: React.VFC<PromotedObjectFilterProps> = ({
  options,
  onChange,
  loading,
  disabled = false,
}) => (
  <DropDownChip
    data-test="filter-os-dropdown"
    placeHolder="All OS"
    defaultOption="All OS"
    dropDownType="multiselect"
    dropDownOptions={options}
    dropDownAlignment="right"
    onChange={onChange}
    prefixLabel="OS"
    disabled={disabled}
    loading={loading}
  />
);

export default PromotedObjectFilter;
