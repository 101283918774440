/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as SDK from '@replai-platform/sdk';

export type TagViewStateProps = {
  tag?: SDK.Tag | null;
  tagMetrics?: SDK.TagMetrics | null; // The state 'null' identifies an error while fetching the metrics. Having 'undefined' indicates the value was still not retrieved.
};

const initialState: TagViewStateProps = {};

export const TagViewSlice = createSlice({
  name: 'TagView',
  initialState,
  reducers: {
    reset: (state) => Object.assign(state, initialState),

    setTag: (state, action: PayloadAction<SDK.Tag | undefined | null>) => {
      state.tag = action.payload;
    },
    setTagMetrics: (state, action: PayloadAction<SDK.TagMetrics | undefined | null>) => {
      state.tagMetrics = action.payload;
    },
  },
});

export const TagViewActions = TagViewSlice.actions;
export const TagViewReducer = TagViewSlice.reducer;
export const TagViewInitialState = initialState;
