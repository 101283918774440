import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;

export const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1rem;
`;

export const MainSection = styled.div`
  padding-bottom: 1rem;
`;

export const SubSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

export const SubSectionDescription = styled.div``;

export const TagsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(11.8125rem, 1fr));
  gap: 1rem;
  padding-top: 2rem;
`;

export const TagsAndPaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;
  padding: 0rem 2rem 2rem 2rem;
  overflow: auto;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  @media (max-width: 950px) {
    justify-content: center;
    padding-top: 1rem;
  }
`;
