import { CountryIconProps } from './types';

const TK = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#FFDA44"
    />
    <path
      d="M19.3043 5.73914C19.1967 6.25641 19.1478 6.80733 19.1478 7.35652C19.1478 10.5222 21.035 13.2621 23.7394 14.4955C23.9097 13.6904 24 12.8558 24 12C24 5.37262 18.6274 0 12 0C5.37262 0 0 5.37262 0 12C0 18.6274 5.37262 24 12 24C16.1101 24 19.7371 21.9332 21.8998 18.7826H6.78263V17.2174H22.8093C22.9733 16.8782 23.1215 16.53 23.2537 16.1739H6.78263L19.3043 5.73914Z"
      fill="#0052B4"
    />
    <path
      d="M8.81498 6.26123L9.07401 7.05839H9.91214L9.23409 7.55109L9.49312 8.3482L8.81498 7.85559L8.13693 8.3482L8.39592 7.55109L7.71777 7.05839H8.55595L8.81498 6.26123Z"
      fill="#F0F0F0"
    />
    <path
      d="M5.41146 12L5.73528 12.9965H6.78294L5.93529 13.6122L6.25911 14.6087L5.41146 13.9928L4.56387 14.6087L4.88763 13.6122L4.04004 12.9965H5.0877L5.41146 12Z"
      fill="#F0F0F0"
    />
    <path
      d="M5.41146 3.65247L5.73528 4.64898H6.78294L5.93529 5.26468L6.25911 6.26115L5.41146 5.64531L4.56387 6.26115L4.88763 5.26468L4.04004 4.64898H5.0877L5.41146 3.65247Z"
      fill="#F0F0F0"
    />
    <path
      d="M2.80209 8.34753L3.1259 9.34405H4.17351L3.32591 9.95975L3.64973 10.9562L2.80209 10.3404L1.95449 10.9562L2.27826 9.95975L1.43066 9.34405H2.47827L2.80209 8.34753Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default TK;
