import styled from 'styled-components';
import { Colors, Icons, Button } from '@replai-platform/ui-components';
import { goToUrl } from '../../utils';

const TopBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 8rem;
  border-bottom: 1px solid ${Colors.Gray[100]};
`;

const LogoContainer = styled.div`
  margin-right: 3.5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

const TopBar = () => (
  <TopBarContainer data-test="top-bar-container">
    <LogoContainer>
      <Icons.BrandIcons.ReplaiFullLogo dimension={32} />
    </LogoContainer>
    <ButtonsContainer>
      <Button
        data-test="home-button"
        variant="text"
        size="lg"
        color="Gray"
        onClick={() => goToUrl('https://www.replai.io/')}
      >
        Home
      </Button>
      <Button
        data-test="blog-button"
        variant="text"
        size="lg"
        color="Gray"
        onClick={() => goToUrl('https://www.replai.io/blog')}
      >
        Blog
      </Button>
    </ButtonsContainer>
  </TopBarContainer>
);

export default TopBar;
