import { CountryIconProps } from './types';

const MU = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3740)">
      <path
        d="M1.45898 6.26037L11.9999 7.30386L22.5408 6.26037C20.5056 2.53025 16.5484 -0.000488281 11.9999 -0.000488281C7.45139 -0.000488281 3.49425 2.53025 1.45898 6.26037Z"
        fill="#D80027"
      />
      <path
        d="M1.45908 17.7384L12 18.7819L22.5409 17.7384C23.4711 16.0335 24 14.0782 24 11.9993L12 10.9558L0 11.9993C0 14.0782 0.528891 16.0335 1.45908 17.7384Z"
        fill="#FFDA44"
      />
      <path
        d="M1.45908 6.26099C0.528891 7.96588 0 9.92122 0 12.0001H24C24 9.92122 23.4711 7.96588 22.5409 6.26099H12H1.45908Z"
        fill="#0052B4"
      />
      <path
        d="M11.9999 23.9996C16.5484 23.9996 20.5056 21.4688 22.5408 17.7387H1.45898C3.49425 21.4688 7.45139 23.9996 11.9999 23.9996Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3740">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default MU;
