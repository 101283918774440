/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { createSelector } from '@reduxjs/toolkit';
import { useFeature } from '@optimizely/react-sdk';
import * as SDK from '@replai-platform/sdk';
import { capitalizeFirstLetter, EmptyState, Tab, Tabs, camelCaseToCapitalCase } from '@replai-platform/ui-components';
import { useCallback, useMemo, useState } from 'react';
import Masonry from 'react-masonry-css';
import { useQuery } from 'react-query';
import { useSelector, useStore } from 'react-redux';
import { logEvent } from '../../../analytics';
import { api } from '../../../api';
import useTagMetrics from '../../../api/hooks/tags/useTagMetrics';
import SearchInput from '../../../components/SearchInput';
import type { RootState } from '../../../store/rootReducer';
import kpiCompare from '../../../utils/kpiCompare';
import './masonry.css';
import * as Styled from './styles';
import OverviewTagCategory from './TagCategoryOverview';
import { separateByTagTypes } from './utils';

const MAX_HIGHLIGHT_TAGS = 3;
const TAG_PERFORMANCE_PLACEHOLDERS = 10;
const TABS = {
  ALL: 'all',
  BEST: 'best',
};

interface TagPerformanceExplorerProps {
  data: Record<string, any> | undefined;
  isLoading: boolean;
  isPrevious: boolean;
  isSuccess: boolean;
}

const TagPerformanceExplorer = ({ data, isLoading, isPrevious, isSuccess }: TagPerformanceExplorerProps) => {
  const store = useStore();
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectBaseMetric = useSelector((state: RootState) => state.project.baseMetric);
  const filtering = useSelector((state: RootState) => state.filters);
  const tagFilters = useSelector((state: RootState) => state.filters.tagFilters);
  const globalPerformance = useSelector((state: RootState) => state.combinations.globalPerformance);
  const [tab, setTab] = useState<string>('all');
  const [search, setSearch] = useState<string>('');
  const defaultProjectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const defaultKpi = useSelector((state: RootState) => state.filters.kpi) || defaultProjectKpis?.[0];
  const filterKPI = filtering.kpi as SDK.Metrics;
  const filteringKPIS: SDK.Metrics[] = useMemo(
    () => [...new Set([...(filterKPI ? [filterKPI] : [defaultKpi as SDK.Metrics]), projectBaseMetric])],
    [defaultKpi, filterKPI, projectBaseMetric]
  );
  const tagFilteredCache = createSelector([(state: RootState) => state.filters.tagFilters], (filteredTagIds) =>
    filteredTagIds.reduce((cur, val) => {
      cur[val.id] = true;
      return cur;
    }, {})
  );
  const tagCache: { [key: string]: boolean } = tagFilteredCache(store.getState());
  const [isTechmerc1569Enabled] = useFeature('techmerc-1569'); // Renaming Tags Filter

  const onTabChange = (selectedTab: Tab, tabIndex: number) => {
    const tabName = Object.values(TABS)[tabIndex];
    setTab(tabName);
    logEvent({
      component: 'Combinations',
      action: 'Change Tab',
      category: 'user_actions',
      parameters: { value: tabName },
    });
  };

  const getGlobalTagsMetricsParams = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: [projectBaseMetric],
      orderBy: {
        condition: SDK.OrderByCondition.DESC_NULLS_LAST,
        value: projectBaseMetric,
      },
      metricsFilters: api.filterConverter.getMetricsFilters({
        ...filtering,
        countriesToConsider: undefined,
        countriesToExclude: undefined,
        ageStartDate: undefined,
        ageEndDate: undefined,
      }),
      adsFilters: {
        ...api.filterConverter.getAdsFilters({ ...filtering, campaignIdsToConsider: [] }),
      },
      adTagsFilters: api.filterConverter.getAdTagsFilters(filtering),
      tagsFilters: {
        tagTypesToConsider: data ? data.map((tagType) => ({ type: tagType.type })) : [],
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(filtering), projectId, JSON.stringify(data)]
  );
  const {
    data: globalTagTypePerformance,
    isLoading: isLoadingGlobalPerformance,
    isPreviousData: isPreviousDataGlobalPerformance,
  } = useQuery(
    ['global-tag-type-performance', getGlobalTagsMetricsParams],
    () => api.tags.getMetrics(getGlobalTagsMetricsParams),
    { enabled: !!data && !isLoading, keepPreviousData: true }
  );

  const getTagsMetricsParams = useMemo<SDK.GetTagsMetricsRequest>(
    () => ({
      projectIds: [projectId],
      metrics: filteringKPIS,
      orderBy: {
        condition: SDK.OrderByCondition.DESC_NULLS_LAST,
        value: projectBaseMetric,
      },
      metricsFilters: api.filterConverter.getMetricsFilters({
        ...filtering,
        countriesToConsider: undefined,
        countriesToExclude: undefined,
        ageStartDate: undefined,
        ageEndDate: undefined,
      }),
      adTagsFilters: api.filterConverter.getAdTagsFilters(filtering),
      adsFilters: {
        ...api.filterConverter.getAdsFilters({ ...filtering, campaignIdsToConsider: [] }),
        ...(tagFilters && tagFilters.length > 0 ? { taggedWith: tagFilters.map((tag) => tag.id) } : null),
      },
      tagsFilters: {
        tagTypesToConsider: data ? data.map((tagType) => ({ type: tagType.type })) : [],
      },
      assetFilters: api.filterConverter.getAssetFilters(filtering),
    }),
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      projectId,
      JSON.stringify(filteringKPIS),
      JSON.stringify(filtering),
      JSON.stringify(tagFilters),
      JSON.stringify(data),
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );
  const {
    data: tagTypePerformance,
    isLoading: isLoadingPerformance,
    isPreviousData: isPreviousDataPerformance,
  } = useTagMetrics(getTagsMetricsParams, {
    select: useCallback(
      (res) => {
        const kpi = filtering.kpi || defaultKpi;

        return res.tags.map((tag) => {
          const goodness = tag.metrics
            ? kpiCompare({
                value1: tag.metrics,
                value2: globalPerformance,
                kpi: kpi as SDK.Metrics,
              })
            : 0;

          let scorePercentage: null | number = 0;
          if (!SDK.kpiUtils.isSummable(kpi)) {
            if (!tag.metrics || !tag.metrics?.[kpi]) {
              scorePercentage = null;
            } else if ((tag.metrics[kpi] ?? 0) > globalPerformance?.[kpi]) {
              scorePercentage = ((tag.metrics[kpi] ?? 0) / (globalPerformance?.[kpi] ?? 1)) * 100 - 100;
            } else {
              scorePercentage =
                (((tag.metrics[kpi] ?? 0) - (globalPerformance?.[kpi] ?? 0)) / (globalPerformance?.[kpi] ?? 1)) * 100;
            }
          }

          return { ...tag, goodness, scorePercentage };
        });
      },
      [filtering.kpi, defaultKpi, JSON.stringify(globalPerformance)]
    ),
    enabled: !!data && !isLoading,
    keepPreviousData: true,
  });

  const tagsPerformancePerType = useMemo(
    () => separateByTagTypes(tagTypePerformance, tagCache),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      JSON.stringify(tagTypePerformance),
      JSON.stringify(globalPerformance),
      filtering.kpi,
      defaultKpi,
      JSON.stringify(tagCache),
    ]
  );

  // calculates the unused tags for each tag type and sorts
  // them by the amount of the key metric
  const unusedTagsPerType = useMemo(() => {
    if (!data || Object.keys(tagsPerformancePerType).length === 0 || !globalTagTypePerformance) return {};

    const unusedTags = {};
    data?.forEach((tagType) => {
      unusedTags[tagType.type] = tagType.values
        .filter((v) => !tagsPerformancePerType[tagType.type]?.map((t) => t.tag.value).includes(v))
        .map((tagValue) => ({
          tag: { id: '', type: tagType.type, value: tagValue },
          shareOfAssets: 0,
          shareOfSpend: 0,
          metrics: undefined,
          individualObjectCount: 0,
          totalObjectCount: 0,
          spend:
            globalTagTypePerformance?.tags.find((t) => t.tag.type === tagType.type && t.tag.value === tagValue)
              ?.shareOfSpend ?? 0,
        }))
        .sort((a, b) => b.spend - a.spend);
    });
    return unusedTags;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), JSON.stringify(tagsPerformancePerType), JSON.stringify(globalTagTypePerformance)]);

  // calculates the tags with the better metric / frequency ratio
  // that are not currently selected
  const bestPerformanceTags = useMemo(
    () =>
      tagTypePerformance
        ?.filter((tag) => tagCache[tag.tag.id] === undefined && tag.goodness > 0)
        .map((tag) => ({ ...tag, highlightScore: tag.shareOfSpend / tag.shareOfAssets }))
        .sort((a, b) => b.highlightScore - a.highlightScore)
        .slice(0, MAX_HIGHLIGHT_TAGS),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(tagTypePerformance), JSON.stringify(tagCache)]
  );

  // filters the tags of a type by their goodness and the search term, tags with
  // goodness at 0 are shown in both 'Good' and 'Bad' tabs but tags with goodness
  // as undefined are only shown in the 'All' tab
  const tagsPerType = (tagType: { type: string; values?: string[]; kind: SDK.TagKind }) =>
    tagsPerformancePerType[tagType.type]?.concat(unusedTagsPerType[tagType.type] || []).filter((tag) => {
      const searchResult =
        !search ||
        tag.tag.value?.toLowerCase().includes(search.toLowerCase()) ||
        tag.tag.type?.toLowerCase().includes(camelCaseToCapitalCase(search).toLowerCase());

      if (tab === TABS.BEST) return tag.goodness >= 0 && searchResult;
      return searchResult;
    }) || [];

  return (
    <Styled.AllTagsWrapper data-test="all-tags-section">
      <Tabs
        tabLabels={Object.keys(TABS).map((t) => ({ label: capitalizeFirstLetter(TABS[t]) }))}
        onTabChange={onTabChange}
      />
      <Styled.SearchInputWrapper>
        <SearchInput
          initialSearchTerm={search}
          onSearchTermChangeEnd={(searchTerm) => setSearch(searchTerm)}
          placeholder={isTechmerc1569Enabled ? 'Search by features or tags' : 'Search by category or value'}
        />
      </Styled.SearchInputWrapper>
      <Masonry
        breakpointCols={{ default: 4, 2050: 3, 1790: 2, 1530: 1 }}
        className="masonry-grid"
        columnClassName="masonry-grid-column"
      >
        {(isLoading || isLoadingPerformance
          ? Array.from(Array(TAG_PERFORMANCE_PLACEHOLDERS))
          : data && Object.keys(tagsPerformancePerType).length > 0
          ? data
          : []
        ).map((tagType, index) => {
          const tags = !tagType ? [] : tagsPerType(tagType);
          if (tagType && tags.length === 0 && Object.keys(tagsPerformancePerType).length !== 0) {
            return undefined;
          }

          const key = tagType ? JSON.stringify(tagType) : index;
          return (
            <Styled.TagCategoryContainer key={key} data-test="tag-container">
              <OverviewTagCategory
                tagType={tagType}
                tagTypePerformance={tags}
                bestPerformanceTags={bestPerformanceTags}
                isLoading={isLoading || isLoadingPerformance || isLoadingGlobalPerformance}
                isUpdating={isPrevious || isPreviousDataPerformance || isPreviousDataGlobalPerformance}
                keyMetric={projectBaseMetric}
              />
            </Styled.TagCategoryContainer>
          );
        })}
      </Masonry>
      {!isSuccess || (!isLoading && !isLoadingPerformance && Object.keys(tagsPerformancePerType).length === 0) ? (
        <Styled.EmptyContentWrapper>
          <EmptyState
            icon="Tag"
            text="No tags found"
            supportingText="Try to expand your search or reset the filters altogether."
          />
        </Styled.EmptyContentWrapper>
      ) : undefined}
    </Styled.AllTagsWrapper>
  );
};

export default TagPerformanceExplorer;
