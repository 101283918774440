import { CountryIconProps } from './types';

const MR = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#496E2D"
    />
    <path
      d="M12.0001 13.8637C10.002 13.8637 8.33275 12.4594 7.9232 10.5842C7.8603 10.8724 7.82617 11.1714 7.82617 11.4786C7.82617 13.7838 9.69484 15.6525 12.0001 15.6525C14.3053 15.6525 16.174 13.7838 16.174 11.4786C16.174 11.1714 16.1398 10.8724 16.0769 10.5841C15.6674 12.4594 13.9982 13.8637 12.0001 13.8637Z"
      fill="#FFDA44"
    />
    <path
      d="M12.0002 8.34778L12.3887 9.54351H13.6461L12.6289 10.2825L13.0174 11.4782L12.0002 10.7392L10.9831 11.4782L11.3716 10.2825L10.3545 9.54351H11.6117L12.0002 8.34778Z"
      fill="#FFDA44"
    />
  </svg>
);
export default MR;
