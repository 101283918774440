import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getTags = async (requestParams: SDK.GetTagsRequest) => {
  const res = await provider.getJson<object, SDK.GetTagsResponse>(
    `${SDK.BASE_URL()}/tags?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useTags<R = SDK.GetTagsResponse>(
  requestParams: SDK.GetTagsRequest,
  options?: UseQueryOptions<SDK.GetTagsResponse, unknown, R>
) {
  return useQuery(['tags', requestParams], () => getTags(requestParams), options);
}
