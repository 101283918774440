import * as SDK from '@replai-platform/sdk';

export const SUPPORTED_RECOMMENDATION_TYPES = [
  SDK.RecommendationType.CREATIVE_ADD_TAG,
  SDK.RecommendationType.CREATIVE_REMOVE_TAG,
  SDK.RecommendationType.CREATIVE_REPLACE_TAG,
  SDK.RecommendationType.CREATIVE_REPLACE_TAG_INTRO,
  SDK.RecommendationType.CREATIVE_REMOVE_TAG_INTRO,
  SDK.RecommendationType.CREATIVE_ADD_TAG_INTRO,
];

export const getRecommendationRequestParams = ({
  projectId,
  minRecommendationScore,
  tagTypesToExclude,
  networks,
  promotedObjectTypes,
  targets,
  debouncedSearchTerm,
  withBreakdowns,
  objectIds,
  countOnly,
  maxRecords,
  offset,
}: {
  projectId: SDK.UUID;
  minRecommendationScore: number;
  tagTypesToExclude: { type: any }[];
  networks?: SDK.Network[];
  promotedObjectTypes?: SDK.PromotedObjectType[];
  targets?: SDK.MetricKPIWithSpend[];
  debouncedSearchTerm?: string;
  withBreakdowns?: boolean;
  objectIds?: SDK.UUID[];
  countOnly?: boolean;
  maxRecords?: number;
  offset?: number;
}) => ({
  name: debouncedSearchTerm ?? null,
  projects: [projectId],
  networks: withBreakdowns ? networks : [],
  promotedObjectTypes: withBreakdowns ? promotedObjectTypes : [],
  targets,
  recommendationTypes: SUPPORTED_RECOMMENDATION_TYPES,
  minRecommendationScore,
  tagTypesToExclude,
  breakdowns: withBreakdowns ? [SDK.BreakdownField.PROMOTED_OBJECT_TYPE, SDK.BreakdownField.NETWORK] : [],
  objectIds,
  countOnly,
  maxRecords,
  offset,
});
