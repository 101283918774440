import styled from 'styled-components';
import { Typography } from '@replai-platform/ui-components';

export const AlignRightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  margin-right: 10px;
`;

export const FooterContainer = styled.div`
  margin-left: 0.75rem;
`;

export const TooltipContainer = styled.div`
  width: 18rem;
  text-align: center;

  > * {
    display: block;
  }
`;

export const TooltipLink = styled(Typography)`
  margin: 0;
  text-decoration: underline;
`;
