/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTitle } from 'react-use';
import { useFeature } from '@optimizely/react-sdk';
import FilterBar from '../../components/FilterBar';
import { FiltersContainer } from '../../components/FilterBar/common/styles';
import { CustomTagsFilterProps } from '../../components/FilterBar/Filters/CustomTagsFilter';
import type { NetworkFilterProps } from '../../components/FilterBar/Filters/NetworkFilter';
import type { BuyingStrategyFilterProps } from '../../components/FilterBar/Filters/BuyingStrategyFilter';
import type { PromotedObjectFilterProps } from '../../components/FilterBar/Filters/PromotedObjectFilter';
import useCustomTagsFilter from '../../components/FilterBar/hooks/useCustomTagsFilter';
import useNetworkFilter from '../../components/FilterBar/hooks/useNetworkFilter';
import useBuyingStrategyFilter from '../../components/FilterBar/hooks/useBuyingStrategyFilter';
import usePromotedObjectFilter from '../../components/FilterBar/hooks/usePromotedObjectFilter';
import useSidebarFilters from '../../components/FilterSidebar/buildSidebarFilters';
import TopNavPageTitle from '../../components/TopNavPageTitle';
import type { RootState } from '../../store/rootReducer';
import { Page } from '../../utils/enums';
import { RouteAnimator } from '../RouteAnimator';
import ConceptsTable from './ConceptsTable';
import { supportedFilters } from './utils';
import useAgencyFilter from '../../components/FilterBar/hooks/useAgencyFilter';
import { AgencyFilterProps } from '../../components/FilterBar/Filters/AgencyFilter';

const ConceptsView: React.VFC = () => {
  const projectName = useSelector((state: RootState) => state.project.name);
  const [isTechmerc1525Enabled] = useFeature('techmerc-1525'); // Buying Strategy filter
  const [isTechmerc1550Enabled] = useFeature('techmerc-1550'); // Agency filter

  useSidebarFilters(supportedFilters);
  useTitle(`Videos - ${projectName}`);

  // Build network filter
  const {
    networkFilterOptions,
    networkFilterOnChange,
    isLoading: isNetworkFilterLoading,
  } = useNetworkFilter(Page.Videos);
  const networkFilter = useMemo(
    () =>
      ({
        options: networkFilterOptions,
        onChange: networkFilterOnChange,
        loading: isNetworkFilterLoading,
      } as NetworkFilterProps),
    [networkFilterOptions, networkFilterOnChange, isNetworkFilterLoading]
  );

  // Build buying strategy filter
  const {
    buyingStrategyFilterOptions,
    buyingStrategyFilterOnChange,
    isLoading: isBuyingStrategyFilterLoading,
  } = useBuyingStrategyFilter(Page.Videos);
  const buyingStrategyFilter = useMemo(
    () =>
      ({
        options: buyingStrategyFilterOptions,
        onChange: buyingStrategyFilterOnChange,
        loading: isBuyingStrategyFilterLoading,
      } as BuyingStrategyFilterProps),
    [buyingStrategyFilterOptions, buyingStrategyFilterOnChange, isBuyingStrategyFilterLoading]
  );

  // Build agency filter
  const { agencyFilterOptions, agencyFilterOnChange, isLoading: isAgencyFilterLoading } = useAgencyFilter(Page.Videos);
  const agencyFilter = useMemo(
    () =>
      ({
        options: agencyFilterOptions,
        onChange: agencyFilterOnChange,
        loading: isAgencyFilterLoading,
      } as AgencyFilterProps),
    [agencyFilterOptions, agencyFilterOnChange, isAgencyFilterLoading]
  );

  // Build promoted object types filter
  const { promotedObjectTypeFilterOptions, promotedObjectTypeFilterOnChange } = usePromotedObjectFilter(Page.Videos);
  const promotedObjectFilter = useMemo(
    () =>
      ({
        options: promotedObjectTypeFilterOptions,
        onChange: promotedObjectTypeFilterOnChange,
        loading: false,
      } as PromotedObjectFilterProps),
    [promotedObjectTypeFilterOnChange, promotedObjectTypeFilterOptions]
  );

  // Build tagged with filter
  const {
    customTagsFilterOptions,
    customTagsFilterOnChange,
    isLoading: isCustomTagsFilterLoading,
  } = useCustomTagsFilter('default');
  const customTagsFilter: CustomTagsFilterProps = useMemo(
    () => ({
      options: customTagsFilterOptions,
      onChange: customTagsFilterOnChange,
      loading: isCustomTagsFilterLoading,
    }),
    [customTagsFilterOnChange, customTagsFilterOptions, isCustomTagsFilterLoading]
  );

  return (
    <RouteAnimator data-test="videos-container">
      <TopNavPageTitle
        title="Videos Performance"
        subtitle="Analyze your videos' performance to decide what to do next. Customize the view with filters and more KPIs, or export the data to use it freely."
      />
      <FiltersContainer data-test="videos-filters-container">
        <FilterBar
          eventPrefix="videos"
          supportedFilters={supportedFilters}
          networkFilter={networkFilter}
          buyingStrategyFilter={isTechmerc1525Enabled ? buyingStrategyFilter : undefined}
          agencyFilter={isTechmerc1550Enabled ? agencyFilter : undefined}
          promotedObjectFilter={promotedObjectFilter}
          customTagsFilter={customTagsFilter}
          withAgeFilter
        />
      </FiltersContainer>
      <ConceptsTable filtersLoading={isNetworkFilterLoading} />
    </RouteAnimator>
  );
};

export default ConceptsView;
