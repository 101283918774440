import { Badge, ChartValue, Colors, LineChart, Skeleton } from '@replai-platform/ui-components';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { linearRegression } from 'simple-statistics';
import * as SDK from '@replai-platform/sdk';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';
import { RootState } from '../../../store/rootReducer';
import useMetricsHistory from '../../../api/hooks/metrics/useMetricsHistory';
import { api } from '../../../api/index';

export type ColumnData = {
  objectLevel: SDK.ObjectLevel;
  tag?: Pick<SDK.Tag, 'type' | 'value'>;
  objectId?: SDK.UUID;
};

const NoDataBadge = styled(Badge)`
  display: flex;
`;

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: -0.5rem;
`;

const CellContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 6.3rem;

  height: 2.5rem;
`;

const SPEND_TREND_CHART_NUMBER_OF_DAYS = 7;
const CHART_LINE_STROKE = 1.4;

/**
 * Any slope/spendPerDay ratio lower than MIN_LINEAR_REGRESSION_SLOPE_RATIO or higher that -MIN_LINEAR_REGRESSION_SLOPE_RATIO means
 * that the trend is not clearly up or down, which will be represented by having the spend trend chart gray colored
 */
const MIN_LINEAR_REGRESSION_SLOPE_RATIO = 0.02;

/**
 * Fraction of buffer between the min/max value of the data and the Y axis domain
 */
const Y_AXIS_DOMAIN_BUFFER = 0.08;

function getSpendTrendColumn<D extends object = object>({
  columnId,
  accessor,
}: RequiredGeneratorParams<D, ColumnData>): GeneratedColumn<D, ColumnData> {
  return {
    id: columnId,
    accessor,
    width: 125,
    minWidth: 125,
    maxWidth: 125,
    Cell: (data: CellData<D, ColumnData>) => {
      const stateFilters = useSelector((state: RootState) => state.filters);
      const projectId = useSelector((state: RootState) => state.project.id);

      const objectLevel = data.cell?.value?.objectLevel;
      const tag = data.cell?.value?.tag;
      const objectId = data.cell?.value?.objectId;

      const getSpendTrendParams = useMemo(
        () => ({
          projectIds: [projectId],
          adsFilters: api.filterConverter.getAdsFilters(stateFilters),
          assetFilters: api.filterConverter.getAssetFilters(stateFilters),
          metricsFilters: {
            ...api.filterConverter.getMetricsFilters(stateFilters),
            dateStartTimestamp: new Date(stateFilters.endDate).setDate(
              // adding 1 because this filter includes the dateStartTimestamp day itself
              // making the chart have one unwanted additional day
              new Date(stateFilters.endDate).getDate() - SPEND_TREND_CHART_NUMBER_OF_DAYS + 1
            ),
          },
          tagsFilters: objectLevel === SDK.ObjectLevel.TAG && tag ? { tagsToConsider: [tag] } : undefined,
          metrics: [SDK.Metrics.SPEND],
          conceptId: objectLevel === SDK.ObjectLevel.CONCEPT ? objectId : undefined,
          assetId: objectLevel === SDK.ObjectLevel.ASSET ? objectId : undefined,
        }),
        [objectId, JSON.stringify(stateFilters), projectId, tag, objectLevel]
      );

      const { data: spendTrendData, isLoading: spendTrendIsLoading } = useMetricsHistory(getSpendTrendParams, {
        enabled: !!tag || !!objectId,
      });

      const chartData = useMemo(
        () =>
          spendTrendData?.history.map(
            (entry) =>
              ({
                name: entry.date,
                [SDK.Metrics.SPEND]: entry.metrics[SDK.Metrics.SPEND] as number,
              } as ChartValue)
          ),
        [JSON.stringify(spendTrendData?.history)]
      );

      if (spendTrendIsLoading || (!tag && !objectId)) {
        return <Skeleton height={20} width={100} />;
      }

      const chartDataByDayIndex =
        spendTrendData?.history.map((entry, index) => [index, entry.metrics.spend ?? 0]) ?? [];

      const averageSpendPerDay =
        chartDataByDayIndex.map((entry) => entry[1]).reduce((a, b) => a + b, 0) /
        (chartDataByDayIndex.length > 0 ? chartDataByDayIndex.length : 1);

      // Calculating the linear regression of the chart to understand if the
      // trend line slope is negative or positive
      const { m: slope } = linearRegression(chartDataByDayIndex);

      const slopeSpendPerDayRatio = slope / (averageSpendPerDay !== 0 ? averageSpendPerDay : 1);

      const isSlopePositive = slopeSpendPerDayRatio > MIN_LINEAR_REGRESSION_SLOPE_RATIO;
      const isSlopeNegative = slopeSpendPerDayRatio < -MIN_LINEAR_REGRESSION_SLOPE_RATIO;

      const chartColor = isSlopePositive ? Colors.Success[400] : isSlopeNegative ? Colors.Error[400] : Colors.Gray[400];

      const lines = [
        {
          name: SDK.kpiUtils.getDisplayName(SDK.Metrics.SPEND),
          accessor: SDK.Metrics.SPEND,
          color: chartColor,
        },
      ];

      if (chartData?.length === 0 || chartData?.every((entry) => entry[SDK.Metrics.SPEND] === 0)) {
        return (
          <NoDataContainer>
            <NoDataBadge color="Gray">N/A</NoDataBadge>
          </NoDataContainer>
        );
      }

      return (
        <CellContainer>
          <LineChart
            data-test="spend-trend-chart"
            data={chartData}
            lines={lines}
            hideXAxis
            hideYAxis
            showLegend={false}
            emptyChartMessage={false}
            showTooltip={false}
            showCartesianGrid={false}
            gradientColor={chartColor}
            // this makes the yAxis start and end on the min Y value and max Y value, respectively (instead of starting on 0)
            // also adds/subtracts a little buffer for the line not to be slightly cut off at the top or bottom of the chart
            yAxisDomain={[
              `dataMin - ${averageSpendPerDay * Y_AXIS_DOMAIN_BUFFER}`,
              `dataMax + ${averageSpendPerDay * Y_AXIS_DOMAIN_BUFFER}`,
            ]}
            lineStroke={CHART_LINE_STROKE}
          />
        </CellContainer>
      );
    },
  };
}

export default getSpendTrendColumn;
