import * as SDK from '@replai-platform/sdk';
import { Card, KeyValues, MetricsSection, SelectInput, Skeleton, type KeyValue } from '@replai-platform/ui-components';
import { useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useFeature } from '@optimizely/react-sdk';
import { capitalCase } from 'capital-case';
import { logEvent } from '../../../../../analytics';
import { api } from '../../../../../api';
import useMetricsPerformance from '../../../../../api/hooks/metrics/useMetricsPerformance';
import VideoSidebar from '../../../../../components/VideoSidebar';
import PlayableSidebar from '../../../../../components/PlayableSidebar';
import CreativeRetentionChart from '../../../../../components/CreativeRetentionChart';
import CreativeWeekdayPerformanceChart from '../../../../../components/CreativeWeekdayPerformanceChart';
import CreativeFatigueTrend from '../../../../../components/CreativeFatigueTrend';
import ContextPerformanceTable from '../../../../../components/ContextPerformanceTable';
import CreativeMetricsBarChart from '../../../../../components/CreativeMetricsBarChart';
import CreativeTotalMetricChart from '../../../../../components/CreativeTotalMetricChart';
import AssetNetworkLinkButton from '../../../../../components/AssetNetworkLinkButton';
import DownloadAssetButton from '../../../../../components/DownloadAssetButton';
import AssetVariationButton from '../../../../../components/AssetVariationButton';
import { RootState } from '../../../../../store/rootReducer';
import buildKpiDropdownFilterProps from '../../../../../utils/buildKpiDropdownFilterProps';
import { calculateDelta, deltaToGoodness, deltaToStatus } from '../../../../../utils/calculateDelta';
import { TypographyClean } from '../../../../../utils/styles';
import { RouteAnimator } from '../../../../RouteAnimator';
import {
  CardChart,
  CardHeader,
  CardHeaderRight,
  Container,
  LeftSide,
  MetricsCard,
  MetricsCardWrapper,
  MetricsContainer,
  Properties,
  RightSide,
  SectionContainer,
} from '../shared/styles';
import type { ClusterType } from '../shared/types';

const VideoMetrics: React.VFC<{
  cluster: Partial<ClusterType>;
  propertyData?: KeyValue[];
  url: string;
  isLoading: boolean;
}> = ({ cluster, propertyData, url, isLoading }) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes);
  const filters = useSelector((state: RootState) => state.filters);
  const defaultProjectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const baseMetric = useSelector((state: RootState) => state.project.baseMetric);
  const availableKpis = buildKpiDropdownFilterProps(
    baseMetric === SDK.Metrics.SPEND ? defaultProjectKpis.concat(SDK.KPI.AVG_CONCEPT_SPEND) : defaultProjectKpis
  ).map((kpi) => ({
    title: kpi.label,
    value: kpi.value,
  }));
  const [weekdayPerformanceModeIdx, setWeekdayPerformanceModeIdx] = useState(0);
  const weekdayPerformanceModeOptions: Array<'total' | 'average'> = ['total', 'average'];
  const [weekdayPerformanceSelectedKpiIndex, setWeekdayPerformanceSelectedKpiIndex] = useState(0);
  const [isTechMerc1388Enabled] = useFeature('techmerc-1388'); // Creative Production

  const player = useRef<ReactPlayer | null>(null);

  const [selectedKpiIndex, setSelectedKpiIndex] = useState(0);

  const projectMetricsParams = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: defaultProjectKpis,
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: {
        ...api.filterConverter.getMetricsFilters({
          ...filters,
        }),
      },
    }),
    [filters, projectId, defaultProjectKpis]
  );
  const { data: projectMetrics, isLoading: isLoadingProjectMetrics } = useMetricsPerformance(projectMetricsParams);

  // Calculates the metrics values and deltas taking into consideration
  // the project and video KPI's values
  const metrics = useMemo(() => {
    if (!cluster || isLoadingProjectMetrics || isLoading) return [];
    return (defaultProjectKpis || [])
      .filter((kpi) => SDK.Metrics.SPEND !== kpi)
      .map((metric) => ({
        name: SDK.kpiUtils.getDisplayName(metric),
        value: cluster[metric]
          ? (cluster[metric] ?? 0) * (SDK.kpiUtils.filters[metric]?.isPercentage ? 100 : 1)
          : undefined,
        isPercentage: !!SDK.kpiUtils.filters[metric]?.isPercentage,
        goodness: deltaToGoodness(
          cluster as SDK.Metric,
          (projectMetrics?.metrics ?? {}) as SDK.Metric,
          metric as SDK.Metrics
        ),
        status: deltaToStatus(
          cluster as SDK.Metric,
          (projectMetrics?.metrics ?? {}) as SDK.Metric,
          metric as SDK.Metrics
        ),
        delta: calculateDelta(cluster[metric] as number, projectMetrics?.metrics?.[metric] ?? 0, true, false, 2),
      }))
      .filter((metric) => metric.value !== undefined);
  }, [defaultProjectKpis, JSON.stringify(cluster), isLoadingProjectMetrics, isLoading]);

  const renderMetricsNumbers = () => {
    if (isLoadingProjectMetrics || isLoading) return <Skeleton key="metrics" height={78} />;
    if (metrics.length === 0)
      return (
        <TypographyClean type="text-md" fontWeight="medium">
          No metrics available.
        </TypographyClean>
      );
    return <MetricsSection metrics={metrics} />;
  };

  const renderFatigueTrend = () => (
    <Card fullWidth disableContentPadding disableShadow>
      <CardHeader>
        <TypographyClean type="text-lg" fontWeight="medium">
          Fatigue Trend
        </TypographyClean>
        <CardHeaderRight>
          <SelectInput
            defaultOption={{ title: availableKpis[selectedKpiIndex].title }}
            onChange={(index) => {
              setSelectedKpiIndex(index);
              if (selectedKpiIndex !== index) {
                logEvent({
                  component: 'Concept Details Overview',
                  action: 'Change KPI',
                  category: 'user_actions',
                  parameters: {
                    changedKpi: availableKpis[selectedKpiIndex],
                    changedKpiLabel: `KPI ${availableKpis[selectedKpiIndex].title}`,
                  },
                });
              }
            }}
            options={availableKpis}
            placeholder="Select KPI"
          />
        </CardHeaderRight>
      </CardHeader>
      <CardChart data-test="overview-chart">
        <CreativeFatigueTrend
          assetId={cluster?.id ?? ''}
          selectedKpi={SDK.getMetricOrKPI(availableKpis[selectedKpiIndex].value) ?? SDK.KPI.IPM}
        />
      </CardChart>
    </Card>
  );

  const renderMetrics = () => (
    <MetricsContainer data-test="video-metrics-container">
      <MetricsCardWrapper>
        {projectAccountTypes?.includes(SDK.AccountTypes.SOCIALS) ? (
          <Card fullWidth>
            <CardHeader data-test="video-card-header">
              <TypographyClean type="text-lg" fontWeight="medium">
                Video retention
              </TypographyClean>
            </CardHeader>
            <CardChart data-test="video-card-chart">
              <CreativeRetentionChart assetId={cluster?.id ?? ''} />
            </CardChart>
          </Card>
        ) : (
          <Card fullWidth>
            <CardHeader data-test="video-card-header">
              <TypographyClean type="text-lg" fontWeight="medium">
                Total {baseMetric}
              </TypographyClean>
            </CardHeader>
            <CardChart data-test="video-card-chart">
              <CreativeTotalMetricChart assetId={cluster?.id ?? ''} baseMetric={baseMetric} />
            </CardChart>
          </Card>
        )}
      </MetricsCardWrapper>
      {projectAccountTypes?.includes(SDK.AccountTypes.SOCIALS) ? (
        <MetricsCardWrapper>
          <Card fullWidth>
            <CardHeader data-test="video-card-header">
              <TypographyClean type="text-lg" fontWeight="medium">
                Video performance per weekday
              </TypographyClean>
              <CardHeaderRight>
                <SelectInput
                  defaultOption={{ title: availableKpis[weekdayPerformanceSelectedKpiIndex].title }}
                  onChange={(index) => {
                    setWeekdayPerformanceSelectedKpiIndex(index);
                    if (weekdayPerformanceSelectedKpiIndex !== index) {
                      logEvent({
                        component: 'Concept Details Weekday Performance',
                        action: 'Change KPI',
                        category: 'user_actions',
                        parameters: {
                          changedKpi: availableKpis[weekdayPerformanceSelectedKpiIndex],
                          changedKpiLabel: `KPI ${availableKpis[weekdayPerformanceSelectedKpiIndex].title}`,
                        },
                      });
                    }
                  }}
                  options={availableKpis}
                  placeholder="Select KPI"
                />
                <SelectInput
                  defaultOption={{
                    title: capitalCase(weekdayPerformanceModeOptions[weekdayPerformanceModeIdx]),
                  }}
                  onChange={(modeIdx) => {
                    setWeekdayPerformanceModeIdx(modeIdx);
                    if (weekdayPerformanceModeIdx !== modeIdx) {
                      logEvent({
                        component: 'Concept Details Weekday Performance',
                        action: 'Change aggregation',
                        category: 'user_actions',
                        parameters: {
                          mode: weekdayPerformanceModeOptions[weekdayPerformanceModeIdx],
                        },
                      });
                    }
                  }}
                  options={weekdayPerformanceModeOptions.map((opt) => ({
                    title: capitalCase(opt),
                  }))}
                  placeholder="Select aggregation mode"
                />
              </CardHeaderRight>
            </CardHeader>
            <CardChart data-test="video-card-chart">
              <CreativeWeekdayPerformanceChart assetId={cluster?.id ?? ''} />
            </CardChart>
          </Card>
        </MetricsCardWrapper>
      ) : null}
      {projectAccountTypes?.includes(SDK.AccountTypes.SOCIALS) ? null : (
        <MetricsCardWrapper>
          <Card fullWidth>
            <CardHeader data-test="video-card-header">
              <TypographyClean type="text-lg" fontWeight="medium">
                Impressions / Clicks
              </TypographyClean>
            </CardHeader>
            <CardChart data-test="video-card-chart">
              <CreativeMetricsBarChart assetId={cluster?.id ?? ''} />
            </CardChart>
          </Card>
        </MetricsCardWrapper>
      )}
    </MetricsContainer>
  );

  return (
    <RouteAnimator>
      <Container>
        <LeftSide>
          {cluster?.assetType === SDK.AssetType.Playable ? (
            <PlayableSidebar assetId={cluster?.id ?? ''} />
          ) : (
            <VideoSidebar
              data-test="video-overview"
              url={url}
              coreVideoTags={[]}
              coreMiscTags={[]}
              showDescription={false}
              onPlay={() => {
                logEvent({
                  component: 'Video Details',
                  action: 'Play Video',
                  category: 'user_actions',
                });
              }}
              onPause={() => {
                logEvent({
                  component: 'Video Details',
                  action: 'Pause Video',
                  category: 'user_actions',
                });
              }}
              player={player}
            />
          )}
          {isTechMerc1388Enabled ? <AssetVariationButton assetId={cluster?.id ?? ''} /> : null}
          <AssetNetworkLinkButton networkAssetId={cluster?.networkAssetId} network={cluster?.network} />
          {cluster.assetType === SDK.AssetType.Playable ? <DownloadAssetButton url={cluster.url ?? ''} /> : null}
          {propertyData ? (
            <Card fullWidth disableContentPadding>
              <Properties>
                <KeyValues
                  header="Properties"
                  values={propertyData}
                  isLoading={isLoading}
                  loadingPlaceholderNumber={11}
                />
              </Properties>
            </Card>
          ) : null}
          <Card fullWidth disableContentPadding>
            <MetricsCard data-test="video-card-metrics">{renderMetricsNumbers()}</MetricsCard>
          </Card>
        </LeftSide>
        <RightSide>
          <SectionContainer data-test="video-overview-fatigue-trend">{renderFatigueTrend()}</SectionContainer>
          <SectionContainer data-test="video-overview-metrics">{renderMetrics()}</SectionContainer>
          <SectionContainer data-test="video-overview-context-performance">
            <ContextPerformanceTable creativeData={cluster} />
          </SectionContainer>
        </RightSide>
      </Container>
    </RouteAnimator>
  );
};

export default VideoMetrics;
