import styled from 'styled-components';
import Colors from '../../Colors';

export const Root = styled.div`
  position: relative;
  display: flex;
  width: max-content;
`;

export const Select = styled.select`
  flex: 1;
  box-sizing: border-box;
  max-width: 100%;
  padding: 0 24px 0 0;
  color: ${Colors.Gray[900]};
  font-size: 14px;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  outline: none;
  appearance: none;
`;

export const IconHolder = styled.span`
  position: absolute;
  right: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  height: 100%;
`;
