import { useMutation, useQueryClient } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';
import { MARKET_ASSETS_MUTATION } from './useMarketAssets';

type UpdateSavedMarketAssetParams = {
  projectId: string;
  assetId: string;
  action: 'PUT' | 'DELETE';
  marketAssets: SDK.MarketAsset[];
};
const updateSavedMarketAsset = async ({
  projectId,
  assetId,
  action,
  marketAssets,
}: UpdateSavedMarketAssetParams): Promise<SDK.MarketAsset[]> => {
  const url = `${SDK.BASE_URL()}/market/favourites/${projectId}/asset/${assetId}`;
  const response = await provider.raw({ url, options: { method: action, body: {} } });

  return response.ok
    ? // Update Market Asset with new state
      marketAssets.map((marketAsset) => {
        if (marketAsset.id === assetId) {
          marketAsset.isSaved = !marketAsset.isSaved;
        }
        return marketAsset;
      })
    : marketAssets;
};

export default function useUpdateSavedMarketAsset() {
  const queryClient = useQueryClient();

  return useMutation<
    SDK.MarketAsset[],
    unknown,
    UpdateSavedMarketAssetParams,
    { previous?: SDK.GetMarketAssetsResponse; new?: SDK.GetMarketAssetsResponse }
  >(
    [`market-assets-update-saved`],
    ({ assetId, projectId, action, marketAssets }: UpdateSavedMarketAssetParams) =>
      updateSavedMarketAsset({ assetId, projectId, action, marketAssets }),
    {
      // For more info on the optimistic update pattern see https://tanstack.com/query/v4/docs/react/guides/optimistic-updates.
      onError: (_error, _variables, context) => {
        queryClient.setQueryData(MARKET_ASSETS_MUTATION, { previous: context?.previous, new: { marketAsset: [] } });
      },

      onSettled: () => queryClient.invalidateQueries({ queryKey: MARKET_ASSETS_MUTATION }),

      onMutate: async (variables) => {
        await queryClient.cancelQueries({ queryKey: MARKET_ASSETS_MUTATION });
        const lastQueryData = queryClient.getQueryData<SDK.GetMarketAssetsResponse>(MARKET_ASSETS_MUTATION);

        const updatedMarketAssets = {
          marketAssets:
            lastQueryData?.marketAssets.map((marketAsset) => {
              if (marketAsset.id === variables.assetId) {
                marketAsset.isSaved = !marketAsset.isSaved;
              }
              return marketAsset;
            }) ?? [],
        };
        // Perform an update so that changes reflect immediately.
        queryClient.setQueryData(MARKET_ASSETS_MUTATION, updatedMarketAssets);
        return { previous: lastQueryData, new: updatedMarketAssets };
      },
    }
  );
}
