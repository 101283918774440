import { CountryIconProps } from './types';

const BZ = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3572)">
      <path
        d="M24 12C24 7.8815 21.9249 4.24808 18.7635 2.08695L12 1.56519L5.23655 2.0869C2.07516 4.24808 0 7.88155 0 12C0 16.1186 2.07516 19.7519 5.23655 21.913L12 22.4348L18.7635 21.9131C21.9249 19.7519 24 16.1186 24 12Z"
        fill="#0052B4"
      />
      <path
        d="M18.7642 2.08709C16.8384 0.77056 14.5097 0.00012207 12.0008 0.00012207C9.49187 0.00012207 7.16316 0.77056 5.2373 2.08709H18.7642Z"
        fill="#A2001D"
      />
      <path
        d="M5.23633 21.9133C7.16214 23.2299 9.49089 24.0003 11.9998 24.0003C14.5087 24.0003 16.8374 23.2299 18.7632 21.9133H5.23633Z"
        fill="#A2001D"
      />
      <path
        d="M12.0009 17.7382C15.1705 17.7382 17.74 15.1687 17.74 11.999C17.74 8.82939 15.1705 6.25989 12.0009 6.25989C8.83122 6.25989 6.26172 8.82939 6.26172 11.999C6.26172 15.1687 8.83122 17.7382 12.0009 17.7382Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.0003 16.6954C14.5937 16.6954 16.696 14.5931 16.696 11.9997C16.696 9.4064 14.5937 7.30408 12.0003 7.30408C9.40701 7.30408 7.30469 9.4064 7.30469 11.9997C7.30469 14.5931 9.40701 16.6954 12.0003 16.6954Z"
        fill="#6DA544"
      />
      <path
        d="M11.9996 15.1296C13.7285 15.1296 15.13 13.728 15.13 11.9991C15.13 10.2702 13.7285 8.86865 11.9996 8.86865C10.2707 8.86865 8.86914 10.2702 8.86914 11.9991C8.86914 13.728 10.2707 15.1296 11.9996 15.1296Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9994 11.217L10.3037 11.9997V13.304L11.9994 14.3475L13.695 13.304V11.9997L11.9994 11.217Z"
        fill="#0052B4"
      />
      <path d="M13.695 10.4358H10.3037V12.001H13.695V10.4358Z" fill="#FFDA44" />
    </g>
    <defs>
      <clipPath id="clip0_1046_3572">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default BZ;
