import { CountryIconProps } from './types';

const GU = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3631)">
      <path
        d="M5.23633 21.9123C7.16214 23.2288 9.49089 23.9992 11.9998 23.9992C14.5087 23.9992 16.8374 23.2288 18.7632 21.9123L11.9998 20.8688L5.23633 21.9123Z"
        fill="#D80027"
      />
      <path
        d="M18.7642 2.08685C16.8383 0.770362 14.5096 -0.00012207 12.0008 -0.00012207C9.49187 -0.00012207 7.16312 0.770362 5.2373 2.08685L12.0008 3.13033L18.7642 2.08685Z"
        fill="#D80027"
      />
      <path
        d="M24 12.0004C24 8.02965 21.6227 4.042 18.7635 2.0874H5.23655C2.07516 4.24853 0 7.882 0 12.0004C0 16.119 2.07516 19.7523 5.23655 21.9135H18.7635C21.9249 19.7523 24 16.119 24 12.0004Z"
        fill="#0052B4"
      />
      <path
        d="M11.3213 19.5748C11.1573 19.4342 7.30469 16.0851 7.30469 12C7.30469 7.91486 11.1573 4.56569 11.3213 4.42511L12.0003 3.84302L12.6794 4.42506C12.8434 4.56569 16.696 7.91481 16.696 12C16.696 16.0851 12.8434 19.4343 12.6794 19.5748L12.0003 20.1569L11.3213 19.5748Z"
        fill="#D80027"
      />
      <path
        d="M15.652 12C15.652 8.34786 11.9998 5.21741 11.9998 5.21741C11.9998 5.21741 8.34766 8.34795 8.34766 12C8.34766 13.1173 8.6897 14.1855 9.16417 15.1305H14.8355C15.31 14.1855 15.652 13.1173 15.652 12Z"
        fill="#338AF3"
      />
      <path
        d="M15.652 12.0005C15.652 13.1178 15.31 14.186 14.8355 15.1309L11.9998 15.6527L9.16417 15.1309C8.6897 14.186 8.34766 13.1178 8.34766 12.0005H15.652Z"
        fill="#0052B4"
      />
      <path
        d="M11.9997 18.7824C11.9997 18.7824 13.7588 17.2741 14.8354 15.1302H9.16406C10.2406 17.2741 11.9997 18.7824 11.9997 18.7824Z"
        fill="#FFDA44"
      />
      <path
        d="M9.65137 9.9126H14.347L11.9992 12.2604L9.65137 9.9126Z"
        fill="#6DA544"
      />
      <path d="M12.783 11.4773H11.2178V16.173H12.783V11.4773Z" fill="#A2001D" />
    </g>
    <defs>
      <clipPath id="clip0_1046_3631">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default GU;
