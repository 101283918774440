import { IntegrationIconProps } from './types';

const WesternUnion = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="14" fill="#0B0006" />
    <path d="M4 12H4.63536L7.11326 20.75H6.4779L4 12Z" fill="#FFFDFE" />
    <path
      d="M5.71545 12H6.35081L8.82872 20.75H8.19336L5.71545 12Z"
      fill="#FFFDFE"
    />
    <path
      d="M9.33772 12H7.43164L9.90954 20.75H11.8156L13.5089 15.0625L15.3101 20.75H17.1209L19.5035 12H17.6292L16.1469 17.5938L14.4206 12H12.6098L10.8944 17.4968L9.33772 12Z"
      fill="#FEDB32"
    />
    <path
      d="M19.5035 16.0625L20.5836 12H21.4096V17.5625C21.4096 18.3909 22.0923 19.0625 22.9344 19.0625H23.5698C24.4119 19.0625 25.0947 18.3909 25.0947 17.5625V12H27.0007V17.5625C27.0007 19.461 25.4362 21 23.5063 21H22.998C21.068 21 19.5035 19.461 19.5035 17.5625V16.0625Z"
      fill="#FEDB32"
    />
  </svg>
);
export default WesternUnion;
