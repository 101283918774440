import { QueryFunction, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getHistoryMetrics = async (requestParams: SDK.GetHistoryMetricsRequest) => {
  const res = await provider.getJson<object, SDK.GetHistoryMetricsResponse>(
    `${SDK.BASE_URL()}/metrics/history?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

const KEY = 'metrics-history';
type Key = typeof KEY;

type Result = SDK.GetHistoryMetricsResponse;
type QueryKey = [Key, SDK.GetHistoryMetricsRequest];

type QueryOptions<R = Result> = UseQueryOptions<Result, unknown, R, QueryKey>;
type QueryResult<R = Result> = UseQueryResult<R>;

const queryFn: QueryFunction<Result, QueryKey> = ({ queryKey: [, requestParams] }) => getHistoryMetrics(requestParams);

// This is exported so we can use useQueries
export function getHistoryMetricsQueryOptions<R = Result>(
  requestParams: SDK.GetHistoryMetricsRequest,
  options?: QueryOptions<R>
): QueryOptions<R> {
  return {
    queryKey: [KEY, requestParams],
    queryFn,
    ...options,
  };
}

export default function useMetricsHistory<R = Result>(
  requestParams: SDK.GetHistoryMetricsRequest,
  options?: QueryOptions<R>
): QueryResult<R> {
  return useQuery(getHistoryMetricsQueryOptions(requestParams, options));
}
