import styled from 'styled-components';
import { formatDuration } from '../../../utils';
import Colors from '../../Colors';

const Container = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.3rem;
`;

const Timestamp = styled.span`
  color: ${Colors.Gray[500]};
  font-weight: 500;
  font-size: 12px;
  font-style: normal;
  line-height: 18px;
`;

export interface TimelineTimestampsProps {
  timestampsInSeconds: number[];
}

const TimelineTimestamps: React.FC<TimelineTimestampsProps> = ({
  timestampsInSeconds,
}) => (
  <Container>
    {timestampsInSeconds.map((timestampInSeconds) => {
      const { minutes, seconds } = formatDuration(timestampInSeconds);
      return (
        <Timestamp key={timestampInSeconds}>{`${minutes
          .toString()
          .padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`}</Timestamp>
      );
    })}
  </Container>
);

export default TimelineTimestamps;
