import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as SDK from '@replai-platform/sdk';
import type { MultiSelectDropDownOption } from '@replai-platform/ui-components';
import type { RootState } from '../../../store/rootReducer';
import { FilterActions } from '../../../store/filters';
import { Page } from '../../../utils/enums';

const usePromotedObjectFilter = (
  page?: Page,
  initialOptions?: SDK.PromotedObjectType[]
): {
  promotedObjectTypeFilterOptions: MultiSelectDropDownOption[];
  promotedObjectTypeFilterOnChange: (options: MultiSelectDropDownOption[]) => void;
  setIsInitialFilter: (i: boolean) => void;
} => {
  const dispatch = useDispatch();
  const promotedObjectTypes = useSelector(
    (state: RootState) => initialOptions ?? state.filters.promotedObjectTypesToConsider
  );

  // The default state of the filter is "ALL" selected. The problem is that the "ALL" option is not
  // stored in the redux store, so we dont have an easy way to know whether to select it or not. We
  // use this ref to identify the first onChange, and until the ref value changes, we can assume
  // that we want the "ALL" option to be selected (assuming that there are no networks in the store,
  // in which case that takes precedence).
  const isInitialFilter = useRef(true);

  const setIsInitialFilter = (i: boolean) => {
    isInitialFilter.current = i;
  };

  // Create filter options
  const promotedObjectTypeFilterOptions = useMemo<MultiSelectDropDownOption[]>(
    () => [
      {
        id: 'ALL',
        label: 'All OS',
        isAllOption: true,
        selected:
          (isInitialFilter.current && promotedObjectTypes.length === 0) ||
          promotedObjectTypes.length === SDK.PROMOTED_OBJECT_TYPES.length,
      },
      ...SDK.PROMOTED_OBJECT_TYPES.map((promotedObjectType) => ({
        id: promotedObjectType,
        label: SDK.getPromotedObjectString(promotedObjectType),
        selected:
          (isInitialFilter.current && promotedObjectTypes.length === 0) ||
          promotedObjectTypes.includes(promotedObjectType),
      })),
    ],
    [promotedObjectTypes]
  );

  const promotedObjectTypeFilterOnChange = useCallback(
    (options: MultiSelectDropDownOption[]) => {
      isInitialFilter.current = false;

      const selectedOptions = options
        .slice(1)
        .filter(({ selected }) => selected)
        .map(({ id }) => id as SDK.PromotedObjectType);
      dispatch(FilterActions.changePromotedObjectTypesToConsider({ logEvent: true, value: selectedOptions, page }));
    },
    [dispatch, page]
  );

  return { promotedObjectTypeFilterOptions, promotedObjectTypeFilterOnChange, setIsInitialFilter };
};

export default usePromotedObjectFilter;
