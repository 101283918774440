/* eslint-disable */

import { Typography } from '@material-ui/core';
import { motion } from 'framer-motion';
import { pageTransition } from '../configurations/animations';
import styled from 'styled-components';

const Container = styled(motion.div)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

export const Route404 = () => {
  return (
    <Container initial="out" animate="in" exit="out" variants={pageTransition}>
      <div>
        <Typography variant="h4">Oops, 404 page not found</Typography>
        <Typography variant="body1">Sorry, please try navigating again from the menu.</Typography>
      </div>
    </Container>
  );
};
