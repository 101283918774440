import { CommonTagIconProps } from '../types';

const DarkLuminosity = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.972 14.3329C19.641 14.0999 20.293 14.7659 20.004 15.4139C18.698 18.3449 15.753 20.3949 12.325 20.3949C7.28596 20.3949 3.28496 15.9609 4.00996 10.7939C4.44796 7.67091 6.69796 5.00791 9.69496 4.02791C10.716 3.69391 11.723 3.57091 12.687 3.61291C13.411 3.64391 13.734 4.52191 13.214 5.02691C12.165 6.04391 11.514 7.47091 11.514 9.04491C11.514 12.1449 14.024 14.6449 17.114 14.6449C17.765 14.6449 18.391 14.5359 18.972 14.3329Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default DarkLuminosity;
