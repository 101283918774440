import { IntegrationIconProps } from './types';

const Intel = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="9" width="2.53" height="2.53" fill="#0068B5" />
    <path d="M32 9H29.4653V22.2891H32V9Z" fill="black" />
    <path
      d="M14.1518 9.68647H16.6865V13.2187H18.2706V15.2753H16.6865L16.6865 18.9932C16.6865 20.1533 17.681 20.2148 18.2442 20.1006V22.3418C14.8862 22.3418 14.152 21.3137 14.1518 18.4404V9.68647Z"
      fill="black"
    />
    <path
      d="M6.38944 14.2958C6.93619 13.6884 7.89945 13.0078 9.24092 13.0078C11.5115 13.0078 12.8581 14.959 12.8581 16.6201V22.289H10.429V17.0947C10.4114 16.4267 9.98548 15.0908 8.42244 15.0908C7.34276 15.0908 6.71324 15.8037 6.38944 16.4909V22.289H3.85479V13.2187H6.38944V14.2958Z"
      fill="black"
    />
    <path d="M0 13.2187H2.53465V22.289H0V13.2187Z" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1546 18.5976H28.4092V17.7538C28.4092 15.1327 26.2815 13.0078 23.6568 13.0078C21.032 13.0078 18.9043 15.1327 18.9043 17.7539C18.9043 20.3751 21.032 22.5 23.6568 22.5C25.1969 22.5 26.5661 21.7672 27.4328 20.636L25.7554 19.3542C25.2711 19.9862 24.5114 20.3906 23.6568 20.3906C22.494 20.3906 21.5069 19.64 21.1546 18.5976ZM26.1405 16.8574C25.7731 15.8425 24.7997 15.1172 23.6568 15.1172C22.5138 15.1172 21.5404 15.8425 21.173 16.8574H26.1405Z"
      fill="black"
    />
  </svg>
);
export default Intel;
