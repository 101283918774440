import { CountryIconProps } from './types';

const VN = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#D80027"
    />
    <path
      d="M12.0005 6.26123L13.2956 10.247H17.4864L14.0959 12.7103L15.391 16.696L12.0005 14.2327L8.61005 16.696L9.90511 12.7103L6.51465 10.247H10.7055L12.0005 6.26123Z"
      fill="#FFDA44"
    />
  </svg>
);
export default VN;
