/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// For unknown reasons if you import the SDK here we will be facing a memory leak issue during local serve of the frontend.
// import * as SDK from '@replai-platform/sdk';

export type CombinationsStateProps = {
  tagPerformance?: any;
  globalPerformance?: any;
};

const initialState: CombinationsStateProps = {};

export const CombinationsSlice = createSlice({
  name: 'Combinations',
  initialState,
  reducers: {
    reset: (state) => Object.assign(state, initialState),

    setTagPerformance: (state, action: PayloadAction<any>) => {
      state.tagPerformance = action.payload;
    },

    setGlobalPerformance: (state, action: PayloadAction<any>) => {
      state.globalPerformance = action.payload;
    },
  },
});

export const CombinationsActions = CombinationsSlice.actions;
export const CombinationsReducer = CombinationsSlice.reducer;
export const CombinationsInitialState = initialState;
