import { Colors } from '..';
import generateGradient from './generateGradient';

const rose = {
  conical: generateGradient(
    [Colors.Rose[600], Colors.Rose[25]],
    'conical',
    {},
    {
      originDeg: 259.08,
      originX: 50,
      originY: 50,
      deg: [0, 360],
    }
  ),
  linear01: generateGradient([Colors.Rose[600], Colors.Rose[500]], 'linear', {
    originDeg: 90,
    percent: [0, 100],
  }),
  linear02: generateGradient([Colors.Rose[700], Colors.Rose[600]], 'linear', {
    originDeg: 45,
    percent: [0, 100],
  }),
  linear03: generateGradient([Colors.Rose[800], Colors.Rose[600]], 'linear', {
    originDeg: 45,
    percent: [0, 100],
  }),
  linear04: generateGradient([Colors.Rose[800], Colors.Rose[600]], 'linear', {
    originDeg: 90,
    percent: [0, 100],
  }),
  linear05: generateGradient([Colors.Rose[800], Colors.Rose[700]], 'linear', {
    originDeg: 26.5,
    percent: [0, 100],
  }),
  linear06: generateGradient([Colors.Rose[900], Colors.Rose[600]], 'linear', {
    originDeg: 45,
    percent: [0, 100],
  }),
} as const;

export default rose;
