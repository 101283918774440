import styled from 'styled-components';

export const TableContainer = styled.div`
  z-index: 0;
  width: 100%;
`;

export const ImgBox = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
  }
`;
