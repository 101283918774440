import './fonts.module.css';

import { LanguageTagIconProps } from '../types';

const Language = ({ dimension, languageCode }: LanguageTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.749 4H6.399C5.074 4 4 5.074 4 6.399V13.686C4 15.011 5.074 16.085 6.399 16.085H7.989V18.904C7.989 19.905 9.199 20.406 9.907 19.698L13.521 16.084H17.75C19.075 16.084 20.149 15.01 20.149 13.685V6.399C20.148 5.074 19.074 4 17.749 4Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <text
      fill="black"
      xmlSpace="preserve"
      style={{ whiteSpace: 'pre' }}
      fontFamily="Montserrat"
      fontSize="5"
      fontWeight="900"
      letterSpacing="0.15em"
    >
      <tspan
        textAnchor="middle"
        style={{ textTransform: 'uppercase' }}
        x="12.5"
        y="11.8074"
      >
        {languageCode ?? ''}
      </tspan>
    </text>
  </svg>
);
export default Language;
