import { Typography } from '@replai-platform/ui-components';
import styled from 'styled-components';

export const RecommendationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const KpisContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  padding-right: 2rem;
`;

export const PlatformsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const SuggestionContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const SuggestionInfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

export const TagInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
`;

export const NoMarginTypography = styled(Typography)`
  margin: 0;
`;

export const Figures = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: 0.5rem;
`;
