import { PaymentIconProps } from './types';

const Bitcoin = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.16 15.9134C33.16 21.7565 28.4232 26.4934 22.58 26.4934C16.7368 26.4934 12 21.7565 12 15.9134C12 10.0702 16.7368 5.33337 22.58 5.33337C28.4232 5.33337 33.16 10.0702 33.16 15.9134ZM25.1218 11.9825C26.5935 12.4879 27.6701 13.2447 27.4589 14.653C27.3055 15.6843 26.7317 16.183 25.9692 16.3577C27.0153 16.9001 27.3717 17.9296 27.0405 19.1743C26.4116 20.9657 24.9166 21.1165 22.9282 20.7421L22.4454 22.6677L21.2798 22.378L21.756 20.4782C21.4538 20.4034 21.145 20.3234 20.8269 20.238L20.3487 22.1471L19.1845 21.8574L19.6666 19.9278L17.3189 19.3377L17.8981 18.0081C17.8981 18.0081 18.7574 18.235 18.7455 18.2185C19.0756 18.2998 19.2223 18.0855 19.2801 17.9433L20.5884 12.7168C20.6031 12.4701 20.5174 12.1592 20.0452 12.0414C20.0631 12.0288 19.1984 11.8317 19.1984 11.8317L19.5086 10.5914L21.8596 11.1715L22.3378 9.26377L23.5027 9.55351L23.0344 11.4236C23.3479 11.4943 23.6627 11.5664 23.9689 11.6425L24.4338 9.78437L25.5993 10.0741L25.1218 11.9825ZM22.3329 15.1649C23.1269 15.3754 24.8546 15.8334 25.1557 14.6325C25.4629 13.4038 23.7844 13.0327 22.9626 12.851C22.8695 12.8304 22.7873 12.8122 22.7203 12.7955L22.1399 15.1147C22.1952 15.1284 22.2601 15.1456 22.3329 15.1649ZM21.4333 18.8989C22.3843 19.1494 24.4641 19.6973 24.7951 18.3739C25.1338 17.0208 23.1173 16.5701 22.1334 16.3502L22.1333 16.3502L22.1332 16.3502C22.0233 16.3256 21.9264 16.304 21.847 16.2842L21.2067 18.8403C21.2716 18.8563 21.3476 18.8764 21.4327 18.8988L21.4333 18.8989L21.4333 18.8989Z"
      fill="#F7931A"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default Bitcoin;
