import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getConceptWeekdayPerformance = async (
  requestParams: SDK.GetConceptWeekdayPerformanceRequest,
  conceptId: SDK.UUID
) => {
  const res = await provider.getJson<object, SDK.GetConceptWeekdayPerformanceResponse>(
    `${SDK.BASE_URL()}/concepts/${conceptId}/weekdayperformance?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useConceptWeekdayPerformance(
  conceptId: SDK.UUID,
  requestParams: SDK.GetConceptWeekdayPerformanceRequest,
  options?: UseQueryOptions<SDK.GetConceptWeekdayPerformanceResponse>
) {
  return useQuery(
    ['concept-weekdayperformance', conceptId, requestParams],
    () => getConceptWeekdayPerformance(requestParams, conceptId),
    options
  );
}
