import { CountryIconProps } from './types';

const LI = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3659)">
      <path
        d="M24 11.9995C24 18.6269 18.6274 23.9995 12 23.9995C5.37262 23.9995 0 18.6269 0 11.9995C0.521719 11.9995 12 10.4343 12 10.4343L24 11.9995Z"
        fill="#D80027"
      />
      <path
        d="M0 12C0 5.37262 5.37262 0 12 0C18.6274 0 24 5.37262 24 12"
        fill="#0052B4"
      />
      <path
        d="M8.87023 8.34736C8.87023 7.48294 8.16945 6.78216 7.30503 6.78216C6.90401 6.78216 6.53853 6.9331 6.26154 7.18102V6.26039H6.78326V5.21691H6.26154V4.69519H5.21806V5.21691H4.69634V6.26039H5.21806V7.18102C4.94108 6.9331 4.57559 6.78216 4.17458 6.78216C3.31016 6.78216 2.60938 7.48294 2.60938 8.34736C2.60938 8.81082 2.81098 9.22707 3.13109 9.51371V10.4343H8.34846V9.51371C8.66867 9.22707 8.87023 8.81082 8.87023 8.34736Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3659">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default LI;
