/* eslint-disable react/jsx-props-no-spreading */
import { type ReactNode, useEffect, useState } from 'react';
import { Avatar, Button, Colors, Input, InputProps, Typography } from '..';
import * as Icons from '../Icons';
import * as Styled from './styles';

import '../common.module.css';
import { CollapsableTitle, OptionsContainer } from './styles';

interface DropDownMenuOption {
  avatarUrl?: string;
  squared?: boolean;
  icon?: Icons.BaseIconTypes;
  label?: string;
  subLabel?: string;
  type?: 'option' | 'divider';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  selected?: boolean;
}

interface DropDownMenuProps {
  title?: string;
  options: DropDownMenuOption[];
  hasInput?: boolean;
  selected?: string;
  input?: InputProps;
  onClose?: () => void;
  includeContainerCard?: boolean; // being used inside another component
  collapsable?: boolean;
  collapsedByDefault?: boolean;
  onCollapseClick?: (isCollapsed?: boolean) => void;
}

const DropDownMenu: React.FC<DropDownMenuProps> = ({
  title,
  hasInput = false,
  input = {},
  selected,
  options,
  onClose,
  includeContainerCard = true,
  collapsable,
  collapsedByDefault,
  onCollapseClick,
}) => {
  const [inputText, setInputText] = useState('');
  const [isHovering, setIsHovering] = useState(options.map(() => false));
  const [isCollapsed, setIsCollapsed] = useState(collapsedByDefault);

  // Create a listener to enable closing the menu with the Escape key.
  useEffect(() => {
    const eventListener = (event: { key: string }) => {
      if (event?.key === 'Escape') {
        onClose?.();
      }
    };
    document.addEventListener('keydown', eventListener, false);
    // Unsubscribe the listener when the effect is re-run.
    return () => document.removeEventListener('keydown', eventListener);
  }, [onClose]);

  useEffect(() => {
    if (selected && options?.length) {
      document.getElementById(selected)?.scrollIntoView({ block: 'center' });
    }
  }, [selected, options]);

  let titleBlock: ReactNode | null = null;
  if (title) {
    titleBlock = collapsable ? (
      <CollapsableTitle
        role="button"
        tabIndex={0}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
          if (onCollapseClick) onCollapseClick(!isCollapsed);
        }}
        onKeyDown={() => {
          setIsCollapsed(!isCollapsed);
          if (onCollapseClick) onCollapseClick(!isCollapsed);
        }}
      >
        {isCollapsed ? (
          <Icons.BaseIcons.Plus color={Colors.Primary[700]} dimension={12} />
        ) : (
          <Icons.BaseIcons.Minus color={Colors.Primary[700]} dimension={12} />
        )}{' '}
        {title}
      </CollapsableTitle>
    ) : (
      <Styled.TitleContainer>
        <Styled.Typography noMargin type="text-xl">
          {title}
        </Styled.Typography>
        {includeContainerCard ? (
          <Styled.CloseIconContainer>
            <Button
              color="Gray"
              leadingIcon={{
                name: 'X',
              }}
              variant="no-fill"
              onClick={onClose}
            />
          </Styled.CloseIconContainer>
        ) : undefined}
      </Styled.TitleContainer>
    );
  }

  let searchBar: ReactNode | null = null;
  if (hasInput) {
    searchBar = (
      <Styled.InputContainer className={collapsable ? '' : 'inputContainer'}>
        <Input
          {...input}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        />
      </Styled.InputContainer>
    );
  }

  return (
    <Styled.Root
      className={`${
        !includeContainerCard ? '' : title ? 'rootWithTitle' : 'root'
      }`}
      data-test="select-drop-down-container"
    >
      {titleBlock}
      {!isCollapsed ? searchBar : undefined}
      {!isCollapsed ? (
        <OptionsContainer borderTop={!collapsable}>
          {options
            .filter((option) =>
              inputText
                ? option.label?.toLowerCase().includes(inputText.toLowerCase())
                : true
            )
            .map((option, index) => {
              if (option.type === 'divider') {
                return <Styled.Divider key={index} />;
              }
              if (!option.label) {
                throw new Error('DropDownMenu: `label` is required for option');
              }

              let optionBeginning: ReactNode | null = null;
              if (option.avatarUrl) {
                optionBeginning = (
                  <Styled.AvatarContainer>
                    <Avatar
                      imgUrl={option.avatarUrl}
                      squared={option.squared}
                    />
                  </Styled.AvatarContainer>
                );
              } else if (option.icon) {
                const Icon = Icons.getBaseIcon(option.icon);
                optionBeginning = (
                  <Styled.IconOption>
                    <Styled.IconContainer>
                      <Icon
                        dimension={16}
                        color={
                          isHovering[index]
                            ? Colors.Primary[700]
                            : Colors.Gray[700]
                        }
                      />
                    </Styled.IconContainer>
                  </Styled.IconOption>
                );
              }

              return (
                <Styled.Button
                  type="button"
                  id={option.label}
                  key={index}
                  className={`${option.label === selected ? 'selected' : ''}`}
                  onClick={(e) => option.onClick?.(e)}
                  onMouseEnter={() =>
                    setIsHovering((isHovering) =>
                      isHovering.map((isHovering, i) =>
                        i === index ? true : isHovering
                      )
                    )
                  }
                  onMouseLeave={() =>
                    setIsHovering((isHovering) =>
                      isHovering.map((isHovering, i) =>
                        i === index ? false : isHovering
                      )
                    )
                  }
                  data-test="select-drop-down-option"
                >
                  <Styled.Label id={option.label}>
                    {option.label === selected
                      ? Icons.getBaseIcon('Check')
                      : undefined}
                    {optionBeginning}
                    {option.label}
                  </Styled.Label>
                  {option.subLabel ? (
                    <Styled.SubLabel>{option.subLabel}</Styled.SubLabel>
                  ) : null}
                </Styled.Button>
              );
            })}
          {options.filter((option) =>
            inputText
              ? option.label?.toLowerCase().includes(inputText.toLowerCase())
              : true
          ).length === 0 && (
            <Styled.NoMatches>
              <Typography type="text-sm">No matches found</Typography>
            </Styled.NoMatches>
          )}
        </OptionsContainer>
      ) : undefined}
    </Styled.Root>
  );
};

export default DropDownMenu;
export type { DropDownMenuOption, DropDownMenuProps };
