import { Colors, VideoCard, SectionHeader, Notification, Tabs } from '@replai-platform/ui-components';
import { useMemo, useState } from 'react';
import * as SDK from '@replai-platform/sdk';
import { useFeature } from '@optimizely/react-sdk';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TopNavPageTitle from '../../components/TopNavPageTitle';
import { RootState } from '../../store/rootReducer';
import { Page } from '../../utils/enums';
import { CommonSnackbar } from '../../utils/styles';
import { GeneratingReportNotificationStatus, ReportsActions } from '../../store/reports';
import AllReportsTab from './Tabs/AllReportsTab';
import FavoriteReportsTab from './Tabs/FavoriteReportsTab';
import TemplatesTab from './Tabs/TemplatesTab';

const GenerateReportContainer = styled.div`
  margin-top: 2rem;
`;

const ReportTypesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 0 0 2rem 0;
`;

const ReportContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  ${(props) =>
    props.disabled
      ? `opacity: 0.5;
  filter: grayscale(90%);
  pointer-events: none;`
      : ''}
`;

const REPORT_TYPES = (isTechmerc1575Enabled: boolean) => [
  {
    type: SDK.ReportTypes.GOOGLE_SLIDES,
    title: 'Google Slides Report',
    route: 'google-slides',
    enabled: true,
    image: SDK.getReportTypeImageUrl(SDK.ReportTypes.GOOGLE_SLIDES),
  },
  {
    type: SDK.ReportTypes.GOOGLE_SHEETS,
    title: 'Google Sheets Report',
    route: 'google-sheets',
    enabled: isTechmerc1575Enabled,
    image: SDK.getReportTypeImageUrl(SDK.ReportTypes.GOOGLE_SHEETS),
  },
  {
    type: SDK.ReportTypes.LOOKER,
    title: 'Google Looker Report',
    enabled: false,
    image: SDK.getReportTypeImageUrl(SDK.ReportTypes.LOOKER),
  },
  {
    type: SDK.ReportTypes.TABLEAU,
    title: 'Tableau Report',
    enabled: false,
    image: SDK.getReportTypeImageUrl(SDK.ReportTypes.TABLEAU),
  },
  {
    type: SDK.ReportTypes.SLACK,
    title: 'Slack Alert',
    enabled: false,
    image: SDK.getReportTypeImageUrl(SDK.ReportTypes.SLACK),
  },
];

enum AvailableTabs {
  ALL_REPORTS = 'all_reports',
  FAVORITES = 'favorites',
  TEMPLATES = 'templates',
}

const Reports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectId = useSelector((state: RootState) => state.project.id);
  const generatingReportStatus = useSelector((state: RootState) => state.reports.generatingReportNotification);
  const [isTechmerc1575Enabled] = useFeature('techmerc-1575'); // Google Sheets export

  const [currentTab, setCurrentTab] = useState<AvailableTabs>(AvailableTabs.ALL_REPORTS);

  const renderedTab = useMemo(() => {
    switch (currentTab) {
      case AvailableTabs.ALL_REPORTS:
        return <AllReportsTab />;
      case AvailableTabs.FAVORITES:
        return <FavoriteReportsTab />;
      case AvailableTabs.TEMPLATES:
        return <TemplatesTab />;
      default:
        return null;
    }
  }, [currentTab]);

  return (
    <>
      <TopNavPageTitle
        title="Reports"
        subtitle="Effortlessly transform your data into beautifully tailored reports"
        eventPrefix="reports"
      />
      <GenerateReportContainer>
        <SectionHeader
          title="Generate a new Report"
          subtitle="Choose one of our available formats"
          withDivider={false}
        />
        <ReportTypesContainer>
          {REPORT_TYPES(isTechmerc1575Enabled).map((reportType) => (
            <ReportContainer disabled={!reportType.enabled}>
              <VideoCard
                isActive={reportType.enabled}
                isStaticImage
                name={reportType.title}
                previewUrl={reportType.image}
                previewLoading={false}
                onCardClick={() => {
                  dispatch(ReportsActions.reset());
                  navigate(`/${projectId}/${Page.Reports}/${reportType.route ?? ''}`);
                }}
                previewBackgroundColor={Colors.Common.White}
                showMetrics={false}
                recommendationNumber={0}
                fixedWidth
                thumbnailHeightRem={9}
              />
            </ReportContainer>
          ))}
        </ReportTypesContainer>
      </GenerateReportContainer>
      <SectionHeader title="Your reports" subtitle="See your previously generated reports" withDivider={false} />
      <Tabs
        tabLabels={[
          { id: AvailableTabs.ALL_REPORTS, label: 'All reports' },
          { id: AvailableTabs.FAVORITES, label: 'Favorites' },
          { id: AvailableTabs.TEMPLATES, label: 'Templates' },
        ]}
        onTabChange={(tab) => {
          setCurrentTab((tab?.id ?? AvailableTabs.ALL_REPORTS) as AvailableTabs);
        }}
      >
        {renderedTab ?? <div />}
      </Tabs>

      <CommonSnackbar
        open={generatingReportStatus && generatingReportStatus !== GeneratingReportNotificationStatus.TODO}
        onClose={() => {
          dispatch(ReportsActions.setGeneratingReportNotification(GeneratingReportNotificationStatus.TODO));
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
      >
        <Notification
          badgeTitle={GeneratingReportNotificationStatus.SUCCESS ? 'Success' : 'Failure'}
          badgeLabel={
            GeneratingReportNotificationStatus.SUCCESS
              ? 'Your report is being generated'
              : 'Your report failed to be generated'
          }
          message={
            GeneratingReportNotificationStatus.SUCCESS
              ? 'In about 5 minutes, you will receive it by email'
              : 'Try again or contact your account manager'
          }
          color={GeneratingReportNotificationStatus.SUCCESS ? 'Success' : 'Error'}
        />
      </CommonSnackbar>
    </>
  );
};

export default Reports;
