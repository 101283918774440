/* eslint-disable */
import { Button, Typography, Badge } from '@replai-platform/ui-components';
import { CellProps, Row } from 'react-table';
import styled from 'styled-components';
import { GeneratedColumn, RequiredGeneratorParams } from '../common';
import { logEvent } from '../../../analytics';

const SuggestionsContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const onExpansionToggle = function <D extends object = object>({
  event,
  row,
}: {
  event: React.MouseEvent;
  row: Row<D>;
}) {
  event.preventDefault();
  event.stopPropagation();
  logEvent({
    component: 'Recommendations',
    action: !row.isExpanded ? 'Row expanded' : 'Row collapsed',
    category: 'dashboard',
    parameters: {
      assetId: row.values.name.objectId,
    },
  });
  return (row.getToggleRowExpandedProps() as any)?.onClick?.(event);
};

const SuggestionsDecorator = <D extends object = object>({ row, value }: { row: Row<D>; value: string | number }) => {
  return row.canExpand ? (
    <SuggestionsContainer
      onClick={(e) =>
        onExpansionToggle({
          event: e,
          row: row,
        })
      }
    >
      <Badge color="Warning" leadingIcon="Bulb">
        {value}
      </Badge>
      {row.isExpanded ? (
        <Button
          color="Gray"
          leadingIcon={{
            name: 'ChevronUp',
          }}
          variant="no-fill"
          aria-label="Collapse Row"
        />
      ) : (
        <Button
          color="Gray"
          leadingIcon={{
            name: 'ChevronDown',
          }}
          onClick={(e) =>
            onExpansionToggle({
              event: e,
              row: row,
            })
          }
          variant="no-fill"
          aria-label="Expand Row"
        />
      )}
    </SuggestionsContainer>
  ) : null;
};

function getSuggestionsColumn<D extends object = object>({
  columnId,
  accessor,
}: RequiredGeneratorParams<D, string | number>): GeneratedColumn<D, string | number> {
  return {
    id: columnId,
    accessor,
    width: 250,
    minWidth: 250,
    Cell: ({ row, cell }: CellProps<D, string | number>) => <SuggestionsDecorator row={row} value={cell.value} />,
  };
}

export default getSuggestionsColumn;
