/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import { BASE_URL, convertRequestToQueryParams } from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getAssetPerformance = async (requestParams: SDK.GetAssetsMetricsRequest, assetId: SDK.UUID) => {
  const res = await provider.getJson<object, any>(
    `${BASE_URL()}/assets/${assetId}/performance?${convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useAssetPerformance(
  assetId: SDK.UUID,
  requestParams: SDK.GetAssetsMetricsRequest,
  options?: UseQueryOptions<any>
) {
  return useQuery(
    ['asset-performance', assetId, requestParams],
    () => getAssetPerformance(requestParams, assetId),
    options
  );
}
