/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Color, Colors, ColorTypes } from '..';
import * as Icons from '../Icons';
import * as Styled from './styles';
import '../common.module.css';

export type BadgeSize = 'sm' | 'md' | 'lg';

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  avatarImageUrl?: string;
  color?: ColorTypes;
  countryFlag?: Icons.CountryIconTypes;
  inverted?: boolean;
  size?: BadgeSize;
  leadingIcon?: Icons.BaseIconTypes | Icons.MiscIconTypes;
  trailingIcon?: Icons.BaseIconTypes | Icons.MiscIconTypes;
  leadingIconColor?: Color;
  trailingIconColor?: Color;
  noBackground?: boolean;
  noBorderRadius?: boolean;
}

const Badge = React.forwardRef(
  (
    {
      avatarImageUrl,
      children,
      className,
      color = 'Primary',
      countryFlag,
      inverted,
      leadingIcon,
      size = 'md',
      style = {},
      trailingIcon,
      leadingIconColor,
      trailingIconColor,
      noBackground = false,
      noBorderRadius = false,
      ...props
    }: BadgeProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const colorBase = Colors[color];

    const LeadingIcon =
      leadingIcon &&
      (Object.keys(Icons.BaseIcons).includes(leadingIcon)
        ? Icons.getBaseIcon(leadingIcon as Icons.BaseIconTypes)
        : Icons.getMiscIcon(leadingIcon as Icons.MiscIconTypes));
    const TrailingIcon =
      trailingIcon &&
      (Object.keys(Icons.BaseIcons).includes(trailingIcon)
        ? Icons.getBaseIcon(trailingIcon as Icons.BaseIconTypes)
        : Icons.getMiscIcon(trailingIcon as Icons.MiscIconTypes));
    const CountryIcon = countryFlag && Icons.getCountryIcon(countryFlag);

    const getBackgroundColor = () => {
      if (noBackground) {
        return 'transparent';
      }

      if (inverted) {
        return colorBase[600];
      }

      return colorBase[100];
    };

    return (
      <Styled.BadgeContainer
        data-test="badges"
        className={`${size} ${
          leadingIcon && (!countryFlag || !avatarImageUrl) ? 'leading' : ''
        } ${trailingIcon ? 'trailing' : ''} ${
          countryFlag || avatarImageUrl ? 'country-and-avatar' : ''
        } ${!React.Children.count(children) ? 'noChildren' : ''} ${
          className || ''
        }`}
        {...props}
        $style={{ ...style } as string}
        $backgroundColor={getBackgroundColor()}
        $color={inverted ? Colors.Common.White : colorBase[700]}
        $noBorderRadius={noBorderRadius}
        ref={ref}
      >
        {avatarImageUrl ? (
          <Styled.Avatar src={avatarImageUrl} />
        ) : CountryIcon ? (
          <CountryIcon dimension={16} />
        ) : (
          LeadingIcon && (
            <LeadingIcon
              color={
                inverted
                  ? Colors.Common.White
                  : leadingIconColor ?? colorBase[400]
              }
              dimension={leadingIcon === 'Dot' ? 8 : 16}
            />
          )
        )}
        {children}
        {TrailingIcon && (
          <TrailingIcon
            color={
              inverted
                ? Colors.Common.White
                : trailingIconColor ?? colorBase[700]
            }
            dimension={trailingIcon === 'Dot' ? 8 : 16}
          />
        )}
      </Styled.BadgeContainer>
    );
  }
);

export default Badge;
export type { BadgeProps };
