export const awsConfig = {
  Auth: {
    identityPoolId: 'us-east-1:66a713c3-f395-459d-b8aa-712c31efd00f',
    region: 'us-east-1',
    userPoolId: 'us-east-1_JpZFS4FoE',
    userPoolWebClientId: 'fbugrhhm94m34qi6nkvr8r3av',
    oauth: {
      domain: 'replai-platform.auth.us-east-1.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'openid',
        'profile',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: 'https://platform.replai.io/',
      redirectSignOut: 'https://platform.replai.io/',
      responseType: 'code',
    },
  },
};
