import { CountryIconProps } from './types';

const JM = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3731)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FFDA44"
      />
      <path
        d="M19.3059 2.48075C17.2824 0.925388 14.7495 0.00012207 12.0001 0.00012207C9.25073 0.00012207 6.71779 0.925434 4.69434 2.48075L12.0001 9.7865L19.3059 2.48075Z"
        fill="#6DA544"
      />
      <path
        d="M2.48058 4.6936C0.925312 6.71706 0 9.24995 0 11.9994C0 14.7488 0.925312 17.2817 2.48062 19.3052L9.78642 11.9994L2.48058 4.6936Z"
        fill="black"
      />
      <path
        d="M4.69434 21.5188C6.71784 23.0741 9.25073 23.9994 12.0001 23.9994C14.7495 23.9994 17.2824 23.0741 19.3059 21.5188L12.0001 14.213L4.69434 21.5188Z"
        fill="#6DA544"
      />
      <path
        d="M21.5196 19.3051C23.075 17.2816 24.0002 14.7488 24.0002 11.9994C24.0002 9.24995 23.075 6.71706 21.5196 4.6936L14.2139 11.9994L21.5196 19.3051Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3731">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default JM;
