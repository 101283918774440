import styled from 'styled-components';
import Colors from '../../../Colors';
import DefaultTagPreviewCarousel from '../../../TagPreviewCarousel/TagPreviewCarousel';

export const TagPreviewCarousel = styled(DefaultTagPreviewCarousel)`
  width: auto;
  height: 420px;
`;

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  height: 31rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26.25rem;
  background-color: ${Colors.Gray[50]};
  border-radius: 8px;

  img {
    max-width: 100%;
  }
`;

export const VideoHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-height: 36px;
  word-break: break-all;
`;

export const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const VideoContainer = styled.div`
  height: 28.5rem;
`;
