import { DropDownChip, DropDownChipProps } from '@replai-platform/ui-components';

export interface AssetTypeFilterProps {
  options: DropDownChipProps['dropDownOptions'];
  onChange?: DropDownChipProps['onChange'];
  loading: boolean;
  disabled?: boolean;
}

const AssetTypeFilter: React.VFC<AssetTypeFilterProps> = ({ options, onChange, loading, disabled = false }) => (
  <DropDownChip
    data-test="filter-assettype-dropdown"
    placeHolder="All Asset Types"
    defaultOption="All Asset Types"
    dropDownType="multiselect"
    dropDownOptions={options}
    dropDownAlignment="right"
    onChange={onChange}
    prefixLabel="Asset Types"
    disabled={disabled}
    loading={loading}
  />
);

export default AssetTypeFilter;
