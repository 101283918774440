import { CountryIconProps } from './types';

const SN = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3757)">
      <path
        d="M17.2175 1.19084C15.6399 0.427997 13.87 0.00012207 12.0001 0.00012207C10.1303 0.00012207 8.36037 0.427997 6.78274 1.19084L5.73926 12.0001L6.78274 22.8094C8.36037 23.5722 10.1303 24.0001 12.0001 24.0001C13.87 24.0001 15.6399 23.5722 17.2175 22.8094L18.261 12.0001L17.2175 1.19084Z"
        fill="#FFDA44"
      />
      <path
        d="M12 7.82605L13.0359 11.0145H16.3888L13.6764 12.9853L14.7124 16.1738L12 14.2032L9.28753 16.1738L10.3237 12.9853L7.61133 11.0145H10.964L12 7.82605Z"
        fill="#496E2D"
      />
      <path
        d="M6.78262 1.1908C2.76872 3.13175 0 7.24217 0 11.9997C0 16.7571 2.76872 20.8676 6.78262 22.8085V1.1908Z"
        fill="#496E2D"
      />
      <path
        d="M17.2178 1.19141V22.8091C21.2317 20.8682 24.0004 16.7578 24.0004 12.0003C24.0004 7.24278 21.2317 3.13236 17.2178 1.19141Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3757">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SN;
