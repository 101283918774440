import blue, { Blue } from './Blue';
import blueGray, { BlueGray } from './BlueGray';
import blueLight, { BlueLight } from './BlueLight';
import common, { Common } from './Common';
import error, { Error } from './Error';
import gray, { Gray } from './Gray';
import indigo, { Indigo } from './Indigo';
import orange, { Orange } from './Orange';
import pink, { Pink } from './Pink';
import primary, { Primary } from './Primary';
import purple, { Purple } from './Purple';
import rose, { Rose } from './Rose';
import success, { Success } from './Success';
import warning, { Warning } from './Warning';

const Colors = {
  Common: common,
  Blue: blue,
  BlueGray: blueGray,
  BlueLight: blueLight,
  Error: error,
  Gray: gray,
  Indigo: indigo,
  Orange: orange,
  Pink: pink,
  Primary: primary,
  Purple: purple,
  Rose: rose,
  Success: success,
  Warning: warning,
} as const;

type Color =
  | Common
  | Blue
  | BlueGray
  | BlueLight
  | Error
  | Gray
  | Indigo
  | Orange
  | Pink
  | Primary
  | Purple
  | Rose
  | Success
  | Warning;

type ColorTypes =
  | 'Blue'
  | 'BlueGray'
  | 'BlueLight'
  | 'Error'
  | 'Gray'
  | 'Indigo'
  | 'Orange'
  | 'Pink'
  | 'Primary'
  | 'Purple'
  | 'Rose'
  | 'Success'
  | 'Warning';

export default Colors;
export type { Color, ColorTypes };
