import { IconProps } from './types';

const DownloadCloud = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1027_7090)">
      <path
        d="M7.99999 17.0001L12 21.0001M12 21.0001L16 17.0001M12 21.0001V12.0001M20.88 18.0901C21.7494 17.4787 22.4014 16.6062 22.7413 15.5992C23.0812 14.5922 23.0914 13.5031 22.7704 12.4899C22.4494 11.4767 21.8139 10.5921 20.9561 9.96462C20.0983 9.33709 19.0628 8.99922 18 9.00006H16.74C16.4392 7.82793 15.8765 6.7393 15.0941 5.81614C14.3117 4.89297 13.3301 4.15932 12.2232 3.67041C11.1163 3.1815 9.91284 2.95008 8.70352 2.99357C7.4942 3.03706 6.31051 3.35433 5.24155 3.92148C4.17259 4.48864 3.24622 5.2909 2.53218 6.26788C1.81814 7.24487 1.33505 8.3711 1.11925 9.5618C0.903464 10.7525 0.960604 11.9767 1.28637 13.1421C1.61214 14.3075 2.19805 15.3838 2.99999 16.2901"
        stroke={color || '#101828'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_1027_7090">
        <rect fill="white" height={dimension || 24} width={dimension || 24} />
      </clipPath>
    </defs>
  </svg>
);

export default DownloadCloud;
