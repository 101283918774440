import { IntegrationIconProps } from './types';

const GoogleAds = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3849 10.9171C20.4652 9.0306 19.8241 6.61829 17.953 5.5291C16.0819 4.4399 13.6893 5.08628 12.6091 6.97282L4.53062 21.0806C3.45035 22.9671 4.09143 25.3794 5.96253 26.4686C7.83362 27.5578 10.2262 26.9114 11.3065 25.0249L19.3849 10.9171Z"
      fill="#F8BB2D"
    />
    <path
      d="M11.8263 23.0546C11.8263 25.2336 10.0743 27 7.91313 27C5.75197 27 4 25.2336 4 23.0546C4 20.8756 5.75197 19.1091 7.91313 19.1091C10.0743 19.1091 11.8263 20.8756 11.8263 23.0546Z"
      fill="#3BA757"
    />
    <path
      d="M12.6205 10.9171C11.5402 9.0306 12.1813 6.61829 14.0524 5.5291C15.9235 4.4399 18.316 5.08628 19.3963 6.97282L27.4747 21.0806C28.555 22.9671 27.9139 25.3794 26.0428 26.4686C24.1717 27.5578 21.7792 26.9114 20.6989 25.0249L12.6205 10.9171Z"
      fill="#4689F2"
    />
  </svg>
);
export default GoogleAds;
