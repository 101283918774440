import { CountryIconProps } from './types';

const OM = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3747)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M0 12.0005C0 16.549 2.53078 20.5062 6.26086 22.5414V1.45959C2.53078 3.49481 0 7.452 0 12.0005Z"
        fill="#D80027"
      />
      <path
        d="M23.2537 16.1752C23.736 14.8752 24 13.4691 24 12.0013C24 10.5334 23.736 9.12733 23.2537 7.82739H0.746438C0.264047 9.12733 0 10.5334 0 12.0013C0 13.4691 0.264047 14.8752 0.746438 16.1752L12 17.2187L23.2537 16.1752Z"
        fill="#D80027"
      />
      <path
        d="M12.0006 23.9997C17.1603 23.9997 21.5587 20.7432 23.2543 16.1736H0.74707C2.44254 20.7432 6.8411 23.9997 12.0006 23.9997Z"
        fill="#6DA544"
      />
      <path
        d="M0 11.9996C0 17.544 3.76036 22.2092 8.86955 23.5862V0.413025C3.76036 1.79002 0 6.45531 0 11.9996Z"
        fill="#D80027"
      />
      <path
        d="M7.34159 5.28157L6.23483 4.1748L5.49701 4.91271L4.7592 4.1748L3.65244 5.28157L4.3903 6.01943L3.65234 6.75734L4.75911 7.8641L5.49697 7.1262L6.23483 7.8641L7.34159 6.75734L6.60373 6.01943L7.34159 5.28157Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3747">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default OM;
