import { ClickAwayListener } from '@material-ui/core';
import { SlideoutMenu, VideoItem } from '@replai-platform/ui-components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { logEvent } from '../../analytics';
import { AppActions } from '../../store/app';
import { RootState } from '../../store/rootReducer';
import { Page } from '../../utils/enums';

const EVENT_COMPONENT = 'Compare Sidebar';

export const SlideoutMenuWrapper = styled.div`
  position: fixed;
  right: 0;
  z-index: 100;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const CompareSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const compareMenuOpen = useSelector((state: RootState) => state.app.compareMenuOpen);
  const compareItems = useSelector((state: RootState) => state.app.compareItems);
  const projectId = useSelector((state: RootState) => state.project.id);
  const match = useMatch(':projectId/:page');

  const closeSidebar = () => {
    if (compareMenuOpen) {
      logEvent({
        component: EVENT_COMPONENT,
        action: 'Close',
        category: 'user_actions',
        parameters: {
          page: match?.params.page,
        },
      });
      dispatch(AppActions.setCompareMenuOpen(false));
    }
  };

  const handleClickAway = (e: React.MouseEvent<Document, MouseEvent>) => {
    // Ignore close menu request if the element clicked was a compare button
    if ((e.target as HTMLButtonElement)?.className?.includes?.('compare')) return;
    closeSidebar();
  };
  const handleResetClick = () => {
    logEvent({
      component: EVENT_COMPONENT,
      action: 'Reset compared items',
      category: 'user_actions',
      parameters: {
        page: match?.params.page,
      },
    });
    dispatch(AppActions.setCompareItems({}));
    dispatch(AppActions.setCompareMenuOpen(false));
  };
  const handleCompareClick = () => {
    navigate(`/${projectId}/${Page.ComparePage}`, { state: { prevRoute: window.location.pathname } });
    logEvent({
      component: EVENT_COMPONENT,
      action: 'Go to comparison view',
      category: 'user_actions',
      parameters: {
        page: match?.params.page,
      },
    });
    dispatch(AppActions.setCompareMenuOpen(false));
  };
  const onItemDelete = (id: string) => {
    if (Object.keys(compareItems).length === 1) dispatch(AppActions.setCompareMenuOpen(false));
    logEvent({
      component: EVENT_COMPONENT,
      action: 'Delete compared item',
      category: 'user_actions',
      parameters: { id, page: match?.params.page },
    });
    dispatch(AppActions.removeCompareItem({ id }));
  };

  const render = () => (
    <>
      {Object.values(compareItems).map((item) => (
        <VideoItem key={item.id} imageUrl={item.imageUrl} name={item.name} onDelete={() => onItemDelete(item.id)} />
      ))}
    </>
  );

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
      <SlideoutMenuWrapper data-test="compare-slideout">
        {compareMenuOpen ? (
          <SlideoutMenu
            title="Comparing"
            subtitle="Compare iterations to better understand what could be your next steps."
            menuSections={[
              {
                id: 'video-items',
                title: '',
                render,
              },
            ]}
            closeHeaderButton={{ icon: 'X', onClick: closeSidebar }}
            closeButton={{ icon: 'X', label: 'Reset', onClick: handleResetClick }}
            saveButton={{
              icon: 'Scales',
              label: 'Compare now',
              disabled: Object.keys(compareItems).length < 2,
              onClick: handleCompareClick,
            }}
          />
        ) : null}
      </SlideoutMenuWrapper>
    </ClickAwayListener>
  );
};

export default CompareSidebar;
