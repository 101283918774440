import { CommonTagIconProps } from '../types';

const LoudIntro = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.24105 6.37807L5.27505 9.79607C5.19405 9.86607 5.09105 9.90407 4.98505 9.90407H2.69305C2.44705 9.90407 2.24805 10.1031 2.24805 10.3491V14.2721C2.24805 14.5181 2.44705 14.7171 2.69305 14.7171H5.35205C5.45905 14.7171 5.56205 14.7551 5.64205 14.8251L9.24105 17.9261C9.52905 18.1741 9.97605 17.9701 9.97605 17.5891V6.71507C9.97705 6.33407 9.53005 6.12907 9.24105 6.37807Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.1731 10.0781C13.1731 10.0781 15.0111 11.7991 13.1731 13.9901"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.124 8.12207C16.124 8.12207 19.639 11.4751 16.124 15.9021"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.311 5.96509C19.311 5.96509 24.802 11.4761 19.311 18.0351"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default LoudIntro;
