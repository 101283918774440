const generateAcronym = (str: string) => {
  if (str.length <= 3) {
    return str.toUpperCase();
  }
  return str
    .split(/\s/)
    .reduce((acc, word) => acc + word.charAt(0), '')
    .toUpperCase()
    .slice(0, 2);
};

export default generateAcronym;
