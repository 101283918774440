import { Amplify, Auth } from 'aws-amplify';
import * as SDK from '@replai-platform/sdk';
import * as provider from './provider';

Amplify.configure(SDK.awsConfig);

export async function signIn({ username, password }: { username: string; password: string }) {
  await Auth.signIn(username, password);
}

export async function isLoggedIn() {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const user = await Auth.currentAuthenticatedUser();
    return !!user;
  } catch (_) {
    return false;
  }
}

export async function getUserData(): Promise<SDK.User> {
  const url = `${SDK.BASE_URL()}/me`;
  return provider.getJson(url);
}

export async function signOut() {
  await Auth.signOut();
}

export async function getProjectConfig(projectid: string) {
  const url = `${SDK.BASE_URL()}/projects/${projectid}?fields=${encodeURIComponent(
    'config,name,icon,userProject{uiPreferences}'
  )}`;

  return provider.getJson(url).then((response) => response as SDK.Project);
}

export function getStoreYoutubeTokenUrl() {
  return `${SDK.BASE_URL()}/api/store-yt-token`;
}
