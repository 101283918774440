import { HeaderGroup, TableFooterProps } from 'react-table';
import Colors from '../Colors';

import * as Styled from './styles';

const TableFooterGroupContainer = (props: TableFooterProps) => (
  <Styled.TableRow
    /* eslint-disable react/jsx-props-no-spreading */
    {...props}
    /* eslint-enable react/jsx-props-no-spreading */
    $style={{ ...props.style } as string}
  />
);

interface TableFootProps<D extends object = object> {
  footerGroups: HeaderGroup<D>[];
}

const TableFooter = <D extends object = object>({
  footerGroups,
}: TableFootProps<D>) => (
  <Styled.TFoot data-test="table-footer">
    {footerGroups.map((group) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <TableFooterGroupContainer {...group.getFooterGroupProps()}>
        {group.headers.map((column, columnIndex) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Styled.TableData
            {...column.getHeaderProps()}
            $isFirstColumn={columnIndex === 0}
            className="cell"
            data-test={`table-footer-cell-${columnIndex}`}
          >
            {column.render('Footer')}
          </Styled.TableData>
        ))}
      </TableFooterGroupContainer>
    ))}
  </Styled.TFoot>
);

export default TableFooter;
