import { useEffect, useRef, useState } from 'react';
import format from 'date-fns/format';
import { useOverflow } from '../../utils';
import ActiveIndicator from '../ActiveIndicator/ActiveIndicator';
import Button from '../Button/Button';
import Card from '../Card/Card';
import Colors, { Color, ColorTypes } from '../Colors';
import SkeletonLoading from '../Skeleton/Skeleton';
import Typography from '../Typography/Typography';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import Tooltip from '../Tooltip/Tooltip';
import * as Styled from './styles';
import StatusIcon from '../StatusIcon/StatusIcon';
import { FlatSocialIconTypes } from '../Icons';
import {
  GridLoadingContainer,
  HiddenThumbnailsContainer,
  ImageGridContainer,
} from './styles';
import { TypographyClean } from '../CombinationDetail/Tabs/TagTrends/styles';

export type VideoCardProps = {
  assetId?: string;
  isActive?: boolean;
  isStaticImage?: boolean;
  isStaticImageGrid?: boolean;
  name?: string;
  nameVisibleLines?: number;
  description?: string;
  networks?: FlatSocialIconTypes[];
  assetCount?: string;
  age?: string;
  heading?: string;
  launchDate?: string;
  relevanceScore?: number | null;
  keyMetricRatio?: number;
  keyMetric?: string;
  keyMetricLabel?: string;
  isSaved?: boolean;
  recommendationNumber?: number;
  previewUrl?: string | string[];
  previewLoading?: boolean;
  previewBackgroundColor?: Color;
  hasPostQaAnnotations?: boolean;
  hiddenThumbnailsNumber?: number;
  onCardClick?: () => void;
  onVideoPlay?: () => void;
  onVideoPause?: () => void;
  onCompareClick?: () => void;
  onSaveClick?: () => void;
  showAssetCount?: boolean;
  showKeyMetric?: boolean;
  showHasPostQaAnnotations?: boolean;
  showAge?: boolean;
  showLaunchDate?: boolean;
  showNetworks?: boolean;
  showRelevanceScore?: boolean;
  showRecommendations?: boolean;
  showCompareButton?: boolean;
  showSaveButton?: boolean;
  showMetrics?: boolean;
  disableActivityIndicator?: boolean;
  disableClick?: boolean;
  disableCompareButton?: boolean;
  compareButtonColor?: ColorTypes;
  compareButtonText?: string;
  compareState?: 'default' | 'clicked' | 'hovered';
  customButton?: JSX.Element;
  fixedWidth?: boolean;
  thumbnailHeightRem?: number;
  showDownloadButton?: boolean;
};

const VideoCard = ({
  isActive,
  isStaticImage,
  isStaticImageGrid,
  name,
  nameVisibleLines = 1,
  description,
  networks,
  heading,
  assetCount,
  age,
  hasPostQaAnnotations,
  launchDate,
  relevanceScore,
  keyMetricRatio,
  keyMetric,
  keyMetricLabel,
  isSaved,
  recommendationNumber,
  previewUrl,
  previewLoading = true,
  previewBackgroundColor,
  hiddenThumbnailsNumber,
  onCardClick,
  onVideoPlay,
  onVideoPause,
  onCompareClick,
  onSaveClick,
  showKeyMetric = true,
  showAssetCount = false,
  showAge = true,
  showLaunchDate = false,
  showNetworks = false,
  showRelevanceScore = false,
  showRecommendations = true,
  showCompareButton = false,
  showSaveButton = false,
  showHasPostQaAnnotations = false,
  showMetrics = true,
  disableActivityIndicator = false,
  disableClick = false,
  disableCompareButton = false,
  compareButtonColor = 'BlueLight',
  compareButtonText = 'Add to compare',
  compareState = 'default',
  customButton,
  fixedWidth = false,
  thumbnailHeightRem = 15.125,
  showDownloadButton,
}: VideoCardProps) => {
  const nameRef = useRef(null);
  const nameOverflows = useOverflow(nameRef);
  const [clicked, setClicked] = useState<boolean>(compareState === 'clicked');
  const [hovered, setHovered] = useState<boolean>(compareState === 'hovered');

  useEffect(() => {
    setClicked(compareState === 'clicked');
    setHovered(compareState === 'hovered');
  }, [compareState]);

  const handleSaveClick = () => {
    onSaveClick?.();
  };

  const handleCompareClick = () => {
    setClicked((prevState) => !prevState);
    onCompareClick?.();
  };

  const handleMouseEnter = () => {
    if (!clicked) return;
    setHovered(true);
  };

  const handleMouseLeave = () => {
    if (!clicked) return;
    setHovered(false);
  };

  const toPercentage = (val: number | null) => {
    if (val === null) return 'N/A';
    return val < 0.001 ? '<0.1%' : `${(val * 100).toFixed(2)}%`;
  };

  const renderImageGrid = () => (
    <ImageGridContainer>
      {(previewUrl as string[]).slice(0, 4).map((url, index) => (
        <img
          width="47.5%"
          height="47.5%"
          src={(url as string) ?? ''}
          alt={name ?? 'Image'}
          style={{
            backgroundColor: previewBackgroundColor ?? Colors.Common.Black,
            objectFit: 'cover',
            opacity: index === 3 && hiddenThumbnailsNumber ? '20%' : '100%',
          }}
        />
      ))}
      {hiddenThumbnailsNumber ? (
        <HiddenThumbnailsContainer>
          <Styled.CleanTypography type={'text-xl'} fontWeight={'semi-bold'}>
            {`+${hiddenThumbnailsNumber}`}
            <br></br>
            {'videos'}
          </Styled.CleanTypography>
        </HiddenThumbnailsContainer>
      ) : (
        ''
      )}
    </ImageGridContainer>
  );

  const renderImageGridLoading = () => (
    <ImageGridContainer>
      {Array.from(Array(4).keys()).map((url, index) => {
        return (
          <GridLoadingContainer>
            <Styled.CustomSkeletonLoading height={'100%'} />
          </GridLoadingContainer>
        );
      })}
    </ImageGridContainer>
  );

  const renderContent = () => (
    <Styled.CardOutterContainer fixedWidth={fixedWidth}>
      <Card disableContentPadding fullWidth disableShadow>
        <Styled.CardInnerContainer
          onClick={
            (showHasPostQaAnnotations && !hasPostQaAnnotations) || disableClick
              ? () => ({})
              : onCardClick
          }
          disableClick={
            showHasPostQaAnnotations && !hasPostQaAnnotations
              ? true
              : disableClick
          }
        >
          {showRecommendations && recommendationNumber !== 0 && (
            <Tooltip
              content={
                <Typography noMargin type="text-sm">
                  Concept&apos;s Recommendations
                </Typography>
              }
              placement="right"
            >
              <Styled.TopBadge
                leadingIcon="Bulb"
                leadingIconColor={Colors.Warning[25]}
                trailingIcon={
                  recommendationNumber === undefined
                    ? 'LoadingCircle'
                    : undefined
                }
                trailingIconColor={Colors.Warning[25]}
              >
                {recommendationNumber ?? ' '}
              </Styled.TopBadge>
            </Tooltip>
          )}
          <Styled.ThumbnailContainer thumbnailHeight={thumbnailHeightRem}>
            {previewLoading ? (
              isStaticImageGrid ? (
                renderImageGridLoading()
              ) : (
                <Styled.CustomSkeletonLoading height="100%" />
              )
            ) : isStaticImage ? (
              <img
                width="100%"
                height="100%"
                src={(previewUrl as string) ?? ''}
                alt={name ?? 'Image'}
                style={{
                  backgroundColor:
                    previewBackgroundColor ?? Colors.Common.Black,
                  objectFit: 'contain',
                }}
              />
            ) : isStaticImageGrid ? (
              renderImageGrid()
            ) : (
              <VideoPlayer
                onPlay={onVideoPlay}
                onPause={onVideoPause}
                url={(previewUrl as string) ?? ''}
                title={name}
              />
            )}
          </Styled.ThumbnailContainer>
          <Styled.DetailsContainer>
            <Styled.NameContainer>
              {name && !disableActivityIndicator ? (
                <ActiveIndicator color={isActive ? 'Success' : 'Error'} />
              ) : null}
              <Tooltip
                content={
                  nameOverflows ? (
                    <Typography noMargin type="text-sm">
                      {name}
                    </Typography>
                  ) : null
                }
              >
                <Styled.NameLabelContainer
                  data-test="video-card-name"
                  height={`${1.25 * (nameVisibleLines + (heading ? 1 : 0))}rem`}
                >
                  {heading ? (
                    <Styled.Name
                      ref={nameRef}
                      noMargin
                      type="text-sm"
                      color={Colors.Gray[600]}
                    >
                      {heading}
                    </Styled.Name>
                  ) : null}
                  <Styled.Name
                    ref={nameRef}
                    noMargin
                    type="text-sm"
                    fontWeight="medium"
                    color={Colors.Gray[700]}
                    nameVisibleLines={nameVisibleLines}
                  >
                    {name}
                  </Styled.Name>
                </Styled.NameLabelContainer>
              </Tooltip>
            </Styled.NameContainer>
            <div>
              <TypographyClean type="text-sm">{description}</TypographyClean>
            </div>
            {showNetworks ? (
              <Tooltip
                content={
                  <Typography noMargin type="text-sm">
                    {networks?.join(', ')}
                  </Typography>
                }
                placement="top"
              >
                <Styled.NetworkContainer>
                  {networks ? (
                    networks.map((network) => (
                      <StatusIcon
                        iconSize={16}
                        rootContainerSize={24}
                        key={network}
                        icon={network}
                        status="neutral"
                      />
                    ))
                  ) : (
                    <SkeletonLoading height="1.25rem" />
                  )}
                </Styled.NetworkContainer>
              </Tooltip>
            ) : null}
            {showMetrics ? (
              <Styled.MetricsContainer>
                {showKeyMetric ? (
                  <Styled.SpendContainer>
                    <Styled.CleanTypography
                      color={Colors.Gray[500]}
                      type="text-xs"
                    >
                      {keyMetricLabel}
                    </Styled.CleanTypography>
                    {keyMetric ? (
                      <Styled.SpendBarContainer>
                        <Styled.SpendLinearProgress
                          value={keyMetricRatio || 0}
                        />
                        <Styled.SpendTypography
                          color={Colors.Gray[700]}
                          type="text-sm"
                          fontWeight="medium"
                          data-test="spend-text"
                        >
                          {keyMetric}
                        </Styled.SpendTypography>
                      </Styled.SpendBarContainer>
                    ) : (
                      <SkeletonLoading height="1.25rem" />
                    )}
                  </Styled.SpendContainer>
                ) : null}
                {showAge ? (
                  <Styled.AgeContainer>
                    <Styled.CleanTypography
                      color={Colors.Gray[500]}
                      type="text-xs"
                    >
                      Age
                    </Styled.CleanTypography>
                    {age ? (
                      <Styled.CleanTypography
                        color={Colors.Gray[700]}
                        type="text-sm"
                        fontWeight="medium"
                        data-test="age-text"
                      >
                        {age}
                      </Styled.CleanTypography>
                    ) : (
                      <SkeletonLoading height="1.25rem" />
                    )}
                  </Styled.AgeContainer>
                ) : null}
                {showLaunchDate ? (
                  <Styled.AgeContainer>
                    <Styled.CleanTypography
                      color={Colors.Gray[500]}
                      type="text-xs"
                    >
                      Launch Date
                    </Styled.CleanTypography>
                    {launchDate ? (
                      <Styled.CleanTypography
                        color={Colors.Gray[700]}
                        type="text-sm"
                        fontWeight="medium"
                        data-test="launch-date-text"
                      >
                        {format(new Date(launchDate), 'yyyy-MM-dd')}
                      </Styled.CleanTypography>
                    ) : (
                      <SkeletonLoading height="1.25rem" />
                    )}
                  </Styled.AgeContainer>
                ) : null}
                {showRelevanceScore ? (
                  <Tooltip
                    content={
                      <Typography noMargin type="text-sm">
                        Evaluation metric based on video age, ad networks, and
                        impressions for determining value and suitability.
                      </Typography>
                    }
                    placement="top"
                  >
                    <Styled.RelevanceScoreContainer>
                      <Styled.CleanTypography
                        color={Colors.Gray[500]}
                        type="text-xs"
                      >
                        Relevance Score
                      </Styled.CleanTypography>
                      {relevanceScore !== undefined ? (
                        <Styled.CleanTypography
                          color={Colors.Gray[700]}
                          type="text-sm"
                          fontWeight="medium"
                          data-test="share-of-advertiser-text"
                        >
                          {toPercentage(relevanceScore)}
                        </Styled.CleanTypography>
                      ) : (
                        <SkeletonLoading height="1.25rem" />
                      )}
                    </Styled.RelevanceScoreContainer>
                  </Tooltip>
                ) : null}
                {showAssetCount ? (
                  <Styled.AgeContainer>
                    <Styled.CleanTypography
                      color={Colors.Gray[500]}
                      type="text-xs"
                    >
                      # assets
                    </Styled.CleanTypography>
                    {assetCount ? (
                      <Styled.CleanTypography
                        color={Colors.Gray[700]}
                        type="text-sm"
                        fontWeight="medium"
                        data-test="asset-count-text"
                      >
                        {assetCount}
                      </Styled.CleanTypography>
                    ) : (
                      <SkeletonLoading height="1.25rem" />
                    )}
                  </Styled.AgeContainer>
                ) : null}
              </Styled.MetricsContainer>
            ) : (
              ''
            )}
            {customButton ? (
              <Styled.ButtonContainer>{customButton}</Styled.ButtonContainer>
            ) : null}
            {showSaveButton || showCompareButton ? (
              <Styled.ButtonContainer>
                {showDownloadButton ? (
                  <a
                    download={'Download creative'}
                    href={(previewUrl as string) ?? ''}
                  >
                    <Tooltip
                      content={
                        <Typography noMargin type="text-sm">
                          Click to download this creative
                        </Typography>
                      }
                      placement="top"
                    >
                      <Button
                        centerIcon={{ name: 'Download' }}
                        size="sm"
                        variant="outlined-light"
                        color="Gray"
                      />
                    </Tooltip>
                  </a>
                ) : (
                  ''
                )}
                {showSaveButton ? (
                  <Tooltip
                    content={
                      <Typography noMargin type="text-sm">
                        Click to {!isSaved ? 'save' : 'unsave'} the creative.
                      </Typography>
                    }
                    placement="top"
                  >
                    <Button
                      data-test="video-card-save-button"
                      size="sm"
                      className="save"
                      aria-label="save"
                      trailingIcon={{
                        name: isSaved ? 'FullHeart' : 'Heart',
                      }}
                      disabled={isSaved === undefined ? true : false}
                      variant="outlined-light"
                      color={compareButtonColor}
                      onMouseEnter={() => handleMouseEnter()}
                      onMouseLeave={() => handleMouseLeave()}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSaveClick();
                      }}
                    >
                      {isSaved ? 'Remove from ' : 'Add to '} favourites
                    </Button>
                  </Tooltip>
                ) : null}
                {showCompareButton ? (
                  <Styled.CompareButton
                    data-test="video-card-compare-button"
                    size="sm"
                    className="compare"
                    aria-label="compare"
                    centerIcon={{
                      name: clicked ? (hovered ? 'X' : 'Check') : 'Scales',
                    }}
                    variant={clicked ? 'regular' : 'outlined-light'}
                    color={
                      clicked
                        ? hovered
                          ? 'Blue'
                          : 'Success'
                        : compareButtonColor
                    }
                    disabled={
                      showHasPostQaAnnotations && !hasPostQaAnnotations
                        ? true
                        : !name || disableCompareButton
                    }
                    onMouseEnter={() => handleMouseEnter()}
                    onMouseLeave={() => handleMouseLeave()}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCompareClick();
                    }}
                  >
                    {clicked
                      ? hovered
                        ? 'Remove'
                        : 'Added'
                      : compareButtonText}
                  </Styled.CompareButton>
                ) : null}
              </Styled.ButtonContainer>
            ) : (
              ''
            )}
          </Styled.DetailsContainer>
        </Styled.CardInnerContainer>
      </Card>
    </Styled.CardOutterContainer>
  );

  return showHasPostQaAnnotations && !hasPostQaAnnotations ? (
    <Tooltip
      content={
        <Typography noMargin type="text-sm">
          Compare is currently not enabled for this creative, please check back
          later.
        </Typography>
      }
      placement="bottom"
    >
      {renderContent()}
    </Tooltip>
  ) : (
    renderContent()
  );
};

export default VideoCard;
