/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { useFloating } from '@floating-ui/react-dom';
import Button from '../Button/Button';
import Colors from '../Colors';
import * as Icons from '../Icons';
import * as Styled from './styles';
import { Input } from '..';

const XIconJSX = Icons.getBaseIcon('X');
const xIcon = <XIconJSX color={Colors.Gray[900]} dimension={20} />;

interface DropDownButtonProps {
  placeHolder?: string;
  dropDownAlignment?: 'right' | 'left';
  onConfirm?: (value: number) => void;
  onClickCrossButton?: () => void;
  hideCrossButton?: boolean;
  defaultValue?: number;
  title?: string;
}

const DropDownButton = ({
  placeHolder,
  dropDownAlignment = 'right',
  onConfirm,
  onClickCrossButton,
  hideCrossButton,
  defaultValue,
  title,
}: DropDownButtonProps) => {
  const [isDropDownCollapsed, setIsDropDownCollapsed] = useState(true);
  const dropDownButtonRef = useRef(null);

  const [value, setValue] = useState(defaultValue ?? 0);

  const { x, y, reference, floating, strategy } = useFloating({
    placement: `bottom-${dropDownAlignment === 'right' ? 'end' : 'start'}`,
  });

  // used to colapse the dropdown if the user clicks outside of it
  useClickAway(dropDownButtonRef, () => {
    setIsDropDownCollapsed(true);
  });

  return (
    <span data-test="dropdown-button-filter" ref={dropDownButtonRef}>
      <span ref={reference}>
        <Styled.Button
          variant="outlined"
          color="Gray"
          size="md"
          onClick={() => setIsDropDownCollapsed((c) => !c)}
        >
          <Styled.ButtonContent>
            <Styled.ButtonText>{placeHolder}</Styled.ButtonText>
            {hideCrossButton ? null : (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  onClickCrossButton?.();
                }}
                role="button"
                tabIndex={0}
                aria-label="Close"
              >
                {xIcon}
              </span>
            )}
          </Styled.ButtonContent>
        </Styled.Button>
      </span>
      {!isDropDownCollapsed && (
        <Styled.DropDownContainer
          data-test="filter-dropdown-container"
          ref={floating}
          $position={strategy}
          $left={x}
          $top={y}
        >
          <Styled.RootContent className={title ? 'rootWithTitle' : 'root'}>
            {title && (
              <span>
                <Styled.Typography type="text-md">{title}</Styled.Typography>
              </span>
            )}
            <Styled.ValueContainer>
              <Styled.InputContainer>
                <Input
                  type="number"
                  defaultValue={defaultValue ?? 0}
                  onChange={(e) =>
                    setValue(e.target.value as unknown as number)
                  }
                />
              </Styled.InputContainer>
              <Button
                aria-label="Confirm"
                leadingIcon={{ name: 'Check' }}
                onClick={() => onConfirm?.(value)}
              />
            </Styled.ValueContainer>
          </Styled.RootContent>
        </Styled.DropDownContainer>
      )}
    </span>
  );
};

export default DropDownButton;
export type { DropDownButtonProps };
