import styled, { createGlobalStyle } from 'styled-components';
import { default as DefaultTypography } from '../Typography/Typography';

export const MessageStyle = createGlobalStyle`
  .ant-message-notice.replai-snackbar-message .ant-message-custom-content {
    display: flex;
    align-items: center;
  }

  .ant-message-notice.replai-snackbar-message .ant-message-notice-content {
    border-radius: 0.5rem;
  }

  .ant-message-notice.replai-snackbar-message .ant-message-custom-content p {
    margin: 0;
  }
`;

export const Typography = styled(DefaultTypography)`
  padding-left: 0.25rem;
`;
