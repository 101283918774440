import styled from 'styled-components';
import { Typography } from '@replai-platform/ui-components';
import { ReactNode } from 'react';

// Ordered List
export const OList = styled.ol<{ type?: string }>`
  padding-bottom: 1rem;
  ${(props) => (props.type ? `type: ${props.type};` : undefined)}
`;

// Ordered List Item
export const OListItem = styled.li``;

// Unordered List
export const UList = styled.ul`
  font-weight: normal;
`;

// Unordered List Item
export const UListItem = styled.li`
  padding-bottom: 0.5rem;
`;

export type ListItem = { item: string | JSX.Element };

export const P = styled.p`
  font-weight: normal;
`;

export const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 2rem;
`;

export const ContentContainer = styled.div`
  padding-right: 1rem;
`;

export const RegularText = ({ children }: { children: ReactNode }) => (
  <Typography type="text-md">{children}</Typography>
);

export const BoldText = styled.span<{ color?: string }>`
  font-weight: bold;
  ${(props) => (props.color ? `color: ${props.color};` : undefined)}
`;

export const NoWrap = styled.span`
  white-space: nowrap;
`;

export const HighlightText = ({ children }: { children: ReactNode }) => <BoldText color="#fc043c">{children}</BoldText>;

export const ItalicText = styled.span`
  font-style: italic;
`;

export const FigureCaption = styled.figure`
  padding: 0.5rem;
  text-align: center;
`;

/**
 * Workaround function to enable links to work within the modal dialog.
 */
export function link({ url, label }: { url: string; label: string | JSX.Element }) {
  return (
    <a
      href={url}
      onClick={() => {
        window.open(url, '_blank');
      }}
    >
      {label}
    </a>
  );
}

export const DEFAULT_NETWORK_FAQS = [
  {
    title: 'How are metrics combined with my MMP metrics?',
    description: (
      <UList>
        <UListItem>
          Replai keeps pre-funnel metrics such as spend or impressions as they are reported by the ad network but for
          late-funnel metrics such as installs Replai uses MMP metrics if you&apos;ve connected an MMP to your account
          and Replai is able to match the ad network metrics against the MMP metrics.
        </UListItem>
        <UListItem>
          In order for this matching to work, please ensure your tracking settings are correctly configured on your MMP.
          Ensure that the network&apos;s ad, campaign, and creative IDs map correctly to those same IDs on the MMP. If
          your tracking settings aren&apos;t properly configured, Replai won&apos;t be able to match the MMP metrics and
          we&apos;ll default to your Ad Network metrics.
        </UListItem>
        <UListItem>
          If you&apos;d prefer to keep certain metrics such as installs as reported by the Ad Network instead of
          overriding them with the MMP metrics, please reach out to your point of contact on Replai.
        </UListItem>
      </UList>
    ),
  },
  {
    title: 'Which asset types does Replai support?',
    description: (
      <UList>
        <UListItem>Currently we support &apos;video&apos; and &apos;image&apos; assets.</UListItem>
        <UListItem>
          Among videos, Replai excludes videos with a duration longer than 100 seconds by default as the analysis for
          those types of videos tends to be different than for regular videos.
        </UListItem>
      </UList>
    ),
  },
];

export const DEFAULT_MMP_FAQS = [
  {
    title: 'How does Replai handle MMP metrics?',
    description: (
      <UList>
        <UListItem>
          Since Ad Networks have limited reach when it comes to late-funnel metrics, if you connect an MMP to your
          account, Replai will give preference to its late-funnel metrics by default as they&apos;re generally
          considered more accurate than ad network metrics.
        </UListItem>
        <UListItem>
          Replai users can modify this default behavior and choose which metrics they prefer to keep as they come from
          the Ad Network. If, for example, you want to keep installs as they&apos;re reported by Facebook instead of
          using your MMP, just inform your Replai point of contact!
        </UListItem>
      </UList>
    ),
  },
  {
    title: 'How does Replai know which ad to match MMP metrics to?',
    description: (
      <UList>
        <UListItem>
          Replai matches your MMP metrics to your Ad Network metrics based on the tracking information you configure on
          both the MMP and Network. To ensure this works as intended, please ensure that the ad, campaign, ad set and
          creative IDs of your ad network are correctly mapped to those same IDs on the MMP in your MMP tracking URL
          settings.
        </UListItem>
        <UListItem>
          If this configuration isn&apos;t done properly, Replai won&apos;t be able to match MMP metrics to Ad Network
          metrics and therefore will default to using just the Ad Network metrics.
        </UListItem>
      </UList>
    ),
  },
  {
    title: 'Why does my MMP data seem incomplete?',
    description: (
      <UList>
        <UListItem>
          Due to the nature of how MMPs work, their data often takes longer to become available, and in some cases, it
          gets updated over a few days due to delays in uploading usage data from the devices of viewers.
        </UListItem>
        <UListItem>
          This means that if you&apos;re viewing a recent day like “yesterday” on the Replai platform, the MMP metrics
          you see might not be the final metrics that will eventually be made available by the MMP.
        </UListItem>
        <UListItem>
          To address this, Replai has automated flows that repeatedly fetch data from both Ad Networks and MMPs to
          ensure you always have the most up-to-date data available, so over time metrics get updated on the Replai
          platform too.
        </UListItem>
      </UList>
    ),
  },
];
