import { IntegrationIconProps } from './types';

const LinuxMint = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.26"
      d="M5.3055 19.4875L5.30091 13.9287V12.264H2V3L15.9198 3.06783L20.2742 3.07743C25.4853 3.07743 29.9988 7.15209 29.9988 12.5335V28.9435H15.0302C9.82371 28.9435 5.3055 24.864 5.3055 19.4874V19.4875Z"
      fill="black"
    />
    <path
      d="M27.9993 24.6519V12.7212C27.9993 8.73529 24.6174 5.50416 20.4447 5.50416H16.1136V5.49455L4.40039 5.44165V9.79473H6.19844C7.45357 9.79473 7.67527 10.6903 7.67527 11.9298L7.68456 19.6257C7.68456 23.6116 11.0665 26.8426 15.2346 26.8426H25.5257C26.8449 26.8426 27.9995 25.979 27.9995 24.6518L27.9993 24.6519Z"
      fill="url(#paint0_linear_1334_955)"
    />
    <path
      opacity="0.175"
      d="M26.9791 25.66C24.3473 23.7855 22.4834 21.6322 20.9133 18.7817C17.8182 12.8723 18.5419 9.93866 10.8774 9.24695C8.66564 9.24695 7.1934 8.74097 4.86523 8.74097V9.57896C4.86523 9.57896 5.78526 10.0617 6.59394 10.0617C7.79986 10.0617 8.00909 10.9262 8.00909 12.1171L8.01674 19.4932C8.01674 23.323 11.2711 26.4268 15.2758 26.4268H25.1585C25.8839 26.4268 26.5356 26.1314 26.9791 25.66Z"
      fill="url(#paint1_linear_1334_955)"
    />
    <path
      d="M17.8188 9.9765C16.8797 9.9765 16.0453 10.3369 15.3748 11.0368C14.7047 11.7366 14.3529 12.61 14.3529 13.5957V18.5784H16.5394V13.5956C16.5394 13.2178 16.6586 12.9224 16.9197 12.6499C17.1815 12.3765 17.4589 12.2527 17.8188 12.2527C18.1864 12.2527 18.4595 12.376 18.722 12.6499C18.9829 12.9223 19.1023 13.2178 19.1023 13.5956V18.5783H21.2888V13.5956C21.2888 13.2178 21.408 12.9224 21.669 12.6499C21.9315 12.3758 22.2046 12.2527 22.5722 12.2527C22.9323 12.2527 23.2095 12.3765 23.4713 12.6499C23.7323 12.9223 23.8516 13.2178 23.8516 13.5956L23.8635 19.4175C23.8635 20.6596 22.7192 21.7062 21.2492 21.7062L14.1984 21.6776C13.064 21.6776 12.095 20.5362 12.095 19.0696V8.10608H10.0273V19.4914C10.0273 20.6993 10.455 21.7587 11.2869 22.6153C11.9981 23.3407 12.8532 23.7701 13.8141 23.8762V23.9172H21.6651C22.8714 23.9172 23.9313 23.4535 24.7865 22.5538H24.7904C25.5176 21.7816 25.9427 20.8563 26.046 19.8148L26.0381 13.5957C26.0381 12.61 25.6863 11.7366 25.0162 11.0368C24.3457 10.3369 23.5113 9.9765 22.5722 9.9765C21.6503 9.9765 20.8437 10.3308 20.1916 10.9795C19.5413 10.3318 18.7402 9.9765 17.8188 9.9765Z"
      fill="black"
      fillOpacity="0.08"
    />
    <path
      d="M17.6452 10.0358C16.7061 10.0358 15.8716 10.3962 15.2011 11.0962C14.531 11.7959 14.1791 12.6693 14.1791 13.655V18.6376H16.3657V13.655C16.3657 13.2772 16.4849 12.9818 16.746 12.7093C17.0078 12.436 17.2852 12.3122 17.6452 12.3122C18.0127 12.3122 18.2859 12.4354 18.5483 12.7093C18.8094 12.9817 18.9286 13.2772 18.9286 13.655V18.6376H21.1151V13.655C21.1151 13.2772 21.2344 12.9818 21.4954 12.7093C21.7579 12.4353 22.031 12.3122 22.3986 12.3122C22.7587 12.3122 23.0359 12.436 23.2978 12.7093C23.5588 12.9817 23.678 13.2772 23.678 13.655L23.69 19.477C23.69 20.719 22.5456 21.7657 21.0756 21.7657L14.0246 21.737C12.8902 21.737 11.9213 20.5957 11.9213 19.129V8.16541H9.85352V19.5507C9.85352 20.7587 10.2812 21.818 11.1131 22.6745C11.8243 23.4001 12.6796 23.8703 13.6404 23.9766H21.4915C22.6979 23.9766 23.7579 23.5129 24.6129 22.6132H24.6169C25.3442 21.8408 25.7692 20.9156 25.8726 19.8741L25.8647 13.655C25.8647 12.6693 25.5129 11.7958 24.8426 11.0962C24.1722 10.3962 23.3377 10.0358 22.3986 10.0358C21.4768 10.0358 20.6702 10.3901 20.0179 11.0389C19.3676 10.3911 18.5666 10.0358 17.6452 10.0358Z"
      fill="white"
    />
    <path
      d="M15.1677 28.495C10.1973 28.495 5.88418 24.6005 5.88418 19.468L5.87981 13.2359V11.6468H2.72852V3.72864L20.1734 3.80246C25.1481 3.80246 29.4568 7.69228 29.4568 12.8294V28.495H15.1677ZM27.4322 24.2927V12.8294C27.4322 8.99974 24.1828 5.89534 20.1735 5.89534L4.75788 5.8352V9.55411H6.0186C6.87453 9.55411 7.90447 10.3854 7.90447 11.6054L7.91344 19.4634C7.91344 23.2932 11.1628 26.3976 15.1675 26.3976H25.0554C26.323 26.3976 27.4322 25.5678 27.4322 24.2927Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_955"
        x1="6.65269"
        y1="8.17241"
        x2="19.3466"
        y2="21.6396"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7F994" />
        <stop offset="1" stopColor="#87CF3E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_955"
        x1="9.24621"
        y1="24.6119"
        x2="17.1549"
        y2="12.6143"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3D3D3D" />
        <stop offset="1" stopOpacity="0.114" />
      </linearGradient>
    </defs>
  </svg>
);
export default LinuxMint;
