/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as SDK from '@replai-platform/sdk';
import {
  ButtonVariant,
  camelCaseToCapitalCase,
  capitalCaseToCamelCase,
  Card,
  Icons,
  MessageDelay,
  TableProps,
  TableWithPaginationProps,
} from '@replai-platform/ui-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { Row, SortingRule } from 'react-table';
import { useFirstMountState, useDeepCompareEffect } from 'react-use';
import { logEvent } from '../../../analytics';
import { api } from '../../../api';
import useTagMetrics from '../../../api/hooks/tags/useTagMetrics';
import useTagCsvReport from '../../../api/hooks/tags/useTagCsvReport';
import Link from '../../../components/Link';
import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';
import TagPreviewVideosWrapper from '../../../components/TagPreviewVideosWrapper';
import { FilterActions } from '../../../store/filters';
import { RootState } from '../../../store/rootReducer';
import { TableRowData, TagsActions } from '../../../store/tags';
import { generateHref } from '../../../utils';
import { Columns, Page } from '../../../utils/enums';
import getUserSelectedColumns from '../../../utils/getUserSelectedColumns';
import {
  ALLOWED_COLUMNS_ON_TAGS_TABLE,
  getOrderByCondition,
  ITEMS_PER_PAGE,
  NON_CUSTOMIZABLE_COLUMNS,
} from '../../../utils/tables';
import { TableWrapper } from './styles';
import { CUSTOMIZE_DIALOG_SUBTITLE, CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP } from '../../../utils/constants';

const SUPPORTED_KPIS = [
  SDK.KPI.CPL,
  SDK.KPI.VINTED_CPL,
  SDK.KPI.YOKE_CPA,
  SDK.KPI.CPC,
  SDK.KPI.CPI,
  SDK.KPI.CTI,
  SDK.KPI.CTR,
  SDK.KPI.IPM,
  SDK.KPI.CPPDAY7,
  SDK.KPI.ROAS,
  SDK.KPI.ROASDAY1,
  SDK.KPI.ROASDAY3,
  SDK.KPI.ROASDAY7,
  SDK.KPI.RETENTIONDAY1,
  SDK.KPI.RETENTIONDAY3,
  SDK.KPI.RETENTIONDAY7,
  SDK.KPI.ROASDAY30,
  SDK.KPI.TREWATWELL_CPL,
  SDK.KPI.ENGAGEMENT_RATE,
  SDK.Metrics.SPEND,
] as SDK.MetricOrKpi[];

const SUPPORTED_METRICS = [
  ...SUPPORTED_KPIS,
  SDK.Metrics.IMPRESSIONS,
  SDK.Metrics.VIEWS,
  SDK.Metrics.INSTALLS,
  SDK.Metrics.PURCHASES,
  SDK.Metrics.PURCHASES_DAY_7,
  SDK.Metrics.EARNINGS,
  SDK.Metrics.EARNINGS_DAY_7,
  SDK.Metrics.AGE,
  SDK.Metrics.CLICKS,
  SDK.Metrics.SUBSCRIBERS_GAINED,
  SDK.Metrics.SUBSCRIBERS_LOST,
  SDK.Metrics.MINUTES_WATCHED,
  SDK.Metrics.AVG_VIEW_DURATION,
  SDK.Metrics.COMMENTS,
  SDK.Metrics.DISLIKES,
  SDK.Metrics.SHARES,
] as SDK.MetricOrKpi[];

const getMetricsColumns = (userSelectedColumns: Columns[]): Columns[] =>
  userSelectedColumns.filter(
    (col) =>
      ((col.includes('metrics.') || col.includes('ads.')) &&
        (SUPPORTED_METRICS.includes(col.replace('metrics.', '') as SDK.Metrics) ||
          SUPPORTED_METRICS.includes(col.replace('ads.', '') as SDK.Metrics))) ||
      col === Columns.Frequency ||
      col === Columns.AdInstances ||
      col === Columns.NumCreatives ||
      col === Columns.SpendTrend
  ) || [];

const supportedMetrics = [...Object.values(SDK.Metrics), ...Object.values(SDK.KPI)];

const getRequestMetrics = (userSelectedColumns: string[]) =>
  [...supportedMetrics.filter((metric) => userSelectedColumns.includes(`metrics.${metric}`))] as SDK.MetricOrKpi[];

const TagsTable: React.VFC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const projectId = useSelector((state: RootState) => state.project.id);
  const group = useSelector((state: RootState) => state.tags.group);
  const filters = useSelector((state: RootState) => state.filters);
  const tagsConfig = useSelector((state: RootState) => state.project.config.tagsTypes);
  const [tagsSorting, setTagsSorting] = useState<SortingRule<TableRowData>[]>([{ id: 'metrics.spend', desc: true }]);
  const [tagsSearchTerm, setTagsSearchTerm] = useState<string>('');
  const tableOffset = useSelector((state: RootState) => state.tags.tableOffset);
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { pathnameBase, params } = useMatch(`:projectId/${Page.Tags}/*`)!;
  const [selectedTag, setSelectedTag] = useState<SDK.Tag | null>(null);
  const [csvExportClicked, setCsvExportClicked] = useState<boolean>(false);
  const userColumns = useSelector(
    (state: RootState) => state.project.userProject.uiPreferences?.videos?.columns as Columns[]
  );
  const userSelectedColumns: Columns[] = useMemo(
    () => getUserSelectedColumns({ userColumns, projectKpis, projectAccountTypes }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectKpis, projectAccountTypes, JSON.stringify(userColumns)]
  );
  const firstRender = useFirstMountState();

  // Resets pagination and CSV data when filters change
  useDeepCompareEffect(() => {
    dispatch(TagsActions.changeTableOffset(0));
    setCsvExportClicked(false);
  }, [filters, dispatch]);

  // Set tag types to consider/exclude based on the grouping type.
  const [tagTypesToConsider, setTagTypesToConsider] = useState<string[]>([]);
  const [tagTypesToExclude, setTagTypesToExclude] = useState<string[]>(tagsConfig?.excluded ?? []);

  useEffect(() => {
    switch (group.type) {
      case 'core':
        setTagTypesToExclude([
          ...(tagsConfig?.excluded ?? []),
          ...(tagsConfig?.custom ?? []),
          ...(tagsConfig?.psychological ?? []),
        ]);
        setTagTypesToConsider([]);
        break;
      case 'custom':
        setTagTypesToExclude([...(tagsConfig?.excluded ?? [])]);
        setTagTypesToConsider([...(tagsConfig?.custom ?? [])]);
        break;
      case 'psychological':
        setTagTypesToExclude([...(tagsConfig?.excluded ?? [])]);
        setTagTypesToConsider([...(tagsConfig?.psychological ?? [])]);
        break;
      case 'tagType':
        setTagTypesToExclude([...(tagsConfig?.excluded ?? [])]);
        setTagTypesToConsider([...(group.value ? [group.value] : [])]);
        break;
      case 'none':
        setTagTypesToExclude([...(tagsConfig?.excluded ?? [])]);
        setTagTypesToConsider([]);
        break;
      default:
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: never = group.type;
        setTagTypesToExclude([...(tagsConfig?.excluded ?? [])]);
        setTagTypesToConsider((currentTagTypesToConsider) => [...currentTagTypesToConsider, exhaustiveCheck]);
        break;
    }
  }, [group.type, group.value, tagsConfig?.custom, tagsConfig?.excluded, tagsConfig?.psychological]);
  const getMetricsParams = useMemo<SDK.GetTagsMetricsRequest>(
    () => ({
      projectIds: [projectId],
      metrics: getRequestMetrics(userSelectedColumns),
      orderBy: getOrderByCondition(tagsSorting[0], userSelectedColumns),
      adsFilters: api.filterConverter.getAdsFilters(filters),
      adTagsFilters: api.filterConverter.getAdTagsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      tagsFilters: {
        tagTypesToConsider: tagTypesToConsider.map((tagType) => ({ type: tagType })),
        tagTypesToExclude: tagTypesToExclude.map((tagType) => ({ type: tagType })),
      },
      search: tagsSearchTerm ? `%${tagsSearchTerm}%` : undefined,
      includeTotalsAndAvg: true,
      maxRecords: ITEMS_PER_PAGE,
      offset: tableOffset,
      excludeNullTagValues: true,
      objectLevel: SDK.ObjectLevel.ASSET,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      projectId,
      tagsSorting,
      userSelectedColumns,
      tagsSearchTerm,
      tagTypesToConsider,
      tagTypesToExclude,
      tableOffset,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(filters),
    ]
  );
  const { data, isLoading, isError } = useTagMetrics(getMetricsParams);

  const getTagsCsvParams = useMemo<SDK.GetTagsCsvReportRequest>(
    () => ({
      projectIds: [projectId],
      metrics: getRequestMetrics(userSelectedColumns),
      orderBy: getOrderByCondition(tagsSorting[0], userSelectedColumns),
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      tagsFilters: {
        tagTypesToExclude: (tagsConfig?.excluded || []).map((tagType) => ({
          type: tagType,
        })),
        tagTypesToConsider: tagTypesToConsider.map((tagType) => ({
          type: tagType,
        })),
      },
      includeTotalsAndAvg: true,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      projectId,
      tagsSorting,
      userSelectedColumns,
      tagTypesToConsider,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(filters),
    ]
  );
  const {
    data: csvTagsData,
    isLoading: isLoadingCsv,
    isFetching: isFetchingCsv,
  } = useTagCsvReport(getTagsCsvParams, {
    select: (res) => res.csv,
    enabled: !!projectId && csvExportClicked,
  });

  const onOffsetChange = useCallback<Exclude<TableWithPaginationProps['onPageUpdate'], undefined>>(
    ({ pageIndex }) => {
      if (pageIndex !== tableOffset) {
        dispatch(TagsActions.changeTableOffset(pageIndex * ITEMS_PER_PAGE));

        logEvent({
          component: 'Tags',
          action: 'Change page',
          category: 'user_actions',
          parameters: { pageOffset: pageIndex + 1, pageOffsetLabel: `Page ${pageIndex + 1}` },
        });
      }
    },
    [dispatch, tableOffset]
  );

  const onSort = useCallback<Exclude<TableProps['onSort'], undefined>>(
    (sortBy: SortingRule<TableRowData>[]) => {
      if (tagsSorting[0] !== sortBy[0]) {
        setTagsSorting(sortBy);
        if (!firstRender) {
          // TODO(analytics): sort is disabled in tags table, so this event is not triggered
          logEvent({
            component: 'Tags',
            action:
              sortBy[0]?.id?.split('.')?.[1] != null
                ? `Tags - Sort by ${camelCaseToCapitalCase(sortBy[0].id.split('.')[1])}`
                : 'Tags - Change Sorting',
            category: 'user_actions',
            parameters: { ...sortBy },
          });
        }
      }
    },
    [firstRender, tagsSorting]
  );

  const onRowProps = useCallback(
    (row: Row<TableRowData>) => ({
      style: {
        cursor: 'pointer',
      },
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        navigate(`${pathnameBase}/${row.original.tag.id}${location.search}`);
      },
    }),
    [navigate, location.search, pathnameBase]
  );

  const generateRowHref = useCallback(
    (row) => generateHref(params.projectId ?? '', Page.Tags, row.tag?.id ?? ''),
    [params.projectId]
  );

  const buildTagsMappedData = (tags) =>
    tags
      .map(
        ({
          tag,
          metrics,
          thumbnailUrl,
          totalSpend,
          network,
          os,
          individualObjectCount,
          totalObjectCount,
          countAdsWithTag,
          countAllAds,
        }) => ({
          name: `${tag.type as string}: ${(tag.value as string) ?? SDK.messages.NOT_AVAILABLE}`,
          tag: { ...tag, thumbnailUrl, network, os },
          cluster: {
            ...metrics,
            totalSpend,
            numCreatives: individualObjectCount,
            numTotalCreatives: totalObjectCount,
            numInstances: countAdsWithTag,
            numTotalInstances: countAllAds,
            frequency: countAdsWithTag && countAllAds && countAllAds > 0 ? countAdsWithTag / countAllAds : null,
          },
          entity: SDK.ObjectLevel.TAG,
        })
      )
      .filter(
        (t) =>
          t.tag.type &&
          t.tag.value &&
          (tagsSearchTerm
            ? t.tag.type.includes(capitalCaseToCamelCase(tagsSearchTerm.toLowerCase())) ||
              t.tag.type.toLowerCase().includes(tagsSearchTerm.toLowerCase()) ||
              t.tag.value.toLowerCase().includes(tagsSearchTerm.toLowerCase())
            : true)
      ) ?? [];

  const tableProps = useMemo(() => {
    const mappedData: TableRowData[] = isError || isLoading ? [] : buildTagsMappedData(data?.tags ?? []);
    return {
      columns: Array.from(
        new Set([Columns.Preview, Columns.Name, Columns.AppearsIn, ...getMetricsColumns(userSelectedColumns)])
      ).filter((column) => ![Columns.ReplaiScore].includes(column)),
      tableData: mappedData,
      loading: isLoading
        ? {
            messages: ['Calculating metrics', 'Fetching tags', 'Aggregating KPIs'],
            firstMessageDelay: 3 as MessageDelay,
          }
        : undefined,
      rowsTotal: isLoading || isError ? 0 : data?.totalCount ?? 0,
      rowsTotalLoading: isLoading,
      emptyStateProps: isError
        ? {
            icon: 'AlertTriangle' as Icons.BaseIconTypes,
            text: 'Something went wrong...',
            supportingText:
              'We had some trouble loading this page. Please refresh the page to try again or get in touch if the problem sticks around!',
          }
        : {
            icon: 'Search' as Icons.BaseIconTypes,
            text: 'No tags found',
            supportingText: "Your search didn't return any tags, please try again or adjust your filters.",
            buttons: [
              {
                variant: 'regular' as ButtonVariant,
                children: 'Clear search',
                onClick: () => {
                  batch(() => {
                    dispatch(TagsActions.changeTableOffset(0));
                    dispatch(setTagsSearchTerm(''));
                  });
                },
              },
              {
                variant: 'outlined' as ButtonVariant,
                children: 'Reset filters',
                onClick: () => dispatch(FilterActions.reset()),
              },
            ],
          },
      rowsPerPage: ITEMS_PER_PAGE,
      pageIndex: tableOffset / ITEMS_PER_PAGE,
      onPageUpdate: onOffsetChange,
      onSort,
      sorting: tagsSorting,
      onRowProps,
      includeFooter: true,
      includeCard: false,
      isTagData: true,
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    isError,
    isLoading,
    data?.tags,
    tableOffset,
    projectId,
    JSON.stringify(filters),
    JSON.stringify(userSelectedColumns),
    onOffsetChange,
    onSort,
    tagsSorting,
    tagsSearchTerm,
    onRowProps,
    dispatch,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const tableLink = ({ cell, anchorFactory }) => {
    const href = generateRowHref(cell.row.original);
    return (
      <Link
        component={anchorFactory({ ariaLabel: cell.row.original.tag?.name, href })}
        onClick={() => {
          logEvent({
            component: 'Tags',
            action: 'Click on Row',
            category: 'user_actions',
          });
        }}
        to={href}
      />
    );
  };

  return (
    <Card data-test="tags-main-table" fullWidth disableContentPadding>
      {selectedTag !== null && (
        <TagPreviewVideosWrapper
          isOpen
          tag={selectedTag}
          page={Page.Tags}
          component="Tags Table"
          onClose={() => setSelectedTag(null)}
        />
      )}
      <TableToolbar
        eventPrefix="tags"
        initialSearchTerm={tagsSearchTerm}
        onSearchTermChangeEnd={(input) => {
          batch(() => {
            setTagsSearchTerm(input);
            dispatch(TagsActions.changeTableOffset(0));
          });
        }}
        exportCSVEnabled
        onExportCSVClick={() => setCsvExportClicked(true)}
        csvName={`${filters.startDate}-${filters.endDate}-tags.csv`}
        csvData={csvTagsData}
        csvLoading={(isLoadingCsv || isFetchingCsv) && csvExportClicked}
        data-test="table-toolbar"
        showCustomize
        hideIntroTagsToggle={false}
        tableColumns={ALLOWED_COLUMNS_ON_TAGS_TABLE}
        nonCustomizableColumns={NON_CUSTOMIZABLE_COLUMNS}
        subtitle={CUSTOMIZE_DIALOG_SUBTITLE}
        disallowedTooltip={CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP}
      />
      <TableWrapper>
        <Table
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...tableProps}
          cellLink={tableLink}
        />
      </TableWrapper>
    </Card>
  );
};

export default TagsTable;
