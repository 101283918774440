import { DropDownChip, DropDownChipProps } from '@replai-platform/ui-components';

export interface NetworkFilterProps {
  options: DropDownChipProps['dropDownOptions'];
  onChange: DropDownChipProps['onChange'];
  loading: boolean;
  disabled?: boolean;
}

const NetworkFilter: React.VFC<NetworkFilterProps> = ({ options, onChange, loading, disabled = false }) => (
  <DropDownChip
    data-test="filter-network-dropdown"
    placeHolder="All Networks"
    defaultOption="All Networks"
    dropDownType="multiselect"
    dropDownOptions={options}
    dropDownAlignment="right"
    onChange={onChange}
    prefixLabel="Networks"
    disabled={disabled}
    loading={loading}
  />
);

export default NetworkFilter;
