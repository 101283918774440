import { IconProps } from './types';

const SlashDivider = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      x1="7.51631"
      x2="16.8139"
      y1="22.1711"
      y2="2.2323"
    />
  </svg>
);

export default SlashDivider;
