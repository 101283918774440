import { ColorTypes } from '../Colors';

export const getTimelineTrackSegmentColor = (index: number) => {
  const SEGMENT_COLORS: ColorTypes[] = [
    'Blue',
    'Error',
    'Indigo',
    'Orange',
    'BlueGray',
    'Pink',
    'Purple',
    'Rose',
    'Success',
    'Warning',
  ];
  if (index == null || index < 0) {
    return 'Gray';
  }
  return SEGMENT_COLORS[index % 10];
};
