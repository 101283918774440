import { Button } from '@replai-platform/ui-components';
import { useState } from 'react';
import styled from 'styled-components';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../../common';

const ButtonContainer = styled.div`
  margin-right: 1rem;
`;

export type ReportTypeAccessorType = {
  onReportOpenClick?: (setButtonLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
};

const ReportTypeCellDecorator: React.FC<{
  cellData: ReportTypeAccessorType;
}> = ({ cellData }) => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  if (!cellData) {
    return null;
  }

  return cellData?.onReportOpenClick ? (
    <ButtonContainer>
      <Button
        trailingIcon={{ name: buttonLoading ? 'LoadingCircle' : 'ExternalLink' }}
        onClick={() => {
          cellData?.onReportOpenClick?.(setButtonLoading);
        }}
        disabled={buttonLoading}
      >
        {buttonLoading ? 'Authorizing...' : 'Open'}
      </Button>
    </ButtonContainer>
  ) : (
    <div />
  );
};

function getReportTypeColumn<T extends object = ReportTypeAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T, ReportTypeAccessorType>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<T, ReportTypeAccessorType>) => <ReportTypeCellDecorator cellData={data?.cell?.value} />,
  };
}

export default getReportTypeColumn;
