import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { camelCaseToCapitalCase } from '@replai-platform/ui-components';
// eslint-disable-next-line import/no-cycle
import { logEvent } from '../../analytics';
import { Page } from '../../utils/enums';

export const AVAILABLE_TABS = {
  ALL: 'all',
  GROWTH_OPPORTUNITIES: 'growth-opportunities',
  YOUR_OUTLIERS: 'your-outliers',
  TRENDING: 'trending',
  FAVOURITES: 'favourites',
} as const;
export type MarketTab = (typeof AVAILABLE_TABS)[keyof typeof AVAILABLE_TABS];

export type MarketState = {
  tagGallery: {
    selectedTagMenuOption: { type: string; kind?: string };
    currentTagsSubPage: number;
    currentTagsTab: MarketTab;
  };
  creativeGallery: {
    currentCreativesTablePage: number;
    currentVideoTab: MarketTab;
  };
  marketApps: {
    id: string;
    name: string;
    genreName: string;
  }[];
};

const initialState: MarketState = {
  tagGallery: {
    selectedTagMenuOption: { type: '', kind: undefined },
    currentTagsTab: AVAILABLE_TABS.GROWTH_OPPORTUNITIES,
    currentTagsSubPage: 1,
  },
  creativeGallery: {
    currentCreativesTablePage: 1,
    currentVideoTab: AVAILABLE_TABS.ALL,
  },
  marketApps: [],
};

export const MarketSlice = createSlice({
  name: 'Market',
  initialState,
  reducers: {
    reset: (state) => Object.assign(state, initialState),
    changeSelectedTagMenuOption: (state, action: PayloadAction<{ type: string; kind?: string; logEvent: boolean }>) => {
      state.tagGallery.selectedTagMenuOption = action.payload;
      if (action.payload.logEvent) {
        logEvent({
          component: `Market Tag Library`,
          action: 'Click Tag Type',
          category: 'user_actions',
          parameters: { page: Page.MarketTagsLibrary, type: action.payload.type ?? 'All', kind: action.payload.kind },
        });
      }
    },
    changeCurrentTagsSubPage: (state, action: PayloadAction<{ page: number; logEvent: boolean }>) => {
      state.tagGallery.currentTagsSubPage = action.payload.page;
      if (action.payload.logEvent) {
        logEvent({
          component: `Market Tag Library`,
          action: 'Change Page',
          category: 'user_actions',
          parameters: { page: Page.MarketTagsLibrary, pageNumber: action.payload.page },
        });
      }
    },
    changeCurrentCreativesTablePage: (state, action: PayloadAction<{ page: number; logEvent: boolean }>) => {
      state.creativeGallery.currentCreativesTablePage = action.payload.page;
      if (action.payload.logEvent) {
        logEvent({
          component: 'Market Videos Library',
          action: 'Change page',
          category: 'user_actions',
          parameters: { page: Page.MarketVideosLibrary, pageNumber: action.payload.page },
        });
      }
    },
    changeCurrentTagsTab: (state, action: PayloadAction<{ tab: MarketTab; logEvent: boolean }>) => {
      state.tagGallery.currentTagsTab = action.payload.tab;
      if (action.payload.logEvent) {
        logEvent({
          component: `Market Tag Library`,
          action: `Change Tab - ${camelCaseToCapitalCase(action.payload.tab)}`,
          category: 'user_actions',
          parameters: { page: Page.MarketTagsLibrary, tab: action.payload.tab },
        });
      }
    },
    changeCurrentVideoTab: (state, action: PayloadAction<{ tab: MarketTab; logEvent: boolean }>) => {
      state.creativeGallery.currentVideoTab = action.payload.tab;
      if (action.payload.logEvent) {
        logEvent({
          component: `Market Videos Library`,
          action: `Change Tab - ${camelCaseToCapitalCase(action.payload.tab)}`,
          category: 'user_actions',
          parameters: { page: Page.MarketVideosLibrary, tab: action.payload.tab },
        });
      }
    },
    changeMarketApps: (
      state,
      action: PayloadAction<{
        apps: {
          id: string;
          name: string;
          genreName: string;
        }[];
      }>
    ) => {
      state.marketApps = action.payload.apps;
    },
  },
});

export const MarketActions = MarketSlice.actions;
export const MarketReducer = MarketSlice.reducer;
export const MarketInitialState = initialState;
