/**
 * Types of recommendations.
 * This is a non-exhaustive enum, so recommendations with unknown types may be generated but should be discarded.
 */
export enum RecommendationType {
  BAD_TAG = 'BAD_TAG',
  BAD_TAG_INTRO = 'BAD_TAG_INTRO',
  CREATIVE_ADD_TAG = 'CREATIVE_ADD_TAG',
  CREATIVE_ADD_TAG_INTRO = 'CREATIVE_ADD_TAG_INTRO',
  CREATIVE_REMOVE_TAG = 'CREATIVE_REMOVE_TAG',
  CREATIVE_REMOVE_TAG_INTRO = 'CREATIVE_REMOVE_TAG_INTRO',
  CREATIVE_REPLACE_TAG = 'CREATIVE_REPLACE_TAG',
  CREATIVE_REPLACE_TAG_INTRO = 'CREATIVE_REPLACE_TAG_INTRO',
  GOOD_TAG = 'GOOD_TAG',
  GOOD_TAG_INTRO = 'GOOD_TAG_INTRO',
}

/**
 * Performance indicator
 */
export enum PerformanceIndicatorType {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
}

export enum OrderByCondition {
  ASC = 'ASC',
  ASC_NULLS_LAST = 'ASC NULLS LAST',
  DESC = 'DESC',
  DESC_NULLS_LAST = 'DESC NULLS LAST',
}

export enum ObjectLevel {
  ASSET = 'asset',
  CONCEPT = 'concept',
  TAG = 'tag',
}
