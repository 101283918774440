import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';
import { TwoColumnLayout } from '../shared/TwoColumnLayout';
import { VerticalLayout } from '../shared/VerticalLayout';

const MOLOCO_FORM_URL = 'https://docs.google.com/forms/d/1phlHt7J56PkQHoijzrMp4uiy3bGT6uIlFlH_cwzgtks/viewform';

export const MolocoConnectionGuide: NetworkConnectionGuideContent = {
  title: 'Moloco Connection Guide',
  icon: 'Moloco',
  guide: {
    label: 'Connection Guide',
    steps: [
      {
        title: 'Access Moloco',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <span>
                Login into {Shared.link({ label: 'Moloco', url: 'https://portal.moloco.cloud/signin' })} plaform
              </span>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/moloco/step-1.png"
          />
        ),
      },
      {
        title: 'Invite User (I)',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              {
                item: (
                  <>
                    Use the picker on the top-left corner to <Shared.BoldText>pick the ad account</Shared.BoldText> you
                    want to give Replai access to
                  </>
                ),
              },
              {
                item: (
                  <>
                    Open the account <Shared.BoldText>Settings</Shared.BoldText> on the top navigation and then select{' '}
                    <Shared.BoldText>Ad Account Users</Shared.BoldText> from the left navigation tab.
                  </>
                ),
              },
              {
                item: (
                  <>
                    Press the <Shared.BoldText>Invite</Shared.BoldText> button on the right.
                  </>
                ),
              },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/moloco/step-2-v2.png"
          />
        ),
      },
      {
        title: 'Invite User (II)',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              {
                item: (
                  <>
                    On the popup, enter in the email address:
                    <Shared.UList>
                      <Shared.UListItem>
                        <Shared.BoldText>
                          <Shared.NoWrap>moloco+[yourcompanyname]@replai.io</Shared.NoWrap>
                        </Shared.BoldText>
                      </Shared.UListItem>
                    </Shared.UList>
                  </>
                ),
              },
              { item: 'You can choose any name you want for the user (e.g. "Replai")' },
              {
                item: (
                  <>
                    Set <Shared.BoldText>Ad Account Viewer</Shared.BoldText> as the role
                  </>
                ),
              },
              {
                item: (
                  <>
                    Press the <Shared.BoldText>Invite</Shared.BoldText> button on the bottom right
                  </>
                ),
              },
              {
                item: (
                  <>
                    <Shared.BoldText>(Optional)</Shared.BoldText> Repeat steps 2-6 for every other ad account to which
                    Replai should have access
                  </>
                ),
              },
              {
                item: (
                  <>
                    Once you&apos;ve completed all the steps, fill out{' '}
                    {Shared.link({ label: 'this form', url: MOLOCO_FORM_URL })}.
                  </>
                ),
              },
              { item: 'Done!' },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/moloco/step-5.png"
          />
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: Shared.DEFAULT_NETWORK_FAQS,
  },
};
