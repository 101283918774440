/* eslint-disable @typescript-eslint/no-floating-promises */
import { Input, Button, Colors, Icons } from '@replai-platform/ui-components';
import {
  getCommonBounds,
  distance,
  viewportCoordsToSceneCoords,
  getGridPoint,
  getElementsWithinSelection,
  getCenterCoordinatesOfElement,
  constructImageElement,
  PLACEHOLDER_INPUT_ID_SUFFIX,
  sceneCoordsToViewportCoords,
  exportCanvas,
} from '@replai/replai-excalidraw';
import {
  ExcalidrawBindableElement,
  ExcalidrawElement,
  ExcalidrawTextElement,
  FileId,
  NonDeleted,
  NonDeletedExcalidrawElement,
} from '@replai/replai-excalidraw/types/element/types';
import { AppState, BinaryFileData, ExcalidrawImperativeAPI, LibraryItem } from '@replai/replai-excalidraw/types/types';
import { now } from 'lodash';
import {
  constructLibraryItem,
  GENERATE_BUTTON_TAG,
  GENERATIVE_FRAME_ELEMENT_TAG,
  genTechComponentHTMLElements,
  GEN_FRAME_SQUARE_SIZE,
  GEN_TECH_COMPONENT_BASE_GROUP_ID,
  GET_INSPIRED_BUTTON_TAG,
  INPUT_CONTAINER_TAG,
  PREVIOUS_VARIATION_BUTTON_TAG,
  NEXT_VARIATION_BUTTON_TAG,
} from './data';
import { GenericCanvasContainer } from './styles';
import { GeneratedImageVariation, GenTechComponent } from './types';
import { logEvent } from '../../analytics';

export const EVENT_COMPONENT = 'Inspire';

const GET_INSPIRED_PROMPT = 'engaging image';

const getMimeType = (b64: string) => {
  const signatures: Record<string, string> = {
    R0lGODdh: 'image/gif',
    R0lGODlh: 'image/gif',
    iVBORw0KGgo: 'image/png',
    '/9j/': 'image/jpeg',
  };
  for (const sign in signatures) {
    if (b64.startsWith(sign)) {
      return signatures[sign];
    }
  }
  return 'application/octet-stream';
};

/**
 * Adds elements to Canvas while maintaining the current elements untouched
 */
export const addElementsToCanvas = ({
  elements,
  excalidrawAPI,
}: {
  elements: readonly NonDeleted<ExcalidrawElement>[];
  excalidrawAPI: ExcalidrawImperativeAPI | null;
}) => {
  if (!excalidrawAPI) {
    return;
  }

  const currentElements = excalidrawAPI.getSceneElementsIncludingDeleted();
  excalidrawAPI.updateScene({
    elements: currentElements.concat(elements),
    commitToHistory: true,
  });
};

/**
 * Adds elements to center of another element
 */
export const addElementToCenterOfOtherElement = ({
  element,
  excalidrawAPI,
  parentElement,
}: {
  element: NonDeleted<ExcalidrawElement>;
  excalidrawAPI: ExcalidrawImperativeAPI | null;
  parentElement: ExcalidrawElement;
}) => {
  const { x, y } = getCenterCoordinatesOfElement({ element: parentElement });

  addElementsToCanvas({ elements: [{ ...element, x, y }], excalidrawAPI });
};

/**
 * Removes elements from Canvas
 */
export const removeElementsFromCanvas = ({
  elementIdsToRemove,
  excalidrawAPI,
}: {
  elementIdsToRemove: string[];
  excalidrawAPI: ExcalidrawImperativeAPI | null;
}) => {
  if (!excalidrawAPI) {
    return;
  }

  const currentElements = excalidrawAPI.getSceneElementsIncludingDeleted();
  excalidrawAPI.updateScene({
    elements: currentElements.filter(
      (currentElement) => !elementIdsToRemove.some((idToRemove) => currentElement.id === idToRemove)
    ),
  });
};

/**
 * Adds elements to the view point center
 */
export const addElementsToViewPointCenter = ({
  excalidrawAPI,
  elements,
}: {
  excalidrawAPI: ExcalidrawImperativeAPI | null;
  elements: readonly NonDeleted<ExcalidrawElement>[];
}) => {
  if (!excalidrawAPI) {
    return;
  }

  const state = excalidrawAPI.getAppState();

  const [minX, minY, maxX, maxY] = getCommonBounds(elements);

  const elementsCenterX = distance(minX, maxX) / 2;
  const elementsCenterY = distance(minY, maxY) / 2;

  const clientX = state.width / 2 + state.offsetLeft;
  const clientY = state.height / 2 + state.offsetTop;

  const { x, y } = viewportCoordsToSceneCoords({ clientX, clientY }, state);

  const dx = x - elementsCenterX;
  const dy = y - elementsCenterY;

  const [gridX, gridY] = getGridPoint(dx, dy, state.gridSize);

  const elementsToAdd = elements.map((element) => ({
    ...element,
    x: element.x + gridX - minX,
    y: element.y + gridY - minY,
  }));

  addElementsToCanvas({ elements: elementsToAdd, excalidrawAPI });
};

export const importImagesIntoTheLibrary = ({
  excalidrawAPI,
  images,
}: {
  excalidrawAPI: ExcalidrawImperativeAPI;
  images: { id: string; b64?: string; height: number; width: number }[];
}) => {
  const alreadyExistingFiles = excalidrawAPI.getFiles();
  const alreadyExistingFilesArray = Object.keys(alreadyExistingFiles).map((key) => alreadyExistingFiles[key]);

  const imagesArray = images.map((image) => {
    const mimeType = getMimeType(image?.b64 ?? '');
    const dataUrl = `data:${mimeType};base64,${image?.b64 ?? ''}`;
    return {
      id: image.id as BinaryFileData['id'],
      dataURL: dataUrl as BinaryFileData['dataURL'],
      mimeType: mimeType as BinaryFileData['mimeType'],
      created: now(),
      height: image.height,
      width: image.width,
    };
  });

  const nonExistingImages = imagesArray
    // filter out files that are already in the library
    .filter((image) => !alreadyExistingFilesArray.some((existingFile) => existingFile.dataURL === image.dataURL));

  if (excalidrawAPI) {
    excalidrawAPI.addFiles(imagesArray);
    const libraryItems: LibraryItem[] = nonExistingImages.map((image) =>
      constructLibraryItem({ fileId: image.id, height: image.height, width: image.width })
    );
    excalidrawAPI.updateLibrary({ libraryItems, merge: true });
  }
};

export const boundTextToElement = ({
  excalidrawAPI,
  parentElement,
  boundedElementId,
}: {
  excalidrawAPI: ExcalidrawImperativeAPI;
  parentElement: ExcalidrawBindableElement;
  boundedElementId: string;
}) => {
  const elementWithBind = {
    ...parentElement,
    boundElements: parentElement.boundElements
      ? parentElement.boundElements.concat({ id: boundedElementId, type: 'text' })
      : [{ id: boundedElementId, type: 'text' }],
  } as ExcalidrawBindableElement;

  removeElementsFromCanvas({ excalidrawAPI, elementIdsToRemove: [parentElement.id] });
  addElementsToCanvas({ excalidrawAPI, elements: [elementWithBind] });
};

const getGenerativeFrameFromGenTechComponentGroupId = ({
  groupId,
  excalidrawAPI,
}: {
  groupId: string;
  excalidrawAPI: ExcalidrawImperativeAPI;
}) => {
  const currentElements = excalidrawAPI.getSceneElements();
  return currentElements.find((el) => el.id === `${groupId}_${GENERATIVE_FRAME_ELEMENT_TAG}`);
};

export const cleanUpElementsInsideGenerativeFrame = ({
  generativeFrame,
  excalidrawAPI,
}: {
  generativeFrame: NonDeletedExcalidrawElement;
  excalidrawAPI: ExcalidrawImperativeAPI;
}) => {
  const currentElements = excalidrawAPI.getSceneElements();

  // all elements inside generative frame excluding the generative frame itself
  const elementsInsideGenerativeFrame = getElementsWithinSelection(currentElements, generativeFrame).filter(
    (element) => !element.id.includes(GENERATIVE_FRAME_ELEMENT_TAG)
  );

  removeElementsFromCanvas({
    excalidrawAPI,
    elementIdsToRemove: elementsInsideGenerativeFrame.map((element) => element.id),
  });
};

export const resetGenTechComponent = ({
  excalidrawAPI,
  genTechComponentGroupId,
  generatedImageVariations,
  setGeneratedImageVariations,
}: {
  excalidrawAPI: ExcalidrawImperativeAPI;
  genTechComponentGroupId: string;
  generatedImageVariations: GeneratedImageVariation[];
  setGeneratedImageVariations: React.Dispatch<React.SetStateAction<GeneratedImageVariation[]>>;
}) => {
  const parentGenerativeFrame = getGenerativeFrameFromGenTechComponentGroupId({
    excalidrawAPI,
    groupId: genTechComponentGroupId,
  });

  if (!parentGenerativeFrame) {
    return;
  }

  cleanUpElementsInsideGenerativeFrame({ excalidrawAPI, generativeFrame: parentGenerativeFrame });

  setGeneratedImageVariations([
    // cleaning up previous variations from the variation tracker
    ...generatedImageVariations.filter((variation) => variation.genTechComponentGroupId !== genTechComponentGroupId),
  ]);

  const currentElements = excalidrawAPI.getSceneElementsIncludingDeleted();
  const promptContainerId = `${genTechComponentGroupId}_${INPUT_CONTAINER_TAG}`;
  const promptTextElement = currentElements.find(
    (el) => (el as { containerId: string }).containerId === promptContainerId && el.type === 'text'
  ) as ExcalidrawTextElement;

  const placeholderElement = currentElements.find(
    (currentElement) =>
      currentElement.id === `${genTechComponentGroupId}_${INPUT_CONTAINER_TAG}${PLACEHOLDER_INPUT_ID_SUFFIX}`
  );

  if (!promptTextElement || !placeholderElement) {
    return;
  }

  removeElementsFromCanvas({
    elementIdsToRemove: [promptTextElement.id, placeholderElement.id],
    excalidrawAPI,
  });

  // restoring placeholder element
  addElementsToCanvas({ elements: [placeholderElement], excalidrawAPI });
};

export const fillGenerativeFrameWithGeneratedImage = ({
  generativeFrame,
  imageFileId,
  excalidrawAPI,
}: {
  generativeFrame: NonDeletedExcalidrawElement;
  imageFileId: string;
  excalidrawAPI: ExcalidrawImperativeAPI;
}) => {
  if (!generativeFrame || !excalidrawAPI || !imageFileId) {
    return;
  }

  cleanUpElementsInsideGenerativeFrame({ generativeFrame, excalidrawAPI });

  addElementsToCanvas({
    elements: [
      constructImageElement({
        propsToOverride: {
          fileId: imageFileId as FileId,
          x: generativeFrame?.x,
          y: generativeFrame?.y,
          height: generativeFrame?.height,
          width: generativeFrame?.width,
        },
      }),
    ],
    excalidrawAPI,
  });
};

export const isThereAnyGeneratedImageInsideGenTechComponent = ({
  excalidrawAPI,
  genTechComponentGroupId,
}: {
  excalidrawAPI: ExcalidrawImperativeAPI;
  genTechComponentGroupId: string;
}) => {
  const parentGenerativeFrame = getGenerativeFrameFromGenTechComponentGroupId({
    excalidrawAPI,
    groupId: genTechComponentGroupId,
  });

  if (!parentGenerativeFrame) {
    return false;
  }
  const currentElements = excalidrawAPI.getSceneElements();

  const elementsInsideGenerativeFrame = getElementsWithinSelection(currentElements, parentGenerativeFrame);

  return elementsInsideGenerativeFrame
    .filter((element) => element.type === 'image')
    .some((image) => image.width === GEN_FRAME_SQUARE_SIZE && image.height === GEN_FRAME_SQUARE_SIZE);
};

export const renderDownloadButton = ({
  imageElement,
  excalidrawAPI,
}: {
  imageElement: ExcalidrawElement;
  excalidrawAPI: ExcalidrawImperativeAPI | null;
}) => {
  const minimalImageSizeForButtonToRender = 80;
  const canvasZoom = excalidrawAPI?.getAppState().zoom.value;

  const imageElementWidthAsSeenOnCanvas = imageElement.width * (canvasZoom ?? 1);
  const imageElementHeightAsSeenOnCanvas = imageElement.height * (canvasZoom ?? 1);

  if (
    !excalidrawAPI ||
    imageElementWidthAsSeenOnCanvas < minimalImageSizeForButtonToRender ||
    imageElementHeightAsSeenOnCanvas < minimalImageSizeForButtonToRender
  ) {
    return undefined;
  }

  const appState = excalidrawAPI.getAppState();
  const { x, y } = sceneCoordsToViewportCoords(
    { sceneX: imageElement.x, sceneY: imageElement.y },
    excalidrawAPI.getAppState()
  );

  const paddingTop = 50;
  const paddingLeft = 32;

  return (
    <GenericCanvasContainer
      id={imageElement.id}
      key={imageElement.id}
      top={y - appState.offsetTop + imageElementHeightAsSeenOnCanvas - paddingTop}
      left={x - appState.offsetLeft + imageElementWidthAsSeenOnCanvas + paddingLeft}
      scale={appState.zoom?.value ?? 1}
      onClick={() => {
        logEvent({
          action: 'Download image',
          component: EVENT_COMPONENT,
        });
        exportCanvas('png', [imageElement], appState, excalidrawAPI.getFiles(), {
          ...appState,
          exportBackground: false,
          exportPadding: 0,
        });
      }}
    >
      <Button data-test="inspire-button-download" leadingIcon={{ name: 'Download' }} variant="regular" color="Gray" />
    </GenericCanvasContainer>
  );
};

const calculateSizesForReactComponentOnGenerativeComponent = ({
  appState,
  generativeFrame,
  topOffset,
  leftOffset,
}: {
  appState: Readonly<AppState>;
  generativeFrame: ExcalidrawElement;
  topOffset: number;
  leftOffset: number;
}) => {
  const { x, y } = sceneCoordsToViewportCoords(
    { sceneX: generativeFrame.x + leftOffset, sceneY: generativeFrame.y + topOffset },
    appState
  );

  return {
    left: x,
    top: y,
  };
};

export const renderReactComponentOnGenerativeComponent = ({
  component,
  componentTag,
  generativeFrameElement,
  excalidrawAPI,
  width,
  height,
  topOffset,
  leftOffset,
  disablePointerEvents,
}: {
  component: JSX.Element;
  componentTag: string;
  generativeFrameElement: ExcalidrawElement;
  excalidrawAPI: ExcalidrawImperativeAPI | null;
  width: number;
  height: number;
  topOffset: number;
  leftOffset: number;
  disablePointerEvents?: boolean;
}) => {
  if (!excalidrawAPI) {
    return undefined;
  }

  const appState = excalidrawAPI.getAppState();

  const { left, top } = calculateSizesForReactComponentOnGenerativeComponent({
    appState,
    generativeFrame: generativeFrameElement,
    topOffset,
    leftOffset,
  });

  return (
    <GenericCanvasContainer
      id={`${generativeFrameElement.id}_${componentTag}`}
      key={`${generativeFrameElement.id}_${componentTag}`}
      className={componentTag}
      top={top}
      left={left}
      height={height}
      scale={appState.zoom?.value ?? 1}
      width={width}
      disablePointerEvents={disablePointerEvents}
    >
      {component}
    </GenericCanvasContainer>
  );
};

export const renderReactComponentsOnGenerativeComponent = ({
  genTechComponent,
  excalidrawAPI,
  onGenerateClick,
  onChangeVariationClick,
  promptsOnScene,
  setPromptsOnScene,
}: {
  genTechComponent: GenTechComponent;
  excalidrawAPI: ExcalidrawImperativeAPI | null;
  onGenerateClick: (element: NonDeleted<ExcalidrawElement>, prompt?: string) => void;
  onChangeVariationClick: (element: NonDeleted<ExcalidrawElement>, increment: number) => void;
  promptsOnScene: Map<string, string>;
  setPromptsOnScene: React.Dispatch<React.SetStateAction<Map<string, string>>>;
}) => {
  if (!excalidrawAPI) {
    return undefined;
  }

  const generativeFrameElement = excalidrawAPI
    .getSceneElementsIncludingDeleted()
    .find((el) => el.id === `${genTechComponent.id}_${GENERATIVE_FRAME_ELEMENT_TAG}`);

  if (!generativeFrameElement) {
    return undefined;
  }

  const generateButton = renderReactComponentOnGenerativeComponent({
    component: (
      <Button
        data-test="inspire-button-generate-image"
        key={`${genTechComponent.id}_${GENERATE_BUTTON_TAG}_component`}
        onClick={() => {
          onGenerateClick(generativeFrameElement);
          logEvent({
            action: 'Generate image',
            component: EVENT_COMPONENT,
            parameters: {
              prompt,
            },
          });
        }}
        size="parent"
        enableTransitions={false}
        disabled={!promptsOnScene.get(genTechComponent.id) || genTechComponent.loading}
      >
        Generate
      </Button>
    ),
    generativeFrameElement,
    excalidrawAPI,
    ...genTechComponentHTMLElements.GENERATE_BUTTON,
  });

  const promptInput = renderReactComponentOnGenerativeComponent({
    component: (
      <Input
        data-test="inspire-prompt-input"
        key={`${genTechComponent.id}_${INPUT_CONTAINER_TAG}_component`}
        style={{ backgroundColor: Colors.Common.White }}
        fullHeight
        fullWidth
        placeholder="Write your prompt here..."
        parentFontSize
        enableTransitions={false}
        value={promptsOnScene.get(genTechComponent.id) ?? ''}
        onChange={(event) => {
          setPromptsOnScene((prevValues) => {
            const mapClone = new Map(prevValues);
            return mapClone.set(genTechComponent.id, event.target.value);
          });
        }}
      />
    ),
    generativeFrameElement,
    excalidrawAPI,
    ...genTechComponentHTMLElements.PROMPT_INPUT,
  });

  let loadingComponent;

  if (genTechComponent.loading) {
    loadingComponent = renderReactComponentOnGenerativeComponent({
      component: (
        <div data-test="inspire-generate-image-loading-indicator">
          {Icons.MiscIcons.LoadingCircle({ color: Colors.Primary[400], dimension: '100%' })}
        </div>
      ),
      generativeFrameElement,
      excalidrawAPI,
      ...genTechComponentHTMLElements.LOADING_ICON,
    });
  }

  const getInspiredButton = renderReactComponentOnGenerativeComponent({
    component: (
      <Button
        data-test="inspire-button-get-inspired"
        key={`${genTechComponent.id}_${GET_INSPIRED_BUTTON_TAG}_component`}
        onClick={() => {
          setPromptsOnScene((prevValues) => {
            const mapClone = new Map(prevValues);
            return mapClone.set(genTechComponent.id, GET_INSPIRED_PROMPT);
          });
          onGenerateClick(generativeFrameElement, GET_INSPIRED_PROMPT);
          logEvent({
            action: 'Get Inspired',
            component: EVENT_COMPONENT,
          });
        }}
        size="parent"
        enableTransitions={false}
        color="Success"
      >
        Get Inspired
      </Button>
    ),
    generativeFrameElement,
    excalidrawAPI,
    ...genTechComponentHTMLElements.GET_INSPIRED_BUTTON,
  });

  const previousVariationButton = renderReactComponentOnGenerativeComponent({
    component: (
      <Button
        data-test="inspire-button-previous-variation"
        key={`${genTechComponent.id}_${PREVIOUS_VARIATION_BUTTON_TAG}_component`}
        leadingIcon={{
          name: 'ArrowLeft',
        }}
        onClick={() => {
          onChangeVariationClick(generativeFrameElement, -1);
          logEvent({
            action: `View previous variation`,
            component: EVENT_COMPONENT,
          });
        }}
        variant="light"
        size="md"
        enableTransitions={false}
        color="Primary"
      />
    ),
    generativeFrameElement,
    excalidrawAPI,
    ...genTechComponentHTMLElements.PREVIOUS_VARIATION_BUTTON,
  });

  const nextVariationButton = renderReactComponentOnGenerativeComponent({
    component: (
      <Button
        data-test="inspire-button-next-variation"
        key={`${genTechComponent.id}_${NEXT_VARIATION_BUTTON_TAG}_component`}
        leadingIcon={{
          name: 'ArrowRight',
        }}
        onClick={() => {
          onChangeVariationClick(generativeFrameElement, 1);
          logEvent({
            action: `View next variation`,
            component: EVENT_COMPONENT,
          });
        }}
        variant="light"
        size="md"
        enableTransitions={false}
        color="Primary"
      />
    ),
    generativeFrameElement,
    excalidrawAPI,
    ...genTechComponentHTMLElements.NEXT_VARIATION_BUTTON,
  });

  return (
    <>
      {generateButton} {promptInput} {loadingComponent} {previousVariationButton} {nextVariationButton}
      {promptsOnScene.get(genTechComponent.id)?.length ? '' : getInspiredButton}
    </>
  );
};

export const rerenderReactComponentTypeOnCanvas = ({
  componentTag,
  excalidrawAPI,
  width,
  height,
  topOffset,
  leftOffset,
  appRef,
}: {
  componentTag: string;
  excalidrawAPI: ExcalidrawImperativeAPI | null;
  width: number;
  height: number;
  topOffset: number;
  leftOffset: number;
  appRef?: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  if (!excalidrawAPI || !appRef?.current) {
    return undefined;
  }

  const appState = excalidrawAPI.getAppState();

  const elements = appRef.current.getElementsByClassName(componentTag) as unknown as HTMLElement[];

  for (const element of elements) {
    const generativeFrame = excalidrawAPI
      .getSceneElements()
      .find((el) => el.id === element.id.replace(`_${componentTag}`, ''));

    if (!generativeFrame) {
      return undefined;
    }

    const { left, top } = calculateSizesForReactComponentOnGenerativeComponent({
      appState,
      generativeFrame,
      topOffset,
      leftOffset,
    });

    element.style.top = `${top}px`;
    element.style.left = `${left}px`;
    element.style.height = `${height}px`;
    element.style.width = `${width}px`;
    element.style.transform = `scale(${appState.zoom.value})`;
    element.style.transformOrigin = 'top left';
  }

  return undefined;
};

export const rerenderReactComponentsOnCanvas = ({
  excalidrawAPI,
  appRef,
}: {
  excalidrawAPI: ExcalidrawImperativeAPI | null;
  appRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  if (!excalidrawAPI || !appRef?.current) {
    return;
  }

  const components = Object.keys(genTechComponentHTMLElements).map(
    (componentName) => genTechComponentHTMLElements[componentName]
  );

  components.forEach((component) => {
    rerenderReactComponentTypeOnCanvas({ excalidrawAPI, appRef, ...component });
  });
};

export const getNextGenTechComponentGroupId = ({
  excalidrawAPI,
}: {
  excalidrawAPI: ExcalidrawImperativeAPI | null;
}) => {
  const uniqueGroupIds = [
    ...new Set(
      excalidrawAPI
        ?.getSceneElementsIncludingDeleted()
        .map((el) => el.groupIds[0])
        .filter((groupId) => !!groupId)
    ),
  ];

  const maxGenTechSuffix =
    uniqueGroupIds.length === 0
      ? -1
      : Math.max(...uniqueGroupIds.map((groupId) => Number(groupId.replace(GEN_TECH_COMPONENT_BASE_GROUP_ID, ''))));

  return `${GEN_TECH_COMPONENT_BASE_GROUP_ID}${maxGenTechSuffix + 1}`;
};
