import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getTypes = async (requestParams: SDK.GetTagsTypesRequest): Promise<SDK.GetTagsTypesResponse> =>
  provider.getJson<object, SDK.GetTagsTypesResponse>(
    `${SDK.BASE_URL()}/tags/types?${SDK.convertRequestToQueryParams(requestParams)}`
  );

export default function useTagTypes<R = SDK.GetTagsTypesResponse>(
  requestParams: SDK.GetTagsTypesRequest,
  options?: UseQueryOptions<SDK.GetTagsTypesResponse, unknown, R>
) {
  return useQuery(['tag-types', requestParams], () => getTypes(requestParams), options);
}
