export const pageTransition = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

export const tableTransition = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0.3,
  },
};

export const loaderTransition = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};
