import * as SDK from '@replai-platform/sdk';
import Colors from '../Colors';

export const getIconInnerContainerBgColor = (
  recommendationType: SDK.RecommendationType
): string => {
  switch (recommendationType) {
    case SDK.RecommendationType.CREATIVE_REPLACE_TAG:
    case SDK.RecommendationType.CREATIVE_REPLACE_TAG_INTRO:
      return Colors.Primary[100];
    case SDK.RecommendationType.CREATIVE_ADD_TAG:
    case SDK.RecommendationType.CREATIVE_ADD_TAG_INTRO:
      return Colors.Success[100];
    case SDK.RecommendationType.CREATIVE_REMOVE_TAG:
    case SDK.RecommendationType.CREATIVE_REMOVE_TAG_INTRO:
      return Colors.Error[100];
    default:
      return Colors.Common.White;
  }
};
