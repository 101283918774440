import { CountryIconProps } from './types';

const SR = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3763)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.2536 7.82684H0.746344C0.264047 9.12687 0 10.5329 0 12.0007C0 13.4686 0.264047 14.8747 0.746344 16.1746H23.2536C23.736 14.8747 24 13.4686 24 12.0007C24 10.5329 23.736 9.12687 23.2536 7.82684Z"
        fill="#A2001D"
      />
      <path
        d="M12.0345 24.0012C16.1446 24.0012 19.7716 21.9344 21.9343 18.7838H2.13477C4.29748 21.9344 7.92439 24.0012 12.0345 24.0012Z"
        fill="#6DA544"
      />
      <path
        d="M12.0345 0.000305176C16.1446 0.000305176 19.7716 2.06712 21.9343 5.21768H2.13477C4.29748 2.06712 7.92439 0.000305176 12.0345 0.000305176Z"
        fill="#6DA544"
      />
      <path
        d="M11.9991 7.82684L13.0351 11.0153H16.3878L13.6754 12.9861L14.7115 16.1746L11.9991 14.204L9.28665 16.1746L10.3228 12.9861L7.61035 11.0153H10.9631L11.9991 7.82684Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3763">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SR;
