import styled from 'styled-components';
import Colors from '../Colors';

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.Gray[50]};
  width: 70%;
`;

export const SidebarContainer = styled.div`
  display: flex;
  border-right: 1px solid ${Colors.Gray[200]};
  width: 30%;
`;

export const Container = styled.div`
  display: flex;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border: 1px solid ${Colors.Gray[200]};
  border-radius: 0.5rem;
`;

export const TimelineTimestampContainer = styled.div`
  padding: 0.75rem 1.5rem;
  background-color: ${Colors.Gray[50]};
  border-bottom: 1px solid ${Colors.Gray[200]};
  height: 3rem;
`;

export const TimelineBodyContainer = styled.div<{ withPaddingTop: boolean }>`
  padding-top: ${({ withPaddingTop }) => (withPaddingTop ? '5.5rem' : '1rem')};
  padding-left: 1rem;
`;
