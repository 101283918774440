import { useMutation } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const patchConceptDisplayName = async (requestParams: SDK.UpdateConceptDisplayNameRequest) => {
  const url = `${SDK.BASE_URL()}/concepts/${requestParams.conceptId}/displayName?${SDK.convertRequestToQueryParams({
    projectId: requestParams.projectId,
  })}`;
  const res = await provider.getJson(url, { method: 'PATCH', body: { newDisplayName: requestParams.newDisplayName } });
  return res;
};

export default function usePatchConceptDisplayName() {
  return useMutation(['concept-display-name'], (requestParams: SDK.UpdateConceptDisplayNameRequest) =>
    patchConceptDisplayName(requestParams)
  );
}
