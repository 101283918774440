/* eslint-disable @typescript-eslint/no-unsafe-argument */
function buildFilteringWhereClause(initialWhere: any | any[]): string[] {
  const where = [] as string[];
  if (Array.isArray(initialWhere)) {
    where.push(...initialWhere);
  } else {
    where.push(initialWhere);
  }
  return where;
}

export default buildFilteringWhereClause;
