/* eslint-disable react/jsx-props-no-spreading */
import { BadgeGroup, Button, ButtonProps, Colors, ColorTypes } from '../';
import { Container, RightAlignedButtonGroup, Message } from './styles';

export interface NotificationProps {
  badgeLabel: string;
  badgeTitle: string;
  color?: ColorTypes;
  message?: string;
  buttons?: ButtonProps[];
}

const Notification = ({
  badgeLabel,
  badgeTitle,
  message,
  buttons,
  color = 'Primary',
}: NotificationProps) => {
  const colorBase = Colors[color];
  return (
    <Container backgroundColor={colorBase[25]} borderColor={colorBase[300]}>
      <BadgeGroup color={color} title={badgeTitle}>
        {badgeLabel}
      </BadgeGroup>
      <Message color={colorBase[600]} type="text-sm" noMargin>
        {message}
      </Message>
      <RightAlignedButtonGroup>
        {buttons &&
          buttons.map((button, index) => (
            <Button key={index} {...button} color={color} />
          ))}
      </RightAlignedButtonGroup>
    </Container>
  );
};

export default Notification;
