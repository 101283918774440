import { CountryIconProps } from './types';

const TC = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C0 12.0029 12 0.0013125 12 0C18.6274 0 24 5.37262 24 12Z"
      fill="#0052B4"
    />
    <path
      d="M11.9688 11.9996H12.0009C12.0009 11.9888 12.0009 11.9783 12.0009 11.9674C11.9902 11.9782 11.9795 11.9889 11.9688 11.9996Z"
      fill="#F0F0F0"
    />
    <path
      d="M12.001 6.26123C12.001 4.14974 12.001 2.76623 12.001 0.000366211H11.999C5.37243 0.00149121 0.000976562 5.3736 0.000976562 12.0004H6.26184V8.47485L9.78735 12.0004H11.9689C11.9796 11.9897 11.9903 11.979 12.001 11.9683C12.001 11.1598 12.001 10.4384 12.001 9.78683L8.47541 6.26123H12.001Z"
      fill="#F0F0F0"
    />
    <path
      d="M6.07124 1.56543C4.19441 2.63413 2.63409 4.19441 1.56543 6.07124V12.0002H4.69588V4.69598V4.69588H12.0002C12.0002 3.70856 12.0002 2.76796 12.0002 1.56543H6.07124Z"
      fill="#D80027"
    />
    <path
      d="M12.0009 10.5242L7.73734 6.26074H6.26172V6.26084L12.0008 11.9998H12.0009C12.0009 11.9998 12.0009 10.9823 12.0009 10.5242Z"
      fill="#D80027"
    />
    <path
      d="M13.5654 6.26038V11.9995C13.5654 14.7951 17.2176 15.6517 17.2176 15.6517C17.2176 15.6517 20.8698 14.7951 20.8698 11.9995V6.26038H13.5654Z"
      fill="#FFDA44"
    />
    <path
      d="M16.6954 8.34768C16.6954 8.92401 16.2282 10.4347 15.6519 10.4347C15.0756 10.4347 14.6084 8.92401 14.6084 8.34768C14.6084 7.77136 15.6519 7.3042 15.6519 7.3042C15.6519 7.3042 16.6954 7.77136 16.6954 8.34768Z"
      fill="#FF9811"
    />
    <path
      d="M19.4642 9.48417C19.6326 9.07331 19.7636 8.47275 19.7636 8.17378C19.7636 7.6935 19.1374 7.3042 19.1374 7.3042C19.1374 7.3042 18.5113 7.69354 18.5113 8.17378C18.5113 8.47275 18.6423 9.07331 18.8107 9.48417L18.4492 10.3003C18.6617 10.3866 18.8939 10.4346 19.1374 10.4346C19.3809 10.4346 19.6131 10.3866 19.8256 10.3003L19.4642 9.48417Z"
      fill="#A2001D"
    />
    <path
      d="M16.4348 12C16.4348 12 15.9131 13.0435 15.9131 14.087C15.9131 14.087 17.4783 14.087 18.5218 14.087C18.5217 13.0435 18 12 18 12L17.2174 11.4783L16.4348 12Z"
      fill="#6DA544"
    />
    <path
      d="M18.0008 12.0005V11.7397C18.0008 11.3075 17.6504 10.957 17.2182 10.957C16.786 10.957 16.4355 11.3074 16.4355 11.7397V12.0005H18.0008Z"
      fill="#D80027"
    />
  </svg>
);
export default TC;
