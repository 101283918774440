import { CountryIconProps } from './types';

const CY = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#FCFCFC"
    />
    <path
      d="M18.7829 10.4344C18.4177 10.4344 17.9655 10.4344 17.2177 10.4344C17.2177 13.316 14.8818 15.6518 12.0004 15.6518C9.1188 15.6518 6.78298 13.316 6.78298 10.4344C6.2786 10.4344 5.72205 10.4344 5.21777 10.4344C5.21777 13.5383 7.30259 16.1552 10.1484 16.9611C9.83637 17.5538 9.8839 18.3 10.3296 18.8562C10.915 18.387 11.521 17.9013 12.0351 17.4894C12.5493 17.9014 13.1553 18.387 13.7407 18.8562C14.1908 18.2945 14.2351 17.5388 13.9127 16.9432C16.7273 16.1175 18.7829 13.5162 18.7829 10.4344Z"
      fill="#6DA544"
    />
    <path
      d="M7.82617 9.91286C7.82617 9.91286 7.82617 12.5215 10.4349 12.5215L10.9566 13.0433H12.0001C12.0001 13.0433 12.5218 11.4781 13.5653 11.4781C13.5653 11.4781 13.5653 10.4346 14.6088 10.4346C15.6522 10.4346 16.174 10.4346 16.174 10.4346C16.174 10.4346 15.6522 8.34761 18.2609 6.7824L17.2174 6.26068C17.2174 6.26068 13.5653 8.86937 10.9566 8.34765V9.39114H9.91309L9.39138 8.86942L7.82617 9.91286Z"
      fill="#FFDA44"
    />
  </svg>
);
export default CY;
