import * as SDK from '@replai-platform/sdk';
import * as provider from './provider';

/**
 * Get concepts count
 */
export function getCount(request: SDK.GetConceptsCountRequest): Promise<SDK.GetConceptsCountResponse> {
  return provider.getJson(`${SDK.BASE_URL()}/concepts/count?${SDK.convertRequestToQueryParams(request)}`);
}

/**
 * Gets average age of concepts
 */
export function getAverageAge(request: SDK.GetConceptsAverageAgeRequest): Promise<SDK.GetConceptsAverageAgeResponse> {
  return provider.getJson(`${SDK.BASE_URL()}/concepts/averageAge?${SDK.convertRequestToQueryParams(request)}`);
}

/**
 * Gets hero concepts count
 */
export function getHeroCount(request: SDK.GetHeroConceptsCountRequest): Promise<SDK.GetHeroConceptsCountResponse> {
  return provider.getJson(`${SDK.BASE_URL()}/concepts/heroCount?${SDK.convertRequestToQueryParams(request)}`);
}

/**
 * Get concept performance
 * @param {GetAssetsMetricsRequest} request request params
 * @param {UUID} conceptId ID of the asset
 * @returns promise that fetches the data.
 */
export function getConceptPerformance(conceptId: SDK.UUID, request: SDK.GetAssetsMetricsRequest): Promise<any> {
  return provider.getJson(
    `${SDK.BASE_URL()}/concepts/${conceptId}/performance?${SDK.convertRequestToQueryParams(request)}`
  );
}

/**
 * Get concept preview info (thumbnail and video URL)
 * @param {String} projectId ID of the project
 * @param {String} conceptId ID of the concept
 * @param {String} size size of the thumbnail
 * @returns promise that fetches the data.
 */
export async function getConceptPreviewInfo({
  projectId,
  conceptId,
  size,
}: {
  projectId: string;
  conceptId: string;
  size: string;
}): Promise<SDK.GetConceptPreviewInfoResponse | null> {
  if (!conceptId) {
    return null;
  }
  return provider.getJson(
    `${SDK.BASE_URL()}/concepts/${conceptId}/preview?projects=${projectId}${size ? `&size=${size}` : ''}`
  );
}

/**
 * Get concept tags
 * @param {String} conceptId ID of the concept
 */
export async function getConceptTags({
  projectId,
  conceptId,
}: {
  projectId: SDK.UUID;
  conceptId: SDK.UUID;
}): Promise<any> {
  const url = `${SDK.BASE_URL()}/concepts/${conceptId}/tags?projectIds=${projectId}`;
  return provider.getJson(url);
}

/**
 * Update concept display name
 * @param {String} conceptId ID of the concept
 */
export async function updateDisplayName({ projectId, conceptId, newDisplayName }: SDK.UpdateConceptDisplayNameRequest) {
  const url = `${SDK.BASE_URL()}/concepts/${conceptId}/displayName?${SDK.convertRequestToQueryParams({
    projectId,
  })}`;
  return provider.getJson(url, { method: 'PATCH', body: { newDisplayName } });
}
