/**
 * Number of consecutive days a creative can go without metrics for it
 * to still be considered active
 */
const ACTIVE_CREATIVE_WITHOUT_METRICS_DAYS = 2;

/**
 * Given the last metric date from a creative (@param inputDate) and a reference date (@param referenceDate),
 * it checks if the given creative is still active or not
 */
export const isCreativeActive = (inputDate: string, referenceDate: string): boolean => {
  const referenceDateObj = new Date(referenceDate);
  const inputDateObj = new Date(inputDate);

  referenceDateObj.setDate(referenceDateObj.getDate() - ACTIVE_CREATIVE_WITHOUT_METRICS_DAYS);
  referenceDateObj.setUTCHours(0, 0, 0, 0);

  return inputDateObj >= referenceDateObj;
};
