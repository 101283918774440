import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';
import { TwoColumnLayout } from '../shared/TwoColumnLayout';
import { VerticalLayout } from '../shared/VerticalLayout';

const APPLOVIN_FORM_URL = 'https://docs.google.com/forms/d/1UAe2KNuI_VK1M25qobENdQDtLbst2dFGuajHnPwWzMc/viewform';

export const ApplovinConnectionGuide: NetworkConnectionGuideContent = {
  title: 'AppLovin Connection Guide',
  icon: 'AppLovin',
  guide: {
    label: 'Connection Guide',
    steps: [
      {
        title: 'Retrieve Reporting Key',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              {
                item: <>Login into {Shared.link({ label: 'AppLovin', url: 'https://dash.applovin.com/login' })}</>,
              },
              {
                item: (
                  <>
                    On the right menu, click on: <Shared.BoldText>{'Account -> Keys'}</Shared.BoldText>
                  </>
                ),
              },
              {
                item: (
                  <>
                    Copy the <Shared.BoldText>Reporting Key</Shared.BoldText>
                  </>
                ),
              },
              {
                item: (
                  <>
                    Share the key with Replai using {Shared.link({ label: 'this form', url: APPLOVIN_FORM_URL })}. Note
                    that other keys will be required later in this guide so don&apos;t submit the form yet.
                  </>
                ),
              },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/applovin/step-1.png"
          />
        ),
      },
      {
        title: 'New OAuth App (I)',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                On the right menu again, go to <Shared.BoldText>{'Account -> Manage -> OAuth Apps'}</Shared.BoldText>{' '}
                and click on <Shared.BoldText>Create New</Shared.BoldText>
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/applovin/step-2.1.png"
          />
        ),
      },
      {
        title: 'New OAuth App (II)',
        description: '',
        content: (
          <VerticalLayout
            contentDescription="Make sure you fill the information on the form according to the picture below."
            imageSrc="https://cdn.replai.io/assets/integration-guide/applovin/step-2.2.png"
          />
        ),
      },
      {
        title: 'Send Keys to Replai',
        description: '',
        content: (
          <TwoColumnLayout
            contentDescription="Now, we need to send Replai the previously retrieved/generated keys"
            contentItems={[
              {
                item: (
                  <>
                    Click <Shared.ItalicText>Edit</Shared.ItalicText> on the previously created{' '}
                    <Shared.BoldText>OAuth Application</Shared.BoldText> (if edit option is not shown, clean on the{' '}
                    <Shared.ItalicText>Application Name</Shared.ItalicText> you gave it)
                  </>
                ),
              },
              {
                item: (
                  <>
                    Copy both <Shared.BoldText>Client ID</Shared.BoldText> and{' '}
                    <Shared.BoldText>Client Secret</Shared.BoldText> (picture on the right)
                  </>
                ),
              },
              {
                item: (
                  <>
                    Add the three retrieved/generated keys to the form previously opened:
                    <Shared.UList style={{ paddingTop: '1rem' }}>
                      <Shared.UListItem>
                        <Shared.BoldText>Retrieve Reporting Key</Shared.BoldText>
                      </Shared.UListItem>
                      <Shared.UListItem>
                        <Shared.BoldText>Client ID</Shared.BoldText>
                      </Shared.UListItem>
                      <Shared.UListItem>
                        <Shared.BoldText>Client Secret</Shared.BoldText>
                      </Shared.UListItem>
                    </Shared.UList>
                  </>
                ),
              },
              {
                item: <>Done!</>,
              },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/applovin/step-2.3.png"
          />
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: [
      {
        title: 'Which AppLovin APIs does Replai use?',
        description: (
          <Shared.P>
            {"Replai uses AppLovin's "}
            {Shared.link({
              label: 'Reporting API',
              url: 'https://support.applovin.com/hc/en-us/articles/115000784688-Reporting-API',
            })}{' '}
            and{' '}
            {Shared.link({
              label: 'Campaign Management API',
              url: 'https://support.applovin.com/hc/en-us/articles/12336314516237-Campaign-Management-API',
            })}
            .
          </Shared.P>
        ),
      },
      {
        title: 'Which metrics are supported on AppLovin?',
        description: (
          <Shared.P>Metrics supported include: Spend, Clicks, Impressions, Installs, Purchases, Purchases D7.</Shared.P>
        ),
      },
      ...Shared.DEFAULT_NETWORK_FAQS,
    ],
  },
};
