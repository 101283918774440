import { PaymentIconProps } from './types';

const DinersClub = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.118 25.7333H24.5794C29.9827 25.7333 34.2884 21.4466 34.4572 16.1513V16.0672C34.3728 10.6878 29.9827 6.3171 24.5794 6.40116H20.6959C15.5459 6.56926 11.4935 10.94 11.6623 16.1513C11.6623 21.3626 15.8836 25.6492 21.118 25.7333ZM13.3508 16.0672C13.3508 11.6964 16.9811 8.08217 21.3713 8.08217C25.7614 8.08217 29.3917 11.6964 29.3917 16.0672C29.3917 20.4379 25.7614 24.0522 21.3713 24.0522C16.9811 24.0522 13.3508 20.4379 13.3508 16.0672ZM22.6377 21.4466V10.7719C25.5925 11.5283 27.4499 14.4702 26.6901 17.412C26.1835 19.4293 24.6639 20.9423 22.6377 21.4466ZM16.1369 14.7224C15.377 17.6642 17.15 20.6901 20.1049 21.4466V10.7719C18.1631 11.2762 16.6434 12.7891 16.1369 14.7224Z"
      fill="#0079BE"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default DinersClub;
