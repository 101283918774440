import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getLockedFeaturesUserInterest = async (requestParams: SDK.GetLockedFeatureUserInterestRequest) => {
  const res = await provider.getJson<object, SDK.GetLockedFeatureUserInterestResponse>(
    `${SDK.BASE_URL()}/projects/locked_features/user_interest?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useLockedFeaturesUserInterest(
  requestParams: SDK.GetLockedFeatureUserInterestRequest,
  options?: UseQueryOptions<SDK.GetLockedFeatureUserInterestResponse>
) {
  return useQuery(
    ['project-locked-features-user-interest', requestParams],
    () => getLockedFeaturesUserInterest(requestParams),
    options
  );
}
