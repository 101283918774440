import { CountryIconProps } from './types';

const MW = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3664)">
      <path
        d="M0 11.9997C0 13.4676 0.264047 14.8736 0.746391 16.1736L12 16.6954L23.2536 16.1737C23.736 14.8736 24 13.4676 24 11.9997C24 10.5319 23.736 9.12583 23.2536 7.82584L12 7.30408L0.746391 7.8258C0.264047 9.12583 0 10.5319 0 11.9997Z"
        fill="#D80027"
      />
      <path
        d="M11.9997 -0.000366211C6.84013 -0.000366211 2.44161 3.25613 0.746094 7.82574H23.2534C21.5578 3.25613 17.1593 -0.000366211 11.9997 -0.000366211Z"
        fill="black"
      />
      <path
        d="M23.2533 16.1747H0.746094C2.44161 20.7442 6.84012 24.0008 11.9997 24.0008C17.1593 24.0008 21.5578 20.7442 23.2533 16.1747Z"
        fill="#496E2D"
      />
      <path
        d="M15.5864 5.73994L14.1209 5.0506L14.9013 3.63127L13.3099 3.93572L13.1082 2.328L11.9997 3.51042L10.8913 2.328L10.6896 3.93572L9.09821 3.63127L9.87863 5.0506L8.41309 5.73994H15.5864Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3664">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default MW;
