import { Indicate } from 'indicate';
import styled from 'styled-components';
import ReactDOMServer from 'react-dom/server';
import ChevronRight from '../Icons/Base/ChevronRight';
import Colors from '../Colors';
import EmptyState, { EmptyStateProps } from '../EmptyState/EmptyState';
import LoadingBars from '../LoadingBars/LoadingBars';
import VideoCard, { VideoCardProps } from '../VideoCard/VideoCard';

export interface VideoCardsCarouselProps {
  data?: (VideoCardProps & React.Attributes)[] | null;
  emptyStateProps: EmptyStateProps;
  showAge?: boolean;
  showKeyMetric?: boolean;
  showLaunchDate?: boolean;
  showRecommendations?: boolean;
  showCompareButton?: boolean;
  disableCompareButton?: boolean;
  itemCustomButtonGenerator?: (
    params: VideoCardProps & React.Attributes
  ) => JSX.Element;
}

const Container = styled.div`
  position: relative;
  overflow: visible;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const NoInsights = styled.div`
  flex-grow: 1;
  align-self: center;
  height: 100%;
  text-align: center;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  background-color: ${Colors.Primary['50']};
  border-radius: 1.125rem;
`;

const VideoCardsCarousel: React.FC<VideoCardsCarouselProps> = ({
  data,
  emptyStateProps,
  showAge,
  showKeyMetric,
  showLaunchDate,
  showRecommendations,
  showCompareButton,
  disableCompareButton,
  itemCustomButtonGenerator,
}: VideoCardsCarouselProps) => {
  const loading = !data;
  if (loading) {
    return (
      <Container>
        <LoadingContainer>
          <LoadingBars />
        </LoadingContainer>
      </Container>
    );
  }
  return (
    <Container>
      {data.length === 0 ? (
        <NoInsights>
          {
            // eslint-disable-next-line react/jsx-props-no-spreading
            <EmptyState {...emptyStateProps} />
          }
        </NoInsights>
      ) : (
        <Indicate
          arrow={{
            icon: 'pointer',
            color: 'red',
            position: 'center',
            markup: ReactDOMServer.renderToString(
              <ArrowContainer>
                <ChevronRight color={Colors.Primary['700']} />
              </ArrowContainer>
            ),
          }}
          as="div"
          color="transparent"
          hideScrollbar
          inlineStyles={{
            arrow: {
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '2.25rem',
              height: '100%',
            },
            element: {
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            },
            innerWrapper: {
              gap: '1.5rem',
              margin: '0.5rem 0.1rem',
            },
          }}
          style={{
            display: 'flex',
            justifyContent: loading ? 'center' : 'normal',
          }}
          width="0"
        >
          {data.map((entry, i) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <VideoCard
              key={`${entry.name}-${i}`}
              {...entry}
              showAge={showAge}
              showKeyMetric={showKeyMetric}
              showLaunchDate={showLaunchDate}
              showRecommendations={showRecommendations}
              showCompareButton={showCompareButton}
              disableCompareButton={disableCompareButton}
              fixedWidth
              customButton={
                itemCustomButtonGenerator
                  ? itemCustomButtonGenerator(entry)
                  : undefined
              }
            />
          ))}
        </Indicate>
      )}
    </Container>
  );
};

export default VideoCardsCarousel;
