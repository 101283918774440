import { WYSIWYGProps } from './types';

const MDQuote = ({ color }: WYSIWYGProps) => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75 9.375H4.375L6.125 5.875V0.625H0.875V5.875H3.5L1.75 9.375ZM8.75 9.375H11.375L13.125 5.875V0.625H7.875V5.875H10.5L8.75 9.375Z"
      fill={color || '#98A2B3'}
    />
  </svg>
);
export default MDQuote;
