import { IntegrationIconProps } from './types';

const Evernote = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="14" fill="#00A82D" />
    <path
      d="M12.7 11.0444C12.7 11.2235 12.6855 11.5219 12.5109 11.716C12.3217 11.895 12.0307 11.91 11.856 11.91C10.8737 11.91 9.51113 11.6631 8.58205 12.0443C10 9.5 12.7469 7.80934 12.7291 7.95513C12.5982 8.96191 12.7 10.0317 12.7 11.0444ZM15.6393 22.5955C15.6393 21.3568 16.6288 20.342 17.8511 20.342C18.2149 20.342 18.5059 20.6405 18.5059 21.0136C18.5059 21.2673 18.375 21.4762 18.1712 21.5956C17.9494 21.7321 17.6421 21.6684 17.4437 21.8493C17.2982 21.9687 17.1818 22.1627 17.1818 22.3716C17.1818 22.5955 17.2691 22.8044 17.4146 22.9537C17.6765 23.2223 18.0257 23.3715 18.4041 23.3715C19.3935 23.3715 20.1938 22.5507 20.1938 21.5359C20.1938 20.6255 19.5972 19.8196 18.8115 19.4615C17.619 18.8499 15.7945 19.1979 15.6539 17.2826C15.4352 18.2956 14.9527 20.1032 13.6458 20.1032C9.69801 20.3437 8.27507 17.1459 8 13.7605C8 13.0293 8.43653 12.5368 8.98948 12.4622C9.97895 12.4622 10.9684 12.4622 11.9579 12.4622C12.4672 12.4622 12.7582 12.3278 12.9474 12.1487C13.1948 11.91 13.253 11.5667 13.253 11.1638C13.253 10.1141 13.253 8.5 13.253 8.01483C13.253 7.3812 13.8059 7 14.5189 7C14.9389 7 15.3331 7.01476 15.7412 7.11939C16.6288 7.34325 16.818 8.26853 16.818 8.26853C16.818 8.26853 18.4914 8.56701 19.3353 8.71625C20.1356 8.86549 22.1146 8.9998 22.4929 11.0444C23.3805 15.9096 22.8422 20.6255 22.7985 20.6255C22.1728 25.2221 18.4477 24.9982 18.4477 24.9982C17.0936 24.9982 15.6393 24.0741 15.6393 22.5955ZM20.0047 14.6709C19.5245 14.6261 19.1171 14.8201 18.9716 15.1932C18.7653 15.7219 20.8923 16.1616 20.8923 15.432C20.8341 15.0141 20.4849 14.7306 20.0047 14.6709Z"
      fill="white"
    />
  </svg>
);
export default Evernote;
