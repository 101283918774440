import * as SDK from '@replai-platform/sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';
import { api } from '../../../api';
import useAsset from '../../../api/hooks/assets/useAsset';
import { NetworkFilterProps, PromotedObjectFilterProps } from '../../../components/FilterBar/Filters';
import useNetworkFilter from '../../../components/FilterBar/hooks/useNetworkFilter';
import usePromotedObjectFilter from '../../../components/FilterBar/hooks/usePromotedObjectFilter';
import TopNavPageTitle from '../../../components/TopNavPageTitle';
import type { RootState } from '../../../store/rootReducer';
import { ActivityType, Page } from '../../../utils/enums';
import { getNavigateToPage } from '../../../utils/getNavigateToPage';
import { RouteAnimator } from '../../RouteAnimator';
import StaticViewTabs from './Tabs';

const StaticView = () => {
  const { id: projectId } = useSelector((state: RootState) => state.project);
  const match = useMatch(`:projectId/${Page.StaticView}/:assetId/*`);
  const params = match?.params ?? { assetId: '' };
  const location = useLocation();
  const filters = useSelector((state: RootState) => state.filters);
  const kpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);

  const getAssetParams: SDK.GetAssetRequest = {
    projectIds: [projectId],
    adsFilters: api.filterConverter.getAdsFilters({ ...filters, activity: ActivityType.all }),
    metricsFilters: api.filterConverter.getMetricsFilters({ ...filters, activity: ActivityType.all }),
    metrics: [...kpis, SDK.Metrics.INSTALLS, SDK.Metrics.IMPRESSIONS],
    includeTotalsAndAvg: true,
  };

  const { data, isLoading } = useAsset(params.assetId ?? '', getAssetParams, {
    enabled: !!params.assetId,
  });

  // Build network filter
  const { networkFilterOptions, networkFilterOnChange } = useNetworkFilter(Page.Videos);
  const networkFilter = useMemo(
    () =>
      ({
        options: networkFilterOptions,
        onChange: networkFilterOnChange,
      } as NetworkFilterProps),
    [networkFilterOptions, networkFilterOnChange]
  );

  // Build promoted object types filter
  const { promotedObjectTypeFilterOptions, promotedObjectTypeFilterOnChange } = usePromotedObjectFilter(Page.Videos);
  const promotedObjectFilter = useMemo(
    () =>
      ({
        options: promotedObjectTypeFilterOptions,
        onChange: promotedObjectTypeFilterOnChange,
      } as PromotedObjectFilterProps),
    [promotedObjectTypeFilterOnChange, promotedObjectTypeFilterOptions]
  );

  const navigateToPage = useMemo(() => getNavigateToPage(location.pathname) ?? Page.Concepts, [location.pathname]);

  return (
    <RouteAnimator data-test="static-creative-view">
      <TopNavPageTitle
        title={data?.name ?? SDK.messages.NOT_AVAILABLE}
        subtitle="Static creative"
        navigateToPage={navigateToPage}
        showFilterBar
        promotedObjectFilter={promotedObjectFilter}
        networkFilter={networkFilter}
        titleIsLoading={isLoading}
      />
      {data ? <StaticViewTabs assetData={data} loading={isLoading} /> : undefined}
    </RouteAnimator>
  );
};

export default StaticView;
