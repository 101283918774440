import { createGlobalStyle } from 'styled-components';
import Colors from '../Colors';

export const TooltipStyle = createGlobalStyle`
  .replai-tooltip .ant-tooltip-inner {
    color: ${Colors.Gray[900]};
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    border-radius: 8px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.15);
  }

  .replai-tooltip.disable-shadow .ant-tooltip-inner {
    box-shadow: unset;
  }

  .replai-tooltip.disable-padding .ant-tooltip-inner {
    padding: unset;
  }

  .replai-tooltip .ant-tooltip-arrow {
    display: none;
  }

  .replai-tooltip.show-arrow .ant-tooltip-arrow {
    display: block;
  }

  .replai-tooltip.enable-max-content .ant-tooltip-inner {
    width: max-content;
  }
`;
