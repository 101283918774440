import { IntegrationIconProps } from './types';

const Vimeo = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.79681 6.99579L2.04492 10.3966L3.34311 12.0312C3.92123 11.6719 5.07748 10.7344 5.59311 10.7344C6.74936 10.7344 7.78879 13.8672 8.78061 17.3125C9.67123 20.4062 10.5931 28.0938 14.8431 28.0938C19.7337 28.0938 30.015 14.8906 30.015 9.01435C30.015 6.88807 29.5931 3.82812 25.3587 3.82812C20.8212 3.82812 18.5889 8.20714 18.0399 10.3966C18.4348 10.1333 19.5099 9.95781 20.1023 9.95781C22.5158 9.95781 22.0003 12.4595 21.6712 13.4688C21.3421 14.478 18.8119 19.3924 17.4475 19.3924C15.7799 19.3924 14.7926 12.1519 14.3099 9.01435C13.8272 5.87679 12.5766 4.16541 10.6677 4.16541C9.14062 4.16541 6.78416 6.05233 5.79681 6.99579Z"
      fill="#3CB9E6"
    />
  </svg>
);
export default Vimeo;
