import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getAvailableLockedFeatures = async (requestParams: SDK.GetAvailableLockedFeaturesRequest) => {
  const res = await provider.getJson<object, SDK.GetAvailableLockedFeaturesResponse>(
    `${SDK.BASE_URL()}/projects/locked_features?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useAvailableLockedFeatures(
  requestParams: SDK.GetAvailableLockedFeaturesRequest,
  options?: UseQueryOptions<SDK.GetAvailableLockedFeaturesResponse>
) {
  return useQuery(
    ['project-available-locked-features', requestParams],
    () => getAvailableLockedFeatures(requestParams),
    options
  );
}
