import * as SDK from '@replai-platform/sdk';
import type { SortingRule } from 'react-table';
import { Columns } from './enums';

export const ITEMS_PER_PAGE = 10;
export const SEARCH_DEBOUNCE_TIME_MS = 800;

export const BASE_KPI_OR_METRICS_COLUMNS = [
  ...new Set([
    ...Object.values(SDK.KPI).map((kpi) => `metrics.${kpi}`),
    Columns.Spend,
    Columns.IPM,
    Columns.Impressions,
    Columns.Installs,
    Columns.Clicks,
    Columns.Purchases,
    Columns.PurchasesDay7,
    Columns.Earnings,
    Columns.EarningsDay7,
    Columns.Likes,
    Columns.Dislikes,
    Columns.Comments,
    Columns.Shares,
    Columns.AvgViewDuration,
    Columns.MinutesWatched,
    Columns.SubscribersGained,
    Columns.SubscribersLost,
    Columns.Views,
  ]),
];

export const ALLOWED_COLUMNS_ON_ALL_MAIN_PERFORMANCE_TABLES = [
  ...new Set([
    ...BASE_KPI_OR_METRICS_COLUMNS,
    Columns.Name,
    Columns.Preview,
    Columns.SpendTrend,
    Columns.Spend,
    Columns.AppearsIn,
    Columns.AGE,
  ]),
];

export const ALLOWED_COLUMNS_ON_CONCEPTS_TABLE = [
  ...ALLOWED_COLUMNS_ON_ALL_MAIN_PERFORMANCE_TABLES,
  Columns.ReplaiScore,
];

export const ALLOWED_COLUMNS_ON_PERFORMANCE_TABLE = [...ALLOWED_COLUMNS_ON_ALL_MAIN_PERFORMANCE_TABLES];

export const ALLOWED_COLUMNS_ON_TAGS_TABLE = [
  ...ALLOWED_COLUMNS_ON_ALL_MAIN_PERFORMANCE_TABLES,
  Columns.Frequency,
  Columns.AdInstances,
  Columns.NumCreatives,
];

export const ALLOWED_COLUMNS_ON_STATICS_TABLE = [...ALLOWED_COLUMNS_ON_ALL_MAIN_PERFORMANCE_TABLES];

export const ALLOWED_COLUMNS_ON_NETWORK_CONTEXT_PERFORMANCE_TABLE = [...BASE_KPI_OR_METRICS_COLUMNS];

/**
 * Columns that shouldn't appear on the CSV export
 */
export const DISALLOWED_COLUMNS_ON_CSV_EXPORT = [
  Columns.CompositePreview,
  Columns.Preview,
  Columns.ReplaiScore,
  Columns.AppearsIn,
  Columns.Frequency,
  Columns.AdInstances,
  Columns.NumCreatives,
  Columns.SpendTrend,
];

/**
 * Columns that shouldn't appear on the customize columns dialog
 */
export const NON_CUSTOMIZABLE_COLUMNS = [Columns.Preview, Columns.Name];

export const getOrderByCondition = (
  sorting: SortingRule<any>,
  userSelectedColumns?: string[]
): SDK.OrderBy<SDK.MetricOrKpi | 'name'> => {
  const defaultSorting: SDK.OrderBy<SDK.MetricOrKpi | 'name'> = {
    condition: SDK.OrderByCondition.DESC_NULLS_LAST,
    value: SDK.Metrics.SPEND,
  };
  const split: string[] = sorting?.id?.split('.');

  // Special case for name since it's not a user selected column.
  if (sorting?.id !== 'name' && userSelectedColumns && !userSelectedColumns.includes(sorting?.id)) {
    return defaultSorting;
  }

  return {
    condition: sorting.desc ? SDK.OrderByCondition.DESC_NULLS_LAST : SDK.OrderByCondition.ASC_NULLS_LAST,
    value: (split[1] ?? split[0]) as SDK.MetricOrKpi | 'name',
  };
};
