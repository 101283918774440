/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Button,
  Colors,
  FeaturedIcons,
  Input,
  Modal,
  ModalButton,
  ModalButtonsContainer,
  ModalHeader,
  Typography,
} from '@replai-platform/ui-components';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import * as SDK from '@replai-platform/sdk';
import { useQueryClient } from 'react-query';
import useUpdateUser from '../../../api/hooks/users/useUpdateUser';
import useDeleteUser from '../../../api/hooks/users/useDeleteUser';
import { logEvent } from '../../../analytics';
import { RootState } from '../../../store/rootReducer';
import { CellData } from '../common';
import { User } from '../../../store/auth';
import { AppActions } from '../../../store/app';

export type ActionsAcessorType = {
  userEmail: string;
  userId: SDK.UUID;
  userName?: string;
  userSurname?: string;
  userStatus?: string;
};

const Flex = styled.div`
  display: flex;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DialogButton = styled.div`
  padding: 0 74px;
`;

const ActionsCellDecorator: React.FC<{
  cellData: ActionsAcessorType;
}> = ({ cellData }) => {
  const currentUser = useSelector((state: RootState) => state.auth);
  const projectId = useSelector((state: RootState) => state.project.id);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const updateUserMutation = useUpdateUser();
  const deleteUserMutation = useDeleteUser();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    reset,
  } = useForm();

  const onSubmit = async ({ name, surname }) => {
    await updateUserMutation.mutateAsync(
      {
        user: {
          id: cellData.userId,
          name,
          surname,
        },
        projectId,
      },
      {
        onSuccess: async () => {
          dispatch(
            AppActions.setAlertOpen({
              badgeTitle: 'Success',
              badgeLabel: 'Update Successfull',
              message: 'The user information was updated',
              color: 'Success',
            })
          );
          await queryClient.invalidateQueries('users', { refetchInactive: true });
        },
        onError: () => {
          dispatch(
            AppActions.setAlertOpen({
              badgeTitle: 'Error',
              badgeLabel: 'Error updating user.',
              message: 'There was an error updating the user, please try again later',
              color: 'Error',
            })
          );
        },
        onSettled: () => {
          setIsEditUserModalOpen(false);
        },
      }
    );
  };

  const deleteUser = async (userId: User['id']) => {
    setIsDeletingUser(true);
    await deleteUserMutation.mutateAsync(
      { userId, projectId },
      {
        onSuccess: async () => {
          dispatch(
            AppActions.setAlertOpen({
              badgeTitle: 'Success',
              badgeLabel: 'Delete successfull',
              message: 'The user information was deleted',
              color: 'Success',
            })
          );
          await queryClient.invalidateQueries('users', { refetchInactive: true });
        },
        onError: () => {
          dispatch(
            AppActions.setAlertOpen({
              badgeTitle: 'Error',
              badgeLabel: 'Error deleting user.',
              message: 'There was an error deleting the user, please try again later',
              color: 'Error',
            })
          );
        },
        onSettled: () => {
          setIsDeletingUser(false);
          setIsDeleteUserModalOpen(false);
        },
      }
    );
  };

  if (!cellData) {
    return null;
  }

  return (
    <>
      <Flex>
        <Button
          data-test="edit-user-button"
          variant="no-fill"
          size="sm"
          onClick={() => {
            setIsEditUserModalOpen(true);
            logEvent({
              component: 'Team Management',
              action: `Edit User`,
              category: 'user_actions',
              parameters: {
                userId: currentUser.id,
                userEmail: currentUser.email,
                editedUser: cellData.userEmail,
                date: new Date(),
              },
            });
          }}
        >
          Edit
        </Button>
        {currentUser.email !== cellData.userEmail && (
          <Button
            data-test="delete-user-button"
            variant="no-fill"
            color="Gray"
            size="sm"
            onClick={() => {
              setIsDeleteUserModalOpen(true);
              logEvent({
                component: 'Team Management',
                action: `Delete User`,
                category: 'user_actions',
                parameters: {
                  userId: currentUser.id,
                  userEmail: currentUser.email,
                  deletedUser: cellData.userEmail,
                  date: new Date(),
                },
              });
            }}
          >
            Delete
          </Button>
        )}
      </Flex>
      <Modal
        data-test="delete-user-modal"
        onClick={(e) => e.stopPropagation()}
        onClose={() => setIsDeleteUserModalOpen(false)}
        isOpen={isDeleteUserModalOpen}
        modalHeader={null}
        modalFooter={null}
      >
        <ModalContent>
          <FeaturedIcons color="Error" icon="AlertCircle" size="lg" variant="outline" colorScheme="light" />
          <Typography style={{ margin: '20px auto 0 auto' }} type="text-lg">
            Remove access
          </Typography>
          <Typography style={{ margin: '8px auto 32px auto' }} type="text-sm" color={Colors.Gray[400]}>
            Are you sure that you want to delete this user?
          </Typography>
          <ModalButtonsContainer>
            <ModalButton variation="secondary" onClick={() => setIsDeleteUserModalOpen(false)}>
              <DialogButton>Cancel</DialogButton>
            </ModalButton>
            <ModalButton
              variation="danger"
              onClick={() => deleteUser(cellData.userId)}
              disabled={isDeletingUser}
              leadingIcon={isDeletingUser ? { name: 'LoadingCircle' } : undefined}
            >
              <DialogButton>Delete</DialogButton>
            </ModalButton>
          </ModalButtonsContainer>
        </ModalContent>
      </Modal>
      <Modal
        data-test="edit-user-modal"
        onClick={(e) => e.stopPropagation()}
        onClose={() => {
          setIsEditUserModalOpen(false);
          reset();
        }}
        isOpen={isEditUserModalOpen}
        modalHeader={<ModalHeader title="Edit user" />}
        modalFooter={
          <ModalButtonsContainer>
            <ModalButton
              variation="secondary"
              onClick={() => {
                setIsEditUserModalOpen(false);
                reset();
              }}
            >
              Cancel
            </ModalButton>
            <ModalButton
              variation="primary"
              type="submit"
              disabled={!isDirty || isSubmitting}
              onClick={handleSubmit(onSubmit)}
              leadingIcon={isSubmitting ? { name: 'LoadingCircle' } : undefined}
            >
              Update
            </ModalButton>
          </ModalButtonsContainer>
        }
      >
        <form>
          <Input
            error={!!errors.name}
            fullWidth
            hintText={errors.name?.message as unknown as string}
            label="Name"
            placeholder="John"
            defaultValue={cellData.userName}
            {...register('name', { required: 'Invalid name.' })}
          />
          <Input
            error={!!errors.surname}
            fullWidth
            hintText={errors.surname?.message as unknown as string}
            label="Surname"
            placeholder="Doe"
            defaultValue={cellData.userSurname}
            {...register('surname', { required: 'Invalid surname.' })}
          />
        </form>
      </Modal>
    </>
  );
};

function getActionsColumn<D extends object = object>({ columnId, accessor }) {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<D, ActionsAcessorType>) => <ActionsCellDecorator cellData={data?.cell?.value} />,
  };
}

export default getActionsColumn;
