import { WYSIWYGProps } from './types';

const MDHeaderLarge = ({ color }: WYSIWYGProps) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0593 13.25H8.49585V7.91797H3.49707V13.25H0.933594V0.808594H3.49707V5.8501H8.49585V0.808594H11.0593V13.25Z"
      fill={color || '#98A2B3'}
    />
  </svg>
);
export default MDHeaderLarge;
