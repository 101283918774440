import { MonthPicker, MonthPickerProps } from '@replai-platform/ui-components';

export interface MonthFilterProps {
  onChange?: MonthPickerProps['onChange'];
  disabled?: MonthPickerProps['disabled'];
}

const MonthFilter: React.VFC<MonthFilterProps> = ({ onChange, disabled }) => (
  <MonthPicker data-test="filter-datepicker-month" onChange={onChange} disabled={disabled} />
);

export default MonthFilter;
