export enum TagKind {
  Core = 'core',
  Custom = 'custom',
  Psychological = 'psychological',
  Market = 'market',
}

export enum TagGroup {
  Assets = 'assets',
  Audio = 'audio',
  ColorTheory = 'colorTheory',
  Cta = 'cta',
  Duration = 'duration',
  Movement = 'movement',
  Music = 'music',
  Orientation = 'orientation',
  TextAnalysis = 'textAnalysis',
  TextOverlayHeader = 'textOverlay-header',
  TextOverlayFooter = 'textOverlay-footer',
}

// Please update our Confluence and the database if you change this enum.
export enum CoreTags {
  ASPECT_RATIO = 'aspectRatio',
  AUDIO = 'audio',
  COLOR_TEMPERATURE = 'colorTemperature',
  COLORFULNESS = 'colorfulness',
  CTA = 'cta',
  CUT_FREQUENCY = 'cutFrequency',
  DURATION = 'duration',
  FOOTER = 'footer',
  FOOTER_BACKGROUND_COLOR = 'footerBackgroundColor',
  FOOTER_NUM_LINES = 'footerNumLines',
  FOOTER_TEXT_COLOR = 'footerTextColor',
  HEADER = 'header',
  HEADER_BACKGROUND_COLOR = 'headerBackgroundColor',
  HEADER_NUM_LINES = 'headerNumLines',
  HEADER_TEXT_COLOR = 'headerTextColor',
  INTRO_LOUDNESS = 'introLoudness',
  KEYWORD = 'keyword',
  LANGUAGE = 'language',
  LUMINOSITY = 'luminosity',
  MUSIC = 'music',
  NUM_CTAS = 'numCtas',
  NUMBER_OF_SCENES = 'numberOfScenes',
  ORIENTATION = 'orientation',
  PACE = 'pace',
  SATURATION = 'saturation',
  SCENE_DURATION = 'sceneDuration',
  SCENE_TRANSITIONS_DIFF = 'sceneTransitionsDiff',
  TEXT = 'text',
}
