import { IconProps } from './types';

const Tool = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7 6.29998C14.5168 6.48691 14.4142 6.73823 14.4142 6.99998C14.4142 7.26173 14.5168 7.51305 14.7 7.69998L16.3 9.29998C16.4869 9.48321 16.7383 9.58584 17 9.58584C17.2618 9.58584 17.5131 9.48321 17.7 9.29998L21.47 5.52998C21.9728 6.64117 22.1251 7.87921 21.9065 9.07913C21.6878 10.279 21.1087 11.3838 20.2463 12.2463C19.3839 13.1087 18.2791 13.6878 17.0792 13.9064C15.8792 14.1251 14.6412 13.9728 13.53 13.47L6.62001 20.38C6.22218 20.7778 5.68262 21.0013 5.12001 21.0013C4.5574 21.0013 4.01783 20.7778 3.62001 20.38C3.22218 19.9822 2.99869 19.4426 2.99869 18.88C2.99869 18.3174 3.22218 17.7778 3.62001 17.38L10.53 10.47C10.0272 9.35879 9.87492 8.12075 10.0936 6.92083C10.3122 5.72092 10.8913 4.61614 11.7537 3.7537C12.6162 2.89127 13.721 2.31215 14.9209 2.09352C16.1208 1.8749 17.3588 2.02714 18.47 2.52998L14.71 6.28998L14.7 6.29998Z"
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default Tool;
