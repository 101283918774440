/* eslint-disable */

/**
 * Retrieve nested item from object/array
 * @param {Object|Array} obj
 * @param {String} path dot separated
 * @param {*} def default value ( if result undefined )
 * @returns {*}
 */
export const resolvePath = (obj: any, path: string, def: any) => {
  const splitPath = path.split('.');
  for (let i = 0; i < splitPath.length; i += 1) {
    if (!obj || typeof obj !== 'object') return def;
    obj = obj[splitPath[i]];
  }

  if (obj === undefined) return def;
  return obj;
};
