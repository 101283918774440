import { Button } from '@replai-platform/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppActions } from '../../../store/app';
import { RootState } from '../../../store/rootReducer';

const ButtonWithMargin = styled(Button)`
  margin-left: 1rem;
`;

const AddFilterButton = () => {
  const dispatch = useDispatch();
  const filterMenuOpen = useSelector((state: RootState) => state.app.filterMenuOpen);

  const handleClick = () => {
    dispatch(AppActions.setFilterMenuOpen(!filterMenuOpen));
  };

  return (
    <ButtonWithMargin
      data-test="filter-add-button"
      onClick={handleClick}
      variant="outlined"
      color="Gray"
      leadingIcon={{ name: 'FiltersLines' }}
    >
      Filters
    </ButtonWithMargin>
  );
};

export default AddFilterButton;
