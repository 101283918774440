import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';
import { YoutubeAuthLink } from './YoutubeAuthLink';

export const YouTubeConnectionGuide: NetworkConnectionGuideContent = {
  title: 'YouTube Connection Guide',
  icon: 'YouTube',
  guide: {
    label: 'Connection Guide',
    steps: [
      {
        title: 'Sign in with your Google account',
        description: 'Sign-in with Google to connect your YouTube channel.',
        content: (
          <div style={{ maxWidth: '100%' }}>
            <div style={{ margin: 'auto', width: 'fit-content' }}>
              <img
                style={{ width: '200px' }}
                src="https://cdn.replai.io/assets/integration-guide/youtube/step-1.png"
                alt="YouTube Logo"
              />
            </div>
            <div style={{ height: '50px' }} />
            <div style={{ width: '150px', margin: 'auto' }}>
              <YoutubeAuthLink />
            </div>
            <div style={{ marginTop: '30px' }}>
              <Shared.RegularText>
                Replai&apos;s use and transfer to any other app of information received from Google APIs will adhere to{' '}
                {Shared.link({
                  label: 'Google API Services User Data Policy',
                  url: 'https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes',
                })}
                , including the Limited Use requirements.
              </Shared.RegularText>
            </div>
          </div>
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: Shared.DEFAULT_NETWORK_FAQS,
  },
};
