import * as SDK from '@replai-platform/sdk';
import * as provider from './provider';

/**
 * Get Country Metrics
 * * @param {SDK.GetCountriesMetricsRequest} request SDK request
 */
export async function getCountriesMetrics(
  request: SDK.GetCountriesMetricsRequest
): Promise<SDK.GetCountriesMetricsResponse> {
  return provider.getJson(`${SDK.BASE_URL()}/countries/metrics?${SDK.convertRequestToQueryParams(request)}`);
}
