import styled from 'styled-components';
import { RouteAnimator } from '../RouteAnimator';

export const StyledRouteAnimator = styled(RouteAnimator)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: fit-content;
  grid-template-columns: auto 467px;
  gap: 1.5rem;
`;
