import { Icons } from '@replai-platform/ui-components';

/**
 * If you add a new network, you should also add a .png file
 * with the network logo to this bucket: replai-public-assets/network-logos
 */
const NETWORK_TO_ICON: {
  [platform in string]: Icons.FlatSocialIconTypes;
} = {
  applovin: 'AppLovin',
  facebook: 'Facebook',
  google: 'Google',
  ironsource: 'IronSource',
  liftoff: 'Liftoff',
  mintegral: 'Mintegral',
  moloco: 'Moloco',
  replai_api: 'Website',
  sensortower: 'SensorTower',
  snapchat: 'Snapchat',
  tiktok: 'TikTok',
  unity: 'Unity',
  vungle: 'Vungle',
  youtube: 'YouTube',
};

export const networkIconType = (network: string) => NETWORK_TO_ICON[network];

export const NetworkIcon = (network: string) => Icons.getFlatIcon(networkIconType(network));
