import { CountryIconProps } from './types';

const SV = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3752)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9997 -0.000976562C7.24218 -0.000976562 3.13143 2.7677 1.19043 6.78165H22.8089C20.868 2.7677 16.7572 -0.000976562 11.9997 -0.000976562Z"
        fill="#0052B4"
      />
      <path
        d="M11.9997 23.9989C16.7572 23.9989 20.868 21.2302 22.809 17.2162H1.19043C3.13143 21.2302 7.24218 23.9989 11.9997 23.9989Z"
        fill="#0052B4"
      />
      <path
        d="M9.58984 12.5225L11.9997 8.34857L14.4095 12.5225H9.58984Z"
        fill="#FFDA44"
      />
      <path
        d="M15.13 13.8996L11.9996 15.4649L8.86914 13.8996V11.8127H15.13V13.8996Z"
        fill="#6DA544"
      />
      <path
        d="M14.9514 8.52728L13.8446 9.63405C14.3167 10.1061 14.6088 10.7583 14.6088 11.4787C14.6088 12.9194 13.4408 14.0874 12.0001 14.0874C10.5594 14.0874 9.39138 12.9194 9.39138 11.4787C9.39138 10.7583 9.68341 10.1061 10.1555 9.63405L9.04872 8.52728C8.29333 9.28253 7.82617 10.326 7.82617 11.4787C7.82617 13.7839 9.69489 15.6526 12.0001 15.6526C14.3052 15.6526 16.174 13.7839 16.174 11.4787C16.1739 10.326 15.7068 9.28253 14.9514 8.52728Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3752">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SV;
