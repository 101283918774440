/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase } from '@replai-platform/ui-components';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';
import { useTitle } from 'react-use';
import { api } from '../../../api';
import useTagDescription from '../../../api/hooks/tags/useTagDescription';
import useTagTypeMetrics from '../../../api/hooks/tags/useTagTypeMetrics';
import { NetworkFilterProps, PromotedObjectFilterProps } from '../../../components/FilterBar/Filters';
import useNetworkFilter from '../../../components/FilterBar/hooks/useNetworkFilter';
import usePromotedObjectFilter from '../../../components/FilterBar/hooks/usePromotedObjectFilter';
import TopNavPageTitle from '../../../components/TopNavPageTitle';
import { RootState } from '../../../store/rootReducer';
import { TagViewActions } from '../../../store/tagView';
import { capitalizeFirstLetter } from '../../../utils';
import { Page } from '../../../utils/enums';
import { getNavigateToPage } from '../../../utils/getNavigateToPage';
import { RouteAnimator } from '../../RouteAnimator';
import TagViewTabs from './TagViewTabs';

type TagWithMetrics = { tag: SDK.Tag | undefined; tagMetrics: SDK.TagMetricsWithThumbnail };
const TagView = () => {
  const dispatch = useDispatch();
  const match = useMatch(`:projectId/${Page.Tags}/:tagId/*`);
  const params = match?.params as { projectId: string; tagId: string };
  const { name: projectName, id: projectId } = useSelector((state: RootState) => state.project);
  const projectBaseMetric = useSelector((state: RootState) => state.project.baseMetric);

  const filters = useSelector((state: RootState) => state.filters);
  const defaultProjectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);

  useTitle(`${capitalizeFirstLetter(Page.Tags)} - ${projectName}`);

  useEffect(() => {
    dispatch(TagViewActions.reset()); // Reset tag state after switching tag from other tabs.
  }, [dispatch, params.tagId]);

  const { data: tagDescription, isLoading: isLoadingTagDescription } = useTagDescription({
    projectIds: [projectId],
    tags: params.tagId ? [params.tagId] : [],
  });

  const getTagMetricsParams = useMemo<SDK.GetTagTypeMetricsRequest>(
    () => ({
      projectIds: [projectId],
      metrics: defaultProjectKpis as SDK.Metrics[],
      orderBy: { condition: SDK.OrderByCondition.DESC_NULLS_LAST, value: projectBaseMetric },
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      tagsFilters: {
        tagTypeToConsider: { type: tagDescription?.tags?.[0].type ?? '' },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultProjectKpis, JSON.stringify(filters), projectId, JSON.stringify(tagDescription?.tags)]
  );
  const { data: tagInfoWithMetrics, isLoading: tagInfoWithMetricsLoading } = useTagTypeMetrics<TagWithMetrics>(
    getTagMetricsParams,
    {
      enabled: !isLoadingTagDescription && !!tagDescription,
      select: useCallback(
        (res) => ({
          tag: tagDescription?.tags?.[0],
          tagMetrics: res.tags?.[0],
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(tagDescription?.tags)]
      ),
    }
  );

  const tagInfo = useMemo(() => tagInfoWithMetrics?.tag, [tagInfoWithMetrics]);

  // Update tag information for all tabs
  useEffect(() => {
    if (tagInfoWithMetrics && !tagInfoWithMetricsLoading) {
      dispatch(TagViewActions.setTag(tagInfoWithMetrics.tag));
      dispatch(TagViewActions.setTagMetrics(tagInfoWithMetrics.tagMetrics));
    }
  }, [dispatch, tagInfoWithMetrics, tagInfoWithMetricsLoading]);

  // Build promoted object types filter
  const { promotedObjectTypeFilterOptions, promotedObjectTypeFilterOnChange } = usePromotedObjectFilter(Page.Videos);
  const promotedObjectFilter = useMemo(
    () =>
      ({
        options: promotedObjectTypeFilterOptions,
        onChange: promotedObjectTypeFilterOnChange,
      } as PromotedObjectFilterProps),
    [promotedObjectTypeFilterOnChange, promotedObjectTypeFilterOptions]
  );

  // Build network filter
  const {
    networkFilterOptions,
    networkFilterOnChange,
    isLoading: isNetworkFilterLoading,
  } = useNetworkFilter(Page.Videos);
  const networkFilter = useMemo(
    () =>
      ({
        options: networkFilterOptions,
        onChange: networkFilterOnChange,
        loading: isNetworkFilterLoading,
      } as NetworkFilterProps),
    [networkFilterOptions, networkFilterOnChange, isNetworkFilterLoading]
  );

  const location = useLocation();
  const navigateToPage = useMemo(() => getNavigateToPage(location.pathname), [location.pathname]);

  return (
    <RouteAnimator data-test="single-tag-view">
      <TopNavPageTitle
        title={tagInfo ? `${camelCaseToCapitalCase(tagInfo?.type)}: ${tagInfo?.value ?? ''}` : 'Tag View'}
        subtitle="Tag"
        navigateToPage={navigateToPage}
        showFilterBar
        networkFilter={networkFilter}
        promotedObjectFilter={promotedObjectFilter}
      />
      <TagViewTabs />
    </RouteAnimator>
  );
};

export default TagView;
