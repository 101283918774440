/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../store/rootReducer';
import { Filters } from '../../../utils/enums';
import { getFilterShowCondition } from '../common/utils';
import FilterDropDownGroup from '../DropDownGroup';
import SpendFilter from '../Filters/SpendFilter';
import InstallsFilter from '../Filters/InstallsFilter';

interface ActiveFilterBarProps {
  supportedFilters: Filters[];
  eventPrefix?: string;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: right;
  width: 100%;
  padding-top: 26px;
  row-gap: 1rem;
`;

const ActiveFilterBar: React.VFC<ActiveFilterBarProps> = ({ supportedFilters, eventPrefix }) => {
  const filters = useSelector((state: RootState) => state.filters);
  const activeFilters = supportedFilters.filter((filter) => getFilterShowCondition(filter, filters));
  return activeFilters.length > 0 ? (
    <Row data-test="active-filters">
      {activeFilters.map((filter) =>
        filter === Filters.Spend ? (
          <SpendFilter key={filter} />
        ) : filter === Filters.Installs ? (
          <InstallsFilter key={filter} />
        ) : (
          <FilterDropDownGroup
            key={filter}
            filter={filter}
            eventPrefix={eventPrefix}
            useAllLabel={false}
            type={filter === Filters.LaunchedDate ? 'singleselect' : 'multiselect'}
          />
        )
      )}
    </Row>
  ) : (
    <div />
  );
};

export default ActiveFilterBar;
