import { CountryIconProps } from './types';

const BR = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#6DA544"
    />
    <path
      d="M11.9999 4.69611L21.913 12.0004L11.9999 19.3048L2.08691 12.0004L11.9999 4.69611Z"
      fill="#FFDA44"
    />
    <path
      d="M12.0001 16.1738C14.3052 16.1738 16.174 14.3051 16.174 11.9999C16.174 9.69476 14.3052 7.82605 12.0001 7.82605C9.69489 7.82605 7.82617 9.69476 7.82617 11.9999C7.82617 14.3051 9.69489 16.1738 12.0001 16.1738Z"
      fill="#F0F0F0"
    />
    <path
      d="M9.91276 11.7395C9.18694 11.7395 8.48649 11.8499 7.82715 12.0547C7.85635 14.3347 9.71279 16.1743 11.9997 16.1743C13.4138 16.1743 14.6628 15.4704 15.4178 14.3945C14.1261 12.7775 12.1385 11.7395 9.91276 11.7395Z"
      fill="#0052B4"
    />
    <path
      d="M16.0962 12.7998C16.1464 12.5409 16.1735 12.2737 16.1735 12.0001C16.1735 9.69491 14.3048 7.82623 11.9997 7.82623C10.2796 7.82623 8.80306 8.86691 8.16406 10.3528C8.72891 10.2357 9.31372 10.1741 9.91273 10.1741C12.337 10.174 14.5301 11.1817 16.0962 12.7998Z"
      fill="#0052B4"
    />
  </svg>
);
export default BR;
