import * as SDK from '@replai-platform/sdk';
import { Button, Typography, VideoModule } from '@replai-platform/ui-components';
import { UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useQueries } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { logEvent } from '../../analytics';
import { api } from '../../api';
import useMarketAssetsTags from '../../api/hooks/assets/useMarketAssetsTags';
import useConceptMetrics from '../../api/hooks/concepts/useConceptMetrics';
import { getConceptPreviewInfoQueryOptions } from '../../api/hooks/concepts/useConceptPreviewInfo';
import { getConceptTagsQueryOptions } from '../../api/hooks/concepts/useConceptTags';
import useGenres from '../../api/hooks/tags/useGenres';
import TopNavPageTitle from '../../components/TopNavPageTitle';
import type { RootState } from '../../store/rootReducer';
import { isCreativeActive } from '../../utils';
import { COMPARE_SCROLL_OFFSET, COMPARE_SCROLL_THRESHOLD } from '../../utils/constants';
import { Page } from '../../utils/enums';
import { formatDateDistance } from '../../utils/formatDateDistance';
import CustomizeVisibleTags from './CustomizeVisibleTags';
import * as Styled from './styles';
import TagsTimeline from './TagsTimeline';
import { KpiState, type AssetToCompare, type ConceptToCompare, type HiddenAsset, type TimelineData } from './types';
import { calculateKpiBadgeStatus, logEventOnAction } from './utils';

const CompareView: React.VFC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const projectName = useSelector((state: RootState) => state.project.name);
  const projectId = useSelector((state: RootState) => state.project.id);
  const filters = useSelector((state: RootState) => state.filters);
  const compareItems = useSelector((state: RootState) => state.app.compareItems);
  const [reference, setReference] = useState<AssetToCompare | null>(null);
  const [hiddenAssets, setHiddenAssets] = useState<HiddenAsset[]>([]);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const playerRefs = useRef<{ [key: string]: ReactPlayer }>({});
  const [playersProgress, setPlayersProgress] = useState<{ [key: string]: { playedSeconds: number; played: number } }>(
    {}
  );
  const [isPlayersPlaying, setIsPlayersPlaying] = useState<{ [key: string]: boolean }>(
    Object.keys(compareItems).reduce((acc, key) => ({ ...acc, [key]: false }), {})
  );
  const [hasVideoEnded, setHasVideoEnded] = useState<{ [key: string]: boolean }>({});
  const [openCustomizeVisibleTags, setOpenCustomizeVisibleTags] = useState(false);
  const [visibleTags, setVisibleTags] = useState<string[]>();

  const VIDEO_MODULE_PLACEHOLDERS: AssetToCompare[] = Array<AssetToCompare>(Object.keys(compareItems).length).fill({
    type: 'concept',
    id: '',
    displayName: '',
    name: '',
    spend: 0,
    totalSpend: 0,
    installs: 0,
    age: undefined,
    ipm: 0,
    ctr: 0,
    maxDate: '',
  });
  const TAGS_TIMELINE_PLACEHOLDERS: TimelineData[] = Array<TimelineData>(Object.keys(compareItems).length).fill({
    id: '',
    duration: 0,
    annotation: [],
    coreAnnotation: [],
    postQaAnnotation: [],
    genres: [],
    name: '',
    appPublisher: '',
    launchDate: '',
    previewUrl: '',
    isActive: false,
    tags: [],
  });

  useTitle(`Compare - ${projectName}`);

  const marketAssets = Object.entries(compareItems)
    .filter(([, value]) => value.isMarketData)
    .map(([key]) => key);

  const nonMarketAssets = Object.entries(compareItems)
    .filter(([, value]) => !value.isMarketData)
    .map(([key]) => key);

  const assetIds = Object.keys(compareItems);
  const visibleAssetIds = assetIds.filter((id) => hiddenAssets.every((hiddenC) => hiddenC.id !== id));

  const previousRoute = !(location.state as { prevRoute: string })?.prevRoute
    ? Page.ConceptsLibrary
    : (location.state as { prevRoute: string })?.prevRoute.indexOf(Page.MarketVideosLibrary) > 0
    ? Page.MarketVideosLibrary
    : Page.ConceptsLibrary;

  const getConceptsParams = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: [SDK.Metrics.SPEND, SDK.Metrics.INSTALLS, SDK.KPI.IPM, SDK.KPI.CTR, SDK.Metrics.AGE],
      adsFilters: api.filterConverter.getAdsFilters({
        campaignIdsToConsider: filters.campaignIdsToConsider,
        campaignIdsToExclude: filters.campaignIdsToExclude,
        networks: filters.networks,
      }),
      metricsFilters: api.filterConverter.getMetricsFilters({
        startDate: filters.startDate,
        endDate: filters.endDate,
        countries: ['ALL'],
      }),
      conceptIds: nonMarketAssets,
      includeTotalsAndAvg: true,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(filters), projectId, JSON.stringify(compareItems)]
  );

  const {
    data: conceptsData,
    isLoading: isLoadingConcepts,
    isError: isErrorConcepts,
  } = useConceptMetrics(getConceptsParams, {
    onSuccess: (result) => {
      const conceptPivot = result.concepts.find((a) => a.id === Object.keys(compareItems)[0]);
      if (!conceptPivot) return;
      setReference({ ...conceptPivot, type: 'concept' } as AssetToCompare);
    },
    enabled: nonMarketAssets.length > 0,
  });

  const getMarketAssetsParams = useMemo(
    () => ({ assetIds: marketAssets, hasPostQaAnnotations: !!filters.onlyMarketCompetitors }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(filters), projectId, JSON.stringify(compareItems)]
  );
  const { data: marketData, isLoading: isLoadingMarket } = useMarketAssetsTags(getMarketAssetsParams, {
    onSuccess: (result) => {
      const marketPivot = result.marketAssets.find((a) => a.id === Object.keys(compareItems)[0]);
      if (!marketPivot) return;
      setReference({ ...marketPivot, type: 'market' } as AssetToCompare);
    },
    enabled: marketAssets.length > 0,
  });

  const isLoading = (nonMarketAssets.length > 0 && isLoadingConcepts) || (marketAssets.length > 0 && isLoadingMarket);

  useEffect(() => {
    if (!isLoading && !reference && Object.keys(compareItems).length > 0) {
      const isPivotMarket = compareItems[Object.keys(compareItems)[0]].isMarketData;
      if (isPivotMarket) {
        const asset = marketData?.marketAssets.find((a) => a.id === Object.keys(compareItems)[0]);
        setReference({ ...asset, type: 'market' } as AssetToCompare);
      } else {
        const asset = conceptsData?.concepts.find((a) => a.id === Object.keys(compareItems)[0]);
        setReference({ ...asset, type: 'concept' } as AssetToCompare);
      }
    }
  }, [isLoading, reference]);

  // Retrieves the tag types of the genre if the pivot video is a market asset
  const getGenresParams = useMemo(
    () => ({
      genreNames: marketData?.marketAssets.find((a) => marketAssets.includes(a.id))?.genres ?? [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(filters), projectId, JSON.stringify(marketData?.marketAssets), JSON.stringify(marketAssets)]
  );
  const { data: genresData } = useGenres(getGenresParams, {
    select: (res) => res?.genres?.flatMap(({ tagTypes }) => tagTypes),
    enabled: marketAssets.length > 0 && !isLoadingMarket,
  });

  // Fetch concepts tags information to build the timeline comparison
  const conceptResults = useQueries(
    nonMarketAssets.map((id) => getConceptTagsQueryOptions({ projectId }, id, { enabled: !!id }))
  );

  const isTagsLoading = isLoadingMarket || conceptResults.some((r) => r.isLoading);

  const filteredData = useMemo(() => {
    if (isTagsLoading || isLoading || !reference) return TAGS_TIMELINE_PLACEHOLDERS;

    const timelines = [
      ...conceptResults
        .filter(({ data }) => !!data)
        .map(({ data }, index) => ({
          id: nonMarketAssets[index],
          url: '',
          duration: 0,
          coreAnnotation: [],
          postQaAnnotation: [],
          ...data,
          tags: (data?.tags || [])?.filter(({ type }) => visibleTags?.includes(type)),
          annotation: (data?.annotation || [])?.filter(({ type }) => visibleTags?.includes(type)),
        })),
      ...(marketData?.marketAssets ?? []).map((data) => ({
        ...data,
        tags: (data?.tags || [])?.filter((tag) => visibleTags?.includes(tag?.type)),
        annotation: (data?.annotation || [])?.filter(({ type }) => visibleTags?.includes(type)),
      })),
    ].sort((a, b) => assetIds.indexOf(a.id) - assetIds.indexOf(b.id));

    return [
      timelines.find(
        (t) => t.id === reference?.id && !hiddenAssets.find((hiddenC) => hiddenC.id === t.id)
      ) as TimelineData,
      ...(timelines.filter(
        (t) => t.id !== reference?.id && !hiddenAssets.find((hiddenC) => hiddenC.id === t.id)
      ) as TimelineData[]),
    ];
  }, [
    JSON.stringify(conceptResults),
    JSON.stringify(marketData?.marketAssets),
    JSON.stringify(visibleTags),
    JSON.stringify(hiddenAssets),
    JSON.stringify(assetIds),
    reference?.id,
    isTagsLoading,
    isLoading,
  ]);

  const assets = useMemo(() => {
    if (isLoading || !reference) return VIDEO_MODULE_PLACEHOLDERS;

    const market = (marketAssets.length > 0 ? marketData?.marketAssets : []) ?? [];
    const concepts = (nonMarketAssets.length > 0 ? conceptsData?.concepts : []) ?? [];
    const allAssets = [
      ...market.map((a) => ({ ...a, type: 'market' })),
      ...concepts.map((a) => ({ ...a, type: 'concept' })),
    ].sort((a, b) => assetIds.indexOf(a.id) - assetIds.indexOf(b.id));
    return [
      allAssets.find((a) => a.id === reference?.id) as AssetToCompare,
      ...(allAssets.filter(
        (a) => a.id !== reference?.id && hiddenAssets.every((hiddenC) => hiddenC.id !== a.id)
      ) as AssetToCompare[]),
    ];
  }, [JSON.stringify(conceptsData), JSON.stringify(marketData), JSON.stringify(hiddenAssets), isLoading, reference]);

  // Calculates the matching percentage of tags between the pivot video and the
  // other market assets
  const matchingTags = useMemo(() => {
    if (isLoading || isTagsLoading || filteredData.length === 0) return {};

    const pivotTags = filteredData[0]?.tags;
    const matching: { [key: string]: number } = {};

    for (const asset of filteredData.slice(1)) {
      const sameTags = asset.tags.filter(
        (tag) => pivotTags.filter((pivotTag) => pivotTag.type === tag.type && pivotTag.value === tag.value).length > 0
      );
      matching[asset.id] = sameTags.length === 0 ? 0 : sameTags.length / asset.tags.length;
    }
    return matching;
  }, [JSON.stringify(filteredData), isLoading, isTagsLoading]);

  // Thumbnails
  const conceptIds = Object.entries(compareItems)
    .filter(([, value]) => !value.isMarketData)
    .map(([id]) => id);
  const thumbnailsQueries = useQueries(
    isLoadingConcepts || !conceptsData || isErrorConcepts
      ? []
      : conceptIds?.map((id) =>
          getConceptPreviewInfoQueryOptions({ projectId, conceptId: id, size: '200x200' }, { enabled: !isLoading })
        ) ?? []
  );

  const conceptThumbnails = useMemo(() => {
    if (thumbnailsQueries.some((q) => q.isLoading)) return {};

    const thumbnails: { [key: string]: string | undefined } = {};
    thumbnailsQueries.forEach(({ data }) => {
      if (!data) return;
      thumbnails[data.id] = data.previewUrl;
    });

    return thumbnails;
  }, [thumbnailsQueries]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.target as HTMLElement;
    if (scrollTop === 0) {
      setIsScrollingDown(false);
      return;
    }

    // Forces the scroll down to compensate for the section of the page that hides
    // on scroll
    if (scrollTop > COMPARE_SCROLL_THRESHOLD && scrollTop < COMPARE_SCROLL_OFFSET && !isScrollingDown) {
      window.document.getElementById('compare-container')?.scrollTo({ top: COMPARE_SCROLL_OFFSET });
      setIsScrollingDown(true);
    } else if (scrollTop > COMPARE_SCROLL_THRESHOLD) {
      setIsScrollingDown(true);
    }
  };

  const startAllVideos = (force = false) => {
    setIsPlayersPlaying((prevState) =>
      Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: force || !hasVideoEnded[key] }), {})
    );
  };

  const startVideo = (key: string) => {
    setIsPlayersPlaying((prevState) => ({ ...prevState, [key]: true }));
  };

  const stopAllVideos = () => {
    setIsPlayersPlaying((prevState) => Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: false }), {}));
  };

  const stopVideo = (key: string) => {
    setIsPlayersPlaying((prevState) => ({ ...prevState, [key]: false }));
  };

  const resetVideos = (stop = true) => {
    Object.values(playerRefs?.current)
      .filter(Boolean)
      .forEach((ref) => ref.seekTo(0, 'seconds'));
    setHasVideoEnded((prevState) => Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: false }), {}));
    setPlayersProgress((prevState) =>
      Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: { playedSeconds: 0, played: 0 } }), {})
    );
    if (stop) stopAllVideos();
  };

  const resetVideo = (key: string) => {
    playerRefs?.current[key]?.seekTo(0, 'seconds');
    setIsPlayersPlaying((prevState) => ({ ...prevState, [key]: false }));
  };

  const seekToPoint = (point: number) => {
    Object.entries(playerRefs?.current).forEach(([key, ref]) => {
      if (!ref) return;

      // In case the point is bigger than the video duration, the video
      // remains paused on the end of the video
      if (ref.getDuration() < point) {
        setHasVideoEnded((prevState) => ({ ...prevState, [key]: true }));
        ref.seekTo(ref.getDuration(), 'seconds');
        stopVideo(key);
      } else {
        setHasVideoEnded((prevState) => ({ ...prevState, [key]: false }));
        ref.seekTo(point, 'seconds');
        if (isPlayersPlaying[0]) startVideo(key);
      }
    });
  };

  const onPlay = (key: string) => {
    if (!isPlayersPlaying[key]) logEventOnAction({ component: 'Compare', action: 'Click Play Video' });
    if (key === reference?.id) {
      // If the reference video has ended, on play it should reset all videos
      // to the start, so that the simultaneous pointer is always synced
      if (hasVideoEnded[key]) {
        resetVideos(false);
        startAllVideos(true);
      } else {
        startAllVideos();
      }
    } else {
      setIsPlayersPlaying((prevState) => ({ ...prevState, [key]: true }));
    }
  };

  const onPause = (key: string, ended?: boolean) => {
    if (isPlayersPlaying[key]) logEventOnAction({ component: 'Compare', action: 'Click Pause Video' });
    if (key === reference?.id && !ended) {
      stopAllVideos();
    } else {
      setIsPlayersPlaying((prevState) => ({ ...prevState, [key]: false }));
    }
  };

  const onSeekToPoint = (point: number) => {
    seekToPoint(point);
    logEventOnAction({ component: 'Tags Timeline', action: 'Seek to Point' });
  };

  const onVisibleTagsChange = useCallback((tags: string[]) => setVisibleTags(tags), []);

  const renderTagsFilterButton = () => (
    <Button
      variant="outlined"
      color="Gray"
      onClick={() => {
        setOpenCustomizeVisibleTags(true);
        logEvent({
          component: 'Compare',
          action: 'Open Customize Visible Tags',
          category: 'user_actions',
        });
      }}
    >
      Customize Visible Tags
    </Button>
  );

  const metrics = (asset: AssetToCompare, index: number) => {
    if (isLoading) return undefined;

    return asset.type === 'concept'
      ? [
          ...[SDK.KPI.IPM, SDK.KPI.CTR].map((kpi) => ({
            name: SDK.kpiUtils.getDisplayName(kpi),
            value: SDK.kpiUtils.format(kpi, asset[kpi] as number),
            useBadge: true,
            status:
              index > 0
                ? calculateKpiBadgeStatus({
                    kpi,
                    controlKpi: (assets[0][kpi] as number) ?? 0,
                    iterationKpi: (asset[kpi] as number) ?? 0,
                  })
                : KpiState.NEUTRAL,
          })),
          {
            name: SDK.kpiUtils.getDisplayName(SDK.Metrics.INSTALLS),
            value: SDK.kpiUtils.format(SDK.Metrics.INSTALLS, asset.installs),
            status:
              index > 0
                ? asset.installs - ((asset[0] as ConceptToCompare)?.installs ?? 0) > 0
                  ? KpiState.WINNING
                  : KpiState.LOSING
                : KpiState.NEUTRAL,
          },
          {
            name: 'Age',
            value: formatDateDistance(new Date(asset.age ?? '')),
          },
        ]
      : undefined;
  };

  const percentageValue = (asset: AssetToCompare) => {
    if (isLoading) return undefined;

    return asset.type === 'concept'
      ? {
          label: 'Total Spend',
          value: SDK.kpiUtils.format(SDK.Metrics.SPEND, asset.spend),
          percentage: asset.totalSpend !== 0 ? asset.spend / (asset.totalSpend ?? 1) : 0,
        }
      : {
          label: 'Matching Tags',
          value: matchingTags[asset.id] ? `${Math.round(matchingTags[asset.id] * 100)}%` : '',
          percentage: matchingTags[asset.id],
        };
  };

  return (
    <Styled.StyledRouteAnimator data-test="compare-container">
      <Styled.TitleContainer>
        <TopNavPageTitle
          title="Compare"
          alternativeBackButton
          navigateToPage={previousRoute}
          showFilterBar
          customFilter={renderTagsFilterButton()}
        />
      </Styled.TitleContainer>
      <Styled.Container id="compare-container" onScroll={handleScroll}>
        {Object.keys(compareItems).length === 0 ? (
          <Styled.WarningContainer>
            <Typography type="display-xs">No items picked for comparison</Typography>
            <Typography type="text-md">Please go back to the previous page</Typography>
            <Button onClick={() => navigate(-1)} size="md">
              Go back
            </Button>
          </Styled.WarningContainer>
        ) : undefined}
        {Object.keys(compareItems).length !== 0 ? (
          <Styled.OuterContainer>
            <Styled.InnerContainer>
              <Styled.VideosContainer data-test="videos-container">
                {assets.map((asset, index) => (
                  <Styled.VideoContainer key={asset.id || index}>
                    <VideoModule
                      reference={index === 0}
                      videoUrl={asset.type === 'concept' ? conceptThumbnails[asset.id] : asset.previewUrl ?? ''}
                      name={
                        asset.type === 'concept' ? asset.displayName ?? asset.name : asset.name ?? asset.appPublisher
                      }
                      percentageValue={percentageValue(asset)}
                      metrics={metrics(asset, index)}
                      date={asset.type === 'concept' ? undefined : asset.launchDate}
                      noCustomMetrics={asset.type !== 'concept'}
                      noPercentage={asset.type !== 'concept' && index === 0}
                      onChangeRadioButton={() => {
                        setReference(asset);
                        resetVideos();
                        logEvent({
                          component: 'Compare',
                          action: 'Change reference',
                          category: 'user_actions',
                        });
                      }}
                      onClickEyeIcon={() => {
                        logEvent({
                          component: 'Compare',
                          action: 'Hide video',
                          category: 'user_actions',
                        });
                        resetVideo(asset.id);
                        setHiddenAssets([
                          ...hiddenAssets,
                          asset.type === 'concept'
                            ? {
                                id: asset.id,
                                name: asset.displayName ?? asset.name,
                                spend: SDK.kpiUtils.format(SDK.Metrics.SPEND, asset.spend),
                                isActive: isCreativeActive(asset.maxDate, new Date().toISOString()),
                              }
                            : {
                                id: asset.id,
                                name: asset.name,
                                isActive: true,
                                launchDate: asset.launchDate,
                              },
                        ]);
                      }}
                      onProgress={({ playedSeconds: updatedPlayedSeconds, played: updatedPlayed }) => {
                        setPlayersProgress((prevState) => ({
                          ...prevState,
                          [asset.id]: { playedSeconds: updatedPlayedSeconds, played: updatedPlayed },
                        }));
                      }}
                      onPlay={() => onPlay(asset.id)}
                      onPause={() => {
                        const ended =
                          playerRefs.current[asset.id].getDuration() === playerRefs.current[asset.id].getCurrentTime();
                        onPause(asset.id, ended);
                      }}
                      onEnded={() =>
                        setHasVideoEnded((prevState) => ({
                          ...prevState,
                          [asset.id]: true,
                        }))
                      }
                      player={(element) => {
                        playerRefs.current[asset.id] = element as ReactPlayer;
                      }}
                      isPlaying={isPlayersPlaying[asset.id]}
                      isActive={
                        asset.type === 'concept'
                          ? isCreativeActive(asset.maxDate, new Date().toISOString())
                          : asset.isActive
                      }
                      showMetrics={!isScrollingDown}
                    />
                  </Styled.VideoContainer>
                ))}
              </Styled.VideosContainer>
              <Styled.TimelineContainer>
                <Styled.Background>
                  {assets.map((a, i) => (
                    <Styled.Col key={a.id || i} index={i} />
                  ))}
                </Styled.Background>
                <TagsTimeline
                  data={filteredData}
                  assetIds={visibleAssetIds}
                  playersProgress={playersProgress}
                  visibleTags={visibleTags}
                  isLoading={isLoading || isTagsLoading}
                  hasMarketData={marketAssets.length > 0}
                  onSeekToPoint={onSeekToPoint}
                />
              </Styled.TimelineContainer>
            </Styled.InnerContainer>
            {hiddenAssets && hiddenAssets.length > 0 ? (
              <Styled.StyledVideoSidebar
                items={hiddenAssets}
                onClickEyeIcon={(item) => {
                  logEvent({
                    component: 'Compare',
                    action: 'Show video',
                    category: 'user_actions',
                  });
                  setHiddenAssets(hiddenAssets.filter((a) => a.id !== item.id));
                }}
              />
            ) : undefined}
          </Styled.OuterContainer>
        ) : undefined}
      </Styled.Container>
      <CustomizeVisibleTags
        open={openCustomizeVisibleTags}
        genreTagTypes={genresData ?? []}
        onClose={() => {
          setOpenCustomizeVisibleTags(false);
        }}
        onVisibleTagsChange={onVisibleTagsChange}
      />
    </Styled.StyledRouteAnimator>
  );
};

export default CompareView;
