/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  ChangeEventHandler,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useUpdateEffect } from 'react-use';
import Colors from '../Colors';
import '../common.module.css';
import Tooltip from '../Tooltip/Tooltip';

import * as Styled from './styles';

interface ToggleProps {
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  labelPosition?: 'start' | 'end';
  secondaryLabel?: string;
  onChange?: (checked: boolean) => void;
  tooltipContent?: ReactNode;
  variant?: 'regular' | 'outlined';
}

function renderToggleButton({
  isChecked,
  handleChange,
  disabled,
  toggleChecked,
  isFocused,
  setIsFocused,
  isHovering,
  setIsHovering,
  label,
}: {
  isChecked: boolean;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  toggleChecked: () => void;
  isFocused: boolean;
  setIsFocused: Dispatch<SetStateAction<boolean>>;
  isHovering: boolean;
  setIsHovering: Dispatch<SetStateAction<boolean>>;
  label?: string;
}) {
  return (
    <Styled.ToggleHolder key="toggle-button">
      <Styled.Input
        type="checkbox"
        role="checkbox"
        checked={isChecked}
        onChange={handleChange}
        disabled={disabled}
        aria-label={label}
      />
      <Styled.Slider
        type="button"
        $checked={isChecked}
        onClick={disabled ? undefined : toggleChecked}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        $backgroundColor={
          disabled
            ? Colors.Gray[100]
            : isChecked
            ? Colors.Primary[600]
            : isHovering
            ? Colors.Gray[400]
            : Colors.Gray[200]
        }
        $boxShadow={
          isFocused && !disabled ? `0 0 0 4px ${Colors.Primary[100]}` : ''
        }
        $cursor={disabled ? 'not-allowed' : 'pointer'}
      />
    </Styled.ToggleHolder>
  );
}

function renderLabel({
  label,
  secondaryLabel,
}: {
  label?: string;
  secondaryLabel?: string;
}) {
  return (
    <Styled.LabelHolder key="toggle-label">
      {(label || secondaryLabel) && <Styled.Label>{label}</Styled.Label>}
      {secondaryLabel && (
        <>
          <div />
          <Styled.SecondaryLabel>{secondaryLabel}</Styled.SecondaryLabel>
        </>
      )}
    </Styled.LabelHolder>
  );
}

const Toggle = ({
  checked,
  disabled,
  label,
  labelPosition,
  secondaryLabel,
  onChange,
  tooltipContent,
  variant,
}: ToggleProps) => {
  const [isChecked, setIsChecked] = useState(checked || false);
  const [isFocused, setIsFocused] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (checked !== undefined) {
      setIsChecked(checked);
    }
  }, [checked]);

  useUpdateEffect(() => {
    onChange?.(isChecked);
  }, [isChecked]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsChecked(e.target.checked);
  };

  const toggleChecked = () => {
    setIsChecked(!isChecked);
  };

  const toggleButtonElement = renderToggleButton({
    isChecked,
    handleChange,
    disabled,
    toggleChecked,
    isFocused,
    setIsFocused,
    isHovering,
    setIsHovering,
    label,
  });

  const labelElement = renderLabel({
    label,
    secondaryLabel,
  });

  return (
    <Tooltip content={tooltipContent}>
      <Styled.Root data-test="toggle-switch">
        <Styled.Switch $isOutlined={variant === 'outlined'}>
          {labelPosition === 'start'
            ? [labelElement, toggleButtonElement]
            : [toggleButtonElement, labelElement]}
        </Styled.Switch>
      </Styled.Root>
    </Tooltip>
  );
};

export default Toggle;
export type { ToggleProps };
