import { IconProps } from './types';

const Shuffle = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 3H21M21 3V8M21 3L4 20M21 16V21M21 21H16M21 21L15 15M4 4L9 9"
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default Shuffle;
