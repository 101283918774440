/* eslint-disable react/jsx-props-no-spreading */
import { SocialIconProps } from './types';

const Singular = ({ dimension, ...props }: SocialIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1080 1080"
    width={dimension || 24}
    height={dimension || 24}
  >
    <g transform="matrix(33.44 0 0 33.44 2610.63 523.98)">
      <g
        vector-effect="non-scaling-stroke"
        style={{
          stroke: 'none',
          fill: 'rgb(62,148,229)',
          opacity: 1,
        }}
      >
        <g transform="matrix(1 0 0 1 -57.69 -7.29)">
          <path
            vector-effect="non-scaling-stroke"
            transform=" translate(-39.1, -53.74)"
            d="M 43 61.5 C 42.6 54.4 39.4 49.6 33.3 46.4 C 34.8 45.699999999999996 36.3 46 37.599999999999994 46.3 C 44.599999999999994 48 45.39999999999999 49.599999999999994 44.699999999999996 56.9 C 44.7 58.4 44.1 59.9 43 61.5 z"
            stroke-linecap="round"
          />
        </g>
        <g transform="matrix(1 0 0 1 -57.35 9.99)">
          <path
            vector-effect="non-scaling-stroke"
            transform=" translate(-39.45, -71.02)"
            d="M 48.3 67.2 C 46.8 70.8 44.3 73.2 40.8 74.7 C 40.599999999999994 74.8 40.199999999999996 74.9 40 74.8 C 36.1 73.89999999999999 32.8 71.89999999999999 30.6 68.3 C 36.8 71.1 42.6 70.7 48.3 67.2 z"
            stroke-linecap="round"
          />
        </g>
        <g transform="matrix(1 0 0 1 -52.53 -0.63)">
          <path
            vector-effect="non-scaling-stroke"
            transform=" translate(-44.27, -60.4)"
            d="M 38.8 68.3 C 44.5 64.5 47.199999999999996 59.3 46.8 52.5 C 49.199999999999996 55.6 50.099999999999994 59.1 49.599999999999994 62.9 C 49.599999999999994 63.199999999999996 49.3 63.5 49.099999999999994 63.8 C 46.3 66.7 42.9 68.3 38.8 68.3 z"
            stroke-linecap="round"
          />
        </g>
        <g transform="matrix(1 0 0 1 -66.6 -9.81)">
          <path
            vector-effect="non-scaling-stroke"
            transform=" translate(-30.2, -51.22)"
            d="M 38.9 53.9 C 32.9 51 27.2 51.4 21.5 55.1 C 21.7 53.4 22.7 52.2 23.6 51.300000000000004 C 28.5 46.300000000000004 30.6 46.00000000000001 36.5 50.6 C 37.5 51.5 38.4 52.5 38.9 53.9 z"
            stroke-linecap="round"
          />
        </g>
        <g transform="matrix(1 0 0 1 -71.17 0.59)">
          <path
            vector-effect="non-scaling-stroke"
            transform=" translate(-25.63, -61.62)"
            d="M 31.3 53.8 C 25.1 57.4 22.700000000000003 62.599999999999994 23.1 69.5 C 21.6 68.3 21.200000000000003 67 20.8 65.7 C 19.1 59.400000000000006 19.6 57.6 25.9 54.800000000000004 C 27.4 54 29.1 53.6 31.3 53.8 z"
            stroke-linecap="round"
          />
        </g>
        <g transform="matrix(1 0 0 1 -65.79 7.28)">
          <path
            vector-effect="non-scaling-stroke"
            transform=" translate(-31.01, -68.31)"
            d="M 37.1 75.8 C 33.4 76.2 30.6 75.6 27.8 74.2 C 26.2 73.4 25.3 72.3 25.1 70.5 C 24.700000000000003 67.2 24.8 64.1 26.900000000000002 60.7 C 26.8 68 30.4 72.7 37.1 75.8 z"
            stroke-linecap="round"
          />
        </g>
        <g transform="matrix(1 0 0 1 -22.34 -12.28)">
          <path
            vector-effect="non-scaling-stroke"
            transform=" translate(-74.45, -48.75)"
            d="M 74.4 51.2 C 73.10000000000001 51.2 72 50.1 72 48.7 C 72 47.300000000000004 73 46.300000000000004 74.4 46.300000000000004 C 75.9 46.300000000000004 77 47.400000000000006 76.9 48.800000000000004 C 76.9 50.1 75.7 51.2 74.4 51.2 z"
            stroke-linecap="round"
          />
        </g>
        <g transform="matrix(1 0 0 1 -20.7 -7.48)">
          <path
            vector-effect="non-scaling-stroke"
            transform=" translate(-76.1, -53.55)"
            d="M 76.3 53.7 C 76.2 53.6 76.1 53.5 75.89999999999999 53.400000000000006 C 76.2 53.4 76.3 53.5 76.3 53.7 z"
            stroke-linecap="round"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default Singular;
