import * as SDK from '@replai-platform/sdk';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeature } from '@optimizely/react-sdk';
import { useTitle } from 'react-use';
import isEqual from 'lodash/isEqual';
import { KpiFilterProps } from '../../components/FilterBar/Filters/KpiFilter';
import { NetworkFilterProps } from '../../components/FilterBar/Filters/NetworkFilter';
import { PromotedObjectFilterProps } from '../../components/FilterBar/Filters/PromotedObjectFilter';
import { TaggedWithFilterProps } from '../../components/FilterBar/Filters/TaggedWithFilter';
import useKpiFilter from '../../components/FilterBar/hooks/useKpiFilter';
import useNetworkFilter from '../../components/FilterBar/hooks/useNetworkFilter';
import usePromotedObjectFilter from '../../components/FilterBar/hooks/usePromotedObjectFilter';
import useTaggedWithFilter from '../../components/FilterBar/hooks/useTaggedWithFilter';
import VerticalMenuPageLayout from '../../components/VerticalMenuPageLayout';
import TopNavPageTitle from '../../components/TopNavPageTitle';
import useFetchInsights from '../../hooks/useFetchInsights';
import { FilterActions } from '../../store/filters';
import { InsightsActions } from '../../store/insights';
import { RootState } from '../../store/rootReducer';
import { capitalizeFirstLetter } from '../../utils';
import { Page } from '../../utils/enums';
import { logEvent } from '../../analytics';
import { RouteAnimator } from '../RouteAnimator';
import InsightsExplorer from './InsightsExplorer';
import buildInsightsSubmenuProps from './InsightsSubmenu';
import useCountryFilter from '../../components/FilterBar/hooks/useCountryFilter';
import { CountryFilterProps } from '../../components/FilterBar/Filters/CountryFilter';
import { PositiveInsightsToggleProps, IntroInsightsToggleProps } from '../../components/FilterBar/Filters';

const Insights: React.VFC = () => {
  const dispatch = useDispatch();
  const projectName = useSelector((state: RootState) => state.project.name);
  const projectNetworks = useSelector((state: RootState) => state.project.networks);

  let kpis = useSelector((state: RootState) => state.filters.kpis);
  kpis = kpis.filter((kpi) => !SDK.BLACKLIST_KPIS.includes(kpi));

  const contextTags = useSelector((state: RootState) => state.insights.filters.contextTags);
  const campaigns = useSelector((state: RootState) => state.insights.filters.campaigns);
  const countries = useSelector((state: RootState) => state.insights.filters.countries);

  const positiveInsightsOnly = useSelector((state: RootState) => state.insights.filters.positiveInsightsOnly);
  const introInsights = useSelector((state: RootState) => state.insights.filters.introInsights);

  const promotedObjectTypes = useSelector((state: RootState) => state.filters.promotedObjectTypesToConsider);

  const [isTechmerc1569Enabled] = useFeature('techmerc-1569'); // Renaming Tags Filter

  const insights = useSelector((state: RootState) => state.insights?.insights);
  const [selectedTagType, setSelectedTagType] = useState('');

  useTitle(`${capitalizeFirstLetter(Page.Insights)} - ${projectName}`);

  // Retrieves insights
  const {
    insightsData,
    isInsightsDataLoading: isInsightsLoading,
    tagsPreviewData: tagPreviews,
    isTagsPreviewDataLoading: isTagPreviewsLoading,
  } = useFetchInsights({
    kpis,
    campaigns,
    tagsToConsider: contextTags,
    countries,
    positiveInsightsOnly,
    introInsights,
    promotedObjectTypes,
  });

  useEffect(() => {
    if (projectNetworks && insightsData) {
      dispatch(InsightsActions.addFilteredInsights(insightsData));
    }
  }, [dispatch, JSON.stringify(insightsData), projectNetworks]);

  useEffect(() => {
    if (projectNetworks) {
      dispatch(InsightsActions.setTagsPreviewData(tagPreviews ?? { tags: [] }));
      dispatch(InsightsActions.setTagsPreviewLoading(isTagPreviewsLoading));
    }
  }, [dispatch, tagPreviews, isTagPreviewsLoading, projectNetworks]);

  // Build network filter
  const {
    networkFilterOptions,
    networkFilterOnChange,
    isLoading: isNetworkFilterLoading,
  } = useNetworkFilter(Page.Insights);
  const networkFilter = useMemo(
    () =>
      ({
        options: networkFilterOptions,
        onChange: networkFilterOnChange,
        loading: isNetworkFilterLoading,
      } as NetworkFilterProps),
    [networkFilterOptions, networkFilterOnChange, isNetworkFilterLoading]
  );

  // Build promoted object types filter
  const { promotedObjectTypeFilterOptions, promotedObjectTypeFilterOnChange } = usePromotedObjectFilter(Page.Insights);
  const promotedObjectFilter = useMemo(
    () =>
      ({
        options: promotedObjectTypeFilterOptions,
        onChange: promotedObjectTypeFilterOnChange,
      } as PromotedObjectFilterProps),
    [promotedObjectTypeFilterOptions, promotedObjectTypeFilterOnChange]
  );

  // Build KPI Filter
  const kpiFilterOptions = useKpiFilter({ blacklistKpis: SDK.BLACKLIST_KPIS });
  const kpiFilter = useMemo(
    () =>
      ({
        options: kpiFilterOptions,
        onChange: (options) => {
          const selectedOptions = options
            .filter(({ isAllOption }) => !isAllOption)
            .filter(({ selected }) => selected)
            // We need to use the 'id'. If we use a transformed value, type conversion won't be
            // possible (e.g: roasday7 cannot be converted to SDK.MetricKPIWithSpend!)
            .map(({ id }) => id as SDK.MetricKPIWithSpend);

          if (!isEqual(selectedOptions, kpis)) {
            dispatch(
              FilterActions.changeKPIs({
                value: selectedOptions,
                logEvent: true,
                page: Page.Insights,
              })
            );
          }
        },
        dropdownType: 'multiselect',
      } as KpiFilterProps),
    [kpis, kpiFilterOptions, dispatch]
  );

  // Build tagged with filter
  const {
    taggedWithFilterOptions,
    hasData: taggedWithFilterHasData,
    isLoading: isTaggedWithFilterLoading,
  } = useTaggedWithFilter();
  const taggedWithFilter = useMemo(
    () =>
      ({
        options: taggedWithFilterOptions,
        onChange: (options) => {
          const isAllSelected = options.some(({ isAllOption, selected }) => isAllOption && selected);

          dispatch(
            InsightsActions.setFilter({
              key: 'contextTags',
              value: isAllSelected
                ? []
                : options
                    .filter(({ isAllOption }) => !isAllOption)
                    .filter(({ selected }) => selected)
                    .map(({ id }) => id)
                    .filter((id): id is string => id !== undefined),
            })
          );
        },
        loading: isTaggedWithFilterLoading,
      } as TaggedWithFilterProps),
    [taggedWithFilterOptions, dispatch, isTaggedWithFilterLoading]
  );

  // Build country filter
  const {
    countryFilterOptions,
    isLoading: countryFilterIsLoading,
    setIsInitialFilter,
    isInitialFilter,
  } = useCountryFilter({
    selectedCountries: countries,
  });
  const countryFilter = useMemo(
    () =>
      ({
        options: countryFilterOptions,
        onChange: (options) => {
          dispatch(
            InsightsActions.setFilter({
              key: 'countries',
              value: options
                .filter(({ isAllOption }) => !isAllOption)
                .filter(({ selected }) => selected)
                .map(({ id }) => id)
                .filter((id): id is string => id !== undefined),
              logEvent: !isInitialFilter,
            })
          );

          setIsInitialFilter(false);
        },
        loading: countryFilterIsLoading,
      } as CountryFilterProps),
    [dispatch, countryFilterIsLoading, countryFilterOptions, isInitialFilter, setIsInitialFilter]
  );

  const insightsSubmenuProps = buildInsightsSubmenuProps({
    isTechmerc1569Enabled,
    insights,
    selectedTagType,
    setSelectedTagType,
    isLoading: isInsightsLoading,
    onSearch: (search) =>
      logEvent({
        component: 'Insights',
        action: `Search Category`,
        category: 'user_actions',
        parameters: { search, page: Page.Insights },
      }),
  });

  const positiveInsightsToggle: PositiveInsightsToggleProps = useMemo(
    () => ({
      checked: positiveInsightsOnly,
      label: 'Positive insights only',
      onChange: (value) => {
        dispatch(
          InsightsActions.setFilter({
            key: 'positiveInsightsOnly',
            logEvent: true,
            value,
          })
        );
      },
    }),
    [positiveInsightsOnly]
  );

  const [isTechAth1416Enabled] = useFeature('techath-1416'); // have intro insights toggle
  const introInsightsToggle: IntroInsightsToggleProps = useMemo(
    () => ({
      checked: introInsights,
      label: 'Intro insights',
      onChange: (value) => {
        dispatch(
          InsightsActions.setFilter({
            key: 'introInsights',
            logEvent: true,
            value,
          })
        );
      },
    }),
    [introInsights]
  );

  return (
    <RouteAnimator data-test="insights-container">
      <VerticalMenuPageLayout
        verticalMenuProps={insightsSubmenuProps}
        header={
          <TopNavPageTitle
            title={`${capitalizeFirstLetter(Page.Insights)}`}
            subtitle="Create your next winning idea with the help of our AI based insights."
            showFilterBar
            networkFilter={networkFilter}
            promotedObjectFilter={promotedObjectFilter}
            kpiFilter={kpiFilter}
            taggedWithFilter={taggedWithFilterHasData ? taggedWithFilter : undefined}
            countryFilter={countryFilter}
            positiveInsightsToggle={positiveInsightsToggle}
            introInsightsToggle={isTechAth1416Enabled ? introInsightsToggle : undefined}
          />
        }
      >
        <InsightsExplorer
          type={selectedTagType}
          setSelectedTagType={setSelectedTagType}
          isLoading={isInsightsLoading}
        />
      </VerticalMenuPageLayout>
    </RouteAnimator>
  );
};

export default Insights;
