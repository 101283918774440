import * as SDK from '@replai-platform/sdk';
import camelCase from 'lodash/camelCase';
import Colors, { type Color } from '../Colors';
import * as Icons from '../Icons';
import Skeleton from '../Skeleton/Skeleton';
import Tooltip from '../Tooltip/Tooltip';
import Typography from '../Typography/Typography';
import VideoTimeline from '../VideoTimeline/VideoTimeline';
import * as Styled from './styles';

export type TooltipVideo = {
  url: URL | string;
  timeframes?: (SDK.Timeframe | SDK.ParsedAnnotationTimeframe)[];
};

const renderRecommendationTitle = (
  recommendation: SDK.CreativeRecommendation
) => {
  switch (recommendation.name.type) {
    case SDK.RecommendationType.CREATIVE_REPLACE_TAG:
    case SDK.RecommendationType.CREATIVE_REPLACE_TAG_INTRO: {
      return (
        <Typography type="text-sm" noMargin style={{ display: 'inline-flex' }}>
          Replace&nbsp;
          <Typography type="text-sm" fontWeight="bold" noMargin>
            {recommendation.name.previous.value}
          </Typography>
          &nbsp;by&nbsp;
          <Typography type="text-sm" fontWeight="bold" noMargin>
            {recommendation.name.new.value}
          </Typography>
        </Typography>
      );
    }
    case SDK.RecommendationType.CREATIVE_ADD_TAG:
      return (
        <Typography type="text-sm" fontWeight="bold" noMargin>
          Add
        </Typography>
      );
    case SDK.RecommendationType.CREATIVE_ADD_TAG_INTRO:
      return (
        <Typography type="text-sm" fontWeight="bold" noMargin>
          Add to first 5 seconds
        </Typography>
      );
    case SDK.RecommendationType.CREATIVE_REMOVE_TAG:
    case SDK.RecommendationType.CREATIVE_REMOVE_TAG_INTRO:
      return (
        <Typography type="text-sm" fontWeight="bold" noMargin>
          Remove
        </Typography>
      );
    default:
      throw new Error('Unknown recommendation type');
  }
};

const renderIcon = (
  recommendationType: SDK.RecommendationType
): JSX.Element => {
  switch (recommendationType) {
    case SDK.RecommendationType.CREATIVE_REPLACE_TAG:
    case SDK.RecommendationType.CREATIVE_REPLACE_TAG_INTRO: {
      const Icon = Icons.getBaseIcon('Repeat');
      return <Icon color={Colors.Primary[500]} />;
    }
    case SDK.RecommendationType.CREATIVE_ADD_TAG:
    case SDK.RecommendationType.CREATIVE_ADD_TAG_INTRO: {
      const Icon = Icons.getBaseIcon('Plus');
      return <Icon color={Colors.Success[500]} />;
    }
    case SDK.RecommendationType.CREATIVE_REMOVE_TAG:
    case SDK.RecommendationType.CREATIVE_REMOVE_TAG_INTRO: {
      const Icon = Icons.getBaseIcon('Trash');
      return <Icon color={Colors.Error[500]} />;
    }
    default:
      throw new Error('Unknown recommendation type');
  }
};

type TimelineTooltipContentProps = Omit<TimelineTooltipProps, 'onOpen'>;

const TooltipContent: React.VFC<TimelineTooltipContentProps> = ({
  tag,
  videos,
  recommendation,
  tagRowColor,
}) => {
  const isReplaceTag = recommendation.name.type.includes('REPLACE_TAG');
  const numVideos = isReplaceTag ? 2 : 1;
  return (
    <Styled.Container>
      <Styled.Header borderLeftColor={tagRowColor}>
        <Typography fontWeight="semi-bold" type="text-lg" noMargin>
          {tag.value}
        </Typography>
        <Typography type="text-lg" color={Colors.Gray[500]} noMargin>
          {tag.type}
        </Typography>
      </Styled.Header>
      <Styled.Body>
        <Styled.RecommendationContainer>
          <Styled.RecommendationTypeIconContainer>
            <Styled.RecommendationTypeIconInnerContainer
              recommendationType={recommendation.name.type}
            >
              {renderIcon(recommendation.name.type)}
            </Styled.RecommendationTypeIconInnerContainer>
          </Styled.RecommendationTypeIconContainer>
          <Styled.RecommendationInfoContainer>
            {renderRecommendationTitle(recommendation)}
            <Typography type="text-sm" color={Colors.Gray[500]} noMargin>
              {tag.type}
            </Typography>
          </Styled.RecommendationInfoContainer>
        </Styled.RecommendationContainer>
        <Styled.TimelineContainer>
          {videos && videos.length > 0
            ? videos.slice(0, numVideos).map((video, i) => (
                <Styled.VideoTimelineContainer
                  key={`${video.url.toString()}-${i}`}
                >
                  <VideoTimeline video={video} />
                </Styled.VideoTimelineContainer>
              ))
            : Array(numVideos)
                .fill(true)
                .map((_, i) => (
                  <Styled.VideoTimelineContainer key={i}>
                    <Skeleton width={172} height={240} />
                  </Styled.VideoTimelineContainer>
                ))}
        </Styled.TimelineContainer>
      </Styled.Body>
    </Styled.Container>
  );
};

export interface TimelineTooltipProps {
  tag: {
    type: string;
    value: string | null;
  };
  videos: TooltipVideo[];
  recommendation: SDK.CreativeRecommendation;
  tagRowColor: Color;
  onOpen: (
    isOpen: boolean,
    tags: { type: string; value: string | null }[]
  ) => void;
}

const TimelineTooltip: React.FC<TimelineTooltipProps> = ({
  tag,
  videos,
  recommendation,
  tagRowColor,
  children,
  onOpen,
}) => {
  return (
    <Tooltip
      placement="right"
      enableMaxContent
      disablePadding
      onOpenChange={(isOpen) =>
        onOpen(isOpen, [
          {
            type: camelCase(tag.type),
            value: tag.value,
          },
          ...(recommendation.name.type ===
            SDK.RecommendationType.CREATIVE_REPLACE_TAG ||
          recommendation.name.type ===
            SDK.RecommendationType.CREATIVE_REPLACE_TAG_INTRO
            ? [
                {
                  type: camelCase(tag.type),
                  value: recommendation.name.new.value,
                },
              ]
            : []),
        ])
      }
      content={
        <TooltipContent
          tag={tag}
          videos={videos}
          recommendation={recommendation}
          tagRowColor={tagRowColor}
        />
      }
    >
      {children}
    </Tooltip>
  );
};

export default TimelineTooltip;
