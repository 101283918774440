import { CountryIconProps } from './types';

const TV = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37263 24 0 18.6274 0 12C0 12.0029 12 0.0013125 12 0C18.6274 0 24 5.37262 24 12Z"
      fill="#338AF3"
    />
    <path
      d="M5.73926 12.0012H9.78654L6.26098 7.82727L5.73926 9.39247V12.0012Z"
      fill="#0052B4"
    />
    <path
      d="M12.0001 9.78671L7.82617 5.73938H12.0001L12.0001 9.78671Z"
      fill="#0052B4"
    />
    <path
      d="M11.9678 11.9996H11.9999C11.9999 11.9888 11.9999 11.9783 11.9999 11.9674C11.9892 11.9782 11.9785 11.9889 11.9678 11.9996Z"
      fill="#F0F0F0"
    />
    <path
      d="M12 6.26123C12 4.14974 12 2.76618 12 0.000366211H11.998C5.3715 0.00149121 0 5.3736 0 12.0004H6.26091V8.47485L9.78642 12.0004H11.968C11.9787 11.9897 11.9894 11.979 12.0001 11.9683C12.0001 11.1598 12.0001 10.4384 12.0001 9.78683L8.47444 6.26123H12Z"
      fill="#F0F0F0"
    />
    <path
      d="M6.07124 1.56543C4.19441 2.63413 2.63413 4.19441 1.56543 6.07124V12.0002H4.69588V4.69598V4.69588H12.0002C12.0002 3.70856 12.0002 2.76796 12.0002 1.56543H6.07124Z"
      fill="#D80027"
    />
    <path
      d="M12.0009 10.5242L7.73735 6.26074H6.26172V6.26084L12.0008 11.9998H12.0009C12.0009 11.9998 12.0009 10.9823 12.0009 10.5242Z"
      fill="#D80027"
    />
    <path
      d="M13.0972 19.8252L13.3562 20.6224H14.1944L13.5163 21.115L13.7753 21.9121L13.0972 21.4195L12.4191 21.9121L12.6781 21.115L12 20.6224H12.8381L13.0972 19.8252Z"
      fill="#FFDA44"
    />
    <path
      d="M19.773 14.6094L20.032 15.4065H20.8701L20.1921 15.8992L20.4512 16.6963L19.773 16.2037L19.0949 16.6963L19.354 15.8992L18.6758 15.4065H19.5139L19.773 14.6094Z"
      fill="#FFDA44"
    />
    <path
      d="M21.4448 6.78259L21.7038 7.57975H22.542L21.864 8.07241L22.123 8.86951L21.4448 8.3769L20.7668 8.86951L21.0258 8.07241L20.3477 7.57975H21.1858L21.4448 6.78259Z"
      fill="#FFDA44"
    />
    <path
      d="M21.8599 12.5215L22.1189 13.3187H22.9571L22.279 13.8113L22.538 14.6085L21.8599 14.1158L21.1819 14.6085L21.4409 13.8113L20.7627 13.3187H21.6009L21.8599 12.5215Z"
      fill="#FFDA44"
    />
    <path
      d="M14.299 17.9513L15.0459 18.3319L15.6386 17.7393L15.5075 18.567L16.2543 18.9475L15.4264 19.0787L15.2953 19.9066L14.9148 19.1597L14.0869 19.2909L14.6796 18.6983L14.299 17.9513Z"
      fill="#FFDA44"
    />
    <path
      d="M14.299 14.8215L15.0459 15.202L15.6386 14.6094L15.5075 15.4372L16.2543 15.8177L15.4264 15.9488L15.2953 16.7767L14.9148 16.0299L14.0869 16.161L14.6796 15.5684L14.299 14.8215Z"
      fill="#FFDA44"
    />
    <path
      d="M16.9084 16.8272L17.6553 17.2078L18.2479 16.6151L18.1169 17.4429L18.8637 17.8234L18.0357 17.9546L17.9046 18.7824L17.5242 18.0355L16.6963 18.1668L17.2889 17.5741L16.9084 16.8272Z"
      fill="#FFDA44"
    />
    <path
      d="M16.9084 10.1248L17.6553 10.5054L18.2479 9.91272L18.1169 10.7405L18.8637 11.121L18.0357 11.2522L17.9046 12.08L17.5242 11.3332L16.6963 11.4644L17.2889 10.8717L16.9084 10.1248Z"
      fill="#FFDA44"
    />
    <path
      d="M19.4367 10.0445L20.1836 10.425L20.7763 9.8324L20.6451 10.6602L21.392 11.0407L20.5641 11.1718L20.4329 11.9997L20.0525 11.2529L19.2246 11.3841L19.8172 10.7914L19.4367 10.0445Z"
      fill="#FFDA44"
    />
  </svg>
);
export default TV;
