/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import { Card } from '@replai-platform/ui-components';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../../../../../../api';
import useConceptPerformance from '../../../../../../api/hooks/concepts/useConceptPerformance';
import CustomizeFieldsButton from '../../../../../../components/CustomizeFieldsButton';
import { NetworksPerformance } from '../../../../../../components/NetworksPerformance';
import { RootState } from '../../../../../../store/rootReducer';
import { Columns } from '../../../../../../utils/enums';
import getUserSelectedColumns from '../../../../../../utils/getUserSelectedColumns';
import { CardHeader, CardHeaderText, TypographyClean } from '../../../../../../utils/styles';
import {
  ALLOWED_COLUMNS_ON_NETWORK_CONTEXT_PERFORMANCE_TABLE,
  NON_CUSTOMIZABLE_COLUMNS,
  getOrderByCondition,
} from '../../../../../../utils/tables';
import type { ConceptData } from '../../../../../../utils/types';
import { CardTable } from '../../shared/styles';
import { CUSTOMIZE_DIALOG_SUBTITLE, CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP } from '../../../../../../utils/constants';

const ContextPerformance: React.VFC<{
  conceptData: ConceptData;
}> = ({ conceptData }) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const filtering = useSelector((state: RootState) => state.filters);
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes);

  const userColumnsState = useSelector(
    (state: RootState) => state.project.userProject.uiPreferences?.videos?.columns as Columns[]
  );

  // TODO(MERC-1555): cleanup
  const userColumns = useMemo(() => {
    if (projectId !== '248ca9eb-6f5b-4106-b52e-cc3c6d96436b') {
      return userColumnsState;
    }
    return userColumnsState ?? [Columns.Installs, Columns.AGE];
  }, [userColumnsState, projectId]);

  const userSelectedColumns: Columns[] = useMemo(
    () => getUserSelectedColumns({ userColumns, projectKpis, projectAccountTypes }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectKpis, projectAccountTypes, JSON.stringify(userColumns)]
  );

  const kpis = useMemo(
    () => userSelectedColumns?.map((c) => c.split('.')?.[1] ?? '')?.filter((c) => c && c !== SDK.Metrics.AGE),
    [userSelectedColumns]
  );

  const conceptPerformanceParams = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: kpis as SDK.MetricOrKpi[],
      orderBy: getOrderByCondition({ id: 'metrics.spend', desc: true }),
      adsFilters: api.filterConverter.getAdsFilters(filtering),
      assetFilters: api.filterConverter.getAssetFilters(filtering),
      metricsFilters: api.filterConverter.getMetricsFilters(filtering),
      includeTotalsAndAvg: true,
      conceptId: conceptData?.id,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, kpis, JSON.stringify(filtering), conceptData?.id]
  );
  const { isLoading, data: performance } = useConceptPerformance(conceptPerformanceParams, {
    enabled: !!conceptData?.id,
  });

  return (
    <Card fullWidth disableContentPadding>
      <CardHeader data-test="context-performance-videos-header">
        <CardHeaderText>
          <TypographyClean type="text-lg" fontWeight="medium">
            Context Performance
          </TypographyClean>
          <TypographyClean type="text-sm" fontWeight="regular">
            Compare this concept performance against its peers across networks and campaigns
          </TypographyClean>
        </CardHeaderText>
        <CustomizeFieldsButton
          tableColumns={ALLOWED_COLUMNS_ON_NETWORK_CONTEXT_PERFORMANCE_TABLE}
          nonCustomizableColumns={NON_CUSTOMIZABLE_COLUMNS}
          disallowedTooltip={CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP}
          subtitle={CUSTOMIZE_DIALOG_SUBTITLE}
          disabled={false}
          eventPrefix="Videos Context Performance"
        />
      </CardHeader>
      <CardTable>
        <NetworksPerformance
          networksData={performance?.concepts}
          perNetworkPerformance={performance?.networkMetrics}
          isLoading={isLoading}
          kpis={kpis}
        />
      </CardTable>
    </Card>
  );
};

export default ContextPerformance;
