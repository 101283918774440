import styled from 'styled-components';
import Shadow from '../Shadow/Shadow';
import Colors from '../Colors';
import Button from '../Button/Button';
import Typography from '../Typography/Typography';

export const Container = styled(Shadow)`
  display: flex;
  flex-direction: column;
  width: 25rem;
  height: 26.5rem;
  background-color: ${Colors.Common.White};
  border-radius: 8px;
`;

export const Calendar = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.75rem;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
`;

export const YearContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MonthsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
`;

export const MonthButton = styled(Button)`
  width: 10rem;
`;

export const ReferenceButton = styled(Button)`
  padding: 10px 16px;
`;

export const Footer = styled.div`
  height: 4.5rem;
  padding: 1rem 1.5rem;
  border-top: 1px solid ${Colors.Gray[300]};
`;

export const DropDown = styled.div<{
  $position?: string;
  $left?: string;
  $top?: string;
}>`
  z-index: 1;

  position: ${(props) => props.$position ?? 'absolute'};
  top: ${(props) => props.$top};
  left: ${(props) => props.$left};
`;
