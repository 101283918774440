import { useEffect, useState } from 'react';
import Pagination from '../Pagination/Pagination';
import Table, { TableProps } from '../Table/Table';
import Typography from '../Typography/Typography';
import * as Styled from './styles';

type PaginationVariant = 'regular' | 'large';

interface TableWithPaginationProps<D extends object = object>
  extends TableProps<D> {
  onPageUpdate?: (args: { pageIndex: number; pageSize: number }) => void;
  paginationVariant?: PaginationVariant;
  rowsTotalLoading?: boolean;
}

const buildPagination = ({
  pageIndex,
  paginationVariant,
  rowsPerPage,
  rowsTotal,
  setCurrentPageIndex,
}: {
  pageIndex: number;
  paginationVariant: PaginationVariant;
  rowsPerPage: number;
  rowsTotal: number;
  setCurrentPageIndex: (value: React.SetStateAction<number>) => void;
}): JSX.Element => {
  if (paginationVariant === 'large') {
    return (
      <Styled.Pagination
        color="Primary"
        currentPage={pageIndex + 1}
        onPageChange={(pageNumber: number) => {
          setCurrentPageIndex(pageNumber - 1);
        }}
        pageSize={rowsPerPage}
        previousButtonInfo={{
          child: 'Previous',
          buttonIcon: {
            name: 'ArrowLeft',
          },
        }}
        nextButtonInfo={{
          child: 'Next',
          buttonIcon: {
            name: 'ArrowRight',
          },
        }}
        totalCount={rowsTotal}
        variant="outlined"
      />
    );
  }

  return (
    <Pagination
      color="Gray"
      currentPage={pageIndex + 1}
      onPageChange={(pageNumber: number) => {
        setCurrentPageIndex(pageNumber - 1);
      }}
      pageSize={rowsPerPage}
      previousButtonInfo={{
        child: 'Previous',
        buttonIcon: {
          name: 'ArrowLeft',
        },
      }}
      nextButtonInfo={{
        child: 'Next',
        buttonIcon: {
          name: 'ArrowRight',
        },
      }}
      selectedNumerationVariant="outlined-light"
      totalCount={rowsTotal}
      unselectedNumerationVariant="outlined"
      variant="outlined"
      shape="leftRightRounded"
      style={{ float: 'right' }}
      dotsVariant="outlined"
      groupButtons
    />
  );
};

const TableWithPagination = <D extends object = object>({
  columns,
  data,
  loading,
  cellLink,
  onRowProps,
  onSort,
  sorting,
  pageIndex: inputPageIndex,
  rowsPerPage: inputRowsPerPage,
  rowsTotal,
  onPageUpdate,
  emptyStateProps,
  paginationVariant,
  rowsTotalLoading,
  disableAutoExpanderColumn,
  onRowExpand,
}: TableWithPaginationProps<D>) => {
  const hasPagination = !!onPageUpdate;
  const rowsPerPage = inputRowsPerPage || 10;

  const [pageIndex, setCurrentPageIndex] = useState(inputPageIndex ?? 0);

  useEffect(() => {
    setCurrentPageIndex(inputPageIndex ?? 0);
  }, [inputPageIndex, rowsPerPage, setCurrentPageIndex]);

  useEffect(() => {
    onPageUpdate?.({ pageIndex: pageIndex ?? 0, pageSize: rowsPerPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, rowsPerPage]);

  return (
    <div>
      <Table
        columns={columns}
        data={data}
        loading={loading}
        cellLink={cellLink}
        onRowProps={onRowProps}
        onSort={onSort}
        sorting={sorting}
        pageIndex={pageIndex}
        rowsPerPage={rowsPerPage}
        rowsTotal={rowsTotal}
        emptyStateProps={emptyStateProps}
        disableAutoExpanderColumn={disableAutoExpanderColumn}
        onRowExpand={(row) => onRowExpand?.(row)}
      />
      {!loading &&
        !rowsTotalLoading &&
        hasPagination &&
        rowsTotal > rowsPerPage && (
          <Styled.Footer>
            <Styled.TotalText>
              <Typography type="text-sm" noMargin>
                {rowsPerPage * (pageIndex + 1) > rowsTotal
                  ? rowsTotal
                  : rowsPerPage * (pageIndex + 1)}{' '}
                of
              </Typography>
              <Typography type="text-sm" fontWeight="bold" noMargin>
                {rowsTotal}
              </Typography>
            </Styled.TotalText>
            <Styled.PaginationWrapper>
              {buildPagination({
                pageIndex,
                paginationVariant: paginationVariant ?? 'regular',
                rowsPerPage,
                rowsTotal,
                setCurrentPageIndex,
              })}
            </Styled.PaginationWrapper>
          </Styled.Footer>
        )}
    </div>
  );
};

export type { TableWithPaginationProps };

export default TableWithPagination;
