import { IconProps } from './types';

const Volume1 = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.54 8.46C16.4774 9.39764 17.0039 10.6692 17.0039 11.995C17.0039 13.3208 16.4774 14.5924 15.54 15.53M11 5L6 9H2V15H6L11 19V5Z"
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default Volume1;
