import React, { useState } from 'react';
import type { TooltipVideo } from '../TimelineTooltip/TimelineTooltip';
import * as Styled from './styles';
import type { TagBreakdown } from './timeline.types';
import TimelineBody from './TimelineBody/TimelineBody';
import TimelineSidebar from './TimelineSidebar/TimelineSidebar';
import TimelineTimestamps from './TimelineTimestamps/TimelineTimestamps';

export interface TimelineV2Props {
  breakdown: TagBreakdown[];
  projectId?: string;
  clusterId?: string;
  setIsPlaying: (isPlaying: boolean) => void;
  isPlaying: boolean;
  playedSeconds: number;
  playedFraction: number;
  videoDurationInSeconds: number;
  timestampsInSeconds: number[];
  seekToPoint: (point: number) => void;
  loading?: boolean;
  onClickShowOnlyRecommendations?: () => void;
  onShareClick?: () => void;
  onTimestampLineDrag?: () => void;
  loopVideo?: boolean;
  setLoopVideo?: (loop: boolean) => void;
  onRecommendationTooltipOpen: (
    isOpen: boolean,
    tags: { type: string; value: string | null }[]
  ) => void;
  recommendationTooltipVideos: TooltipVideo[];
  recommendationsOnly: boolean;
  withoutTabs: boolean;
  onClickSkipBack?: (point: number) => void;
}

const TimelineV2: React.FC<TimelineV2Props> = ({
  breakdown,
  setIsPlaying,
  isPlaying,
  playedSeconds,
  playedFraction,
  timestampsInSeconds,
  videoDurationInSeconds,
  seekToPoint,
  loading = false,
  onClickShowOnlyRecommendations,
  onShareClick,
  onTimestampLineDrag,
  loopVideo,
  setLoopVideo,
  onRecommendationTooltipOpen,
  recommendationTooltipVideos,
  onClickSkipBack,
  recommendationsOnly = false,
  withoutTabs = false,
}) => {
  const [showOnlyRecommendations, setShowOnlyRecommendations] =
    useState(recommendationsOnly);

  const [hoveredTag, setHoveredTag] = useState<string | null>(null);

  return (
    <Styled.Container>
      <Styled.SidebarContainer>
        <TimelineSidebar
          data={breakdown}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          playedSeconds={playedSeconds}
          showOnlyRecommendations={showOnlyRecommendations}
          setShowOnlyRecommendations={setShowOnlyRecommendations}
          onClickShowOnlyRecommendations={onClickShowOnlyRecommendations}
          onShareClick={onShareClick}
          onClickSkipBack={onClickSkipBack}
          loopVideo={loopVideo}
          setLoopVideo={setLoopVideo}
          onRecommendationTooltipOpen={onRecommendationTooltipOpen}
          recommendationTooltipVideos={recommendationTooltipVideos}
          withoutTabs={withoutTabs}
          hoveredTag={hoveredTag}
          onMouseOver={(id) => setHoveredTag(id)}
        />
      </Styled.SidebarContainer>
      <Styled.BodyContainer>
        <Styled.TimelineTimestampContainer>
          <TimelineTimestamps timestampsInSeconds={timestampsInSeconds} />
        </Styled.TimelineTimestampContainer>
        <Styled.TimelineBodyContainer withPaddingTop={!withoutTabs}>
          <TimelineBody
            data={breakdown}
            videoDurationInSeconds={videoDurationInSeconds}
            playedFraction={playedFraction}
            seekToPoint={seekToPoint}
            recommendationsOnly={showOnlyRecommendations}
            loading={loading}
            onTimestampLineDrag={onTimestampLineDrag}
            showEmptyTracks
            onMouseOver={(id) => setHoveredTag(id)}
            hoveredTag={hoveredTag}
            withoutTabs={withoutTabs}
          />
        </Styled.TimelineBodyContainer>
      </Styled.BodyContainer>
    </Styled.Container>
  );
};

export default TimelineV2;
export type { TagBreakdown };
