import { VerticalMenu, VerticalMenuProps } from '@replai-platform/ui-components';
import { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow: auto;
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2rem 2rem 0rem 2rem;
`;

const RightPanelBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;
  padding: 0rem 2rem 2rem 2rem;
  overflow: auto;
`;

const VerticalMenuPageLayout = ({
  children,
  header,
  verticalMenuProps,
}: {
  children?: ReactNode;
  header?: ReactNode;
  verticalMenuProps: VerticalMenuProps;
}): JSX.Element => (
  <Container>
    {
      // eslint-disable-next-line react/jsx-props-no-spreading
      <VerticalMenu {...verticalMenuProps} />
    }
    <RightPanel>
      <Header>{header}</Header>
      <RightPanelBody>{children}</RightPanelBody>
    </RightPanel>
  </Container>
);

export default VerticalMenuPageLayout;
