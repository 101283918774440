import { CountryIconProps } from './types';

const HT = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C0 5.37262 12 0 12 0C12 0 24 5.37262 24 12Z"
      fill="#A2001D"
    />
    <path
      d="M0 12C0 5.37262 5.37262 0 12 0C18.6274 0 24 5.37262 24 12"
      fill="#0052B4"
    />
    <path
      d="M16.174 15.1305L12.0001 14.6088L7.82617 15.1305V8.86963H16.174V15.1305Z"
      fill="#F0F0F0"
    />
    <path
      d="M12.0001 14.6083C13.1527 14.6083 14.087 13.6739 14.087 12.5213C14.087 11.3687 13.1527 10.4343 12.0001 10.4343C10.8475 10.4343 9.91309 11.3687 9.91309 12.5213C9.91309 13.6739 10.8475 14.6083 12.0001 14.6083Z"
      fill="#0052B4"
    />
    <path
      d="M12.0005 13.5651C12.5768 13.5651 13.044 13.0979 13.044 12.5216C13.044 11.9453 12.5768 11.4781 12.0005 11.4781C11.4242 11.4781 10.957 11.9453 10.957 12.5216C10.957 13.0979 11.4242 13.5651 12.0005 13.5651Z"
      fill="#A2001D"
    />
    <path
      d="M10.4346 9.91333H13.565L11.9998 11.4786L10.4346 9.91333Z"
      fill="#6DA544"
    />
    <path d="M12.522 10.9556H11.4785V14.086H12.522V10.9556Z" fill="#FFDA44" />
    <path
      d="M13.6696 13.7745H10.3304L7.82617 15.1311H16.174L13.6696 13.7745Z"
      fill="#6DA544"
    />
  </svg>
);
export default HT;
