import { IntegrationIconProps } from './types';

const Grooveshark = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="14" fill="#F8591A" />
    <path
      d="M22.4481 18.2072C21.6165 17.8564 20.9234 17.1435 20.4066 16.4214C19.8136 15.5923 19.2062 14.7775 18.5417 14.0047C17.9168 13.2788 17.2523 12.5832 16.5305 11.9547C15.8103 11.327 15.0264 10.73 14.1631 10.3154C13.5844 10.0363 12.9882 9.82649 12.3713 9.64951C12.3014 9.63006 12.113 9.54452 12.0573 9.63046C12.0231 9.6837 12.0493 9.78315 12.0565 9.84198C12.0716 9.96999 12.0867 10.0976 12.1026 10.2251C12.2871 11.7563 12.357 13.3726 11.8816 14.8594C11.6264 15.6607 11.1614 16.393 10.5413 16.9606C10.027 17.4305 9.29324 17.9064 8.5643 17.8724C6.45547 17.7833 7.40187 14.303 7.79954 13.1309C8.99401 9.66855 12.3525 7.2705 15.9994 7.2705C20.7515 7.2705 24.6997 11.2809 24.6691 16.0001C24.6579 17.4025 24.208 18.9495 22.4481 18.2072ZM26.5658 12.9437C25.2415 8.35633 21.0142 5 15.9994 5C10.9855 5 6.75741 8.35633 5.43298 12.9437C4.96023 14.5823 4.87504 16.3183 5.18394 17.9948C6.12237 23.1182 10.6061 27 15.9994 27C21.3936 27 25.8765 23.1182 26.8164 17.9948C27.124 16.318 27.0373 14.5825 26.5658 12.9437Z"
      fill="white"
    />
  </svg>
);
export default Grooveshark;
