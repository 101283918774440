import { Button } from '@replai-platform/ui-components';
import { useState } from 'react';
import styled from 'styled-components';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../../common';

const ButtonContainer = styled.div`
  margin-right: 1rem;
`;

export type ReportFavoriteAccessorType = {
  onReportFavoriteClick?: (
    setButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
    isFavoriteStarFilled: boolean,
    setIsFavoriteStarFilled: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  favorite?: boolean;
};

const ReportFavoriteCellDecorator: React.FC<{
  cellData: ReportFavoriteAccessorType;
}> = ({ cellData }) => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [isFavoriteStarFilled, setIsFavoriteStarFilled] = useState<boolean>(cellData.favorite ?? false);

  if (!cellData) {
    return null;
  }

  return (
    <ButtonContainer>
      <Button
        trailingIcon={{ name: buttonLoading ? 'LoadingCircle' : isFavoriteStarFilled ? 'Star100' : 'Star' }}
        onClick={() => {
          cellData?.onReportFavoriteClick?.(setButtonLoading, isFavoriteStarFilled, setIsFavoriteStarFilled);
        }}
        disabled={buttonLoading}
        color="Warning"
        variant="text"
      />
    </ButtonContainer>
  );
};

function getReportFavoriteColumn<T extends object = ReportFavoriteAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T, ReportFavoriteAccessorType>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<T, ReportFavoriteAccessorType>) => (
      <ReportFavoriteCellDecorator cellData={data?.cell?.value} />
    ),
  };
}

export default getReportFavoriteColumn;
