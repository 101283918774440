import { CountryIconProps } from './types';

const BW = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3579)">
      <path
        d="M0.566625 8.34777C0.199031 9.49944 -4.68667e-05 10.7263 8.276e-09 11.9999C-4.68667e-05 13.2735 0.199031 14.5004 0.566672 15.6521L12 16.1739L23.4333 15.6521C23.8008 14.5004 24 13.2734 24 12C24 10.7264 23.8009 9.49944 23.4333 8.34777L12 7.82605L0.566625 8.34777Z"
        fill="#F0F0F0"
      />
      <path
        d="M24 12.0005C24 11.2886 23.9377 10.5913 23.8187 9.91351H0.181313C0.0623437 10.5913 0 11.2885 0 12.0005C0 12.7124 0.0623906 13.4096 0.181313 14.0874H23.8187C23.9376 13.4096 24 12.7124 24 12.0005Z"
        fill="black"
      />
      <path
        d="M11.9997 23.9997C17.3536 23.9997 21.8878 20.4933 23.433 15.6518H0.566406C2.11164 20.4932 6.64591 23.9996 11.9997 23.9997Z"
        fill="#338AF3"
      />
      <path
        d="M11.9998 -0.00061035C6.64595 -0.00061035 2.11173 3.50578 0.566406 8.34712L23.433 8.34717C21.8878 3.50578 17.3536 -0.0007041 11.9998 -0.00061035Z"
        fill="#338AF3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3579">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default BW;
