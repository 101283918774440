import { IntegrationIconProps } from './types';

const Netflix = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.56 2.08264L18.5472 8.24318L18.5344 14.4037L17.9464 12.9736V12.9699L17.1836 26.6845C17.9336 28.5033 18.3341 29.475 18.3384 29.4787C18.3427 29.4824 18.7688 29.5044 19.2844 29.5264C20.844 29.5924 22.7787 29.7354 24.2488 29.8931C24.5897 29.9297 24.8795 29.9481 24.9008 29.9334C24.9221 29.9187 24.9263 23.6482 24.9221 15.9952L24.9093 2.08264H18.56Z"
      fill="url(#paint0_linear_1334_984)"
    />
    <path
      d="M7.07422 2.06421V15.9915C7.07422 23.6518 7.08274 29.926 7.09553 29.937C7.10831 29.948 7.65802 29.9004 8.32278 29.838C8.98754 29.7757 9.90372 29.695 10.3597 29.662C11.0585 29.6107 13.1551 29.497 13.3937 29.4933C13.4662 29.4933 13.4704 29.1816 13.4789 23.5931L13.4917 17.6929L13.9988 18.9251C14.0755 19.1157 14.1011 19.1744 14.1778 19.3614L14.9406 5.65053C14.7786 5.25817 14.8639 5.46352 14.6806 5.01615C14.0585 3.50168 13.5301 2.22189 13.5088 2.16322L13.4662 2.06055H7.07422V2.06421Z"
      fill="url(#paint1_linear_1334_984)"
    />
    <path
      d="M7.07422 2.06421L13.4875 17.7039V17.6965L13.9946 18.9286C16.8113 25.7895 18.3241 29.4712 18.3326 29.4785C18.3369 29.4822 18.763 29.5042 19.2786 29.5262C20.8383 29.5922 22.7729 29.7352 24.2431 29.8929C24.5797 29.9295 24.8737 29.9479 24.8908 29.9332L18.5329 14.3999V14.4036L17.9448 12.9735C17.3696 11.5763 16.986 10.6413 14.6721 5.01613C14.05 3.50167 13.5216 2.22189 13.5003 2.16322L13.4577 2.06055H10.2659L7.07422 2.06421Z"
      fill="#E30A17"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_984"
        x1="27.7858"
        y1="22.9104"
        x2="17.6235"
        y2="27.3733"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B20610" />
        <stop offset="0.255959" stopColor="#B20610" />
        <stop offset="1" stopColor="#590004" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_984"
        x1="3.99254"
        y1="8.17115"
        x2="14.9828"
        y2="3.40039"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B20610" />
        <stop offset="0.255959" stopColor="#B20610" />
        <stop offset="1" stopColor="#590004" />
      </linearGradient>
    </defs>
  </svg>
);
export default Netflix;
