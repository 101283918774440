/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import { Table } from '@replai-platform/ui-components';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../../api';
import useAssetsMetrics from '../../api/hooks/assets/useAssetsMetrics';
import { RootState } from '../../store/rootReducer';
import { Columns } from '../../utils/enums';
import getMetricsFromColumns from '../../utils/getMetricsFromColumns';
import getUserSelectedColumns from '../../utils/getUserSelectedColumns';
import generateColumn from '../columns/generateColumnDefaults';

interface VideosTableProps {
  conceptId: SDK.UUID;
}

const VideosTable: React.VFC<VideosTableProps> = ({ conceptId }) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes);
  const filters = useSelector((state: RootState) => state.filters);

  const userColumnsState = useSelector(
    (state: RootState) => state.project.userProject.uiPreferences?.videos?.columns as Columns[]
  );

  // TODO(MERC-1555): cleanup
  const userColumns = useMemo(() => {
    if (projectId !== '248ca9eb-6f5b-4106-b52e-cc3c6d96436b') {
      return userColumnsState;
    }
    return userColumnsState ?? [Columns.Installs, Columns.AGE];
  }, [userColumnsState, projectId]);

  const userSelectedColumns: Columns[] = useMemo(
    () => getUserSelectedColumns({ userColumns, projectKpis, projectAccountTypes }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectKpis, projectAccountTypes, JSON.stringify(userColumns)]
  );
  const metrics = useMemo(() => getMetricsFromColumns(userSelectedColumns), [userSelectedColumns]);
  const getAssetsMetricsParams: SDK.GetAssetsMetricsRequest = {
    projectIds: [projectId],
    metrics,
    orderBy: { condition: SDK.OrderByCondition.DESC_NULLS_LAST, value: SDK.Metrics.SPEND },
    adsFilters: api.filterConverter.getAdsFilters(filters),
    assetFilters: api.filterConverter.getAssetFilters(filters),
    metricsFilters: api.filterConverter.getMetricsFilters(filters),
    conceptId,
  };
  const { data: videosMetricsData, isLoading: videosMetricsIsLoading } = useAssetsMetrics(getAssetsMetricsParams);
  const tableData = useMemo(
    () =>
      videosMetricsData?.assets.map((entry) => ({
        cluster: {
          ...entry,
          name: entry.name ?? '',
        },
        expanded: false,
      })),
    [videosMetricsData?.assets]
  );
  const tableColumns = useMemo(
    () =>
      [...new Set([Columns.Preview, Columns.Name, ...userSelectedColumns])].map((column) =>
        generateColumn({
          column,
          projectId,
          filters,
          networks: filters.networks,
        })
      ),
    [JSON.stringify(filters), projectId, userSelectedColumns]
  );

  return (
    <Table
      data={videosMetricsIsLoading || !tableData ? [] : tableData}
      rowsTotal={videosMetricsIsLoading ? 0 : tableData?.length ?? 0}
      columns={tableColumns}
      sorting={[{ id: 'metrics.spend', desc: true }]}
      loading={
        videosMetricsIsLoading
          ? {
              messages: ['Fetching videos', 'Getting everything ready', 'Computing aggregated metrics'],
            }
          : undefined
      }
      emptyStateProps={{
        icon: 'Search',
        text: 'No metrics found',
        supportingText: 'An error may occurred. Try again later!',
      }}
    />
  );
};

export default VideosTable;
