import { MetricKPIAvg, MetricTotal } from '../../models/metrics/enums';

export const getAggregatedMetricKeyName = (
  metric: string
): MetricKPIAvg | MetricTotal | undefined => {
  switch (metric) {
    case 'ipm':
      return MetricKPIAvg.AVGIPM;
    case 'cti':
      return MetricKPIAvg.AVGCTI;
    case 'cpi':
      return MetricKPIAvg.AVGCPI;
    case 'ctr':
      return MetricKPIAvg.AVGCTR;
    case 'cppDay7':
      return MetricKPIAvg.AVGCPPDAY7;
    case 'roasDay7':
      return MetricKPIAvg.AVGROASDAY7;
    case 'cpl':
      return MetricKPIAvg.AVGCPL;
    case 'cpc':
      return MetricKPIAvg.AVGCPC;
    case 'vintedCostPerFirstListing':
      return MetricKPIAvg.AVGVINTEDCOSTPERFIRSTLISTING;
    case 'yokeCpa':
      return MetricKPIAvg.AVGYOKECPA;
    case 'installs':
      return MetricTotal.TOTALINSTALLS;
    case 'impressions':
      return MetricTotal.TOTALIMPRESSIONS;
    case 'views':
      return MetricTotal.TOTALVIEWS;
    case 'purchasesDay7':
      return MetricTotal.TOTALPURCHASES;
    case 'earningsDay7':
      return MetricTotal.TOTALEARNINGS;
    case 'clicks':
      return MetricTotal.TOTALCLICKS;
    case 'spend':
      return MetricTotal.TOTALSPEND;
    default:
      return undefined;
  }
};
