import { CountryIconProps } from './types';

const TJ = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3769)">
      <path
        d="M1.19072 6.78177C0.427875 8.35939 0 10.1293 0 11.9991C0 13.869 0.427875 15.6389 1.19072 17.2165L12 18.26L22.8093 17.2165C23.5721 15.6389 24 13.869 24 11.9991C24 10.1293 23.5721 8.35939 22.8093 6.78177L12 5.73828L1.19072 6.78177Z"
        fill="#F0F0F0"
      />
      <path
        d="M1.19141 17.2176C3.13236 21.2315 7.24278 24.0002 12.0003 24.0002C16.7577 24.0002 20.8682 21.2315 22.8091 17.2176H1.19141Z"
        fill="#6DA544"
      />
      <path
        d="M1.19141 6.78201H22.8091C20.8682 2.76811 16.7577 -0.000610352 12.0003 -0.000610352C7.24278 -0.000610352 3.13236 2.76811 1.19141 6.78201Z"
        fill="#D80027"
      />
      <path
        d="M9.91309 15.1315H14.087V13.3576L13.2522 13.7749L12.0001 12.5228L10.7479 13.7749L9.91309 13.3576V15.1315Z"
        fill="#FFDA44"
      />
      <path
        d="M7.52504 13.0438L7.71934 13.6416H8.34793L7.83934 14.0111L8.03363 14.609L7.52504 14.2395L7.01645 14.609L7.21074 14.0111L6.70215 13.6416H7.33074L7.52504 13.0438Z"
        fill="#FFDA44"
      />
      <path
        d="M8.12758 10.9577L8.32187 11.5555H8.95047L8.44187 11.9251L8.63612 12.5229L8.12758 12.1534L7.61898 12.5229L7.81328 11.9251L7.30469 11.5555H7.93328L8.12758 10.9577Z"
        fill="#FFDA44"
      />
      <path
        d="M9.84926 9.39282L10.0436 9.99067H10.6721L10.1636 10.3602L10.3578 10.958L9.84926 10.5886L9.34071 10.958L9.53496 10.3602L9.02637 9.99067H9.65501L9.84926 9.39282Z"
        fill="#FFDA44"
      />
      <path
        d="M16.4752 13.0438L16.2809 13.6416H15.6523L16.1609 14.0111L15.9666 14.609L16.4752 14.2395L16.9838 14.609L16.7895 14.0111L17.2981 13.6416H16.6695L16.4752 13.0438Z"
        fill="#FFDA44"
      />
      <path
        d="M15.8727 10.9577L15.6784 11.5555H15.0498L15.5584 11.9251L15.3641 12.5229L15.8727 12.1534L16.3813 12.5229L16.187 11.9251L16.6956 11.5555H16.067L15.8727 10.9577Z"
        fill="#FFDA44"
      />
      <path
        d="M14.151 9.39282L13.9567 9.99067H13.3281L13.8366 10.3602L13.6424 10.958L14.151 10.5886L14.6595 10.958L14.4653 10.3602L14.9738 9.99067H14.3452L14.151 9.39282Z"
        fill="#FFDA44"
      />
      <path
        d="M12.0006 8.60938L12.1949 9.20722H12.8235L12.3149 9.57673L12.5092 10.1746L12.0006 9.80511L11.492 10.1746L11.6863 9.57673L11.1777 9.20722H11.8063L12.0006 8.60938Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3769">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default TJ;
