import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase, MultiSelectDropDownOption } from '@replai-platform/ui-components';
import { api } from '../../../api';
import { FiltersState } from '../../../store/filters';

const fetchMarketNetworks = ({
  projectId,
  currentFiltering,
}: {
  projectId: SDK.UUID;
  currentFiltering: FiltersState;
}) =>
  api.market.getNetworks({ projectId }).then(
    (fetchedNetworks) =>
      [
        {
          id: 'all',
          label: 'All Networks',
          isAllOption: true,
          selected: currentFiltering.marketNetworks.length === fetchedNetworks.length,
        },
        ...(fetchedNetworks || []).map((network) => ({
          id: network,
          label: camelCaseToCapitalCase(network),
          selected: currentFiltering.marketNetworks.includes(network),
        })),
      ] as MultiSelectDropDownOption[]
  );

export default fetchMarketNetworks;
