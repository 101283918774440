import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as SDK from '@replai-platform/sdk';
import orderBy from 'lodash/orderBy';
import useTags from '../../../api/hooks/tags/useTags';
import type { RootState } from '../../../store/rootReducer';
import { CustomTagsFilterProps } from '../Filters/CustomTagsFilter';
import { FilterActions } from '../../../store/filters';
import { LibraryActions } from '../../../store/library';
import { ReportsActions } from '../../../store/reports';

const HALF_DAY_IN_MS = 12 * 60 * 60 * 1000;

const useCustomTagsFilter = (
  filterStateType: 'default' | 'library' | 'reports'
): {
  customTagsFilterOptions: CustomTagsFilterProps['options'];
  customTagsFilterOnChange: (options: string[]) => void;
  isLoading: boolean;
} => {
  const dispatch = useDispatch();
  const projectId = useSelector((state: RootState) => state.project.id);
  const tagsTypes = useSelector((state: RootState) => state.project.config.tagsTypes);
  const tagsToConsider = useSelector((state: RootState) => {
    switch (filterStateType) {
      case 'library':
        return state.library.filters.tagsToConsider;
      case 'reports':
        return state.reports.tagsToConsider;
      case 'default':
      default:
        return state.filters.tagsToConsider;
    }
  }) as Pick<SDK.Tag, 'type' | 'value'>[];

  // Fetch and update project networks
  const { isSuccess, isLoading, data } = useTags(
    {
      projectIds: [projectId],
      tagsFilters: {
        tagTypesToExclude: tagsTypes?.excluded?.map((type) => ({ type })) ?? [],
      },
    },
    { cacheTime: HALF_DAY_IN_MS, select: (res) => orderBy(res, ['type', 'value']) }
  );

  const customTagsFilterOptions = useMemo<CustomTagsFilterProps['options']>(
    () =>
      isSuccess && (data ?? []).length > 0
        ? [
            ...(data ?? []).map((tag) => ({
              id: tag.id ?? null,
              type: tag.type ?? null,
              value: tag.value ?? null,
              selected:
                tagsToConsider.length === 0
                  ? false
                  : !!tagsToConsider.find((mt) => mt.type === tag.type && mt.value === tag.value),
            })),
          ]
        : [],
    [isSuccess, data, tagsToConsider]
  );

  const customTagsFilterOnChange = useCallback(
    (options: string[]) => {
      const selectedOptions = options
        .map((option) => (JSON.parse(option) as SDK.Tag) ?? '')
        .filter((option) => option?.type?.length > 0);

      switch (filterStateType) {
        case 'library':
          dispatch(
            LibraryActions.changeTags({
              value: selectedOptions,
              toExclude: false,
              logEvent: true,
            })
          );
          break;
        case 'reports':
          dispatch(
            ReportsActions.changeTags({
              value: selectedOptions,
              toExclude: false,
            })
          );
          break;
        case 'default':
        default:
          dispatch(
            FilterActions.changeTags({
              value: selectedOptions,
              toExclude: false,
              logEvent: true,
            })
          );
          break;
      }
    },
    [dispatch]
  );

  return { customTagsFilterOptions, customTagsFilterOnChange, isLoading };
};

export default useCustomTagsFilter;
