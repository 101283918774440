import { CountryIconProps } from './types';

const FK = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3619)">
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C0 12.0029 12 0.0013125 12 0C18.6274 0 24 5.37262 24 12Z"
        fill="#0052B4"
      />
      <path
        d="M11.9678 12.0002H11.9999C11.9999 11.9894 11.9999 11.9789 11.9999 11.968C11.9892 11.9788 11.9785 11.9895 11.9678 12.0002Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 6.26074C12 4.14925 12 2.76574 12 -0.00012207H11.998C5.3715 0.00100293 0 5.37311 0 11.9999H6.26086V8.47436L9.78642 11.9999H11.9679C11.9786 11.9892 11.9893 11.9785 12 11.9678C12 11.1593 12 10.438 12 9.78635L8.47443 6.26074H12Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.07027 1.56494C4.19344 2.63364 2.63316 4.19393 1.56445 6.07075V11.9997H4.69491V4.69549V4.69539H11.9993C11.9993 3.70807 11.9993 2.76747 11.9993 1.56494H6.07027Z"
        fill="#D80027"
      />
      <path
        d="M11.9989 10.5244L7.73544 6.26099H6.25977V6.26108L11.9988 12.0001H11.9989C11.9989 12.0001 11.9989 10.9826 11.9989 10.5244Z"
        fill="#D80027"
      />
      <path
        d="M19.3046 14.0863V14.608H15.1307V14.0863H13.5654V16.1732H14.6089V16.695H19.8263V16.1732H20.8698V14.0863H19.3046Z"
        fill="#FFDA44"
      />
      <path
        d="M13.5654 6.2616V12.0007C13.5654 14.7963 17.2176 15.6529 17.2176 15.6529C17.2176 15.6529 20.8698 14.7963 20.8698 12.0007V6.2616H13.5654Z"
        fill="#338AF3"
      />
      <path
        d="M17.2174 10.5428C16.3044 10.5428 16.3044 11.3776 15.3914 11.3776C14.4784 11.3776 14.4784 10.5428 13.5654 10.5428V12.0037C14.4784 12.0037 14.4784 12.8385 15.3914 12.8385C16.3044 12.8385 16.3044 12.0037 17.2174 12.0037C18.1304 12.0037 18.1304 12.8385 19.0436 12.8385C19.9567 12.8385 19.9566 12.0037 20.8698 12.0037V10.5428C19.9567 10.5428 19.9567 11.3776 19.0436 11.3776C18.1304 11.3776 18.1304 10.5428 17.2174 10.5428Z"
        fill="#F3F3F3"
      />
      <path
        d="M17.2174 7.61804C16.3044 7.61804 16.3044 8.45284 15.3914 8.45284C14.4784 8.45284 14.4784 7.61804 13.5654 7.61804V9.0789C14.4784 9.0789 14.4784 9.9137 15.3914 9.9137C16.3044 9.9137 16.3044 9.0789 17.2174 9.0789C18.1304 9.0789 18.1304 9.9137 19.0436 9.9137C19.9567 9.9137 19.9566 9.0789 20.8698 9.0789V7.61804C19.9567 7.61804 19.9567 8.45284 19.0436 8.45284C18.1304 8.45284 18.1304 7.61804 17.2174 7.61804Z"
        fill="#F3F3F3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3619">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default FK;
