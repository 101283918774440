/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { objectToQueryParams } from '../../utils/objectToQueryParams';

function tagFilterToParam(tag: { type: any; value: any }): string {
  return `${tag.type}=${!tag.value ? '' : tag.value}`;
}

export function filtersAsQueryParams(
  {
    projectId = null,
    kind = null,
    assetIds = null,
    assetClusterIds = null,
    tagFiltering = {},
    maxRecords = null,
    offset = null,
  }: {
    projectId?: string | null;
    kind?: string | null;
    assetIds?: string[] | null;
    assetClusterIds?: string[] | null;
    tagFiltering?:
      | {
          campaignIdsToConsider?: string[];
          campaignIdsToExclude?: string[];
          adSetsIds?: string[];
          networkAccountIds?: string[];
          excludedNetworkAccountIds?: string[];
          countries?: string[];
          excludedCountries?: string[];
          tagFilters?: { type: any; value: any } | { type: any; value: any }[];
          tagsToExclude?: { type: any; value: any } | { type: any; value: any }[];
          tagsToConsider?: { type: any; value: any } | { type: any; value: any }[];
          tagTypesToConsider?: string | string[];
          tagTypesToExclude?: string | string[];
          startDate?: string;
          endDate?: string;
          kpi?: string;
          numExcludedTopCreatives?: number;
          advtIds?: any;
          minSpend?: number;
          minAssetActiveDate?: string;
        }
      | Record<string, never>;
    maxRecords?: number | null;
    offset?: number | null;
    isMarketData?: boolean;
  },
  includeTagTypes = false,
  includeAvgAndTotal = false,
  isMarketData = false
): string[] {
  const { campaignIdsToConsider = [] } = tagFiltering;
  const { campaignIdsToExclude = [] } = tagFiltering;
  const { adSetsIds } = tagFiltering;
  const { networkAccountIds } = tagFiltering;
  const { excludedNetworkAccountIds } = tagFiltering;
  const { countries } = tagFiltering;
  const { excludedCountries } = tagFiltering;
  const tagFilters = Array.isArray(tagFiltering.tagFilters) ? tagFiltering.tagFilters : [];
  const tagsToConsider = Array.isArray(tagFiltering.tagsToConsider) ? tagFiltering.tagsToConsider : [];
  const tagsToExclude = Array.isArray(tagFiltering.tagsToExclude) ? tagFiltering.tagsToExclude : [];
  // RPL-1322: tag types filters only applies to certain queries
  const tagTypesToConsider =
    includeTagTypes && Array.isArray(tagFiltering.tagTypesToConsider) ? tagFiltering.tagTypesToConsider : [];
  const tagTypesToExclude =
    includeTagTypes && Array.isArray(tagFiltering.tagTypesToExclude) ? tagFiltering.tagTypesToExclude : [];
  const { startDate } = tagFiltering;
  const { endDate } = tagFiltering;
  const { kpi } = tagFiltering;
  const { numExcludedTopCreatives } = tagFiltering;
  const { advtIds } = tagFiltering;
  const minAssetSpend = tagFiltering?.minSpend;
  const minAssetActiveDate = tagFiltering?.minAssetActiveDate;
  // Single value query params
  const queryParams = {
    kind,
    assets: assetIds || null,
    advt_ids: advtIds || null,
    asset_clusters: assetClusterIds || null,
    tags: tagFilters.length > 0 ? tagFilters.map((tag) => tagFilterToParam(tag)) : null,
    '-tags': tagsToExclude.length > 0 ? tagsToExclude.map((tag) => tagFilterToParam(tag)) : null,
    tagsToConsider: tagsToConsider.length > 0 ? tagsToConsider.map((tag) => tagFilterToParam(tag)) : null,
    '-tagTypes': tagTypesToExclude.length > 0 ? tagTypesToExclude : null,
    tagTypes: tagTypesToConsider.length > 0 ? tagTypesToConsider : null,
    campaigns: campaignIdsToConsider?.length > 0 ? campaignIdsToConsider.join(',') : null,
    excluded_campaigns: campaignIdsToExclude?.length > 0 ? campaignIdsToExclude.join(',') : null,
    projects: projectId ? [projectId] : null,
    ad_sets: adSetsIds,
    countries,
    excluded_countries: excludedCountries,
    network_accounts: networkAccountIds,
    excluded_network_accounts: excludedNetworkAccountIds,
    start_timestamp: startDate ? new Date(`${startDate}T00:00:00.000Z`).getTime() : null,
    end_timestamp: endDate ? new Date(`${endDate}T23:59:59.999Z`).getTime() : null,
    kpi: kpi || null,
    max_records: maxRecords !== null && maxRecords !== undefined ? maxRecords : null,
    offset: offset !== null && offset !== undefined ? offset : null,
    num_excluded_top_creatives:
      numExcludedTopCreatives !== undefined && numExcludedTopCreatives > 0 ? numExcludedTopCreatives : null,
    min_asset_spend: minAssetSpend !== undefined && minAssetSpend > 0 ? minAssetSpend : null,
    min_asset_active_date: minAssetActiveDate,
    includeAvgAndTotal,
    isMarketData,
  };

  return objectToQueryParams(queryParams);
}
