import { useDispatch, useSelector } from 'react-redux';
import { useTitle } from 'react-use';
import { useEffect, useMemo, useState } from 'react';
import * as SDK from '@replai-platform/sdk';
import styled from 'styled-components';
import { capitalCase } from 'change-case';
import { Card, ChartValue, Colors, LineChart, SectionHeader, Skeleton } from '@replai-platform/ui-components';
import { api } from '../../api';
import useMetricsHistory from '../../api/hooks/metrics/useMetricsHistory';
import { useNetworks } from '../../api/hooks/networks/useNetworks';
import FilterBar from '../../components/FilterBar';
import { FiltersContainer } from '../../components/FilterBar/common/styles';
import TopNavPageTitle from '../../components/TopNavPageTitle';
import type { PromotedObjectFilterProps } from '../../components/FilterBar/Filters/PromotedObjectFilter';
import usePromotedObjectFilter from '../../components/FilterBar/hooks/usePromotedObjectFilter';
import { Page } from '../../utils/enums';
import { logEvent } from '../../analytics';
import type { RootState } from '../../store/rootReducer';
import { FilterActions } from '../../store/filters';
import { RouteAnimator } from '../RouteAnimator';

const ANALYTICS_COMPONENT = 'Replai Creatives Page';

const DEFAULT_ASSET_NAME_SEGMENT = 'replai';

const TopChartContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 450px;
  height: fit-content;
  margin-bottom: 1rem;
  flex-direction: column;
`;

const ReplaiPerformanceView: React.VFC = () => {
  const dispatch = useDispatch();
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectName = useSelector((state: RootState) => state.project.name);
  const replaiCreativeFilter = useSelector((state: RootState) => state.project.config.replaiCreativeFilter);
  const filters = useSelector((state: RootState) => state.filters);

  const [networkFilter, setNetworkFilter] = useState<SDK.Network | null>(null);
  const [isInitialNetworkFilter, setIsInitialNetworkFilter] = useState(true);

  // Force-reset the min spend filter.
  useEffect(() => {
    logEvent({
      component: ANALYTICS_COMPONENT,
      action: 'Page Load',
      category: 'engagement',
    });
    dispatch(
      FilterActions.changeMinSpend({
        value: 0,
        logEvent: false,
      })
    );
  }, [dispatch]);

  useTitle(`Replai Creatives - ${projectName}`);

  const replaiHistoryMetricsParams: SDK.GetHistoryMetricsRequest = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: [SDK.Metrics.SPEND],
      adsFilters: {
        ...api.filterConverter.getAdsFilters(filters),
        networksToConsider: undefined,
      },
      assetFilters: {
        ...api.filterConverter.getAssetFilters(filters),
        name: {
          contains: replaiCreativeFilter ?? DEFAULT_ASSET_NAME_SEGMENT,
          ignoreCase: true,
        },
      },
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      tagsFilters: api.filterConverter.getTagsFilters(filters),
    }),
    [projectId, filters, replaiCreativeFilter]
  );
  const { data: replaiHistoryMetricsData, isLoading: replaiHistoryMetricsLoading } =
    useMetricsHistory(replaiHistoryMetricsParams);
  const totalistoryMetricsParams: SDK.GetHistoryMetricsRequest = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: [SDK.Metrics.SPEND],
      adsFilters: {
        ...api.filterConverter.getAdsFilters(filters),
        networksToConsider: undefined,
      },
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      tagsFilters: api.filterConverter.getTagsFilters(filters),
    }),
    [projectId, filters]
  );
  const { data: totalHistoryMetricsData, isLoading: totalHistoryMetricsLoading } =
    useMetricsHistory(totalistoryMetricsParams);

  const { data: networks, isLoading: networksLoading } = useNetworks({ projectId });
  useEffect(() => {
    if (isInitialNetworkFilter && networks && networks.networks[0].network) {
      setIsInitialNetworkFilter(false);
      setNetworkFilter(
        // If FB exists use it as the default as it's likely to have relevant data.
        networks.networks.find((net) => net.network === SDK.Network.FACEBOOK)
          ? SDK.Network.FACEBOOK
          : networks.networks[0].network
      );
    }
  }, [isInitialNetworkFilter, networks]);

  // Build promoted object types filter
  const { promotedObjectTypeFilterOptions, promotedObjectTypeFilterOnChange } = usePromotedObjectFilter(
    Page.ReplaiCreativesPerformance
  );
  const promotedObjectFilter = useMemo(
    () =>
      ({
        options: promotedObjectTypeFilterOptions,
        onChange: promotedObjectTypeFilterOnChange,
        loading: false,
      } as PromotedObjectFilterProps),
    [promotedObjectTypeFilterOnChange, promotedObjectTypeFilterOptions]
  );

  const overallChartData = useMemo(
    () =>
      totalHistoryMetricsLoading || replaiHistoryMetricsLoading || !replaiHistoryMetricsData
        ? []
        : replaiHistoryMetricsData.history.map((entry) => {
            const matchingEntry = totalHistoryMetricsData?.history.find((totalEntry) => totalEntry.date === entry.date);
            return {
              name: entry.date ?? '',
              spend: entry.metrics.spend ?? 0,
              shareOfSpend: matchingEntry
                ? (matchingEntry.metrics.spend ?? 0) === 0
                  ? 0
                  : (entry.metrics.spend ?? 0) / (matchingEntry.metrics.spend ?? 0)
                : 0,
            };
          }),
    [totalHistoryMetricsLoading, replaiHistoryMetricsLoading, replaiHistoryMetricsData, totalHistoryMetricsData]
  );

  const replaiPerNetworkMetricsParams: SDK.GetHistoryMetricsRequest = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: [SDK.Metrics.SPEND],
      adsFilters: {
        ...api.filterConverter.getAdsFilters(filters),
        networksToConsider: networkFilter ? [networkFilter] : undefined,
      },
      assetFilters: {
        ...api.filterConverter.getAssetFilters(filters),
        name: {
          contains: replaiCreativeFilter ?? DEFAULT_ASSET_NAME_SEGMENT,
          ignoreCase: true,
        },
      },
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      tagsFilters: api.filterConverter.getTagsFilters(filters),
    }),
    [projectId, filters, networkFilter, replaiCreativeFilter]
  );
  const { data: replaiPerNetworkMetricsData, isLoading: replaiPerNetworkMetricsLoading } = useMetricsHistory(
    replaiPerNetworkMetricsParams,
    { enabled: networkFilter !== null }
  );
  const totalPerNetworkMetricsParams: SDK.GetHistoryMetricsRequest = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: [SDK.Metrics.SPEND],
      adsFilters: {
        ...api.filterConverter.getAdsFilters(filters),
        networksToConsider: networkFilter ? [networkFilter] : undefined,
      },
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      tagsFilters: api.filterConverter.getTagsFilters(filters),
    }),
    [projectId, filters, networkFilter]
  );
  const { data: totalPerNetworkMetricsData, isLoading: totalPerNetworkMetricsLoading } = useMetricsHistory(
    totalPerNetworkMetricsParams,
    { enabled: networkFilter !== null }
  );

  const perNetworkChartData = useMemo(
    () =>
      replaiPerNetworkMetricsLoading || totalPerNetworkMetricsLoading || !replaiPerNetworkMetricsData
        ? []
        : replaiPerNetworkMetricsData.history.map((entry) => {
            const matchingEntry = totalPerNetworkMetricsData?.history.find(
              (totalEntry) => totalEntry.date === entry.date
            );
            return {
              name: entry.date ?? '',
              spend: entry.metrics.spend ?? 0,
              shareOfSpend: matchingEntry
                ? (matchingEntry.metrics.spend ?? 0) === 0
                  ? 0
                  : (entry.metrics.spend ?? 0) / (matchingEntry.metrics.spend ?? 0)
                : 0,
            };
          }),
    [
      replaiPerNetworkMetricsLoading,
      totalPerNetworkMetricsLoading,
      replaiPerNetworkMetricsData,
      totalPerNetworkMetricsData,
    ]
  );

  const renderLineChart = (chartData: ChartValue[] | undefined) => (
    <LineChart
      data-test="spend-trend-chart"
      data={chartData}
      lines={[
        { name: 'Spend', accessor: (entry) => entry.spend },
        {
          name: 'Share of Spend',
          accessor: (entry) => entry.shareOfSpend,
          yAxisId: 'axis2',
        },
      ]}
      xValueFormatter={(s) =>
        new Date(s).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
        })
      }
      additionalYAxis={{
        id: 'axis2',
        label: 'Share of Spend',
        yValueFormatter: (val) => `${Math.round(val * 10000) / 100}%`,
      }}
      yValueFormatter={(v, _, lineIndex) => {
        if (lineIndex === 1) {
          // Share of Spend line
          return `${Math.round(v * 1000) / 10}%`;
        }
        return `${Math.round(v * 100) / 100}`;
      }}
      showLegend
      emptyChartMessage
      showTooltip
      showCartesianGrid={false}
      gradientColor={Colors.Success[400]}
      lineStroke={1.4}
      xLabel="Date"
      yLabel="Spend"
    />
  );

  return (
    <RouteAnimator data-test="videos-container">
      <TopNavPageTitle
        title="Replai Creatives Performance"
        subtitle="Analyze the performance of Replai-produced creatives across your channels."
      />
      <FiltersContainer data-test="videos-filters-container">
        <FilterBar
          eventPrefix="replai-performance"
          supportedFilters={[]}
          promotedObjectFilter={promotedObjectFilter}
          withAddFilterButton={false}
        />
      </FiltersContainer>
      <TopChartContainer>
        <SectionHeader title="Overall Performance" subtitle="Performance across all channels" withDivider={false} />
        <Card fullHeight fullWidth>
          <SectionHeader title="Spend History" withDivider={false} />
          {totalHistoryMetricsLoading || replaiHistoryMetricsLoading ? (
            <div style={{ height: '100%', width: '100%' }}>
              <Skeleton height="350px" width="100%" />
            </div>
          ) : (
            renderLineChart(overallChartData)
          )}
        </Card>
      </TopChartContainer>
      <TopChartContainer>
        <SectionHeader
          title="Per-network performance"
          subtitle="Use the dropdown on the right to choose which network to analyze."
          withDivider={false}
          dropDownChips={[
            {
              dropDownType: 'singleselect',
              placeHolder: 'Network',
              disableCrossButton: true,
              loading: networksLoading,
              defaultOption: `${networkFilter ? capitalCase(networkFilter) : ''}`,
              dropDownOptions:
                networks?.networks.map(({ network }, idx) => ({
                  id: network ?? `${idx}`,
                  label: capitalCase(network ?? ''),
                  onClick: () => {
                    setNetworkFilter(network);

                    logEvent({
                      component: ANALYTICS_COMPONENT,
                      action: 'Change Networks Filter',
                      category: 'user_actions',
                    });
                  },
                })) ?? [],
            },
          ]}
        />
        <Card fullHeight fullWidth>
          <SectionHeader title="Spend History" withDivider={false} />
          {replaiPerNetworkMetricsLoading || totalPerNetworkMetricsLoading ? (
            <div style={{ height: '100%', width: '100%' }}>
              <Skeleton height="350px" width="100%" />
            </div>
          ) : (
            renderLineChart(perNetworkChartData)
          )}
        </Card>
      </TopChartContainer>
    </RouteAnimator>
  );
};

export default ReplaiPerformanceView;
