/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import { AppcuesTutorial, camelCaseToCapitalCase, Typography } from '@replai-platform/ui-components';
import { Row } from 'react-table';
import * as columns from '.';
import { FiltersState } from '../../store/filters';
import { capitalizeFirstLetter, capitalizeWords, isCreativeActive } from '../../utils';
import { APPCUES_IDS } from '../../utils/constants';
import { Columns, Page } from '../../utils/enums';
import { formatDateDistance } from '../../utils/formatDateDistance';
import { GeneratedColumn } from './common';
import { TooltipContainer, TooltipLink } from './common/styles';
import ColumnHeader from './generateHeader';

const NUMBER_OS = 2;
const ICON_SIZE = 24;
const ICON_PADDING = 8;
const COLUMN_PADDING = 24;

type GenerateColumnProps = {
  column: Columns;
  projectId: SDK.UUID;
  filters: FiltersState;
  includeFooter?: boolean;
  generateRowHref?: ((row: any) => string) | null;
  networks: SDK.Network[];
  isTagData?: boolean;
};

type TableData = {
  entity: SDK.ObjectLevel;
  id?: SDK.UUID;
  name?: string;
  displayName?: string;
  isActive?: boolean;
  email?: string;
  surname?: string;
  status?: string;
  loading?: boolean;
  allowEdit?: boolean;
  cluster?: {
    id?: SDK.UUID;
    name?: string;
    displayName?: string;
    age?: string;
    ads?: {
      count: number;
    };
    adsCount?: number;
    assetId?: SDK.UUID;
    asset?: {
      minId?: SDK.UUID;
      minUrl?: string;
    };
    frequency?: number;
    maxDate: string;
    metrics?: {
      maxDate?: string;
      age?: string;
      spend?: number;
      totalSpend?: number;
    };
    minUrl?: string;
    network?: SDK.Network[];
    numCreatives?: number;
    numTotalCreatives?: number;
    numInstances?: number;
    numTotalInstances?: number;
    os?: SDK.PromotedObjectType[];
    spend?: number;
    totalSpend?: number;
    score?: number;
  };
  tag?: SDK.Tag & { thumbnailUrl?: string; network?: SDK.Network[]; os?: SDK.PromotedObjectType[] };
  createdAt?: Date;
  userEmail?: string;
};

type ReportTableData = {
  reportId: string;
  reportStatus?: SDK.ReportStatus;
  reportName?: string;
  reportType?: SDK.ReportTypes;
  userId?: string;
  userEmail?: string;
  onReportOpenClick?: () => void;
  onReportFavoriteClick?: () => void;
  onReportDeleteClick?: () => void;
  onReportDeleteTemplateClick?: () => void;
  favorite?: boolean;
  templateId?: string;
  templateName?: string;
  externalId?: string;
};

const recommendationGetLinkPath = ({
  data,
  projectId,
  row,
  id,
  objectLevel,
}: {
  data: SDK.GetRecommendationsResponse['recommendations'];
  projectId: SDK.UUID;
  objectLevel: SDK.ObjectLevel;
} & ({ row: Row<any>; id?: never } | { id: SDK.UUID; row?: never })) => {
  let objectId = id;
  if (!objectId && row) {
    objectId = row.depth === 0 ? row.original.id : data?.[Number.parseInt(row.id?.split('.')?.[0], 10)]?.id;
  }

  return `/${projectId}/${objectLevel === SDK.ObjectLevel.CONCEPT ? Page.Concepts : Page.VideoView}/${
    objectId ?? ''
  }/timeline`;
};

function generateColumn({
  column,
  projectId,
  filters,
  includeFooter = false,
  generateRowHref = null,
  networks = [],
  isTagData = false,
}: GenerateColumnProps): GeneratedColumn<any> {
  const parts = column.split('.');
  const [prefix, suffix] = parts.length === 1 ? ['cluster', parts[0]] : parts;
  let generatedColumn: GeneratedColumn<any> = {
    id: column,
    Cell: () => null,
    accessor: () => null,
  };

  switch (column) {
    case Columns.Preview:
      generatedColumn = {
        ...columns.getPreviewColumn({
          columnId: column,
          accessor: (data: TableData) => ({
            objectId: !isTagData ? data?.cluster?.assetId ?? data?.cluster?.id : data?.cluster?.asset?.minId,
            objectLevel: SDK.ObjectLevel.ASSET,
            conceptId: data.cluster?.id,
            tagId: data.tag?.id,
            tagType: data.tag?.type,
            tagValue: data.tag?.value ?? '',
            projectId,
            isActive:
              data.cluster?.metrics?.maxDate ?? data.cluster?.maxDate
                ? isCreativeActive(data.cluster?.metrics?.maxDate ?? data.cluster?.maxDate, new Date().toISOString())
                : undefined,
            loading: data.loading,
            videoUrl: !isTagData ? data.cluster?.minUrl : data.cluster?.asset?.minUrl,
            tagThumbnailImgUrl: data.tag?.thumbnailUrl,
            ...(generateRowHref && { rowHref: generateRowHref(data) }),
            allowEdit: data.allowEdit,
          }),
        }),
        Header: <ColumnHeader title="Preview" />,
        tooltip: 'Preview Thumbnail',
        sortDescFirst: false,
        sticky: 'left',
        width: isTagData ? 100 : 95,
        disableSortBy: true,
      };
      break;
    case Columns.Name:
      generatedColumn = {
        ...columns.getClusterNameColumn({
          columnId: column,
          accessor: (data: TableData) => {
            const ads = data.cluster?.ads?.count ?? data.cluster?.adsCount;
            return {
              title: !isTagData
                ? `${data.cluster?.displayName ?? data.cluster?.name ?? SDK.messages.NOT_AVAILABLE}`
                : data.tag?.value ?? '',
              originalName: !isTagData
                ? data.cluster?.name ?? SDK.messages.NOT_AVAILABLE
                : data.tag?.value ?? SDK.messages.NOT_AVAILABLE,
              subTitle: !isTagData ? `${ads ?? 0} ad instances` : camelCaseToCapitalCase(data.tag?.type ?? ''),
              objectId: !isTagData ? data?.cluster?.assetId ?? data?.cluster?.id : data?.cluster?.asset?.minId,
              conceptId: data.cluster?.id,
              projectId,
              tagId: data.tag?.id,
              loading: data.loading,
              ...(generateRowHref && { rowHref: generateRowHref(data) }),
              allowEdit: data.allowEdit,
            };
          },
        }),
        ...(isTagData
          ? {
              Header: <ColumnHeader title="Name" />,
              tooltip: 'Tags from the same category of the selected Tag.',
              width: 200,
              minWidth: 200,
              disableSortBy: true,
            }
          : {
              Header: <ColumnHeader title="Name" appcuesId={APPCUES_IDS.CONCEPT_NAME_COLUMN} />,
              tooltip:
                'These are the different concepts. Instances count is the number of ads where the concept has been used. Always according to the selected filters.',
              sortDescFirst: false,
              sticky: 'left',
              Footer: includeFooter ? 'Totals' : '',
              width: 450,
            }),
      };
      break;
    case Columns.CompositePreview:
      generatedColumn = {
        ...columns.getClusterNameAndThumbnailColumn({
          columnId: column,
          accessor: (data: TableData) => {
            const ads = data.cluster?.ads?.count ?? data.cluster?.adsCount;
            return {
              objectId: !isTagData ? data?.cluster?.assetId ?? data?.cluster?.id : data?.cluster?.asset?.minId,
              objectLevel: SDK.ObjectLevel.ASSET,
              conceptId: data.cluster?.id,
              tagId: data.tag?.id,
              title: !isTagData
                ? `${data.cluster?.displayName ?? data.cluster?.name ?? SDK.messages.NOT_AVAILABLE}`
                : data.tag?.value ?? '',
              originalName: !isTagData
                ? data.cluster?.name ?? SDK.messages.NOT_AVAILABLE
                : data.tag?.value ?? SDK.messages.NOT_AVAILABLE,
              subTitle: !isTagData ? `${ads ?? 0} ad instances` : camelCaseToCapitalCase(data.tag?.type ?? ''),
              tagType: data.tag?.type,
              tagValue: data.tag?.value ?? '',
              projectId,
              isActive:
                data.cluster?.metrics?.maxDate ?? data.cluster?.maxDate
                  ? isCreativeActive(data.cluster?.metrics?.maxDate ?? data.cluster?.maxDate, new Date().toISOString())
                  : undefined,
              loading: data.loading,
              videoUrl: !isTagData ? data.cluster?.minUrl : data.cluster?.asset?.minUrl,
              tagThumbnailImgUrl: data.tag?.thumbnailUrl,
              ...(generateRowHref && { rowHref: generateRowHref(data) }),
              allowEdit: data.allowEdit,
            };
          },
        }),
        ...(isTagData
          ? {
              Header: 'Tag',
              tooltip: 'Tags from the same category of the selected Tag.',
              width: 200,
              minWidth: 200,
              Footer: 'Totals',
              disableSortBy: true, // TODO: enable sorting by this column
            }
          : {
              Header: <ColumnHeader title="Name" appcuesId={APPCUES_IDS.CONCEPT_NAME_COLUMN} />,
              tooltip:
                'These are the different concepts. Instances count is the number of ads where the concept has been used. Always according to the selected filters.',
              sortDescFirst: false,
              sticky: 'left',
              Footer: includeFooter ? 'Totals' : '',
              width: 500,
            }),
      };
      break;
    case Columns.RecommendationsName:
      generatedColumn = {
        ...columns.getRecommendationNameColumn({
          columnId: 'name',
          accessor: (data: TableData) => ({
            objectId: data.id ?? '',
            objectLevel: SDK.ObjectLevel.CONCEPT,
            name: data.name,
            displayName: data.displayName,
            isActive: data.isActive,
            rowHref: recommendationGetLinkPath({
              data: [],
              projectId,
              id: data.id ?? '',
              objectLevel: SDK.ObjectLevel.CONCEPT,
            }),
            rowHrefState: {
              recommendationsOnly: true,
            },
          }),
        }),
        width: 350,
      };
      break;
    case Columns.User:
      generatedColumn = {
        ...columns.getUserInfoColumn({
          columnId: column,
          accessor: (data: TableData) => ({
            title: `${data.name ?? data.email ?? ''} ${data.surname ?? ''}`,
            subTitle: data.name && data.email ? data.email : '',
          }),
        }),
        Header: <ColumnHeader title="Name" />,
        disableSortBy: true,
        sticky: 'left',
        width: 100,
      };
      break;
    case Columns.Status:
      generatedColumn = {
        ...columns.getStatusColumn({
          columnId: column,
          accessor: (data: TableData) => ({
            status: data.status ?? '',
          }),
        }),
        Header: <ColumnHeader title="Status" />,
        disableSortBy: true,
        width: 50,
      };
      break;
    case Columns.Actions:
      generatedColumn = {
        ...columns.getActionsColumn({
          columnId: column,
          accessor: (data: TableData) => ({
            userId: data.id,
            userEmail: data.email,
            userName: data.name,
            userSurname: data.surname,
            userStatus: data.status,
          }),
        }),
        disableSortBy: true,
        width: 100,
      };
      break;
    case Columns.ReplaiScore:
      generatedColumn = {
        ...columns.getReplaiScoreColumn({
          columnId: column,
          accessor: (data: TableData) => ({
            objectId: data.cluster?.id ?? '',
            projectId,
            filters,
            objectType: data?.entity === SDK.ObjectLevel.ASSET ? SDK.ObjectLevel.ASSET : SDK.ObjectLevel.CONCEPT,
            score: data.cluster?.score ?? null,
          }),
        }),
        Header: <ColumnHeader title="Replai Score" />,
        tooltip: (
          <TooltipContainer>
            <Typography type="text-sm" fontWeight="medium">
              Replai Score© AI algorithm is applied to each of your videos to predict their success, on a 0 to 100
              scale.
            </Typography>
            <AppcuesTutorial appcuesId={APPCUES_IDS.REPLAI_SCORE}>
              <TooltipLink type="text-sm" fontWeight="medium" style={{ textDecoration: 'underline' }}>
                Know more
              </TooltipLink>
            </AppcuesTutorial>
          </TooltipContainer>
        ),
        tooltipProps: { enableMaxContent: true },
        disableSortBy: true,
        width: 100,
      };
      break;
    case Columns.AppearsIn: {
      const width = (networks.length + NUMBER_OS) * (ICON_SIZE + ICON_PADDING) + 2 * ICON_PADDING + 2 * COLUMN_PADDING;
      generatedColumn = {
        ...columns.getAssetNetworkAndOSColumn({
          columnId: column,
          accessor: (data: TableData) => ({
            network: isTagData ? data?.tag?.network ?? [] : data?.cluster?.network ?? [],
            os: isTagData ? data?.tag?.os ?? [] : data?.cluster?.os ?? [],
            loading: data.loading,
          }),
        }),
        Header: <ColumnHeader title="Appears In" />,
        tooltip: 'Networks and OSs where the video is published',
        disableSortBy: true,
        minWidth: width,
        width,
        maxWidth: width,
      };
      break;
    }
    case Columns.AGE:
      generatedColumn = {
        ...columns.getDefaultColumn({
          columnId: column,
          accessor: (data: TableData) => {
            const ageStr: string | undefined = data.cluster?.age ?? data.cluster?.metrics?.age;
            return {
              defaultValue: ageStr ? formatDateDistance(new Date(ageStr)) : '',
              tooltip: ageStr,
            };
          },
        }),
        Header: <ColumnHeader title="Launch Date" />,
        tooltip: 'Date when the video was first launched',
      };
      break;
    case Columns.SpendTrend: {
      generatedColumn = {
        ...columns.getSpendTrendColumn({
          columnId: column,
          accessor: (data: TableData) => {
            const objectLevel = data.entity;
            let objectId;
            let tag;

            switch (objectLevel) {
              case SDK.ObjectLevel.ASSET:
              case SDK.ObjectLevel.CONCEPT:
                objectId = data.cluster?.id;
                break;
              case SDK.ObjectLevel.TAG:
                tag = { type: data.tag?.type, value: data.tag?.value };
                break;
              default: {
                const exhaustiveCheck: never = objectLevel;
                return exhaustiveCheck;
              }
            }

            return {
              objectLevel,
              objectId,
              tag,
            };
          },
          includeFooter,
        }),
        Header: <ColumnHeader title="7D Spend Trend" />,
        tooltip: "Spend trend based on selected period's last 7 days",
        disableSortBy: true,
      };
      break;
    }
    case Columns.Spend:
      generatedColumn = {
        ...columns.getSpendColumn({
          columnId: column,
          accessor: (data: TableData) => ({
            spendValue: data.cluster?.metrics?.spend ?? data.cluster?.spend,
            total: data.cluster?.metrics?.totalSpend ?? data.cluster?.totalSpend,
            loading: data.loading,
          }),
          includeFooter,
        }),
        sortDescFirst: true,
      };
      break;
    case Columns.LifetimeSpend: {
      const spendColumn = columns.getSpendColumn({
        columnId: 'spend',
        accessor: (data: any) => ({
          spendValue: data.spend?.current,
          total: data.spend?.max,
        }),
      });

      const targetsColumn = columns.getRecommendationTargetsColumn({
        columnId: 'targets',
        accessor: (data: any) => ({
          score: (data?.name as SDK.CreativeRecommendation['name'])?.score,
          targets: (data?.name as SDK.CreativeRecommendation['name'])?.targets,
        }),
      });

      generatedColumn = {
        ...columns.getRecommendationTargetsColumn({
          columnId: 'targets',
          accessor: (data) => ({
            score: (data?.name as SDK.CreativeRecommendation['name'])?.score,
            targets: (data?.name as SDK.CreativeRecommendation['name'])?.targets,
          }),
        }),
        ...columns.getSpendColumn({
          columnId: 'spend',
          accessor: (data) => ({
            spendValue: data.spend?.current,
            total: data.spend?.max,
          }),
        }),
        Header: 'Lifetime Spend',
        minWidth: 250,
        width: 250,
        accessor: (data) => (data.subRows ? spendColumn.accessor(data) : targetsColumn.accessor(data)),
        Cell: (props) => (props.row.depth > 0 ? (targetsColumn.Cell as any)(props) : (spendColumn.Cell as any)(props)),
      };
      break;
    }
    case Columns.Suggestions: {
      const platformColumnCell = columns.getPlatformsColumn({
        columnId: 'platforms',
        accessor: (data: any) => ({
          projectNetworks: data.projectNetworks,
          networks: data.networks,
          promotedObjectType: data.promotedObjectType,
        }),
      }).Cell;

      const suggestionsColumnCell = columns.getSuggestionsColumn({
        columnId: 'suggestions',
        accessor: (data: any) => data.suggestions ?? 0,
      }).Cell;

      generatedColumn = {
        ...columns.getPlatformsColumn({
          columnId: 'platforms',
          accessor: (data) => ({
            projectNetworks: data.projectNetworks,
            networks: data.networks,
            promotedObjectType: data.promotedObjectType,
          }),
        }),
        ...columns.getSuggestionsColumn({
          columnId: 'suggestions',
          accessor: (data) => data.suggestions ?? 0,
        }),
        Header: 'Recommendations',
        accessor: (data) =>
          data.subRows
            ? data.suggestions ?? 0
            : {
                projectNetworks: data.projectNetworks,
                networks: data.networks,
                promotedObjectType: data.promotedObjectType,
              },
        Cell: (props) =>
          props.row.depth > 0 ? (platformColumnCell as any)(props) : (suggestionsColumnCell as any)(props),
      };
      break;
    }
    case Columns.Frequency: {
      generatedColumn = {
        ...columns.getFrequencyColumn({
          columnId: column,
          accessor: (data: TableData) => ({
            frequencyRatio: data?.cluster?.frequency,
            loading: data.loading,
          }),
          includeFooter,
        }),
        Header: <ColumnHeader title="Frequency" />,
        tooltip: 'Frequency at which the item appeared in your ads.',
        sortDescFirst: true,
      };
      break;
    }
    case Columns.AdInstances: {
      generatedColumn = {
        ...columns.getNumInstancesColumn({
          columnId: column,
          accessor: (data: TableData) => ({
            numInstances: data?.cluster?.numInstances,
            numTotalInstances: data?.cluster?.numTotalInstances,
            loading: data.loading,
          }),
          includeFooter,
        }),
        Header: <ColumnHeader title="Ad Instances" />,
        tooltip: 'Number of Ad Instances of this item.',
        sortDescFirst: true,
      };
      break;
    }
    case Columns.NumCreatives: {
      generatedColumn = {
        ...columns.getNumCreativesColumn({
          columnId: column,
          accessor: (data: TableData) => ({
            numCreatives: data?.cluster?.numCreatives,
            numTotalCreatives: data?.cluster?.numTotalCreatives,
            loading: data.loading,
          }),
          includeFooter,
        }),
        Header: <ColumnHeader title="Creatives" />,
        tooltip: 'Number of Creatives of this item.',
        sortDescFirst: true,
      };
      break;
    }
    case Columns.UserEmail:
      generatedColumn = {
        ...columns.getUserEmailColumn({
          columnId: column,
          accessor: (data: TableData) => ({
            email: data?.userEmail ?? SDK.messages.NOT_AVAILABLE,
          }),
        }),
        Header: <ColumnHeader title="User" />,
        disableSortBy: true,
        width: 130,
      };
      break;
    case Columns.ReportName:
      generatedColumn = {
        ...columns.getReportNameColumn({
          columnId: column,
          accessor: (data: ReportTableData) => ({
            name: data?.reportName ?? SDK.messages.NOT_AVAILABLE,
          }),
        }),
        Header: <ColumnHeader title="Report Name" />,
        disableSortBy: true,
        width: 250,
      };
      break;
    case Columns.CreatedAt:
      generatedColumn = {
        ...columns.getCreatedAtColumn({
          columnId: column,
          accessor: (data: TableData) => ({
            createdAt: data?.createdAt,
          }),
        }),
        Header: <ColumnHeader title="Created At" />,
        disableSortBy: true,
        width: 75,
      };
      break;
    case Columns.ReportStatus:
      generatedColumn = {
        ...columns.getReportStatusColumn({
          columnId: column,
          accessor: (data: ReportTableData) => ({
            status: data?.reportStatus ?? SDK.messages.NOT_AVAILABLE,
          }),
        }),
        Header: <ColumnHeader title="Generation Status" />,
        disableSortBy: true,
        width: 80,
      };
      break;
    case Columns.ReportType:
      generatedColumn = {
        ...columns.getReportTypeColumn({
          columnId: column,
          accessor: (data: ReportTableData) => ({
            type: data?.reportType ?? SDK.ReportTypes.GOOGLE_SLIDES,
          }),
        }),
        Header: <ColumnHeader title="" />,
        disableSortBy: true,
        width: 35,
      };
      break;
    case Columns.ReportOpen:
      generatedColumn = {
        ...columns.getReportOpenColumn({
          columnId: column,
          accessor: (data: ReportTableData) => ({
            onReportOpenClick: data?.onReportOpenClick,
          }),
        }),
        Header: <ColumnHeader title="" />,
        disableSortBy: true,
        sticky: 'right',
        width: 68,
      };
      break;
    case Columns.ReportSaveTemplate:
      generatedColumn = {
        ...columns.getReportSaveTemplateColumn({
          columnId: column,
          accessor: (data: ReportTableData) => ({
            reportId: data.reportId,
            reportType: data.reportType,
            templateId: data.templateId,
            templateName: data.templateName,
            externalId: data.externalId,
          }),
        }),
        disableSortBy: true,
        sticky: 'right',
        width: 80,
      };
      break;
    case Columns.ReportApplyTemplate:
      generatedColumn = {
        ...columns.getReportApplyTemplateColumn({
          columnId: column,
          accessor: (data: {
            reportId: string;
            reportType?: SDK.ReportTypes;
            templateId: string;
            params: object;
            userEmail: string;
            reportUrl?: string;
            externalId?: string;
          }) => ({
            reportId: data.reportId,
            reportType: data.reportType,
            templateId: data.templateId,
            userEmail: data.userEmail,
            params: data.params,
            reportUrl: data.reportUrl,
            externalId: data.externalId,
          }),
        }),
        disableSortBy: true,
        sticky: 'right',
        width: 80,
      };
      break;
    case Columns.ReportFavorite:
      generatedColumn = {
        ...columns.getReportFavoriteColumn({
          columnId: column,
          accessor: (data: ReportTableData) => ({
            onReportFavoriteClick: data?.onReportFavoriteClick,
            favorite: data?.favorite,
          }),
        }),
        Header: <ColumnHeader title="" />,
        disableSortBy: true,
        width: 20,
      };
      break;
    case Columns.ReportDelete:
      generatedColumn = {
        ...columns.getReportDeleteColumn({
          columnId: column,
          accessor: (data: ReportTableData) => ({
            reportId: data.reportId,
            userEmail: data.userEmail ?? '',
            onReportDeleteClick: data?.onReportDeleteClick,
          }),
        }),
        Header: <ColumnHeader title="" />,
        disableSortBy: true,
        width: 30,
      };
      break;
    case Columns.ReportDeleteTemplate:
      generatedColumn = {
        ...columns.getReportDeleteTemplateColumn({
          columnId: column,
          accessor: (data: ReportTableData) => ({
            templateId: data.templateId ?? '',
            userId: data.userId ?? '',
            onReportDeleteTemplateClick: data?.onReportDeleteTemplateClick,
          }),
        }),
        Header: <ColumnHeader title="" />,
        disableSortBy: true,
        width: 30,
      };
      break;
    default:
      if (prefix === 'metrics') {
        if (Object.keys(SDK.kpiUtils.filters).includes(suffix)) {
          const kpi = suffix;
          const displayName = SDK.kpiUtils.getDisplayName(suffix);
          const longName = SDK.kpiUtils.getLongName(suffix);
          generatedColumn = {
            ...columns.getPerformanceColumn({
              columnId: column,
              accessor: (data: any) => {
                const kpiValue = data.cluster?.metrics?.[suffix] ?? data.cluster?.[suffix];
                const kpiPerformance =
                  data.cluster?.metrics?.[`performance${capitalizeFirstLetter(kpi)}`] ??
                  data.cluster?.[`performance${capitalizeFirstLetter(kpi)}`];
                return {
                  kpi,
                  kpiValue,
                  kpiPerformance,
                  loading: data.loading,
                };
              },
              includeFooter,
            }),
            width: 112,
            maxWidth: 150,
            Header: <ColumnHeader title={SDK.kpiUtils.getDisplayName(suffix)} />,
            tooltip: `${
              displayName === longName ? displayName : `${displayName} (${longName})`
            } of the video according to the filters you have selected.`,
            sortDescFirst: !!SDK.kpiUtils.shouldMaximize(kpi),
          };
        }
      } else {
        generatedColumn = {
          ...columns.getDefaultColumn({
            columnId: column,
            accessor: (data: any) => ({
              defaultValue: data.cluster?.metrics?.[suffix],
            }),
            includeFooter,
          }),
          Header: <ColumnHeader title={capitalizeWords(suffix)} />,
        };
      }
      break;
  }

  return generatedColumn;
}

export default generateColumn;
