export type DateType = 'current' | 'previous' | 'next';

export type DateItem = {
  date: number;
  type: DateType;
} | null;

export const MONTH_ACRONYMS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const WEEK_ACRONYMS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sat'];

export const isDateInRange = (start: Date, date: Date, end: Date) => [
  date.getTime() >= start.getTime() && date.getTime() <= end.getTime(),
  date.getDate() === start.getDate() &&
    date.getMonth() === start.getMonth() &&
    date.getFullYear() === start.getFullYear(),
  date.getDate() === end.getDate() &&
    date.getMonth() === end.getMonth() &&
    date.getFullYear() === end.getFullYear(),
];

/**
 * Converts a given input to its valid range if required
 * @param min Lower limit
 * @param max Upper limit
 * @param value Input value
 * @returns Either the original or the converted input
 */
export function inputRangeValidaton(min: number, max: number, value: number) {
  if (value < min) return min;
  if (value > max) return max;
  return value;
}

/**
 * Generate the calendar as a matrix of 6 rows and 7 columns
 */
export const generateMonthCalendar = (month: number, year: number) => {
  const calendar: DateItem[] = [];
  const firstDay = new Date(year, month, 1);
  const firstDayOfWeek = firstDay.getDay();
  const lastDay = new Date(year, month + 1, 0);
  const daysInMonth = lastDay.getDate();

  // Fill the calendar with empty days
  for (let i = 0; i < firstDayOfWeek; i += 1) {
    calendar.push(null);
  }

  // Fill the calendar with the days of the current month
  for (let i = 1; i <= daysInMonth; i += 1) {
    calendar.push({ date: i, type: 'current' });
  }

  const formattedCalendar: DateItem[][] = [];
  for (let i = 0; i < calendar.length; i += 7) {
    formattedCalendar.push(calendar.slice(i, i + 7));
  }

  return formattedCalendar.slice(0, 6);
};
