import styled, { css } from 'styled-components';
import Colors from '../Colors';

export const Root = styled.button<{
  $hasFullWidth?: boolean;
  $hasNoChildren?: boolean;
  $backgroundColor?: string | undefined;
  $color?: string | undefined;
  $style?: string;
}>`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 16px;
  color: ${Colors.Gray[700]};
  font-weight: 500;
  font-size: 16px;
  background-color: ${Colors.Common.White};
  border: 1px solid ${Colors.Gray[300]};
  border-radius: 8px;
  transition: 0.2s;
  background-color: ${(props) =>
    props.$backgroundColor ? props.$backgroundColor : ''};
  color: ${(props) => (props.$color ? props.$color : '')};

  &:hover {
    background-color: ${Colors.Gray[50]};
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px ${Colors.Gray[100]};
  }

  ${(props) => (props.$hasFullWidth ? FullWidthMixin : '')}
  ${(props) => (props.$hasNoChildren ? NoChildrenMixin : '')}

  &&& {
    ${(props) =>
      css`
        ${props.$style}
      `}
  }
`;

/*   Mixins   */

const FullWidthMixin = css`
  width: 100%;
`;

const NoChildrenMixin = css`
  grid-template-columns: max-content;
  padding: 10px;
`;
