import * as SDK from '@replai-platform/sdk';
import checkInverseKpi from './checkInverseKpi';
import kpiCompare from './kpiCompare';

export const calculateDelta = (
  current: number,
  previous: number,
  isPercentage = false,
  allowNegative = true,
  decimalPlaces?: number
): number | undefined => {
  if (!previous) return undefined;

  let delta = (current - previous) / previous;

  if (isPercentage) delta *= 100;

  if (!allowNegative) delta = Math.abs(delta);

  if (decimalPlaces) delta = Number(delta.toFixed(decimalPlaces));

  return delta;
};

export const deltaToGoodness = (
  first: SDK.Metric,
  second: SDK.Metric,
  kpi: SDK.Metrics
): 'good' | 'bad' | undefined => {
  const goodness =
    kpiCompare({
      value1: first,
      value2: second,
      kpi,
    }) ?? 0;

  if (goodness === 0) return undefined;
  return goodness > 0 ? 'good' : 'bad';
};

export const deltaToStatus = (first: SDK.Metric, second: SDK.Metric, kpi: SDK.Metrics): 'up' | 'down' | undefined => {
  if (first === second) return undefined;
  const goodness =
    kpiCompare({
      value1: first,
      value2: second,
      kpi,
    }) ?? 0;

  // Returns 'up' if the score is good and the kpi is not inverted
  // or the complete opposite, the same for the other 'down' (xnor gate)
  return goodness > 0 === !checkInverseKpi(kpi) ? 'up' : 'down';
};
