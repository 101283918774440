/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { forwardRef } from 'react';
import '../common.module.css';
import * as Icons from '../Icons';
import * as Styled from './styles';
import { Colors, Typography } from '..';
import SelectInput from './SelectInput/SelectInput';

interface AdditionalInputProps {
  values: string[];
  onChange: (value: string) => void;
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  warning?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  hintText?: string;
  label?: string;
  leadingIcon?: Icons.BaseIconTypes | Icons.PaymentIconTypes;
  leadingOptions?: AdditionalInputProps;
  leadingText?: string;
  prefix?: string;
  trailingIcon?: Icons.BaseIconTypes | Icons.PaymentIconTypes;
  trailingOptions?: AdditionalInputProps;
  parentFontSize?: boolean;
  enableTransitions?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      error = false,
      warning = false,
      fullWidth = false,
      fullHeight = false,
      hintText,
      label,
      leadingIcon,
      leadingOptions,
      leadingText,
      prefix,
      trailingIcon,
      trailingOptions,
      style,
      parentFontSize = false,
      enableTransitions = true,
      ...props
    },
    ref
  ) => {
    const isLeadingBaseIcon =
      leadingIcon && Object.keys(Icons.BaseIcons).includes(leadingIcon);
    const isTrailingBaseIcon =
      trailingIcon && Object.keys(Icons.BaseIcons).includes(trailingIcon);

    const LeadingIcon =
      leadingIcon &&
      (isLeadingBaseIcon
        ? Icons.getBaseIcon(leadingIcon as Icons.BaseIconTypes)
        : Icons.getPaymentIcon(leadingIcon as Icons.PaymentIconTypes));

    const TrailingIcon =
      trailingIcon &&
      (isTrailingBaseIcon
        ? Icons.getBaseIcon(trailingIcon as Icons.BaseIconTypes)
        : Icons.getPaymentIcon(trailingIcon as Icons.PaymentIconTypes));

    return (
      <Styled.RootContainer
        $style={{ ...style } as string}
        $fullWidth={fullWidth}
        $fullHeight={fullHeight}
        $parentFontSize={parentFontSize}
      >
        {label && (
          <Styled.Typography
            type="text-sm"
            color="#344054"
            $margin="0"
            $fontWeight={500}
          >
            {label}
          </Styled.Typography>
        )}
        <Styled.PrefixContainer $fullHeight={fullHeight}>
          {prefix && <Styled.Prefix>{prefix}</Styled.Prefix>}
          <Styled.Root
            $prefix={Boolean(prefix)}
            $error={error}
            $warning={warning}
            $fullHeight={fullHeight}
            $enableTransitions={enableTransitions}
          >
            {leadingOptions ? (
              <SelectInput {...leadingOptions} />
            ) : LeadingIcon ? (
              <Styled.LeadingIconHolder>
                <LeadingIcon
                  dimension={16}
                  color={isLeadingBaseIcon ? Colors.Gray[500] : undefined}
                />
              </Styled.LeadingIconHolder>
            ) : (
              leadingText && (
                <Styled.LeadingText>{leadingText}</Styled.LeadingText>
              )
            )}
            <Styled.Input
              $enableTransitions={enableTransitions}
              {...props}
              ref={ref}
            />
            {!error && !warning && TrailingIcon && (
              <Styled.TrailingIconHolder>
                <TrailingIcon dimension={16} color={Colors.Gray[500]} />
              </Styled.TrailingIconHolder>
            )}
            {error && (
              <Styled.TrailingIconHolder $withInput={Boolean(trailingOptions)}>
                <Icons.BaseIcons.AlertCircle
                  dimension={16}
                  color={Colors.Error[500]}
                />
              </Styled.TrailingIconHolder>
            )}
            {warning && (
              <Styled.TrailingIconHolder $withInput={Boolean(trailingOptions)}>
                <Icons.BaseIcons.AlertCircle
                  dimension={16}
                  color={Colors.Warning[500]}
                />
              </Styled.TrailingIconHolder>
            )}
            {trailingOptions && <SelectInput {...trailingOptions} />}
          </Styled.Root>
        </Styled.PrefixContainer>
        {hintText && (
          <Styled.HintContainer>
            <Typography
              type="text-sm"
              color={
                error
                  ? Colors.Error[500]
                  : warning
                  ? Colors.Warning[500]
                  : '#667085'
              }
              noMargin
            >
              {hintText}
            </Typography>
          </Styled.HintContainer>
        )}
      </Styled.RootContainer>
    );
  }
);

export default Input;
export type { InputProps };
