import { createContext } from 'react';
import { ButtonIcon, ColorTypes } from '..';
import { ButtonSize, ButtonVariant } from '../Button/Button';

interface ButtonGroupContextProps {
  color?: ColorTypes;
  variant?: ButtonVariant;
  size?: ButtonSize;
  leadingIcon?: ButtonIcon;
  trailingIcon?: ButtonIcon;
  position: string[];
  pushToPosition(id: string): void;
}

const ButtonGroupContext = createContext({
  color: 'Primary',
  variant: 'regular',
  size: 'md',
  leadingIcon: undefined,
  trailingIcon: undefined,
  position: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  pushToPosition: () => {},
} as ButtonGroupContextProps);

export default ButtonGroupContext;
