import { Colors, CustomScore, Tooltip, Typography, ReplaiScoreProps } from '@replai-platform/ui-components';
import * as Styled from './styles';

const ReplaiScore = ({
  score,
  useCase,
}: ReplaiScoreProps & {
  useCase: 'insight-confidence' | 'video-performance';
}): JSX.Element => {
  if (score === undefined) {
    return <Typography type="text-xs">No score for this video</Typography>;
  }

  const tooltipContent = (
    <Styled.TooltipContent>
      <Typography type="text-sm" fontWeight="regular" color={Colors.Gray[600]}>
        <div style={{ marginBottom: '1rem' }}>
          Replai Score© AI algorithm is applied to each of your videos to predict their potential success on a 0 to 100
          scale:
        </div>
        <div>
          <Styled.TooltipList>
            <li>Up to 50 - Room to grow</li>
            <li>50 to 75 - On the right track</li>
            <li>More than 75 - Here for the long run</li>
          </Styled.TooltipList>
        </div>
      </Typography>
    </Styled.TooltipContent>
  );

  let finalScore = score ?? 0;
  // Normalization for values greater than 0.5 in video performance score.
  if (useCase === 'video-performance' && score > 0.5) {
    finalScore = ((score ** 3 - 0.5 ** 3) / (1 - 0.5 ** 3) / 2) * (1 - 0.5 ** 3) + 0.5;
  }

  return (
    <Styled.RatingContainer isBadge>
      <Tooltip content={tooltipContent} enableMaxContent>
        <Styled.Wrapper>
          <CustomScore score={finalScore} size="sm" />
        </Styled.Wrapper>
      </Tooltip>
    </Styled.RatingContainer>
  );
};

export default ReplaiScore;
