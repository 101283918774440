import {
  CreativeRecommendation,
  RecommendationType,
} from '@replai-platform/sdk';
import styled from 'styled-components';
import Colors, { type ColorTypes } from '../../../Colors';

const Track = styled.div`
  position: relative;
  height: 1.25rem;
  border-radius: 0.25rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 0 -1rem;
  padding: 0 1rem 1rem 1rem;
  background-color: transparent;
  min-height: 3.25rem;
`;

interface SegmentContainerProps {
  start: number;
  end: number;
  color: ColorTypes;
  withStripes?: boolean;
  onClick?: (point: number) => void;
  hoveredTag?: boolean;
}

const getSegmentBg = ({
  color,
  withStripes,
  isFakeSegment,
}: {
  color: ColorTypes;
  withStripes?: boolean;
  isFakeSegment?: boolean;
}): string => {
  if (isFakeSegment) {
    return `repeating-linear-gradient(
      -60deg,
      ${Colors.Gray[50]},
      ${Colors.Gray[50]} 8px,
      ${Colors.Gray[100]} 8px,
      ${Colors.Gray[100]} 16px
    )`;
  }
  if (withStripes) {
    return `repeating-linear-gradient(
      -60deg,
      ${Colors[color][300]},
      ${Colors[color][300]} 0.5rem,
      ${Colors[color][400]} 0.5rem,
      ${Colors[color][400]} 1rem
    )`;
  }
  return Colors[color][400];
};

const SegmentContainer = styled.div<SegmentContainerProps>`
  position: absolute;
  left: ${({ start }) => 100 * start}%;
  width: ${({ start, end }) => 100 * Math.min(end - start, 1 - start)}%;
  height: 100%;
  background: ${({ color, withStripes }) =>
    getSegmentBg({ color, withStripes })};
  border-radius: 0.25rem;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  outline: 4px solid transparent;
  transition: 0.4s outline ease-in-out;
  top: 10%;

  outline: ${({ color, hoveredTag }) =>
    hoveredTag ? `4px solid ${Colors[color][100]}` : 'none'};

  &:hover {
    outline: ${({ color }) => `4px solid ${Colors[color][100]}`};
  }
`;

interface TimelineSegmentProps {
  color?: ColorTypes;
  start: number;
  end: number;
  startSeconds: number;
  seekToPoint?: (point: number) => void;
  withStripes?: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  hoveredTag?: boolean;
}

const TimelineSegment: React.VFC<TimelineSegmentProps> = ({
  color,
  start,
  end,
  startSeconds,
  seekToPoint,
  withStripes = false,
  onMouseOver,
  onMouseLeave,
  hoveredTag,
}) => (
  <SegmentContainer
    color={color ?? 'Primary'}
    start={start}
    end={end}
    onClick={seekToPoint ? () => seekToPoint(startSeconds) : undefined}
    withStripes={withStripes}
    onMouseOver={onMouseOver}
    onMouseLeave={onMouseLeave}
    hoveredTag={hoveredTag}
  />
);

interface TimelineTrackProps {
  color?: ColorTypes;
  segments: { startSeconds: number; endSeconds: number }[];
  duration: number;
  seekToPoint?: (point: number) => void;
  showEmpty?: boolean;
  withStripes?: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  hoveredTag?: boolean;
}

export const TimelineTrack: React.VFC<TimelineTrackProps> = ({
  color,
  segments,
  duration,
  seekToPoint,
  showEmpty = false,
  withStripes = false,
  onMouseOver,
  onMouseLeave,
  hoveredTag,
}) => (
  <div>
    {showEmpty || segments.length > 0 ? (
      <Track>
        {segments.map((segment, i) => (
          <TimelineSegment
            data-test="timeline-segment"
            key={i}
            color={color}
            start={segment.startSeconds / duration}
            end={segment.endSeconds / duration}
            startSeconds={segment.startSeconds}
            seekToPoint={seekToPoint}
            withStripes={withStripes}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            hoveredTag={hoveredTag}
          />
        ))}
      </Track>
    ) : null}
  </div>
);

type TimelineRowProps = {
  color: ColorTypes;
  segments: { startSeconds: number; endSeconds: number }[];
  duration: number;
  seekToPoint?: (point: number) => void;
  showEmptyTracks?: boolean;
  recommendations?: CreativeRecommendation[];
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  hoveredTag?: boolean;
};

const TimelineRow: React.VFC<TimelineRowProps> = ({
  color,
  segments,
  duration,
  seekToPoint,
  showEmptyTracks = false,
  recommendations = [],
  onMouseOver,
  onMouseLeave,
  hoveredTag,
}) => (
  <Container data-test="timeline-row-container">
    <div data-test="timeline-track-container">
      <TimelineTrack
        data-test="timeline-track"
        color={color}
        segments={
          segments.length > 0
            ? segments
            : [
                {
                  startSeconds: 0,
                  endSeconds:
                    recommendations.filter(
                      (r) =>
                        r.name.type ===
                        RecommendationType.CREATIVE_ADD_TAG_INTRO
                    ).length > 0
                      ? 5
                      : duration,
                },
              ]
        }
        duration={duration}
        seekToPoint={seekToPoint}
        showEmpty={showEmptyTracks}
        withStripes={
          recommendations.filter(
            (r) =>
              r.name.type === RecommendationType.CREATIVE_ADD_TAG ||
              r.name.type === RecommendationType.CREATIVE_ADD_TAG_INTRO
          ).length > 0
        }
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        hoveredTag={hoveredTag}
      />
    </div>
  </Container>
);
export default TimelineRow;
