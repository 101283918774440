import { IntegrationIconProps } from './types';

const AlfaBank = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="14" fill="#EE2A23" />
    <path
      d="M9 22.7808C9 22.6803 9.09594 22.5977 9.21606 22.5977H22.7814C22.9041 22.5977 23 22.6803 23 22.7808V24.8182C23 24.9188 22.9041 25 22.7814 25H9.21606C9.09594 25 9 24.9188 9 24.8182V22.7808Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0017 7C17.4858 7 17.9521 7.87859 18.3433 8.75579L22.4803 18.107C22.5111 18.1799 22.4903 18.2997 22.3543 18.3431C22.2175 18.3858 20.2137 19.0289 20.1027 19.0633C19.9918 19.0985 19.8675 19.0475 19.8274 18.9559C19.7857 18.8657 18.954 16.9653 18.8606 16.7533H13.1444C13.0543 16.9653 12.2126 18.8699 12.1751 18.9559C12.1367 19.0434 12.0174 19.1026 11.8989 19.0633C11.7805 19.0248 9.76916 18.3817 9.64903 18.3431C9.53391 18.3059 9.48135 18.1978 9.52139 18.107C9.66321 17.7806 13.4614 9.20335 13.655 8.75579C14.0321 7.89167 14.5142 7 16.0017 7ZM14.0563 14.6353L16.0133 9.84645L17.9521 14.6353H14.0563Z"
      fill="white"
    />
  </svg>
);
export default AlfaBank;
