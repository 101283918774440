import styled from 'styled-components';
import Colors from '../Colors';

export const Root = styled.div`
  position: relative;
  display: flex;
  width: max-content;
  background-color: ${Colors.Gray[900]};
  border-radius: 8px;

  button {
    margin: 0;
    margin: 0 10px;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  &.md {
    padding: 10px 6px;
  }

  &.lg {
    padding: 14px 8px;
  }
`;

export const ArrowRoot = styled.div`
  position: absolute;
  right: 0;
  bottom: -4px;
  left: 0;
  display: flex;
  align-items: flex-end;

  &.left {
    justify-content: flex-start;
    margin-left: 12px;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
    margin-right: 12px;
  }
`;

export const Arrow = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${Colors.Gray[900]};
  border-radius: 2px;
  transform: rotateZ(45deg);
`;
