/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMutation } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const updateUiPreferences = async (requestParams: { uiPreferences: any; projectId: SDK.UUID }) => {
  const { uiPreferences, projectId } = requestParams;
  const url = `${SDK.BASE_URL()}/projects/${projectId}/ui_preferences`;
  const res = await provider.getJson<object, any>(url, {
    method: 'PATCH',
    body: uiPreferences,
  });
  return res;
};

export default function useUpdateUiPreferences() {
  return useMutation(['project-update-ui-preferences'], (requestParams: { uiPreferences: any; projectId: SDK.UUID }) =>
    updateUiPreferences(requestParams)
  );
}
