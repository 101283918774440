import { CountryIconProps } from './types';

const IQ = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3645)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9997 -0.000610352C6.84012 -0.000610352 2.44161 3.25589 0.746094 7.8255H23.2534C21.5578 3.25589 17.1593 -0.000610352 11.9997 -0.000610352Z"
        fill="#A2001D"
      />
      <path
        d="M11.9997 24.0006C17.1593 24.0006 21.5578 20.7441 23.2533 16.1744H0.746094C2.44161 20.7441 6.84013 24.0006 11.9997 24.0006Z"
        fill="black"
      />
      <path
        d="M9.13039 11.2186C9.12383 11.2186 9.1174 11.219 9.11084 11.2191V11.2186H6.81692C6.93322 10.7692 7.34094 10.4359 7.82605 10.4359V8.87073C6.38759 8.87073 5.21736 10.041 5.21736 11.4794V12.7577V12.7838H9.11084C9.11966 12.7838 9.12659 12.7838 9.13039 12.7838C9.27425 12.7838 9.39125 12.9009 9.39125 13.0447V13.5664H4.17383V15.1316H10.9565V13.0446C10.9565 12.0377 10.1373 11.2186 9.13039 11.2186Z"
        fill="#496E2D"
      />
      <path
        d="M13.0437 13.5657V8.87H11.4785V15.1309H14.0872V13.5657H13.0437Z"
        fill="#496E2D"
      />
      <path
        d="M18.2606 13.5657V8.87H16.6954V13.5657H16.1736V12.0005H14.6084V15.1309H19.3041V13.5657H18.2606Z"
        fill="#496E2D"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3645">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IQ;
