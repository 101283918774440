import { IntegrationIconProps } from './types';

const Behance = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="14" fill="#105DFB" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.875 21.9594V9.74341H12.5223C14.3362 9.74341 15.8066 11.2139 15.8066 13.0278C15.8066 14.1804 15.4522 14.8945 14.1526 15.6269C15.7078 16.3355 16.1397 17.3176 16.1397 18.6874C16.1397 20.5405 14.4935 21.9594 12.6404 21.9594H6.875ZM9.26468 11.7656V14.7031H12.0616C12.0616 14.7031 13.4209 14.7031 13.4209 13.2343C13.4209 11.7656 12.0616 11.7656 12.0616 11.7656H9.26468ZM9.26468 19.8906V16.7187H12.2647C12.7491 16.7187 13.7647 16.9687 13.7647 18.4687C13.7647 19.5812 12.7647 19.8801 12.2647 19.8906H9.26468Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2491 13.1093C19.5303 13.1093 16.9834 14.3437 16.9834 17.6249C16.9834 19.6229 18.0772 22.1874 21.3584 22.1874C23.9834 22.1874 25.1918 20.2604 25.4678 19.2968H22.9991C22.8741 19.7343 22.3897 20.2187 21.3584 20.2187C19.8584 20.2187 19.3272 18.9374 19.2491 18.2968H25.4678V17.6249C25.4678 14.3437 22.9678 13.1093 21.2491 13.1093ZM21.2491 14.9843C19.8491 14.9843 19.3324 16.1406 19.2491 16.7187H22.9991C22.9991 16.1406 22.6491 14.9843 21.2491 14.9843Z"
      fill="white"
    />
    <path d="M18.2647 10.4374V11.9531H24.1553V10.4374H18.2647Z" fill="white" />
  </svg>
);
export default Behance;
