import * as SDK from '@replai-platform/sdk';
import { UseQueryOptions, useQuery } from 'react-query';
import * as provider from '../../api/provider';

export const GET_RELATED_CREATIVES_REQUEST_KEY = 'get-related-creatives-request';

const getRelatedCreativesRequest = async (
  projectId: SDK.UUID,
  creativeId: SDK.UUID
): Promise<SDK.GetRelatedCreativesResponse> =>
  provider.getJson(`${SDK.BASE_URL()}/creative-production/${projectId}/related-creatives/${creativeId}`);

export function useGetRelatedCreativesRequest(
  projectId: SDK.UUID,
  creativeId: SDK.UUID,
  options?: UseQueryOptions<SDK.GetRelatedCreativesResponse>
) {
  return useQuery(
    [GET_RELATED_CREATIVES_REQUEST_KEY, projectId, creativeId],
    () => getRelatedCreativesRequest(projectId, creativeId),
    options
  );
}
