import { PaymentIconProps } from './types';

const Elo = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      d="M10.4321 12.661C10.8157 12.5352 11.226 12.4669 11.653 12.4669C13.5164 12.4669 15.0707 13.7654 15.4273 15.4903L18.0679 14.9622C17.462 12.0305 14.8205 9.82373 11.653 9.82373C10.9278 9.82373 10.2297 9.93962 9.57812 10.1531L10.4321 12.661Z"
      fill="#FECA2F"
    />
    <path
      d="M7.28936 21.1569L8.87042 19.1155C8.16468 18.4016 7.71925 17.3595 7.71925 16.1978C7.71925 15.037 8.16427 13.9949 8.8698 13.2816L7.28791 11.2404C6.08871 12.4531 5.33301 14.2247 5.33301 16.1978C5.33301 18.172 6.08982 19.9441 7.28936 21.1569Z"
      fill="#1BA7DE"
    />
    <path
      d="M15.4281 16.907C15.0704 18.6316 13.517 19.9285 11.6552 19.9285C11.2281 19.9285 10.8169 19.86 10.4333 19.7339L9.57812 22.243C10.2308 22.4575 10.929 22.5736 11.6552 22.5736C14.8198 22.5736 17.4596 20.3683 18.0679 17.4378L15.4281 16.907Z"
      fill="#EC412A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.7721 11.2404V18.4954L32.0741 19.0172L31.4582 20.4482L30.1707 19.9307C29.8812 19.8093 29.6852 19.6243 29.5364 19.4154C29.3935 19.2017 29.2875 18.9096 29.2875 18.5153V11.2404H30.7721ZM20.8979 16.6325C20.9303 14.5578 22.6971 12.9017 24.8411 12.9334C26.6608 12.961 28.1685 14.1935 28.5645 15.8328L21.5266 18.7415C21.1178 18.1372 20.8854 17.4101 20.8979 16.6325ZM22.5083 16.9234C22.4986 16.8363 22.4918 16.7471 22.4944 16.657C22.5146 15.4347 23.5548 14.4593 24.8179 14.4795C25.5055 14.4884 26.116 14.7929 26.5287 15.2641L22.5083 16.9234ZM26.3805 18.2703C25.9563 18.6693 25.3811 18.9124 24.746 18.9037C24.3107 18.8966 23.9073 18.7706 23.5644 18.5606L22.7142 19.8706C23.2965 20.2265 23.983 20.4371 24.7232 20.4482C25.8006 20.4636 26.7826 20.0544 27.4966 19.3786L26.3805 18.2703ZM36.1907 14.4795C35.9371 14.4795 35.6934 14.5191 35.4657 14.593L34.9591 13.1251C35.3457 13.0002 35.7598 12.9324 36.1907 12.9324C38.0711 12.9324 39.6398 14.224 39.9995 15.9399L38.4313 16.249C38.22 15.2392 37.2971 14.4795 36.1907 14.4795ZM33.6159 19.5053L34.6755 18.3466C34.2023 17.9414 33.9043 17.3497 33.9043 16.6901C33.9043 16.0313 34.2023 15.4398 34.6751 15.035L33.6147 13.8762C32.8108 14.5647 32.3043 15.5707 32.3043 16.6901C32.3043 17.811 32.8112 18.8166 33.6159 19.5053ZM36.1906 18.9016C37.296 18.9016 38.2187 18.1426 38.4312 17.1342L39.999 17.4447C39.6375 19.1587 38.0693 20.4486 36.1906 20.4486C35.7594 20.4486 35.3448 20.3805 34.957 20.2551L35.4649 18.7877C35.693 18.8613 35.9368 18.9016 36.1906 18.9016Z"
      fill="black"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default Elo;
