import { CountryIconProps } from './types';

const AE = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M6.78223 16.1729L7.82571 23.2527C9.12565 23.7351 10.5318 23.999 11.9996 23.999C17.1591 23.999 21.5577 20.7425 23.2532 16.1729H6.78223Z"
      fill="black"
    />
    <path
      d="M6.78223 7.8266L7.82571 0.746785C9.12565 0.264394 10.5318 0.000488281 11.9996 0.000488281C17.1591 0.000488281 21.5577 3.25699 23.2532 7.8266H6.78223Z"
      fill="#6DA544"
    />
    <path
      d="M0 11.9999C0 17.1595 3.25655 21.558 7.82611 23.2536V0.746338C3.25655 2.44185 0 6.84037 0 11.9999Z"
      fill="#A2001D"
    />
  </svg>
);
export default AE;
