/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Colors, LineChart, LoadingBars } from '@replai-platform/ui-components';
import { useQuery } from 'react-query';
import { LoadingContainer } from '../../../../../../utils/styles';
import { RootState } from '../../../../../../store/rootReducer';
import { getMetricsHistoryEntries } from '../../../../utils';

interface ConceptTotalMetricChartProps {
  conceptId: SDK.UUID;
  baseMetric?: SDK.Metrics;
}

const ConceptTotalMetricChart = ({ conceptId, baseMetric: providedBaseMetric }: ConceptTotalMetricChartProps) => {
  const filters = useSelector((state: RootState) => state.filters);
  const projectId = useSelector((state: RootState) => state.project.id);
  const baseMetric = providedBaseMetric ?? SDK.Metrics.SPEND;

  const lines = [
    {
      name: SDK.kpiUtils.getDisplayName(baseMetric),
      accessor: baseMetric,
      color: Colors.Orange[400],
    },
  ];

  const getConceptTotalMetricParams = useMemo(
    () => ({
      projectId,
      filters,
      conceptId,
      metrics: [baseMetric],
    }),
    [JSON.stringify(filters), conceptId, projectId, baseMetric]
  );
  const { data, isLoading } = useQuery([getConceptTotalMetricParams], () =>
    getMetricsHistoryEntries(getConceptTotalMetricParams)
  );

  if (isLoading || !data) {
    return (
      <LoadingContainer>
        <LoadingBars barSize="xxl" />
      </LoadingContainer>
    );
  }

  return (
    <LineChart
      data-test="chart"
      data={data}
      yValueFormatter={(v) => SDK.kpiUtils.format(baseMetric, v)}
      xValueFormatter={(s) =>
        new Date(s).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
        })
      }
      lines={lines}
      gradientColor={Colors.Orange[400]}
      yLabel={SDK.kpiUtils.getDisplayName(baseMetric)}
      showLegend={false}
    />
  );
};

export default ConceptTotalMetricChart;
