import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextContainer = styled.div`
  width: 320px;
  padding-right: 32px;
`;

export const NetworksContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;
