/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Colors, LineChart, LoadingBars } from '@replai-platform/ui-components';
import { useQuery } from 'react-query';
import { LoadingContainer } from '../../utils/styles';
import { RootState } from '../../store/rootReducer';
import { api } from '../../api';
import { FiltersState } from '../../store/filters';

async function getMetricsHistoryEntries({
  projectId,
  filters,
  metrics,
  conceptId,
  assetId,
}: {
  projectId: string;
  filters: FiltersState;
  metrics: SDK.MetricOrKpi[];
  conceptId?: SDK.UUID;
  assetId?: SDK.UUID;
}): Promise<
  {
    name: string;
    globalMetrics: Record<string, any>;
    assetMetrics: Record<string, any>;
    [key: string]: any;
  }[]
> {
  const [globalHistory, assetHistory] = await Promise.all([
    api.metrics.getHistoryMetrics({
      projectIds: [projectId],
      metrics,
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
    }),
    api.metrics.getHistoryMetrics({
      projectIds: [projectId],
      metrics,
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      conceptId,
      assetId,
    }),
  ]);

  return globalHistory.history.map((entry) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const copyObjectExcludingDate = (object) => (({ date, ...o }) => o)(object);
    let assetMetrics = assetHistory.history.find((e) => e.date === entry.date);
    if (assetMetrics) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      assetMetrics = copyObjectExcludingDate(assetMetrics);
    }

    const historyEntry = {
      name: entry.date,
      globalMetrics: entry?.metrics,
      assetMetrics: assetMetrics?.metrics ?? {},
    };
    metrics.forEach((metric) => {
      historyEntry[metric] = assetMetrics?.metrics[metric];
    });
    return historyEntry;
  });
}

const CreativeTotalMetricChart = ({
  assetId,
  baseMetric: providedBaseMetric,
}: {
  assetId: SDK.UUID;
  baseMetric?: SDK.Metrics;
}) => {
  const filters = useSelector((state: RootState) => state.filters);
  const projectId = useSelector((state: RootState) => state.project.id);
  const baseMetric = providedBaseMetric ?? SDK.Metrics.SPEND;

  const lines = [
    {
      name: SDK.kpiUtils.getDisplayName(baseMetric),
      accessor: baseMetric,
      color: Colors.Orange[400],
    },
  ];

  const getVideoTotalMetricParams = useMemo(
    () => ({
      projectId,
      filters: {
        ...filters,
        assetTypes: [], // No need to filter for asset type since we fetch the asset ID.
      },
      assetId,
      metrics: [baseMetric],
    }),
    [JSON.stringify(filters), assetId, projectId, baseMetric]
  );
  const { data, isLoading } = useQuery([getVideoTotalMetricParams], () =>
    getMetricsHistoryEntries(getVideoTotalMetricParams)
  );

  if (isLoading || !data) {
    return (
      <LoadingContainer>
        <LoadingBars barSize="xxl" />
      </LoadingContainer>
    );
  }

  return (
    <LineChart
      data-test="chart"
      data={data}
      yValueFormatter={(v) => SDK.kpiUtils.format(baseMetric, v)}
      xValueFormatter={(s) =>
        new Date(s).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
        })
      }
      lines={lines}
      gradientColor={Colors.Orange[400]}
      yLabel={SDK.kpiUtils.getDisplayName(baseMetric)}
      showLegend={false}
    />
  );
};

export default CreativeTotalMetricChart;
