import { WYSIWYGProps } from './types';

const MDHeaderSmall = ({ color }: WYSIWYGProps) => (
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.1543 10.25H6.76855V6.09375H3.23438V10.25H0.834961V0.296875H3.23438V4.24805H6.76855V0.296875H9.1543V10.25Z"
      fill={color || '#98A2B3'}
    />
  </svg>
);
export default MDHeaderSmall;
