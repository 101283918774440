import { Badge, camelCaseToCapitalCase } from '@replai-platform/ui-components';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';

export type StatusAcessorType = {
  status: string;
};

const StatusCellDecorator: React.FC<{
  cellData: StatusAcessorType;
}> = ({ cellData }) => {
  if (!cellData) {
    return null;
  }

  return (
    <Badge color={cellData.status === 'pending_invitation' ? 'Warning' : 'Success'}>
      {camelCaseToCapitalCase(cellData.status ?? 'active')}
    </Badge>
  );
};

function getStatusColumn<T extends object = StatusAcessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T, StatusAcessorType>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<T, StatusAcessorType>) => <StatusCellDecorator cellData={data?.cell?.value} />,
  };
}

export default getStatusColumn;
