/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as SDK from '@replai-platform/sdk';
import { Columns } from '../utils/enums';

const config = (projectKpis, accountTypes = [] as SDK.AccountTypes[]) => {
  const mainOptions = [
    ...(!(accountTypes ?? []).includes(SDK.AccountTypes.SOCIALS)
      ? [
          {
            description: '7D Spend Trend',
            label: '7D Spend Trend',
            value: Columns.SpendTrend,
          },
          {
            description: `Earnings`,
            label: `Earnings`,
            value: Columns.Earnings,
            metric: SDK.Metrics.EARNINGS,
          },
          {
            description: `Earnings D7`,
            label: `Earnings D7`,
            value: Columns.EarningsDay7,
            metric: SDK.Metrics.EARNINGS_DAY_7,
          },
          {
            description: 'Installs',
            label: 'Installs',
            value: Columns.Installs,
            metric: SDK.Metrics.INSTALLS,
          },
          {
            description: `Purchases`,
            label: `Purchases`,
            value: Columns.Purchases,
            metric: SDK.Metrics.PURCHASES,
          },
          {
            description: `Purchases D7`,
            label: `Purchases D7`,
            value: Columns.PurchasesDay7,
            metric: SDK.Metrics.PURCHASES_DAY_7,
          },
          {
            label: 'Ad Instances',
            description: 'Number of ad instances containing the displayed element.',
            value: Columns.AdInstances,
          },
          {
            description: 'Clicks',
            label: 'Clicks',
            value: Columns.Clicks,
            metric: SDK.Metrics.CLICKS,
          },
          {
            description: 'Impressions',
            label: 'Impressions',
            value: Columns.Impressions,
            metric: SDK.Metrics.IMPRESSIONS,
          },
        ]
      : []),
    {
      description: 'Launch Date',
      label: 'Launch Date',
      value: Columns.AGE,
      metric: SDK.Metrics.AGE,
    },
    {
      label: 'Frequency',
      description: 'Frequency at which the displayed element occurred in your ads.',
      value: Columns.Frequency,
    },
    {
      label: 'Creatives',
      description: 'Number of creatives containing the displayed element.',
      value: Columns.NumCreatives,
    },
    ...(accountTypes.includes(SDK.AccountTypes.SOCIALS)
      ? [
          {
            label: 'Views',
            description: 'Amount of views of the displayed element.',
            value: Columns.Views,
            metric: SDK.Metrics.VIEWS,
          },
          {
            label: 'Watch Time',
            description: 'Total number of minutes watched on the displayed element.',
            value: Columns.MinutesWatched,
            metric: SDK.Metrics.MINUTES_WATCHED,
          },
          {
            label: 'Avg View Duration',
            description: 'Avg seconds watched on the displayed element.',
            value: Columns.AvgViewDuration,
            metric: SDK.Metrics.AVG_VIEW_DURATION,
          },
          {
            label: 'Likes',
            description: 'Total number of likes on the displayed element.',
            value: Columns.Likes,
            metric: SDK.Metrics.LIKES,
          },
          {
            label: 'Dislikes',
            description: 'Total number of dislikes on the displayed element.',
            value: Columns.Dislikes,
            metric: SDK.Metrics.DISLIKES,
          },
          {
            label: 'Subscribers Gained',
            description: 'Total number of subscribers gained on the displayed element.',
            value: Columns.SubscribersGained,
            metric: SDK.Metrics.SUBSCRIBERS_GAINED,
          },
          {
            label: 'Subscribers Lost',
            description: 'Total number of subscribers gained on the displayed element.',
            value: Columns.SubscribersLost,
            metric: SDK.Metrics.SUBSCRIBERS_LOST,
          },
          {
            label: 'Shares',
            description: 'Amount of shares of the displayed element.',
            value: Columns.Shares,
            metric: SDK.Metrics.SHARES,
          },
          {
            label: 'Comments',
            description: 'Amount of comments of the displayed element.',
            value: Columns.Comments,
            metric: SDK.Metrics.COMMENTS,
          },
        ]
      : []),
  ];

  const secondaryOptions = [
    ...[...Object.keys(SDK.kpiUtils.filters)]
      .filter((kpi) => projectKpis.includes(kpi) && !mainOptions.some((opt) => opt.metric === kpi))
      .flatMap((kpi) => [
        {
          description: 'KPI value of the cluster according to the filters you have selected',
          label: SDK.kpiUtils.getDisplayName(kpi),
          value: `metrics.${kpi}`,
        },
      ]),
    ...(accountTypes.includes(SDK.AccountTypes.SOCIALS)
      ? []
      : [
          {
            description: `Replai Score© AI algorithm is applied to each of your videos to predict their success, on a 0 to 100 scale.`,
            label: `Replai Score`,
            value: Columns.ReplaiScore,
          },
        ]),
  ];

  return {
    groups: [
      {
        name: 'General',
        options: mainOptions,
      },
      ...(secondaryOptions.length > 0
        ? [
            {
              name: 'KPI',
              options: secondaryOptions,
            },
          ]
        : []),
    ],
    initialValues: !(accountTypes ?? []).includes(SDK.AccountTypes.SOCIALS)
      ? [
          Columns.Preview,
          Columns.Name,
          Columns.Spend,
          Columns.SpendTrend,
          Columns.Frequency,
          ...(projectKpis.includes(SDK.KPI.IPM) ? [Columns.IPM] : []),
          Columns.AGE,
        ]
      : [Columns.Preview, Columns.Name, Columns.Impressions, Columns.Views, Columns.Likes, Columns.Dislikes],
  };
};

const getColumnsConfig = (projectKpis, accountTypes?: SDK.AccountTypes[]) => config(projectKpis, accountTypes);

export default getColumnsConfig;
