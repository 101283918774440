import * as SDK from '@replai-platform/sdk';
import { Icons, Colors, ColorTypes, BadgeSize } from '@replai-platform/ui-components';
import { Page } from '../../../utils/enums';
import { logEvent } from '../../../analytics';
import { getTagIconDefinition, shouldHighlight } from '../../../utils';

export const MAX_PREVIEW_ITEMS = 10;

export const TAG_SUGGESTIONS_LOCKED_FEATURE_NAME = 'Tag Suggestions';

export const PROMISING_BADGE_PROPS = {
  color: 'Success' as ColorTypes,
  children: <Icons.BaseIcons.DollarSign color={Colors.Common.White} dimension={12} />,
  inverted: true,
  size: 'sm' as BadgeSize,
  style: { padding: '4px' },
};

export const NEW_BADGE_PROPS = {
  color: 'Primary' as ColorTypes,
  children: 'New',
  inverted: true,
  size: 'sm' as BadgeSize,
};

export const getTagBadge = ({ tagMetric }: { tagMetric: SDK.TagMetrics }) => {
  if (shouldHighlight({ shareOfSpend: tagMetric.shareOfSpend, shareOfAssets: tagMetric.shareOfAssets })) {
    return {
      badge: PROMISING_BADGE_PROPS,
      badgeTooltip: 'This tag has an higher average spend than normal',
    };
  }

  return {};
};

export const hasTagIcon = ({ type, value }: { type: string; value: string | null }) =>
  !!getTagIconDefinition({
    type,
    value,
  })?.iconName;

export const logEventOnAction = ({
  action,
  componentSuffix,
  params = {},
}: {
  action: string;
  componentSuffix?: string;
  params?: object;
}) => {
  logEvent({
    component: `Tag Library${componentSuffix ? ` - ${componentSuffix}` : ''}`,
    action,
    category: 'user_actions',
    parameters: { page: Page.TagsLibrary, ...params },
  });
};
