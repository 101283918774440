import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getDescription = async (requestParams: SDK.GetTagsDescriptionsRequest) => {
  const res = await provider.getJson<object, SDK.GetTagsDescriptionsResponse>(
    `${SDK.BASE_URL()}/tags/description?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useTagDescription<R = SDK.GetTagsDescriptionsResponse>(
  requestParams: SDK.GetTagsDescriptionsRequest,
  options?: UseQueryOptions<SDK.GetTagsDescriptionsResponse, unknown, R>
) {
  return useQuery(['tag-description', requestParams], () => getDescription(requestParams), options);
}
