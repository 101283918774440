import * as RadixAccordion from '@radix-ui/react-accordion';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import type { Color, ColorTypes } from '../Colors';
import Colors from '../Colors';
import { IconType } from '../Icons';
import { ChevronDown } from '../Icons/Base';
import { getIcon } from '../Icons';
import { type AllIconTypes } from '../Icons/getIcon';
import Typography from '../Typography/Typography';

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: 'var(--radix-accordion-content-height)';
  }
`;

const slideUp = keyframes`
  from {
    height: 'var(--radix-accordion-content-height)';
  }
  to {
    height: 0;
  }
`;

const CleanTypography = styled(Typography)`
  margin: 0;
`;

const AccordionRoot = styled(RadixAccordion.Root)<{
  $rootBorderColor: string;
  $rootBgColor: string;
}>`
  border-radius: 0.5rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ $rootBorderColor }) => $rootBorderColor};
  background-color: ${({ $rootBgColor }) => $rootBgColor};
`;

const AccordionItem = styled(RadixAccordion.Item)`
  &:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  &:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  &:focus-within {
    position: relative;
    z-index: 1;
  }
`;

const AccordionHeader = styled(RadixAccordion.Header)`
  margin: 0;
  all: unset;
  display: flex;
`;

const StyledTrigger = styled(RadixAccordion.Trigger)`
  all: unset;
  font-family: inherit;
  background-color: transparent;
  flex: 1;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 1rem;
  cursor: pointer;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  & > svg:last-child {
    transition: transform 200ms;
  }

  [data-state='close'] & > svg:last-child {
    transform: rotate(0deg);
  }
  [data-state='open'] & > svg:last-child {
    transform: rotate(-180deg);
  }
`;

interface AccordionTriggerProps {
  color: Color;
  icon?: {
    name: AllIconTypes;
    type: IconType;
  };
}

const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<'button'> & AccordionTriggerProps
>(({ icon, color, title, ...props }, forwardedRef) => {
  const IconEl = ({
    dimension = 24,
    color,
  }: {
    dimension?: number;
    color: Color;
  }) => {
    if (icon) {
      const Icon = getIcon({ name: icon.name, iconType: icon.type });
      return (
        <Icon
          dimension={dimension}
          {...(icon.type !== IconType.TAG && {
            color,
          })}
        />
      );
    }
    return null;
  };
  return (
    <AccordionHeader>
      <StyledTrigger {...props} ref={forwardedRef}>
        <HeaderContent>
          {icon ? <IconEl color={color} /> : null}
          <CleanTypography type="text-lg" fontWeight="medium" color={color}>
            {title}
          </CleanTypography>
          <ChevronDown aria-hidden color={color} />
        </HeaderContent>
      </StyledTrigger>
    </AccordionHeader>
  );
});

const StyledContent = styled(RadixAccordion.Content)`
  padding: 1rem;
  background-color: white;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  &[data-state='open'] {
    animation: ${slideDown} 200ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  &[data-state='closed'] {
    animation: ${slideUp} 200ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`;

const AccordionContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({ children, ...props }, forwardedRef) => (
  <StyledContent {...props} ref={forwardedRef}>
    {children}
  </StyledContent>
));

export interface AccordionItem {
  title: string;
  content: React.ReactNode;
  icon?: {
    name: AllIconTypes;
    type: IconType;
  };
}

export type AccordionProps = {
  collapsible?: boolean;
  colorSchema: ColorTypes;
  items: AccordionItem[];
} & (
  | {
      type: 'single';
      defaultValue?: string;
      value?: string;
      onValueChange?: (value: string) => void;
    }
  | {
      type: 'multiple';
      defaultValue?: string[];
      value?: string[];
      onValueChange?: (value: string[]) => void;
    }
);

const Accordion: React.VFC<AccordionProps> = ({
  collapsible,
  type,
  colorSchema,
  value,
  defaultValue,
  items,
  onValueChange,
}) => {
  const rootBorderColor =
    colorSchema === 'BlueGray'
      ? Colors[colorSchema][400]
      : Colors[colorSchema][300];
  const rootBgColor = Colors[colorSchema][100];
  const headerContentColor =
    colorSchema === 'BlueGray'
      ? Colors[colorSchema][500]
      : Colors[colorSchema][800];

  return (
    <AccordionRoot
      {...(type === 'single'
        ? ({
            type: 'single',
            defaultValue,
            value,
            collapsible,
            onValueChange,
          } as RadixAccordion.AccordionSingleProps)
        : ({
            type: 'multiple',
            defaultValue,
            value,
            onValueChange,
          } as RadixAccordion.AccordionMultipleProps))}
      $rootBorderColor={rootBorderColor}
      $rootBgColor={rootBgColor}
    >
      {items.map(({ title, content, icon }, index) => (
        <AccordionItem value={title} key={index}>
          <AccordionTrigger
            icon={icon}
            color={headerContentColor}
            title={title}
          />
          <AccordionContent id="accordion-content">{content}</AccordionContent>
        </AccordionItem>
      ))}
    </AccordionRoot>
  );
};

export default Accordion;
