import * as SDK from '@replai-platform/sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import * as provider from '../../api/provider';

const getConceptRecommendations = async (requestParams: SDK.GetRecommendationsRequest & { refreshCache?: boolean }) => {
  const opts = {} as provider.SupportedHeaders;
  if (requestParams.refreshCache) {
    opts['cache-control'] = 'max-age=0';
  }
  const res = await provider.getJson(
    `${SDK.BASE_URL()}/recommendations/concepts?${SDK.convertRequestToQueryParams(requestParams)}`,
    undefined,
    opts
  );
  return res;
};

export default function useConceptRecommendations(
  requestParams: SDK.GetRecommendationsRequest & { refreshCache?: boolean },
  options?: UseQueryOptions<SDK.GetRecommendationsResponse>
): UseQueryResult<SDK.GetRecommendationsResponse> {
  return useQuery(['recommendations-concept', requestParams], () => getConceptRecommendations(requestParams), options);
}
