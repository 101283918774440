import { Image } from 'antd';
import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';
import { TwoColumnLayout } from '../shared/TwoColumnLayout';
import { VerticalLayout } from '../shared/VerticalLayout';

const GOOGLE_FORM_URL = 'https://docs.google.com/forms/d/1NN_qPhwchAY7rprH8HYxhn0HNjqnLJ6ji1aLJufUhBg/viewform';

export const GoogleConnectionGuide: NetworkConnectionGuideContent = {
  title: 'Google Connection Guide',
  icon: 'Google',
  guide: {
    label: 'Connection Guide',
    steps: [
      {
        title: 'Send Replai your accounts IDs',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              { item: 'Open Google Ads platform' },
              { item: 'On the top right corner, click/open your account' },
              { item: 'Copy your ID (one per account you want connected)' },
              {
                item: (
                  <>
                    Use {Shared.link({ label: 'this form', url: GOOGLE_FORM_URL })} to share all of your relevant
                    account IDs <Shared.HighlightText>except for IDs the Manager&apos;s</Shared.HighlightText>
                    <p>
                      Example of a valid ID: <Shared.BoldText>111-111-1111</Shared.BoldText>
                    </p>
                  </>
                ),
              },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/google/step-1.png"
          />
        ),
      },
      {
        title: 'Verify Email Request',
        description: '(No action required)',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                In less than 24 hours, you will receive an email notification to connect your account to us. The Replai
                team will provide you the requesting account ID so ignore requests from any other ID&apos;s.
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/google/step-2.1.png"
          />
        ),
      },
      {
        title: 'Accept us from the platform',
        description: '(Alternative step)',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                If you don&apos;t receive an email, you can do it also from the platform. On the top right corner, click
                on <Shared.BoldText>Settings</Shared.BoldText>. When a dropdown appears, select{' '}
                <Shared.BoldText>Access and security</Shared.BoldText>. Go to{' '}
                <Shared.BoldText>Managers</Shared.BoldText> tab and click on <Shared.BoldText>ACCEPT</Shared.BoldText>{' '}
                Again, ensure the requesting ID matches the Replai ID provided to you.
                <div
                  style={{
                    marginTop: '1rem',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <figure>
                    <Image
                      width="200px"
                      height="100%"
                      src="https://cdn.replai.io/assets/integration-guide/google/step-3.1.png"
                      alt="Step 3.1"
                    />
                    <Shared.FigureCaption>Step 1</Shared.FigureCaption>
                  </figure>
                  <figure>
                    <Image
                      width="450px"
                      height="100%"
                      src="https://cdn.replai.io/assets/integration-guide/google/step-3.2.png"
                      alt="Step 3.2"
                    />
                    <Shared.FigureCaption>Step 2</Shared.FigureCaption>
                  </figure>
                </div>
              </>
            }
          />
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: [
      {
        title: 'Which Google campaign types can I expect to see on Replai?',
        description: 'We currently support Video and App campaign types.',
      },
      ...Shared.DEFAULT_NETWORK_FAQS,
      {
        title:
          'Some MMPs only report Google data on the ad set, not on the creative. How does Replai still provide creative metrics?',
        description: (
          <Shared.UList>
            <Shared.UListItem>
              Due to how Google&apos;s APIs work, many MMPs report Google metrics on the ad set level and not on
              individual creatives. Ad sets might contain multiple creatives, which makes it hard to do creative-level
              analysis for Google on MMPs since you won&apos;t know which creative the metrics belong to.
            </Shared.UListItem>
            <Shared.UListItem>
              To enable you to still get creative-level MMP metrics for Google creatives, Replai has a proprietary
              algorithm trained on huge historical datasets that accurately assigns metrics to the correct creative.
              Apart from being trained on years of historical data, the model takes into consideration all metadata and
              available metrics on your ad set and creative to provide the best match possible.
            </Shared.UListItem>
          </Shared.UList>
        ),
      },
    ],
  },
};
