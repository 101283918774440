import styled from 'styled-components';

export const RootContainer = styled.div`
  width: 100%;
  padding-right: 0.5rem;
  overflow: auto;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  gap: 3rem;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const Container = styled.div`
  width: 50%;
  & > div {
    width: 100% !important;
  }
`;

export const InsightsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16.7rem, 1fr));
  gap: 1rem;
  padding-top: 2rem;
`;
