import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';
import { camelCaseToCapitalCase } from '@replai-platform/ui-components';
import { GOOGLE_CONFIG, MIXPANEL_PROJECT_ID } from '../utils/constants';
// eslint-disable-next-line import/no-cycle
import store from '../store';
import gtag from './gtag';

const SAME_EVENT_INTERVAL_MS = 20;
const REPEATED_EVENT_COUNTER: { [key: string]: number } = {};

let currentStoreState = { userId: '', userEmail: '', project: { id: '', name: '', organizationName: '' } };
function listener() {
  const state = store.getState();
  currentStoreState = {
    userId: state.auth.id,
    userEmail: state.auth.email,
    project: {
      id: state.project.id,
      name: state.project.name,
      organizationName: state.project.organizationName,
    },
  };
}
store.subscribe(listener);

mixpanel.init(MIXPANEL_PROJECT_ID);

interface EventParams {
  component: string; // Component where the action was performed
  action: string; // Action performed on the component
  category?: 'user_actions' | 'dashboard' | 'insights' | 'single_tag_view' | 'engagement'; // Action Category type
  parameters?: Record<string, unknown>; // Extra parameters required for analysis
}

/**
 * Function responsible for keeping a specific standard/naming convention for events
 *
 * @param component component name
 * @param action the action performed on the component
 * @returns string with specific naming convention
 */
function createActionWithEventPrefix({ component, action }: { component: string; action: string }) {
  return `${camelCaseToCapitalCase(component.trim())} - ${action.trim()}`;
}

/**
 * Function responsible for checking if an event is repeated, meaning that the
 * event was triggered with a small interval, meaning that no human user could
 * produce events so close to each other in terms of timing.
 *
 * @param eventKey The key that represents the event parameters
 * @returns True if the event is repeated
 */
function isEventRepeated(eventKey: string) {
  const eventTime = REPEATED_EVENT_COUNTER[eventKey];
  REPEATED_EVENT_COUNTER[eventKey] = Date.now();

  setTimeout(() => {
    delete REPEATED_EVENT_COUNTER[eventKey];
  }, SAME_EVENT_INTERVAL_MS);
  return Date.now() - eventTime < SAME_EVENT_INTERVAL_MS;
}

export const logEvent = (event: EventParams) => {
  const { userId, userEmail, project } = currentStoreState;

  const { component, action, category, parameters } = event;

  const enrichedParameters = {
    ...parameters,
    projectId: project.id,
    projectName: project.name,
    organizationName: project.organizationName,
  };
  const customAction = createActionWithEventPrefix({ component, action });

  window.Appcues?.track(customAction, {
    category,
    label: customAction,
    ...enrichedParameters,
  });

  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    // eslint-disable-next-line no-console
    console.log('📝 %clogEvent', 'color: #7FFFD4', customAction, { ...enrichedParameters });
  } else {
    // If the event is repeated, logs it in Sentry
    const eventKey = JSON.stringify({
      component,
      action,
      ...parameters,
    });
    const isRepeated = isEventRepeated(eventKey);
    if (isRepeated) {
      Sentry.captureEvent({
        message: `Duplicated analytics event - ${customAction}`,
        level: 'error',
        extra: {
          component,
          action,
          parameters: {
            ...enrichedParameters,
          },
        },
      });
    }

    if (!isRepeated) {
      gtag('event', action, {
        event_category: category,
        event_label: customAction,
        ...parameters,
      });

      mixpanel.track(customAction, { ...enrichedParameters });

      if (userId) {
        mixpanel.identify(userId);
        mixpanel.people.set({ $email: userEmail, $name: userId });
      }
    }
  }
};

gtag('js', new Date());

gtag('config', GOOGLE_CONFIG);
