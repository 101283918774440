import { IconProps } from './types';

const Gitlab = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1027_7163)">
      <path
        d="M22.65 14.39L12 22.13L1.34999 14.39C1.20722 14.285 1.10131 14.1375 1.04743 13.9687C0.993542 13.7998 0.99444 13.6183 1.04999 13.45L2.26999 9.66996L4.70999 2.15996C4.73366 2.09877 4.77133 2.04397 4.81999 1.99996C4.89923 1.92758 5.00267 1.88745 5.10999 1.88745C5.21731 1.88745 5.32075 1.92758 5.39999 1.99996C5.45137 2.04963 5.48924 2.11158 5.50999 2.17996L7.94999 9.66996H16.05L18.49 2.15996C18.5137 2.09877 18.5513 2.04397 18.6 1.99996C18.6792 1.92758 18.7827 1.88745 18.89 1.88745C18.9973 1.88745 19.1008 1.92758 19.18 1.99996C19.2314 2.04963 19.2692 2.11158 19.29 2.17996L21.73 9.68996L23 13.45C23.0505 13.6234 23.0437 13.8086 22.9807 13.9779C22.9176 14.1473 22.8017 14.2918 22.65 14.39V14.39Z"
        stroke={color || '#101828'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_1027_7163">
        <rect fill="white" height={dimension || 24} width={dimension || 24} />
      </clipPath>
    </defs>
  </svg>
);

export default Gitlab;
