import * as SDK from '@replai-platform/sdk';
import { UseQueryOptions, useQuery } from 'react-query';
import * as provider from '../../api/provider';

export const GET_CREATIVE_THUMBNAIL_REQUEST_KEY = 'get-creative-thumbnail-request';

const getCreativeThumbnailRequest = async (
  projectId: SDK.UUID,
  creativeId: SDK.UUID
): Promise<SDK.GetCreativeThumbnailResponse> =>
  provider.getJson(`${SDK.BASE_URL()}/creative-production/${projectId}/creative/${creativeId}/thumbnail`);

export function useGetCreativeThumbnailRequest(
  projectId: SDK.UUID,
  creativeId: SDK.UUID,
  options?: UseQueryOptions<SDK.GetCreativeThumbnailResponse>
) {
  return useQuery(
    [GET_CREATIVE_THUMBNAIL_REQUEST_KEY, projectId, creativeId],
    () => getCreativeThumbnailRequest(projectId, creativeId),
    options
  );
}
