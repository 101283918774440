import { CountryIconProps } from './types';

const CU = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3602)">
      <path
        d="M12 24.0001C18.6273 24.0001 23.9999 18.6275 23.9999 12.0001C23.9999 5.37274 18.6273 0.000183105 12 0.000183105C5.37256 0.000183105 0 5.37274 0 12.0001C0 18.6275 5.37256 24.0001 12 24.0001Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9999 0.000244141C8.07405 0.000244141 4.58871 1.8857 2.39941 4.80024H21.6004C19.4111 1.88565 15.9258 0.000244141 11.9999 0.000244141Z"
        fill="#0052B4"
      />
      <path
        d="M11.9999 24.0002C15.9258 24.0002 19.4111 22.1147 21.6004 19.2002H2.39941C4.58866 22.1148 8.07406 24.0002 11.9999 24.0002Z"
        fill="#0052B4"
      />
      <path
        d="M0 12.0002C0 12.8222 0.0828749 13.6248 0.240328 14.4002H23.7597C23.9172 13.6248 24 12.8222 24 12.0002C24 11.1783 23.9171 10.3757 23.7597 9.60022H0.240328C0.0828749 10.3757 0 11.1783 0 12.0002Z"
        fill="#0052B4"
      />
      <path
        d="M3.51468 3.51459C-1.17156 8.20082 -1.17156 15.7988 3.51468 20.4852C5.45122 18.5486 7.31366 16.6861 11.9999 11.9999L3.51468 3.51459Z"
        fill="#D80027"
      />
      <path
        d="M4.85695 8.86957L5.63385 11.2609H8.14851L6.11427 12.739L6.89118 15.1303L4.85695 13.6524L2.82262 15.1303L3.59971 12.739L1.56543 11.2609H4.0799L4.85695 8.86957Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3602">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default CU;
