import styled from 'styled-components';
import { Image } from 'antd';
import * as Shared from '..';

const ImageContainer = styled.div`
  max-width: 50%;
  max-height: 25rem;

  .ant-image-mask {
    border-radius: 8px;
  }
`;

const StyledImage = styled(Image)`
  max-width: 100%;
  max-height: 25rem;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const TwoColumnLayout = ({
  contentDescription,
  contentItems,
  imageSrc,
}: {
  contentDescription?: string | JSX.Element;
  contentItems?: Shared.ListItem[];
  imageSrc?: string;
}) => (
  <Shared.MainContent>
    <Shared.ContentContainer>
      {contentDescription ? <Shared.RegularText>{contentDescription}</Shared.RegularText> : null}
      {contentItems ? (
        <Shared.OList>
          {contentItems.map((option) => (
            <Shared.OListItem>
              <Shared.RegularText>{option.item}</Shared.RegularText>
            </Shared.OListItem>
          ))}
        </Shared.OList>
      ) : null}
    </Shared.ContentContainer>
    {imageSrc ? (
      <ImageContainer>
        <StyledImage src={imageSrc} preview={{ icons: { rotateLeft: null, rotateRight: null } }} />
      </ImageContainer>
    ) : null}
  </Shared.MainContent>
);

export default TwoColumnLayout;
