import styled from 'styled-components';
import Colors from '../Colors';
import Typography from '../Typography/Typography';

export const VideoItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  overflow: hidden;
  border-radius: 8px;
  gap: 4px;
  height: 96px;
  justify-content: space-between;
  background-color: ${Colors.Primary[50]};
  border: 1px solid ${Colors.Primary[300]};
`;

export const ImageContainer = styled.div`
  margin-right: 12px;
`;

export const Image = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 4px;
`;

export const Name = styled(Typography)`
  margin: 0;
  flex: 2;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  div > svg {
    height: 10px;
    width: 10px;
  }
`;
