import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase, Tab } from '@replai-platform/ui-components';
import { capitalizeFirstLetter } from '../../../utils';
import type { TagsPageGroupingState } from '../../../utils/enums/tags';

export const groupingButtonLabel = (group: TagsPageGroupingState) => {
  switch (group.type) {
    case 'core':
    case 'custom':
    case 'psychological':
      return `All ${capitalizeFirstLetter(group.type)} Tags`;
    case 'tagType':
      return `All "${capitalizeFirstLetter(group.value ?? SDK.messages.NOT_AVAILABLE)}" Tags`;
    case 'none':
      return 'All tags';
    default: {
      const exhaustiveCheck: never = group.type;
      return exhaustiveCheck;
    }
  }
};

export const groupingDropdownOptions = (
  tagsConfig: Partial<SDK.ProjectConfigTags>,
  tagTypesWithCounts: { type: string; count: number }[],
  isTechmerc1569Enabled: boolean
): Tab[] => [
  {
    id: 'none',
    label: 'All Tags',
  },
  ...(tagsConfig?.custom?.length
    ? [
        {
          id: 'custom',
          label: `All Custom ${isTechmerc1569Enabled ? 'Features' : 'Tags'}`,
        },
      ]
    : []),
  ...(tagsConfig?.psychological?.length
    ? [
        {
          id: 'psychological',
          label: 'All Psychological Tags',
        },
      ]
    : []),
  {
    id: 'core',
    label: `All Core ${isTechmerc1569Enabled ? 'Features' : 'Tags'}`,
  },
  ...(tagTypesWithCounts?.length
    ? [
        ...tagTypesWithCounts.map(({ type, count }) => ({
          id: type,
          label: camelCaseToCapitalCase(type),
          subLabel: `(${count} tags)`,
        })),
      ]
    : []),
];

export const getTagTypeValueByTabLabel = (tab: Tab): TagsPageGroupingState => {
  switch (tab.label) {
    case 'All Tags': {
      return {
        type: 'none',
      };
    }
    case 'All Custom Features': {
      // https://replai.atlassian.net/browse/TECHMERC-1569
      return {
        type: 'custom',
      };
    }
    case 'All Custom Tags': {
      return {
        type: 'custom',
      };
    }
    case 'All Psychological Tags': {
      return {
        type: 'psychological',
      };
    }
    case 'All Core Features': {
      // https://replai.atlassian.net/browse/TECHMERC-1569
      return {
        type: 'core',
      };
    }
    case 'All Core Tags': {
      return {
        type: 'core',
      };
    }
    default: {
      return {
        type: 'tagType',
        value: tab.id,
      };
    }
  }
};

export const getTabIdByGroupingState = (groupState: TagsPageGroupingState): string => {
  switch (groupState.type) {
    case 'tagType':
      return groupState.value ?? '';
    case 'core':
    case 'psychological':
    case 'none':
      return groupState.type;
    case 'custom':
    default:
      return 'custom';
  }
};
