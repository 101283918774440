import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';
import { GET_ALL_QUERY_KEY } from './constants';

const getProductUpdates = async (requestParams: SDK.GetProductUpdatesRequest): Promise<SDK.GetProductUpdatesResponse> =>
  provider.getJson(`${SDK.BASE_URL()}/productUpdates?${SDK.convertRequestToQueryParams(requestParams)}`);

export default function useProductUpdates<R = SDK.GetProductUpdatesResponse>(
  requestParams: SDK.GetProductUpdatesRequest,
  options?: UseQueryOptions<SDK.GetProductUpdatesResponse, unknown, R>
) {
  return useQuery(GET_ALL_QUERY_KEY, () => getProductUpdates(requestParams), {
    ...options,
    cacheTime: 0,
  });
}
