import * as SDK from '@replai-platform/sdk';

export const ALLOWED_EXCLUDED_TAGS = [...Object.values(SDK.CoreTags), 'internal', 'endcardPresence'];

/**
 * https://replai.atlassian.net/wiki/spaces/RPL/pages/2068021223/Core
 */
export const MARKET_CORE_TAGS = [
  SDK.CoreTags.ASPECT_RATIO,
  SDK.CoreTags.COLORFULNESS,
  SDK.CoreTags.COLOR_TEMPERATURE,
  SDK.CoreTags.CTA,
  SDK.CoreTags.DURATION,
  SDK.CoreTags.INTRO_LOUDNESS,
  SDK.CoreTags.LANGUAGE,
  SDK.CoreTags.LUMINOSITY,
  SDK.CoreTags.MUSIC,
  SDK.CoreTags.NUMBER_OF_SCENES,
  SDK.CoreTags.ORIENTATION,
  SDK.CoreTags.PACE,
  SDK.CoreTags.SCENE_DURATION,
];

export const MARKET_LOCKED_FEATURE_NAME = 'Market';
