import { WYSIWYGProps } from './types';

const HeaderSmall = ({ color }: WYSIWYGProps) => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.74805 12H7.02148V7.25H2.98242V12H0.240234V0.625H2.98242V5.14062H7.02148V0.625H9.74805V12Z"
      fill={color || '#98A2B3'}
    />
  </svg>
);
export default HeaderSmall;
