/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react';
import * as Styled from './styles';

export interface ModalProps extends React.HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  maskClosable?: boolean;
  width?: string | number;
  height?: string | number;
  className?: string;
  closable?: boolean;
  modalHeader?: ReactNode;
  modalFooter?: ReactNode;
  onClose: () => void;
}

const Modal = ({
  children,
  width,
  height,
  className,
  closable = true,
  modalFooter,
  modalHeader,
  isOpen,
  onClose,
  maskClosable,
}: ModalProps) => (
  <div
    aria-hidden="true"
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    <Styled.Modal
      closable={closable}
      className={`replai-ant-modal ${className ?? ''}`}
      title={modalHeader ?? null}
      footer={modalFooter ?? null}
      open={isOpen}
      onCancel={onClose}
      width={width ?? '512px'} // 512px is Antd's default width
      style={height ? { height } : {}}
      bodyStyle={{ height: '100%' }}
      maskClosable={maskClosable}
    >
      {children}
    </Styled.Modal>
  </div>
);

export default Modal;
