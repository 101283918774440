import { useSelector } from 'react-redux';
import { useTitle } from 'react-use';
import { useMemo } from 'react';
import * as SDK from '@replai-platform/sdk';
import type { RootState } from '../../store/rootReducer';
import Summary from './Summary';
import TopInsights from './TopInsights';
import TopVideos from './TopVideos';
import TopNavPageTitle from '../../components/TopNavPageTitle';
import useNetworkFilter from '../../components/FilterBar/hooks/useNetworkFilter';
import type { NetworkFilterProps } from '../../components/FilterBar/Filters';
import { Page } from '../../utils/enums';
import { daysBetween } from './utils';

const Dashboard: React.VFC = () => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectName = useSelector((state: RootState) => state.project.name);
  const accountTypes = useSelector((state: RootState) => state.project.config.accountTypes);
  const startDate = useSelector((state: RootState) => state.filters.startDate);
  const endDate = useSelector((state: RootState) => state.filters.endDate);

  useTitle(`Overview - ${projectName}`);

  const {
    networkFilterOptions,
    networkFilterOnChange,
    isLoading: isNetworkFilterLoading,
  } = useNetworkFilter(Page.Dashboard);
  const networkFilter = useMemo(
    () =>
      ({
        options: networkFilterOptions,
        onChange: networkFilterOnChange,
        loading: isNetworkFilterLoading,
      } as NetworkFilterProps),
    [networkFilterOptions, networkFilterOnChange, isNetworkFilterLoading]
  );

  const daysOfDataToConsider = daysBetween(new Date(startDate), new Date(endDate));

  return (
    <>
      <TopNavPageTitle
        title="Overview"
        subtitle={`Quickly get your most important insights, concepts and performance results from the last ${daysOfDataToConsider} days of data.`}
        showFilterBar
        networkFilter={networkFilter}
        eventPrefix="dashboard"
        withDateFilter
      />
      <Summary filtersLoading={isNetworkFilterLoading} />
      {/* TODO(TECHMERC-1555): cleanup */}
      {(!accountTypes || !accountTypes?.includes(SDK.AccountTypes.SOCIALS)) &&
      projectId !== '248ca9eb-6f5b-4106-b52e-cc3c6d96436b' ? (
        <TopInsights />
      ) : null}
      <TopVideos filtersLoading={isNetworkFilterLoading} />
    </>
  );
};

export default Dashboard;
