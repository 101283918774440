import { AppcuesTutorial } from '@replai-platform/ui-components';
import styled from 'styled-components';

const AlignRightHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

const ColumnHeader = ({ title, appcuesId }: { title: string; appcuesId?: string }) => (
  <AlignRightHeaderWrapper>
    {title} {appcuesId ? <AppcuesTutorial appcuesId={appcuesId} /> : undefined}
  </AlignRightHeaderWrapper>
);

export default ColumnHeader;
