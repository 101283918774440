import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getCountries = async (requestParams: SDK.GetCountriesMetricsRequest) =>
  provider.getJson<object, SDK.GetCountriesMetricsResponse>(
    `${SDK.BASE_URL()}/countries/metrics?${SDK.convertRequestToQueryParams(requestParams)}`
  );

export default function useCountries<R = SDK.GetCountriesMetricsResponse>(
  requestParams: SDK.GetCountriesMetricsRequest,
  options?: UseQueryOptions<SDK.GetCountriesMetricsResponse, unknown, R>
) {
  return useQuery(['get-countries', requestParams], () => getCountries(requestParams), options);
}
