/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import * as SDK from '@replai-platform/sdk';
import { CreativeRecommendation, Network, RecommendationType, UUID } from '@replai-platform/sdk';
import {
  AppcuesTutorial,
  camelCaseToCapitalCase,
  Colors,
  LoadingScreen,
  TagBreakdownT,
  Timeline as TimelineUI,
  TimelineV2,
  Typography,
} from '@replai-platform/ui-components';
import { useMemo, useState } from 'react';
import type ReactPlayer from 'react-player/lazy';
import { useQueries } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasure } from 'react-use';
import { logEvent } from '../../analytics';
import { getTagsVideoAssetsQueryOptions } from '../../api/hooks/tags/useTagsVideoAssets';
import { RouteAnimator } from '../../routes/RouteAnimator';
import { AppActions } from '../../store/app';
import type { RootState } from '../../store/rootReducer';
import { APPCUES_IDS } from '../../utils/constants';
import { TypographyClean } from '../../utils/styles';
import TagPreviewVideosWrapper from '../TagPreviewVideosWrapper';
import TimelineRecommendations from '../TimelineRecommendations';
import * as Styled from './styles';
import { createCoreAnnotations, orderAnnotations } from './utils';

/**
 * Creates a TagBreakdownT object, which represents a row
 * in the timeline
 */
const createTagBreakdownObject = ({
  id,
  type,
  value,
  segments,
  recommendations,
  projectNetworks,
}: {
  id: UUID;
  type: string;
  value: string | null;
  segments: { startSeconds: number; endSeconds: number }[];
  recommendations: CreativeRecommendation[];
  projectNetworks: Network[];
}): TagBreakdownT => ({
  id,
  key: id,
  type: camelCaseToCapitalCase(type),
  originalType: type,
  value,
  segments,
  ...(recommendations?.length
    ? {
        recommendations: (
          <TimelineRecommendations recommendations={recommendations} projectNetworks={projectNetworks} />
        ),
      }
    : {}),
});

/**
 * Builds the breakdown data necessary to populate the timeline component.
 */
export function buildBreakdownData(
  data,
  recommendations,
  excludedTagTypes: string[],
  projectNetworks: Network[],
  isV2 = false,
  allowNoNone = false
) {
  if (!data || !data.annotation?.length) return [];

  data.annotation = orderAnnotations(data.annotation, data.duration, allowNoNone);

  // As we want to display core tags recommendations on the timeline, even if they don't have annotations,
  // we create these 'mock' annotations for core tags that have recommendations but don't have annotations.
  const coreRecommendationsWithoutCoreAnnotations = recommendations
    ? createCoreAnnotations(recommendations, data.coreAnnotation)
    : [];

  // Here we create the timeline data, except for the ADD recommendations (those need to appear in the end of the timeline)
  const timelineDataWithoutAddRecommendations = coreRecommendationsWithoutCoreAnnotations
    .concat(data.annotation)
    .filter((annotation) => !excludedTagTypes.includes(annotation.type))
    .reduce((acc, annotation) => {
      const { type, value, startSeconds, endSeconds } = annotation;
      const existingIndex = acc.findIndex((a) => a.type === camelCaseToCapitalCase(type) && a.value === value);
      // if there's already a row on the timeline of the above tag type and value, we just add a new segment to that existing row
      if (existingIndex !== -1) {
        acc[existingIndex].segments.push({ startSeconds, endSeconds });
        // if there isn't, we first gather all recommendations of that tag value and type and then create a new timeline row
      } else {
        const recommendationsByType = recommendations
          ? recommendations.filter((r) => {
              switch (r.name.type) {
                case RecommendationType.CREATIVE_REMOVE_TAG:
                case RecommendationType.CREATIVE_REMOVE_TAG_INTRO:
                  return (
                    camelCaseToCapitalCase(r.name.tag.type) === camelCaseToCapitalCase(type) &&
                    r.name.tag.value === value
                  );
                case RecommendationType.CREATIVE_REPLACE_TAG:
                case RecommendationType.CREATIVE_REPLACE_TAG_INTRO:
                  return (
                    camelCaseToCapitalCase(r.name.previous.type) === camelCaseToCapitalCase(type) &&
                    r.name.previous.value === value
                  );
                default:
                  return false;
              }
            })
          : [];
        const { id } = data.tags.find((t) => t.type === type && t.value === value) ?? '';
        if (isV2) {
          acc.push({
            id,
            type: camelCaseToCapitalCase(type),
            value,
            segments: startSeconds || endSeconds ? [{ startSeconds, endSeconds }] : [],
            recommendations: recommendationsByType,
            projectNetworks,
          });
        } else {
          acc.push(
            createTagBreakdownObject({
              id,
              type,
              value,
              segments: startSeconds || endSeconds ? [{ startSeconds, endSeconds }] : [],
              recommendations: recommendationsByType,
              projectNetworks,
            })
          );
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return acc;
    }, []);

  // Adding ADD recommendations to the end of the timeline
  const addRecommendationsData = recommendations
    ? recommendations
        .filter(
          (rec) =>
            rec.name.type === RecommendationType.CREATIVE_ADD_TAG ||
            rec.name.type === RecommendationType.CREATIVE_ADD_TAG_INTRO
        )
        // grouping recommendations from the same type and value (e.g. one of them is intro and other isn't)
        .reduce((acc, recommendation) => {
          const { id, type, value } = recommendation.name.tag;
          const existingIndex = acc.findIndex((a) => id === a.id);
          if (existingIndex !== -1) {
            acc[existingIndex].recommendationsOfSameTypeAndValue.push(recommendation);
          } else {
            acc.push({ id, type, value, recommendationsOfSameTypeAndValue: [recommendation] });
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return acc;
        }, [])
        .map((groupedRecommendations) => {
          const { id, type, value, recommendationsOfSameTypeAndValue } = groupedRecommendations;
          if (isV2) {
            return {
              id,
              type: camelCaseToCapitalCase(type),
              value,
              segments: [],
              recommendations: recommendationsOfSameTypeAndValue,
              projectNetworks,
            };
          }
          return createTagBreakdownObject({
            id,
            type,
            value,
            segments: [],
            recommendations: recommendationsOfSameTypeAndValue,
            projectNetworks,
          });
        })
    : [];

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return timelineDataWithoutAddRecommendations.concat(addRecommendationsData);
}
interface TimelineProps {
  assetId: string;
  recommendations?: SDK.CreativeRecommendation[];
  loadingRecommendations: boolean;
  tagData: any;
  tagDataIsLoading: boolean;
  playedFraction: number;
  playedSeconds: number;
  duration: number;
  player: React.MutableRefObject<ReactPlayer>;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  showRecommendationsToggle?: boolean;
  v2?: boolean;
  videoOnLoop?: boolean;
  setLoopVideo?: React.Dispatch<React.SetStateAction<boolean>>;
  recommendationsOnly?: boolean;
}

const Timeline: React.FC<TimelineProps> = ({
  assetId,
  recommendations,
  loadingRecommendations,
  tagData,
  tagDataIsLoading,
  playedFraction,
  playedSeconds,
  duration,
  player,
  isPlaying,
  setIsPlaying,
  showRecommendationsToggle = true,
  v2 = false,
  videoOnLoop,
  setLoopVideo,
  recommendationsOnly = false,
}) => {
  const dispatch = useDispatch();
  const projectId = useSelector((state: RootState) => state.project.id);
  const tagsTypes = useSelector((state: RootState) => state.project.config.tagsTypes);
  const [containerRef, { width }] = useMeasure<HTMLDivElement>();
  const [dialogProps, setDialogProps] = useState({ open: false, tagId: null, tagType: null, tagValue: null });
  const networks = useSelector((state: RootState) => state.filters.networks);
  const [recommendationTooltipTags, setRecommendationTooltipTags] = useState<{ type: string; value: string | null }[]>(
    []
  );

  const seekToPoint = (pointInSeconds: number) => {
    player.current?.seekTo(pointInSeconds, 'seconds');
  };

  const timestampsInSeconds = useMemo(() => {
    if (duration > 0 && width > 0) {
      // Minimum two timestamps (00:00 and video duration)
      const numTimestampsToShow = Math.max(2, Math.floor(width / 90) - 2);
      // Calculate interval between timestamps
      const interval = duration / (numTimestampsToShow - 1);
      // Generate timestamps with zero in beginning, duraton in end, and intervals in between
      return [0, ...Array.from(Array(numTimestampsToShow - 2), (_, i) => interval * (i + 1)), duration];
    }

    return [];
  }, [duration, width]);

  const tagsVideosAssetsQueries = useQueries(
    recommendationTooltipTags.map((tag) =>
      getTagsVideoAssetsQueryOptions(
        {
          projectIds: [projectId],
          tagsFilters: {
            tagsToConsider: [tag],
          },
          maxRecords: 1,
          filterInactive: true,
        },
        {
          enabled: recommendationTooltipTags.length > 0,
          select: (data) => ({
            ...data,
            assets: data.assets.map((asset) => ({
              ...asset,
              timeframes: asset.timeframes?.filter(
                (timeframe: SDK.ParsedAnnotationTimeframe) =>
                  timeframe.type === tag.type && timeframe.value === tag.value
              ),
            })),
          }),
        }
      )
    )
  );
  const isLoadingTagsVideosAssets = tagsVideosAssetsQueries.some((query) => query.isLoading);
  const tagsVideosAssetsData = isLoadingTagsVideosAssets
    ? []
    : tagsVideosAssetsQueries.reduce((acc, query) => (query.data?.assets ? [...acc, ...query.data.assets] : acc), []);

  if (tagDataIsLoading) {
    return (
      <RouteAnimator>
        <Styled.LoadingContainer>
          <LoadingScreen messages={[]} />
        </Styled.LoadingContainer>
      </RouteAnimator>
    );
  }

  if (!tagDataIsLoading && !tagData?.postQaAnnotation?.length) {
    return (
      <RouteAnimator>
        <Typography style={{ margin: '2rem 0' }}>
          Timeline not available for this creative. Try a different one.
        </Typography>
      </RouteAnimator>
    );
  }

  const breakdown = buildBreakdownData(tagData, recommendations, tagsTypes?.excluded ?? [], networks, v2);

  return (
    <RouteAnimator data-test="videos-timeline-container">
      <>
        {!v2 ? (
          <>
            <TypographyClean type="text-lg" color={Colors.Gray[900]} fontWeight="medium">
              Timeline <AppcuesTutorial appcuesId={APPCUES_IDS.TIMELINE} />
            </TypographyClean>
            <Styled.Divider />
          </>
        ) : undefined}
        {v2 ? (
          <div ref={containerRef} data-test="timeline-container">
            <TimelineV2
              breakdown={breakdown}
              projectId={projectId}
              clusterId={assetId}
              setIsPlaying={(updatedIsPlaying) => setIsPlaying(updatedIsPlaying)}
              isPlaying={isPlaying}
              playedSeconds={playedSeconds}
              playedFraction={playedFraction}
              timestampsInSeconds={timestampsInSeconds}
              videoDurationInSeconds={tagData.duration}
              seekToPoint={seekToPoint}
              onClickShowOnlyRecommendations={() => {
                logEvent({
                  component: 'Timeline',
                  action: 'Toggled show only recommendations',
                  category: 'user_actions',
                });
              }}
              loading={loadingRecommendations}
              onShareClick={() => {
                logEvent({
                  component: 'Timeline',
                  action: 'Click share',
                  category: 'user_actions',
                });
                navigator.clipboard
                  .writeText(window.location.href)
                  .then(() => {
                    dispatch(
                      AppActions.setAlertOpen({
                        badgeTitle: 'Success',
                        badgeLabel: 'Copied to the clipboard',
                        message: `URL was copied`,
                        color: 'Success',
                      })
                    );
                  })
                  .catch((err) => console.error('Error on copy to clipboard: ', err));
              }}
              onTimestampLineDrag={() => {
                logEvent({
                  component: 'Timeline',
                  action: 'Drag timeline pointer',
                  category: 'user_actions',
                });
              }}
              loopVideo={videoOnLoop}
              setLoopVideo={(loopVideo) => {
                if (loopVideo) {
                  logEvent({
                    component: 'Timeline',
                    action: 'Click loop video',
                    category: 'user_actions',
                  });
                }
                setLoopVideo?.(loopVideo);
              }}
              onRecommendationTooltipOpen={(isOpen, tags) => setRecommendationTooltipTags(isOpen ? tags : [])}
              recommendationTooltipVideos={tagsVideosAssetsData}
              recommendationsOnly={recommendationsOnly}
              withoutTabs={false}
              onClickSkipBack={(point) => {
                logEvent({
                  component: 'Timeline',
                  action: 'Click skip back',
                  category: 'user_actions',
                });
                seekToPoint(point);
              }}
            />
          </div>
        ) : (
          <Styled.TimelineContainer ref={containerRef} data-test="timeline-container">
            <TimelineUI
              breakdown={breakdown}
              projectId={projectId}
              clusterId={assetId}
              setIsPlaying={(updatedIsPlaying) => setIsPlaying(updatedIsPlaying)}
              isPlaying={isPlaying}
              playedSeconds={playedSeconds}
              playedFraction={playedFraction}
              timestampsInSeconds={timestampsInSeconds}
              videoDurationInSeconds={tagData.duration}
              showWithBreakdowns={false}
              showRecommendationsToggle={showRecommendationsToggle}
              seekToPoint={seekToPoint}
              onClickGroup={(group) => {
                logEvent({
                  component: 'Timeline',
                  action: `Click ${group ? 'Group' : 'Ungroup'}`,
                  category: 'user_actions',
                });
              }}
              onClickShowOnlyRecommendations={() => {
                logEvent({
                  component: 'Timeline',
                  action: 'Toggled show only recommendations',
                  category: 'user_actions',
                });
              }}
              loading={loadingRecommendations}
            />
          </Styled.TimelineContainer>
        )}
      </>
      {!!dialogProps?.tagId && (
        <TagPreviewVideosWrapper
          isOpen={dialogProps.open}
          tag={{ type: dialogProps.tagType!, value: dialogProps.tagValue }}
          component="Timeline"
          onClose={() => setDialogProps({ open: false, tagId: null, tagType: null, tagValue: null })}
        />
      )}
    </RouteAnimator>
  );
};

export default Timeline;
