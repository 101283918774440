import { CountryIconProps } from './types';

const SK = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3701)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 23.9996C18.6274 23.9996 24 18.6269 24 11.9996C24 10.5317 23.736 9.12562 23.2536 7.82568H0.746391C0.264047 9.12562 0 10.5317 0 11.9996C0 18.6269 5.37262 23.9996 12 23.9996Z"
        fill="#0052B4"
      />
      <path
        d="M11.9997 23.9997C17.1593 23.9997 21.5578 20.7432 23.2533 16.1736H0.746094C2.44161 20.7432 6.84012 23.9997 11.9997 23.9997Z"
        fill="#D80027"
      />
      <path
        d="M3.10254 6.78186V12.7681C3.10254 16.1733 7.55102 17.2167 7.55102 17.2167C7.55102 17.2167 11.9995 16.1733 11.9995 12.7681V6.78186H3.10254Z"
        fill="#F0F0F0"
      />
      <path
        d="M4.14648 6.78186V12.7681C4.14648 13.1678 4.23527 13.5454 4.41114 13.8993C6.15648 13.8993 8.76517 13.8993 10.6918 13.8993C10.8677 13.5454 10.9564 13.1678 10.9564 12.7681V6.78186H4.14648Z"
        fill="#D80027"
      />
      <path
        d="M9.63874 10.9561H8.07358V9.91261H9.11707V8.86912H8.07358V7.82568H7.03009V8.86912H5.98666V9.91261H7.03009V10.9561H5.46484V11.9996H7.03009V13.0431H8.07358V11.9996H9.63874V10.9561Z"
        fill="#F0F0F0"
      />
      <path
        d="M5.8344 15.3573C6.50682 15.7799 7.19766 16.0252 7.55134 16.1343C7.90501 16.0253 8.59585 15.7799 9.26827 15.3573C9.94637 14.9311 10.4217 14.4437 10.6916 13.9007C10.3939 13.69 10.0307 13.5658 9.63831 13.5658C9.49543 13.5658 9.35663 13.5827 9.22318 13.6138C8.94038 12.9713 8.29843 12.5223 7.55138 12.5223C6.80434 12.5223 6.16234 12.9713 5.87959 13.6138C5.74613 13.5827 5.60729 13.5658 5.46446 13.5658C5.07207 13.5658 4.70884 13.69 4.41113 13.9007C4.6809 14.4437 5.15626 14.9311 5.8344 15.3573Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3701">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SK;
