import styled from 'styled-components';
import { Colors, FeaturedIcons, Typography } from '..';
import { ModalButton, ModalButtonsContainer } from '../';
import * as Styled from './styles';

export interface LogoutProps {
  isOpen: boolean;
  onClose: (cause: 'cancel' | 'confirm') => void;
}

const LogoutContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoutDialogButton = styled.div`
  padding: 0 74px;
`;

const Logout = ({ isOpen, onClose }: LogoutProps) => (
  <Styled.Modal
    className="replai-logout-ant-modal"
    isOpen={isOpen}
    closable={false}
    modalHeader={null}
    onClose={() => onClose('cancel')}
  >
    <LogoutContent>
      <FeaturedIcons
        color="Gray"
        icon="LogOut"
        size="lg"
        variant="outline"
        colorScheme="light"
      />
      <Typography style={{ margin: '20px auto 0 auto' }} type="text-lg">
        Confirm logout
      </Typography>
      <Typography
        style={{ margin: '8px auto 32px auto' }}
        type="text-sm"
        color={Colors.Gray[400]}
      >
        Are you sure that you want to log out?
      </Typography>

      <ModalButtonsContainer>
        <ModalButton variation="secondary" onClick={() => onClose('cancel')}>
          <LogoutDialogButton>No</LogoutDialogButton>
        </ModalButton>
        <ModalButton variation="primary" onClick={() => onClose('confirm')}>
          <LogoutDialogButton>Yes</LogoutDialogButton>
        </ModalButton>
      </ModalButtonsContainer>
    </LogoutContent>
  </Styled.Modal>
);

export default Logout;
