/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import * as Styled from './styles';

interface ShadowProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
}

const Shadow = ({
  children,
  className,
  size = 'md',
  ...props
}: ShadowProps) => (
  <Styled.Root className={`${size} ${className || ''}`} {...props}>
    {children}
  </Styled.Root>
);

export default Shadow;
export type { ShadowProps };
