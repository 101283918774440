/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import millify from 'millify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AutoAwesome, ExpandLess, ExpandMore } from '@mui/icons-material';
import { createSelector } from '@reduxjs/toolkit';
import * as SDK from '@replai-platform/sdk';
import {
  Badge,
  Checkbox,
  Colors,
  ColorTypes,
  Skeleton,
  Typography,
  Tooltip,
  FrequencyMetricBadge,
  camelCaseToCapitalCase,
  Icons,
} from '@replai-platform/ui-components';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { FilterActions } from '../../../../store/filters';
import type { RootState } from '../../../../store/rootReducer';
import checkInverseKpi from '../../../../utils/checkInverseKpi';
import * as Styled from './styles';
import { TypographyClean } from '../../../../utils/styles';

const MAX_VALUES_COLLAPSED = 5;
const TAG_PLACEHOLDERS = 5;

interface OverviewTagCategoryProps {
  tagType: { type: string; values?: string[]; kind: SDK.TagKind };
  tagTypePerformance: (SDK.TagMetrics & { goodness: number; scorePercentage: number })[];
  bestPerformanceTags: SDK.TagMetrics[];
  isLoading: boolean;
  isUpdating: boolean;
  keyMetric: SDK.Metrics;
}

const LongArrowRight = Icons.getBaseIcon('LongArrowRight');

const OverviewTagCategory: React.FC<OverviewTagCategoryProps> = ({
  tagType,
  tagTypePerformance,
  bestPerformanceTags,
  isLoading,
  isUpdating,
  keyMetric,
}) => {
  const dispatch = useDispatch();
  const store = useStore();
  const [expanded, setExpanded] = useState(false);
  const filtering = useSelector((state: RootState) => state.filters);
  const defaultProjectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const tagFilters = useSelector((state: RootState) => state.filters.tagFilters);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const globalPerformance = useSelector((state: RootState) => state.combinations.globalPerformance);
  const tagFilteredCache = createSelector([(state: RootState) => state.filters.tagFilters], (filteredTagIds) =>
    filteredTagIds.reduce((cur, val) => {
      cur[val.id] = true;
      return cur;
    }, {})
  );
  const tagCache = tagFilteredCache(store.getState());

  const filterTag = useCallback(
    (tag: SDK.Tag) => {
      if (tagCache[tag.id]) {
        logEvent({
          component: 'Combinations',
          action: 'Unselect Tag',
          category: 'user_actions',
          parameters: { tag },
        });
        const newTagFilters = tagFilters.filter((x) => x.id !== tag.id);
        dispatch(FilterActions.changeTagFilters(newTagFilters));
        dispatch(FilterActions.changeSelectedInsightIds(null));
      } else {
        logEvent({
          component: 'Combinations',
          action: 'Select Tag',
          category: 'user_actions',
          parameters: { tag },
        });
        const newTagFilters = [...tagFilters, tag];
        dispatch(FilterActions.changeTagFilters(newTagFilters));
        dispatch(FilterActions.changeSelectedInsightIds(null));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, JSON.stringify(tagCache), JSON.stringify(tagFilters)]
  );

  const getUnselectableTagTooltipContent = (hasSelection: boolean, selected: boolean, isUsed: boolean) => {
    if (selected) return '';
    if (hasSelection && !isUsed) {
      return (
        <Styled.TooltipTitle>
          <b>This tag was never combined</b> with the tags you have selected in the last 60 days
        </Styled.TooltipTitle>
      );
    }
    if (!hasSelection && !isUsed) {
      return (
        <Styled.TooltipTitle>
          <b>This tag was never used</b> in any of your videos in the last 60 days
        </Styled.TooltipTitle>
      );
    }
    return '';
  };

  const getDeltaExplanationTooltipContent = ({
    kpi,
    leadingIcon,
    scorePercentage,
    scorePercentageFixed,
    color,
    metrics,
  }: {
    kpi: SDK.Metrics;
    leadingIcon: 'Minus' | 'Plus';
    scorePercentage: number;
    scorePercentageFixed: number;
    color: ColorTypes;
    metrics: SDK.Metric;
  }) => (
    <Styled.DeltaExplainTooltipContainer>
      <Styled.TooltipTitle>
        This delta is calculated by comparing the
        <b> {SDK.kpiUtils.getDisplayName(kpi) ?? 'KPI value'} </b>
        of all creatives that match the filters with the ones that, besides matching the filters, also have this tag
        <Styled.DeltaExplainTooltipBadgesContainer>
          <div>
            <Styled.DeltaExplainTooltipColumnItemContainer>
              <TypographyClean fontWeight="bold" type="text-sm">
                Global
              </TypographyClean>
            </Styled.DeltaExplainTooltipColumnItemContainer>
            <Styled.DeltaExplainTooltipColumnItemContainer>
              <Badge color="Gray">{SDK.kpiUtils.format(kpi, globalPerformance?.[kpi], { longDisplay: true })}</Badge>
            </Styled.DeltaExplainTooltipColumnItemContainer>
          </div>
          <div>
            <Styled.DeltaExplainTooltipColumnItemContainer>
              <Badge color={color} leadingIcon={leadingIcon}>
                {scorePercentageFixed}%
              </Badge>
            </Styled.DeltaExplainTooltipColumnItemContainer>
            <Styled.DeltaExplainTooltipColumnItemContainer>
              <LongArrowRight color={scorePercentage > 0 ? Colors.Success[500] : Colors.Error[500]} />
            </Styled.DeltaExplainTooltipColumnItemContainer>
          </div>
          <div>
            <Styled.DeltaExplainTooltipColumnItemContainer>
              <TypographyClean fontWeight="bold" type="text-sm">
                Tag
              </TypographyClean>
            </Styled.DeltaExplainTooltipColumnItemContainer>
            <Styled.DeltaExplainTooltipColumnItemContainer>
              <Badge color="Gray">{SDK.kpiUtils.format(kpi, metrics?.[kpi], { longDisplay: true })}</Badge>
            </Styled.DeltaExplainTooltipColumnItemContainer>
          </div>
        </Styled.DeltaExplainTooltipBadgesContainer>
      </Styled.TooltipTitle>
    </Styled.DeltaExplainTooltipContainer>
  );

  return (
    <>
      <Styled.NameContainer data-test="name-container">
        <Styled.Name data-test="name" variant="subtitle1">
          {!tagType ? <Skeleton height={20} width={100} /> : camelCaseToCapitalCase(tagType.type)}
        </Styled.Name>
        {!tagType ? <Skeleton height={20} width={40} /> : undefined}
        {tagType?.kind ? <Styled.TagTypeGroupIndicator>{tagType.kind}</Styled.TagTypeGroupIndicator> : undefined}
      </Styled.NameContainer>
      <div style={{ position: 'relative', paddingBottom: '0rem' }}>
        {/* eslint-disable react/no-array-index-key */}
        {isLoading || tagTypePerformance.length === 0
          ? Array.from(Array(TAG_PLACEHOLDERS)).map((_val, index) => <Skeleton key={index} height={40} />)
          : undefined}
        {/* eslint-enable react/no-array-index-key */}
        {!isLoading && tagTypePerformance ? (
          <Styled.TagValuesList data-test="tag-values-list">
            {tagTypePerformance.slice(0, expanded ? undefined : MAX_VALUES_COLLAPSED).map((tagPerformance) => {
              const {
                tag,
                shareOfAssets,
                metrics,
                individualObjectCount,
                totalObjectCount,
                goodness,
                scorePercentage,
              } = tagPerformance;

              const frequencyPercentage = Number(shareOfAssets * 100);

              const metricValue = metrics?.[keyMetric];
              const totalMetricValue = metrics?.[`total${SDK.capitalizeFirstLetter(keyMetric)}`];
              const metricPercentage =
                100 * ((totalMetricValue ?? 0) === 0 ? 0 : (metricValue ?? 0) / (totalMetricValue ?? 0));

              const color: ColorTypes = goodness === 0 ? 'Gray' : goodness > 0 ? 'Success' : 'Error';

              const kpi = (filtering.kpi || defaultProjectKpis?.[0]) as SDK.Metrics;

              // Show arrow up if the score is good and the kpi is not inverted
              // or the complete opposite, the same for the other arrow (xnor gate)
              const leadingIcon: 'Plus' | 'Minus' = goodness > 0 === !checkInverseKpi(kpi) ? 'Plus' : 'Minus';

              // should highlight the tag if it belongs to the best performance
              // tags and is not currently selected
              const shouldHighlight = !!bestPerformanceTags.find(
                (t) => t.tag.value === tagPerformance.tag.value && t.tag.type === tagType.type
              );
              const scorePercentageFixed = parseFloat(Math.abs(scorePercentage ?? 0).toFixed(1));

              return (
                <Styled.TagContainer
                  key={JSON.stringify(tag)}
                  animate={isLoading ? 'out' : 'in'}
                  selected={!!tagCache[tag.id]}
                  highlighted={shouldHighlight}
                  opaque={shareOfAssets === 0}
                  onClick={(e) => {
                    if (metrics) {
                      filterTag(tag);
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                >
                  <Styled.TagToolsContainer>
                    <div data-test="filter-checkbox">
                      {shareOfAssets > 0 ? (
                        <Checkbox
                          onClick={(_, e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            filterTag(tag);
                          }}
                          checked={!!tagCache[tag.id]}
                          variant="rounded"
                        />
                      ) : undefined}
                    </div>
                    <Tooltip
                      content={getUnselectableTagTooltipContent(
                        Object.keys(tagCache).length > 0,
                        tagCache[tag.id],
                        shareOfAssets > 0
                      )}
                      placement="bottom"
                    >
                      <Styled.TagName variant="body1">{`${
                        tag.value ? tag.value : SDK.messages.NOT_AVAILABLE
                      }`}</Styled.TagName>
                    </Tooltip>
                    {shouldHighlight ? (
                      <Tooltip
                        placement="bottom"
                        content={
                          <Styled.TooltipTitle>
                            This tag presents a great cost/benefit for the current selection
                          </Styled.TooltipTitle>
                        }
                      >
                        <Styled.FlexItem>
                          <AutoAwesome fontSize="small" color="success" />
                        </Styled.FlexItem>
                      </Tooltip>
                    ) : undefined}
                    {isUpdating ? (
                      <Styled.FlexItem>
                        <CircularProgress size={16} color="inherit" />
                      </Styled.FlexItem>
                    ) : undefined}
                    {!isUpdating && filtering.kpi !== SDK.Metrics.SPEND && scorePercentageFixed !== 0 ? (
                      <Tooltip
                        placement="bottom"
                        showArrow
                        enableMaxContent
                        content={getDeltaExplanationTooltipContent({
                          kpi,
                          leadingIcon,
                          scorePercentage,
                          scorePercentageFixed,
                          color,
                          metrics,
                        })}
                      >
                        <Styled.FlexItem>
                          <Badge leadingIcon={leadingIcon} color={color}>
                            {scorePercentageFixed}%
                          </Badge>
                        </Styled.FlexItem>
                      </Tooltip>
                    ) : undefined}
                  </Styled.TagToolsContainer>
                  {metrics ? (
                    <Tooltip
                      placement="bottom"
                      enableMaxContent
                      content={
                        <Styled.FreqAndMetricTooltipContainer>
                          <div style={{ paddingRight: '16px' }}>
                            <Typography type="text-sm">Frequency</Typography>
                            <FrequencyMetricBadge
                              type="frequency"
                              current={millify(individualObjectCount)}
                              total={millify(totalObjectCount)}
                              percentage={frequencyPercentage}
                            />
                          </div>
                          <div>
                            <Typography type="text-sm">{SDK.capitalizeFirstLetter(keyMetric)}</Typography>
                            <FrequencyMetricBadge
                              type="metric"
                              current={millify(metrics?.[keyMetric] ?? 0)}
                              total={millify(globalPerformance?.[keyMetric] ?? 0)}
                              percentage={metricPercentage}
                              metric={keyMetric}
                            />
                          </div>
                        </Styled.FreqAndMetricTooltipContainer>
                      }
                    >
                      <div style={{ display: 'block' }}>
                        <Styled.CustomProgress
                          coloroverride={Colors.Success[400]}
                          backgroundcolor={Colors.Success[50]}
                          variant="determinate"
                          value={metricPercentage}
                        />
                        <Styled.CustomProgress
                          coloroverride={Colors.BlueLight[400]}
                          backgroundcolor={Colors.BlueLight[50]}
                          variant="determinate"
                          value={frequencyPercentage}
                        />
                      </div>
                    </Tooltip>
                  ) : undefined}
                </Styled.TagContainer>
              );
            })}
          </Styled.TagValuesList>
        ) : undefined}
        {!isLoading && tagTypePerformance && tagTypePerformance.length > MAX_VALUES_COLLAPSED ? (
          <Styled.ExpandCollapseButton
            endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
            onClick={(e) => {
              setExpanded(!expanded);
              e.preventDefault();
              e.stopPropagation();
            }}
            size="small"
          >
            View {expanded ? 'less' : `more (${tagTypePerformance.length - MAX_VALUES_COLLAPSED})`}
          </Styled.ExpandCollapseButton>
        ) : undefined}
      </div>
    </>
  );
};

export default OverviewTagCategory;
