import { CountryIconProps } from './types';

const AS = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3555)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M17.8217 1.50525C16.0974 0.54675 14.1127 0 12 0C5.37309 0 0.00084375 5.37178 0 11.9986L8.34783 7.82611L17.8217 1.50525Z"
        fill="#0052B4"
      />
      <path
        d="M0 12.0019C0.00084375 18.6287 5.37309 24.0005 12 24.0005C14.1127 24.0005 16.0974 23.4538 17.8217 22.4952L8.34783 16.1744L0 12.0019Z"
        fill="#0052B4"
      />
      <path
        d="M18.8217 2.12703C18.4994 1.90395 18.1654 1.69648 17.8213 1.50519L0 11.9983C0 11.9988 0 11.9993 0 11.9998C0 12.0002 0 12.0007 0 12.0012L17.8213 22.4943C18.1655 22.3029 18.4994 22.0955 18.8218 21.8724L2.05416 11.9998L18.8217 2.12703Z"
        fill="#D80027"
      />
      <path
        d="M20.8692 11.5526H19.4398C19.8115 11.1064 19.7885 10.4425 19.3698 10.0238C19.8133 9.58035 19.8133 8.86124 19.3698 8.41766L19.3431 8.44447C19.7866 8.00099 19.8133 7.25507 19.3698 6.81158L12.9453 13.2361C13.3888 13.6797 14.0953 13.6744 14.5388 13.2308L14.6625 13.1178L17.7388 12.8382V14.1613H18.7823V12.7433L20.3475 12.601L20.8692 11.5526Z"
        fill="#A2001D"
      />
      <path
        d="M13.0439 14.6089L12 14.0872L13.0439 13.5654H19.8264V14.6089H13.0439Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3555">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default AS;
