import { CountryIconProps } from './types';

const LU = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3661)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9997 -0.000366211C6.84012 -0.000366211 2.44161 3.25613 0.746094 7.82574H23.2534C21.5578 3.25613 17.1593 -0.000366211 11.9997 -0.000366211Z"
        fill="#D80027"
      />
      <path
        d="M12.0007 24.0008C17.1603 24.0008 21.5588 20.7443 23.2543 16.1747H0.74707C2.44259 20.7443 6.8411 24.0008 12.0007 24.0008Z"
        fill="#338AF3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3661">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default LU;
