import styled from 'styled-components';
import Typography from '../../Typography/Typography';

export const MetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export const BadgeInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
`;

export const TooltipText = styled(Typography)`
  margin: 0;
  white-space: nowrap;
`;

export const TooltipPercentage = styled(TooltipText)`
  font-size: 0.625rem;
`;
