import { WYSIWYGProps } from './types';

const MDBold = ({ color }: WYSIWYGProps) => (
  <svg
    width="11"
    height="13"
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.15 5.94125C8.99875 5.355 9.59375 4.3925 9.59375 3.5C9.59375 1.5225 8.0625 0 6.09375 0H0.625V12.25H6.785C8.61375 12.25 10.0312 10.7625 10.0312 8.93375C10.0312 7.60375 9.27875 6.46625 8.15 5.94125ZM3.25 2.1875H5.875C6.60125 2.1875 7.1875 2.77375 7.1875 3.5C7.1875 4.22625 6.60125 4.8125 5.875 4.8125H3.25V2.1875ZM6.3125 10.0625H3.25V7.4375H6.3125C7.03875 7.4375 7.625 8.02375 7.625 8.75C7.625 9.47625 7.03875 10.0625 6.3125 10.0625Z"
      fill={color || '#98A2B3'}
    />
  </svg>
);
export default MDBold;
