import styled from 'styled-components';

export const TagsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(11.8125rem, 1fr));
  gap: 1rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
`;
