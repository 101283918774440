import { IntegrationIconProps } from './types';

const Python = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0164 2C10.8193 2 9.03825 3.72453 9.03825 5.85185V8.51852H15.9235V9.25926H5.97814C3.78107 9.25926 2 10.9838 2 13.1111L2 18.8889C2 21.0162 3.78107 22.7407 5.97814 22.7407H8.27322V19.4815C8.27322 17.3542 10.0543 15.6296 12.2514 15.6296H19.5956C21.4547 15.6296 22.9617 14.1704 22.9617 12.3704V5.85185C22.9617 3.72453 21.1807 2 18.9836 2H13.0164ZM12.0984 6.74074C12.8589 6.74074 13.4754 6.14378 13.4754 5.40741C13.4754 4.67103 12.8589 4.07407 12.0984 4.07407C11.3378 4.07407 10.7213 4.67103 10.7213 5.40741C10.7213 6.14378 11.3378 6.74074 12.0984 6.74074Z"
      fill="url(#paint0_linear_1334_1681)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9844 30C21.1815 30 22.9626 28.2755 22.9626 26.1482V23.4815L16.0773 23.4815L16.0773 22.7408L26.0227 22.7408C28.2197 22.7408 30.0008 21.0162 30.0008 18.8889V13.1111C30.0008 10.9838 28.2197 9.25928 26.0227 9.25928L23.7276 9.25928V12.5185C23.7276 14.6459 21.9465 16.3704 19.7494 16.3704L12.4052 16.3704C10.5461 16.3704 9.03906 17.8296 9.03906 19.6296L9.03906 26.1482C9.03906 28.2755 10.8201 30 13.0172 30H18.9844ZM19.9024 25.2593C19.1419 25.2593 18.5254 25.8562 18.5254 26.5926C18.5254 27.329 19.1419 27.9259 19.9024 27.9259C20.663 27.9259 21.2795 27.329 21.2795 26.5926C21.2795 25.8562 20.663 25.2593 19.9024 25.2593Z"
      fill="url(#paint1_linear_1334_1681)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_1681"
        x1="12.4809"
        y1="2"
        x2="12.4809"
        y2="22.7407"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#327EBD" />
        <stop offset="1" stopColor="#1565A7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1681"
        x1="19.5199"
        y1="9.25928"
        x2="19.5199"
        y2="30"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA4B" />
        <stop offset="1" stopColor="#F9C600" />
      </linearGradient>
    </defs>
  </svg>
);
export default Python;
