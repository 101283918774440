import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { api } from './api';
import App from './App';
import store from './store';

Sentry.init({
  dsn: 'https://ab45af1f500e4dd08ae34a3e7e216e04@o1333565.ingest.sentry.io/6599160',
  integrations: [new BrowserTracing(), new CaptureConsole({ levels: ['error', 'warn'] })],
  enabled: process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  ignoreErrors: [
    'ChunkLoadError',

    // Ignore ResizeObserver errors to avoid false-positive traces.
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',

    // Ignore Optimizely errors
    '[OPTIMIZELY]',

    // Ignore session expired errors
    'No current user',
    'User is not authenticated',

    // Ignore API errors
    'Failed to fetch',
  ],
  denyUrls: [/extensions\//i, /^chrome:\/\//i, /reactPlayerFilePlayer\.[a-z0-9]+\.chunk/],
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

window.REPLAI_API = api;

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
