import { IntegrationIconProps } from './types';

const GCalendar = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || '32'}
    height={dimension ? (dimension * 33) / 32 : '33'}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1334_1346)">
      <path
        d="M2 11.9556C2 8.47078 2 6.7284 2.67818 5.39739C3.27473 4.22661 4.22661 3.27473 5.39739 2.67818C6.7284 2 8.47078 2 11.9556 2H20.0444C23.5292 2 25.2716 2 26.6026 2.67818C27.7734 3.27473 28.7253 4.22661 29.3218 5.39739C30 6.7284 30 8.47078 30 11.9556V20.0444C30 23.5292 30 25.2716 29.3218 26.6026C28.7253 27.7734 27.7734 28.7253 26.6026 29.3218C25.2716 30 23.5292 30 20.0444 30H11.9556C8.47078 30 6.7284 30 5.39739 29.3218C4.22661 28.7253 3.27473 27.7734 2.67818 26.6026C2 25.2716 2 23.5292 2 20.0444V11.9556Z"
        fill="white"
      />
      <path
        d="M24.4935 6H21.2422V10.7112H25.9997V7.32845C26.001 7.32845 25.7646 6.12848 24.4935 6Z"
        fill="#1967D2"
      />
      <path
        d="M21.2422 25.9781V25.9897V25.9999L25.9997 21.2887H25.957L21.2422 25.9781Z"
        fill="#1967D2"
      />
      <path
        d="M25.9993 21.2886V21.2463L25.957 21.2886H25.9993Z"
        fill="#FBBC05"
      />
      <path
        d="M25.9997 10.7113H21.2422V21.2464H25.9997V10.7113Z"
        fill="#FBBC05"
      />
      <path
        d="M25.957 21.2887H21.2422V25.9781L25.957 21.2887Z"
        fill="#EA4335"
      />
      <path
        d="M21.2422 21.2886H25.957L25.9997 21.2463H21.2422V21.2886Z"
        fill="#EA4335"
      />
      <path
        d="M21.2305 25.9898H21.242V25.9783L21.2305 25.9898Z"
        fill="#34A853"
      />
      <path
        d="M10.6504 21.2463V25.9897H21.231L21.2427 21.2463H10.6504Z"
        fill="#34A853"
      />
      <path
        d="M21.242 21.2887V21.2463L21.2305 25.9897L21.242 25.9781V21.2887Z"
        fill="#34A853"
      />
      <path
        d="M6 21.2463V24.5443C6.04263 25.6146 7.20002 25.9897 7.20002 25.9897H10.6503V21.2463H6Z"
        fill="#188038"
      />
      <path
        d="M10.6503 10.7114H21.2425V6.00012H7.33437C7.33437 6.00012 6.08525 6.1286 6 7.45577V21.2465H10.6503V10.7114Z"
        fill="#4285F4"
      />
      <path
        d="M14.1165 19.1139C13.8742 19.1139 13.6405 19.0823 13.4155 19.0192C13.1962 18.956 12.9943 18.8614 12.8096 18.7351C12.625 18.6031 12.4606 18.4396 12.3163 18.2445C12.1778 18.0493 12.0711 17.8227 11.9961 17.5644L13.0606 17.1426C13.1356 17.4296 13.2626 17.6476 13.4414 17.7968C13.6203 17.9403 13.8453 18.012 14.1165 18.012C14.2376 18.012 14.353 17.9948 14.4627 17.9604C14.5723 17.9202 14.6675 17.8657 14.7483 17.7968C14.8291 17.728 14.8925 17.6476 14.9387 17.5558C14.9906 17.4583 15.0166 17.3492 15.0166 17.2287C15.0166 16.9762 14.9214 16.7782 14.731 16.6348C14.5463 16.4913 14.2896 16.4196 13.9607 16.4196H13.4501V15.3952H13.9174C14.0328 15.3952 14.1453 15.3809 14.255 15.3522C14.3646 15.3235 14.4598 15.2804 14.5406 15.2231C14.6271 15.1599 14.6935 15.0825 14.7396 14.9906C14.7915 14.8931 14.8175 14.7812 14.8175 14.6549C14.8175 14.4598 14.7483 14.302 14.6098 14.1815C14.4713 14.0552 14.2838 13.9921 14.0472 13.9921C13.7934 13.9921 13.5972 14.061 13.4587 14.1987C13.326 14.3307 13.2337 14.4799 13.1818 14.6463L12.1432 14.2245C12.1952 14.0811 12.273 13.9347 12.3769 13.7855C12.4808 13.6306 12.6106 13.4929 12.7664 13.3724C12.9279 13.2461 13.1154 13.1457 13.3289 13.0711C13.5424 12.9907 13.7876 12.9506 14.0646 12.9506C14.3473 12.9506 14.604 12.9907 14.8348 13.0711C15.0714 13.1514 15.2733 13.2633 15.4406 13.4068C15.608 13.5445 15.7378 13.7109 15.8301 13.906C15.9224 14.0954 15.9686 14.302 15.9686 14.5258C15.9686 14.698 15.9455 14.8529 15.8993 14.9906C15.859 15.1284 15.8041 15.2518 15.7349 15.3608C15.6657 15.4698 15.5849 15.5645 15.4926 15.6449C15.406 15.7195 15.3166 15.7797 15.2243 15.8256V15.8945C15.5012 16.0035 15.7291 16.1785 15.908 16.4196C16.0926 16.6606 16.1849 16.9647 16.1849 17.332C16.1849 17.5902 16.1359 17.8284 16.0378 18.0465C15.9397 18.2588 15.7984 18.4453 15.6137 18.606C15.4349 18.7667 15.2185 18.89 14.9646 18.9761C14.7108 19.0679 14.4281 19.1139 14.1165 19.1139Z"
        fill="#4285F4"
      />
      <path
        d="M18.2517 18.9761V14.4139L17.2045 14.8529L16.7891 13.8974L18.5287 13.0883H19.3855V18.9761H18.2517Z"
        fill="#4285F4"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1334_1346"
        x="-2"
        y="-1"
        width="36"
        height="36"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1334_1346"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1334_1346"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default GCalendar;
