/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { ClickAwayListener } from '@material-ui/core';
import { PromotedObjectType, Tag } from '@replai-platform/sdk';
import {
  CheckboxMenuOption,
  MultiSelectDropDownMenuOption,
  RangeInputMenuOption,
  SlideoutMenu,
} from '@replai-platform/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { useFeature } from '@optimizely/react-sdk';
import { supportedFilters as videosPageSupportedFilters } from '../../routes/Concepts/utils';
import { supportedFilters as tagsPageSupportedFilters } from '../../routes/Tags/utils';
import { supportedFilters as staticsPageSupportedFilters } from '../../routes/Statics/utils';
import { supportedFilters as conceptViewPageSupportedFilters } from '../../routes/Concepts/ConceptView/utils';
import { supportedFilters as videoViewPageSupportedFilters } from '../../routes/Concepts/CreativeView/utils';
import { supportedFilters as socialPostsPageSupportedFilters } from '../../routes/Social/SocialsPerformance/utils';
import { AppActions } from '../../store/app';
import { FilterActions } from '../../store/filters';
import { RootState } from '../../store/rootReducer';
import { FILTER_SUFFIX } from '../../utils/constants';
import { Filters, Page } from '../../utils/enums';
import {
  getFilterAction,
  getFilterSelectedCondition,
  getFilterShowCondition,
  getFilterStateKey,
} from '../FilterBar/common/utils';

export const FilterMenuWrapper = styled.div`
  position: fixed;
  right: 0;
  z-index: 100;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const FilterSidebar = () => {
  const dispatch = useDispatch();
  const filterMenuOpen = useSelector((state: RootState) => state.app.filterMenuOpen);
  const filters = useSelector((state: RootState) => state.filters);
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes ?? []);
  const availableFilters = useSelector((state: RootState) => state.app.availableFilters);
  const filtersValues = Object.values(Filters);
  const [isTechmerc1549Enabled] = useFeature('techmerc-1549', { autoUpdate: true }); // Installs filter in videos performance page

  // We sort the filters so we have the same order every time
  // Then we need to match the filters info (the default values) with the actual filtering from the user
  let filtersData = [...availableFilters]
    .sort((a, b) => filtersValues.indexOf(a.id) - filtersValues.indexOf(b.id))
    .map((filter) => {
      const filterStateKey = getFilterStateKey(filter.id, filters);
      const filterValue = filters[filterStateKey];
      if (filterValue) {
        return {
          ...filter,
          options: filter.options?.map((item) => ({
            ...item,
            selected: getFilterSelectedCondition({
              filter: filter.id,
              filterValue,
              item,
              toExclude: filterStateKey.includes(FILTER_SUFFIX.TO_EXCLUDE),
            }),
          })),
          collapsedByDefault: !getFilterShowCondition(filter.id, filters),
        };
      }
      return filter;
    });

  // TODO: infer loading state from react query (add loading var to each filter, etc..) For now, we
  // compare the number of filters that are supposed to show up in the sidebar vs the number of
  // filters that we have in redux. while they dont match, we are loading
  const location = useLocation();
  const page = location.pathname.split('/')[2] as Page;
  let isLoading = true;
  if (page === Page.Videos) {
    filtersData = filtersData.filter((s) => videosPageSupportedFilters.includes(s.id));
    isLoading = filtersData.length !== videosPageSupportedFilters.length;
  } else if (page === Page.Performance || page === Page.ReplaiCreativesPerformance) {
    filtersData = filtersData.filter((s) => videosPageSupportedFilters.includes(s.id));
    isLoading = filtersData.length !== videosPageSupportedFilters.length;
  } else if (page === Page.Tags) {
    filtersData = filtersData.filter((s) => tagsPageSupportedFilters(projectAccountTypes).includes(s.id));
    isLoading = filtersData.length !== tagsPageSupportedFilters(projectAccountTypes).length;
  } else if (page === Page.StaticsPerformance) {
    filtersData = filtersData.filter((s) => staticsPageSupportedFilters.includes(s.id));
    isLoading = filtersData.length !== staticsPageSupportedFilters.length;
  } else if (page === Page.Concepts) {
    filtersData = filtersData.filter((s) => conceptViewPageSupportedFilters.includes(s.id));
    isLoading = filtersData.length !== conceptViewPageSupportedFilters.length;
  } else if (page === Page.SocialPosts) {
    filtersData = filtersData.filter((s) => socialPostsPageSupportedFilters.includes(s.id));
    isLoading = filtersData.length !== socialPostsPageSupportedFilters.length;
  } else if (page === Page.VideoView) {
    filtersData = filtersData.filter((s) => videoViewPageSupportedFilters.includes(s.id));
    isLoading = filtersData.length !== videoViewPageSupportedFilters.length;
  }

  const handleCloseSlideoutMenu = () => dispatch(AppActions.setFilterMenuOpen(false));

  const handleSaveSlideoutMenu = (
    multiSelectOptions: MultiSelectDropDownMenuOption[],
    rangeInput: RangeInputMenuOption[]
  ) => {
    const actions: unknown[] = [];

    for (const section of multiSelectOptions ?? []) {
      if (section.options && section.options.length > 0) {
        const action = getFilterAction(section.id as Filters);

        const selectedIds = section.options
          .filter((o) => o.selected && !o.isAllOption)
          ?.map((o) => (section.id === Filters.CustomTag ? (JSON.parse(o.id ?? '') as Tag) : o.id));

        if (action) {
          // NOTE The slice below prevents passing too much data to google analytics
          actions.push(
            action({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value:
                section.id === Filters.LaunchedDate
                  ? selectedIds[0]
                  : ((selectedIds.length > 200 ? selectedIds.slice(0, 200) : selectedIds) as string[] &
                      Tag[] &
                      PromotedObjectType[]),
              logEvent: true,
              eventPrefix: page,
            })
          );
        }
      }
    }

    // Check if min spend input has data (if so, check if the value has changed)
    if (rangeInput[0]?.minValue && filters.minSpend !== rangeInput[0].minValue) {
      actions.push(FilterActions.changeMinSpend({ value: rangeInput[0].minValue, logEvent: true }));
    }

    if (rangeInput[1]?.minValue && filters.minInstalls !== rangeInput[1].minValue) {
      actions.push(FilterActions.changeMinInstalls({ value: rangeInput[1].minValue, logEvent: true }));
    }

    actions.push(AppActions.setFilterMenuOpen(false));

    actions.forEach((action) => {
      dispatch(action);
    });
  };

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleCloseSlideoutMenu}>
      <FilterMenuWrapper data-test="filters-slideout">
        {filterMenuOpen ? (
          <SlideoutMenu
            title="Filters"
            subtitle="Apply filters to table data"
            menuSections={(
              filtersData as (MultiSelectDropDownMenuOption | RangeInputMenuOption | CheckboxMenuOption)[]
            ).filter((filter) => {
              // Filter out installs filter if flag is not true
              if (filter.id === Filters.Installs && !isTechmerc1549Enabled) {
                return false;
              }

              return true;
            })}
            closeButton={{ icon: 'X', onClick: handleCloseSlideoutMenu }}
            loading={isLoading ? { messages: ['Loading filters...'] } : null}
            saveButton={{ onClick: handleSaveSlideoutMenu }}
          />
        ) : null}
      </FilterMenuWrapper>
    </ClickAwayListener>
  );
};

export default FilterSidebar;
