/* eslint-disable @typescript-eslint/no-unsafe-return */

import { useMountedState } from 'react-use';
import { messages } from '@replai-platform/sdk';
import * as UiComponents from '@replai-platform/ui-components';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { logEvent } from '../../analytics';
import usePatchConceptDisplayName from '../../api/hooks/concepts/usePatchConceptDisplayName';
import { AppActions } from '../../store/app';
import { ConceptsActions } from '../../store/concepts';
import { DashboardActions } from '../../store/dashboard';
import { FilterActions } from '../../store/filters';
import { TypographyClean } from '../../utils/styles';
import Link from '../Link';
import { shortenString } from '../../utils';

const CopyIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 100%;
  background-color: transparent;
  opacity: 0;
  transition: opacity 300ms ease, background-color 300ms ease;
`;

const EditNameIconContainer = styled(CopyIconContainer)`
  right: 3rem;
`;

const Container = styled.div<{ clickable?: boolean }>`
  position: relative;
  padding: 0.25rem; /* Compensate for hover outline */

  ${(props) => props.clickable && 'cursor: pointer;'}

  &:hover ${CopyIconContainer}, &:hover ${EditNameIconContainer} {
    background-color: rgba(228, 231, 236, 0.8);
    opacity: 1;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const LinkAnchor = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: 0;
  }
`;

export type ClusterNameProps = {
  objectId: string;
  conceptId?: string;
  name?: string;
  originalName?: string;
  projectId: string;
  size?: UiComponents.InfoHolderProps['size'];
  additionalInfo?: string;
  videoHref?: string;
  videoHrefState?: Record<string, any>;
  eventOrigin?: string;
  allowEdit?: boolean;
  containerOnClick?: () => void;
  linkOnClick?: () => void;
};

const ClusterName = ({
  objectId,
  conceptId,
  name,
  originalName,
  projectId,
  size,
  additionalInfo,
  videoHref,
  videoHrefState,
  eventOrigin,
  allowEdit = false,
  containerOnClick,
  linkOnClick,
}: ClusterNameProps) => {
  const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false);
  const [editedName, setEditedName] = useState<string | null>(null);
  const [isLoadingUpdateDisplayName, setIsLoadingUpdateDisplayName] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const isMounted = useMountedState();

  const mutation = usePatchConceptDisplayName();

  const copyNameToClipboard = async () => {
    logEvent({
      component: eventOrigin ?? 'Video Avatar',
      action: 'Click copy name',
      category: 'user_actions',
    });
    await navigator.clipboard.writeText(name ?? messages.NOT_AVAILABLE);
    dispatch(
      AppActions.setAlertOpen({
        badgeTitle: 'Success',
        badgeLabel: 'Copied to the clipboard',
        message: `Text '${name ? shortenString(name, 30) : messages.NOT_AVAILABLE}' was copied`,
        color: 'Success',
      })
    );
  };

  const saveEditName = async () => {
    setIsLoadingUpdateDisplayName(true);
    mutation.mutate(
      { projectId, conceptId: conceptId ?? objectId, newDisplayName: editedName ?? '' },
      {
        onSuccess: () => {
          // log event
          logEvent({
            component: 'Concepts',
            action: 'Edit concept name',
            category: 'user_actions',
          });
          // force refresh metrics query that feeds the table
          dispatch(ConceptsActions.changeShouldRefreshConceptsCache(true));
          // eslint-disable-next-line no-void
          void queryClient.removeQueries('concept-metrics');
          // force refresh recommendations query
          dispatch(DashboardActions.changeShouldRefreshConceptsCache(true));
          dispatch(FilterActions.changeShouldRefreshConceptsCache(true));
          // eslint-disable-next-line no-void
          void queryClient.removeQueries('recommendations-concept');
        },
        onSettled: () => {
          // Fix "Can't perform a React state update on an unmounted component" warning
          // Learn more: https://github.com/TanStack/query/issues/3804
          if (!isMounted()) return;

          setIsLoadingUpdateDisplayName(false);
          setIsEditNameModalOpen(false);
        },
      }
    );
  };

  let cleanName = name;
  const maxNameLength = 42;
  if (cleanName && cleanName.length > maxNameLength) {
    cleanName = `${cleanName.slice(0, maxNameLength)}...`;
  }

  return (
    <Container onClick={containerOnClick} clickable={!!containerOnClick} aria-label="Video Name">
      {videoHref && (
        <Link component={<LinkAnchor href={videoHref} />} onClick={linkOnClick} to={videoHref} state={videoHrefState} />
      )}
      <UiComponents.InfoHolder label={cleanName} secondaryLabel={additionalInfo} size={size} />
      {allowEdit && (
        <EditNameIconContainer>
          <UiComponents.Tooltip content={<TypographyClean type="text-sm">Edit name</TypographyClean>}>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <IconWrapper onClick={() => setIsEditNameModalOpen(true)} data-test="edit-name" aria-label="Edit Name">
              <UiComponents.Icons.BaseIcons.Edit2 dimension={20} color={UiComponents.Colors.Gray[500]} />
            </IconWrapper>
          </UiComponents.Tooltip>
        </EditNameIconContainer>
      )}
      <CopyIconContainer>
        <UiComponents.Tooltip content={<TypographyClean type="text-sm">Copy name</TypographyClean>}>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <IconWrapper onClick={copyNameToClipboard} data-test="copy-name" aria-label="Copy name">
            <UiComponents.Icons.BaseIcons.Copy dimension={20} color={UiComponents.Colors.Gray[500]} />
          </IconWrapper>
        </UiComponents.Tooltip>
      </CopyIconContainer>
      <UiComponents.Modal
        onClick={(e) => e.stopPropagation()}
        onClose={() => setIsEditNameModalOpen(false)}
        isOpen={isEditNameModalOpen}
        modalHeader={
          <UiComponents.ModalHeader
            title="Edit concept display name"
            subtitle={`This won't change the concept's original name (${originalName ?? messages.NOT_AVAILABLE})`}
          />
        }
        modalFooter={
          <UiComponents.ModalButtonsContainer>
            <UiComponents.ModalButton variation="secondary" onClick={() => setIsEditNameModalOpen(false)}>
              Cancel
            </UiComponents.ModalButton>
            <UiComponents.ModalButton
              variation="primary"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={saveEditName}
              disabled={isLoadingUpdateDisplayName}
            >
              Save
            </UiComponents.ModalButton>
          </UiComponents.ModalButtonsContainer>
        }
      >
        <UiComponents.Input fullWidth type="text" defaultValue={name} onChange={(e) => setEditedName(e.target.value)} />
      </UiComponents.Modal>
    </Container>
  );
};

export default ClusterName;
