import { IntegrationIconProps } from './types';

const Js = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="28" height="28" fill="#FFCA28" />
    <path
      d="M19 25.2877L21.0615 23.9236C21.2231 24.4311 22.2462 25.6366 23.5385 25.6366C24.8308 25.6366 25.4308 24.9308 25.4308 24.4629C25.4308 23.1877 24.1112 22.7381 23.4774 22.5221C23.374 22.4869 23.289 22.4579 23.2308 22.4326C23.2009 22.4196 23.1558 22.4023 23.0979 22.3802C22.393 22.1109 19.7923 21.1173 19.7923 18.2372C19.7923 15.0648 22.8538 14.7 23.5462 14.7C23.9991 14.7 26.1769 14.7555 27.2615 16.7938L25.2615 18.1896C24.8231 17.3013 24.0946 17.0079 23.6462 17.0079C22.5385 17.0079 22.3077 17.82 22.3077 18.1896C22.3077 19.2268 23.5112 19.6917 24.5273 20.0842C24.7932 20.1869 25.0462 20.2847 25.2615 20.3864C26.3692 20.9099 28 21.7664 28 24.4629C28 25.8134 26.8672 28 24.0154 28C20.1846 28 19.1692 25.7001 19 25.2877Z"
      fill="#3E3E3E"
    />
    <path
      d="M9 25.5586L11.1487 24.1951C11.317 24.7025 11.9713 25.6378 12.9205 25.6378C13.8698 25.6378 14.3557 24.6628 14.3557 24.1951V15H16.9982V24.1951C17.041 25.4634 16.3376 28 13.2332 28C10.379 28 9.19242 26.3037 9 25.5586Z"
      fill="#3E3E3E"
    />
  </svg>
);
export default Js;
