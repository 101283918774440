import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase, Tab, Tabs, Typography } from '@replai-platform/ui-components';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';
import useHistoryState from 'use-history-state';
import { logEvent } from '../../../../analytics';
import { Page } from '../../../../utils/enums';
import { RouteAnimator } from '../../../RouteAnimator';
import * as Styled from './styles';
import TabPaths from './TabPaths';
import StaticMetricsTab from './StaticMetricsTab';
import StaticTagsTab from './StaticTagsTab';

const getTabLabel = (tab: string): string => {
  const cleanTab = tab.replace('-', ' ');
  return camelCaseToCapitalCase(cleanTab);
};

const StaticViewTabs: React.FC<{
  assetData: SDK.GetAssetResponse;
  loading: boolean;
}> = ({ assetData, loading }) => {
  const location = useLocation();
  const { pathnameBase } = useMatch(`:projectId/${Page.StaticView}/:clusterId/*`) || {
    pathnameBase: location.pathname,
  };
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useHistoryState(TabPaths.Tags, 'currentTab');

  const tabs = useMemo(() => [TabPaths.Tags, TabPaths.Metrics], []);

  // Navigate to the default tab on the initial render.
  useEffect(() => {
    navigate(`${pathnameBase}/${tabs[0]}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathnameBase, tabs]);

  const tabLabels: Tab[] = useMemo<Tab[]>(
    () =>
      tabs.map((tab) => ({
        label: getTabLabel(tab),
      })),
    [tabs]
  );

  const onChangeTab = (tab: Tab, tabIndex: number) => {
    navigate(`${pathnameBase}/${tabs[tabIndex]}${location.search}`, {
      replace: true,
    });
    logEvent({
      component: 'Video Details',
      action: `click ${camelCaseToCapitalCase(tabs[tabIndex])}`,
      category: 'user_actions',
    });
    setCurrentTab(tabs[tabIndex], true);
  };

  return (
    <Styled.MainContainer marginTop={2}>
      <Tabs
        variant="gray-button"
        tabLabels={tabLabels}
        onTabChange={onChangeTab}
        defaultSelectedTab={tabLabels.find((tab) => tab.label === getTabLabel(currentTab))}
      />
      <Styled.Container>
        <Styled.TabContainer>
          {!loading && !assetData.id ? (
            <Typography>This video is not available on your filter combination.</Typography>
          ) : (
            <AnimatePresence exitBeforeEnter>
              <Routes>
                <Route
                  path={`${TabPaths.Metrics}/*`}
                  element={
                    <RouteAnimator>
                      <StaticMetricsTab creativeData={assetData} url={assetData.url} isLoading={loading} />
                    </RouteAnimator>
                  }
                />
                <Route
                  path={`${TabPaths.Tags}/*`}
                  element={
                    <RouteAnimator>
                      <StaticTagsTab creativeData={assetData} isLoading={loading} />
                    </RouteAnimator>
                  }
                />
              </Routes>
            </AnimatePresence>
          )}
        </Styled.TabContainer>
      </Styled.Container>
    </Styled.MainContainer>
  );
};

export default StaticViewTabs;
