import { CountryIconProps } from './types';

const PF = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M1.45898 6.26159H22.5408C20.5056 2.53147 16.5484 0.000732422 11.9999 0.000732422C7.45139 0.000732422 3.49425 2.53147 1.45898 6.26159Z"
      fill="#D80027"
    />
    <path
      d="M11.9999 23.9999C16.5484 23.9999 20.5056 21.4691 22.5408 17.739H1.45898C3.49425 21.4691 7.45139 23.9999 11.9999 23.9999Z"
      fill="#D80027"
    />
    <path
      d="M16.174 11.9997C16.174 14.3049 14.3052 15.6519 12.0001 15.6519C9.69489 15.6519 7.82617 14.3049 7.82617 11.9997C7.82617 9.69448 9.69489 7.82581 12.0001 7.82581C14.3052 7.82581 16.174 9.69448 16.174 11.9997Z"
      fill="#FFDA44"
    />
    <path
      d="M16.174 11.9998C16.174 14.305 14.3052 16.1737 12.0001 16.1737C9.69489 16.1737 7.82617 14.305 7.82617 11.9998"
      fill="#0052B4"
    />
    <path d="M10.4351 10.9559H9.3916V13.0429H10.4351V10.9559Z" fill="#D80027" />
    <path
      d="M14.6089 10.9559H13.5654V13.0429H14.6089V10.9559Z"
      fill="#D80027"
    />
    <path d="M12.522 9.39087H11.4785V13.043H12.522V9.39087Z" fill="#D80027" />
  </svg>
);
export default PF;
