import { useState, useEffect, useMemo, useRef } from 'react';
import { useClickAway } from 'react-use';
import Button from '../Button/Button';
import Colors from '../Colors';
import { MONTHS } from '../DatePicker/utils';
import { offset, useFloating } from '@floating-ui/react-dom';

import * as Styled from './styles';
import Typography from '../Typography/Typography';

type DateRange = {
  startDate: string;
  endDate: string;
};

export type MonthPickerProps = {
  month?: number;
  year?: number;
  last30Days?: boolean;
  onChange?: ({
    startDate,
    endDate,
    isLast30Days,
    month,
    year,
  }: DateRange & {
    isLast30Days: boolean;
    month: string;
    year: number;
  }) => void;
  disabled?: boolean;
};

const getDateWithOffset = (date: Date): Date =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

const getDateRange = ({
  year = 1970,
  month = 1,
  isLast30Days,
}: {
  year?: number;
  month?: number;
  isLast30Days?: boolean;
}) => {
  let startDate = '';
  let endDate = '';
  const today = new Date();

  if (isLast30Days) {
    const previous30Days = new Date();
    previous30Days.setDate(previous30Days.getDate() - 30);
    [startDate] = getDateWithOffset(previous30Days).toISOString().split('T');
    [endDate] = getDateWithOffset(today).toISOString().split('T');
  } else {
    const firstDay = new Date(year, month, 1);
    // If the selected month is the current month, the last day is today
    const lastDay =
      today.getMonth() === month && today.getFullYear() === year
        ? today
        : new Date(year, month + 1, 0);
    [startDate] = getDateWithOffset(firstDay).toISOString().split('T');
    [endDate] = getDateWithOffset(lastDay).toISOString().split('T');
  }

  return { startDate, endDate };
};

const MonthPicker = ({
  month,
  year,
  last30Days = true,
  onChange,
  disabled,
}: MonthPickerProps) => {
  const today = useMemo(() => new Date(), []);
  const currentMonth = today.getUTCMonth();
  const currentYear = today.getFullYear();
  const [isLast30Days, setIsLast30Days] = useState(last30Days);
  const [selectedDate, setSelectedDate] = useState<DateRange>(
    getDateRange({
      year: year ?? currentYear,
      month: month ?? currentMonth,
      isLast30Days,
    })
  );
  const [selectedYear, setSelectedYear] = useState(
    new Date(selectedDate.endDate).getFullYear()
  );
  const date = useMemo(
    () => new Date(selectedDate.endDate),
    [selectedDate.endDate]
  );
  const disabledNextYear = selectedYear === today.getFullYear();
  const disabledPreviousYear = selectedYear === 1970;

  const [showPicker, setShowPicker] = useState(false);
  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(6)],
  });
  const pickerRef = useRef(null);

  const onMonthClick = (monthIndex: number) => {
    setSelectedDate(getDateRange({ year: selectedYear, month: monthIndex }));
    setIsLast30Days(false);
    setShowPicker(false);
  };

  const onLast30DaysClick = () => {
    if (!isLast30Days) {
      setSelectedDate(getDateRange({ isLast30Days: true }));
      setIsLast30Days(true);
      setShowPicker(false);
    }
  };

  const isSelectedMonth = (monthIndex: number) =>
    !isLast30Days &&
    monthIndex === date.getUTCMonth() &&
    selectedYear === date.getFullYear();

  useEffect(() => {
    onChange?.({
      startDate: selectedDate.startDate,
      endDate: selectedDate.endDate,
      isLast30Days,
      year: date.getFullYear(),
      month: MONTHS[date.getUTCMonth()],
    });
  }, [
    selectedDate.startDate,
    selectedDate.endDate,
    isLast30Days,
    date,
    onChange,
  ]);

  useClickAway(pickerRef, () => {
    setShowPicker(false);
  });

  return (
    <div ref={pickerRef}>
      <div ref={reference}>
        <Styled.ReferenceButton
          variant="outlined"
          leadingIcon={{ name: 'Calendar' }}
          color="Gray"
          onClick={() => setShowPicker((s) => !s)}
          disabled={disabled}
        >
          {isLast30Days
            ? 'Last 30 days'
            : `${MONTHS[date.getUTCMonth()]}, ${date.getFullYear()}`}
        </Styled.ReferenceButton>
      </div>
      {showPicker && !disabled && (
        <Styled.DropDown
          ref={floating}
          $position={strategy}
          $left={`${x}px`}
          $top={`${y}px`}
        >
          <Styled.Container size="xl">
            <Styled.Calendar>
              <Styled.YearContainer>
                <Button
                  variant="no-fill"
                  leadingIcon={{
                    name: 'ChevronLeft',
                    dimension: 20,
                    color: disabledPreviousYear
                      ? Colors.Gray[300]
                      : Colors.Gray[500],
                  }}
                  color="Gray"
                  onClick={() => setSelectedYear((y) => Math.max(1970, y - 1))}
                  disabled={disabledPreviousYear}
                />
                <Typography noMargin>{selectedYear}</Typography>
                <Button
                  variant="no-fill"
                  leadingIcon={{
                    name: 'ChevronRight',
                    dimension: 20,
                    color: disabledNextYear
                      ? Colors.Gray[300]
                      : Colors.Gray[500],
                  }}
                  color="Gray"
                  onClick={() =>
                    setSelectedYear((y) => Math.min(today.getFullYear(), y + 1))
                  }
                  disabled={disabledNextYear}
                />
              </Styled.YearContainer>
              <Styled.MonthsContainer>
                {MONTHS.map((monthName, index) => (
                  <Styled.MonthButton
                    key={monthName}
                    variant={isSelectedMonth(index) ? 'light' : 'no-fill'}
                    color={isSelectedMonth(index) ? 'Primary' : 'Gray'}
                    onClick={() => onMonthClick(index)}
                    disabled={
                      selectedYear === currentYear && index > currentMonth
                    }
                  >
                    {monthName}
                  </Styled.MonthButton>
                ))}
              </Styled.MonthsContainer>
            </Styled.Calendar>
            <Styled.Footer>
              <Button
                fullWidth
                variant="outlined"
                color={isLast30Days ? 'Primary' : 'Gray'}
                onClick={() => onLast30DaysClick()}
              >
                Last 30 days
              </Button>
            </Styled.Footer>
          </Styled.Container>
        </Styled.DropDown>
      )}
    </div>
  );
};

export default MonthPicker;
