import { Button } from '@replai-platform/ui-components';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import * as SDK from '@replai-platform/sdk';
import { useState } from 'react';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../../common';
import useGrantReportPermissions from '../../../../api/hooks/reports/useGrantReportPermissions';
import { RootState } from '../../../../store/rootReducer';
import { ReportsActions } from '../../../../store/reports';
import { Page } from '../../../../utils/enums';

const ButtonContainer = styled.div`
  margin-right: 1rem;
`;

export type ReportTypeAccessorType = {
  templateId: string;
  params: object;
  reportId: string;
  reportUrl?: string;
  userEmail: string;
  reportType?: SDK.ReportTypes;
  externalId?: string;
};

const timestampToDateStr = (timestamp: number) => new Date(timestamp).toISOString().split('T')[0];

const ReportTypeCellDecorator: React.FC<{
  cellData: ReportTypeAccessorType;
}> = ({ cellData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectId = useSelector((state: RootState) => state.project.id);
  const userEmail = useSelector((state: RootState) => state.auth.email);
  const grantReportPermissionsMutation = useGrantReportPermissions();
  const [buttonLoading, setButtonLoading] = useState(false);

  if (!cellData) {
    return null;
  }

  if (cellData.reportId && cellData.userEmail) {
    // This is a template sample so the button should instead open the report.
    return (
      <Button
        trailingIcon={{ name: 'ExternalLink' }}
        disabled={buttonLoading}
        onClick={() => {
          if (cellData.userEmail === userEmail) {
            window.open(cellData.reportUrl);
            return;
          }
          setButtonLoading(true);
          // Grant permissions if a given user is trying to open a report from another user
          grantReportPermissionsMutation.mutate(
            { projectId, reportId: cellData.reportId },
            {
              onSuccess: () => {
                window.open(cellData.reportUrl);
              },
              onError: () => {},
              onSettled: () => {
                setButtonLoading(false);
              },
            }
          );
        }}
      >
        {buttonLoading ? 'Opening...' : 'Open report'}
      </Button>
    );
  }

  const params = cellData.params as {
    breakdown: SDK.Breakdown;
    template: SDK.GoogleSlidesTemplates;
    mainKpi: string;
    adsFilters: SDK.AdsFilters;
    maxTopItems: number;
    tagsFilters: SDK.TagsFilters;
    assetFilters: SDK.AssetFilters;
    conceptLevel: boolean;
    metricsFilters: SDK.MetricsFilters;
    assetTagsFilters: SDK.AssetTagsFilters;
    metricsToDisplay: SDK.MetricOrKpi[];
    tagTypesToDisplay: string[];
    compareToTimeRange: {
      dateStartTimestamp: number;
      dateEndTimestamp: number;
    };
    trendSlidesToInclude: SDK.TrendSlideToInclude[];
  };

  return (
    <ButtonContainer>
      <Button
        trailingIcon={{ name: 'FilePlus' }}
        onClick={() => {
          batch(() => {
            dispatch(ReportsActions.reset());
            if (cellData.reportType === SDK.ReportTypes.GOOGLE_SHEETS && cellData.externalId) {
              dispatch(ReportsActions.setBaseSpreadsheetId(cellData.externalId));
            }
            dispatch(ReportsActions.setTemplateId(cellData.templateId));
            if (params.metricsFilters.ageDateStartTimestamp && params.metricsFilters.ageDateEndTimestamp) {
              dispatch(
                ReportsActions.changeAgeDateRange({
                  startDate: timestampToDateStr(params.metricsFilters.ageDateStartTimestamp),
                  endDate: timestampToDateStr(params.metricsFilters.ageDateEndTimestamp),
                  value: 0,
                })
              );
            }
            dispatch(ReportsActions.changeConceptLevel(params.conceptLevel));
            dispatch(ReportsActions.changeMaxTopItems(params.maxTopItems));
            dispatch(ReportsActions.setTemplate(params.template));
            dispatch(
              ReportsActions.changeCompareToTimeRange({
                startDate: timestampToDateStr(params.compareToTimeRange.dateStartTimestamp),
                endDate: timestampToDateStr(params.compareToTimeRange.dateEndTimestamp),
                rangeDays: 0,
              })
            );
            if (params.assetTagsFilters?.maxFirstAppearanceSeconds) {
              dispatch(
                ReportsActions.changeMaxFirstAppearanceSeconds({
                  value: params.assetTagsFilters.maxFirstAppearanceSeconds,
                })
              );
            }

            if (params.assetFilters.minSpend) {
              dispatch(ReportsActions.changeMinSpend(params.assetFilters.minSpend));
            }
            if (params.metricsFilters.dateStartTimestamp && params.metricsFilters.dateEndTimestamp) {
              dispatch(
                ReportsActions.changeDateRange({
                  startDate: timestampToDateStr(params.metricsFilters.dateStartTimestamp),
                  endDate: timestampToDateStr(params.metricsFilters.dateEndTimestamp),
                  rangeDays: 0,
                })
              );
            }
            if (params.adsFilters.adSetIdsToConsider) {
              dispatch(ReportsActions.changeAdSets({ value: params.adsFilters.adSetIdsToConsider, toExclude: false }));
            }
            dispatch(ReportsActions.setSelectedKpis(params.metricsToDisplay));
            dispatch(ReportsActions.setSelectedTagTypes(params.tagTypesToDisplay));
            dispatch(ReportsActions.changeBreakdown({ value: params.breakdown }));
            if (params.adsFilters.campaignIdsToConsider) {
              dispatch(
                ReportsActions.changeCampaigns({ value: params.adsFilters.campaignIdsToConsider, toExclude: false })
              );
            }
            if (params.metricsFilters.countriesToConsider) {
              dispatch(ReportsActions.changeCountries({ value: params.metricsFilters.countriesToConsider }));
            }
            if (params.adsFilters.networksToConsider) {
              dispatch(ReportsActions.changeNetworks({ value: params.adsFilters.networksToConsider }));
            }
            if (params.adsFilters.promotedObjectTypesToConsider) {
              dispatch(
                ReportsActions.changePromotedObjectTypes({
                  value: params.adsFilters.promotedObjectTypesToConsider,
                })
              );
            }
            if (params.tagsFilters.tagsToConsider) {
              dispatch(
                ReportsActions.changeTags({
                  value: params.tagsFilters.tagsToConsider as SDK.Tag[],
                  toExclude: false,
                })
              );
            }
            if (params.mainKpi) {
              dispatch(ReportsActions.setDefaultSortByKpi(params.mainKpi as SDK.MetricOrKpi));
            }
            dispatch(ReportsActions.changeTrendSlidesToInclude({ value: params.trendSlidesToInclude }));
          });
          navigate(
            `/${projectId}/${Page.Reports}/${
              cellData.reportType === SDK.ReportTypes.GOOGLE_SHEETS ? 'google-sheets' : 'google-slides'
            }`
          );
        }}
      >
        Use template
      </Button>
    </ButtonContainer>
  );
};

function getReportApplyTemplateColumn<T extends object = ReportTypeAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T, ReportTypeAccessorType>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<T, ReportTypeAccessorType>) => <ReportTypeCellDecorator cellData={data?.cell?.value} />,
  };
}

export default getReportApplyTemplateColumn;
