import { CountryIconProps } from './types';

const CH = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#D80027"
    />
    <path
      d="M18.26 9.91308H14.0861V5.73914H9.91217V9.91308H5.73828V14.087H9.91217V18.2609H14.0861V14.087H18.26V9.91308Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default CH;
