/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as SDK from '@replai-platform/sdk';
import {
  ButtonVariant,
  camelCaseToCapitalCase,
  Card,
  Icons,
  TableProps,
  TableWithPagination,
  TableWithPaginationProps,
} from '@replai-platform/ui-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import type { Row, SortingRule } from 'react-table';
import { useDeepCompareEffect } from 'react-use';
import shiftColumns from '../../../utils/shiftColumns';
import { logEvent } from '../../../analytics';
import { api } from '../../../api';
import useConceptCsvReport from '../../../api/hooks/concepts/useConceptCsvReport';
import useConceptMetrics from '../../../api/hooks/concepts/useConceptMetrics';
import generateColumn from '../../../components/columns/generateColumnDefaults';
import Link from '../../../components/Link';
import TableToolbar from '../../../components/TableToolbar';
import TagPreviewVideosWrapper from '../../../components/TagPreviewVideosWrapper';
import { ConceptsActions } from '../../../store/concepts';
import { FilterActions } from '../../../store/filters';
import type { RootState } from '../../../store/rootReducer';
import { generateHref } from '../../../utils';
import { Columns, Page } from '../../../utils/enums';
import getMetricsFromColumns from '../../../utils/getMetricsFromColumns';
import getUserSelectedColumns from '../../../utils/getUserSelectedColumns';
import {
  ALLOWED_COLUMNS_ON_CONCEPTS_TABLE,
  DISALLOWED_COLUMNS_ON_CSV_EXPORT,
  getOrderByCondition,
  ITEMS_PER_PAGE,
  NON_CUSTOMIZABLE_COLUMNS,
} from '../../../utils/tables';
import * as Styled from './styles';
import { CUSTOMIZE_DIALOG_SUBTITLE, CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP } from '../../../utils/constants';

const ConceptsTable = ({ filtersLoading }: { filtersLoading?: boolean }) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const match = useMatch(':projectId/*')!;
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes);
  const filters = useSelector((state: RootState) => state.filters);
  const searchTerm = useSelector((state: RootState) => state.concepts.searchTerm);
  const shouldRefreshConceptsCache = useSelector((state: RootState) => state.concepts.shouldRefreshConceptsCache);
  const [dialogProps, setDialogProps] = useState({ open: false, tagId: null, tagType: null, tagValue: null });
  const [propData, setPropData] = useState<
    (SDK.GetConceptsMetricsResponse['concepts'][number] & {
      allowEdit: boolean;
      cluster: any;
      expanded: boolean;
      subRows: any[];
    })[]
  >([]);
  const [videosSorting, setVideosSorting] = useState<SortingRule<object>[]>([{ id: 'metrics.spend', desc: true }]);
  const [videosOffset, setVideosOffset] = useState<number>(0);
  const [csvExportClicked, setCsvExportClicked] = useState<boolean>(false);
  const onSearchTermChange = (newSearchTerm: string): void => {
    dispatch(ConceptsActions.changeSearchTerm(newSearchTerm));
    setVideosOffset(0);
  };

  const userColumnsState = useSelector(
    (state: RootState) => state.project.userProject.uiPreferences?.videos?.columns as Columns[]
  );

  // TODO(MERC-1555): cleanup
  const userColumns = useMemo(() => {
    if (projectId !== '248ca9eb-6f5b-4106-b52e-cc3c6d96436b') {
      return userColumnsState;
    }
    return userColumnsState ?? [Columns.Installs, Columns.AGE];
  }, [userColumnsState, projectId]);

  const userSelectedColumns: Columns[] = useMemo(
    () => getUserSelectedColumns({ userColumns, projectKpis, projectAccountTypes }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectKpis, projectAccountTypes, JSON.stringify(userColumns)]
  );

  // Reset pagination on filters change
  useEffect(() => {
    setVideosOffset(0);
  }, [filters]);

  const metrics = useMemo(() => getMetricsFromColumns(userSelectedColumns), [userSelectedColumns]);

  const metricsParams = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: metrics?.length === 0 ? [SDK.Metrics.SPEND] : metrics,
      orderBy: getOrderByCondition(videosSorting[0], userSelectedColumns),
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters({ ...filters, assetTypes: [SDK.AssetType.Video] }),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      tagsFilters: api.filterConverter.getTagsFilters(filters),
      maxRecords: 10,
      offset: videosOffset,
      search: searchTerm,
      includeTotalsAndAvg: true,
      refreshCache: shouldRefreshConceptsCache,
      sorting: videosSorting,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(filters),
      metrics,
      projectId,
      shouldRefreshConceptsCache,
      userSelectedColumns,
      videosOffset,
      searchTerm,
      videosSorting,
    ]
  );

  const { data, isLoading, isError, isFetching } = useConceptMetrics(metricsParams, {
    onSuccess: useCallback(
      (res) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setPropData(res);
        dispatch(ConceptsActions.changeShouldRefreshConceptsCache(false));
      },
      [dispatch]
    ),
    select: useCallback(
      (res) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        res?.concepts?.map((entry) => ({
          cluster: {
            ...entry,
            name: entry.name ?? entry.value,
          },
          entity: SDK.ObjectLevel.CONCEPT,
          expanded: false,
          allowEdit: true,
        })),
      []
    ),
    enabled: !filtersLoading,
    staleTime: 30,
  });

  const { sorting, offset, searching, eventPrefix, emptyStateProps } = useMemo(
    () => ({
      sorting: videosSorting,
      offset: videosOffset,
      searching: searchTerm,
      eventPrefix: 'videos',
      emptyStateProps: isError
        ? {
            icon: 'AlertTriangle' as Icons.BaseIconTypes,
            text: 'Something went wrong...',
            supportingText:
              'We had some trouble loading this page. Please refresh the page to try again or get in touch if the problem sticks around!',
          }
        : {
            icon: 'Search' as Icons.BaseIconTypes,
            text: 'No videos found',
            supportingText: "Your search didn't return any videos, please try again or adjust your filters.",
            buttons: [
              {
                variant: 'regular' as ButtonVariant,
                children: 'Clear search',
                onClick: () => dispatch(ConceptsActions.changeSearchTerm('')),
              },
              {
                variant: 'outlined' as ButtonVariant,
                children: 'Reset filters',
                onClick: () => dispatch(FilterActions.reset()),
              },
            ],
          },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(videosSorting), videosOffset, searchTerm, isError, dispatch]
  );

  const fetchConceptAssets = useCallback(
    (conceptId: SDK.UUID, index: number) => {
      const newIsExpandedState = !propData[index].expanded;
      const newPropData = propData.map((entry, i) =>
        i === index ? { ...entry, expanded: newIsExpandedState } : entry
      );
      setPropData(newPropData);

      if (!newIsExpandedState) {
        // Don't fetch assets if we are closing the row
        return;
      }

      const hasData = !!propData[index].subRows?.[0].cluster;
      if (hasData) {
        // Don't fetch assets if we already have them
        return;
      }
      setPropData(newPropData.map((entry, i) => (i === index ? { ...entry, subRows: [{ loading: true }] } : entry)));

      api.assets
        .getAssetsMetrics({
          projectIds: [projectId],
          metrics: metrics?.length === 0 ? [SDK.Metrics.SPEND] : metrics,
          orderBy: getOrderByCondition(sorting[0], userSelectedColumns),
          adsFilters: api.filterConverter.getAdsFilters(filters),
          assetFilters: api.filterConverter.getAssetFilters(filters),
          metricsFilters: api.filterConverter.getMetricsFilters(filters),
          tagsFilters: api.filterConverter.getTagsFilters(filters),
          conceptId,
        })
        .then((assets) => {
          const subRows = assets.assets.map((entry) => ({
            cluster: {
              ...entry,
              name: entry.name,
              totalSpend: data?.[0]?.cluster?.totalSpend,
            },
            entity: SDK.ObjectLevel.ASSET,
          }));
          setPropData(newPropData.map((entry, i) => (i === index ? { ...entry, subRows } : entry)));
        })
        .catch(() => []);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      JSON.stringify(propData),
      projectId,
      JSON.stringify(metrics),
      JSON.stringify(sorting),
      JSON.stringify(userSelectedColumns),
      JSON.stringify(filters),
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  const onOffsetChange = useCallback<Exclude<TableWithPaginationProps['onPageUpdate'], undefined>>(
    ({ pageIndex }) => {
      if (pageIndex !== offset) {
        setVideosOffset(pageIndex * ITEMS_PER_PAGE);

        logEvent({
          component: 'Videos',
          action: 'Change page',
          category: 'user_actions',
          parameters: { page: `Page ${pageIndex + 1}` },
        });
      }
    },
    [offset]
  );

  const onSort = useCallback<Exclude<TableProps['onSort'], undefined>>(
    (sortBy) => {
      if (sortBy[0] !== sorting[0]) {
        setVideosSorting(sortBy);

        logEvent({
          component: 'Videos',
          action:
            sortBy[0]?.id?.split('.')?.[1] != null
              ? `Sort by ${camelCaseToCapitalCase(sortBy[0].id.split('.')[1])}`
              : 'Change Sorting',
          category: 'user_actions',
          parameters: { ...sortBy },
        });
      }
    },
    [sorting]
  );

  const onRowExpand = (row: Row<any>) => {
    logEvent({
      component: 'Videos',
      action: !row.isExpanded ? 'Row expanded' : 'Row collapsed',
      category: 'user_actions',
      parameters: { conceptId: row.original.cluster.id, rowIndex: row.index },
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    fetchConceptAssets(row.original.cluster.id, row.index);
  };

  const generateRowHref = useCallback(
    (row) =>
      generateHref(
        match?.params.projectId ?? '',
        row.entity !== 'asset' ? Page.Concepts : Page.VideoView,
        (row.cluster?.id as string) ?? ''
      ),
    [match?.params.projectId]
  );

  // Improve this
  const columns: Columns[] = useMemo(() => {
    const cols = new Set(
      [Columns.Preview, Columns.Name, ...userSelectedColumns, Columns.AppearsIn].filter(
        // disable some columns on the concepts table
        (column) => ![Columns.Frequency, Columns.AdInstances, Columns.NumCreatives].includes(column)
      )
    );

    return shiftColumns(Array.from(cols), [Columns.CompositePreview, Columns.Preview, Columns.Name, Columns.AppearsIn]);
  }, [userSelectedColumns]);

  const tableColumns = useMemo(
    () =>
      columns.map((column) =>
        generateColumn({
          column,
          projectId,
          filters,
          includeFooter: true,
          generateRowHref,
          networks: filters.networks,
        })
      ),
    [columns, projectId, filters, generateRowHref]
  );

  const rowsTotal = useMemo<number>(() => (propData?.[0]?.cluster?.conceptsCount as number) ?? 0, [propData]);

  const csvColumns = useMemo(
    () => [
      'network' as keyof SDK.ConceptWithTags,
      ...columns
        // Disable these columns on CSV
        .filter((column) => !DISALLOWED_COLUMNS_ON_CSV_EXPORT.includes(column))
        .map((column) => (column.split('.')?.[1] ?? column) as keyof SDK.ConceptWithTags),
    ],
    [columns]
  );
  const csvParams = useMemo<SDK.GetConceptsCsvReportRequest>(
    () => ({
      projectId,
      metrics: metrics?.length === 0 ? [SDK.Metrics.SPEND] : metrics,
      orderBy: getOrderByCondition(sorting[0], userSelectedColumns),
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: {
        ...api.filterConverter.getAssetFilters(filters),
        types: [SDK.AssetType.Video],
      },
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      tagsFilters: api.filterConverter.getTagsFilters(filters),
      columns: csvColumns,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, metrics, JSON.stringify(filters), JSON.stringify(sorting), userSelectedColumns, csvColumns]
  );
  const {
    isLoading: isLoadingCsv,
    data: csvConceptsData,
    isFetching: isFetchingCsv,
  } = useConceptCsvReport(csvParams, {
    select: (res) => res.csv,
    enabled: !!projectId && !filtersLoading && csvExportClicked,
  });

  // Resets CSV data when filters change
  useDeepCompareEffect(() => {
    setCsvExportClicked(false);
  }, [filters]);

  return (
    <>
      {!!dialogProps?.tagId && (
        <TagPreviewVideosWrapper
          isOpen={dialogProps.open}
          tag={{ type: dialogProps.tagType!, value: dialogProps.tagValue }}
          page={Page.Concepts}
          component="Concepts Table"
          onClose={() => setDialogProps({ open: false, tagId: null, tagType: null, tagValue: null })}
        />
      )}
      <Card fullWidth disableContentPadding>
        <TableToolbar
          eventPrefix={eventPrefix}
          initialSearchTerm={searching}
          onSearchTermChangeEnd={onSearchTermChange}
          onExportCSVClick={() => setCsvExportClicked(true)}
          exportCSVEnabled
          csvName={`${filters.startDate}-${filters.endDate}-videos.csv`}
          csvData={csvConceptsData}
          csvLoading={(isLoadingCsv || isFetchingCsv) && csvExportClicked}
          data-test="table-toolbar"
          showCustomize
          tableColumns={ALLOWED_COLUMNS_ON_CONCEPTS_TABLE}
          nonCustomizableColumns={NON_CUSTOMIZABLE_COLUMNS}
          subtitle={CUSTOMIZE_DIALOG_SUBTITLE}
          disallowedTooltip={CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP}
        />
        <Styled.TableContainer>
          <TableWithPagination
            // eslint-disable-next-line react/no-unstable-nested-components
            cellLink={({ cell, anchorFactory }) => {
              const href = generateRowHref(cell.row.original);

              return (
                <Link
                  component={anchorFactory({ ariaLabel: cell.row.original.cluster?.name, href })}
                  onClick={() => {
                    if (cell.row.original.cluster?.type) {
                      // Open Video dialog
                      setDialogProps({
                        open: true,
                        tagId: cell.row.original.cluster?.id,
                        tagType: cell.row.original.cluster?.type,
                        tagValue: cell.row.original.cluster?.value,
                      });
                    }
                    logEvent({
                      component: 'Videos',
                      action: cell.row.depth === 0 ? 'Click on Row' : 'Click on Subrow',
                      category: 'user_actions',
                      parameters: {
                        assetId: cell.row.original?.cluster?.id,
                      },
                    });
                  }}
                  to={href}
                />
              );
            }}
            pageIndex={offset / ITEMS_PER_PAGE}
            onPageUpdate={onOffsetChange}
            onSort={onSort}
            data={propData}
            columns={tableColumns || []}
            rowsPerPage={ITEMS_PER_PAGE}
            rowsTotal={rowsTotal}
            rowsTotalLoading={propData.length === 0}
            sorting={sorting}
            emptyStateProps={emptyStateProps}
            loading={
              isLoading || (isFetching && propData.length === 0) || filtersLoading
                ? {
                    messages: ['Fetching videos', 'Getting everything ready', 'Computing aggregated metrics'],
                    firstMessageDelay: 3,
                  }
                : undefined
            }
            disableAutoExpanderColumn={false}
            onRowExpand={onRowExpand}
          />
        </Styled.TableContainer>
      </Card>
    </>
  );
};

export default ConceptsTable;
