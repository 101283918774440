import * as SDK from '@replai-platform/sdk';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { Colors, LineChart, LoadingBars } from '@replai-platform/ui-components';
import { RootState } from '../../store/rootReducer';
import { api } from '../../api';
import useAssetWeekdayPerformance from '../../api/hooks/assets/useAssetWeekdayPerformance';
import useConceptWeekdayPerformance from '../../api/hooks/concepts/useConceptWeekdayPerformance';
import { Columns } from '../../utils/enums';
import { LoadingContainer } from '../../utils/styles';
import getUserSelectedColumns from '../../utils/getUserSelectedColumns';

function formatWeekday(weekday: number) {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return daysOfWeek[weekday];
}

const CreativeWeekdayPerformanceChart = ({
  assetId,
  conceptId,
  kpi,
  mode = 'total',
}: {
  assetId?: SDK.UUID;
  conceptId?: SDK.UUID;
  kpi?: SDK.Metrics;
  mode?: 'total' | 'average';
}) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes);
  const filters = useSelector((state: RootState) => state.filters);
  const userColumns = useSelector(
    (state: RootState) => state.project.userProject.uiPreferences?.videos?.columns as Columns[]
  );

  const mainKpi = kpi ?? SDK.Metrics.VIEWS;

  const userSelectedColumns: Columns[] = useMemo(
    () => getUserSelectedColumns({ userColumns, projectKpis, projectAccountTypes }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectKpis, projectAccountTypes, JSON.stringify(userColumns)]
  );

  const kpis = useMemo(
    () => userSelectedColumns?.map((c) => c.split('.')?.[1] ?? '')?.filter((c) => c && c !== SDK.Metrics.AGE),
    [userSelectedColumns]
  );

  const { data: assetWeekdayPerformance, isLoading: isLoadingAssetWeekdayPerformance } = useAssetWeekdayPerformance(
    assetId ?? '',
    {
      projectIds: [projectId],
      metrics: kpis.map((availableKpi) => availableKpi as SDK.MetricOrKpi),
      adsFilters: api.filterConverter.getAdsFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
    },
    { enabled: !!assetId }
  );
  const { data: conceptWeekdayPerformance, isLoading: isLoadingConceptWeekdayPerformance } =
    useConceptWeekdayPerformance(
      conceptId ?? '',
      {
        projectIds: [projectId],
        metrics: kpis.map((availableKpi) => availableKpi as SDK.MetricOrKpi),
        adsFilters: api.filterConverter.getAdsFilters(filters),
        metricsFilters: api.filterConverter.getMetricsFilters(filters),
      },
      { enabled: !!conceptId }
    );

  const lines = [
    {
      name: SDK.kpiUtils.getDisplayName(mainKpi),
      accessor: 'metric',
      color: Colors.Orange[400],
    },
  ];

  if (
    isLoadingAssetWeekdayPerformance ||
    isLoadingConceptWeekdayPerformance ||
    (!assetWeekdayPerformance && !conceptWeekdayPerformance)
  ) {
    return (
      <LoadingContainer>
        <LoadingBars barSize="xxl" />
      </LoadingContainer>
    );
  }

  return (
    <LineChart
      data-test="chart"
      data={(assetWeekdayPerformance ??
        conceptWeekdayPerformance ?? { totalPerformancePeriods: [], avgPerformancePeriods: [] })[
        mode === 'total' ? 'totalPerformancePeriods' : 'avgPerformancePeriods'
      ].map((entry) => ({
        name: formatWeekday(parseInt(`${entry.dayOfWeek}`, 10)),
        metric: entry.metrics[mainKpi] ?? 0,
      }))}
      lines={lines}
      gradientColor={Colors.Orange[400]}
      yLabel={SDK.kpiUtils.getDisplayName(mainKpi)}
      xLabel="Weekday"
      showLegend={false}
    />
  );
};

export default CreativeWeekdayPerformanceChart;
