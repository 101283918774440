import { useState } from 'react';
import LoadingBars from '../../LoadingBars/LoadingBars';
import TabsUI, { Tab } from '../../Tabs/Tabs';
import EmptyState from './EmptyState';
import KpiScores, { KPIScoresProps } from './KPIScores';
import TagPreview, { TagPreviewProps } from './TagPreview';
import TagTrends, { TagTrendsProps } from './TagTrends';

const tabLabels: Tab[] = [
  {
    label: 'Tag Preview',
  },
  {
    label: 'Tag Trends',
  },
  {
    label: 'KPI Scores',
  },
];

export interface TabsProps
  extends Partial<TagTrendsProps>,
    Partial<TagPreviewProps>,
    Partial<KPIScoresProps> {
  onTabChange?: (newTab: Tab) => void;
  videosLoading: boolean;
}

const Tabs: React.VFC<TabsProps> = ({
  kpiScores,
  availableKpis,
  defaultKpi,
  onKpiChange,
  performanceChartData,
  performanceChartIsLoading,
  thumbnails,
  videos,
  videosLoading,
  onTabChange,
  onTagClick,
  onVideoPlay,
  onVideoPause,
  onTimelineClick,
  onPreviewNavigation,
  onCopyNameToClipboardClick,
}) => {
  const [selectedTab, setSelectedTab] = useState<Tab>(tabLabels[0]);
  const isEmptyState =
    !videosLoading &&
    (!videos || videos.length === 0) &&
    (!thumbnails || thumbnails.length === 0);

  return (
    <>
      <TabsUI
        tabLabels={tabLabels}
        defaultSelectedTab={selectedTab}
        onTabChange={(tab, index) => {
          setSelectedTab(tabLabels[index]);
          onTabChange?.(tabLabels[index]);
        }}
      />
      {selectedTab === tabLabels[0] ? (
        isEmptyState ? (
          <EmptyState />
        ) : videosLoading ? (
          <LoadingBars />
        ) : (
          <TagPreview
            thumbnails={thumbnails}
            videos={videos}
            onTagClick={onTagClick}
            onVideoPlay={onVideoPlay}
            onVideoPause={onVideoPause}
            onTimelineClick={onTimelineClick}
            onPreviewNavigation={onPreviewNavigation}
            onCopyNameToClipboardClick={onCopyNameToClipboardClick}
          />
        )
      ) : null}
      {selectedTab === tabLabels[1] ? (
        <TagTrends
          availableKpis={availableKpis!}
          defaultKpi={defaultKpi!}
          onKpiChange={onKpiChange!}
          performanceChartData={performanceChartData!}
          performanceChartIsLoading={performanceChartIsLoading!}
        />
      ) : null}
      {selectedTab === tabLabels[2] ? (
        <KpiScores kpiScores={kpiScores!} />
      ) : null}
    </>
  );
};

export default Tabs;
