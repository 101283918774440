/* eslint-disable */

import * as SDK from '@replai-platform/sdk';
import buildFilteringWhereClause from '../../utils/filtering';
import { filtersAsQueryParams } from './filters';
import * as provider from './provider';

/**
 * Get all kind tags
 * @param {String} projectId ID of the project to get the tag
 * @param {String} kind tags kind
 * @param {String} where where conditions
 * @param {Object} tagFiltering filters to use
 */
export async function getAllKindTags({ projectId, kind, where = null, tagFiltering }: any): Promise<Array<any>> {
  const queryParams = [
    ...filtersAsQueryParams(
      {
        projectId,
        kind,
        tagFiltering,
      },
      true // include tag type filtering
    ),
  ];

  const whereClause = buildFilteringWhereClause(where);
  /* @ts-ignore */
  if (whereClause.length > 0) queryParams.push(`where=${encodeURIComponent(whereClause)}`);

  const url = `${SDK.BASE_URL()}/kind/tags${queryParams.length > 0 ? '?' + queryParams.join('&') : ''}`;

  return provider.getJson(url);
}

/**
 * Get tag type and value information URL call
 * @param {Array} fields fields to get
 * @param {String} type name of the tag type to retrieve
 */
function getTypeValueURL({ fields, projectId, type, value }): any {
  const queryParams: string[] = [];
  queryParams.push(
    /* @ts-ignore */
    ...filtersAsQueryParams({
      projectId: projectId,
    })
  );
  /* @ts-ignore */
  if (fields && fields.length > 0) queryParams.push(`fields=${encodeURIComponent(fields)}`);

  return `${SDK.BASE_URL()}/tags/${type}/values/${btoa(value ?? '')}${
    queryParams.length > 0 ? '?' + queryParams.join('&') : ''
  }`;
}

/**
 * Get information on a tag type and value
 * @param {Array} fields fields to get
 * @param {String} type name of the tag type to retrieve
 */
export async function getTagByTypeValue({ fields, projectId, type, value }): Promise<any> {
  const url = getTypeValueURL({
    fields: fields,
    projectId: projectId,
    type: type,
    value: value,
  });

  return provider.getJson(url);
}

// ----------------------------------------------------- //
// ------------------- NEW ENDPOINTS ------------------- //
// ----------------------------------------------------- //

/* eslint-enable */

/**
 * Gets a list of preview examples for a tag, including images and videos
 */
export async function getPreviewInfo({
  projectId,
  tagIds,
  maxRecords,
  thumbnailsOnly,
}: {
  projectId: SDK.UUID;
  tagIds: SDK.UUID[];
  maxRecords?: number;
  thumbnailsOnly?: boolean;
}): Promise<SDK.GetTagsPreviewsResponse> {
  const request: SDK.GetTagsPreviewsRequest = {
    projectIds: [projectId],
    tagIds,
    thumbnailsOnly,
    ...(maxRecords ? { maxRecords } : {}),
  };
  return provider.getJson(`${SDK.BASE_URL()}/tags/previews?${SDK.convertRequestToQueryParams(request)}`);
}

/**
 * Get the descriptions of a given set of tags.
 */
export async function getDescription(
  request: SDK.GetTagsDescriptionsRequest
): Promise<SDK.GetTagsDescriptionsResponse> {
  return provider.getJson(`${SDK.BASE_URL()}/tags/description?${SDK.convertRequestToQueryParams(request)}`);
}

/**
 * Get all the tag types for a given set of projects.
 */
export async function getTypes(
  request: SDK.GetTagsTypesRequest
): Promise<{ type: string; numValues: number; values: string }[]> {
  return provider.getJson(`${SDK.BASE_URL()}/tags/types?${SDK.convertRequestToQueryParams(request)}`);
}

/**
 * Get a list of tags with metrics.
 */
export async function getMetrics(request: SDK.GetTagsMetricsRequest): Promise<SDK.GetTagsMetricsResponse> {
  return provider.getJson(`${SDK.BASE_URL()}/tags/metrics?${SDK.convertRequestToQueryParams(request)}`);
}

/**
 * Get a list of tag types with a count of existing values in the current view filters.
 */
export async function getTypeValueCounts(
  request: SDK.GetTagsTypeValueCountsRequest
): Promise<SDK.GetTagsTypeValueCountsResponse> {
  return provider.getJson(`${SDK.BASE_URL()}/tags/type_value_counts?${SDK.convertRequestToQueryParams(request)}`);
}

/**
 * Gets the suggested tag types
 */
export function getTagTypeSuggestions(
  request: SDK.GetTagTypeSuggestionsRequest
): Promise<SDK.GetTagTypeSuggestionsResponse> {
  return provider.getJson(
    `${SDK.BASE_URL()}/tags/type_suggestions?${SDK.convertRequestToQueryParams(request as object)}`
  );
}

/**
 * Gets all tags
 */
export function getTags(request: SDK.GetTagsRequest): Promise<SDK.GetTagsResponse> {
  return provider.getJson(`${SDK.BASE_URL()}/tags?${SDK.convertRequestToQueryParams(request as object)}`);
}
