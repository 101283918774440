import { createSelector } from '@reduxjs/toolkit';
import * as SDK from '@replai-platform/sdk';
import { useMemo } from 'react';
import { useQueries } from 'react-query';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import useTagRecommendations from '../api/hooks/recommendations/useTagRecommendations';
import { getTagInsightsMetricsQueryOptions } from '../api/hooks/tags/useTagInsightsMetrics';
import useTagPreviewInfo from '../api/hooks/tags/useTagPreviewInfo';
import { RootState } from '../store/rootReducer';
import { api } from '../api/index';
import { SpendAndFrequencyBadgeValues } from '../store/insights';

export type UseFetchInsightsReturn = {
  insightsData?: (SDK.FeatureRecommendation & SpendAndFrequencyBadgeValues)[];
  isInsightsDataLoading: boolean;
  tagsPreviewData?: SDK.GetTagsPreviewsResponse;
  isTagsPreviewDataLoading: boolean;
};

const buildSpendAndFrequencyQueries = ({
  recommendations,
  projectId,
  countries,
  promotedObjectTypes,
  campaignsToConsider,
  campaignsToExclude,
}: {
  recommendations: SDK.GetTagRecommendationsResponse;
  projectId: string;
  promotedObjectTypes?: SDK.PromotedObjectType[];
  countries?: string[];
  campaignsToConsider?: string[];
  campaignsToExclude?: string[];
}) =>
  (recommendations || []).map((recommendation) => {
    const { tag } = recommendation.content;
    const { since, until } = SDK.getDateRangeForRecommendations();

    const spendAndFrequencyParams: SDK.GetTagInsightsMetricsRequest = {
      projectId,
      tagToConsider: { type: tag.type, value: tag.value ?? '' },
      insightIds: Array.isArray(recommendation.id) ? recommendation.id : [recommendation.id],
      adsFilters: {
        networksToConsider: recommendation.networks,
        promotedObjectTypesToConsider: promotedObjectTypes,
        campaignIdsToConsider: campaignsToConsider,
        campaignIdsToExclude: campaignsToExclude,
      },
      assetFilters: api.filterConverter.getAssetFilters({
        assetTypes: [SDK.AssetType.Video],
      }),
      metricsFilters: {
        countriesToConsider: !countries || countries?.length === 0 ? ['ALL'] : countries,
        dateStartTimestamp: since.getTime(),
        dateEndTimestamp: until.getTime(),
      },
    };

    return getTagInsightsMetricsQueryOptions(spendAndFrequencyParams);
  });

/**
 * Hook to fetch insights based on parameters.
 *
 * Note: networks and promotedObjectTypes are not sent via parameter, they
 * are fetched directly from state.filters
 */
const useFetchInsights = ({
  countries: countriesWithAll,
  kpis,
  campaigns,
  tagsToConsider,
  promotedObjectTypes,
  positiveInsightsOnly,
  maxRecords,
  introInsights = false,
  offset = 0,
}: {
  countries?: string[];
  kpis?: SDK.MetricKPIWithSpend[];
  campaigns?: SDK.UUID[];
  tagsToConsider?: SDK.UUID[];
  promotedObjectTypes?: SDK.PromotedObjectType[];
  positiveInsightsOnly?: boolean;
  maxRecords?: number;
  introInsights?: boolean;
  offset?: number;
}): UseFetchInsightsReturn => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectPromotedObjectTypes = useSelector((state: RootState) => state.project.promotedObjectTypes);
  const projectCampaigns = useSelector((state: RootState) => state.project.campaigns);
  const projectCountries = useSelector((state: RootState) => state.project.countries);
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const minInsightScore = useSelector((state: RootState) => state.project.config.minInsightScore);
  const excludedTagTypes = useSelector(
    createSelector(
      (state: RootState) => state.project.config?.tagsTypes?.excluded,
      (value) => value ?? []
    )
  );

  const defaultKpis = projectKpis.filter((kpi) => !SDK.BLACKLIST_KPIS.includes(kpi));

  const globalFilters = useSelector((state: RootState) => state.filters);

  const countries = countriesWithAll?.filter((country) => country !== 'ALL');
  const filteringByPromotedObjectTypes =
    (promotedObjectTypes?.length ?? 0) > 0 &&
    (promotedObjectTypes?.length ?? 0) < SDK.PROMOTED_OBJECT_TYPES.length &&
    (projectPromotedObjectTypes?.length ?? 0) !== (promotedObjectTypes?.length ?? 0);
  const filteringByCampaign =
    (campaigns?.length ?? 0) > 0 && (projectCampaigns?.length ?? 0) !== (campaigns?.length ?? 0);
  const filteringByCountry =
    (countries?.length ?? 0) > 0 && (projectCountries?.length ?? 0) !== (countries?.length ?? 0);
  const shouldExcludeCampaigns = (campaigns?.length ?? 0) > (projectCampaigns?.length ?? 0) / 2;
  const shouldExcludeCountries = (countries?.length ?? 0) > (projectCountries?.length ?? 0) / 2;

  const campaignsToExclude = shouldExcludeCampaigns
    ? filteringByCampaign
      ? projectCampaigns?.filter((c) => !campaigns?.includes(c.campaignId)).map((c) => c.campaignId)
      : undefined
    : undefined;
  const campaignsToConsider = filteringByCampaign ? campaigns : undefined;

  const countriesToExclude = shouldExcludeCountries
    ? filteringByCountry
      ? projectCountries?.filter((c) => !countries?.includes(c.country)).map((c) => c.country)
      : undefined
    : undefined;

  const countriesToConsider = filteringByCountry ? countries : undefined;
  const withBreakdowns = true;

  const recommendationTypesRequest = introInsights
    ? [SDK.RecommendationType.GOOD_TAG_INTRO, ...(positiveInsightsOnly ? [] : [SDK.RecommendationType.BAD_TAG_INTRO])]
    : [SDK.RecommendationType.GOOD_TAG, ...(positiveInsightsOnly ? [] : [SDK.RecommendationType.BAD_TAG])];

  const tagRecommendationsParams = useMemo<SDK.GetTagRecommendationsRequest>(
    () => ({
      projects: [projectId],
      networks: withBreakdowns ? globalFilters.networks : [],
      promotedObjectTypes: filteringByPromotedObjectTypes ? promotedObjectTypes : [],
      recommendationTypes: recommendationTypesRequest,
      minRecommendationScore: minInsightScore,
      tagTypesToExclude: (excludedTagTypes ?? []).map((type) => ({ type })),
      objectLevels: [SDK.ObjectLevel.CONCEPT],
      breakdowns: withBreakdowns ? [SDK.BreakdownField.NETWORK] : [],
      offset,
      maxRecords,
      targets: kpis?.length ? kpis : defaultKpis,
      tagsToConsider,
      campaignsToConsider,
      campaignsToExclude,
      // countriesToConsider,
      countriesToConsider:
        projectId === 'ac32d484-dae2-4f40-9a34-4ddbdd8e1c76' && (countriesToConsider?.length ?? 0) === 0
          ? ['US']
          : countriesToConsider,
      countriesToExclude,
    }),
    [
      projectId,
      withBreakdowns,
      globalFilters.networks,
      filteringByPromotedObjectTypes,
      promotedObjectTypes,
      positiveInsightsOnly,
      introInsights,
      minInsightScore,
      excludedTagTypes,
      offset,
      maxRecords,
      kpis,
      projectKpis,
      tagsToConsider,
      campaignsToConsider,
      campaignsToExclude,
      countriesToConsider,
      countriesToExclude,
    ]
  );
  const insights = useTagRecommendations(tagRecommendationsParams);

  const tagPreviewsParams = useMemo(
    () => ({
      projectIds: [projectId],
      tagIds: (insights.data || []).map((recommendation) => recommendation.content.tag.id),
      thumbnailsOnly: true,
    }),
    [projectId, insights.data]
  );
  const tagPreviews = useTagPreviewInfo(tagPreviewsParams, {
    enabled: !!insights?.data?.length,
  });

  const spendAndFrequencyQueries = useMemo(
    () =>
      buildSpendAndFrequencyQueries({
        recommendations: insights.data ?? [],
        projectId,
        countries,
        promotedObjectTypes,
        campaignsToConsider,
        campaignsToExclude,
      }),
    [insights.data, projectId, JSON.stringify(globalFilters)]
  );
  const spendAndFrequency = useQueries(spendAndFrequencyQueries);

  const insightsDataWithSpendAndFrequency = insights?.data?.map((insight) => {
    const correspondentSpendAndFrequencyQuery = spendAndFrequency?.find(
      (spendAndFrequencyQuery) =>
        spendAndFrequencyQuery.isSuccess &&
        _.isEqual(spendAndFrequencyQuery?.data?.insightIds, Array.isArray(insight.id) ? insight.id : [insight.id])
    );

    return {
      ...insight,
      spendAndFrequency: {
        isLoading: !correspondentSpendAndFrequencyQuery,
        insightObjectCount: correspondentSpendAndFrequencyQuery?.data?.insightObjectCount,
        contextObjectCount: correspondentSpendAndFrequencyQuery?.data?.contextObjectCount,
        insightSpend: correspondentSpendAndFrequencyQuery?.data?.insightSpend,
        contextSpend: correspondentSpendAndFrequencyQuery?.data?.contextSpend,
      },
    };
  });

  return {
    isInsightsDataLoading: insights.isLoading,
    insightsData: insightsDataWithSpendAndFrequency,
    isTagsPreviewDataLoading: tagPreviews.isLoading,
    tagsPreviewData: tagPreviews.data,
  };
};

export default useFetchInsights;
