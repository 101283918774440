import styled from 'styled-components';

export const EmptyContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 10rem;
  margin-top: 1rem;
`;

export const LoadingScreenWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 10rem;
`;

export const TagCategoryContainer = styled.div`
  margin-top: 20px;
  padding: 0.5rem;
  border-bottom: 1px solid #e4e7ec;
`;

export const AllTagsWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  margin-bottom: 5rem;
`;

export const SearchInputWrapper = styled.div`
  margin-top: 16px;
`;
