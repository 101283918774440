import { Toggle } from '@replai-platform/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { FilterActions } from '../../store/filters';
import { RootState } from '../../store/rootReducer';

export const INTRO_TAGS_MAX_FIRST_APPEARANCE_SECS = 5;

const IntroTagsToggle = ({
  initialValue,
  customDispatch,
}: {
  initialValue?: number;
  customDispatch?: (checked: boolean) => AnyAction;
}): JSX.Element => {
  const currentFilter = useSelector((state: RootState) => initialValue ?? state.filters.maxFirstAppearanceSeconds);
  const dispatch = useDispatch();

  return (
    <div data-test="intro-tags-toggle">
      <Toggle
        checked={(currentFilter ?? 0) > 0}
        label="Show only intro tags"
        labelPosition="start"
        tooltipContent={`Consider only tags that appear in the first ${INTRO_TAGS_MAX_FIRST_APPEARANCE_SECS} seconds of videos`}
        onChange={(checked: boolean) =>
          dispatch(
            customDispatch
              ? customDispatch(checked)
              : FilterActions.changeMaxFirstAppearanceSeconds({
                  logEvent: true,
                  value: checked ? INTRO_TAGS_MAX_FIRST_APPEARANCE_SECS : undefined,
                })
          )
        }
        variant="outlined"
      />
    </div>
  );
};

export default IntroTagsToggle;
