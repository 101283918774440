/**
 * Formats a number with the number of decimal places provided,
 * ignoring insignificant zeros
 *
 * @param number number to be formatted
 * @param numberOfDecimals number of decimal places
 * @returns formatted number
 */
export const formatWithDecimals = (number: number, numberOfDecimals: number) =>
  parseFloat(number.toFixed(numberOfDecimals));
