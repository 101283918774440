import { IntegrationIconProps } from './types';

const Appearin = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1334_974)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 18C26 19.1046 25.1046 20 24 20H5C3.89543 20 3 19.1046 3 18V6.00002C3 4.89545 3.89543 4.00002 5 4.00001L24 4C25.1046 4 26 4.89543 26 6V18ZM31.2506 19.5432L28.8041 17.8295C28.2964 17.4739 27.9973 16.9154 27.9973 16.3221V7.54499C27.9973 6.95175 28.2964 6.39325 28.8041 6.03759L31.2506 4.32393C31.5594 4.10764 32 4.31369 32 4.67438V19.1927C32 19.5534 31.5594 19.7595 31.2506 19.5432Z"
        fill="#41CC8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 9C23 7.89543 22.1046 7 21 7L2.00001 7C0.895436 7 5.48622e-06 7.89543 5.08502e-06 9L7.26432e-07 21C3.25235e-07 22.1046 0.895431 23 2 23H7.50001L16 28V23H21C22.1046 23 23 22.1046 23 21V9Z"
        fill="#FF2E63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5259 19.061H13.9856V17.8524H13.9512C13.3348 18.7298 12.3765 19.3257 11.2291 19.3257C8.78115 19.3257 7.39453 17.3558 7.39453 15.1372C7.39453 13.0183 8.84997 11.0815 11.178 11.0815C12.3592 11.0815 13.3179 11.6776 13.9512 12.6046H13.9856V11.3133H15.5259V19.061ZM14.0711 15.187C14.0711 13.6806 13.1293 12.4058 11.4689 12.4058C9.87685 12.4058 8.93547 13.7467 8.93547 15.187C8.93547 16.6438 9.84244 18.0014 11.4689 18.0014C13.1466 18.0014 14.0711 16.7101 14.0711 15.187Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1334_974">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Appearin;
