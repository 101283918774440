import { CountryIconProps } from './types';

const PG = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3684)">
      <path
        d="M20.4853 20.4857C15.799 25.172 8.20108 25.172 3.51471 20.4857C-1.17158 15.7995 -1.17158 8.20145 3.51475 3.51512C8.20103 -1.17115 15.0611 -0.433295 19.7474 4.25303C24.4337 8.93931 25.1716 15.7995 20.4853 20.4857Z"
        fill="black"
      />
      <path
        d="M3.51465 3.51467C8.20093 -1.17161 15.7989 -1.17152 20.4852 3.51472C25.1715 8.201 25.1715 15.799 20.4852 20.4853"
        fill="#A2001D"
      />
      <path
        d="M9.17152 16.174L9.36577 16.7718H9.99441L9.48582 17.1413L9.68012 17.7392L9.17152 17.3697L8.66293 17.7392L8.85718 17.1413L8.34863 16.7718H8.97723L9.17152 16.174Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.56483 10.4351L7.88869 11.4315H8.93635L8.08871 12.0473L8.41247 13.0438L7.56483 12.4279L6.71724 13.0438L7.04105 12.0473L6.19336 11.4315H7.24111L7.56483 10.4351Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.56483 17.7388L7.8886 18.7352H8.93635L8.08861 19.351L8.41247 20.3475L7.56483 19.7316L6.71724 20.3475L7.04095 19.351L6.19336 18.7352H7.24097L7.56483 17.7388Z"
        fill="#F0F0F0"
      />
      <path
        d="M10.6292 13.0439L10.9531 14.0404H12.0008L11.1531 14.6562L11.4769 15.6526L10.6292 15.0367L9.78164 15.6526L10.1055 14.6562L9.25781 14.0404H10.3055L10.6292 13.0439Z"
        fill="#F0F0F0"
      />
      <path
        d="M4.50233 13.0439L4.82619 14.0404H5.8738L5.02621 14.6562L5.34993 15.6526L4.50233 15.0367L3.65469 15.6526L3.97855 14.6562L3.13086 14.0404H4.17856L4.50233 13.0439Z"
        fill="#F0F0F0"
      />
      <path
        d="M20.0879 8.88636C19.5516 8.35006 18.8547 8.03324 18.1059 7.97881L20.3475 6.23539C19.4547 5.34266 18.2582 4.93949 17.0906 5.02475C16.9509 4.52244 16.6844 4.04797 16.2894 3.65295L15.4039 5.4238C15.3459 5.31795 15.2723 5.21834 15.1826 5.12867C14.6732 4.61928 13.8473 4.61928 13.338 5.12867C12.8286 5.63806 12.8285 6.46391 13.338 6.9733C13.4276 7.06297 13.5273 7.13652 13.6331 7.19464L11.8623 8.08006C12.2574 8.47522 12.7322 8.74189 13.2348 8.88158C13.1493 10.0491 13.552 11.2455 14.4448 12.1383L16.4032 9.62023C16.5506 9.47516 16.7451 9.39505 16.9521 9.39505C17.1612 9.39505 17.3577 9.47642 17.5055 9.62431C17.6533 9.77211 17.7348 9.96866 17.7348 10.1777C17.7348 10.3867 17.6533 10.5832 17.5055 10.7311L18.2434 11.469C18.5883 11.1241 18.7782 10.6655 18.7782 10.1777C18.7782 9.77863 18.6509 9.39922 18.4159 9.08553C18.7584 9.17699 19.082 9.35614 19.3502 9.62431C20.1639 10.438 20.1639 11.762 19.3502 12.5757L20.0881 13.3136C20.6793 12.7223 21.0049 11.9362 21.0049 11.1C21.0048 10.2637 20.6791 9.47764 20.0879 8.88636Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3684">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default PG;
