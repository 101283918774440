import { CountryIconProps } from './types';

const EH = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3780)">
      <path
        d="M1.19072 6.78189C0.427875 8.35951 0 10.1294 0 11.9993C0 13.8692 0.427875 15.639 1.19072 17.2166L12 18.2601L22.8093 17.2166C23.5721 15.639 24 13.8692 24 11.9993C24 10.1294 23.5721 8.35951 22.8093 6.78189L12 5.7384L1.19072 6.78189Z"
        fill="#F0F0F0"
      />
      <path
        d="M1.19141 17.2178C3.13231 21.2317 7.24278 24.0004 12.0003 24.0004C16.7577 24.0004 20.8682 21.2317 22.8091 17.2178H1.19141Z"
        fill="#496E2D"
      />
      <path
        d="M1.19141 6.78226H22.8091C20.8682 2.76831 16.7578 -0.000366211 12.0003 -0.000366211C7.24273 -0.000366211 3.13231 2.76831 1.19141 6.78226Z"
        fill="black"
      />
      <path
        d="M3.51471 3.51501C-1.17157 8.2013 -1.17157 15.7993 3.51471 20.4856C5.45126 18.5491 7.31375 16.6866 12 12.0003L3.51471 3.51501Z"
        fill="#D80027"
      />
      <path
        d="M15.1307 12C15.1307 10.2516 16.3594 8.79103 18.0002 8.43272C17.748 8.37764 17.4864 8.34778 17.2176 8.34778C15.2005 8.34778 13.5654 9.98287 13.5654 12C13.5654 14.017 15.2005 15.6521 17.2176 15.6521C17.4863 15.6521 17.748 15.6223 18.0002 15.5672C16.3594 15.2089 15.1307 13.7483 15.1307 12Z"
        fill="#D80027"
      />
      <path
        d="M18.1533 9.6521L18.6714 11.2465H20.3477L18.9915 12.2317L19.5096 13.826L18.1533 12.8407L16.7972 13.826L17.3153 12.2317L15.959 11.2465H17.6353L18.1533 9.6521Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3780">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default EH;
