import { IntegrationIconProps } from './types';

const Bamboo = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.2424 17.9155H21.4694C21.0928 17.9112 20.7707 18.1873 20.7142 18.5627C20.3863 20.9285 18.3787 22.6882 16.0098 22.6861L17.5729 29.8858C23.2361 29.1397 27.595 24.4752 27.9975 18.7302C28.0122 18.5192 27.9392 18.3115 27.7959 18.157C27.6527 18.0024 27.4522 17.9149 27.2424 17.9155Z"
      fill="#2684FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.252 17.7327L25.5584 10.5329C25.721 10.3884 25.8141 10.1804 25.8141 9.96184C25.8141 9.74329 25.721 9.53528 25.5584 9.39073L17.252 2.17958C17.0278 1.98882 16.7144 1.94618 16.4482 2.07022C16.1819 2.19426 16.011 2.46255 16.0098 2.7583V17.1539C16.011 17.4497 16.1819 17.718 16.4482 17.842C16.7144 17.9661 17.0278 17.9234 17.252 17.7327Z"
      fill="#2684FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.11328 19.4878C4.90775 25.5024 9.99326 29.9959 16.0104 30V22.686C13.3958 22.686 11.2744 20.552 11.2682 17.9154L4.11328 19.4878Z"
      fill="url(#paint0_linear_1334_1164)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 17.8012V17.9154H11.2682C11.2744 15.2788 13.3958 13.1448 16.0104 13.1448L14.4511 5.94507C8.52674 6.72352 4.07207 11.7771 4 17.8012Z"
      fill="url(#paint1_linear_1334_1164)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_1164"
        x1="10.0637"
        y1="6.03643"
        x2="10.0637"
        y2="22.5337"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1164"
        x1="10.0071"
        y1="6.03645"
        x2="10.0071"
        y2="22.5338"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default Bamboo;
