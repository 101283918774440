import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-radius: 50%;

  &.labelRoot {
    flex-grow: 1;
  }

  &.root-xs {
    gap: 8px;
  }

  &.root-sm {
    gap: 10px;
  }

  &.root-md {
    gap: 12px;
  }

  &.root-lg {
    gap: 12px;
  }

  &.root-xl {
    gap: 16px;
  }

  &.root-xxl {
    gap: 18px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-width: 0;
`;

export const LabelSpan = styled.span<{
  $multiLine: boolean;
  $color: string;
  $labelSize: number;
  $fontWeight?: number;
}>`
  overflow: hidden;
  ${({ $fontWeight }) =>
    $fontWeight ? `font-weight: ${$fontWeight || 0};` : ''}
  ${({ $multiLine }) => ($multiLine ? '' : 'white-space: nowrap;')}
  ${({ $multiLine }) => ($multiLine ? '' : 'text-overflow: ellipsis;')}
  color: ${({ $color }) => $color};
  font-size: ${({ $labelSize }) => $labelSize}px;
`;
