import { Toggle } from '@replai-platform/ui-components';
import styled from 'styled-components';
import { type ReactElement } from 'react';
import { kpiUtils } from '@replai-platform/sdk';
import AddFilterButton from '../AddFilterButton';
import {
  AgeDateFilter,
  AssetTypeFilter,
  BuyingStrategyFilter,
  DateFilter,
  NetworkFilter,
  PromotedObjectFilter,
  KpiFilter,
  CampaignFilter,
  TaggedWithFilter,
  MonthFilter,
  MarketAppsFilter,
  PublisherFilter,
  CountryFilter,
  CustomMarketTagsFilter,
  CustomTagsFilter,
  PositiveInsightsToggle,
  IntroInsightsToggle,
  type AssetTypeFilterProps,
  type BuyingStrategyFilterProps,
  type NetworkFilterProps,
  type CampaignFilterProps,
  type KpiFilterProps,
  type PromotedObjectFilterProps,
  type TaggedWithFilterProps,
  type MonthFilterProps,
  type MarketAppsFilterProps,
  type PublisherFilterProps,
  type CustomMarketTagsFilterProps,
  type CountryFilterProps,
  type CustomTagsFilterProps,
  type DateFilterProps,
  type PositiveInsightsToggleProps,
  type IntroInsightsToggleProps,
} from '../Filters';
import SearchInput from '../../SearchInput';
import { Page } from '../../../utils/enums';
import IntroTagsToggle from '../../IntroTagsToggle';
import AgencyFilter, { AgencyFilterProps } from '../Filters/AgencyFilter';

export interface CoreFilterBarProps {
  withAddFilterButton?: boolean;
  withDateFilter?: boolean;
  withAgeFilter?: boolean;
  withSearchInput?: boolean;
  withCustomFilter?: boolean;
  withIntroTagsFilter?: boolean;
  withCustomFilterLast?: boolean;
  withOnlyMarketCompetitors?: boolean;
  networkFilter?: NetworkFilterProps;
  assetTypeFilter?: AssetTypeFilterProps;
  buyingStrategyFilter?: BuyingStrategyFilterProps;
  agencyFilter?: AgencyFilterProps;
  promotedObjectFilter?: PromotedObjectFilterProps;
  kpiFilter?: KpiFilterProps;
  campaignFilter?: CampaignFilterProps;
  taggedWithFilter?: TaggedWithFilterProps;
  monthFilter?: MonthFilterProps;
  customFilter?: ReactElement;
  marketAppsFilter?: MarketAppsFilterProps;
  publisherFilter?: PublisherFilterProps;
  countryFilter?: CountryFilterProps;
  customMarketTagsFilter?: CustomMarketTagsFilterProps;
  customTagsFilter?: CustomTagsFilterProps;
  dateFilter?: DateFilterProps;
  positiveInsightsToggle?: PositiveInsightsToggleProps;
  introInsightsToggle?: IntroInsightsToggleProps;
  onSearchTermChangeEnd?: (searchTerm: string) => void;
  onlyMarketCompetitors?: { disabled?: boolean; checked?: boolean; onChange: (value: boolean) => void };
  page?: Page;
  disabledDatePicker?: boolean;
}
interface RowProps {
  hasLeftFilter: boolean;
}

const Row = styled.div<RowProps>`
  display: flex;
  justify-content: ${({ hasLeftFilter }) => (hasLeftFilter ? 'space-between' : 'flex-end')};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const LeftFilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-right: 0.75rem;
`;

const CoreFilterBar: React.VFC<CoreFilterBarProps> = ({
  withAddFilterButton = true,
  withDateFilter = true,
  withAgeFilter = false,
  withIntroTagsFilter = false,
  withSearchInput = false,
  withCustomFilter = false,
  withCustomFilterLast = false,
  withOnlyMarketCompetitors = false,
  assetTypeFilter,
  buyingStrategyFilter,
  agencyFilter,
  networkFilter,
  promotedObjectFilter,
  kpiFilter,
  taggedWithFilter,
  campaignFilter,
  monthFilter,
  customFilter,
  marketAppsFilter,
  publisherFilter,
  countryFilter,
  customMarketTagsFilter,
  customTagsFilter,
  dateFilter,
  positiveInsightsToggle,
  introInsightsToggle,
  onlyMarketCompetitors,
  onSearchTermChangeEnd,
  page,
  disabledDatePicker,
}) => (
  <Row hasLeftFilter={withDateFilter || withAgeFilter || !!monthFilter || withSearchInput}>
    <LeftFilterContainer>
      {withDateFilter && !withSearchInput ? (
        <DateFilter customRanges={dateFilter?.customRanges} disabled={disabledDatePicker} />
      ) : withSearchInput ? (
        <SearchInput onSearchTermChangeEnd={(searchTerm) => onSearchTermChangeEnd?.(searchTerm)} />
      ) : undefined}
      {withAgeFilter && !withSearchInput && <AgeDateFilter page={page} />}
      {monthFilter && <MonthFilter onChange={monthFilter.onChange} />}
    </LeftFilterContainer>
    <Container>
      {withCustomFilter ? customFilter : undefined}
      {withDateFilter && withSearchInput ? (
        <div>
          <DateFilter customRanges={dateFilter?.customRanges} disabled={disabledDatePicker} />
        </div>
      ) : undefined}
      {withAgeFilter && withSearchInput && <AgeDateFilter page={page} />}
      {introInsightsToggle ? (
        <div style={{ marginRight: '0.75rem' }}>
          <IntroInsightsToggle
            checked={introInsightsToggle.checked}
            label={introInsightsToggle.label}
            onChange={introInsightsToggle.onChange}
            tooltipContent={introInsightsToggle?.tooltipContent}
            secondaryLabel={introInsightsToggle?.secondaryLabel}
          />
        </div>
      ) : undefined}
      {positiveInsightsToggle ? (
        <PositiveInsightsToggle
          checked={positiveInsightsToggle.checked}
          label={positiveInsightsToggle.label}
          onChange={positiveInsightsToggle.onChange}
          tooltipContent={positiveInsightsToggle?.tooltipContent}
          secondaryLabel={positiveInsightsToggle?.secondaryLabel}
        />
      ) : undefined}
      {withIntroTagsFilter && (
        <div style={{ marginLeft: '0.75rem' }}>
          <IntroTagsToggle />
        </div>
      )}
      {networkFilter ? (
        <NetworkFilter
          options={networkFilter.options}
          onChange={networkFilter.onChange}
          disabled={networkFilter.disabled}
          loading={networkFilter.loading}
        />
      ) : undefined}
      {assetTypeFilter ? (
        <AssetTypeFilter
          options={assetTypeFilter.options}
          onChange={assetTypeFilter.onChange}
          loading={assetTypeFilter.loading}
        />
      ) : undefined}
      {buyingStrategyFilter ? (
        <BuyingStrategyFilter
          options={buyingStrategyFilter.options}
          onChange={buyingStrategyFilter.onChange}
          disabled={buyingStrategyFilter.disabled}
          loading={buyingStrategyFilter.loading}
        />
      ) : undefined}
      {agencyFilter ? (
        <AgencyFilter
          options={agencyFilter.options}
          onChange={agencyFilter.onChange}
          disabled={agencyFilter.disabled}
          loading={agencyFilter.loading}
        />
      ) : undefined}
      {promotedObjectFilter ? (
        <PromotedObjectFilter
          options={promotedObjectFilter.options}
          onChange={promotedObjectFilter.onChange}
          disabled={promotedObjectFilter.disabled}
          loading={promotedObjectFilter.loading}
        />
      ) : undefined}
      {kpiFilter ? (
        <KpiFilter
          options={kpiFilter.options}
          defaultOption={kpiUtils.getDisplayName(kpiFilter.defaultOption)}
          dropdownType={kpiFilter.dropdownType}
          onChange={kpiFilter.onChange}
        />
      ) : undefined}
      {campaignFilter ? (
        <CampaignFilter
          options={campaignFilter.options}
          onChange={campaignFilter.onChange}
          loading={campaignFilter.loading}
        />
      ) : undefined}
      {taggedWithFilter ? (
        <TaggedWithFilter
          options={taggedWithFilter.options}
          onChange={taggedWithFilter.onChange}
          loading={taggedWithFilter.loading}
        />
      ) : undefined}
      {withOnlyMarketCompetitors && onlyMarketCompetitors ? (
        <Toggle
          label="Only my competitors"
          variant="outlined"
          disabled={onlyMarketCompetitors.disabled}
          checked={onlyMarketCompetitors.checked}
          onChange={onlyMarketCompetitors?.onChange}
        />
      ) : null}
      {marketAppsFilter ? (
        <MarketAppsFilter
          options={marketAppsFilter.options}
          onChange={marketAppsFilter.onChange}
          loading={marketAppsFilter.loading}
          onClearClick={marketAppsFilter.onClearClick}
        />
      ) : null}
      {publisherFilter ? (
        <PublisherFilter
          options={publisherFilter.options}
          onChange={publisherFilter.onChange}
          loading={publisherFilter.loading}
        />
      ) : null}
      {countryFilter ? (
        <CountryFilter
          options={countryFilter.options}
          onChange={countryFilter.onChange}
          loading={countryFilter.loading}
        />
      ) : undefined}
      {customMarketTagsFilter ? (
        <CustomMarketTagsFilter
          options={customMarketTagsFilter.options}
          onChange={customMarketTagsFilter.onChange}
          loading={customMarketTagsFilter.loading}
        />
      ) : undefined}
      {customTagsFilter ? (
        <CustomTagsFilter
          options={customTagsFilter.options}
          onChange={customTagsFilter.onChange}
          loading={customTagsFilter.loading}
        />
      ) : undefined}
      {withAddFilterButton ? <AddFilterButton /> : undefined}
      {withCustomFilterLast ? customFilter : undefined}
    </Container>
  </Row>
);
export default CoreFilterBar;
