/* eslint-disable react/jsx-props-no-spreading */
import { FlatSocialIconProps } from './types';

const Moloco = ({ color, dimension, ...props }: FlatSocialIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 3459 3459"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        style={{ opacity: '0.997' }}
        fill="#027ffb"
        d="M 1721.5,573.5 C 1948.05,566.05 2145.05,637.55 2312.5,788C 2420.59,889.114 2496.26,1010.78 2539.5,1153C 2694.19,1165.77 2824.03,1228.6 2929,1341.5C 2998.24,1418.63 3043.74,1508.3 3065.5,1610.5C 2978.83,1610.5 2892.17,1610.5 2805.5,1610.5C 2769.36,1521.52 2706.69,1459.02 2617.5,1423C 2509.71,1385.47 2408.38,1398.14 2313.5,1461C 2304.21,1467.95 2295.21,1475.29 2286.5,1483C 2284.95,1484.29 2283.28,1484.96 2281.5,1485C 2311.28,1314.07 2270.11,1162.9 2158,1031.5C 2038.28,902.452 1890.11,843.952 1713.5,856C 1571.46,871.095 1453.63,931.928 1360,1038.5C 1254.25,1166.79 1214.58,1313.46 1241,1478.5C 1256.55,1561.07 1289.39,1635.73 1339.5,1702.5C 1214.46,1674.24 1097.13,1693.41 987.5,1760C 865.562,1840.76 796.062,1954.26 779,2100.5C 768.773,2257.14 822.273,2386.97 939.5,2490C 1021.51,2557.13 1115.84,2592.13 1222.5,2595C 1421.83,2595.5 1621.17,2595.67 1820.5,2595.5C 1820.5,2326.5 1820.5,2057.5 1820.5,1788.5C 1904.5,1788.33 1988.5,1788.5 2072.5,1789C 2199.13,1998.42 2325.63,2207.92 2452,2417.5C 2575.84,2208.5 2699.17,1999.16 2822,1789.5C 2906.1,1788.5 2990.26,1788.17 3074.5,1788.5C 3075.83,2153.83 3077.16,2519.17 3078.5,2884.5C 2984.5,2884.5 2890.5,2884.5 2796.5,2884.5C 2795.35,2691.8 2793.85,2499.13 2792,2306.5C 2700.17,2462.17 2608.33,2617.83 2516.5,2773.5C 2470.84,2774.67 2425.17,2774.83 2379.5,2774C 2287.67,2622.83 2195.83,2471.67 2104,2320.5C 2103.5,2508.5 2103.33,2696.5 2103.5,2884.5C 1806.83,2884.67 1510.17,2884.5 1213.5,2884C 1009.85,2874.9 837.684,2797.4 697,2651.5C 557.004,2497.91 490.67,2316.58 498,2107.5C 513.769,1881.76 609.602,1698.93 785.5,1559C 837.386,1519.37 893.719,1486.87 954.5,1461.5C 936.19,1220.92 1007.02,1011.25 1167,832.5C 1317.58,674.144 1502.41,587.811 1721.5,573.5 Z"
      />
    </g>
  </svg>
);
export default Moloco;
