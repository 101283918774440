import { Typography } from '@material-ui/core';
import { Button } from '@replai-platform/ui-components';
import { motion } from 'framer-motion';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { pageTransition } from '../configurations/animations';
import { logEvent } from '../analytics';

function reloadPage() {
  logEvent({
    component: 'Unexpected Error Page',
    action: 'Refresh page',
    category: 'user_actions',
  });
  window.location.reload();
}

const Container = styled(motion.div)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
`;

export const ModalBodyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.25rem;
`;

export class ErrorBoundary extends React.Component<{ children: ReactElement }, { hasError: boolean }> {
  constructor(props: { children: ReactElement }) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.log('error info: ', errorInfo);
    console.log('Error: ', error);
    this.setState({ hasError: true });
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error('error: ', error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container initial="out" animate="in" exit="out" variants={pageTransition}>
          <div>
            <img src="/assets/error.png" alt="Unexpected Error" />
            <ModalBodyTextContainer>
              <Typography variant="h4">Sorry, unexpected error</Typography>
            </ModalBodyTextContainer>
            <ModalBodyTextContainer>
              <Typography variant="body2">This usually solves it, if not please contact us</Typography>
            </ModalBodyTextContainer>
            <div>
              <ButtonContainer>
                <Button
                  onClick={() => {
                    reloadPage();
                  }}
                  size="md"
                >
                  Refresh page
                </Button>
              </ButtonContainer>
            </div>
          </div>
        </Container>
      );
    }
    return this.props.children;
  }
}
