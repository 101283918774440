import { IntegrationIconProps } from './types';

const AdobePhotoshop = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="28" height="28" fill="#32CAFA" />
    <rect x="3" y="3" width="26" height="26" fill="#021D25" />
    <path
      d="M8 9.20927C8 9.12615 8.17939 9.06234 8.28317 9.06234C9.09859 9.02078 10.295 9 11.5508 9C15.063 9 16.4314 10.9264 16.4314 13.3946C16.4314 16.6181 14.0964 17.9999 11.232 17.9999C10.7517 17.9999 10.5841 17.9791 10.2491 17.9791V22.8531C10.2491 22.9584 10.2076 23 10.1023 23H8.14678C8.04151 23 8 22.9584 8 22.8531V9.20927ZM10.2491 15.9488C10.5411 15.9696 10.7724 15.9696 11.2735 15.9696C12.7576 15.9696 14.1527 15.4457 14.1527 13.4376C14.1527 11.8258 13.1564 11.0095 11.4618 11.0095C10.9607 11.0095 10.4789 11.0303 10.2491 11.0511V15.9488ZM21.1564 14.4409C20.1527 14.4409 19.8176 14.944 19.8176 15.3626C19.8176 15.8226 20.0474 16.1373 21.3862 16.8274C23.3729 17.7906 24 18.7108 24 20.0718C24 22.1021 22.4522 23 20.3617 23C19.2543 23 18.3099 22.7758 17.7672 22.4612C17.6842 22.4196 17.662 22.3573 17.662 22.2519V20.5734C17.662 20.4473 17.7242 20.4057 17.8087 20.468C18.6034 20.9919 19.5256 21.222 20.3617 21.222C21.3655 21.222 21.7835 20.8035 21.7835 20.238C21.7835 19.7779 21.4915 19.3802 20.215 18.7108C18.4166 17.853 17.6635 16.9729 17.6635 15.508C17.6635 13.8754 18.9385 12.6447 21.1549 12.6447C22.2417 12.6447 23.0096 12.6822 23.4277 12.8707C23.533 12.933 23.5537 13.0384 23.5537 13.1215V14.8594C23.5537 14.9633 23.4915 15.0271 23.3655 14.9856C22.9623 14.7462 22.1601 14.4409 21.1564 14.4409Z"
      fill="#32CAFA"
    />
  </svg>
);
export default AdobePhotoshop;
