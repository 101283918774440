import { BrandIconProps } from './types';

const Replai = ({ dimension }: BrandIconProps) => (
  <svg
    width={dimension || 27}
    height={dimension ? (dimension * 32) / 27 : 32}
    viewBox="0 0 27 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.1524 9.74282C22.0163 9.6199 21.7924 9.69892 21.7622 9.87745C21.1995 13.1904 19.6023 16.3658 16.9674 18.9149C16.6861 19.1871 16.3927 19.4505 16.0932 19.7022C16.0357 19.752 16.0297 19.8368 16.0841 19.8924L18.9398 22.8015C19.0063 22.8689 18.9579 22.983 18.8611 22.983L8.55172 23.3459C8.47912 23.3459 8.42769 23.2815 8.44282 23.2142L10.4908 14.4284C10.5089 14.3435 10.6178 14.3172 10.6783 14.3786L13.1891 16.9365C13.2406 16.9892 13.3222 16.9921 13.3797 16.9482C13.661 16.72 13.9303 16.477 14.1935 16.2254C16.792 13.7114 18.0807 10.4042 18.0655 7.10298C18.0625 6.25425 17.9355 5.40552 17.6208 4.6124C16.547 1.91403 13.8456 0 10.6814 0C8.95708 0 7.36892 0.570697 6.10747 1.52479C6.06512 1.55698 6.10142 1.61844 6.15285 1.6038C7.00592 1.31699 7.99209 1.50137 8.67575 2.15987C9.62562 3.07884 9.62562 4.5685 8.67575 5.4904L4.09883 9.85111C0.998147 12.7075 -0.671686 17.0068 0.257007 21.6426C1.30368 26.8608 5.71119 30.9113 11.1442 31.6986C19.33 32.8868 26.3693 26.7847 26.3693 19.0905C26.3693 15.3971 24.7449 12.0724 22.1524 9.74282Z"
      fill="#FF4857"
    />
  </svg>
);

export default Replai;
