import { CountryIconProps } from './types';

const BT = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3575)">
      <path
        d="M3.51373 20.4854C-1.17255 15.7991 -1.17255 8.20118 3.51373 3.51485C8.20002 -1.17148 15.798 -1.17143 20.4844 3.51485C20.4844 3.51649 23.6704 14.2988 18.984 18.9851C14.2978 23.6715 3.51702 20.4887 3.51373 20.4854Z"
        fill="#FFDA44"
      />
      <path
        d="M3.51465 20.4846C8.20093 25.1709 15.7989 25.1709 20.4853 20.4846C25.1716 15.7983 25.1716 8.20036 20.4853 3.51404L3.51465 20.4846Z"
        fill="#FF9811"
      />
      <path
        d="M18.7886 6.78192V4.69495H14.7885L14.4829 5.00052C13.3959 6.08751 13.5788 7.35557 13.7123 8.28145C13.8361 9.14034 13.8509 9.46935 13.5315 9.78881C13.2121 10.1082 12.883 10.0934 12.0242 9.96946C11.0983 9.83601 9.83032 9.65315 8.74334 10.7401C7.6564 11.827 7.83935 13.095 7.97294 14.0208C8.09684 14.8796 8.11174 15.2086 7.79243 15.5279C7.47307 15.8472 7.14396 15.8323 6.28535 15.7084C5.95057 15.6601 5.6044 15.6102 5.23484 15.6061L5.21191 17.693C5.4432 17.6955 5.70752 17.7336 5.98737 17.774C6.31868 17.8219 6.69368 17.876 7.08865 17.876C7.49796 17.876 7.92855 17.8176 8.3538 17.634V19.3037H11.4843V17.7385H9.91905V16.695H10.9625V15.1298H10.1285C10.1681 14.6235 10.0985 14.1386 10.0385 13.7229C9.91465 12.8641 9.89974 12.5351 10.2191 12.2159C10.5385 11.8965 10.8674 11.9113 11.7264 12.0351C12.401 12.1324 13.2574 12.2553 14.093 11.8946V13.5646H17.2234V11.9994H15.6582V10.9559H16.7017V9.3907H15.8678C15.9074 8.88431 15.8379 8.39948 15.7779 7.98365C15.6914 7.3836 15.658 7.04217 15.7548 6.78196H18.7886V6.78192Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3575">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default BT;
