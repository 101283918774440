import { useMutation } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';
import { User } from '../../../store/auth';

const updateUser = async (requestParams: { user: Omit<User, 'email'>; projectId: SDK.UUID }) => {
  const { user, projectId } = requestParams;
  const url = `${SDK.BASE_URL()}/users/${user.id}`;
  const res = await provider.getJson<object, void>(url, { method: 'PATCH', body: { ...user, projectId } });
  return res;
};

export default function useUpdateUser() {
  return useMutation(['user-update'], (requestParams: { user: Omit<User, 'email'>; projectId: SDK.UUID }) =>
    updateUser(requestParams)
  );
}
