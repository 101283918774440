import { CommonTagIconProps } from '../types';

const AspectRatio1By1 = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.72302 17.5569V20.7589C2.72302 21.0449 2.95502 21.2769 3.24102 21.2769H6.44302"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.557 21.2769H20.759C21.045 21.2769 21.277 21.0449 21.277 20.7589V17.5569"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.44302 2.7229H3.24102C2.95502 2.7229 2.72302 2.9549 2.72302 3.2409V6.4429"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.277 6.4429V3.2409C21.277 2.9549 21.045 2.7229 20.759 2.7229H17.557"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default AspectRatio1By1;
