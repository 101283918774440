import { DropDownButton } from '@replai-platform/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { kpiUtils } from '@replai-platform/sdk';
import { FilterActions } from '../../../store/filters';
import { RootState } from '../../../store/rootReducer';
import { logEvent } from '../../../analytics';

export const SpendFilterContainer = styled.div`
  padding-left: 12px;
`;

export type SpendFilterProps = {
  customState?: { value: number | null; onChange: (minSpend: number | null) => void };
};

const SpendFilter: React.VFC<SpendFilterProps> = ({ customState }) => {
  const dispatch = useDispatch();
  const filtering = useSelector((state: RootState) => state.filters);

  if (customState) {
    return (
      <SpendFilterContainer>
        <DropDownButton
          onClickCrossButton={() => {
            customState.onChange(null);
          }}
          onConfirm={(value) => {
            customState.onChange(+value);
          }}
          hideCrossButton={customState.value === null}
          placeHolder={customState.value ? `> $${kpiUtils.format('spend', customState.value)}` : 'Set min spend'}
          defaultValue={customState.value ?? undefined}
          title="Min Spend"
        />
      </SpendFilterContainer>
    );
  }
  return (
    <SpendFilterContainer hidden={!filtering.minSpend}>
      <DropDownButton
        onClickCrossButton={() => {
          dispatch(FilterActions.changeMinSpend({ value: 0, logEvent: false, removeMinSpend: true }));
          logEvent({
            component: 'Active Filters',
            action: 'Remove Min Spend Filter',
            category: 'user_actions',
          });
        }}
        onConfirm={(value) => {
          dispatch(FilterActions.changeMinSpend({ value: +value, logEvent: true }));
        }}
        placeHolder={filtering.minSpend ? `> $${kpiUtils.format('spend', filtering.minSpend)}` : ''}
        defaultValue={filtering.minSpend}
        title="Min Spend"
      />
    </SpendFilterContainer>
  );
};

export default SpendFilter;
