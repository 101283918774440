/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const GenericCanvasContainer = styled.div<{
  top?: number;
  left?: number;
  width?: number;
  height?: number;
  scale: number;
  disablePointerEvents?: boolean;
}>`
  position: fixed;
  ${(props) => (props.top ? `top: ${props.top}px;` : '')}
  ${(props) => (props.left ? `left: ${props.left}px;` : '')}
  ${(props) => (props.width ? `width: ${props.width}px;` : '')}
  ${(props) => (props.height ? `height: ${props.height}px;` : '')}
  ${(props) => (props.disablePointerEvents ? `pointer-events: none;` : '')}
  z-index: 1;
  transform: scale(${(props) => props.scale ?? 1});
  transform-origin: top left;
`;
