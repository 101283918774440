import { CountryIconProps } from './types';

const DZ = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3554)">
      <path
        d="M12.0008 0C18.6281 0 24.0007 5.37262 24.0007 12C24.0007 18.6274 18.6281 24 12.0008 24C12.0008 23.4783 10.4355 12 10.4355 12L12.0008 0Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 24C5.37262 24 0 18.6274 0 12C0 5.37262 5.37262 0 12 0"
        fill="#496E2D"
      />
      <path
        d="M14.5781 9.69867L13.5936 11.0555L11.999 10.5383L12.9852 11.894L12.0007 13.2508L13.5946 12.7318L14.5808 14.0874L14.5798 12.4111L16.1738 11.8921L14.5793 11.3751L14.5781 9.69867Z"
        fill="#D80027"
      />
      <path
        d="M12.9948 15.392C11.1219 15.392 9.60353 13.8736 9.60353 12.0007C9.60353 10.1277 11.1219 8.60935 12.9948 8.60935C13.5788 8.60935 14.1283 8.757 14.608 9.01697C13.8554 8.28099 12.8262 7.82672 11.6905 7.82672C9.38528 7.82672 7.5166 9.69544 7.5166 12.0006C7.5166 14.3058 9.38532 16.1745 11.6905 16.1745C12.8263 16.1745 13.8555 15.7202 14.608 14.9843C14.1283 15.2443 13.5788 15.392 12.9948 15.392Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3554">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default DZ;
