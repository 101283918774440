/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import Colors, { ColorTypes } from '../Colors';
import * as Icons from '../Icons';
import * as Styled from './styles';

interface FeaturedIconsProps {
  color?: ColorTypes;
  rootContainerSize?: number;
  colorScheme?: 'light' | 'dark' | 'primary';
  icon: Icons.BaseIconTypes | Icons.TagIconTypes;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  variant?: 'default' | 'outline' | 'square';
  iconProps?: Icons.TagIconProps;
  iconType?: Icons.IconType;
  showShadow?: boolean;
}

const FeaturedIcons = ({
  color = 'Primary',
  colorScheme = 'light',
  icon,
  rootContainerSize,
  size = 'md',
  variant = 'default',
  iconProps,
  iconType = Icons.IconType.BASE,
  showShadow = false,
}: FeaturedIconsProps) => {
  const Icon = Icons.getIcon({ name: icon, iconType });

  const colorBase = Colors[color];

  let borderRadius = 12;
  let borderSize = 10;
  let iconSize = 28;
  switch (size) {
    case 'xs':
      borderRadius = 4;
      borderSize = 2;
      iconSize = 12;
      break;
    case 'sm':
      borderRadius = 6;
      borderSize = 4;
      iconSize = 16;
      break;
    case 'md':
      borderRadius = 8;
      borderSize = 6;
      iconSize = 20;
      break;
    case 'lg':
      borderRadius = 10;
      borderSize = 8;
      iconSize = 24;
      break;
    case 'xl':
      borderRadius = 12;
      break;
    case 'xxl':
      borderRadius = 18;
      borderSize = 14;
      iconSize = 42;
      break;
    default:
      throw new Error('Invalid size');
  }

  return (
    <Styled.FeaturedIconContainer
      className={`${rootContainerSize ? '' : size} ${variant || ''}`}
      $showShadow={showShadow}
      $backgroundColor={
        colorScheme === 'light'
          ? colorBase[100]
          : colorScheme === 'primary'
          ? colorBase[500]
          : variant === 'outline'
          ? colorBase[600]
          : colorBase[800]
      }
      $borderColor={
        variant === 'outline'
          ? colorScheme === 'light'
            ? colorBase[50]
            : colorScheme === 'dark'
            ? colorBase[700]
            : 'transparent'
          : ''
      }
      $width={rootContainerSize ? `${rootContainerSize}px` : ''}
      $height={rootContainerSize ? `${rootContainerSize}px` : ''}
      $borderWidth={variant === 'outline' ? `${borderSize}px` : ''}
      $borderRadius={variant === 'square' ? `${borderRadius}px` : ''}
    >
      <Icon
        dimension={iconSize}
        {...(iconType !== Icons.IconType.TAG && {
          color: colorScheme === 'light' ? colorBase[600] : Colors.Common.White,
        })}
        {...iconProps}
      />
    </Styled.FeaturedIconContainer>
  );
};

export default FeaturedIcons;
export type { FeaturedIconsProps };
