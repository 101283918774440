/* eslint-disable */
import { ArrowDropUp } from '@mui/icons-material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';

const EXPAND_ARROW_STYLE = {
  fontSize: '24px',
  opacity: '0.6',
};

const ExpandCollapseCellDecorator: React.FC<{ row: any }> = ({ row }) => {
  if (!row) {
    return null;
  }

  return (
    <div
      {...row.getToggleRowExpandedProps()}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        // @ts-ignore 'onClick' not recognized for some reason.
        return row.getToggleRowExpandedProps().onClick(e);
      }}
    >
      {row.isExpanded ? <ArrowDropUp style={EXPAND_ARROW_STYLE} /> : <ArrowDropDown style={EXPAND_ARROW_STYLE} />}
    </div>
  );
};

function getExpandCollapseColumn({ columnId, accessor }: RequiredGeneratorParams): GeneratedColumn {
  return {
    id: columnId,
    accessor,
    width: 14,
    minWidth: 14,
    disableSortBy: true,
    Cell: ({ row }: CellData) => {
      return row.canExpand ? <ExpandCollapseCellDecorator row={row} /> : null;
    },
  };
}

export default getExpandCollapseColumn;
