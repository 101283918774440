/* eslint-disable prefer-destructuring */
import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import Badge from '../../Badge/Badge';
import Colors, { Color, ColorTypes } from '../../Colors';
import * as Icons from '../../Icons';
import Tooltip from '../../Tooltip/Tooltip';
import Typography from '../../Typography/Typography';

export enum VerticalMenuItemVariant {
  COUNTER = 'COUNTER',
  TEXT = 'TEXT',
  ICON = 'ICON',
  DIVIDER = 'DIVIDER',
}

interface VerticalMenuItemProps {
  id?: string;
  label?: string;
  variant: VerticalMenuItemVariant;
  isSelected?: boolean;
  icon?: Icons.BaseIconTypes;
  badgeText?: string;
  tooltipText?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onIconClick?: MouseEventHandler<HTMLDivElement>;
}

const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &:hover {
    color: ${Colors.Primary[700]} !important;
  }
`;

const StyledBadge = styled(Badge)`
  white-space: pre;
`;

const ItemButton = styled.button<{
  $hasBadgeHover: boolean;
  $backgroundColor: string;
}>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  margin: 0 1rem 0 0.0625rem;
  padding: 0.5rem 0.5rem 0.5rem 0;
  text-align: left;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;

  &:hover {
    p {
      color: ${Colors.Primary[700]} !important;
    }

    ${StyledBadge} {
      ${({ $hasBadgeHover }) =>
        $hasBadgeHover ? `color: ${Colors.Primary[700]} !important;` : ''}
    }
  }
`;

const Label = styled(Typography)`
  margin: 0 0.75rem 0 0.75rem;
`;

const MainIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${Colors.Primary[600]};
  border-radius: 0.25rem;
`;

const DividerContainer = styled.div`
  display: flex;
  margin: 1.5rem 1rem 0.5rem 0;
`;

const HelpCircleContainer = styled.div`
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
`;

const MenuItemIcon = ({
  icon,
  onClick,
}: {
  icon: Icons.BaseIconTypes;
  onClick?: MouseEventHandler<HTMLDivElement>;
}) => {
  const Icon = Icons.getBaseIcon(icon);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    onClick?.(event);
  };

  return (
    <MainIconContainer onClick={handleClick}>
      <Icon color={Colors.Common.White} dimension={20} />
    </MainIconContainer>
  );
};

const VerticalMenuItem = ({
  id,
  isSelected = false,
  label,
  icon,
  variant,
  badgeText,
  tooltipText,
  onClick,
  onIconClick,
}: VerticalMenuItemProps) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick?.(event);
  };

  const defaultBackgroundColor = 'transparent';
  const defaultTextColor: Color = Colors.Gray[700];
  let hasBadge = true;
  let hasBadgeHover = true;
  let badgeColor: ColorTypes = 'Gray';
  let badgeIcon: Icons.BaseIconTypes | undefined;
  let soloIcon: Icons.BaseIconTypes | undefined;

  switch (variant) {
    case VerticalMenuItemVariant.ICON:
      hasBadge = false;
      hasBadgeHover = false;
      soloIcon = icon;
      break;
    case VerticalMenuItemVariant.COUNTER:
      badgeColor = isSelected ? 'Blue' : 'Gray';
      badgeIcon = icon;
      break;
    case VerticalMenuItemVariant.TEXT:
      hasBadgeHover = false;
      badgeColor = 'Warning';
      badgeIcon = icon;
      break;
    default:
      break;
  }

  return variant === VerticalMenuItemVariant.DIVIDER ? (
    <DividerContainer>
      <Typography
        noMargin={true}
        color={Colors.Gray[400]}
        type={'text-xs'}
        fontWeight={'semi-bold'}
      >
        {label}
      </Typography>
      {tooltipText?.length ? (
        <Tooltip
          content={
            <Typography noMargin={true} type="text-sm">
              {tooltipText}
            </Typography>
          }
          placement="top"
        >
          <HelpCircleContainer>
            <Icons.BaseIcons.HelpCircle
              color={Colors.Gray[400]}
              dimension={13}
            />
          </HelpCircleContainer>
        </Tooltip>
      ) : (
        ''
      )}
    </DividerContainer>
  ) : (
    <ItemContainer id={id}>
      <ItemButton
        data-test="vertical-menu-item"
        type="button"
        onClick={handleClick}
        $hasBadgeHover={hasBadgeHover}
        $backgroundColor={
          isSelected ? Colors.Primary[50] : defaultBackgroundColor
        }
      >
        <Label
          fontWeight="medium"
          color={isSelected ? Colors.Primary[700] : defaultTextColor}
        >
          {label}
        </Label>
        {soloIcon && <MenuItemIcon icon={soloIcon} onClick={onIconClick} />}
        {hasBadge && (
          <StyledBadge color={badgeColor} leadingIcon={badgeIcon}>
            {badgeText}
          </StyledBadge>
        )}
      </ItemButton>
    </ItemContainer>
  );
};

export default VerticalMenuItem;
export type { VerticalMenuItemProps };
