import { IntegrationIconProps } from './types';

const Dribbble = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM20.924 26.9465C19.4216 27.6234 17.7548 28 16 28C13.1031 28 10.4459 26.9735 8.3724 25.2643C10.5294 21.6447 13.9989 18.9003 18.123 17.6861C19.3076 20.6581 20.2494 23.7531 20.924 26.9465ZM22.7487 25.9239C22.0841 22.9351 21.193 20.0318 20.0945 17.2327C21.009 17.0797 21.9483 17 22.9062 17C24.6366 17 26.3047 17.2596 27.8745 17.7415C27.3807 21.1382 25.4641 24.0737 22.7487 25.9239ZM27.9962 15.6933C26.3758 15.2414 24.6685 15 22.9062 15C21.6778 15 20.4767 15.1172 19.3136 15.3409C19.0102 14.6408 18.6937 13.9477 18.3643 13.262C20.9824 12.0999 23.402 10.5718 25.5577 8.7429C27.0326 10.6824 27.9307 13.0845 27.9962 15.6933ZM24.2186 7.25613C22.1947 8.96582 19.9237 10.3915 17.4667 11.4721C16.1697 8.99186 14.7017 6.61533 13.0784 4.35816C14.0136 4.12421 14.9923 4 16 4C19.1801 4 22.0713 5.23702 24.2186 7.25613ZM11.1025 5.04161C7.57974 6.6184 4.95607 9.844 4.2131 13.7368C4.89578 13.787 5.58546 13.8125 6.28127 13.8125C9.54918 13.8125 12.6851 13.2492 15.5974 12.2142C14.2746 9.70713 12.7706 7.31047 11.1025 5.04161ZM4.00305 15.7267C4.00102 15.8176 4 15.9087 4 16C4 18.992 5.09504 21.7283 6.90621 23.8299C9.29203 20.0493 12.982 17.172 17.3453 15.8311C17.0759 15.2193 16.796 14.6131 16.506 14.0127C13.3168 15.1771 9.87298 15.8125 6.28127 15.8125C5.51493 15.8125 4.75517 15.7836 4.00305 15.7267Z"
      fill="#E84C88"
    />
  </svg>
);
export default Dribbble;
