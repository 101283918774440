/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { ReactElement } from 'react';
import {
  Button,
  ButtonGroup,
  ButtonGroupButtonProps,
  ButtonGroupContainerProps,
  ButtonProps,
  Colors,
  DropDownChip,
  DropDownChipProps,
  Input,
  InputProps,
  Tabs,
  TabsProps,
} from '..';
import * as Icons from '../Icons';
import * as Styled from './styles';
import Toggle, { ToggleProps } from '../Toggle/Toggle';

interface SectionHeaderProps {
  buttonGroup?: {
    container: ButtonGroupContainerProps;
    buttons: ButtonGroupButtonProps[];
  };
  buttons?: ButtonProps[];
  dropDownChips?: DropDownChipProps[];
  toggle?: ToggleProps;
  iconButton?: {
    icon: Icons.BaseIconTypes;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  };
  input?: InputProps;
  subtitle?: string;
  tabs?: TabsProps;
  title: string | ReactElement;
  withDivider?: boolean;
  withPaddingBottom?: boolean;
}

const SectionHeader = ({
  buttonGroup,
  buttons,
  dropDownChips,
  toggle,
  iconButton,
  input,
  subtitle,
  tabs,
  title,
  withDivider = true,
  withPaddingBottom = true,
}: SectionHeaderProps) => {
  const Icon = iconButton && Icons.getBaseIcon(iconButton.icon);

  return (
    <Styled.Root
      $withDivider={withDivider}
      $withPaddingBottom={withPaddingBottom}
    >
      <Styled.TopContainer
        className={(tabs ?? '') && 'overridePadding'}
        $withPaddingBottom={withPaddingBottom}
      >
        <Styled.TitleContainer>
          <Styled.Typography
            type="text-lg"
            fontWeight="medium"
            $margin="0"
            $display="flex"
            $justifyContent="space-between"
          >
            {title}
            {Icon && (
              <Styled.IconButton type="button" onClick={iconButton.onClick}>
                <Icon dimension={16} color={Colors.Gray[400]} />
              </Styled.IconButton>
            )}
          </Styled.Typography>
          <Styled.Typography
            type="text-sm"
            color={Colors.Gray[500]}
            $marginTop="4px"
            $marginBottom="0"
          >
            {subtitle}
          </Styled.Typography>
        </Styled.TitleContainer>
        <Styled.RightContainer>
          {toggle && <Toggle {...toggle} />}
          {dropDownChips
            ? dropDownChips.map((dropDownChipProps, index) => (
                <DropDownChip key={index} {...dropDownChipProps} />
              ))
            : ''}
          {input ? (
            <Styled.InputHolder>
              <Input {...input} fullWidth />
            </Styled.InputHolder>
          ) : buttonGroup ? (
            <ButtonGroup.Container {...buttonGroup.container}>
              {buttonGroup.buttons.map((button, index) => (
                <ButtonGroup.Button {...button} key={index} />
              ))}
            </ButtonGroup.Container>
          ) : buttons ? (
            buttons.map((buttonProps, index) => (
              <Button
                key={index}
                {...buttonProps}
                style={{
                  ...(buttonProps.style || {}),
                }}
              />
            ))
          ) : (
            ''
          )}
        </Styled.RightContainer>
      </Styled.TopContainer>

      {tabs && (
        <Styled.Div
          className={tabs.variant !== 'underline' ? 'overridePadding' : ''}
        >
          <Tabs {...tabs} />
        </Styled.Div>
      )}
    </Styled.Root>
  );
};

export default SectionHeader;
export type { SectionHeaderProps };
