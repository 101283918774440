import styled from 'styled-components';
import Colors from '../../Colors';
import Typography from '../../Typography/Typography';

export const Container = styled.div`
  background-color: transparent;
  width: 100%;
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  height: 3rem;
  border-bottom: 1px solid ${Colors.Gray[200]};
  padding: 1rem 1rem;
  align-items: center;
`;

export const TabsContainer = styled.div`
  height: fit-content;
  border-bottom: 1px solid ${Colors.Gray[200]};
  padding: 0.5rem 1rem;
`;

export const PlayerControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const Control = styled.div<{ active?: boolean }>`
  padding: 0.65rem 0.75rem;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: ${({ active }) => (active ? Colors.Gray[100] : 'inherit')};

  & > * {
    vertical-align: middle;
  }

  &:hover {
    background-color: ${Colors.Gray[50]};
  }
`;

export const DurationMain = styled.span`
  color: ${Colors.Gray[900]};
  font-weight: bold;
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
`;

export const DurationSub = styled(DurationMain)`
  font-weight: normal;
  color: ${Colors.Gray[400]};
`;

export const ValuesContainer = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const KeyValue = styled.span<{ scale?: number }>`
  display: flex;
  gap: 0.5rem;
  min-height: 3.25rem;
  max-height: fit-content;
  align-items: center;
  transition: scale 0.4s ease-in-out;
  scale: ${({ scale }) => scale ?? '1'};
  max-width: 120px;

  @media (min-width: 1200px) {
    max-width: 140px;
  }

  @media (min-width: 1400px) {
    max-width: 180px;
  }

  @media (min-width: 1500px) {
    max-width: 220px;
  }

  @media (min-width: 1600px) {
    max-width: 260px;
  }

  @media (min-width: 1800px) {
    max-width: 310px;
  }

  @media (min-width: 1900px) {
    max-width: 350px;
  }
`;

export const SegmentColor = styled.span<{
  backgroundColor: string;
  scale?: number;
}>`
  width: ${({ scale }) => (scale ? scale * 2 : 2)}px;
  height: ${({ scale }) => (scale ? scale * 16 : 16)}px;
  background-color: ${(props) => props.backgroundColor};
  transition: scale 0.4s ease-in-out;
  scale: ${({ scale }) => scale ?? '1'};
`;

export const TypographyWithEllipsis = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-word;
  }
`;
