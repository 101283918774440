import { IntegrationIconProps } from './types';

const JbHub = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.0236 0.411447C27.2465 0.0457328 26.3779 -0.0456958 25.5094 0.0457328C25.3722 0.0457328 4.80078 1.78288 4.80078 1.78288L19.3379 18.9257L21.9894 19.0629L30.3094 10.0572C33.0979 7.26859 32.5036 2.28573 28.0236 0.411447Z"
      fill="url(#paint0_linear_1334_1588)"
    />
    <path
      d="M24.5021 21.5771L23.9078 22.4914L16.5935 13.5771L9.50781 18.3314L21.2564 31.0857C21.485 31.3143 21.805 31.4972 22.125 31.68C22.765 32 23.4964 32.0914 24.2278 31.8629C24.9135 31.68 25.4164 31.3143 25.8278 30.9029C25.8735 30.8571 30.765 26.0114 30.765 26.0114L24.5021 21.5771Z"
      fill="url(#paint1_linear_1334_1588)"
    />
    <path
      d="M20.892 19.0171L8.96056 3.83998C8.4577 3.10855 7.68056 2.51426 6.67485 2.10283C5.8977 1.78283 4.98342 1.69141 4.16056 1.87426C-0.22801 2.83426 -1.23372 7.63426 1.60056 10.1943L9.87485 18.6971L20.892 19.0171Z"
      fill="url(#paint2_linear_1334_1588)"
    />
    <path
      d="M28.8908 18.5143C27.3365 18.0571 26.4679 18.1943 24.4108 18.1486L7.03934 15.68L2.05648 20.4343C-1.09781 23.5429 -0.594949 28.9829 4.06791 31.3143C5.25648 31.9086 6.58219 32.0914 7.81648 31.9086C16.6393 30.2629 27.7022 27.6114 27.7936 27.6114C27.9765 27.5657 28.2051 27.52 28.3879 27.4743C31.2679 26.6057 32.4565 23.9086 31.7708 21.6229C31.4051 20.3429 30.3993 18.9714 28.8908 18.5143Z"
      fill="url(#paint3_linear_1334_1588)"
    />
    <path d="M6.125 6.12567H25.8736V25.8742H6.125V6.12567Z" fill="black" />
    <path
      d="M7.95508 8.73145H9.78365V12.0229H13.1665V8.73145H14.9951V17.0057H13.1665V13.6686H9.78365V17.0057H7.95508V8.73145Z"
      fill="white"
    />
    <path
      d="M16.3211 8.73145H20.1611C21.1211 8.73145 21.8525 9.00573 22.3097 9.46287C22.6754 9.82859 22.8582 10.2857 22.8582 10.88V10.9257C22.8582 11.84 22.3554 12.3886 21.7611 12.7086C22.7211 13.0743 23.3154 13.6229 23.3154 14.7657V14.8114C23.3154 16.32 22.0811 17.0972 20.2068 17.0972H16.2754V8.73145H16.3211ZM21.1211 11.1543C21.1211 10.6057 20.7097 10.2857 19.9325 10.2857H18.1497V12.0229H19.8411C20.6182 12.0686 21.1211 11.7943 21.1211 11.1543ZM20.2068 13.5772H18.104V15.4057H20.2525C21.0754 15.4057 21.5325 15.1314 21.5325 14.4914V14.4457C21.5782 13.9429 21.1668 13.5772 20.2068 13.5772Z"
      fill="white"
    />
    <path d="M8 22.1714H15.4057V23.4057H8V22.1714Z" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_1334_1588"
        x1="18.4762"
        y1="0.406419"
        x2="18.0968"
        y2="17.9767"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.328" stopColor="#00B8F1" />
        <stop offset="0.973" stopColor="#9758FB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1588"
        x1="26.6712"
        y1="29.2649"
        x2="5.45387"
        y2="6.23361"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEE45" />
        <stop offset="1" stopColor="#9758FB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_1588"
        x1="14.1834"
        y1="4.33781"
        x2="9.77976"
        y2="13.7517"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.328" stopColor="#00B8F1" />
        <stop offset="0.79" stopColor="#9758FB" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1334_1588"
        x1="-1.49049"
        y1="23.8281"
        x2="31.4581"
        y2="23.8281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00B8F1" />
        <stop offset="0.306" stopColor="#9758FB" />
        <stop offset="0.989" stopColor="#FFEE45" />
      </linearGradient>
    </defs>
  </svg>
);
export default JbHub;
