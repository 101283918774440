import { IntegrationIconProps } from './types';

const Weibo = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6882 6.11267C10.5834 4.40051 0 12.7318 0 19.7569C0 23.8696 5.20067 28 13.7959 28C24.3257 28 29.3943 20.8337 27.6453 17.3211C26.5301 15.0814 23.7011 14.8853 23.5226 14.2851C23.4071 13.8968 24.8254 11.5668 23.2906 10.3136C20.6728 8.17618 16.2231 11.2491 15.7234 10.7019C15.4114 10.3604 16.9013 7.03575 14.6882 6.11267Z"
      fill="#E31C34"
    />
    <path
      d="M22.5362 18.9447C22.8725 22.383 18.8388 25.5823 13.5266 26.0906C8.21433 26.5989 3.63525 24.2238 3.29889 20.7856C2.96254 17.3473 6.99628 14.148 12.3085 13.6397C17.6207 13.1314 22.1998 15.5065 22.5362 18.9447Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1359 19.1216C17.7861 21.4196 16.0969 23.8959 13.3628 24.6526C10.6287 25.4094 7.88519 24.1599 7.23494 21.862C6.5847 19.564 8.27396 17.0876 11.008 16.3309C13.7421 15.5742 16.4856 16.8236 17.1359 19.1216ZM12.2727 20.6021C12.6342 21.3711 12.1883 22.3343 11.2768 22.7534C10.3653 23.1725 9.33328 22.8889 8.97179 22.1199C8.61031 21.3509 9.0562 20.3877 9.96772 19.9686C10.8792 19.5495 11.9112 19.8331 12.2727 20.6021ZM13.4682 20.2022C13.8234 20.0389 13.9971 19.6636 13.8563 19.3639C13.7154 19.0643 13.3133 18.9538 12.9581 19.1171C12.603 19.2804 12.4292 19.6557 12.5701 19.9553C12.7109 20.255 13.113 20.3655 13.4682 20.2022Z"
      fill="black"
    />
    <path
      d="M21.8375 4.47733C22.3243 4.35816 22.8345 4.29464 23.3613 4.29464C26.8506 4.29464 29.6792 7.09218 29.6792 10.5431C29.6792 11.3053 29.5418 12.0332 29.291 12.7055C29.0693 13.3 29.3769 13.9597 29.978 14.1789C30.5791 14.3982 31.2461 14.0941 31.4678 13.4996C31.812 12.5768 31.9994 11.5803 31.9994 10.5431C31.9994 5.82488 28.132 2 23.3613 2C22.6452 2 21.9479 2.08644 21.2801 2.24989C20.6582 2.40212 20.2788 3.02416 20.4327 3.63925C20.5866 4.25433 21.2156 4.62956 21.8375 4.47733Z"
      fill="#FD9940"
    />
    <path
      d="M22.8625 8.48247C23.0215 8.44506 23.1883 8.42499 23.3613 8.42499C24.5441 8.42499 25.503 9.3733 25.503 10.5431C25.503 10.7985 25.4578 11.0408 25.3759 11.2645C25.1578 11.8602 25.4693 12.5181 26.0717 12.7339C26.6741 12.9496 27.3393 12.6416 27.5574 12.0458C27.7297 11.5753 27.8231 11.0687 27.8231 10.5431C27.8231 8.10601 25.8255 6.13035 23.3613 6.13035C23.006 6.13035 22.6589 6.17166 22.3253 6.2502C21.702 6.39693 21.317 7.01559 21.4654 7.63201C21.6137 8.24844 22.2393 8.6292 22.8625 8.48247Z"
      fill="#FD9940"
    />
  </svg>
);
export default Weibo;
