import * as SDK from '@replai-platform/sdk';
import {
  Accordion,
  Colors,
  EmptyState,
  IconType,
  remToPixels,
  TimelineBody,
  TimelineTimestampLine,
  TimelineTimestamps,
} from '@replai-platform/ui-components';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { buildBreakdownData } from '../../../components/Timeline';
import type { RootState } from '../../../store/rootReducer';
import marketTagTypesToExclude from '../../../utils/market/tagTypesToExclude';
import type { TimelineData } from '../types';
import * as Styled from './styles';
import { getCommonTags, getDifferentTags, getTimelines, organizeTags } from './utils';
import { TagBreakdownWithId } from '../../../utils/types';

interface TagsTimelineProps {
  data: TimelineData[];
  assetIds: SDK.UUID[];
  playersProgress: { [key: string]: { playedSeconds: number; played: number } };
  visibleTags?: string[];
  isLoading: boolean;
  hasMarketData: boolean;
  onSeekToPoint: (point: number) => void;
}

const NUMBER_OF_TIMESTAMPS = 3;

const TagsTimeline: React.VFC<TagsTimelineProps> = ({
  data,
  assetIds,
  playersProgress,
  visibleTags,
  isLoading,
  hasMarketData,
  onSeekToPoint,
}) => {
  const networks = useSelector((state: RootState) => state.filters.networks);
  const tagsTypes = useSelector((state: RootState) => state.project.config.tagsTypes) ?? {};
  const [isSharedOpen, setIsSharedOpen] = useState(true);
  const [isDifferentOpen, setIsDifferentOpen] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const timelineLinesRef = useRef<HTMLDivElement>(null);
  const timelineLineFixedRef = useRef<HTMLDivElement>(null);
  const fixedColRef = useRef<HTMLDivElement>(null);

  const numVideos = data.length;
  const excludedTagTypes =
    (hasMarketData ? marketTagTypesToExclude({ projectTagTypesConfig: tagsTypes ?? {} }) : tagsTypes.excluded) ?? [];

  const pivotVideoTagData = useMemo(() => data[0] ?? [], [data]);
  const otherVideoTagData = useMemo(() => data.slice(1) ?? [], [data]);

  const pivotVideoTagsBreakdown: TagBreakdownWithId[] = buildBreakdownData(
    pivotVideoTagData,
    [],
    excludedTagTypes,
    networks,
    false,
    true
  ) as TagBreakdownWithId[];
  const otherVideoTagsBreakdown: TagBreakdownWithId[][] = otherVideoTagData.map(
    (videoTags) => buildBreakdownData(videoTags, [], excludedTagTypes, networks, false, true) as TagBreakdownWithId[]
  );

  let commonTags = getCommonTags({
    pivotVideoTags: pivotVideoTagsBreakdown,
    otherVideosTags: otherVideoTagsBreakdown,
  });
  commonTags = organizeTags(commonTags);

  const differentTags = getDifferentTags({
    pivotVideoTags: pivotVideoTagsBreakdown,
    commonTags,
    otherVideosTags: otherVideoTagsBreakdown,
  });

  const differentTagsPivot = {
    tags: differentTags[0],
    videoDuration: pivotVideoTagData?.duration ?? 0,
  };
  const differentTagsOtherVideos = differentTags.slice(1).map((diffTags, i) => ({
    tags: diffTags,
    videoDuration: otherVideoTagData?.[i]?.duration ?? 0,
  }));

  let timelines = getTimelines({
    pivot: differentTagsPivot,
    videosTags: differentTagsOtherVideos,
    allTags: otherVideoTagsBreakdown,
    commonTags,
  });
  timelines = organizeTags(timelines);

  const videosTimestampsInSeconds = useMemo(() => {
    if (isLoading) return [];

    return data?.map((video) => {
      const duration = video?.duration;
      if (!duration) return [];

      const columnWidth = remToPixels(Styled.TIMELINE_WIDTH);
      if (duration > 0 && columnWidth > 0) {
        // Minimum 3 timestamps (00:00, middle and video duration)
        const numTimestampsToShow = Math.max(NUMBER_OF_TIMESTAMPS, Math.floor(columnWidth / 90) - 2);
        // Calculate interval between timestamps
        const interval = duration / (numTimestampsToShow - 1);
        // Generate timestamps with zero in beginning, duraton in end, and intervals in between
        return [0, ...Array.from(Array(numTimestampsToShow - 2), (_, i) => interval * (i + 1)), duration];
      }
      return [];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), isLoading]);

  // This logic is present here instead of in the parent component due to
  // performance, since this approach provides a smooth scrolling experience
  const handleScroll = useCallback(
    (e: Event) => {
      if ((e.target as HTMLElement).scrollLeft === 0) setIsScrolling(false);
      else setIsScrolling(true);

      const { scrollTop } = e.target as HTMLElement;
      const top =
        isSharedOpen && commonTags.flat().length > 0
          ? Styled.TIMELINE_LINES_SHARED_OFFSET_TOP
          : isSharedOpen
          ? Styled.TIMELINE_LINES_EMPTY_SHARED_OFFSET_TOP
          : Styled.TIMELINE_LINES_DIFFERENCES_OFFSET_TOP;

      // Sets the timelines lines for the videos on scroll, stopping them
      // from going behing the top elements
      if (timelineLinesRef.current) {
        if (scrollTop >= remToPixels(top)) {
          timelineLinesRef.current.style.top = `${scrollTop}px`;
          timelineLinesRef.current.style.height = `calc(100% - ${scrollTop}px)`;
        } else {
          timelineLinesRef.current.style.top = `${top}rem`;
        }

        // Hides the timelines lines once they start to appear behind the fixed column
        Array.from(timelineLinesRef.current.children).forEach((element, index) => {
          const lineOffset = (element.children[0] as HTMLElement).offsetLeft;
          const { scrollLeft } = e.target as HTMLElement;

          let isHidden = false;
          if (scrollLeft > 0) {
            isHidden =
              scrollLeft + remToPixels(Styled.TIMELINE_WIDTH + Styled.PADDING * 2) >=
              index * remToPixels(Styled.TIMELINE_WIDTH + Styled.PADDING * 2) + lineOffset;
          }
          (element as HTMLElement).style.visibility = isHidden ? 'hidden' : 'visible';
        });
      }

      // Sets the timelines lines on the fixed columns on scroll, stopping them
      // from going behing the top elements
      if (timelineLineFixedRef.current) {
        if (scrollTop >= remToPixels(top)) {
          timelineLineFixedRef.current.style.top = `${scrollTop}px`;
          timelineLineFixedRef.current.style.height = `calc(100% - ${scrollTop}px)`;
        } else {
          timelineLineFixedRef.current.style.top = `${top}rem`;
        }
      }
    },
    [commonTags, isSharedOpen]
  );

  useDeepCompareEffect(() => {
    const container = document.getElementById('compare-container');
    container?.addEventListener('scroll', handleScroll, { passive: true });
    return () => container?.removeEventListener('scroll', handleScroll);
  }, [handleScroll, isSharedOpen, isDifferentOpen, commonTags]);

  useDeepCompareEffect(() => {
    if (timelineLinesRef.current) {
      const top = isSharedOpen && commonTags.flat().length > 0 ? '7.5rem' : isSharedOpen ? '23.5rem' : '12rem';
      timelineLinesRef.current.style.top = top;
    }
  }, [isSharedOpen, isDifferentOpen, commonTags]);

  // No timeline lines are shown if both sections are closed, if there
  // are no tags to show in any of the sections or if an empty section
  // is open and the other one is closed
  const hideTimelineLines =
    (!isSharedOpen && !isDifferentOpen) ||
    (isSharedOpen && commonTags.flat().length === 0 && isDifferentOpen && timelines[0]?.length === 0) ||
    (isSharedOpen && commonTags.flat().length === 0 && !isDifferentOpen) ||
    (isDifferentOpen && timelines[0]?.length === 0 && !isSharedOpen);

  const sharedTagsEl =
    commonTags.flat().length > 0 || isLoading ? (
      <Styled.GridContainer>
        {[...Array.from(Array(numVideos).keys())].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Styled.ColumnContainer key={`shared-${assetIds[i] || i}`}>
            <Styled.Timestamps>
              <TimelineTimestamps timestampsInSeconds={videosTimestampsInSeconds[i] ?? []} />
            </Styled.Timestamps>
            <TimelineBody
              data={commonTags[i] || []}
              loading={isLoading || !visibleTags}
              videoDurationInSeconds={data[i]?.duration ? Number(data[i]?.duration) : 0}
              recommendationsOnly={false}
              groupByTagCategory={false}
              showDividers={false}
              showTimelineTimestampLine={false}
              showTypeAndValue
              showRecommendationsOnTop={false}
              showDifference={i !== 0}
              customTrackColorType="BlueGray"
              seekToPoint={(point: number) => onSeekToPoint(point)}
            />
          </Styled.ColumnContainer>
        ))}
      </Styled.GridContainer>
    ) : (
      <Styled.EmptyStateWrapper>
        <EmptyState
          icon="Search"
          text="No shared tags"
          supportingText="The videos are entirely different or you need more custom tags"
        />
      </Styled.EmptyStateWrapper>
    );

  const differentTagsEl =
    timelines[0]?.length > 0 || isLoading ? (
      <Styled.GridContainer>
        {timelines.map((timeline, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Styled.ColumnContainer key={`different-${assetIds[i] || i}`}>
            <Styled.Timestamps>
              <TimelineTimestamps timestampsInSeconds={videosTimestampsInSeconds[i] ?? []} />
            </Styled.Timestamps>
            <TimelineBody
              data={timeline}
              loading={isLoading || !visibleTags}
              videoDurationInSeconds={data[i]?.duration ? Number(data[i]?.duration) : 0}
              recommendationsOnly={false}
              groupByTagCategory={false}
              showDividers={false}
              showTimelineTimestampLine={false}
              showTypeAndValue
              showRecommendationsOnTop={false}
              showEmptyTracks={i === 0}
              showDifference={i !== 0}
              showFake={i === 0}
              seekToPoint={(point: number) => onSeekToPoint(point)}
            />
          </Styled.ColumnContainer>
        ))}
      </Styled.GridContainer>
    ) : (
      <Styled.EmptyStateWrapper>
        <EmptyState
          icon="Search"
          text="No different tags"
          supportingText="The videos are equal or most likely you need new custom tags"
        />
      </Styled.EmptyStateWrapper>
    );

  // The column that will become fixed when the page is horizontally scrolled
  const fixedCol = (
    <Styled.Absolute style={{ display: isScrolling ? 'block' : 'none' }} ref={fixedColRef}>
      <Styled.FixedContainer>
        <Accordion
          type="single"
          collapsible
          colorSchema="BlueGray"
          value={isSharedOpen ? 'Shared Tags' : ''}
          items={[
            {
              title: 'Shared Tags',
              content: commonTags[0]?.length ? (
                <Styled.GridContainerFixed>
                  <Styled.ColumnContainer>
                    <Styled.Timestamps>
                      <TimelineTimestamps timestampsInSeconds={videosTimestampsInSeconds[0] ?? []} />
                    </Styled.Timestamps>
                    <TimelineBody
                      data={commonTags[0]}
                      loading={isLoading || !visibleTags}
                      videoDurationInSeconds={data[0]?.duration ? Number(data[0]?.duration) : 0}
                      recommendationsOnly={false}
                      groupByTagCategory={false}
                      showDividers={false}
                      showTimelineTimestampLine={false}
                      showTypeAndValue
                      showRecommendationsOnTop={false}
                      customTrackColorType="BlueGray"
                      seekToPoint={(point: number) => onSeekToPoint(point)}
                    />
                  </Styled.ColumnContainer>
                </Styled.GridContainerFixed>
              ) : (
                <Styled.Empty />
              ),
              icon: {
                name: 'Tag',
                type: IconType.BASE,
              },
            },
          ]}
          onValueChange={(value) => setIsSharedOpen(value === 'Shared Tags')}
        />
        <Styled.Border />
        <Accordion
          type="single"
          collapsible
          colorSchema="Primary"
          value={isDifferentOpen ? 'Differences Detected' : ''}
          items={[
            {
              title: 'Differences Detected',
              content:
                timelines[0]?.length > 0 ? (
                  <Styled.GridContainerFixed>
                    <Styled.ColumnContainer>
                      <Styled.Timestamps>
                        <TimelineTimestamps timestampsInSeconds={videosTimestampsInSeconds[0] ?? []} />
                      </Styled.Timestamps>
                      <TimelineBody
                        data={timelines[0] ?? []}
                        loading={isLoading || !visibleTags}
                        videoDurationInSeconds={data[0]?.duration ? Number(data[0]?.duration) : 0}
                        recommendationsOnly={false}
                        groupByTagCategory={false}
                        showDividers={false}
                        showTimelineTimestampLine={false}
                        showTypeAndValue
                        showRecommendationsOnTop={false}
                        showEmptyTracks
                        showFake
                        seekToPoint={(point: number) => onSeekToPoint(point)}
                      />
                    </Styled.ColumnContainer>
                  </Styled.GridContainerFixed>
                ) : (
                  <Styled.Empty />
                ),
              icon: {
                name: 'Sparkles',
                type: IconType.BASE,
              },
            },
          ]}
          onValueChange={(value) => setIsDifferentOpen(value === 'Differences Detected')}
        />
      </Styled.FixedContainer>
    </Styled.Absolute>
  );

  return (
    <Styled.AccordionContainer>
      {isLoading || hideTimelineLines ? null : (
        <Styled.TimestampLines
          ref={timelineLinesRef}
          isSharedOpen={isSharedOpen}
          isSharedEmpty={commonTags.flat().length === 0}
        >
          {[...Array.from(Array(numVideos).keys())].map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Styled.TimestampCol key={assetIds[i] || i} index={i}>
              <TimelineTimestampLine
                playedSeconds={playersProgress[assetIds[i]]?.playedSeconds ?? 0}
                playedFraction={playersProgress[assetIds[i]]?.played ?? 0}
                color={i === 0 ? Colors.Gray[900] : Colors.Gray[500]}
              />
            </Styled.TimestampCol>
          ))}
        </Styled.TimestampLines>
      )}
      {isScrolling ? (
        <Styled.AbsoluteTimestampLine
          ref={timelineLineFixedRef}
          isSharedOpen={isSharedOpen}
          isSharedEmpty={commonTags.flat().length === 0}
        >
          <Styled.TimestampLine>
            <Styled.TimestampCol index={0}>
              <TimelineTimestampLine
                playedSeconds={playersProgress[assetIds[0]]?.playedSeconds ?? 0}
                playedFraction={playersProgress[assetIds[0]]?.played ?? 0}
              />
            </Styled.TimestampCol>
          </Styled.TimestampLine>
        </Styled.AbsoluteTimestampLine>
      ) : null}
      {fixedCol}
      <Accordion
        type="single"
        collapsible
        colorSchema="BlueGray"
        value={isSharedOpen ? 'Shared Tags' : ''}
        items={[
          {
            title: 'Shared Tags',
            content: sharedTagsEl,
            icon: {
              name: 'Tag',
              type: IconType.BASE,
            },
          },
        ]}
        onValueChange={(value) => setIsSharedOpen(value === 'Shared Tags')}
      />
      <Accordion
        type="single"
        collapsible
        colorSchema="Primary"
        value={isDifferentOpen ? 'Differences Detected' : ''}
        items={[
          {
            title: 'Differences Detected',
            content: differentTagsEl,
            icon: {
              name: 'Sparkles',
              type: IconType.BASE,
            },
          },
        ]}
        onValueChange={(value) => setIsDifferentOpen(value === 'Differences Detected')}
      />
    </Styled.AccordionContainer>
  );
};

export default TagsTimeline;
