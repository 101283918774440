import { IconProps } from './types';

const Square = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default Square;
