import { PaymentIconProps } from './types';

const JCB = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      d="M29.309 15.9391C30.0174 15.9545 30.7298 15.9078 31.4353 15.9633C32.1498 16.0966 32.322 17.1782 31.6872 17.5325C31.2541 17.7659 30.7396 17.6194 30.2701 17.6606H29.309V15.9391ZM31.8449 13.9905C32.0022 14.546 31.4668 15.0448 30.9318 14.9683H29.309C29.3204 14.4444 29.2868 13.8758 29.3255 13.3796C29.9756 13.3979 30.6316 13.3422 31.2779 13.4087C31.5556 13.4784 31.788 13.7067 31.8449 13.9905ZM35.7501 5.75272C35.7803 6.81358 35.7545 7.9305 35.7631 9.01287C35.7611 13.4134 35.7674 17.8138 35.7597 22.2146C35.7312 23.8638 34.2696 25.2968 32.6318 25.3297C30.9924 25.3364 29.3528 25.3308 27.7132 25.3326V18.6799C29.4996 18.6707 31.287 18.6986 33.0727 18.666C33.901 18.614 34.8084 18.0674 34.847 17.1558C34.9445 16.2402 34.0813 15.6069 33.2618 15.5069C32.9468 15.4987 32.956 15.4151 33.2618 15.3786C34.0434 15.2097 34.6572 14.4007 34.4273 13.5905C34.2313 12.7383 33.2894 12.4085 32.506 12.4101C30.9087 12.3993 29.311 12.4086 27.7136 12.4055C27.7239 11.1635 27.692 9.92018 27.7307 8.67912C27.8573 7.05968 29.3557 5.72417 30.9704 5.75315C32.5639 5.75291 34.157 5.75291 35.7501 5.75285V5.75272Z"
      fill="url(#paint0_linear_1142_85001)"
    />
    <path
      d="M9.66163 8.84678C9.70242 7.20019 11.1703 5.77891 12.8059 5.75484C14.4393 5.74981 16.0728 5.75411 17.7061 5.75266C17.7016 11.2618 17.715 16.7716 17.6994 22.2804C17.6365 23.907 16.1847 25.3012 14.5668 25.33C12.9304 25.3359 11.2938 25.3308 9.65733 25.3325V18.4555C11.2469 18.831 12.9138 18.9909 14.5354 18.7418C15.5047 18.5858 16.5653 18.1098 16.8934 17.1042C17.1349 16.244 16.999 15.3386 17.0349 14.4558V12.4054H14.2286C14.2159 13.7615 14.2544 15.1199 14.2082 16.4743C14.1326 17.3068 13.3081 17.8358 12.523 17.8075C11.5492 17.8178 9.61962 17.1018 9.61962 17.1018C9.61477 14.5611 9.64787 11.3793 9.66163 8.84702V8.84678Z"
      fill="url(#paint1_linear_1142_85001)"
    />
    <path
      d="M18.7525 13.2325C18.6048 13.2638 18.7228 12.7293 18.6851 12.5265C18.6951 11.2445 18.664 9.96106 18.7022 8.67993C18.8284 7.05364 20.3383 5.7148 21.9596 5.75287H26.7342C26.7297 11.262 26.743 16.7718 26.7275 22.2806C26.6645 23.9072 25.2126 25.3013 23.5947 25.3302C21.9582 25.3364 20.3216 25.3312 18.685 25.3327V17.7981C19.8027 18.7151 21.3218 18.8579 22.7143 18.8604C23.764 18.86 24.8077 18.6982 25.8271 18.4561V17.0757C24.6782 17.6483 23.3277 18.012 22.0542 17.683C21.1658 17.4619 20.5209 16.6033 20.5352 15.6865C20.4323 14.7331 20.9912 13.7265 21.9281 13.443C23.0914 13.0788 24.3593 13.3574 25.4497 13.8308C25.6833 13.9532 25.9205 14.1049 25.827 13.7144V12.6294C24.0033 12.1955 22.0625 12.0359 20.2303 12.5079C19.7 12.6575 19.1833 12.8844 18.7525 13.2326V13.2325Z"
      fill="url(#paint2_linear_1142_85001)"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1142_85001"
        x1="27.6582"
        y1="15.124"
        x2="35.7124"
        y2="15.124"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58B03A" />
        <stop offset="1" stopColor="#55B330" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1142_85001"
        x1="9.48608"
        y1="15.7301"
        x2="17.5742"
        y2="15.7301"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0F6EB6" />
        <stop offset="1" stopColor="#006DBA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1142_85001"
        x1="18.6732"
        y1="15.3138"
        x2="26.7327"
        y2="15.3138"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DE0D3D" />
        <stop offset="1" stopColor="#E30138" />
      </linearGradient>
    </defs>
  </svg>
);
export default JCB;
