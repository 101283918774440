import { CommonTagIconProps } from '../types';

const BrightLuminosity = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 16.4421C14.4532 16.4421 16.442 14.4534 16.442 12.0001C16.442 9.54686 14.4532 7.55811 12 7.55811C9.54673 7.55811 7.55798 9.54686 7.55798 12.0001C7.55798 14.4534 9.54673 16.4421 12 16.4421Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12 2.66309V4.37109"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12 19.6292V21.3372"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M2.66296 12H4.37096"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.6289 12H21.3369"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M5.39795 5.39819L6.60495 6.60519"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.3949 17.3953L18.6019 18.6023"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M5.39795 18.6023L6.60495 17.3953"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.3949 6.60519L18.6019 5.39819"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12 8.14917V15.7132"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default BrightLuminosity;
