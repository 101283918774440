import { CountryIconProps } from './types';

const ST = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3755)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FFDA44"
      />
      <path
        d="M7.30486 7.82623H23.2541C21.5586 3.25662 17.16 0.00012207 12.0005 0.00012207C8.68673 0.00012207 5.68706 1.34351 3.51562 3.51523L7.30486 7.82623Z"
        fill="#6DA544"
      />
      <path
        d="M7.30388 16.1746H23.2531C21.5576 20.7442 17.1591 24.0007 11.9995 24.0007C8.68576 24.0007 5.68609 22.6573 3.51465 20.4856L7.30388 16.1746Z"
        fill="#6DA544"
      />
      <path
        d="M3.51471 3.51379C-1.17157 8.20008 -1.17157 15.7981 3.51471 20.4844C5.45126 18.5479 7.31375 16.6854 12 11.9991L3.51471 3.51379Z"
        fill="#D80027"
      />
      <path
        d="M15.2383 9.91278L15.7563 11.5071H17.4327L16.0765 12.4924L16.5945 14.0867L15.2383 13.1014L13.8821 14.0867L14.4001 12.4924L13.0439 11.5071H14.7203L15.2383 9.91278Z"
        fill="black"
      />
      <path
        d="M20.4561 9.91278L20.9741 11.5071H22.6504L21.2943 12.4924L21.8123 14.0867L20.4561 13.1014L19.0999 14.0867L19.6179 12.4924L18.2617 11.5071H19.9381L20.4561 9.91278Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3755">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ST;
