import * as SDK from '@replai-platform/sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ChartValue, LineChart, LoadingBars } from '@replai-platform/ui-components';
import { kpiUtils } from '@replai-platform/sdk';
import { LoadingContainer } from '../../../../../../utils/styles';
import { RootState } from '../../../../../../store/rootReducer';
import { api } from '../../../../../../api/index';
import useMetricsHistory from '../../../../../../api/hooks/metrics/useMetricsHistory';

interface FatigueTrendProps {
  tag: SDK.Tag;
  selectedKpi: SDK.MetricKPIWithSpend;
}

const FatigueTrend = ({ tag, selectedKpi }: FatigueTrendProps) => {
  const filters = useSelector((state: RootState) => state.filters);
  const projectId = useSelector((state: RootState) => state.project.id);

  const lines = [
    {
      name: `${
        selectedKpi === SDK.Metrics.SPEND ? 'total' : SDK.kpiConfig[selectedKpi]?.isSummable ? 'total' : 'average'
      } ${kpiUtils.getDisplayName(selectedKpi)}`,
      accessor: `global${selectedKpi}`,
    },
    {
      name: `tag ${kpiUtils.getDisplayName(selectedKpi)}`,
      accessor: `tag${selectedKpi}`,
    },
  ];

  const getFatigueTrendTagParams = useMemo(
    () => ({
      projectIds: [projectId],
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      tagsFilters: { tagsToConsider: [tag] },
      metrics: [selectedKpi],
    }),
    [JSON.stringify(filters), projectId, tag, selectedKpi]
  );

  const { data: fatigueTrendTagData, isLoading: fatigueTrendTagIsLoading } =
    useMetricsHistory(getFatigueTrendTagParams);

  const getFatigueTrendGlobalParams = useMemo(
    () => ({
      projectIds: [projectId],
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      metrics: [selectedKpi],
    }),
    [JSON.stringify(filters), projectId, selectedKpi]
  );

  const { data: fatigueTrendGlobalData, isLoading: fatigueTrendGlobalIsLoading } =
    useMetricsHistory(getFatigueTrendGlobalParams);

  const chartData = useMemo(
    () =>
      fatigueTrendTagData?.history?.map(
        (tagEntry) =>
          ({
            name: tagEntry.date,
            [`tag${selectedKpi}`]: tagEntry.metrics[selectedKpi],
            [`global${selectedKpi}`]: fatigueTrendGlobalData?.history?.find(
              (globalEntry) => globalEntry.date === tagEntry.date
            )?.metrics[selectedKpi],
          } as ChartValue)
      ),
    [JSON.stringify(fatigueTrendTagData), JSON.stringify(fatigueTrendGlobalData), selectedKpi]
  );

  if (fatigueTrendTagIsLoading || !fatigueTrendTagData || fatigueTrendGlobalIsLoading || !fatigueTrendGlobalData) {
    return (
      <LoadingContainer>
        <LoadingBars barSize="xxl" />
      </LoadingContainer>
    );
  }

  return (
    <LineChart
      data-test="chart"
      data={chartData}
      yValueFormatter={(v) => kpiUtils.format(selectedKpi, v)}
      xValueFormatter={(s) =>
        new Date(s).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
        })
      }
      lines={lines}
      yLabel={kpiUtils.getDisplayName(selectedKpi)}
    />
  );
};

export default FatigueTrend;
