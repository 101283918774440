import styled from 'styled-components';

export const Root = styled.div<{
  $width?: string;
  $height?: string;
  $backgroundColor?: string;
  $borderColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.$width ? props.$width : '24px')};
  height: ${(props) => (props.$height ? props.$height : '24px')};
  padding: 0.188rem;
  border-style: solid;
  border-width: 0.063rem;
  border-radius: 50%;
  background-color: ${(props) => props.$backgroundColor};
  border-color: ${(props) => props.$borderColor};
`;
