const SQUARE_ORIENTATION_ERROR_MARGIN_RATIO = 1.025;

// given a width and height, returns the orientation
export const getOrientation = ({
  width,
  height,
}: {
  width: number;
  height: number;
}): string => {
  if (
    Math.max(Number(width) / Number(height), Number(height) / Number(width)) <
    SQUARE_ORIENTATION_ERROR_MARGIN_RATIO
  ) {
    return 'square';
  }
  return Number(width) > Number(height) ? 'landscape' : 'portrait';
};
