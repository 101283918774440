import { WYSIWYGProps } from './types';

const MDLink = ({ color }: WYSIWYGProps) => (
  <svg
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.9125 5C1.9125 3.50375 3.12875 2.2875 4.625 2.2875H8.125V0.625H4.625C2.21 0.625 0.25 2.585 0.25 5C0.25 7.415 2.21 9.375 4.625 9.375H8.125V7.7125H4.625C3.12875 7.7125 1.9125 6.49625 1.9125 5ZM5.5 5.875H12.5V4.125H5.5V5.875ZM13.375 0.625H9.875V2.2875H13.375C14.8713 2.2875 16.0875 3.50375 16.0875 5C16.0875 6.49625 14.8713 7.7125 13.375 7.7125H9.875V9.375H13.375C15.79 9.375 17.75 7.415 17.75 5C17.75 2.585 15.79 0.625 13.375 0.625Z"
      fill={color || '#98A2B3'}
    />
  </svg>
);
export default MDLink;
