import { Typography } from '@material-ui/core';
import { Colors } from '@replai-platform/ui-components';
import styled from 'styled-components';

export const TagCategoryContainer = styled.div`
  margin-top: 20px;
  padding: 0.5rem;
  border-bottom: 1px solid #e4e7ec;
`;

export const Name = styled(Typography)`
  display: flex;
  padding: 0;
  color: ${Colors.Gray[900]};
  font-weight: 500;
  font-size: 16px;
`;

export const TagEntryContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  height: fit-content;
  margin: 0.3rem 0;
`;

export const TagThumbnailContainer = styled.div`
  width: fit-content;
  height: fit-content;
`;

export const TagThumbnailImg = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 6px;
`;

export const TagNameContainer = styled.div`
  width: 100%;
  height: fit-content;
`;
