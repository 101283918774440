/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTitle } from 'react-use';
import FilterBar from '../../../components/FilterBar';
import { FiltersContainer } from '../../../components/FilterBar/common/styles';
import { CustomTagsFilterProps } from '../../../components/FilterBar/Filters/CustomTagsFilter';
import type { NetworkFilterProps } from '../../../components/FilterBar/Filters/NetworkFilter';
import type { PromotedObjectFilterProps } from '../../../components/FilterBar/Filters/PromotedObjectFilter';
import useCountryFilter from '../../../components/FilterBar/hooks/useCountryFilter';
import useCustomTagsFilter from '../../../components/FilterBar/hooks/useCustomTagsFilter';
import useNetworkFilter from '../../../components/FilterBar/hooks/useNetworkFilter';
import usePromotedObjectFilter from '../../../components/FilterBar/hooks/usePromotedObjectFilter';
import TopNavPageTitle from '../../../components/TopNavPageTitle';
import { CountryFilterProps } from '../../../components/FilterBar/Filters/CountryFilter';
import type { RootState } from '../../../store/rootReducer';
import { FilterActions } from '../../../store/filters';
import { Page } from '../../../utils/enums';
import { RouteAnimator } from '../../RouteAnimator';
import SocialsTable from './SocialsTable';

const SocialsPerformanceView: React.VFC = () => {
  const dispatch = useDispatch();
  const projectName = useSelector((state: RootState) => state.project.name);
  const countries = useSelector((state: RootState) => state.filters.countries);

  useTitle(`Social Posts - ${projectName}`);

  // Build network filter
  const {
    networkFilterOptions,
    networkFilterOnChange,
    isLoading: isNetworkFilterLoading,
  } = useNetworkFilter(Page.Videos);
  const networkFilter = useMemo(
    () =>
      ({
        options: networkFilterOptions,
        onChange: networkFilterOnChange,
        loading: isNetworkFilterLoading,
      } as NetworkFilterProps),
    [networkFilterOptions, networkFilterOnChange, isNetworkFilterLoading]
  );

  // Build promoted object types filter
  const { promotedObjectTypeFilterOptions, promotedObjectTypeFilterOnChange } = usePromotedObjectFilter(Page.Videos);
  const promotedObjectFilter = useMemo(
    () =>
      ({
        options: promotedObjectTypeFilterOptions,
        onChange: promotedObjectTypeFilterOnChange,
        loading: false,
      } as PromotedObjectFilterProps),
    [promotedObjectTypeFilterOnChange, promotedObjectTypeFilterOptions]
  );

  // Build tagged with filter
  const {
    customTagsFilterOptions,
    customTagsFilterOnChange,
    isLoading: isCustomTagsFilterLoading,
  } = useCustomTagsFilter('default');
  const customTagsFilter: CustomTagsFilterProps = useMemo(
    () => ({
      options: customTagsFilterOptions,
      onChange: customTagsFilterOnChange,
      loading: isCustomTagsFilterLoading,
    }),
    [customTagsFilterOnChange, customTagsFilterOptions, isCustomTagsFilterLoading]
  );

  const {
    countryFilterOptions,
    isLoading: countryFilterIsLoading,
    setIsInitialFilter,
    isInitialFilter,
  } = useCountryFilter({
    selectedCountries: countries,
  });
  const countryFilter = useMemo(
    () =>
      ({
        options: countryFilterOptions,
        onChange: (options) => {
          dispatch(
            FilterActions.changeCountries({
              value: options
                .filter(({ isAllOption }) => !isAllOption)
                .filter(({ selected }) => selected)
                .map(({ id }) => id)
                .filter((id): id is string => id !== undefined),
              logEvent: !isInitialFilter,
            })
          );

          setIsInitialFilter(false);
        },
        loading: countryFilterIsLoading,
      } as CountryFilterProps),
    [dispatch, countryFilterIsLoading, countryFilterOptions, isInitialFilter, setIsInitialFilter]
  );

  return (
    <RouteAnimator data-test="videos-container">
      <TopNavPageTitle
        title="Socials Performance"
        subtitle="Analyze your videos posted across social networks, comparing performance on the KPIs relevant to you."
      />
      <FiltersContainer data-test="videos-filters-container">
        <FilterBar
          eventPrefix="videos"
          supportedFilters={[]}
          countryFilter={countryFilter}
          networkFilter={networkFilter}
          promotedObjectFilter={promotedObjectFilter}
          customTagsFilter={customTagsFilter}
          withAgeFilter
          withAddFilterButton={false}
        />
      </FiltersContainer>
      <SocialsTable filtersLoading={isNetworkFilterLoading} />
    </RouteAnimator>
  );
};

export default SocialsPerformanceView;
