import { IntegrationIconProps } from './types';

const Steam = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.56967 20.0269C4.30041 25.7964 9.65423 30 15.9906 30C23.7274 30 29.9995 23.7318 29.9995 16C29.9995 8.26803 23.7274 2 15.9906 2C8.56634 2 2.49151 7.77172 2.01172 15.0699C2.01172 17.1667 2.01172 18.0417 2.56967 20.0269Z"
      fill="url(#paint0_linear_1334_1793)"
    />
    <path
      d="M15.2706 12.5628L11.8426 17.5394C11.0345 17.5026 10.221 17.7313 9.54572 18.1751L2.01829 15.0783C2.01829 15.0783 1.84411 17.9419 2.56999 20.0762L7.89147 22.2705C8.15866 23.4638 8.97779 24.5105 10.1863 25.014C12.1635 25.8396 14.4433 24.8987 15.2658 22.9219C15.4799 22.4051 15.5797 21.8631 15.5652 21.3224L20.5904 17.8218C23.5257 17.8218 25.9114 15.4304 25.9114 12.4936C25.9114 9.55661 23.5257 7.16736 20.5904 7.16736C17.7553 7.16736 15.1117 9.64113 15.2706 12.5628ZM14.4469 22.5782C13.8103 24.1056 12.054 24.8302 10.5273 24.1945C9.82302 23.9013 9.29128 23.3641 8.98452 22.7236L10.7167 23.441C11.8426 23.9097 13.1343 23.3761 13.6023 22.2513C14.0718 21.1253 13.5392 19.8322 12.4139 19.3636L10.6233 18.6221C11.3142 18.3601 12.0997 18.3505 12.8336 18.6558C13.5734 18.9634 14.1475 19.5426 14.4517 20.2829C14.756 21.0231 14.7548 21.8403 14.4469 22.5782ZM20.5904 16.0432C18.6364 16.0432 17.0455 14.451 17.0455 12.4936C17.0455 10.5378 18.6364 8.94506 20.5904 8.94506C22.5457 8.94506 24.1365 10.5378 24.1365 12.4936C24.1365 14.451 22.5457 16.0432 20.5904 16.0432ZM17.9341 12.4882C17.9341 11.0157 19.127 9.82147 20.5964 9.82147C22.0671 9.82147 23.2599 11.0157 23.2599 12.4882C23.2599 13.9608 22.0671 15.154 20.5964 15.154C19.127 15.154 17.9341 13.9608 17.9341 12.4882Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_1793"
        x1="16.0056"
        y1="2"
        x2="16.0056"
        y2="30"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#111D2E" />
        <stop offset="0.21248" stopColor="#051839" />
        <stop offset="0.40695" stopColor="#0A1B48" />
        <stop offset="0.5811" stopColor="#132E62" />
        <stop offset="0.7376" stopColor="#144B7E" />
        <stop offset="0.87279" stopColor="#136497" />
        <stop offset="1" stopColor="#1387B8" />
      </linearGradient>
    </defs>
  </svg>
);
export default Steam;
