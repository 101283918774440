import styled from 'styled-components';
import { default as DefaultPagination } from '../Pagination/Pagination';

export const PaginationWrapper = styled.div`
  padding: 1.125rem 1.5rem;
`;

export const Pagination = styled(DefaultPagination)`
  display: flex;
  justify-content: space-between;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.5rem;
`;

export const TotalText = styled.span`
  display: flex;
  gap: 0.3rem;
`;
