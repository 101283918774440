import { CountryIconProps } from './types';

const GT = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3632)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.9994 12.0002C23.9994 7.24267 21.2307 3.13192 17.2168 1.19092V22.8094C21.2307 20.8685 23.9994 16.7577 23.9994 12.0002Z"
        fill="#338AF3"
      />
      <path
        d="M0 12.0001C0 16.7576 2.76867 20.8684 6.78262 22.8094V1.1908C2.76867 3.1318 0 7.24255 0 12.0001Z"
        fill="#338AF3"
      />
      <path
        d="M15.135 14.0284L13.106 11.9994L15.0374 10.0679L14.9499 9.04778L14.3969 8.49475L11.9992 10.8925L9.60142 8.49475L9.04848 9.04778L8.96097 10.0679L10.8924 11.9994L8.86328 14.0284L9.97009 15.1351L11.9992 13.1061L14.0282 15.1351L15.135 14.0284Z"
        fill="#ACABB1"
      />
      <path
        d="M14.9515 9.04895L13.8447 10.1557C14.3168 10.6278 14.6088 11.28 14.6088 12.0003C14.6088 13.4411 13.4408 14.609 12.0001 14.609C10.5594 14.609 9.39138 13.4411 9.39138 12.0003C9.39138 11.28 9.68341 10.6278 10.1555 10.1557L9.04872 9.04895C8.29333 9.8042 7.82617 10.8477 7.82617 12.0003C7.82617 14.3056 9.69489 16.1742 12.0001 16.1742C14.3052 16.1742 16.174 14.3055 16.174 12.0003C16.174 10.8477 15.7068 9.80425 14.9515 9.04895Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3632">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default GT;
