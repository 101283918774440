/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as SDK from '@replai-platform/sdk';
import { logEvent } from '../../analytics';
import gtag from '../../analytics/gtag';

export type User = Pick<SDK.User, 'id' | 'email' | 'name' | 'surname'> & { status?: string };
interface AuthState extends User {
  enabled?: boolean; // TODO: This is still in progress
}

const initialState: AuthState = {
  // @ts-expect-error enabled strictNullChecks without fixing all errors in detail
  id: null,
  // @ts-expect-error enabled strictNullChecks without fixing all errors in detail
  email: null,
  name: undefined,
  surname: undefined,
  status: undefined,
};

const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<AuthState>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.email = action.payload.email;

      // analytics
      gtag('set', {
        user_id: action.payload.id,
      });

      gtag('set', 'user_properties', {
        user_id: action.payload.id,
      });

      logEvent({
        component: 'Authentication',
        action: 'User Sign in',
        category: 'engagement',
      });
    },
    clearUser: () => initialState,
  },
});

export const AuthActions = AuthSlice.actions;
export const AuthReducer = AuthSlice.reducer;
