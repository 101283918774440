import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';
import { TwoColumnLayout } from '../shared/TwoColumnLayout';

const UNITY_FORM_URL = 'https://docs.google.com/forms/d/1hQLqkx_5CieVNqx3MmKL17B-jjgbk8QHOwUkdUGfupM/viewform';

export const UnityConnectionGuide: NetworkConnectionGuideContent = {
  title: 'Unity Connection Guide',
  icon: 'Unity',
  guide: {
    label: 'Connection Guide',
    steps: [
      {
        title: 'Add member.',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              {
                item: (
                  <>
                    Access the{' '}
                    {Shared.link({
                      label: 'Unity Ads dashboard',
                      url: 'https://dashboard.unity3d.com/',
                    })}
                    .
                  </>
                ),
              },
              {
                item: `Click your profile icon on the top-right corner and then click "Manage organization". Ensure that you've selected a project that belongs to the organization you wish to share with Replai.`,
              },
              {
                item: 'Using the project switcher on the top-left corner, repeat the steps below for each project you wish to share with Replai.',
              },
              {
                item: (
                  <>
                    Click &quot;Organization Members&quot; on the left navigation bar and then click &quot;Invite
                    users&quot;. Add the user &quot;<Shared.NoWrap>replai@replai.io</Shared.NoWrap>&quot; under the role
                    &quot;User&quot;.
                  </>
                ),
              },
              {
                item: 'Switch to the project you wish to share and click the settings gear icon. Then, visit "Project Members" from the left navigation bar and ensure the Replai user is added under the role "User" to that project.',
              },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/unity/step-1.png"
          />
        ),
      },
      {
        title: 'Ad Statistics API',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              {
                item: (
                  <>
                    From the{' '}
                    {Shared.link({
                      label: 'Unity Ads dashboard',
                      url: 'https://dashboard.unity3d.com/',
                    })}
                    , visit the &quot;Growth&quot; - &quot;API Management&quot; page.
                  </>
                ),
              },
              {
                item: 'On this page, notice a section called "Stats API Access". If a key is already present there, copy it and deliver it to Replai as the "Stats API key". If no key is present, click the button to generate one.',
              },
              {
                item: 'Note: if a key is already present, do not generate a new key. This will invalidate the existing key permanently possibly breaking tools built using it.',
              },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/unity/step-2.png"
          />
        ),
      },
      {
        title: 'Ad Management API',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              {
                item: 'Click "Projects" from the left navigation bar and then select the "Service Accounts" page.',
              },
              {
                item: 'Click the "Create service account" button. On the popup, you can choose any name and description for the account, e.g. "Replai".',
              },
              {
                item: (
                  <>
                    On the new page, click the &quot;Create key&quot; button on the right on the &quot;Keys&quot;
                    section. Take note of both the Key ID and Secret Key and share both of those with Replai using{' '}
                    {Shared.link({
                      label: 'this form',
                      url: UNITY_FORM_URL,
                    })}
                    . Note that after you close the dialog you won&apos;t be able to see the secret anymore, so if you
                    lose it just recreate the key.
                  </>
                ),
              },
              {
                item: 'Under the "Roles" section, click "Add organization role" and select the "Advertise API Viewer" option.',
              },
              {
                item: (
                  <>
                    (Optional) If this option isn&apos;t available for your account, email Unity support via
                    <Shared.NoWrap>unityads-support@unity3d.com</Shared.NoWrap>. Unity generally responds within 24h and
                    this access is required for Replai to ingest Unity Ads data.
                  </>
                ),
              },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/unity/step-4.png"
          />
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: [
      {
        title:
          'Unity ads can have multiple creatives associated with them via Creative Packs. How does Replai know which creative the metrics belong to?',
        description: (
          <Shared.UList>
            <Shared.UListItem>
              Unity&apos;s{' '}
              {Shared.link({
                label: 'Ad Statistics API',
                url: 'https://services.docs.unity.com/statistics/v1/index.html#tag/Acquisitions/operation/stats_acquisition',
              })}{' '}
              does not report metrics on the creative level, only on the creative pack level. Because of this, on
              creative packs with multiple creatives, there&apos;s no official way to know exactly which creative the
              metrics belong to.
            </Shared.UListItem>
            <Shared.UListItem>
              In order to still provide creative-level metrics on Unity, Replai handles this in the following way:
              <p> - In creative packs with a single creative, all metrics are simply assigned to that creative;</p>
              <p>
                {' '}
                - In creative packs with multiple creatives, all metrics are assigned to the creative that Replai
                considers to be more relevant, taking into consideration multiple factors such as the creative type
                (i.e. video, playable or static) and aspect ratio;
              </p>
            </Shared.UListItem>
          </Shared.UList>
        ),
      },
      ...Shared.DEFAULT_NETWORK_FAQS,
      {
        title: 'Which tracking settings should I use on MMPs with Unity?',
        description: (
          <Shared.UList>
            <Shared.UListItem>
              In the specific case of Unity, you should ensure Campaign ID and Creative Pack are all correctly
              configured in the tracking URL, otherwise MMP metrics will be ignored.
            </Shared.UListItem>
          </Shared.UList>
        ),
      },
    ],
  },
};
