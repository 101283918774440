import { IntegrationIconProps } from './types';

const Things = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.11832 2H23.798C25.8918 2 27.6041 3.62845 27.6578 5.67089L28 18.6758L27.2532 26.878C27.0923 28.6452 25.5751 30 23.7569 30H8.12255C6.29065 30 4.7668 28.6253 4.62328 26.8433L4 19.104L4.25794 5.69687C4.29744 3.64401 6.01417 2 8.11832 2ZM7.85112 6.63669H23.98C24.555 6.63669 25.0238 7.08688 25.0328 7.64797L25.2228 19.3728C25.2302 19.8238 24.8778 20.2029 24.4171 20.2398L21.3335 20.4864C20.5139 20.552 19.883 21.2201 19.883 22.0226V25.6459C19.883 26.4025 19.2544 27.0159 18.479 27.0159H13.4333C12.6579 27.0159 12.0293 26.4025 12.0293 25.6459V22.0226C12.0293 21.2201 11.3983 20.552 10.5788 20.4864L7.48995 20.2394C7.03144 20.2027 6.6798 19.8267 6.68416 19.3778L6.79815 7.65446C6.80363 7.09081 7.27345 6.63669 7.85112 6.63669Z"
      fill="#2274E5"
    />
    <path
      d="M23.9794 6.63672H7.85059C7.27292 6.63672 6.8031 7.09084 6.79762 7.65449L6.68363 19.3779C6.67927 19.8268 7.03092 20.2027 7.48942 20.2394L10.5782 20.4865C11.3978 20.552 12.0287 21.2202 12.0287 22.0226V22.4006H19.8825V22.0226C19.8825 21.2202 20.5134 20.552 21.333 20.4865L24.4166 20.2398C24.8772 20.203 25.2296 19.8238 25.2223 19.3729L25.0323 7.648C25.0232 7.08691 24.5545 6.63672 23.9794 6.63672Z"
      fill="white"
    />
    <path
      d="M19.883 22.4006V25.6459C19.883 26.4025 19.2544 27.0159 18.479 27.0159H13.4333C12.6579 27.0159 12.0293 26.4025 12.0293 25.6459V22.4006H19.883Z"
      fill="#BEC2C8"
    />
    <path
      d="M12.7746 13.367L11.6777 14.4159C12.7161 15.315 15.043 17.3401 16.0434 18.2477C17.4123 16.1242 20.9867 12.2253 22.6028 10.5413C22.4858 10.4129 22.1728 10.0746 21.8569 9.74927C19.3472 11.7187 16.5406 14.4088 15.451 15.5077L12.7746 13.367Z"
      fill="#2C3B4A"
    />
  </svg>
);
export default Things;
