import styled from 'styled-components';
import { Star0, Star100, Star50 } from '../Icons/Misc';

export interface RatingProps {
  thresholds?: number[];
  value: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NUMBER_OF_STARS = 5;

const NUMBER_OF_THRESHOLDS = 2 * NUMBER_OF_STARS - 1;

const DEFAULT_THRESHOLDS = Array(NUMBER_OF_THRESHOLDS)
  .fill(null)
  .map((_value, index) => (0.5 + index * 0.5) / NUMBER_OF_STARS);

const Rating = ({
  thresholds: inputThresholds,
  value,
  onClick,
}: RatingProps) => {
  const thresholds =
    (inputThresholds ?? []).length === NUMBER_OF_THRESHOLDS
      ? inputThresholds ?? DEFAULT_THRESHOLDS
      : DEFAULT_THRESHOLDS;
  const starScore = thresholds.reduce(
    (stars, percentile) => (value < percentile ? stars : stars + 0.5),
    0.5
  );
  const fullStars = Math.floor(starScore);
  const hasHalfStar = starScore - fullStars > 0;
  const emptyStars = Math.floor(NUMBER_OF_STARS - starScore);
  return (
    <Container data-test="rating" onClick={onClick}>
      {Array.from(Array(fullStars).keys()).map((el, i) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <Star100 key={`star-${i}`} />
      ))}
      {hasHalfStar && <Star50 key={`star-${fullStars}`} />}
      {Array.from(Array(emptyStars).keys()).map((el, i) => (
        <Star0 key={`star-${NUMBER_OF_STARS - emptyStars + i}`} />
      ))}
    </Container>
  );
};

export default Rating;
