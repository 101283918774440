import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';
import { TwoColumnLayout } from '../shared/TwoColumnLayout';

const TIKTOK_CALL_URL = 'https://calendly.com/velizar-stefanovic/15min';
const TIKTOK_FORM_URL =
  'https://docs.google.com/forms/d/1cbFROCzsthMHo_ryU9RbwOixAeAqRMcR4Xa5zUXLTV4/viewform?edit_requested=true';

export const TiktokConnectionGuide: NetworkConnectionGuideContent = {
  title: 'TikTok Connection Guide',
  icon: 'TikTok',
  guide: {
    label: 'Connection Guide',
    steps: [
      {
        title: 'Schedule a call with a Replai team member.',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              {
                item: 'Due to the way the TikTok API works, account access requires synchronous action between you and a Replai team member in order for the access to be correctly setup.',
              },
              {
                item: (
                  <>
                    When you wish to integrate your TikTok account, use{' '}
                    {Shared.link({
                      label: 'this link',
                      url: TIKTOK_CALL_URL,
                    })}{' '}
                    to schedule a quick call where the integration is done. Once you&apos;ve scheduled the call, use{' '}
                    {Shared.link({
                      label: 'this form',
                      url: TIKTOK_FORM_URL,
                    })}
                    .
                  </>
                ),
              },
            ]}
          />
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: [
      {
        title: 'What happens if I delete TikTok ads from the TikTok UI?',
        description: (
          <Shared.UList>
            <Shared.UListItem>
              When you delete ads from the TikTok UI, you won&apos;t lose their metrics from Replai as long as the data
              for those ads was already fetched by Replai. This way you can continue to analyse historical data for your
              account even if those ads no longer exist.
            </Shared.UListItem>
          </Shared.UList>
        ),
      },
      {
        title: 'How long do TikTok metrics take to appear on the Replai UI?',
        description: (
          <Shared.UList>
            <Shared.UListItem>
              TikTok&apos;s API{' '}
              {Shared.link({
                label: 'documentation',
                url: 'https://ads.tiktok.com/marketing_api/docs?id=1738864817670145',
              })}{' '}
              recommends only fetching data after 9 a.m. to ensure the most data accuracy, so it might take up to a
              couple of hours after that before you can see the previous day&apos;s TikTok data on Replai.
            </Shared.UListItem>
          </Shared.UList>
        ),
      },
      ...Shared.DEFAULT_NETWORK_FAQS,
    ],
  },
};
