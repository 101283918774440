import { Colors, ColorTypes } from '..';
import * as Icons from '../Icons';
import * as Styled from './styles';

interface StatusIconProps {
  icon: Icons.FlatSocialIconTypes;
  iconSize?: number;
  rootContainerSize?: number;
  status?: 'default' | 'success' | 'error' | 'neutral';
}

const StatusIcon = ({
  icon,
  iconSize,
  rootContainerSize,
  status = 'default',
}: StatusIconProps) => {
  const Icon = Icons.getFlatIcon(icon);

  let color: ColorTypes = 'Gray';
  switch (status) {
    case 'success':
      color = 'Success';
      break;
    case 'error':
      color = 'Error';
      break;
    case 'neutral':
      color = 'Gray';
      break;
    case 'default':
      color = 'Gray';
      break;
    default:
      throw new Error('Invalid status');
  }
  const colorBase = Colors[color];
  const isDefault = status === 'default';

  return (
    <Styled.Root
      data-test="status-icon"
      $width={rootContainerSize ? `${rootContainerSize}px` : ''}
      $height={rootContainerSize ? `${rootContainerSize}px` : ''}
      $backgroundColor={colorBase[50]}
      $borderColor={colorBase[isDefault ? '300' : '100']}
    >
      <Icon
        dimension={iconSize ?? 12}
        color={colorBase[isDefault ? 400 : 700]}
      />
    </Styled.Root>
  );
};

export default StatusIcon;
export type { StatusIconProps };
