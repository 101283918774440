/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';

import '../common.module.css';
import * as Styled from './styles';

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
  fullWidth?: boolean;
  label?: string;
  hintText?: string;
}

const TextArea = ({
  className,
  error,
  fullWidth,
  hintText,
  label,
  rows = 5,
  ...props
}: TextAreaProps) => (
  <Styled.Root $hasFullWidth={fullWidth}>
    {label && <Styled.Label>{label}</Styled.Label>}
    <Styled.TextArea
      className={`${className || ''}`}
      $error={error}
      rows={rows}
      {...props}
    />
    {hintText && <Styled.Hint $error={error}>{hintText}</Styled.Hint>}
  </Styled.Root>
);

export default TextArea;
export type { TextAreaProps };
