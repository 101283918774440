import { IntegrationIconProps } from './types';

const Envato = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2C8.27812 2 2 8.27812 2 16C2 23.7219 8.27812 30 16 30C23.7219 30 30 23.7219 30 16C30 8.27812 23.7219 2 16 2Z"
      fill="#81B441"
    />
    <path
      d="M21.0383 7.96765C14.2505 8.41669 12.201 14.7493 12.0274 17.9891C12.0274 18.5221 10.3158 17.4432 11.3339 12.7917C11.4212 12.3929 11.3339 11.9167 11.3339 11.9167C7.79904 15.0259 8.10301 20.9046 12.4485 23.2946C15.8171 25.1473 20.1962 23.9683 22.1331 20.5155C24.4069 16.5575 22.3858 8.72558 21.0383 7.96765Z"
      fill="white"
    />
  </svg>
);
export default Envato;
