import { useMutation } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';
import { User } from '../../../store/auth';

const deleteUser = async (requestParams: { userId: User['id']; projectId: SDK.UUID }) => {
  const { userId, projectId } = requestParams;
  const url = `${SDK.BASE_URL()}/users/${userId}`;
  const res = await provider.getJson<object, void>(url, { method: 'DELETE', body: { projectId } });
  return res;
};

export default function useDeleteUser() {
  return useMutation(['user-delete'], (requestParams: { userId: User['id']; projectId: SDK.UUID }) =>
    deleteUser(requestParams)
  );
}
