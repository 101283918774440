import * as SDK from '@replai-platform/sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ChartValue, Colors, LineChart, LoadingBars } from '@replai-platform/ui-components';
import { LoadingContainer } from '../../../../../../utils/styles';
import { RootState } from '../../../../../../store/rootReducer';
import { api } from '../../../../../../api/index';
import useMetricsHistory from '../../../../../../api/hooks/metrics/useMetricsHistory';

interface TagTotalMetricChartProps {
  tag: SDK.Tag;
  baseMetric?: SDK.Metrics;
}

const TagTotalMetricChart = ({ tag, baseMetric: providedBaseMetric }: TagTotalMetricChartProps) => {
  const filters = useSelector((state: RootState) => state.filters);
  const projectId = useSelector((state: RootState) => state.project.id);
  const baseMetric = providedBaseMetric ?? SDK.Metrics.SPEND;

  const lines = [
    {
      name: SDK.kpiUtils.getDisplayName(baseMetric),
      accessor: baseMetric,
      color: Colors.Orange[400],
    },
  ];

  const getSpendTrendParams = useMemo(
    () => ({
      projectIds: [projectId],
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      tagsFilters: { tagsToConsider: [tag] },
      metrics: [baseMetric],
    }),
    [JSON.stringify(filters), projectId, tag]
  );

  const { data: spendTrendData, isLoading: spendTrendIsLoading } = useMetricsHistory(getSpendTrendParams, {
    enabled: !!tag,
  });

  const chartData = useMemo(
    () =>
      spendTrendData?.history?.map(
        (entry) =>
          ({
            name: entry.date,
            [baseMetric]: entry?.metrics[baseMetric],
          } as ChartValue)
      ),
    [JSON.stringify(spendTrendData)]
  );

  if (spendTrendIsLoading || !spendTrendData) {
    return (
      <LoadingContainer>
        <LoadingBars barSize="xxl" />
      </LoadingContainer>
    );
  }

  return (
    <LineChart
      data-test="chart"
      data={chartData}
      yValueFormatter={(v) => SDK.kpiUtils.format(baseMetric, v)}
      xValueFormatter={(s) =>
        new Date(s).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
        })
      }
      lines={lines}
      gradientColor={Colors.Orange[400]}
      yLabel={SDK.kpiUtils.getDisplayName(baseMetric)}
      showLegend={false}
    />
  );
};

export default TagTotalMetricChart;
