import { CommonTagIconProps } from '../types';

const AspectRatio9By16 = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4801 21.2442H16.6821C16.9681 21.2442 17.2001 21.0122 17.2001 20.7262V18.5242"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.2001 5.4761V3.2741C17.2001 2.9881 16.9681 2.7561 16.6821 2.7561H14.4801"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.80005 18.5242V20.7262C6.80005 21.0122 7.03205 21.2442 7.31805 21.2442H9.52005"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.52005 2.7561H7.31905C7.03205 2.7561 6.80005 2.9881 6.80005 3.2741V5.4761"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default AspectRatio9By16;
