import { IntegrationIconProps } from './types';

const Qzone = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2C8.27812 2 2 8.27812 2 16C2 23.7219 8.27812 30 16 30C23.7219 30 30 23.7219 30 16C30 8.27812 23.7219 2 16 2Z"
      fill="white"
    />
    <path
      d="M16.3195 4.18742C16.1796 3.93753 15.8204 3.93753 15.6805 4.18742L11.9636 10.8234C11.9114 10.9165 11.8213 10.9822 11.7168 11.0035L4.2939 12.5106C4.0147 12.5673 3.90408 12.9075 4.09639 13.118L9.22323 18.7299C9.29486 18.8083 9.32904 18.914 9.31691 19.0196L8.44739 26.5905C8.41475 26.8747 8.70493 27.0854 8.96443 26.9658L15.8469 23.796C15.9441 23.7513 16.0559 23.7513 16.1531 23.796L23.0356 26.9658C23.2951 27.0854 23.5852 26.8747 23.5526 26.5905L22.9165 21.0518C22.9165 21.0518 22.8582 20.5443 22.6831 19.0196C22.671 18.914 22.7051 18.8083 22.7768 18.7299L27.9036 13.118C28.0959 12.9075 27.9853 12.5673 27.7061 12.5106L20.2832 11.0035C20.1787 10.9822 20.0886 10.9165 20.0364 10.8234L16.3195 4.18742Z"
      fill="#FECE48"
    />
    <path
      d="M15.3276 16.6133C14.8391 15.4635 11.2364 14.4013 9.49609 14.014C10.8395 13.1088 15.8364 13.168 18.167 13.3107C18.7661 13.3795 20.2846 13.5433 21.5974 14.0494C21.6704 14.0776 21.6974 14.1651 21.6491 14.2267C20.93 15.1424 18.3722 17.5128 17.1289 18.6315L16.6099 19.2737C18.6351 19.3858 23.1009 19.5428 24.7618 19.2737C24.2122 19.977 23.3777 20.8027 22.9299 21.2002C22.9604 21.4449 22.991 21.8424 23.0825 22.4846C14.4605 23.243 10.9616 21.6283 10.2594 20.7415C10.3713 20.6294 11.5417 19.6468 15.3276 16.6133Z"
      fill="#F6AD46"
    />
    <path
      d="M15.6344 12.3016C13.3413 12.3016 10.7239 13.2275 9.43606 13.8195C9.31931 13.8731 9.36038 14.0372 9.48883 14.0354C14.3881 13.9645 16.6971 14.456 17.2476 14.8193C17.3057 14.8576 17.2997 14.9349 17.2446 14.9774C15.1016 16.6288 11.2345 19.8155 10.2584 20.6264C10.1768 20.6942 10.217 20.8245 10.3224 20.8373C16.7011 21.6119 22.4604 20.4054 24.6857 19.3616C24.8052 19.3056 24.7503 19.1478 24.6191 19.1612C21.244 19.5068 17.4835 18.643 15.9792 18.0933C15.8917 18.0614 15.8747 17.9484 15.9468 17.8895C17.1923 16.873 19.7097 14.8211 21.1833 13.6298C21.2477 13.5777 21.2425 13.4781 21.1725 13.4339C20.0489 12.7241 18.7697 12.3016 15.6344 12.3016Z"
      fill="white"
    />
  </svg>
);
export default Qzone;
