/**
 * Returns the date range for the fetch of metrics to generate recommendations from,
 * taking into account exceptions for the given @param projectId
 * Default: The 60 days preceding last friday
 */
export const getDateRangeForRecommendations = () => {
  const date = new Date();
  // Last friday date
  date.setDate(date.getDate() - ((date.getDay() + 2) % 7));
  const until = new Date(date);

  date.setDate(date.getDate() - 60);
  const since = new Date(date);

  until.setUTCHours(0, 0, 0, 0);
  since.setUTCHours(0, 0, 0, 0);

  return { since, until };
};

export const getStringifiedDateRangeForRecommendations = () => {
  const { since, until } = getDateRangeForRecommendations();

  const [sinceStringified] = since.toISOString().split('T');
  const [untilStringified] = until.toISOString().split('T');

  return { since: sinceStringified, until: untilStringified };
};
