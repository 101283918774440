import { useSelector } from 'react-redux';
import * as SDK from '@replai-platform/sdk';
import LinkImage from '../../../../../components/LinkImage';
import { getStoreYoutubeTokenUrl } from '../../../../../api/api/auth';
import type { RootState } from '../../../../../store/rootReducer';

const GOOGLE_CLIENT_ID = '605600751933-6je5pasadpvrvk3jec41gcmgipgabdg2.apps.googleusercontent.com';

export const YoutubeAuthLink = () => {
  const { id: projectId } = useSelector((state: RootState) => state.project);
  const userEmail = useSelector((state: RootState) => state.auth.email);

  const redirectUrl = getStoreYoutubeTokenUrl();
  const requestState: SDK.StoreYoutubeTokenRequestState = {
    projectId,
    redirectUrl,
    userEmail,
  };

  const linkUrl = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyt-analytics.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.readonly&response_type=code&client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${encodeURIComponent(
    redirectUrl
  )}&state=${encodeURIComponent(JSON.stringify(requestState))}`;

  return <LinkImage imageUrl="https://cdn.replai.io/assets/integration-guide/youtube/step-2.png" linkUrl={linkUrl} />;
};

export default YoutubeAuthLink;
