import { Modal as AntModal } from 'antd';
import 'antd/dist/antd.min.css';
import styled from 'styled-components';

export const Modal = styled(AntModal)`
  &.replai-ant-modal .ant-modal-content {
    height: 100%;
  }

  &.replai-ant-modal .ant-modal-content,
  &.replai-ant-modal .ant-modal-header {
    border-radius: 8px;
  }
`;
