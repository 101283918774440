import { IntegrationIconProps } from './types';

const JbGoland = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.1825 10.2222L30.5441 19.9122L24.3773 30.4471L9.56445 18.3781L17.1398 11.0766L26.1825 10.2222Z"
      fill="url(#paint0_linear_1334_1485)"
    />
    <path
      d="M19.8854 19.5415L24.3773 30.4476L10.6927 25.7436L9.56445 18.3785L19.8854 19.5415Z"
      fill="#BB4EFC"
    />
    <path
      d="M11.0989 4.31226L17.3965 7.53968L11.7289 24.7008L0.314453 24.582L2.76657 19.1443L0.314453 12.1952L11.0989 4.31226Z"
      fill="#078EFC"
    />
    <path
      d="M18.9993 0.218994L21.9775 10.3539L0.314453 12.1952L7.20405 0.218994H18.9993Z"
      fill="url(#paint1_linear_1334_1485)"
    />
    <path
      d="M30.543 6.63721L17.8692 9.64521L0.314453 24.5819L11.637 30.4475L21.4724 20.3076L30.543 6.63721Z"
      fill="url(#paint2_linear_1334_1485)"
    />
    <path d="M5.35352 5.25714H25.5057V25.4094H5.35352V5.25714Z" fill="black" />
    <path d="M7.36914 22.0841H14.9262V23.2933H7.36914V22.0841Z" fill="white" />
    <path
      d="M6.67831 11.8308V11.8061C6.59603 9.38557 8.4918 7.35632 10.9124 7.27449C10.9535 7.27312 10.9942 7.2722 11.0353 7.2722C12.2422 7.21049 13.4216 7.64477 14.3003 8.47449L13.1483 9.94009C12.5764 9.3586 11.791 9.03723 10.9755 9.05094C9.56609 9.15426 8.50231 10.3716 8.58917 11.7819V11.8066C8.47123 13.2196 9.52128 14.4603 10.9343 14.5783C10.9915 14.5828 11.0491 14.586 11.1067 14.5869C11.7224 14.6052 12.3272 14.4201 12.8278 14.0608V12.8082H10.9878V11.1428H14.5969V14.9499C13.631 15.8436 12.3633 16.3401 11.0472 16.341C8.68746 16.3972 6.72906 14.5293 6.67283 12.1696C6.67054 12.0567 6.67237 11.9437 6.67831 11.8308Z"
      fill="white"
    />
    <path
      d="M15.3649 11.8308V11.8061C15.3004 9.36726 17.225 7.338 19.6643 7.27355C22.1036 7.20909 24.1324 9.13366 24.1969 11.573C24.1987 11.6425 24.1987 11.7124 24.1973 11.7819V11.8066C24.2618 14.2454 22.3372 16.2747 19.8979 16.3391C17.459 16.4036 15.4298 14.479 15.3653 12.0397C15.3635 11.9702 15.3635 11.9007 15.3649 11.8308ZM22.2865 11.8308V11.8061C22.3715 10.3707 21.2766 9.13869 19.8412 9.05366C19.817 9.05229 19.7932 9.05137 19.769 9.05046C18.3537 9.08612 17.2355 10.2619 17.2711 11.6772C17.2721 11.7119 17.2734 11.7467 17.2757 11.7819V11.8066C17.1907 13.242 18.2855 14.474 19.721 14.559C19.7452 14.5604 19.769 14.5613 19.7932 14.5622C21.2085 14.5266 22.3267 13.3508 22.291 11.9355C22.2901 11.9007 22.2887 11.8655 22.2865 11.8308Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_1485"
        x1="24.7788"
        y1="12.508"
        x2="16.2259"
        y2="25.5487"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.174" stopColor="#078EFC" />
        <stop offset="0.204" stopColor="#118AFC" />
        <stop offset="0.435" stopColor="#5971FC" />
        <stop offset="0.627" stopColor="#8E5EFC" />
        <stop offset="0.77" stopColor="#AF52FC" />
        <stop offset="0.849" stopColor="#BB4EFC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1485"
        x1="3.30628"
        y1="13.7759"
        x2="20.6513"
        y2="0.726566"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.174" stopColor="#078EFC" />
        <stop offset="0.204" stopColor="#118AFC" />
        <stop offset="0.435" stopColor="#5971FC" />
        <stop offset="0.627" stopColor="#8E5EFC" />
        <stop offset="0.77" stopColor="#AF52FC" />
        <stop offset="0.849" stopColor="#BB4EFC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_1485"
        x1="4.51414"
        y1="26.6258"
        x2="26.3198"
        y2="12.6136"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#078EFC" />
        <stop offset="0.106" stopColor="#15A7D3" />
        <stop offset="0.228" stopColor="#23BFAA" />
        <stop offset="0.348" stopColor="#2DD28B" />
        <stop offset="0.463" stopColor="#35DF74" />
        <stop offset="0.573" stopColor="#39E767" />
        <stop offset="0.67" stopColor="#3BEA62" />
      </linearGradient>
    </defs>
  </svg>
);
export default JbGoland;
