import { QueryClient, useMutation, useQueryClient } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const deleteReportTemplate = async (
  request: SDK.DeleteReportTemplateRequest,
  queryClient: QueryClient
): Promise<SDK.DeleteReportTemplateResponse> => {
  const url = `${SDK.BASE_URL()}/reports/deleteTemplate?${SDK.convertRequestToQueryParams(request)}`;
  const res = await provider.getJson<object, SDK.DeleteReportTemplateResponse>(url, { method: 'DELETE', body: {} });

  await queryClient.invalidateQueries('getReports');
  await queryClient.invalidateQueries('getReportsTemplates');

  return res;
};

export default function useDeleteReport() {
  const queryClient = useQueryClient();
  return useMutation(['delete-report-template'], (request: SDK.DeleteReportTemplateRequest) =>
    deleteReportTemplate(request, queryClient)
  );
}
