import * as SDK from '@replai-platform/sdk';
import { VideoCardProps } from '@replai-platform/ui-components';
import { logEvent } from '../../../../analytics';
import { Page } from '../../../../utils/enums';

export const formatVideoData = ({
  assetsData,
  projectId,
  onSaveClick,
}: {
  assetsData: SDK.MarketAsset[];
  projectId: SDK.UUID;
  onSaveClick: ({ assetId, projectId, isSaved }: { assetId: SDK.UUID; projectId: SDK.UUID; isSaved: boolean }) => void;
}): (VideoCardProps & { id: SDK.UUID })[] => {
  const returnData = assetsData?.map(
    (marketAsset) =>
      ({
        ...marketAsset,
        networks: undefined,
        showNetworks: false,
        onVideoPlay: () => {
          logEvent({
            component: `Market Videos Library`,
            action: 'Play video from video card',
            category: 'user_actions',
            parameters: { value: marketAsset.id, page: Page.MarketVideosLibrary },
          });
        },
        onVideoPause: () => {
          logEvent({
            component: `Market Videos Library`,
            action: 'Pause video from video card',
            category: 'user_actions',
            parameters: { value: marketAsset.id, page: Page.MarketVideosLibrary },
          });
        },
        onSaveClick: () => {
          onSaveClick?.({
            assetId: marketAsset.id,
            projectId,
            isSaved: marketAsset.isSaved,
          });
          logEvent({
            component: `Market Videos Library`,
            action: `Click ${!marketAsset.isSaved ? 'save' : 'unsave'} creative`,
            category: 'user_actions',
            parameters: { value: marketAsset.id, page: Page.MarketVideosLibrary, action: !marketAsset.isSaved },
          });
        },
      } as VideoCardProps & { id: SDK.UUID })
  );

  return returnData;
};
