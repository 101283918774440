import { useDispatch, useSelector } from 'react-redux';
import { useTitle } from 'react-use';
import { useMemo } from 'react';
import * as SDK from '@replai-platform/sdk';
import { capitalCase } from 'capital-case';
import { useFeature } from '@optimizely/react-sdk';
import FilterBar from '../../components/FilterBar';
import { FilterActions } from '../../store/filters';
import { FiltersContainer } from '../../components/FilterBar/common/styles';
import useSidebarFilters from '../../components/FilterSidebar/buildSidebarFilters';
import TopNavPageTitle from '../../components/TopNavPageTitle';
import { CustomTagsFilterProps } from '../../components/FilterBar/Filters/CustomTagsFilter';
import { AssetTypeFilterProps } from '../../components/FilterBar/Filters/AssetTypeFilter';
import type { BuyingStrategyFilterProps } from '../../components/FilterBar/Filters/BuyingStrategyFilter';
import type { NetworkFilterProps } from '../../components/FilterBar/Filters/NetworkFilter';
import type { PromotedObjectFilterProps } from '../../components/FilterBar/Filters/PromotedObjectFilter';
import useCustomTagsFilter from '../../components/FilterBar/hooks/useCustomTagsFilter';
import useBuyingStrategyFilter from '../../components/FilterBar/hooks/useBuyingStrategyFilter';
import useNetworkFilter from '../../components/FilterBar/hooks/useNetworkFilter';
import usePromotedObjectFilter from '../../components/FilterBar/hooks/usePromotedObjectFilter';
import { Page } from '../../utils/enums';
import type { RootState } from '../../store/rootReducer';
import { RouteAnimator } from '../RouteAnimator';
import { supportedFilters } from './utils';
import PerformanceTable from './PerformanceTable';

const SUPPORTED_ASSET_TYPES: SDK.AssetType[] = [SDK.AssetType.Video, SDK.AssetType.Image, SDK.AssetType.Playable];

const PerformanceView: React.VFC = () => {
  const dispatch = useDispatch();
  const projectName = useSelector((state: RootState) => state.project.name);
  const assetTypesFilters = useSelector((state: RootState) => state.filters.assetTypes);
  const [isTechmerc1525Enabled] = useFeature('techmerc-1525'); // Buying Strategy filter

  useSidebarFilters(supportedFilters);
  useTitle(`Performance - ${projectName}`);

  // Build network filter
  const {
    networkFilterOptions,
    networkFilterOnChange,
    isLoading: isNetworkFilterLoading,
  } = useNetworkFilter(Page.Videos);
  const networkFilter = useMemo(
    () =>
      ({
        options: networkFilterOptions,
        onChange: networkFilterOnChange,
        loading: isNetworkFilterLoading,
      } as NetworkFilterProps),
    [networkFilterOptions, networkFilterOnChange, isNetworkFilterLoading]
  );

  // Build buying strategy filter
  const {
    buyingStrategyFilterOptions,
    buyingStrategyFilterOnChange,
    isLoading: isBuyingStrategyFilterLoading,
  } = useBuyingStrategyFilter(Page.Videos);
  const buyingStrategyFilter = useMemo(
    () =>
      ({
        options: buyingStrategyFilterOptions,
        onChange: buyingStrategyFilterOnChange,
        loading: isBuyingStrategyFilterLoading,
      } as BuyingStrategyFilterProps),
    [buyingStrategyFilterOptions, buyingStrategyFilterOnChange, isBuyingStrategyFilterLoading]
  );

  // Build promoted object types filter
  const { promotedObjectTypeFilterOptions, promotedObjectTypeFilterOnChange } = usePromotedObjectFilter(Page.Videos);
  const promotedObjectFilter = useMemo(
    () =>
      ({
        options: promotedObjectTypeFilterOptions,
        onChange: promotedObjectTypeFilterOnChange,
        loading: false,
      } as PromotedObjectFilterProps),
    [promotedObjectTypeFilterOnChange, promotedObjectTypeFilterOptions]
  );

  // Build tagged with filter
  const {
    customTagsFilterOptions,
    customTagsFilterOnChange,
    isLoading: isCustomTagsFilterLoading,
  } = useCustomTagsFilter('default');
  const customTagsFilter: CustomTagsFilterProps = useMemo(
    () => ({
      options: customTagsFilterOptions,
      onChange: customTagsFilterOnChange,
      loading: isCustomTagsFilterLoading,
    }),
    [customTagsFilterOnChange, customTagsFilterOptions, isCustomTagsFilterLoading]
  );

  const assetTypeFilter = useMemo<AssetTypeFilterProps>(
    () => ({
      options: SUPPORTED_ASSET_TYPES.map((assetType) => ({
        id: assetType,
        label: capitalCase(assetType),
        selected: assetTypesFilters.includes(assetType),
      })),
      onChange: (e) => {
        dispatch(
          FilterActions.changeAssetTypes({
            assetTypes: e.filter((option) => option.selected).map((option) => option.id as SDK.AssetType),
          })
        );
      },
      loading: false,
    }),
    [dispatch, assetTypesFilters]
  );

  return (
    <RouteAnimator data-test="videos-container">
      <TopNavPageTitle
        title="Performance"
        subtitle="Analyze your videos' and statics' performance to decide what to do next. Customize the view with filters and more KPIs."
      />
      <FiltersContainer data-test="videos-filters-container">
        <FilterBar
          eventPrefix="performance"
          supportedFilters={supportedFilters}
          networkFilter={networkFilter}
          promotedObjectFilter={promotedObjectFilter}
          buyingStrategyFilter={isTechmerc1525Enabled ? buyingStrategyFilter : undefined}
          customTagsFilter={customTagsFilter}
          assetTypeFilter={assetTypeFilter}
          withAgeFilter
        />
      </FiltersContainer>
      <PerformanceTable filtersLoading={isNetworkFilterLoading} />
    </RouteAnimator>
  );
};

export default PerformanceView;
