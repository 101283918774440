import { CountryIconProps } from './types';

const NZ = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3679)">
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C0 12.0029 12 0.0013125 12 0C18.6274 0 24 5.37262 24 12Z"
        fill="#0052B4"
      />
      <path
        d="M11.9678 12.0004H11.9999C11.9999 11.9896 11.9999 11.9791 11.9999 11.9683C11.9892 11.979 11.9785 11.9897 11.9678 12.0004Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 6.26086C12 4.14937 12 2.76586 12 0H11.998C5.3715 0.001125 0 5.37323 0 12H6.26086V8.47448L9.78642 12H11.9679C11.9786 11.9893 11.9894 11.9786 12 11.9679C12 11.1594 12 10.4381 12 9.78647L8.47443 6.26086H12Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.07124 1.56494C4.19441 2.63364 2.63413 4.19393 1.56543 6.07075V11.9997H4.69588V4.69549V4.69539H12.0002C12.0002 3.70807 12.0002 2.76747 12.0002 1.56494H6.07124Z"
        fill="#D80027"
      />
      <path
        d="M12.0008 10.5247L7.73739 6.26123C7.73739 6.26123 6.26172 6.26132 6.26172 6.26123V6.26132L12.0008 12.0003H12.0008C12.0008 12.0003 12.0008 10.9828 12.0008 10.5247Z"
        fill="#D80027"
      />
      <path
        d="M20.7808 8.90466L21.0398 9.70182H21.878L21.1999 10.1945L21.4589 10.9916L20.7808 10.499L20.1027 10.9916L20.3617 10.1945L19.6836 9.70182H20.5218L20.7808 8.90466Z"
        fill="#D80027"
      />
      <path
        d="M17.7854 14.6436L18.174 15.8393H19.4312L18.414 16.5783L18.8026 17.774L17.7854 17.035L16.7682 17.774L17.1568 16.5783L16.1396 15.8393H17.3969L17.7854 14.6436Z"
        fill="#D80027"
      />
      <path
        d="M17.8988 5.25183L18.2226 6.24834H19.2703L18.4226 6.86405L18.7464 7.86051L17.8988 7.24463L17.0512 7.86051L17.3749 6.86405L16.5273 6.24834H17.575L17.8988 5.25183Z"
        fill="#D80027"
      />
      <path
        d="M15.0149 8.86987L15.4034 10.0657H16.6607L15.6435 10.8046L16.0321 12.0003L15.0149 11.2613L13.9977 12.0003L14.3863 10.8046L13.3691 10.0657H14.6264L15.0149 8.86987Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3679">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default NZ;
