import styled, { css } from 'styled-components';
import Colors from '../Colors';
import { default as DefaultTypography } from '../Typography/Typography';

export const Root = styled.div<{
  $withDivider?: boolean;
  $withPaddingBottom?: boolean;
}>`
  display: flex;
  flex-direction: column;

  ${(props) => (props.$withDivider ? DividerMixin : '')}
  ${(props) => (props.$withPaddingBottom ? PaddingBottomMixin : '')}

  @media screen and (min-width: 992px) {
    padding-bottom: 0;
  }
`;

export const TopContainer = styled.div<{ $withPaddingBottom?: boolean }>`
  display: flex;
  flex-direction: column;

  &.overridePadding {
    padding-bottom: 12px;
  }

  ${(props) => (props.$withPaddingBottom ? PaddingBottomMixin : '')}

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

export const TitleContainer = styled.div`
  flex: 1;
`;

export const Typography = styled(DefaultTypography)<{
  $display?: string;
  $justifyContent?: string;
  $margin?: string;
  $marginTop?: string;
  $marginBottom?: string;
}>`
  display: ${(props) => props.$display};
  justify-content: ${(props) => props.$justifyContent};
  margin: ${(props) => (props.$margin ? props.$margin : '')};
  margin-top: ${(props) => (props.$marginTop ? props.$marginTop : '')};
  margin-bottom: ${(props) => (props.$marginBottom ? props.$marginBottom : '')};
`;

export const IconButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const InputHolder = styled.div`
  min-width: 100%;

  @media screen and (min-width: 992px) {
    min-width: 320px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const Div = styled.div`
  &.overridePadding {
    padding-bottom: 12px;
  }
`;

/* Mixins */

const DividerMixin = css`
  border-bottom: 1px solid ${Colors.Gray[200]};
`;

const PaddingBottomMixin = css`
  padding-bottom: 20px;
`;
