import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase, VerticalMenuItemProps, VerticalMenuItemVariant } from '@replai-platform/ui-components';
import { Page } from '../../../utils/enums';
import { logEvent } from '../../../analytics';
import { getTagIconDefinition } from '../../../utils';
import { TAG_KINDS_TOOLTIPS } from '../../../utils/constants';

type TagWithFrequency = SDK.TagWithKind & { frequency?: number };

export const buildTagTypeWithCount = ({
  categoryLabel,
  categoryTooltip,
  onTagTypeClick,
  selectedTagType,
  tags,
}: {
  categoryLabel: string;
  categoryTooltip: string;
  onTagTypeClick: ({ type, kind }: { type: string; kind?: string }) => void;
  selectedTagType?: string;
  tags?: SDK.TagWithKind[];
}) => {
  if (!tags?.length) {
    return [];
  }

  let tagTypeObjects: VerticalMenuItemProps[] = [];

  const tagTypes: { [k: string]: { count: number; kind: string } } = {};

  tags.forEach((tag) => {
    tagTypes[tag.type] = { count: (tagTypes[tag.type]?.count ?? 0) + 1, kind: tag.kind };
  });

  tagTypeObjects = Object.entries(tagTypes).map(([tagType, { count, kind }]) => ({
    id: camelCaseToCapitalCase(tagType),
    label: camelCaseToCapitalCase(tagType),
    badgeText: count.toString(),
    variant: VerticalMenuItemVariant.COUNTER,
    onClick: () => onTagTypeClick({ type: camelCaseToCapitalCase(tagType), kind }),
    isSelected: selectedTagType === camelCaseToCapitalCase(tagType),
  }));

  return [
    { variant: VerticalMenuItemVariant.DIVIDER, label: categoryLabel, tooltipText: categoryTooltip },
    ...tagTypeObjects.sort((a, b) => a.label?.localeCompare(b.label ?? '') ?? 0),
  ];
};

export const buildTagTypes = ({
  isTechmerc1569Enabled,
  tags = [],
  selectedTagType,
  onTagTypeClick,
  tagKindFilter,
}: {
  isTechmerc1569Enabled: boolean;
  tags: SDK.TagWithKind[];
  selectedTagType?: string;
  onTagTypeClick: ({ type, kind }: { type: string; kind?: string }) => void;
  tagKindFilter?: SDK.TagKind;
}) => {
  let totalTags = 0;

  let tagTypesCustom: VerticalMenuItemProps[] = [];
  if (!tagKindFilter || tagKindFilter === SDK.TagKind.Custom) {
    const customTags = tags.filter((tag) => tag.kind === SDK.TagKind.Custom);
    tagTypesCustom = buildTagTypeWithCount({
      tags: customTags,
      onTagTypeClick,
      selectedTagType,
      categoryLabel: TAG_KINDS_TOOLTIPS({ isTechmerc1569Enabled }).MARKET.label,
      categoryTooltip: TAG_KINDS_TOOLTIPS({ isTechmerc1569Enabled }).MARKET.tooltip,
    });
    totalTags += customTags.length;
  }

  let tagTypesNotCustom: VerticalMenuItemProps[] = [];
  if (!tagTypesNotCustom || tagKindFilter !== SDK.TagKind.Custom) {
    const notCustomTags = tags.filter((tag) => tag.kind !== SDK.TagKind.Custom);
    tagTypesNotCustom = buildTagTypeWithCount({
      tags: notCustomTags,
      onTagTypeClick,
      selectedTagType,
      categoryLabel: TAG_KINDS_TOOLTIPS({ isTechmerc1569Enabled }).CORE.label,
      categoryTooltip: TAG_KINDS_TOOLTIPS({ isTechmerc1569Enabled }).CORE.tooltip,
    });
    totalTags += notCustomTags.length;
  }

  return [
    {
      label: 'All',
      badgeText: totalTags.toString(),
      variant: VerticalMenuItemVariant.COUNTER,
      onClick: () => onTagTypeClick({ type: '' }),
      isSelected: !selectedTagType,
    } as VerticalMenuItemProps,
  ]
    .concat(tagTypesCustom)
    .concat(tagTypesNotCustom);
};

export const buildTagValues = ({
  tags,
  onClick,
}: {
  tags: TagWithFrequency[];
  onClick: (t: SDK.TagWithKind & { clientFrequency?: number }) => void;
}) =>
  (tags || []).map((tag) => {
    const { iconName: iconThumbnail, props: iconProps } = getTagIconDefinition({
      type: tag.type,
      value: tag.value,
    });

    return {
      tagId: tag.id,
      marketFrequency: tag.frequency,
      rawTagType: tag.type,
      kind: tag.kind,
      title: tag.value ?? SDK.messages.NOT_AVAILABLE,
      subTitle: camelCaseToCapitalCase(tag.type),
      onClick: (clientFrequency: number) => {
        onClick({ ...tag, clientFrequency });
      },
      iconThumbnail,
      iconProps,
    };
  });

export const logEventOnAction = ({
  action,
  componentSuffix,
  params = {},
}: {
  action: string;
  componentSuffix?: string;
  params?: object;
}) => {
  logEvent({
    component: `Market Tag Library${componentSuffix ? ` - ${componentSuffix}` : ''}`,
    action,
    category: 'user_actions',
    parameters: { page: Page.MarketTagsLibrary, ...params },
  });
};

export const logPreviewEvent = (params: { action: string; componentSuffix?: string; params?: object }) =>
  logEventOnAction({ componentSuffix: 'Tag Preview', ...params });
