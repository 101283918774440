/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the target page
// If not: they are redirected to the login page.
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { api } from '../api';
import type { RootState } from '../store/rootReducer';
import { AuthActions } from '../store/auth';

async function updateCurrentUser({ dispatch }) {
  const user = await api.auth.getUserData();
  dispatch(AuthActions.updateUser(user));
}

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const userId = useSelector((state: RootState) => state.auth.id);
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  useEffect(() => {
    api.auth.isLoggedIn().then(async (result) => {
      if (result && !userId) {
        await updateCurrentUser({
          dispatch,
        });
      }
      setIsLoggedIn(result);
    });
  }, [dispatch, userId]);

  if (isLoggedIn === null) {
    return null;
  }
  if (isLoggedIn) {
    return children;
  }
  return <Navigate replace to={{ pathname: '/sign-in' }} state={{ from: location }} />;
};

export default RequireAuth;
