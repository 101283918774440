import * as SDK from '@replai-platform/sdk';
import { useSelector } from 'react-redux';
import { Colors, LineChart, LoadingBars } from '@replai-platform/ui-components';
import { RootState } from '../../store/rootReducer';
import { api } from '../../api';
import useAssetRetention from '../../api/hooks/assets/useAssetRetention';
import useConceptRetention from '../../api/hooks/concepts/useConceptRetention';
import { LoadingContainer } from '../../utils/styles';

const CreativeRetentionChart = ({ assetId, conceptId }: { assetId?: SDK.UUID; conceptId?: SDK.UUID }) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const filters = useSelector((state: RootState) => state.filters);

  const { data: assetRetention, isLoading: isLoadingAssetRetention } = useAssetRetention(
    assetId ?? '',
    {
      projectIds: [projectId],
      adsFilters: api.filterConverter.getAdsFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
    },
    { enabled: !!assetId }
  );
  const { data: conceptRetention, isLoading: isLoadingConceptRetention } = useConceptRetention(
    conceptId ?? '',
    {
      projectIds: [projectId],
      adsFilters: api.filterConverter.getAdsFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
    },
    { enabled: !!conceptId }
  );

  const lines = [
    {
      name: 'Retention',
      accessor: 'retention',
      color: Colors.Orange[400],
    },
  ];

  if (isLoadingAssetRetention || isLoadingConceptRetention || (!assetRetention && !conceptRetention)) {
    return (
      <LoadingContainer>
        <LoadingBars barSize="xxl" />
      </LoadingContainer>
    );
  }

  return (
    <LineChart
      data-test="chart"
      data={(assetRetention ?? conceptRetention ?? { retentionInfo: { periods: [] } }).retentionInfo.periods.map(
        (entry) => ({
          name: `${entry[0]}`,
          retention: entry[1],
        })
      )}
      yValueFormatter={(v) => `${(parseFloat(v.toFixed(4)) * 100).toFixed(0)}%`}
      xValueFormatter={(s) => `${(typeof s === 'number' ? s : parseFloat(s)) * 100}%`}
      lines={lines}
      gradientColor={Colors.Orange[400]}
      yLabel="Retention"
      xLabel="Video duration %"
      showLegend={false}
    />
  );
};

export default CreativeRetentionChart;
