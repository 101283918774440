import { CommonTagIconProps } from '../types';

const AspectRatio16By9 = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.75598 14.48V16.682C2.75598 16.968 2.98798 17.2 3.27398 17.2H5.47598"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M18.5239 17.2H20.7259C21.0119 17.2 21.2439 16.968 21.2439 16.682V14.48"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M5.47598 6.80005H3.27398C2.98798 6.80005 2.75598 7.03205 2.75598 7.31805V9.52005"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.2439 9.52003V7.31903C21.2439 7.03303 21.0119 6.80103 20.7259 6.80103H18.5239"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default AspectRatio16By9;
