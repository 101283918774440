import { useRef } from 'react';
import Colors from '../Colors';
import { useOverflow } from '../../utils';
import styled from 'styled-components';
import Typography from '../Typography/Typography';
import Tooltip from '../Tooltip/Tooltip';

export interface NotificationListItemProps {
  description?: string;
  onClick?: React.MouseEventHandler;
  title: string;
  unread?: boolean;
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 4.5rem;
  padding: 0.5rem;
  overflow: hidden;
  background-color: ${Colors.Common.White};
  &:hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
    background-color: ${({ onClick }) =>
      onClick ? Colors.Gray[25] : Colors.Common.White};
  }
`;

const UnreadIndicator = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${Colors.Primary[500]};
  border-radius: 0.5rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
`;

const StyledTypography = styled(Typography)<{ $maxLines?: number }>`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ $maxLines }) => $maxLines ?? 1};
`;

const NotificationListItem = ({
  description,
  onClick,
  title,
  unread,
}: NotificationListItemProps) => {
  const descriptionRef = useRef(null);
  const descriptionOverflows = useOverflow(descriptionRef);
  const titleRef = useRef(null);
  const titleOverflows = useOverflow(titleRef);
  return (
    <Container data-test="notification-list-item" onClick={onClick}>
      <Header>
        <Tooltip content={titleOverflows ? title : undefined}>
          <StyledTypography
            $maxLines={1}
            ref={titleRef}
            noMargin
            fontWeight="semi-bold"
            type="text-xs"
          >
            {title}
          </StyledTypography>
        </Tooltip>
        {unread && <UnreadIndicator />}
      </Header>
      <Tooltip content={descriptionOverflows ? description : undefined}>
        <StyledTypography
          $maxLines={2}
          ref={descriptionRef}
          noMargin
          type="text-xs"
        >
          {description}
        </StyledTypography>
      </Tooltip>
    </Container>
  );
};

export default NotificationListItem;
