import { IconProps } from './types';

const Sparkles = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.00313 8.63792C2.12615 8.54834 2.23977 8.43906 2.37397 8.3692C3.6484 7.7274 4.92418 7.09323 6.19817 6.44785C6.31265 6.38639 6.40613 6.29202 6.46656 6.17689C7.1137 4.89629 7.75487 3.61316 8.39007 2.32748C8.47685 2.15282 8.57347 1.99561 8.79535 2.00009C9.00559 2.00457 9.09819 2.15729 9.18139 2.3239C9.82047 3.60778 10.4627 4.89167 11.108 6.17555C11.1686 6.29061 11.262 6.38496 11.3764 6.44651C12.6473 7.09278 13.9235 7.72875 15.1984 8.36741C15.2661 8.3978 15.332 8.4322 15.3957 8.47041C15.6301 8.62448 15.6283 8.92724 15.3916 9.08041C15.3089 9.12986 15.2235 9.17472 15.1358 9.21477C13.8877 9.84178 12.6374 10.4616 11.3934 11.0958C11.267 11.1648 11.1636 11.2697 11.0964 11.3972C10.4567 12.6566 9.82823 13.9218 9.1966 15.1857C9.17692 15.2256 9.15724 15.2654 9.13845 15.3062C9.06777 15.4589 8.95952 15.5664 8.78059 15.5637C8.60166 15.561 8.50414 15.4491 8.42899 15.2986C7.85015 14.1341 7.25028 12.9786 6.69604 11.8021C6.48804 11.36 6.20398 11.0734 5.76202 10.8652C4.61463 10.3228 3.48647 9.73787 2.35115 9.16416C2.22366 9.09967 2.11765 8.99307 2.00134 8.90619L2.00313 8.63792Z"
      fill={color || '#101828'}
    />
    <path
      d="M2.00313 18.6482C2.12615 18.5586 2.24021 18.4507 2.37397 18.3795C2.86155 18.1251 3.35809 17.8868 3.84478 17.6333C3.93923 17.5817 4.01687 17.5039 4.06844 17.4094C4.3261 16.9167 4.57258 16.4151 4.81816 15.9144C4.90405 15.7397 5.00022 15.5812 5.22076 15.5848C5.431 15.5875 5.52583 15.7379 5.60814 15.9059C5.84746 16.3932 6.0832 16.8822 6.33863 17.361C6.40924 17.4864 6.51354 17.5894 6.63968 17.6584C7.13755 17.924 7.64482 18.1717 8.1512 18.4198C8.30955 18.4977 8.42854 18.5989 8.42496 18.7888C8.42183 18.968 8.30642 19.0647 8.15656 19.139C7.63095 19.3993 7.10535 19.6599 6.5851 19.9313C6.49068 19.983 6.41306 20.0607 6.36144 20.1552C6.1087 20.6452 5.87162 21.1419 5.61664 21.6305C5.5473 21.7649 5.43771 21.8773 5.34824 22H5.07985C4.99038 21.8777 4.88302 21.7649 4.81369 21.6323C4.5614 21.1464 4.3261 20.651 4.07202 20.1656C4.01711 20.0644 3.93456 19.981 3.83404 19.925C3.34198 19.668 2.84053 19.4297 2.34847 19.174C2.22187 19.1081 2.11541 19.0033 2 18.9165L2.00313 18.6482Z"
      fill={color || '#101828'}
    />
    <path
      d="M22 16.0166C21.9352 16.0771 21.8457 16.2114 21.7204 16.2755C20.8956 16.7014 20.0613 17.1085 19.236 17.5331C19.1214 17.5943 19.0272 17.6877 18.9649 17.8018C18.544 18.6218 18.1338 19.4477 17.7249 20.274C17.6435 20.4379 17.546 20.5719 17.3456 20.5665C17.1564 20.5616 17.0647 20.4321 16.9877 20.2758C16.5784 19.45 16.1687 18.6241 15.7477 17.804C15.6867 17.6893 15.5934 17.5949 15.4793 17.5326C14.6603 17.1112 13.8354 16.7005 13.0101 16.2912C12.8468 16.2101 12.7122 16.1151 12.7171 15.9132C12.7216 15.7228 12.8513 15.6323 13.0069 15.5549C13.8318 15.1455 14.6567 14.7348 15.4757 14.3134C15.5902 14.2522 15.6843 14.1588 15.7464 14.0447C16.1602 13.2385 16.5628 12.4252 16.9649 11.6123C17.0485 11.4439 17.1438 11.2961 17.355 11.2957C17.5661 11.2952 17.6627 11.4412 17.7459 11.6092C18.1485 12.4225 18.5511 13.2354 18.9653 14.0424C19.0269 14.1562 19.1201 14.2495 19.2337 14.3111C20.0591 14.7357 20.8889 15.1491 21.7218 15.5625C21.8833 15.6413 21.9942 15.7448 22 16.0166Z"
      fill={color || '#101828'}
    />
  </svg>
);

export default Sparkles;
