import { CountryIconProps } from './types';

const QA = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3691)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.9997 12C23.9997 5.37262 18.627 0 11.9997 0C9.65216 0 7.46244 0.674766 5.61284 1.83989L8.24314 2.95744L4.69531 4.46484L8.24314 5.9722L4.69531 7.47952L8.24314 8.98678L4.69531 10.4939L8.24314 12.0008L4.69531 13.5082L8.24314 15.0154L4.69531 16.5225L8.24314 18.0297L4.69531 19.537L8.24314 21.0441L5.61402 22.161C7.46338 23.3256 9.65263 24 11.9997 24C18.627 24 23.9997 18.6274 23.9997 12Z"
        fill="#751A46"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3691">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default QA;
