import * as SDK from '@replai-platform/sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';
import { capitalCase } from 'capital-case';
import { api } from '../../../api';
import useAsset from '../../../api/hooks/assets/useAsset';
import { NetworkFilterProps, PromotedObjectFilterProps } from '../../../components/FilterBar/Filters';
import useNetworkFilter from '../../../components/FilterBar/hooks/useNetworkFilter';
import usePromotedObjectFilter from '../../../components/FilterBar/hooks/usePromotedObjectFilter';
import TopNavPageTitle from '../../../components/TopNavPageTitle';
import type { RootState } from '../../../store/rootReducer';
import { ActivityType, Page } from '../../../utils/enums';
import { getNavigateToPage } from '../../../utils/getNavigateToPage';
import { RouteAnimator } from '../../RouteAnimator';
import CreativeViewTabs from './Tabs';
import useSidebarFilters from '../../../components/FilterSidebar/buildSidebarFilters';
import { supportedFilters } from './utils';
import FilterBar from '../../../components/FilterBar';
import TabPaths from './Tabs/TabPaths';

const CreativeView = ({ currentPage, assetType }: { currentPage: Page; assetType: SDK.AssetType }) => {
  const { id: projectId } = useSelector((state: RootState) => state.project);
  const match = useMatch(`:projectId/${currentPage}/:clusterId/*`);
  const params = match?.params ?? { clusterId: '' };
  const location = useLocation();
  const filters = useSelector((state: RootState) => state.filters);
  const kpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  useSidebarFilters(supportedFilters);

  const getAssetParams = {
    projectIds: [projectId],
    adsFilters: api.filterConverter.getAdsFilters({ ...filters, activity: ActivityType.all }),
    metricsFilters: api.filterConverter.getMetricsFilters({ ...filters, activity: ActivityType.all }),
    metrics: [...kpis, SDK.Metrics.INSTALLS, SDK.Metrics.IMPRESSIONS],
    includeTotalsAndAvg: true,
  };

  const { data, isLoading } = useAsset(params.clusterId ?? '', getAssetParams, {
    enabled: !!params.clusterId,
  });

  // Build network filter
  const { networkFilterOptions, networkFilterOnChange } = useNetworkFilter(Page.Videos);
  const networkFilter = useMemo(
    () =>
      ({
        options: networkFilterOptions,
        onChange: networkFilterOnChange,
      } as NetworkFilterProps),
    [networkFilterOptions, networkFilterOnChange]
  );

  // Build promoted object types filter
  const { promotedObjectTypeFilterOptions, promotedObjectTypeFilterOnChange } = usePromotedObjectFilter(Page.Videos);
  const promotedObjectFilter = useMemo(
    () =>
      ({
        options: promotedObjectTypeFilterOptions,
        onChange: promotedObjectTypeFilterOnChange,
      } as PromotedObjectFilterProps),
    [promotedObjectTypeFilterOnChange, promotedObjectTypeFilterOptions]
  );

  const navigateToPage = useMemo(() => getNavigateToPage(location.pathname) ?? Page.Concepts, [location.pathname]);

  return (
    <RouteAnimator data-test="creative-cluster-view">
      <TopNavPageTitle
        title={data?.name ?? SDK.messages.NOT_AVAILABLE}
        subtitle={capitalCase(assetType)}
        navigateToPage={navigateToPage}
        titleIsLoading={isLoading}
      />
      <FilterBar
        eventPrefix={`${assetType}-view`}
        supportedFilters={supportedFilters}
        networkFilter={networkFilter}
        promotedObjectFilter={promotedObjectFilter}
        withDateFilter={false}
      />
      {data ? (
        <CreativeViewTabs
          defaultTab={currentPage === Page.PlayableView ? TabPaths.Metrics : undefined}
          currentPage={currentPage}
          cluster={data}
          loading={isLoading}
          allowedTabs={currentPage === Page.PlayableView ? [TabPaths.Metrics] : undefined}
        />
      ) : undefined}
    </RouteAnimator>
  );
};

export default CreativeView;
