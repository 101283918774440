import { CountryIconProps } from './types';

const ID = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M0 12C0 5.37262 5.37262 0 12 0C18.6274 0 24 5.37262 24 12"
      fill="#A2001D"
    />
  </svg>
);
export default ID;
