import { QueryFunction, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getPerformanceMetrics = async (
  requestParams: SDK.GetPerformanceMetricsRequest & { refreshCache?: boolean }
): Promise<SDK.GetPerformanceMetricsResponse> => {
  const opts = {} as provider.SupportedHeaders;
  if (requestParams.refreshCache) {
    opts['cache-control'] = 'max-age=0';
  }
  const res = await provider.getJson<object, SDK.GetPerformanceMetricsResponse>(
    `${SDK.BASE_URL()}/performance/metrics?${SDK.convertRequestToQueryParams(requestParams)}`,
    undefined,
    opts
  );
  return res;
};

const KEY = 'performance-metrics';
type Key = typeof KEY;

type QueryKey = [Key, SDK.GetPerformanceMetricsRequest];

type QueryOptions = UseQueryOptions<
  SDK.GetPerformanceMetricsResponse,
  unknown,
  SDK.GetPerformanceMetricsResponse,
  QueryKey
>;
type QueryResult = UseQueryResult<SDK.GetPerformanceMetricsResponse>;

const queryFn: QueryFunction<SDK.GetPerformanceMetricsResponse, QueryKey> = ({ queryKey: [, requestParams] }) =>
  getPerformanceMetrics(requestParams);

// This is exported so we can use useQueries
export function getPerformanceMetricsQueryOptions(
  requestParams: SDK.GetPerformanceMetricsRequest,
  options?: QueryOptions
): QueryOptions {
  return {
    queryKey: [KEY, requestParams],
    queryFn,
    ...options,
  };
}

export default function usePerformanceMetrics(
  requestParams: SDK.GetPerformanceMetricsRequest & { refreshCache?: boolean },
  options?: QueryOptions
): QueryResult {
  return useQuery(getPerformanceMetricsQueryOptions(requestParams, options));
}
