import * as Icons from '../Icons';
import Badge from '../Badge/Badge';
import Button from '../Button/Button';
import Colors, { ColorTypes } from '../Colors';
import * as Styled from './styles';

export type VideoItemProps = {
  imageUrl: string;
  name: string;
  statusIcon?: {
    name: Icons.MiscIconTypes | Icons.BaseIconTypes;
    color?: ColorTypes;
    dimension?: number;
  };
  showDelete?: boolean;
  showStatus?: boolean;
  onDelete?: () => void;
};

const VideoItem = ({
  imageUrl,
  name,
  statusIcon = { name: 'Check' },
  showDelete = true,
  showStatus = true,
  onDelete,
}: VideoItemProps) => {
  return (
    <Styled.VideoItemContainer>
      <Styled.ImageContainer>
        <Styled.Image alt="Video thumbnail" src={imageUrl} />
      </Styled.ImageContainer>
      <Styled.Name
        type="text-sm"
        fontWeight="semi-bold"
        color={Colors.Primary[600]}
      >
        {name}
      </Styled.Name>
      <Styled.ActionsContainer>
        {showStatus ? (
          <Badge
            leadingIcon={statusIcon.name}
            color={statusIcon.color || 'Success'}
            size="sm"
            inverted
          />
        ) : undefined}
        {showDelete ? (
          <Button
            style={{ padding: 0, height: '20px', minWidth: '20px' }}
            variant="no-fill"
            leadingIcon={{
              name: 'Trash2',
              color: Colors.Gray[500],
              dimension: 15,
            }}
            aria-label="Delete"
            onClick={() => onDelete?.()}
          />
        ) : undefined}
      </Styled.ActionsContainer>
    </Styled.VideoItemContainer>
  );
};

export default VideoItem;
