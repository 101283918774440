/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';
import NetworkAndOS from './components/NetworkAndOs/NetworkAndOS';

export type AppearsInAccessorType = {
  network: SDK.Network[];
  os: SDK.PromotedObjectType[];
  loading?: boolean;
};

function getAssetNetworkAndOSColumn<D extends object = object>({
  columnId,
  accessor,
}: RequiredGeneratorParams<D, AppearsInAccessorType>): GeneratedColumn<D, AppearsInAccessorType> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<D, AppearsInAccessorType>) => {
      const { network, os, loading } = data.cell.value;

      return <NetworkAndOS loading={loading} projectNetworks={network} possiblePromotedObjectTypes={os} />;
    },
  };
}

export default getAssetNetworkAndOSColumn;
