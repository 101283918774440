import { CountryIconProps } from './types';

const NA = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M3.51451 20.4849C4.0179 20.9883 4.55499 21.4372 5.11838 21.8325L21.8326 5.11826C21.4374 4.55487 20.9884 4.01778 20.485 3.51439C19.9816 3.011 19.4445 2.56212 18.8811 2.16687L2.16699 18.8811C2.5622 19.4444 3.01112 19.9815 3.51451 20.4849Z"
      fill="#A2001D"
    />
    <path
      d="M3.51526 3.51478C-0.270555 7.30059 -0.997306 12.9862 1.33346 17.5022L17.5026 1.33307C12.9866 -0.997692 7.30098 -0.270848 3.51526 3.51478Z"
      fill="#0052B4"
    />
    <path
      d="M20.4854 20.4847C24.2711 16.699 24.9979 11.0134 22.6672 6.49731L6.49805 22.6665C11.014 24.9972 16.6996 24.2705 20.4854 20.4847Z"
      fill="#496E2D"
    />
    <path
      d="M9.91356 6.78182L8.56891 7.41431L9.28497 8.71659L7.82486 8.43731L7.6398 9.91223L6.6228 8.8274L5.60575 9.91223L5.42078 8.43731L3.96063 8.71654L4.67664 7.41426L3.33203 6.78182L4.67669 6.14929L3.96063 4.8471L5.42073 5.12638L5.6058 3.65137L6.6228 4.73624L7.63984 3.65137L7.82486 5.12638L9.28497 4.8471L8.569 6.14934L9.91356 6.78182Z"
      fill="#FFDA44"
    />
  </svg>
);
export default NA;
