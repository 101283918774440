import * as SDK from '@replai-platform/sdk';
import { QueryFunction, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import * as provider from '../../api/provider';

const getTagMetrics = async (requestParams: SDK.GetTagsMetricsRequest) => {
  const res = await provider.getJson<object, SDK.GetTagsMetricsResponse>(
    `${SDK.BASE_URL()}/tags/metrics?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

const KEY = 'tags-metrics';
type Key = typeof KEY;

type Result = SDK.GetTagsMetricsResponse;
type QueryKey = [Key, SDK.GetTagsMetricsRequest];

type QueryOptions<R = Result> = UseQueryOptions<Result, unknown, R, QueryKey>;
type QueryResult<R = Result> = UseQueryResult<R>;

const queryFn: QueryFunction<Result, QueryKey> = ({ queryKey: [, requestParams] }) => getTagMetrics(requestParams);

// This is exported so we can use useQueries
export function getTagMetricsQueryOptions<R = Result>(
  requestParams: SDK.GetTagsMetricsRequest,
  options?: QueryOptions<R>
): QueryOptions<R> {
  return {
    queryKey: [KEY, requestParams],
    queryFn,
    ...options,
  };
}

export default function useTagMetrics<R = Result>(
  requestParams: SDK.GetTagsMetricsRequest,
  options?: QueryOptions<R>
): QueryResult<R> {
  return useQuery(getTagMetricsQueryOptions(requestParams, options));
}
