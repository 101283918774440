import { CountryIconProps } from './types';

const HU = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3640)">
      <path
        d="M0.746391 7.82522C0.264047 9.12525 0 10.5313 0 11.9991C0 13.467 0.264047 14.8731 0.746391 16.173L12 17.2165L23.2536 16.173C23.736 14.8731 24 13.467 24 11.9991C24 10.5313 23.736 9.12525 23.2536 7.82522L12 6.78174L0.746391 7.82522Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.0006 -0.000976562C6.8411 -0.000976562 2.44254 3.25552 0.74707 7.82513H23.2543C21.5587 3.25552 17.1603 -0.000976562 12.0006 -0.000976562Z"
        fill="#D80027"
      />
      <path
        d="M12.0006 23.9993C17.1603 23.9993 21.5587 20.7428 23.2543 16.1732H0.74707C2.44254 20.7428 6.8411 23.9993 12.0006 23.9993Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3640">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default HU;
