import { AdsFilters, Network } from '../../ads';
import { AssetFilters } from '../../assets';
import { AssetTagsFilters } from '../../assetTags';
import { UUID } from '../../atomics';
import { MetricOrKpi, Metrics, MetricsFilters } from '../../metrics';
import { TagsFilters } from '../../tags';

export enum TrendSlideToInclude {
  NegativeCreative = 'NEGATIVE_CREATIVE',
  NegativeTag = 'NEGATIVE_TAG',
  PositiveCreative = 'POSITIVE_CREATIVE',
  PositiveTag = 'POSITIVE_TAG',
}

export type Breakdown = 'network' | 'country';

export type ExportToGoogleSlidesRequestBody = {
  reportId?: UUID;
  reportTitle?: string;
  reportType: ReportTypes;
  shareWithEmail: string;
  template?: GoogleSlidesTemplates;
  breakdown?: Breakdown;
  templateId?: UUID;
  /**
   * As we exceeded the limit of API endpoints we re-used the Google Slides endpoint for the
   * Google Sheets export also. This field is only used for the latter.
   */
  existingSpreadsheetId?: string;
} & (
  | {
      adsFilters?: AdsFilters;
      assetFilters?: AssetFilters;
      conceptLevel?: boolean;
      mainKpi: MetricOrKpi;
      maxTopItems?: number;
      metricsFilters?: MetricsFilters;
      metricsToDisplay: MetricOrKpi[];
      projectIds: UUID[];
      tagsFilters?: TagsFilters;
      tagTypesToDisplay: string[];
      template?: GoogleSlidesTemplates.TOP_CREATIVES;
    }
  | {
      adsFilters?: AdsFilters;
      assetFilters?: AssetFilters;
      assetTagsFilters?: AssetTagsFilters;
      compareToTimeRange: {
        dateStartTimestamp: number;
        dateEndTimestamp: number;
      };
      conceptLevel?: boolean;
      mainKpi: MetricOrKpi;
      maxTopItems?: number;
      metricsFilters?: MetricsFilters;
      metricsToDisplay: MetricOrKpi[];
      projectIds: UUID[];
      tagsFilters?: TagsFilters;
      template: GoogleSlidesTemplates.TRENDS;
      trendSlidesToInclude: TrendSlideToInclude[];
    }
  | {
      adsFilters?: AdsFilters;
      assetFilters?: AssetFilters;
      conceptLevel?: boolean;
      maxTopItems?: number;
      metricsFilters?: MetricsFilters;
      projectIds: UUID[];
      tagsFilters?: TagsFilters;
      template: GoogleSlidesTemplates.WINNERS_DNA;
    }
);

export type ExportToGoogleSlidesResponse = {
  presentationId: string;
  presentationUrl: string;
  gid?: string;
};

export enum ReportTypes {
  GOOGLE_SLIDES = 'GOOGLE_SLIDES',
  GOOGLE_SHEETS = 'GOOGLE_SHEETS',
  PDF = 'PDF',
  SLACK = 'SLACK',
  LOOKER = 'LOOKER',
  TABLEAU = 'TABLEAU',
}

export enum ReportStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

export enum GoogleSlidesTemplates {
  TOP_CREATIVES = 'TOP_CREATIVES',
  TRENDS = 'TRENDS',
  WINNERS_DNA = 'WINNERS_DNA',
}

export type ReportTemplates = GoogleSlidesTemplates;

export type Report = {
  id: UUID;
  name: string;
  type: ReportTypes;
  status: ReportStatus;
  userId: UUID;
  projectId: UUID;
  createdAt: string;
  updatedAt: string;
  totalNumberOfReports: number;
  url?: string;
  template?: ReportTemplates;
  externalId?: string;
  externalSubId?: string;
  userEmail?: string;
  favorite?: boolean;
  templateId?: string;
  templateName?: string;
};

export type ReportTemplate = {
  id: string;
  name: string;
  createdAt: string;
  type: string;
  params: object;
  userId: string;
};

export type GetReportsRequest = {
  projectId: UUID;
  onlyFavorites?: boolean;

  // Pagination info
  maxRecords?: number;
  offset?: number;
};

export type GetReportsResponse = Report[];

export type GrantReportPermissionsRequest = {
  projectId: UUID;
  reportId: UUID;
};

export type GrantReportPermissionsResponse = {
  reportUrl: string;
};

export type ChartImageInfo = {
  // Can either be a Network or a Country
  entity: Network | string;
  data: { date: string; metric: number; kpiToShow: Metrics }[];
};

export type GenerateChartImageRequestBody = ChartImageInfo[];

export type ToggleFavoriteReportRequestBody = {
  projectId: UUID;
  reportId: UUID;
  favorite: boolean;
};

export type ToggleFavoriteReportResponse = {
  reportId: UUID;
};

export type GetReportsTemplatesRequest = {
  projectId: UUID;
};

export type GetReportsTemplatesResponse = {
  templates: {
    template: ReportTemplate;
    samples: Report[];
  }[];
};

export type SaveReportAsTemplateRequest = {
  projectId: UUID;
  reportId: string;
  name: string;
};

export type SaveReportAsTemplateResponse = {
  templateId: string;
};

// -------------- Delete Report - Begin --------------

export type DeleteReportRequest = {
  projectId: UUID;
  reportId: UUID;
};

export type DeleteReportResponse = {
  ok: boolean;
  userMessage?: string;
};

// -------------- Delete Report - End --------------

// -------------- Delete Report Template - Begin --------------

export type DeleteReportTemplateRequest = {
  projectId: UUID;
  templateId: UUID;
};

export type DeleteReportTemplateResponse = {
  ok: boolean;
  userMessage?: string;
};

// -------------- Delete Report Template - End --------------
