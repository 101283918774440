import { CountryIconProps } from './types';

const KI = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3650)">
      <path
        d="M0 12C0 5.37262 5.37262 0 12 0C18.6274 0 24 5.37262 24 12"
        fill="#D80027"
      />
      <path
        d="M16.173 11.16L14.4674 11.9622L15.3757 13.614L13.5237 13.2597L13.289 15.1305L11.9991 13.7545L10.7091 15.1305L10.4745 13.2597L8.62249 13.6139L9.5307 11.9622L7.8252 11.16L9.5307 10.3577L8.62249 8.70605L10.4744 9.06023L10.7091 7.18945L11.9991 8.56537L13.2891 7.18945L13.5237 9.06023L15.3757 8.70605L14.4676 10.3578L16.173 11.16Z"
        fill="#FFDA44"
      />
      <path
        d="M15.13 3.913H12.7822C12.7822 3.48086 12.4318 3.13037 11.9996 3.13037C11.5674 3.13037 11.217 3.48081 11.217 3.913H8.86914C8.86914 4.34518 9.24564 4.69562 9.67783 4.69562H9.65172C9.65172 5.12781 10.0021 5.47824 10.4343 5.47824C10.4343 5.91043 10.7847 6.26087 11.217 6.26087H12.7822C13.2143 6.26087 13.5648 5.91048 13.5648 5.47824C13.9969 5.47824 14.3474 5.12785 14.3474 4.69562H14.3213C14.7535 4.69562 15.13 4.34523 15.13 3.913Z"
        fill="#FFDA44"
      />
      <path
        d="M24 11.9996C24 18.627 18.6274 23.9996 12 23.9996C5.37262 23.9996 0 18.627 0 11.9996C0.521719 11.9996 24 11.9996 24 11.9996Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.0004 24.0005C15.7515 24.0005 19.0999 22.279 21.3005 19.5835C20.9047 19.3137 20.691 18.8774 20.0003 18.8774C19.0003 18.8774 19.0003 19.7918 18.0003 19.7918C17.0004 19.7918 17.0004 18.8774 16.0004 18.8774C15.0004 18.8774 15.0004 19.7918 14.0004 19.7918C13.0004 19.7918 13.0003 18.8774 12.0002 18.8774C11.0003 18.8774 11.0003 19.7918 10.0003 19.7918C9.00048 19.7918 9.00048 18.8774 8.00049 18.8774C7.00041 18.8774 7.00041 19.7918 6.00034 19.7918C5.00026 19.7918 5.00026 18.8774 4.00018 18.8774C3.30952 18.8774 3.09582 19.3136 2.7002 19.5834C4.9007 22.279 8.24935 24.0005 12.0004 24.0005Z"
        fill="#0052B4"
      />
      <path
        d="M21.9999 13.5312C20.9999 13.5312 20.9999 12.6168 19.9998 12.6168C18.9999 12.6168 18.9999 13.5312 17.9999 13.5312C17 13.5312 16.9999 12.6168 16 12.6168C15 12.6168 15 13.5312 14 13.5312C13 13.5312 12.9999 12.6168 11.9998 12.6168C10.9998 12.6168 10.9998 13.5312 9.99991 13.5312C9.00006 13.5312 9.00006 12.6168 8.00008 12.6168C7 12.6168 7 13.5312 5.99992 13.5312C4.99984 13.5312 4.99984 12.6168 3.99977 12.6168C2.99992 12.6168 2.99992 13.5312 1.99994 13.5312C1.00534 13.5312 0.999578 12.6269 0.015625 12.6172C0.0433281 13.1661 0.108859 13.705 0.207953 14.232C1.00502 14.3491 1.07481 15.1311 1.99998 15.1311C2.99997 15.1311 2.99997 14.2169 3.99981 14.2169C4.99989 14.2169 4.99989 15.1311 5.99997 15.1311C7.00005 15.1311 7.00005 14.2169 8.00012 14.2169C9.00011 14.2169 9.00011 15.1311 9.99995 15.1311C10.9999 15.1311 10.9999 14.2169 11.9999 14.2169C13 14.2169 13 15.1311 14 15.1311C15.0001 15.1311 15.0001 14.2169 16 14.2169C17 14.2169 17 15.1311 18 15.1311C18.9999 15.1311 19 14.2169 19.9999 14.2169C21 14.2169 21 15.1311 21.9999 15.1311C22.9252 15.1311 22.995 14.3492 23.7921 14.232C23.8912 13.705 23.9567 13.1662 23.9845 12.6173C23.0004 12.627 22.9945 13.5312 21.9999 13.5312Z"
        fill="#0052B4"
      />
      <path
        d="M19.9998 15.7477C18.9998 15.7477 18.9998 16.662 17.9998 16.662C16.9999 16.662 16.9999 15.7477 15.9999 15.7477C14.9999 15.7477 14.9999 16.662 13.9999 16.662C12.9998 16.662 12.9998 15.7477 11.9998 15.7477C10.9998 15.7477 10.9998 16.662 9.99983 16.662C8.99998 16.662 8.99998 15.7477 8 15.7477C6.99992 15.7477 6.99992 16.662 5.99984 16.662C4.99977 16.662 4.99977 15.7477 3.99969 15.7477C2.99984 15.7477 2.99984 16.662 1.99986 16.662C1.28534 16.662 1.08125 16.1953 0.658203 15.9287C0.939828 16.7424 1.3062 17.5165 1.74744 18.24C1.82417 18.2542 1.9077 18.262 1.99986 18.262C2.99984 18.262 2.99984 17.3478 3.99969 17.3478C4.99977 17.3478 4.99977 18.262 5.99984 18.262C6.99992 18.262 6.99992 17.3478 8 17.3478C8.99998 17.3478 8.99998 18.262 9.99983 18.262C10.9998 18.262 10.9998 17.3478 11.9998 17.3478C12.9998 17.3478 12.9998 18.262 13.9999 18.262C15 18.262 15 17.3478 15.9999 17.3478C16.9999 17.3478 16.9999 18.262 17.9998 18.262C18.9998 18.262 18.9998 17.3478 19.9998 17.3478C20.9998 17.3478 20.9998 18.262 21.9998 18.262C22.092 18.262 22.1756 18.2542 22.2523 18.24C22.6935 17.5165 23.0599 16.7424 23.3416 15.9288C22.9185 16.1952 22.7144 16.662 21.9998 16.662C20.9998 16.662 20.9998 15.7477 19.9998 15.7477Z"
        fill="#0052B4"
      />
      <path
        d="M23.9628 11.0511C23.0004 11.0738 22.9872 11.9644 21.9998 11.9644C20.9999 11.9644 20.9999 11.05 19.9998 11.05C18.9998 11.05 18.9998 11.9644 17.9999 11.9644C17 11.9644 16.9999 11.05 16 11.05C15 11.05 15 11.9644 14 11.9644C12.9999 11.9644 12.9999 11.05 11.9998 11.05C10.9998 11.05 10.9998 11.9644 9.99989 11.9644C9.00005 11.9644 9.00005 11.05 8.00006 11.05C6.99998 11.05 6.99998 11.9644 5.99991 11.9644C4.99983 11.9644 4.99983 11.05 3.99975 11.05C2.99991 11.05 2.99991 11.9644 1.99992 11.9644C1.01278 11.9644 0.999422 11.0739 0.0371719 11.0511C0.01275 11.3639 0 11.68 0 11.9991C0 12.2177 0.0061875 12.4349 0.0178594 12.6505C0.999563 12.6616 1.00608 13.5643 1.99997 13.5643C2.99995 13.5643 2.99995 12.6501 3.9998 12.6501C4.99988 12.6501 4.99988 13.5643 5.99995 13.5643C7.00003 13.5643 7.00003 12.6501 8.00011 12.6501C9.00009 12.6501 9.00009 13.5643 9.99994 13.5643C10.9999 13.5643 10.9999 12.6501 11.9999 12.6501C12.9999 12.6501 12.9999 13.5643 14 13.5643C15.0001 13.5643 15.0001 12.6501 16 12.6501C17 12.6501 17 13.5643 18 13.5643C18.9999 13.5643 18.9999 12.6501 19.9999 12.6501C21 12.6501 21 13.5643 21.9999 13.5643C22.9939 13.5643 23.0004 12.6616 23.9823 12.6505C23.9938 12.4348 24 12.2177 24 11.9991C24 11.68 23.9872 11.3639 23.9628 11.0511Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3650">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default KI;
