import { BrandIconProps } from './types';

const ReplaiFullLogo = ({ dimension }: BrandIconProps) => (
  <svg
    width={dimension ? (dimension * 110) / 33 : 33}
    height={dimension || 33}
    viewBox="0 0 110 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8056_15129)">
      <path
        d="M35.5333 27.0859H40.8592V19.5644C40.8592 17.9109 42.0277 16.8192 43.5988 16.8192C44.1485 16.8192 45.0376 16.9041 45.5663 17.0826V12.6575C45.1428 12.5346 44.6802 12.4614 44.2146 12.4614C42.6315 12.4614 41.3969 13.3628 40.8562 15.3939H40.703V12.6458H35.5303V27.0859H35.5333Z"
        fill="#3E3A40"
      />
      <path
        d="M51.1747 18.2123C51.2047 16.9626 52.2651 16.108 53.5688 16.108C54.8905 16.108 55.8938 16.9714 55.9238 18.2123H51.1747ZM53.5658 27.3493C57.6751 27.3493 60.3185 25.4323 60.8202 22.4237H55.9569C55.6475 23.2403 54.7703 23.7027 53.6799 23.7027C52.0968 23.7027 51.1717 22.6871 51.1717 21.297V21.0716H60.8202V19.8688C60.8202 15.271 57.9244 12.4614 53.4486 12.4614C48.8556 12.4614 45.9238 15.432 45.9238 19.9068C45.9268 24.5748 48.8196 27.3493 53.5658 27.3493Z"
        fill="#3E3A40"
      />
      <path
        d="M68.2039 19.8658C68.2039 17.7235 69.09 16.4446 70.5589 16.4446C72.0248 16.4446 72.875 17.7235 72.875 19.8658C72.875 22.0082 72.0248 23.2871 70.5589 23.2871C69.093 23.2871 68.2039 21.973 68.2039 19.8658ZM62.9951 32.5002H68.321V24.7153H68.3991C69.0179 26.1816 70.4448 27.2732 72.5295 27.2732C75.6926 27.2732 78.3571 24.8675 78.3571 19.8658C78.3571 14.6389 75.5004 12.4585 72.5686 12.4585C70.3697 12.4585 68.9789 13.6994 68.4021 15.1656H68.285V12.6458H62.9981V32.5002H62.9951Z"
        fill="#3E3A40"
      />
      <path
        d="M85.9119 7.83398H80.5859V27.0855H85.9119V7.83398Z"
        fill="#3E3A40"
      />
      <path
        d="M94.4911 24.0393C93.5839 24.0393 92.9471 23.6061 92.9471 22.7984C92.9471 22.055 93.4878 21.4814 94.7224 21.2941C95.5725 21.1624 96.2844 20.9926 96.8461 20.7673V21.9701C96.8431 23.2871 95.6956 24.0393 94.4911 24.0393ZM92.7548 27.3113C94.6833 27.3113 96.1402 26.6732 97.0384 25.0548H97.1555V27.0859H102.133V17.1967C102.133 14.5276 99.5376 12.4585 95.3021 12.4585C90.8744 12.4585 88.6935 14.7149 88.5103 17.346H93.4127C93.5388 16.4534 94.2147 16.029 95.227 16.029C96.1522 16.029 96.8101 16.4417 96.8101 17.1938V17.2319C96.8101 18.0689 95.8639 18.3323 93.3766 18.5108C90.3186 18.7274 87.9365 19.9302 87.9365 23.0969C87.9305 25.9767 89.9161 27.3113 92.7548 27.3113Z"
        fill="#3E3A40"
      />
      <path
        d="M107.339 11.144C108.787 11.144 109.964 10.0816 109.964 8.77635C109.964 7.47107 108.787 6.40869 107.339 6.40869C105.891 6.40869 104.713 7.47107 104.713 8.77635C104.713 10.0816 105.891 11.144 107.339 11.144ZM104.677 27.0855H110.003V12.6454H104.677V27.0855Z"
        fill="#3E3A40"
      />
      <path
        d="M21.9975 10.2428C21.8623 10.1199 21.64 10.1989 21.61 10.3774C21.0513 13.6904 19.4652 16.8658 16.8488 19.4149C16.5694 19.6871 16.278 19.9505 15.9807 20.2022C15.9236 20.252 15.9176 20.3368 15.9716 20.3924L18.8073 23.3015C18.8734 23.3689 18.8254 23.483 18.7292 23.483L8.49193 23.8459C8.41983 23.8459 8.36876 23.7815 8.38378 23.7142L10.4174 14.9284C10.4355 14.8435 10.5436 14.8172 10.6037 14.8786L13.0969 17.4365C13.148 17.4892 13.2291 17.4921 13.2862 17.4482C13.5655 17.22 13.8329 16.977 14.0942 16.7254C16.6746 14.2114 17.9542 10.9042 17.9392 7.60298C17.9362 6.75425 17.81 5.90552 17.4976 5.1124C16.4312 2.41403 13.7488 0.5 10.6067 0.5C8.89445 0.5 7.3174 1.0707 6.06477 2.02479C6.02272 2.05698 6.05876 2.11844 6.10983 2.1038C6.95693 1.81699 7.9362 2.00137 8.61508 2.65987C9.55831 3.57884 9.55831 5.0685 8.61508 5.9904L4.07018 10.3511C0.991174 13.2075 -0.666982 17.5068 0.255217 22.1426C1.29457 27.3608 5.67126 31.4113 11.0663 32.1986C19.1948 33.3868 26.1849 27.2847 26.1849 19.5905C26.1849 15.8971 24.5718 12.5724 21.9975 10.2428Z"
        fill="#FF4857"
      />
    </g>
    <defs>
      <clipPath id="clip0_8056_15129">
        <rect
          width="110"
          height="32"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ReplaiFullLogo;
