import { CountryIconProps } from './types';

const KG = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#D80027"
    />
    <path
      d="M17.8681 12L15.4702 13.1279L16.7471 15.4504L14.1431 14.9522L13.8133 17.5826L11.9994 15.6481L10.1857 17.5826L9.85578 14.9522L7.25197 15.4503L8.52884 13.1278L6.13086 12L8.52884 10.8721L7.25197 8.54956L9.85569 9.04784L10.1858 6.41736L11.9994 8.35193L13.8133 6.41736L14.1431 9.04784L16.7472 8.54956L15.4702 10.8723L17.8681 12Z"
      fill="#FFDA44"
    />
    <path
      d="M11.9998 15.6514C14.0169 15.6514 15.652 14.0163 15.652 11.9992C15.652 9.98218 14.0169 8.34705 11.9998 8.34705C9.98279 8.34705 8.34766 9.98218 8.34766 11.9992C8.34766 14.0163 9.98279 15.6514 11.9998 15.6514Z"
      fill="#D80027"
    />
    <path
      d="M10.1746 12.0004C10.0867 12.0004 9.9998 12.0049 9.91406 12.0134C9.9172 12.5192 10.1002 12.9822 10.4025 13.3419C10.5637 12.908 10.8045 12.5123 11.1081 12.1737C10.8181 12.0621 10.5035 12.0004 10.1746 12.0004Z"
      fill="#FFDA44"
    />
    <path
      d="M11.2988 13.967C11.5182 14.0454 11.7544 14.0884 12.0008 14.0884C12.2472 14.0884 12.4834 14.0454 12.7027 13.967C12.5832 13.4977 12.3363 13.0788 12.0008 12.7495C11.6653 13.0787 11.4184 13.4977 11.2988 13.967Z"
      fill="#FFDA44"
    />
    <path
      d="M13.8079 10.9573C13.447 10.3335 12.7731 9.91345 12.0006 9.91345C11.2281 9.91345 10.5542 10.3335 10.1934 10.9573C10.8512 10.9606 11.4681 11.1395 12.0006 11.4483C12.5332 11.1395 13.15 10.9606 13.8079 10.9573Z"
      fill="#FFDA44"
    />
    <path
      d="M12.8936 12.1739C13.1972 12.5125 13.4379 12.9082 13.5992 13.3421C13.9014 12.9825 14.0844 12.5195 14.0876 12.0136C14.0018 12.0051 13.9149 12.0006 13.827 12.0006C13.4982 12.0006 13.1836 12.0623 12.8936 12.1739Z"
      fill="#FFDA44"
    />
  </svg>
);
export default KG;
