import styled, { css } from 'styled-components';
import Colors from '../Colors';
import { default as DefaultTypography } from '../Typography/Typography';

export const Root = styled.div`
  position: relative;
  width: 100%;
`;

export const RootButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 6px;
  padding: 10px 32px 10px 14px;
  font-size: 0.875rem;
  background-color: ${Colors.Common.White};
  border: 1px solid ${Colors.Gray[300]};
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
  user-select: none;

  &:focus {
    border: 1px solid ${Colors.Primary[300]};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #ffcdd6;
  }

  &.rootWithAvatar {
    padding: 8px 32px 8px 14px;
  }
`;

export const AvatarContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

export const PlaceholderIcon = styled.span`
  display: inline-flex;
  align-items: center;
  height: max-content;
  margin-right: 8px;
`;

export const SecondaryTitle = styled.span`
  display: inline-block;
  margin-left: 8px;
  color: ${Colors.Gray[500]};
  font-weight: 400;
  font-size: 0.875rem;
`;

export const Placeholder = styled.span`
  color: ${Colors.Gray[500]};
`;

export const IconContainer = styled.span`
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  display: flex;
  align-items: center;
  transition: 0.2s;
`;

export const DropDown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  margin-top: 8px;
  background: ${Colors.Common.White};
  border-radius: 8px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  z-index: 10;
`;

export const Option = styled.button<{ $optionWithAvatar?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
  width: 100%;
  padding: 10px 14px;
  color: ${Colors.Gray[900]};
  font-weight: 400;
  font-size: 0.875rem;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: ${Colors.Primary[700]};
    background-color: ${Colors.Primary[50]};
  }

  &:disabled {
    color: ${Colors.Gray[200]};
    cursor: not-allowed;
  }

  ${(props) => (props.$optionWithAvatar ? OptionWithAvatarMixin : '')}
`;

export const Selected = styled.span`
  display: inline-flex;
  align-items: center;
  margin-left: auto;
`;

export const Typography = styled(DefaultTypography)`
  margin-bottom: 0;
`;

/*  Mixins  */

const OptionWithAvatarMixin = css`
  padding: 8px 14px;
`;
