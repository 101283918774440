import { IconProps } from './types';

const LongArrowRight = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 100 24"
    width={(Number(dimension) || 24) * 4}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 12H80M80 12L75 6M80 12L75 17"
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default LongArrowRight;
