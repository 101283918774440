import { IconProps } from './types';

const Radio = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.24 7.76005C16.7979 8.31729 17.2404 8.97902 17.5424 9.70741C17.8443 10.4358 17.9997 11.2166 17.9997 12.0051C17.9997 12.7935 17.8443 13.5743 17.5424 14.3027C17.2404 15.0311 16.7979 15.6928 16.24 16.2501M7.76 16.2401C7.20214 15.6828 6.75959 15.0211 6.45764 14.2927C6.1557 13.5643 6.00028 12.7835 6.00028 11.9951C6.00028 11.2066 6.1557 10.4258 6.45764 9.69741C6.75959 8.96902 7.20214 8.30729 7.76 7.75005M19.07 4.93005C20.9447 6.80533 21.9979 9.34841 21.9979 12.0001C21.9979 14.6517 20.9447 17.1948 19.07 19.0701M4.93 19.0701C3.05529 17.1948 2.00214 14.6517 2.00214 12.0001C2.00214 9.34841 3.05529 6.80533 4.93 4.93005M14 12.0001C14 13.1046 13.1046 14.0001 12 14.0001C10.8954 14.0001 10 13.1046 10 12.0001C10 10.8955 10.8954 10.0001 12 10.0001C13.1046 10.0001 14 10.8955 14 12.0001Z"
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default Radio;
