import { IntegrationIconProps } from './types';

const Basecamp = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2C8.27812 2 2 8.27812 2 16C2 23.7219 8.27812 30 16 30C23.7219 30 30 23.7219 30 16C30 8.27812 23.7219 2 16 2Z"
      fill="white"
    />
    <path
      d="M16.0022 6C10.0803 6 5.44525 13.801 5 20.3895C6.85671 23.7275 11.3404 25 16.0022 25C20.664 25 25.1433 23.7459 27 20.3895C26.5592 13.801 21.9197 6 16.0022 6Z"
      fill="#B3DCFF"
    />
    <path
      d="M22.8543 14.193C21.5542 12.4548 19.8979 10.6106 18.9361 10.6106C17.9744 10.6106 15.2895 15.5346 14.0383 15.5346C12.7871 15.5346 12.1638 13.2939 10.6499 13.3031C8.58395 13.3031 6.5625 19.5965 6.5625 19.5965C6.5625 19.5965 7.39958 23.4371 16.1533 23.4371C24.907 23.4371 25.606 19.6611 25.606 19.6611C24.9706 17.7006 24.042 15.8555 22.8543 14.193Z"
      fill="#5ECC62"
    />
    <path
      d="M11.3086 19.0708C11.3086 18.8437 11.3736 18.6217 11.4954 18.4329C11.6173 18.2442 11.7904 18.097 11.993 18.0101C12.1956 17.9232 12.4185 17.9005 12.6336 17.9448C12.8486 17.9891 13.0462 18.0984 13.2012 18.259C13.3563 18.4195 13.4619 18.6241 13.5047 18.8468C13.5474 19.0695 13.5255 19.3003 13.4416 19.5101C13.3577 19.7199 13.2155 19.8992 13.0332 20.0253C12.8509 20.1514 12.6366 20.2188 12.4173 20.2188C12.1232 20.2188 11.8412 20.0978 11.6333 19.8825C11.4254 19.6672 11.3086 19.3752 11.3086 19.0708ZM18.4727 19.0708C18.4727 19.2978 18.5378 19.5198 18.6596 19.7086C18.7814 19.8974 18.9546 20.0445 19.1571 20.1314C19.3597 20.2183 19.5826 20.241 19.7977 20.1967C20.0128 20.1524 20.2103 20.0431 20.3654 19.8825C20.5204 19.722 20.626 19.5174 20.6688 19.2947C20.7116 19.072 20.6896 18.8412 20.6057 18.6314C20.5218 18.4216 20.3797 18.2424 20.1974 18.1162C20.015 17.9901 19.8007 17.9227 19.5814 17.9227C19.4358 17.9227 19.2916 17.9524 19.1571 18.0101C19.0226 18.0678 18.9004 18.1524 18.7975 18.259C18.6945 18.3656 18.6128 18.4921 18.5571 18.6314C18.5014 18.7707 18.4727 18.92 18.4727 19.0708ZM18.2724 20.7536C18.3053 20.6716 18.3074 20.5798 18.2781 20.4963C18.2489 20.4128 18.1905 20.3437 18.1145 20.3026C18.0384 20.2615 17.9503 20.2514 17.8674 20.2743C17.7845 20.2972 17.713 20.3514 17.6668 20.4262C17.4916 20.7084 17.2471 20.9373 16.9585 21.0896C16.6698 21.2419 16.3473 21.3121 16.0238 21.293C15.6914 21.301 15.3621 21.2245 15.0648 21.0702C14.7675 20.9158 14.5113 20.6884 14.3185 20.4078C14.2678 20.3357 14.1928 20.286 14.1083 20.2686C14.0238 20.2511 13.9361 20.2673 13.8626 20.3137C13.7891 20.3602 13.7352 20.4337 13.7116 20.5194C13.688 20.6052 13.6964 20.697 13.7352 20.7766C13.9893 21.1605 14.3314 21.473 14.7308 21.6862C15.1302 21.8995 15.5745 22.0067 16.0238 21.9984C16.472 22.0192 16.9174 21.9145 17.313 21.6955C17.7087 21.4764 18.0401 21.1511 18.2724 20.7536Z"
      fill="black"
    />
    <path
      d="M14.1945 17.6463C12.8365 17.6463 12.1374 16.2631 11.759 15.4009C11.4206 14.631 11.3582 13.4092 10.3965 13.3769C10.4826 13.3396 10.5747 13.3192 10.6681 13.317C12.182 13.317 12.8231 15.5485 14.0565 15.5485C15.2898 15.5485 17.9792 10.6106 18.9365 10.6106C19.1494 10.6223 19.3568 10.6855 19.542 10.795C19.447 10.7765 19.349 10.7814 19.2562 10.8095C19.1634 10.8375 19.0782 10.8878 19.0077 10.9564C18.7005 11.2837 15.8776 17.6463 14.1945 17.6463Z"
      fill="#00AD45"
    />
  </svg>
);
export default Basecamp;
