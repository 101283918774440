import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { DropDownMenuOption } from '@replai-platform/ui-components';
import useAllKindTags from '../../../api/hooks/tags/useAllKindTags';
import type { RootState } from '../../../store/rootReducer';
import { ProjectActions } from '../../../store/project';
import { capitalizeWords } from '../../../utils';

const HALF_DAY_IN_MS = 12 * 60 * 60 * 1000;

const useTaggedWithFilter = (): {
  taggedWithFilterOptions: DropDownMenuOption[];
  hasData: boolean;
  isLoading: boolean;
} => {
  const dispatch = useDispatch();
  const projectId = useSelector((state: RootState) => state.project.id);
  const contextTagTypes = useSelector((state: RootState) => state.project.config.contextTagTypes);

  // Fetch and update project networks
  const { isSuccess, isLoading, data } = useAllKindTags(
    {
      projectId,
      tagFiltering: {
        tagTypesToConsider: contextTagTypes,
      },
    },
    { cacheTime: HALF_DAY_IN_MS, enabled: contextTagTypes && contextTagTypes.length > 0 }
  );

  useEffect(() => {
    if (isSuccess && data && data.length > 0) {
      dispatch(ProjectActions.changeContextTags(data));
    }
  }, [data, isSuccess, dispatch]);

  // Create filter options
  const taggedWithFilterOptions = useMemo(
    () =>
      isSuccess && data
        ? [
            {
              label: 'All Tags',
              isAllOption: true,
              selected: false,
            },
            ...data.map((contextTag) => ({
              label: [capitalizeWords(contextTag.type), contextTag.value].join(': '),
              id: contextTag.id,
              selected: false,
            })),
          ]
        : [],
    [data, isSuccess]
  );

  return { taggedWithFilterOptions, isLoading, hasData: taggedWithFilterOptions.length > 0 };
};

export default useTaggedWithFilter;
