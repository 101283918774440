import styled from 'styled-components';
import { TagBreakdownT } from '../timeline.types';
import { getTimelineTrackSegmentColor } from '../utils';
import GroupRow from './TimelineRow/GroupRow';
import TimelineRow from './TimelineRow/TimelineRow';
import TimelineTimestampLine from './TimelineTimestampLine/TimelineTimestampLine';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { ColorTypes } from '../../Colors';

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 300px;
`;

interface DataGroupedByType {
  [type: string]: TagBreakdownT[];
}

export interface TimelineBodyProps {
  data: TagBreakdownT[];
  videoDurationInSeconds: number;
  recommendationsOnly: boolean;
  groupByTagCategory: boolean;
  loading: boolean;
  playedFraction?: number;
  playedSeconds?: number;
  seekToPoint?: (point: number) => void;
  showTimelineTimestampLine?: boolean;
  showDividers?: boolean;
  showTypeAndValue?: boolean;
  showRecommendationsOnTop?: boolean;
  onTagClick?: ({
    value,
    type,
    checked,
  }: {
    value: string;
    type: string;
    checked: boolean;
  }) => void | Promise<void>;
  timestampLineVerticalOffset?: number;
  rowsSpacing?: string;
  showEmptyTracks?: boolean;
  showDifference?: boolean;
  showFake?: boolean;
  customTrackColorType?: ColorTypes;
}

const TimelineBody: React.FC<TimelineBodyProps> = ({
  data,
  videoDurationInSeconds,
  recommendationsOnly,
  groupByTagCategory,
  loading,
  playedFraction = 0,
  playedSeconds = 0,
  seekToPoint,
  showTimelineTimestampLine = true,
  showDividers = true,
  showTypeAndValue = true,
  showRecommendationsOnTop = false,
  onTagClick,
  timestampLineVerticalOffset,
  rowsSpacing,
  showEmptyTracks = false,
  showDifference,
  showFake = false,
  customTrackColorType,
}) => {
  let dataToRender: TimelineBodyProps['data'] | DataGroupedByType = data;
  if (recommendationsOnly) {
    dataToRender = data.filter((row) => !!row.recommendations);
  }
  const tagCategories = Array.from(new Set(data.map((entry) => entry.type)));
  if (groupByTagCategory) {
    dataToRender = dataToRender.reduce<DataGroupedByType>((r, a) => {
      r[a.type] = r[a.type] || [];
      r[a.type].push(a);
      return r;
    }, {});
  }

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingScreen />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      {groupByTagCategory
        ? Object.keys(dataToRender as DataGroupedByType).map((category) => (
            <GroupRow
              key={category}
              tagCategory={category}
              numberOfTags={
                (dataToRender as DataGroupedByType)[category].length
              }
            >
              {(dataToRender as DataGroupedByType)[category].map((entry) => (
                <TimelineRow
                  key={entry.key}
                  color={getTimelineTrackSegmentColor(
                    tagCategories.indexOf(entry.type)
                  )}
                  segments={entry.segments}
                  type={entry.type}
                  value={entry.value}
                  description={entry.description}
                  duration={videoDurationInSeconds}
                  seekToPoint={seekToPoint}
                  recommendations={entry.recommendations}
                  showDividers={showDividers}
                  showTypeAndValue={showTypeAndValue}
                  showRecommendationsOnTop={showRecommendationsOnTop}
                  onTagClick={onTagClick}
                  rowsSpacing={rowsSpacing}
                />
              ))}
            </GroupRow>
          ))
        : (dataToRender as TimelineBodyProps['data']).map((entry) => (
            <TimelineRow
              key={entry.key}
              color={
                customTrackColorType ??
                getTimelineTrackSegmentColor(tagCategories.indexOf(entry.type))
              }
              segments={entry.segments}
              type={entry.type}
              value={entry.value}
              description={entry.description}
              duration={videoDurationInSeconds}
              seekToPoint={seekToPoint}
              recommendations={entry.recommendations}
              showDividers={showDividers}
              showTypeAndValue={showTypeAndValue}
              showRecommendationsOnTop={showRecommendationsOnTop}
              onTagClick={onTagClick}
              rowsSpacing={rowsSpacing}
              showEmptyTracks={showEmptyTracks}
              showDifference={showDifference}
              showFake={showFake}
              difference={entry.difference}
              differenceDetails={entry.differenceDetails}
            />
          ))}
      {showTimelineTimestampLine && (
        <TimelineTimestampLine
          playedFraction={playedFraction}
          playedSeconds={playedSeconds}
          verticalOffset={timestampLineVerticalOffset}
        />
      )}
    </Container>
  );
};

export default TimelineBody;
