import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as SDK from '@replai-platform/sdk';
import subDays from 'date-fns/subDays';
import sortBy from 'lodash/sortBy';
import type { RootState } from '../../../store/rootReducer';
import { FilterActions } from '../../../store/filters';
import { ProjectActions } from '../../../store/project';
import useMarketTags from '../../../api/hooks/market/useMarketTags';
import marketTagTypesToExclude from '../../../utils/market/tagTypesToExclude';
import { CustomMarketTagsFilterProps } from '../Filters';
import { GENRES_ALLOWED_CUSTOM_TAGS } from '../../../routes/Market/constants';

const useMarketTagsFilter = (): {
  marketTagsFilterOptions: CustomMarketTagsFilterProps['options'];
  marketTagsFilterOnChange: (options: string[]) => void;
  loading: boolean;
} => {
  const dispatch = useDispatch();
  const {
    marketAppsToConsider,
    marketAppsToExclude,
    ageRangeDays,
    marketTags,
    ageStartDate,
    ageEndDate,
    startDate,
    endDate,
  } = useSelector((state: RootState) => state.filters);

  const onlyCompetitors = useSelector((state: RootState) => state.filters.onlyMarketCompetitors);
  const projectId = useSelector((state: RootState) => state.project.id);
  const organizationName = useSelector((state: RootState) => state.project.organizationName);
  const projectMarketGenres = useSelector((state: RootState) => state.project.config.marketGenres);
  const maxFirstAppearanceSeconds =
    useSelector((state: RootState) => state.filters.maxFirstAppearanceSeconds) ?? undefined;
  const tagsConfig = useSelector((state: RootState) => state.project.config.tagsTypes);
  const allowUntaggedMarketAssets = useSelector((state: RootState) => state.project.config.allowUntaggedMarketAssets);
  const projectMarketTags = useSelector((state: RootState) => state.project.marketTags);

  // Fetch and update market tags
  const getMarketTagsParams = useMemo<SDK.GetMarketTagsRequest>(
    () => ({
      projectId,
      tagsFilters: {
        tagTypesToExclude: marketTagTypesToExclude({ projectTagTypesConfig: tagsConfig ?? {} }).map((type) => ({
          type,
        })),
      },
      adTagsFilters: { maxFirstAppearanceSeconds },
      appsFilters: {
        appIdsToInclude: marketAppsToConsider.map((ma) => ma.id),
        appIdsToExclude: marketAppsToExclude.map((ma) => ma.id),
        publishersToExclude: [organizationName],
        ...(onlyCompetitors && projectMarketGenres ? { genres: projectMarketGenres } : {}),
      },
      adsFilters: {
        launchDateMin: ageRangeDays ? subDays(new Date(), Number(ageRangeDays)).toISOString() : undefined,
        launchDateMax: ageRangeDays ? new Date().toISOString() : undefined,
      },
      marketMetricsFilters: {
        assetLaunchDateStartTimestamp: ageStartDate ? new Date(ageStartDate).getTime() : undefined,
        assetLaunchDateEndTimestamp: ageEndDate ? new Date(ageEndDate).getTime() : undefined,
        startDate,
        endDate,
      },
      hasPostQaAnnotations: allowUntaggedMarketAssets ? false : !!onlyCompetitors,
      ...(onlyCompetitors && projectMarketGenres && !allowUntaggedMarketAssets
        ? { assetsFilters: { taggedGenres: projectMarketGenres } }
        : {}),
    }),
    [
      projectId,
      tagsConfig,
      maxFirstAppearanceSeconds,
      allowUntaggedMarketAssets,
      marketAppsToConsider,
      marketAppsToExclude,
      organizationName,
      projectMarketGenres,
      ageRangeDays,
      onlyCompetitors,
      ageStartDate,
      ageEndDate,
      startDate,
      endDate,
    ]
  );
  const { data: fetchedMarketTags, isLoading } = useMarketTags<SDK.Tag[]>(getMarketTagsParams, {
    select: (data) => {
      const tags = data
        .filter(({ kind }) =>
          onlyCompetitors
            ? kind !== SDK.TagKind.Custom ||
              projectMarketGenres?.some((genre) => GENRES_ALLOWED_CUSTOM_TAGS.includes(genre))
            : kind !== SDK.TagKind.Custom
        )
        .map(({ id, type, value }) => ({ id, type, value }));
      const sortedMarketTags = sortBy(tags, ['type', 'value']);
      return sortedMarketTags;
    },
    onSuccess: (data) => dispatch(ProjectActions.changeMarketTags(data)),
  });

  // Create filter options
  const marketTagsFilterOptions = useMemo<CustomMarketTagsFilterProps['options']>(
    () =>
      !!fetchedMarketTags?.length || !!projectMarketTags.length
        ? [
            ...(fetchedMarketTags ?? projectMarketTags).map((tag) => ({
              id: tag.id ?? null,
              type: tag.type ?? null,
              value: tag.value ?? null,
              selected:
                marketTags.length === 0
                  ? false
                  : !!marketTags.find((mt) => mt.type === tag.type && mt.value === tag.value),
            })),
          ]
        : [],
    [fetchedMarketTags, projectMarketTags, marketTags]
  );

  const marketTagsFilterOnChange = useCallback(
    (options: string[]) => {
      const selectedOptions = options
        .map((option) => (JSON.parse(option) as SDK.Tag) ?? '')
        .filter((option) => option?.type?.length > 0);

      dispatch(
        FilterActions.changeMarketTags({
          value: selectedOptions,
          logEvent: true,
        })
      );
    },
    [dispatch]
  );

  return { marketTagsFilterOptions, marketTagsFilterOnChange, loading: isLoading };
};

export default useMarketTagsFilter;
