import { Filters } from '../../utils/enums';
import ActiveFilterBar from './ActiveFilterBar';
import CoreFilterBar, { type CoreFilterBarProps } from './CoreFilterBar';

interface FilterBarProps extends CoreFilterBarProps {
  eventPrefix: string;
  supportedFilters?: Filters[];
  withActiveFilters?: boolean;
  withIntroTagsFilter?: boolean;
}

const FilterBar: React.VFC<FilterBarProps> = ({
  eventPrefix,
  supportedFilters = [],
  withActiveFilters = true,
  withAddFilterButton = true,
  withDateFilter = true,
  withAgeFilter = false,
  withIntroTagsFilter = false,
  withSearchInput = false,
  withCustomFilter = false,
  withCustomFilterLast = false,
  withOnlyMarketCompetitors = false,
  networkFilter,
  assetTypeFilter,
  buyingStrategyFilter,
  agencyFilter,
  promotedObjectFilter,
  kpiFilter,
  taggedWithFilter,
  campaignFilter,
  monthFilter,
  customFilter,
  marketAppsFilter,
  publisherFilter,
  countryFilter,
  customMarketTagsFilter,
  customTagsFilter,
  dateFilter,
  positiveInsightsToggle,
  introInsightsToggle,
  onSearchTermChangeEnd,
  onlyMarketCompetitors,
  page,
  disabledDatePicker,
}) => (
  <div data-test="filter-bar">
    <CoreFilterBar
      withAddFilterButton={withAddFilterButton}
      withDateFilter={withDateFilter}
      withAgeFilter={withAgeFilter}
      withSearchInput={withSearchInput}
      withCustomFilter={withCustomFilter}
      withCustomFilterLast={withCustomFilterLast}
      withIntroTagsFilter={withIntroTagsFilter}
      withOnlyMarketCompetitors={withOnlyMarketCompetitors}
      networkFilter={networkFilter}
      buyingStrategyFilter={buyingStrategyFilter}
      assetTypeFilter={assetTypeFilter}
      agencyFilter={agencyFilter}
      promotedObjectFilter={promotedObjectFilter}
      kpiFilter={kpiFilter}
      taggedWithFilter={taggedWithFilter}
      campaignFilter={campaignFilter}
      monthFilter={monthFilter}
      customFilter={customFilter}
      marketAppsFilter={marketAppsFilter}
      publisherFilter={publisherFilter}
      countryFilter={countryFilter}
      customMarketTagsFilter={customMarketTagsFilter}
      customTagsFilter={customTagsFilter}
      dateFilter={dateFilter}
      onSearchTermChangeEnd={onSearchTermChangeEnd}
      onlyMarketCompetitors={onlyMarketCompetitors}
      positiveInsightsToggle={positiveInsightsToggle}
      introInsightsToggle={introInsightsToggle}
      page={page}
      disabledDatePicker={disabledDatePicker}
    />
    {withActiveFilters && <ActiveFilterBar supportedFilters={supportedFilters} eventPrefix={eventPrefix} />}
  </div>
);

export default FilterBar;
