/* eslint-disable react/jsx-props-no-spreading */
import { FlatSocialIconProps } from './types';

const Figma = ({ color, dimension, ...props }: FlatSocialIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.1599 0H15.8398C18.2246 0 20.1598 1.93525 20.1598 4.32003C20.1598 5.99123 19.2095 7.44161 17.82 8.15996C19.2095 8.87835 20.1598 10.3288 20.1598 12C20.1598 14.3848 18.2246 16.32 15.8398 16.32C14.4832 16.32 13.2721 15.6938 12.48 14.7149V19.68C12.48 22.0648 10.5447 24 8.1599 24C5.77511 24 3.83984 22.0648 3.83984 19.68C3.83984 18.0088 4.79023 16.5583 6.1797 15.84C4.79023 15.1216 3.83984 13.6712 3.83984 12C3.83984 10.3288 4.79022 8.87835 6.17968 8.15996C4.79023 7.44161 3.83984 5.99123 3.83984 4.32003C3.83984 1.93525 5.77511 0 8.1599 0ZM4.79984 4.32003C4.79984 2.46545 6.3053 0.96 8.1599 0.96H11.5197V7.67994H8.1599L8.14949 7.67995C6.29965 7.67433 4.79984 6.17112 4.79984 4.32003ZM11.52 11.9543C11.5198 11.9695 11.5197 11.9847 11.5197 12C11.5197 12.0152 11.5198 12.0304 11.52 12.0457V15.3599H8.15988C6.3053 15.36 4.79984 13.8546 4.79984 12C4.79984 10.1488 6.29971 8.64553 8.14958 8.63996L8.1599 8.63997H11.5197L11.52 11.9543ZM12.48 12.0402V11.9597C12.5015 10.1282 13.9912 8.64734 15.826 8.63997H15.8398C15.8432 8.63997 15.8467 8.63996 15.8501 8.63996C17.7 8.64553 19.1998 10.1488 19.1998 12C19.1998 13.8546 17.6944 15.36 15.8398 15.36C13.9986 15.36 12.5016 13.8763 12.48 12.0402ZM13.1251 8.63997C12.8881 8.83174 12.6717 9.04806 12.48 9.28506V8.63997H13.1251ZM15.8502 7.67995C15.8467 7.67994 15.8433 7.67994 15.8398 7.67994C15.8345 7.67994 15.8293 7.67995 15.824 7.67997H12.48V0.96H15.8398C17.6944 0.96 19.1998 2.46545 19.1998 4.32003C19.1998 6.17112 17.7 7.67433 15.8502 7.67995ZM8.1599 16.3199C6.3053 16.3199 4.79984 17.8254 4.79984 19.68C4.79984 21.5345 6.3053 23.04 8.1599 23.04C10.0145 23.04 11.52 21.5345 11.52 19.68V16.3199H8.1599Z"
      fill={color || '#98A2B3'}
    />
  </svg>
);
export default Figma;
