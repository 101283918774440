import styled from 'styled-components';
import Colors from '../Colors';

export interface LinearProgressProps {
  value: number;
}

const Container = styled.div`
  width: 100%;
  height: 0.5rem;
  overflow: hidden;
  background: ${Colors.Primary[100]};
  border-radius: 0.25rem;
`;

const ValueBar = styled.div<{ value: number }>`
  width: ${({ value }) => `${100 * value}%`};
  height: 100%;
  background: ${Colors.Primary[500]};
  border-radius: 0.25rem;
  transition: width 0.2s linear;
`;

const LinearProgress = ({ value }: LinearProgressProps) => (
  <Container>
    <ValueBar value={value} />
  </Container>
);

export default LinearProgress;
