import { CountryIconProps } from './types';

const RS = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M12 0C5.37262 0 0 5.37262 0 12C0 13.4678 0.264047 14.874 0.746391 16.1739H23.2537C23.736 14.874 24 13.4678 24 12C24 5.37262 18.6274 0 12 0Z"
      fill="#0052B4"
    />
    <path
      d="M12.0007 0.00012207C6.8411 0.00012207 2.44259 3.25662 0.74707 7.82623H23.2543C21.5588 3.25662 17.1603 0.00012207 12.0007 0.00012207Z"
      fill="#D80027"
    />
    <path
      d="M3.10352 6.78314V12.7694C3.10352 16.1746 7.552 17.2179 7.552 17.2179C7.552 17.2179 12.0004 16.1746 12.0004 12.7694V6.78314H3.10352Z"
      fill="#D80027"
    />
    <path
      d="M4.94238 7.82638H10.1598V5.73941L9.11632 6.26113L7.55112 4.69592L5.98587 6.26113L4.94238 5.73941V7.82638Z"
      fill="#FFDA44"
    />
    <path
      d="M10.9565 13.6026L7.56514 10.2112L4.17383 13.6026L5.28059 14.7093L7.56514 12.4248L9.84969 14.7093L10.9565 13.6026Z"
      fill="#FFDA44"
    />
    <path
      d="M10.9565 10.4341H9.37414C9.46311 10.2805 9.51486 10.1027 9.51486 9.91244C9.51486 9.33607 9.04766 8.86896 8.47137 8.86896C8.07317 8.86896 7.72737 9.09199 7.55145 9.41988C7.37553 9.09204 7.02973 8.86896 6.63153 8.86896C6.05525 8.86896 5.58805 9.33607 5.58805 9.91244C5.58805 10.1027 5.6398 10.2806 5.72877 10.4341H4.17383C4.17383 11.0104 4.67581 11.4776 5.25205 11.4776H5.21731C5.21731 12.0539 5.68447 12.521 6.2608 12.521C6.2608 13.0313 6.62726 13.4553 7.11125 13.546L6.28906 15.4023C6.68309 15.5625 7.11369 15.6515 7.56519 15.6515C8.01669 15.6515 8.44728 15.5625 8.84131 15.4023L8.01912 13.546C8.50311 13.4553 8.86958 13.0314 8.86958 12.521C9.44591 12.521 9.91306 12.0539 9.91306 11.4776H9.87833C10.4545 11.4776 10.9565 11.0103 10.9565 10.4341Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default RS;
