import { Colors } from '@replai-platform/ui-components';
import * as Styled from './styles';

export interface SidebarSectionProps {
  title: string;
  children: React.ReactNode;
}

const SidebarSection: React.FC<SidebarSectionProps> = ({ title, children }) => (
  <Styled.SidebarSectionContainer>
    <Styled.TypographyClean type="text-lg" color={Colors.Gray[900]} fontWeight="medium">
      {title}
    </Styled.TypographyClean>
    <Styled.CustomDivider />
    {children}
  </Styled.SidebarSectionContainer>
);

export default SidebarSection;
