import { TagsConfig } from '../types';
import { CoreTags, TagGroup } from '../enums';

/**
 * Specifies the configuration for each tag
 */
export const TAGS_CONFIG: TagsConfig = {
  aspectRatio: {
    group: TagGroup.Orientation,
  },
  audio: {
    group: TagGroup.Audio,
  },
  colorfulness: {
    group: TagGroup.ColorTheory,
  },
  colorTemperature: {
    group: TagGroup.ColorTheory,
  },
  cta: {
    group: TagGroup.Cta,
  },
  cutFrequency: {
    group: TagGroup.Movement,
  },
  duration: {
    group: TagGroup.Duration,
  },
  footer: {
    group: TagGroup.TextOverlayFooter,
  },
  footerBackgroundColor: {
    group: TagGroup.TextOverlayFooter,
  },
  footerNumLines: {
    group: TagGroup.TextOverlayFooter,
  },
  footerTextColor: {
    group: TagGroup.TextOverlayFooter,
  },
  header: {
    group: TagGroup.TextOverlayHeader,
  },
  headerBackgroundColor: {
    group: TagGroup.TextOverlayHeader,
  },
  headerNumLines: {
    group: TagGroup.TextOverlayHeader,
  },
  headerTextColor: {
    group: TagGroup.TextOverlayHeader,
  },
  keyword: {
    group: TagGroup.TextAnalysis,
  },
  language: {
    group: TagGroup.TextAnalysis,
  },
  introLoudness: {
    group: TagGroup.Audio,
  },
  luminosity: {
    group: TagGroup.ColorTheory,
  },
  music: {
    group: TagGroup.Music,
  },
  numCtas: {
    group: TagGroup.Cta,
  },
  numberOfScenes: {
    group: TagGroup.Movement,
  },
  orientation: {
    group: TagGroup.Orientation,
  },
  pace: {
    group: TagGroup.Movement,
  },
  saturation: {
    group: TagGroup.ColorTheory,
  },
  sceneDuration: {
    group: TagGroup.Movement,
  },
  sceneTransitionsDiff: {
    group: TagGroup.Movement,
  },
  text: {
    group: TagGroup.TextAnalysis,
  },
};

/**
 * List of tags that we don't want to generate insights from.
 * Also the list of core tags we don't want to show in the Tags Library.
 */
export const CORE_TAGS_WITHOUT_INSIGHTS = [
  CoreTags.AUDIO,
  CoreTags.ASPECT_RATIO,
  CoreTags.COLORFULNESS,
  CoreTags.FOOTER,
  CoreTags.FOOTER_BACKGROUND_COLOR,
  CoreTags.FOOTER_NUM_LINES,
  CoreTags.FOOTER_TEXT_COLOR,
  CoreTags.HEADER,
  CoreTags.HEADER_BACKGROUND_COLOR,
  CoreTags.HEADER_NUM_LINES,
  CoreTags.HEADER_TEXT_COLOR,
  CoreTags.KEYWORD,
  CoreTags.LANGUAGE,
  CoreTags.NUM_CTAS,
  CoreTags.NUMBER_OF_SCENES,
  CoreTags.ORIENTATION,
  CoreTags.TEXT,
];
