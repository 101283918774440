import { CountryIconProps } from './types';

const BM = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3574)">
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C0 12.0029 12 0.0013125 12 0C18.6274 0 24 5.37262 24 12Z"
        fill="#D80027"
      />
      <path
        d="M12.0001 5.73889V9.78622L7.82617 5.73889H12.0001Z"
        fill="#0052B4"
      />
      <path
        d="M6.26098 11.9996H9.78649L6.26098 7.30396L5.73926 10.9561L6.26098 11.9996Z"
        fill="#0052B4"
      />
      <path
        d="M11.9688 12.0012H12.0009C12.0009 11.9904 12.0009 11.9798 12.0009 11.969C11.9902 11.9797 11.9795 11.9905 11.9688 12.0012Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.999 6.26135C11.999 4.14986 11.999 2.76635 11.999 0.000488281H11.997C5.37048 0.00161328 -0.000976562 5.37372 -0.000976562 12.0005H6.25988V8.47497L9.7854 12.0005H11.967C11.9776 11.9898 11.9883 11.9791 11.9991 11.9684C11.9991 11.1599 11.9991 10.4386 11.9991 9.78696L8.47346 6.26135H11.999Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.07027 1.56519C4.19344 2.63389 2.63311 4.19417 1.56445 6.071V12.5217H4.69491V4.69573V4.69564H12.521C12.521 3.70831 12.521 2.76772 12.521 1.56519H6.07027Z"
        fill="#D80027"
      />
      <path
        d="M12.476 11.0002L7.73539 6.25977H6.25977V6.25986L12.4758 12.4759H12.4759C12.476 12.476 12.476 11.4584 12.476 11.0002Z"
        fill="#D80027"
      />
      <path
        d="M13.5654 6.25989V11.999C13.5654 14.7946 20.8698 14.7946 20.8698 11.999V6.25989H13.5654Z"
        fill="#F3F3F3"
      />
      <path
        d="M13.5654 12.0005V12.0005C13.5654 14.7961 17.2176 15.6527 17.2176 15.6527C17.2176 15.6527 20.8698 14.7961 20.8698 12.0005V12.0005H13.5654Z"
        fill="#6DA544"
      />
      <path
        d="M17.2171 9.70447L15.5215 10.4349V12.0001L17.2171 13.0436L18.9128 12.0001V10.4349L17.2171 9.70447Z"
        fill="#A2001D"
      />
      <path
        d="M18.9128 8.86865H15.5215V10.4339H18.9128V8.86865Z"
        fill="#338AF3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3574">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default BM;
