import { CountryIconProps } from './types';

const FR = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M23.9999 11.9999C23.9999 6.84037 20.7434 2.44185 16.1738 0.746338V23.2536C20.7434 21.558 23.9999 17.1595 23.9999 11.9999Z"
      fill="#D80027"
    />
    <path
      d="M0 12.0008C0 17.1604 3.25655 21.5589 7.82611 23.2544V0.747192C3.25655 2.44271 0 6.84122 0 12.0008Z"
      fill="#0052B4"
    />
  </svg>
);
export default FR;
