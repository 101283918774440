import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase } from '@replai-platform/ui-components';
// eslint-disable-next-line import/no-cycle
import { logEvent } from '../../analytics';
import { TagsPageGroupingState } from '../../utils/enums/tags';

export type TableRowData = {
  name: string;
  tag: SDK.Tag & { thumbnailUrl?: string };
  cluster: {
    metrics: SDK.Metric;
  };
};

export type TagsState = {
  group: TagsPageGroupingState;
  tableOffset: number;
};

const initialState: TagsState = {
  group: {
    type: 'custom',
  },
  tableOffset: 0,
};

export const TagsSlice = createSlice({
  name: 'Tags',
  initialState,
  reducers: {
    reset: (state) => Object.assign(state, initialState),
    changeGroup: (state, action: PayloadAction<TagsPageGroupingState>) => {
      state.group = action.payload;
      logEvent({
        component: 'Tags',
        action: `Group ${camelCaseToCapitalCase(action.payload.type)}`,
        category: 'user_actions',
        parameters: { tag: `${camelCaseToCapitalCase(action.payload.value ?? SDK.messages.NOT_AVAILABLE)}` },
      });
    },
    changeTableOffset: (state, action: PayloadAction<number>) => {
      state.tableOffset = action.payload;
    },
  },
});

export const TagsActions = TagsSlice.actions;
export const TagsReducer = TagsSlice.reducer;
export const TagsInitialState = initialState;
