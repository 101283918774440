import styled, { css } from 'styled-components';
import Colors from '../Colors';

export const Root = styled.div`
  display: inline-block;
  background-color: ${Colors.Common.White};
`;

export const Switch = styled.div<{ $isOutlined?: boolean }>`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  height: 2.5rem;

  ${(props) => (props.$isOutlined ? OutlinedMixin : '')}
`;

export const ToggleHolder = styled.span`
  position: relative;
  display: flex;
  align-self: flex-start;
  width: 36px;
  height: 20px;
`;

export const Input = styled.input`
  display: none;
`;

export const LabelHolder = styled.div`
  display: flex;
  flex-direction: column;
  text-wrap: nowrap;
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  color: ${Colors.Gray[700]};
`;

export const SecondaryLabel = styled(Label)`
  font-weight: normal;
  color: ${Colors.Gray[500]};
`;

export const Slider = styled.button<{
  $checked: boolean;
  $backgroundColor?: string;
  $boxShadow?: string;
  $cursor?: string;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 0;
  border-radius: 0.75rem;
  outline: none;
  transition: 0.2s;
  background-color: ${(props) => props.$backgroundColor};
  box-shadow: ${(props) => props.$boxShadow};
  cursor: ${(props) => props.$cursor};

  :before {
    position: absolute;
    bottom: 0.125rem;
    left: 0.125rem;
    width: 1rem;
    height: 1rem;
    background-color: ${Colors.Common.White};
    border-radius: 50%;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    transition: 0.2s;
    content: '';
    ${({ $checked }) => ($checked ? 'transform: translateX(16px);' : '')}
  }
`;

/*  Mixins  */

const OutlinedMixin = css`
  padding: 0.5625rem;
  border: 1px solid ${Colors.Gray[300]};
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
`;
