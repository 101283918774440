import { CountryIconProps } from './types';

const GI = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3627)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F9F9F9"
      />
      <path
        d="M11.9997 24.0007C17.1593 24.0007 21.5578 20.7442 23.2533 16.1746H0.746094C2.44166 20.7442 6.84012 24.0007 11.9997 24.0007Z"
        fill="#D80027"
      />
      <path
        d="M16.6955 9.91359V8.34839H17.2173V7.30491H16.1738V7.82667H15.1303V7.30491H14.0869V8.34839H14.6086V9.91359H13.5651V6.26152H14.0869V5.21808H13.0434V5.7397H12.5216V5.21808H11.4782V5.7397H10.9564V5.21808H9.91292V6.26152H10.4347V9.91359H9.3912V8.34839H9.91292V7.30491H8.86943V7.82667H7.82599V7.30491H6.78251V8.34839H7.30423V9.91359H6.26074V14.0875H17.739V9.91359H16.6955Z"
        fill="#D80027"
      />
      <path
        d="M12 13.5651C11.1356 13.5651 10.4348 14.2659 10.4348 15.1303C10.4348 15.8117 10.8705 16.3913 11.4783 16.6061V18.7826H9.91309V20.8695H12.5218V16.6062C13.1296 16.3913 13.5653 15.8118 13.5653 15.1304C13.5652 14.266 12.8644 13.5651 12 13.5651ZM12 15.6521C11.7119 15.6521 11.4783 15.4185 11.4783 15.1304C11.4783 14.8422 11.7119 14.6087 12 14.6087C12.2881 14.6087 12.5217 14.8422 12.5217 15.1304C12.5217 15.4185 12.2881 15.6521 12 15.6521Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3627">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default GI;
