import { CountryIconProps } from './types';

const BN = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0002 23.9977C18.6266 23.9977 23.9984 18.6259 23.9984 11.9995C23.9984 5.37302 18.6266 0.0012207 12.0002 0.0012207C5.37375 0.0012207 0.00195312 5.37302 0.00195312 11.9995C0.00195312 18.6259 5.37375 23.9977 12.0002 23.9977Z"
      fill="#F0F0F0"
    />
    <path
      d="M1.6094 6.00122C1.06784 6.93912 0.668365 7.91967 0.402344 8.91846L20.468 20.5034C21.2 19.7736 21.8495 18.9373 22.3909 17.9995L1.6094 6.00122Z"
      fill="black"
    />
    <path
      d="M17.9983 1.6092C13.1974 -1.16253 7.27791 -0.238742 3.53125 3.4969L23.5969 15.0818C24.9588 9.9694 22.799 4.38098 17.9983 1.6092Z"
      fill="#FFDA44"
    />
    <path
      d="M6.0003 22.3899C10.8011 25.1617 16.7207 24.2379 20.4673 20.5023L0.401573 8.91724C-0.960222 14.0298 1.19945 19.6182 6.0003 22.3899Z"
      fill="#FFDA44"
    />
    <path
      d="M15.3903 10.6964C15.3903 12.569 13.8722 14.0872 11.9995 14.0872C10.1268 14.0872 8.60867 12.5691 8.60867 10.6964C8.60867 10.1125 8.75629 9.56306 9.01626 9.08337C8.28045 9.83587 7.82617 10.8649 7.82617 12.0005C7.82617 14.3054 9.6946 16.1738 11.9995 16.1738C14.3044 16.1738 16.1728 14.3054 16.1728 12.0005C16.1728 10.8649 15.7186 9.83587 14.9827 9.08337C15.2427 9.56315 15.3903 10.1125 15.3903 10.6964Z"
      fill="#D80027"
    />
    <path
      d="M12.7828 6.78357H11.2178V14.6085H12.7828V6.78357Z"
      fill="#D80027"
    />
    <path
      d="M14.6082 8.34827H9.3916C9.3916 8.70841 9.70534 9.00036 10.0654 9.00036H10.0437C10.0437 9.36051 10.3357 9.65245 10.6958 9.65245C10.6958 10.0126 10.9878 10.3045 11.3479 10.3045H12.652C13.0122 10.3045 13.3041 10.0126 13.3041 9.65245C13.6643 9.65245 13.9562 9.36051 13.9562 9.00036H13.9345C14.2945 9.00031 14.6082 8.70846 14.6082 8.34827Z"
      fill="#D80027"
    />
    <path d="M15.6519 15.13H8.34863V16.695H15.6519V15.13Z" fill="#D80027" />
    <path d="M16.6944 15.651H13.5645V17.216H16.6944V15.651Z" fill="#D80027" />
    <path d="M10.4346 15.651H7.30469V17.216H10.4346V15.651Z" fill="#D80027" />
  </svg>
);
export default BN;
