/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTitle } from 'react-use';
import { Excalidraw, getElementsWithinSelection } from '@replai/replai-excalidraw';
import { ImportedDataState } from '@replai/replai-excalidraw/types/data/types';
import { ExcalidrawImperativeAPI } from '@replai/replai-excalidraw/types/types';
import { ExcalidrawElement, ExcalidrawImageElement, NonDeleted } from '@replai/replai-excalidraw/types/element/types';
import { Colors, Icons } from '@replai-platform/ui-components';
import { useCallback, useMemo, useRef, useState } from 'react';
import { GenerateImageResponse, InspireImage } from '@replai-platform/sdk';
import { UseMutationResult } from 'react-query';
import { RootState } from '../../store/rootReducer';
import {
  GENERATIVE_FRAME_ELEMENT_TAG,
  genTechComponentExcalidrawElements,
  GEN_TECH_COMPONENT_BASE_GROUP_ID,
} from './data';
import {
  addElementsToViewPointCenter,
  cleanUpElementsInsideGenerativeFrame,
  fillGenerativeFrameWithGeneratedImage,
  renderDownloadButton,
  renderReactComponentsOnGenerativeComponent,
  rerenderReactComponentsOnCanvas,
  getNextGenTechComponentGroupId,
} from './utils';
import { radiansToDegrees } from '../../utils/inspire/image';
import useGenerateImage, { GenerateImageRequestParamsAndBody } from '../../api/hooks/inspire/useGenerateImage';
import { generateImage } from './mutations';
import { GeneratedImageVariation, GenTechComponent } from './types';
import { logEvent } from '../../analytics';
import './excalidrawDist';

const N_VARIATIONS = 3;

const initialData: ImportedDataState = {
  elements: [],
  appState: { viewBackgroundColor: Colors.Common.White, currentItemFontFamily: 1 },
  scrollToContent: true,
};

const ImageGeneratorToolbarIcon = () => {
  const IconElement = Icons.getBaseIcon('Sparkles');
  const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${Colors.Primary[500]};
    border-radius: 0.5rem;
    &:hover {
      background-color: ${Colors.Primary[600]};
    }
  `;
  return (
    <Wrapper>
      <IconElement color={Colors.Common.White} />
    </Wrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin: -2rem;
`;

// UI tests cannot currently be implemented because Excalidraw cannot be run from a Node context.
// Instead, until they are supported we rely on E2E tests to validate the functionality.

const Inspire = () => {
  const projectName = useSelector((state: RootState) => state.project.name);
  const projectId = useSelector((state: RootState) => state.project.id);

  const generateImageMutations: UseMutationResult<
    GenerateImageResponse,
    unknown,
    GenerateImageRequestParamsAndBody,
    unknown
  >[] = Array.from(Array(N_VARIATIONS).keys()).map(() => useGenerateImage());

  // variation tracker
  const [generatedImageVariations, setGeneratedImageVariations] = useState<GeneratedImageVariation[]>([]);

  // hovered element on the canvas
  const [hoveredElement, setHoveredElement] = useState<ExcalidrawElement>();

  // API used to manipulate the canvas
  const [excalidrawAPI, setExcalidrawAPI] = useState<ExcalidrawImperativeAPI | null>(null);

  const [currentGenTechComponentsOnScene, setCurrentGenTechComponentsOnScene] = useState<GenTechComponent[]>([]);

  const [promptsOnScene, setPromptsOnScene] = useState<Map<string, string>>(new Map<string, string>());

  // used to manipulate HTML elements on the canvas
  const appRef = useRef<HTMLDivElement>(null);

  useTitle(`Inspire - ${projectName}`);

  const onGenerateClick = useCallback(
    (element: NonDeleted<ExcalidrawElement>, prompt?: string) => {
      if (!excalidrawAPI) {
        return;
      }
      const clickedGenerativeElementGroupId = element.groupIds[0];
      const currentElements = excalidrawAPI.getSceneElements();
      const promptText = prompt || (promptsOnScene.get(clickedGenerativeElementGroupId) ?? '');
      const parentGenerativeFrame = currentElements.find(
        (el) => el.id === `${clickedGenerativeElementGroupId}_${GENERATIVE_FRAME_ELEMENT_TAG}`
      );

      if (!parentGenerativeFrame) {
        return;
      }

      const elementsInsideGenerativeFrame = getElementsWithinSelection(currentElements, parentGenerativeFrame);

      const imagesInsideGenerativeFrame = elementsInsideGenerativeFrame.filter(
        (el) => el.type === 'image'
      ) as ExcalidrawImageElement[];

      const idsOfFilesInsideGenerativeFrame = imagesInsideGenerativeFrame.map((el) => el.fileId);

      const allFiles = excalidrawAPI.getFiles();

      const filesInsideGenerativeFrame = Object.keys(allFiles)
        .map((key) => allFiles[key])
        .filter((file) => idsOfFilesInsideGenerativeFrame.some((id) => id === file.id));

      const inspireImagesInsideFrame: InspireImage[] = filesInsideGenerativeFrame.map((file) => {
        const correspondingElement = imagesInsideGenerativeFrame.find((el) => el.fileId === file.id);

        return {
          x: (correspondingElement?.x ?? 0) - (parentGenerativeFrame?.x ?? 0),
          y: (correspondingElement?.y ?? 0) - (parentGenerativeFrame?.y ?? 0),
          height: correspondingElement?.height ?? 0,
          width: correspondingElement?.width ?? 0,
          angle: radiansToDegrees(correspondingElement?.angle ?? 0),
          base64Content: excalidrawAPI.getBase64Images()?.get(file.id)?.base64 ?? '',
        };
      });

      const variationsToAdd: GeneratedImageVariation[] = Array.from(Array(N_VARIATIONS).keys()).map(
        (variationNumber) => ({
          prompt: promptText,
          n: variationNumber,
          genTechComponentGroupId: clickedGenerativeElementGroupId,
          beingShown: variationNumber === 0,
          loading: true,
        })
      );

      setGeneratedImageVariations((existingValues) => {
        const existingValuesWithoutPreviousIterationsOnCurrentFrame = existingValues.filter(
          (iteration) => iteration.genTechComponentGroupId !== clickedGenerativeElementGroupId
        );

        return [...existingValuesWithoutPreviousIterationsOnCurrentFrame, ...variationsToAdd];
      });

      generateImageMutations.forEach((generateImageMutation, index) => {
        generateImage({
          generateImageMutation,
          projectId,
          promptText,
          parentGenerativeFrame,
          inspireImagesInsideFrame,
          excalidrawAPI,
          variationNumber: index,
          setGeneratedImageVariations,
        });
      });
    },
    [excalidrawAPI, generateImageMutations, projectId, promptsOnScene]
  );

  // handles everything that needs to happen when there's a change in the generatedImageVariations
  useMemo(() => {
    if (!excalidrawAPI) {
      return;
    }

    currentGenTechComponentsOnScene.forEach((genTechComponent) => {
      const variationToShow = generatedImageVariations.find(
        (variation) => variation.genTechComponentGroupId === genTechComponent.id && variation.beingShown
      );
      const generativeFrame = excalidrawAPI
        ?.getSceneElementsIncludingDeleted()
        .find((el) => el.id === `${genTechComponent.id}_${GENERATIVE_FRAME_ELEMENT_TAG}`);

      if (!variationToShow || !generativeFrame) {
        return;
      }

      if (variationToShow.loading) {
        cleanUpElementsInsideGenerativeFrame({ excalidrawAPI, generativeFrame });

        setCurrentGenTechComponentsOnScene((currentValues) => [
          ...currentValues.filter((val) => val.id !== genTechComponent.id),
          { ...genTechComponent, loading: true },
        ]);
        return;
      }

      setCurrentGenTechComponentsOnScene((currentValues) => [
        ...currentValues.filter((val) => val.id !== genTechComponent.id),
        { ...genTechComponent, loading: false },
      ]);

      if (variationToShow.id) {
        fillGenerativeFrameWithGeneratedImage({ generativeFrame, excalidrawAPI, imageFileId: variationToShow.id });
      }
    });
  }, [generatedImageVariations]);

  const onChangeVariationClick = useCallback(
    (element: NonDeleted<ExcalidrawElement>, increment: number) => {
      if (!excalidrawAPI) {
        return;
      }
      const clickedGenTechComponentGroupId = element.groupIds[0];
      const currentElements = excalidrawAPI.getSceneElements();
      const parentGenerativeFrame = currentElements.find(
        (el) => el.id === `${clickedGenTechComponentGroupId}_${GENERATIVE_FRAME_ELEMENT_TAG}`
      );

      if (!parentGenerativeFrame) {
        return;
      }

      const variationsOfThisFrame = generatedImageVariations
        .filter((variation) => variation.genTechComponentGroupId === clickedGenTechComponentGroupId)
        .sort((a, b) => a.n - b.n);

      const currentVariationNumber = variationsOfThisFrame.findIndex((variation) => variation.beingShown);

      const nextVariation =
        variationsOfThisFrame[
          (((currentVariationNumber + increment) % variationsOfThisFrame.length) + variationsOfThisFrame.length) %
            variationsOfThisFrame.length
        ];

      setGeneratedImageVariations(
        generatedImageVariations.map((variation) => ({
          ...variation,
          beingShown:
            variation.genTechComponentGroupId === clickedGenTechComponentGroupId
              ? variation.n === (nextVariation?.n ?? 0)
              : variation.beingShown,
        }))
      );
    },
    [generatedImageVariations]
  );

  return (
    <Container ref={appRef} data-test="inspire-main-container">
      <Excalidraw
        ref={(api: ExcalidrawImperativeAPI) => setExcalidrawAPI(api)}
        initialData={initialData}
        onScrollChange={() => {
          // while zoom or scroll is changing, no element can be considered hovered
          setHoveredElement(undefined);
          rerenderReactComponentsOnCanvas({ excalidrawAPI, appRef });
        }}
        onChange={() => {}}
        onElementsDelete={(elements) => {
          const uniqueGroupIds = [...new Set(elements.map((el) => el.groupIds[0]))].filter((groupId) =>
            groupId?.includes(GEN_TECH_COMPONENT_BASE_GROUP_ID)
          );

          if (uniqueGroupIds.length > 0) {
            setCurrentGenTechComponentsOnScene((prevValues) =>
              prevValues.filter((val) => !uniqueGroupIds.some((groupId) => groupId === val.id))
            );
          }
        }}
        onElementsReappearance={(elements) => {
          const uniqueGroupIds = [...new Set(elements.map((el) => el.groupIds[0]))].filter((groupId) =>
            groupId?.includes(GEN_TECH_COMPONENT_BASE_GROUP_ID)
          );

          if (uniqueGroupIds.length > 0) {
            setCurrentGenTechComponentsOnScene((prevValues) => [
              ...prevValues,
              ...uniqueGroupIds.map((groupId) => ({ id: groupId, loading: false })),
            ]);
          }
        }}
        onPaste={(clipboardData) => {
          if (
            [...new Set(clipboardData.elements?.map((el) => el.groupIds[0]))].some((groupId) =>
              groupId.includes(GEN_TECH_COMPONENT_BASE_GROUP_ID)
            )
          ) {
            excalidrawAPI?.setToast({
              message: 'Pasting a Generative Frame element is not supported yet.',
              duration: 3000,
            });
            return false;
          }
          return true;
        }}
        onPointerUpdate={(payload) => {
          const currentHoveredElementFromCanvas = excalidrawAPI?.getHoveredElement();

          // if mouse is "down" (undergoing a click), no element is considered hovered
          // usually this happens when the user is dragging, resizing, rotating the hovered element
          if (payload.button === 'down') {
            setHoveredElement(undefined);
            rerenderReactComponentsOnCanvas({ excalidrawAPI, appRef });
            return;
          }

          if (currentHoveredElementFromCanvas !== hoveredElement) {
            setHoveredElement(currentHoveredElementFromCanvas);
          }
        }}
        customToolbarTools={[
          {
            title: 'Add Replai Image Generator',
            name: 'Image Generator Component',
            checked: false,
            onChange: () => {
              logEvent({
                action: 'Add Replai image generation element',
                component: 'Inspire',
              });

              const genTechComponentGroupId = getNextGenTechComponentGroupId({ excalidrawAPI });
              addElementsToViewPointCenter({
                excalidrawAPI,
                elements: genTechComponentExcalidrawElements.map((element) => ({
                  ...element,
                  id: `${genTechComponentGroupId}_${element.id}`,
                  groupIds: [genTechComponentGroupId],
                })),
              });
              setCurrentGenTechComponentsOnScene((prevValues) => [
                ...prevValues,
                { id: genTechComponentGroupId, loading: false },
              ]);
            },
            icons: {
              checked: <ImageGeneratorToolbarIcon />,
              unchecked: <ImageGeneratorToolbarIcon />,
            },
          },
        ]}
      />
      {hoveredElement?.type === 'image'
        ? renderDownloadButton({ imageElement: hoveredElement, excalidrawAPI })
        : undefined}
      {currentGenTechComponentsOnScene.map((genTechComponent) =>
        renderReactComponentsOnGenerativeComponent({
          excalidrawAPI,
          genTechComponent,
          onChangeVariationClick,
          onGenerateClick,
          setPromptsOnScene,
          promptsOnScene,
        })
      )}
    </Container>
  );
};

export default Inspire;
