import { CountryIconProps } from './types';

const BF = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3585)">
      <path
        d="M0 12C0 5.37262 5.37262 0 12 0C18.6274 0 24 5.37262 24 12C23.4783 12 12 13.5652 12 13.5652L0 12Z"
        fill="#D80027"
      />
      <path
        d="M24 12.0007C24 18.6281 18.6274 24.0007 12 24.0007C5.37262 24.0007 0 18.6281 0 12.0007"
        fill="#6DA544"
      />
      <path
        d="M12.0003 7.82544L12.9067 10.6153H15.8405L13.4671 12.3397L14.3736 15.1298L12.0003 13.4055L9.62697 15.1298L10.5336 12.3397L8.16016 10.6153H11.0938L12.0003 7.82544Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3585">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default BF;
