import { CountryIconProps } from './types';

const AL = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#D80027"
    />
    <path
      d="M18.783 8.90742H14.4364C14.7048 8.62646 14.8699 8.24606 14.8699 7.82686C14.8699 6.96244 14.1691 6.26166 13.3047 6.26166C12.7601 6.26166 12.2807 6.53991 12.0004 6.96183C11.72 6.53991 11.2406 6.26166 10.696 6.26166C9.83159 6.26166 9.13081 6.96244 9.13081 7.82686C9.13081 8.24606 9.29595 8.6265 9.56426 8.90742H5.21777C5.21777 10.06 6.2217 10.9943 7.37421 10.9943H7.30465C7.30465 12.147 8.23901 13.0814 9.39166 13.0814C9.39166 13.4547 9.49005 13.8046 9.6618 14.1076L7.93071 15.8388L9.25887 17.1668L11.1429 15.2829C11.2166 15.3099 11.293 15.3316 11.3719 15.3464L10.2334 17.9168L12.0004 19.8269L13.7673 17.9167L12.6289 15.3463C12.7077 15.3316 12.7841 15.3099 12.8578 15.2828L14.7418 17.1668L16.07 15.8386L14.3389 14.1075C14.5106 13.8046 14.609 13.4545 14.609 13.0812C15.7616 13.0812 16.696 12.1469 16.696 10.9942H16.6265C17.779 10.9943 18.783 10.06 18.783 8.90742Z"
      fill="black"
    />
  </svg>
);
export default AL;
