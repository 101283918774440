import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getTagPreviewInfo = async (requestParams: SDK.GetTagsPreviewsRequest) => {
  const res = await provider.getJson<object, SDK.GetTagsPreviewsResponse>(
    `${SDK.BASE_URL()}/tags/previews?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useTagPreviewInfo<R = SDK.GetTagsPreviewsResponse>(
  requestParams: SDK.GetTagsPreviewsRequest,
  options?: UseQueryOptions<SDK.GetTagsPreviewsResponse, unknown, R>
) {
  return useQuery(['tag-preview-info', requestParams], () => getTagPreviewInfo(requestParams), options);
}
