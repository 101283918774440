import './discard.global.css';
import { useState } from 'react';
import styled from 'styled-components';
import Colors from '../Colors';
import Typography from '../Typography/Typography';
import Modal from '../Modal/Modal';
import { ModalButton, ModalButtonsContainer } from '../Modal/ModalButtons';
import MultiSelectDropDown, {
  MultiSelectDropDownProps,
} from '../MultiSelectDropDown/MultiSelectDropDown';

const DiscardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  [data-test='multi-select-drop-down-container'] {
    box-shadow: none;
  }

  [data-test='multi-select-drop-down-option'] {
    padding: 0.625rem 0.5rem;
  }

  label {
    overflow: unset;
  }
`;

const Title = styled(Typography)`
  margin: 0;
`;

const Subtitle = styled(Typography)`
  margin: 0.5rem 0;
`;

export interface DiscardProps {
  isOpen: boolean;
  options: MultiSelectDropDownProps['options'];
  onClose: (cause: 'back' | 'discard', selectedOptions?: string[]) => void;
}

const Discard = ({ isOpen, options, onClose }: DiscardProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  return (
    <Modal
      className="replai-discard-ant-modal"
      isOpen={isOpen}
      closable={false}
      modalHeader={null}
      onClose={() => onClose('back')}
    >
      <DiscardContent>
        <Title type="text-lg">What&#39;s wrong with the insight?</Title>
        <Subtitle type="text-sm" color={Colors.Gray[400]}>
          Tell us why you want to discard your insight to improve your AI engine
        </Subtitle>
        <MultiSelectDropDown
          disableSearch
          options={options}
          onChange={(o) =>
            setSelectedOptions(
              o
                .filter(({ selected }) => selected)
                .map(({ label }) => label as string)
            )
          }
        />
        <ModalButtonsContainer
          style={{ display: 'block', marginTop: '0.5rem' }}
        >
          <ModalButton
            variation="primary"
            style={{ width: '100%', marginBottom: '0.5rem' }}
            onClick={() => onClose('discard', selectedOptions)}
          >
            <div>Discard&nbsp;Insight</div>
          </ModalButton>
          <ModalButton
            variation="secondary"
            style={{ width: '100%' }}
            onClick={() => onClose('back')}
          >
            <div>Back</div>
          </ModalButton>
        </ModalButtonsContainer>
      </DiscardContent>
    </Modal>
  );
};

export default Discard;
