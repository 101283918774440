import { CountryIconProps } from './types';

const RU = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3696)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.2536 16.1733C23.736 14.8734 24 13.4673 24 11.9995C24 10.5316 23.736 9.12555 23.2536 7.82556H0.746391C0.264047 9.12555 0 10.5316 0 11.9995C0 13.4673 0.264047 14.8734 0.746391 16.1733L12 17.2168L23.2536 16.1733Z"
        fill="#0052B4"
      />
      <path
        d="M12.0007 23.9995C17.1603 23.9995 21.5588 20.743 23.2543 16.1733H0.74707C2.44259 20.743 6.8411 23.9995 12.0007 23.9995Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3696">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default RU;
