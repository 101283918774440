/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */

import { ReactNode, Ref } from 'react';
import styled from 'styled-components';
import { Carousel as AntdCarousel } from 'antd';
import {
  CarouselProps as AntdCarouselProps,
  CarouselRef,
} from 'antd/lib/carousel';
import { ArrowLeft, ArrowRight } from '../Icons/Base';
import Colors from '../Colors';

import 'antd/dist/antd.min.css';

export interface CarouselProps<T> extends AntdCarouselProps {
  slidesData: T[];
  accessor: (data: T, index: number) => ReactNode;
  onDrag?: (page: 'left' | 'right') => void;
  setTransitionState?: (state: boolean) => void;
  slider?: Ref<CarouselRef>;
  disableShadow?: boolean;
}

const StyledCarousel = styled(AntdCarousel)<{ disableShadow: boolean }>`
  border-radius: 8px;
  box-shadow: ${({ disableShadow }) =>
    disableShadow
      ? 'none'
      : '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)'};

  .slick-list {
    border-radius: 8px;
  }

  .slick-slide {
    :first-child > div > div > * {
      border-radius: 8px 0 0 8px;
    }

    :last-child > div > div > * {
      border-radius: 0 8px 8px 0;
    }
  }

  .slick-next,
  .slick-prev {
    z-index: 2;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-top: -13px;
    border-radius: 50%;
    opacity: 1;
    transition: opacity ease-in-out 0.2s;

    &.slick-disabled {
      cursor: default;
      opacity: 0;
    }
  }

  .slick-prev,
  .slick-prev:hover {
    left: 8px;
    background-color: ${Colors.Common.White};
  }

  .slick-next,
  .slick-next:hover {
    right: 8px;
    background-color: ${Colors.Common.White};
  }
`;

function Carousel<T>({
  accessor,
  onDrag,
  setTransitionState,
  slidesData,
  slider,
  disableShadow,
  ...props
}: CarouselProps<T>) {
  const renderPrevArrow = () => (
    <div aria-label="Previous Slide">
      <ArrowLeft color={Colors.Gray[700]} dimension={16} />
    </div>
  );

  const renderNextArrow = () => (
    <div aria-label="Next Slide">
      <ArrowRight color={Colors.Gray[700]} dimension={16} />
    </div>
  );

  return (
    <StyledCarousel
      disableShadow={disableShadow ?? false}
      speed={650}
      ref={slider}
      infinite={false}
      beforeChange={() => setTransitionState?.(true)}
      afterChange={() => setTransitionState?.(false)}
      onSwipe={(s) => (s === 'left' || s === 'right' ? onDrag?.(s) : null)}
      prevArrow={renderPrevArrow()}
      nextArrow={renderNextArrow()}
      {...props}
    >
      {slidesData.map((data: T, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>{accessor(data, index)}</div>
      ))}
    </StyledCarousel>
  );
}

export default Carousel;
