import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';
import { TwoColumnLayout } from '../shared/TwoColumnLayout';

const APPSFLYER_FORM_URL = 'https://docs.google.com/forms/d/1_vGqtKMITarI9kIN7CLDH7vOGwi6TqUo9gKjeB7N5Gg/viewform';

export const AppsFlyerConnectionGuide: NetworkConnectionGuideContent = {
  title: 'AppsFlyer Connection Guide',
  icon: 'AppsFlyer',
  guide: {
    label: 'Connection Guide',
    steps: [
      {
        title: 'Add Replai as a member',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              { item: 'Visit the "User management" page on the AppsFlyer dashboard.' },
              {
                item: (
                  <>
                    Add a new user under the email &quot;
                    <Shared.NoWrap>appsflyer+[your_company_name]@replai.io</Shared.NoWrap>&quot;
                  </>
                ),
              },
              { item: 'Select all apps you want to give access to.' },
              {
                item: (
                  <>
                    Assign the <Shared.HighlightText>Contributor</Shared.HighlightText> role to the new user
                  </>
                ),
              },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/appsflyer/step1.png"
          />
        ),
      },
      {
        title: 'Add Replai as an integrated partner',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              {
                item: `Visit the "Partner Marketplace" page on the AppsFlyer dashboard (should be visible at the top of the page).`,
              },
              {
                item: (
                  <>
                    Use the search bar at the top of the page to search for &quot;Replai&quot;.{' '}
                    <Shared.HighlightText>Select Replai- API Permissions Integration</Shared.HighlightText>.
                  </>
                ),
              },
              {
                item: (
                  <>
                    When prompted, make sure to enable the toggle for{' '}
                    <Shared.HighlightText>Aggregate analytics reporting</Shared.HighlightText>.
                  </>
                ),
              },
              {
                item: 'Repeate the steps above for each individual app on AppsFlyer that Replai should have access to.',
              },
              {
                item: (
                  <>
                    Use {Shared.link({ label: 'this form', url: APPSFLYER_FORM_URL })} to confirm to Replai you&apos;ve
                    followed the steps on this guide.
                  </>
                ),
              },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/appsflyer/step2.png"
          />
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: [
      ...Shared.DEFAULT_MMP_FAQS,
      {
        title: 'I use agencies to publish ads. How does this affect my AppsFlyer data?',
        description: (
          <Shared.UList>
            <Shared.UListItem>
              AppsFlyer has support for agency-published metrics and you can see these metrics on the AppsFlyer UI.
              Agencies on AppsFlyer also have a feature called &quot;Agency transparency&quot; which is what allows you
              to see the metrics associated with the right network.
            </Shared.UListItem>
            <Shared.UListItem>
              Unfortunately, the AppsFlyer API used to fetch your ad metrics (AppsFlyer Cohort API) does not support
              &quot;Agency transparency&quot;, which means Replai isn&apos;t able to fetch the metrics for campaigns
              launched through agencies except on Facebook. This limitation is documented{' '}
              {Shared.link({
                label: 'here',
                url: 'https://support.appsflyer.com/hc/en-us/articles/360004799057#traits-and-limitations',
              })}
              .
            </Shared.UListItem>
            <Shared.UListItem>
              If this issue affects you, reach out to your Replai point of contact to explore alternatives on how to
              still give Replai access to your metrics.
            </Shared.UListItem>
          </Shared.UList>
        ),
      },
    ],
  },
};
