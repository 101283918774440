import { Button, Modal } from '@replai-platform/ui-components';
import { useState } from 'react';
import PlayablePreview from '../PlayablePreview';
import { logEvent } from '../../analytics';

const VideoSidebar: React.FC<{
  assetId: string;
}> = ({ assetId }) => {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ width: '100%' }}>
      <Button
        style={{ width: '100%' }}
        leadingIcon={{ name: 'Play' }}
        color="Orange"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          logEvent({
            component: 'VideoSidebar',
            action: 'Click to Preview',
            category: 'user_actions',
          });
          setOpen(true);
        }}
      >
        Play creative
      </Button>
      <Modal
        width="1000px"
        height="800px"
        onClick={(e) => {
          e.stopPropagation();
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOpen={open}
      >
        <PlayablePreview assetId={assetId} enabled={open} />
      </Modal>
    </div>
  );
};

export default VideoSidebar;
