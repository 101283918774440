import { IntegrationIconProps } from './types';

const AdobeAfterEffects = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="28" height="28" fill="#D097F9" />
    <rect x="3" y="3" width="26" height="26" fill="#1F063E" />
    <path
      d="M9.98325 19.053L9.04331 22.8541C9.02387 22.9583 8.985 22.9985 8.86699 22.9985H7.12457C7.00655 22.9985 6.98712 22.9568 7.00655 22.8094L10.3748 10.1688C10.4331 9.938 10.4733 9.75635 10.4928 9.12656C10.4928 9.04318 10.5317 9 10.5914 9H13.078C13.1557 9 13.1946 9.02084 13.2154 9.12656L16.9946 22.8303C17.014 22.936 16.9946 22.9985 16.896 22.9985H14.9384C14.8412 22.9985 14.7815 22.9583 14.7621 22.8749L13.7833 19.053H9.98325ZM11.8826 11.004L10.4761 16.9864L13.314 16.9879L11.8826 11.004ZM19.6742 18.2892C19.6936 20.0103 20.4572 21.1657 22.2593 21.1657C22.9646 21.1657 23.5686 21.0659 24.1947 20.7711C24.2725 20.7294 24.3322 20.7503 24.3322 20.8545V22.4506C24.3322 22.5772 24.2933 22.6397 24.2142 22.7022C23.588 23.0387 22.5 22.9985 22 22.9985C18.8678 22.9985 17.5208 20.7071 17.5208 17.9348C17.5208 14.9317 18.9703 12.4751 21.5152 12.4751C24.0989 12.4751 25 14.8052 25 16.6961C25 17.305 24.9611 17.8083 24.9028 18.0405C24.8834 18.1447 24.8445 18.182 24.7459 18.2028C24.5113 18.2445 23.806 18.2862 22.7689 18.2862L19.6742 18.2892ZM22.083 16.5397C22.6897 16.5397 22.9049 16.5397 22.9646 16.5189C22.9646 15.539 22.6526 14.3347 21.4374 14.3347C20.3017 14.3347 19.8116 15.4052 19.695 16.5397H22.083Z"
      fill="#D097F9"
    />
  </svg>
);
export default AdobeAfterEffects;
