import { IntegrationIconProps } from './types';

const JbPycharmEdu = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1334_1606)">
      <path
        d="M22.4456 5.02856L31.7713 12.8457L28.4342 19.6571L22.7656 18.1028H17.9199L22.4456 5.02856Z"
        fill="url(#paint0_linear_1334_1606)"
      />
      <path
        d="M13.0286 10.1029L11.2 19.6571L11.0171 22.9486L6.49143 24.9143L0 25.6L1.96571 4.89143L13.6686 0L20.8914 4.75429L13.0286 10.1029Z"
        fill="url(#paint1_linear_1334_1606)"
      />
      <path
        d="M13.0286 10.1028L13.8971 28.5714L10.9714 32L0 25.6L9.00571 12.16L13.0286 10.1028Z"
        fill="url(#paint2_linear_1334_1606)"
      />
      <path
        d="M25.0969 8.73143H13.9883L23.8169 0L25.0969 8.73143Z"
        fill="url(#paint3_linear_1334_1606)"
      />
      <path
        d="M32.0001 28.6171L22.2172 31.9543L9.23438 28.2971L13.0287 10.1029L14.5372 8.73143L22.4458 8L21.7144 15.9543L28.0229 13.5314L32.0001 28.6171Z"
        fill="url(#paint4_linear_1334_1606)"
      />
      <path d="M6.125 6.12567H25.8736V25.8742H6.125V6.12567Z" fill="black" />
      <path d="M8 22.1714H15.4057V23.4057H8V22.1714Z" fill="white" />
      <path
        d="M7.95312 8.73138H11.2446C13.2103 8.73138 14.3988 9.91996 14.3988 11.6114C14.3988 13.4857 12.936 14.4914 11.1074 14.4914H9.73598V16.9142H7.95312V8.73138ZM11.1531 12.8457C12.0674 12.8457 12.5703 12.2971 12.5703 11.6114C12.5703 10.7885 12.0217 10.3314 11.1074 10.3314H9.73598V12.8457H11.1531Z"
        fill="white"
      />
      <path
        d="M15.3594 8.73138H21.5308V10.3314H17.1422V11.9771H21.0279V13.5771H17.1879V15.3142H21.6222V16.9142H15.3594V8.73138Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1334_1606"
        x1="11.4276"
        y1="12.3639"
        x2="30.4712"
        y2="12.3639"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#21D789" />
        <stop offset="1" stopColor="#07C3F2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1606"
        x1="-11.227"
        y1="27.0085"
        x2="27.9863"
        y2="-1.93875"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.011" stopColor="#FCF84A" />
        <stop offset="0.112" stopColor="#A7EB62" />
        <stop offset="0.206" stopColor="#5FE077" />
        <stop offset="0.273" stopColor="#32DA84" />
        <stop offset="0.306" stopColor="#21D789" />
        <stop offset="0.577" stopColor="#21D789" />
        <stop offset="0.597" stopColor="#21D789" />
        <stop offset="0.686" stopColor="#20D68C" />
        <stop offset="0.763" stopColor="#1ED497" />
        <stop offset="0.835" stopColor="#19D1A9" />
        <stop offset="0.904" stopColor="#13CCC2" />
        <stop offset="0.971" stopColor="#0BC6E1" />
        <stop offset="1" stopColor="#07C3F2" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_1606"
        x1="4.26514"
        y1="35.499"
        x2="10.8055"
        y2="14.976"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#21D789" />
        <stop offset="0.164" stopColor="#24D788" />
        <stop offset="0.305" stopColor="#2FD886" />
        <stop offset="0.437" stopColor="#41DA82" />
        <stop offset="0.564" stopColor="#5ADC7D" />
        <stop offset="0.688" stopColor="#7AE077" />
        <stop offset="0.809" stopColor="#A1E36E" />
        <stop offset="0.925" stopColor="#CFE865" />
        <stop offset="1" stopColor="#F1EB5E" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1334_1606"
        x1="12.9254"
        y1="17.6562"
        x2="27.1581"
        y2="-1.47932"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#21D789" />
        <stop offset="0.061" stopColor="#24D788" />
        <stop offset="0.113" stopColor="#2FD886" />
        <stop offset="0.162" stopColor="#41DA82" />
        <stop offset="0.209" stopColor="#5ADD7D" />
        <stop offset="0.255" stopColor="#79E077" />
        <stop offset="0.258" stopColor="#7CE076" />
        <stop offset="0.499" stopColor="#8CE173" />
        <stop offset="0.925" stopColor="#B2E56B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1334_1606"
        x1="34.6922"
        y1="20.0914"
        x2="6.01518"
        y2="19.8258"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.387" stopColor="#FCF84A" />
        <stop offset="0.536" stopColor="#ECF451" />
        <stop offset="0.826" stopColor="#C2E964" />
        <stop offset="0.925" stopColor="#B2E56B" />
      </linearGradient>
      <clipPath id="clip0_1334_1606">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default JbPycharmEdu;
