/* eslint-disable react/jsx-props-no-spreading */
import { FlatSocialIconProps } from './types';

const SensorTower = ({ color, dimension, ...props }: FlatSocialIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="-10.87053604 -10.9602179 172.48528755 172.32830715"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m80.45 161.35a80.68 80.68 0 1 1 80.9-80.2 80.87 80.87 0 0 1 -80.9 80.2zm-72.09-80.93c-.08 34.87 24.84 65.26 58.3 71.37 4.35.79 6-.21 6.27-4.74 1-17.62 2.36-35.22 3.6-52.83.24-3.35.25-6.37-2.17-9.35a7.11 7.11 0 0 1 1.56-10.21c3.2-2.37 6.62-2.45 9.64.14 3.38 2.9 4 6.73 1.34 10.26-2 2.66-2.36 5.27-2.14 8.36 1.29 17.77 2.46 35.55 3.72 53.32.41 5.75 1 6.08 6.88 4.87 37.21-7.59 62.21-43 57.08-80.85-5.16-38.07-38.56-64.76-77.85-62.21-36.76 2.38-66.15 34.27-66.23 71.87z"
      fill={color || '#22AB9D'}
    />
    <path
      d="m35.62 83.65c0-24.26 14.87-42.65 35.79-47.1a45 45 0 0 1 35.45 80.73c-1.84 1.31-3.67 4-6.42 2.27-1.77-1.08-1.58-9.65 0-11.23.82-.82 1.8-1.49 2.66-2.27 14.41-13 15.34-35 2.06-48.74a33.83 33.83 0 0 0 -48.78-.14 33.44 33.44 0 0 0 1.87 48.62c3.75 3.28 4.44 6.33 3.69 10.81-.69 4.11-2.25 4.21-5.24 2.22-13.7-9.06-20.61-21.82-21.08-35.17z"
      fill={color || '#22AB9D'}
    />
  </svg>
);
export default SensorTower;
