import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getGenres = async (requestParams: SDK.GetGenresRequest) => {
  const res = await provider.getJson<object, SDK.GetGenresResponse>(
    `${SDK.BASE_URL()}/tags/genres?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useGenres<R = SDK.GetGenresResponse>(
  requestParams: SDK.GetGenresRequest,
  options?: UseQueryOptions<SDK.GetGenresResponse, unknown, R>
) {
  return useQuery(['genres', requestParams], () => getGenres(requestParams), options);
}
