import { PaymentIconProps } from './types';

const Qiwi = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H40C43.3137 0 46 2.68629 46 6V26C46 29.3137 43.3137 32 40 32H6C2.68629 32 0 29.3137 0 26V6Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9521 15.4231C12.9521 20.5858 17.0422 24.7961 22.106 24.7961C22.7276 24.7961 23.3491 24.7401 24.0632 24.6757C24.8597 24.604 25.7712 24.5218 26.9264 24.4954C28.6793 24.4954 30.5783 25.147 32.672 27.0517C32.8667 27.2521 33.1589 27.0015 32.9641 26.7509C30.8704 24.0944 28.9715 23.5932 27.0725 23.1421C24.7353 22.5907 23.5181 21.2875 22.6903 19.7838C22.5443 19.4831 22.4469 19.5332 22.4469 19.9342C22.3982 20.5357 22.4469 21.087 22.5443 21.6384H22.2521C18.9411 21.6384 16.2144 18.8315 16.2144 15.4231C16.2144 12.0148 18.9411 9.20787 22.2521 9.20787C25.5631 9.20787 28.2898 12.0148 28.2898 15.4231C28.2898 15.6236 28.2898 15.8742 28.2411 16.1248C27.7542 16.0246 26.9264 16.0246 26.2935 16.0747C26.05 16.0747 26.05 16.175 26.2448 16.2251C28.1924 16.6261 29.5557 17.8791 29.8966 20.1848C29.8966 20.2851 29.9453 20.2851 29.994 20.2349C30.773 18.8315 31.2599 17.1774 31.2599 15.4231C31.2599 10.2103 27.1699 6 22.106 6C17.0422 6 12.9521 10.2605 12.9521 15.4231ZM28.7279 19.2826C29.0688 19.433 29.2149 19.9342 29.2149 20.1848C29.2635 20.5858 29.1662 20.7863 29.0201 20.7863C28.874 20.7863 28.6792 20.6359 28.4845 20.2851C28.2897 19.9342 28.1923 19.5833 28.2897 19.3828C28.4358 19.2826 28.5819 19.2325 28.7279 19.2826ZM26.7316 20.8364C26.9264 20.8364 27.1698 20.9367 27.4133 21.1372C27.8028 21.488 27.9489 21.889 27.7541 22.1897C27.6567 22.3401 27.4133 22.4404 27.2185 22.4404C26.9751 22.4404 26.7316 22.3401 26.5856 22.1897C26.196 21.8389 26.0986 21.3377 26.3421 21.0369C26.3908 20.9367 26.5369 20.8364 26.7316 20.8364Z"
      fill="#F28A1A"
    />
    <path
      d="M6 1H40V-1H6V1ZM45 6V26H47V6H45ZM40 31H6V33H40V31ZM1 26V6H-1V26H1ZM6 31C3.23858 31 1 28.7614 1 26H-1C-1 29.866 2.13401 33 6 33V31ZM45 26C45 28.7614 42.7614 31 40 31V33C43.866 33 47 29.866 47 26H45ZM40 1C42.7614 1 45 3.23858 45 6H47C47 2.13401 43.866 -1 40 -1V1ZM6 -1C2.13401 -1 -1 2.13401 -1 6H1C1 3.23858 3.23858 1 6 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default Qiwi;
