import styled, { css } from 'styled-components';
import Colors from '../Colors';
import { default as DefaultTypography } from '../Typography/Typography';

export const RootContainer = styled.div<{
  $style?: string;
  $fullWidth?: boolean;
  $fullHeight?: boolean;
  $parentFontSize?: boolean;
}>`
  ${(props) => (props.$parentFontSize ? `` : 'font-size: 14px;')}
  border-radius: 0.571em;
  width: ${(props) => props.$fullWidth && '100%'};
  ${(props) => (props.$fullHeight ? 'height: 100%;' : '')}

  &&& {
    ${(props) =>
      css`
        ${props.$style}
      `}
  }
`;

export const Root = styled.div<{
  $prefix?: boolean;
  $error?: boolean;
  $warning?: boolean;
  $fullHeight?: boolean;
  $enableTransitions?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  height: ${(props) => (props.$fullHeight ? '100%' : '40px')};
  padding: 0.714em 1em;
  border: 0.08em solid ${Colors.Gray[300]};
  border-radius: 0.571em;
  ${(props) => (props.$enableTransitions ? 'transition: 0.2s;' : '')}

  &:focus-within {
    border: 0.08em solid ${Colors.Primary[300]};
    box-shadow: 0 0.071em 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #d1e9ff;
  }

  &:hover .input:not(:disabled)::placeholder,
  &:hover .input:focus::placeholder .input:not(:disabled) {
    color: ${Colors.Gray[900]};
  }

  ${(props) => (props.$prefix ? PrefixRootMixin : '')}
  ${(props) => (props.$error ? ErrorMixin : '')}
  ${(props) => (props.$warning ? WarningMixin : '')}
`;

export const PrefixContainer = styled.div<{
  $fullHeight?: boolean;
}>`
  display: flex;
  width: 100%;
  ${(props) => (props.$fullHeight ? 'height: 100%;' : '')}
`;

export const Prefix = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 12px;
  color: ${Colors.Gray[500]};
  border: 1px solid ${Colors.Gray[300]};
  border-right: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const LeadingIconHolder = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
`;

export const TrailingIconHolder = styled.div<{ $withInput?: boolean }>`
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  ${(props) => (props.$withInput ? TrailingIconWithInputMixin : '')}
`;

export const LeadingText = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
  color: ${Colors.Gray[500]};
`;

export const Input = styled.input<{ $enableTransitions: boolean }>`
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${Colors.Gray[500]};
  ${(props) => (props.$enableTransitions ? 'transition: color 0.2s;' : '')}

  &::placeholder,
  &:disabled {
    color: ${Colors.Gray[500]};
    ${(props) => (props.$enableTransitions ? 'transition: color 0.2s;' : '')}
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    color: ${Colors.Gray[900]};
  }
`;

export const HintContainer = styled.div`
  margin-top: 6px;
`;

export const Typography = styled(DefaultTypography)<{
  $margin?: string;
  $fontWeight?: number;
}>`
  margin: ${(props) => props.$margin};
  font-weight: ${(props) => props.$fontWeight};
`;

/* Mixins */

const PrefixRootMixin = css`
  flex: 1;
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const ErrorMixin = css`
  border: 1px solid ${Colors.Error[300]};

  &:focus-within {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px ${Colors.Error[100]};
  }
`;

const WarningMixin = css`
  border: 1px solid ${Colors.Warning[300]};

  :focus-within {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px ${Colors.Warning[100]};
  }
`;

const TrailingIconWithInputMixin = css`
  margin-right: 12px;
`;
