import { IntegrationIconProps } from './types';

const Figma = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 16C16 13.5147 18.0147 11.5 20.5 11.5C22.9853 11.5 25 13.5147 25 16C25 18.4853 22.9853 20.5 20.5 20.5C18.0147 20.5 16 18.4853 16 16Z"
      fill="#1ABCFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 25C7 22.5147 9.01472 20.5 11.5 20.5H16V25C16 27.4853 13.9853 29.5 11.5 29.5C9.01472 29.5 7 27.4853 7 25Z"
      fill="#0ACF83"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2.5V11.5H20.5C22.9853 11.5 25 9.48528 25 7C25 4.51472 22.9853 2.5 20.5 2.5H16Z"
      fill="#FF7262"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 7C7 9.48528 9.01472 11.5 11.5 11.5H16V2.5H11.5C9.01472 2.5 7 4.51472 7 7Z"
      fill="#F24E1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 16C7 18.4853 9.01472 20.5 11.5 20.5H16V11.5H11.5C9.01472 11.5 7 13.5147 7 16Z"
      fill="#A259FF"
    />
  </svg>
);
export default Figma;
