import { CountryIconProps } from './types';

const SM = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3754)">
      <path
        d="M24 11.9997C24 18.6271 18.6274 23.9997 12 23.9997C5.37262 23.9997 0 18.6271 0 11.9997C0.521719 11.9997 12 10.4345 12 10.4345L24 11.9997Z"
        fill="#338AF3"
      />
      <path
        d="M0 12C0 5.37262 5.37262 0 12 0C18.6274 0 24 5.37262 24 12"
        fill="#F0F0F0"
      />
      <path
        d="M16.7631 8.28088L11.9998 13.0442L7.23659 8.28088C6.6206 9.19527 6.26074 10.2959 6.26074 11.4789V13.0441C6.26074 15.5574 7.88473 17.6983 10.1383 18.4732C9.83754 19.0627 9.88826 19.7984 10.329 20.3485C10.9144 19.8793 11.5204 19.3936 12.0346 18.9817C12.5487 19.3937 13.1548 19.8794 13.7401 20.3485C14.186 19.792 14.2331 19.0453 13.9204 18.4523C16.1433 17.6606 17.7389 15.5354 17.7389 13.0441V11.479C17.739 10.2959 17.3791 9.19532 16.7631 8.28088Z"
        fill="#6DA544"
      />
      <path
        d="M12.0001 17.2176C9.69855 17.2176 7.82617 15.3451 7.82617 13.0437V11.4785C7.82617 9.17694 9.69859 7.30457 12.0001 7.30457C14.3015 7.30457 16.174 9.17699 16.174 11.4785V13.0437C16.174 15.3451 14.3016 17.2176 12.0001 17.2176Z"
        fill="#FFDA44"
      />
      <path
        d="M14.609 13.0433V11.4781C14.609 10.0397 13.4387 8.86945 12.0003 8.86945C10.5619 8.86945 9.3916 10.0397 9.3916 11.4781V13.0433L12.0003 13.565L14.609 13.0433Z"
        fill="#338AF3"
      />
      <path
        d="M9.3916 13.0432C9.3916 14.4817 10.5619 15.6519 12.0003 15.6519C13.4387 15.6519 14.609 14.4816 14.609 13.0432H9.3916Z"
        fill="#6DA544"
      />
      <path
        d="M15.13 7.30397C15.13 6.43955 14.4292 5.73876 13.5648 5.73876C13.1638 5.73876 12.7983 5.8897 12.5213 6.13762V5.217H13.043V4.17351H12.5213V3.65179H11.4778V4.17351H10.9561V5.217H11.4778V6.13762C11.2008 5.8897 10.8353 5.73876 10.4343 5.73876C9.56992 5.73876 8.86914 6.43955 8.86914 7.30397C8.86914 7.76742 9.07075 8.18367 9.39086 8.47031V9.39094H14.6082V8.47031C14.9284 8.18367 15.13 7.76742 15.13 7.30397Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3754">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SM;
