import { DropDownChip, DropDownChipProps } from '@replai-platform/ui-components';

export interface AgencyFilterProps {
  options: DropDownChipProps['dropDownOptions'];
  onChange: DropDownChipProps['onChange'];
  loading: boolean;
  disabled?: boolean;
}

const AgencyFilter: React.VFC<AgencyFilterProps> = ({ options, onChange, loading, disabled = false }) => (
  <DropDownChip
    data-test="filter-agency-dropdown"
    placeHolder="All Agencies"
    defaultOption="All Agencies"
    dropDownType="multiselect"
    dropDownOptions={options}
    dropDownAlignment="right"
    onChange={onChange}
    prefixLabel="Agencies"
    disabled={disabled}
    loading={loading}
  />
);

export default AgencyFilter;
