import * as SDK from '@replai-platform/sdk';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';
import { TypographyClean } from '../../../utils/styles';

export type CreatedAtAccessorType = {
  createdAt?: Date;
};

const CreatedAtCellDecorator: React.FC<{
  cellData: CreatedAtAccessorType;
}> = ({ cellData }) => {
  if (!cellData) {
    return null;
  }

  return <TypographyClean>{cellData?.createdAt?.toLocaleDateString() ?? SDK.messages.NOT_AVAILABLE}</TypographyClean>;
};

function getCreatedAtColumn<T extends object = CreatedAtAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T, CreatedAtAccessorType>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<T, CreatedAtAccessorType>) => <CreatedAtCellDecorator cellData={data?.cell?.value} />,
  };
}

export default getCreatedAtColumn;
