import { CommonTagIconProps } from '../types';

const NoSoundIntro = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.087 6.22597L7.11999 9.64397C7.03899 9.71397 6.93599 9.75197 6.82999 9.75197H4.53899C4.29299 9.75197 4.09399 9.95097 4.09399 10.197V14.12C4.09399 14.366 4.29299 14.565 4.53899 14.565H7.19799C7.30499 14.565 7.40799 14.603 7.48799 14.673L11.087 17.774C11.375 18.022 11.822 17.818 11.822 17.437V6.56297C11.822 6.18197 11.375 5.97697 11.087 6.22597Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.0179 10.0559L19.9059 13.9439"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.9059 10.0559L16.0179 13.9439"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default NoSoundIntro;
