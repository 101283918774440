/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
export function getBase64ImageFromUrl2(url: string) {
  return fetch(url, { mode: url.includes('replai-tag-cut-outs') ? undefined : 'no-cors' }) // TODO improve this
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((callback) => {
          const reader = new FileReader();
          reader.onload = () => {
            callback(reader.result);
          };
          reader.readAsDataURL(blob);
        })
    );
}

// TODO unused for now
export async function fetchImage(
  url: string,
  fileId: string,
  setBase64VersionOfImagesOnScreen: React.Dispatch<React.SetStateAction<{ id: string; base64Content: string }[]>>,
  base64VersionOfImagesOnScreen: { id: string; base64Content: string }[]
) {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = () => {
    if (xhr.status === 200) {
      const blob = xhr.response;

      new Promise((callback) => {
        const reader = new FileReader();
        reader.onload = () => {
          callback(reader.result);
        };
        reader.readAsDataURL(blob);
      }).then((base64: string) => {
        setBase64VersionOfImagesOnScreen(base64VersionOfImagesOnScreen.concat({ id: fileId, base64Content: base64 }));
      });

      const img = document.createElement('img');
      img.src = URL.createObjectURL(blob);
    }
  };
  xhr.open('GET', url);
  xhr.send();
}

export function radiansToDegrees(radians: number): number {
  // The conversion factor from radians to degrees is 180/π
  return radians * (180 / Math.PI);
}
