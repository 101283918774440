import * as SDK from '@replai-platform/sdk';
import { useQuery, UseQueryOptions } from 'react-query';
import * as provider from '../../api/provider';

const getReportsTemplates = async (request: SDK.GetReportsTemplatesRequest): Promise<SDK.GetReportsTemplatesResponse> =>
  provider.getJson(`${SDK.BASE_URL()}/reports/templates?${SDK.convertRequestToQueryParams(request)}`);

export default function useReports(
  requestParams: SDK.GetReportsTemplatesRequest,
  options?: UseQueryOptions<SDK.GetReportsTemplatesResponse>
) {
  return useQuery([`getReportsTemplates`, requestParams], () => getReportsTemplates(requestParams), options);
}
