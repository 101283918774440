import { useEffect } from 'react';
import styled from 'styled-components';
import { useDraggable, type DragPosition } from '../../../../hooks';
import Colors from '../../../Colors';

const Container = styled.div<{ verticalOffset: number }>`
  position: absolute;
  cursor: pointer;
  top: ${({ verticalOffset }) => `${verticalOffset}px`};
  bottom: 0;
  height: ${({ verticalOffset }) => `calc(100% + ${-verticalOffset}px)`};
`;

const Line = styled.div`
  position: absolute;
  left: 50%;
  z-index: 200;
  width: 2px;
  height: calc(100% + 5.5rem);
  background-color: ${Colors.Gray[400]};
  border-radius: 0.5rem;
  transform: translateX(-50%);
`;

const InnerContainer = styled.div<{ withoutTabs?: boolean }>`
  position: relative;
  height: 100%;
  padding: 0 0.5rem;
  margin-left: -0.5rem;
  top: ${({ withoutTabs }) => (withoutTabs ? '-2rem' : '-6rem')};
`;

const Tooltip = styled.div`
  position: absolute;
  z-index: 200;
  padding: 0.25rem;
  height: 1.5rem;
  color: ${Colors.Common.White};
  background-color: ${Colors.Gray[400]};
  border-radius: 0.5rem;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  transform: translateX(-50%);
`;

interface TimelineTimestampLineProps {
  playedFraction: number;
  verticalOffset?: number;
  onUpdateFraction?: (fraction: number) => void;
  onDrag?: () => void;
  withoutTabs?: boolean;
}

const TimelineTimestampLine: React.FC<TimelineTimestampLineProps> = ({
  playedFraction,
  verticalOffset = 0,
  onUpdateFraction,
  onDrag,
  withoutTabs,
}) => {
  useEffect(() => {
    if (!ref.current || dragging) return;
    ref.current.style.left = `calc(100 * ${playedFraction}%)`;
  }, [playedFraction]);

  const onChange = (position: DragPosition) => {
    if (!ref || !ref.current) return;

    ref.current.style.left = `calc(100 * ${position.x}%)`;
    onUpdateFraction?.(position.x);
  };

  const { ref, dragging } = useDraggable(
    onChange,
    {
      onDragStart: onDrag,
    },
    { useParentForDimensions: true }
  );

  return (
    <Container
      verticalOffset={verticalOffset}
      ref={ref}
      role="button"
      aria-label="timestamp-line"
    >
      <InnerContainer withoutTabs={withoutTabs}>
        <Tooltip />
        <Line />
      </InnerContainer>
    </Container>
  );
};

export default TimelineTimestampLine;
