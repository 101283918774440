/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as SDK from '@replai-platform/sdk';
import { Badge, ColorTypes, Typography, Skeleton } from '@replai-platform/ui-components';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';
import { FooterContainer } from '../common/styles';
import ColumnHeader from '../generateHeader';

export type KpiColumnData = {
  kpi: string;
  kpiValue: number | null;
  kpiPerformance?: number;
  enableContextPerformanceLazyLoading?: boolean;
  loading?: boolean;
};

const TypographyNoMargin = styled(Typography)`
  margin: 0;
`;

type PerformanceColumnParams<T extends object = object> = RequiredGeneratorParams<T, KpiColumnData> &
  Partial<{
    columnKpi: string;
  }>;

function getPerformanceColumn<D extends object = object>({
  columnId,
  columnKpi,
  accessor,
  includeFooter,
}: PerformanceColumnParams<D>): GeneratedColumn<D, KpiColumnData> {
  const additionalFields = columnKpi
    ? {
        Header: columnKpi ? <ColumnHeader title={SDK.kpiUtils.getDisplayName(columnKpi)} /> : undefined,
        tooltip: columnKpi
          ? `${SDK.kpiUtils.getDisplayName(columnKpi)} (${SDK.kpiUtils.getLongName(
              columnKpi
            )}) of the video according to the filters you have selected.`
          : undefined,
      }
    : {};
  return {
    id: columnId,
    accessor,
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    ...additionalFields,
    Cell: (data: CellData<D, KpiColumnData>) => {
      const loading = data.cell.value?.loading;

      if (loading) {
        return <Skeleton height={20} width={100} />;
      }

      const { kpi, kpiValue, kpiPerformance: performance } = data.cell.value;

      const color: ColorTypes = performance === 0 ? 'Gray' : (performance ?? 0) > 0 ? 'Success' : 'Error';
      const leadingIcon: 'ArrowUp' | 'ArrowDown' = (performance ?? 0) >= 0 ? 'ArrowUp' : 'ArrowDown';

      return (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Badge color="Gray">{SDK.kpiUtils.format(kpi, kpiValue)}</Badge>{' '}
          {performance && (
            <Badge leadingIcon={leadingIcon} color={color}>
              {Math.abs(performance * 100).toFixed(1)}%
            </Badge>
          )}
        </div>
      );
    },
    Footer: (info) => {
      const kpi = columnId.split('.')[1];
      const aggregatedMetricKey = SDK.getAggregatedMetricKeyName(kpi);
      const aggregatedMetric = aggregatedMetricKey
        ? info?.data?.[0]?.cluster?.metrics?.[aggregatedMetricKey] ?? info?.data?.[0]?.cluster?.[aggregatedMetricKey]
        : null;
      return includeFooter && aggregatedMetric ? (
        <FooterContainer style={{ marginLeft: '0.75rem' }}>
          <TypographyNoMargin fontWeight="medium" type="text-sm">
            {SDK.kpiUtils.format(kpi, aggregatedMetric)}
          </TypographyNoMargin>
        </FooterContainer>
      ) : null;
    },
  };
}

export default getPerformanceColumn;
