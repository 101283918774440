import { CommonTagIconProps } from '../types';

const NormalSceneTransitionsDiff = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 16.813C14.6582 16.813 16.813 14.6582 16.813 12C16.813 9.34187 14.6582 7.18701 12 7.18701C9.34187 7.18701 7.18701 9.34187 7.18701 12C7.18701 14.6582 9.34187 16.813 12 16.813Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.08997 14.8052C7.43997 15.3652 6.59997 15.6952 5.66997 15.6952C3.62997 15.6952 1.96997 14.0452 1.96997 11.9952C1.96997 9.95518 3.62997 8.30518 5.66997 8.30518C6.59997 8.30518 7.43997 8.63518 8.08997 9.19518"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.9099 14.8052C16.5599 15.3652 17.3999 15.6952 18.3299 15.6952C20.3699 15.6952 22.0299 14.0452 22.0299 11.9952C22.0299 9.95518 20.3699 8.30518 18.3299 8.30518C17.3999 8.30518 16.5599 8.63518 15.9099 9.19518"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.458 10.2559L13.202 11.9999L11.458 13.7439"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default NormalSceneTransitionsDiff;
