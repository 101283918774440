import { IntegrationIconProps } from './types';

const Fedora = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 16.0004C30 8.26828 23.7318 2 15.9998 2C8.27112 2 2.00547 8.26303 2 15.9906V26.8246C2.00416 28.5792 3.42723 29.9997 5.18292 29.9997H16.0055C23.735 29.9966 30 23.7305 30 16.0004Z"
      fill="#294172"
    />
    <path
      d="M20.1101 5.31482C16.4888 5.31482 13.5427 8.26083 13.5427 11.8822V15.368H10.0713C6.45003 15.368 3.50391 18.3143 3.50391 21.9356C3.50391 25.5567 6.45003 28.503 10.0713 28.503C13.6926 28.503 16.6388 25.5567 16.6388 21.9356V18.4497H20.1101C23.7314 18.4497 26.6776 15.5035 26.6776 11.8822C26.6776 8.26083 23.7314 5.31482 20.1101 5.31482ZM13.567 21.9356C13.567 23.863 11.9988 25.4311 10.0713 25.4311C8.14381 25.4311 6.5757 23.863 6.5757 21.9356C6.5757 20.008 8.14381 18.4399 10.0713 18.4399H13.5427V18.4497H13.567V21.9356ZM20.1101 15.3779H16.6388V15.368H16.6146V11.8822C16.6146 9.95473 18.1827 8.38662 20.1101 8.38662C22.0375 8.38662 23.6057 9.95473 23.6057 11.8822C23.6057 13.8098 22.0375 15.3779 20.1101 15.3779Z"
      fill="#3C6EB4"
    />
    <path
      d="M21.5619 5.51395C21.0522 5.38073 20.6609 5.3186 20.1104 5.3186C16.4816 5.3186 13.5397 8.26068 13.5397 11.8892V15.3719H10.7878C9.92975 15.3719 9.23631 16.0462 9.23686 16.9027C9.23686 17.7539 9.92264 18.431 10.7716 18.431L13.05 18.4315C13.3205 18.4315 13.5399 18.6502 13.5399 18.9203V21.9339C13.5365 23.8469 11.9847 25.3966 10.0716 25.3966C9.42356 25.3966 9.26311 25.3117 8.82069 25.3117C7.89133 25.3117 7.26953 25.9347 7.26953 26.7913C7.26975 27.5 7.877 28.1092 8.62009 28.3036C9.12978 28.4369 9.52112 28.4991 10.0716 28.4991C13.7003 28.4991 16.6423 25.557 16.6423 21.9284V18.4458H19.3942C20.2522 18.4458 20.9457 17.7715 20.9451 16.915C20.9451 16.0637 20.2593 15.3867 19.4104 15.3867L17.132 15.3862C17.0022 15.3864 16.8777 15.3349 16.7858 15.2432C16.6939 15.1516 16.6422 15.0271 16.6421 14.8973V11.8837C16.6455 9.97065 18.1973 8.42103 20.1104 8.42103C20.7584 8.42103 20.9189 8.50601 21.3613 8.50601C22.2907 8.50601 22.9125 7.8829 22.9125 7.02639C22.9122 6.31763 22.305 5.70842 21.5619 5.51395Z"
      fill="white"
    />
  </svg>
);
export default Fedora;
