import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getAdSets = async (requestParams: SDK.GetAdSetsRequest) =>
  provider.getJson<object, SDK.GetAdSetsResponse>(
    `${SDK.BASE_URL()}/adSets?${SDK.convertRequestToQueryParams(requestParams)}`
  );

export default function useGetAdSets<R = SDK.GetAdSetsResponse>(
  requestParams: SDK.GetAdSetsRequest,
  options?: UseQueryOptions<SDK.GetAdSetsResponse, unknown, R>
) {
  return useQuery(['get-ad-sets', requestParams], () => getAdSets(requestParams), options);
}
