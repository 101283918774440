import { IntegrationIconProps } from './types';

const Ruby = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5931 19.8408L8.89062 27.9999L26.6328 26.7926L27.9993 8.85266L22.5931 19.8408Z"
      fill="url(#paint0_linear_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6631 26.7804L25.1381 16.2259L20.9844 21.7261L26.6631 26.7804Z"
      fill="url(#paint1_linear_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6836 26.7804L15.5117 25.901L8.95117 27.977L26.6836 26.7804Z"
      fill="url(#paint2_linear_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.96576 27.9794L11.7567 18.8109L5.61523 20.1277L8.96576 27.9794Z"
      fill="url(#paint3_linear_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.4166 11.8118L20.4696 6.12213L18.5352 12.3936L27.4166 11.8118Z"
      fill="url(#paint4_linear_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1694 4.09348L20.0836 6.35767L17.5059 4.06311L24.1694 4.09348Z"
      fill="url(#paint5_linear_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 23.2031L5.7116 20.0729L4.32706 16.3438L4 23.2031Z"
      fill="url(#paint6_linear_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.23438 16.2259L5.6274 20.1883L11.6804 18.8265L18.591 12.3862L20.5412 6.17431L17.4704 4L12.2496 5.95931C10.6047 7.49347 7.41289 10.529 7.29782 10.5861C7.18395 10.6444 5.19011 14.4233 4.23438 16.2259Z"
      fill="white"
    />
    <path
      d="M11.7227 18.788L18.592 12.3861L18.5951 12.3763L20.9832 21.7564L11.7227 18.788Z"
      fill="url(#paint7_linear_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.12619 9.10786C12.6911 5.56337 17.2869 3.46923 19.0506 5.25363C20.8132 7.03802 18.944 11.3745 15.3791 14.9178C11.8141 18.461 7.27528 20.6706 5.5128 18.8862C3.74911 17.103 5.56126 12.6511 9.12619 9.10786Z"
      fill="url(#paint8_linear_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.9668 27.9757L11.7359 18.778L20.9323 21.7407C17.6072 24.8673 13.909 27.5105 8.9668 27.9757Z"
      fill="url(#paint9_linear_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6035 12.368L20.9644 21.7455C23.742 18.8169 26.2349 15.6684 27.4558 11.774L18.6035 12.368Z"
      fill="url(#paint10_linear_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.4327 11.8215C28.3775 8.9621 28.5955 4.86007 24.1402 4.09845L20.4844 6.12335L27.4327 11.8215Z"
      fill="url(#paint11_linear_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 23.1618C4.13082 27.8811 7.52617 27.9514 8.97249 27.9928L5.63166 20.1688L4 23.1618Z"
      fill="#9E1209"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6172 12.3827C20.7515 13.6982 25.0531 16.3401 25.1403 16.3887C25.2758 16.4653 26.9948 13.482 27.3849 11.796L18.6172 12.3827Z"
      fill="url(#paint12_radial_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7324 18.778L15.4342 25.9399C17.6231 24.7495 19.3371 23.2991 20.907 21.7455L11.7324 18.778Z"
      fill="url(#paint13_radial_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.6163 20.1786L5.0918 26.4416C6.08145 27.7972 7.44298 27.915 8.87113 27.8093C7.83787 25.2305 5.77377 20.0741 5.6163 20.1786Z"
      fill="url(#paint14_linear_1334_1685)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4629 6.13906L27.8168 7.17398C27.4244 5.5062 26.2191 4.42998 24.1647 4.09351L20.4629 6.13906Z"
      fill="url(#paint15_linear_1334_1685)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_1685"
        x1="25.0853"
        y1="30.1826"
        x2="20.0009"
        y2="21.2308"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FB7655" />
        <stop offset="0.41" stopColor="#E42B1E" />
        <stop offset="0.99" stopColor="#990000" />
        <stop offset="1" stopColor="#990000" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1685"
        x1="27.6086"
        y1="22.6526"
        x2="21.0723"
        y2="18.2785"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#871101" />
        <stop offset="0.99" stopColor="#911209" />
        <stop offset="1" stopColor="#911209" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_1685"
        x1="22.3878"
        y1="30.4543"
        x2="15.8517"
        y2="26.0804"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#871101" />
        <stop offset="0.99" stopColor="#911209" />
        <stop offset="1" stopColor="#911209" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1334_1685"
        x1="8.68671"
        y1="19.4742"
        x2="9.70375"
        y2="26.0656"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.23" stopColor="#E57252" />
        <stop offset="0.46" stopColor="#DE3B20" />
        <stop offset="0.99" stopColor="#A60003" />
        <stop offset="1" stopColor="#A60003" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1334_1685"
        x1="21.8182"
        y1="7.10008"
        x2="22.9399"
        y2="11.9205"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.18" stopColor="#E46342" />
        <stop offset="0.4" stopColor="#C82410" />
        <stop offset="0.99" stopColor="#A80D00" />
        <stop offset="1" stopColor="#A80D00" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1334_1685"
        x1="18.4127"
        y1="5.40189"
        x2="23.2261"
        y2="3.00187"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.54" stopColor="#C81F11" />
        <stop offset="0.99" stopColor="#BF0905" />
        <stop offset="1" stopColor="#BF0905" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1334_1685"
        x1="4.47282"
        y1="17.7935"
        x2="4.87073"
        y2="21.7663"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.31" stopColor="#DE4024" />
        <stop offset="0.99" stopColor="#BF190B" />
        <stop offset="1" stopColor="#BF190B" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1334_1685"
        x1="15.6452"
        y1="13.3186"
        x2="16.02"
        y2="20.0463"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.23" stopColor="#E4714E" />
        <stop offset="0.56" stopColor="#BE1A0D" />
        <stop offset="0.99" stopColor="#A80D00" />
        <stop offset="1" stopColor="#A80D00" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1334_1685"
        x1="1.75216"
        y1="22.8406"
        x2="20.4078"
        y2="3.71065"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BD0012" />
        <stop offset="0.07" stopColor="white" />
        <stop offset="0.17" stopColor="white" />
        <stop offset="0.27" stopColor="#C82F1C" />
        <stop offset="0.33" stopColor="#820C01" />
        <stop offset="0.46" stopColor="#A31601" />
        <stop offset="0.72" stopColor="#B31301" />
        <stop offset="0.99" stopColor="#E82609" />
        <stop offset="1" stopColor="#E82609" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1334_1685"
        x1="16.0015"
        y1="24.7754"
        x2="10.3965"
        y2="23.3958"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C0C01" />
        <stop offset="0.54" stopColor="#990C00" />
        <stop offset="0.99" stopColor="#A80D0E" />
        <stop offset="1" stopColor="#A80D0E" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1334_1685"
        x1="25.6251"
        y1="18.0315"
        x2="20.635"
        y2="13.5715"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7E110B" />
        <stop offset="0.99" stopColor="#9E0C00" />
        <stop offset="1" stopColor="#9E0C00" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1334_1685"
        x1="27.4549"
        y1="9.82296"
        x2="24.968"
        y2="7.17171"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#79130D" />
        <stop offset="0.99" stopColor="#9E120B" />
        <stop offset="1" stopColor="#9E120B" />
      </linearGradient>
      <radialGradient
        id="paint12_radial_1334_1685"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(21.4229 13.6433) scale(6.09995 6.11692)"
      >
        <stop stopColor="#A80D00" />
        <stop offset="0.99" stopColor="#7E0E08" />
        <stop offset="1" stopColor="#7E0E08" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_1334_1685"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.9755 21.7043) scale(8.10906 8.13164)"
      >
        <stop stopColor="#A30C00" />
        <stop offset="0.99" stopColor="#800E08" />
        <stop offset="1" stopColor="#800E08" />
      </radialGradient>
      <linearGradient
        id="paint14_linear_1334_1685"
        x1="7.22978"
        y1="27.9704"
        x2="5.19877"
        y2="21.099"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B2114" />
        <stop offset="0.43" stopColor="#9E100A" />
        <stop offset="0.99" stopColor="#B3100C" />
        <stop offset="1" stopColor="#B3100C" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_1334_1685"
        x1="22.733"
        y1="5.19012"
        x2="27.2673"
        y2="7.18603"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B31000" />
        <stop offset="0.44" stopColor="#910F08" />
        <stop offset="0.99" stopColor="#791C12" />
        <stop offset="1" stopColor="#791C12" />
      </linearGradient>
    </defs>
  </svg>
);
export default Ruby;
