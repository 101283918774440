import './linechart.global.css';
import { ReactNode } from 'react';
import * as Recharts from 'recharts';
import { AxisDomain, AxisInterval, DataKey } from 'recharts/types/util/types';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import Typography from '../Typography/Typography';
import EmptyState from '../EmptyState/EmptyState';
import Colors, { Color } from '../Colors';
import { resolvePath } from '../../utils';
import DefaultTooltip from './Tooltip';

const CHART_COLORS = [
  Colors.BlueLight[400],
  Colors.Orange[400],
  Colors.Primary[600],
  Colors.Success[300],
  Colors.Pink[400],
  Colors.Error[900],
  Colors.Warning[300],
  Colors.Purple[300],
  Colors.Rose[200],
  Colors.Success[700],
  Colors.Warning[700],
];

export interface LineChartProps {
  lines: ChartLine[];
  data?: ChartValue[];
  customTooltip?: (
    props: Recharts.TooltipProps<ValueType, NameType>
  ) => ReactNode | null;
  showLegend?: boolean;
  showTooltip?: boolean;
  hideYAxis?: boolean;
  hideXAxis?: boolean;
  showCartesianGrid?: boolean;
  cartesianGridVertical?: boolean;
  xLabel?: string;
  yLabel?: string;
  gradientColor?: Color;
  xValueFormatter?: (v: string | number, index: number) => string;
  yValueFormatter?: (v: number, index: number, lineIndex?: number) => string;
  xAxisTicks?: string[] | number[];
  xAxisInterval?: AxisInterval;
  emptyChartMessage?: boolean;
  yAxisDomain?: AxisDomain;
  lineStroke?: number;
  adaptedToServerSideRendering?: boolean;
  serverSideHeight?: number;
  serverSideWidth?: number;
  xReferenceLine?: { x: string | number; label: string };
  yAxisTickCount?: number;
  xAxisTickCount?: number;
  additionalYAxis?: {
    id: string;
    label?: string;
    yValueFormatter?: (v: number) => string;
  };
}

/**
 * @typedef {Object} ChartLine defines the line that will appear in the chart
 * @property {string} name - name that will appear in the chart legend
 * @property {string} accessor - key to retrieve the value in the data object (should be a key in @type {ChartValue})
 * @property {Color} color - color of the line
 */
type ChartLine = {
  name: string;
  accessor: DataKey<ChartValue>;
  color?: Color;
  yAxisId?: string;
};

export type ChartValue = {
  name: string | number;
  [key: string]: string | number;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const StyledComposedChart = styled(Recharts.ComposedChart)`
  font-family: 'Inter', sans-serif;
`;

const LegendText = styled(Typography)`
  display: inline-block;
  margin: 0 0 0 4px;
`;

const tickStyle = {
  fontWeight: '400',
  fontSize: '0.75rem',
  fill: Colors.Gray[500],
};
const axisLabelStyle = {
  fontWeight: '500',
  fontSize: '0.75rem',
  fill: Colors.Gray[500],
};

const getHighestPoint = (
  chartValue: ChartValue,
  accessors: DataKey<ChartValue>[]
) =>
  Math.max(
    ...accessors.map((accessor: DataKey<ChartValue>): number => {
      if (typeof accessor === 'string' || typeof accessor === 'number') {
        return resolvePath(chartValue, accessor as string, 0) as number;
      }

      return accessor(chartValue) as number;
    })
  );

const getLineColor = (line: ChartLine, index: number): Color => {
  if (line.color) {
    return line.color;
  }

  return CHART_COLORS[index] ?? Colors.Gray[300];
};

const LineChart = ({
  data,
  xValueFormatter,
  yValueFormatter,
  customTooltip,
  lines,
  yLabel,
  xLabel,
  gradientColor = Colors.BlueLight[400],
  showLegend = true,
  showTooltip = true,
  hideYAxis = false,
  hideXAxis = false,
  showCartesianGrid = true,
  cartesianGridVertical = false,
  emptyChartMessage = true,
  xAxisTicks,
  xAxisInterval = 'preserveStartEnd',
  yAxisDomain,
  lineStroke = 2,
  adaptedToServerSideRendering = false,
  serverSideHeight,
  serverSideWidth,
  xReferenceLine,
  yAxisTickCount = 7,
  xAxisTickCount,
  additionalYAxis,
}: LineChartProps) => {
  if (!data || !data.length) {
    return (
      <EmptyState
        icon="LineChart"
        text={emptyChartMessage ? 'No data available' : ''}
      />
    );
  }

  // create "fake" data line with the highest data points to pass to area chart in order to create gradient
  const gradientLineKey = uuid();
  const gradientDefKey = uuid();
  const accessors = lines.map((l) => l.accessor);
  const chartData = data.map((d) => ({
    ...d,
    [gradientLineKey]: getHighestPoint(d, accessors),
  }));

  const legendFormatter = (value: string) => (
    <LegendText type="text-sm" color={Colors.Gray[500]}>
      {value}
    </LegendText>
  );

  const renderGradientDef = () => (
    <defs>
      <linearGradient id={gradientDefKey} x1="0" y1="0" x2="0" y2="1">
        <stop offset="5%" stopColor={gradientColor} stopOpacity={0.1} />
        <stop offset="95%" stopColor={Colors.Common.White} stopOpacity={0.1} />
      </linearGradient>
    </defs>
  );

  const renderCartesianGrid = () => (
    <Recharts.CartesianGrid
      vertical={cartesianGridVertical}
      stroke={Colors.Gray[100]}
      strokeLinecap="round"
    />
  );

  const renderXAxis = () => (
    <Recharts.XAxis
      dataKey="name"
      axisLine={false}
      tick={tickStyle}
      tickLine={false}
      tickCount={xAxisTickCount}
      tickFormatter={xValueFormatter}
      label={
        xLabel
          ? {
              value: xLabel,
              position: 'insideBottom',
              offset: -10,
              style: axisLabelStyle,
            }
          : {}
      }
      interval={xAxisInterval}
      ticks={xAxisTicks}
      hide={hideXAxis}
    />
  );

  const renderYAxis = () => (
    <Recharts.YAxis
      id="axis1"
      yAxisId="axis1"
      axisLine={false}
      tick={tickStyle}
      tickLine={false}
      tickCount={yAxisTickCount}
      tickFormatter={yValueFormatter}
      label={
        yLabel
          ? {
              value: yLabel,
              position: 'insideLeft',
              angle: -90,
              offset: 0,
              style: axisLabelStyle,
            }
          : {}
      }
      hide={hideYAxis}
      domain={yAxisDomain}
    />
  );

  const renderAdditionalYAxis = () => (
    <Recharts.YAxis
      id={additionalYAxis?.id}
      yAxisId={additionalYAxis?.id}
      axisLine={false}
      tick={tickStyle}
      tickLine={false}
      tickCount={yAxisTickCount}
      tickFormatter={additionalYAxis?.yValueFormatter}
      label={
        additionalYAxis?.label
          ? {
              value: additionalYAxis.label,
              position: 'insideRight',
              angle: 90,
              offset: 0,
              style: axisLabelStyle,
            }
          : {}
      }
      orientation="right"
      hide={hideYAxis}
      domain={yAxisDomain}
    />
  );

  const renderTooltip = () => (
    <Recharts.Tooltip
      content={customTooltip ?? DefaultTooltip}
      cursor={{
        stroke: Colors.Gray[200],
        strokeDasharray: '4,4',
        strokeWidth: 1,
      }}
      formatter={yValueFormatter}
      filterNull={false}
    />
  );

  const renderLegend = () => (
    <Recharts.Legend
      wrapperStyle={{ marginTop: '-15px' }}
      verticalAlign="top"
      align="right"
      iconType="circle"
      iconSize={8}
      payload={(lines || []).map((l, i) => ({
        value: l.name,
        color: getLineColor(l, i),
      }))}
      formatter={legendFormatter}
    />
  );

  const renderAreaChart = () => (
    <Recharts.Area
      yAxisId="axis1"
      isAnimationActive={false}
      dataKey={gradientLineKey}
      strokeWidth={0}
      fill={`url(#${gradientDefKey})`}
      legendType="none"
      tooltipType="none"
    />
  );

  const renderLines = () =>
    (lines || []).map((line, index) => (
      <Recharts.Line
        yAxisId={line.yAxisId ?? 'axis1'}
        isAnimationActive={false}
        key={index}
        name={line.name}
        type="monotone"
        dataKey={line.accessor}
        stroke={getLineColor(line, index)}
        strokeWidth={lineStroke}
        dot={{
          fill: getLineColor(line, index),
          r: lineStroke / 2,
          strokeWidth: 0,
        }}
      />
    ));

  const renderXReferenceLine = () => {
    const numberOfDataPointsInChart = data.length;
    const referenceLineIndex =
      data.findIndex((value) => value.name === xReferenceLine?.x) ?? 0;

    // if the reference line is somewhere between the first half of the chart, it renders
    // the label on the right, else, on the left
    const renderRight = referenceLineIndex < numberOfDataPointsInChart / 2;

    return xReferenceLine?.x && xReferenceLine?.label ? (
      <Recharts.ReferenceLine
        yAxisId="axis1"
        x={xReferenceLine.x}
        stroke="gray"
        label={{
          value: xReferenceLine.label,
          position: renderRight ? 'right' : 'left',
        }}
        strokeDasharray="3 3"
      />
    ) : undefined;
  };

  const content = (
    <>
      {renderGradientDef()}
      {showCartesianGrid && renderCartesianGrid()}
      {renderXAxis()}
      {renderYAxis()}
      {additionalYAxis && renderAdditionalYAxis()}
      {showTooltip && renderTooltip()}
      {showLegend && renderLegend()}
      {renderAreaChart()}
      {renderLines()}
      {renderXReferenceLine()}
    </>
  );

  return adaptedToServerSideRendering ? (
    <Recharts.LineChart
      width={serverSideWidth}
      height={serverSideHeight}
      data={data}
    >
      {content}
    </Recharts.LineChart>
  ) : (
    <Recharts.ResponsiveContainer data-test="line-chart">
      <StyledComposedChart
        data={chartData}
        margin={{
          top: showLegend ? 20 : 0,
          bottom: xLabel ? 10 : 0,
          left: yLabel ? 10 : 0,
        }}
      >
        {content}
      </StyledComposedChart>
    </Recharts.ResponsiveContainer>
  );
};

export default LineChart;
