import * as SDK from '@replai-platform/sdk';
import { useQuery, UseQueryOptions } from 'react-query';
import * as provider from '../../api/provider';

const getMarketTagPreview = async (
  request: SDK.GetMarketTagsPreviewsRequest
): Promise<SDK.GetMarketTagsPreviewsResponse> =>
  provider.getJson(`${SDK.BASE_URL()}/market/tags/assets?${SDK.convertRequestToQueryParams(request as object)}`);

export default function useMarketTagPreview(
  requestParams: SDK.GetMarketTagsPreviewsRequest,
  options?: UseQueryOptions<SDK.GetMarketTagsPreviewsResponse>
) {
  return useQuery(['market.useMarketTagPreview', requestParams], () => getMarketTagPreview(requestParams), options);
}
