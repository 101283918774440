import { messages, CreativeRecommendation, RecommendationType } from '@replai-platform/sdk';
import { camelCaseToCapitalCase } from '@replai-platform/ui-components';

export function nameFromContent({
  content,
  includeType,
  showPreviousOnReplace = true,
}: {
  content: CreativeRecommendation['name'];
  includeType: boolean;
  showPreviousOnReplace?: boolean;
}): string | null {
  switch (content.type) {
    case RecommendationType.CREATIVE_ADD_TAG:
    case RecommendationType.CREATIVE_ADD_TAG_INTRO:
    case RecommendationType.CREATIVE_REMOVE_TAG:
    case RecommendationType.CREATIVE_REMOVE_TAG_INTRO: {
      if (includeType) {
        return `${camelCaseToCapitalCase(content?.tag?.type ?? '')}${
          content?.tag?.value ? `: ${content?.tag?.value}` : ''
        }`;
      }

      return content?.tag?.value ?? '';
    }
    case RecommendationType.CREATIVE_REPLACE_TAG:
    case RecommendationType.CREATIVE_REPLACE_TAG_INTRO: {
      if (includeType) {
        return `${camelCaseToCapitalCase(content?.previous?.type ?? '')}: ${
          showPreviousOnReplace ? (content?.previous?.value || messages.NOT_AVAILABLE).concat(' <> ') : ''
        } ${content?.new?.value || messages.NOT_AVAILABLE}`;
      }

      return `${showPreviousOnReplace ? (content?.previous?.value || messages.NOT_AVAILABLE).concat(' <> ') : ''} ${
        content?.new?.value || messages.NOT_AVAILABLE
      }`;
    }
    default:
      return null;
  }
}
