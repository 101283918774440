import { MiscIconProps } from './types';

const LoadingCircle = ({ color, dimension }: MiscIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      margin: 'auto',
      background: 'none',
      display: 'block',
      shapeRendering: 'auto',
    }}
    width={dimension || '20'}
    height={dimension || '20'}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke={color || 'white'}
      strokeWidth="8"
      strokeLinecap="round"
      r="40"
      strokeDasharray="164.93361431346415 96.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="0.8s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
);

export default LoadingCircle;
