import { CountryIconProps } from './types';

const SA = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#6DA544"
    />
    <path
      d="M6.7832 14.3477C6.7832 15.2121 7.48399 15.9129 8.34841 15.9129H13.0441C13.0441 16.6332 13.628 17.2172 14.3484 17.2172H15.9136C16.634 17.2172 17.218 16.6332 17.218 15.9129V14.3477H6.7832Z"
      fill="#F0F0F0"
    />
    <path
      d="M17.3472 6.78308V10.4352C17.3472 11.0106 16.8791 11.4787 16.3037 11.4787V13.0439C17.7422 13.0439 18.9124 11.8737 18.9124 10.4352V6.78308H17.3472Z"
      fill="#F0F0F0"
    />
    <path
      d="M6.1304 10.435C6.1304 11.0104 5.6623 11.4785 5.08691 11.4785V13.0437C6.52537 13.0437 7.6956 11.8734 7.6956 10.435V6.78284H6.1304V10.435Z"
      fill="#F0F0F0"
    />
    <path d="M16.5652 6.78308H15V10.4353H16.5652V6.78308Z" fill="#F0F0F0" />
    <path
      d="M12.6524 8.87005C12.6524 9.01391 12.5354 9.13091 12.3916 9.13091C12.2477 9.13091 12.1307 9.01386 12.1307 8.87005V6.78308H10.5655V8.87005C10.5655 9.01391 10.4484 9.13091 10.3046 9.13091C10.1608 9.13091 10.0438 9.01386 10.0438 8.87005V6.78308H8.47852V8.87005C8.47852 9.87697 9.2977 10.6962 10.3046 10.6962C10.6924 10.6962 11.0521 10.5743 11.3481 10.3673C11.6441 10.5742 12.0038 10.6962 12.3916 10.6962C12.4697 10.6962 12.5465 10.6907 12.622 10.6811C12.5111 11.138 12.0995 11.4787 11.609 11.4787V13.0439C13.0474 13.0439 14.2177 11.8737 14.2177 10.4353V8.87005V6.78308H12.6525V8.87005H12.6524Z"
      fill="#F0F0F0"
    />
    <path
      d="M10.8263 11.4781H8.47852V13.0433H10.8263V11.4781Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default SA;
