/* eslint-disable no-case-declarations */

import { message } from 'antd';
import { Colors, Typography } from '../';
import * as Icons from '../Icons';
import * as Styled from './styles';

import 'antd/dist/antd.min.css';

export type SnackbarMessageProps = {
  content: string;
  state: 'loading' | 'success' | 'error' | 'warning';
  duration?: number;
};

const SnackbarMessage = ({
  state,
  content,
  duration,
}: SnackbarMessageProps) => {
  let icon: JSX.Element | null = null;

  switch (state) {
    case 'loading':
      const LoadingIcon = Icons.getMiscIcon('LoadingCircle');
      icon = <LoadingIcon color={Colors.Gray[400]} />;
      break;
    case 'success':
      const SuccessIcon = Icons.getBaseIcon('CheckCircle');
      icon = <SuccessIcon color={Colors.Success[400]} />;
      break;
    case 'error':
      const ErrorIcon = Icons.getBaseIcon('XCircle');
      icon = <ErrorIcon color={Colors.Error[400]} />;
      break;
    case 'warning':
      const WarningIcon = Icons.getBaseIcon('AlertCircle');
      icon = <WarningIcon color={Colors.Warning[400]} />;
      break;
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = state;
  }

  return message[state]({
    duration,
    icon,
    className: 'replai-snackbar-message',
    content: (
      <>
        <Styled.MessageStyle />
        <Styled.Typography type="text-sm">{content}</Styled.Typography>
      </>
    ),
  });
};

export default SnackbarMessage;
