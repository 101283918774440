import { CountryIconProps } from './types';

const CC = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#6DA544"
    />
    <path
      d="M18.4209 17.2178L18.7502 17.9063L19.4938 17.7345L19.1608 18.4212L19.7588 18.8954L19.0142 19.0632L19.0163 19.8265L18.4209 19.349L17.8255 19.8265L17.8275 19.0632L17.083 18.8954L17.681 18.4212L17.348 17.7345L18.0916 17.9063L18.4209 17.2178Z"
      fill="#FFDA44"
    />
    <path
      d="M15.3574 9.91223L15.6867 10.6008L16.4304 10.4289L16.0973 11.1157L16.6953 11.5899L15.9508 11.7577L15.9528 12.5209L15.3574 12.0435L14.762 12.5209L14.764 11.7577L14.0195 11.5899L14.6175 11.1157L14.2845 10.4289L15.0281 10.6008L15.3574 9.91223Z"
      fill="#FFDA44"
    />
    <path
      d="M18.4208 5.73828L18.7501 6.42683L19.4937 6.25489L19.1608 6.9417L19.7587 7.41594L19.0142 7.5837L19.0162 8.34697L18.4208 7.86945L17.8254 8.34697L17.8275 7.5837L17.083 7.41594L17.6809 6.9417L17.3479 6.25489L18.0915 6.42683L18.4208 5.73828Z"
      fill="#FFDA44"
    />
    <path
      d="M21.0976 8.87012L21.4269 9.55866L22.1706 9.38677L21.8376 10.0736L22.4355 10.5478L21.691 10.7156L21.6931 11.4788L21.0976 11.0013L20.5022 11.4788L20.5042 10.7156L19.7598 10.5478L20.3578 10.0736L20.0247 9.38677L20.7683 9.55866L21.0976 8.87012Z"
      fill="#FFDA44"
    />
    <path
      d="M19.1841 12.5211L19.4432 13.3183H20.2813L19.6032 13.811L19.8623 14.6081L19.1841 14.1155L18.506 14.6081L18.765 13.811L18.0869 13.3183H18.9251L19.1841 12.5211Z"
      fill="#FFDA44"
    />
    <path
      d="M13.3045 15.3913C11.4315 15.3913 9.9131 13.8729 9.9131 12C9.9131 10.127 11.4314 8.60867 13.3045 8.60867C13.8884 8.60867 14.4379 8.75638 14.9177 9.0163C14.1651 8.28027 13.1359 7.82605 12 7.82605C9.69489 7.82605 7.82617 9.69477 7.82617 11.9999C7.82617 14.3051 9.69489 16.1738 12 16.1738C13.1359 16.1738 14.1651 15.7196 14.9176 14.9836C14.4379 15.2436 13.8884 15.3913 13.3045 15.3913Z"
      fill="#FFDA44"
    />
    <path
      d="M7.54678 4.69546C7.18584 4.07184 6.51201 3.65198 5.7397 3.65198C4.96738 3.65198 4.29356 4.07184 3.93262 4.69546H7.54678Z"
      fill="#FFDA44"
    />
    <path
      d="M7.55328 4.7074L5.73927 6.52141L3.92525 4.7074C3.75186 5.01166 3.65234 5.36355 3.65234 5.73883C3.65234 6.89144 4.5867 7.8258 5.73931 7.8258C6.89192 7.8258 7.82628 6.89149 7.82628 5.73883C7.82623 5.36355 7.72667 5.01166 7.55328 4.7074Z"
      fill="#FFDA44"
    />
    <path
      d="M5.21777 6.00049V7.76083C5.38451 7.80372 5.55935 7.82655 5.73949 7.82655C5.91964 7.82655 6.09448 7.80367 6.26121 7.76083V6.00049H5.21777Z"
      fill="#A2001D"
    />
  </svg>
);
export default CC;
