import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';
import { TwoColumnLayout } from '../shared/TwoColumnLayout';

const ADJUST_FORM_URL = 'https://docs.google.com/forms/d/1UnzYwIcXWh06NcZw9PEppkzPCZSzVAVaKvCZtBXOfWs/viewform';

export const AdjustConnectionGuide: NetworkConnectionGuideContent = {
  title: 'Adjust Connection Guide',
  icon: 'Adjust',
  guide: {
    label: 'Connection Guide',
    steps: [
      {
        title: 'Add Replai as a member',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              { item: 'Visit the Adjust dashboard.' },
              { item: 'Open the side menu and click "My Profile".' },
              { item: 'Click on "API Token".' },
              {
                item: (
                  <span>
                    Copy the token and send it to Replai using{' '}
                    {Shared.link({ label: 'this form', url: ADJUST_FORM_URL })}.
                  </span>
                ),
              },
            ]}
          />
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: Shared.DEFAULT_MMP_FAQS,
  },
};
