/* eslint-disable react/jsx-props-no-spreading */
import { FlatSocialIconProps } from './types';

const AppsFlyer = ({ dimension, ...props }: FlatSocialIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1080 1080"
    width={dimension || 24}
    height={dimension || 24}
  >
    <g transform="matrix(6.06 0 0 6.06 1860.33 540)">
      <g vector-effect="non-scaling-stroke">
        <g transform="matrix(0.13 0 0 -0.13 -249.68 -7.42)">
          <path
            fill="rgb(117,184,44)"
            vector-effect="non-scaling-stroke"
            transform=" translate(-346.46, -705.78)"
            d="M 5.53516 888.508 L 197.926 522.023 C 211.06199999999998 497.055 243.44099999999997 476.78900000000004 270.188 476.855 L 270.188 476.855 L 662.664 477.684 C 689.457 477.738 700.512 498.055 687.387 523.066 L 687.387 523.066 L 494.977 889.531 C 481.852 914.5469999999999 449.47999999999996 934.7579999999999 422.707 934.699 L 422.707 934.699 L 30.2383 933.883 C 29.6328 933.883 29.046899999999997 933.863 28.441399999999998 933.863 L 28.441399999999998 933.863 C 2.98047 932.902 -7.3125 912.961 5.53516 888.508"
            stroke-linecap="round"
          />
        </g>
        <g transform="matrix(0.13 0 0 -0.13 -168.53 39.44)">
          <path
            fill="rgb(117,184,44)"
            vector-effect="non-scaling-stroke"
            transform=" translate(-955.09, -354.32)"
            d="M 751.031 461.488 L 867.539 244.09 C 875.465 229.262 894.848 217.359 910.809 217.53900000000002 L 910.809 217.53900000000002 L 1144.65 220.01200000000003 C 1160.6200000000001 220.17200000000003 1167.0600000000002 232.33200000000002 1159.14 247.17200000000003 L 1159.14 247.17200000000003 L 1042.67 464.55100000000004 C 1034.73 479.391 1015.32 491.266 999.398 491.09800000000007 L 999.398 491.09800000000007 L 765.551 488.6410000000001 C 765.277 488.6410000000001 765.0200000000001 488.6410000000001 764.7460000000001 488.6410000000001 L 764.7460000000001 488.6410000000001 C 749.375 488.07 743.23 476.098 751.031 461.488"
            stroke-linecap="round"
          />
        </g>
        <g transform="matrix(0.13 0 0 -0.13 -194.89 -34.57)">
          <path
            fill="rgb(61,144,213)"
            vector-effect="non-scaling-stroke"
            transform=" translate(-757.38, -909.41)"
            d="M 752.395 1286.95 L 518.582 986.836 C 502.602 966.332 500.484 929.59 513.828 904.664 L 513.828 904.664 L 709.305 539.84 C 722.6289999999999 514.957 746.3979999999999 511.367 762.352 531.82 L 762.352 531.82 L 996.191 831.9920000000001 C 1012.14 852.4570000000001 1014.28 889.2420000000001 1000.9100000000001 914.1450000000001 L 1000.9100000000001 914.1450000000001 L 805.461 1278.98 C 797.711 1293.38 786.508 1300.64 775.336 1300.25 L 775.336 1300.25 C 767.227 1299.92 759.117 1295.57 752.395 1286.95"
            stroke-linecap="round"
          />
        </g>
        <g transform="matrix(0.13 0 0 -0.13 -202.47 55.63)">
          <path
            fill="rgb(61,144,213)"
            vector-effect="non-scaling-stroke"
            transform=" translate(-700.57, -232.9)"
            d="M 697.633 457.848 L 558.277 279.02 C 548.738 266.80899999999997 547.508 244.89799999999997 555.441 230.082 L 555.441 230.082 L 671.922 12.6914 C 679.8910000000001 -2.148440000000001 694.0350000000001 -4.281250000000002 703.5550000000001 7.92969 L 703.5550000000001 7.92969 L 842.871 186.738 C 852.371 198.969 853.641 220.898 845.715 235.691 L 845.715 235.691 L 729.227 453.09 C 724.641 461.676 717.9449999999999 466.008 711.273 465.789 L 711.273 465.789 C 706.461 465.621 701.605 463.012 697.633 457.848"
            stroke-linecap="round"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default AppsFlyer;
