import { CountryIconProps } from './types';

const WS = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C4.12172 7.87828 7.61737 4.38263 12 0C18.6274 0 24 5.37262 24 12Z"
      fill="#D80027"
    />
    <path
      d="M12 12C12 5.37262 12 4.59131 12 0C5.37262 0 0 5.37262 0 12H12Z"
      fill="#0052B4"
    />
    <path
      d="M9.61098 7.82617L9.80527 8.42406H10.4339L9.92527 8.79348L10.1196 9.39137L9.61098 9.02186L9.10238 9.39137L9.29668 8.79348L8.78809 8.42406H9.41668L9.61098 7.82617Z"
      fill="#F0F0F0"
    />
    <path
      d="M6.45448 3.13116L6.77824 4.12768H7.826L6.97831 4.74338L7.30217 5.73985L6.45448 5.12396L5.60684 5.73985L5.93065 4.74338L5.08301 4.12768H6.13067L6.45448 3.13116Z"
      fill="#F0F0F0"
    />
    <path
      d="M9.58534 4.17499L9.9091 5.17155H10.9568L10.1092 5.78725L10.4329 6.78372L9.58534 6.16783L8.7377 6.78372L9.06151 5.78725L8.21387 5.17155H9.26153L9.58534 4.17499Z"
      fill="#F0F0F0"
    />
    <path
      d="M7.11072 8.349L7.43454 9.34551H8.48219L7.63455 9.96122L7.95836 10.9577L7.11072 10.3418L6.26309 10.9577L6.5869 9.96122L5.73926 9.34551H6.78696L7.11072 8.349Z"
      fill="#F0F0F0"
    />
    <path
      d="M4.36752 5.73987L4.69133 6.73638H5.73903L4.89135 7.35209L5.21516 8.34856L4.36752 7.73271L3.51992 8.34856L3.84369 7.35209L2.99609 6.73638H4.04375L4.36752 5.73987Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default WS;
