export const formatTagType = (tagType: string): string => {
  switch (tagType) {
    case 'duration':
      return 'Duration';
    case 'aspectRatio':
      return 'Aspect Ratio';
    case 'language':
      return 'Language';
    case 'sceneTransitionsDiff':
      return 'Scene Transitions Diff';
    case 'cutFrequency':
      return 'Cut Frequency';
    default:
      return tagType;
  }
};
