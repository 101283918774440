import { MouseEventHandler, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Icons from '../Icons';
import Colors from '../Colors';
import Tooltip from '../Tooltip/Tooltip';
import Badge from '../Badge/Badge';
import * as Styled from './styles';

export type LockedConfig = {
  featureName: string;
  title?: string;
  subtitle?: string;
};

export type NavigationSubItemProps = {
  href?: string;
  icon?: Icons.BaseIconTypes;
  isSelected?: boolean;
  isBeta?: boolean;
  label: string;
  lockedConfig?: LockedConfig;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export type NavigationItemProps = {
  href?: string;
  id?: string;
  icon?: Icons.BaseIconTypes;
  isSelected?: boolean;
  label: string;
  hasNotification?: boolean;
  subMenu?: NavigationSubItemProps[];
  onClick?: MouseEventHandler<HTMLElement>;
};

const NavigationItem = ({
  href,
  icon,
  id,
  isSelected = false,
  label,
  hasNotification,
  subMenu,
  onClick,
}: NavigationItemProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [closeTooltip, setCloseTooltip] = useState(false);

  const isClickable = !subMenu?.length || !!onClick;
  const isSubMenuSelected = (subMenu || []).some((s) => s.isSelected);
  const shouldHighlight = isHovering || isSelected || isSubMenuSelected;
  const Icon = icon && Icons.getBaseIcon(icon);

  useEffect(() => {
    if (closeTooltip) {
      setCloseTooltip(false);
    }
  }, [closeTooltip]);

  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    onClick?.(event);
  };

  const getTooltipContent = () => (
    <Styled.SubMenuTooltipContainer data-test="sub-menu-wrapper">
      {(subMenu || []).map(
        ({ icon, label, onClick, isSelected, isBeta, lockedConfig, href }) => {
          const SubMenuIcon = icon && Icons.getBaseIcon(icon);

          const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
            if (lockedConfig) {
              event?.stopPropagation();
              event?.preventDefault();
            }

            onClick?.(event);
            setCloseTooltip(true);
          };

          return (
            <Link to={href ?? '#'} onClick={handleClick} key={label}>
              <Styled.SubMenuContainer
                data-test="sub-menu-item"
                $isSelected={isSelected ?? false}
              >
                {SubMenuIcon && (
                  <SubMenuIcon
                    dimension={24}
                    color={isSelected ? Colors.Common.White : Colors.Gray[300]}
                  />
                )}
                <Styled.MenuLabel
                  key={label}
                  noMargin
                  fontWeight="semi-bold"
                  type="text-md"
                  color={isSelected ? Colors.Common.White : Colors.Gray[300]}
                >
                  {label}
                </Styled.MenuLabel>
                {(lockedConfig || isBeta) && (
                  <Styled.ExtraIconContainer>
                    {lockedConfig && (
                      <Icons.BaseIcons.Lock
                        color={Colors.Gray[300]}
                        dimension={20}
                      />
                    )}
                    {isBeta && !lockedConfig && (
                      <Badge size="sm" color="Gray">
                        Beta
                      </Badge>
                    )}
                  </Styled.ExtraIconContainer>
                )}
              </Styled.SubMenuContainer>
            </Link>
          );
        }
      )}
    </Styled.SubMenuTooltipContainer>
  );

  const renderItem = () => (
    <Styled.ItemContainer
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={handleClick}
    >
      <Styled.ItemButton
        data-test="navigation-menus"
        $selected={isSelected || isSubMenuSelected}
        $clickable={isClickable}
      >
        {Icon && (
          <Styled.IconContainer>
            <Icon
              dimension={26}
              color={shouldHighlight ? Colors.Common.White : Colors.Gray[300]}
            />
            <Styled.MenuLabel
              noMargin
              type="text-xxs"
              color={shouldHighlight ? Colors.Common.White : Colors.Gray[300]}
              fontWeight="medium"
            >
              {label}
            </Styled.MenuLabel>
          </Styled.IconContainer>
        )}
        {hasNotification && (
          <Styled.HasNotificationContainer>
            {hasNotification}
          </Styled.HasNotificationContainer>
        )}
      </Styled.ItemButton>
    </Styled.ItemContainer>
  );

  return (
    <Tooltip
      className="nav-sub-menu-tooltip"
      placement="rightTop"
      content={subMenu?.length ? getTooltipContent() : null}
      color={Colors.Gray[900]}
      align={{ targetOffset: [8, 16] }}
      overlayStyle={{ maxWidth: 'unset' }}
      open={closeTooltip ? false : undefined}
      arrowPointAtCenter
      showArrow
      disableShadow
      disablePadding
    >
      {href || onClick ? (
        <Styled.StyledLink id={id} to={href ?? '#'}>
          {renderItem()}
        </Styled.StyledLink>
      ) : (
        renderItem()
      )}
    </Tooltip>
  );
};

export default NavigationItem;
