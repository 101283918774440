import styled, { css } from 'styled-components';
import { default as DefaultButton } from '../Button/Button';
import Colors from '../Colors';
import { default as DefaultTypography } from '../Typography/Typography';

export const Button = styled(DefaultButton)`
  max-width: 300px;
  margin-left: 0.75rem;
`;

export const ButtonContent = styled.span`
  display: flex;
  gap: 8px;
`;

export const ButtonText = styled.span`
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropDownContainer = styled.div<{
  $style?: string;
  $position?: string;
  $left?: number | null;
  $top?: number | null;
}>`
  z-index: 50;
  margin-top: 1rem;
  position: ${(props) => props.$position ?? 'absolute'};
  top: ${(props) => (props.$top ? `${props.$top}px` : 0)};
  left: ${(props) => (props.$left ? `${props.$left}px` : 0)};

  &&& {
    ${(props) =>
      css`
        ${props.$style}
      `}
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InputContainer = styled.div`
  padding-right: 12px;
`;

export const RootContent = styled.div`
  &.root {
    display: flex;
    flex-direction: column;
    min-width: 240px;
    max-width: 240px;
    padding-right: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    overflow: hidden;
    background-color: ${Colors.Common.White};
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(16, 24, 40, 0.1), 0 0 2px rgba(16, 24, 40, 0.05);
  }

  &.rootWithTitle {
    display: flex;
    flex-direction: column;
    min-width: 240px;
    max-height: 600px;
    padding-right: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    overflow-x: hidden;
    background-color: ${Colors.Common.White};
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(16, 24, 40, 0.1), 0 0 2px rgba(16, 24, 40, 0.05);
  }
`;

export const Typography = styled(DefaultTypography)`
  display: inline-block;
  max-width: 75%;
  margin-top: 20px;
  vertical-align: center;
`;
