import { useMutation } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

// Note: This endpoint is using a POST request due to size limitations of GET requests in AWS API
// Gateway. There are no side-effects.
export const MARKET_ASSETS_MUTATION = 'assets-market';

const getMarketAssets = async (requestParams: SDK.GetMarketAssetsRequest) => {
  const {
    projectId,
    maxRecords,
    offset,
    search,
    appsFilters,
    tagsFilters,
    adsFilters,
    marketMetricsFilters,
    hasPostQaAnnotations,
    isSaved,
  } = requestParams;
  const url = `${SDK.BASE_URL()}/market/assets?${SDK.convertRequestToQueryParams({
    projectId,
    maxRecords,
    offset,
    search,
    appsFilters,
    adsFilters,
    marketMetricsFilters,
    hasPostQaAnnotations,
    isSaved,
  })}`;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const res = await provider.getJson(url, { method: 'POST', body: { tagsFilters } });
  return res as SDK.GetMarketAssetsResponse;
};

export default function useMarketAssetsMutation() {
  return useMutation<SDK.GetMarketAssetsResponse, unknown, SDK.GetMarketAssetsRequest, unknown>(
    [MARKET_ASSETS_MUTATION],
    (requestParams: SDK.GetMarketAssetsRequest) => getMarketAssets(requestParams),
    { retry: 2 }
  );
}
