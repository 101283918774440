/* eslint-disable @typescript-eslint/no-unsafe-return */

import { useMountedState } from 'react-use';
import * as SDK from '@replai-platform/sdk';
import { messages } from '@replai-platform/sdk';
import {
  Avatar,
  AvatarProps,
  Colors,
  Icons,
  Input,
  Modal,
  ModalButton,
  ModalButtonsContainer,
  ModalHeader,
  Tooltip,
} from '@replai-platform/ui-components';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { logEvent } from '../../analytics';
import useAssetPreviewInfo from '../../api/hooks/assets/useAssetPreviewInfo';
import useConceptPreviewInfo from '../../api/hooks/concepts/useConceptPreviewInfo';
import useMarketAssetThumbnail from '../../api/hooks/market/useMarketAssetThumbnail';
import usePatchConceptDisplayName from '../../api/hooks/concepts/usePatchConceptDisplayName';
import { AppActions } from '../../store/app';
import { ConceptsActions } from '../../store/concepts';
import { DashboardActions } from '../../store/dashboard';
import { FilterActions } from '../../store/filters';
import { TypographyClean } from '../../utils/styles';
import Link from '../Link';
import VideoPreviewDialog from '../VideoPreviewDialog';
import { shortenString } from '../../utils';

const CopyIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 100%;
  background-color: transparent;
  opacity: 0;
  transition: opacity 300ms ease, background-color 300ms ease;
`;

const EditNameIconContainer = styled(CopyIconContainer)`
  right: 3rem;
`;

const Container = styled.div<{ clickable?: boolean }>`
  position: relative;
  padding: 0.25rem; /* Compensate for hover outline */

  /* make avatar clickable */
  > div > span:first-child {
    z-index: 1;
  }
  ${(props) => props.clickable && 'cursor: pointer;'}

  &:hover ${CopyIconContainer}, &:hover ${EditNameIconContainer} {
    background-color: rgba(228, 231, 236, 0.8);
    opacity: 1;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const LinkAnchor = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: 0;
  }
`;

export type VideoAvatarProps = {
  objectId: string;
  objectLevel: SDK.ObjectLevel;
  conceptId?: string;
  isActive?: boolean;
  isMarketData?: boolean;
  name?: string;
  originalName?: string;
  projectId: string;
  size?: AvatarProps['size'];
  additionalInfo?: string;
  videoHref?: string;
  videoHrefState?: Record<string, any>;
  eventOrigin?: string;
  allowEdit?: boolean;
  allowCopy?: boolean;
  containerOnClick?: () => void;
  linkOnClick?: () => void;
};

const VideoAvatar = ({
  objectId,
  objectLevel,
  conceptId,
  isActive,
  isMarketData,
  name,
  originalName,
  projectId,
  size,
  additionalInfo,
  videoHref,
  videoHrefState,
  eventOrigin,
  allowEdit = false,
  allowCopy = true,
  containerOnClick,
  linkOnClick,
}: VideoAvatarProps) => {
  const [open, setOpen] = useState(false);
  const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false);
  const [editedName, setEditedName] = useState<string | null>(null);
  const [isLoadingUpdateDisplayName, setIsLoadingUpdateDisplayName] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const isMounted = useMountedState();

  const mutation = usePatchConceptDisplayName();

  const { isLoading: isLoadingConceptPreviewInfo, data: conceptPreviewInfo } = useConceptPreviewInfo(
    {
      conceptId: objectId,
      size: '100x100',
      ...(projectId ? { projectId } : {}),
    },
    { enabled: objectLevel === SDK.ObjectLevel.CONCEPT }
  );

  const { isLoading: isLoadingAssetPreviewInfo, data: assetPreviewInfo } = useAssetPreviewInfo(
    { projectId, assetId: objectId, size: '100x100' },
    { enabled: objectLevel !== SDK.ObjectLevel.CONCEPT && !isMarketData }
  );

  const { isLoading: isLoadingMarketAssetPreviewInfo, data: marketAssetPreviewInfo } = useMarketAssetThumbnail(
    { assetId: objectId, size: '100x100' },
    { enabled: objectLevel === SDK.ObjectLevel.ASSET && isMarketData }
  );

  const copyNameToClipboard = async () => {
    logEvent({
      component: eventOrigin ?? 'Video Avatar',
      action: 'Click copy name',
      category: 'user_actions',
    });
    await navigator.clipboard.writeText(name ?? messages.NOT_AVAILABLE);
    dispatch(
      AppActions.setAlertOpen({
        badgeTitle: 'Success',
        badgeLabel: 'Copied to the clipboard',
        message: `Text '${name ? shortenString(name, 30) : messages.NOT_AVAILABLE}' was copied`,
        color: 'Success',
      })
    );
  };

  const saveEditName = async () => {
    setIsLoadingUpdateDisplayName(true);
    mutation.mutate(
      { projectId, conceptId: conceptId ?? objectId, newDisplayName: editedName ?? '' },
      {
        onSuccess: () => {
          // log event
          logEvent({
            component: 'Concepts',
            action: 'Edit concept name',
            category: 'user_actions',
          });
          // force refresh metrics query that feeds the table
          dispatch(ConceptsActions.changeShouldRefreshConceptsCache(true));
          // eslint-disable-next-line no-void
          void queryClient.removeQueries('concept-metrics');
          // force refresh recommendations query
          dispatch(DashboardActions.changeShouldRefreshConceptsCache(true));
          dispatch(FilterActions.changeShouldRefreshConceptsCache(true));
          // eslint-disable-next-line no-void
          void queryClient.removeQueries('recommendations-concept');
        },
        onSettled: () => {
          // Fix "Can't perform a React state update on an unmounted component" warning
          // Learn more: https://github.com/TanStack/query/issues/3804
          if (!isMounted()) return;

          setIsLoadingUpdateDisplayName(false);
          setIsEditNameModalOpen(false);
        },
      }
    );
  };

  return (
    <Container onClick={containerOnClick} clickable={!!containerOnClick} aria-label="Video Avatar">
      {videoHref && (
        <Link component={<LinkAnchor href={videoHref} />} onClick={linkOnClick} to={videoHref} state={videoHrefState} />
      )}
      <Avatar
        clickable
        imgUrl={
          isLoadingAssetPreviewInfo || isLoadingConceptPreviewInfo || isLoadingMarketAssetPreviewInfo
            ? undefined
            : (conceptPreviewInfo ?? assetPreviewInfo ?? marketAssetPreviewInfo)?.thumbnailUrl
        }
        label={name}
        secondaryLabel={additionalInfo}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          logEvent({
            component: 'Thumbnail',
            action: 'Click to Preview',
            category: 'user_actions',
            parameters: { objectId },
          });
          setOpen(true);
        }}
        squared
        overlayIcon="Play"
        outline="hover"
        size={size}
        status={isActive === true ? 'active' : isActive === false ? 'inactive' : undefined}
      />
      {allowEdit && (
        <EditNameIconContainer>
          <Tooltip content={<TypographyClean type="text-sm">Edit name</TypographyClean>}>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <IconWrapper onClick={() => setIsEditNameModalOpen(true)} data-test="edit-name" aria-label="Edit Name">
              <Icons.BaseIcons.Edit2 dimension={20} color={Colors.Gray[500]} />
            </IconWrapper>
          </Tooltip>
        </EditNameIconContainer>
      )}

      {allowCopy && (
        <CopyIconContainer>
          <Tooltip content={<TypographyClean type="text-sm">Copy name</TypographyClean>}>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <IconWrapper onClick={copyNameToClipboard} data-test="copy-name" aria-label="Copy name">
              <Icons.BaseIcons.Copy dimension={20} color={Colors.Gray[500]} />
            </IconWrapper>
          </Tooltip>
        </CopyIconContainer>
      )}
      <VideoPreviewDialog
        isImage={false}
        onClose={() => setOpen(false)}
        open={open}
        src={(conceptPreviewInfo ?? assetPreviewInfo ?? marketAssetPreviewInfo)?.previewUrl ?? ''}
      />
      <Modal
        onClick={(e) => e.stopPropagation()}
        onClose={() => setIsEditNameModalOpen(false)}
        isOpen={isEditNameModalOpen}
        modalHeader={
          <ModalHeader
            title="Edit concept display name"
            subtitle={`This won't change the concept's original name (${originalName ?? messages.NOT_AVAILABLE})`}
          />
        }
        modalFooter={
          <ModalButtonsContainer>
            <ModalButton variation="secondary" onClick={() => setIsEditNameModalOpen(false)}>
              Cancel
            </ModalButton>
            <ModalButton
              variation="primary"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={saveEditName}
              disabled={isLoadingUpdateDisplayName}
            >
              Save
            </ModalButton>
          </ModalButtonsContainer>
        }
      >
        <Input fullWidth type="text" defaultValue={name} onChange={(e) => setEditedName(e.target.value)} />
      </Modal>
    </Container>
  );
};

export default VideoAvatar;
