import * as SDK from '@replai-platform/sdk';
import { kpiUtils } from '@replai-platform/sdk';

/**
 * Checks if a KPI is superior to another
 */
const kpiCompare = ({
  kpi,
  value1,
  value2,
}: {
  kpi: SDK.Metrics;
  value1: SDK.Metric | Record<string, never> | undefined;
  value2: SDK.Metric | Record<string, never> | undefined;
}) => {
  const kpiValue1 = value1?.[kpi];
  const kpiValue2 = value2?.[kpi];
  return (kpiUtils.shouldMaximize(kpi) ? 1 : -1) * ((kpiValue1 ?? 0) - (kpiValue2 ?? 0));
};

export default kpiCompare;
