import { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase } from '@replai-platform/ui-components';
import { logEvent } from '../../../analytics';
import useTagTypes from '../../../api/hooks/tags/useTagTypes';
import type { RootState } from '../../../store/rootReducer';
import PickAndSortDialog, { PickAndSortDataGroup } from '../../../components/PickAndSortDialog';

const COMPONENT = 'Compare - Customize Visible Tags';

type CustomizeVisibleTagsProps = {
  open: boolean;
  genreTagTypes: string[];
  onClose: () => void;
  onVisibleTagsChange?: (tags: string[]) => void;
};

const CustomizeVisibleTags = ({ open, genreTagTypes, onClose, onVisibleTagsChange }: CustomizeVisibleTagsProps) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const excludedTagTypes = useSelector((state: RootState) => state.project.config.tagsTypes?.excluded ?? []);

  const [selectedTags, setSelectedTags] = useState<string[]>();

  const tagTypesParams = useMemo<SDK.GetTagsTypesRequest>(
    () => ({
      projectIds: [projectId],
      tagsFilters: {
        tagTypesToExclude: excludedTagTypes.map((type) => ({ type })),
      },
    }),
    [projectId, excludedTagTypes]
  );
  const { data: tagTypes, isLoading: isTagTypesLoading } = useTagTypes(tagTypesParams);

  // If genre tags are provided (market asset is used), only have the core tags and genre
  // tags and initially selected
  const initialValues = useMemo(
    () => [
      ...(Array.from(new Set(genreTagTypes)) || [])
        .filter((g) => !tagTypes?.map(({ type }) => type).includes(g))
        .map((type) => ({ label: camelCaseToCapitalCase(type), id: type }))
        .sort((a, b) => a.id.localeCompare(b.id)),
      ...(tagTypes || [])
        .filter(({ kind }) => (genreTagTypes.length === 0 ? true : kind === SDK.TagKind.Core))
        .map(({ type }) => ({ label: camelCaseToCapitalCase(type), id: type }))
        .sort((a, b) => a.id.localeCompare(b.id)),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(tagTypes), JSON.stringify(genreTagTypes)]
  );
  const groups = useMemo(() => {
    const nonMarketGroups = [SDK.TagKind.Custom, SDK.TagKind.Core, SDK.TagKind.Psychological].reduce((acc, group) => {
      const groupOptions = (tagTypes || [])?.filter(({ kind }) => kind === group);

      if (groupOptions?.length) {
        acc.push({
          title: camelCaseToCapitalCase(group),
          options: groupOptions
            .map(({ type }) => ({
              id: type,
              label: camelCaseToCapitalCase(type),
            }))
            .sort((a, b) => a.id.localeCompare(b.id)),
        });
      }

      return acc;
    }, [] as PickAndSortDataGroup[]);

    if (genreTagTypes.length === 0) return nonMarketGroups;

    // Filters market tag types that are already present in any other group
    const filteredGenreTagTypes = genreTagTypes.filter(
      (g) => !nonMarketGroups.some(({ options }) => !!options?.find(({ id }) => id === g))
    );
    return [
      {
        title: camelCaseToCapitalCase(SDK.TagKind.Market),
        options: filteredGenreTagTypes
          .map((type) => ({
            id: type,
            label: camelCaseToCapitalCase(type),
          }))
          .sort((a, b) => a.id.localeCompare(b.id)),
      },
      ...nonMarketGroups,
    ];
  }, [tagTypes, genreTagTypes]);

  const selectedOptions = useMemo(() => {
    if (selectedTags) {
      return selectedTags.map((id) => ({ id, label: camelCaseToCapitalCase(id) }));
    }

    return initialValues;
  }, [initialValues, selectedTags]);

  const onSave = (values: string[]) => {
    logEvent({
      component: COMPONENT,
      action: 'Save',
      category: 'user_actions',
    });

    setSelectedTags(values);

    onVisibleTagsChange?.(values);
    onClose?.();
  };

  useEffect(() => {
    if (initialValues?.length) {
      onVisibleTagsChange?.(initialValues.map(({ id }) => id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialValues), onVisibleTagsChange]);

  return (
    <PickAndSortDialog
      loading={isTagTypesLoading ? { messages: [] } : undefined}
      data={{ groups, initialValues: selectedOptions }}
      initialSelectedOptions={selectedOptions}
      onCancel={() => {
        logEvent({
          component: COMPONENT,
          action: 'Cancel',
          category: 'user_actions',
        });
        onClose?.();
      }}
      onSave={onSave}
      open={open}
      showSort={false}
      title="Customize visible tags"
      subtitle="Apply filters to update timelines data."
      onReset={() => {
        logEvent({
          component: COMPONENT,
          action: 'Reset',
          category: 'user_actions',
        });
      }}
    />
  );
};

export default CustomizeVisibleTags;
