import { ReportTemplates, ReportTypes } from '../models';

export const getReportTypeImageUrl = (reportType: ReportTypes) =>
  `https://replai-public-assets.s3.amazonaws.com/report-logos/${reportType.toLowerCase()}.png`;

export const getReportTemplateImageUrl = ({
  reportType,
  reportTemplate,
  index,
}: {
  reportType: ReportTypes;
  reportTemplate: ReportTemplates;
  index: number;
}) =>
  `https://replai-public-assets.s3.amazonaws.com/report-templates/template_${reportType.toLowerCase()}_${reportTemplate.toLowerCase()}_${index}.png`;
