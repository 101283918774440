import { Badge, Skeleton } from '@replai-platform/ui-components';
import 'react-loading-skeleton/dist/skeleton.css';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';

export type KpiColumnData = {
  frequencyRatio?: number;
  loading?: boolean;
};

function getFrequencyColumn<D extends object = object>({
  columnId,
  accessor,
}: RequiredGeneratorParams<D, KpiColumnData>): GeneratedColumn<D, KpiColumnData> {
  return {
    id: columnId,
    accessor,
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    Cell: (data: CellData<D, KpiColumnData>) => {
      const loading = data.cell.value?.loading;

      if (loading) {
        return <Skeleton height={20} width={100} />;
      }

      const { frequencyRatio } = data.cell.value;

      return (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Badge color="Gray">{((frequencyRatio ?? 0) * 100).toFixed(0)}%</Badge>{' '}
        </div>
      );
    },
  };
}

export default getFrequencyColumn;
