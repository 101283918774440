import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';
import { TwoColumnLayout } from '../shared/TwoColumnLayout';

const VUNGLE_FORM_URL = 'https://docs.google.com/forms/d/1vVLX3HqHmI10iwGvVnCIckfiEqlj77wNii1yL4X_LdM/viewform';

export const VungleConnectionGuide: NetworkConnectionGuideContent = {
  title: 'Vungle Connection Guide',
  icon: 'Vungle',
  guide: {
    label: 'Connection Guide',
    steps: [
      {
        title: 'Provide Vungle API key',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              {
                item: (
                  <>
                    Visit the{' '}
                    {Shared.link({
                      label: 'Vungle dashboard',
                      url: 'https://advertiser.vungle.com/applications',
                    })}
                    .
                  </>
                ),
              },
              { item: 'Click the 3 dots on the top-right corner and click "My Account".' },
              {
                item: (
                  <>
                    Find the &quot;Reporting API key&quot; section, copy the key and provide it to Replai using{' '}
                    {Shared.link({
                      label: 'this form',
                      url: VUNGLE_FORM_URL,
                    })}
                    .
                  </>
                ),
              },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/vungle/step-1.png"
          />
        ),
      },
      {
        title: 'Add Replai as a team member',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              { item: 'Click the 3 dots on the top-right corner and click "My Team".' },
              { item: 'Click on "Add Team Member".' },
              {
                item: (
                  <>
                    Create the new user with the email &quot;
                    <Shared.NoWrap>vungle+[your_company_name]@replai.io</Shared.NoWrap>&quot; (make sure not to forget
                    the &apos;+&apos;). Set the access level to &quot;Read-only Access&quot;.
                  </>
                ),
              },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/vungle/step-2.png"
          />
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: Shared.DEFAULT_NETWORK_FAQS,
  },
};
