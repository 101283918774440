import * as SDK from '@replai-platform/sdk';
import { Icons } from '@replai-platform/ui-components';

type IconDefinition = {
  iconName?: Icons.TagIconTypes;
  props?: Icons.TagIconProps;
};

// Config holding the mapping: database values <> tag icons
const iconConfig: Record<string, IconDefinition | ((value: string | null) => IconDefinition)> = {
  aspectRatio: (value) => {
    const aspectRatio = value?.split(':') ?? [];
    const orientation = SDK.getOrientation({
      width: Number(aspectRatio[0]),
      height: Number(aspectRatio[1]),
    });
    let iconName: Icons.TagIconTypes | undefined;
    switch (orientation) {
      case 'square':
        iconName = 'AspectRatio1By1';
        break;
      case 'landscape':
        iconName = 'AspectRatio16By9';
        break;
      case 'portrait':
        iconName = 'AspectRatio9By16';
        break;
      default:
        break;
    }
    return { iconName };
  },
  cta: { iconName: 'CallToAction' },
  colorfulness: (value) => {
    switch (value) {
      case 'not colorful':
        return { iconName: 'NotColorful' };
      case 'moderately colorful':
        return { iconName: 'ModeratelyColorful' };
      case 'very colorful':
        return { iconName: 'VeryColorful' };
      default:
        return { iconName: undefined };
    }
  },
  colorTemperature: (value) => ({ iconName: value === 'cool' ? 'Cool' : 'Warm' }),
  cutFrequency: (value) => {
    switch (value) {
      case 'low':
        return { iconName: 'CutFrequencyLow' };
      case 'normal':
        return { iconName: 'CutFrequencyNormal' };
      case 'high':
        return { iconName: 'CutFrequencyHigh' };
      default:
        return { iconName: undefined };
    }
  },
  duration: (value) => {
    switch (value) {
      case '0':
      case '0-8':
        return { iconName: 'DurationVeryShort' };
      case '8-13':
      case '13-17':
        return { iconName: 'DurationShort' };
      case '17-22':
      case '22-27':
      case '27-33':
        return { iconName: 'DurationMiddle' };
      case '33+':
        return { iconName: 'DurationLong' };
      default:
        return { iconName: undefined };
    }
  },
  introLoudness: (value) => {
    switch (value) {
      case 'no sound intro':
        return { iconName: 'NoSoundIntro' };
      case 'quiet intro':
        return { iconName: 'QuietIntro' };
      case 'moderate volume intro':
        return { iconName: 'ModerateVolumeIntro' };
      case 'loud intro':
        return { iconName: 'LoudIntro' };
      default:
        return { iconName: undefined };
    }
  },
  keyword: { iconName: 'Keyword' },
  language: (value) => ({
    iconName: 'Language',
    props: { languageCode: value ?? undefined },
  }),
  luminosity: (value) => {
    switch (value) {
      case 'bright':
        return { iconName: 'BrightLuminosity' };
      case 'moderate brightness':
        return { iconName: 'ModerateBrightness' };
      case 'dark':
        return { iconName: 'DarkLuminosity' };
      default:
        return { iconName: undefined };
    }
  },
  music: { iconName: 'Music' },
  numberOfScenes: (value) => {
    switch (value) {
      case '1-2':
        return { iconName: 'NumberOfScenes1To2' };
      case '3-4':
        return { iconName: 'NumberOfScenes3To4' };
      case '5-10':
        return { iconName: 'NumberOfScenes5To10' };
      case '11+':
        return { iconName: 'NumberOfScenes11OrMore' };
      default:
        return { iconName: undefined };
    }
  },
  pace: (value) => {
    switch (value) {
      case 'slow pace':
        return { iconName: 'SlowPace' };
      case 'moderate pace':
        return { iconName: 'ModeratePace' };
      case 'fast pace':
        return { iconName: 'FastPace' };
      default:
        return { iconName: undefined };
    }
  },
  saturation: (value) => {
    switch (value) {
      case 'grayscale':
        return { iconName: 'Grayscale' };
      case 'low saturation':
        return { iconName: 'LowSaturation' };
      case 'moderate saturation':
        return { iconName: 'ModerateSaturation' };
      case 'high saturation':
        return { iconName: 'HighSaturation' };
      default:
        return { iconName: undefined };
    }
  },
  sceneDuration: (value) => {
    switch (value) {
      case '0-3':
        return { iconName: 'SceneDurationLessThan3Seconds' };
      case '3-8':
        return { iconName: 'SceneDuration3To8Seconds' };
      case '8-20':
        return { iconName: 'SceneDuration8To20Seconds' };
      case '20+':
        return { iconName: 'SceneDurationGreaterThan20Seconds' };
      default:
        return { iconName: undefined };
    }
  },
  sceneTransitionsDiff: (value) => {
    switch (value) {
      case 'low variance':
        return { iconName: 'LowSceneTransitionsDiff' };
      case 'normal':
        return { iconName: 'NormalSceneTransitionsDiff' };
      case 'high variance':
        return { iconName: 'HighSceneTransitionsDiff' };
      default:
        return { iconName: undefined };
    }
  },
  orientation: (value) => {
    switch (value) {
      case 'square':
        return { iconName: 'AspectRatio1By1' };
      case 'landscape':
        return { iconName: 'AspectRatio16By9' };
      case 'portrait':
        return { iconName: 'AspectRatio9By16' };
      default:
        return { iconName: undefined };
    }
  },
};

export const getTagIconDefinition = ({ type, value }: { type: string; value: string | null }) => {
  if (!iconConfig[type]) return { iconName: undefined, props: undefined };

  return (iconConfig[type] as IconDefinition).iconName
    ? (iconConfig[type] as IconDefinition)
    : (iconConfig[type] as (value: string | null) => IconDefinition)(value);
};

export default iconConfig;
