import { CountryIconProps } from './types';

const FM = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#338AF3"
    />
    <path
      d="M12.001 5.21741L12.519 6.81172H14.1954L12.8392 7.79708L13.3572 9.39134L12.001 8.40603L10.6448 9.39134L11.1629 7.79708L9.80664 6.81172H11.483L12.001 5.21741Z"
      fill="#F0F0F0"
    />
    <path
      d="M5.21777 11.9997L6.81209 11.4816V9.80518L7.79745 11.1615L9.39171 10.6434L8.4064 11.9997L9.39171 13.3558L7.79745 12.8377L6.81209 14.1939V12.5176L5.21777 11.9997Z"
      fill="#F0F0F0"
    />
    <path
      d="M12.0011 18.7829L11.4831 17.1886H9.80664L11.1629 16.2033L10.6449 14.609L12.0011 15.5943L13.3572 14.609L12.8392 16.2033L14.1954 17.1886H12.5191L12.0011 18.7829Z"
      fill="#F0F0F0"
    />
    <path
      d="M18.7833 11.9995L17.189 12.5176V14.1939L16.2036 12.8377L14.6094 13.3557L15.5947 11.9995L14.6094 10.6434L16.2036 11.1615L17.189 9.80518V11.4816L18.7833 11.9995Z"
      fill="#F0F0F0"
    />
  </svg>
);
export default FM;
