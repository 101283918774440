import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as SDK from '@replai-platform/sdk';
import { MultiSelectDropDownOption } from '@replai-platform/ui-components';
import { capitalCase } from 'capital-case';
import { ReportsActions } from '../../../../store/reports';
import { RootState } from '../../../../store/rootReducer';

const TREND_SLIDES_TO_INCLUDE_SELECTOR_DEFAULT_OPTIONS = Object.values(SDK.TrendSlideToInclude).map((value) => ({
  id: value,
  label: capitalCase(value),
  selected: true,
}));

const useTrendSlidesToIncludeSelector = (): {
  trendSlidesToIncludeSelectorOptions: MultiSelectDropDownOption[];
  trendSlidesToIncludeSelectorOnChange: (options: MultiSelectDropDownOption[]) => void;
} => {
  const dispatch = useDispatch();
  const { trendSlidesToInclude } = useSelector((state: RootState) => state.reports);

  const trendSlidesToIncludeSelectorOptions = useMemo(
    () =>
      TREND_SLIDES_TO_INCLUDE_SELECTOR_DEFAULT_OPTIONS.map((option) => ({
        ...option,
        selected: trendSlidesToInclude.includes(option.id),
      })),
    [trendSlidesToInclude]
  );

  const trendSlidesToIncludeSelectorOnChange = useCallback(
    (options: MultiSelectDropDownOption[]) => {
      const selectedOptions = options
        .filter((option) => option.selected)
        .map((option) => (option.id ?? '') as SDK.TrendSlideToInclude);

      dispatch(
        ReportsActions.changeTrendSlidesToInclude({
          value: selectedOptions,
        })
      );
    },
    [dispatch]
  );

  return { trendSlidesToIncludeSelectorOptions, trendSlidesToIncludeSelectorOnChange };
};

export default useTrendSlidesToIncludeSelector;
