/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */

import { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ButtonIcon } from '../Button/Button';
import Colors from '../Colors';
import * as Icons from '../Icons';
import Input from '../Input/Input';
import Modal, { ModalProps } from '../Modal/Modal';
import { ModalButton, ModalButtonsContainer } from '../Modal/ModalButtons';
import { ModalHeader } from '../Modal/ModalHeader';
import PickAndSort from '../PickAndSort/PickAndSort';
import Typography from '../Typography/Typography';

import LoadingScreen, {
  LoadingScreenProps,
} from '../LoadingScreen/LoadingScreen';
import MultiSelectDropDown from '../MultiSelectDropDown/MultiSelectDropDown';
import { Tooltip } from '..';

const StyledModal = styled(Modal)`
  &.customizableOptionsSelector.replai-ant-modal .ant-modal-body {
    margin: 0;
    padding: 0;
  }
  &.customizableOptionsSelector.replai-ant-modal .ant-modal-content {
    width: fit-content;
  }

  &.customizableOptionsSelector [data-test='multi-select-drop-down-container'] {
    box-shadow: unset;
  }
`;

const LoadingScreenWrapper = styled.div`
  position: relative;
  float: left;
  width: 800px;
  height: 10rem;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const SelectableOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  max-height: 500px;
  overflow: auto;

  [data-test='multi-select-drop-down-option'] {
    padding: 6px 24px;
    line-height: 0;
  }
`;

const TypographyNoMargin = styled(Typography)`
  margin: 0;
`;

// BEGIN - PickAndSort Container
const PickAndSortContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-height: 500px;
  background-color: '${Colors.Gray[50]}';
`;

const PickAndSortHeader = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-height: 500px;
  padding-top: 24px;
  padding-left: 24px;
  background-color: ${Colors.Gray[50]};
`;

const PickAndSortContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  height: 100%;
  min-height: 400px;
  max-height: 500px;
  overflow-x: auto;
  background-color: ${Colors.Gray[50]};
`;
// END - PickAndSort Container

// BEGIN - PickAndSort items to display components
const ListItemMain = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

const ListItemContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
`;

const ListItemAction = styled.div`
  display: flex;
  cursor: pointer;
`;
// END - PickAndSort items to display components

const InputContainer = styled.div`
  padding: 16px 16px 0px 16px;
`;

type SectionData = { title: string; options: SelectDropDownOption[] };

export type SelectDropDownOption = {
  id: string;
  label: string;
  selected?: boolean;
  isAllOption?: boolean;
  disallowed?: boolean;
};

interface CustomizableOptionsSelectorProps extends ModalProps {
  onSave: (selectedOptions: SelectDropDownOption[]) => void;
  title: string;
  subtitle?: string;
  availableOptions: SectionData[];
  selectedOptions: SelectDropDownOption[];
  loading?: LoadingScreenProps;
  initialSelectedOptions?: SelectDropDownOption[];
  showSort?: boolean;
  onReset?: () => void;
  disallowedTooltip?: string;
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

// manually implementing replaceAll due to the ui-components lib being used on both the client
// side and on the server side (on the server side the "target" is "ES2015" which does not yet have the replaceAll
// function implemented)
function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

const CustomizableOptionsSelector = ({
  isOpen = true,
  title: modalTitle,
  subtitle,
  availableOptions,
  selectedOptions,
  initialSelectedOptions,
  showSort = true,
  onClose,
  onSave,
  onReset,
  loading,
  disallowedTooltip,
}: CustomizableOptionsSelectorProps) => {
  // When an action is triggered, the button should be disabled and give some experience of saving
  const [leadingIcon, setLeadingIcon] = useState<ButtonIcon | undefined>(
    undefined
  );
  const [optionsData, setOptionsData] = useState(() => {
    const selectedOptionsIds = selectedOptions.map((option) => option.id);
    return availableOptions.map(({ title, options }) => ({
      title,
      options: [
        {
          id: `Select all ${title}`,
          label: `Select all`,
          isAllOption: true,
          selected:
            options.filter((o) => selectedOptionsIds.includes(o.id)).length ===
            options.length,
        },
        ...options.map(
          ({ id, label, isAllOption, disallowed }) =>
            ({
              id,
              label,
              selected: selectedOptionsIds.includes(id),
              isAllOption,
              disallowed,
            } as SelectDropDownOption)
        ),
      ],
    }));
  });
  const [filteredOptions, setFilteredOptions] = useState<SectionData[] | null>(
    null
  );
  const [selectedData, setSelectedData] = useState(selectedOptions);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const expandedState = useRef<{ [key: string]: boolean }>({});

  const onResetClickHandler = () => {
    if (initialSelectedOptions) {
      setSelectedData(initialSelectedOptions);
      const selectedOptionsIds = initialSelectedOptions.map(
        (option) => option.id
      );
      const optionsData = availableOptions.map(({ title, options }) => ({
        title,
        options: [
          {
            id: `Select all ${title}`,
            label: `Select all`,
            isAllOption: true,
            selected:
              options.filter((o) => selectedOptionsIds.includes(o.id))
                .length === options.length,
          },
          ...options.map(
            ({ id, label, isAllOption, disallowed }) =>
              ({
                id,
                label,
                selected: selectedOptionsIds.includes(id),
                isAllOption,
                disallowed,
              } as SelectDropDownOption)
          ),
        ],
      }));
      setOptionsData(optionsData);
    }
    onReset?.();
  };

  const updateOptionsData = (selectDropdownValue: SelectDropDownOption[]) => {
    setOptionsData(
      optionsData.map(({ title, options }) => ({
        title,
        options: options.map((option) => {
          const existingOption = selectDropdownValue.find(
            ({ id }) => id === option.id
          );
          if (existingOption) {
            option.selected = existingOption.selected;
          }
          return option;
        }),
      }))
    );
  };

  const columnPresentation = ({
    id,
    label,
    disallowed,
  }: {
    id: string;
    label: string;
    disallowed?: boolean;
  }): ReactNode => {
    const item = (
      <ListItemMain>
        <ListItemContent>
          <Icons.BaseIcons.DragIndicator color={Colors.Gray[500]} />
          <TypographyNoMargin
            type="text-sm"
            fontWeight="medium"
            color={disallowed ? Colors.Gray[400] : Colors.Common.Black}
          >
            {label}
          </TypographyNoMargin>
        </ListItemContent>
        <ListItemAction
          onClick={() => {
            updateOptionsData([{ id, label, selected: false }]);
          }}
        >
          <Icons.BaseIcons.X color={Colors.Gray[500]} />
        </ListItemAction>
      </ListItemMain>
    );

    return disallowed ? (
      <Tooltip
        content={
          <TypographyNoMargin type="text-sm">
            {disallowedTooltip}
          </TypographyNoMargin>
        }
      >
        {item}
      </Tooltip>
    ) : (
      item
    );
  };

  // Disable the saving icon after when the Modal is closed
  useEffect(() => {
    if (!isOpen) {
      setLeadingIcon(undefined);
    }
  }, [isOpen]);

  useEffect(() => {
    // If there is no data, do not apply the useEffect
    if (!availableOptions || !selectedOptions) {
      return;
    }
    // all the options of the selected items
    const selectedItems = optionsData.reduce((result, optionData) => {
      optionData.options.forEach((option) => {
        if (option.selected && !option.isAllOption) {
          result.push(option);
        }
      });
      return result;
    }, [] as SelectDropDownOption[]);

    // Create an object with: the already selected ids, recently added options
    const selectedDataIds = selectedData.map((item) => item.id);
    const computedSelectedDataIds = selectedItems.reduce(
      (result, item) => {
        if (selectedDataIds.includes(item.id)) {
          result.existent.push(item.id);
        } else {
          result.new.push(item);
        }
        return result;
      },
      {
        existent: [] as string[],
        new: [] as SelectDropDownOption[],
      }
    );

    // Keep the old data in the array
    const newSelectedData = selectedData.filter(({ id }) =>
      computedSelectedDataIds.existent.includes(id)
    );
    // Add new selected items to array
    newSelectedData.push(...computedSelectedDataIds.new);

    setSelectedData(newSelectedData);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    JSON.stringify(optionsData),
    JSON.stringify(availableOptions),
    JSON.stringify(selectedOptions),
    loading,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (searchTerm) {
      const filteredResults = optionsData.map((category) => ({
        ...category,
        options: (category.options ?? []).filter(
          (o) =>
            o.label.toLowerCase().search(searchTerm.trim().toLowerCase()) > -1
        ),
      }));
      setFilteredOptions(filteredResults.filter((c) => c.options?.length));
    } else {
      setFilteredOptions(null);
    }
  }, [searchTerm, optionsData]);

  return (
    <StyledModal
      data-test="customizable-options-selector"
      className="customizableOptionsSelector"
      width={showSort ? '800px' : '420px'}
      isOpen={isOpen}
      onClose={onClose}
      modalHeader={<ModalHeader title={modalTitle} subtitle={subtitle} />}
      modalFooter={
        <ModalButtonsContainer>
          <ModalButton variation="secondary" onClick={onClose}>
            Cancel
          </ModalButton>
          <ModalButton variation="secondary" onClick={onResetClickHandler}>
            Reset
          </ModalButton>
          <ModalButton
            key={JSON.stringify(leadingIcon)} // This is here in order to disable the button while action executing the 'save'
            disabled={!!loading || !!leadingIcon}
            leadingIcon={leadingIcon}
            variation="primary"
            onClick={() => {
              setLeadingIcon({ name: 'LoadingCircle' });
              onSave(selectedData);
            }}
          >
            Save and Update
          </ModalButton>
        </ModalButtonsContainer>
      }
    >
      <MainContainer>
        {loading ? (
          <LoadingScreenWrapper>
            <LoadingScreen {...loading} />
          </LoadingScreenWrapper>
        ) : (
          <>
            <SelectableOptions>
              <InputContainer>
                <Input
                  data-test="customizable-options-selector-search"
                  autoComplete="off"
                  leadingIcon="Search"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputContainer>
              {(filteredOptions || optionsData).map(({ title, options }) => (
                <MultiSelectDropDown
                  key={JSON.stringify(
                    {
                      title,
                      options,
                    } /* Would be nice to find an alternative for this */
                  )}
                  title={title}
                  id={replaceAll(JSON.stringify(title), ' ', '-')}
                  limit={
                    expandedState.current[
                      replaceAll(JSON.stringify(title), ' ', '-')
                    ]
                      ? 0
                      : 4
                  }
                  disableScroll
                  disableSearch
                  options={options}
                  onChange={(data) => {
                    updateOptionsData(
                      data.map(
                        ({ id, label, selected }) =>
                          ({ id, label, selected } as SelectDropDownOption)
                      )
                    );
                  }}
                  onShowMoreClick={(id) => {
                    expandedState.current[id] = true;
                  }}
                />
              ))}
            </SelectableOptions>
            {showSort ? (
              <PickAndSortContainer>
                <PickAndSortHeader>
                  <TypographyNoMargin
                    type="text-sm"
                    fontWeight="regular"
                    color={Colors.Gray[700]}
                  >
                    Selected Categories Order
                  </TypographyNoMargin>
                </PickAndSortHeader>
                <PickAndSortContent>
                  <PickAndSort
                    style={{ width: '100%' }}
                    data={selectedData.map(({ id, label, disallowed }) => ({
                      id,
                      label,
                      renderedValue: columnPresentation({
                        id,
                        label,
                        disallowed,
                      }),
                      disallowed,
                    }))}
                    onSorted={(data) =>
                      setSelectedData(
                        data.map(({ id, label, disallowed }) => ({
                          id,
                          label,
                          disallowed,
                        }))
                      )
                    }
                  />
                </PickAndSortContent>
              </PickAndSortContainer>
            ) : undefined}
          </>
        )}
      </MainContainer>
    </StyledModal>
  );
};

export default CustomizableOptionsSelector;
export type { CustomizableOptionsSelectorProps };
