import { useMutation } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const createInvite = async (requestParams: { emails: string[]; projectId: SDK.UUID }) => {
  const { emails, projectId } = requestParams;
  const url = `${SDK.BASE_URL()}/invite?projectIds=["${projectId}"]`;
  const res = await provider.getJson<object, string[]>(url, { method: 'POST', body: { emails } });
  return res;
};

export default function useInvite() {
  return useMutation(['user-invite'], (requestParams: { emails: string[]; projectId: SDK.UUID }) =>
    createInvite(requestParams)
  );
}
