import { CommonTagIconProps } from '../types';

const NotColorful = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 20.655C16.78 20.655 20.655 16.78 20.655 12C20.655 7.21995 16.78 3.34497 12 3.34497C7.21995 3.34497 3.34497 7.21995 3.34497 12C3.34497 16.78 7.21995 20.655 12 20.655Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.8359 6.32202L6.16394 17.994"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default NotColorful;
