import { motion } from 'framer-motion';
import styled from 'styled-components';
import { pageTransition } from '../configurations/animations';

export const RouteAnimator = styled(motion.div).attrs({
  initial: 'out',
  animate: 'in',
  exit: 'out',
  variants: pageTransition,
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`;
