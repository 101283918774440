import { CountryIconProps } from './types';

const IR = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3644)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M15.8976 8.86968H14.3275C14.3405 9.04222 14.3479 9.21632 14.3479 9.39139C14.3479 10.5522 14.0577 11.6774 13.5519 12.4783C13.3958 12.7253 13.1336 13.0741 12.7826 13.3094V8.86963H11.2174V13.3094C10.8665 13.0741 10.6042 12.7253 10.4481 12.4783C9.94227 11.6774 9.65216 10.5522 9.65216 9.39139C9.65216 9.21632 9.65957 9.04218 9.67251 8.86968H8.10248C8.0924 9.04138 8.08691 9.21529 8.08691 9.39139C8.08691 12.6096 9.80573 15.1305 11.9999 15.1305C14.1942 15.1305 15.913 12.6096 15.913 9.39139C15.9131 9.21529 15.9076 9.04138 15.8976 8.86968Z"
        fill="#D80027"
      />
      <path
        d="M4.9572 5.73816H6.52241V6.78165H8.08761V5.73816H9.65281V6.78165H11.218V5.73816H12.7832V6.78165H14.3484V5.73816H15.9136V6.78165H17.4788V5.73816H19.044V6.78165H22.8098C20.869 2.7677 16.7582 -0.000976562 12.0007 -0.000976562C7.2432 -0.000976562 3.13241 2.7677 1.19141 6.78165H4.9572V5.73816Z"
        fill="#6DA544"
      />
      <path
        d="M19.0442 17.217V18.2605H17.479V17.217H15.9138V18.2605H14.3486V17.217H12.7834V18.2605H11.2182V17.217H9.65295V18.2605H8.08775V17.217H6.52241V18.2605H4.9572V17.217H1.19141C3.13241 21.231 7.24316 23.9997 12.0007 23.9997C16.7582 23.9997 20.869 21.231 22.81 17.217H19.0442Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3644">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IR;
