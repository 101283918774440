import { SocialIconProps } from './types';

const YouTube = ({ dimension }: SocialIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1027_1533)">
      <path
        d="M23.5216 6.18547C23.3859 5.67489 23.1185 5.20889 22.7462 4.83413C22.3738 4.45936 21.9095 4.18897 21.3998 4.05002C19.5234 3.54547 12.0234 3.54547 12.0234 3.54547C12.0234 3.54547 4.52344 3.54547 2.64707 4.05002C2.13737 4.18897 1.6731 4.45936 1.30073 4.83413C0.928354 5.20889 0.660943 5.67489 0.525256 6.18547C0.0234376 8.07002 0.0234375 12 0.0234375 12C0.0234375 12 0.0234376 15.93 0.525256 17.8146C0.660943 18.3251 0.928354 18.7911 1.30073 19.1659C1.6731 19.5407 2.13737 19.8111 2.64707 19.95C4.52344 20.4546 12.0234 20.4546 12.0234 20.4546C12.0234 20.4546 19.5234 20.4546 21.3998 19.95C21.9095 19.8111 22.3738 19.5407 22.7462 19.1659C23.1185 18.7911 23.3859 18.3251 23.5216 17.8146C24.0234 15.93 24.0234 12 24.0234 12C24.0234 12 24.0234 8.07002 23.5216 6.18547Z"
        fill="#FF0302"
      />
      <path
        d="M9.56934 15.5687V8.4314L15.8421 12L9.56934 15.5687Z"
        fill="#FEFEFE"
      />
    </g>
    <defs>
      <clipPath id="clip0_1027_1533">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default YouTube;
