/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { messages } from '@replai-platform/sdk';
import { Colors, LinearProgress, Skeleton, Typography } from '@replai-platform/ui-components';
import millify from 'millify';
import { CellProps } from 'react-table';
import styled from 'styled-components';
import { GeneratedColumn, RequiredGeneratorParams } from '../common';
import ColumnHeader from '../generateHeader';

const SpendContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const LinearProgressWrapper = styled.div`
  width: 5rem;
`;

const TypographyWithMinWidth = styled(Typography)`
  min-width: 50px;
`;

const normalize = (value, maxValue) => value / maxValue;

export type SpendAccessorType = {
  spendValue?: number | null;
  total?: number;
  loading?: boolean;
  disabled?: boolean;
};

export type SpendType = {
  columnId: string;
  accessor: (data: any) => SpendAccessorType;
  Header?: string | JSX.Element;
  tooltip?: string;
};

const DisabledSpend = styled.span`
  color: ${Colors.Gray[400]};
`;

const SpendCellDecorator: React.FC<{
  value?: number | null;
  total?: number;
  loading?: boolean;
  disabled?: boolean;
}> = ({ value, total, loading, disabled }) => {
  if (loading) {
    return <Skeleton height={20} width={100} />;
  }

  const valueIsSet = value !== null && value !== undefined;
  return (
    <SpendContainer>
      <TypographyWithMinWidth noMargin fontWeight="medium" type="text-sm">
        {disabled ? (
          <DisabledSpend>{valueIsSet ? millify(value) : messages.NOT_AVAILABLE}</DisabledSpend>
        ) : valueIsSet ? (
          millify(value)
        ) : (
          messages.NOT_AVAILABLE
        )}
      </TypographyWithMinWidth>
      <LinearProgressWrapper>
        {valueIsSet ? <LinearProgress value={value >= 0.05 ? normalize(value, total ?? 1) : 0} /> : null}
      </LinearProgressWrapper>
    </SpendContainer>
  );
};

function getSpendColumn<D extends object = object>({
  columnId,
  accessor,
  includeFooter = false,
}: RequiredGeneratorParams<D, SpendAccessorType>): GeneratedColumn<D, SpendAccessorType> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return {
    id: columnId,
    accessor,
    width: 170,
    minWidth: 170,
    maxWidth: 170,
    Cell: ({ value }: CellProps<SpendAccessorType>) => {
      const spendValue = value?.spendValue;
      const total = value?.total;
      const loading = value?.loading;
      const disabled = value?.disabled;

      return <SpendCellDecorator value={spendValue} total={total} loading={loading} disabled={disabled} />;
    },
    Header: <ColumnHeader title="Spend" />,
    tooltip: 'Money spent on advertising campaigns.',
    Footer: (info) =>
      includeFooter
        ? (
            <SpendCellDecorator
              value={info.data?.[0]?.cluster?.metrics?.totalSpend ?? info.data?.[0]?.cluster?.totalSpend}
              total={info.data?.[0]?.cluster?.metrics?.totalSpend ?? info.data?.[0]?.cluster?.totalSpend}
            />
          ) ?? null
        : null,
  };
}

export default getSpendColumn;
