import styled, { css } from 'styled-components';

const FontWeights = css`
  &.regular {
    font-weight: 400;
  }
  &.medium {
    font-weight: 500;
  }
  &.semi-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
`;

export const H1 = styled.h1<{
  $noMargin?: boolean;
  $color?: string;
  $style?: string;
}>`
  font-size: 4.5rem;
  line-height: 5.625rem;
  color: ${(props) => props.$color};

  ${FontWeights}
  ${(props) => (props.$noMargin ? NoMarginMixin : '')}

  &&& {
    ${(props) =>
      css`
        ${props.$style}
      `};
  }
`;

export const H2 = styled.h2<{
  $noMargin?: boolean;
  $color?: string;
  $style?: string;
}>`
  font-size: 3.75rem;
  line-height: 4.625rem;
  color: ${(props) => props.$color};

  ${FontWeights}
  ${(props) => (props.$noMargin ? NoMarginMixin : '')}

  &&& {
    ${(props) =>
      css`
        ${props.$style}
      `};
  }
`;

export const H3 = styled.h3<{
  $noMargin?: boolean;
  $color?: string;
  $style?: string;
}>`
  font-size: 3rem;
  line-height: 3.75rem;
  color: ${(props) => props.$color};

  ${FontWeights}
  ${(props) => (props.$noMargin ? NoMarginMixin : '')}
  
  &&& {
    ${(props) =>
      css`
        ${props.$style}
      `};
  }
`;

export const H4 = styled.h4<{
  $noMargin?: boolean;
  $color?: string;
  $style?: string;
}>`
  font-size: 2.25rem;
  line-height: 2.75rem;
  color: ${(props) => props.$color};

  ${FontWeights}
  ${(props) => (props.$noMargin ? NoMarginMixin : '')}
  
  &&& {
    ${(props) =>
      css`
        ${props.$style}
      `};
  }
`;

export const H5 = styled.h5<{
  $noMargin?: boolean;
  $color?: string;
  $style?: string;
}>`
  font-size: 1.875rem;
  line-height: 2.375rem;
  color: ${(props) => props.$color};

  ${FontWeights}
  ${(props) => (props.$noMargin ? NoMarginMixin : '')}
  
  &&& {
    ${(props) =>
      css`
        ${props.$style}
      `};
  }
`;

export const Paragraph = styled.p<{
  $noMargin?: boolean;
  $color?: string;
  $style?: string;
}>`
  color: ${(props) => props.$color};

  &.display-xs {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  &.text-xl {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  &.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  &.text-md {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  &.text-xs {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  &.text-xxs {
    font-size: 0.625rem;
    line-height: 0.75rem;
  }

  ${FontWeights}
  ${(props) => (props.$noMargin ? NoMarginMixin : '')}
  
  &&& {
    ${(props) =>
      css`
        ${props.$style}
      `};
  }
`;

/*  Mixins  */

const NoMarginMixin = css`
  margin: 0;
`;
