/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as BaseIcons from './Base';
import * as CountryIcons from './Country';
import * as IntegrationIcons from './Integrations';
import * as MiscIcons from './Misc';
import * as PaymentIcons from './Payment';
import * as TagIcons from './Tag';
import * as SocialIcons from './Social';
import * as WYSIWYGIcons from './WYSIWYG';
import { IconType } from '.';

export type AllIconTypes =
  | BaseIcons.BaseIconTypes
  | SocialIcons.Brand.BandSocialIconTypes
  | CountryIcons.CountryIconTypes
  | SocialIcons.Flat.FlatSocialIconTypes
  | IntegrationIcons.IntegrationIconTypes
  | MiscIcons.MiscIconTypes
  | PaymentIcons.PaymentIconTypes
  | TagIcons.TagIconTypes
  | WYSIWYGIcons.WYSIWYGType;

const getBaseIcon = (name: BaseIcons.BaseIconTypes) => BaseIcons[name];

const getBrandIcon = (name: SocialIcons.Brand.BandSocialIconTypes) =>
  SocialIcons.Brand[name];

const getCountryIcon = (name: CountryIcons.CountryIconTypes) =>
  CountryIcons[name];

const getFlatIcon = (name: SocialIcons.Flat.FlatSocialIconTypes) =>
  SocialIcons.Flat[name];

const getIntegrationIcon = (name: IntegrationIcons.IntegrationIconTypes) =>
  IntegrationIcons[name];

const getMiscIcon = (name: MiscIcons.MiscIconTypes) => MiscIcons[name];

const getPaymentIcon = (name: PaymentIcons.PaymentIconTypes) =>
  PaymentIcons[name];

const getTagIcon = (name: TagIcons.TagIconTypes) => TagIcons[name];

const getWYSIWYGIcon = (
  name: WYSIWYGIcons.WYSIWYGType,
  variant: 'md' | 'lg' = 'md'
) =>
  WYSIWYGIcons[`${variant.toUpperCase()}${name}` as keyof typeof WYSIWYGIcons];

const getIcon = ({
  name,
  iconType,
}: {
  name: AllIconTypes;
  iconType: IconType;
}) => {
  switch (iconType) {
    case IconType.BASE:
      return getBaseIcon(name as BaseIcons.BaseIconTypes);
    case IconType.BRAND:
      return getBrandIcon(name as SocialIcons.Brand.BandSocialIconTypes);
    case IconType.COUNTRY:
      return getCountryIcon(name as CountryIcons.CountryIconTypes);
    case IconType.FLAT:
      return getFlatIcon(name as SocialIcons.Flat.FlatSocialIconTypes);
    case IconType.INTEGRATION:
      return getIntegrationIcon(name as IntegrationIcons.IntegrationIconTypes);
    case IconType.MISC:
      return getMiscIcon(name as MiscIcons.MiscIconTypes);
    case IconType.PAYMENT:
      return getPaymentIcon(name as PaymentIcons.PaymentIconTypes);
    case IconType.TAG:
      return getTagIcon(name as TagIcons.TagIconTypes);
    case IconType.WYSIWYG:
      return getWYSIWYGIcon(name as WYSIWYGIcons.WYSIWYGType);
    default: {
      const exhaustiveCheck: never = iconType;
      return exhaustiveCheck;
    }
  }
};

export {
  getIcon,
  getBaseIcon,
  getBrandIcon,
  getCountryIcon,
  getFlatIcon,
  getIntegrationIcon,
  getMiscIcon,
  getPaymentIcon,
  getTagIcon,
  getWYSIWYGIcon,
};
