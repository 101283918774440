import { CountryIconProps } from './types';

const SY = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3767)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9997 -0.000732422C6.84013 -0.000732422 2.44161 3.25577 0.746094 7.82538H23.2534C21.5578 3.25577 17.1593 -0.000732422 11.9997 -0.000732422Z"
        fill="#D80027"
      />
      <path
        d="M11.9997 24.0005C17.1593 24.0005 21.5578 20.744 23.2533 16.1744H0.746094C2.44161 20.744 6.84012 24.0005 11.9997 24.0005Z"
        fill="black"
      />
      <path
        d="M7.17067 9.13013L7.81815 11.1229H9.91361L8.21837 12.3547L8.8659 14.3475L7.17067 13.1159L5.47544 14.3475L6.12297 12.3547L4.42773 11.1229H6.52319L7.17067 9.13013Z"
        fill="#6DA544"
      />
      <path
        d="M16.8308 9.13013L17.4783 11.1229H19.5738L17.8785 12.3547L18.5261 14.3475L16.8308 13.1159L15.1356 14.3475L15.7831 12.3547L14.0879 11.1229H16.1833L16.8308 9.13013Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3767">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SY;
