import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Colors, Icons } from '@replai-platform/ui-components';
import { useDeleteCreativePreferencesFile } from '../../../api/hooks/creativeProduction/useCreativeRequests';
import { RootState } from '../../../store/rootReducer';

export const IconButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

const FileIcon = Icons.getBaseIcon('File');
const TrashIcon = Icons.getBaseIcon('Trash');

type PreferencesFileEntryProps = {
  fileId: string;
  fileName: string;
};

const PreferencesFileEntry = ({ fileId, fileName }: PreferencesFileEntryProps) => {
  const projectId = useSelector((state: RootState) => state.project.id);

  const deleteProductionCreativeRequest = useDeleteCreativePreferencesFile(projectId);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <FileIcon dimension={24} />
      <span style={{ flexGrow: 1, paddingLeft: '1.2rem' }}>{fileName}</span>
      <IconButton
        type="button"
        onClick={() => {
          deleteProductionCreativeRequest.mutate({ fileId });
        }}
      >
        <TrashIcon dimension={24} color={Colors.Error[400]} />
      </IconButton>
    </div>
  );
};

export default PreferencesFileEntry;
