import { CountryIconProps } from './types';

const GQ = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3615)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.30486 7.82574H23.2541C21.5586 3.25613 17.16 -0.000366211 12.0005 -0.000366211C8.68673 -0.000366211 5.68706 1.34302 3.51562 3.51474L7.30486 7.82574Z"
        fill="#6DA544"
      />
      <path
        d="M7.30486 16.1742H23.2541C21.5586 20.7438 17.16 24.0003 12.0005 24.0003C8.68673 24.0003 5.68706 22.6569 3.51562 20.4852L7.30486 16.1742Z"
        fill="#D80027"
      />
      <path
        d="M3.51471 3.5155C-1.17157 8.20178 -1.17157 15.7998 3.51471 20.4861C5.45126 18.5496 7.31375 16.6871 12 12.0008L3.51471 3.5155Z"
        fill="#0052B4"
      />
      <path
        d="M14.0869 9.91272V12.5214C14.0869 14.1189 16.1739 14.6084 16.1739 14.6084C16.1739 14.6084 18.2609 14.1189 18.2609 12.5214V9.91272H14.0869Z"
        fill="#DEDDE0"
      />
      <path
        d="M16.6958 12.0524H15.6523V13.5654H16.6958V12.0524Z"
        fill="#786145"
      />
      <path
        d="M17.2171 11.4788C17.2171 10.9025 16.7499 10.4353 16.1736 10.4353C15.5973 10.4353 15.1301 10.9025 15.1301 11.4788C14.842 11.4788 14.6084 11.7124 14.6084 12.0005C14.6084 12.2887 14.842 12.5222 15.1301 12.5222C15.3012 12.5222 17.046 12.5222 17.2171 12.5222C17.5052 12.5222 17.7388 12.2887 17.7388 12.0005C17.7388 11.7124 17.5053 11.4788 17.2171 11.4788Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3615">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default GQ;
