import { Pagination } from '@replai-platform/ui-components';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect, useFirstMountState } from 'react-use';
import * as SDK from '@replai-platform/sdk';
import useMarketAssetsMutation from '../../../../api/hooks/assets/useMarketAssets';
import useUpdateSavedMarketAsset from '../../../../api/hooks/assets/useUpdateSavedMarketAsset';
import LibraryGrid from '../../../../components/LibraryGrid';
import { RootState } from '../../../../store/rootReducer';
import { MarketActions } from '../../../../store/market';
import * as Styled from './styles';
import { formatVideoData } from './utils';
import { api } from '../../../../api';

const MAX_RECORDS = 15;

enum MarketAssetsRequestStatus {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
  IDLE = 'idle',
}

const MarketVideosGrid: React.VFC = () => {
  const dispatch = useDispatch();
  const {
    id: projectId,
    organizationName,
    marketTags: projectMarketTags,
  } = useSelector((state: RootState) => state.project);
  const filters = useSelector((state: RootState) => state.filters);
  const currentPage = useSelector((state: RootState) => state.market.creativeGallery.currentCreativesTablePage);
  const currentTab = useSelector((state: RootState) => state.market.creativeGallery.currentVideoTab);
  const projectMarketGenres = useSelector((state: RootState) => state.project.config.marketGenres);
  const allowUntaggedMarketAssets = useSelector((state: RootState) => state.project.config.allowUntaggedMarketAssets);
  const { mutate } = useMarketAssetsMutation();
  const { mutate: mutateSavedMarketAsset } = useUpdateSavedMarketAsset();
  const [marketAssets, setMarketAssets] = useState<SDK.MarketAsset[]>([]);
  const [marketAssetsRequestStatus, setMarketAssetsRequestStatus] = useState<MarketAssetsRequestStatus>(
    MarketAssetsRequestStatus.IDLE
  );
  const isFirstMount = useFirstMountState();

  // Reset pagination when filters are changed
  useDeepCompareEffect(() => {
    // Reset the pagination only when the filters change
    if (!isFirstMount) {
      dispatch(MarketActions.changeCurrentCreativesTablePage({ page: 1, logEvent: false }));
    }
  }, [filters]);

  const onSaveClick = ({
    assetId,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    projectId,
    isSaved,
  }: {
    assetId: SDK.UUID;
    projectId: SDK.UUID;
    isSaved: boolean;
  }) => {
    setMarketAssets(
      marketAssets.map((marketAsset) => {
        if (marketAsset.id === assetId) {
          return { ...marketAsset, isSaved: !marketAsset.isSaved };
        }
        return marketAsset;
      })
    );
    mutateSavedMarketAsset(
      { projectId, assetId, action: isSaved ? 'DELETE' : 'PUT', marketAssets },
      {
        onSuccess(data) {
          setMarketAssets(data);
          setMarketAssetsRequestStatus(MarketAssetsRequestStatus.SUCCESS);
        },
        onError() {
          setMarketAssets([]);
          setMarketAssetsRequestStatus(MarketAssetsRequestStatus.ERROR);
        },
      }
    );
  };

  const requestParams = useMemo<SDK.GetMarketAssetsRequest>(
    () => ({
      projectId,
      maxRecords: MAX_RECORDS,
      offset: (currentPage - 1) * MAX_RECORDS,
      appsFilters: {
        appIdsToInclude: filters.marketAppsToConsider.map((ma) => ma.id),
        appIdsToExclude: filters.marketAppsToExclude.map((ma) => ma.id),
        publishersToExclude: [organizationName],
        ...(projectMarketGenres && filters.onlyMarketCompetitors ? { genres: projectMarketGenres } : {}),
      },
      tagsFilters: {
        ...api.filterConverter.getTagsFilters(filters),
        tagsToConsider:
          filters.marketTags.length > 0 && filters.marketTags.length === projectMarketTags.length
            ? []
            : filters.marketTags.map((tag) => ({ type: tag.type, value: tag.value })),
      },
      marketMetricsFilters: {
        assetLaunchDateStartTimestamp: filters.ageStartDate ? new Date(filters.ageStartDate).getTime() : undefined,
        assetLaunchDateEndTimestamp: filters.ageEndDate ? new Date(filters.ageEndDate).getTime() : undefined,
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
      hasPostQaAnnotations: allowUntaggedMarketAssets ? false : !!filters.onlyMarketCompetitors,
      ...(currentTab === 'favourites' ? { isSaved: true } : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentPage,
      currentTab,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(filters),
      allowUntaggedMarketAssets,
      organizationName,
      projectId,
      projectMarketTags.length,
    ]
  );

  useEffect(() => {
    setMarketAssetsRequestStatus(MarketAssetsRequestStatus.LOADING);
    mutate(requestParams, {
      onSuccess(data) {
        setMarketAssets(data.marketAssets);
        setMarketAssetsRequestStatus(MarketAssetsRequestStatus.SUCCESS);
      },
      onError() {
        setMarketAssets([]);
        setMarketAssetsRequestStatus(MarketAssetsRequestStatus.ERROR);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutate, JSON.stringify(requestParams)]);

  const totalCount = marketAssets?.[0]?.totalCount ?? 0;

  const data = useMemo(
    () =>
      formatVideoData({
        assetsData:
          marketAssets.map((asset) => ({ ...asset, name: `${asset.name} (by: ${asset.appPublisher})` })) ?? [],
        projectId,
        onSaveClick,
      }),
    [JSON.stringify(marketAssets), projectId, onSaveClick]
  );

  return (
    <Styled.Container id="grid-container">
      <LibraryGrid
        title="Top Videos"
        topFiveData={[]}
        videoCardsData={marketAssetsRequestStatus === MarketAssetsRequestStatus.SUCCESS ? data : []}
        isLoading={marketAssetsRequestStatus === MarketAssetsRequestStatus.LOADING}
        isEmpty={marketAssetsRequestStatus === MarketAssetsRequestStatus.SUCCESS && marketAssets.length === 0}
        emptyStateIcon="Video"
        emptyStateText="No videos found"
        showKeyMetric={false}
        showNetworks={false}
        thumbnailsQueries={[]}
        showLaunchDate
        showRelevanceScore
        showHasPostQaAnnotations
        showAge={false}
        showRecommendations={false}
        showTopVideos={false}
        showSave
        disableClick
        generateVariationButtonLabel="Adapt to my style"
      />
      <Pagination
        style={{ alignSelf: 'center' }}
        totalCount={totalCount}
        pageSize={MAX_RECORDS}
        currentPage={currentPage}
        disabled={marketAssetsRequestStatus === MarketAssetsRequestStatus.SUCCESS && marketAssets.length === 0}
        onPageChange={(page) => {
          document.getElementById('grid-container')?.scrollIntoView({ behavior: 'smooth' });
          dispatch(MarketActions.changeCurrentCreativesTablePage({ page, logEvent: true }));
        }}
      />
    </Styled.Container>
  );
};

export default MarketVideosGrid;
