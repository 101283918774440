import { CountryIconProps } from './types';

const KP = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3744)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9999 -0.000244141C8.36285 -0.000244141 5.10396 1.61807 2.90332 4.17365H21.0965C18.8959 1.61807 15.637 -0.000244141 11.9999 -0.000244141Z"
        fill="#0052B4"
      />
      <path
        d="M21.0965 19.827H2.90332C5.10396 22.3825 8.36285 24.0009 11.9999 24.0009C15.637 24.0009 18.8959 22.3825 21.0965 19.827Z"
        fill="#0052B4"
      />
      <path
        d="M22.2387 5.73859H1.76128C0.644297 7.56132 0 9.70506 0 11.9994C0 14.2938 0.644297 16.4376 1.76128 18.2603H22.2387C23.3557 16.4376 24 14.2938 24 11.9994C24 9.70506 23.3557 7.56132 22.2387 5.73859Z"
        fill="#D80027"
      />
      <path
        d="M7.383 16.6182C9.9331 16.6182 12.0004 14.551 12.0004 12.0009C12.0004 9.45076 9.9331 7.38348 7.383 7.38348C4.83289 7.38348 2.76562 9.45076 2.76562 12.0009C2.76562 14.551 4.83289 16.6182 7.383 16.6182Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.38279 7.38348L8.41877 10.572H11.7768L9.05913 12.5427L10.1032 15.7407L7.38279 13.7606L4.66648 15.737L5.70645 12.5427L2.99121 10.572H6.34676L7.38279 7.38348Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3744">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default KP;
