import { PaymentIconProps } from './types';

const GooglePay = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4108 20.704V16.7911H23.4303C24.2579 16.7911 24.9563 16.5138 25.5257 15.967L25.6624 15.8283C26.7025 14.696 26.6342 12.9322 25.5257 11.8846C24.9715 11.3301 24.2123 11.0297 23.4303 11.0451H20.1885V20.704H21.4108ZM21.4109 15.6049V12.2312H23.4611C23.9016 12.2312 24.3192 12.4006 24.6305 12.7087C25.2911 13.3557 25.3063 14.4341 24.6685 15.1042C24.3572 15.4354 23.9167 15.6203 23.4611 15.6049H21.4109ZM31.3641 14.6113C30.8402 14.126 30.1266 13.8795 29.2231 13.8795C28.0615 13.8795 27.1884 14.3109 26.6114 15.1659L27.6895 15.8514C28.0843 15.266 28.6234 14.9733 29.3066 14.9733C29.7394 14.9733 30.157 15.1351 30.4834 15.4277C30.8023 15.705 30.9845 16.1056 30.9845 16.5292V16.8142C30.5138 16.5523 29.9216 16.4137 29.1928 16.4137C28.3424 16.4137 27.6592 16.6139 27.1505 17.0222C26.6418 17.4304 26.3837 17.9696 26.3837 18.6551C26.3685 19.279 26.6342 19.8721 27.1049 20.2726C27.5832 20.704 28.1906 20.9196 28.9043 20.9196C29.747 20.9196 30.4151 20.5422 30.9238 19.7874H30.9769V20.704H32.1461V16.6293C32.1461 15.7744 31.888 15.0965 31.3641 14.6113ZM28.0466 19.487C27.796 19.3021 27.6441 19.0017 27.6441 18.6782C27.6441 18.3162 27.8112 18.0158 28.1377 17.777C28.4718 17.5383 28.8894 17.415 29.383 17.415C30.0664 17.4073 30.5979 17.5614 30.9776 17.8695C30.9776 18.3932 30.7726 18.8477 30.3701 19.2328C30.0056 19.6025 29.5121 19.8105 28.9957 19.8105C28.654 19.8182 28.3199 19.7027 28.0466 19.487ZM34.773 23.6078L38.8575 14.0952H37.5289L35.6385 18.8399H35.6157L33.6797 14.0952H32.3511L35.0311 20.2803L33.5127 23.6078H34.773Z"
      fill="#3C4043"
    />
    <path
      d="M17.1905 15.9438C17.1905 15.5664 17.1601 15.189 17.0994 14.8193H11.9443V16.9529H14.8977C14.7762 17.6384 14.3814 18.2546 13.8044 18.6397V20.0262H15.5658C16.5983 19.0634 17.1905 17.6384 17.1905 15.9438Z"
      fill="#4285F4"
    />
    <path
      d="M11.9452 21.3664C13.4181 21.3664 14.6632 20.8734 15.5666 20.0262L13.8053 18.6397C13.3118 18.9786 12.6816 19.1712 11.9452 19.1712C10.5179 19.1712 9.31074 18.193 8.87799 16.8835H7.06348V18.3162C7.98971 20.1879 9.88015 21.3664 11.9452 21.3664Z"
      fill="#34A853"
    />
    <path
      d="M8.87795 16.8836C8.65015 16.198 8.65015 15.4509 8.87795 14.7577V13.3327H7.06315C6.28103 14.8963 6.28103 16.7449 7.06315 18.3085L8.87795 16.8836Z"
      fill="#FBBC04"
    />
    <path
      d="M11.9452 12.47C12.7272 12.4546 13.4788 12.755 14.0406 13.3019L15.6046 11.7152C14.61 10.7755 13.3042 10.2594 11.9452 10.2748C9.88015 10.2748 7.98971 11.461 7.06348 13.3327L8.87799 14.7654C9.31074 13.4482 10.5179 12.47 11.9452 12.47Z"
      fill="#EA4335"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default GooglePay;
