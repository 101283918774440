import { KPI, MetricKPIWithSpend, kpiUtils } from '@replai-platform/sdk';

export type DropdownFilterValue = {
  label: string;
  value: string;
};

export type DropdownFilterProps = {
  key: string;
  value: DropdownFilterValue;
  availableValues: DropdownFilterValue[];
  onValueChange: (value: DropdownFilterValue) => void;
};

/**
 * Function responsible building all the available KPIs for a dropdown selector
 */
function buildKpiDropdownFilterProps(kpis: MetricKPIWithSpend[]): DropdownFilterValue[] {
  return kpis.length === 0
    ? [{ value: KPI.IPM, label: kpiUtils.getDisplayName(KPI.IPM) }]
    : kpis.map((kpi) => ({
        value: kpi,
        label: kpiUtils.getDisplayName(kpi),
      }));
}

export default buildKpiDropdownFilterProps;
