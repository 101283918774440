import { Colors } from '@replai-platform/ui-components';
import styled from 'styled-components';

export const MainContainer = styled.div<{ marginTop: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop}rem;
`;

export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StickyTop = styled.div`
  position: sticky;
  top: -2rem;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  background-color: ${Colors.Common.White};
`;

export const TimelineCardWrapper = styled.div`
  flex: 2;

  > * {
    max-width: 20rem;
  }
`;

export const TimelineWrapper = styled.div`
  z-index: 2;
`;

export const TimelineRightSide = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const VideoWrapper = styled.div`
  display: flex;
  flex: 3;
  max-height: 24rem;
  background-color: ${Colors.Gray[100]};
  border-radius: 0.5rem;
`;

export const Container = styled.div`
  display: grid;
  grid-column-gap: 1.5rem;
  grid-template-rows: auto auto;
  grid-template-columns: 320px minmax(0, 1fr);
  row-gap: 2rem;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  height: 100vh;
  margin-top: 1.5rem;
`;

export const StickyLeftSide = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  height: 100vh;
  margin-top: 1.5rem;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Properties = styled.div`
  width: 100%;
  padding: 1rem;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const SectionBody = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr;
`;

export const MetricsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 16px;
`;

export const MetricsCardWrapper = styled.div`
  flex: 2;
  min-width: 400px;
`;

export const MetricsCardWrapperSmall = styled.div`
  flex: 1;
  min-width: 400px;
`;

export const MetricsCard = styled.div`
  height: 100%;
  padding: 24px;
`;

export const ValueContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1.25rem;
  margin-bottom: 1.5625rem;
  background-color: ${Colors.Gray[200]};
`;

export const CardContainer = styled.div`
  grid-column: 1 / span 2;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  > [aria-hidden='true']:last-child {
    z-index: 101;
    display: none;
    width: auto;
  }
`;

export const CardHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  > :last-child {
    z-index: 10;
    width: auto;
  }
`;

export const CardHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: flex-start;
  justify-content: center;
`;

export const CardChart = styled.div`
  width: 100%;
  height: 300px;
`;

export const CardTable = styled(CardChart)`
  height: auto;
  padding: 0;
`;

export const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 5rem;
  margin-top: 1.5rem;
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
  background-color: ${Colors.Common.Black};
  border-radius: 0.5rem;
`;
