/* eslint-disable no-restricted-globals */
import { MiscIconProps } from './types';

const Check = ({ color, dimension }: MiscIconProps) => (
  <svg
    width={dimension || 21}
    height={dimension && !isNaN(+dimension) ? (+dimension * 19) / 21 : 19}
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4942 1.31671L6.56084 12.8334L3.39417 9.45004C2.81084 8.90004 1.89417 8.86671 1.22751 9.33338C0.577506 9.81671 0.394172 10.6667 0.794172 11.35L4.54417 17.45C4.91084 18.0167 5.54417 18.3667 6.26084 18.3667C6.94417 18.3667 7.59417 18.0167 7.96084 17.45C8.56084 16.6667 20.0108 3.01671 20.0108 3.01671C21.5108 1.48338 19.6942 0.133375 18.4942 1.30004V1.31671Z"
      fill={color || '#667085'}
    />
  </svg>
);
export default Check;
