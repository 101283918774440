import { UseQueryOptions, useQuery } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

export const GET_ALL_PRODUCTION_CONCEPTS_KEY = 'get-all-production-concepts';

const GetAllProductionConceptsRequest = async (
  requestParams: SDK.GetAllProductionConceptsRequest
): Promise<SDK.GetAllProductionConceptsResponse> =>
  provider.getJson(`${SDK.BASE_URL()}/creative-production/concepts?${SDK.convertRequestToQueryParams(requestParams)}`);

export function useGetAllProductionConcepts(
  projectId: SDK.UUID,
  requestParams: SDK.GetAllProductionConceptsRequest,
  options?: UseQueryOptions<SDK.GetAllProductionConceptsResponse>
) {
  return useQuery(
    [GET_ALL_PRODUCTION_CONCEPTS_KEY, projectId, requestParams],
    () => GetAllProductionConceptsRequest(requestParams),
    options
  );
}
