import styled, { createGlobalStyle, css } from 'styled-components';
import { Link } from 'react-router-dom';
import Colors from '../Colors';
import Typography from '../Typography/Typography';
import Modal from '../Modal/Modal';

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 241px;
  max-width: 300px;
  padding: 16px;
`;

// ---- Global styling ----

export const GlobalNavigationStyle = createGlobalStyle`
  .replai-tooltip.nav-sub-menu-tooltip.ant-tooltip-placement-rightTop
    .ant-tooltip-arrow {
    top: 17px;
  }

  .replai-tooltip.nav-sub-menu-tooltip.ant-tooltip-placement-rightBottom
    .ant-tooltip-arrow {
    bottom: 15px;
  }
`;

// ---- Navigation ----

export const NavigationContainer = styled.aside`
  display: flex;
  flex-direction: column;
  width: 82px;
  min-height: 100vh;
  padding-top: 24px;
  background: ${Colors.Gray[900]};
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.Gray[600]};
`;

export const ProjectContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const AvatarContainer = styled.div`
  cursor: pointer;

  > div > div[data-test='thumbnail'] {
    width: 54px;
    height: 54px;
  }

  img {
    filter: brightness(1);
    transition: filter 0.2s ease-in-out;

    :hover {
      filter: brightness(1.1);
    }
  }
`;

export const DropdownContainer = styled.div<{
  $style?: string;
  $position?: string;
  $left?: string;
  $top?: string;
}>`
  position: ${(props) => props.$position ?? 'absolute'};
  top: ${(props) => props.$top};
  left: ${(props) => props.$left};
  z-index: 10;

  &&& {
    ${(props) =>
      css`
        ${props.$style}
      `}
  }
`;

export const ItemsContainer = styled.div`
  position: relative;
  flex: 1;
  margin-top: 24px;
`;

export const BottomItemsContainer = styled.div`
  margin-bottom: 16px;
`;

export const BottomIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px 24px 16px;

  :before {
    position: absolute;
    top: 0;
    content: '';
    height: 1px;
    width: 50px;
    background-color: ${Colors.Gray[600]};
  }
`;

export const BottomAvatarContainer = styled.div`
  margin-top: 16px;
`;

export const AccountTooltipContainer = styled(TooltipContainer)`
  gap: 16px;
`;

export const LogoutContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  svg {
    cursor: pointer;
  }

  svg path {
    transition: 0.2s;
  }

  svg:hover path {
    stroke: ${Colors.Common.White};
  }
`;

export const LogoutIconContainer = styled.div`
  height: 24px;
`;

export const Email = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

// ---- NavigationItem ----

export const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const ItemButton = styled.div<{
  $selected: boolean;
  $clickable: boolean;
}>`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 54px;
  width: 54px;
  padding: 4px;
  background-color: ${({ $selected }) =>
    $selected ? Colors.Gray[700] : 'transparent'};
  border: none;
  border-radius: 6px;
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};

  svg path {
    transition: 0.2s;
  }
`;

export const MenuLabel = styled(Typography)`
  transition: 0.2s ease-in-out;
`;

export const HasNotificationContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 0rem;
  width: 0.5rem;
  height: 0.5rem;
  margin-left: auto;
  background: ${Colors.Primary[500]};
  border-radius: 0.25rem;
  transition: 0.2s;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 100%;
`;

export const SubMenuContainer = styled.div<{
  $isSelected?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: ${({ $isSelected }) =>
    $isSelected ? Colors.Gray[700] : 'transparent'};
  cursor: pointer;

  svg path {
    transition: 0.2s;
  }

  :hover ${MenuLabel} {
    color: ${Colors.Common.White} !important;
  }

  :hover svg path {
    stroke: ${Colors.Common.White};
  }
`;

export const SubMenuTooltipContainer = styled(TooltipContainer)`
  gap: 4px;
`;

export const ExtraIconContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

export const StyledLink = styled(Link)`
  line-height: 0;

  + div:not(:last-child) {
    margin-bottom: 4px;
  }
`;

// ---- LockedModal ----

export const StyledModal = styled(Modal)`
  &.nav-locked-modal {
    .ant-modal-header {
      border: 0;
    }

    .ant-modal-body {
      padding-top: 0;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const BodyText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const BodyTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;
