import { PromotedObjectType } from '../models';

export const getPromotedObjectString = (type: PromotedObjectType): string =>
  ({
    [PromotedObjectType.ANDROID]: 'Android',
    [PromotedObjectType.IOS]: 'iOS',
    [PromotedObjectType.WEBSITE]: 'Website',
  }[type] ?? type);

export const PROMOTED_OBJECT_TYPES = Object.values(PromotedObjectType);
