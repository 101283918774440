import { CommonTagIconProps } from '../types';

const SlowPace = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1191 18.7249C19.3771 17.2959 20.1221 15.4329 20.1221 13.3979C20.1221 8.90988 16.4881 5.27588 12.0001 5.27588C7.52705 5.27588 3.87805 8.90988 3.87805 13.3979C3.87805 15.4319 4.63905 17.2809 5.89705 18.7089"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12 5.5V7.574"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.87805 13.5808H5.95205"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.984 13.5808H20.059"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.222 9.32686L17.689 7.85986"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.24707 7.85986L7.71407 9.32686"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.8401 14.6108L8.77905 12.5498"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12 17.3299C12.8345 17.3299 13.511 16.6534 13.511 15.8189C13.511 14.9844 12.8345 14.3079 12 14.3079C11.1655 14.3079 10.489 14.9844 10.489 15.8189C10.489 16.6534 11.1655 17.3299 12 17.3299Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default SlowPace;
