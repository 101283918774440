import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';
import { VerticalLayout } from '../shared/VerticalLayout';

const IRONSOURCE_FORM_URL = 'https://docs.google.com/forms/d/1fN4Kwmrnd7-ONEs9ichU0CqWEiRNziHyFw-N71d0Uho/viewform';

export const IronsourceConnectionGuide: NetworkConnectionGuideContent = {
  title: 'IronSource Connection Guide',
  icon: 'IronSource',
  guide: {
    label: 'Connection Guide',
    steps: [
      {
        title: 'Access IronSource',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                Login into the{' '}
                {Shared.link({
                  label: 'IronSource',
                  url: 'https://auth.ironsrc.com/login',
                })}{' '}
                plaform
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/ironsource/step-1.png"
          />
        ),
      },
      {
        title: 'Account Info',
        description: '',
        content: (
          <VerticalLayout
            contentDescription="Head over to your Account information page"
            imageSrc="https://cdn.replai.io/assets/integration-guide/ironsource/step-2.png"
          />
        ),
      },
      {
        title: 'API',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                Click on the <Shared.BoldText>API</Shared.BoldText> tab
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/ironsource/step-3.png"
          />
        ),
      },
      {
        title: 'Share Keys to Replai',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                Share your <Shared.BoldText>Secret Key</Shared.BoldText> and{' '}
                <Shared.BoldText>Refresh Token</Shared.BoldText> with Replai using{' '}
                {Shared.link({
                  label: 'this form',
                  url: IRONSOURCE_FORM_URL,
                })}{' '}
                (do not click on the &quot;Regenerate Access Key&quot; or &quot;Regenerate Refresh Token&quot; buttons,
                after you send the keys to Replai).
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/ironsource/step-4.png"
          />
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: Shared.DEFAULT_NETWORK_FAQS,
  },
};
