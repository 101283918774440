import Tooltip from '@material-ui/core/Tooltip';
import { PromotedObjectType } from '@replai-platform/sdk';
import { Icons } from '@replai-platform/ui-components';
import { ReactFragment } from 'react';
import styled from 'styled-components';
import { OBJECT_TYPE_TO_ICON } from '../../utils/constants';

const PlatformsContainer = styled.div`
  display: flex;
  align-items: center;
  div {
    margin-right: 0.5rem;
  }
`;

type Props = {
  possiblePromotedObjects: PromotedObjectType[];
  tooltipContent?: string | ReactFragment;
};

const PromotedObjectDecorator: React.FC<Props> = ({ possiblePromotedObjects, tooltipContent }) => {
  const render = () => (
    <PlatformsContainer>
      {possiblePromotedObjects
        ?.filter((promotedObject) => promotedObject !== null)
        .map((promotedObject) => {
          const Icon = Icons.getFlatIcon(OBJECT_TYPE_TO_ICON[promotedObject] ?? 'Website');

          return (
            <div>
              <Icon dimension={20} />
            </div>
          );
        })}
    </PlatformsContainer>
  );

  return tooltipContent ? <Tooltip title={tooltipContent}>{render()}</Tooltip> : render();
};

export default PromotedObjectDecorator;
