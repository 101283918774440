import { KPI, MetricOrKpi, Metrics, SerialID } from '../../models';
import { MetricKPIWithSpend } from '../../models/metrics/types';
import { messages } from '../messages';
import compactNumberFormatter from './compactNumberFormatter';

export const cohortPeriods = Array.from(Array(31).keys()); // Periods Day 0 to Day 30

const toFixedIfNecessary = (number: number, decimals: number) =>
  number?.toFixed(decimals);

const numberWithCommas = (
  number?: number | null,
  options?: { decimals?: number }
): string => {
  if (number === null || number === undefined) {
    return messages.NOT_AVAILABLE;
  }
  return toFixedIfNecessary(number, options?.decimals ?? 0).replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );
};

const userFriendlySeconds = (seconds: number): string => {
  if (!seconds) {
    return '00s';
  }
  const [hours, mins, secs] = new Date(seconds * 1000)
    .toISOString()
    .slice(11, 19)
    .split(':');
  if (hours === '00' && mins === '00' && secs === '00') {
    return '00s';
  }
  return `${hours !== '00' ? `${hours}h` : ''} ${
    mins !== '00' ? `${mins}m` : ''
  } ${secs !== '00' ? `${secs}s` : ''}`;
};

export const kpiConfig: {
  [kpi in string]: {
    cohortDays?: number;
    displayName: string;
    format: (
      value: number,
      options: { isDelta?: boolean; longDisplay?: boolean }
    ) => string;
    isCurrency: boolean;
    isPercentage: boolean;
    longName: string;
    shouldMaximize: boolean;
    /** If true, the KPI can be added, otherwise it must be averaged. */
    isSummable: boolean;
  };
} = {
  age: {
    displayName: 'Launch Date',
    longName: 'Launch Date',
    shouldMaximize: true,
    format: (value) => value?.toString() ?? messages.NOT_AVAILABLE,
    isPercentage: false,
    isCurrency: false,
    isSummable: false,
  },
  cpi: {
    displayName: 'CPI',
    longName: 'Cost Per Install',
    shouldMaximize: false,
    format: (value, { isDelta }) =>
      toFixedIfNecessary(isDelta ? value * 100 : value, 2) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: true,
    isSummable: false,
  },
  cti: {
    displayName: 'CTI',
    longName: 'Click To Install',
    shouldMaximize: true,
    format: (value, { longDisplay }) =>
      `${toFixedIfNecessary(100 * value, longDisplay ? 2 : 1)}%`,
    isPercentage: true,
    isCurrency: false,
    isSummable: false,
  },
  ctr: {
    displayName: 'CTR',
    longName: 'Click Through Rate',
    shouldMaximize: true,
    format: (value, { longDisplay }) =>
      `${toFixedIfNecessary(100 * value, longDisplay ? 2 : 1)}%`,
    isPercentage: true,
    isCurrency: false,
    isSummable: false,
  },
  ipm: {
    displayName: 'IPM',
    longName: 'Installs Per Mile',
    shouldMaximize: true,
    format: (value, { isDelta }) =>
      toFixedIfNecessary(isDelta ? value * 100 : value, 2) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: false,
    isSummable: false,
  },
  avgAssetSpend: {
    displayName: 'Avg Spend',
    longName: 'Average Asset Spend',
    shouldMaximize: true,
    format: (value, { longDisplay }) =>
      (longDisplay
        ? (v: number) => numberWithCommas(v, { decimals: 2 })
        : compactNumberFormatter)(value),
    isPercentage: false,
    isCurrency: true,
    isSummable: false,
  },
  avgConceptSpend: {
    displayName: 'Avg Spend',
    longName: 'Average Concept Spend',
    shouldMaximize: true,
    format: (value, { longDisplay }) =>
      (longDisplay
        ? (v: number) => numberWithCommas(v, { decimals: 2 })
        : compactNumberFormatter)(value),
    isPercentage: false,
    isCurrency: true,
    isSummable: false,
  },
  ...Object.fromEntries(
    cohortPeriods.map((period) => [
      `cppDay${period}`,
      {
        cohortDays: period,
        displayName: `CPPD${period}`,
        longName: `Cost Per Purchase Day ${period}`,
        shouldMaximize: false,
        format: (value, { isDelta }) =>
          toFixedIfNecessary(isDelta ? value * 100 : value, 1) +
          (isDelta ? '%' : ''),
        isPercentage: false,
        isCurrency: true,
        isSummable: false,
      },
    ])
  ),
  ...Object.fromEntries(
    cohortPeriods.map((period) => [
      `retentionDay${period}`,
      {
        cohortDays: period,
        displayName: `RETENTIOND${period}`,
        longName: `Users Retention Day ${period}`,
        shouldMaximize: true,
        format: (value, { longDisplay }) =>
          `${toFixedIfNecessary(value * 100, longDisplay ? 2 : 1)}%`,
        isPercentage: true,
        isCurrency: false,
        isSummable: false,
      },
    ])
  ),
  roas: {
    displayName: 'ROAS',
    longName: 'Return On Ad Spend',
    shouldMaximize: true,
    format: (value, { longDisplay }) =>
      `${toFixedIfNecessary(value * 100, longDisplay ? 2 : 1)}%`,
    isPercentage: true,
    isCurrency: false,
    isSummable: false,
  },
  ...Object.fromEntries(
    cohortPeriods.map((period) => [
      `roasDay${period}`,
      {
        cohortDays: period,
        displayName: `ROASD${period}`,
        longName: `Return On Ad Spend Day ${period}`,
        shouldMaximize: true,
        format: (value, { longDisplay }) =>
          `${toFixedIfNecessary(value * 100, longDisplay ? 2 : 1)}%`,
        isPercentage: true,
        isCurrency: false,
        isSummable: false,
      },
    ])
  ),
  ...Object.fromEntries(
    cohortPeriods.map((period) => [
      `earningsDay${period}`,
      {
        cohortDays: period,
        displayName: `Earnings D${period}`,
        longName: `Earnings Day ${period}`,
        shouldMaximize: true,
        format: (value, { isDelta, longDisplay }) =>
          (longDisplay
            ? (v: number) => numberWithCommas(v, { decimals: 2 })
            : compactNumberFormatter)(isDelta ? value * 100 : value) +
          (isDelta ? '%' : ''),
        isPercentage: false,
        isCurrency: true,
        isSummable: true,
      },
    ])
  ),
  ...Object.fromEntries(
    cohortPeriods.map((period) => [
      `purchasesDay${period}`,
      {
        cohortDays: period,
        displayName: `Purchases D${period}`,
        longName: `Purchases Day ${period}`,
        shouldMaximize: true,
        format: (value, { longDisplay, isDelta }) =>
          (longDisplay ? numberWithCommas : compactNumberFormatter)(value) +
          (isDelta ? '%' : ''),
        isPercentage: false,
        isCurrency: false,
        isSummable: true,
      },
    ])
  ),
  spend: {
    displayName: 'Spend',
    longName: 'Ad Spend',
    shouldMaximize: true,
    format: (value, { longDisplay }) =>
      (longDisplay
        ? (v: number) => numberWithCommas(v, { decimals: 2 })
        : compactNumberFormatter)(value),
    isPercentage: false,
    isCurrency: true,
    isSummable: true,
  },
  installs: {
    displayName: 'Installs',
    longName: 'Installs',
    shouldMaximize: true,
    format: (value, { longDisplay, isDelta }) =>
      (longDisplay ? numberWithCommas : compactNumberFormatter)(value) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: false,
    isSummable: true,
  },
  purchases: {
    displayName: 'Purchases',
    longName: 'Purchases',
    shouldMaximize: true,
    format: (value, { longDisplay, isDelta }) =>
      (longDisplay ? numberWithCommas : compactNumberFormatter)(value) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: false,
    isSummable: true,
  },
  earnings: {
    displayName: 'Earnings',
    longName: 'Earnings',
    shouldMaximize: true,
    format: (value, { longDisplay, isDelta }) =>
      (longDisplay ? numberWithCommas : compactNumberFormatter)(value) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: true,
    isSummable: true,
  },
  impressions: {
    displayName: 'Impressions',
    longName: 'Impressions',
    shouldMaximize: true,
    format: (value, { longDisplay, isDelta }) =>
      (longDisplay ? numberWithCommas : compactNumberFormatter)(
        isDelta ? value * 100 : value
      ) + (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: false,
    isSummable: true,
  },
  cpl: {
    displayName: 'CPL',
    longName: 'Cost Per Like',
    shouldMaximize: false,
    format: (value, { isDelta }) =>
      toFixedIfNecessary(isDelta ? value * 100 : value, 2) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: true,
    isSummable: false,
  },
  cpc: {
    displayName: 'CPC',
    longName: 'Cost Per Click',
    shouldMaximize: false,
    format: (value, { longDisplay, isDelta }) =>
      (longDisplay
        ? (v: number) => numberWithCommas(v, { decimals: 2 })
        : compactNumberFormatter)(isDelta ? value * 100 : value) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: true,
    isSummable: false,
  },
  vintedCostPerFirstListing: {
    displayName: 'CPL',
    longName: 'Cost Per First Listing',
    shouldMaximize: false,
    format: (value, { isDelta }) =>
      toFixedIfNecessary(isDelta ? value * 100 : value, 1) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: true,
    isSummable: false,
  },
  clicks: {
    displayName: 'Clicks',
    longName: 'Clicks',
    shouldMaximize: true,
    format: (value, { longDisplay, isDelta }) =>
      (longDisplay
        ? (v: number) => numberWithCommas(v, { decimals: 2 })
        : compactNumberFormatter)(isDelta ? value * 100 : value) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: false,
    isSummable: true,
  },
  yokeCpa: {
    displayName: 'CPA',
    longName: 'Cost Per Action',
    shouldMaximize: false,
    format: (value, { isDelta }) =>
      toFixedIfNecessary(isDelta ? value * 100 : value, 1) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: true,
    isSummable: false,
  },
  treatwellCostPerLead: {
    displayName: 'CPL',
    longName: 'Cost Per Lead',
    shouldMaximize: false,
    format: (value, { isDelta }) =>
      toFixedIfNecessary(isDelta ? value * 100 : value, 1) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: true,
    isSummable: false,
  },
  likes: {
    displayName: 'Likes',
    longName: 'Likes',
    shouldMaximize: true,
    format: (value, { longDisplay, isDelta }) =>
      (longDisplay ? numberWithCommas : compactNumberFormatter)(value) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: false,
    isSummable: true,
  },
  dislikes: {
    displayName: 'Dislikes',
    longName: 'Dislikes',
    shouldMaximize: false,
    format: (value, { longDisplay, isDelta }) =>
      (longDisplay ? numberWithCommas : compactNumberFormatter)(value) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: false,
    isSummable: true,
  },
  minutesWatched: {
    displayName: 'Watch Time',
    longName: 'Watch Time',
    shouldMaximize: true,
    format: (value, { isDelta }) =>
      userFriendlySeconds(value) + (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: false,
    isSummable: true,
  },
  avgViewDuration: {
    displayName: 'Avg View Duration',
    longName: 'Average View Duration',
    shouldMaximize: true,
    format: (value, { isDelta }) =>
      userFriendlySeconds(value) + (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: false,
    isSummable: false,
  },
  comments: {
    displayName: 'Comments',
    longName: 'Comments',
    shouldMaximize: true,
    format: (value, { longDisplay, isDelta }) =>
      (longDisplay ? numberWithCommas : compactNumberFormatter)(value) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: false,
    isSummable: true,
  },
  shares: {
    displayName: 'Shares',
    longName: 'Shares',
    shouldMaximize: true,
    format: (value, { longDisplay, isDelta }) =>
      (longDisplay ? numberWithCommas : compactNumberFormatter)(value) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: false,
    isSummable: true,
  },
  subscribersGained: {
    displayName: 'Subs Gained',
    longName: 'Subscribers Gained',
    shouldMaximize: true,
    format: (value, { longDisplay, isDelta }) =>
      (longDisplay ? numberWithCommas : compactNumberFormatter)(value) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: false,
    isSummable: true,
  },
  subscribersLost: {
    displayName: 'Subs Lost',
    longName: 'Subscribers Lost',
    shouldMaximize: false,
    format: (value, { longDisplay, isDelta }) =>
      (longDisplay ? numberWithCommas : compactNumberFormatter)(value) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: false,
    isSummable: true,
  },
  views: {
    displayName: 'Views',
    longName: 'Views',
    shouldMaximize: true,
    format: (value, { longDisplay, isDelta }) =>
      (longDisplay ? numberWithCommas : compactNumberFormatter)(value) +
      (isDelta ? '%' : ''),
    isPercentage: false,
    isCurrency: false,
    isSummable: true,
  },
  engagementRate: {
    displayName: 'Engagement Rate',
    longName: 'Engagement Rate',
    shouldMaximize: true,
    format: (value, { longDisplay }) =>
      `${toFixedIfNecessary(100 * value, longDisplay ? 2 : 1)}%`,
    isPercentage: true,
    isCurrency: false,
    isSummable: false,
  },
};

export const kpiUtils = {
  cohortPeriods,
  filters: kpiConfig,
  isSummable: (kpi: string | MetricKPIWithSpend): boolean => {
    if (!(kpi in kpiConfig)) {
      return false;
    }
    return kpiConfig[kpi].isSummable;
  },
  getDisplayName: (kpi: string | MetricKPIWithSpend): string => {
    if (!(kpi in kpiConfig)) {
      return kpi;
    }
    if (!kpiConfig[kpi].displayName) {
      return kpi;
    }
    return kpiConfig[kpi].displayName;
  },
  getLongName: (kpi: string): string => {
    if (!(kpi in kpiConfig)) {
      return kpi;
    }
    if (!kpiConfig[kpi].longName) {
      return kpi;
    }
    return kpiConfig[kpi].longName;
  },
  shouldMaximize: (kpi: string): boolean => {
    if (!(kpi in kpiConfig)) {
      return true;
    }
    if (
      kpiConfig[kpi].shouldMaximize === null ||
      kpiConfig[kpi].shouldMaximize === undefined
    ) {
      return true;
    }
    return kpiConfig[kpi].shouldMaximize;
  },
  format: (
    kpi: string,
    value: number | null | undefined,
    options?: { isDelta?: boolean; longDisplay?: boolean }
  ): string => {
    if (value === null || value === undefined) {
      return 'N/A';
    }
    if (!(kpi in kpiConfig)) {
      return value.toString();
    }
    if (!kpiConfig[kpi].format) {
      return value.toString();
    }
    return kpiConfig[kpi].format(value, options ?? {});
  },
};

/** Temporary aid to be used while we migrate from the old SDK-based KPIs to the new DB-based. */
export const TEMPORARY_KPI_LEGACY_MAP: {
  newKpiId: SerialID;
  mapsTo: MetricOrKpi;
}[] = [
  { newKpiId: 1, mapsTo: Metrics.SPEND },
  { newKpiId: 2, mapsTo: KPI.IPM },
  { newKpiId: 3, mapsTo: KPI.VINTED_CPL },
  { newKpiId: 4, mapsTo: KPI.CPL },
  { newKpiId: 5, mapsTo: KPI.CPI },
  { newKpiId: 6, mapsTo: KPI.CTI },
  { newKpiId: 7, mapsTo: KPI.CTR },
  { newKpiId: 8, mapsTo: KPI.CPP },
  { newKpiId: 9, mapsTo: KPI.CPPDAY0 },
  { newKpiId: 10, mapsTo: KPI.CPPDAY1 },
  { newKpiId: 11, mapsTo: KPI.CPPDAY2 },
  { newKpiId: 12, mapsTo: KPI.CPPDAY3 },
  { newKpiId: 13, mapsTo: KPI.CPPDAY4 },
  { newKpiId: 14, mapsTo: KPI.CPPDAY5 },
  { newKpiId: 15, mapsTo: KPI.CPPDAY6 },
  { newKpiId: 16, mapsTo: KPI.CPPDAY7 },
  { newKpiId: 17, mapsTo: KPI.CPPDAY8 },
  { newKpiId: 18, mapsTo: KPI.CPPDAY9 },
  { newKpiId: 19, mapsTo: KPI.CPPDAY10 },
  { newKpiId: 20, mapsTo: KPI.CPPDAY11 },
  { newKpiId: 21, mapsTo: KPI.CPPDAY12 },
  { newKpiId: 22, mapsTo: KPI.CPPDAY13 },
  { newKpiId: 23, mapsTo: KPI.CPPDAY14 },
  { newKpiId: 24, mapsTo: KPI.CPPDAY15 },
  { newKpiId: 25, mapsTo: KPI.CPPDAY16 },
  { newKpiId: 26, mapsTo: KPI.CPPDAY17 },
  { newKpiId: 27, mapsTo: KPI.CPPDAY18 },
  { newKpiId: 28, mapsTo: KPI.CPPDAY19 },
  { newKpiId: 29, mapsTo: KPI.CPPDAY20 },
  { newKpiId: 30, mapsTo: KPI.CPPDAY21 },
  { newKpiId: 31, mapsTo: KPI.CPPDAY22 },
  { newKpiId: 32, mapsTo: KPI.CPPDAY23 },
  { newKpiId: 33, mapsTo: KPI.CPPDAY24 },
  { newKpiId: 34, mapsTo: KPI.CPPDAY25 },
  { newKpiId: 35, mapsTo: KPI.CPPDAY26 },
  { newKpiId: 36, mapsTo: KPI.CPPDAY27 },
  { newKpiId: 37, mapsTo: KPI.CPPDAY28 },
  { newKpiId: 38, mapsTo: KPI.CPPDAY29 },
  { newKpiId: 39, mapsTo: KPI.CPPDAY30 },
  { newKpiId: 40, mapsTo: KPI.ROASDAY0 },
  { newKpiId: 41, mapsTo: KPI.ROASDAY1 },
  { newKpiId: 42, mapsTo: KPI.ROASDAY2 },
  { newKpiId: 43, mapsTo: KPI.ROASDAY3 },
  { newKpiId: 44, mapsTo: KPI.ROASDAY4 },
  { newKpiId: 45, mapsTo: KPI.ROASDAY5 },
  { newKpiId: 46, mapsTo: KPI.ROASDAY6 },
  { newKpiId: 47, mapsTo: KPI.ROASDAY7 },
  { newKpiId: 48, mapsTo: KPI.ROASDAY8 },
  { newKpiId: 49, mapsTo: KPI.ROASDAY9 },
  { newKpiId: 50, mapsTo: KPI.ROASDAY10 },
  { newKpiId: 51, mapsTo: KPI.ROASDAY11 },
  { newKpiId: 52, mapsTo: KPI.ROASDAY12 },
  { newKpiId: 53, mapsTo: KPI.ROASDAY13 },
  { newKpiId: 54, mapsTo: KPI.ROASDAY14 },
  { newKpiId: 55, mapsTo: KPI.ROASDAY15 },
  { newKpiId: 56, mapsTo: KPI.ROASDAY16 },
  { newKpiId: 57, mapsTo: KPI.ROASDAY17 },
  { newKpiId: 58, mapsTo: KPI.ROASDAY18 },
  { newKpiId: 59, mapsTo: KPI.ROASDAY19 },
  { newKpiId: 60, mapsTo: KPI.ROASDAY20 },
  { newKpiId: 61, mapsTo: KPI.ROASDAY21 },
  { newKpiId: 62, mapsTo: KPI.ROASDAY22 },
  { newKpiId: 63, mapsTo: KPI.ROASDAY23 },
  { newKpiId: 64, mapsTo: KPI.ROASDAY24 },
  { newKpiId: 65, mapsTo: KPI.ROASDAY25 },
  { newKpiId: 66, mapsTo: KPI.ROASDAY26 },
  { newKpiId: 67, mapsTo: KPI.ROASDAY27 },
  { newKpiId: 68, mapsTo: KPI.ROASDAY28 },
  { newKpiId: 69, mapsTo: KPI.ROASDAY29 },
  { newKpiId: 70, mapsTo: KPI.ROASDAY30 },
  { newKpiId: 71, mapsTo: KPI.RETENTIONDAY0 },
  { newKpiId: 72, mapsTo: KPI.RETENTIONDAY1 },
  { newKpiId: 73, mapsTo: KPI.RETENTIONDAY2 },
  { newKpiId: 74, mapsTo: KPI.RETENTIONDAY3 },
  { newKpiId: 75, mapsTo: KPI.RETENTIONDAY4 },
  { newKpiId: 76, mapsTo: KPI.RETENTIONDAY5 },
  { newKpiId: 77, mapsTo: KPI.RETENTIONDAY6 },
  { newKpiId: 78, mapsTo: KPI.RETENTIONDAY7 },
  { newKpiId: 79, mapsTo: KPI.RETENTIONDAY8 },
  { newKpiId: 80, mapsTo: KPI.RETENTIONDAY9 },
  { newKpiId: 81, mapsTo: KPI.RETENTIONDAY10 },
  { newKpiId: 82, mapsTo: KPI.RETENTIONDAY11 },
  { newKpiId: 83, mapsTo: KPI.RETENTIONDAY12 },
  { newKpiId: 84, mapsTo: KPI.RETENTIONDAY13 },
  { newKpiId: 85, mapsTo: KPI.RETENTIONDAY14 },
  { newKpiId: 86, mapsTo: KPI.RETENTIONDAY15 },
  { newKpiId: 87, mapsTo: KPI.RETENTIONDAY16 },
  { newKpiId: 88, mapsTo: KPI.RETENTIONDAY17 },
  { newKpiId: 89, mapsTo: KPI.RETENTIONDAY18 },
  { newKpiId: 90, mapsTo: KPI.RETENTIONDAY19 },
  { newKpiId: 91, mapsTo: KPI.RETENTIONDAY20 },
  { newKpiId: 92, mapsTo: KPI.RETENTIONDAY21 },
  { newKpiId: 93, mapsTo: KPI.RETENTIONDAY22 },
  { newKpiId: 94, mapsTo: KPI.RETENTIONDAY23 },
  { newKpiId: 95, mapsTo: KPI.RETENTIONDAY24 },
  { newKpiId: 96, mapsTo: KPI.RETENTIONDAY25 },
  { newKpiId: 97, mapsTo: KPI.RETENTIONDAY26 },
  { newKpiId: 98, mapsTo: KPI.RETENTIONDAY27 },
  { newKpiId: 99, mapsTo: KPI.RETENTIONDAY28 },
  { newKpiId: 100, mapsTo: KPI.RETENTIONDAY29 },
  { newKpiId: 101, mapsTo: KPI.RETENTIONDAY30 },
  { newKpiId: 133, mapsTo: KPI.CPC },
  { newKpiId: 134, mapsTo: KPI.TREWATWELL_CPL },
];

/** Returns the KPI ID if any exists, or null otherwise. */
export const getKpiIdForKpi = (kpi: MetricOrKpi): SerialID | null => {
  const mappedKpi = TEMPORARY_KPI_LEGACY_MAP.find(
    (kpiMap) => kpiMap.mapsTo === kpi
  );
  return mappedKpi === undefined ? null : mappedKpi.newKpiId;
};

export const getKpiForId = (kpi: SerialID): MetricOrKpi | null => {
  const mappedKpi = TEMPORARY_KPI_LEGACY_MAP.find(
    (kpiMap) => kpiMap.newKpiId === kpi
  );
  return mappedKpi === undefined ? null : mappedKpi.mapsTo;
};

export default kpiUtils;

export * from './getAggregatedMetricKeyName';

/** List of KPIs not supported in insights due to its source reliability. */
export const BLACKLIST_KPIS: MetricKPIWithSpend[] = [
  KPI.CTR,
  KPI.CTI,
  KPI.CPPDAY7,
  KPI.CPPDAY0,
  KPI.CPPDAY1,
  KPI.CPPDAY2,
  KPI.CPPDAY3,
  KPI.CPPDAY4,
  KPI.CPPDAY5,
  KPI.CPPDAY6,
  KPI.CPPDAY8,
  KPI.CPPDAY9,
  KPI.CPPDAY10,
  KPI.CPPDAY11,
  KPI.CPPDAY12,
  KPI.CPPDAY13,
  KPI.CPPDAY14,
  KPI.CPPDAY15,
  KPI.CPPDAY16,
  KPI.CPPDAY17,
  KPI.CPPDAY18,
  KPI.CPPDAY19,
  KPI.CPPDAY20,
  KPI.CPPDAY21,
  KPI.CPPDAY22,
  KPI.CPPDAY23,
  KPI.CPPDAY24,
  KPI.CPPDAY25,
  KPI.CPPDAY26,
  KPI.CPPDAY27,
  KPI.CPPDAY28,
  KPI.CPPDAY29,
  KPI.CPPDAY30,
];
