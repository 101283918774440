import { IntegrationIconProps } from './types';

const Trello = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0133 4H7.0019C5.344 4 4 5.34315 4 7V25C4 26.6569 5.344 28 7.0019 28H25.0133C26.6653 27.9916 28 26.6509 28 25V7C28 5.34907 26.6653 4.00838 25.0133 4ZM9 7H13C14.1046 7 15 7.89543 15 9V23C15 24.1046 14.1046 25 13 25H9C7.89543 25 7 24.1046 7 23V9C7 7.89543 7.89543 7 9 7ZM23 7H19C17.8954 7 17 7.89543 17 9V16C17 17.1046 17.8954 18 19 18H23C24.1046 18 25 17.1046 25 16V9C25 7.89543 24.1046 7 23 7Z"
      fill="url(#paint0_linear_1334_1141)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_1141"
        x1="16.0076"
        y1="28"
        x2="16.0076"
        y2="4"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset="0.51698" stopColor="#217EF8" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default Trello;
