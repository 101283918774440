import { CountryIconProps } from './types';

const ZW = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3783)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M22.8522 6.87246C22.2426 5.58461 21.4105 4.42291 20.4055 3.43643L12.0008 2.60913L3.5961 3.43647C3.56929 3.46277 3.54313 3.48968 3.5166 3.51625L6.87285 6.8725L12.0008 7.02911L22.8522 6.87246Z"
        fill="#FFDA44"
      />
      <path
        d="M3.51562 20.4852C3.55978 20.5294 3.60384 20.5736 3.64866 20.6171L12 21.3922L20.3512 20.6171C21.3665 19.6327 22.2088 18.4709 22.827 17.181L7.30425 16.6965C5.96864 18.0322 4.42814 19.5726 3.51562 20.4852Z"
        fill="#FFDA44"
      />
      <path
        d="M23.8807 10.3081L9.91309 9.91309L12.0001 12.0001C11.3819 12.6182 10.4591 13.541 9.91309 14.087L23.8734 13.7442C23.9564 13.1747 24.0001 12.5925 24.0001 12.0001C24.0001 11.4256 23.9588 10.861 23.8807 10.3081Z"
        fill="black"
      />
      <path
        d="M12.0004 0.000610352C8.7272 0.000610352 5.76067 1.3117 3.5957 3.43664H20.4051C18.2401 1.3117 15.2736 0.000610352 12.0004 0.000610352Z"
        fill="#6DA544"
      />
      <path
        d="M10.3071 10.3086H23.8797C23.7085 9.09551 23.3562 7.94079 22.8505 6.87256H6.87109L10.3071 10.3086Z"
        fill="#D80027"
      />
      <path
        d="M6.81934 17.1815H22.8266C23.3382 16.1141 23.6961 14.9592 23.8729 13.7455H10.2554C8.92473 15.0761 7.79701 16.2038 6.81934 17.1815Z"
        fill="#D80027"
      />
      <path
        d="M11.9996 24.0006C15.246 24.0006 18.1907 22.7109 20.3508 20.6167H3.64844C5.80863 22.7109 8.75331 24.0006 11.9996 24.0006Z"
        fill="#6DA544"
      />
      <path
        d="M4.28504 2.80994C4.01827 3.03409 3.76088 3.26908 3.51465 3.51522L12 12.0005L3.51465 20.4858C3.76084 20.732 4.01827 20.967 4.28504 21.1911L13.4756 12.0005L4.28504 2.80994Z"
        fill="black"
      />
      <path
        d="M4.82467 8.86914L5.60163 11.2605H8.11624L6.08195 12.7386L6.85895 15.13L4.82467 13.652L2.79034 15.13L3.56744 12.7386L1.5332 11.2605H4.04767L4.82467 8.86914Z"
        fill="#D80027"
      />
      <path
        d="M6.96184 12.1947L4.93694 11.4773C4.93694 11.4773 4.78731 10.0738 4.77827 10.0262C4.70955 9.66418 4.39141 9.39038 4.00937 9.39038C3.57719 9.39038 3.22675 9.74077 3.22675 10.173C3.22675 10.2447 3.2372 10.3137 3.25525 10.3797L2.6875 10.9513C2.6875 10.9513 3.36184 10.9513 3.697 10.9513C3.697 11.9991 2.91512 11.9991 2.91512 13.0425L3.34886 14.086H5.95755L6.39236 13.0425H6.39217C6.43572 12.9424 6.46216 12.8392 6.47308 12.736C6.84789 12.5844 6.96184 12.1947 6.96184 12.1947Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3783">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ZW;
