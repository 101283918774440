/* eslint-disable */

import { useEffect, useState } from 'react';
import { MetricKPIWithSpend, kpiUtils } from '@replai-platform/sdk';
import { CellProps } from 'react-table';
import { useMeasure } from 'react-use';
import { Badge, Tooltip, Typography } from '@replai-platform/ui-components';
import styled from 'styled-components';
import { GeneratedColumn, RequiredGeneratorParams } from '../common';

const AVERAGE_BADGE_WIDTH = 70;

interface AccessorType {
  score: number;
  targets: MetricKPIWithSpend[];
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.875rem;
`;

const Badges = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 0.25rem;
`;

const TooltipKPI = styled(Typography)`
  margin-bottom: 0.5rem;

  :last-child {
    margin-bottom: 0;
  }
`;

const RecommendationTargets = ({ score, targets }: { score: number; targets: MetricKPIWithSpend[] }) => {
  const [numberOfBadges, setNumberOfBadges] = useState(0);
  const [badgesContainerRef, { width: badgesContainerWidth }] = useMeasure<HTMLDivElement>();

  useEffect(() => {
    setNumberOfBadges(Math.trunc(badgesContainerWidth / AVERAGE_BADGE_WIDTH));
  }, [badgesContainerWidth]);

  const slideIndex = Math.max(0, numberOfBadges - 1);
  const kpisInCard = targets.length > numberOfBadges ? targets.slice(0, slideIndex) : targets;
  const kpisInTooltip = targets.length > numberOfBadges ? targets.slice(slideIndex, targets.length) : [];

  const kpisTooltipContent = kpisInTooltip.map((kpi) => (
    <TooltipKPI type="text-xs">{kpiUtils.getDisplayName(kpi).toLocaleUpperCase()}</TooltipKPI>
  ));

  return (
    <Container>
      <Badges ref={badgesContainerRef}>
        {badgesContainerWidth > 0 && (
          <>
            {kpisInCard.map((kpi) => (
              <Badge key={kpi} color="Gray">
                {kpiUtils.getDisplayName(kpi).toUpperCase()}
              </Badge>
            ))}
            {!!kpisInTooltip.length && (
              <Tooltip content={kpisTooltipContent} placement="bottom">
                <Badge color="Gray">{`+${kpisInTooltip.length}`}</Badge>
              </Tooltip>
            )}
          </>
        )}
      </Badges>
    </Container>
  );
};

function getRecommendationTargetsColumn<D extends object = object>({
  columnId,
  accessor,
}: RequiredGeneratorParams<D, AccessorType>): GeneratedColumn<D, AccessorType> {
  return {
    id: columnId,
    accessor,
    width: 500,
    minWidth: 200,
    Cell: ({ value }: CellProps<D, AccessorType>) => (
      <RecommendationTargets score={value?.score} targets={value?.targets} />
    ),
  };
}
export default getRecommendationTargetsColumn;
