const common = {
  White: '#FFFFFF',
  Black: '#000000',
} as const;

type Keys = keyof typeof common;
type Common = typeof common[Keys];

export default common;
export type { Common };
