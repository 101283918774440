import styled from 'styled-components';

export const FiltersContainer = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

export const ActiveBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  padding-top: 26px;
`;

export const Container = styled.div`
  display: flex;
`;

export const FilterDropDownContainer = styled.div`
  padding-right: 16px;
`;

export const SpendFilterContainer = styled.div`
  padding-left: 12px;
`;
