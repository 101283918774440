import { useMutation } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const resendInvite = async (requestParams: { emails: string[] }) => {
  const { emails } = requestParams;
  const url = `${SDK.BASE_URL()}/resend`;
  const res = await provider.getJson<object, void>(url, { method: 'POST', body: { emails } });
  return res;
};

export default function useResendInvite() {
  return useMutation(['user-resend-invite'], (requestParams: { emails: string[] }) => resendInvite(requestParams));
}
