import styled from 'styled-components';
import * as Badges from './Badges';

type Size = 'lg' | 'md' | 'sm';
// Score is always a multiple of 0.05
const SCORE_STEP = 0.05;

export type ReplaiScoreProps = {
  score: number;
  size?: Size;
};

const Container = styled.div<{ $dimension: number }>`
  min-width: ${({ $dimension }) => `${$dimension}px`};
  max-width: ${({ $dimension }) => `${$dimension}px`};
  min-height: ${({ $dimension }) => `${$dimension}px`};
  max-height: ${({ $dimension }) => `${$dimension}px`};
`;

const AVAILABLE_TIERS = [1, 2, 3, 4] as const;
type Tier = (typeof AVAILABLE_TIERS)[number];

const getDimensionBySize = (size: Size) =>
  ({
    lg: 64,
    md: 48,
    sm: 32,
  }[size]);

const CustomScore = ({ score, size = 'md' }: ReplaiScoreProps) => {
  const roundedScore = Number(
    (Math.round(score / SCORE_STEP) * SCORE_STEP).toFixed(2)
  );

  const tier = Math.ceil(roundedScore * 4);

  const TierBadge = AVAILABLE_TIERS.includes(tier as Tier)
    ? Badges[`Tier${tier as Tier}`]
    : Badges.Tier1;

  return (
    <Container $dimension={getDimensionBySize(size)}>
      <TierBadge
        score={Math.round(roundedScore * 100)}
        dimension={getDimensionBySize(size)}
      />
    </Container>
  );
};

export default CustomScore;
