/* eslint-disable react/jsx-props-no-spreading */
import { MobileStoreFlatButtonProps } from './types';
import * as Styled from './styles';

const FDroid = ({
  className,
  size = 'md',
  color,
  ...props
}: MobileStoreFlatButtonProps) => (
  <Styled.Button type="button" className={className || ''} {...props}>
    <svg
      width={size === 'md' ? '112' : '123.2'}
      height={size === 'md' ? '40' : '44'}
      viewBox="0 0 112 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 5C0 2.23858 2.23858 0 5 0H107C109.761 0 112 2.23858 112 5V35C112 37.7614 109.761 40 107 40H5C2.23858 40 0 37.7614 0 35V5Z"
        fill="white"
      />
      <path
        d="M42.5498 31V29.7172L43.8743 29.4673V20.4125L42.5498 20.1626V18.8714H51.9878V22.0202H50.3468L50.2135 20.4958H45.9735V24.1943H50.3051V25.8187H45.9735V29.4673L47.2979 29.7172V31H42.5498Z"
        fill={color || 'black'}
      />
      <path
        d="M53.6871 26.6517V25.0273H58.1187V26.6517H53.6871Z"
        fill={color || 'black'}
      />
      <path
        d="M59.7931 31V29.7172L61.1259 29.4589V20.4291L59.7931 20.1626V18.8714H64.8744C65.9518 18.8714 66.8986 19.1268 67.715 19.6378C68.5313 20.1487 69.1672 20.8512 69.6226 21.7453C70.0779 22.6394 70.3056 23.664 70.3056 24.8191V25.0606C70.3056 26.1991 70.0807 27.2181 69.6309 28.1178C69.1866 29.0119 68.5619 29.7172 67.7566 30.2336C66.9514 30.7445 66.0101 31 64.9327 31H59.7931ZM63.2251 29.384H64.8244C65.5353 29.384 66.1406 29.1951 66.6404 28.8175C67.1457 28.4343 67.5317 27.9179 67.7983 27.2681C68.0648 26.6128 68.1981 25.877 68.1981 25.0606V24.8107C68.1981 23.9777 68.0648 23.2364 67.7983 22.5866C67.5317 21.9369 67.1457 21.426 66.6404 21.0539C66.1406 20.6818 65.5353 20.4958 64.8244 20.4958H63.2251V29.384Z"
        fill={color || 'black'}
      />
      <path
        d="M71.5551 31V29.7172L72.7963 29.4673V23.5279L71.4802 23.278V21.9868H74.7289L74.8372 23.1197L74.8538 23.303C75.337 22.3145 76.0367 21.8202 76.953 21.8202C77.2973 21.8202 77.6055 21.8647 77.8777 21.9535L77.6361 23.8944L76.4616 23.8278C76.0784 23.8056 75.7618 23.8805 75.5119 24.0527C75.262 24.2249 75.0565 24.4637 74.8955 24.7691V29.4673L76.145 29.7172V31H71.5551Z"
        fill={color || 'black'}
      />
      <path
        d="M82.7674 31.1749C81.9011 31.1749 81.1569 30.9806 80.535 30.5918C79.913 30.1975 79.4354 29.6561 79.1022 28.9674C78.769 28.2788 78.6024 27.4847 78.6024 26.585V26.4101C78.6024 25.516 78.769 24.7247 79.1022 24.036C79.4354 23.3474 79.913 22.806 80.535 22.4117C81.1569 22.0174 81.8955 21.8202 82.7508 21.8202C83.6171 21.8202 84.3612 22.0174 84.9832 22.4117C85.6052 22.8004 86.0828 23.3419 86.416 24.036C86.7492 24.7247 86.9158 25.516 86.9158 26.4101V26.585C86.9158 27.4902 86.7492 28.2872 86.416 28.9758C86.0828 29.6644 85.6052 30.2031 84.9832 30.5918C84.3668 30.9806 83.6282 31.1749 82.7674 31.1749ZM82.7674 29.5589C83.4616 29.5589 83.9781 29.284 84.3168 28.7342C84.6556 28.1789 84.8249 27.4625 84.8249 26.585V26.4101C84.8249 25.5493 84.6528 24.8413 84.3085 24.2859C83.9697 23.7251 83.4505 23.4446 82.7508 23.4446C82.0566 23.4446 81.5401 23.7251 81.2014 24.2859C80.8682 24.8413 80.7016 25.5493 80.7016 26.4101V26.585C80.7016 27.4625 80.8682 28.1789 81.2014 28.7342C81.5401 29.284 82.0621 29.5589 82.7674 29.5589Z"
        fill={color || 'black'}
      />
      <path
        d="M88.0154 31V29.7172L89.2649 29.4673V23.5279L87.9404 23.278V21.9868H91.3641V29.4673L92.6052 29.7172V31H88.0154ZM89.1816 19.946V18.0051H91.3641V19.946H89.1816Z"
        fill={color || 'black'}
      />
      <path
        d="M97.0618 31.1749C96.3343 31.1749 95.7124 30.9917 95.1959 30.6251C94.6794 30.2586 94.2824 29.7477 94.0047 29.0924C93.7326 28.4315 93.5965 27.6624 93.5965 26.785V26.61C93.5965 25.6549 93.7326 24.8191 94.0047 24.1027C94.2824 23.3807 94.6794 22.8199 95.1959 22.42C95.7124 22.0202 96.3316 21.8202 97.0535 21.8202C97.57 21.8202 98.0198 21.9202 98.403 22.1201C98.7917 22.3145 99.1249 22.5949 99.4026 22.9615V19.5461L98.0781 19.2962V18.0051H101.502V29.4673L102.751 29.7172V31H99.6858L99.5192 29.9087C98.9361 30.7529 98.117 31.1749 97.0618 31.1749ZM97.6533 29.5172C98.0531 29.5172 98.3974 29.4284 98.6862 29.2507C98.975 29.073 99.2138 28.8203 99.4026 28.4926V24.5192C99.2193 24.1915 98.9805 23.9361 98.6862 23.7528C98.3974 23.5696 98.0587 23.4779 97.6699 23.4779C96.9869 23.4779 96.4871 23.7667 96.1705 24.3443C95.854 24.9218 95.6957 25.6771 95.6957 26.61V26.785C95.6957 27.618 95.8484 28.2816 96.1539 28.7759C96.4648 29.2701 96.9646 29.5172 97.6533 29.5172Z"
        fill={color || 'black'}
      />
      <path
        d="M47.2092 13.2211C46.9922 13.5041 46.6914 13.7184 46.3067 13.864C45.9221 14.0096 45.4852 14.0824 44.9962 14.0824C44.4934 14.0824 44.0484 13.9684 43.661 13.7403C43.2736 13.5123 42.9741 13.1867 42.7626 12.7636C42.5538 12.3378 42.4453 11.8419 42.437 11.2759V10.8061C42.437 9.89948 42.6541 9.19203 43.0881 8.68376C43.5222 8.17274 44.128 7.91724 44.9055 7.91724C45.5732 7.91724 46.1034 8.08208 46.4963 8.41177C46.8892 8.74146 47.1254 9.21675 47.2051 9.83767H46.1831C46.0677 9.1151 45.6487 8.75382 44.9262 8.75382C44.4591 8.75382 44.1033 8.92278 43.8588 9.26071C43.617 9.5959 43.492 10.0891 43.4838 10.7402V11.2017C43.4838 11.8501 43.6198 12.3543 43.8918 12.7142C44.1665 13.0714 44.547 13.2499 45.0333 13.2499C45.5663 13.2499 45.9454 13.129 46.1707 12.8873V11.7128H44.9344V10.9215H47.2092V13.2211Z"
        fill={color || 'black'}
      />
      <path
        d="M51.9464 11.3213H49.4819V13.1675H52.3626V14H48.4393V7.99966H52.3337V8.84036H49.4819V10.497H51.9464V11.3213Z"
        fill={color || 'black'}
      />
      <path
        d="M57.7137 8.84036H55.8428V14H54.8084V8.84036H52.9539V7.99966H57.7137V8.84036Z"
        fill={color || 'black'}
      />
      <path
        d="M61.6286 14H60.5901V7.99966H61.6286V14Z"
        fill={color || 'black'}
      />
      <path
        d="M67.2064 8.84036H65.3355V14H64.3011V8.84036H62.4466V7.99966H67.2064V8.84036Z"
        fill={color || 'black'}
      />
      <path
        d="M74.8097 11.1564C74.8097 11.7444 74.708 12.2609 74.5047 12.7059C74.3014 13.1483 74.0102 13.489 73.6311 13.728C73.2547 13.9643 72.8206 14.0824 72.3288 14.0824C71.8425 14.0824 71.4084 13.9643 71.0265 13.728C70.6474 13.489 70.3534 13.1497 70.1446 12.7101C69.9386 12.2705 69.8342 11.7636 69.8314 11.1894V10.8515C69.8314 10.2663 69.9344 9.74975 70.1405 9.30192C70.3493 8.8541 70.6419 8.51205 71.0183 8.27577C71.3974 8.03675 71.8315 7.91724 72.3205 7.91724C72.8096 7.91724 73.2423 8.03537 73.6187 8.27165C73.9978 8.50518 74.2904 8.84311 74.4965 9.28544C74.7025 9.72502 74.8069 10.2374 74.8097 10.8226V11.1564ZM73.767 10.8432C73.767 10.1783 73.6407 9.6687 73.3879 9.31429C73.1379 8.95987 72.7821 8.78267 72.3205 8.78267C71.87 8.78267 71.5169 8.95987 71.2614 9.31429C71.0087 9.66595 70.8795 10.1646 70.874 10.8102V11.1564C70.874 11.8158 71.0018 12.3254 71.2573 12.6853C71.5156 13.0453 71.8727 13.2252 72.3288 13.2252C72.7903 13.2252 73.1448 13.0494 73.392 12.6977C73.642 12.346 73.767 11.8323 73.767 11.1564V10.8432Z"
        fill={color || 'black'}
      />
      <path
        d="M80.6925 14H79.6498L76.9752 9.74288V14H75.9326V7.99966H76.9752L79.6581 12.2732V7.99966H80.6925V14Z"
        fill={color || 'black'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.59088 5.53034C8.29799 5.23745 7.82311 5.23745 7.53022 5.53034C7.23733 5.82323 7.23732 6.29811 7.53021 6.591L10.1581 9.21893C10.0562 9.4589 9.99988 9.72287 9.99988 10V14C9.99988 15.1046 10.8953 16 11.9999 16H30.9999C32.1045 16 32.9999 15.1046 32.9999 14V10C32.9999 9.72376 32.9439 9.4606 32.8426 9.22125L35.4728 6.59101C35.7657 6.29812 35.7657 5.82324 35.4728 5.53035C35.1799 5.23746 34.7051 5.23745 34.4122 5.53034L31.7833 8.15926C31.5427 8.05674 31.2779 8.00001 30.9999 8.00001H11.9999C11.7227 8.00001 11.4588 8.05638 11.2188 8.15827L8.59088 5.53034ZM17.4999 12C17.4999 13.1046 16.6044 14 15.4999 14C14.3953 14 13.4999 13.1046 13.4999 12C13.4999 10.8954 14.3953 10 15.4999 10C16.6044 10 17.4999 10.8954 17.4999 12ZM29.4999 12C29.4999 13.1046 28.6045 14 27.4999 14C26.3953 14 25.4999 13.1046 25.4999 12C25.4999 10.8954 26.3953 10 27.4999 10C28.6045 10 29.4999 10.8954 29.4999 12Z"
        fill={color || 'black'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5015 25C26.5015 27.7614 24.2629 30 21.5015 30C18.7401 30 16.5015 27.7614 16.5015 25C16.5015 22.2386 18.7401 20 21.5015 20C24.2629 20 26.5015 22.2386 26.5015 25ZM21.5015 28.5C23.4345 28.5 25.0015 26.933 25.0015 25C25.0015 23.067 23.4345 21.5 21.5015 21.5C19.916 21.5 18.5767 22.5543 18.1465 24H19.7691C20.1149 23.4022 20.7612 23 21.5015 23C22.6061 23 23.5015 23.8954 23.5015 25C23.5015 26.1046 22.6061 27 21.5015 27C20.7612 27 20.1149 26.5978 19.7691 26H18.1465C18.5767 27.4457 19.916 28.5 21.5015 28.5Z"
        fill={color || 'black'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99988 31V19C9.99988 17.8954 10.8953 17 11.9999 17H30.9999C32.1045 17 32.9999 17.8954 32.9999 19V31C32.9999 32.1046 32.1045 33 30.9999 33H11.9999C10.8953 33 9.99988 32.1046 9.99988 31ZM27.5015 25C27.5015 28.3137 24.8152 31 21.5015 31C18.1878 31 15.5015 28.3137 15.5015 25C15.5015 21.6863 18.1878 19 21.5015 19C24.8152 19 27.5015 21.6863 27.5015 25Z"
        fill={color || 'black'}
      />
      <path
        d="M5 1H107V-1H5V1ZM111 5V35H113V5H111ZM107 39H5V41H107V39ZM1 35V5H-1V35H1ZM5 39C2.79086 39 1 37.2091 1 35H-1C-1 38.3137 1.68629 41 5 41V39ZM111 35C111 37.2091 109.209 39 107 39V41C110.314 41 113 38.3137 113 35H111ZM107 1C109.209 1 111 2.79086 111 5H113C113 1.68629 110.314 -1 107 -1V1ZM5 -1C1.68629 -1 -1 1.68629 -1 5H1C1 2.79086 2.79086 1 5 1V-1Z"
        fill={color || 'black'}
      />
    </svg>
  </Styled.Button>
);
export default FDroid;
