/* eslint-disable react/jsx-props-no-spreading */
import React, { MouseEventHandler, useState } from 'react';
import { FlatSocialIconProps, getBrandIcon, getFlatIcon } from '../Icons';
import { Color, Colors } from '..';

import '../common.module.css';
import * as Styled from './styles';

type SocialButtonType = 'brand' | 'outline' | 'flat';

interface SocialButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean;
  icon:
    | 'Adjust'
    | 'Apple'
    | 'AppLovin'
    | 'AppsFlyer'
    | 'Facebook'
    | 'Dribbble'
    | 'Figma'
    | 'Google'
    | 'IronSource'
    | 'Liftoff'
    | 'Mintegral'
    | 'Moloco'
    | 'SensorTower'
    | 'Singular'
    | 'Snapchat'
    | 'TikTok'
    | 'Twitter'
    | 'Unity'
    | 'Vungle'
    | 'YouTube';
  socialButtonType?: SocialButtonType;
}

const SocialButton = ({
  children,
  className,
  fullWidth,
  icon,
  socialButtonType = 'brand',
  style,
  onMouseEnter,
  onMouseLeave,
  ...props
}: SocialButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);

  const isBrand = socialButtonType === 'brand';
  const shouldDisplayFlatIcon =
    (isBrand && icon !== 'Google' && icon !== 'Figma') ||
    socialButtonType === 'flat';
  const Icon = shouldDisplayFlatIcon ? getFlatIcon(icon) : getBrandIcon(icon);

  const handleMouseEnter: MouseEventHandler<HTMLButtonElement> = (event) => {
    setIsHovering(true);
    onMouseEnter?.(event);
  };

  const handleMouseLeave: MouseEventHandler<HTMLButtonElement> = (event) => {
    setIsHovering(false);
    onMouseLeave?.(event);
  };

  let backgroundColor: Color | string = Colors.Common.White;
  let color: Color | undefined =
    shouldDisplayFlatIcon && !isBrand ? Colors.Gray[700] : Colors.Common.White;
  switch (icon) {
    case 'Google':
      backgroundColor = isHovering ? Colors.Gray[50] : Colors.Common.White;
      color = '#344054';
      break;
    case 'Facebook':
      backgroundColor = isHovering ? '#0C63D4' : '#1877F2';
      break;
    case 'Apple':
      backgroundColor = Colors.Common.Black;
      break;
    case 'Twitter':
      backgroundColor = isHovering ? '#0C8BD9' : '#1DA1F2';
      break;
    case 'Figma':
      backgroundColor = Colors.Common.Black;
      break;
    case 'Dribbble':
      backgroundColor = isHovering ? '#E62872' : '#EA4C89';
      break;
    case 'Adjust':
    case 'AppsFlyer':
    case 'Mintegral':
    case 'SensorTower':
    case 'Singular':
    case 'YouTube':
      color = undefined;
      break;
    case 'TikTok':
    case 'AppLovin':
    case 'Snapchat':
    case 'Unity':
    case 'Vungle':
    case 'IronSource':
    case 'Moloco':
    case 'Liftoff':
      backgroundColor = Colors.Common.Black;
      break;
    default:
      throw new Error('Invalid icon');
  }

  return (
    <Styled.Root
      type="button"
      className={`${className || ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      $style={{ ...style } as string}
      $hasFullWidth={fullWidth}
      $hasNoChildren={!children}
      $backgroundColor={isBrand ? backgroundColor : undefined}
      $color={isBrand ? color : undefined}
      {...props}
    >
      <Icon
        {...(shouldDisplayFlatIcon && ({ color } as FlatSocialIconProps))}
      />
      {children}
    </Styled.Root>
  );
};

export default SocialButton;
export type { SocialButtonProps };
