import { CountryIconProps } from './types';

const MA = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#D80027"
    />
    <path
      d="M19.0924 9.84775H13.6749L12.0008 4.69543L10.3267 9.84775H4.90918L9.29204 13.0321L7.6179 18.1845L12.0008 15.0002L16.3837 18.1846L14.7096 13.0321L19.0924 9.84775ZM10.5187 12.6335L11.0848 10.8912H12.9167L13.4829 12.6335V12.6335L12.0008 13.7103L10.5188 12.6335L10.5187 12.6335ZM12.5777 9.84775H11.4239L12.0008 8.07212L12.5777 9.84775ZM14.3705 11.9886L14.0139 10.8912H15.8809L14.3705 11.9886ZM9.98766 10.8912L9.63109 11.9886L8.12063 10.8912H9.98766ZM9.60273 15.4526L10.1797 13.6771L11.1132 14.3552L9.60273 15.4526ZM12.8884 14.3553L13.8219 13.6771L14.3989 15.4527L12.8884 14.3553Z"
      fill="#FFDA44"
    />
  </svg>
);
export default MA;
