import { IntegrationIconProps } from './types';

const MsWord = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="8"
      y="2"
      width="24"
      height="28"
      rx="2"
      fill="url(#paint0_linear_1334_1202)"
    />
    <path
      d="M8 23H32V28C32 29.1046 31.1046 30 30 30H10C8.89543 30 8 29.1046 8 28V23Z"
      fill="url(#paint1_linear_1334_1202)"
    />
    <rect
      x="8"
      y="16"
      width="24"
      height="7"
      fill="url(#paint2_linear_1334_1202)"
    />
    <rect
      x="8"
      y="9"
      width="24"
      height="7"
      fill="url(#paint3_linear_1334_1202)"
    />
    <path
      d="M8 12C8 10.3431 9.34315 9 11 9H17C18.6569 9 20 10.3431 20 12V24C20 25.6569 18.6569 27 17 27H8V12Z"
      fill="black"
      fillOpacity="0.3"
    />
    <rect
      y="7"
      width="18"
      height="18"
      rx="2"
      fill="url(#paint4_linear_1334_1202)"
    />
    <path
      d="M15 11.0142H13.0523L11.5229 17.539L9.84967 11H8.20261L6.51634 17.539L5 11.0142H3L5.60131 21H7.3268L9 14.6879L10.6732 21H12.3987L15 11.0142Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_1202"
        x1="8"
        y1="6.66667"
        x2="32"
        y2="6.66667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2B78B1" />
        <stop offset="1" stopColor="#338ACD" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1202"
        x1="8"
        y1="27.375"
        x2="32"
        y2="27.375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1B366F" />
        <stop offset="1" stopColor="#2657B0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_1202"
        x1="18.5"
        y1="20"
        x2="32"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#20478B" />
        <stop offset="1" stopColor="#2D6FD1" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1334_1202"
        x1="18.5"
        y1="13"
        x2="32"
        y2="13"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#215295" />
        <stop offset="1" stopColor="#2E84D3" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1334_1202"
        x1="3.31137e-08"
        y1="17"
        x2="19"
        y2="17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#223E74" />
        <stop offset="1" stopColor="#215091" />
      </linearGradient>
    </defs>
  </svg>
);
export default MsWord;
