import { QueryFunction, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getAssetCsvReport = async (
  requestParams: SDK.GetAssetsCsvReportRequest
): Promise<SDK.GetAssetsCsvReportResponse> => {
  const res = await provider.getJson<object, SDK.GetAssetsCsvReportResponse>(
    `${SDK.BASE_URL()}/assets/csv?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

const KEY = 'assets-csv-report';
type Key = typeof KEY;

type Result = SDK.GetAssetsCsvReportResponse;
type QueryKey = [Key, SDK.GetAssetsCsvReportRequest];

type QueryOptions<R = Result> = UseQueryOptions<Result, unknown, R, QueryKey>;
type QueryResult<R = Result> = UseQueryResult<R>;

const queryFn: QueryFunction<Result, QueryKey> = ({ queryKey: [, requestParams] }) => getAssetCsvReport(requestParams);

// This is exported so we can use useQueries
export function getAssetsCsvReportQueryOptions<R = Result>(
  requestParams: SDK.GetAssetsCsvReportRequest,
  options?: QueryOptions<R>
): QueryOptions<R> {
  return {
    queryKey: [KEY, requestParams],
    queryFn,
    ...options,
  };
}

export default function useAssetsCsvReport<R = Result>(
  requestParams: SDK.GetAssetsCsvReportRequest,
  options?: QueryOptions<R>
): QueryResult<R> {
  return useQuery(getAssetsCsvReportQueryOptions(requestParams, options));
}
