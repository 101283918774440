import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase, VideosPreviewDialog } from '@replai-platform/ui-components';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import { logEvent } from '../../analytics';
import useTagsVideoAssets from '../../api/hooks/tags/useTagsVideoAssets';
import type { RootState } from '../../store/rootReducer';
import { generateHref } from '../../utils';
import { Page } from '../../utils/enums';
import { PreviewTag } from './types';

// export for testing
export const PAGE_SIZE = 3;

const logEventOnAction = ({
  component,
  action,
  componentSuffix = 'Tag Preview',
  page,
  params = {},
}: {
  component: string;
  action: string;
  componentSuffix?: string;
  page?: Page;
  params?: object;
}) => {
  logEvent({
    component: `${component}${componentSuffix ? ` - ${componentSuffix}` : ''}`,
    action,
    category: 'user_actions',
    parameters: { page, ...params },
  });
};

interface TagPreviewVideosWrapperProps {
  title?: ReactNode;
  tag: PreviewTag;
  isOpen: boolean;
  page?: Page;
  component: string;
  onClose: () => void;
}

const TagPreviewVideosWrapper: React.VFC<TagPreviewVideosWrapperProps> = ({
  title,
  tag,
  page,
  component,
  isOpen,
  onClose,
}) => {
  const match = useMatch(':projectId/*')!;
  const projectId = useSelector((state: RootState) => state.project.id);
  const [pagination, setPagination] = useState(1);
  const navigate = useNavigate();

  // Workaround for not logging pause events if the modal is closed while
  // the videos were playing
  let wasClosed = !isOpen;

  // Retrieves the first page of assets for the selected tag
  const getFirstAssetsParams: SDK.GetTagsVideoAssetsRequest = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: [SDK.Metrics.SPEND],
      orderBy: { condition: SDK.OrderByCondition.DESC_NULLS_LAST, value: SDK.Metrics.SPEND },
      tagsFilters: {
        tagsToConsider: tag ? [{ type: tag.type, value: tag.value }] : [],
      },
      maxRecords: PAGE_SIZE,
      filterInactive: true,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, JSON.stringify(tag)]
  );
  const { data: tagAssets, isLoading: tagAssetsLoading } = useTagsVideoAssets(getFirstAssetsParams, {
    enabled: !!tag && isOpen,
  });

  // Retrieves the rest of the assets for the selected tag in the background
  const getRestAssetsParams: SDK.GetTagsVideoAssetsRequest = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: [SDK.Metrics.SPEND],
      orderBy: { condition: SDK.OrderByCondition.DESC_NULLS_LAST, value: SDK.Metrics.SPEND },
      tagsFilters: {
        tagsToConsider: tag ? [{ type: tag.type, value: tag.value }] : [],
      },
      assetsToExclude: tagAssets?.assets.map((asset) => asset.id),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, JSON.stringify(tag), JSON.stringify(tagAssets?.assets)]
  );

  const { data: allTagAssets, isLoading: allTagAssetsLoading } = useTagsVideoAssets(getRestAssetsParams, {
    enabled: !!tag && !tagAssetsLoading && isOpen,
  });

  const allAssets = useMemo(
    () => [...(tagAssets?.assets || []), ...(allTagAssets?.assets || [])],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(tagAssets?.assets), JSON.stringify(allTagAssets?.assets)]
  );

  const generateAssetHref = useCallback(
    (
      asset: SDK.PreviewVideo & {
        id: string;
        isActive: boolean;
      }
    ) => generateHref(match?.params?.projectId ?? '', Page.VideoView, asset.id ?? ''),
    [match?.params?.projectId]
  );

  const assets = useMemo(
    () =>
      allAssets.slice((pagination - 1) * PAGE_SIZE, (pagination - 1) * PAGE_SIZE + PAGE_SIZE).map((asset) => ({
        ...asset,
        href: generateAssetHref(asset),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(allAssets), pagination]
  );

  const onVideoClick = (assetId: string) => {
    navigate(`/${projectId}/${Page.VideoView}/${assetId}`);
    logEventOnAction({ component, page, action: 'Click Video', params: { value: assetId } });
  };

  return (
    <VideosPreviewDialog
      title={
        title ??
        `Videos with ${tag.type ? `${camelCaseToCapitalCase(tag.type)}:` : ''} ${
          tag.value || SDK.messages.NOT_AVAILABLE
        }`
      }
      videos={assets}
      totalVideos={tagAssets?.totalCount || allTagAssets?.totalCount || 0}
      page={pagination}
      pageSize={PAGE_SIZE}
      isOpen={isOpen}
      isLoading={tagAssetsLoading || (!tagAssetsLoading && tagAssets?.assets.length === 0 && allTagAssetsLoading)}
      isUpdating={pagination > 1 && allTagAssetsLoading}
      width={645}
      onClose={() => {
        wasClosed = true;
        onClose?.();
      }}
      onVideoClick={onVideoClick}
      onPauseClick={() => {
        if (wasClosed) return;
        logEventOnAction({ component, page, action: 'Click Pause Video' });
      }}
      onPlayClick={() => logEventOnAction({ component, page, action: 'Click Play Video' })}
      onTimelineClick={() => logEventOnAction({ component, page, action: 'Click Timeline' })}
      onPageChange={(pageNumber) => {
        logEventOnAction({ component, page, action: 'Change page', params: { page: pageNumber } });
        setPagination(pageNumber);
      }}
    />
  );
};

export default TagPreviewVideosWrapper;
