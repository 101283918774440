import styled from 'styled-components';
import { Colors, VideoSidebar } from '@replai-platform/ui-components';
import { RouteAnimator } from '../RouteAnimator';

export const StyledRouteAnimator = styled(RouteAnimator)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  position: relative;
  height: 100%;
  margin-top: 1rem;
  overflow: auto;
`;

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  overflow: visible;

  > :last-child {
    height: unset;
    background-color: ${Colors.Common.White};
  }
`;

export const InnerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  overflow: visible;
`;

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

export const VideosContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  width: fit-content;
`;

export const TitleContainer = styled.div`
  padding-top: 1rem;
  padding-right: 2rem;
  padding-left: 2rem;
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;

  :first-of-type {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;

export const TimelineContainer = styled.div`
  position: relative;
  display: flex;
  padding-bottom: 3rem;
  border-right: 1px solid ${Colors.Gray[200]};

  > :last-child {
    padding-left: 0.9rem;
  }
`;

export const Background = styled.div`
  position: absolute;
  z-index: 0;
  display: flex;
  height: 100%;

  > :last-child:not(:first-child) {
    border-right: 0px;
  }
`;

export const Col = styled.div<{ index: number }>`
  position: ${({ index }) => (index === 0 ? 'sticky' : 'unset')};
  top: ${({ index }) => (index === 0 ? 0 : 'unset')};
  left: ${({ index }) => (index === 0 ? 0 : 'unset')};
  width: 23rem;
  height: 100%;
  background-color: ${({ index }) => (index === 0 ? Colors.Gray[50] : 'inherit')};
  border-right: 1px solid ${Colors.Gray[200]};
`;

export const StyledVideoSidebar = styled(VideoSidebar)`
  background-color: ${Colors.Common.White};
  background-color: red;
  border-right: 0px;
`;
