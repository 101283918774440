interface ConditionalWrapperProps {
  renderWrapper: (children: React.ReactElement) => React.ReactElement;
  condition: boolean;
  children: React.ReactElement;
}

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  renderWrapper,
  children,
}) => (condition ? renderWrapper(children) : children);

export default ConditionalWrapper;
