import { capitalizeWords } from './capitalizeWords';

const SPLIT_CAMEL_CASE_REGEX = /([a-z0-9])([A-Z])/g;

/**
 * Converts camel case strings into capital case
 * strings, e.g. 'numCta' -> 'Num Cta'.
 * @param value The string to be converted
 * @returns The converted string in capital case
 */
export function camelCaseToCapitalCase(value: string) {
  let res = value;
  // Replace underscores and hyphens by spaces
  res = res.replace(/[_-]/g, ' ');
  //  Split camel case
  res = res.replace(SPLIT_CAMEL_CASE_REGEX, '$1 $2');
  res = capitalizeWords(res);
  return res;
}

/**
 * Reverts the capital case conversion of a string into
 * a camelCase string, e.g. 'Num Cta' -> 'numCta'.
 * @param value The capital case to be converted to camelCase
 * @returns The camelCase string.
 */
export function capitalCaseToCamelCase(value: string) {
  if (!value.includes(' ')) return value;
  const parts = value.split(' ');
  return `${parts[0].toLowerCase()}${parts
    .slice(1)
    .map((p) => capitalizeWords(p))
    .join('')}`;
}
