import { PaymentIconProps } from './types';

const Maestro = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      d="M25.6768 15.6077C25.6768 20.5457 21.7197 24.5487 16.8384 24.5487C11.9571 24.5487 8 20.5457 8 15.6077C8 10.6697 11.9571 6.66669 16.8384 6.66669C21.7197 6.66669 25.6768 10.6697 25.6768 15.6077Z"
      fill="#ED0006"
    />
    <path
      d="M37.1426 15.6077C37.1426 20.5457 33.1855 24.5487 28.3042 24.5487C23.4229 24.5487 19.4658 20.5457 19.4658 15.6077C19.4658 10.6697 23.4229 6.66669 28.3042 6.66669C33.1855 6.66669 37.1426 10.6697 37.1426 15.6077Z"
      fill="#0099DF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5712 8.80261C24.4715 10.4426 25.6765 12.8828 25.6765 15.6077C25.6765 18.3326 24.4715 20.7728 22.5712 22.4128C20.6708 20.7728 19.4658 18.3326 19.4658 15.6077C19.4658 12.8828 20.6708 10.4426 22.5712 8.80261Z"
      fill="#6C6BBD"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default Maestro;
