import { messages } from '@replai-platform/sdk';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import * as SDK from '@replai-platform/sdk';
import { Card, Typography, Colors, Icons, Tooltip, Badge, Skeleton } from '@replai-platform/ui-components';
import { useQueries, UseQueryResult } from 'react-query';
import { useCallback } from 'react';
import { getConceptAvgAgeQueryOptions } from '../../../api/hooks/concepts/useConceptAvgAge';
import useConceptHeroCount from '../../../api/hooks/concepts/useConceptHeroCount';
import { api } from '../../../api';
import { RootState } from '../../../store/rootReducer';
import { toFormattedDate, isoDateToDayObject } from '../../../components/FilterBar/DatesPicker/helpers';
import { TypographyClean } from '../../../utils/styles';
import { daysBetween } from '../utils';
import { formatDateDistance, calculateDelta } from '../../../utils';
import { ActivityType } from '../../../utils/enums';
import { getConceptCountQueryOptions } from '../../../api/hooks/concepts/useConceptCount';
import { isoDateSubtract } from '../../../utils/isoDateSubtract';

const ConceptsCardContainer = styled.div`
  min-width: 16.75rem;
  max-width: 16.75rem;
  height: 100%;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MetricContainer = styled.div``;

const MetricTitle = styled.div`
  display: flex;
  gap: 0.375rem;
  align-items: center;
`;

const Metric = styled.div`
  display: flex;
  gap: 0.625rem;
  height: 2rem;
  margin-top: 0.125rem;
`;

const MetricValue = styled.div`
  display: flex;
  gap: 0.125rem;
  align-items: flex-end;
`;

const DeltaBadge = styled(Badge)`
  align-self: center;
`;

const calculateActiveCountMetrics = (counts: {
  [key in 'currentTimeframe' | 'previousTimeframe']: UseQueryResult<SDK.GetConceptsCountResponse>;
}) => {
  if (Object.values(counts).some((c) => c.isLoading || c.isIdle)) {
    return { loading: true };
  }

  const { currentTimeframe: active, previousTimeframe: previous } = counts;
  const {
    active: { count: activeCount },
    previous: { count: previousCount },
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = { active: active.data!, previous: previous.data! };

  return {
    loading: false,
    primary: activeCount.toString(),
    delta: calculateDelta(activeCount, previousCount),
    previousValue: previousCount.toString(),
  };
};

const calculateLaunchedCountMetrics = (counts: {
  [key in 'currentTimeframe' | 'previousTimeframe']: UseQueryResult<SDK.GetConceptsCountResponse>;
}) => {
  if (Object.values(counts).some((c) => c.isLoading || c.isIdle)) {
    return { loading: true };
  }

  const { currentTimeframe: launched, previousTimeframe: previous } = counts;
  const {
    active: { count: launchedCount },
    previous: { count: previousCount },
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = { active: launched.data!, previous: previous.data! };

  return {
    loading: false,
    primary: launchedCount.toString(),
    delta: calculateDelta(launchedCount, previousCount),
    previousValue: previousCount.toString(),
  };
};

const calculateAverageAgeMetrics = (averageAges: {
  [key in 'currentTimeframe' | 'previousTimeframe']: UseQueryResult<SDK.GetConceptsAverageAgeResponse>;
}) => {
  if (Object.values(averageAges).some((a) => a.isLoading || a.isIdle)) {
    return { loading: true };
  }

  const {
    current: { averageAge: currentAverageAge },
    previous: { averageAge: previousAverageAge },
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = { current: averageAges.currentTimeframe.data!, previous: averageAges.previousTimeframe.data! };

  const currentDateObject = new Date();
  currentDateObject.setDate(currentDateObject.getDate() - Math.round(currentAverageAge));
  const previousDateObject = new Date();
  previousDateObject.setDate(previousDateObject.getDate() - Math.round(previousAverageAge));

  return {
    loading: false,
    primary: formatDateDistance(currentDateObject),
    delta: calculateDelta(currentAverageAge, previousAverageAge),
    previousValue: formatDateDistance(previousDateObject),
  };
};

const renderMetricTitle = ({ title, tooltipContent }: { title: string; tooltipContent: string }) => (
  <MetricTitle>
    <TypographyClean type="text-sm" fontWeight="medium" color={Colors.Gray[500]}>
      {title}
    </TypographyClean>
    <Tooltip content={<TypographyClean type="text-sm">{tooltipContent}</TypographyClean>} placement="top">
      <div>
        <Icons.BaseIcons.HelpCircle color={Colors.Gray[500]} dimension={14} />
      </div>
    </Tooltip>
  </MetricTitle>
);

const renderMetric = ({
  loading,
  daysOfDataToConsider,
  primary,
  delta,
  previousValue,
  lessIsBetter = false,
}: {
  loading: boolean;
  daysOfDataToConsider: number;
  primary?: string;
  delta?: number | null;
  previousValue?: string;
  lessIsBetter?: boolean;
}) => {
  const increasingDelta = (delta ?? 0) > 0;
  const winning = lessIsBetter ? !increasingDelta : increasingDelta;
  return (
    <Metric>
      {loading ? (
        <Skeleton height="2rem" width="11rem" />
      ) : (
        <>
          <MetricValue>
            <TypographyClean type="display-xs" fontWeight="semi-bold">
              {primary}
            </TypographyClean>
          </MetricValue>
          {!!Math.trunc(Math.abs(delta ?? 0) * 100) && (
            <Tooltip
              content={
                <TypographyClean type="text-sm" data-test="trend-card-kpi-past-value">
                  Previous {daysOfDataToConsider} days: {previousValue}
                </TypographyClean>
              }
              placement="top"
            >
              <DeltaBadge
                size="sm"
                color={!previousValue ? 'Gray' : winning ? 'Success' : 'Error'}
                leadingIcon={!previousValue ? undefined : increasingDelta ? 'ArrowUp' : 'ArrowDown'}
                leadingIconColor={winning ? Colors.Success[500] : Colors.Error[500]}
              >
                {!previousValue ? messages.NOT_AVAILABLE : `${Math.trunc(Math.abs(delta ?? 0) * 100)}%`}
              </DeltaBadge>
            </Tooltip>
          )}
        </>
      )}
    </Metric>
  );
};

const getRequestParamsStatic = ({
  ageEndDate,
  ageStartDate,
  endDate,
  activeOnly = false,
  maxRecords,
  networks,
  offset,
  projectId,
  startDate,
}: {
  ageEndDate?: string;
  ageStartDate?: string;
  endDate: string;
  activeOnly?: boolean;
  maxRecords?: number;
  networks: SDK.Network[];
  offset?: number;
  projectId: string;
  startDate: string;
}) => ({
  projectIds: [projectId],
  metricsFilters: api.filterConverter.getMetricsFilters({
    ageEndDate,
    ageStartDate,
    countries: ['ALL'],
    endDate,
    startDate,
  }),
  adsFilters: {
    networksToConsider: networks,
  },
  ...(activeOnly && {
    assetFilters: api.filterConverter.getAssetFilters({
      activity: ActivityType.active,
      endDate: toFormattedDate(isoDateToDayObject(new Date().toISOString())),
    }),
  }),
  maxRecords,
  offset,
});

const Metrics = ({ filtersLoading }: { filtersLoading?: boolean }) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const networks = useSelector((state: RootState) => state.filters.networks);
  const startDate = useSelector((state: RootState) => state.filters.startDate);
  const endDate = useSelector((state: RootState) => state.filters.endDate);

  const daysOfDataToConsider = daysBetween(new Date(startDate), new Date(endDate));

  const previousTimeframeEndDate = isoDateSubtract(endDate ?? null, daysOfDataToConsider);
  const previousTimeframeStartDate = isoDateSubtract(endDate ?? null, 2 * daysOfDataToConsider - 1);

  const getRequestParams = useCallback(
    (opts: Omit<Parameters<typeof getRequestParamsStatic>[0], 'networks' | 'projectId'>) =>
      getRequestParamsStatic({ networks, projectId, ...opts }),
    [projectId, networks]
  );

  const enabled = !!endDate && !filtersLoading;

  const activeCount = (([currentTimeframe, previousTimeframe]) => ({ currentTimeframe, previousTimeframe }))(
    useQueries([
      getConceptCountQueryOptions(
        getRequestParams({
          ageEndDate: endDate,
          endDate,
          startDate,
        }),
        { enabled }
      ),
      getConceptCountQueryOptions(
        getRequestParams({
          ageEndDate: previousTimeframeEndDate,
          endDate: previousTimeframeEndDate,
          startDate: previousTimeframeStartDate,
        }),
        { enabled }
      ),
    ])
  );

  const launchedCount = (([currentTimeframe, previousTimeframe]) => ({ currentTimeframe, previousTimeframe }))(
    useQueries([
      getConceptCountQueryOptions(
        getRequestParams({
          ageEndDate: endDate,
          ageStartDate: startDate,
          endDate,
          startDate,
        }),
        { enabled }
      ),
      getConceptCountQueryOptions(
        getRequestParams({
          ageEndDate: previousTimeframeEndDate,
          ageStartDate: previousTimeframeStartDate,
          endDate: previousTimeframeEndDate,
          startDate: previousTimeframeStartDate,
        }),
        { enabled }
      ),
    ])
  );

  const averageAge = (([currentTimeframe, previousTimeframe]) => ({ currentTimeframe, previousTimeframe }))(
    useQueries([
      getConceptAvgAgeQueryOptions(
        getRequestParams({
          ageEndDate: endDate,
          endDate,
          startDate,
        }),
        { enabled }
      ),
      getConceptAvgAgeQueryOptions(
        getRequestParams({
          ageEndDate: previousTimeframeEndDate,
          endDate: previousTimeframeEndDate,
          startDate: previousTimeframeStartDate,
        }),
        {
          enabled,
        }
      ),
    ])
  );

  const averageAgeTop10 = (([currentTimeframe, previousTimeframe]) => ({ currentTimeframe, previousTimeframe }))(
    useQueries([
      getConceptAvgAgeQueryOptions(
        getRequestParams({
          ageEndDate: endDate,
          endDate,
          startDate,
          maxRecords: 10,
        }),
        {
          enabled,
        }
      ),
      getConceptAvgAgeQueryOptions(
        getRequestParams({
          ageEndDate: previousTimeframeEndDate,
          endDate: previousTimeframeEndDate,
          startDate: previousTimeframeStartDate,
          maxRecords: 10,
        }),
        {
          enabled,
        }
      ),
    ])
  );

  const { data: heroConceptsCount, isLoading: isHeroConceptsCountLoading } = useConceptHeroCount(
    {
      ...getRequestParams({
        ageEndDate: endDate,
        endDate,
        startDate,
      }),
      minPercentage: 0.1,
    },
    { enabled }
  );

  return (
    <ConceptsCardContainer data-test="dashboard-metrics-card">
      <Card fullWidth fullHeight>
        <CardHeader>
          <Typography type="text-lg" fontWeight="medium">
            Concepts
          </Typography>
        </CardHeader>
        <CardContent>
          <MetricContainer data-test="active-concepts">
            {renderMetricTitle({
              title: `Active concepts`,
              tooltipContent: `Concepts with at least one ad active in the last two days`,
            })}
            {renderMetric({ ...calculateActiveCountMetrics(activeCount), daysOfDataToConsider })}
          </MetricContainer>
          <MetricContainer data-test="launched-concepts">
            {renderMetricTitle({
              title: `Launched concepts`,
              tooltipContent: `Total number of concepts launched in the last ${daysOfDataToConsider} days`,
            })}
            {renderMetric({ ...calculateLaunchedCountMetrics(launchedCount), daysOfDataToConsider })}
          </MetricContainer>
          <MetricContainer data-test="age-avg-concepts">
            {renderMetricTitle({
              title: 'Avg Age',
              tooltipContent: `Average age of concepts that have been active at least once in the last ${daysOfDataToConsider} days`,
            })}
            {renderMetric({ ...calculateAverageAgeMetrics(averageAge), lessIsBetter: true, daysOfDataToConsider })}
          </MetricContainer>
          <MetricContainer data-test="avg-age-top-concepts">
            {renderMetricTitle({
              title: 'Avg Age Top 10',
              tooltipContent: `Average age of top 10 concepts from the last ${daysOfDataToConsider} days`,
            })}
            {renderMetric({ ...calculateAverageAgeMetrics(averageAgeTop10), lessIsBetter: true, daysOfDataToConsider })}
          </MetricContainer>
          <MetricContainer data-test="hero-concepts">
            {renderMetricTitle({
              title: `Hero concepts`,
              tooltipContent: `Concepts responsible for at least 10% of spend in the last ${daysOfDataToConsider} days`,
            })}
            {renderMetric({
              loading: isHeroConceptsCountLoading || !enabled,
              primary: heroConceptsCount?.heroConceptsCount?.toString() ?? '0',
              daysOfDataToConsider,
            })}
          </MetricContainer>
        </CardContent>
      </Card>
    </ConceptsCardContainer>
  );
};

export default Metrics;
