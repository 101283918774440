/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import buildFilteringWhereClause from '../../../utils/filtering';
import * as provider from '../../api/provider';
import { filtersAsQueryParams } from '../../api/filters';

// TODO: Move to SDK
interface GetAllKindTagsRequest {
  projectId: SDK.UUID;
  kind?: string;
  where?: any | any[];
  tagFiltering:
    | {
        campaignIdsToConsider?: string[];
        campaignIdsToExclude?: string[];
        adSetsIds?: string[];
        networkAccountIds?: string[];
        excludedNetworkAccountIds?: string[];
        countries?: string[];
        excludedCountries?: string[];
        tagFilters?: { type: any; value: any } | { type: any; value: any }[];
        tagsToExclude?: { type: any; value: any } | { type: any; value: any }[];
        tagsToConsider?: { type: any; value: any } | { type: any; value: any }[];
        tagTypesToConsider?: string | string[];
        tagTypesToExclude?: string | string[];
        startDate?: string;
        endDate?: string;
        kpi?: string;
        numExcludedTopCreatives?: number;
        advtIds?: any;
        minSpend?: number;
        maxSpend?: number;
        minAssetActiveDate?: string;
      }
    | Record<string, never>;
}

const getAllKindTags = async (requestParams: GetAllKindTagsRequest) => {
  const { projectId, kind, where, tagFiltering } = requestParams;
  const queryParams = [
    ...filtersAsQueryParams(
      {
        projectId,
        kind,
        tagFiltering,
      },
      true // include tag type filtering
    ),
  ];

  const whereClause = buildFilteringWhereClause(where);
  if (whereClause.length > 0) {
    queryParams.push(`where=${encodeURIComponent(whereClause as unknown as string)}`);
  }

  const url = `${SDK.BASE_URL()}/kind/tags${queryParams.length > 0 ? `?${queryParams.join('&')}` : ''}`;

  return provider.getJson<object, SDK.Tag[]>(url);
};

export default function useAllKindTags<R = SDK.Tag[]>(
  requestParams: GetAllKindTagsRequest,
  options?: UseQueryOptions<SDK.Tag[], unknown, R>
) {
  return useQuery(['tag-all-kind', requestParams], () => getAllKindTags(requestParams), options);
}
