import styled from 'styled-components';
import { Image } from 'antd';
import * as Shared from '..';

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  .ant-image-mask {
    border-radius: 8px;
  }
`;

const StyledImage = styled(Image)`
  width: auto;
  max-width: 100%;
  max-height: 22rem;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const VerticalLayout = ({
  contentDescription,
  imageSrc,
}: {
  contentDescription: string | JSX.Element;
  imageSrc?: string;
}) => (
  <div style={{ maxWidth: '80%', paddingTop: '2rem' }}>
    <Shared.RegularText>{contentDescription}</Shared.RegularText>
    {imageSrc ? (
      <ImageContainer>
        <StyledImage src={imageSrc} preview={{ icons: { rotateLeft: null, rotateRight: null } }} />
      </ImageContainer>
    ) : null}
  </div>
);

export default VerticalLayout;
