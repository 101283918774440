import { DropDownChip, DropDownChipProps } from '@replai-platform/ui-components';

export interface PublisherFilterProps {
  options: DropDownChipProps['dropDownOptions'];
  onChange: DropDownChipProps['onChange'];
  loading: boolean;
  disabled?: boolean;
}

const PublisherFilter: React.VFC<PublisherFilterProps> = ({ options, onChange, loading, disabled = false }) => (
  <DropDownChip
    data-test="filter-app-dropdown"
    placeHolder="Select Publishers"
    defaultOption="All Publishers"
    dropDownType="multiselect"
    dropDownOptions={options}
    dropDownAlignment="right"
    onChange={onChange}
    prefixLabel="Publishers"
    disabled={disabled}
    loading={loading}
  />
);

export default PublisherFilter;
