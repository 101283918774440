import { Network, PromotedObjectType } from '../../ads';
import { RecommendationType } from '../../common';
import { MetricKPIWithSpend } from '../../metrics';
import { TagWithKind, TagIntroWithKind } from '../../tags';

export enum BreakdownField {
  NETWORK = 'network',
  PROMOTED_OBJECT_TYPE = 'promotedObjectType',
  COUNTRY = 'country',
}

export type RecommendedCreativeLevelContent =
  | RecommendedCreativeAddTagContent
  | RecommendedCreativeAddTagIntroContent
  | RecommendedCreativeRemoveTagContent
  | RecommendedCreativeRemoveTagIntroContent
  | RecommendedCreativeReplaceTagContent
  | RecommendedCreativeReplaceTagIntroContent;

export type RecommendedCreativeAddTagContent = {
  tag: TagWithKind;
  type: RecommendationType.CREATIVE_ADD_TAG;
};

export type RecommendedCreativeAddTagIntroContent = {
  tag: TagIntroWithKind;
  type: RecommendationType.CREATIVE_ADD_TAG_INTRO;
};

export type RecommendedCreativeRemoveTagContent = {
  tag: TagWithKind;
  type: RecommendationType.CREATIVE_REMOVE_TAG;
};

export type RecommendedCreativeRemoveTagIntroContent = {
  tag: TagIntroWithKind;
  type: RecommendationType.CREATIVE_REMOVE_TAG_INTRO;
};

export type RecommendedCreativeReplaceTagContent = {
  previous: TagWithKind;
  new: TagWithKind;
  type: RecommendationType.CREATIVE_REPLACE_TAG;
};

export type RecommendedCreativeReplaceTagIntroContent = {
  previous: TagIntroWithKind;
  new: TagIntroWithKind;
  type: RecommendationType.CREATIVE_REPLACE_TAG_INTRO;
};

export type RecommendedFeatureLevelContent =
  | RecommendedTagContent
  | RecommendedTagIntroContent;

export type RecommendedTagContent = {
  tag: TagWithKind;
  type: RecommendationType.GOOD_TAG | RecommendationType.BAD_TAG;
};

export type RecommendedTagIntroContent = {
  tag: TagIntroWithKind;
  type: RecommendationType.GOOD_TAG_INTRO | RecommendationType.BAD_TAG_INTRO;
};

type RecommendationBase = {
  id: string | string[];
  since: string;
  until: string;
};

export type CreativeRecommendation = RecommendationBase & {
  name: {
    score: number;
    targets: MetricKPIWithSpend[];
  } & RecommendedCreativeLevelContent;
  networks?: Network[];
  promotedObjectType?: PromotedObjectType;
};

export type FeatureRecommendation = RecommendationBase & {
  score: number;
  targets: MetricKPIWithSpend[];
  networks?: Network[];
  promotedObjectType?: PromotedObjectType;
  /**
   * @deprecated To be removed soon. Do not use.
   */
  textDescription: string;
} & (
    | {
        content: RecommendedTagContent;
        type: RecommendedTagContent['type'];
      }
    | {
        content: RecommendedTagIntroContent;
        type: RecommendedTagIntroContent['type'];
      }
  );

export type Recommendation = CreativeRecommendation | FeatureRecommendation;
