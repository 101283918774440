import { CountryIconProps } from './types';

const DM = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3609)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M14.3486 23.7693C19.0925 22.8282 22.8294 19.0913 23.7705 14.3474H14.3486V23.7693Z"
        fill="#496E2D"
      />
      <path
        d="M0.230469 14.3474C1.17148 19.0913 4.90845 22.8282 9.65234 23.7693V14.3474H0.230469Z"
        fill="#496E2D"
      />
      <path
        d="M23.7705 9.65222C22.8294 4.90838 19.0925 1.17141 14.3486 0.230347V9.65222H23.7705Z"
        fill="#496E2D"
      />
      <path
        d="M9.65234 0.230469C4.90845 1.17153 1.17148 4.9085 0.230469 9.65234H9.65234V0.230469Z"
        fill="#496E2D"
      />
      <path
        d="M11.2175 14.3481H9.65234V23.77C10.1631 23.8713 10.6857 23.9399 11.2175 23.9742V14.3481Z"
        fill="#FFDA44"
      />
      <path
        d="M11.2175 9.6528V0.0267334C10.6857 0.0609522 10.1631 0.129624 9.65234 0.230921V9.6528H11.2175Z"
        fill="#FFDA44"
      />
      <path
        d="M12.782 0.0265977C12.5232 0.00986328 12.2624 0.000488281 11.9994 0.000488281C11.7364 0.000488281 11.4756 0.00986328 11.2168 0.0265977V9.65271H12.782V0.0265977H12.782Z"
        fill="black"
      />
      <path
        d="M11.2168 23.9738C11.4756 23.9906 11.7364 24 11.9994 24C12.2624 24 12.5232 23.9906 12.782 23.9738V14.3478H11.2168V23.9738H11.2168Z"
        fill="black"
      />
      <path
        d="M9.65243 11.2178V9.65259H0.230555C0.129211 10.1634 0.0605859 10.6859 0.0263672 11.2178H9.65243Z"
        fill="#FFDA44"
      />
      <path
        d="M14.3486 11.2183H23.9747C23.9405 10.6864 23.8719 10.1639 23.7706 9.65308H14.3487V11.2183H14.3486Z"
        fill="#FFDA44"
      />
      <path
        d="M14.3477 12.7832H23.9738C23.9904 12.5244 23.9998 12.2635 23.9998 12.0005C23.9998 11.7375 23.9904 11.4767 23.9737 11.2179H14.3477V12.7832Z"
        fill="black"
      />
      <path
        d="M0.0261094 11.2179C0.00942187 11.4767 0 11.7375 0 12.0005C0 12.2635 0.00942187 12.5244 0.0261094 12.7832H9.65222V11.2179H0.0261094V11.2179Z"
        fill="black"
      />
      <path
        d="M11.9999 17.7389C15.1695 17.7389 17.739 15.1694 17.739 11.9998C17.739 8.83012 15.1695 6.26062 11.9999 6.26062C8.83024 6.26062 6.26074 8.83012 6.26074 11.9998C6.26074 15.1694 8.83024 17.7389 11.9999 17.7389Z"
        fill="#D80027"
      />
      <path
        d="M13.3075 12.6408C12.8806 11.7871 12.3258 10.9218 12.3258 10.9218C12.3258 10.9218 12.3395 10.5278 12.3395 10.293C12.3395 9.9394 12.0529 9.65271 11.6992 9.65271C11.3642 9.65271 11.0897 9.9101 11.0617 10.2378C10.7983 10.255 10.5898 10.4735 10.5898 10.7413C10.5898 10.936 10.7001 11.1047 10.8616 11.189C10.9418 11.0013 11.099 10.8548 11.2933 10.788C11.325 10.8139 11.359 10.8371 11.3953 10.8567L11.4774 10.896C11.4774 10.896 11.1732 11.7534 11.1732 12.214C11.1732 13.5088 12.027 13.9082 12.027 13.9082V13.9215L11.6001 14.3484H12.4538V13.4946L12.8807 13.9215C12.8845 13.9135 13.6893 13.4044 13.3075 12.6408Z"
        fill="#496E2D"
      />
      <path
        d="M11.9996 7.30444L12.1938 7.90229H12.8224L12.3139 8.2718L12.5082 8.86964L11.9996 8.50018L11.491 8.86964L11.6853 8.2718L11.1768 7.90229H11.8054L11.9996 7.30444Z"
        fill="#496E2D"
      />
      <path
        d="M9.24012 8.20183L9.74862 8.57135L10.2572 8.20178L10.063 8.79972L10.5716 9.16914L9.94292 9.16919L9.74867 9.76704L9.55437 9.16919L8.92578 9.16914L9.43433 8.79963L9.24012 8.20183Z"
        fill="#496E2D"
      />
      <path
        d="M7.53418 10.5491H8.16273L8.35702 9.95129L8.55127 10.5491L9.17991 10.5491L8.67136 10.9187L8.86566 11.5165L8.35702 11.147L7.84848 11.5165L8.04268 10.9186L7.53418 10.5491Z"
        fill="#496E2D"
      />
      <path
        d="M7.53418 13.4513L8.04268 13.0819L7.84843 12.484L8.35702 12.8535L8.86562 12.484L8.67137 13.0819L9.17996 13.4514L8.55127 13.4514L8.35702 14.0492L8.16273 13.4513H7.53418Z"
        fill="#496E2D"
      />
      <path
        d="M9.24017 15.7989L9.43437 15.2012L8.92578 14.8317H9.55442L9.74872 14.2338L9.94297 14.8316H10.5716L10.063 15.2012L10.2573 15.799L9.74867 15.4295L9.24017 15.7989Z"
        fill="#496E2D"
      />
      <path
        d="M11.9996 16.6963L11.8054 16.0986H11.1768L11.6853 15.729L11.491 15.1312L11.9996 15.5006L12.5081 15.1311L12.3139 15.729L12.8224 16.0985L12.1938 16.0986L11.9996 16.6963Z"
        fill="#496E2D"
      />
      <path
        d="M14.7601 15.7989L14.2516 15.4295L13.7431 15.799L13.9373 15.2012L13.4287 14.8317L14.0574 14.8316L14.2516 14.2338L14.4459 14.8317H15.0745L14.5659 15.2012L14.7601 15.7989Z"
        fill="#496E2D"
      />
      <path
        d="M16.4661 13.4514H15.8375L15.6433 14.0493L15.449 13.4515H14.8203L15.3289 13.0819L15.1346 12.4841L15.6433 12.8536L16.1518 12.4841L15.9576 13.082L16.4661 13.4514Z"
        fill="#496E2D"
      />
      <path
        d="M16.4661 10.5491L15.9576 10.9186L16.1518 11.5164L15.6433 11.1469L15.1347 11.5165L15.3289 10.9186L14.8203 10.5491L15.449 10.549L15.6432 9.95129L15.8375 10.5491H16.4661Z"
        fill="#496E2D"
      />
      <path
        d="M14.7601 8.20178L14.5659 8.79958L15.0745 9.1691H14.4459L14.2516 9.76699L14.0574 9.16915L13.4287 9.16919L13.9373 8.79958L13.7431 8.20183L14.2516 8.57125L14.7601 8.20178Z"
        fill="#496E2D"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3609">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default DM;
