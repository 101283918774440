import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import { BASE_URL, convertRequestToQueryParams } from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getAssetsMetrics = async (requestParams: SDK.GetAssetsMetricsRequest) => {
  const res = await provider.getJson<object, SDK.GetAssetsMetricsResponse>(
    `${BASE_URL()}/assets/metrics?${convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useAssetsMetrics(
  requestParams: SDK.GetAssetsMetricsRequest,
  options?: UseQueryOptions<SDK.GetAssetsMetricsResponse>
) {
  return useQuery(['assets-metrics', requestParams], () => getAssetsMetrics(requestParams), options);
}
