import styled from 'styled-components';
import Badge from '../../Badge/Badge';
import Colors from '../../Colors';
import TimelineRow from '../../Timeline/TimelineBody/TimelineRow/TimelineRow';
import TimelineTimestamps from '../../Timeline/TimelineTimestamps/TimelineTimestamps';

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 28.75rem;
  background-color: ${Colors.Gray[300]};
  border-radius: 0.5rem;
`;

const SyledBadge = styled(Badge)`
  position: absolute;
  top: 50%;
  left: 50%;
  gap: 0.25rem;
  transform: translate(-50%, -50%);

  &&& {
    display: flex;
  }
`;

const TimestampsContainer = styled.div`
  margin-top: 1rem;
`;

const EmptyState: React.VFC = () => (
  <div>
    <VideoContainer>
      <SyledBadge leadingIcon="Video" color="Gray">
        Select a tag on the left to preview its videos.
      </SyledBadge>
    </VideoContainer>
    <TimestampsContainer>
      <TimelineTimestamps timestampsInSeconds={[0, 5, 10, 15, 20, 25, 30]} />
    </TimestampsContainer>
    <TimelineRow
      color="Gray"
      segments={[
        { startSeconds: 2, endSeconds: 8 },
        { startSeconds: 10, endSeconds: 15 },
        { startSeconds: 22, endSeconds: 24 },
      ]}
      duration={30}
      type=""
      value=""
    />
  </div>
);

export default EmptyState;
