import styled, { css } from 'styled-components';

export const Root = styled.div<{
  $backgroundColor?: string;
  $color?: string;
  $style?: string;
}>`
  display: inline-block;
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  width: max-content;
  font-weight: 500;
  border-radius: 32px;
  background-color: ${(props) => props.$backgroundColor};
  color: ${(props) => props.$color};

  &&& {
    ${(props) =>
      css`
        ${props.$style}
      `}
  }

  &.md {
    padding: 4px 12px 4px 4px;
    font-size: 12px;
  }

  &.lg {
    padding: 4px 14px 4px 6px;
    font-size: 14px;
  }

  &.trailingTitle {
    direction: rtl;
  }

  &.trailingTitle.md {
    padding: 4px 4px 4px 12px;
  }

  &.trailingTitle.lg {
    padding: 4px 6px 4px 14px;
  }
`;

export const TitleContainer = styled.span`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  direction: ltr;
  border-radius: 16px;
`;

export const LeadingTitleIconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-right: 6px;
`;

export const TrailingTitleIconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-left: 6px;
`;

export const BodyContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;
