import { PaymentIconProps } from './types';

const PayPal = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.485 24.5976L19.7793 22.6655L19.1237 22.6497H15.9932L18.1688 8.39152C18.1756 8.34834 18.1975 8.30826 18.2295 8.27975C18.2617 8.25124 18.3026 8.2356 18.3455 8.2356H23.6242C25.3767 8.2356 26.5861 8.61244 27.2174 9.35637C27.5134 9.70535 27.7019 10.0701 27.7932 10.4714C27.8889 10.8926 27.8905 11.3957 27.7972 12.0095L27.7904 12.0541V12.4474L28.0864 12.6208C28.3356 12.7575 28.5338 12.9139 28.6857 13.0929C28.9389 13.3914 29.1027 13.7707 29.1719 14.2202C29.2434 14.6826 29.2198 15.233 29.1027 15.856C28.9676 16.5725 28.7493 17.1967 28.4543 17.7073C28.1832 18.1778 27.8377 18.5682 27.4274 18.8706C27.0356 19.158 26.5703 19.3762 26.0441 19.5158C25.5342 19.653 24.9528 19.7222 24.3152 19.7222H23.9044C23.6108 19.7222 23.3254 19.8315 23.1014 20.0275C22.8768 20.2276 22.7283 20.501 22.6828 20.8L22.6517 20.974L22.1317 24.3796L22.1082 24.5046C22.1019 24.5442 22.0912 24.5639 22.0754 24.5773C22.0614 24.5895 22.0412 24.5976 22.0215 24.5976H19.485Z"
      fill="#28356A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3668 12.0996C28.3512 12.2037 28.3331 12.3101 28.3129 12.4194C27.6168 16.1136 25.2351 17.3898 22.1934 17.3898H20.6447C20.2727 17.3898 19.9591 17.6689 19.9013 18.0482L18.8838 24.7192C18.8461 24.9683 19.0318 25.1927 19.2749 25.1927H22.0218C22.347 25.1927 22.6233 24.9485 22.6745 24.6169L22.7015 24.4727L23.2187 21.0804L23.2519 20.8944C23.3025 20.5617 23.5795 20.3173 23.9047 20.3173H24.3155C26.9768 20.3173 29.0602 19.2006 29.6692 15.9688C29.9234 14.6188 29.7918 13.4915 29.1187 12.6987C28.915 12.4597 28.6624 12.2612 28.3668 12.0996Z"
      fill="#298FC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.6388 11.7995C27.5324 11.7674 27.4226 11.7384 27.3101 11.7122C27.1969 11.6866 27.0811 11.6639 26.9617 11.6441C26.5442 11.5743 26.0866 11.5413 25.5965 11.5413H21.4591C21.3571 11.5413 21.2603 11.565 21.1738 11.6081C20.9829 11.7029 20.8412 11.8896 20.8068 12.1182L19.9266 17.8802L19.9014 18.0482C19.9592 17.6689 20.2727 17.3898 20.6448 17.3898H22.1935C25.2352 17.3898 27.6168 16.113 28.313 12.4194C28.3338 12.3101 28.3513 12.2037 28.3669 12.0996C28.1908 12.003 28.0001 11.9204 27.7947 11.85C27.7439 11.8326 27.6916 11.8158 27.6388 11.7995Z"
      fill="#22284F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8069 12.1182C20.8412 11.8896 20.9829 11.7029 21.1738 11.6087C21.261 11.5656 21.3572 11.5418 21.4591 11.5418H25.5965C26.0866 11.5418 26.5442 11.575 26.9618 11.6447C27.0811 11.6645 27.197 11.6873 27.3101 11.7128C27.4227 11.7389 27.5324 11.7681 27.6388 11.8C27.6916 11.8163 27.7439 11.8332 27.7952 11.85C28.0006 11.9204 28.1915 12.0037 28.3675 12.0996C28.5747 10.7345 28.3658 9.80498 27.6517 8.9633C26.8644 8.03658 25.4435 7.64001 23.6252 7.64001H18.3464C17.975 7.64001 17.6581 7.91911 17.6008 8.29905L15.4021 22.7038C15.3588 22.9888 15.5714 23.2459 15.8495 23.2459H19.1084L20.8069 12.1182Z"
      fill="#28356A"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default PayPal;
