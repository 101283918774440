import { Snackbar } from '@material-ui/core';
import { Typography } from '@replai-platform/ui-components';
import styled from 'styled-components';

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  > [aria-hidden='true']:last-child {
    z-index: 101;
    display: none;
    width: auto;
  }
`;

export const CardHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: flex-start;
  justify-content: center;
`;

export const CardChart = styled.div`
  width: 100%;
  height: 300px;
  padding: 1rem;
`;

export const TypographyClean = styled(Typography)`
  margin: 0;
  padding: 0;
`;

export const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 4rem;
`;

export const CommonSnackbar = styled(Snackbar)`
  width: 50%;
  > * {
    width: 50%;
  }
`;
