import { CountryIconProps } from './types';

const BA = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3578)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9999 -0.000732422C7.74505 -0.000732422 4.0077 2.21378 1.87695 5.55297L18.15 22.3047C21.6536 20.2093 23.9999 16.3785 23.9999 11.9993C23.9999 5.37189 18.6273 -0.000732422 11.9999 -0.000732422Z"
        fill="#FFDA44"
      />
      <path
        d="M23.9996 11.9991C23.9996 8.8856 22.8137 6.04928 20.8691 3.91656V20.0817C22.8137 17.949 23.9996 15.1127 23.9996 11.9991Z"
        fill="#0052B4"
      />
      <path
        d="M14.9901 22.587L16.2471 22.5862L16.6348 21.3904L17.024 22.5857L17.6544 22.5853C18.041 22.3784 18.4149 22.1509 18.7747 21.9043L2.0948 5.22437C1.81992 5.62543 1.56923 6.04421 1.34381 6.47827L1.49203 6.93352L2.74898 6.93272L1.73255 7.67218L2.1217 8.86744L1.10433 8.12926L0.497531 8.57063C0.174047 9.65701 0 10.8077 0 11.9992C0 18.6265 5.37262 23.9992 12 23.9992C13.4012 23.9992 14.7461 23.7586 15.9961 23.317L14.9901 22.587ZM2.67652 11.4774L3.06417 10.2818L2.04689 9.54348L3.30384 9.54268L3.69155 8.34694L4.0807 9.54221L5.33766 9.54141L4.32117 10.2809L4.71033 11.4761L3.69295 10.7379L2.67652 11.4774ZM5.26519 14.0861L5.65284 12.8904L4.63556 12.1522L5.89252 12.1514L6.28022 10.9556L6.66937 12.1509L7.92633 12.1501L6.90989 12.8896L7.29905 14.0848L6.28167 13.3466L5.26519 14.0861ZM7.85381 16.6948L8.24147 15.4991L7.22419 14.7608L8.48114 14.7601L8.86884 13.5643L9.258 14.7596L10.515 14.7588L9.49851 15.4982L9.88767 16.6935L8.8703 15.9552L7.85381 16.6948ZM11.4589 18.5641L10.4424 19.3035L10.8301 18.1078L9.81281 17.3695L11.0698 17.3687L11.4575 16.173L11.8467 17.3683L13.1036 17.3675L12.0872 18.1069L12.4763 19.3022L11.4589 18.5641ZM13.0311 21.9122L13.4188 20.7165L12.4015 19.9782L13.6584 19.9774L14.0461 18.7817L14.4353 19.977L15.6922 19.9762L14.6758 20.7156L15.065 21.9109L14.0476 21.1726L13.0311 21.9122Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3578">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default BA;
