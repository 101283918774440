import { CommonTagIconProps } from '../types';

const ModerateVolumeIntro = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.274 6.22597L7.30701 9.64397C7.22601 9.71397 7.12301 9.75197 7.01701 9.75197H4.72601C4.48001 9.75197 4.28101 9.95097 4.28101 10.197V14.12C4.28101 14.366 4.48001 14.565 4.72601 14.565H7.38501C7.49201 14.565 7.59501 14.603 7.67501 14.673L11.274 17.774C11.562 18.022 12.009 17.818 12.009 17.437V6.56297C12.009 6.18197 11.562 5.97697 11.274 6.22597Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.205 9.92603C15.205 9.92603 17.043 11.647 15.205 13.838"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M18.157 7.96997C18.157 7.96997 21.672 11.323 18.157 15.75"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default ModerateVolumeIntro;
