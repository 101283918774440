import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Typography,
  Input,
  Button,
  Tooltip,
  Colors,
  Icons,
  LoadingBars,
  Modal,
  ModalHeader,
  ModalButtonsContainer,
  ModalButton,
} from '@replai-platform/ui-components';
import { useQueryClient } from 'react-query';
import type { RootState } from '../../../store/rootReducer';
import useGetExternalApiKey, { GET_EXTRENAL_API_KEY } from '../../../api/hooks/externalApi/useGetExternalApiKey';
import useUpdateExternalApiKey from '../../../api/hooks/externalApi/useUpdateExternalApiKey';
import { logEvent } from '../../../analytics';

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextContainer = styled.div`
  width: 320px;
  padding-right: 32px;
`;

const FullWidthContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 3;
  gap: 0.5rem;
  justify-content: center;
  padding-top: 1rem;
`;

const KeyDisplay = styled.div`
  display: flex;
  flex-direction: row;
  width: 425px;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
`;

const LeadingIcon = Icons.getBaseIcon('Copy');

const DEFAULT_KEY_VALUE = '••••••••••••••••••••••••••••••••••••••••••••••';

const logSettingsEvent = (action: string) =>
  logEvent({
    component: 'Settings',
    action,
    category: 'user_actions',
  });

const ExternalApiKeyManagement: React.VFC = () => {
  const queryClient = useQueryClient();

  const projectId = useSelector((state: RootState) => state.project.id);

  // Fetch or reset organization api keys
  const { isSuccess, data, isLoading } = useGetExternalApiKey({ projectIds: [projectId] });

  const mutateUpdateExternalApiKey = useUpdateExternalApiKey();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [isInputClicked, setIsInputClicked] = useState(false);

  // Key State display management
  const [isResetingApiKey, setIsResetingApiKey] = useState(false);
  const [keyValue, setKeyValue] = useState<string | null>(null);
  const [isShowingKey, setIsShowingKey] = useState(false);

  useEffect(() => {
    if (isInputClicked) {
      const timer = setTimeout(() => {
        setIsInputClicked(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [isInputClicked]);

  // Changing KeyValue according to new response
  useEffect(() => {
    if (isSuccess && data?.api_key && !isLoading) {
      setKeyValue(data.api_key);
    } else {
      setKeyValue(null);
    }
  }, [isSuccess, data, isLoading]);

  const showContent = () => {
    if (data && isSuccess) {
      return (
        <FullWidthContainer style={{ justifyContent: 'flex-end' }}>
          <Tooltip
            content={
              <TooltipContent>
                <LeadingIcon color={Colors.Gray[400]} dimension={16} />
                <Typography type="text-sm" color={Colors.Gray[500]} noMargin>
                  Copied to clipboard!
                </Typography>
              </TooltipContent>
            }
            placement="top"
            open={isInputClicked}
          >
            <KeyDisplay>
              <Input
                key="external-api-key-placeholder"
                onClick={() => {
                  if (!isInputClicked && keyValue !== null) {
                    logSettingsEvent('Copy API Key to Clipboard');
                    setIsInputClicked(true);
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    navigator.clipboard.writeText(keyValue ?? '');
                  }
                }}
                error={false}
                warning={false}
                fullWidth
                width="420px"
                leadingIcon="Key"
                readOnly
                value={isShowingKey ? keyValue ?? DEFAULT_KEY_VALUE : DEFAULT_KEY_VALUE}
                data-test="external-api-key-placeholder"
              />
            </KeyDisplay>
          </Tooltip>
          <Button
            variant="regular"
            onClick={() => {
              // Only display the API key if both getRequest is sucess AND is not already showing
              if (isSuccess && !isShowingKey) {
                logSettingsEvent('Show API Key');
                setIsShowingKey(true);
              } else {
                logSettingsEvent('Hide API Key');
                setIsShowingKey(false);
              }
            }}
            color={isShowingKey ? 'Gray' : 'Success'}
            leadingIcon={isShowingKey ? { name: 'EyeOff' } : { name: 'Eye' }}
            disabled={isResetingApiKey}
            data-test="retrieve-external-api-key-show-hide"
          >
            {isShowingKey ? 'Hide' : 'Show'}
          </Button>
          <Button
            variant="regular"
            onClick={() => {
              setIsConfirmationModalOpen(true);
            }}
            color="Error"
            leadingIcon={isResetingApiKey ? { name: 'LoadingCircle' } : { name: 'RefreshCw' }}
            disabled={isResetingApiKey}
            data-test="retrieve-external-api-key-reset"
          >
            Reset
          </Button>
        </FullWidthContainer>
      );
    }
    return (
      <FullWidthContainer>
        <Typography type="text-sm">
          The API key is not available for your organization. Please contact your customer success representative.
        </Typography>
      </FullWidthContainer>
    );
  };

  return (
    <MainContainer data-test="external-api-key-management-container">
      <Modal
        isOpen={isConfirmationModalOpen}
        onClose={() => {
          setIsConfirmationModalOpen(false);
        }}
        modalHeader={<ModalHeader title="Reset Key" subtitle="API Key for Organization" />}
        modalFooter={
          <ModalButtonsContainer>
            <ModalButton variation="secondary" onClick={() => setIsConfirmationModalOpen(false)}>
              Cancel
            </ModalButton>
            <ModalButton
              variation="primary"
              data-test="reset-api-key-button"
              onClick={() => {
                logSettingsEvent('Reset API Key');
                setIsResetingApiKey(true);
                mutateUpdateExternalApiKey.mutate(
                  { projectIds: [projectId] },
                  {
                    onSuccess: (res, req) => {
                      // log event
                      queryClient.setQueryData([GET_EXTRENAL_API_KEY, { projectIds: req.projectIds }], res);
                      setIsResetingApiKey(false);
                      setIsShowingKey(true);
                    },
                    onSettled: () => {
                      // Fix "Can't perform a React state update on an unmounted component" warning
                      // Learn more: https://github.com/TanStack/query/issues/3804
                      setIsResetingApiKey(false);
                    },
                  }
                );
                setIsConfirmationModalOpen(false);
              }}
            >
              Confirm
            </ModalButton>
          </ModalButtonsContainer>
        }
      >
        <Typography type="text-sm">You are going to reset your organization&#39;s API key.</Typography>
        <Typography type="text-sm">
          This might break any automated flows you or someone else in your organization has built using this API key.
        </Typography>
        <Typography fontWeight="semi-bold" type="text-sm">
          Are you sure you want to perform this action?
        </Typography>
      </Modal>
      <TextContainer>
        <Typography fontWeight="semi-bold">API Key</Typography>
        <Typography type="text-sm">
          Retrieve/Reset current organization&#39;s API key. Documentation available{' '}
          <a href="https://docs.replai.io/" target="_blank" rel="noreferrer">
            here
          </a>
          .
        </Typography>
      </TextContainer>
      {isLoading ? (
        <FullWidthContainer style={{ justifyContent: 'center' }}>
          <LoadingBars />
        </FullWidthContainer>
      ) : (
        showContent()
      )}
    </MainContainer>
  );
};

export default ExternalApiKeyManagement;
