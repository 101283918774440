import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getAssetsByTags = async (requestParams: SDK.GetTagAssetsByTagsRequest) => {
  const res = await provider.getJson<object, SDK.GetTagAssetsByTagsResponse>(
    `${SDK.BASE_URL()}/tags/getAssetsByTags?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useAssetsByTags<R = SDK.GetTagAssetsByTagsResponse>(
  requestParams: SDK.GetTagAssetsByTagsRequest,
  options?: UseQueryOptions<SDK.GetTagAssetsByTagsResponse, unknown, R>
) {
  return useQuery(['tag-assets', requestParams], () => getAssetsByTags(requestParams), options);
}
