import Button from './Button';
import Container from './Container';

const ButtonGroup = {
  Button,
  Container,
};

export default ButtonGroup;
export type { ButtonProps as ButtonGroupButtonProps } from '../Button/Button';
export type { ContainerProps as ButtonGroupContainerProps } from './Container';
