import React from 'react';

import styled from 'styled-components';
import '../common.module.css';
import CardHeader, { CardHeaderProps } from './CardHeader';
import Shadow from '../Shadow/Shadow';
import Colors from '../Colors';

const StyledShadow = styled(Shadow)<{
  $fullHeight?: boolean;
  $fullWidth?: boolean;
}>`
  max-width: 100%;
  max-height: 100%;
  ${(props) => props.$fullHeight && 'height: 100%;'};
  ${(props) => props.$fullWidth && 'width: 100%;'};
  background: ${Colors.Common.White};
  border-radius: 0.5rem;
`;

const CardContent = styled.div<{
  $disableContentPadding?: boolean;
  $fullHeight?: boolean;
  $fullWidth?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${(props) => props.$fullHeight && 'height: 100%;'};
  ${(props) => props.$fullWidth && 'width: 100%;'};
  ${(props) => !props.$disableContentPadding && 'padding: 16px;'};
`;

const Container = styled.div<{
  $fullHeight?: boolean;
  $fullWidth?: boolean;
  $disableBorder?: boolean;
  $overflowWrapAnywhere?: boolean;
}>`
  ${(props) => props.$overflowWrapAnywhere && 'overflow-wrap: anywhere;'};
  border-radius: 0.5rem;
  background: ${Colors.Common.White};
  ${(props) => props.$fullHeight && 'height: 100%;'};
  ${(props) => props.$fullWidth && 'width: 100%;'};
  ${(props) =>
    !props.$disableBorder && `border: 1px solid ${Colors.Gray[200]};`};
`;

export type CardProps = {
  disableShadow?: boolean;
  disableBorder?: boolean;
  children: React.ReactNode;
  header?: CardHeaderProps;
  fullHeight?: boolean;
  fullWidth?: boolean;
  disableContentPadding?: boolean;
  overflowWrapAnywhere?: boolean;
};

const Card: React.FC<CardProps> = ({
  header,
  children,
  disableShadow,
  disableBorder,
  fullHeight,
  fullWidth,
  disableContentPadding,
  overflowWrapAnywhere,
}) => {
  const cardCoreComponent = (
    <Container
      $fullHeight={fullHeight}
      $fullWidth={fullWidth}
      $disableBorder={disableBorder}
      $overflowWrapAnywhere={overflowWrapAnywhere}
    >
      {
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        header ? <CardHeader {...header} /> : null
      }
      <CardContent
        data-test="card-content"
        $disableContentPadding={disableContentPadding}
        $fullHeight={fullHeight}
        $fullWidth={fullWidth}
      >
        {children}
      </CardContent>
    </Container>
  );
  return disableShadow ? (
    cardCoreComponent
  ) : (
    <StyledShadow size="sm" $fullHeight={fullHeight} $fullWidth={fullWidth}>
      {cardCoreComponent}
    </StyledShadow>
  );
};

export default Card;
