import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import type { DropDownMenuOption } from '@replai-platform/ui-components';
import * as SDK from '@replai-platform/sdk';
import { RootState } from '../../../store/rootReducer';

const useKpiFilter = ({
  blacklistKpis = [],
}: { blacklistKpis?: SDK.MetricKPIWithSpend[] } = {}): DropDownMenuOption[] => {
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const selectedKpis = useSelector((state: RootState) => state.filters.kpis);
  const projectKpisFiltered = projectKpis.filter((kpi) => !blacklistKpis.includes(kpi));
  const selectedKpisFiltered = selectedKpis.filter((kpi) => !blacklistKpis.includes(kpi));

  // The default state of the filter is "ALL" selected. The problem is that the "ALL" option is not
  // stored in the redux store, so we dont have an easy way to know whether to select it or not. We
  // use this ref to identify the first onChange, and until the ref value changes, we can assume
  // that we want the "ALL" option to be selected (assuming that there are no networks in the store,
  // in which case that takes precedence).
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, []);

  // Create filter options
  const kpiFilterOptions = useMemo(
    () => [
      {
        label: 'All KPIs',
        isAllOption: true,
        selected:
          projectKpisFiltered.length === selectedKpisFiltered.length ||
          (firstRender.current === true && selectedKpisFiltered.length === 0),
      },
      ...projectKpisFiltered.map((projectKpi) => ({
        id: projectKpi,
        label: SDK.kpiUtils.getDisplayName(projectKpi).toUpperCase(),
        selected:
          selectedKpisFiltered.some((kpi) => kpi === projectKpi) ||
          (firstRender.current === true && selectedKpisFiltered.length === 0),
      })),
    ],
    [JSON.stringify(projectKpisFiltered), JSON.stringify(selectedKpisFiltered)]
  );

  return kpiFilterOptions;
};

export default useKpiFilter;
