import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';
import { TwoColumnLayout } from '../shared/TwoColumnLayout';

const SINGULAR_FORM_URL = 'https://docs.google.com/forms/d/1Vk5bA4ieaI1qxIJ8oQdPOy6QB2qgzxDsyLHb-2AWgl0/viewform';

export const SingularConnectionGuide: NetworkConnectionGuideContent = {
  title: 'Singular Connection Guide',
  icon: 'Singular',
  guide: {
    label: 'Connection Guide',
    steps: [
      {
        title: 'Share your Singular API key',
        description: '',
        content: (
          <TwoColumnLayout
            contentItems={[
              { item: 'Log in to the Singular platform' },
              {
                item: (
                  <>
                    On the right menu, click on: <Shared.BoldText>Account-&gt;Keys</Shared.BoldText>
                  </>
                ),
              },
              {
                item: (
                  <>
                    Head over to the <Shared.HighlightText>Settings - API</Shared.HighlightText> page.
                  </>
                ),
              },
              {
                item: (
                  <>
                    Copy the API key and share it with Replai using
                    {Shared.link({
                      label: 'this form',
                      url: SINGULAR_FORM_URL,
                    })}
                    .
                  </>
                ),
              },
            ]}
            imageSrc="https://cdn.replai.io/assets/integration-guide/singular/step-1.png"
          />
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: Shared.DEFAULT_MMP_FAQS,
  },
};
