import { CountryIconProps } from './types';

const IL = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M16.5173 9.39207H13.505L11.9989 6.78339L10.4928 9.39207H7.48047L8.98675 12.0008L7.48047 14.6094H10.4928L11.9989 17.2181L13.505 14.6094H16.5173L15.011 12.0008L16.5173 9.39207ZM13.8493 12.0008L12.9242 13.6033H11.0738L10.1485 12.0008L11.0737 10.3982H12.9241L13.8493 12.0008ZM11.9989 8.79559L12.3432 9.39203H11.6546L11.9989 8.79559ZM9.22324 10.3982H9.91192L9.56758 10.9946L9.22324 10.3982ZM9.22324 13.6033L9.56763 13.0069L9.91197 13.6033H9.22324ZM11.9989 15.2059L11.6546 14.6094H12.3432L11.9989 15.2059ZM14.7746 13.6033H14.0859L14.4303 13.0069L14.7746 13.6033ZM14.0859 10.3982H14.7746L14.4302 10.9946L14.0859 10.3982Z"
      fill="#0052B4"
    />
    <path
      d="M19.4693 2.60907H4.5296C3.43446 3.48132 2.49527 4.54079 1.76074 5.73952H22.2382C21.5036 4.54084 20.5644 3.48132 19.4693 2.60907Z"
      fill="#0052B4"
    />
    <path
      d="M4.5296 21.3914H19.4693C20.5644 20.5191 21.5036 19.4597 22.2381 18.2609H1.76074C2.49532 19.4596 3.43451 20.5191 4.5296 21.3914Z"
      fill="#0052B4"
    />
  </svg>
);
export default IL;
