import { createSelector } from '@reduxjs/toolkit';
import * as SDK from '@replai-platform/sdk';
import { EmptyState, InsightMetric } from '@replai-platform/ui-components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import TagPreviewVideosWrapper from '../../../components/TagPreviewVideosWrapper';
import { getFilteredInsights, SpendAndFrequencyBadgeValues, TagInsight } from '../../../store/insights';
import { RootState } from '../../../store/rootReducer';
import { Page } from '../../../utils/enums';
import { getInsightMetricProps } from '../utils';
import * as Styled from './styles';

type Props = {
  setSelectedTagType: (type: string) => void;
  type?: string;
  kind?: SDK.TagKind;
  isLoading?: boolean;
};

const renderInsightsSkeleton = () => (
  <Styled.InsightsContainer>
    {Array.from(Array(10), (_, i) => (
      <InsightMetric key={`insight-ske-${i}`} skeletonOnly wrapped />
    ))}
  </Styled.InsightsContainer>
);

const CustomInsightMetric = ({
  insight,
  setCurrentTag,
  setTagPreviewOpen,
}: {
  insight: TagInsight & SpendAndFrequencyBadgeValues;
  setCurrentTag: React.Dispatch<React.SetStateAction<SDK.Tag>>;
  setTagPreviewOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const tagPreviews = useSelector((state: RootState) => state.insights.tagsPreviewData);
  const isTagPreviewsLoading = useSelector((state: RootState) => state.insights.tagsPreviewLoading);

  const projectNetworks = useSelector((state: RootState) => state.project.networks);
  return (
    <InsightMetric
      key={`insight-age-${insight.id.toString()}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getInsightMetricProps({
        insight,
        projectNetworks: projectNetworks ?? [],
        thumbnailUrl: tagPreviews?.tags?.find((tagPreview) => tagPreview.tagId === insight.content?.tag?.id)
          ?.thumbnails?.[0]?.imageUrl,
        thumbnailLoading: isTagPreviewsLoading,
        onClick: () => {
          logEvent({
            component: 'Insights',
            action: 'Click Insight',
            category: 'insights',
            parameters: { tag: insight?.content?.tag ?? {} },
          });
          setCurrentTag(insight?.content?.tag);
          setTagPreviewOpen(true);
        },
        showCoreTagThumbnails: true,
        wrapped: true,
      })}
    />
  );
};

const InsightsExplorer = ({ kind, type, isLoading, setSelectedTagType }: Props) => {
  const [currentTag, setCurrentTag] = useState({} as SDK.Tag);
  const [tagPreviewOpen, setTagPreviewOpen] = useState(false);

  const selectInsights = (state: RootState) => state.insights.insights;
  const selectFilteredInsights = createSelector(selectInsights, (insights) =>
    insights ? getFilteredInsights(insights, type, kind) : undefined
  );
  const insights = useSelector(selectFilteredInsights);

  useEffect(() => {
    // if there are no insights for a given tag type, the selected tag type resets to 'All'
    if (!insights?.length && !isLoading) {
      setSelectedTagType('');
    }
  }, [insights, isLoading, setSelectedTagType]);

  const renderAgeInsights = () => (
    <Styled.InsightsContainer>
      {(insights || []).map((insight) => (
        <CustomInsightMetric insight={insight} setCurrentTag={setCurrentTag} setTagPreviewOpen={setTagPreviewOpen} />
      ))}
    </Styled.InsightsContainer>
  );

  return (
    <Styled.RootContainer>
      {isLoading || !insights ? (
        renderInsightsSkeleton()
      ) : (
        <>
          {insights?.length ? renderAgeInsights() : <EmptyState icon="Search" text="No insights available" />}
          <TagPreviewVideosWrapper
            isOpen={tagPreviewOpen}
            tag={currentTag}
            page={Page.Insights}
            component="Insights"
            onClose={() => {
              setTagPreviewOpen(false);
              logEvent({
                component: 'Insights',
                action: 'Close Tag Preview',
                category: 'insights',
              });
            }}
          />
        </>
      )}
    </Styled.RootContainer>
  );
};

export default InsightsExplorer;
