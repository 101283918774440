import { Colors, Typography } from '@replai-platform/ui-components';
import styled from 'styled-components';

export const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 8rem;
`;

export const MainContainer = styled.div`
  display: grid;
  grid-column-gap: 1.5rem;
  grid-template-rows: auto auto;
  grid-template-columns: 320px minmax(0, 1fr);
  margin-top: 1.5rem;
  row-gap: 2rem;
`;

export const PreviewContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  height: fit-content;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1.25rem;
  margin-bottom: 1.5625rem;
  background-color: ${Colors.Gray[200]};
`;

export const TypographyClean = styled(Typography)`
  margin: 0;
  padding: 0;
`;

export const CardContainer = styled.div`
  margin-bottom: 16px;
`;

export const MetricsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 16px;
`;

export const MetricsCardWrapper = styled.div`
  flex: 2;
  min-width: 400px;
`;

export const MetricsCardWrapperSmall = styled.div`
  flex: 1;
  min-width: 400px;
`;

export const MetricsCard = styled.div`
  height: 100%;
  padding: 24px;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  > [aria-hidden='true']:last-child {
    z-index: 101;
    display: none;
    width: auto;
  }
`;

export const CardHeaderRight = styled.div`
  z-index: 10;
  width: auto;
`;

export const CardChart = styled.div`
  width: 100%;
  height: 300px;
`;

export const LeftMetricsContainer = styled.div`
  padding-top: 1rem;
`;
