/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as SDK from '@replai-platform/sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BarChart, LoadingBars } from '@replai-platform/ui-components';
import { kpiUtils, Metrics } from '@replai-platform/sdk';
import { useQuery } from 'react-query';
import { LoadingContainer } from '../../../../../../utils/styles';
import { RootState } from '../../../../../../store/rootReducer';
import { getMetricsHistoryEntries } from '../../../../utils';

interface ImpressionClicksChartProps {
  conceptId: SDK.UUID;
}

const ImpressionClicksChart = ({ conceptId }: ImpressionClicksChartProps) => {
  const filters = useSelector((state: RootState) => state.filters);
  const projectId = useSelector((state: RootState) => state.project.id);

  const bars = [
    {
      name: kpiUtils.getDisplayName(Metrics.CLICKS),
      accessor: 'clicks',
    },
    {
      name: kpiUtils.getDisplayName(Metrics.IMPRESSIONS),
      accessor: 'impressions',
    },
  ];

  const getConceptImpressionClicksParams = useMemo(
    () => ({
      projectId,
      filters,
      conceptId,
      metrics: [Metrics.CLICKS, Metrics.IMPRESSIONS],
    }),
    [JSON.stringify(filters), conceptId, projectId]
  );
  const { data, isLoading } = useQuery([getConceptImpressionClicksParams], () =>
    getMetricsHistoryEntries(getConceptImpressionClicksParams)
  );

  // aggregates all clicks and impressions from the same month
  // to show a per month bar in the graph
  const monthlyData = useMemo(() => {
    const newData: {
      name: string;
      clicks: number;
      impressions: number;
    }[] = [];
    let current: typeof newData[number] = { name: '', clicks: 0, impressions: 0 };

    (data || []).forEach((value) => {
      if (!current.name) {
        current = { name: value.name, clicks: value.clicks || 0, impressions: value.impressions || 0 };
        return;
      }

      const currentMonth = new Date(current.name).getMonth();
      if (currentMonth !== new Date(value.name).getMonth()) {
        newData.push(current);
        current = { name: value.name, clicks: value.clicks || 0, impressions: value.impressions || 0 };
        return;
      }

      current.clicks += value.clicks || 0;
      current.impressions += value.impressions || 0;
    });

    if (current.name !== '') newData.push(current);
    return newData;
  }, [JSON.stringify(data)]);

  if (isLoading || !data) {
    return (
      <LoadingContainer>
        <LoadingBars barSize="xxl" />
      </LoadingContainer>
    );
  }

  return (
    <BarChart
      data-test="chart"
      data={monthlyData}
      bars={bars}
      yValueFormatter={(v) => kpiUtils.format(Metrics.IMPRESSIONS, v)}
      xValueFormatter={(s) =>
        new Date(s).toLocaleDateString('en-US', {
          month: 'long',
        })
      }
    />
  );
};

export default ImpressionClicksChart;
