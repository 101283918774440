import { IconProps } from './types';

const DragIndicator = ({ color, dimension }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
  >
    <path
      fill={color || '#101828'}
      d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
    />
  </svg>
);

export default DragIndicator;
