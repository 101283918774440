import { IntegrationIconProps } from './types';

const Dell = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0109 12.5602C14.911 12.4947 14.811 12.4291 14.7115 12.363C14.292 12.6326 13.8737 12.9033 13.4553 13.1741C12.6684 13.6835 11.8815 14.1929 11.0862 14.694C10.7993 13.8245 10.1809 13.1263 9.30857 12.8545C8.69315 12.6628 7.92989 12.6878 7.14065 12.7137C6.66474 12.7293 6.17939 12.7452 5.7113 12.7141V18.5695C6.1905 18.5473 6.6666 18.5608 7.12793 18.5739C7.62483 18.5881 8.10461 18.6017 8.55271 18.5695C9.93829 18.4699 10.7757 17.6265 11.1283 16.5054C11.722 16.8938 12.3056 17.2923 12.8893 17.6909C13.5022 18.1093 14.1151 18.5278 14.7395 18.9346C15.1408 18.6778 15.5447 18.4147 15.9491 18.1513C16.7245 17.6463 17.5019 17.14 18.2668 16.6738V18.5695H22.2281V16.7019C21.9938 16.6984 21.754 16.7003 21.5143 16.7021C21.1239 16.7052 20.7335 16.7082 20.3664 16.688V12.7141H18.2668V14.7081C18.007 14.8743 17.7479 15.0411 17.4888 15.2079C16.7959 15.6541 16.103 16.1002 15.3974 16.5335C15.3421 16.4973 15.2845 16.4635 15.2269 16.4297C15.1255 16.3703 15.0242 16.3108 14.9356 16.2386C15.3325 15.9812 15.7253 15.7196 16.1181 15.4581C16.6536 15.1016 17.189 14.7451 17.735 14.3991C17.344 14.1033 16.9269 13.8337 16.5032 13.5706C15.6316 14.1302 14.7565 14.6863 13.8815 15.2424C13.8083 15.2889 13.735 15.3355 13.6618 15.382C13.5904 15.3272 13.514 15.2773 13.4377 15.2275C13.3615 15.1777 13.2852 15.1278 13.2139 15.0731C13.6049 14.8232 13.9935 14.571 14.3822 14.3187C14.9285 13.9643 15.4747 13.6098 16.0273 13.2617C15.7025 13.0138 15.3567 12.787 15.0109 12.5602ZM7.71284 16.702V14.5957C7.71164 14.5758 7.71061 14.5559 7.72679 14.5536C8.33297 14.5146 8.7698 14.5774 9.02854 14.8765C9.41624 15.3248 9.32776 16.1661 8.88858 16.5054C8.62235 16.7112 8.28681 16.7077 7.85663 16.7032C7.80983 16.7027 7.76191 16.7022 7.71284 16.702Z"
      fill="#0083CA"
    />
    <path
      d="M24.8734 12.7141H22.7739C22.7576 12.7164 22.7587 12.7361 22.7598 12.756L22.7598 18.5696H26.7211V16.702H24.8734V12.7141Z"
      fill="#0083CA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.433 2H15.5652C11.372 2.23159 8.33109 3.85109 6.10317 6.08629C3.88544 8.31113 2.22701 11.3142 2.01596 15.5507C2.01831 15.567 2.01294 15.5757 2.00195 15.5788V16.4213C2.22236 20.674 3.8718 23.6461 6.10311 25.8997C8.33441 28.1533 11.3446 29.7607 15.5652 29.9999H16.419C20.7236 29.7875 23.5754 28.1177 25.8952 25.9138C28.1035 23.5936 29.782 20.7419 29.9963 16.4213V15.5788C29.7759 11.3261 28.1265 8.35388 25.8952 6.10033C23.6638 3.84679 20.6542 2.2388 16.433 2ZM10.5683 4.85053C11.2443 4.51516 11.9901 4.23144 12.8218 4.00803C13.6597 3.78291 14.578 3.66134 15.5514 3.61484C17.5315 3.52026 19.3802 3.9611 20.8004 4.55567C22.2641 5.16846 23.5061 6.0163 24.5376 7.01301C26.6223 9.0274 28.2019 11.6689 28.3868 15.5647C28.4845 17.6212 28.0326 19.3189 27.435 20.7884C26.2533 23.6935 24.1214 25.7989 21.4161 27.1635C20.0358 27.8597 18.3698 28.2933 16.4471 28.3852C14.4427 28.4809 12.6627 28.0536 11.2262 27.4444C8.32119 26.2124 6.22212 24.1854 4.84336 21.4343C4.1452 20.041 3.70324 18.4065 3.61168 16.4634C3.46647 13.3812 4.46918 11.0308 5.62723 9.20359C6.8137 7.33167 8.51058 5.87129 10.5683 4.85053Z"
      fill="#0083CA"
    />
  </svg>
);
export default Dell;
