/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import { kpiUtils } from '@replai-platform/sdk';
import { ChartValue, LineChart, LoadingBars } from '@replai-platform/ui-components';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { api } from '../../api';
import { RootState } from '../../store/rootReducer';
import { FiltersState } from '../../store/filters';

const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 5rem;
  margin-top: 1.5rem;
`;

async function getMetricsHistoryEntries({
  projectId,
  filters,
  metrics,
  conceptId,
  assetId,
}: {
  projectId: string;
  filters: FiltersState;
  metrics: SDK.MetricOrKpi[];
  conceptId?: SDK.UUID;
  assetId?: SDK.UUID;
}): Promise<
  {
    name: string;
    globalMetrics: Record<string, any>;
    assetMetrics: Record<string, any>;
    [key: string]: any;
  }[]
> {
  const [globalHistory, assetHistory] = await Promise.all([
    api.metrics.getHistoryMetrics({
      projectIds: [projectId],
      metrics,
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
    }),
    api.metrics.getHistoryMetrics({
      projectIds: [projectId],
      metrics,
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      conceptId,
      assetId,
    }),
  ]);

  return globalHistory.history.map((entry) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const copyObjectExcludingDate = (object) => (({ date, ...o }) => o)(object);
    let assetMetrics = assetHistory.history.find((e) => e.date === entry.date);
    if (assetMetrics) {
      assetMetrics = copyObjectExcludingDate(assetMetrics);
    }

    const historyEntry = {
      name: entry.date,
      globalMetrics: entry?.metrics,
      assetMetrics: assetMetrics?.metrics ?? {},
    };
    metrics.forEach((metric) => {
      historyEntry[metric] = assetMetrics?.metrics[metric];
    });
    return historyEntry;
  });
}

const CreativeFatigueTrend: React.VFC<{
  assetId: SDK.UUID;
  selectedKpi: SDK.MetricKPIWithSpend;
}> = ({ assetId, selectedKpi }) => {
  const filters = useSelector((state: RootState) => state.filters);
  const projectId = useSelector((state: RootState) => state.project.id);
  const selectedKpiDisplayName = kpiUtils.getDisplayName(selectedKpi);
  const lines = [
    {
      name: `${
        selectedKpi === SDK.Metrics.SPEND
          ? 'total'
          : ([SDK.KPI.AVG_ASSET_SPEND, SDK.KPI.AVG_CONCEPT_SPEND] as SDK.MetricKPIWithSpend[]).includes(selectedKpi)
          ? 'project'
          : SDK.kpiConfig[selectedKpi]?.isSummable
          ? 'total'
          : 'average'
      } ${selectedKpiDisplayName}`,
      accessor: `global${selectedKpi}`,
    },
    {
      name: `creative ${selectedKpiDisplayName}`,
      accessor: `asset${selectedKpi}`,
    },
  ];

  const getHistoryParams = useMemo(
    () => ({
      projectId,
      filters: {
        ...filters,
        assetTypes: [], // No need to filter for asset type since we fetch the asset ID.
      },
      assetId,
      metrics: [selectedKpi],
    }),
    [JSON.stringify(filters), assetId, projectId, selectedKpi]
  );
  const { data, isLoading } = useQuery([getHistoryParams], () => getMetricsHistoryEntries(getHistoryParams));

  const chartData = useMemo(
    () =>
      data?.map(
        (entry) =>
          ({
            name: entry.name,
            [`asset${selectedKpi}`]: entry.assetMetrics?.[selectedKpi],
            [`global${selectedKpi}`]: entry.globalMetrics?.[selectedKpi],
          } as ChartValue)
      ),
    [JSON.stringify(data)]
  );

  if (isLoading || !data) {
    return (
      <LoadingContainer>
        <LoadingBars barSize="xxl" />
      </LoadingContainer>
    );
  }

  return (
    <LineChart
      data-test="chart"
      data={chartData}
      yValueFormatter={(v) => kpiUtils.format(selectedKpi, v)}
      xValueFormatter={(s) =>
        new Date(s).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
        })
      }
      lines={lines}
      yLabel={kpiUtils.getDisplayName(selectedKpi)}
    />
  );
};

export default CreativeFatigueTrend;
