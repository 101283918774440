import { CountryIconProps } from './types';

const ER = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3616)">
      <path
        d="M10.9565 10.9565C10.9565 10.9565 3.52168 20.487 3.51465 20.4853C5.68618 22.6569 8.68623 24 12 24C18.6273 24 24 18.6274 24 12L10.9565 10.9565Z"
        fill="#338AF3"
      />
      <path
        d="M10.9565 11.9996C10.9565 11.9996 3.52168 3.51268 3.51465 3.51437C5.68618 1.34279 8.68623 -0.000366211 12 -0.000366211C18.6273 -0.000366211 24 5.37226 24 11.9996H10.9565Z"
        fill="#6DA544"
      />
      <path
        d="M3.51373 3.5155C-1.17255 8.20178 -1.17255 15.7998 3.51373 20.4861C3.51341 20.4881 23.999 12.0008 23.999 12.0008L3.51373 3.5155Z"
        fill="#D80027"
      />
      <path
        d="M6.26081 7.04321C3.95929 7.04321 2.08691 8.91563 2.08691 11.2171V12.7823C2.08691 15.0838 3.95934 16.9562 6.26081 16.9562C8.56227 16.9562 10.4347 15.0838 10.4347 12.7823V11.2171C10.4347 8.91563 8.56232 7.04321 6.26081 7.04321ZM8.86949 12.7824C8.86949 13.9482 8.10065 14.9377 7.04338 15.2709V13.565L8.15076 12.4576L7.04399 11.3508L7.04338 10.9569V10.4346H5.47823V11.9997L4.3716 13.1063L5.47823 14.2129V15.271C4.42096 14.9378 3.65212 13.9483 3.65212 12.7824V11.2172C3.65212 9.77874 4.8224 8.60851 6.26081 8.60851C7.69921 8.60851 8.86949 9.77879 8.86949 11.2172V12.7824Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3616">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ER;
