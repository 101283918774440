import { Card, CellLink, EmptyStateProps, MessageDelay, TableWithPagination } from '@replai-platform/ui-components';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, SortingRule } from 'react-table';
import { RouteAnimator } from '../../routes/RouteAnimator';
import { RootState } from '../../store/rootReducer';
import { Columns } from '../../utils/enums';
import generateColumn from '../columns/generateColumnDefaults';
import * as Styled from './styles';

interface TableProps {
  tableData: object[];
  columns: string[];
  rowsTotal: number;
  rowsPerPage: number;
  emptyStateProps: EmptyStateProps;
  pageIndex?: number;
  onPageUpdate?: (args: { pageIndex: number; pageSize: number }) => any;
  onSort?: (sortBy: SortingRule<object>[]) => any;
  sorting?: SortingRule<object>[];
  loading?: {
    messages: string[];
    firstMessageDelay?: MessageDelay;
  };
  includeFooter?: boolean;
  includeCard?: boolean;
  isTagData?: boolean;
  onRowProps?: (row: Row<any>) => object;
  cellLink?: CellLink<any>;
  generateRowHref?: (row: any) => string;
}

const Table: React.FC<TableProps> = ({
  tableData,
  columns,
  rowsTotal,
  rowsPerPage,
  emptyStateProps,
  pageIndex,
  onPageUpdate,
  onSort,
  sorting,
  loading,
  includeFooter = false,
  includeCard = true,
  isTagData = false,
  onRowProps,
  cellLink,
  generateRowHref,
}) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const filters = useSelector((state: RootState) => state.filters);
  const projectNetworks = useSelector((state: RootState) => state.project.networks);

  const tableColumns = useMemo(
    () =>
      columns.map((column) =>
        generateColumn({
          column: column as Columns,
          projectId,
          filters,
          includeFooter,
          isTagData,
          generateRowHref,
          networks: projectNetworks ?? [],
        })
      ),
    [columns, projectId, filters, includeFooter, isTagData, generateRowHref, projectNetworks]
  );

  const tableElement = (
    <Styled.TableContainer>
      <TableWithPagination
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        cellLink={cellLink}
        pageIndex={pageIndex}
        onPageUpdate={onPageUpdate}
        onSort={onSort}
        data={tableData || []}
        columns={tableColumns || []}
        rowsPerPage={rowsPerPage}
        rowsTotal={rowsTotal}
        rowsTotalLoading={tableData === undefined}
        sorting={sorting}
        emptyStateProps={emptyStateProps}
        loading={loading}
        disableAutoExpanderColumn
        onRowProps={onRowProps}
      />
    </Styled.TableContainer>
  );

  return (
    <RouteAnimator data-test="team-container">
      {includeCard ? (
        <Card fullWidth disableContentPadding>
          {tableElement}
        </Card>
      ) : (
        tableElement
      )}
    </RouteAnimator>
  );
};

export default Table;
