import { CountryIconProps } from './types';

const JE = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M21.5209 19.3071L14.2143 12.0006H14.2142L21.5209 4.69382C21.2058 4.28432 20.861 3.89033 20.486 3.51519C20.1109 3.1401 19.7169 2.79533 19.3074 2.48029L12.0007 9.78693L12.0007 9.78697L4.69405 2.48029C4.28459 2.79538 3.89056 3.14005 3.51542 3.51519C3.14028 3.89033 2.79556 4.28432 2.48052 4.69382L9.78716 12.0004L9.7872 12.0005L2.48047 19.3072C2.79561 19.7167 3.14033 20.1107 3.51538 20.4858C3.89052 20.8609 4.28445 21.2057 4.694 21.5207L12.0007 14.2141L12.0007 14.214L19.3074 21.5207C19.7168 21.2056 20.1109 20.8609 20.486 20.4858C20.8611 20.1106 21.2058 19.7166 21.5209 19.3071Z"
      fill="#D80027"
    />
    <path
      d="M9.91309 3.65288L12.0001 4.1746L14.087 3.65288V1.87899L13.2522 2.29636L12.0001 1.04419L10.7479 2.29636L9.91309 1.87899V3.65288Z"
      fill="#FFDA44"
    />
    <path
      d="M9.91309 3.65289V4.95733C9.91309 6.55483 12.0001 7.0443 12.0001 7.0443C12.0001 7.0443 14.087 6.55478 14.087 4.95733V3.65289H9.91309Z"
      fill="#D80027"
    />
  </svg>
);
export default JE;
