/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSelector } from '@reduxjs/toolkit';
import * as SDK from '@replai-platform/sdk';
import { messages } from '@replai-platform/sdk';
import { SectionHeader, VideoCardProps, VideoCardsCarousel } from '@replai-platform/ui-components';
import { useMemo } from 'react';
import { useQueries } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useFeature } from '@optimizely/react-sdk';
import { logEvent } from '../../analytics';
import { api } from '../../api';
import useConceptMetrics from '../../api/hooks/concepts/useConceptMetrics';
import { getConceptPreviewInfoQueryOptions } from '../../api/hooks/concepts/useConceptPreviewInfo';
import useConceptRecommendations from '../../api/hooks/recommendations/useConceptRecommendations';
import { DashboardActions } from '../../store/dashboard';
import { initialState } from '../../store/filters';
import { RootState } from '../../store/rootReducer';
import { isCreativeActive } from '../../utils';
import { ActivityType, Page } from '../../utils/enums';
import { formatDateDistance } from '../../utils/formatDateDistance';
import { getRecommendationRequestParams } from '../../utils/recommendationUtils';
import { getNetworksString } from './utils';
import AssetVariationButton from '../../components/AssetVariationButton';

const Container = styled.div`
  margin-top: 2rem;
`;

const VideosContainer = styled.div`
  margin-top: 1rem;
`;

const MAX_NUMBER_OF_VIDEOS_ON_LANE = 5;

// This function makes sure that the recommendations that appear in the Top Videos badge match the ones seen in the concept Timeline
const filterRecommendationsToMatchTimelineRecommendations = (
  recommendations: SDK.CreativeRecommendation[] | undefined
) => {
  const filteredConceptRecommendations: SDK.CreativeRecommendation[] = [];

  recommendations?.forEach((rec) => {
    switch (rec.name.type) {
      case SDK.RecommendationType.CREATIVE_ADD_TAG:
      case SDK.RecommendationType.CREATIVE_ADD_TAG_INTRO:
      case SDK.RecommendationType.CREATIVE_REMOVE_TAG:
      case SDK.RecommendationType.CREATIVE_REMOVE_TAG_INTRO:
        // Recommendations with 'None' or 'No' values are filtered in the timeline
        if (rec.name.tag.value?.startsWith('None') || rec.name.tag.value?.startsWith('No')) {
          break;
        }

        filteredConceptRecommendations.push(rec);
        break;
      case SDK.RecommendationType.CREATIVE_REPLACE_TAG:
      case SDK.RecommendationType.CREATIVE_REPLACE_TAG_INTRO:
        // Recommendations with 'None' or 'No' values are filtered in the timeline
        if (rec.name.previous.value?.startsWith('None') || rec.name.previous.value?.startsWith('No')) {
          break;
        }

        // If there are 2 or more REPLACE recommendations for the same type and value, only one will show up in the timeline
        if (
          filteredConceptRecommendations
            .map((r) => (r?.name as SDK.RecommendedCreativeReplaceTagContent)?.previous?.type)
            ?.includes(rec.name?.previous?.type) &&
          filteredConceptRecommendations
            .map((r) => (r?.name as SDK.RecommendedCreativeReplaceTagContent)?.previous?.value)
            ?.includes(rec.name?.previous?.value)
        ) {
          break;
        }

        filteredConceptRecommendations.push(rec);
        break;
      default:
        filteredConceptRecommendations.push(rec);
        break;
    }
  });

  return filteredConceptRecommendations;
};

const TopVideos = ({ filtersLoading }: { filtersLoading?: boolean }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projectId = useSelector((state: RootState) => state.project.id);
  const accountTypes = useSelector((state: RootState) => state.project.config.accountTypes);
  const keyMetric = useSelector((state: RootState) => state.project.baseMetric);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const projectNetworks = useSelector((state: RootState) => state.project.networks)!;
  const networksFilter = useSelector((state: RootState) => state.filters.networks);
  const startDate = useSelector((state: RootState) => state.filters.startDate);
  const endDate = useSelector((state: RootState) => state.filters.endDate);
  const shouldRefreshConceptsCache = useSelector((state: RootState) => state.dashboard.shouldRefreshConceptsCache);
  const [isTechMerc1388Enabled] = useFeature('techmerc-1388'); // Creative Production
  const excludedTagTypes = useSelector(
    createSelector(
      (state: RootState) => state.project.config.tagsTypes?.excluded,
      (value) => value ?? []
    )
  );
  const dashboardMinRecommendationScore =
    useSelector((state: RootState) => state.project.config.minRecommendationScore) ?? 0.39;
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const filters = useMemo(
    () => ({ ...initialState, networks: networksFilter, activity: ActivityType.all, startDate, endDate }),
    [endDate, networksFilter, startDate]
  );
  const withBreakdowns = filters.networks?.length !== 0 && filters.networks?.length !== projectNetworks?.length;

  const conceptsMetricsParams = useMemo<SDK.GetAssetsMetricsRequest & { refreshCache: boolean }>(
    () => ({
      projectIds: [projectId],
      metrics: [SDK.Metrics.SPEND, SDK.Metrics.IMPRESSIONS, SDK.Metrics.VIEWS, SDK.Metrics.AGE],
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters({ ...filters, assetTypes: [SDK.AssetType.Video] }),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      includeTotalsAndAvg: true,
      orderBy: {
        condition: SDK.OrderByCondition.DESC_NULLS_LAST,
        value: keyMetric,
      },
      maxRecords: MAX_NUMBER_OF_VIDEOS_ON_LANE,
      refreshCache: !!shouldRefreshConceptsCache,
    }),
    [projectId, filters, shouldRefreshConceptsCache, keyMetric]
  );
  const { isLoading: conceptMetricsLoading, data: conceptMetrics } = useConceptMetrics(conceptsMetricsParams, {
    enabled: !filtersLoading,
  });

  const getThumbnailRequestParams = (conceptId: string) => ({ projectId, conceptId, size: '200x200' });

  const thumbnailsQueries = useQueries(
    conceptMetrics
      ? conceptMetrics?.concepts?.map((concept) =>
          getConceptPreviewInfoQueryOptions(getThumbnailRequestParams(concept.id), { enabled: !conceptMetricsLoading })
        )
      : []
  );
  const recommendationParams = useMemo(
    () => ({
      ...getRecommendationRequestParams({
        projectId,
        tagTypesToExclude: excludedTagTypes.map((type) => ({ type })),
        minRecommendationScore: dashboardMinRecommendationScore,
        networks: filters.networks,
        promotedObjectTypes: [],
        targets: projectKpis,
        withBreakdowns,
        objectIds: conceptMetrics?.concepts?.map((c) => c.id),
        countOnly: false,
      }),
      refreshCache: shouldRefreshConceptsCache,
    }),
    [
      dashboardMinRecommendationScore,
      excludedTagTypes,
      filters.networks,
      projectId,
      projectKpis,
      shouldRefreshConceptsCache,
      withBreakdowns,
      conceptMetrics,
    ]
  );
  const { isLoading: isRecommendationsDataLoading, data: { recommendations } = {} } = useConceptRecommendations(
    recommendationParams,
    {
      enabled: !filtersLoading && !conceptMetricsLoading && !!conceptMetrics?.concepts?.length,
      onSuccess: () => {
        dispatch(DashboardActions.changeShouldRefreshConceptsCache(false));
      },
    }
  );

  // @ts-expect-error enabled strictNullChecks without fixing all errors in detail
  const videoCardsCarouselData: (VideoCardProps & React.Attributes)[] = conceptMetricsLoading
    ? undefined
    : conceptMetrics?.concepts?.map<VideoCardProps & React.Attributes>((concept, index) => {
        const filteredConceptRecommendations = filterRecommendationsToMatchTimelineRecommendations(
          recommendations?.find((recommendation) => recommendation.id === concept.id)?.subRows
        );

        return {
          assetId: concept.assetId,
          // @ts-expect-error enabled strictNullChecks without fixing all errors in detail
          isActive: isCreativeActive(concept.maxDate, new Date().toDateString()),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          name: (concept.displayName || concept.name) ?? messages.NOT_AVAILABLE,
          previewLoading: !!thumbnailsQueries[index].isLoading,
          previewUrl: thumbnailsQueries[index].isLoading ? '' : thumbnailsQueries[index].data?.previewUrl ?? '',
          // @ts-expect-error enabled strictNullChecks without fixing all errors in detail
          age: formatDateDistance(new Date(concept.age)),
          keyMetric: SDK.kpiUtils.format(keyMetric, concept[keyMetric]),
          keyMetricLabel: SDK.capitalizeFirstLetter(keyMetric),
          keyMetricRatio:
            (concept[keyMetric] ?? 0) /
            (concept[`total${SDK.capitalizeFirstLetter(keyMetric)}`] === 0
              ? 1
              : concept[`total${SDK.capitalizeFirstLetter(keyMetric)}`]),
          recommendationNumber: isRecommendationsDataLoading ? undefined : filteredConceptRecommendations?.length || 0,
          onCardClick: () => {
            navigate(`/${projectId}/${Page.Concepts}/${concept.id}/timeline`);
            logEvent({
              component: 'Dashboard',
              action: 'Click video card',
              category: 'user_actions',
              parameters: { value: concept.id, page: Page.Dashboard },
            });
          },
          onVideoPlay: () => {
            logEvent({
              component: `Dashboard`,
              action: 'Play video from video card',
              category: 'user_actions',
              parameters: { value: concept.id, page: Page.Dashboard },
            });
          },
          onVideoPause: () => {
            logEvent({
              component: `Dashboard`,
              action: 'Pause video from video card',
              category: 'user_actions',
              parameters: { value: concept.id, page: Page.Dashboard },
            });
          },
        };
      });

  const getVariationButton = (assetId: string) => <AssetVariationButton assetId={assetId} />;

  return (
    <Container data-test="insights-top-concepts-container">
      <SectionHeader
        title={accountTypes?.includes(SDK.AccountTypes.SOCIALS) ? 'Top Videos' : 'Top Concepts'}
        subtitle={`Concepts for ${getNetworksString(filters.networks, projectNetworks)}`}
        buttons={[
          {
            children: 'See all',
            variant: 'outlined',
            color: 'Gray',
            onClick: () => {
              navigate(`/${projectId}/${Page.Videos}`);
              logEvent({
                component: 'Dashboard',
                action: 'Click See all (Top Videos)',
                category: 'user_actions',
                parameters: { page: Page.Dashboard },
              });
            },
          },
        ]}
      />
      <VideosContainer data-test="insights-top-concepts-video-container">
        <VideoCardsCarousel
          data={videoCardsCarouselData ?? null}
          emptyStateProps={{
            icon: 'Activity',
            text: 'No data available',
          }}
          itemCustomButtonGenerator={
            isTechMerc1388Enabled ? (data) => getVariationButton(data.assetId || '') : undefined
          }
        />
      </VideosContainer>
    </Container>
  );
};

export default TopVideos;
