/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/no-unstable-nested-components */
import * as SDK from '@replai-platform/sdk';
import { Card, DropDownChip, DropDownMenuOption, Typography } from '@replai-platform/ui-components';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { logEvent } from '../../../../../analytics';
import { api } from '../../../../../api';
import useAssetsMetrics from '../../../../../api/hooks/assets/useAssetsMetrics';
import Link from '../../../../../components/Link';
import Table from '../../../../../components/Table';
import { RootState } from '../../../../../store/rootReducer';
import { Columns, Page } from '../../../../../utils/enums';
import { RouteAnimator } from '../../../../RouteAnimator';
import * as Styled from './styles';

const MAX_ITEMS_PER_PAGE = 10;

const METRICS_TO_REQUEST = [
  SDK.Metrics.SPEND,
  SDK.KPI.CPL,
  SDK.KPI.VINTED_CPL,
  SDK.KPI.YOKE_CPA,
  SDK.KPI.CPC,
  SDK.KPI.CPI,
  SDK.KPI.CTI,
  SDK.KPI.CTR,
  SDK.KPI.IPM,
  SDK.KPI.CPPDAY7,
  SDK.KPI.ROASDAY7,
] as SDK.MetricOrKpi[];

type TableRowData = {
  cluster: any;
  expanded: boolean;
  allowEdit: boolean;
};

const TagVideos = () => {
  const params = useParams();
  const projectId = useSelector((state: RootState) => state.project.id);
  const filters = useSelector((state: RootState) => state.filters);
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const projectBaseMetric = useSelector((state: RootState) => state.project.baseMetric);
  const { tagId } = params;

  const [pageOffset, setPageOffset] = useState(0);
  const [selectedKpi, setSelectedKpi] = useState<SDK.KPI>(SDK.KPI.IPM);

  const kpisToConsider: SDK.KPI[] = useMemo(
    // The type cast below is safe since we filter out SPEND.
    () => (projectKpis?.length ? (projectKpis.filter((kpi) => kpi !== SDK.Metrics.SPEND) as SDK.KPI[]) : []),
    [projectKpis]
  );

  useEffect(() => {
    if (kpisToConsider.length) {
      setSelectedKpi(kpisToConsider[0]);
    }
  }, [kpisToConsider]);

  const getAssetsMetricsParams = useMemo<SDK.GetAssetsMetricsRequest>(
    () => ({
      projectIds: [projectId],
      metrics: METRICS_TO_REQUEST,
      orderBy: {
        condition: SDK.OrderByCondition.DESC_NULLS_LAST,
        value: SDK.Metrics.SPEND,
      },
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: { ...api.filterConverter.getAssetFilters(filters), taggedWith: tagId ? [tagId] : undefined },
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      includeTotalsAndAvg: true,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, JSON.stringify(filters), tagId]
  );

  const { data, isLoading: tableDataLoading } = useAssetsMetrics(getAssetsMetricsParams, {
    enabled: !!tagId,
  });

  const tableData: TableRowData[] | undefined = data?.assets?.map((entry) => ({
    cluster: {
      ...entry,
    },
    expanded: false,
    allowEdit: false,
  }));

  const dropDownOptions: DropDownMenuOption[] = useMemo(
    () =>
      kpisToConsider.map(
        (kpi) =>
          ({
            label: SDK.kpiUtils.getDisplayName(kpi).toUpperCase(),
            type: 'option',
            onClick: () => setSelectedKpi(kpi),
          } as DropDownMenuOption)
      ),
    [kpisToConsider, setSelectedKpi]
  );

  const generateRowHref = (row: TableRowData) => `/${projectId}/${Page.VideoView}/${(row.cluster?.id as string) ?? ''}`;

  return (
    <RouteAnimator>
      <Styled.ContentContainer data-test="tag-videos">
        <Card fullWidth fullHeight>
          <Styled.FiltersGroup data-test="filter-group">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography type="text-lg">Videos</Typography>
              <Typography type="text-sm">These are the videos where this tag is present.</Typography>
            </div>
            <div style={{ flexGrow: 1 }} />
            <Styled.ButtonsGroup data-test="button-group">
              <DropDownChip
                defaultOption={kpisToConsider[0].toUpperCase()}
                dropDownType="singleselect"
                dropDownOptions={dropDownOptions}
                disableCrossButton
              />
            </Styled.ButtonsGroup>
          </Styled.FiltersGroup>
          <Styled.TableWrapper>
            <Table
              cellLink={({ cell, anchorFactory }) => {
                const href = generateRowHref(cell.row.original as TableRowData);

                return (
                  <Link
                    component={anchorFactory({ ariaLabel: cell.value, href })}
                    onClick={() => {
                      logEvent({
                        component: 'Single Tag View',
                        action: 'Click on Row',
                        category: 'user_actions',
                      });
                    }}
                    to={href}
                  />
                );
              }}
              generateRowHref={generateRowHref}
              pageIndex={pageOffset}
              tableData={
                tableData?.length
                  ? tableData.slice(
                      pageOffset * MAX_ITEMS_PER_PAGE,
                      pageOffset * MAX_ITEMS_PER_PAGE + MAX_ITEMS_PER_PAGE
                    )
                  : []
              }
              columns={[
                Columns.Preview,
                Columns.Name,
                ...(projectBaseMetric === SDK.Metrics.SPEND ? [Columns.Spend] : []),
                `metrics.${selectedKpi}`,
              ]}
              rowsTotal={tableData?.length ?? 0}
              rowsPerPage={MAX_ITEMS_PER_PAGE}
              onPageUpdate={({ pageIndex }) => setPageOffset(pageIndex)}
              loading={tableDataLoading ? { messages: ['Fetching Metrics'], firstMessageDelay: 3 } : undefined}
              emptyStateProps={{
                icon: 'Search',
                text: 'No metrics found',
                supportingText: 'An error may occurred. Try again later!',
              }}
            />
          </Styled.TableWrapper>
        </Card>
      </Styled.ContentContainer>
    </RouteAnimator>
  );
};

export default TagVideos;
