import {
  BaseIconTypes,
  Colors,
  EmptyState,
  Typography,
  VideoCardProps,
  VideoCardsCarousel,
} from '@replai-platform/ui-components';
import * as SDK from '@replai-platform/sdk';
import { useFeature } from '@optimizely/react-sdk';
import * as Styled from './styles';
import VideoCardGrid from './VideoCardGrid';
import AssetVariationButton from '../AssetVariationButton';

const LibraryGrid = ({
  title,
  topFiveData,
  videoCardsData,
  isLoading,
  isEmpty,
  searchTerm,
  thumbnailsQueries,
  emptyStateIcon,
  emptyStateText,
  showKeyMetric,
  showNetworks,
  showHasPostQaAnnotations = false,
  showAge,
  showLaunchDate,
  showRelevanceScore,
  showRecommendations,
  showTopVideos = true,
  disableClick = false,
  showCompare = false,
  showSave = false,
  disableCompare = false,
  generateVariationButtonLabel,
  showDownloadButton = true,
}: {
  title: string;
  topFiveData?: VideoCardProps[];
  videoCardsData: (VideoCardProps & {
    id?: SDK.UUID;
    conceptsCount?: number;
  })[];
  isLoading: boolean;
  isEmpty: boolean;
  searchTerm?: string;
  thumbnailsQueries: any[];
  emptyStateIcon: BaseIconTypes;
  emptyStateText: string;
  showKeyMetric?: boolean;
  showNetworks?: boolean;
  showHasPostQaAnnotations?: boolean;
  showAge?: boolean;
  showLaunchDate?: boolean;
  showRelevanceScore?: boolean;
  showRecommendations?: boolean;
  showTopVideos?: boolean;
  disableClick?: boolean;
  showCompare?: boolean;
  showSave?: boolean;
  disableCompare?: boolean;
  generateVariationButtonLabel?: string;
  showDownloadButton?: boolean;
}) => {
  const [isTechMerc1388Enabled] = useFeature('techmerc-1388'); // Creative Production

  const getVariationButton = (assetId: string) => (
    <AssetVariationButton assetId={assetId} buttonLabel={generateVariationButtonLabel} />
  );

  return isEmpty ? (
    <EmptyState icon={emptyStateIcon} text={emptyStateText} />
  ) : (
    <>
      {!searchTerm && showTopVideos ? (
        <Styled.TopVideoCardsContainer data-test="top-concepts-container">
          <Typography type="display-xs" color={Colors.Gray[900]} fontWeight="medium">
            {title}
          </Typography>
          <Styled.PaddingContainer>
            <VideoCardsCarousel
              data={topFiveData ?? null}
              emptyStateProps={{
                icon: 'Activity',
                text: 'No data available',
              }}
              showAge={showAge}
              showKeyMetric={showKeyMetric}
              showLaunchDate={showLaunchDate}
              showRecommendations={showRecommendations}
              showCompareButton={showCompare}
              disableCompareButton={disableCompare}
              itemCustomButtonGenerator={
                isTechMerc1388Enabled ? (data) => getVariationButton(data.assetId || '') : undefined
              }
            />
          </Styled.PaddingContainer>
        </Styled.TopVideoCardsContainer>
      ) : undefined}
      <VideoCardGrid
        data={videoCardsData}
        isLoading={isLoading}
        thumbnailsQueries={thumbnailsQueries}
        showKeyMetric={showKeyMetric}
        showNetworks={showNetworks}
        showHasPostQaAnnotations={showHasPostQaAnnotations}
        showAge={showAge}
        showLaunchDate={showLaunchDate}
        showRelevanceScore={showRelevanceScore}
        showRecommendations={showRecommendations}
        disableClick={disableClick}
        showCompare={showCompare}
        showSave={showSave}
        disableCompare={disableCompare}
        generateVariationButtonLabel={generateVariationButtonLabel}
        showDownloadButton={showDownloadButton}
      />
    </>
  );
};
export default LibraryGrid;
