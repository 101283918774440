import styled from 'styled-components';
import ConditionalWrapper from '../../utils/ConditionalWrapper';
import AppcuesTutorial from '../AppcuesTutorial/AppcuesTutorial';
import Colors from '../Colors';
import * as Icons from '../Icons';
import SkeletonLoading from '../Skeleton/Skeleton';
import Tooltip from '../Tooltip/Tooltip';
import Typography from '../Typography/Typography';

const PageHeaderContainer = styled.div`
  margin-right: 0.5rem;
`;

const Title = styled(Typography)`
  display: -webkit-box;
  margin-bottom: 0.25rem;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const TitleContainer = styled.div`
  display: inline-flex;
`;
const IconSpan = styled.span`
  align-self: center;
  margin-right: 0.5rem;
`;
const SubTitle = styled(Typography)`
  margin-bottom: 0;
`;

export interface PageHeaderProps {
  title: string;
  subtitle?: string;
  withTooltip?: boolean;
  titleIcon?: Icons.BaseIconTypes;
  appcuesId?: string;
  titleIsLoading?: boolean;
}

const PageHeader = ({
  title,
  subtitle,
  titleIcon,
  appcuesId,
  withTooltip = false,
  titleIsLoading,
}: PageHeaderProps) => {
  const TitleIcon = titleIcon && Icons.getBaseIcon(titleIcon);

  return (
    <PageHeaderContainer>
      <ConditionalWrapper
        condition={withTooltip}
        renderWrapper={(children) => (
          <Tooltip content={title} placement="bottom">
            {children}
          </Tooltip>
        )}
      >
        <TitleContainer>
          {TitleIcon ? (
            <IconSpan>
              <TitleIcon dimension={22} color={Colors.Gray[500]} />
            </IconSpan>
          ) : undefined}
          {titleIsLoading ? (
            <SkeletonLoading height={40} width={300} />
          ) : (
            <Title
              type="display-sm"
              color={Colors.Gray[900]}
              fontWeight="medium"
            >
              {title}
              {appcuesId ? (
                <AppcuesTutorial appcuesId={appcuesId} />
              ) : undefined}
            </Title>
          )}
        </TitleContainer>
      </ConditionalWrapper>
      <SubTitle type="text-md" color={Colors.Gray[500]} fontWeight="regular">
        {subtitle}
      </SubTitle>
    </PageHeaderContainer>
  );
};

export default PageHeader;
