/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { Button, ButtonIcon, ColorTypes } from '..';

export type ModalButtonsContainerProps = React.HTMLAttributes<HTMLElement>;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: end;
`;

export const ModalButtonsContainer = ({
  children,
  style,
}: ModalButtonsContainerProps) => (
  <Container style={style}>{children}</Container>
);

export type ModalButtonType = 'primary' | 'secondary' | 'danger';

export interface ModalButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variation: ModalButtonType;
  leadingIcon?: ButtonIcon;
}

export const ModalButton = ({
  disabled,
  children,
  leadingIcon,
  onClick,
  variation,
  style,
  ...rest
}: ModalButtonProps) => {
  let color: ColorTypes | undefined;
  switch (variation) {
    case 'secondary':
      color = 'Gray';
      break;
    case 'danger':
      color = 'Error';
      break;
    case 'primary':
    default:
      color = undefined;
      break;
  }
  return (
    <Button
      {...rest}
      style={style}
      disabled={disabled}
      leadingIcon={leadingIcon}
      variant={variation === 'secondary' ? 'outlined' : 'regular'}
      color={color}
      onClick={(e) => {
        onClick?.(e);
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {children}
    </Button>
  );
};
