import styled, { css } from 'styled-components';
import Colors from '../Colors';

export const Root = styled.div<{ $hasFullWidth?: boolean }>`
  font-size: 14px;

  ${(props) => (props.$hasFullWidth ? FullWidthMixin : '')}
`;

export const Label = styled.label`
  color: ${Colors.Gray[700]};
  font-weight: 500;
`;

export const TextArea = styled.textarea<{ $error?: boolean }>`
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  margin: 6px 0;
  padding: 10px 14px;
  color: ${Colors.Gray[900]};
  font-size: 16px;
  border: 1px solid ${Colors.Gray[300]};
  border-radius: 8px;
  outline: none;
  resize: none;

  &::placeholder {
    color: ${Colors.Gray[500]};
  }

  &:focus {
    border: 1px solid ${Colors.Primary[300]};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px ${Colors.Primary[100]};
  }

  ${(props) => (props.$error ? ErrorMixin : '')}
`;

export const Hint = styled.div<{ $error?: boolean }>`
  ${(props) => (props.$error ? ErrorHintMixin : HintTextMixin)}
`;

/* Mixins */

const FullWidthMixin = css`
  width: 100%;
`;

const ErrorMixin = css`
  border: 1px solid ${Colors.Error[300]};

  &:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px ${Colors.Error[100]};
  }
`;

const ErrorHintMixin = css`
  color: ${Colors.Error[500]};
`;

const HintTextMixin = css`
  color: ${Colors.Gray[500]};
`;
