import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConceptsState {
  searchTerm: string;
  shouldRefreshConceptsCache: boolean;
}

const initialState: ConceptsState = {
  searchTerm: '',
  shouldRefreshConceptsCache: false,
};

export const ConceptsSlice = createSlice({
  name: 'Concepts',
  initialState,
  reducers: {
    changeSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    changeShouldRefreshConceptsCache: (state, action: PayloadAction<boolean>) => {
      state.shouldRefreshConceptsCache = action.payload;
    },
  },
});

export const ConceptsActions = ConceptsSlice.actions;
export const ConceptsReducer = ConceptsSlice.reducer;
export const ConceptsInitialState = initialState;
