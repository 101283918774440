import { useSelector } from 'react-redux';
import { useTitle } from 'react-use';
import type { RootState } from '../../store/rootReducer';
import { RouteAnimator } from '../RouteAnimator';
import TopNavPageTitle from '../../components/TopNavPageTitle';
import TeamManagement from './TeamManagement';
import * as Styled from './styles';
import ExternalApiKeyManagement from './ExternalApiKeyManagement';
import NetworkManagement from './NetworkManagement';

const Settings: React.VFC = () => {
  const projectName = useSelector((state: RootState) => state.project.name);

  useTitle(`Settings - ${projectName}`);

  return (
    <RouteAnimator data-test="settings-container">
      <TopNavPageTitle
        title="Settings"
        subtitle="Manage your organization settings, team members and their account permissions here."
      />
      <Styled.Divider />
      <NetworkManagement />
      <Styled.Divider />
      <TeamManagement />
      <Styled.Divider />
      <ExternalApiKeyManagement />
    </RouteAnimator>
  );
};

export default Settings;
