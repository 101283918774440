/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Button } from '@replai-platform/ui-components';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as SDK from '@replai-platform/sdk';
import { logEvent } from '../../analytics';
import useUpdateUiPreferences from '../../api/hooks/projects/useUpdateUiPreferences';
import columnsConfig from '../../configurations/columnsConfig';
import { ProjectActions } from '../../store/project';
import type { RootState } from '../../store/rootReducer';
import PickAndSortDialog, { PickAndSortDialogData } from '../PickAndSortDialog';
import { Columns } from '../../utils/enums';

type CustomizeFieldsConfig = {
  groups: {
    name: string;
    options: {
      disallowed: boolean;
      description: string;
      label: string;
      value: string;
    }[];
  }[];
  initialValues: { column: string; disallowed: boolean }[];
};

function load(
  userSelectedColumns,
  projectKpis,
  tableColumns,
  nonCustomizableColumns,
  projectAccountTypes?: SDK.AccountTypes[]
): CustomizeFieldsConfig {
  const { groups, initialValues } = columnsConfig(projectKpis, projectAccountTypes);
  return {
    groups: groups.map((g) => ({
      name: g.name,
      options: g.options
        .filter((o) => !nonCustomizableColumns?.includes(o.value))
        .map((option) => ({
          ...option,
          disallowed: tableColumns?.length > 0 && !tableColumns?.some((tableColumn) => tableColumn === option.value),
        })),
    })),
    initialValues: (userSelectedColumns
      ? userSelectedColumns.filter((column) =>
          groups.find((group) => group.options.find((option) => option.value === column))
        )
      : initialValues
    )
      .filter((column) => !nonCustomizableColumns?.includes(column))
      .map((column) => ({
        column,
        disallowed: tableColumns?.length > 0 && !tableColumns?.some((tableColumn) => tableColumn === column),
      })),
  };
}

function transformColumnsConfigToOptions(columnConfig: CustomizeFieldsConfig): PickAndSortDialogData {
  return {
    groups: columnConfig?.groups.map((d) => ({
      title: d.name,
      options: d.options.map((o) => ({ id: o.value, label: o.label, disallowed: o.disallowed })),
    })),
    initialValues: columnConfig?.initialValues.map((v) => {
      const group = columnConfig?.groups
        .reduce((previous, current) => [...previous, ...current.options], [])
        ?.filter((o) => o.value === v.column)?.[0];
      return { id: v.column, label: group?.label, disallowed: v.disallowed };
    }),
  };
}

const CustomizeFieldsButton = ({
  disabled = false,
  eventPrefix,
  tableColumns,
  nonCustomizableColumns,
  subtitle,
  disallowedTooltip,
}: {
  disabled: boolean;
  eventPrefix: string;
  // this can also have strings because some KPIs are not specified in the Columns enum
  tableColumns: (Columns | string)[];
  nonCustomizableColumns: Columns[];
  subtitle: string;
  disallowedTooltip: string;
}) => {
  const [open, setOpen] = useState(false);
  const projectId = useSelector((state: RootState) => state.project.id);
  const uiPreferences = useSelector((state: RootState) => state.project.userProject?.uiPreferences);
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes);
  const userSelectedColumns = uiPreferences?.videos?.columns;
  const updateUiPreferencesMutation = useUpdateUiPreferences();

  const dispatch = useDispatch();

  // The columns config default values, used in "reset" functionality
  const initialRawData = load(undefined, projectKpis, tableColumns, nonCustomizableColumns, projectAccountTypes);
  const initialData = transformColumnsConfigToOptions(initialRawData);

  // The user's columns config values
  const rawData = load(userSelectedColumns, projectKpis, tableColumns, nonCustomizableColumns, projectAccountTypes);
  const data = transformColumnsConfigToOptions(rawData);

  return (
    <>
      <Button
        data-test="customize-fields-button"
        disabled={disabled}
        variant="outlined"
        color="Gray"
        onClick={() => {
          logEvent({
            component: eventPrefix,
            action: 'Customize Columns',
            category: 'user_actions',
          });
          setOpen(true);
        }}
        size="md"
      >
        Customize
      </Button>

      <PickAndSortDialog
        data-test="customize-fields-dialog"
        data={data}
        onCancel={() => {
          logEvent({
            component: eventPrefix,
            action: 'Customize Columns Cancel',
            category: 'user_actions',
          });
          setOpen(false);
        }}
        onSave={async (values) => {
          logEvent({
            component: eventPrefix,
            action: 'Customize Columns Save and Update',
            category: 'user_actions',
          });
          await updateUiPreferencesMutation.mutateAsync(
            {
              uiPreferences: [
                {
                  op: 'add',
                  path: '/videos',
                  value: {
                    ...uiPreferences?.videos,
                    columns: values,
                  },
                },
              ],
              projectId,
            },
            {
              onSuccess: (res) => {
                dispatch(ProjectActions.changeUiPreferences(res));
              },
              onSettled: () => {
                setOpen(false);
              },
            }
          );
        }}
        onReset={() => {
          logEvent({
            component: eventPrefix,
            action: 'Reset',
            category: 'user_actions',
          });
        }}
        open={open}
        title="Customize Columns"
        subtitle={subtitle}
        initialSelectedOptions={initialData.initialValues}
        disallowedTooltip={disallowedTooltip}
      />
    </>
  );
};

export default CustomizeFieldsButton;
