import { Button, Tooltip, Typography } from '@replai-platform/ui-components';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../../common';
import { RootState } from '../../../../store/rootReducer';

const ButtonContainer = styled.div`
  margin-right: 1rem;
`;

export type ReportDeleteAccessorType = {
  reportId: string;
  userEmail: string;
  onReportDeleteClick?: (setButtonLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
};

const ReportDeleteCellDecorator: React.FC<{
  cellData: ReportDeleteAccessorType;
}> = ({ cellData }) => {
  const userEmail = useSelector((state: RootState) => state.auth.email);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const button = useMemo(
    () => (
      <Button
        trailingIcon={{ name: buttonLoading ? 'LoadingCircle' : 'Trash2' }}
        onClick={() => {
          cellData?.onReportDeleteClick?.(setButtonLoading);
        }}
        disabled={buttonLoading}
        color="Warning"
        variant="text"
      />
    ),
    [cellData, buttonLoading, setButtonLoading]
  );

  if (!cellData) {
    return null;
  }

  return userEmail !== cellData.userEmail ? (
    <Tooltip
      placement="left"
      content={
        <Typography type="text-sm" fontWeight="medium" noMargin>
          Only the user who created a report can delete it
        </Typography>
      }
    >
      <ButtonContainer>
        <Button trailingIcon={{ name: 'Trash2' }} onClick={() => {}} disabled color="Warning" variant="text" />
      </ButtonContainer>
    </Tooltip>
  ) : (
    <ButtonContainer>{button}</ButtonContainer>
  );
};

function getReportDeleteColumn<T extends object = ReportDeleteAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T, ReportDeleteAccessorType>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<T, ReportDeleteAccessorType>) => <ReportDeleteCellDecorator cellData={data?.cell?.value} />,
  };
}

export default getReportDeleteColumn;
