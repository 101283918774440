import { TagMetrics } from '@replai-platform/sdk';

const sortByHigherSpenders = (first: TagMetrics, second: TagMetrics) => second.shareOfSpend - first.shareOfSpend;
const sortBySelected = (first: TagMetrics, second: TagMetrics, selected: { [key: string]: boolean }) => {
  if (!!selected[first.tag.id] === !!selected[second.tag.id]) return 0;
  if (!!selected[first.tag.id] && !selected[second.tag.id]) return 1;
  return -1;
};

export const separateByTagTypes = (
  tags: (TagMetrics & { goodness: number; scorePercentage: number })[],
  selectedTags: { [key: string]: boolean }
) => {
  const tagTypes: { [key: string]: (TagMetrics & { goodness: number; scorePercentage: number })[] } = {};
  (tags || []).forEach((tag) => {
    if (tagTypes[tag.tag.type] === undefined) {
      tagTypes[tag.tag.type] = [tag];
    } else {
      tagTypes[tag.tag.type] = [...tagTypes[tag.tag.type], tag];
    }
  });
  Object.keys(tagTypes).forEach((tagType) => {
    tagTypes[tagType] = tagTypes[tagType].sort(
      (a, b) => sortByHigherSpenders(a, b) - sortBySelected(a, b, selectedTags)
    );
  });
  return tagTypes;
};

export default separateByTagTypes;
