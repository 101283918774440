import { Colors } from '..';
import generateGradient from './generateGradient';

const error = {
  conical: generateGradient(
    [Colors.Error[600], Colors.Error[25]],
    'conical',
    {},
    {
      originDeg: 259.08,
      originX: 50,
      originY: 50,
      deg: [0, 360],
    }
  ),
  linear01: generateGradient([Colors.Error[600], Colors.Error[500]], 'linear', {
    originDeg: 90,
    percent: [0, 100],
  }),
  linear02: generateGradient([Colors.Error[700], Colors.Error[600]], 'linear', {
    originDeg: 45,
    percent: [0, 100],
  }),
  linear03: generateGradient([Colors.Error[800], Colors.Error[600]], 'linear', {
    originDeg: 45,
    percent: [0, 100],
  }),
  linear04: generateGradient([Colors.Error[800], Colors.Error[600]], 'linear', {
    originDeg: 90,
    percent: [0, 100],
  }),
  linear05: generateGradient([Colors.Error[800], Colors.Error[700]], 'linear', {
    originDeg: 26.5,
    percent: [0, 100],
  }),
  linear06: generateGradient([Colors.Error[900], Colors.Error[600]], 'linear', {
    originDeg: 45,
    percent: [0, 100],
  }),
} as const;

export default error;
