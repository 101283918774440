/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../../../../../../api';
import useTagPerformance from '../../../../../../api/hooks/tags/useTagPerformance';
import CustomizeFieldsButton from '../../../../../../components/CustomizeFieldsButton';
import { NetworksPerformance } from '../../../../../../components/NetworksPerformance';
import { RootState } from '../../../../../../store/rootReducer';
import { Columns } from '../../../../../../utils/enums';
import getUserSelectedColumns from '../../../../../../utils/getUserSelectedColumns';
import { CardHeader, CardHeaderText, TypographyClean } from '../../../../../../utils/styles';
import {
  ALLOWED_COLUMNS_ON_NETWORK_CONTEXT_PERFORMANCE_TABLE,
  NON_CUSTOMIZABLE_COLUMNS,
  getOrderByCondition,
} from '../../../../../../utils/tables';
import * as Styled from './styles';
import { CUSTOMIZE_DIALOG_SUBTITLE, CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP } from '../../../../../../utils/constants';

const ContextPerformance = () => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const filters = useSelector((state: RootState) => state.filters);
  const projectKpis: SDK.MetricKPIWithSpend[] = useSelector(
    (state: RootState) => state.project.config.defaultProjectKpis
  );
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes);

  const { type: tagType, value: tagValue } = useSelector(
    (state: RootState) => state.tagView?.tag || ({ id: '', value: null, type: '' } as SDK.Tag)
  );
  const userColumns = useSelector(
    (state: RootState) => state.project.userProject.uiPreferences?.videos?.columns as Columns[]
  );
  const userSelectedColumns: Columns[] = useMemo(
    () => getUserSelectedColumns({ userColumns, projectKpis, projectAccountTypes }),
    [projectKpis, projectAccountTypes, JSON.stringify(userColumns)]
  );
  const kpis = useMemo(
    () => userSelectedColumns?.map((c) => c.split('.')?.[1] ?? '')?.filter((c) => c && c !== SDK.Metrics.AGE),
    [userSelectedColumns]
  );

  const getCampaignParams = useMemo(
    () => ({
      projectIds: [projectId],
      metrics: kpis as SDK.MetricOrKpi[],
      orderBy: getOrderByCondition({ id: 'metrics.spend', desc: true }),
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      includeTotalsAndAvg: true,
      tag: { type: tagType, value: tagValue },
    }),
    [projectId, kpis, filters, tagType, tagValue]
  );

  const { data: performance, isLoading } = useTagPerformance(getCampaignParams, { enabled: !!tagType });

  return (
    <>
      <CardHeader data-test="context-performance-videos-header">
        <CardHeaderText>
          <TypographyClean type="text-lg" fontWeight="medium">
            Context Performance
          </TypographyClean>
          <TypographyClean type="text-sm" fontWeight="regular">
            Compare this tag performance against its peers across networks and campaigns
          </TypographyClean>
        </CardHeaderText>
        <CustomizeFieldsButton
          nonCustomizableColumns={NON_CUSTOMIZABLE_COLUMNS}
          tableColumns={ALLOWED_COLUMNS_ON_NETWORK_CONTEXT_PERFORMANCE_TABLE}
          disallowedTooltip={CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP}
          subtitle={CUSTOMIZE_DIALOG_SUBTITLE}
          disabled={false}
          eventPrefix="Videos Context Performance"
        />
      </CardHeader>
      <Styled.CardTable>
        <NetworksPerformance networksData={performance?.tags} isLoading={isLoading} kpis={kpis} />
      </Styled.CardTable>
    </>
  );
};

export default ContextPerformance;
