import * as SDK from '@replai-platform/sdk';
import { useQuery, UseQueryOptions } from 'react-query';
import * as provider from '../../api/provider';

const getTagPerformance = async (requestParams: SDK.GetTagContextPerformanceRequest) => {
  const res = await provider.getJson(
    `${SDK.BASE_URL()}/tags/context_performance?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useTagPerformance(
  requestParams: SDK.GetTagContextPerformanceRequest,
  options?: UseQueryOptions<any>
) {
  return useQuery(['tag-performance', requestParams], () => getTagPerformance(requestParams), options);
}
