import { CountryIconProps } from './types';

const MD = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3667)">
      <path
        d="M17.2175 1.19133C15.6399 0.428485 13.87 0.000610352 12.0001 0.000610352C10.1303 0.000610352 8.36037 0.428485 6.78274 1.19133L5.73926 12.0006L6.78274 22.8099C8.36037 23.5727 10.1303 24.0006 12.0001 24.0006C13.87 24.0006 15.6399 23.5727 17.2175 22.8099L18.261 12.0006L17.2175 1.19133Z"
        fill="#FFDA44"
      />
      <path
        d="M6.78262 1.19214C2.76872 3.13309 0 7.24351 0 12.001C0 16.7585 2.76872 20.8689 6.78262 22.8099V1.19214Z"
        fill="#0052B4"
      />
      <path
        d="M17.2178 1.19214V22.8099C21.2317 20.8689 24.0004 16.7585 24.0004 12.001C24.0004 7.24351 21.2317 3.13309 17.2178 1.19214Z"
        fill="#D80027"
      />
      <path
        d="M16.174 9.44228H13.3044C13.3044 8.72191 12.7204 8.13794 12.0001 8.13794C11.2797 8.13794 10.6957 8.72191 10.6957 9.44228H7.82617C7.82617 10.1515 8.44403 10.7265 9.1532 10.7265H9.11045C9.11045 11.4358 9.68542 12.0108 10.3947 12.0108C10.3947 12.6388 10.8458 13.1606 11.4415 13.2722L10.4294 15.5569C10.9143 15.754 11.4443 15.8636 12.0001 15.8636C12.5558 15.8636 13.0858 15.754 13.5707 15.5569L12.5587 13.2722C13.1544 13.1606 13.6054 12.6388 13.6054 12.0108C14.3147 12.0108 14.8897 11.4358 14.8897 10.7265H14.8469C15.5561 10.7265 16.174 10.1515 16.174 9.44228Z"
        fill="#FF9811"
      />
      <path
        d="M12.0003 11.2178L10.3047 12.0004V13.5656L12.0003 14.6091L13.696 13.5656V12.0004L12.0003 11.2178Z"
        fill="#0052B4"
      />
      <path
        d="M13.696 10.4341H10.3047V11.9993H13.696V10.4341Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3667">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default MD;
