import { Button, Input, Modal, Typography } from '@replai-platform/ui-components';
import styled from 'styled-components';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as SDK from '@replai-platform/sdk';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../../common';
import ColumnHeader from '../../generateHeader';
import useSaveReportAsTemplate from '../../../../api/hooks/reports/useSaveReportAsTemplate';
import { RootState } from '../../../../store/rootReducer';

const ButtonContainer = styled.div`
  margin-right: 1rem;
`;

export type ReportSaveTemplateAccessorType = {
  reportId: string;
  templateId?: string;
  templateName?: string;
  reportType?: SDK.ReportTypes;
  externalId?: string;
};

const ReportTypeCellDecorator: React.FC<{
  cellData: ReportSaveTemplateAccessorType;
}> = ({ cellData }) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const [isEnabled, setIsEnabled] = useState(!!cellData?.templateId);
  const [modalOpen, setModalOpen] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const saveReportAsTemplateMutation = useSaveReportAsTemplate();

  if (!cellData) {
    return null;
  }

  return (
    <ButtonContainer>
      <Modal
        className="report-save-template-modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
        onClose={() => setModalOpen(false)}
        isOpen={modalOpen}
      >
        <Typography>Type the template name:</Typography>
        <Input
          key="template-id"
          onChange={(e) => {
            setTemplateName(e.target.value ?? '');
          }}
          fullWidth
          placeholder="UA Report"
          leadingIcon="Tag"
        />
        <Button
          disabled={(templateName?.length ?? 0) === 0 || isButtonLoading}
          style={{ marginTop: '1rem' }}
          trailingIcon={{ name: 'Send' }}
          onClick={() => {
            setIsButtonLoading(true);

            saveReportAsTemplateMutation
              .mutateAsync({
                projectId,
                reportId: cellData.reportId,
                name: templateName,
              })
              .then(() => {
                setModalOpen(false);
                setIsEnabled(true);
              })
              .catch((e) => {
                // eslint-disable-next-line no-console
                console.error(e);
              });
          }}
        >
          Save template
        </Button>
      </Modal>
      {!isEnabled ? (
        <Button
          disabled={isEnabled}
          trailingIcon={{ name: 'Heart' }}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Save template
        </Button>
      ) : (
        <span>{cellData.templateName ?? templateName}</span>
      )}
    </ButtonContainer>
  );
};

function getReportSaveTemplateColumn<T extends object = ReportSaveTemplateAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T, ReportSaveTemplateAccessorType>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Header: <ColumnHeader title="Template" />,
    Cell: (data: CellData<T, ReportSaveTemplateAccessorType>) => (
      <ReportTypeCellDecorator cellData={data?.cell?.value} />
    ),
  };
}

export default getReportSaveTemplateColumn;
