export const calculateTimestamps = (
  duration: number,
  timestampsToShow: number
): number[] => {
  const interval = Math.floor(duration / (timestampsToShow - 1));
  return [
    0,
    ...Array.from(Array(timestampsToShow - 2), (_, i) => interval * (i + 1)),
    duration,
  ];
};
