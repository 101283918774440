import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 100%;
  user-select: none;
`;

export const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 300px;
`;
