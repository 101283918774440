/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AssetType, Network, ObjectLevel, RelatedCreative, RelatedCreativeType } from '@replai-platform/sdk';
import {
  Colors,
  Icons,
  LoadingScreen,
  Table,
  Tooltip,
  Typography,
  getBaseIcon,
  getFlatIcon,
} from '@replai-platform/ui-components';
import styled from 'styled-components';
import { Column } from 'react-table';
import { capitalCase } from 'change-case';
import {
  getCreativeNameColumn,
  getProductionTrackCreativePreviewColumn,
  getUpdatedAtColumn,
} from '../../routes/CreativeProduction/columns';
import { useGetRelatedCreativesRequest } from '../../api/hooks/creativeProduction/useGetRelatedCreatives';
import { CellData } from '../columns/common';
import { networkIconType } from '../NetworkIcon';
import ColumnHeader from '../columns/generateHeader';
import ClickableAvatar from '../ClickableAvatar';
import { PreviewCellDecorator } from '../columns/generators/getPreviewColumn';
import Link from '../Link';
import * as Styled from './styles';

export type RelatedCreativesTabProps = {
  creativeId: string;
  projectId: string;
};

const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 300px;
`;

const getRelatedCreativeTypeColumn = (): Column<RelatedCreative> => ({
  id: 'relatedCreativeType',
  accessor: (d: RelatedCreative) => d.type,
  Cell: (d: CellData<RelatedCreative, RelatedCreativeType>) => {
    const type = d.cell.value;
    let Icon: (props: Icons.BaseIconProps) => JSX.Element;
    switch (type) {
      case RelatedCreativeType.GENERATED_CONCEPT:
        Icon = getBaseIcon('Sparkles');
        break;
      case RelatedCreativeType.LAUNCHED_ASSET:
        Icon = d.row.original.assetType === AssetType.Video ? getBaseIcon('Video') : getBaseIcon('Image');
        break;
      default:
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: never = type;
        return exhaustiveCheck;
    }
    return (
      <Tooltip content={capitalCase(type.toString())}>
        <div>
          <Icon color={Colors.Purple[400]} />
        </div>
      </Tooltip>
    );
  },
  width: 50,
});

const NetworksContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const getNetworksColumn = (): Column<RelatedCreative> => ({
  id: 'networks',
  accessor: (data: RelatedCreative) => data.networks,
  Cell: (data: CellData<RelatedCreative, Network[]>) => (
    <NetworksContainer>
      {(data.cell.value ?? []).map((network) => {
        const Icon = getFlatIcon(networkIconType(network));
        return <Icon />;
      })}
    </NetworksContainer>
  ),
  Header: 'Networks',
  width: 150,
});

const getRelatedCreativePreviewColumn = ({ projectId }: { projectId: string }): Column<RelatedCreative> => ({
  id: 'preview',
  accessor: (data: RelatedCreative) => ({
    creativeId: data.productionAssetId ?? data.id,
    creativeType: data.assetType,
    creativePreviewUrl: data.assetPreviewUrl,
    isMarketData: data.isMarketData,
    type: data.type,
    videoUrl: data.productionAssetUrl,
  }),
  Cell: (
    data: CellData<
      RelatedCreative,
      {
        creativeType: AssetType;
        creativeId: string;
        creativePreviewUrl: string;
        isMarketData: boolean;
        type: RelatedCreativeType;
        videoUrl: string | null;
      }
    >
  ) => {
    const creativeType = data.cell.value.type;
    switch (creativeType) {
      case RelatedCreativeType.GENERATED_CONCEPT: {
        const productionTrackPreviewColumnDefinition = getProductionTrackCreativePreviewColumn({ projectId });
        return productionTrackPreviewColumnDefinition.Cell(
          data as CellData<object, { creativeId: string; videoUrl: string | null }>
        );
      }
      case RelatedCreativeType.LAUNCHED_ASSET: {
        if (data.cell.value.creativeType === AssetType.Video) {
          return (
            <PreviewCellDecorator
              cellData={{
                isMarketData: data.cell.value.isMarketData,
                objectLevel: ObjectLevel.ASSET,
                objectId: data.cell.value.creativeId,
                projectId,
                tagValue: null,
              }}
              row={data.row}
            />
          );
        }
        return (
          <ClickableAvatar
            avatarImageUrl={data.cell.value.creativePreviewUrl}
            dialogContent={
              <Styled.ImgBox>
                <img alt="Parent creative thumbnail" src={data.cell.value.creativePreviewUrl} />
              </Styled.ImgBox>
            }
          />
        );
      }
      default:
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: never = creativeType;
        return exhaustiveCheck;
    }
  },
  Header: <ColumnHeader title="Preview" />,
  tooltip: 'Preview Thumbnail',
  sortDescFirst: false,
  sticky: 'left',
  width: 90,
  disableSortBy: true,
});

const RelatedCreativesTab = ({ creativeId, projectId }: RelatedCreativesTabProps) => {
  const { data } = useGetRelatedCreativesRequest(projectId, creativeId);
  if (!data) {
    return (
      <LoadingContainer>
        <LoadingScreen
          messages={[
            'Finding creative that inspired this concept...',
            'Searching creatives inspired by the current concept...',
          ]}
        />
      </LoadingContainer>
    );
  }

  const tableColumns: Column<RelatedCreative>[] = [
    getRelatedCreativeTypeColumn(),
    getRelatedCreativePreviewColumn({ projectId }),
    { ...getCreativeNameColumn<RelatedCreative>(), accessor: (d: RelatedCreative) => d.name },
    { ...getUpdatedAtColumn<RelatedCreative>(), accessor: (d: RelatedCreative) => d.latestUpdate || '' },
    {
      ...getUpdatedAtColumn<RelatedCreative>(), // Basing on updatedAt column definition to build the launchDate column definition.
      id: 'launchDate',
      Header: 'Launch Date',
      accessor: (d: RelatedCreative) => d.launchDate || '',
    },
    getNetworksColumn(),
  ];
  return (
    <div>
      <Typography>Creative that inspired this concept:</Typography>
      <Table<RelatedCreative>
        columns={tableColumns}
        // eslint-disable-next-line react/no-unstable-nested-components
        cellLink={({ cell, anchorFactory }) => {
          if (cell.row.original.isMarketData) {
            return null; // Nowhere to link to.
          }
          const assetId = cell.row.original.id;
          if (
            (assetId?.length ?? 0) <= 0 ||
            (cell as any)?.row?.values?.preview?.type !== 'LAUNCHED_ASSET' ||
            ((cell as any)?.row?.values.launchDate?.length ?? 0) < 1
          ) {
            return null;
          }

          const href = `/${projectId}/${
            cell.row.original.assetType === AssetType.Video ? 'video-view' : 'static'
          }/${assetId}`;
          return <Link component={anchorFactory({ ariaLabel: assetId, href })} onClick={() => {}} to={href} />;
        }}
        data={data.parent ? [data.parent] : []}
        rowsTotal={data.parent ? 1 : 0}
        emptyStateProps={{
          icon: 'AlertTriangle',
          text: 'This concept was not inspired by any reference creative.',
        }}
      />
      <Typography>Creatives inspired by the current concept:</Typography>
      <Table
        columns={tableColumns}
        data={data.children}
        rowsTotal={data.children.length}
        emptyStateProps={{
          icon: 'AlertTriangle',
          text: 'This concept has not been used as inspiration for new creatives.',
        }}
      />
    </div>
  );
};

export default RelatedCreativesTab;
