import { ClickAwayListener, Popper } from '@material-ui/core';
import {
  Button,
  ButtonIcon,
  ButtonVariant,
  ColorTypes,
  DatePicker,
  DatePickerButton,
} from '@replai-platform/ui-components';
import { useMemo, useState } from 'react';
import { dayObjectToIsoDate, isoDateToDayObject, toFormattedDate } from './helpers';
import * as Styled from './styles';

const DatesPicker = ({
  onChange,
  label,
  startDate,
  endDate,
  rangeDays,
  dataTest,
  leadingIcon,
  customRanges,
  disabled = false,
  buttonVariant,
  buttonColor,
  allLabel,
}: {
  startDate: string | undefined;
  endDate: string | undefined;
  rangeDays: string | number;
  onChange: (startDate: string, endDate: string, rangeDays: string | number) => void;
  label?: string;
  dataTest?: string;
  leadingIcon?: ButtonIcon;
  customRanges?: DatePickerButton[];
  disabled?: boolean;
  buttonVariant?: ButtonVariant;
  buttonColor?: ColorTypes;
  allLabel?: string;
}) => {
  const [datePickerAnchorEl, setDatePickerAnchorEl] = useState<HTMLElement | null>(null);

  const { startDay, startMonth, startYear, endDay, endMonth, endYear } = useMemo(() => {
    const startDayObject = startDate ? isoDateToDayObject(startDate) : null;
    const endDayObject = endDate ? isoDateToDayObject(endDate) : null;
    return {
      startDay: startDayObject?.day,
      startMonth: startDayObject?.month,
      startYear: startDayObject?.year,
      endDay: endDayObject?.day,
      endMonth: endDayObject?.month,
      endYear: endDayObject?.year,
    };
  }, [startDate, endDate]);

  const getLabel = () => {
    if (label && rangeDays && typeof rangeDays === 'number') {
      return `Last ${rangeDays} days`;
    }

    if (startDate) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${dayObjectToIsoDate(isoDateToDayObject(startDate))} -
      ${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        endDate ? dayObjectToIsoDate(isoDateToDayObject(endDate)) : 'Present'
      }`;
    }

    return allLabel ?? 'All';
  };

  return (
    <>
      <Button
        data-test={dataTest ?? 'date-picker'}
        variant={buttonVariant ?? 'outlined'}
        color={buttonColor ?? 'Gray'}
        disabled={disabled}
        leadingIcon={leadingIcon ?? { name: 'Calendar' }}
        style={{ padding: '10px 16px' }}
        onClick={((event) => setDatePickerAnchorEl(event.currentTarget)) as React.MouseEventHandler<HTMLButtonElement>}
      >
        {label ? `${label}:` : ''} {getLabel()}
      </Button>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <Popper style={{ zIndex: 50 }} open={Boolean(datePickerAnchorEl)} anchorEl={datePickerAnchorEl}>
        <ClickAwayListener onClickAway={() => setDatePickerAnchorEl(null)}>
          <Styled.DatePickerContainer>
            <DatePicker
              selectedDate={startDay}
              // Offset month since 'Date' uses months starting in 0.
              selectedMonth={startMonth ? startMonth - 1 : undefined}
              selectedYear={startYear}
              selectedDateEnd={endDay}
              // Offset month since 'Date' uses months starting in 0.
              selectedMonthEnd={endMonth ? endMonth - 1 : undefined}
              selectedYearEnd={endYear}
              selectedRange={rangeDays}
              customButtons={customRanges}
              onSelectionChanged={(
                date: number,
                month: number,
                year: number,
                dateEnd?: number,
                monthEnd?: number,
                yearEnd?: number,
                range?: string | number
              ) => {
                if ((dateEnd === undefined || monthEnd === undefined || yearEnd === undefined) && range !== 0) {
                  return;
                }
                // Offset month since 'Date' uses months starting in 0 but we store it starting in 1.
                const newStartDate = range === 0 ? undefined : toFormattedDate({ year, month: month + 1, day: date });
                const newEndDate =
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  range === 0 ? undefined : toFormattedDate({ year: yearEnd!, month: monthEnd! + 1, day: dateEnd! });

                if (newStartDate !== startDate || newEndDate !== endDate) {
                  // @ts-expect-error enabled strictNullChecks without fixing all errors in detail
                  onChange(newStartDate, newEndDate, range);
                }
              }}
            />
          </Styled.DatePickerContainer>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default DatesPicker;
