import { Colors } from '..';
import generateGradient from './generateGradient';

const blueLight = {
  conical: generateGradient(
    [Colors.BlueLight[600], Colors.BlueLight[25]],
    'conical',
    {},
    {
      originDeg: 259.08,
      originX: 50,
      originY: 50,
      deg: [0, 360],
    }
  ),
  linear01: generateGradient(
    [Colors.BlueLight[600], Colors.BlueLight[500]],
    'linear',
    {
      originDeg: 90,
      percent: [0, 100],
    }
  ),
  linear02: generateGradient(
    [Colors.BlueLight[700], Colors.BlueLight[600]],
    'linear',
    {
      originDeg: 45,
      percent: [0, 100],
    }
  ),
  linear03: generateGradient(
    [Colors.BlueLight[800], Colors.BlueLight[600]],
    'linear',
    {
      originDeg: 45,
      percent: [0, 100],
    }
  ),
  linear04: generateGradient(
    [Colors.BlueLight[800], Colors.BlueLight[600]],
    'linear',
    {
      originDeg: 90,
      percent: [0, 100],
    }
  ),
  linear05: generateGradient(
    [Colors.BlueLight[800], Colors.BlueLight[700]],
    'linear',
    {
      originDeg: 26.5,
      percent: [0, 100],
    }
  ),
  linear06: generateGradient(
    [Colors.BlueLight[900], Colors.BlueLight[600]],
    'linear',
    {
      originDeg: 45,
      percent: [0, 100],
    }
  ),
} as const;

export default blueLight;
