import { CommonTagIconProps } from '../types';

const VeryColorful = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.88696 20.4111C12.0482 20.4111 14.611 17.8484 14.611 14.6871C14.611 11.5259 12.0482 8.96313 8.88696 8.96313C5.72569 8.96313 3.16296 11.5259 3.16296 14.6871C3.16296 17.8484 5.72569 20.4111 8.88696 20.4111Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.1129 20.4111C18.2742 20.4111 20.8369 17.8484 20.8369 14.6871C20.8369 11.5259 18.2742 8.96313 15.1129 8.96313C11.9516 8.96313 9.38892 11.5259 9.38892 14.6871C9.38892 17.8484 11.9516 20.4111 15.1129 20.4111Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12 15.0371C15.1613 15.0371 17.724 12.4744 17.724 9.31311C17.724 6.15183 15.1613 3.58911 12 3.58911C8.83872 3.58911 6.276 6.15183 6.276 9.31311C6.276 12.4744 8.83872 15.0371 12 15.0371Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default VeryColorful;
