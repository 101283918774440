import { Colors, Typography } from '@replai-platform/ui-components';
import styled from 'styled-components';

export const SidebarContainer = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const MainContainer = styled.div`
  display: grid;
  grid-column-gap: 1.5rem;
  grid-template-rows: 1fr;
  grid-template-columns: 320px 1fr;
  align-items: flex-start;
  margin-top: 2rem;
  padding-bottom: 4rem;
`;

export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.Gray[50]};
  border: 1px solid ${Colors.Gray[200]};
  border-radius: 0.5rem;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
`;

export const MetricsContainerTop = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  gap: 1.5rem;
  max-width: 845px;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const MetricsContainerBottom = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(135px, max-content));
  gap: 1.5rem;
  max-width: 845px;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  :not(:first-child) {
    margin-top: 2rem;
  }
`;

export const SectionBody = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr;
`;

export const MetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ValueContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1.25rem;
  margin-bottom: 1.5625rem;
  background-color: ${Colors.Gray[200]};
`;

export const TypographyClean = styled(Typography)`
  margin: 0;
  padding: 0;
`;

export const CardContainer = styled.div`
  grid-column: 1 / span 2;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  > [aria-hidden='true']:last-child {
    z-index: 101;
    display: none;
    width: auto;
  }
`;

export const CardHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: flex-start;
  justify-content: center;
`;

export const CardChart = styled.div`
  width: 100%;
  height: 300px;
  padding: 1rem;
`;

export const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 5rem;
  margin-top: 1.5rem;
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
  background-color: ${Colors.Common.Black};
  border-radius: 0.5rem;
`;
