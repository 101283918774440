import {
  Button,
  Colors,
  EmptyState,
  LoadingScreen,
  NotificationListItem,
  Shadow,
} from '@replai-platform/ui-components';
import styled from 'styled-components';
import { useRef } from 'react';
import { useClickAway } from 'react-use';
import { ProductUpdateDetails } from '@replai-platform/sdk';
import useMarkProductUpdatesAsRead from '../../api/hooks/productUpdates/useMarkProductUpdatesAsRead';
import { logEvent } from '../../analytics';

export type ProductUpdatesSidebarProps = {
  data: ProductUpdateDetails[];
  loading?: boolean;
  onClose?: (e: Event) => void;
  open: boolean;
};

const SIDEBAR_WIDTH = '20rem';

const ClosableContainer = styled(Shadow)<{ $open: boolean }>`
  position: fixed;
  top: 0;
  width: ${({ $open }) => ($open ? SIDEBAR_WIDTH : '0')};
  height: 100%;
  overflow: hidden;
  visibility: ${({ $open }) => ($open ? 'visible' : 'hidden')};
  transition-timing-function: ease;
  transition-duration: 0.2s;
  transition-property: visibility, width;
`;

const Content = styled.div`
  width: ${SIDEBAR_WIDTH};
  height: 100%;
  padding: 1rem 0;
  overflow: auto;
  background-color: ${Colors.Gray[50]};
  border: 1px solid ${Colors.Gray[200]};
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MarkAllAsReadWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const SidebarBody = ({ data, loading }: Pick<ProductUpdatesSidebarProps, 'data' | 'loading'>) => {
  const markProductUpdatesAsReadMutation = useMarkProductUpdatesAsRead();
  if (loading) {
    return <LoadingScreen />;
  }
  if (data.length === 0) {
    return (
      <EmptyState
        icon="Inbox"
        text="Nothing to display"
        supportingText="Come back later to be informed of the latest updates to the Replai Platform."
      />
    );
  }
  return (
    <>
      <MarkAllAsReadWrapper>
        <Button
          onClick={() => {
            logEvent({
              action: 'Mark all as read',
              category: 'user_actions',
              component: 'Product Updates',
            });
            if (data.some((e) => !e.read)) {
              markProductUpdatesAsReadMutation.mutate({
                body: { productUpdateIds: data.map((e) => e.id) },
                requestParams: {},
              });
            }
          }}
          variant="text"
        >
          Mark all as read
        </Button>
      </MarkAllAsReadWrapper>
      <ItemsWrapper>
        {data.map(({ appcuesId, description, id, read, title }) => (
          <NotificationListItem
            description={description}
            key={id}
            onClick={
              !read || appcuesId
                ? (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (appcuesId) {
                      window.Appcues?.show(appcuesId);
                    }
                    if (!read) {
                      markProductUpdatesAsReadMutation.mutate({ body: { productUpdateIds: [id] }, requestParams: {} });
                    }

                    logEvent({
                      action: 'Mark as read',
                      category: 'user_actions',
                      component: 'Product Updates',
                      parameters: {
                        productUpdateId: id,
                        productUpdateTitle: title,
                      },
                    });
                  }
                : undefined
            }
            title={title}
            unread={!read}
          />
        ))}
      </ItemsWrapper>
    </>
  );
};

const ProductUpdatesSidebar = ({ data, loading, onClose, open }: ProductUpdatesSidebarProps) => {
  const ref = useRef(null);
  useClickAway(ref, (e) => {
    onClose?.(e);
  });
  return (
    <ClosableContainer $open={open}>
      <Content ref={ref} data-test="product-updates-sidebar">
        <SidebarBody data={data} loading={loading} />
      </Content>
    </ClosableContainer>
  );
};

export default ProductUpdatesSidebar;
