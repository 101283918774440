import * as SDK from '@replai-platform/sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BarChart, LoadingBars } from '@replai-platform/ui-components';
import { kpiUtils, Metrics } from '@replai-platform/sdk';
import { useQuery } from 'react-query';
import { LoadingContainer } from '../../utils/styles';
import { RootState } from '../../store/rootReducer';
import { api } from '../../api';
import { FiltersState } from '../../store/filters';

async function getMetricsHistoryEntries({
  projectId,
  filters,
  metrics,
  conceptId,
  assetId,
}: {
  projectId: string;
  filters: FiltersState;
  metrics: SDK.MetricOrKpi[];
  conceptId?: SDK.UUID;
  assetId?: SDK.UUID;
}): Promise<
  {
    name: string;
    globalMetrics: Record<string, any>;
    assetMetrics: Record<string, any>;
    [key: string]: any;
  }[]
> {
  const [globalHistory, assetHistory] = await Promise.all([
    api.metrics.getHistoryMetrics({
      projectIds: [projectId],
      metrics,
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
    }),
    api.metrics.getHistoryMetrics({
      projectIds: [projectId],
      metrics,
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      conceptId,
      assetId,
    }),
  ]);

  return globalHistory.history.map((entry) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const copyObjectExcludingDate = (object) => (({ date, ...o }) => o)(object);
    let assetMetrics = assetHistory.history.find((e) => e.date === entry.date);
    if (assetMetrics) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      assetMetrics = copyObjectExcludingDate(assetMetrics);
    }

    const historyEntry = {
      name: entry.date,
      globalMetrics: entry?.metrics,
      assetMetrics: assetMetrics?.metrics ?? {},
    };
    metrics.forEach((metric) => {
      historyEntry[metric] = assetMetrics?.metrics[metric];
    });
    return historyEntry;
  });
}

const CreativeMetricsBarChart = ({ assetId }: { assetId: SDK.UUID }) => {
  const filters = useSelector((state: RootState) => state.filters);
  const projectId = useSelector((state: RootState) => state.project.id);

  const bars = [
    {
      name: kpiUtils.getDisplayName(Metrics.CLICKS),
      accessor: 'clicks',
    },
    {
      name: kpiUtils.getDisplayName(Metrics.IMPRESSIONS),
      accessor: 'impressions',
    },
  ];

  const getVideoImpressionClicksParams = useMemo(
    () => ({
      projectId,
      filters: {
        ...filters,
        assetTypes: [], // No need to filter for asset type since we fetch the asset ID.
      },
      assetId,
      metrics: [Metrics.CLICKS, Metrics.IMPRESSIONS],
    }),
    [JSON.stringify(filters), assetId, projectId]
  );
  const { data, isLoading } = useQuery([getVideoImpressionClicksParams], () =>
    getMetricsHistoryEntries(getVideoImpressionClicksParams)
  );

  // aggregates all clicks and impressions from the same month
  // to show a per month bar in the graph
  const monthlyData = useMemo(() => {
    const newData: { name: string; clicks: number; impressions: number }[] = [];
    let current: (typeof newData)[number] = { name: '', clicks: 0, impressions: 0 };

    (data || []).forEach((value) => {
      if (!current.name) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        current = { name: value.name, clicks: value.clicks || 0, impressions: value.impressions || 0 };
        return;
      }

      const currentMonth = new Date(current.name).getMonth();
      if (currentMonth !== new Date(value.name).getMonth()) {
        newData.push(current);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        current = { name: value.name, clicks: value.clicks || 0, impressions: value.impressions || 0 };
        return;
      }

      current.clicks += value.clicks || 0;
      current.impressions += value.impressions || 0;
    });

    if (current.name !== '') newData.push(current);
    return newData;
  }, [JSON.stringify(data)]);

  if (isLoading || !data) {
    return (
      <LoadingContainer>
        <LoadingBars barSize="xxl" />
      </LoadingContainer>
    );
  }

  return (
    <BarChart
      data-test="chart"
      data={monthlyData}
      bars={bars}
      yValueFormatter={(v) => kpiUtils.format(Metrics.IMPRESSIONS, v)}
      xValueFormatter={(s) =>
        new Date(s).toLocaleDateString('en-US', {
          month: 'long',
        })
      }
    />
  );
};

export default CreativeMetricsBarChart;
