import * as SDK from '@replai-platform/sdk';
import { Avatar, AvatarProps, Icons } from '@replai-platform/ui-components';
import { useState } from 'react';
import styled from 'styled-components';
import useTagByTypeValue from '../../api/hooks/tags/useTagByTypeValue';
import { getTagIconDefinition } from '../../utils/getTagIcon';
import TagPreviewVideosWrapper from '../TagPreviewVideosWrapper';

const Container = styled.div`
  margin: 0.25rem;
  pointer-events: none;
`;

const InnerContainer = styled.div`
  border-radius: 50%;
  pointer-events: auto;
`;

export type TagAvatarProps = {
  projectId: SDK.UUID;
  tagId: SDK.UUID;
  tagType: string;
  tagValue: string | null;
  size?: AvatarProps['size'];
  onClick?: (tag: SDK.Tag) => void;
  thumbnailImgUrl?: string;
};

const TagAvatar = ({ projectId, size, tagId, tagType, tagValue, onClick, thumbnailImgUrl }: TagAvatarProps) => {
  const [open, setOpen] = useState(false);

  const { iconName: iconThumbnail, props: iconProps } = getTagIconDefinition({
    type: tagType,
    value: tagValue,
  });

  const { isLoading, data } = useTagByTypeValue(
    {
      fields: ['description', 'exampleUrl'],
      projectId,
      type: tagType,
      value: tagValue,
    },
    { enabled: thumbnailImgUrl === undefined }
  );

  const tagPreviewUrl = thumbnailImgUrl ?? (isLoading ? null : data?.exampleUrl);

  const shouldShowIconThumbnail = !!iconThumbnail;
  return (
    <Container>
      <InnerContainer>
        <Avatar
          clickable
          imgUrl={shouldShowIconThumbnail ? undefined : tagPreviewUrl}
          onClick={(e) => {
            // Prevents the default behavior with a custom one (e.g., Switching between tags on the TagPreviewDialog)
            if (onClick) onClick({ id: tagId, type: tagType, value: tagValue });
            else setOpen(true);

            e.preventDefault();
            e.stopPropagation();
          }}
          squared
          outline="hover"
          size={size}
          icon={shouldShowIconThumbnail ? iconThumbnail : undefined}
          iconType={shouldShowIconThumbnail ? Icons.IconType.TAG : undefined}
          iconProps={iconProps}
        />
        <TagPreviewVideosWrapper
          isOpen={open}
          tag={{ type: tagType, value: tagValue }}
          component="Tag Avatar"
          onClose={() => setOpen(false)}
        />
      </InnerContainer>
    </Container>
  );
};

export default TagAvatar;
