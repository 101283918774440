import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getTypeValueCounts = async (requestParams: SDK.GetTagsTypeValueCountsRequest) => {
  const res = await provider.getJson<object, SDK.GetTagsTypeValueCountsResponse>(
    `${SDK.BASE_URL()}/tags/type_value_counts?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useTagTypeValueCounts<R = SDK.GetTagsTypeValueCountsResponse>(
  requestParams: SDK.GetTagsTypeValueCountsRequest,
  options?: UseQueryOptions<SDK.GetTagsTypeValueCountsResponse, unknown, R>
) {
  return useQuery(['tag-type-value-counts', requestParams], () => getTypeValueCounts(requestParams), options);
}
