import { IntegrationIconProps } from './types';

const Marvel = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
      fill="#1FB6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6047 12.9212C15.6298 12.9323 13.825 18.2538 14.0188 20.5673C14.2142 22.9013 16.7968 22.2293 16.6329 20.8857C16.354 18.6015 17.8756 11.4438 19.4187 11.4438C20.5015 11.4438 18.2195 16.3782 18.6685 19.5379C18.9656 21.6284 21.662 20.9032 21.388 19.6637C20.4816 15.5615 23.29 11.619 22.9753 8.82577C22.8376 7.6036 22.1026 7.0059 21.3674 7.00006C18.7933 6.98025 16.8048 12.2592 16.8048 12.2592C16.8048 12.2592 17.0119 9.25881 15.0113 9.14866C11.9482 8.97986 8.91588 16.6458 9.00178 23.4907C9.02917 25.6744 11.747 25.3025 11.6041 23.5614C11.3383 20.3236 13.1435 12.9055 14.6047 12.9212Z"
      fill="white"
    />
  </svg>
);
export default Marvel;
