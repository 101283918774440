import { IntegrationIconProps } from './types';

const Uc = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 7.62758C2.81839 5.75172 5.68274 2 10.5931 2C16.8497 2 17.0873 6.71607 17.0873 8.38057C17.0873 12.0466 14.5113 13.811 11.9216 15.5847C9.30415 17.3775 6.67272 19.1798 6.67272 22.9647C6.67272 27.6412 10.5271 29.8869 12.4542 29.9398C3.06918 29.9398 2.1188 23.2421 2.1188 21.9343C2.1188 18.2182 3.86918 16.218 5.45027 14.4114C6.75251 12.9233 7.9399 11.5665 7.9399 9.49023C7.9399 5.36862 2.95038 6.12161 2 7.62758Z"
      fill="white"
    />
    <path
      d="M2 7.62758C2.81839 5.75172 5.68274 2 10.5931 2C16.8497 2 17.0873 6.71607 17.0873 8.38057C17.0873 12.0466 14.5113 13.811 11.9216 15.5847C9.30415 17.3775 6.67272 19.1798 6.67272 22.9647C6.67272 27.6412 10.5271 29.8869 12.4542 29.9398C3.06918 29.9398 2.1188 23.2421 2.1188 21.9343C2.1188 18.2182 3.86918 16.218 5.45027 14.4114C6.75251 12.9233 7.9399 11.5665 7.9399 9.49023C7.9399 5.36862 2.95038 6.12161 2 7.62758Z"
      fill="url(#paint0_linear_1334_626)"
    />
    <path
      d="M16.5726 13.9685C17.8133 13.5326 20.7226 12.304 22.4333 10.8773C22.2749 9.21282 22.9084 7.58795 23.5816 6.95386C24.4132 7.66721 24.8488 8.96182 24.9676 9.72802C26.4328 10.1243 29.4503 11.7967 29.7987 15.316C29.8938 15.9501 29.8383 16.6634 29.7987 16.9408C29.3763 17.139 28.1752 17.456 26.5912 17.2975C25.6039 17.1987 24.9217 16.7894 24.0538 16.2688C23.1812 15.7453 22.1209 15.1093 20.3741 14.563C18.4733 13.9685 16.8893 13.9685 16.5726 13.9685Z"
      fill="white"
    />
    <path
      d="M16.5726 13.9685C17.8133 13.5326 20.7226 12.304 22.4333 10.8773C22.2749 9.21282 22.9084 7.58795 23.5816 6.95386C24.4132 7.66721 24.8488 8.96182 24.9676 9.72802C26.4328 10.1243 29.4503 11.7967 29.7987 15.316C29.8938 15.9501 29.8383 16.6634 29.7987 16.9408C29.3763 17.139 28.1752 17.456 26.5912 17.2975C25.6039 17.1987 24.9217 16.7894 24.0538 16.2688C23.1812 15.7453 22.1209 15.1093 20.3741 14.563C18.4733 13.9685 16.8893 13.9685 16.5726 13.9685Z"
      fill="url(#paint1_linear_1334_626)"
    />
    <path
      d="M18.0377 15.2763C15.5034 14.8325 13.497 15.4613 12.8106 15.8312C18.4179 15.2288 21.6149 19.279 22.5125 21.3795C22.4201 21.6041 23.6608 21.6965 24.2152 21.6965C24.8067 21.6965 25.6799 21.3582 26.5102 21.0365C27.2364 20.7551 27.9298 20.4865 28.3731 20.468C29.1335 20.4363 29.7723 20.7454 29.9967 20.9039C30.0231 20.6001 29.9017 19.8418 29.2047 19.2394C28.7372 18.8353 28.1101 18.7965 27.3478 18.7492C26.6895 18.7084 25.9305 18.6614 25.0864 18.3675C24.4247 18.1372 23.7315 17.7394 22.9653 17.2998C21.6224 16.5291 20.0548 15.6296 18.0377 15.2763Z"
      fill="white"
    />
    <path
      d="M18.0377 15.2763C15.5034 14.8325 13.497 15.4613 12.8106 15.8312C18.4179 15.2288 21.6149 19.279 22.5125 21.3795C22.4201 21.6041 23.6608 21.6965 24.2152 21.6965C24.8067 21.6965 25.6799 21.3582 26.5102 21.0365C27.2364 20.7551 27.9298 20.4865 28.3731 20.468C29.1335 20.4363 29.7723 20.7454 29.9967 20.9039C30.0231 20.6001 29.9017 19.8418 29.2047 19.2394C28.7372 18.8353 28.1101 18.7965 27.3478 18.7492C26.6895 18.7084 25.9305 18.6614 25.0864 18.3675C24.4247 18.1372 23.7315 17.7394 22.9653 17.2998C21.6224 16.5291 20.0548 15.6296 18.0377 15.2763Z"
      fill="url(#paint2_linear_1334_626)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8898 28.5923C15.1862 28.5923 17.0477 26.7292 17.0477 24.4311C17.0477 22.1329 15.1862 20.2698 12.8898 20.2698C10.5935 20.2698 8.73189 22.1329 8.73189 24.4311C8.73189 26.7292 10.5935 28.5923 12.8898 28.5923ZM12.8898 26.2937C13.9177 26.2937 14.751 25.4598 14.751 24.4311C14.751 23.4023 13.9177 22.5684 12.8898 22.5684C11.8619 22.5684 11.0286 23.4023 11.0286 24.4311C11.0286 25.4598 11.8619 26.2937 12.8898 26.2937Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8898 28.5923C15.1862 28.5923 17.0477 26.7292 17.0477 24.4311C17.0477 22.1329 15.1862 20.2698 12.8898 20.2698C10.5935 20.2698 8.73189 22.1329 8.73189 24.4311C8.73189 26.7292 10.5935 28.5923 12.8898 28.5923ZM12.8898 26.2937C13.9177 26.2937 14.751 25.4598 14.751 24.4311C14.751 23.4023 13.9177 22.5684 12.8898 22.5684C11.8619 22.5684 11.0286 23.4023 11.0286 24.4311C11.0286 25.4598 11.8619 26.2937 12.8898 26.2937Z"
      fill="url(#paint3_linear_1334_626)"
    />
    <path
      d="M8.49429 20.7335C9.04987 19.3754 10.8201 17.0201 14.157 17.0201C18.5525 17.0201 21.4829 20.8247 21.4829 24.7481C21.4829 25.8181 20.8493 27.5223 20.8493 27.5223C22.6709 27.5223 24.73 28.6319 24.73 29.6623C22.7422 29.8964 18.9513 30.0536 14.3769 29.9829C16.8272 29.3272 18.6317 27.0901 18.6317 24.4311C18.6317 21.2574 16.061 18.6846 12.8898 18.6846C11.1258 18.6846 9.54758 19.4807 8.49429 20.7335Z"
      fill="white"
    />
    <path
      d="M8.49429 20.7335C9.04987 19.3754 10.8201 17.0201 14.157 17.0201C18.5525 17.0201 21.4829 20.8247 21.4829 24.7481C21.4829 25.8181 20.8493 27.5223 20.8493 27.5223C22.6709 27.5223 24.73 28.6319 24.73 29.6623C22.7422 29.8964 18.9513 30.0536 14.3769 29.9829C16.8272 29.3272 18.6317 27.0901 18.6317 24.4311C18.6317 21.2574 16.061 18.6846 12.8898 18.6846C11.1258 18.6846 9.54758 19.4807 8.49429 20.7335Z"
      fill="url(#paint4_linear_1334_626)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_626"
        x1="27.0588"
        y1="2.58823"
        x2="2.76471"
        y2="29.7059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCA336" />
        <stop offset="1" stopColor="#FC7625" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_626"
        x1="27.0588"
        y1="2.58823"
        x2="2.76471"
        y2="29.7059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCA336" />
        <stop offset="1" stopColor="#FC7625" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_626"
        x1="27.0588"
        y1="2.58823"
        x2="2.76471"
        y2="29.7059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCA336" />
        <stop offset="1" stopColor="#FC7625" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1334_626"
        x1="27.0588"
        y1="2.58823"
        x2="2.76471"
        y2="29.7059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCA336" />
        <stop offset="1" stopColor="#FC7625" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1334_626"
        x1="27.0588"
        y1="2.58823"
        x2="2.76471"
        y2="29.7059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCA336" />
        <stop offset="1" stopColor="#FC7625" />
      </linearGradient>
    </defs>
  </svg>
);
export default Uc;
