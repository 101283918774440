import { CommonTagIconProps } from '../types';

const LowSaturation = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 21.165C17.0617 21.165 21.165 17.0617 21.165 12C21.165 6.93827 17.0617 2.83496 12 2.83496C6.93827 2.83496 2.83496 6.93827 2.83496 12C2.83496 17.0617 6.93827 21.165 12 21.165Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12 17.0159C14.7703 17.0159 17.016 14.7701 17.016 11.9999C17.016 9.22963 14.7703 6.98389 12 6.98389C9.22975 6.98389 6.98401 9.22963 6.98401 11.9999C6.98401 14.7701 9.22975 17.0159 12 17.0159Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.585 13.6379H7.479"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default LowSaturation;
