/* eslint-disable react/no-array-index-key */
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Colors } from '..';

type MessageType = 'p' | 'h1' | 'h2' | 'h6';

/**
 *
 * Wrapper around words
 *
 * @param children children to render
 * @returns
 */
const Wrapper = ({ children }: React.HTMLAttributes<HTMLSpanElement>) => (
  // We'll do this to prevent wrapping of words using CSS
  <span style={{ whiteSpace: 'nowrap' }}>{children}</span>
);

/**
 *
 *  Handles the deconstruction of each word and character
 *  to setup for the individual character animations
 *
 * @param message message too display
 * @param type html element type
 * @param visisble if message should be visible or not
 * @param fontColor font color to apply on message
 * @param fontSize font size to apply on message
 * @returns
 */
const AnimatedCharacters = ({
  message,
  type = 'p',
  visible = true,
  fontSize = 15,
}: {
  message: string;
  type: MessageType;
  visible?: boolean;
  fontSize?: number;
}) => {
  const color = Colors.Primary[600];
  // Framer Motion variant object, for controlling animation
  const item = {
    hidden: {
      y: '200%',
      color,
      fontSize,
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 },
    },
    visible: {
      y: 0,
      color,
      fontSize,
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 },
    },
  };

  //  Split each word of message into an array
  const splitWords = message.split(' ');

  // Create storage array
  const words: string[][] = [];

  // Push each word into words array
  splitWords.forEach((value) => {
    // Split each word from the sentence && add a space ("\u00A0") to the end of each word
    const values = value.split('');
    values.push('\u00A0');
    words.push(values);
  });

  // Get the tag name from tagMap
  const Tag = type;

  return (
    <Tag>
      {words.map((word, wordIndex: number) => (
        // Wrap each word in the Wrapper component
        <Wrapper key={`word-${wordIndex}`}>
          {word.flat().map((element, index: number) => (
            <span
              style={{
                overflow: 'hidden',
                display: 'inline-block',
              }}
              key={`span-${index}`}
            >
              <AnimatePresence>
                {visible && (
                  <motion.span
                    style={{ display: 'inline-block' }}
                    variants={item}
                  >
                    {element}
                  </motion.span>
                )}
              </AnimatePresence>
            </span>
          ))}
        </Wrapper>
      ))}
    </Tag>
  );
};

export default AnimatedCharacters;
