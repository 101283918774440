/* eslint-disable react/jsx-props-no-spreading */
import { ChangeEventHandler, useState } from 'react';
import styled from 'styled-components';
import VerticalMenuItem, {
  VerticalMenuItemProps,
  VerticalMenuItemVariant,
} from './VerticalMenuItem/VerticalMenuItem';
import Colors from '../Colors';
import Input, { InputProps } from '../Input/Input';
import SkeletonLoading from '../Skeleton/Skeleton';

const VerticalMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 16.25rem;
  min-width: 16.25rem;
  height: 100%;
  padding: 1.5rem 0rem 1rem 1rem;
  background: ${Colors.Gray[25]};
  border-right: 1px solid ${Colors.Gray[200]};
`;

const SearchContainer = styled.div`
  align-self: center;
  width: 100%;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
`;

const MenuItemsWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const MenuItemsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
`;

const DividerContainer = styled.div`
  margin-bottom: 0.8rem;
  margin-right: 1rem;
  border-bottom: 1px solid ${Colors.Gray[200]};
`;

const LoaderContainer = styled.div`
  height: 2.5rem;
  margin-right: 1rem;
`;

export type VerticalMenuProps = {
  items: VerticalMenuItemProps[];
  input?: InputProps;
  loading?: boolean;
  onSearch?: (value: string) => void;
};

const VerticalMenu = ({
  items,
  input,
  loading,
  onSearch,
}: VerticalMenuProps) => {
  const [inputText, setInputText] = useState('');

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputText(e.target.value);
    onSearch?.(e.target.value);
  };

  return (
    <VerticalMenuContainer>
      <SearchContainer>
        <Input
          {...input}
          value={inputText}
          leadingIcon="Search"
          placeholder="Search"
          onChange={onSearchChange}
        />
      </SearchContainer>
      <MenuItemsWrapper>
        <MenuItemsContainer data-test="vertical-menu-item-container">
          <DividerContainer key="divider" />
          {loading
            ? Array.from(Array(18), (_val, index) => (
                <LoaderContainer key={index}>
                  <SkeletonLoading height="1.5rem" />
                </LoaderContainer>
              ))
            : items
                .filter(
                  (item) =>
                    item.variant === VerticalMenuItemVariant.DIVIDER ||
                    item.label?.toLowerCase().includes(inputText.toLowerCase())
                )
                .map((item, index) => (
                  <VerticalMenuItem
                    key={item.label || item.id || index}
                    id={item.id}
                    label={item.label}
                    variant={item.variant}
                    icon={item.icon}
                    badgeText={item.badgeText}
                    isSelected={item.isSelected}
                    onClick={item.onClick}
                    onIconClick={item.onIconClick}
                    tooltipText={item.tooltipText}
                  />
                ))}
        </MenuItemsContainer>
      </MenuItemsWrapper>
    </VerticalMenuContainer>
  );
};

export default VerticalMenu;
