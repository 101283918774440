import { IntegrationIconProps } from './types';

const Facetime = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 11.9556C2 8.47078 2 6.7284 2.67818 5.39739C3.27473 4.22661 4.22661 3.27473 5.39739 2.67818C6.7284 2 8.47078 2 11.9556 2H20.0444C23.5292 2 25.2716 2 26.6026 2.67818C27.7734 3.27473 28.7253 4.22661 29.3218 5.39739C30 6.7284 30 8.47078 30 11.9556V20.0444C30 23.5292 30 25.2716 29.3218 26.6026C28.7253 27.7734 27.7734 28.7253 26.6026 29.3218C25.2716 30 23.5292 30 20.0444 30H11.9556C8.47078 30 6.7284 30 5.39739 29.3218C4.22661 28.7253 3.27473 27.7734 2.67818 26.6026C2 25.2716 2 23.5292 2 20.0444V11.9556Z"
      fill="url(#paint0_linear_1334_794)"
    />
    <path
      d="M6.31141 11.512C6 12.1044 6 12.8799 6 14.4308V17.5692C6 19.1201 6 19.8956 6.31141 20.488C6.58533 21.009 7.02242 21.4327 7.56003 21.6982C8.1712 22 8.97128 22 10.5714 22H15.3968C16.997 22 17.7971 22 18.4082 21.6982C18.9458 21.4327 19.3829 21.009 19.6568 20.488C19.9683 19.8956 19.9683 19.1201 19.9683 17.5692V14.4308C19.9683 12.8799 19.9683 12.1044 19.6568 11.512C19.3829 10.991 18.9458 10.5673 18.4082 10.3018C17.7971 10 16.997 10 15.3968 10H10.5714C8.97128 10 8.1712 10 7.56003 10.3018C7.02242 10.5673 6.58533 10.991 6.31141 11.512Z"
      fill="white"
    />
    <path
      d="M21.0573 13.4003L24.1751 10.8672C24.8982 10.2797 26 10.778 26 11.6925V20.3334C26 21.2435 24.9074 21.7433 24.1825 21.1647L21.0658 18.6769C20.7729 18.4431 20.6032 18.0946 20.6032 17.7269V14.3435C20.6032 13.9795 20.7694 13.6341 21.0573 13.4003Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_794"
        x1="16"
        y1="2"
        x2="16"
        y2="30"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61F677" />
        <stop offset="1" stopColor="#10BC2B" />
      </linearGradient>
    </defs>
  </svg>
);
export default Facetime;
