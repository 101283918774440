import {
  SectionHeader,
  EmptyStateProps,
  TableWithPaginationProps,
  TableWithPagination,
  Card,
  TagCard,
  IconOverlay,
  DropDownChipProps,
} from '@replai-platform/ui-components';
import { useCallback, useMemo, useState, cloneElement } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import TopNavPageTitle from '../../components/TopNavPageTitle';
import { RootState } from '../../store/rootReducer';
import { logEvent } from '../../analytics';
import {
  ProductionTrackCreativeColumnData,
  UNLINKED_TABLE_COLUMNS,
  getProductionTrackCreativeColumns,
} from './columns';
import SearchInput from '../../components/SearchInput';
import PreferencesModal from './components/PreferencesModal';
import TextImageToVideoModal from './components/TextImageToVideoModal';
import {
  CHARACTER_TO_VIDEO_TOOL_DEFAULT_VIDEO_PATH,
  TEXT_IMAGE_TO_IMAGE_TOOL_DEFAULT_IMAGE_PATH,
  TEXT_IMAGE_TO_VIDEO_TOOL_DEFAULT_VIDEO_PATH,
  TEXT_VIDEO_TO_VIDEO_TOOL_DEFAULT_VIDEO_PATH,
} from './constants';
import ConceptPreviewWrapper from './components/ConceptPreviewWrapper';
import { useGetAllProductionConcepts } from '../../api/hooks/creativeProduction/useGetAllProductionConcepts';

const ITEMS_PER_PAGE = 10;

const emptyStateProps = {
  icon: 'Search',
  text: 'No Creative Requests found',
  supportingText: 'Generate your first creative requests above',
} as EmptyStateProps;

export const TableContainer = styled.div`
  z-index: 0;
  width: 100%;
`;

export const FiltersGroup = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
`;

const GenerationToolsOuterContainer = styled.div`
  margin-top: 2rem;
`;

const GenerationToolsInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 0 0 2rem 0;
`;

const GenerationToolContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  ${(props) =>
    props.disabled
      ? `opacity: 0.5;
  filter: grayscale(90%);`
      : ''}
`;

const CardWrapperContainer = styled.div`
  display: flex;
  min-height: 50rem;
`;

const CreativeRequests = () => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const organizationName = useSelector((state: RootState) => state.project.organizationName);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [textImageToVideoModalOpen, setTextImageToVideoModalOpen] = useState<boolean>(false);
  const [activeConceptId, setActiveConceptId] = useState<string | null>(null);
  const [creativeRequestsTableOffset, setCreativeRequestsTableOffset] = useState<number>(0);
  const [usersToFilter, setUsersToFilter] = useState<string[]>([]);
  // const [conceptPreviewModalData, setConceptPreviewModalData] = useState<
  //   Partial<{ title: string; prompt: string; videos: ConceptPreviewVideo[] }>
  // >({
  //   title: undefined,
  // });

  const { textImageToVideoPath, textVideoToVideoPath, characterToVideoPath, textImageToImagePath } =
    useSelector((state: RootState) => state.project.config.creativeGenerationToolsAssets) ?? {};

  const GENERATION_TOOLS: {
    title: string;
    enabled: boolean;
    thumbnailUrl?: string;
    icons: IconOverlay[];
    videoUrl?: string;
    onClick?: () => void;
  }[] = [
    {
      title: 'Text/Image to Video',
      enabled: true,
      videoUrl: textImageToVideoPath ?? TEXT_IMAGE_TO_VIDEO_TOOL_DEFAULT_VIDEO_PATH,
      icons: [
        { icon: 'Type', position: 'left' },
        { icon: 'Image', position: 'left' },
        { icon: 'Video', position: 'right' },
      ],
      onClick: () => {
        setTextImageToVideoModalOpen(true);
      },
    },
    {
      title: 'Text/Video to Video',
      videoUrl: textVideoToVideoPath ?? TEXT_VIDEO_TO_VIDEO_TOOL_DEFAULT_VIDEO_PATH,
      enabled: false,
      icons: [
        { icon: 'Type', position: 'left' },
        { icon: 'Video', position: 'left' },
        { icon: 'Video', position: 'right' },
      ],
    },
    {
      title: 'Character Model to Video',
      enabled: false,
      videoUrl: characterToVideoPath ?? CHARACTER_TO_VIDEO_TOOL_DEFAULT_VIDEO_PATH,
      icons: [
        { icon: 'Codesandbox', position: 'left' },
        { icon: 'Video', position: 'right' },
      ],
    },
    {
      title: 'Text/Image to Image',
      thumbnailUrl: textImageToImagePath ?? TEXT_IMAGE_TO_IMAGE_TOOL_DEFAULT_IMAGE_PATH,
      enabled: false,
      icons: [
        { icon: 'Type', position: 'left' },
        { icon: 'Image', position: 'left' },
        { icon: 'Image', position: 'right' },
      ],
    },
  ];

  // Fetch Creative Requests list
  const { data: creativesList, isLoading } = useGetAllProductionConcepts(
    projectId, // minimal cache time because we want this query to be refreshed when changing pages
    { projectId, search: searchTerm },
    { cacheTime: 1 }
  );

  const tableData = useMemo(
    () =>
      (creativesList?.results ?? [])
        .filter((entry) =>
          usersToFilter.length > 0
            ? usersToFilter.includes(entry.versions[0]?.requestingUserEmail ?? organizationName)
            : true
        )
        .map<ProductionTrackCreativeColumnData>((entry) => ({
          conceptId: entry.conceptId,
          creativeId: entry.versions[0].assets[0]?.id,
          creativeName: entry.conceptName ?? '',
          creativeStatus: entry.versions[0].completionStatusPercentage,
          user: entry.versions[0]?.requestingUserEmail ?? organizationName,
          prompt: entry.prompt ?? '',
          createdAt: entry.createdAt,
          updatedAt: entry.updatedAt,
          videoUrl: entry.versions[0].assets[0]?.url,
        })),
    [creativesList?.results, usersToFilter, organizationName]
  );

  const onOffsetChange = useCallback<Exclude<TableWithPaginationProps['onPageUpdate'], undefined>>(
    ({ pageIndex }) => {
      if (pageIndex !== creativeRequestsTableOffset) {
        setCreativeRequestsTableOffset(pageIndex * ITEMS_PER_PAGE);

        logEvent({
          component: 'Creative Production Requests table',
          action: 'Change page',
          category: 'user_actions',
          parameters: { page: `Page ${pageIndex + 1}` },
        });
      }
    },
    [creativeRequestsTableOffset]
  );

  const onSearchTermChangeEnd = (term: string) => {
    setSearchTerm(term === '' ? undefined : term);
  };

  const activeConcept = creativesList?.results?.find((e) => e.conceptId === activeConceptId);

  const userFilter = useMemo(() => {
    const users = [
      ...new Set(
        creativesList?.results.map((creative) => creative.versions[0]?.requestingUserEmail ?? organizationName)
      ),
    ];

    const filter = {
      dropDownType: 'multiselect',
      dropDownOptions: users.map((user, index) => ({ id: index, label: user, selected: false })),
      placeHolder: 'All users',
      onChange: (options) => {
        setUsersToFilter(options.filter((option) => option.selected).map((option) => option.label ?? ''));
        setCreativeRequestsTableOffset(0);
      },
    } as DropDownChipProps;

    return filter;
  }, [creativesList?.results, organizationName]);

  return (
    <>
      <TopNavPageTitle
        title="Creative Generation"
        subtitle="Generate new videos based on your ideas and previous concepts, automatically"
        eventPrefix="creative-production-requests"
        customFilter={<PreferencesModal />}
        withCustomFilter
        showFilterBar
      />
      <GenerationToolsOuterContainer>
        <SectionHeader
          title="Generate a new concept"
          subtitle="Generate new videos or concepts based on your ideas"
          withDivider={false}
        />
        <GenerationToolsInnerContainer>
          {GENERATION_TOOLS.map((generationTool) => (
            <GenerationToolContainer disabled={!generationTool.enabled}>
              <TagCard
                title={generationTool.title}
                thumbnailUrl={generationTool.thumbnailUrl}
                videoUrl={generationTool.videoUrl ? new URL(generationTool.videoUrl) : undefined}
                iconsOverlay={generationTool.icons}
                width="18rem"
                objectFit="cover"
                hoveringDelaySeconds={0}
                onClick={generationTool.onClick}
              />
            </GenerationToolContainer>
          ))}
        </GenerationToolsInnerContainer>
      </GenerationToolsOuterContainer>
      <SectionHeader
        title="Generated Concepts"
        subtitle="See all previously generated concepts and all their versions"
        withDivider={false}
        dropDownChips={[userFilter]}
      />
      <CardWrapperContainer>
        <Card fullWidth disableContentPadding>
          <FiltersGroup data-test="table-filter">
            <SearchInput
              data-test="table-search-input"
              initialSearchTerm={searchTerm}
              onSearchTermChangeEnd={onSearchTermChangeEnd}
            />
          </FiltersGroup>
          <TableContainer>
            <TableWithPagination
              columns={getProductionTrackCreativeColumns(projectId)}
              data={tableData.slice(creativeRequestsTableOffset, creativeRequestsTableOffset + ITEMS_PER_PAGE)}
              // eslint-disable-next-line react/no-unstable-nested-components
              cellLink={({ cell, anchorFactory }) => {
                if (UNLINKED_TABLE_COLUMNS.includes(cell.column.id)) return undefined;
                return cloneElement(anchorFactory({}), {
                  onClick: () => {
                    setActiveConceptId(cell.row.original.conceptId);
                  },
                });
              }}
              disableAutoExpanderColumn
              rowsPerPage={ITEMS_PER_PAGE}
              rowsTotal={tableData.length ?? ITEMS_PER_PAGE}
              pageIndex={creativeRequestsTableOffset / ITEMS_PER_PAGE}
              onPageUpdate={onOffsetChange}
              emptyStateProps={emptyStateProps}
              // TODO: make row clickable and call setActiveConceptId().
              loading={
                isLoading
                  ? {
                      messages: ['Fetching requested creatives', 'Getting everything ready'],
                    }
                  : undefined
              }
            />
          </TableContainer>
        </Card>
      </CardWrapperContainer>
      {activeConcept && (
        <ConceptPreviewWrapper
          concept={activeConcept}
          isOpen={!!activeConceptId}
          onClose={() => setActiveConceptId(null)}
        />
      )}

      <TextImageToVideoModal modalOpen={textImageToVideoModalOpen} setModalOpen={setTextImageToVideoModalOpen} />
    </>
  );
};

export default CreativeRequests;
