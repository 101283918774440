/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState } from 'react';
import { ButtonIcon, ButtonSize, ButtonVariant, ColorTypes } from '..';
import ButtonGroupContext from './context';
import * as Styled from './styles';

export interface ContainerProps {
  children?: React.ReactNode;
  color?: ColorTypes;
  variant?: ButtonVariant;
  size?: ButtonSize;
  leadingIcon?: ButtonIcon;
  trailingIcon?: ButtonIcon;
}

const Container = ({
  children,
  color = 'Gray',
  variant = 'outlined',
  size = 'md',
  leadingIcon,
  trailingIcon,
}: ContainerProps) => {
  const [position, setPosition] = useState<string[]>([]);

  return (
    <Styled.Root data-test="button-group-container">
      <ButtonGroupContext.Provider
        value={{
          color,
          position,
          leadingIcon,
          size,
          trailingIcon,
          variant,
          pushToPosition: (id: string) => setPosition([...position, id]),
        }}
      >
        {children}
      </ButtonGroupContext.Provider>
    </Styled.Root>
  );
};

export default Container;
