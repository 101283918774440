import { CountryIconProps } from './types';

const BE = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3571)">
      <path
        d="M16.1735 0.746513C14.8735 0.264169 13.4675 0.00012207 11.9996 0.00012207C10.5318 0.00012207 9.1257 0.264169 7.82571 0.746513L6.78223 12.0001L7.82571 23.2537C9.1257 23.7361 10.5318 24.0001 11.9996 24.0001C13.4675 24.0001 14.8735 23.7361 16.1735 23.2537L17.217 12.0001L16.1735 0.746513Z"
        fill="#FFDA44"
      />
      <path
        d="M23.9999 11.9993C23.9999 6.83976 20.7434 2.4412 16.1738 0.745728V23.253C20.7434 21.5574 23.9999 17.1589 23.9999 11.9993Z"
        fill="#D80027"
      />
      <path
        d="M0 12C0 17.1596 3.2565 21.5581 7.82611 23.2537V0.74646C3.2565 2.44193 0 6.84049 0 12Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3571">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default BE;
