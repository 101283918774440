import { CountryIconProps } from './types';

const UY = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3724)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 8.86873H23.5866C23.2857 7.75245 22.8284 6.70053 22.2387 5.73828H12V8.86873Z"
        fill="#338AF3"
      />
      <path
        d="M4.5296 21.3898H19.4693C20.5644 20.5176 21.5036 19.4581 22.2381 18.2594H1.76074C2.49532 19.4581 3.43451 20.5176 4.5296 21.3898Z"
        fill="#338AF3"
      />
      <path
        d="M12 -0.000854492C12 1.04263 12 2.60783 12 2.60783H19.4699C17.42 0.975271 14.8242 -0.000854492 12 -0.000854492Z"
        fill="#338AF3"
      />
      <path
        d="M12 8.86873H23.5866C23.2857 7.75245 22.8284 6.70053 22.2387 5.73828H12V8.86873Z"
        fill="#338AF3"
      />
      <path
        d="M0 12.0001C0 13.0833 0.144375 14.1324 0.413391 15.1306H23.5867C23.8556 14.1324 24 13.0833 24 12.0001H0Z"
        fill="#338AF3"
      />
      <path
        d="M10.435 7.02317L8.96941 7.71256L9.74983 9.13194L8.15842 8.82753L7.95677 10.4351L6.84836 9.25273L5.73986 10.4351L5.53825 8.82753L3.94684 9.13184L4.72722 7.71251L3.26172 7.02317L4.72727 6.33387L3.94684 4.9145L5.5382 5.21891L5.73991 3.61133L6.84836 4.7937L7.95681 3.61133L8.15842 5.21891L9.74988 4.9145L8.96945 6.33392L10.435 7.02317Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3724">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default UY;
