const ScoreText = ({ score }: { score: number }) => (
  <text
    fill="white"
    fontFamily="Inter"
    fontSize={score === 100 ? 22 : 30}
    fontWeight="700"
    textAnchor="middle"
    dominantBaseline="central"
    // numbers starting with 1 seem off centered because of letter width
    x={[1, 10].includes(Math.trunc(score / 10)) ? '48%' : '50%'}
    // we need to account for the star at the bottom
    y={score > 25 ? '47%' : '50%'}
  >
    {score}
  </text>
);

export default ScoreText;
