import { IntegrationIconProps } from './types';

const JbDatagrip = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.9428 4.98285L31.9999 18.0571L24.2285 22.5828L22.7656 15.1771L29.9428 4.98285Z"
      fill="#9775F8"
    />
    <path
      d="M29.9434 4.98286L18.5149 0L8.86914 8L22.7663 15.1771L29.9434 4.98286Z"
      fill="url(#paint0_linear_1334_1472)"
    />
    <path
      d="M21.622 32L8.22772 13.9886L4.25058 16.64L0.273438 28.5714L21.622 32Z"
      fill="url(#paint1_linear_1334_1472)"
    />
    <path
      d="M24.1371 22.9028L14.7657 16.7314L0 14.7657L21.6229 32L24.1371 22.9028Z"
      fill="url(#paint2_linear_1334_1472)"
    />
    <path
      d="M0 0.228577V14.7657L27.7943 24.32L29.9429 4.98286L0 0.228577Z"
      fill="url(#paint3_linear_1334_1472)"
    />
    <path d="M6.125 6.12567H25.8736V25.8742H6.125V6.12567Z" fill="black" />
    <path
      d="M8.13672 8.68567H11.3367C13.8967 8.68567 15.6796 10.4685 15.6796 12.7542V12.8C15.6796 15.0857 13.8967 16.8685 11.3367 16.8685H8.13672V8.68567ZM9.91958 10.3314V15.2685H11.291C12.7539 15.2685 13.7596 14.2628 13.7596 12.8457V12.8C13.7596 11.3371 12.7539 10.3314 11.291 10.3314H9.91958Z"
      fill="white"
    />
    <path
      d="M16 12.8C16 10.4685 17.8286 8.54852 20.2971 8.54852C21.76 8.54852 22.6743 8.95995 23.4971 9.69138L22.3543 11.0628C21.7143 10.5142 21.1657 10.24 20.2057 10.24C18.88 10.24 17.8743 11.3828 17.8743 12.8C17.8743 14.3085 18.88 15.4057 20.3429 15.4057C20.9829 15.4057 21.5771 15.2228 22.0343 14.9028V13.7142H20.2057V12.16H23.7714V15.7257C22.9486 16.4571 21.76 17.0057 20.2971 17.0057C17.7371 17.0057 16 15.2228 16 12.8Z"
      fill="white"
    />
    <path d="M7.95312 22.1714H15.3588V23.4057H7.95312V22.1714Z" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_1334_1472"
        x1="18.775"
        y1="8.06537"
        x2="21.2673"
        y2="1.85417"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9775F8" />
        <stop offset="0.952" stopColor="#22D88F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_1472"
        x1="7.80121"
        y1="16.5765"
        x2="11.0373"
        y2="30.6766"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9775F8" />
        <stop offset="0.214" stopColor="#689CCE" />
        <stop offset="0.423" stopColor="#42BDAC" />
        <stop offset="0.59" stopColor="#2BD197" />
        <stop offset="0.694" stopColor="#22D88F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_1472"
        x1="2.24"
        y1="15.557"
        x2="30.2807"
        y2="31.0391"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.075" stopColor="#22D88F" />
        <stop offset="0.72" stopColor="#9775F8" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1334_1472"
        x1="0"
        y1="12.2743"
        x2="28.181"
        y2="12.2743"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.075" stopColor="#22D88F" />
        <stop offset="0.266" stopColor="#5AB0B4" />
        <stop offset="0.565" stopColor="#B86CF2" />
        <stop offset="1" stopColor="#FF59E6" />
      </linearGradient>
    </defs>
  </svg>
);
export default JbDatagrip;
