/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react';
import styled from 'styled-components';
import { Colors, Button, Typography, Input } from '@replai-platform/ui-components';
import { goToUrl, isValidEmail } from '../../utils';

type SignInProps = {
  signIn: (email: string, password: string) => Promise<void>;
  goToView: (view: string) => void;
};

const SignInContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
`;

const Content = styled.div`
  width: 21rem;
  margin-top: 7.5rem;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ContactUsContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
`;

// TODO: remove once it's fixed in UI library
const ContactUsButton = styled(Button)`
  width: unset;
  min-width: unset;
  height: unset;
  min-height: unset;
  margin-bottom: 1em;
`;

const initialErrorMessages: {
  email: string;
  password: string;
  form: string;
} = {
  email: '',
  password: '',
  form: '',
};

const SignIn = ({ signIn, goToView }: SignInProps) => {
  const [{ email, password }, setSignInInfo] = useState({ email: '', password: '' });
  const [signInDisabled, setSignInDisabled] = useState(false);
  const [errorMessages, setErrorMessages] = useState(initialErrorMessages);

  const isValidForm = () => {
    let isValid = true;

    if (!isValidEmail(email)) {
      setErrorMessages((m) => ({ ...m, email: 'Email is empty or not valid.' }));
      isValid = false;
    }

    if (!password) {
      setErrorMessages((m) => ({ ...m, password: 'Password cannot be empty.' }));
      isValid = false;
    }

    return isValid;
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.name) {
      setErrorMessages(initialErrorMessages);
      setSignInInfo((info) => ({ ...info, [event.target.name]: event.target.value }));
    }
  };

  const onSignInClick = async () => {
    if (!isValidForm()) {
      return;
    }

    setSignInDisabled(true);

    try {
      await signIn(email, password);
    } catch (e) {
      if (e instanceof Error) {
        const { message = '' } = e ?? {};
        setErrorMessages((m) => ({ ...m, form: message ?? 'Sign in failed.' }));
      } else {
        setErrorMessages((m) => ({ ...m, form: 'Sign in failed.' }));
      }

      setSignInDisabled(false);
    }
  };

  return (
    <SignInContainer>
      <Content>
        <Typography type="display-sm" fontWeight="semi-bold">
          Sign in to Replai
        </Typography>
        <Typography type="text-md" fontWeight="regular" color={Colors.Gray[500]}>
          Welcome back! Please enter your details.
        </Typography>
        <FormContainer>
          <Input
            type="email"
            label="Email"
            name="email"
            placeholder="Enter your email"
            value={email}
            onChange={onInputChange}
            hintText={errorMessages.email}
            error={!!errorMessages.email || !!errorMessages.form}
            onKeyPress={(e) => (e.key === 'Enter' ? onSignInClick() : undefined)}
          />
          <Input
            type="password"
            label="Password"
            name="password"
            placeholder="Enter your password"
            value={password}
            onChange={onInputChange}
            hintText={errorMessages.password || errorMessages.form}
            error={!!errorMessages.password || !!errorMessages.form}
            onKeyPress={(e) => (e.key === 'Enter' ? onSignInClick() : undefined)}
          />
          <Button
            size="lg"
            onClick={onSignInClick}
            disabled={signInDisabled}
            leadingIcon={signInDisabled ? { name: 'LoadingCircle' } : undefined}
          >
            {signInDisabled ? 'Signing in...' : 'Sign in'}
          </Button>
          <Button size="md" variant="text" onClick={() => goToView('set-password')}>
            Forgot password
          </Button>
          <ContactUsContainer>
            <Typography type="text-sm" color={Colors.Gray[500]}>
              Don&apos;t have an account?
            </Typography>
            <ContactUsButton
              data-test="contact-us-button"
              size="md"
              variant="text"
              onClick={() => goToUrl('https://www.replai.io/')}
            >
              Contact us
            </ContactUsButton>
          </ContactUsContainer>
        </FormContainer>
      </Content>
    </SignInContainer>
  );
};

export default SignIn;
