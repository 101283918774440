import * as SDK from '@replai-platform/sdk';
import styled from 'styled-components';
import type { Row } from 'react-table';
import { Skeleton } from '@replai-platform/ui-components';
import { logEvent } from '../../../analytics';
import TagAvatar from '../../TagAvatar';
import VideoAvatar from '../../VideoAvatar';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';
import ClickableAvatar from '../../ClickableAvatar';
import PlayableAvatar from '../../PlayableAvatar';

const CreativeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const VideoAvatarWrapper = styled.div`
  display: flex;
`;

const Line = styled.div`
  display: flex;
`;

const ImgBox = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
  }
`;

export type NameAccessorType = {
  tagType?: string | null;
  tagValue: string | null;
  previewUrl?: string;
  objectId?: string;
  objectLevel?: SDK.ObjectLevel;
  assetType?: SDK.AssetType;
  conceptId?: string;
  isActive?: boolean;
  isMarketData?: boolean;
  projectId?: string;
  onTagClick?: (tag: SDK.Tag) => void;
  tagId?: string | null;
  tagThumbnailImgUrl?: string;
  loading?: boolean;
  rowHref?: string;
  allowEdit?: boolean;
};

export const PreviewCellDecorator: React.FC<{
  cellData: NameAccessorType;
  row: Row<any>;
}> = ({ cellData, row }) => {
  if (!cellData) {
    return null;
  }

  if (cellData.loading) {
    return <Skeleton height={20} width="100%" />;
  }

  if (!cellData.tagId) {
    if (cellData.assetType === SDK.AssetType.Image) {
      return (
        <ClickableAvatar
          avatarImageUrl={cellData.previewUrl ?? ''}
          dialogContent={
            <ImgBox>
              <img alt={cellData.objectId ?? ''} src={cellData.previewUrl} />
            </ImgBox>
          }
        />
      );
    }

    if (cellData.assetType === SDK.AssetType.Playable) {
      return <PlayableAvatar assetId={cellData.objectId ?? ''} />;
    }

    return (
      <VideoAvatarWrapper>
        <Line id="videoAvatarLine">
          <VideoAvatar
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            objectId={cellData.objectId!}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            objectLevel={cellData.objectLevel!}
            conceptId={cellData.conceptId}
            isActive={cellData.isActive}
            isMarketData={cellData.isMarketData}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            projectId={cellData.projectId!}
            videoHref={cellData.rowHref}
            eventOrigin="Videos"
            allowEdit={false}
            allowCopy={false}
            linkOnClick={() => {
              logEvent({
                component: 'Videos',
                action: row.depth === 0 ? 'Click on Row' : 'Click on Subrow',
                category: 'user_actions',
                parameters: {
                  assetId: row.depth === 0 ? cellData.conceptId : cellData.objectId,
                },
              });
            }}
          />
        </Line>
      </VideoAvatarWrapper>
    );
  }

  return (
    <CreativeContainer>
      <TagAvatar
        tagId={cellData.tagId}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        tagType={cellData.tagType!}
        tagValue={cellData.tagValue}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        projectId={cellData.projectId!}
        onClick={cellData?.onTagClick}
        thumbnailImgUrl={cellData.tagThumbnailImgUrl}
      />
    </CreativeContainer>
  );
};

function getPreviewColumn<T extends object = NameAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T, NameAccessorType>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<T, NameAccessorType>) => (
      <PreviewCellDecorator cellData={data?.cell?.value} row={data?.cell?.row} />
    ),
  };
}

export default getPreviewColumn;
