import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getNetworks = async (requestParams: SDK.GetNetworksRequest) =>
  provider.getJson(`${SDK.BASE_URL()}/networks?${SDK.convertRequestToQueryParams(requestParams)}`);

export function useNetworks<R = SDK.GetNetworksResponse>(
  requestParams: SDK.GetNetworksRequest,
  options?: UseQueryOptions<SDK.GetNetworksResponse, unknown, R>
) {
  return useQuery(['networks', requestParams], () => getNetworks(requestParams), options);
}

const getConnectedNetworkAccounts = async (requestParams: SDK.GetNetworksConnectedRequest) =>
  provider.getJson(`${SDK.BASE_URL()}/networks/connected?${SDK.convertRequestToQueryParams(requestParams)}`);

export function useConnectedNetworkAccounts<R = SDK.GetNetworksConnectedResponse>(
  requestParams: SDK.GetNetworksConnectedRequest,
  options?: UseQueryOptions<SDK.GetNetworksConnectedResponse, unknown, R>
) {
  return useQuery(
    ['connectedNetworkAccounts', requestParams],
    () => getConnectedNetworkAccounts(requestParams),
    options
  );
}
