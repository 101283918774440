import Masonry from 'react-masonry-css';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { Skeleton, camelCaseToCapitalCase } from '@replai-platform/ui-components';
import { CreativeDetailsData } from '../../../../../utils/types';
import * as Style from './styles';
import TabWithSidebar from '../shared/TabWithSidebar';
import { RootState } from '../../../../../store/rootReducer';
import useTagPreviewInfo from '../../../../../api/hooks/tags/useTagPreviewInfo';

const NUM_LOADING_CONTAINERS = 7;

const StaticTagsTab: React.VFC<{
  creativeData: Partial<CreativeDetailsData>;
  isLoading: boolean;
}> = ({ creativeData, isLoading }) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectCustomTags = useSelector((state: RootState) => state.project.config.tagsTypes?.custom);

  const { tagIds, formattedTagsList } = useMemo(() => {
    const filteredTags =
      creativeData.tags?.filter((tag) => !projectCustomTags || projectCustomTags.includes(tag.type)) ?? [];
    return {
      tagIds: Array.from(
        new Set(
          filteredTags.reduce<string[]>((acc, { id }) => {
            acc.push(id);
            return acc;
          }, [])
        )
      ),
      formattedTagsList:
        filteredTags?.reduce<{
          [key: string]: { id: string; type: string; value: string | null }[];
        }>((acc, curr) => {
          if (acc[curr.type]) {
            acc[curr.type].push(curr);
          } else {
            acc[curr.type] = [curr];
          }
          return acc;
        }, {}) ?? {},
    };
  }, [creativeData.tags, projectCustomTags]);

  const { data: tagPreviews } = useTagPreviewInfo({ projectIds: [projectId], tagIds }, { enabled: tagIds.length > 0 });

  return (
    <TabWithSidebar
      url={creativeData.url ?? ''}
      isLoading={isLoading}
      mainContent={
        !isLoading && Object.keys(formattedTagsList).length === 0 ? (
          <span style={{ marginTop: '2rem' }}>No tags available for this creative yet. Please try again later.</span>
        ) : (
          <Masonry
            breakpointCols={{ default: 5, 2050: 5, 1790: 4, 1530: 3 }}
            className="masonry-grid"
            columnClassName="masonry-grid-column"
          >
            {isLoading
              ? Array.from(Array(NUM_LOADING_CONTAINERS)).map(() => (
                  <Style.TagCategoryContainer>
                    <Skeleton height="3rem" width="10rem" />
                  </Style.TagCategoryContainer>
                ))
              : Object.keys(formattedTagsList)
                  .sort()
                  .map((key) => (
                    <Style.TagCategoryContainer>
                      <Style.Name data-test="name" variant="subtitle1">
                        {camelCaseToCapitalCase(key)}
                      </Style.Name>
                      <div>
                        {formattedTagsList[key].map((tag) => (
                          <Style.TagEntryContainer>
                            <Style.TagThumbnailContainer>
                              <Style.TagThumbnailImg
                                alt={`Tag thumbnail for ${tag.type} ${tag.value ?? ''}`}
                                src={
                                  tagPreviews?.tags.find((previewTag) => previewTag.tagId === tag.id)?.thumbnails[0]
                                    ?.imageUrl ?? creativeData.url
                                }
                              />
                            </Style.TagThumbnailContainer>
                            <Style.TagNameContainer>
                              <span>{tag.value}</span>
                            </Style.TagNameContainer>
                          </Style.TagEntryContainer>
                        ))}
                      </div>
                    </Style.TagCategoryContainer>
                  ))}
          </Masonry>
        )
      }
    />
  );
};

export default StaticTagsTab;
