import { TagBreakdownWithId } from '../../../utils/types';

export const ACCEPTABLE_TIMELINE_OFFSET_SECONDS = 0.8;
export const ACCEPTABLE_TIMELINE_DURATION_SECONDS = 1;

export enum TagDifference {
  NOT_PRESENT = 'not-present',
  ADDED = 'added',
  POSITION = 'position',
  LENGTH = 'length',
  VALUE_CHANGED_OLD = 'value-changed-old',
  VALUE_CHANGED_NEW = 'value-changed-new',
  NO_SHOW = 'no-show',
}

export enum SegmentPositionDifference {
  BACKWARD = 'backward',
  FORWARD = 'forward',
}

// The "difference" key stores the type of difference between the two videos (eg: removed, other,
// position, length, etc..), and the "differenceDetails" stores any additional details about the
// difference (eg: the length difference, the position change, etc..)
export type TagBreakdownWithDifference = TagBreakdownWithId;
