import { CommonTagIconProps } from '../types';

const AspectRatio5By4 = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.72302 15.7012V18.9032C2.72302 19.1892 2.95502 19.4212 3.24102 19.4212H6.44302"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.557 19.4212H20.759C21.045 19.4212 21.277 19.1892 21.277 18.9032V15.7012"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.44302 4.5791H3.24102C2.95502 4.5791 2.72302 4.8111 2.72302 5.0971V8.2991"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.277 8.2991V5.0971C21.277 4.8111 21.045 4.5791 20.759 4.5791H17.557"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default AspectRatio5By4;
