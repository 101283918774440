import { DropDownButton } from '@replai-platform/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { kpiUtils } from '@replai-platform/sdk';
import { FilterActions } from '../../../store/filters';
import { RootState } from '../../../store/rootReducer';
import { logEvent } from '../../../analytics';

export const InstallsFilterContainer = styled.div`
  padding-left: 12px;
`;

export type InstallsFilterProps = {
  customState?: { value: number | null; onChange: (minInstalls: number | null) => void };
};

const InstallsFilter: React.VFC<InstallsFilterProps> = ({ customState }) => {
  const dispatch = useDispatch();
  const filtering = useSelector((state: RootState) => state.filters);

  if (customState) {
    return (
      <InstallsFilterContainer>
        <DropDownButton
          onClickCrossButton={() => {
            customState.onChange(null);
          }}
          onConfirm={(value) => {
            customState.onChange(+value);
          }}
          hideCrossButton={customState.value === null}
          placeHolder={customState.value ? `> ${kpiUtils.format('installs', customState.value)}` : 'Set min installs'}
          defaultValue={customState.value ?? undefined}
          title="Min Installs"
        />
      </InstallsFilterContainer>
    );
  }
  return (
    <InstallsFilterContainer hidden={!filtering.minInstalls}>
      <DropDownButton
        onClickCrossButton={() => {
          dispatch(FilterActions.changeMinInstalls({ value: 0, logEvent: false, removeMinInstalls: true }));
          logEvent({
            component: 'Active Filters',
            action: 'Remove Min Installs Filter',
            category: 'user_actions',
          });
        }}
        onConfirm={(value) => {
          dispatch(FilterActions.changeMinInstalls({ value: +value, logEvent: true }));
        }}
        placeHolder={filtering.minInstalls ? `> ${kpiUtils.format('installs', filtering.minInstalls)}` : ''}
        defaultValue={filtering.minInstalls}
        title="Min Installs"
      />
    </InstallsFilterContainer>
  );
};

export default InstallsFilter;
