type HelperDate = {
  year: number;
  month: number;
  day: number;
};

export function toFormattedDate({ year, month, day }: HelperDate): string {
  return `${year.toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}

export function dayObjectToIsoDate(dayObject: HelperDate | null, onChange = false) {
  if (!dayObject) {
    return null;
  }

  const date = new Date(
    `${dayObject.year.toString().padStart(4, '0')}-${dayObject.month.toString().padStart(2, '0')}-${dayObject.day
      .toString()
      .padStart(2, '0')}`
  );

  // TODO: Refactor all new Date() conversions to use moment.utc() to avoid timezone differences
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const userDate = new Date(date.getTime() + Math.abs(userTimezoneOffset));

  if (onChange) {
    return userDate;
  }
  const options = { year: 'numeric', month: 'short', day: 'numeric' } as const;
  return userDate.toLocaleDateString('en-US', options);
}

export function isoDateToDayObject(iso: string): HelperDate {
  const date = new Date(iso);
  return {
    year: date.getUTCFullYear(),
    month: date.getUTCMonth() + 1,
    day: date.getUTCDate(),
  };
}
