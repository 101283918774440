import { CountryIconProps } from './types';

const PH = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3687)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 0V12L3.51465 20.4853C5.68623 22.6568 8.68623 24 12 24C18.6273 24 24 18.6274 24 12C24 5.37262 12 0 12 0Z"
        fill="#D80027"
      />
      <path
        d="M12 0C8.68623 0 5.68623 1.34316 3.51465 3.51469C6.45887 6.45891 7.50404 7.50412 12 12H24C24 5.37262 18.6273 0 12 0Z"
        fill="#0052B4"
      />
      <path
        d="M8.21723 11.9998L6.75173 11.3104L7.53215 9.89111L5.94074 10.1956L5.73909 8.58789L4.63059 9.77027L3.52213 8.58789L3.32043 10.1956L1.72907 9.89106L2.50949 11.3104L1.04395 11.9998L2.50949 12.6891L1.72907 14.1085L3.32057 13.8041L3.52209 15.4117L4.63059 14.2293L5.73909 15.4117L5.94074 13.8041L7.53205 14.1085L6.75168 12.6892L8.21723 11.9998Z"
        fill="#FFDA44"
      />
      <path
        d="M3.20082 4.69519L3.68851 5.37689L4.48749 5.12382L3.98977 5.79825L4.47746 6.47996L3.68218 6.21507L3.18451 6.88946L3.19074 6.05133L2.39551 5.78639L3.19463 5.53332L3.20082 4.69519Z"
        fill="#FFDA44"
      />
      <path
        d="M3.20082 17.1095L3.68851 17.7912L4.48749 17.5381L3.98977 18.2126L4.47746 18.8943L3.68218 18.6294L3.18451 19.3038L3.19074 18.4656L2.39551 18.2007L3.19463 17.9476L3.20082 17.1095Z"
        fill="#FFDA44"
      />
      <path
        d="M10.1558 10.9032L9.66817 11.5849L8.86914 11.3317L9.36686 12.0062L8.87917 12.688L9.67445 12.423L10.1722 13.0974L10.1659 12.2593L10.9611 11.9944L10.162 11.7413L10.1558 10.9032Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3687">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default PH;
