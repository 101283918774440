import { Button, Colors, DropDownMenu, DropDownMenuOption } from '@replai-platform/ui-components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { logEvent } from '../../analytics';
import { DropdownWrapper } from '../../routes/Tags/TagsTable/styles';
import { groupingButtonLabel } from '../../routes/Tags/TagsTable/utils';
import { RootState } from '../../store/rootReducer';
import { Columns } from '../../utils/enums';
import ActiveFilterToggle from '../ActiveFilterToggle';
import IntroTagsToggle from '../IntroTagsToggle';
import CustomizeFieldsButton from '../CustomizeFieldsButton';
import SearchInput from '../SearchInput';
import * as Styled from './styles';
import { CUSTOMIZE_DIALOG_SUBTITLE, CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP } from '../../utils/constants';

interface Props {
  eventPrefix: string;
  // this can also have strings because some KPIs are not specified in the Columns enum
  tableColumns: (Columns | string)[];
  nonCustomizableColumns: Columns[];
  hideActiveFilter?: boolean;
  hideIntroTagsToggle?: boolean;
  initialSearchTerm?: string;
  onSearchTermChangeEnd: (newSearchTerm: string) => void;
  exportCSVEnabled?: boolean;
  onExportCSVClick?: () => void;
  csvName?: string;
  csvData?: string;
  csvLoading?: boolean;
  customizeVisibleTagsOptions?: DropDownMenuOption[];
  showCustomize?: boolean;
  subtitle?: string;
  disallowedTooltip?: string;
}

const TableToolbar = ({
  eventPrefix,
  hideActiveFilter = false,
  hideIntroTagsToggle = true,
  initialSearchTerm,
  onSearchTermChangeEnd,
  exportCSVEnabled,
  onExportCSVClick,
  csvName,
  csvData,
  csvLoading,
  customizeVisibleTagsOptions,
  showCustomize,
  tableColumns,
  nonCustomizableColumns,
  subtitle,
  disallowedTooltip,
}: Props): JSX.Element => {
  // Customize visible tags
  const group = useSelector((state: RootState) => state.tags.group);
  const prevGroup = usePrevious(group);
  const [groupingDropdownOpen, setGroupingDropdownOpen] = useState(false);
  const [csvClicked, setCsvClicked] = useState(false);
  useEffect(() => {
    if (groupingDropdownOpen && prevGroup !== group) {
      setGroupingDropdownOpen(false);
    }
  }, [group, groupingDropdownOpen, prevGroup]);

  const downloadCsv = () => {
    const link = document.createElement('a');
    if (link.download === undefined || !csvData) return;

    const blob = new Blob([csvData as unknown as BlobPart], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', csvName ?? 'download.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if ((csvLoading && !csvData) || !csvClicked) return;
    downloadCsv();
    setCsvClicked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvLoading, csvData, csvClicked]);

  return (
    <Styled.FiltersGroup data-test="table-filter">
      <SearchInput
        data-test="table-search-input"
        initialSearchTerm={initialSearchTerm}
        onSearchTermChangeEnd={onSearchTermChangeEnd}
      />
      <div style={{ flexGrow: 1 }} />
      {!hideIntroTagsToggle ? (
        <Styled.ItemContainer>
          <IntroTagsToggle />
        </Styled.ItemContainer>
      ) : undefined}
      {!hideActiveFilter ? (
        <Styled.ItemContainer>
          <ActiveFilterToggle />
        </Styled.ItemContainer>
      ) : undefined}
      {customizeVisibleTagsOptions && (
        <Styled.ItemContainer>
          <Button
            variant="outlined"
            color="Gray"
            onClick={() => setGroupingDropdownOpen(!groupingDropdownOpen)}
            trailingIcon={{ name: 'ChevronDown', color: Colors.Gray[900], dimension: 20 }}
          >
            {groupingButtonLabel(group)}
          </Button>
          <DropdownWrapper>
            {groupingDropdownOpen && (
              <DropDownMenu
                title="Customize visible tags"
                hasInput
                input={{ leadingIcon: 'Search', placeholder: 'Search' }}
                options={customizeVisibleTagsOptions}
                onClose={() => setGroupingDropdownOpen(false)}
              />
            )}
          </DropdownWrapper>
        </Styled.ItemContainer>
      )}
      {exportCSVEnabled ? (
        <Styled.ItemContainer>
          <Button
            data-test="table-csv-button"
            variant="outlined"
            color="Gray"
            size="md"
            disabled={csvLoading}
            leadingIcon={csvLoading ? { name: 'LoadingCircle' } : undefined}
            onClick={() => {
              logEvent({
                component: 'Table Actions',
                action: 'Export to CSV',
                category: 'user_actions',
              });
              onExportCSVClick?.();
              setCsvClicked(true);
              if (!csvLoading && csvData) {
                downloadCsv();
                setCsvClicked(false);
              }
            }}
          >
            {csvLoading ? 'Creating your export...' : 'Export to CSV'}
          </Button>
        </Styled.ItemContainer>
      ) : undefined}
      {showCustomize ? (
        <CustomizeFieldsButton
          disabled={false}
          eventPrefix={eventPrefix}
          tableColumns={tableColumns}
          nonCustomizableColumns={nonCustomizableColumns}
          subtitle={subtitle ?? CUSTOMIZE_DIALOG_SUBTITLE}
          disallowedTooltip={disallowedTooltip ?? CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP}
        />
      ) : undefined}
    </Styled.FiltersGroup>
  );
};
export default TableToolbar;
