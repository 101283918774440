/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase, MultiSelectDropDownOption } from '@replai-platform/ui-components';
import { api } from '../../../api';
import { FiltersState } from '../../../store/filters';

export async function fetchTags(
  projectId: SDK.UUID,
  currentFiltering: FiltersState,
  tagsTypes?: { core: string[]; psychological: string[]; excluded: string[] }
) {
  return api.tags
    .getAllKindTags({
      projectId,
      kind: SDK.TagKind.Custom,
      tagFiltering: {
        tagsToConsider: null,
        tagsToExclude: null,
      },
    })
    .then((allTags: SDK.Tag[]) => {
      const nothingSelected =
        currentFiltering.tagsToConsider.length === 0 && currentFiltering.tagsToExclude.length === 0;
      const toExclude = !nothingSelected && currentFiltering.tagsToExclude.length > 0;

      const tags = allTags
        .filter(
          (tag) =>
            !tagsTypes?.core.includes(tag.type) &&
            !tagsTypes?.psychological.includes(tag.type) &&
            !tagsTypes?.excluded.includes(tag.type)
        )
        .map(
          (tag) =>
            ({
              label:
                (tag.type ? `${camelCaseToCapitalCase(tag.type)}: ` : '') +
                (tag.value ? `${tag.value}` : SDK.messages.NOT_AVAILABLE),
              id: JSON.stringify(tag),
              type: 'option',
              selected: nothingSelected
                ? false
                : toExclude
                ? !(
                    currentFiltering.tagsToExclude?.filter((t) => t.type === tag.type && t.value === tag.value)
                      ?.length > 0
                  )
                : currentFiltering.tagsToConsider?.filter((t) => t.type === tag.type && t.value === tag.value)?.length >
                  0,
            } as MultiSelectDropDownOption)
        );

      const options: MultiSelectDropDownOption[] = [
        {
          id: 'All',
          label: 'All tags',
          isAllOption: true,
          type: 'option',
          selected: false,
        },
        ...tags,
      ];

      if (options.filter((o) => o.selected)?.length === options.length - 1) {
        options[0].selected = true;
      }

      return options;
    });
}
