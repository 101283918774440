import { CommonTagIconProps } from '../types';

const QuietIntro = ({ dimension }: CommonTagIconProps) => (
  <svg
    width={dimension || 20}
    height={dimension || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.122 6.22597L9.15503 9.64397C9.07403 9.71397 8.97103 9.75197 8.86503 9.75197H6.57403C6.32803 9.75197 6.12903 9.95097 6.12903 10.197V14.12C6.12903 14.366 6.32803 14.565 6.57403 14.565H9.23303C9.34003 14.565 9.44303 14.603 9.52303 14.673L13.122 17.774C13.41 18.022 13.857 17.818 13.857 17.437V6.56297C13.857 6.18197 13.41 5.97697 13.122 6.22597Z"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.0541 9.92603C17.0541 9.92603 18.8921 11.647 17.0541 13.838"
      stroke="#101828"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default QuietIntro;
