import { IntegrationIconProps } from './types';

const Qiwi = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="14" fill="#FF8C00" />
    <path
      d="M21.949 20.1005C21.9264 19.9027 21.8127 19.4492 21.5172 19.3327H21.5176C21.3696 19.2745 21.2447 19.3208 21.1882 19.4257C21.0971 19.5888 21.1652 19.9027 21.3357 20.1822C21.5059 20.4614 21.6651 20.601 21.7788 20.601C21.8925 20.601 21.9946 20.4614 21.949 20.1005Z"
      fill="white"
    />
    <path
      d="M20.3588 20.8918C20.177 20.729 19.9726 20.6477 19.7908 20.6477C19.6319 20.6477 19.5069 20.7055 19.4271 20.8104C19.223 21.0661 19.3138 21.5319 19.6319 21.8111C19.7682 21.9391 19.9726 22.0089 20.1657 22.0089C20.3588 22.0089 20.529 21.9275 20.6314 21.7995C20.8132 21.5434 20.7108 21.1945 20.3588 20.8918Z"
      fill="white"
    />
    <path
      d="M8 16.0049C8 20.4263 11.4985 24.0102 15.8153 24.0102H15.815C16.9624 24.0102 18.0982 23.7892 19.7682 23.7773C21.2563 23.7773 22.892 24.3125 24.6866 25.9417C24.8684 26.1045 25.1068 25.8951 24.9479 25.6973C23.1872 23.4052 21.5515 22.9748 19.9157 22.6023C17.9167 22.1485 16.8943 21.0082 16.1786 19.7399C16.0423 19.4839 15.9742 19.5306 15.9625 19.8564C15.9497 20.3358 15.984 20.8154 16.0649 21.2878H15.8266C12.9753 21.2878 10.6578 18.914 10.6578 15.9933C10.6578 13.0727 12.9753 10.6989 15.8263 10.6989C18.6776 10.6989 20.995 13.0727 20.995 15.9933C20.995 16.2027 20.9834 16.4121 20.9608 16.6099C20.5745 16.5401 19.8363 16.5286 19.3138 16.5752C19.1206 16.5984 19.1432 16.6913 19.2912 16.7148C20.995 17.0403 22.1651 18.1227 22.4376 20.0889C22.4489 20.1356 22.5058 20.1472 22.5287 20.1124C23.2214 18.914 23.6303 17.5057 23.6303 16.0049C23.6303 11.5839 20.1315 8 15.815 8C11.4988 8 8 11.5835 8 16.0049Z"
      fill="white"
    />
  </svg>
);
export default Qiwi;
