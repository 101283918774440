import * as SDK from '@replai-platform/sdk';
import * as provider from './provider';

/**
 * Get historical metrics of tags
 */
export async function getHistoryMetrics(request: SDK.GetHistoryMetricsRequest): Promise<SDK.GetHistoryMetricsResponse> {
  return provider.getJson(`${SDK.BASE_URL()}/metrics/history?${SDK.convertRequestToQueryParams(request)}`);
}

/**
 * Get metrics performance
 */
export async function getMetricsPerformance(
  request: SDK.GetMetricsPerformanceRequest
): Promise<SDK.GetMetricsPerformanceResponse> {
  return provider.getJson(`${SDK.BASE_URL()}/metrics/performance?${SDK.convertRequestToQueryParams(request)}`);
}
