import styled, { css } from 'styled-components';
import Typography from '../Typography/Typography';
import Colors from '../Colors';

export const Root = styled.div<{
  $includeContainerCard?: boolean;
  $disableScroll?: boolean;
  $maxHeightInVH?: number;
}>`
  ${(props) => (props.$includeContainerCard ? RootMixin : '')}
  ${(props) => (props.$disableScroll ? DisableScrollMixin : '')}
`;

export const TypographyNoMargin = styled(Typography)`
  margin: 0;
`;

export const InputContainer = styled.div<{ $includeContainerCard?: boolean }>`
  ${(props) => (props.$includeContainerCard ? InputContainerMixin : '')}
`;

export const IconsContainer = styled.span`
  color: ${Colors.Primary[700]};
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${Colors.Gray[100]};
`;

export const Option = styled.div`
  padding: 10px 16px;

  &:hover {
    background: ${Colors.Primary[50]};
  }
`;

export const ShowMoreOption = styled.span`
  padding: 10px 16px;
  color: ${Colors.Primary[700]};
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
`;

export const NoMatches = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

/* Mixins */

const RootMixin = css<{ $maxHeightInVH?: number }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 240px;
  max-height: ${(props) => props.$maxHeightInVH ?? 80}vh;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
`;

const DisableScrollMixin = css`
  max-height: unset;
  overflow-y: unset;
`;

const InputContainerMixin = css`
  padding: 10px 16px;
`;
