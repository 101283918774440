import { Button, LinearProgress, Typography } from '@material-ui/core';
import { Colors, Typography as ReplaiTypography } from '@replai-platform/ui-components';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { tableTransition } from '../../../../configurations/animations';

export const ExpandCollapseButton = styled(Button)`
  margin-left: -0.3rem;
  color: ${Colors.Gray[500]};
  text-transform: none;
`;

export const NameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const Name = styled(Typography)`
  display: flex;
  padding: 0;
  color: ${Colors.Gray[900]};
  font-weight: 500;
  font-size: 16px;
`;

export const TagTypeGroupIndicator = styled.div`
  display: flex;
  align-self: center;
  padding: 0.25rem 0.5rem;
  color: ${Colors.Blue[700]};
  font-weight: 500;
  font-size: 0.7rem;
  text-transform: capitalize;
  background-color: ${Colors.Blue[50]};
  border-radius: 1rem;
`;

export const TagContainer = styled(motion.div).attrs({
  initial: 'out',
  exit: 'out',
  variants: tableTransition,
})<{ selected: boolean; highlighted: boolean; opaque: boolean }>`
  margin: 0.45rem -0.1rem;
  padding: 0.3rem;
  border: 1px solid transparent;
  border-radius: 0.5rem;

  ${(props) =>
    css`
      &:hover {
        border: 1px solid ${props.highlighted ? Colors.Success[100] : Colors.Gray[200]};
      }
    `}

  ${(props) =>
    props.selected &&
    css`
      background-color: ${Colors.Gray[100]};
    `}

  ${(props) =>
    props.highlighted &&
    css`
      background-color: ${Colors.Success[50]};
    `}

  ${(props) =>
    props.opaque &&
    css`
      opacity: 0.3 !important;
    `}

    ${(props) =>
    !props.opaque &&
    css`
      cursor: pointer;
    `}
`;

export const CustomProgress = styled(LinearProgress)<{ coloroverride: string; backgroundcolor?: string }>`
  height: 0.2rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  &&.MuiLinearProgress-bar {
    border-radius: 15rem;
  }

  &&.MuiLinearProgress-root {
    border-radius: 15rem;
  }

  &&.MuiLinearProgress-colorPrimary {
    background-color: ${(props) => props.backgroundcolor ?? '#454545'};
    border-radius: 15rem;
  }

  && .MuiLinearProgress-barColorPrimary {
    background-color: ${(props) => props.coloroverride};
    border-radius: 15rem;
  }
`;

export const TagToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
`;

export const TagName = styled(Typography)`
  flex-grow: 1;
  margin-bottom: 6px;
  margin-left: -16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const FlexItem = styled.div`
  display: flex;
`;

export const TagValuesList = styled.div<{ children: any[] }>`
  /* stylelint-disable-next-line unit-no-unknown */
  max-height: ${(props) => `${props.children.length * 4}rem`};
  overflow: visible;
  transition: max-height 0.3s;
`;

export const TooltipTitle = styled(Typography)`
  margin: 0;
  font-weight: normal;
`;

export const FreqAndMetricTooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BadgeInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
`;

export const TooltipText = styled(ReplaiTypography)`
  margin: 0;
  white-space: nowrap;
`;

export const TooltipPercentage = styled(TooltipText)`
  font-size: 0.625rem;
`;

export const DeltaExplainTooltipContainer = styled.div`
  align-items: center;
  width: 15rem;
`;

export const DeltaExplainTooltipBadgesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.75rem;
`;

export const DeltaExplainTooltipColumnItemContainer = styled.div`
  display: flex;
  justify-content: center;
`;
