import { CountryIconProps } from './types';

const MY = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3665)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.4785 12.0002H24.0002C24.0002 10.9171 23.8559 9.86791 23.5869 8.86975H11.4785V12.0002Z"
        fill="#D80027"
      />
      <path
        d="M11.4785 5.73873H22.239C21.5044 4.54004 20.5652 3.48053 19.4701 2.60828H11.4785V5.73873Z"
        fill="#D80027"
      />
      <path
        d="M12.0011 24.0002C14.8253 24.0002 17.4211 23.024 19.471 21.3915H4.53125C6.58114 23.024 9.17694 24.0002 12.0011 24.0002Z"
        fill="#D80027"
      />
      <path
        d="M1.76195 18.2601H22.2394C22.8291 17.2978 23.2864 16.2459 23.5873 15.1296H0.414062C0.714859 16.2459 1.17222 17.2978 1.76195 18.2601Z"
        fill="#D80027"
      />
      <path
        d="M12 12C12 5.37262 12 4.59131 12 0C5.37262 0 0 5.37262 0 12H12Z"
        fill="#0052B4"
      />
      <path
        d="M7.98001 10.2709C6.34117 10.2709 5.01263 8.94229 5.01263 7.30349C5.01263 5.6647 6.34121 4.33612 7.98001 4.33612C8.491 4.33612 8.97179 4.46535 9.39156 4.69279C8.73306 4.04887 7.8325 3.65137 6.8387 3.65137C4.82171 3.65137 3.18652 5.28651 3.18652 7.30354C3.18652 9.32057 4.82171 10.9557 6.8387 10.9557C7.8325 10.9557 8.73306 10.5582 9.39156 9.91429C8.97179 10.1416 8.49104 10.2709 7.98001 10.2709Z"
        fill="#FFDA44"
      />
      <path
        d="M8.81647 5.21729L9.3433 6.31894L10.533 6.04407L10.0002 7.14277L10.9571 7.90153L9.76578 8.17008L9.76911 9.39122L8.81647 8.62725L7.86378 9.39122L7.86706 8.17008L6.67578 7.90153L7.63259 7.14277L7.09977 6.04407L8.28959 6.31894L8.81647 5.21729Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3665">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default MY;
