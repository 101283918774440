import styled from 'styled-components';
import Typography from '../Typography/Typography';
import Colors from '../Colors';
import FeaturedIcons from '../FeaturedIcons/FeaturedIcons';
import { BaseIconTypes } from '../Icons/Base/types';
import Button, { ButtonProps } from '../Button/Button';

export interface EmptyStateProps {
  icon: BaseIconTypes;
  text: string;
  supportingText?: string;
  buttons?: ButtonProps[];
}

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  max-width: 22rem;
  margin: 2rem;
  font-family: 'Inter', sans-serif;
  text-align: center;
`;

const EmptyState = ({
  icon,
  text,
  supportingText,
  buttons,
}: EmptyStateProps) => (
  <OuterContainer data-test="empty-state">
    <Container>
      <FeaturedIcons icon={icon} variant="outline" />
      <div>
        <Typography
          color={Colors.Gray[900]}
          style={{ margin: 0 }}
          type="text-md"
        >
          {text}
        </Typography>
        {supportingText ? (
          <Typography
            color={Colors.Gray[500]}
            style={{ margin: 0 }}
            type="text-sm"
          >
            {supportingText}
          </Typography>
        ) : null}
      </div>
      {(buttons?.length ?? 0) > 0 ? (
        <div
          style={{
            display: 'flex',
            gap: '0.75rem',
          }}
        >
          {buttons?.map((buttonProps, index) => (
            // eslint-disable-next-line react/jsx-props-no-spreading, react/no-array-index-key
            <Button
              data-test={`empty-state-button-${index}`}
              key={`button-${index}`}
              {...buttonProps}
            />
          ))}
        </div>
      ) : null}
    </Container>
  </OuterContainer>
);

export default EmptyState;
