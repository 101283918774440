/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as SDK from '@replai-platform/sdk';
import {
  ButtonVariant,
  camelCaseToCapitalCase,
  Card,
  Icons,
  TableProps,
  TableWithPagination,
  TableWithPaginationProps,
} from '@replai-platform/ui-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import type { SortingRule } from 'react-table';
import { logEvent } from '../../../../analytics';
import { api } from '../../../../api';
import useAssetsCsvReport from '../../../../api/hooks/assets/useAssetsCsvReport';
import useStaticsMetrics from '../../../../api/hooks/statics/useStaticsMetrics';
import getClickablePreviewColumn from '../../../../components/columns/generators/getClickablePreviewColumn';
import generateColumn from '../../../../components/columns/generateColumnDefaults';
import Link from '../../../../components/Link';
import TableToolbar from '../../../../components/TableToolbar';
import { FilterActions } from '../../../../store/filters';
import type { RootState } from '../../../../store/rootReducer';
import { generateHref } from '../../../../utils';
import { Columns, Page } from '../../../../utils/enums';
import getMetricsFromColumns from '../../../../utils/getMetricsFromColumns';
import getUserSelectedColumns from '../../../../utils/getUserSelectedColumns';
import {
  DISALLOWED_COLUMNS_ON_CSV_EXPORT,
  ALLOWED_COLUMNS_ON_STATICS_TABLE,
  getOrderByCondition,
  ITEMS_PER_PAGE,
  NON_CUSTOMIZABLE_COLUMNS,
} from '../../../../utils/tables';
import * as Styled from './styles';
import { CUSTOMIZE_DIALOG_SUBTITLE, CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP } from '../../../../utils/constants';

type AugmentedRowData = SDK.GetStaticsMetricsResponse['entries'][number] & {
  allowEdit: boolean;
  cluster: object & { name: string; previewUrl: string; staticsCount: number };
  expanded: boolean;
  subRows: unknown[];
};

const StaticsTable = ({ filtersLoading }: { filtersLoading?: boolean }) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const match = useMatch(':projectId/*')!;
  const projectId = useSelector((state: RootState) => state.project.id);
  const baseMetric = useSelector((state: RootState) => state.project.baseMetric);
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const projectAccountTypes = useSelector((state: RootState) => state.project.config.accountTypes);
  const filters = useSelector((state: RootState) => state.filters);
  const [propData, setPropData] = useState<AugmentedRowData[]>([]);
  const [sorting, setSorting] = useState<SortingRule<object>[]>([{ id: `metrics.${baseMetric}`, desc: true }]);
  const [offset, setOffset] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [csvExportClicked, setCsvExportClicked] = useState<boolean>(false);
  const onSearchTermChange = (newSearchTerm: string): void => {
    setSearchTerm(newSearchTerm);
    setOffset(0);
  };

  const userColumns = useSelector(
    (state: RootState) => state.project.userProject.uiPreferences?.videos?.columns as Columns[]
  );
  const userSelectedColumns: Columns[] = useMemo(
    () => getUserSelectedColumns({ userColumns, projectKpis, projectAccountTypes }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectKpis, projectAccountTypes, JSON.stringify(userColumns)]
  );

  // Reset pagination on filters change
  useEffect(() => {
    setOffset(0);
  }, [filters]);

  const metrics = useMemo(() => getMetricsFromColumns(userSelectedColumns), [userSelectedColumns]);

  const metricsParams = useMemo<SDK.GetStaticsMetricsRequest>(
    () => ({
      projectIds: [projectId],
      metrics: metrics?.length === 0 ? [baseMetric] : metrics,
      orderBy: getOrderByCondition(sorting[0], userSelectedColumns),
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      tagsFilters: api.filterConverter.getTagsFilters(filters),
      maxRecords: 10,
      offset,
      search: searchTerm,
      sorting,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(filters),
      metrics,
      projectId,
      userSelectedColumns,
      offset,
      searchTerm,
      sorting,
    ]
  );

  const { isLoading, isError, isFetching } = useStaticsMetrics(metricsParams, {
    onSuccess: useCallback((res) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setPropData(res);
    }, []),
    select: useCallback(
      (res) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        res?.entries?.map((entry) => ({
          cluster: {
            ...entry,
            name: entry.name ?? entry.value,
          },
          entity: SDK.ObjectLevel.ASSET,
        })),
      []
    ),
    enabled: !filtersLoading,
    staleTime: 30,
  });

  const {
    sorting: tableSorting,
    offset: tableOffset,
    searching,
    eventPrefix,
    emptyStateProps,
  } = useMemo(
    () => ({
      sorting,
      offset,
      searching: searchTerm,
      eventPrefix: 'statics',
      emptyStateProps: isError
        ? {
            icon: 'AlertTriangle' as Icons.BaseIconTypes,
            text: 'Something went wrong...',
            supportingText:
              'We had some trouble loading this page. Please refresh the page to try again or get in touch if the problem sticks around!',
          }
        : {
            icon: 'Search' as Icons.BaseIconTypes,
            text: 'No statics found',
            supportingText: "Your search didn't return any statics, please try again or adjust your filters.",
            buttons: [
              {
                variant: 'regular' as ButtonVariant,
                children: 'Clear search',
                onClick: () => setSearchTerm(''),
              },
              {
                variant: 'outlined' as ButtonVariant,
                children: 'Reset filters',
                onClick: () => dispatch(FilterActions.reset()),
              },
            ],
          },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(sorting), offset, searchTerm, isError, dispatch]
  );

  const onOffsetChange = useCallback<Exclude<TableWithPaginationProps['onPageUpdate'], undefined>>(
    ({ pageIndex }) => {
      if (pageIndex !== offset) {
        setOffset(pageIndex * ITEMS_PER_PAGE);

        logEvent({
          component: 'Videos',
          action: 'Change page',
          category: 'user_actions',
          parameters: { page: `Page ${pageIndex + 1}` },
        });
      }
    },
    [offset]
  );

  const onSort = useCallback<Exclude<TableProps['onSort'], undefined>>(
    (sortBy) => {
      if (sortBy[0] !== sorting[0]) {
        setSorting(sortBy);

        logEvent({
          component: 'Videos',
          action:
            sortBy[0]?.id?.split('.')?.[1] != null
              ? `Sort by ${camelCaseToCapitalCase(sortBy[0].id.split('.')[1])}`
              : 'Change Sorting',
          category: 'user_actions',
          parameters: { ...sortBy },
        });
      }
    },
    [sorting]
  );

  const generateRowHref = useCallback(
    (row) => generateHref(match?.params.projectId ?? '', Page.StaticView, (row.cluster?.id as string) ?? ''),
    [match?.params.projectId]
  );

  const columns: Columns[] = useMemo(
    () =>
      Array.from(
        new Set<Columns>(
          [Columns.Name, Columns.AppearsIn, ...userSelectedColumns].filter(
            (column) =>
              ![Columns.Frequency, Columns.AdInstances, Columns.NumCreatives, Columns.ReplaiScore].includes(column)
          )
        )
      ),
    [userSelectedColumns]
  );

  const tableColumns = useMemo(
    () => [
      getClickablePreviewColumn<AugmentedRowData>({
        columnId: 'preview2',
        accessor: (row) => ({
          dialogContent: (
            <Styled.ImgBox>
              <img alt={row?.cluster?.name} src={row?.cluster?.previewUrl} />
            </Styled.ImgBox>
          ),
          previewUrl: row?.cluster?.previewUrl,
        }),
      }),
      ...columns
        .filter((col) => col !== Columns.Frequency && col !== Columns.AdInstances && col !== Columns.NumCreatives)
        .map((column) =>
          generateColumn({
            column,
            projectId,
            filters,
            includeFooter: true,
            generateRowHref,
            networks: filters.networks,
          })
        ),
    ],
    [columns, projectId, filters, generateRowHref]
  );

  const rowsTotal = useMemo<number>(() => propData?.[0]?.cluster?.staticsCount ?? 0, [propData]);

  const csvColumns = useMemo(
    () =>
      columns
        // Disable these columns on CSV
        .filter((column) => !DISALLOWED_COLUMNS_ON_CSV_EXPORT.includes(column))
        .map((column) => (column.split('.')?.[1] ?? column) as keyof SDK.AssetMetricsWithTags),
    [columns]
  );
  const csvParams = useMemo(
    () => ({
      projectId,
      metrics: metrics?.length === 0 ? [baseMetric] : metrics,
      orderBy: getOrderByCondition(sorting[0], userSelectedColumns),
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: {
        ...api.filterConverter.getAssetFilters(filters),
        types: [SDK.AssetType.Image],
      },
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      tagsFilters: api.filterConverter.getTagsFilters(filters),
      columns: csvColumns,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, metrics, JSON.stringify(filters), JSON.stringify(sorting), userSelectedColumns, csvColumns]
  );
  const {
    isLoading: isLoadingCsv,
    data: csvAssetsData,
    isFetching: isFetchingCsv,
  } = useAssetsCsvReport(csvParams, {
    select: (res) => res.csv,
    enabled: !!projectId && !filtersLoading && csvExportClicked,
  });

  // Resets CSV data when filters change
  useDeepCompareEffect(() => {
    setCsvExportClicked(false);
  }, [filters]);

  return (
    <Card fullWidth disableContentPadding>
      <TableToolbar
        eventPrefix={eventPrefix}
        initialSearchTerm={searching}
        onSearchTermChangeEnd={onSearchTermChange}
        data-test="table-toolbar"
        showCustomize
        hideActiveFilter
        tableColumns={ALLOWED_COLUMNS_ON_STATICS_TABLE}
        nonCustomizableColumns={NON_CUSTOMIZABLE_COLUMNS}
        subtitle={CUSTOMIZE_DIALOG_SUBTITLE}
        disallowedTooltip={CUSTOMIZE_DISALLOWED_COLUMNS_TOOLTIP}
        onExportCSVClick={() => setCsvExportClicked(true)}
        exportCSVEnabled
        csvName={`${filters.startDate}-${filters.endDate}-statics.csv`}
        csvData={csvAssetsData}
        csvLoading={(isLoadingCsv || isFetchingCsv) && csvExportClicked}
      />
      <Styled.TableContainer>
        <TableWithPagination
          // eslint-disable-next-line react/no-unstable-nested-components
          cellLink={({ cell, anchorFactory }) => {
            const href = generateRowHref(cell.row.original);

            return (
              <Link
                component={anchorFactory({ ariaLabel: cell.row.original.cluster?.name, href })}
                onClick={() => {
                  logEvent({
                    component: 'Videos',
                    action: 'Click on Row',
                    category: 'user_actions',
                    parameters: {
                      assetId: cell.row.original?.cluster?.id,
                    },
                  });
                }}
                to={href}
              />
            );
          }}
          pageIndex={tableOffset / ITEMS_PER_PAGE}
          onPageUpdate={onOffsetChange}
          onSort={onSort}
          data={propData}
          columns={tableColumns || []}
          rowsPerPage={ITEMS_PER_PAGE}
          rowsTotal={rowsTotal}
          rowsTotalLoading={propData.length === 0}
          sorting={tableSorting}
          emptyStateProps={emptyStateProps}
          loading={
            isLoading || (isFetching && propData.length === 0) || filtersLoading
              ? {
                  messages: ['Fetching statics', 'Getting everything ready', 'Computing aggregated metrics'],
                  firstMessageDelay: 3,
                }
              : undefined
          }
          disableAutoExpanderColumn
        />
      </Styled.TableContainer>
    </Card>
  );
};

export default StaticsTable;
