import { Notification, TableWithPagination, TableWithPaginationProps } from '@replai-platform/ui-components';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useFeature } from '@optimizely/react-sdk';
import { RootState } from '../../../store/rootReducer';
import { Columns } from '../../../utils/enums';
import generateColumn from '../../../components/columns/generateColumnDefaults';
import useReportsTemplates from '../../../api/hooks/reports/useReportsTemplates';
import { logEvent } from '../../../analytics';
import useDeleteReportTemplate from '../../../api/hooks/reports/useDeleteReportTemplate';
import { CommonSnackbar } from '../../../utils/styles';

export const TableContainer = styled.div`
  z-index: 0;
  width: 100%;
  border-radius: 0.5rem;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(228, 231, 236);
`;

const COLUMNS = ({ isTechmerc1603Enabled }: { isTechmerc1603Enabled: boolean }) => [
  Columns.ReportType,
  Columns.ReportName,
  Columns.CreatedAt,
  Columns.ReportApplyTemplate,
  ...(isTechmerc1603Enabled ? [Columns.ReportDeleteTemplate] : []),
];

const TemplatesTab = () => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const filters = useSelector((state: RootState) => state.filters);
  const deleteReportTemplateMutation = useDeleteReportTemplate();

  const [isTechmerc1603Enabled] = useFeature('techmerc-1603'); // delete report templates

  const [reportsTableOffset, setReportsTableOffset] = useState<number>(0);
  const [reportDeleteStatus, setReportDeleteStatus] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  // Fetch reports
  const { data: templates, isLoading } = useReportsTemplates(
    {
      projectId,
    },
    // minimal cache time because we want this query to be refreshed when changing pages
    { cacheTime: 1 }
  );

  const tableData = useMemo(
    () =>
      templates?.templates?.map(({ template, samples }) => ({
        templateId: template.id,
        reportType: template.type,
        reportName: template.name,
        userId: template.userId,
        params: template.params,
        externalId: samples[0].externalId,
        createdAt: new Date(template.createdAt),
        subRows: samples.map((sample) => ({
          ...sample,
          reportId: sample.id,
          reportName: sample.name,
          userEmail: sample.userEmail,
          createdAt: new Date(sample.createdAt),
          reportUrl: sample.url,
        })),
        expanded: false,
        onReportDeleteTemplateClick: async (setButtonLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
          setButtonLoading(true);

          const response = await deleteReportTemplateMutation.mutateAsync({
            projectId,
            templateId: template.id,
          });

          setReportDeleteStatus(response?.ok ?? false);
          setSnackbarOpen(true);

          setButtonLoading(false);
        },
      })),
    [deleteReportTemplateMutation, projectId, templates?.templates]
  );

  const onOffsetChange = useCallback<Exclude<TableWithPaginationProps['onPageUpdate'], undefined>>(
    ({ pageIndex }) => {
      if (pageIndex !== reportsTableOffset) {
        setReportsTableOffset(pageIndex);

        logEvent({
          component: 'Reports table',
          action: 'Change page',
          category: 'user_actions',
          parameters: { page: `Page ${pageIndex + 1}` },
        });
      }
    },
    [reportsTableOffset]
  );

  return (
    <>
      <TableContainer>
        <TableWithPagination
          disableAutoExpanderColumn={false}
          columns={COLUMNS({ isTechmerc1603Enabled }).map((column) =>
            generateColumn({
              column,
              projectId,
              filters,
              networks: filters.networks,
            })
          )}
          data={tableData || []}
          rowsPerPage={templates?.templates.length ?? 1000}
          rowsTotal={templates?.templates.length ?? 0}
          pageIndex={reportsTableOffset}
          onPageUpdate={onOffsetChange}
          emptyStateProps={{
            icon: 'Search',
            text: `No favorites found`,
            supportingText: '',
          }}
          loading={
            isLoading
              ? {
                  messages: ['Fetching templates', 'Getting everything ready'],
                }
              : undefined
          }
        />
      </TableContainer>
      <CommonSnackbar
        open={snackbarOpen}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={4000}
      >
        <Notification
          badgeTitle="Info"
          badgeLabel={
            reportDeleteStatus ? 'TEmplate deleted successfully' : 'An error occurred while deleting the template.'
          }
          color={reportDeleteStatus ? 'Success' : 'Error'}
        />
      </CommonSnackbar>
    </>
  );
};

export default TemplatesTab;
