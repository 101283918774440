import { CountryIconProps } from './types';

const HN = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3638)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.9997 23.9987C16.7572 23.9987 20.868 21.23 22.809 17.2161H1.19043C3.13143 21.23 7.24218 23.9987 11.9997 23.9987Z"
        fill="#338AF3"
      />
      <path
        d="M12.0007 -0.000976562C7.24316 -0.000976562 3.13241 2.7677 1.19141 6.78165H22.8099C20.869 2.7677 16.7582 -0.000976562 12.0007 -0.000976562Z"
        fill="#338AF3"
      />
      <path
        d="M7.38504 7.82446L7.77354 9.0202H9.03077L8.01368 9.75913L8.40223 10.9549L7.38504 10.2159L6.36785 10.9549L6.7564 9.75913L5.73926 9.0202H6.99649L7.38504 7.82446Z"
        fill="#338AF3"
      />
      <path
        d="M7.38504 13.0425L7.77354 14.2383H9.03077L8.01368 14.9772L8.40223 16.1729L7.38504 15.4339L6.36785 16.1729L6.7564 14.9772L5.73926 14.2383H6.99649L7.38504 13.0425Z"
        fill="#338AF3"
      />
      <path
        d="M16.6145 7.82446L17.0031 9.0202H18.2603L17.2432 9.75913L17.6317 10.9549L16.6145 10.2159L15.5973 10.9549L15.9859 9.75913L14.9688 9.0202H16.226L16.6145 7.82446Z"
        fill="#338AF3"
      />
      <path
        d="M16.6145 13.0425L17.0031 14.2383H18.2603L17.2432 14.9772L17.6317 16.1729L16.6145 15.4339L15.5973 16.1729L15.9859 14.9772L14.9688 14.2383H16.226L16.6145 13.0425Z"
        fill="#338AF3"
      />
      <path
        d="M11.9993 10.4333L12.3878 11.6291H13.6451L12.6279 12.3681L13.0165 13.5638L11.9993 12.8248L10.9821 13.5638L11.3707 12.3681L10.3535 11.6291H11.6107L11.9993 10.4333Z"
        fill="#338AF3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3638">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default HN;
