import { CommonTagIconProps } from '../types';

const SceneDurationGreaterThan20Seconds = ({
  dimension,
}: CommonTagIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#101828"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    width={dimension || 20}
    height={dimension || 20}
  >
    <rect x="2" y="2" width="20" height="20" rx="2" ry="2" />
    <circle cx="12" cy="12" r="5" />
    <line x1="12" y1="12" x2="10" y2="11" />
    <line x1="12" y1="12" x2="12" y2="9.5" />
  </svg>
);
export default SceneDurationGreaterThan20Seconds;
