import { IconProps } from './types';

const PhoneOff = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.19 12.81C3.50586 10.213 2.45436 7.25717 2.12 4.18C2.09501 3.90347 2.12787 3.62476 2.2165 3.36162C2.30513 3.09849 2.44757 2.85669 2.63477 2.65162C2.82196 2.44655 3.0498 2.28271 3.30379 2.17052C3.55778 2.05833 3.83234 2.00026 4.11 2H7.11C7.59531 1.99522 8.06579 2.16708 8.43376 2.48353C8.80173 2.79999 9.04208 3.23945 9.11 3.72C9.23662 4.68007 9.47145 5.62273 9.81 6.53C9.94454 6.88792 9.97366 7.27691 9.89391 7.65088C9.81415 8.02485 9.62886 8.36811 9.36 8.64L8.09 9.91M23 1L1 23M10.68 13.31C11.6949 14.3257 12.8418 15.2003 14.09 15.91L15.36 14.64C15.6319 14.3711 15.9752 14.1858 16.3491 14.1061C16.7231 14.0263 17.1121 14.0555 17.47 14.19C18.3773 14.5286 19.3199 14.7634 20.28 14.89C20.7606 14.9579 21.2 15.1983 21.5165 15.5662C21.8329 15.9342 22.0048 16.4047 22 16.89V19.89C22.0011 20.1685 21.9441 20.4442 21.8325 20.6993C21.7209 20.9545 21.5573 21.1836 21.3521 21.3719C21.1469 21.5601 20.9046 21.7035 20.6407 21.7927C20.3769 21.8819 20.0974 21.9151 19.82 21.89C16.7428 21.5556 13.787 20.5041 11.19 18.82C9.98527 18.0551 8.86853 17.1596 7.86 16.15L10.68 13.31Z"
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default PhoneOff;
