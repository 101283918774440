import { NetworkConnectionGuideContent } from '../../types';
import * as Shared from '../shared';
import { VerticalLayout } from '../shared/VerticalLayout';

const SNAPCHAT_FORM_URL = 'https://docs.google.com/forms/d/1ncNvV663rQA4xFxGjeanDBzKsZvmAxZhTW0e2Qa2iFo/viewform';

export const SnapchatConnectionGuide: NetworkConnectionGuideContent = {
  title: 'Snapchat Connection Guide',
  icon: 'Snapchat',
  guide: {
    label: 'Connection Guide',
    steps: [
      {
        title: 'Login',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                Login into{' '}
                {Shared.link({
                  label: 'SnapChat Ads Manager',
                  url: 'https://accounts.snapchat.com/accounts/login?client_id=ads-api&referrer=https%253A%252F%252Fbusiness.snapchat.com%252F',
                })}{' '}
                plaform
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/snapchat/step-1.png"
          />
        ),
      },
      {
        title: 'Member (I)',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                Click on <Shared.BoldText>Members</Shared.BoldText>
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/snapchat/step-2.png"
          />
        ),
      },
      {
        title: 'Member (II)',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                Click on <Shared.BoldText>Invite Member</Shared.BoldText>
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/snapchat/step-3.png"
          />
        ),
      },
      {
        title: 'Replai User',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                Invite Replai user:{' '}
                <Shared.BoldText>
                  <Shared.NoWrap>replai@replai.io</Shared.NoWrap>
                </Shared.BoldText>
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/snapchat/step-4.png"
          />
        ),
      },
      {
        title: 'Ad Account',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                Click on <Shared.BoldText>Ad Accounts</Shared.BoldText> and select the one you want to share.
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/snapchat/step-6.png"
          />
        ),
      },
      {
        title: 'Share',
        description: '',
        content: (
          <VerticalLayout
            contentDescription={
              <>
                Search for <Shared.BoldText>Replai</Shared.BoldText> in members, and add us as{' '}
                <Shared.BoldText>Campaign Manager</Shared.BoldText>. Press <Shared.BoldText>Add Member</Shared.BoldText>
                . Finally, submit{' '}
                {Shared.link({
                  label: 'this form',
                  url: SNAPCHAT_FORM_URL,
                })}{' '}
                to confirm to Replai you&apos;ve completed the steps on this guide.
              </>
            }
            imageSrc="https://cdn.replai.io/assets/integration-guide/snapchat/step-7.png"
          />
        ),
      },
    ],
  },
  faqs: {
    label: 'FAQ',
    content: Shared.DEFAULT_NETWORK_FAQS,
  },
};
