import millify from 'millify';
import * as SDK from '@replai-platform/sdk';
import { camelCaseToCapitalCase, Icons, InsightMetricProps } from '@replai-platform/ui-components';
import { rejectNotHandled, getSocialIconByName } from '../../utils';
import { APPCUES_IDS } from '../../utils/constants';
import { getTagIconDefinition } from '../../utils/getTagIcon';
import { SpendAndFrequencyBadgeValues } from '../../store/insights';

export const INSIGHT_INTRO_TYPES = [SDK.RecommendationType.GOOD_TAG_INTRO, SDK.RecommendationType.BAD_TAG_INTRO];

const getInsightPositiveness = (recommendationType: SDK.FeatureRecommendation['type']): 'positive' | 'negative' => {
  switch (recommendationType) {
    case SDK.RecommendationType.GOOD_TAG:
    case SDK.RecommendationType.GOOD_TAG_INTRO:
      return 'positive';
    case SDK.RecommendationType.BAD_TAG:
    case SDK.RecommendationType.BAD_TAG_INTRO:
      return 'negative';
    default:
      return rejectNotHandled(recommendationType);
  }
};

export const getInsightMetricProps = ({
  insight,
  projectNetworks,
  onClick,
  thumbnailUrl,
  thumbnailLoading,
  wrapped = false,
  showCoreTagThumbnails = false,
}: {
  insight: SDK.FeatureRecommendation & SpendAndFrequencyBadgeValues;
  projectNetworks: SDK.Network[];
  onClick: () => void;
  thumbnailUrl?: string;
  thumbnailLoading?: boolean;
  wrapped?: boolean;
  showCoreTagThumbnails?: boolean;
}): InsightMetricProps & React.Attributes => {
  const { id, type, content, score } = insight;
  const insightType = getInsightPositiveness(type);

  const networkIcons: Icons.FlatSocialIconTypes[] = [];
  if ((insight.networks?.length ?? 0) > 0 && !!projectNetworks) {
    networkIcons.push(
      ...projectNetworks
        .filter((network: SDK.Network) => insight.networks?.includes(network))
        .map((network: SDK.Network) => getSocialIconByName(network))
    );
  }

  const { iconName: iconThumbnail, props: iconProps } = getTagIconDefinition({
    type: content.tag.type,
    value: content.tag.value,
  });

  return {
    key: typeof id === 'string' ? id : id.join(),
    tagType: camelCaseToCapitalCase(content.tag.type),
    tagValue: content.tag.value,
    kpis: insight.targets.map((target) => ({
      unit: target === SDK.KPI.AGE_PREDICTION ? 'DAYS ACTIVE' : SDK.kpiUtils.getDisplayName(target),
      signMaximize: (insightType === 'positive') === SDK.kpiUtils.shouldMaximize(target) ? 1 : -1,
    })),
    insightType,
    isF5S: INSIGHT_INTRO_TYPES.includes(type),
    networkIcons,
    onClick,
    thumbnailUrl,
    thumbnailLoading,
    iconThumbnail: showCoreTagThumbnails ? iconThumbnail : undefined,
    iconProps,
    score,
    wrapped,
    appcuesId: APPCUES_IDS.REPLAI_CONFIDENCE,
    ...(insight?.spendAndFrequency.isLoading
      ? {}
      : {
          spend: {
            current: millify(insight?.spendAndFrequency?.insightSpend ?? 0),
            total: millify(insight?.spendAndFrequency?.contextSpend ?? 0),
          },
          frequency: {
            current: millify(insight?.spendAndFrequency?.insightObjectCount ?? 0),
            total: millify(insight?.spendAndFrequency?.contextObjectCount ?? 0),
          },
        }),
  };
};
