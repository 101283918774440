import { PaymentIconProps } from './types';

const WeChat = ({ dimension }: PaymentIconProps) => (
  <svg
    width={(dimension * 46) / 32 || 46}
    height={dimension || 32}
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      d="M20.2534 18.4744C20.1628 18.5227 20.0608 18.5506 19.9523 18.5506C19.7013 18.5506 19.4831 18.405 19.3686 18.1896L19.3247 18.0885L17.4972 13.8624C17.4775 13.8164 17.4653 13.7644 17.4653 13.7138C17.4653 13.5191 17.6152 13.3613 17.8 13.3613C17.8749 13.3613 17.9444 13.3875 18.0002 13.4312L20.1567 15.0493C20.3142 15.1579 20.5025 15.2215 20.7049 15.2215C20.8257 15.2215 20.9408 15.1979 21.0482 15.1567L31.1896 10.4C29.3718 8.14224 26.3779 6.66663 22.9901 6.66663C17.4461 6.66663 12.9521 10.6134 12.9521 15.4823C12.9521 18.1384 14.3042 20.5295 16.4205 22.1456C16.5903 22.2734 16.7015 22.4833 16.7015 22.7201C16.7015 22.7985 16.6855 22.8699 16.6661 22.9446C16.497 23.6091 16.2265 24.6732 16.214 24.723C16.1928 24.8064 16.1599 24.8935 16.1599 24.9807C16.1599 25.1755 16.3096 25.3333 16.4947 25.3333C16.5672 25.3333 16.6266 25.3048 16.6879 25.2677L18.8854 23.9305C19.0508 23.8302 19.2257 23.7677 19.4186 23.7677C19.5212 23.7677 19.6203 23.7845 19.7135 23.8146C20.7387 24.1253 21.8448 24.298 22.9901 24.298C28.5338 24.298 33.0283 20.351 33.0283 15.4823C33.0283 14.0076 32.6136 12.6187 31.8848 11.397L20.3269 18.4298L20.2534 18.4744Z"
      fill="#1AAD19"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F2F4F7"
    />
  </svg>
);
export default WeChat;
