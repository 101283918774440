import { useMutation } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const updateExternalApiKey = async (requestParams: SDK.UpdateApiKeyRequest) => {
  const url = `${SDK.BASE_URL()}/api/update-key?${SDK.convertRequestToQueryParams(requestParams)}`;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const res = await provider.getJson(url, { method: 'POST', body: {} });
  return res as SDK.UpdateApiKeyResponse;
};

export default function useUpdateExternalApiKeyMutation() {
  return useMutation<SDK.UpdateApiKeyResponse, unknown, SDK.UpdateApiKeyRequest, unknown>(
    'update-api-key',
    (requestParams: SDK.UpdateApiKeyRequest) => updateExternalApiKey(requestParams)
  );
}
