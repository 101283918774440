/* eslint-disable */
import { CreativeRecommendation, RecommendationType, TagIntroWithKind, TagWithKind, UUID } from '@replai-platform/sdk';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../store/rootReducer';
import { GeneratedColumn, RequiredGeneratorParams } from '../common';
import IntroIndicator from '../../IntroIndicator';
import { CellProps } from 'react-table';
import VideoAvatar from '../../VideoAvatar';
import RecommendationFigure from '../../RecommendationFigure';
import * as SDK from '@replai-platform/sdk';
import { Typography, Colors, AppcuesTutorial } from '@replai-platform/ui-components';
import { logEvent } from '../../../analytics';
import { getBadgeText } from '../../../utils/getBadgeText';
import { nameFromContent } from '../../../utils/nameFromContent';
import { APPCUES_IDS } from '../../../utils/constants';
import { AlignRightHeaderWrapper } from '../../NetworksPerformance/styles';
import { messages } from '@replai-platform/sdk';

const SuggestionContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const SubRowContainer = styled.div`
  width: 100%;
  margin-left: 20px;
  padding-right: 15px;
`;

const SuggestionInfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

const TagInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
`;

const NoMarginTypography = styled(Typography)`
  margin: 0;
`;

const Figures = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: 0.5rem;
`;

export const SuggestionDecorator: React.FC<{
  value: CreativeRecommendation['name'];
  includeType?: boolean;
}> = ({ value, includeType = true }) => {
  let firstTag: TagWithKind | TagIntroWithKind;
  switch (value?.type) {
    case RecommendationType.CREATIVE_ADD_TAG:
    case RecommendationType.CREATIVE_ADD_TAG_INTRO:
    case RecommendationType.CREATIVE_REMOVE_TAG:
    case RecommendationType.CREATIVE_REMOVE_TAG_INTRO:
      firstTag = value?.tag;
      break;
    case RecommendationType.CREATIVE_REPLACE_TAG:
    case RecommendationType.CREATIVE_REPLACE_TAG_INTRO:
      firstTag = value?.previous;
      break;
  }

  return (
    <SuggestionContainer>
      <Figures>
        <RecommendationFigure value={value} />
        <IntroIndicator data-test="recommendation-intro-indicator" content={value} />
      </Figures>
      <SuggestionInfoContainer>
        <TagInfoContainer>
          <NoMarginTypography type="text-sm" fontWeight="medium">
            {nameFromContent({ content: value, includeType })}
          </NoMarginTypography>
          <NoMarginTypography type="text-sm" fontWeight="regular" color={Colors.Gray[500]}>
            {getBadgeText(firstTag?.type, value)}
          </NoMarginTypography>
        </TagInfoContainer>
      </SuggestionInfoContainer>
    </SuggestionContainer>
  );
};

type AccessorType = {
  objectId: UUID;
  objectLevel: SDK.ObjectLevel;
  name: string | null | undefined | CreativeRecommendation['name'];
  displayName?: string;
  isActive?: boolean;
  rowHref?: string;
  rowHrefState?: Record<string, any>;
  containerOnClick?: () => void;
};

function getRecommendationNameColumn<D extends object = object>({
  columnId,
  accessor,
}: RequiredGeneratorParams<D, AccessorType>): GeneratedColumn<D, AccessorType> {
  return {
    id: columnId,
    accessor,
    width: 500,
    minWidth: 200,
    Cell: ({ value, row }: CellProps<D, AccessorType>) => {
      const projectId = useSelector((state: RootState) => state.project.id);

      return row?.depth > 0 ? (
        <SubRowContainer>
          <SuggestionDecorator value={value.name as CreativeRecommendation['name']} />
        </SubRowContainer>
      ) : (
        <VideoAvatar
          objectId={value.objectId}
          objectLevel={value.objectLevel}
          isActive={value.isActive}
          name={(value.displayName || value.name || messages.NOT_AVAILABLE) as string}
          originalName={value.name as string}
          projectId={projectId}
          videoHref={value.rowHref}
          videoHrefState={value.rowHrefState}
          eventOrigin="Recommendations"
          containerOnClick={value.containerOnClick}
          linkOnClick={() => {
            logEvent({
              component: 'Recommendations',
              action: 'Click on Row',
              category: 'dashboard',
              parameters: {
                assetId: value.objectId,
              },
            });
          }}
        />
      );
    },
    Header: (
      <AlignRightHeaderWrapper>
        Name <AppcuesTutorial appcuesId={APPCUES_IDS.CONCEPT_NAME_COLUMN} />
      </AlignRightHeaderWrapper>
    ),
  };
}
export default getRecommendationNameColumn;
