import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import { BASE_URL } from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getMarketAssetsTags = async (requestParams: SDK.GetMarketAssetsTagsRequest) => {
  const res = await provider.getJson<object, SDK.GetMarketAssetsTagsResponse>(
    `${BASE_URL()}/market/assets/tags?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useMarketAssetsTags(
  requestParams: SDK.GetMarketAssetsTagsRequest,
  options?: UseQueryOptions<SDK.GetMarketAssetsTagsResponse>
) {
  return useQuery(['assets-tags-market', requestParams], () => getMarketAssetsTags(requestParams), options);
}
