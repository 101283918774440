import { useRef } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { Badge, ColorTypes, Skeleton, Tooltip, Typography, VideoPlayer } from '@replai-platform/ui-components';
import { Column } from 'react-table';
import ColumnHeader from '../../components/columns/generateHeader';
import { TypographyClean } from '../../utils/styles';
import { CellData } from '../../components/columns/common';
import { logEvent } from '../../analytics';
import ClickableAvatar from '../../components/ClickableAvatar';
import { useGetCreativeThumbnailRequest } from '../../api/hooks/creativeProduction/useCreativeThumbnail';
import { formatDateDistance } from '../../utils/formatDateDistance';

enum ColumnIds {
  User = 'user',
  Status = 'status',
  ConceptId = 'conceptId',
  Preview = 'preview',
  Prompt = 'prompt',
  LaunchDate = 'launchDate',
  CreativeName = 'creativeName',
  UpdatedAt = 'updatedAt',
}

export const UNLINKED_TABLE_COLUMNS: string[] = [ColumnIds.Prompt, ColumnIds.LaunchDate];

export type ProductionTrackCreativeColumnData = {
  creativeId: string;
  conceptId: string | null;
  creativeName: string;
  creativeStatus: number;
  user: string;
  prompt: string;
  videoUrl: string | null;
  createdAt: string;
  updatedAt: string;
};

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

export const getCreativeNameColumn = <D extends object = object>() => ({
  id: ColumnIds.CreativeName,
  Header: <ColumnHeader title="Name" />,
  accessor: (data: ProductionTrackCreativeColumnData) => data.creativeName,
  Cell: (data: CellData<D, string>) => <TypographyClean>{data?.cell?.value}</TypographyClean>,
  disableSortBy: true,
  width: 80,
});

export const getUpdatedAtColumn = <D extends object = object>() => ({
  id: ColumnIds.UpdatedAt,
  Header: <ColumnHeader title="Latest update" />,
  accessor: (data: ProductionTrackCreativeColumnData) => data.updatedAt,
  Cell: (data: CellData<D, string>) => {
    const updatedAt = new Date(data.cell.value);
    if (Number.isNaN(updatedAt.getTime())) {
      return <TypographyClean>N/A</TypographyClean>;
    }

    return <TypographyClean>{updatedAt.toLocaleDateString()}</TypographyClean>;
  },
  disableSortBy: true,
  width: 60,
});

const Container = styled.div`
  position: relative;
  z-index: 1;
`;

export const getLaunchDateColumn = <D extends object = object>(): Column<D> => ({
  accessor: (data: { launchDate?: string }) => {
    const { launchDate } = data;
    return {
      defaultValue: launchDate ? formatDateDistance(new Date(launchDate)) : '',
      tooltip: launchDate,
    };
  },
  Cell: (data: CellData<D, { defaultValue: string; tooltip?: string }>) => (
    <Tooltip
      placement="bottom"
      content={
        data.cell.value?.tooltip && (
          <Typography type="text-sm" fontWeight="medium" noMargin>
            {data.cell.value?.tooltip}
          </Typography>
        )
      }
    >
      <Container>{data.cell.value.defaultValue}</Container>
    </Tooltip>
  ),
  id: ColumnIds.LaunchDate,
  Header: <ColumnHeader title="Launch Date" />,
  tooltip: 'Date when the video was first launched',
});

export const getProductionTrackCreativePreviewColumn = <D extends object = object>({
  projectId,
}: {
  projectId: string;
}) => ({
  id: ColumnIds.Preview,
  accessor: () => ({
    // Stub. Should be overridden.
    creativeId: '',
    videoUrl: null,
  }),
  Cell: (data: CellData<D, { creativeId: string; videoUrl: string | null }>) => {
    const videoPlayer = useRef<ReactPlayer | null>(null);
    const { creativeId, videoUrl } = data?.cell?.value ?? {};
    const { data: thumbnailData, isLoading, isError } = useGetCreativeThumbnailRequest(projectId, creativeId);

    return isLoading ? (
      <Skeleton height={40} width={40} />
    ) : isError ? (
      <Badge color="Gray">N/A</Badge>
    ) : (
      <ClickableAvatar
        avatarImageUrl={thumbnailData?.previewUrl ?? undefined}
        onDialogContentClose={() => (videoPlayer.current?.getInternalPlayer() as HTMLVideoElement)?.pause()}
        dialogContent={
          <VideoContainer>
            <VideoPlayer
              allowDownload
              width="100%"
              height="600px"
              title={creativeId}
              url={videoUrl ?? ''}
              playerRef={videoPlayer}
              autoPlay
              playing
              onPlay={() =>
                logEvent({
                  component: 'Creative Production - Thumbnail',
                  action: 'Video Play',
                  category: 'user_actions',
                })
              }
            />
          </VideoContainer>
        }
      />
    );
  },
  Header: <ColumnHeader title="Preview" />,
  tooltip: 'Preview Thumbnail',
  sortDescFirst: false,
  sticky: 'left' as Column['sticky'],
  width: 40,
  disableSortBy: true,
});

export const getProductionTrackCreativeColumns = (projectId: string): Column<ProductionTrackCreativeColumnData>[] => [
  {
    ...getProductionTrackCreativePreviewColumn({ projectId }),
    accessor: (data: { creativeId: string; videoUrl: string | null }) => ({
      creativeId: data.creativeId,
      videoUrl: data.videoUrl,
    }),
  },
  {
    id: ColumnIds.ConceptId,
    Header: <ColumnHeader title="ID" />,
    accessor: (data: ProductionTrackCreativeColumnData) => data.conceptId,
    Cell: (data: CellData<ProductionTrackCreativeColumnData, string | null>) => {
      const name = data?.cell?.value;
      return name !== null ? <TypographyClean>{name}</TypographyClean> : <Badge color="Gray">N/A</Badge>;
    },
    disableSortBy: true,
    width: 80,
  },
  getCreativeNameColumn(),
  {
    id: ColumnIds.Status,
    Header: <ColumnHeader title="Status" />,
    accessor: (data: ProductionTrackCreativeColumnData) => data.creativeStatus,
    Cell: (data: CellData<ProductionTrackCreativeColumnData, number>) => {
      const status = data?.cell?.value;

      let color: ColorTypes;
      if (status === 1) {
        color = 'Success';
      } else {
        color = 'Gray';
      }

      // eslint-disable-next-line react/jsx-no-useless-fragment
      return status !== undefined ? (
        <Badge style={{ margin: 0 }} color={color}>
          {status === 1 ? 'COMPLETED' : `${Math.round(status * 100)}%`}
        </Badge>
      ) : null;
    },
    disableSortBy: true,
    width: 70,
  },
  {
    id: ColumnIds.Prompt,
    Header: <ColumnHeader title="Prompt" />,
    accessor: (data: ProductionTrackCreativeColumnData) => data.prompt,
    Cell: (data: CellData<ProductionTrackCreativeColumnData, string>) => {
      const maxPromptTextLen = 100;

      const promptText = data?.cell?.value;
      let shortenedText: string | null = null;
      if (promptText.length > maxPromptTextLen) {
        shortenedText = `${promptText.substring(0, maxPromptTextLen - 1)}...`;
      }
      return shortenedText ? (
        <Tooltip
          placement="bottom"
          content={
            <TypographyClean type="text-sm" fontWeight="medium" noMargin>
              {promptText}
            </TypographyClean>
          }
        >
          <TypographyClean>{shortenedText}</TypographyClean>
        </Tooltip>
      ) : (
        <TypographyClean>{promptText}</TypographyClean>
      );
    },
    disableSortBy: true,
    width: 170,
  },
  {
    id: ColumnIds.User,
    Header: <ColumnHeader title="User" />,
    accessor: (data: ProductionTrackCreativeColumnData) => data.user,
    Cell: (data: CellData<ProductionTrackCreativeColumnData, string>) => (
      <TypographyClean>{data?.cell?.value}</TypographyClean>
    ),
    disableSortBy: true,
    width: 100,
  },
  getUpdatedAtColumn(),
];
