import { useSelector } from 'react-redux';
import { useTitle } from 'react-use';
import { useMemo } from 'react';
import { useFeature } from '@optimizely/react-sdk';
import FilterBar from '../../../components/FilterBar';
import { FiltersContainer } from '../../../components/FilterBar/common/styles';
import TopNavPageTitle from '../../../components/TopNavPageTitle';
import type { RootState } from '../../../store/rootReducer';
import { RouteAnimator } from '../../RouteAnimator';
import StaticsTable from './StaticsTable';
import type { BuyingStrategyFilterProps } from '../../../components/FilterBar/Filters/BuyingStrategyFilter';
import { CustomTagsFilterProps } from '../../../components/FilterBar/Filters/CustomTagsFilter';
import type { NetworkFilterProps } from '../../../components/FilterBar/Filters/NetworkFilter';
import type { PromotedObjectFilterProps } from '../../../components/FilterBar/Filters/PromotedObjectFilter';
import useBuyingStrategyFilter from '../../../components/FilterBar/hooks/useBuyingStrategyFilter';
import useCustomTagsFilter from '../../../components/FilterBar/hooks/useCustomTagsFilter';
import useNetworkFilter from '../../../components/FilterBar/hooks/useNetworkFilter';
import usePromotedObjectFilter from '../../../components/FilterBar/hooks/usePromotedObjectFilter';
import { Page } from '../../../utils/enums';
import useSidebarFilters from '../../../components/FilterSidebar/buildSidebarFilters';
import { supportedFilters } from '../utils';

const StaticsPerformance: React.VFC = () => {
  const projectName = useSelector((state: RootState) => state.project.name);
  const [isTechmerc1525Enabled] = useFeature('techmerc-1525'); // Buying Strategy filter
  useSidebarFilters(supportedFilters);

  useTitle(`Statics Performance - ${projectName}`);

  // Build network filter
  const {
    networkFilterOptions,
    networkFilterOnChange,
    isLoading: isNetworkFilterLoading,
  } = useNetworkFilter(Page.StaticsPerformance);
  const networkFilter = useMemo(
    () =>
      ({
        options: networkFilterOptions,
        onChange: networkFilterOnChange,
        loading: isNetworkFilterLoading,
      } as NetworkFilterProps),
    [networkFilterOptions, networkFilterOnChange, isNetworkFilterLoading]
  );

  // Build promoted object types filter
  const { promotedObjectTypeFilterOptions, promotedObjectTypeFilterOnChange } = usePromotedObjectFilter(
    Page.StaticsPerformance
  );
  const promotedObjectFilter = useMemo(
    () =>
      ({
        options: promotedObjectTypeFilterOptions,
        onChange: promotedObjectTypeFilterOnChange,
        loading: false,
      } as PromotedObjectFilterProps),
    [promotedObjectTypeFilterOnChange, promotedObjectTypeFilterOptions]
  );

  // Build tagged with filter
  const {
    customTagsFilterOptions,
    customTagsFilterOnChange,
    isLoading: isCustomTagsFilterLoading,
  } = useCustomTagsFilter('default');
  const customTagsFilter: CustomTagsFilterProps = useMemo(
    () => ({
      options: customTagsFilterOptions,
      onChange: customTagsFilterOnChange,
      loading: isCustomTagsFilterLoading,
    }),
    [customTagsFilterOnChange, customTagsFilterOptions, isCustomTagsFilterLoading]
  );

  // Build buying strategy filter
  const {
    buyingStrategyFilterOptions,
    buyingStrategyFilterOnChange,
    isLoading: isBuyingStrategyFilterLoading,
  } = useBuyingStrategyFilter(Page.Videos);
  const buyingStrategyFilter = useMemo(
    () =>
      ({
        options: buyingStrategyFilterOptions,
        onChange: buyingStrategyFilterOnChange,
        loading: isBuyingStrategyFilterLoading,
      } as BuyingStrategyFilterProps),
    [buyingStrategyFilterOptions, buyingStrategyFilterOnChange, isBuyingStrategyFilterLoading]
  );

  return (
    <RouteAnimator data-test="statics-container">
      <TopNavPageTitle
        title="Statics Performance"
        subtitle="Dive into your static creatives and see their performance across networks."
      />
      <FiltersContainer data-test="videos-filters-container">
        <FilterBar
          eventPrefix="statics-performance"
          supportedFilters={supportedFilters}
          networkFilter={networkFilter}
          promotedObjectFilter={promotedObjectFilter}
          customTagsFilter={customTagsFilter}
          buyingStrategyFilter={isTechmerc1525Enabled ? buyingStrategyFilter : undefined}
        />
      </FiltersContainer>
      <StaticsTable />
    </RouteAnimator>
  );
};

export default StaticsPerformance;
