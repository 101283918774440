/*
 * Formats a number to a specific compact notation.
 * Examples:
 *  - 1 000 = 1k
 *  - 1 000 000 = 1M
 *
 * @param number to format
 * @returns formatted number
 */
function compactNumberFormatter(value: number): string {
  return (
    Intl.NumberFormat(
      'en',
      // Add to add this here because of: https://github.com/microsoft/TypeScript/issues/36533#issuecomment-607081387
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      { notation: 'compact' }
    ).format(value) ?? value.toString()
  );
}

export default compactNumberFormatter;
