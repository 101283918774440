/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import { useFloating } from '@floating-ui/react-dom';
import { useEffect, useRef, useState } from 'react';
import { useClickAway, useUpdateEffect } from 'react-use';
import Colors from '../Colors';
import DropDownMenu, { DropDownMenuOption } from '../DropDownMenu/DropDownMenu';
import * as Icons from '../Icons';
import * as Styled from './styles';
import MultiSelectDropDown, {
  MultiSelectDropDownOption,
} from '../MultiSelectDropDown/MultiSelectDropDown';
import { DropDownChipContainer } from './styles';

const MAX_NUMBER_OF_SELECTED_OPTIONS_TO_SHOW = 3;

const ChevronDownIconJSX = Icons.getBaseIcon('ChevronDown');

const XIconJSX = Icons.getBaseIcon('X');
const xIcon = <XIconJSX color={Colors.Gray[900]} dimension={20} />;

interface DropDownChipProps {
  dropDownType: 'singleselect' | 'multiselect';
  dropDownOptions: DropDownMenuOption[] | MultiSelectDropDownOption[];
  defaultOption?: string;
  placeHolder?: string;
  placement?: 'top' | 'bottom';
  dropDownAlignment?: 'right' | 'left';
  onChange?: (options: MultiSelectDropDownOption[]) => void;
  disableCrossButton?: boolean;
  prefixLabel?: string;
  useAllLabel?: boolean;
  disabled?: boolean;
  loading?: boolean;
  invalid?: boolean;
  dropdownMaxHeightInVH?: number;
}

const DropDownChip = ({
  defaultOption,
  dropDownType,
  dropDownOptions,
  placeHolder,
  dropDownAlignment = 'right',
  onChange,
  placement,
  disableCrossButton = false,
  prefixLabel,
  useAllLabel = true,
  disabled = false,
  loading = false,
  invalid = false,
  dropdownMaxHeightInVH,
}: DropDownChipProps) => {
  const [isDropDownCollapsed, setIsDropDownCollapsed] = useState(true);
  const [singleDropDownSelectedOption, setSingleDropDownSelectedOption] =
    useState(
      defaultOption ??
        (dropDownOptions as DropDownMenuOption[]).filter((o) => o.selected)?.[0]
          ?.label
    );
  const [multiDropDownOptions, setMultiDropDownOptions] = useState(
    dropDownOptions as MultiSelectDropDownOption[]
  );
  const isDisabled = disabled || loading;
  const dropDownChipRef = useRef(null);
  const chevronDownIcon = (
    <ChevronDownIconJSX
      color={isDisabled ? Colors.Gray[300] : Colors.Gray[900]}
      dimension={20}
    />
  );

  const { x, y, reference, floating, strategy } = useFloating({
    placement: `${placement ?? 'bottom'}-${
      dropDownAlignment === 'right' ? 'end' : 'start'
    }`,
  });

  // used to colapse the dropdown if the user clicks outside of it
  useClickAway(dropDownChipRef, () => {
    setIsDropDownCollapsed(true);
  });

  // if the parent component changes the options, the changes are reflected here
  useEffect(() => {
    setSingleDropDownSelectedOption(
      defaultOption ??
        (dropDownOptions as DropDownMenuOption[]).filter((o) => o.selected)?.[0]
          ?.label
    );
  }, [defaultOption, dropDownOptions]);

  useEffect(() => {
    setMultiDropDownOptions(dropDownOptions);
  }, [dropDownOptions]);

  useUpdateEffect(() => {
    onChange?.(multiDropDownOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(multiDropDownOptions)]);

  const clearSingleDropDown = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.stopPropagation(); // prevent the dropDown from opening when the selected option is cleared
    if (defaultOption) {
      setSingleDropDownSelectedOption(defaultOption);
    } else {
      setSingleDropDownSelectedOption(undefined);
      onChange?.(
        enhancedDropDownOptions.map((o) => ({ ...o, selected: false }))
      );
    }
  };

  const clearMultiDropDown = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.stopPropagation(); // prevent the dropDown from opening when the selected options are cleared
    setMultiDropDownOptions(
      multiDropDownOptions.map((option) => ({ ...option, selected: false }))
    );
  };

  const getIcon = (showChevron: boolean) => {
    if (loading) {
      return (
        <span data-test="dropdown-chip-loading-icon">
          <Icons.MiscIcons.LoadingCircle
            color={Colors.Gray[300]}
            dimension={20}
          />
        </span>
      );
    }

    if (showChevron) {
      return chevronDownIcon;
    }

    return (
      <span
        onClick={(e) =>
          dropDownType === 'multiselect'
            ? clearMultiDropDown(e)
            : clearSingleDropDown(e)
        }
        role="button"
        tabIndex={0}
        aria-hidden="true"
      >
        {xIcon}
      </span>
    );
  };

  const generateSingleDropDownButtonContent = () => (
    <Styled.ButtonContent>
      <Styled.ButtonText>
        {singleDropDownSelectedOption
          ? `${prefixLabel ?? ''}${singleDropDownSelectedOption}`
          : placeHolder}
      </Styled.ButtonText>
      {getIcon(
        defaultOption === singleDropDownSelectedOption ||
          !singleDropDownSelectedOption ||
          disableCrossButton
      )}
    </Styled.ButtonContent>
  );

  const generateMultiDropDownButtonContent = () => {
    const allOption = multiDropDownOptions
      .filter((option) => option.isAllOption && option.selected)
      .map((option) => option.label)
      .join();

    const multiDropDownOptionsWithoutDividers = multiDropDownOptions.filter(
      (option) => option.type !== 'divider'
    );

    let selectedOptionsText = '';

    if (!allOption || (allOption && !useAllLabel)) {
      const selectedOptions = multiDropDownOptionsWithoutDividers.filter(
        (option) => option.selected
      );

      if (prefixLabel && selectedOptions.length > 1) {
        const numberOfSelectedOptions = allOption
          ? selectedOptions.length - 1
          : selectedOptions.length;
        selectedOptionsText = `${prefixLabel}: ${numberOfSelectedOptions} selected`;
      } else {
        selectedOptionsText = selectedOptions
          .slice(0, MAX_NUMBER_OF_SELECTED_OPTIONS_TO_SHOW)
          .map((option) => option.label)
          .join(', ');

        if (selectedOptions.length > MAX_NUMBER_OF_SELECTED_OPTIONS_TO_SHOW) {
          selectedOptionsText += `, +${
            selectedOptions.length - MAX_NUMBER_OF_SELECTED_OPTIONS_TO_SHOW
          }`;
        }
      }
    }

    const textToShow =
      (useAllLabel ? allOption : undefined) ||
      selectedOptionsText ||
      placeHolder;

    return (
      <Styled.ButtonContent>
        <Styled.ButtonText>{textToShow}</Styled.ButtonText>
        {getIcon(textToShow === allOption || textToShow === placeHolder)}
      </Styled.ButtonContent>
    );
  };

  const enhancedDropDownOptions = dropDownOptions.map(
    (option: DropDownMenuOption) => ({
      ...option,
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (option.onClick) option.onClick(event);
        setSingleDropDownSelectedOption(option.label || '');
        setIsDropDownCollapsed(true);
      },
    })
  );

  const getDropDown = () => {
    switch (dropDownType) {
      case 'singleselect':
        return (
          <DropDownMenu
            title=""
            hasInput={false}
            options={enhancedDropDownOptions}
            selected={singleDropDownSelectedOption}
          />
        );
      case 'multiselect':
        return (
          <MultiSelectDropDown
            options={multiDropDownOptions}
            includeContainerCard
            input={{
              leadingIcon: 'Search',
              placeholder: 'Search',
            }}
            onChange={setMultiDropDownOptions}
            maxHeightInVH={dropdownMaxHeightInVH}
          />
        );
      default:
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: never = dropDownType;
        return exhaustiveCheck;
    }
  };

  return (
    <span data-test="dropdown-chip-filter" ref={dropDownChipRef}>
      <DropDownChipContainer ref={reference}>
        <Styled.Button
          variant="outlined"
          color={invalid ? 'Error' : 'Gray'}
          size="md"
          disabled={isDisabled}
          onClick={() => setIsDropDownCollapsed((c) => !c)}
          fullWidth={false}
        >
          {dropDownType === 'singleselect'
            ? generateSingleDropDownButtonContent()
            : generateMultiDropDownButtonContent()}
        </Styled.Button>
      </DropDownChipContainer>
      {!isDropDownCollapsed && (
        <Styled.DropDownContainer
          data-test="filter-dropdown-container"
          ref={floating}
          $position={strategy}
          $left={x}
          $top={y}
        >
          {getDropDown()}
        </Styled.DropDownContainer>
      )}
    </span>
  );
};

export default DropDownChip;
export type { DropDownChipProps };
