/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import { kpiUtils } from '@replai-platform/sdk';
import { ChartValue, LineChart, LoadingBars } from '@replai-platform/ui-components';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/rootReducer';
import { getMetricsHistoryEntries } from '../../../../utils';
import { LoadingContainer } from '../../shared/styles';

interface FatigueTrendProps {
  selectedKpi: SDK.MetricKPIWithSpend;
  conceptId?: SDK.UUID;
}

const FatigueTrend: React.VFC<FatigueTrendProps> = ({ selectedKpi, conceptId }) => {
  const filters = useSelector((state: RootState) => state.filters);
  const projectId = useSelector((state: RootState) => state.project.id);
  const selectedKpiDisplayName = kpiUtils.getDisplayName(selectedKpi);
  const lines = [
    {
      name: `${
        selectedKpi === SDK.Metrics.SPEND
          ? 'total'
          : ([SDK.KPI.AVG_ASSET_SPEND, SDK.KPI.AVG_CONCEPT_SPEND] as SDK.MetricKPIWithSpend[]).includes(selectedKpi)
          ? 'project'
          : SDK.kpiConfig[selectedKpi]?.isSummable
          ? 'total'
          : 'average'
      } ${selectedKpiDisplayName}`,
      accessor: `global${selectedKpi}`,
    },
    {
      name: `concept ${selectedKpiDisplayName}`,
      accessor: `asset${selectedKpi}`,
    },
  ];

  const getHistoryParams = {
    projectId,
    filters,
    metrics: [selectedKpi],
    conceptId,
  };
  const { data, isLoading } = useQuery([getHistoryParams], () => getMetricsHistoryEntries(getHistoryParams));

  const chartData = useMemo(
    () =>
      data?.map(
        (entry) =>
          ({
            name: entry.name,
            [`asset${selectedKpi}`]: entry.assetMetrics?.[selectedKpi],
            [`global${selectedKpi}`]: entry.globalMetrics?.[selectedKpi],
          } as ChartValue)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(data)]
  );

  if (isLoading || !data) {
    return (
      <LoadingContainer>
        <LoadingBars barSize="xxl" />
      </LoadingContainer>
    );
  }

  return (
    <LineChart
      data-test="chart"
      data={chartData}
      yValueFormatter={(v) => kpiUtils.format(selectedKpi, v)}
      xValueFormatter={(s) =>
        new Date(s).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
        })
      }
      lines={lines}
      yLabel={kpiUtils.getDisplayName(selectedKpi)}
    />
  );
};

export default FatigueTrend;
