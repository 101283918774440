import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getConceptHeroCount = async (requestParams: SDK.GetHeroConceptsCountRequest) => {
  const res = await provider.getJson<object, SDK.GetHeroConceptsCountResponse>(
    `${SDK.BASE_URL()}/concepts/heroCount?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useConceptHeroCount(
  requestParams: SDK.GetHeroConceptsCountRequest,
  options?: UseQueryOptions<SDK.GetHeroConceptsCountResponse>
) {
  return useQuery(['concept-hero-count', requestParams], () => getConceptHeroCount(requestParams), options);
}
