import * as SDK from '@replai-platform/sdk';
import { Button, ColorTypes, Icons } from '@replai-platform/ui-components';
import { logEvent } from '../../analytics';

type AssetNetworkLinkButtonProps = {
  networkAssetId?: string;
  network?: SDK.Network;
};

const PER_NETWORK_CONFIG: Record<
  string,
  {
    urlFormatter: (networkAssetId: string) => string;
    icon: Icons.MiscIconTypes | Icons.BaseIconTypes;
    color: ColorTypes;
    networkLabel: string;
  }
> = {
  youtube: {
    urlFormatter: (networkAssetId: string) => `https://www.youtube.com/watch?v=${networkAssetId}`,
    icon: 'Youtube',
    color: 'Error',
    networkLabel: 'YouTube',
  },
};

const AssetNetworkLinkButton = ({ networkAssetId, network }: AssetNetworkLinkButtonProps) => {
  if (!networkAssetId || !network) {
    return null;
  }

  const config = network ? PER_NETWORK_CONFIG[network] : null;
  if (!config) {
    return null;
  }

  const networkUrl = config.urlFormatter(networkAssetId);

  return (
    <div style={{ width: '100%' }} aria-label="Clickable Avatar">
      <Button
        style={{ width: '100%' }}
        color={config.color}
        leadingIcon={{ name: config.icon }}
        onClick={(e) => {
          e.stopPropagation();
          window.open(networkUrl, '_blank', 'noopener,noreferrer');
          logEvent({
            component: 'AssetNetworkLinkButton',
            action: 'click_view_asset_in_network',
            category: 'user_actions',
          });
        }}
      >
        View on {config.networkLabel}
      </Button>
    </div>
  );
};

export default AssetNetworkLinkButton;
