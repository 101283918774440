import { CountryIconProps } from './types';

const SC = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3759)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M20.1006 3.14638C17.9659 1.19206 15.1224 -0.000671387 12.0002 -0.000671387C11.489 -0.000671387 10.9854 0.0315317 10.491 0.0935474L4.69581 6.78195L0.379883 15.0055C0.658695 16.0865 1.08498 17.108 1.63496 18.0485L12.0002 11.9993L20.1006 3.14638Z"
        fill="#FFDA44"
      />
      <path
        d="M21.9903 18.6479L5.20117 21.8879C7.13378 23.2192 9.47556 23.9993 11.9998 23.9993C16.168 23.9993 19.8392 21.8738 21.9903 18.6479Z"
        fill="#6DA544"
      />
      <path
        d="M20.1021 3.14819L1.6416 18.0603C2.12446 18.8838 2.70257 19.6446 3.36102 20.3276L23.9999 11.9996C23.9999 8.49522 22.4976 5.34204 20.1021 3.14819Z"
        fill="#D80027"
      />
      <path
        d="M0 12C0 13.0382 0.131906 14.0455 0.379734 15.0062L10.4909 0.0942383C4.57584 0.836457 0 5.88377 0 12Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3759">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SC;
