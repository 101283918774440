import { CountryIconProps } from './types';

const LK = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37257 18.6274 0 12 0C5.37257 0 0 5.37257 0 12C0 18.6274 5.37257 24 12 24Z"
      fill="#FFDA44"
    />
    <path
      d="M9.39149 2.08618H5.23672C5.0526 2.21204 4.87241 2.34306 4.69583 2.47867L3.65234 11.9992L4.69583 21.5197C4.87241 21.6554 5.0526 21.7864 5.23672 21.9122H9.39149V2.08618Z"
      fill="#FF9811"
    />
    <path
      d="M0 12.0007C0 15.8793 1.84069 19.3276 4.69567 21.5212V2.4801C1.84069 4.67376 0 8.12199 0 12.0007Z"
      fill="#6DA544"
    />
    <path
      d="M19.3047 7.31531V7.61582L19.3159 7.61751L19.3047 7.31531Z"
      fill="#FFDA44"
    />
    <path
      d="M23.043 15.1296H20.7821L19.8256 16.1731V18.2601H17.7386V17.2166H18.7821V15.1296H14.0865V16.434H13.043V13.9482C12.7228 13.6616 12.5213 13.2453 12.5213 12.7818V5.99922C12.5213 5.1348 13.222 4.43401 14.0865 4.43401V14.0862H16.1734L16.8895 13.4894C16.7644 13.1915 16.6952 12.8644 16.6952 12.521V10.9558H15.13V7.82538H18.2604C18.2604 7.30366 19.043 6.78189 19.043 6.78189C19.043 6.78189 19.8256 7.30361 19.8256 7.82538V8.60801V10.9558C20.4061 10.9627 21.3283 10.9477 22.3246 10.9558C22.0629 10.4983 21.9125 9.95548 21.9125 9.39063C21.9125 8.46996 22.31 7.64224 22.9428 7.06943C22.0308 5.04841 20.5755 3.32518 18.763 2.08618H10.9561V21.9123H18.763C20.967 20.4057 22.6428 18.1833 23.4558 15.5799L23.043 15.1296Z"
      fill="#A2001D"
    />
  </svg>
);
export default LK;
