import { IconProps } from './types';

const Volume2 = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.07 4.92993C20.9447 6.80521 21.9979 9.34829 21.9979 11.9999C21.9979 14.6516 20.9447 17.1947 19.07 19.0699M15.54 8.45993C16.4774 9.39757 17.0039 10.6691 17.0039 11.9949C17.0039 13.3208 16.4774 14.5923 15.54 15.5299M11 4.99993L6 8.99993H2V14.9999H6L11 18.9999V4.99993Z"
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default Volume2;
