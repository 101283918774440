import styled from 'styled-components';
import Colors from '../../../Colors';
import { formatDuration } from '../../../../utils';

// Avoid creting a SC for every change in the "left" style
const Container = styled.div.attrs<{ videoProgress: number }>(
  ({ videoProgress }) => ({
    style: {
      left: `calc(100 * ${videoProgress}%)`,
    },
  })
)<{ videoProgress: number; verticalOffset: number; heightOffset: number }>`
  position: absolute;
  top: ${({ verticalOffset }) => `${verticalOffset}px`};
  bottom: 0;
  height: ${({ verticalOffset, heightOffset }) =>
    `calc(100% + ${-verticalOffset}px + ${heightOffset}px)`};
`;

const Line = styled.div<{ color: string }>`
  position: absolute;
  top: 1rem;
  left: 50%;
  z-index: 200;
  width: 1px;
  height: calc(100% - 1rem - 0.5rem);
  background-color: ${({ color }) => color}};
  border-radius: 0.5rem;
  transform: translateX(-50%);
`;

const InnerContainer = styled.div`
  position: relative;
  height: 100%;
`;

const Tooltip = styled.div<{ color: string }>`
  position: absolute;
  top: -1rem;
  left: 50%;
  z-index: 200;
  padding: 0.25rem 0.5rem;
  color: ${Colors.Common.White};
  font-weight: 500;
  font-size: 12px;
  font-style: normal;
  line-height: 18px;
  background-color: ${({ color }) => color};
  border-radius: 0.5rem;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  transform: translateX(-50%);

  :after {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-color: ${({ color }) => color} transparent transparent transparent;
    border-style: solid;
    border-width: 5px;
    content: '';
  }
`;

interface TimelineTimestampLineProps {
  playedFraction: number;
  playedSeconds: number;
  verticalOffset?: number;
  heightOffset?: number;
  color?: string;
}

const TimelineTimestampLine: React.FC<TimelineTimestampLineProps> = ({
  playedFraction,
  playedSeconds,
  verticalOffset = 0,
  heightOffset = 0,
  color = Colors.Gray[900],
}) => {
  const { minutes, seconds } = formatDuration(playedSeconds);
  return (
    <Container
      videoProgress={playedFraction}
      verticalOffset={verticalOffset}
      heightOffset={heightOffset}
      color={color}
    >
      <InnerContainer>
        <Tooltip color={color}>{`${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</Tooltip>
        <Line color={color} />
      </InnerContainer>
    </Container>
  );
};

export default TimelineTimestampLine;
