import { useState } from 'react';
import { useSelector } from 'react-redux';
import ClickableAvatar from '../ClickableAvatar';
import PlayablePreview from '../PlayablePreview';
import usePlayablePreview from '../../api/hooks/assets/usePlayablePreview';
import type { RootState } from '../../store/rootReducer';

const PlayableAvatar = ({ assetId }: { assetId: string }) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { data: playablePreview } = usePlayablePreview({ projectId, assetId, size: '100x100' }, { enabled: true });

  return (
    <ClickableAvatar
      // This helps ensure the playable is destroyed when the dialog closes.
      key={dialogOpen ? 'en' : 'dis'}
      dialogOpen={dialogOpen}
      onDialogContentOpen={() => setDialogOpen(true)}
      onDialogContentClose={() => setDialogOpen(false)}
      avatarImageUrl={playablePreview?.thumbnailUrl ?? ''}
      dialogContent={<PlayablePreview assetId={assetId} enabled={dialogOpen} />}
    />
  );
};

export default PlayableAvatar;
