/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as SDK from '@replai-platform/sdk';
import { Color, Colors } from '@replai-platform/ui-components';
import { api } from '../../api';
import { FiltersState } from '../../store/filters';

export async function getTagTrendMetricsHistory({
  projectId,
  tagFiltering,
  taggedWith,
  filteringKpi,
}: {
  projectId: SDK.UUID;
  tagFiltering: FiltersState;
  taggedWith?: SDK.UUID[];
  filteringKpi: SDK.MetricOrKpi;
}): Promise<SDK.HistoryMetrics[]> {
  try {
    const globalTagsMetrics = await api.metrics.getHistoryMetrics({
      projectIds: [projectId],
      metrics: [filteringKpi ?? SDK.Metrics.SPEND],
      adsFilters: {
        ...api.filterConverter.getAdsFilters({
          ...tagFiltering,
          campaignIdsToConsider: [],
        }),
        ...(taggedWith && taggedWith.length > 0 ? { taggedWith } : null),
      },
      assetFilters: api.filterConverter.getAssetFilters(tagFiltering),
      metricsFilters: api.filterConverter.getMetricsFilters({
        ...tagFiltering,
        countriesToConsider: undefined,
        countriesToExclude: undefined,
        ageStartDate: undefined,
        ageEndDate: undefined,
      }),
    });
    return globalTagsMetrics.history;
  } catch {
    return [] as SDK.HistoryMetrics[];
  }
}

export async function retrieveMetricsPerformance({
  projectId,
  projectKpis,
  filters,
  taggedWith,
}: {
  projectId: SDK.UUID;
  projectKpis: SDK.Metrics[];
  filters: FiltersState;
  taggedWith?: SDK.UUID[];
}): Promise<SDK.Metric | Record<string, never>> {
  const filteringKPI: SDK.Metrics[] = [...new Set([...projectKpis, SDK.Metrics.SPEND])];
  try {
    const globalMetricsPerformance = await api.metrics.getMetricsPerformance({
      projectIds: [projectId],
      metrics: filteringKPI,
      adsFilters: {
        ...api.filterConverter.getAdsFilters({ ...filters, campaignIdsToConsider: [] }),
        ...(taggedWith && taggedWith.length > 0 ? { taggedWith } : null),
      },
      metricsFilters: api.filterConverter.getMetricsFilters({
        ...filters,
        countriesToConsider: undefined,
        countriesToExclude: undefined,
        ageStartDate: undefined,
        ageEndDate: undefined,
      }),
      assetFilters: api.filterConverter.getAssetFilters(filters),
    });
    return globalMetricsPerformance.metrics;
  } catch {
    return {};
  }
}

export const getTrendIcon = ({
  goodness,
  value,
}: {
  goodness: number;
  value: number;
}): { color: Color; icon: string } => {
  const result: { color: Color; icon: string } = { color: Colors.Gray[900], icon: 'MinusCircle' };

  if (goodness > 0) {
    // eslint-disable-next-line prefer-destructuring
    result.color = Colors.Success[700];
  } else if (goodness < 0) {
    // eslint-disable-next-line prefer-destructuring
    result.color = Colors.Error[700];
  }

  if (value > 0) {
    result.icon = 'ArrowUp';
  } else if (value < 0) {
    result.icon = 'ArrowDown';
  }

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const copyObjectExcludingDate = (object) => (({ date, ...o }) => o)(object);

export async function getHistory({
  projectId,
  tagFiltering,
  filteredTagsIds,
  filteringKpi,
}: {
  projectId: SDK.UUID;
  tagFiltering: FiltersState;
  filteredTagsIds: SDK.UUID[];
  filteringKpi: SDK.Metrics;
}) {
  const globalHistoryPromise = getTagTrendMetricsHistory({ projectId, tagFiltering, filteringKpi });
  const tagsHistoryPromise =
    filteredTagsIds.length > 0
      ? getTagTrendMetricsHistory({ projectId, tagFiltering, taggedWith: filteredTagsIds, filteringKpi })
      : Promise.resolve([]);

  const [globalHistory, tagsHistory] = await Promise.all([
    globalHistoryPromise,
    filteredTagsIds.length > 0 ? tagsHistoryPromise : null,
  ]);

  return globalHistory.map((entry) => {
    const result = {
      name: entry.date,
      [`global${SDK.kpiUtils.getDisplayName(filteringKpi)}`]: copyObjectExcludingDate(entry).metrics[filteringKpi],
    };

    if (filteredTagsIds.length > 0) {
      let filteredMetrics = tagsHistory?.find((e) => e.date === entry.date);
      if (filteredMetrics) {
        filteredMetrics = copyObjectExcludingDate(filteredMetrics);
        result[`tags${SDK.kpiUtils.getDisplayName(filteringKpi)}`] = filteredMetrics?.metrics?.[filteringKpi];
      }
    }

    return result;
  });
}
