/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { IdleRefresh } from '@replai-platform/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { logEvent } from '../../analytics';
import { api } from '../../api';
import { AuthActions } from '../../store/auth';
import { RootState } from '../../store/rootReducer';

// export for testing
export const idleManagerConfiguration = {
  dialogTimeout: 60 * 15, // equivalent to 15min
  idleTimerTimeout: 1000 * 60 * 60 * 12, // 12 hours
  eventsThrottle: 1000, // Only trigger events every 1 second
  crossTab: true, // Allow crosstab events
  syncTimers: 1, // All tabs counters are sync
  stopOnIdle: true, // Stop all events when user gets on idle
  startOnMount: true, // Start timer on component mount
};

/**
 * This manager is responsible to look to user actions on our platform.
 * When a user is idling for too long, it will prompt a message with a X minute time range for him to logout or keep browsing.
 *
 * Documentation for the used library can be consulted on both links:
 *  - https://idletimer.dev/
 *  - https://www.npmjs.com/package/react-idle-timer
 */
const IdleManager = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.auth.id);
  const [isOpen, setIsOpen] = useState(false);

  // If user is active, close the dialog
  const handleOnActive = () => {
    setIsOpen(false);
  };

  // On idle, open the refresh dialog
  const handleOnIdle = () => {
    setIsOpen(true);
  };

  const idleTimer = useIdleTimer({
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    timeout: idleManagerConfiguration.idleTimerTimeout,
    eventsThrottle: idleManagerConfiguration.eventsThrottle,
    startOnMount: idleManagerConfiguration.startOnMount,
    stopOnIdle: idleManagerConfiguration.stopOnIdle,
    crossTab: idleManagerConfiguration.crossTab,
    syncTimers: idleManagerConfiguration.syncTimers,
  });

  const resetIdleRefresh = (cause: 'timeout' | 'keep-connected') => {
    // Resets the timer, propagate notifications to other tabs and close dialog
    setIsOpen(false);
    idleTimer.reset();

    logEvent({
      component: 'Idle Manager',
      action: `User wanted to: ${cause}`,
      category: 'user_actions',
    });
    if (cause === 'timeout') {
      api.auth
        .signOut()
        .then(() => {
          dispatch(AuthActions.clearUser());
          window.location.reload();
        })
        .catch(() => {
          // TODO(TECHPROM-201): log this error.
          // Do nothing.
        });
    } else {
      // Force the window reload to avoid troubles
      window.location.reload();
    }
  };

  // Stop the counter if the user is not set
  useEffect(() => {
    if (userId) {
      idleTimer.resume();
    } else {
      idleTimer.pause();
    }
  }, [idleTimer, userId]);

  // Only activate the IdlerManager if the user has already logged in
  if (!userId) {
    return null;
  }
  return <IdleRefresh isOpen={isOpen} timeout={idleManagerConfiguration.dialogTimeout} onClose={resetIdleRefresh} />;
};

export default IdleManager;
