import { CountryIconProps } from './types';

const MT = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3736)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 -0.000488281C18.6274 -0.000488281 24 5.37214 24 11.9995C24 18.6269 18.6274 23.9995 12 23.9995"
        fill="#D80027"
      />
      <path
        d="M8.34725 4.69624V3.13104H6.78205V4.69624H5.2168V6.26145H6.78205V7.82669H8.34725V6.26145H9.91245V4.69624H8.34725Z"
        fill="#ACABB1"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3736">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default MT;
