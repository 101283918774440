import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getAssetRecommendations = async (requestParams: SDK.GetRecommendationsRequest) => {
  const res = await provider.getJson<object, SDK.GetRecommendationsResponse>(
    `${SDK.BASE_URL()}/recommendations/assets?${SDK.convertRequestToQueryParams(requestParams)}`
  );
  return res;
};

export default function useAssetRecommendations(
  requestParams: SDK.GetRecommendationsRequest,
  options?: UseQueryOptions<any>
) {
  return useQuery(['recommendations-asset', requestParams], () => getAssetRecommendations(requestParams), options);
}
