import { IntegrationIconProps } from './types';

const JiraServiceDesk = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1442 13.0581L25.8111 13.0877C26.2628 13.0898 26.6745 13.3448 26.8753 13.7468C27.076 14.1487 27.0313 14.6288 26.7597 14.9874L15.4435 30C13.735 28.7323 12.6034 26.8421 12.2978 24.7453C11.9923 22.6486 12.5377 20.5171 13.8142 18.82L18.1442 13.0581Z"
      fill="url(#paint0_linear_1334_1143)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8108 18.82L6.18855 18.7719C5.73693 18.7698 5.32513 18.5148 5.12441 18.1128C4.9237 17.7108 4.96841 17.2308 5.23996 16.8722L16.4445 2C18.1531 3.26772 19.2847 5.15794 19.5902 7.25469C19.8958 9.35145 19.3503 11.4829 18.0739 13.18L13.8108 18.82Z"
      fill="#2684FF"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_1143"
        x1="17.8392"
        y1="14.1825"
        x2="21.912"
        y2="23.4925"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default JiraServiceDesk;
