import { CountryIconProps } from './types';

const PR = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3690)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.999 -0.000610352C8.07308 -0.000610352 4.58769 1.88484 2.39844 4.79939H21.5995C19.4102 1.88484 15.9248 -0.000610352 11.999 -0.000610352Z"
        fill="#D80027"
      />
      <path
        d="M11.999 23.9993C15.9248 23.9993 19.4102 22.1139 21.5995 19.1993H2.39844C4.58769 22.1139 8.07308 23.9993 11.999 23.9993Z"
        fill="#D80027"
      />
      <path
        d="M-0.000976562 11.9994C-0.000976562 12.8213 0.0818984 13.6239 0.239352 14.3994H23.7588C23.9162 13.6239 23.999 12.8213 23.999 11.9994C23.999 11.1774 23.9161 10.3748 23.7587 9.59937H0.239352C0.0818984 10.3748 -0.000976562 11.1774 -0.000976562 11.9994Z"
        fill="#D80027"
      />
      <path
        d="M3.51471 3.51538C-1.17157 8.20166 -1.17157 15.7997 3.51471 20.486C5.45126 18.5495 7.31374 16.687 12 12.0007L3.51471 3.51538Z"
        fill="#0052B4"
      />
      <path
        d="M4.85694 8.86877L5.6339 11.2601H8.14855L6.11427 12.7382L6.89118 15.1297L4.85694 13.6517L2.82262 15.1297L3.59971 12.7382L1.56543 11.2601H4.0799L4.85694 8.86877Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3690">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default PR;
