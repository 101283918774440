import { IntegrationIconProps } from './types';

const Tripadvisor = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2C8.27813 2 2 8.27812 2 16C2 23.7219 8.27813 30 16 30C23.7219 30 30 23.7219 30 16C30 8.27812 23.7219 2 16 2Z"
      fill="#00AF87"
    />
    <path
      d="M10 17C10 16.4091 10.4545 16 11 16C11.5455 16 12 16.4091 12 17C12 17.5909 11.5909 18 11 18C10.4091 18 10 17.5455 10 17Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 14C9.36364 14 8 15.2273 8 17C8 18.6364 9.22727 20 11 20C12.7727 20 14 18.7727 14 17C14 15.2273 12.6364 14 11 14ZM11 15C9.90909 15 9 15.8182 9 17C9 18.0909 9.81818 19 11 19C12.1818 19 13 18.1818 13 17C13 15.8182 12.0909 15 11 15Z"
      fill="white"
    />
    <path
      d="M22 17C22 16.4091 21.5455 16 21 16C20.4545 16 20 16.4091 20 17C20 17.5909 20.4091 18 21 18C21.5909 18 22 17.5455 22 17Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 14C22.6364 14 24 15.2273 24 17C24 18.6364 22.7727 20 21 20C19.2273 20 18 18.7727 18 17C18 15.2273 19.3636 14 21 14ZM21 15C22.0909 15 23 15.8182 23 17C23 18.0909 22.1818 19 21 19C19.8182 19 19 18.1818 19 17C19 15.8182 19.9091 15 21 15Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 11.9838C10.8511 10.8127 13.4311 10 16 10C18.5689 10 21.1489 10.8127 23 11.9838L26 12L24.9345 13.986C25.5389 14.7794 26 15.9044 26 17C26 19.7578 23.65 22 20.9678 22C19.3811 22 17.9444 21.0956 17 20L16 22L15 20C14.0556 21.0956 12.6189 22 11.0322 22C8.34998 22 6 19.7578 6 17C6 15.9044 6.46109 14.7794 7.06554 13.986L6 12L9 11.9838ZM16 16.5C16.1889 13.9689 18.3989 12.0378 20.9678 12C19.57 11.3956 17.7756 11 16 11C14.2244 11 12.43 11.3956 11.0322 12C13.6011 12.0378 15.8111 13.9689 16 16.5ZM11.0322 21.0178C8.84109 21.0178 7 19.1911 7 17C7 14.8089 8.84109 12.966 11.0322 12.966C13.2233 12.966 14.9989 14.8089 14.9989 17C14.9989 19.1533 13.2233 21.0178 11.0322 21.0178ZM20.9678 21.0178C23.1589 21.0178 25 19.1911 25 17C25 14.8089 23.1589 12.966 20.9678 12.966C18.7767 12.966 17.0011 14.8089 17.0011 17C17.0011 19.1533 18.7767 21.0178 20.9678 21.0178Z"
      fill="white"
    />
  </svg>
);
export default Tripadvisor;
