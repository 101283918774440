import { IntegrationIconProps } from './types';

const GooglePlayMusic = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.8141 14.4263L5.84852 2.36263C4.91007 1.85315 4.0704 1.90087 3.55986 2.37838C3.19776 2.71269 3 3.2539 3 3.95425V28.0337C3 28.7345 3.21401 29.2757 3.55986 29.6097C4.08676 30.1032 4.91007 30.1508 5.84852 29.6254L27.8141 17.5616C29.3953 16.7022 29.3953 15.3018 27.8141 14.4263Z"
      fill="#F1471D"
    />
    <path
      d="M25.9371 18.6213C26.2009 17.7461 26.3327 16.8068 26.3327 15.8522C26.3327 10.234 21.6065 5.66638 15.7941 5.66638C9.9816 5.66638 5.25586 10.234 5.25586 15.8522C5.25586 20.5631 8.5657 24.526 13.061 25.6881L25.9371 18.6213Z"
      fill="#FCD117"
    />
    <path
      d="M22.5868 16.0021C22.5868 19.518 19.6384 22.3682 16.0004 22.3682C12.3627 22.3682 9.41406 19.518 9.41406 16.0021C9.41406 12.486 12.3627 9.63599 16.0004 9.63599C19.6383 9.63588 22.5868 12.4859 22.5868 16.0021Z"
      fill="#F48B15"
    />
    <path
      d="M16.0006 12.66V16.5747C15.77 16.4479 15.5066 16.368 15.2267 16.368C14.3705 16.368 13.6953 17.0369 13.6953 17.848C13.6953 18.6761 14.3868 19.3285 15.2267 19.3285C16.0832 19.3285 16.824 18.6597 16.824 17.848V14.2515H18.3058V12.66H16.0006Z"
      fill="white"
    />
  </svg>
);
export default GooglePlayMusic;
