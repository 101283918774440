import Typography from '../Typography/Typography';
import Tooltip from '../Tooltip/Tooltip';
import Colors from '../Colors';
import * as Icons from '../Icons';
import AppcuesTutorial from '../AppcuesTutorial/AppcuesTutorial';
import TagThumbnail from '../TagThumbnail/TagThumbnail';
import FrequencyMetricBadgege from '../FrequencyMetricBadge/FrequencyMetricBadge';
import CustomScore from '../CustomScore/CustomScore';
import KPIs from './KPIs';
import * as Styled from './styles';
import SkeletonLoading from '../Skeleton/Skeleton';

export type InsightMetricProps = {
  tagType?: string;
  tagValue?: string | null;
  kpis?: KPI[];
  onClick?: () => void;
  insightType?: InsightType;
  networkIcons?: Icons.FlatSocialIconTypes[];
  isF5S?: boolean;
  thumbnailUrl?: string;
  thumbnailLoading?: boolean;
  iconThumbnail?: Icons.TagIconTypes;
  iconProps?: Icons.TagIconProps;
  spend?: {
    current: string;
    total: string;
  };
  frequency?: {
    current: string;
    total: string;
  };
  score?: number;
  wrapped?: boolean;
  skeletonOnly?: boolean;
  appcuesId?: string;
};

export type KPI = {
  unit: string;
  signMaximize?: number;
};

export type InsightType = 'positive' | 'negative';

const TagTypeValue = ({
  insightType,
  tagType,
  tagValue,
  wrapped,
}: Pick<
  InsightMetricProps,
  'insightType' | 'tagType' | 'tagValue' | 'wrapped'
>) => {
  const TagValueTypography = wrapped
    ? Styled.StyledTypography
    : Styled.EllipsisTypography;

  return (
    <Styled.TypeValueContainer data-test="insights-cards-name">
      <TagValueTypography
        type="text-sm"
        fontWeight="semi-bold"
        color={
          insightType === 'positive' ? Colors.Success[600] : Colors.Error[600]
        }
        noMargin
        {...(TagValueTypography === Styled.StyledTypography && {
          $maxLines: 2,
        })}
      >
        {tagValue === null ? 'N/A' : tagValue}
      </TagValueTypography>
      <Typography
        type="text-xs"
        fontWeight="medium"
        color={Colors.Gray[400]}
        noMargin
      >
        {tagType}
      </Typography>
    </Styled.TypeValueContainer>
  );
};

const IntroIcon = () => (
  <Tooltip
    content={
      <Typography noMargin type="text-sm">
        Insight for the initial seconds of the video
      </Typography>
    }
  >
    <Styled.FirstSecondsContainer data-test="first-second-container">
      <Icons.BaseIcons.Start
        data-test="insights-start-icon"
        dimension={16}
        color={Colors.Gray[400]}
      />
      <Styled.TypographyFirstSeconds
        data-test="insights-seconds"
        type="text-xs"
        fontWeight="semi-bold"
        color={Colors.Gray[400]}
      >
        5 sec
      </Styled.TypographyFirstSeconds>
    </Styled.FirstSecondsContainer>
  </Tooltip>
);

const SpendAndFrequency = ({
  frequency,
  spend,
}: Pick<InsightMetricProps, 'frequency' | 'spend'>) => (
  <Styled.SpendAndFrequencyContainer>
    {spend && frequency ? (
      <>
        <FrequencyMetricBadgege
          type="frequency"
          current={frequency.current}
          total={frequency.total}
          colored={false}
        />
        <FrequencyMetricBadgege
          type="metric"
          current={spend.current}
          total={spend.total}
          colored={false}
        />
      </>
    ) : (
      <SkeletonLoading width={230} />
    )}
  </Styled.SpendAndFrequencyContainer>
);

const Footer = ({ networkIcons }: Pick<InsightMetricProps, 'networkIcons'>) => (
  <Styled.Footer data-test="insights-networks-promoted-object-types-container">
    {(networkIcons || []).map((icon) => {
      const Icon = Icons.getFlatIcon(icon);
      return (
        Icon && (
          <Styled.IconSpan data-test="insights-scope-icons" key={icon}>
            <Icon dimension={24} />
          </Styled.IconSpan>
        )
      );
    })}
  </Styled.Footer>
);

const InsightMetric = ({
  networkIcons,
  insightType,
  isF5S,
  tagType,
  tagValue,
  kpis,
  onClick,
  thumbnailUrl,
  thumbnailLoading,
  iconThumbnail,
  iconProps,
  spend,
  frequency,
  score,
  wrapped = false,
  skeletonOnly = false,
  appcuesId = '',
}: InsightMetricProps) => {
  const tooltipContent = (
    <Styled.TooltipContainer>
      <Typography type="text-sm" fontWeight="medium">
        Replai Confidence© badge is based on the level of impact you'll get on
        your next video's success if you use the insight. We only show you the
        most impacting ones.
      </Typography>
      <AppcuesTutorial appcuesId={appcuesId}>
        <Styled.TooltipLink
          type="text-sm"
          fontWeight="medium"
          style={{ textDecoration: 'underline' }}
        >
          Know more
        </Styled.TooltipLink>
      </AppcuesTutorial>
    </Styled.TooltipContainer>
  );

  return (
    <Styled.MainContainer
      data-test="insights-cards"
      onClick={onClick}
      aria-hidden="true"
      $wrapped={wrapped}
    >
      {skeletonOnly ? (
        <SkeletonLoading
          width="100%"
          height={Styled.getHeight({
            wrapped,
          })}
        />
      ) : (
        <>
          <Styled.Container $wrapped={wrapped}>
            <TagThumbnail
              iconProps={iconProps}
              iconThumbnail={iconThumbnail}
              thumbnailLoading={thumbnailLoading}
              thumbnailUrl={thumbnailUrl}
            />
            {wrapped && (
              <Styled.ReplaiScoreContainer>
                <Tooltip content={tooltipContent} enableMaxContent>
                  <div>
                    <CustomScore score={score ?? 0} size="md" />
                  </div>
                </Tooltip>
              </Styled.ReplaiScoreContainer>
            )}
            <Styled.RightContainer $wrapped={wrapped}>
              <Styled.Header>
                <TagTypeValue
                  insightType={insightType}
                  tagType={tagType}
                  tagValue={tagValue}
                  wrapped={wrapped}
                />
                {isF5S && <IntroIcon />}
                {!wrapped && (
                  <Tooltip content={tooltipContent} enableMaxContent>
                    <div>
                      <CustomScore score={score ?? 0} size="sm" />
                    </div>
                  </Tooltip>
                )}
              </Styled.Header>
              <Styled.Body>
                <KPIs kpis={kpis} insightType={insightType} />
                <SpendAndFrequency frequency={frequency} spend={spend} />
              </Styled.Body>
            </Styled.RightContainer>
          </Styled.Container>
          {!!networkIcons?.length && <Footer networkIcons={networkIcons} />}
        </>
      )}
    </Styled.MainContainer>
  );
};

export default InsightMetric;
