import { useState, useRef } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player/lazy';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { TimelineTrack } from '../Timeline/TimelineBody/TimelineRow/TimelineRow';
import Colors from '../Colors';
import { calculateClusterViewPreviewVideoStartTime } from '../../utils/calculateClusterViewPreviewVideoStartTime';
import { PreviewVideo } from '..';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 26.25rem;
`;

const TimelineContainer = styled.div`
  width: 100%;
  height: 1.25rem;
  padding-right: 1rem;
  padding-left: 1rem;
`;

const VideoWithTimeline = ({
  url,
  timeframes,
  onPlay,
  onPause,
  onPressProgressBar,
}: PreviewVideo) => {
  const [duration, setDuration] = useState(0);
  const playerRef = useRef<ReactPlayer>(null);
  const hasTimeframes = timeframes?.length ?? false;

  const onLoadedMetadata = (data: React.SyntheticEvent<HTMLVideoElement>) => {
    const targetElm = data?.target as HTMLVideoElement;

    if (hasTimeframes && targetElm?.duration) {
      setDuration(targetElm?.duration);
    }
  };

  const seekToPoint = (point: number) => {
    onPressProgressBar?.();
    playerRef.current?.seekTo(point, 'seconds');
  };

  return (
    <Container>
      <VideoContainer>
        <VideoPlayer
          onPlay={onPlay}
          onPause={onPause}
          playerRef={playerRef}
          url={url.toString()}
          onLoadedMetadata={onLoadedMetadata}
          startTime={calculateClusterViewPreviewVideoStartTime(
            timeframes ?? []
          )}
        />
      </VideoContainer>
      {!!duration && hasTimeframes && (
        <TimelineContainer>
          <TimelineTrack
            segments={timeframes || []}
            duration={duration}
            color="Warning"
            seekToPoint={seekToPoint}
          />
        </TimelineContainer>
      )}
    </Container>
  );
};

export default VideoWithTimeline;
