import { IntegrationIconProps } from './types';

const Procreate = ({ dimension }: IntegrationIconProps) => (
  <svg
    width={dimension || 32}
    height={dimension || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 12.1333C2 8.58633 2 6.81283 2.69029 5.45806C3.29749 4.26637 4.26637 3.29749 5.45806 2.69029C6.81283 2 8.58633 2 12.1333 2H19.8667C23.4137 2 25.1872 2 26.5419 2.69029C27.7336 3.29749 28.7025 4.26637 29.3097 5.45806C30 6.81283 30 8.58633 30 12.1333V19.8667C30 23.4137 30 25.1872 29.3097 26.5419C28.7025 27.7336 27.7336 28.7025 26.5419 29.3097C25.1872 30 23.4137 30 19.8667 30H12.1333C8.58633 30 6.81283 30 5.45806 29.3097C4.26637 28.7025 3.29749 27.7336 2.69029 26.5419C2 25.1872 2 23.4137 2 19.8667V12.1333Z"
      fill="#242424"
    />
    <path
      d="M9.8659 16.4C5.96592 19.2704 5.19922 23.4667 5.19922 26.9667C5.377 25.9222 6.19255 23.4733 8.03255 22.0333C10.7659 19.8942 15.7659 20.5989 21.2326 16.9667C26.6992 13.3344 27.2326 8.60001 26.5326 4.96667C26.0462 8.47256 24.3864 10.0005 22.0992 11.3667C17.1326 14.3333 14.0326 13.3333 9.8659 16.4Z"
      fill="url(#paint0_linear_1334_799)"
    />
    <path
      d="M9.8659 16.4C5.96592 19.2704 5.19922 23.4667 5.19922 26.9667C5.377 25.9222 6.19255 23.4733 8.03255 22.0333C10.7659 19.8942 15.7659 20.5989 21.2326 16.9667C26.6992 13.3344 27.2326 8.60001 26.5326 4.96667C26.0462 8.47256 24.3864 10.0005 22.0992 11.3667C17.1326 14.3333 14.0326 13.3333 9.8659 16.4Z"
      fill="url(#paint1_linear_1334_799)"
    />
    <path
      d="M5.24609 25.532C5.4601 22.3695 6.48697 18.887 9.86612 16.4C12.0383 14.8013 13.9206 14.3078 15.9285 13.7813C17.1004 13.4741 18.315 13.1556 19.655 12.5997C19.1661 13.0667 16.843 14.1567 15.0907 14.8849C13.343 15.6113 11.5004 16.3772 9.96613 17.4C6.88794 19.4521 5.63632 23.0225 5.24609 25.532Z"
      fill="url(#paint2_linear_1334_799)"
    />
    <path
      d="M5.24609 25.532C5.4601 22.3695 6.48697 18.887 9.86612 16.4C12.0383 14.8013 13.9206 14.3078 15.9285 13.7813C17.1004 13.4741 18.315 13.1556 19.655 12.5997C19.1661 13.0667 16.843 14.1567 15.0907 14.8849C13.343 15.6113 11.5004 16.3772 9.96613 17.4C6.88794 19.4521 5.63632 23.0225 5.24609 25.532Z"
      fill="url(#paint3_linear_1334_799)"
    />
    <path
      d="M24.2169 14.3709C23.8336 14.7463 23.4063 15.0825 22.9326 15.3667C20.7993 16.6467 18.5317 17.0595 16.3253 17.4611C14.5671 17.7812 12.8477 18.0942 11.2659 18.8333C7.69949 20.4999 5.49955 23.9662 5.19932 26.9661C5.1993 26.9662 5.19928 26.9663 5.19926 26.9665C5.19924 26.9659 5.19923 26.9654 5.19922 26.9648C5.19924 26.7359 5.20254 26.5039 5.20978 26.2695C5.22795 25.8977 5.2664 25.4857 5.33001 25.0463C5.80085 22.5792 7.08141 19.323 9.96591 17.4C11.187 16.5859 12.6034 15.9347 14.0119 15.3365C15.2506 14.9298 16.3669 14.6768 17.4305 14.4356C19.1048 14.056 20.6484 13.7061 22.3326 12.8333C25.8667 11.002 26.7926 7.2388 26.5437 5.02515C27.0721 7.83003 26.8585 11.2799 24.2169 14.3709Z"
      fill="url(#paint4_linear_1334_799)"
    />
    <path
      d="M24.2171 14.371C23.8339 14.7464 23.4065 15.0825 22.9329 15.3667C20.7995 16.6467 18.532 17.0595 16.3256 17.4611C14.5673 17.7812 12.8479 18.0942 11.2662 18.8333C9.21514 19.7918 7.61604 21.3455 6.57031 23.0634C7.493 21.7669 8.89864 20.3185 10.6329 19.7667C12.3251 19.2282 13.9392 19.1726 15.6162 19.1148C16.0968 19.0982 16.5826 19.0815 17.0769 19.0532C18.409 18.5682 19.805 17.9154 21.2328 16.9667C22.4636 16.1489 23.4443 15.2753 24.2171 14.371Z"
      fill="url(#paint5_linear_1334_799)"
    />
    <path
      d="M24.2171 14.371C23.8339 14.7464 23.4065 15.0825 22.9329 15.3667C20.7995 16.6467 18.532 17.0595 16.3256 17.4611C14.5673 17.7812 12.8479 18.0942 11.2662 18.8333C9.21514 19.7918 7.61604 21.3455 6.57031 23.0634C7.493 21.7669 8.89864 20.3185 10.6329 19.7667C12.3251 19.2282 13.9392 19.1726 15.6162 19.1148C16.0968 19.0982 16.5826 19.0815 17.0769 19.0532C18.409 18.5682 19.805 17.9154 21.2328 16.9667C22.4636 16.1489 23.4443 15.2753 24.2171 14.371Z"
      fill="url(#paint6_linear_1334_799)"
    />
    <path
      d="M26.5454 5.02515C26.5418 5.00562 26.538 4.98613 26.5343 4.96667C26.0479 8.47256 24.3882 10.0005 22.101 11.3667C21.2345 11.8842 20.4249 12.281 19.6565 12.5998C19.1675 13.0667 16.8445 14.1567 15.0922 14.885L15.0919 14.8851C14.7353 15.0333 14.3748 15.1831 14.0137 15.3365C15.2523 14.9298 16.3686 14.6767 17.4322 14.4356C19.1065 14.056 20.6501 13.7061 22.3343 12.8333C25.8684 11.002 26.7943 7.2388 26.5454 5.02515Z"
      fill="url(#paint7_linear_1334_799)"
    />
    <path
      d="M26.5454 5.02515C26.5418 5.00562 26.538 4.98613 26.5343 4.96667C26.0479 8.47256 24.3882 10.0005 22.101 11.3667C21.2345 11.8842 20.4249 12.281 19.6565 12.5998C19.1675 13.0667 16.8445 14.1567 15.0922 14.885L15.0919 14.8851C14.7353 15.0333 14.3748 15.1831 14.0137 15.3365C15.2523 14.9298 16.3686 14.6767 17.4322 14.4356C19.1065 14.056 20.6501 13.7061 22.3343 12.8333C25.8684 11.002 26.7943 7.2388 26.5454 5.02515Z"
      fill="url(#paint8_linear_1334_799)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1334_799"
        x1="17.3992"
        y1="18.2"
        x2="5.86588"
        y2="26.4667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE5004" />
        <stop offset="0.602538" stopColor="#FFB915" />
        <stop offset="1" stopColor="#FFFC41" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1334_799"
        x1="12.8326"
        y1="18.9"
        x2="12.8992"
        y2="20.1"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD3E00" />
        <stop offset="1" stopColor="#FD4400" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1334_799"
        x1="19.6661"
        y1="12"
        x2="3.39945"
        y2="26.4667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005FC6" />
        <stop offset="0.279756" stopColor="#23A4DF" />
        <stop offset="0.465158" stopColor="#3AC2B7" />
        <stop offset="0.640642" stopColor="#60EC7A" />
        <stop offset="0.818668" stopColor="#CFFDBD" />
        <stop offset="1" stopColor="#D0FCB6" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1334_799"
        x1="16.3661"
        y1="14.7667"
        x2="14.9661"
        y2="13.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0061CB" />
        <stop offset="1" stopColor="#067EEA" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1334_799"
        x1="26.5992"
        y1="5.00001"
        x2="5.19922"
        y2="26.9333"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8E55F1" />
        <stop offset="0.140795" stopColor="#8B48DF" />
        <stop offset="0.274905" stopColor="#E04DD9" />
        <stop offset="0.492309" stopColor="#F496C8" />
        <stop offset="0.633104" stopColor="#FAD2BA" />
        <stop offset="0.856964" stopColor="#FEF7E5" />
        <stop offset="1" stopColor="#FFF5BF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1334_799"
        x1="24.4995"
        y1="14.1667"
        x2="7.03281"
        y2="22.0667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F41A73" />
        <stop offset="0.399909" stopColor="#FC50A3" />
        <stop offset="0.584511" stopColor="#FE9485" />
        <stop offset="0.703104" stopColor="#FFAF88" />
        <stop offset="1" stopColor="#FFC385" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1334_799"
        x1="18.1995"
        y1="16.6"
        x2="18.2328"
        y2="18.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9B0008" />
        <stop offset="1" stopColor="#EA115A" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1334_799"
        x1="26.601"
        y1="6.80001"
        x2="10.8343"
        y2="17.1"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#231283" />
        <stop offset="0.362865" stopColor="#7053C6" />
        <stop offset="0.466353" stopColor="#7272DB" />
        <stop offset="0.634311" stopColor="#399EE9" />
        <stop offset="1" stopColor="#00CFFF" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1334_799"
        x1="22.6676"
        y1="12"
        x2="23.3676"
        y2="12.9"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3F259D" stopOpacity="0" />
        <stop offset="1" stopColor="#412DAA" />
      </linearGradient>
    </defs>
  </svg>
);
export default Procreate;
