import { CountryIconProps } from './types';

const RO = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3695)">
      <path
        d="M16.1745 0.745367C14.8744 0.26307 13.4684 -0.000976556 12.0006 -0.000976556C10.5327 -0.00102343 9.12667 0.26307 7.82669 0.745367L6.7832 11.999L7.82669 23.2526C9.12662 23.735 10.5327 23.999 12.0006 23.999C13.4684 23.999 14.8745 23.735 16.1745 23.2526L17.2179 11.999L16.1745 0.745367Z"
        fill="#FFDA44"
      />
      <path
        d="M23.9999 11.9996C23.9999 6.84005 20.7434 2.44144 16.1738 0.745972V23.2533C20.7434 21.5576 23.9999 17.1592 23.9999 11.9996Z"
        fill="#D80027"
      />
      <path
        d="M0 11.9995C0 17.1592 3.2565 21.5576 7.82602 23.2532L7.82606 0.745972C3.2565 2.44144 0 6.83991 0 11.9995Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3695">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default RO;
