import { Colors } from '@replai-platform/ui-components';
import * as Styled from './styles';

export interface SidebarSectionItemProps {
  title: string;
}

const SidebarSectionItem: React.FC<SidebarSectionItemProps> = ({ title, children }) => (
  <Styled.Container>
    <Styled.TypographyClean type="text-sm" color={Colors.Gray[500]} fontWeight="medium">
      {title}
    </Styled.TypographyClean>
    <Styled.ValueContainer>{children}</Styled.ValueContainer>
  </Styled.Container>
);

export default SidebarSectionItem;
