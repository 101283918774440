import { CountryIconProps } from './types';

const GB = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M2.48032 4.69299C1.53771 5.91938 0.826945 7.3329 0.413086 8.86848H6.6558L2.48032 4.69299Z"
      fill="#0052B4"
    />
    <path
      d="M23.5864 8.86855C23.1725 7.33302 22.4617 5.91951 21.5191 4.69312L17.3438 8.86855H23.5864Z"
      fill="#0052B4"
    />
    <path
      d="M0.413086 15.1302C0.826992 16.6658 1.53776 18.0793 2.48032 19.3056L6.65567 15.1302H0.413086Z"
      fill="#0052B4"
    />
    <path
      d="M19.3053 2.47976C18.0789 1.53715 16.6655 0.826382 15.1299 0.412476V6.65515L19.3053 2.47976Z"
      fill="#0052B4"
    />
    <path
      d="M4.69336 21.5176C5.91975 22.4602 7.33327 23.171 8.8688 23.5849V17.3423L4.69336 21.5176Z"
      fill="#0052B4"
    />
    <path
      d="M8.86875 0.412476C7.33322 0.826382 5.9197 1.53715 4.69336 2.47971L8.86875 6.6551V0.412476Z"
      fill="#0052B4"
    />
    <path
      d="M15.1299 23.5849C16.6654 23.171 18.0789 22.4602 19.3053 21.5177L15.1299 17.3423V23.5849Z"
      fill="#0052B4"
    />
    <path
      d="M17.3438 15.1302L21.5191 19.3057C22.4617 18.0793 23.1725 16.6658 23.5864 15.1302H17.3438Z"
      fill="#0052B4"
    />
    <path
      d="M23.8984 10.4348H13.5653H13.5653V0.101578C13.0529 0.034875 12.5305 0 12 0C11.4694 0 10.9471 0.034875 10.4348 0.101578V10.4347V10.4347H0.101578C0.034875 10.9471 0 11.4695 0 12C0 12.5306 0.034875 13.0529 0.101578 13.5652H10.4347H10.4347V23.8984C10.9471 23.9651 11.4694 24 12 24C12.5305 24 13.0529 23.9652 13.5652 23.8984V13.5653V13.5653H23.8984C23.9651 13.0529 24 12.5306 24 12C24 11.4695 23.9651 10.9471 23.8984 10.4348Z"
      fill="#D80027"
    />
    <path
      d="M15.1309 15.1313L20.4857 20.4861C20.732 20.2399 20.9669 19.9825 21.191 19.7157L16.6066 15.1312H15.1309V15.1313Z"
      fill="#D80027"
    />
    <path
      d="M8.86951 15.1312H8.86941L3.51465 20.486C3.76084 20.7323 4.01823 20.9672 4.28504 21.1914L8.86951 16.6068V15.1312Z"
      fill="#D80027"
    />
    <path
      d="M8.86976 8.86911V8.86902L3.51495 3.51416C3.26866 3.76035 3.03373 4.01774 2.80957 4.28455L7.39409 8.86907H8.86976V8.86911Z"
      fill="#D80027"
    />
    <path
      d="M15.1309 8.87017L20.4857 3.51526C20.2395 3.26898 19.9821 3.03405 19.7153 2.80994L15.1309 7.39445V8.87017Z"
      fill="#D80027"
    />
  </svg>
);
export default GB;
