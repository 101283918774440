import { Link } from 'react-router-dom';
import Colors from '../Colors';
import * as Styled from './styles';

const AppcuesTutorial: React.FC<{
  appcuesId: string;
}> = ({ appcuesId, children }) => (
  <Link
    to={`${window.location.search}`}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window?.Appcues?.show(appcuesId);
    }}
  >
    {children ? (
      children
    ) : (
      <Styled.HelpIcon dimension={16} color={Colors.Gray[500]} />
    )}
  </Link>
);

export default AppcuesTutorial;
