import styled from 'styled-components';

export const FiltersGroup = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
`;

export const ItemContainer = styled.div`
  position: relative;
  padding-right: 16px;
`;
