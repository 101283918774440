import { IconProps } from './types';

const Scales = ({ color, dimension }: IconProps) => (
  <svg
    fill="none"
    height={dimension || 24}
    viewBox="0 0 24 24"
    width={dimension || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 15V14.8498C1 14.5333 1 14.3751 1.02421 14.2209C1.0457 14.084 1.08136 13.9497 1.13061 13.8202C1.18609 13.6743 1.2646 13.5369 1.42162 13.2622L5 7M1 15C1 17.2091 2.79086 19 5 19C7.20914 19 9 17.2091 9 15M1 15V14.8C1 14.52 1 14.38 1.0545 14.273C1.10243 14.1789 1.17892 14.1024 1.273 14.0545C1.37996 14 1.51997 14 1.8 14H8.2C8.48003 14 8.62004 14 8.727 14.0545C8.82108 14.1024 8.89757 14.1789 8.9455 14.273C9 14.38 9 14.52 9 14.8V15M5 7L8.57838 13.2622C8.7354 13.5369 8.81391 13.6743 8.86939 13.8202C8.91864 13.9497 8.9543 14.084 8.97579 14.2209C9 14.3751 9 14.5333 9 14.8498V15M5 7L17 5M13 13V12.8498C13 12.5333 13 12.3751 13.0242 12.2209C13.0457 12.084 13.0814 11.9497 13.1306 11.8202C13.1861 11.6743 13.2646 11.5369 13.4216 11.2622L17 5M13 13C13 15.2091 14.7909 17 17 17C19.2091 17 21 15.2091 21 13M13 13V12.8C13 12.52 13 12.38 13.0545 12.273C13.1024 12.1789 13.1789 12.1024 13.273 12.0545C13.38 12 13.52 12 13.8 12H20.2C20.48 12 20.62 12 20.727 12.0545C20.8211 12.1024 20.8976 12.1789 20.9455 12.273C21 12.38 21 12.52 21 12.8V13M17 5L20.5784 11.2622C20.7354 11.5369 20.8139 11.6743 20.8694 11.8202C20.9186 11.9497 20.9543 12.084 20.9758 12.2209C21 12.3751 21 12.5333 21 12.8498V13M11 1V6"
      stroke={color || '#101828'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default Scales;
