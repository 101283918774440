/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/unbound-method */
import { useContext, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import ButtonGroupContext from './context';
import RegularButton, { ButtonProps } from '../Button/Button';

const Button = ({
  color: customColor,
  variant: customVariant,
  size: customSize,
  leadingIcon: customLeadingIcon,
  trailingIcon: customTrailingIcon,
  ...props
}: ButtonProps) => {
  const id = useRef(uuid());
  const {
    color,
    variant,
    size,
    position,
    leadingIcon,
    trailingIcon,
    pushToPosition,
  } = useContext(ButtonGroupContext);

  useEffect(() => {
    if (!position.includes(id.current)) {
      pushToPosition(id.current);
    }
  }, [position, pushToPosition]);

  /* The list is populated in inverted order */
  const isFirst = position[position.length - 1] === id.current;
  const isLast = position[0] === id.current;

  return (
    <RegularButton
      color={customColor || color}
      variant={customVariant || variant}
      size={customSize || size}
      leadingIcon={customLeadingIcon || leadingIcon}
      trailingIcon={customTrailingIcon || trailingIcon}
      isFirst={isFirst}
      isLast={isLast}
      isGroupButton
      {...props}
    />
  );
};

export default Button;
