import { CountryIconProps } from './types';

const IO = ({ dimension }: CountryIconProps) => (
  <svg
    width={dimension || 24}
    height={dimension || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1046_3581)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M21.9999 13.4358C20.9999 13.4358 20.9999 12.5214 19.9998 12.5214C18.9999 12.5214 18.9999 13.4358 17.9999 13.4358C17 13.4358 16.9999 12.5214 16 12.5214C15 12.5214 15 13.4358 14 13.4358C13 13.4358 12.9999 12.5214 11.9998 12.5214C10.9998 12.5214 10.9998 13.4358 9.99991 13.4358C9.00006 13.4358 9.00006 12.5214 8.00008 12.5214C7 12.5214 7 13.4358 5.99992 13.4358C4.99984 13.4358 4.99984 12.5214 3.99977 12.5214C2.99992 12.5214 2.99992 13.4358 1.99994 13.4358C1.00534 13.4358 0.999578 12.5315 0.015625 12.5218C0.0433281 13.0707 0.108859 13.6096 0.207953 14.1366C1.00502 14.2537 1.07481 15.0357 2.00003 15.0357C3.00002 15.0357 3.00002 14.1215 3.99986 14.1215C4.99994 14.1215 4.99994 15.0357 6.00002 15.0357C7.00009 15.0357 7.00009 14.1215 8.00017 14.1215C9.00016 14.1215 9.00016 15.0357 10 15.0357C11 15.0357 11 14.1215 11.9999 14.1215C13 14.1215 13 15.0357 14.0001 15.0357C15.0002 15.0357 15.0002 14.1215 16.0001 14.1215C17.0001 14.1215 17.0001 15.0357 18 15.0357C19 15.0357 19 14.1215 19.9999 14.1215C21 14.1215 21 15.0357 22 15.0357C22.9252 15.0357 22.995 14.2537 23.7921 14.1366C23.8913 13.6096 23.9567 13.0708 23.9845 12.5218C23.0004 12.5315 22.9945 13.4358 21.9999 13.4358Z"
        fill="#0052B4"
      />
      <path
        d="M20.0003 15.6514C19.0003 15.6514 19.0003 16.5658 18.0003 16.5658C17.0004 16.5658 17.0004 15.6514 16.0004 15.6514C15.0004 15.6514 15.0004 16.5658 14.0004 16.5658C13.0003 16.5658 13.0003 15.6514 12.0003 15.6514C11.0003 15.6514 11.0003 16.5658 10.0003 16.5658C9.00049 16.5658 9.00049 15.6514 8.00051 15.6514C7.00043 15.6514 7.00043 16.5658 6.00035 16.5658C5.00027 16.5658 5.00027 15.6514 4.0002 15.6514C3.00035 15.6514 3.00035 16.5658 2.00037 16.5658C1.26373 16.5658 1.06924 16.0699 0.618164 15.8087C0.891352 16.6253 1.25018 17.4024 1.68354 18.1301C1.77738 18.1527 1.88173 18.1658 2.00032 18.1658C3.0003 18.1658 3.0003 17.2515 4.00015 17.2515C5.00023 17.2515 5.00023 18.1658 6.0003 18.1658C7.00038 18.1658 7.00038 17.2515 8.00046 17.2515C9.00045 17.2515 9.00045 18.1658 10.0003 18.1658C11.0003 18.1658 11.0003 17.2515 12.0002 17.2515C13.0003 17.2515 13.0003 18.1658 14.0004 18.1658C15.0004 18.1658 15.0004 17.2515 16.0004 17.2515C17.0004 17.2515 17.0004 18.1658 18.0003 18.1658C19.0002 18.1658 19.0003 17.2515 20.0002 17.2515C21.0003 17.2515 21.0003 18.1658 22.0002 18.1658C22.1189 18.1658 22.2234 18.1527 22.3173 18.13C22.7507 17.4024 23.1094 16.6253 23.3826 15.8088C22.9315 16.07 22.737 16.5658 22.0002 16.5658C21.0004 16.5658 21.0003 15.6514 20.0003 15.6514Z"
        fill="#0052B4"
      />
      <path
        d="M23.7211 9.41946C22.9902 9.57119 22.897 10.306 22.0002 10.306C21.0002 10.306 21.0002 9.39166 20.0002 9.39166C19.0002 9.39166 19.0002 10.306 18.0002 10.306C17.0003 10.306 17.0003 9.39166 16.0003 9.39166C15.0003 9.39166 15.0003 10.306 14.0003 10.306C13.0003 10.306 13.0002 9.39166 12.0001 9.39166C11.4598 9.39166 11.2114 9.65857 10.9395 9.904V11.504C11.2114 11.2586 11.4598 10.9917 12.0001 10.9917C13.0002 10.9917 13.0002 11.906 14.0003 11.906C15.0004 11.906 15.0004 10.9917 16.0003 10.9917C17.0003 10.9917 17.0003 11.906 18.0002 11.906C19.0002 11.906 19.0002 10.9917 20.0002 10.9917C21.0002 10.9917 21.0002 11.906 22.0002 11.906C22.9254 11.906 22.9952 11.124 23.7923 11.0069C23.8434 11.0069 23.9005 11.0064 23.959 11.0057C23.9147 10.466 23.8345 9.93654 23.7211 9.41946Z"
        fill="#0052B4"
      />
      <path
        d="M22.8445 6.85727C22.6373 7.03132 22.3978 7.17588 22.0002 7.17588C21.0002 7.17588 21.0002 6.26154 20.0002 6.26154C19.0002 6.26154 19.0002 7.17588 18.0002 7.17588C17.0003 7.17588 17.0003 6.26154 16.0003 6.26154C15.0003 6.26154 15.0003 7.17588 14.0003 7.17588C13.0003 7.17588 13.0002 6.26154 12.0001 6.26154C11.4598 6.26154 11.2114 6.52844 10.9395 6.77388V8.37386C11.2114 8.12847 11.4598 7.86161 12.0001 7.86161C13.0002 7.86161 13.0002 8.77586 14.0003 8.77586C15.0003 8.77586 15.0003 7.86161 16.0003 7.86161C17.0003 7.86161 17.0003 8.77586 18.0002 8.77586C19.0001 8.77586 19.0001 7.86161 20.0001 7.86161C21.0002 7.86161 21.0002 8.77586 22.0001 8.77586C22.7097 8.77586 22.9164 8.31625 23.3335 8.04897C23.1914 7.6413 23.0281 7.24357 22.8445 6.85727Z"
        fill="#0052B4"
      />
      <path
        d="M20.0856 3.13263C20.0579 3.13113 20.0296 3.13019 20.0002 3.13019C19.0002 3.13019 19.0002 4.04453 18.0003 4.04453C17.0003 4.04453 17.0003 3.13019 16.0004 3.13019C15.0004 3.13019 15.0004 4.04453 14.0003 4.04453C13.0002 4.04453 13.0002 3.13019 12.0001 3.13019C11.4598 3.13019 11.2114 3.39709 10.9395 3.64253V5.24252C11.2114 4.99713 11.4598 4.73027 12.0001 4.73027C13.0002 4.73027 13.0002 5.64452 14.0003 5.64452C15.0004 5.64452 15.0004 4.73027 16.0003 4.73027C17.0003 4.73027 17.0003 5.64452 18.0002 5.64452C19.0002 5.64452 19.0002 4.73027 20.0002 4.73027C21.0002 4.73027 21.0002 5.64452 22.0002 5.64452C22.0618 5.64452 22.1193 5.64086 22.1738 5.63435C21.5929 4.70791 20.8896 3.86617 20.0856 3.13263Z"
        fill="#0052B4"
      />
      <path
        d="M15.0668 0.396249C14.7923 0.643186 14.5443 0.914405 13.9996 0.914405C12.9996 0.914405 12.9996 0.000154785 11.9995 6.10352e-05H11.9994C11.4822 0.00010791 11.2326 0.244655 10.9736 0.480858V2.08089C11.2325 1.84469 11.4823 1.60009 11.9995 1.60009C12.9996 1.60009 12.9996 2.51434 13.9997 2.51434C14.9997 2.51434 14.9997 1.60009 15.9997 1.60009C16.9997 1.60009 16.9997 2.51434 17.9996 2.51434C18.4192 2.51434 18.6626 2.35328 18.8778 2.16644C17.7371 1.36698 16.4509 0.761077 15.0668 0.396249Z"
        fill="#0052B4"
      />
      <path
        d="M14.0003 22.8273C13.0002 22.8273 13.0002 21.913 12.0001 21.913C11.0002 21.913 11.0002 22.8273 10.0002 22.8273C9.00037 22.8273 9.00037 21.913 8.00038 21.913C7.2344 21.913 7.05473 22.4489 6.56348 22.7001C7.91817 23.3898 9.42131 23.8294 11.0122 23.9591C11.2583 23.7343 11.5082 23.513 12.0001 23.513C12.4922 23.513 12.7421 23.7343 12.9882 23.9592C14.5792 23.8295 16.0824 23.3898 17.4372 22.7001C16.9458 22.449 16.7662 21.9129 16.0003 21.9129C15.0004 21.9129 15.0004 22.8273 14.0003 22.8273Z"
        fill="#0052B4"
      />
      <path
        d="M18.0003 19.6977C17.0003 19.6977 17.0003 18.7833 16.0003 18.7833C15.0004 18.7833 15.0004 19.6977 14.0003 19.6977C13.0002 19.6977 13.0003 18.7833 12.0002 18.7833C11.0002 18.7833 11.0002 19.6977 10.0003 19.6977C9.00041 19.6977 9.00041 18.7833 8.00042 18.7833C7.00034 18.7833 7.00034 19.6977 6.00027 19.6977C5.00019 19.6977 5.00019 18.7833 4.00011 18.7833C3.281 18.7833 3.07906 19.2563 2.65039 19.5218C2.91908 19.8555 3.20473 20.1749 3.50703 20.4777C3.64245 20.4199 3.80136 20.3834 4.00006 20.3834C5.00014 20.3834 5.00014 21.2976 6.00022 21.2976C7.0003 21.2976 7.0003 20.3834 8.00038 20.3834C9.00036 20.3834 9.00036 21.2976 10.0002 21.2976C11.0002 21.2976 11.0002 20.3834 12.0001 20.3834C13.0002 20.3834 13.0002 21.2976 14.0003 21.2976C15.0004 21.2976 15.0004 20.3834 16.0003 20.3834C17.0002 20.3834 17.0003 21.2976 18.0002 21.2976C19.0002 21.2976 19.0002 20.3834 20.0001 20.3834C20.1991 20.3834 20.358 20.4199 20.4935 20.4778C20.7958 20.1749 21.0815 19.8555 21.3502 19.5219C20.9215 19.2564 20.7195 18.7834 20.0002 18.7834C19.0002 18.7833 19.0002 19.6977 18.0003 19.6977Z"
        fill="#0052B4"
      />
      <path
        d="M11.9678 11.9991H11.9999C11.9999 11.9883 11.9999 11.9778 11.9999 11.9669C11.9892 11.9777 11.9785 11.9884 11.9678 11.9991Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.0001 6.26153V9.78714L7.82617 5.73981L12.0001 6.26153Z"
        fill="#0052B4"
      />
      <path
        d="M6.26102 12.0005H9.78649L6.26098 7.30487L5.73926 10.957L6.26102 12.0005Z"
        fill="#0052B4"
      />
      <path
        d="M12 6.26086C12 4.14937 12 2.76586 12 0H11.998C5.3715 0.001125 0 5.37323 0 12H6.2609V8.47448L9.78637 12H11.9679C11.9786 11.9893 11.9893 11.9786 12 11.9679C12 11.1594 12 10.4381 12 9.78647L8.47443 6.26086H12Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.07124 1.56415C4.19441 2.63285 2.63413 4.19313 1.56543 6.06996V11.9989H4.69588V4.6947V4.6946H12.0002C12.0002 3.70727 12.0002 2.76668 12.0002 1.56415H6.07124Z"
        fill="#D80027"
      />
      <path
        d="M11.9999 10.5234L7.73637 6.25995H6.26074V6.26004L11.9998 11.999H11.9999C11.9999 11.999 11.9999 10.9815 11.9999 10.5234Z"
        fill="#D80027"
      />
      <path
        d="M15.6523 6.26099H20.348L18.0002 8.60882L15.6523 6.26099Z"
        fill="#6DA544"
      />
      <path d="M18.522 8.08649H17.4785V10.956H18.522V8.08649Z" fill="#A2001D" />
      <path d="M18.521 15.652H17.4775V18.5215H18.521V15.652Z" fill="#A2001D" />
      <path
        d="M21.13 14.0874C21.13 13.2229 20.4292 12.5222 19.5648 12.5222C19.1638 12.5222 18.7983 12.6731 18.5213 12.921V12.0004H19.043V10.9569H18.5213V10.4352H17.4778V10.9569H16.9561V12.0004H17.4778V12.921C17.2008 12.6731 16.8353 12.5222 16.4343 12.5222C15.5699 12.5222 14.8691 13.2229 14.8691 14.0874C14.8691 14.5508 15.0708 14.9671 15.3909 15.2537V16.1743H20.6082V15.2537C20.9284 14.9671 21.13 14.5508 21.13 14.0874Z"
        fill="#FFDA44"
      />
      <path
        d="M19.5652 13.5649C19.4006 13.5649 19.2854 13.6372 19.2177 13.6978L18.5217 14.3208H17.4783L16.7823 13.6978C16.7146 13.6372 16.5994 13.5649 16.4348 13.5649C16.1471 13.5649 15.9131 13.799 15.9131 14.0867C15.9131 14.2893 16.0224 14.4173 16.0875 14.4757L16.4349 14.7867V15.1301H19.5653V14.7867L19.9127 14.4757C19.9778 14.4174 20.0871 14.2893 20.0871 14.0867C20.087 13.799 19.8529 13.5649 19.5652 13.5649Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1046_3581">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IO;
