export const formatDuration = (
  durationInSeconds: number
): {
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
} => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds - hours * 3600) / 60);
  const secondsFull = durationInSeconds - hours * 3600 - minutes * 60;
  const seconds = Math.floor(secondsFull);
  const milliseconds = Math.floor((secondsFull - seconds) * 1000);
  return {
    hours,
    minutes,
    seconds,
    milliseconds,
  };
};
