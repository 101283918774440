/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Modal } from '@replai-platform/ui-components';
import { useState } from 'react';
import styled from 'styled-components';

export const ImgBox = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
  }
`;

const ClickableImagePreview = ({ imageUrl }: { imageUrl: string }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <img
        src={imageUrl}
        onClick={() => setOpen(true)}
        aria-label="clickable image"
        style={{
          position: 'relative',
          cursor: 'pointer',
          width: '100%',
          height: 'auto',
          borderRadius: '6px',
          zIndex: 1, // To show above the skeleton
        }}
      />
      <Modal
        onClick={(e) => {
          e.stopPropagation();
        }}
        onClose={() => setOpen(false)}
        isOpen={open}
      >
        <ImgBox>
          <img src={imageUrl} aria-label="clickable image" />
        </ImgBox>
      </Modal>
    </div>
  );
};

export default ClickableImagePreview;
