import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMeasure } from 'react-use';
import Badge, { BadgeProps } from '../Badge/Badge';
import Tooltip from '../Tooltip/Tooltip';
import Colors from '../Colors';
import { KPI, InsightType } from './InsightMetric';

const NON_TEXT_BADGE_WIDTH = 34;
const WIDTH_BETWEEN_BADGES = 8;
const PLUS_TOOLTIP_BADGE_WIDTH = 38;

const measureText = (text: string): number => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') || new CanvasRenderingContext2D();
  const { width } = context?.measureText(text) ?? { width: 0 };

  return width * 1.3;
};

const KpisContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const OverflowBadge = styled(Badge)`
  align-self: center;
`;

const NoWrapBadge = styled(Badge)`
  white-space: nowrap;
`;

const KPIs = ({
  kpis,
  insightType,
}: {
  kpis?: KPI[];
  insightType?: InsightType;
}) => {
  const [numberOfBadges, setNumberOfBadges] = useState(0);

  const [badgesContainerRef, { width: badgesContainerWidth }] =
    useMeasure<HTMLDivElement>();

  const kpisInCard =
    kpis?.length ?? 0 > numberOfBadges ? kpis?.slice(0, numberOfBadges) : kpis;
  const kpisInTooltip =
    kpis?.length ?? 0 > numberOfBadges
      ? kpis?.slice(numberOfBadges, kpis?.length)
      : [];

  const getBadgeProps = (kpi: KPI): BadgeProps => ({
    color: insightType === 'positive' ? 'Success' : 'Error',
    size: 'sm',
    leadingIcon:
      kpi.signMaximize && kpi.signMaximize > 0 ? 'ArrowUp' : 'ArrowDown',
    leadingIconColor:
      insightType === 'positive' ? Colors.Success[500] : Colors.Error[500],
  });

  const kpisTooltipContent = kpisInTooltip?.map((kpi) => (
    <Badge key={kpi.unit} noBackground {...getBadgeProps(kpi)}>
      {kpi.unit}
    </Badge>
  ));

  useEffect(() => {
    let currentVisibleWidth = 0;
    let numberOfVisibleBadges = 0;

    kpis?.some((kpi, index) => {
      currentVisibleWidth += measureText(kpi.unit) + NON_TEXT_BADGE_WIDTH;
      if (
        currentVisibleWidth +
          (index + 1 === kpis.length ? 0 : PLUS_TOOLTIP_BADGE_WIDTH) >
        badgesContainerWidth
      ) {
        return true;
      }

      currentVisibleWidth += WIDTH_BETWEEN_BADGES;
      numberOfVisibleBadges += 1;

      return false;
    });

    setNumberOfBadges(numberOfVisibleBadges);
  }, [badgesContainerWidth, kpis]);

  return (
    <KpisContainer ref={badgesContainerRef}>
      {kpisInCard?.map((kpi) => (
        <NoWrapBadge
          key={kpi.unit}
          data-test="kpi-unit"
          {...getBadgeProps(kpi)}
        >
          {kpi.unit}
        </NoWrapBadge>
      ))}
      {!!kpisInTooltip?.length && (
        <Tooltip content={kpisTooltipContent} placement="bottom">
          <OverflowBadge color="Gray">{`+${kpisInTooltip.length}`}</OverflowBadge>
        </Tooltip>
      )}
    </KpisContainer>
  );
};

export default KPIs;
