import { useQuery, UseQueryOptions } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';
import { filtersAsQueryParams } from '../../api/filters';

// TODO: Move to SDK
interface GetTagByTypeValueRequest {
  fields?: string[];
  projectId: SDK.UUID;
  type: string;
  value?: string | null;
}

interface GetTagTypeByValueResponse {
  exampleUrl?: string;
  description?: string;
}

const getTagByTypeValue = async (requestParams: GetTagByTypeValueRequest) => {
  const { fields, projectId, type, value } = requestParams;
  const queryParams: string[] = [];
  queryParams.push(
    ...filtersAsQueryParams({
      projectId,
    })
  );
  if ((fields?.length ?? 0) > 0) {
    queryParams.push(`fields=${encodeURIComponent(fields as unknown as string)}`);
  }
  const valueInBase64 = value ? window.btoa(value) : '';
  const url = `${SDK.BASE_URL()}/tags/${type}/values/${valueInBase64}${
    queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
  }`;
  const res = await provider.getJson<object, GetTagTypeByValueResponse>(url);
  return res;
};

export default function useTagByTypeValue(
  requestParams: GetTagByTypeValueRequest,
  options?: UseQueryOptions<GetTagTypeByValueResponse>
) {
  return useQuery(['tag-type-value', requestParams], () => getTagByTypeValue(requestParams), options);
}
