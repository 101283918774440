import styled from 'styled-components';
import Colors from '../../Colors';
import PlayCircle2 from '../../Icons/Base/PlayCircle2';
import PauseCircle2 from '../../Icons/Base/PauseCircle2';
import Button from '../../Button/Button';
import { formatDuration } from '../../../utils';
import Toggle from '../../Toggle/Toggle';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  background-color: transparent;
  border-bottom: 1px solid ${Colors.Gray[200]};
`;

const PlayerControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const DurationMain = styled.span`
  color: ${Colors.Gray[900]};
  font-weight: bold;
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
`;

const DurationSub = styled(DurationMain)`
  font-weight: normal;
`;

const GroupUngroupButton = styled(Button)`
  height: 40px;
  width: 100px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
`;

interface DefaultHeaderActionsProps {
  projectId?: string;
  clusterId?: string;
  groupByTagCategory: boolean;
  setShowOnlyRecommendations: (showOnlyRecommendations: boolean) => void;
  setGroupByTagCategory: (groupByTagCategory: boolean) => void;
  showWithBreakdowns?: boolean;
  showRecommendationsToggle?: boolean;
  resetFilters?: () => void;
  onClickGroup?: (group: boolean) => void;
  onClickWithBreakdowns?: (withBreakdowns: boolean) => void;
  onClickShowOnlyRecommendations?: () => void;
}

const DefaultHeaderActions: React.FC<DefaultHeaderActionsProps> = ({
  groupByTagCategory,
  setShowOnlyRecommendations,
  setGroupByTagCategory,
  showWithBreakdowns = false,
  showRecommendationsToggle = true,
  resetFilters,
  onClickGroup,
  onClickWithBreakdowns,
  onClickShowOnlyRecommendations,
}) => (
  <Actions>
    {showWithBreakdowns && (
      <Toggle
        checked={false}
        label="With breakdowns"
        labelPosition="start"
        onChange={(checked: boolean) => onClickWithBreakdowns?.(checked)}
        variant="outlined"
      />
    )}
    {showRecommendationsToggle ? (
      <Toggle
        checked={false}
        label="Show only recommendations"
        labelPosition="start"
        onChange={(checked: boolean) => {
          onClickShowOnlyRecommendations?.();
          setShowOnlyRecommendations(checked);
        }}
        variant="outlined"
      />
    ) : (
      ''
    )}
    <GroupUngroupButton
      color="Gray"
      variant="outlined"
      leadingIcon={{ name: groupByTagCategory ? 'Maximize2' : 'Minimize2' }}
      onClick={() => {
        setGroupByTagCategory(!groupByTagCategory);
        onClickGroup?.(!groupByTagCategory);

        if (groupByTagCategory) {
          resetFilters?.();
        }
      }}
    >
      {groupByTagCategory ? 'Ungroup' : 'Group'}
    </GroupUngroupButton>
  </Actions>
);

interface PlayerTimestampProps {
  playedSeconds: number;
}

const PlayerTimestamp: React.FC<PlayerTimestampProps> = ({ playedSeconds }) => {
  const { minutes, seconds, milliseconds } = formatDuration(playedSeconds);
  return (
    <div>
      <DurationMain>{`${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`}</DurationMain>
      <DurationSub>{`:${milliseconds
        .toString()
        .padStart(2, '0')}`}</DurationSub>
    </div>
  );
};

interface PlayerControlsContainerProps {
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  playedSeconds: number;
}

const PlayerControlsContainer: React.FC<PlayerControlsContainerProps> = ({
  isPlaying,
  setIsPlaying,
  playedSeconds,
}) => (
  <PlayerControls>
    {isPlaying ? (
      <PauseCircle2 dimension={28} onClick={() => setIsPlaying(false)} />
    ) : (
      <PlayCircle2 dimension={28} onClick={() => setIsPlaying(true)} />
    )}
    <PlayerTimestamp playedSeconds={playedSeconds} />
  </PlayerControls>
);

export interface TimelineHeaderProps {
  customHeaderActionsComponent?: React.ReactNode;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  playedSeconds: number;
  groupByTagCategory: boolean;
  setGroupByTagCategory: (groupByTagCategory: boolean) => void;
  setShowOnlyRecommendations: (showOnlyRecommendations: boolean) => void;
  showWithBreakdowns?: boolean;
  showRecommendationsToggle?: boolean;
  resetFilters?: () => void;
  onClickGroup?: (group: boolean) => void;
  onClickWithBreakdowns?: (withBreakdowns: boolean) => void;
  onClickShowOnlyRecommendations?: () => void;
}

const TimelineHeader: React.FC<TimelineHeaderProps> = ({
  customHeaderActionsComponent,
  isPlaying,
  setIsPlaying,
  playedSeconds,
  groupByTagCategory,
  setGroupByTagCategory,
  setShowOnlyRecommendations,
  showWithBreakdowns = false,
  showRecommendationsToggle = true,
  resetFilters,
  onClickGroup,
  onClickWithBreakdowns,
  onClickShowOnlyRecommendations,
}) => (
  <Container>
    <PlayerControlsContainer
      isPlaying={isPlaying}
      setIsPlaying={setIsPlaying}
      playedSeconds={playedSeconds}
    />
    {customHeaderActionsComponent ? (
      { customHeaderActionsComponent }
    ) : (
      <DefaultHeaderActions
        groupByTagCategory={groupByTagCategory}
        setGroupByTagCategory={setGroupByTagCategory}
        setShowOnlyRecommendations={setShowOnlyRecommendations}
        showWithBreakdowns={showWithBreakdowns}
        showRecommendationsToggle={showRecommendationsToggle}
        resetFilters={resetFilters}
        onClickGroup={onClickGroup}
        onClickWithBreakdowns={onClickWithBreakdowns}
        onClickShowOnlyRecommendations={onClickShowOnlyRecommendations}
      />
    )}
  </Container>
);

export default TimelineHeader;
