import * as SDK from '@replai-platform/sdk';
import { LoadingBars, TagPreviewCarousel, Icons } from '@replai-platform/ui-components';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { logEvent } from '../../analytics';
import useTagPreviewInfo from '../../api/hooks/tags/useTagPreviewInfo';
import type { RootState } from '../../store/rootReducer';
import { getTagIconDefinition } from '../../utils/getTagIcon';

const LoadingContainer = styled.div`
  height: 28.75rem;

  div {
    height: inherit;
  }

  div > svg {
    height: inherit;
  }
`;

const MAX_PREVIEW_ITEMS = 3;

export type PreviewData = {
  thumbnails: { imageUrl: URL }[];
  videos: {
    url: URL;
    timeframes: SDK.Timeframe[];
  }[];
  icon?: { name: Icons.TagIconTypes; props?: Icons.TagIconProps };
};

type Props = {
  tag: SDK.Tag;
  isOpen: boolean;
  stateData?: PreviewData[];
  onLoad: (data: PreviewData[]) => void;
  eventPrefix?: string;
};

const Preview = ({ tag, isOpen, stateData, onLoad, eventPrefix }: Props) => {
  const projectId = useSelector((state: RootState) => state.project.id);

  const getPreviewInfoParams = useMemo(
    () => ({
      projectIds: [projectId],
      tagIds: [tag.id],
      maxRecords: MAX_PREVIEW_ITEMS,
    }),
    [projectId, tag.id]
  );

  const { iconName: iconThumbnail, props: iconProps } = getTagIconDefinition({
    type: tag.type,
    value: tag.value,
  });

  const { data, isLoading } = useTagPreviewInfo<PreviewData[]>(getPreviewInfoParams, {
    select: useCallback(
      (res) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        res.tags?.map(({ thumbnails, videos }) => ({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment
          thumbnails: thumbnails.map(({ imageUrl }) => ({ imageUrl: new URL(imageUrl) })),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
          videos: videos.map(({ timeframes, url }) => ({ timeframes, url: new URL(url) })),
          icon: { name: iconThumbnail, props: iconProps },
        })),
      [iconProps, iconThumbnail]
    ),
    enabled: isOpen && !stateData,
  });

  // Update dialog state
  if (!isLoading && data && !stateData) onLoad(data);

  return (isLoading && !stateData) || !data ? (
    <LoadingContainer>
      <LoadingBars barSize="xxl" />
    </LoadingContainer>
  ) : (
    <TagPreviewCarousel
      videos={(stateData ?? data)[0].videos}
      thumbnails={(stateData ?? data)[0].thumbnails}
      icon={(stateData ?? data)[0]?.icon?.name}
      iconProps={(stateData ?? data)[0]?.icon?.props}
      onPressProgressBar={() => {
        logEvent({
          component: eventPrefix ?? 'Tag Preview',
          action: 'Seek to Point',
        });
      }}
      onPlay={() => {
        logEvent({
          component: eventPrefix ?? 'Tag Preview',
          action: 'Play Video',
        });
      }}
      onPause={() => {
        logEvent({
          component: eventPrefix ?? 'Tag Preview',
          action: 'Pause Video',
        });
      }}
    />
  );
};

export default Preview;
