import { kpiUtils } from '@replai-platform/sdk';
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval';
import format from 'date-fns/format';
import { useState } from 'react';
import LineChart, { ChartValue } from '../../../LineChart/LineChart';
import LoadingBars from '../../../LoadingBars/LoadingBars';
import SelectInput, {
  SelectInputOption,
} from '../../../SelectInput/SelectInput';
import * as Styled from './styles';
export interface TagTrendsProps {
  availableKpis: SelectInputOption[];
  defaultKpi: SelectInputOption;
  onKpiChange: (index: number) => void;
  performanceChartData: ChartValue[];
  performanceChartIsLoading: boolean;
}

const TagTrends: React.VFC<TagTrendsProps> = ({
  availableKpis,
  defaultKpi,
  onKpiChange,
  performanceChartData,
  performanceChartIsLoading,
}) => {
  const [selectedKpi, setSelectedKpi] = useState(defaultKpi.title);

  const hasTagValues = performanceChartData?.some(
    (data) => !!data[`tags${selectedKpi}`]
  );

  const lines = [
    {
      name: `${selectedKpi === 'spend' ? 'Total' : 'Avg'} performance`,
      accessor: `global${selectedKpi}`,
    },
  ];
  if (hasTagValues) {
    lines.push({
      name: `Videos w/selected tags`,
      accessor: `tags${selectedKpi}`,
    });
  }

  // Ticks must be in format YYYY-MM-DD
  const getTicks = () => {
    if (
      performanceChartData?.[0].name &&
      performanceChartData?.[performanceChartData?.length - 1].name
    ) {
      const startDate = new Date(performanceChartData?.[0].name);
      const endDate = new Date(
        performanceChartData?.[performanceChartData?.length - 1].name
      );
      const firstTick = format(startDate, 'yyyy-MM-dd');
      const middleTicks = eachWeekOfInterval({
        start: startDate,
        end: endDate,
      })
        .slice(1)
        .map((d) => format(d, 'yyyy-MM-dd'));
      return [firstTick, ...middleTicks];
    }

    return [];
  };

  return (
    <div>
      <Styled.CardHeader>
        <Styled.TypographyClean type="text-md" fontWeight="medium">
          Performance over time
        </Styled.TypographyClean>
        <Styled.CardHeaderRight>
          <SelectInput
            defaultOption={defaultKpi}
            onChange={(index) => {
              setSelectedKpi(availableKpis[index].title);
              onKpiChange(index);
            }}
            options={availableKpis}
            placeholder="Select KPI"
          />
        </Styled.CardHeaderRight>
      </Styled.CardHeader>
      <Styled.CardChart data-test="overview-chart">
        {performanceChartIsLoading ? (
          <LoadingBars />
        ) : (
          <LineChart
            data-test="chart"
            data={performanceChartData}
            yValueFormatter={(v: number) =>
              kpiUtils.format(selectedKpi.toLowerCase(), v)
            }
            xValueFormatter={(v) =>
              new Date(v).toLocaleDateString('en-US', {
                day: '2-digit',
                month: 'short',
              })
            }
            lines={lines}
            xAxisTicks={performanceChartData ? getTicks() : []}
          />
        )}
      </Styled.CardChart>
    </div>
  );
};
export default TagTrends;
