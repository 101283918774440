import { Avatar } from '@replai-platform/ui-components';
import { CellData, GeneratedColumn, RequiredGeneratorParams } from '../common';

export type UserInfoAccessorType = {
  title: string;
  subTitle: string;
};

const UserInfoCellDecorator: React.FC<{
  cellData: UserInfoAccessorType;
}> = ({ cellData }) => {
  if (!cellData) {
    return null;
  }

  return <Avatar label={cellData.title} secondaryLabel={cellData.subTitle} size="md" />;
};

function getUserInfoColumn<T extends object = UserInfoAccessorType>({
  columnId,
  accessor,
}: RequiredGeneratorParams<T, UserInfoAccessorType>): GeneratedColumn<T> {
  return {
    id: columnId,
    accessor,
    Cell: (data: CellData<T, UserInfoAccessorType>) => <UserInfoCellDecorator cellData={data?.cell?.value} />,
  };
}

export default getUserInfoColumn;
