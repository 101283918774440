/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useCallback, useState } from 'react';
import ReactPlayer, { type ReactPlayerProps } from 'react-player';
import { Data, PreviewThumbnail, PreviewVideo } from '.';
import Carousel from '../../../Carousel/Carousel';
import Colors from '../../../Colors';
import { BaseIcons } from '../../../Icons';
import Tooltip from '../../../Tooltip/Tooltip';
import Typography from '../../../Typography/Typography';
import VideoPlayer from '../../../VideoPlayer/VideoPlayer';
import * as Styled from './styles';

const renderThumbnail = ({ imageUrl }: PreviewThumbnail) => (
  <Styled.ImageContainer>
    <img alt="Preview of a tag instance" src={imageUrl.toString()} />
  </Styled.ImageContainer>
);

interface VideoProps extends PreviewVideo {
  name: string;
  onVideoProgress: ReactPlayerProps['onProgress'];
  isVideoPlaying: ReactPlayerProps['playing'];
  setVideoPlaying: (isPlaying: boolean) => void;
  onVideoDuration: ReactPlayerProps['onDuration'];
  onCopyNameToClipboardClick?: (name: string) => void | Promise<void>;
}

const renderVideo: React.VFC<VideoProps> = ({
  name,
  url,
  videoPlayerRef,
  onVideoProgress,
  isVideoPlaying,
  setVideoPlaying,
  onVideoDuration,
  onCopyNameToClipboardClick,
}) => {
  return (
    <>
      <Styled.VideoHeaderContainer>
        <Typography type="text-xs" fontWeight="bold" noMargin>
          {name}
        </Typography>
        <Tooltip
          content={
            <Typography type="text-sm" noMargin>
              Copy name
            </Typography>
          }
        >
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Styled.IconWrapper
            onClick={() => onCopyNameToClipboardClick?.(name)}
            data-test="copy-name"
            aria-label="Copy name"
          >
            <BaseIcons.Copy dimension={16} color={Colors.Gray[500]} />
          </Styled.IconWrapper>
        </Tooltip>
      </Styled.VideoHeaderContainer>
      <Styled.VideoContainer>
        <VideoPlayer
          url={url.toString()}
          playerRef={videoPlayerRef}
          onProgress={onVideoProgress}
          playing={isVideoPlaying}
          onPlay={() => setVideoPlaying(true)}
          onPause={() => setVideoPlaying(false)}
          onEnded={() => setVideoPlaying(false)}
          onDuration={onVideoDuration}
          stopOnUnmount
        />
      </Styled.VideoContainer>
    </>
  );
};

const renderSlideContent = ({
  type,
  ...rest
}: { type: string } & (VideoProps | PreviewThumbnail)) => {
  switch (type) {
    case 'thumbnail':
      return renderThumbnail(rest as PreviewThumbnail);
    case 'video':
      return renderVideo(rest as VideoProps);
    default:
      return null;
  }
};

const renderSlide = ({
  data,
  onVideoProgress,
  isVideoPlaying,
  setVideoPlaying,
  videoPlayerRef,
  onVideoDuration,
  onCopyNameToClipboardClick,
}: {
  data: Data;
  onVideoProgress: ReactPlayerProps['onProgress'];
  isVideoPlaying: boolean;
  setVideoPlaying: (isPlaying: boolean) => void;
  videoPlayerRef?: React.Ref<ReactPlayer>;
  onVideoDuration: ReactPlayerProps['onDuration'];
  onCopyNameToClipboardClick?: (name: string) => void | Promise<void>;
}) => (
  <Styled.SlideContainer>
    {renderSlideContent({
      ...data,
      videoPlayerRef,
      isVideoPlaying,
      setVideoPlaying,
      onVideoProgress,
      onVideoDuration,
      onCopyNameToClipboardClick,
    })}
  </Styled.SlideContainer>
);

export interface TagCarouselProps {
  tags: Data[];
  onAfterSlideChange?: (currentSlide: number) => void;
  playedSeconds?: number;
  videoPlayerRef: React.MutableRefObject<ReactPlayer | null>;
  onBeforeSlideChange?: () => void;
  onVideoProgress: ReactPlayerProps['onProgress'];
  isVideoPlaying: boolean;
  setVideoPlaying: (isPlaying: boolean) => void;
  onVideoDuration: ReactPlayerProps['onDuration'];
  onCopyNameToClipboardClick?: (name: string) => void | Promise<void>;
}

const TagCarousel: React.VFC<TagCarouselProps> = ({
  tags,
  onAfterSlideChange,
  onBeforeSlideChange,
  videoPlayerRef,
  onVideoProgress,
  isVideoPlaying,
  setVideoPlaying,
  onVideoDuration,
  onCopyNameToClipboardClick,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const afterChange = (newSlide: number) => {
    setCurrentSlide(newSlide);
    onAfterSlideChange?.(newSlide);
  };
  const beforeChange = () => {
    onBeforeSlideChange?.();
  };
  const accessor = useCallback(
    (data, index) =>
      renderSlide({
        data,
        // Because we render multiple video players in the carousel, we need to pass the
        // videoPlayerRef and all callbacks to the current active slide/player only
        onVideoProgress: currentSlide === index ? onVideoProgress : undefined,
        isVideoPlaying: currentSlide === index ? isVideoPlaying : false,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setVideoPlaying: currentSlide === index ? setVideoPlaying : () => {},
        videoPlayerRef: currentSlide === index ? videoPlayerRef : null,
        onVideoDuration: currentSlide === index ? onVideoDuration : undefined,
        onCopyNameToClipboardClick,
      }),
    [
      currentSlide,
      videoPlayerRef,
      onVideoProgress,
      isVideoPlaying,
      setVideoPlaying,
      onVideoDuration,
      onCopyNameToClipboardClick,
    ]
  );
  return (
    <Carousel
      slidesData={tags}
      accessor={accessor}
      arrows
      dots={false}
      disableShadow
      afterChange={afterChange}
      beforeChange={beforeChange}
    />
  );
};

export default TagCarousel;
