import * as SDK from '@replai-platform/sdk';
import { ApplovinConnectionGuide } from './applovin';
import { AdjustConnectionGuide } from './adjust';
import { AppsFlyerConnectionGuide } from './appsflyer';
import { GoogleConnectionGuide } from './google';
import { FacebookConnectionGuide } from './facebook';
import { IronsourceConnectionGuide } from './ironsource';
import { MolocoConnectionGuide } from './moloco';
import { MintegralConnectionGuide } from './mintegral';
import { SingularConnectionGuide } from './singular';
import { LiftoffConnectionGuide } from './liftoff';
import { SnapchatConnectionGuide } from './snapchat';
import { TiktokConnectionGuide } from './tiktok';
import { UnityConnectionGuide } from './unity';
import { VungleConnectionGuide } from './vungle';
import { YouTubeConnectionGuide } from './youtube';
import { NetworkConnectionGuideContent } from '../types';

export type ConnectionGuides = {
  [key in SDK.NetworkOrMmp]?: NetworkConnectionGuideContent;
};

export function getConnectionGuides(): ConnectionGuides {
  return {
    // Keep alphabetically sorted.
    [SDK.Network.APPLOVIN]: ApplovinConnectionGuide,
    [SDK.Network.FACEBOOK]: FacebookConnectionGuide,
    [SDK.Network.GOOGLE]: GoogleConnectionGuide,
    [SDK.Network.IRONSOURCE]: IronsourceConnectionGuide,
    [SDK.Network.LIFTOFF]: LiftoffConnectionGuide,
    [SDK.Network.MINTEGRAL]: MintegralConnectionGuide,
    [SDK.Network.MOLOCO]: MolocoConnectionGuide,
    [SDK.Network.SNAPCHAT]: SnapchatConnectionGuide,
    [SDK.Network.TIKTOK]: TiktokConnectionGuide,
    [SDK.Network.UNITY]: UnityConnectionGuide,
    [SDK.Network.VUNGLE]: VungleConnectionGuide,
    [SDK.Network.YOUTUBE]: YouTubeConnectionGuide,
    [SDK.MMP.ADJUST]: AdjustConnectionGuide,
    [SDK.MMP.APPSFLYER]: AppsFlyerConnectionGuide,
    [SDK.MMP.SINGULAR]: SingularConnectionGuide,
  };
}
