/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import { Card, KeyValues, Typography, type KeyValue } from '@replai-platform/ui-components';
import { useCallback, useRef, useState, type MutableRefObject } from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useFeature } from '@optimizely/react-sdk';
import { logEvent } from '../../../../../analytics';
import useAssetRecommendations from '../../../../../api/hooks/recommendations/useAssetRecommendations';
import Timeline from '../../../../../components/Timeline';
import VideoSidebar from '../../../../../components/VideoSidebar';
import AssetVariationButton from '../../../../../components/AssetVariationButton';
import type { RootState } from '../../../../../store/rootReducer';
import { getRecommendationRequestParams } from '../../../../../utils';
import { TIMELINE_MAX_DURATION_S_AVAILABILITY } from '../../../../../utils/constants';
import { RouteAnimator } from '../../../../RouteAnimator';
import { Container, Properties, StickyLeftSide, TimelineRightSide, TimelineWrapper } from '../shared/styles';

const TimelineTab: React.FC<{
  cluster: SDK.AssetMetricsWithTags;
  propertyData: KeyValue[];
  url: string;
  isLoading: boolean;
}> = ({ cluster, propertyData, url, isLoading }) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const clusterId = cluster?.id;
  const tagsTypes = useSelector((state: RootState) => state.project.config.tagsTypes);
  const minRecommendationScore = useSelector((state: RootState) => state.project.config.minRecommendationScore) ?? 0.15;
  const networks = useSelector((state: RootState) => state.filters.networks);
  const projectNetworks = useSelector((state: RootState) => state.project.networks);
  const kpis = useSelector((state: RootState) => state.filters.kpis);
  const projectKpis = useSelector((state: RootState) => state.project.config.defaultProjectKpis);
  const [isTechMerc1388Enabled] = useFeature('techmerc-1388'); // Creative Production

  const withBreakdowns = networks?.length !== 0 && networks?.length !== projectNetworks?.length;

  const [playedFraction, setPlayedFraction] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [onLoop, setOnLoop] = useState(false);
  const player = useRef<ReactPlayer>(null);

  // Get asset recommendations
  const { data: recommendations, isLoading: recommendationsIsLoading } = useAssetRecommendations(
    getRecommendationRequestParams({
      projectId,
      minRecommendationScore,
      tagTypesToExclude: (tagsTypes?.excluded || []).map((type) => ({ type })),
      networks,
      promotedObjectTypes: [],
      targets: kpis?.length ? kpis : projectKpis,
      withBreakdowns,
      objectIds: clusterId ? [clusterId] : [],
    }),
    {
      enabled: true,
      select: useCallback((res) => res?.recommendations?.filter((r) => r.id === clusterId), [clusterId]),
    }
  );

  const onPlay = () => {
    setIsPlaying(true);
    logEvent({
      component: 'Video Details',
      action: 'Play Video',
      category: 'user_actions',
    });
  };

  const onPause = () => {
    setIsPlaying(false);
    logEvent({
      component: 'Video Details',
      action: 'Pause Video',
      category: 'user_actions',
    });
  };

  const onProgress = ({
    playedSeconds: updatedPlayedSeconds,
    played: updatedPlayed,
  }: {
    playedSeconds: number;
    played: number;
  }) => {
    setPlayedSeconds(updatedPlayedSeconds);
    setPlayedFraction(updatedPlayed);
  };

  const onDuration = (updatedDuration: number) => setDuration(updatedDuration);

  if ((cluster.duration ?? 0) > TIMELINE_MAX_DURATION_S_AVAILABILITY) {
    return (
      <RouteAnimator>
        <Typography style={{ margin: '2rem 0' }}>
          Timeline not available for creatives longer than 100 seconds. Try a different one.
        </Typography>
      </RouteAnimator>
    );
  }

  if (!cluster.postQaAnnotation) {
    return (
      <RouteAnimator>
        <Typography style={{ margin: '2rem 0' }}>
          Timeline not available for this creative. Try a different one.
        </Typography>
      </RouteAnimator>
    );
  }

  return (
    <RouteAnimator>
      <Container>
        <StickyLeftSide>
          <VideoSidebar
            data-test="video-overview"
            url={url}
            coreVideoTags={[]}
            coreMiscTags={[]}
            showDescription={false}
            onPlay={onPlay}
            onPause={onPause}
            onProgress={onProgress}
            onDuration={onDuration}
            isPlaying={isPlaying}
            onLoop={onLoop}
            player={player}
          />
          {isTechMerc1388Enabled ? <AssetVariationButton assetId={clusterId} /> : null}
          <Card fullWidth disableContentPadding>
            <Properties>
              <KeyValues
                header="Properties"
                values={propertyData}
                isLoading={isLoading}
                loadingPlaceholderNumber={11}
              />
            </Properties>
          </Card>
        </StickyLeftSide>
        <TimelineRightSide>
          <TimelineWrapper>
            <Timeline
              assetId={clusterId ?? ''}
              recommendations={recommendations?.[0]?.subRows ?? []}
              loadingRecommendations={recommendationsIsLoading}
              tagData={cluster}
              tagDataIsLoading={false}
              playedFraction={playedFraction}
              playedSeconds={playedSeconds}
              duration={duration}
              player={player as MutableRefObject<ReactPlayer>}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              v2
              videoOnLoop={onLoop}
              setLoopVideo={setOnLoop}
            />
          </TimelineWrapper>
        </TimelineRightSide>
      </Container>
    </RouteAnimator>
  );
};

export default TimelineTab;
