import React from 'react';

import styled from 'styled-components';
import * as Icons from '../Icons';
import FeaturedIcons from '../FeaturedIcons/FeaturedIcons';
import Colors from '../Colors';
import SkeletonLoading from '../Skeleton/Skeleton';

export type TagThumbnailProps = {
  iconProps?: Icons.TagIconProps;
  iconThumbnail?: Icons.TagIconTypes;
  thumbnailLoading?: boolean;
  thumbnailUrl?: string;
  alt?: string;
  objectFit?: 'contain' | 'cover';
};

const ThumbnailImageContain = styled.img<{ objectFit: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: ${({ objectFit }) => objectFit};
`;

const ThumbnailImageBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.1);
  filter: opacity(85%);
  filter: blur(6px);
`;
const ThumbnailInnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ThumbnailContainer = styled.div`
  display: inline-block;
  flex: 1;
  width: 100%;
  height: 100%;

  .react-loading-skeleton {
    line-height: unset;
    border-radius: unset;
  }
`;

const IconThumbnailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${Colors.Gray[300]};
`;

const IconThumbnail = ({
  icon,
  iconProps,
}: {
  icon: Icons.TagIconTypes;
  iconProps?: Icons.TagIconProps;
}) => (
  <ThumbnailContainer key={icon}>
    <IconThumbnailContainer>
      <FeaturedIcons
        icon={icon}
        iconProps={iconProps}
        iconType={Icons.IconType.TAG}
        color="Gray"
        size="xxl"
        variant="outline"
        showShadow
      />
    </IconThumbnailContainer>
  </ThumbnailContainer>
);

const PreviewThumbnail = ({
  objectFit,
  thumbnailUrl,
  thumbnailLoading,
  alt,
}: {
  objectFit: 'cover' | 'contain';
  thumbnailUrl?: string;
  thumbnailLoading?: boolean;
  alt?: string;
}) => (
  <ThumbnailContainer key={thumbnailUrl}>
    {thumbnailLoading ? (
      <SkeletonLoading height="100%" />
    ) : (
      <ThumbnailInnerContainer>
        {thumbnailUrl && (
          <>
            <ThumbnailImageBackground src={thumbnailUrl} />
            <ThumbnailImageContain
              src={thumbnailUrl}
              alt={alt ?? 'thumbnail'}
              objectFit={objectFit}
            />
          </>
        )}
      </ThumbnailInnerContainer>
    )}
  </ThumbnailContainer>
);

const TagThumbnail: React.FC<TagThumbnailProps> = ({
  iconProps,
  iconThumbnail,
  thumbnailLoading,
  thumbnailUrl,
  alt,
  objectFit = 'contain',
}) =>
  iconThumbnail ? (
    <IconThumbnail icon={iconThumbnail} iconProps={iconProps} />
  ) : (
    <PreviewThumbnail
      thumbnailUrl={thumbnailUrl}
      thumbnailLoading={thumbnailLoading}
      alt={alt}
      objectFit={objectFit}
    />
  );

export default TagThumbnail;
