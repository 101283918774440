import { ReactNode } from 'react';
import * as Recharts from 'recharts';
import { DataKey } from 'recharts/types/util/types';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import styled from 'styled-components';
import Colors, { Color } from '../Colors';
import Typography from '../Typography/Typography';
import EmptyState from '../EmptyState/EmptyState';
import DefaultTooltip from './Tooltip';

const Y_AXIS_TICK_COUNT = 7;
const MAX_BAR_SIZE = 75;

export interface BarChartProps {
  bars: ChartBar[];
  data: ChartValue[];
  referenceLines?: ReferenceLine[];
  onBarClick?: (value: ChartValue) => void;
  customLegend?: Legend[];
  customTooltip?: (
    props: Recharts.TooltipProps<ValueType, NameType>
  ) => ReactNode | null;
  hideYAxis?: boolean;
  showLegend?: boolean;
  showTooltip?: boolean;
  xLabel?: string;
  yLabel?: string;
  xValueFormatter?: (v: string | number, index: number) => string;
  yValueFormatter?: (v: number, index: number) => string;
  barColors?: string[];
}

/**
 * @typedef {Object} ChartBar defines the bar that will appear in the chart
 * @property {string} name - name that will appear in the chart legend
 * @property {string} accessor - key to retrieve the value in the data object (should be a key in @type {ChartValue})
 * @property {Color} color - color of the bar
 */
export type ChartBar = {
  name: string;
  accessor: DataKey<ChartValue>;
  color?: Color;
};

type ChartValue = {
  name: string;
  [key: string]: string | number;
  custom?: any; // not used internally, any extra data that the user might need (for example, to be used in a custom tooltip)
};

type Legend = {
  value: string;
  color: Color;
};

type ReferenceLine = {
  value: number;
  color?: Color;
};

const StyledResponsiveContainer = styled(Recharts.ResponsiveContainer)`
  font-family: 'Inter', sans-serif;
`;

const LegendText = styled(Typography)`
  display: inline-block;
  margin: 0 0 0 4px;
`;

const tickStyle = {
  fontWeight: '400',
  fontSize: '0.75rem',
  fill: Colors.Gray[500],
};
const axisLabelStyle = {
  fontWeight: '500',
  fontSize: '0.75rem',
  fill: Colors.Gray[500],
};

const BarChart = ({
  data,
  customLegend,
  onBarClick,
  customTooltip,
  bars = [{ name: '', accessor: 'value' }],
  referenceLines,
  hideYAxis = false,
  showLegend = true,
  showTooltip = true,
  xLabel,
  yLabel,
  xValueFormatter,
  yValueFormatter,
  barColors = [Colors.Primary[500], Colors.Primary[300], Colors.Primary[100]],
}: BarChartProps) => {
  if (!data || !data.length) {
    return <EmptyState icon="BarChart2" text="No data available" />;
  }

  const showLegendCheck =
    !!customLegend?.length || (showLegend && bars.length > 0 && bars[0].name);

  const onDataClick = (
    v: React.MouseEvent<SVGElement> & { payload: ChartValue }
  ) => {
    if (v.payload && onBarClick) {
      onBarClick(v.payload ?? null);
    }
  };

  const legendFormatter = (value: string) => (
    <LegendText type="text-sm" color={Colors.Gray[500]}>
      {value}
    </LegendText>
  );

  const renderCartesianGrid = () => (
    <Recharts.CartesianGrid
      vertical={false}
      stroke={Colors.Gray[100]}
      strokeLinecap="round"
    />
  );

  const renderXAxis = () =>
    // renders more than one X axis if the graph contains
    // more than one value for each column, which means
    // the bar charts will overlap
    bars.map((bar, index) => (
      <Recharts.XAxis
        dataKey="name"
        key={bar.accessor.toString()}
        axisLine={false}
        tick={tickStyle}
        tickLine={false}
        tickFormatter={xValueFormatter}
        xAxisId={index}
        hide={index < bars.length - 1}
        label={
          xLabel
            ? {
                value: xLabel,
                position: 'insideBottom',
                offset: -10,
                style: axisLabelStyle,
              }
            : {}
        }
      />
    ));

  const renderYAxis = () => (
    <Recharts.YAxis
      axisLine={false}
      tick={tickStyle}
      tickLine={false}
      tickCount={Y_AXIS_TICK_COUNT}
      tickFormatter={yValueFormatter}
      label={
        yLabel
          ? {
              value: yLabel,
              position: 'insideLeft',
              angle: -90,
              offset: 0,
              style: axisLabelStyle,
            }
          : {}
      }
      hide={hideYAxis}
    />
  );

  const renderTooltip = () => (
    <Recharts.Tooltip
      content={customTooltip ?? DefaultTooltip}
      cursor={{ fill: 'transparent' }}
    />
  );

  const renderLegend = () => (
    <Recharts.Legend
      wrapperStyle={{ marginTop: '-20px' }}
      verticalAlign="top"
      align="right"
      iconType="circle"
      iconSize={8}
      payload={
        customLegend ||
        (bars || []).map((b, i) => ({ value: b.name, color: barColors[i] }))
      }
      formatter={legendFormatter}
    />
  );

  const renderBars = () =>
    bars
      .map((bar, index) => (
        <Recharts.Bar
          key={`bar-${bar.accessor.toString()}`}
          dataKey={bar.accessor}
          name={bar.name}
          maxBarSize={MAX_BAR_SIZE}
          radius={[7, 7, 0, 0]}
          cursor={onBarClick ? 'pointer' : 'default'}
          fill={bar.color ?? barColors[index]}
          xAxisId={index}
          onClick={onDataClick}
        />
      ))
      .reverse();

  const renderReferenceLines = () =>
    (referenceLines || []).map((line) => (
      <Recharts.ReferenceLine
        key={line.value}
        y={line.value}
        stroke={line.color ?? Colors.Success[500]}
        strokeLinecap="round"
      />
    ));

  return (
    <StyledResponsiveContainer data-test="bar-chart">
      <Recharts.BarChart
        data={data}
        margin={{
          top: showLegend ? 25 : 0,
          bottom: xLabel ? 10 : 0,
          left: yLabel ? 10 : 0,
        }}
      >
        {renderCartesianGrid()}
        {renderXAxis()}
        {renderYAxis()}
        {showTooltip ? renderTooltip() : null}
        {showLegendCheck ? renderLegend() : null}
        {renderReferenceLines()}
        {renderBars()}
      </Recharts.BarChart>
    </StyledResponsiveContainer>
  );
};

export default BarChart;
