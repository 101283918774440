import styled from 'styled-components';
import { default as DefaultModal } from '../Modal/Modal';

export const Modal = styled(DefaultModal)`
  &.replai-network-connection-guide-ant-modal.replai-ant-modal
    .ant-modal-content {
    width: fit-content;
    margin: auto;
  }

  &.replai-network-connection-guide-ant-modal.replai-ant-modal .ant-collapse {
    width: 60rem;
  }
`;
