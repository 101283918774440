import React, { ReactNode, useRef, useState } from 'react';
import { useDebounce, useHover, useUpdateEffect } from 'react-use';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { Badge, BadgeProps, Tooltip, VideoPlayer } from '../';
import * as Icons from '../Icons';
import Colors from '../Colors';
import TagThumbnail from '../TagThumbnail/TagThumbnail';
import Typography from '../Typography/Typography';
import LinearProgress from '../LinearProgress/LinearProgress';
import SkeletonLoading from '../Skeleton/Skeleton';
import { scrimGradient } from '../../utils';
import { useOverflow } from '../../utils';

type Variant = 'type-value' | 'frequency';

export type IconOverlay = {
  icon: Icons.BaseIconTypes;
  position: 'left' | 'right';
};

export type TagCardProps = {
  alwaysShowSubtitle?: boolean;
  badge?: BadgeProps;
  badgeTooltip?: ReactNode;
  clientFrequency?: number;
  clientFrequencyActiveVideos?: boolean;
  endSeconds?: number;
  iconProps?: Icons.TagIconProps;
  iconThumbnail?: Icons.TagIconTypes;
  isFiltered?: boolean;
  marketFrequency?: number;
  onClick?: (clientFrequency?: number) => void;
  onToggleHoverPreview?: (enabled: boolean) => unknown;
  startSeconds?: number;
  subTitle?: string;
  thumbnailLoading?: boolean;
  thumbnailUrl?: string;
  title?: string;
  variant?: Variant;
  videoUrl?: URL;
  iconsOverlay?: IconOverlay[];
  width?: string;
  objectFit?: 'cover' | 'contain';
  hoveringDelaySeconds?: number;
};

// Time in seconds that each crossfade animation should last.
const ANIMATION_DURATION_SECONDS = 0.5;

// Time in seconds to wait between mouse hover and starting to load the video for playing.
const PLAY_VIDEO_DELAY_SECONDS = ANIMATION_DURATION_SECONDS;

const RootContainer = styled.div<{ variant?: Variant; width?: string }>`
  position: relative;
  display: flex;
  width: ${({ width }) => width ?? '100%'};
  ${({ width }) => (width ? '' : 'min-width: 11.8125rem;')}
  ${({ width }) => (width ? '' : 'max-width: 14.8125rem;')}
  height: ${({ variant }) =>
    variant === 'frequency' ? '17.5rem' : '13.75rem'};
  overflow: hidden;
  background-color: ${Colors.Gray[500]};
  border-radius: 0.75rem;

  &:hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  }
`;

const OverlayText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: end;
  justify-content: end;
  width: 100%;
  height: 5rem;
  padding: 1.125rem;
  background: ${scrimGradient('#101828', 'to top')};
  text-align: center;
  z-index: 2;
`;

const FrequencyOverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: end;
  width: 100%;
  height: 5.625rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(24px);
`;

const MarketFrequencyContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  height: 1.25rem;
`;

const NoWrapBadge = styled(Badge)`
  white-space: nowrap;
`;

const TagContainer = styled.div`
  overflow: hidden;
`;

const OverlayTextWrapper = styled.div`
  position: relative;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const StyledReactCSSTransitionReplace = styled(ReactCSSTransitionReplace)`
  position: absolute;
  width: 100%;
  height: 100%;

  .cross-fade-leave {
    opacity: 1;
  }
  .cross-fade-leave.cross-fade-leave-active {
    opacity: 0;
    transition: opacity ${ANIMATION_DURATION_SECONDS}s ease-in;
  }

  .cross-fade-enter {
    opacity: 0;
  }
  .cross-fade-enter.cross-fade-enter-active {
    opacity: 1;
    transition: opacity ${ANIMATION_DURATION_SECONDS}s ease-in;
  }

  .cross-fade-height {
    transition: height ${ANIMATION_DURATION_SECONDS}s ease-in-out;
  }
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  white-space: nowrap;
  z-index: 2;
`;

const ClientFrequencyContainer = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;

  .react-loading-skeleton {
    line-height: unset;
  }
`;

const Fill = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const StyledTypography = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TextContainer = styled.div`
  width: calc(100%);
`;

const IconsOverlayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
`;

const IconsOverlayMiddleContainer = styled.div`
  margin: 0.5rem;
`;

const TwoIconGroupContainer = styled.div`
  width: 4rem;
`;

const FlexEndContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const formatFrequency = (frequency: number) => {
  if (frequency > 0 && frequency <= 0.01) {
    return 1;
  }

  return Math.round(frequency * 100);
};

const Preview = ({
  endSeconds,
  iconProps,
  iconThumbnail,
  shouldPlayVideo,
  startSeconds,
  thumbnailLoading,
  thumbnailUrl,
  videoUrl,
  title,
  objectFit,
}: Pick<
  TagCardProps,
  | 'endSeconds'
  | 'iconProps'
  | 'iconThumbnail'
  | 'startSeconds'
  | 'thumbnailLoading'
  | 'thumbnailUrl'
  | 'videoUrl'
  | 'title'
> & {
  shouldPlayVideo: boolean;
  objectFit?: 'cover' | 'contain';
}) => {
  const [canPlayVideo, setCanPlayVideo] = useState(false);
  const onCanPlay = () => {
    // The browser can start playing the video (it has buffered enough to begin).
    setCanPlayVideo(true);
  };

  const thumbnailExists = !!thumbnailUrl?.length || thumbnailLoading;

  // Video is being played only if the `shouldPlayVideo` prop is true and the video is ready to be played (enough data available).
  const isPlayingVideo = shouldPlayVideo && canPlayVideo;

  const playerRef = React.createRef<ReactPlayer>();
  const onProgress = ({ playedSeconds }: { playedSeconds: number }) => {
    if (endSeconds && playedSeconds >= endSeconds) {
      playerRef.current?.seekTo(startSeconds || 0, 'seconds');
    }
  };

  const onPause = () => {
    // if the thumbnail doesn't exist, once the video is paused,
    // it needs to reset to the initial state
    if (!thumbnailExists) {
      playerRef.current?.seekTo(startSeconds || 0, 'seconds');
    }
  };

  const VideoPlayerJSX = (
    <VideoPlayer
      playerRef={playerRef}
      playing={shouldPlayVideo}
      controls={false}
      loop
      muted
      onPause={onPause}
      onCanPlay={onCanPlay}
      onProgress={onProgress}
      url={videoUrl?.toString() ?? ''}
      startTime={startSeconds?.toString()}
      title={title}
      objectFit={objectFit}
    />
  );

  // if the thumbnail exists, we first show the thumbnail and only when "shouldPlayVideo" is true we actually show the video
  // if the thumbnail doesn't exist, we show the video directly
  return thumbnailExists || iconThumbnail ? (
    <StyledReactCSSTransitionReplace
      component={Fill}
      childComponent={Fill}
      transitionName="cross-fade"
      transitionEnterTimeout={1000 * ANIMATION_DURATION_SECONDS}
      transitionLeaveTimeout={1000 * ANIMATION_DURATION_SECONDS}
    >
      <Fill key={isPlayingVideo ? 'video' : 'thumbnail'}>
        <Fill style={{ display: isPlayingVideo ? 'initial' : 'none' }}>
          {
            // We need to ensure that the video data starts loading, therefore the video player is rendered even before it is ready to start playing.
            shouldPlayVideo && VideoPlayerJSX
          }
        </Fill>
        <Fill
          style={{ display: isPlayingVideo ? 'none' : 'initial', zIndex: 0 }}
        >
          <TagThumbnail
            iconProps={iconProps}
            iconThumbnail={iconThumbnail}
            thumbnailLoading={thumbnailLoading}
            thumbnailUrl={thumbnailUrl}
            alt={title}
            objectFit={objectFit}
          />
        </Fill>
      </Fill>
    </StyledReactCSSTransitionReplace>
  ) : (
    VideoPlayerJSX
  );
};

const AnimatedTextElement = ({
  hovered,
  alwaysShowSubtitle,
  title,
  subTitle,
}: {
  hovered: boolean;
  title?: string;
  alwaysShowSubtitle?: boolean;
  subTitle?: string;
}) => {
  const titleLabelRef = useRef(null);
  const titleLabelOverflows = useOverflow(titleLabelRef);

  return (
    <Tooltip
      content={
        titleLabelOverflows ? (
          <Typography noMargin type="text-sm">
            {title}
          </Typography>
        ) : null
      }
    >
      <OverlayTextWrapper>
        <OverlayText>
          <TextContainer>
            {title && (
              <StyledTypography
                ref={titleLabelRef}
                color={Colors.Common.White}
                noMargin
                data-test="tag-card-title"
              >
                {title}
              </StyledTypography>
            )}
          </TextContainer>

          <TextContainer>
            {(hovered || alwaysShowSubtitle) && subTitle && (
              <StyledTypography
                type="text-xs"
                color={Colors.Common.White}
                noMargin
              >
                {subTitle}
              </StyledTypography>
            )}
          </TextContainer>
        </OverlayText>
      </OverlayTextWrapper>
    </Tooltip>
  );
};

const FrequencyElement = ({
  title,
  subTitle,
  marketFrequency,
  isFiltered,
}: {
  title?: string;
  subTitle?: string;
  marketFrequency?: number;
  isFiltered?: boolean;
}) => {
  const titleLabelRef = useRef(null);
  const subtitleLabelRef = useRef(null);
  const titleLabelOverflows = useOverflow(titleLabelRef);
  const subtitleLabelOverflows = useOverflow(subtitleLabelRef);

  return (
    <OverlayTextWrapper>
      <FrequencyOverlayContainer data-test="tag-card-main-frequency-container">
        <TagContainer>
          <Tooltip
            content={
              subtitleLabelOverflows ? (
                <Typography noMargin type="text-sm">
                  {subTitle}
                </Typography>
              ) : null
            }
          >
            <TextContainer>
              <StyledTypography
                ref={subtitleLabelRef}
                type="text-xs"
                color={Colors.Common.White}
                fontWeight="regular"
                noMargin
              >
                {subTitle}
              </StyledTypography>
            </TextContainer>
          </Tooltip>
          <Tooltip
            content={
              titleLabelOverflows ? (
                <Typography noMargin type="text-sm">
                  {title}
                </Typography>
              ) : null
            }
          >
            <TextContainer>
              <StyledTypography
                ref={titleLabelRef}
                type="text-sm"
                color={Colors.Common.White}
                fontWeight="semi-bold"
                noMargin
              >
                {title}
              </StyledTypography>
            </TextContainer>
          </Tooltip>
        </TagContainer>
        <Tooltip
          content={
            <Typography noMargin type="text-sm">
              This tag's frequency in the{' '}
              <strong>{isFiltered ? 'current context' : "market's"}</strong>{' '}
              videos
            </Typography>
          }
        >
          <MarketFrequencyContainer>
            <LinearProgress value={marketFrequency ?? 0} />
            <Typography
              type="text-sm"
              color={Colors.Common.White}
              fontWeight="medium"
              noMargin
            >{`${formatFrequency(marketFrequency ?? 0)}%`}</Typography>
          </MarketFrequencyContainer>
        </Tooltip>
      </FrequencyOverlayContainer>
    </OverlayTextWrapper>
  );
};

const IconGroupOnOverlay = (
  icons: (({ color, dimension }: Icons.BaseIconProps) => JSX.Element)[]
) => {
  return icons.length === 2 ? (
    <TwoIconGroupContainer>
      <div>{icons[0]({ dimension: 40, color: Colors.Common.White })}</div>
      <FlexEndContainer>
        {icons[1]({ dimension: 40, color: Colors.Common.White })}
      </FlexEndContainer>
    </TwoIconGroupContainer>
  ) : (
    icons[0]({ dimension: 65, color: Colors.Common.White })
  );
};

const IconsOverlay = ({ iconsOverlay }: { iconsOverlay: IconOverlay[] }) => {
  const iconsOverlayLeft = iconsOverlay?.filter(
    (icon) => icon.position === 'left'
  );

  const iconsOverlayRight = iconsOverlay?.filter(
    (icon) => icon.position === 'right'
  );

  const iconsJSXLeft = iconsOverlayLeft.map((icon) =>
    Icons.getBaseIcon(icon.icon)
  );

  const iconsJSXRight = iconsOverlayRight.map((icon) =>
    Icons.getBaseIcon(icon.icon)
  );

  const IconJSXMiddle = Icons.getBaseIcon('ChevronRight');

  return (
    <IconsOverlayContainer>
      <div>{IconGroupOnOverlay(iconsJSXLeft)}</div>
      <IconsOverlayMiddleContainer>
        {IconJSXMiddle({ dimension: 24, color: Colors.Common.White })}
      </IconsOverlayMiddleContainer>
      <div>{IconGroupOnOverlay(iconsJSXRight)}</div>
    </IconsOverlayContainer>
  );
};

const HoverableElement: React.FC<TagCardProps & { hovered: boolean }> = ({
  alwaysShowSubtitle,
  badge,
  badgeTooltip,
  clientFrequency,
  clientFrequencyActiveVideos,
  endSeconds,
  hovered,
  iconProps,
  iconThumbnail,
  isFiltered,
  marketFrequency,
  onClick,
  onToggleHoverPreview,
  startSeconds,
  subTitle,
  thumbnailLoading,
  thumbnailUrl,
  title,
  variant,
  videoUrl,
  iconsOverlay,
  width,
  objectFit,
  hoveringDelaySeconds = PLAY_VIDEO_DELAY_SECONDS,
}) => {
  // Debounce hover action so that video does not start playing until the mouse has been hovering the card for enough time.
  const [debouncedHovered, setDebouncedHovered] = useState(false);
  const [, cancelDebouncedHovered] = useDebounce(
    () => {
      setDebouncedHovered(hovered);
    },
    hoveringDelaySeconds * 1000,
    [hovered]
  );
  useUpdateEffect(() => {
    if (!hovered) {
      cancelDebouncedHovered();
      setDebouncedHovered(false);
    }
  }, [hovered]);

  const shouldPlayVideo = !!(debouncedHovered && hovered && videoUrl);

  useUpdateEffect(() => {
    onToggleHoverPreview?.(shouldPlayVideo);
  }, [shouldPlayVideo]);

  return (
    <RootContainer
      data-test="tag-card"
      onClick={() => {
        onClick?.(clientFrequency);
      }}
      variant={variant}
      width={width}
    >
      {iconsOverlay?.length ? IconsOverlay({ iconsOverlay }) : ''}
      <Fill>
        <Preview
          endSeconds={endSeconds}
          iconProps={iconProps}
          iconThumbnail={iconThumbnail}
          shouldPlayVideo={shouldPlayVideo}
          startSeconds={startSeconds}
          thumbnailLoading={thumbnailLoading}
          thumbnailUrl={thumbnailUrl}
          videoUrl={videoUrl}
          title={title}
          objectFit={objectFit}
        />
      </Fill>
      {title ? (
        variant === 'frequency' ? (
          <FrequencyElement
            title={title}
            subTitle={subTitle}
            marketFrequency={marketFrequency}
            isFiltered={isFiltered}
          />
        ) : (
          <StyledReactCSSTransitionReplace
            component={Fill}
            childComponent={Fill}
            transitionName="cross-fade"
            transitionEnterTimeout={1000 * ANIMATION_DURATION_SECONDS}
            transitionLeaveTimeout={1000 * ANIMATION_DURATION_SECONDS}
          >
            <AnimatedTextElement
              title={title}
              subTitle={subTitle}
              hovered={hovered}
              alwaysShowSubtitle={alwaysShowSubtitle}
              key={hovered ? 'hovered' : 'non-hovered'}
            />
          </StyledReactCSSTransitionReplace>
        )
      ) : null}
      {variant === 'type-value' && badge && (
        <Tooltip content={badgeTooltip}>
          {
            // eslint-disable-next-line react/jsx-props-no-spreading
            <StyledBadge {...badge} />
          }
        </Tooltip>
      )}
      {variant === 'frequency' && title && subTitle ? (
        <ClientFrequencyContainer>
          {clientFrequency !== undefined ? (
            <Tooltip
              content={
                <Typography noMargin type="text-sm">
                  This tag's frequency in{' '}
                  <strong>
                    your{clientFrequencyActiveVideos ? ' active' : ''}
                  </strong>{' '}
                  videos
                </Typography>
              }
            >
              <NoWrapBadge
                color={clientFrequency > 0 ? 'Blue' : 'Warning'}
                size="sm"
              >
                {clientFrequency > 0
                  ? `You: ${formatFrequency(clientFrequency ?? 0)}%`
                  : 'Unused'}
              </NoWrapBadge>
            </Tooltip>
          ) : (
            <SkeletonLoading width="4rem" height="1.3125rem" />
          )}
        </ClientFrequencyContainer>
      ) : null}
    </RootContainer>
  );
};

const TagCard: React.FC<TagCardProps> = ({
  alwaysShowSubtitle,
  badge,
  badgeTooltip,
  clientFrequency,
  clientFrequencyActiveVideos,
  endSeconds,
  iconProps,
  iconThumbnail,
  isFiltered,
  marketFrequency,
  onClick,
  onToggleHoverPreview,
  startSeconds,
  subTitle,
  thumbnailLoading,
  thumbnailUrl,
  title,
  variant = 'type-value',
  videoUrl,
  iconsOverlay,
  width,
  objectFit,
  hoveringDelaySeconds,
}) => {
  const element = (hovered: boolean) =>
    HoverableElement(
      {
        alwaysShowSubtitle,
        badge,
        badgeTooltip,
        clientFrequency,
        clientFrequencyActiveVideos,
        endSeconds,
        hovered,
        iconProps,
        iconThumbnail,
        isFiltered,
        marketFrequency,
        onClick,
        onToggleHoverPreview,
        startSeconds,
        subTitle,
        thumbnailLoading,
        thumbnailUrl,
        title,
        variant,
        videoUrl,
        iconsOverlay,
        width,
        objectFit,
        hoveringDelaySeconds,
      } ?? <div />
    ) as React.ReactElement;
  const [hoverable] = useHover(element);
  return hoverable;
};

export default TagCard;
